import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form, ErrorMessage, useFormik } from 'formik'
import { IconDownload, IconX } from '@tabler/icons-react'
import Select from 'react-select'
import axiosInstance from '../../utils/AxiosInstance'
import * as Yup from 'yup'
import { saveAs } from 'file-saver'
const ModalExportFile = ({ click, setClick }) => {
  const buttonExcelFileRef1 = useRef()
  const [loadingFileExcel, setLoadingFileExcel] = useState(false)
  const [selectedOptionMonth, setSelectedOptionMonth] = useState(null)
  const [selectedValueMonth, setSelectedValueMonth] = useState(null)
  const [selectedValueYear, setSelectedValueYear] = useState(null)

  const [initialValues, setInitialValues] = useState({
    year: '',
    month: '',
  })

  // Set Option Months
  const optionsMonth = [
    { value: 'year', label: 'Tous les mois' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
  ]

  // Set Option Years
  const currentYear = new Date().getFullYear()
  let optionsYears = []
  for (let i = currentYear - 11; i <= currentYear; i++) {
    optionsYears.push({ value: i.toString(), label: i.toString() })
  }
  optionsYears = optionsYears.reverse()

  const validationSchema = Yup.object().shape({
    year: Yup.string().required("L'année est obligatoire"),
    // month: Yup.string().required('Le mois est obligatoire'),
    month: Yup.string().required('Le mois est obligatoire'),
    // month: Yup.array().required('Le mois est obligatoire').of(Yup.string()),
  })

  // handle selection Month
  const handleChangeMonth = (value, action) => {
    console.log(value)
    setSelectedValueMonth(value)

    if (value) {
      formik.values.month = value.value
      // setSelectedValueYear(value.value)
    } else {
      formik.values.month = ''
      // setSelectedValueYear(null)
    }
    // console.log(value)

    // if (
    //   value &&
    //   value.find((element) => element.value === 'year') &&

    //   action.option.value === 'year'
    // ) {
    //   setSelectedOptionMonth(value.filter((value) => value.value === 'year'))
    //   setSelectedValueMonth(['year'])
    //   formik.values.month = ['year']

    //   console.log('yes it is all monts', value)
    // } else {

    //   setSelectedOptionMonth(value.filter((value) => value.value !== 'year'))
    //   const arr = []
    //   value
    //     .filter((value) => value.value !== 'year')
    //     .map((x) => {
    //       return arr.push(x.value)
    //     })

    //   setSelectedValueMonth(arr)
    //   formik.values.month = arr
    // }

    // console.log()
    // const arr = []
    // value.map((x) => {
    //   return arr.push(x.value)
    // })
    // setSelectedValueMonth(arr)
  }

  // handle selection Year
  const handleChangeYear = (value) => {
    setSelectedValueYear(value)
    if (value) {
      formik.values.year = value.value
      // setSelectedValueYear(value.value)
    } else {
      formik.values.year = ''
      // setSelectedValueYear(null)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,

    onSubmit: (values, errors, touched) => {
      setLoadingFileExcel(true)
      buttonExcelFileRef1.current.disabled = true

      const response = axiosInstance
        .post(
          'api/trade/exportfileexceltest/',
          {
            month: values.month,
            year: values.year,
          },
          { responseType: 'arraybuffer' }
        )
        .then((res) => {
          const data = res.data

          const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const FileName = 'test' + '.xlsx'
          saveAs(blob, FileName)
          setLoadingFileExcel(false)
          buttonExcelFileRef1.current.disabled = false
        })
        .catch(function (error) {
          setLoadingFileExcel(false)
          console.log(error)
          buttonExcelFileRef1.current.disabled = false
        })
    },
  })

  useEffect(() => {
    formik.initialValues = {
      year: '',
      month: '',
    }
    formik.values.year = ''
    formik.values.month = ''
    formik.resetForm()
    setSelectedValueYear('')
    setSelectedValueMonth('')
  }, [click])

  return (
    <div
      className='modal modal-blur fade'
      id='modal-export-file'
      tabIndex='-1'
      role='dialog'
      aria-hidden='true'
    >
      <div
        className='modal-dialog modal-lg modal-dialog-centered'
        role='document'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Exporter Fichier</h5>
            {/* <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                ></button> */}
          </div>
          <form autoComplete='off' onSubmit={formik.handleSubmit}>
            <div className='modal-body'>
              <div className='row'>
                {/* Year */}
                <div className='col-lg-6'>
                  <div className='mb-3'>
                    <label className='form-label'>Année</label>
                    <Select
                      // defaultValue={[optionsYears[0]]}
                      value={selectedValueYear}
                      options={optionsYears}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      id='year'
                      name='year'
                      isSearchable={true}
                      isClearable={true}
                      placeholder='Sélectionner l"année'
                      // ref={subProductSelectRef}
                      onChange={handleChangeYear}
                    />
                    {formik.errors.year && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.year}
                      </div>
                    )}
                  </div>
                </div>
                {/* Type */}
                <div className='col-lg-6'>
                  <div className='mb-3'>
                    <label className='form-label'>Mois</label>
                    {/* Month */}
                    <Select
                      // defaultValue={[optionsMonth[0]]}
                      options={optionsMonth}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      // isMulti
                      value={selectedValueMonth}
                      id='month'
                      name='month'
                      isSearchable={true}
                      isClearable={true}
                      placeholder='Sélectionner le mois'
                      // ref={selectMontRef}
                      onChange={handleChangeMonth}
                    />

                    {formik.errors.month && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.month}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='modal-footer'>
              <a
                href='#'
                className='btn btn-link link-secondary'
                data-bs-dismiss='modal'
                // ref={modalRef}
              >
                Annuler
              </a>
              <button
                type='submit'
                className='btn btn-primary ms-auto'
                ref={buttonExcelFileRef1}
                style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
              >
                <IconDownload size={24} className='icon' />

                <div className='pe-2'>Télécharger</div>
                {loadingFileExcel ? (
                  <div
                    className='spinner-border spinner-border-sm'
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                ) : null}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ModalExportFile
