import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axiosInstance from '../../../utils/AxiosInstance'
const ModalAddProduct = ({
  titleModalProduct,
  subchapters,
  refreshProduct,
  setRefreshProduct,
  itemSelectedProduct,
  setItemSelectedProduct,
  dataTableProduct,
  setShow,
  setMessage,
  setShowError,
  setMessageError,
  settitleMessage,
}) => {
  const buttonSubmitRef = useRef()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [selectedValueSubChapter, setSelectedValueSubChapter] = useState(null)
  const [selectedValueType, setSelectedValueType] = useState(null)
  const [selectedValueUnit, setSelectedValueUnit] = useState(null)
  const selectlRefTypeProduct = useRef()
  const selectlRefUnit = useRef()
  const selectlRefSubChapter = useRef()
  const modalRefProduct = useRef()
  const [initialValues, setInitialValues] = useState({
    id: '',
    name: '',
    quantity_unit: '',
    type_trade: '',
    parent_id: '',
    subchapter: '',
  })

  // let iniValues = {
  //   id: '',
  //   name: '',
  //   quantity_unit: '',
  //   type_trade: '',
  //   parent_id: '',
  //   subchapter: '',
  // }

  const validationSchema = Yup.object().shape({
    id: Yup.string()
      .min(8, 'Id produit doit être au moins 8 caractères')
      .max(10, 'Id produit est trop long !')
      .trim()
      .required('Id produit est obligatoire'),
    name: Yup.string().trim().required('Nom sous position est obligatoire.'),
    parent_id: Yup.string()
      .min(0, 'Code parent doit être au moins 8 caractères')
      .max(10, 'Code parent est trop long !')
      .trim()
      .required("Code parent est obligatoire,mettre 0 s'il n'y est pas."),
    type_trade: Yup.string().trim().required('Type est obligatoire.'),
    quantity_unit: Yup.string().trim().required('Unité est obligatoire.'),
    subchapter: Yup.string().trim().required('Produit est obligatoire.'),
    // .test('user_name', "Le Nom d'utilisater exist déja", async (value) => {
    //   try {
    //     if (value != '' && value != null) {
    //       const res = await axiosInstance.post('serie/checkusername', {
    //         user_name: value,
    //       })
    //       return !res.data.username_exist
    //     } else return true
    //   } catch (err) {
    //     console.error(err)
    //   }
    // }),
  })

  // Option Unit For Select
  const optionsUnit = [
    { value: 'Kg', label: 'Kg' },
    { value: 'U', label: 'Unité' },
  ]

  // Option Type For Select
  const optionsType = [
    { value: 'A', label: 'Alimentaire' },
    { value: 'N', label: 'Non alimentaire' },
  ]

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,

    onSubmit: (values, errors, touched) => {
      // alert(JSON.stringify(values, null, 2))
      console.log('values', values)

      setLoadingSubmit(true)
      if (itemSelectedProduct == null) {
        AddProduct(
          values.id,
          values.name,
          values.quantity_unit,
          values.type_trade,
          values.parent_id,
          values.subchapter
        )
      } else {
        UppdateProduct(
          values.id,
          values.name,
          values.quantity_unit,
          values.type_trade,
          values.parent_id,
          values.subchapter
        )
      }
    },
  })

  // handle selection Type
  const handleChangeType = (value) => {
    setSelectedValueType(value)
    if (value) {
      formik.values.type_trade = value.value
    } else {
      formik.values.type_trade = ''
    }
  }

  // handle selection Unit
  const handleChangeUnit = (value) => {
    setSelectedValueUnit(value)
    if (value) {
      formik.values.quantity_unit = value.value
    } else {
      formik.values.quantity_unit = ''
    }
  }

  // handle selection SubChapter
  const handleChangeSubChapter = (value) => {
    setSelectedValueSubChapter(value)
    if (value) {
      formik.values.subchapter = value.value
    } else {
      formik.values.subchapter = ''
    }
  }

  const hideModalProduct = () => {
    formik.resetForm()
    formik.initialValues.subchapter = ''
    selectlRefTypeProduct.current.clearValue()
    selectlRefUnit.current.clearValue()
    selectlRefSubChapter.current.clearValue()
    setItemSelectedProduct(null)
    modalRefProduct.current.click()
  }

  const UppdateProduct = async (
    id,
    name,
    quantity_unit,
    type_trade,
    parent_id,
    subchapter
  ) => {
    const response = await axiosInstance
      .put('api/trade/product/' + id + '/', {
        name: name,
        quantity_unit: quantity_unit,
        type_trade: type_trade,
        parent_id: parent_id,
        subchapter: subchapter,
      })
      .then((res) => {
        const data = res.data
        settitleMessage("L'opération s'est terminée avec succès.")
        setMessage(data.message)

        formik.resetForm()
        formik.initialValues.subchapter = ''

        selectlRefTypeProduct.current.clearValue()
        selectlRefUnit.current.clearValue()
        selectlRefSubChapter.current.clearValue()
        // modalRefProduct.current.click()
        setLoadingSubmit(false)
        hideModalProduct()
        setRefreshProduct(!refreshProduct)
        setShow(true)
      })
      .catch(function (error) {
        settitleMessage("L'opération a échoué.")
        setMessageError(error.response.data.message)
        formik.resetForm()
        formik.initialValues.subchapter = ''

        selectlRefTypeProduct.current.clearValue()
        selectlRefUnit.current.clearValue()
        selectlRefSubChapter.current.clearValue()
        setLoadingSubmit(false)
        hideModalProduct()
        setRefreshProduct(!refreshProduct)
        setShowError(true)
      })
  }

  const AddProduct = async (
    id,
    name,
    quantity_unit,
    type_trade,
    parent_id,
    subchapter
  ) => {
    const response = await axiosInstance
      .post('api/trade/product/', {
        id: id,
        name: name,
        quantity_unit: quantity_unit,
        type_trade: type_trade,
        parent_id: parent_id,
        subchapter: subchapter,
      })
      .then((res) => {
        const data = res.data
        settitleMessage("L'opération s'est terminée avec succès.")
        setMessage(data.message)

        formik.resetForm()
        formik.initialValues.subchapter = ''

        selectlRefTypeProduct.current.clearValue()
        selectlRefUnit.current.clearValue()
        selectlRefSubChapter.current.clearValue()
        setLoadingSubmit(false)
        hideModalProduct()
        setRefreshProduct(!refreshProduct)
        setShow(true)
      })
      .catch(function (error) {
        settitleMessage("L'opération a échoué.")
        setMessageError(error.response.data.message)
        formik.resetForm()
        formik.initialValues.subchapter = ''

        selectlRefTypeProduct.current.clearValue()
        selectlRefUnit.current.clearValue()
        selectlRefSubChapter.current.clearValue()
        setLoadingSubmit(false)
        hideModalProduct()
        setRefreshProduct(!refreshProduct)
        setShowError(true)
      })
  }

  // const handleClickAddProduct = async (e) => {
  //   e.preventDefault()
  //   // modalAddButton.current.disabled = true
  //   // // console.log(modalRef)
  //   // // console.log(selectedValueSubChapter)
  //   // console.log('idProduct', idProduct)
  //   // console.log('parentId', parentId)
  //   // console.log('nameProduct', nameProduct)
  //   // console.log('typeTrade', typeTrade)
  //   // console.log('subChapterId', subChapterId)
  //   // console.log('unit', unit)

  //   // if (itemSelectedProduct == null) {
  //   //   const response = await axiosInstance
  //   //     .post('api/trade/product/', {
  //   //       id: idProduct,
  //   //       name: nameProduct,
  //   //       quantity_unit: unit,
  //   //       type_trade: typeTrade,
  //   //       parent_id: parentId,
  //   //       subchapter: subChapterId,
  //   //     })
  //   //     .then((res) => {
  //   //       const data = res.data
  //   //       console.log(data)
  //   //       modalRefProduct.current.click()
  //   //       setIdProduct('')
  //   //       setTypeTrade('')
  //   //       setSelectedValueType(null)
  //   //       setNameProduct('')
  //   //       setParentId('')
  //   //       setSubChapterId('')
  //   //       setSelectedValueSubChapter(null)
  //   //       setUnit('')
  //   //       setSelectedValueUnit(null)
  //   //       setRefreshProduct(!refreshProduct)
  //   //     })
  //   //     .catch(function (error) {
  //   //       console.log(error)
  //   //     })
  //   // } else {
  //   //   const response = await axiosInstance
  //   //     .put('api/trade/product/' + idProduct + '/', {
  //   //       name: nameProduct,
  //   //       quantity_unit: unit,
  //   //       type_trade: typeTrade,
  //   //       parent_id: parentId,
  //   //       subchapter: subChapterId,
  //   //     })
  //   //     .then((res) => {
  //   //       const data = res.data
  //   //       console.log(data)
  //   //       modalRefProduct.current.click()
  //   //       setIdProduct('')
  //   //       setTypeTrade('')
  //   //       setSelectedValueType(null)
  //   //       setNameProduct('')
  //   //       setParentId('')
  //   //       setSubChapterId('')
  //   //       setSelectedValueSubChapter(null)
  //   //       setUnit('')
  //   //       setSelectedValueUnit(null)
  //   //       setRefreshProduct(!refreshProduct)
  //   //     })
  //   //     .catch(function (error) {
  //   //       console.log(error)
  //   //     })
  //   // }
  //   // modalAddButton.current.disabled = false
  // }

  useEffect(() => {
    formik.initialValues = {
      id: '',
      name: '',
      quantity_unit: '',
      type_trade: '',
      parent_id: '',
      subchapter: '',
    }
    formik.values.id = ''
    formik.values.parent_id = ''
    formik.values.name = ''
    formik.resetForm()
    setSelectedValueType('')
    setSelectedValueUnit('')
    setSelectedValueSubChapter('')
    // console.log(itemSelectedProduct)

    formik.values.id =
      (itemSelectedProduct !== null) & (dataTableProduct.length !== 0)
        ? dataTableProduct.find((x) => x.id === itemSelectedProduct).id
        : ''

    formik.values.parent_id =
      (itemSelectedProduct !== null) & (dataTableProduct.length !== 0)
        ? dataTableProduct.find((x) => x.id === itemSelectedProduct).parent_id
        : ''

    formik.values.name =
      (itemSelectedProduct !== null) & (dataTableProduct.length !== 0)
        ? dataTableProduct.find((x) => x.id === itemSelectedProduct).name
        : ''

    setSelectedValueType(
      (itemSelectedProduct !== null) & (dataTableProduct.length !== 0)
        ? optionsType[
            optionsType
              .map((object) => object.value)
              .indexOf(
                dataTableProduct.find((x) => x.id === itemSelectedProduct)
                  .type_trade
              )
          ]
        : ''
    )

    formik.values.type_trade =
      (itemSelectedProduct !== null) & (dataTableProduct.length !== 0)
        ? dataTableProduct.find((x) => x.id === itemSelectedProduct).type_trade
        : ''

    setSelectedValueUnit(
      (itemSelectedProduct !== null) & (dataTableProduct.length !== 0)
        ? optionsUnit[
            optionsUnit
              .map((object) => object.value)
              .indexOf(
                dataTableProduct.find((x) => x.id === itemSelectedProduct)
                  .quantity_unit
              )
          ]
        : ''
    )

    formik.values.quantity_unit =
      (itemSelectedProduct !== null) & (dataTableProduct.length !== 0)
        ? dataTableProduct.find((x) => x.id === itemSelectedProduct)
            .quantity_unit
        : ''

    setSelectedValueSubChapter(
      (itemSelectedProduct !== null) & (dataTableProduct.length !== 0)
        ? subchapters[
            subchapters
              .map((object) => object.value)
              .indexOf(
                dataTableProduct.find((x) => x.id === itemSelectedProduct)
                  .subchapter_id
              )
          ]
        : ''
    )

    formik.values.subchapter =
      (itemSelectedProduct !== null) & (dataTableProduct.length !== 0)
        ? dataTableProduct.find((x) => x.id === itemSelectedProduct)
            .subchapter_id
        : ''

    // if (itemSelectedProduct != null) {
    //   if (dataTableProduct.length !== 0) {
    //     // console.log(formik)
    //     // setIdProduct(
    //     //   dataTableProduct.find((x) => x.id === itemSelectedProduct).id
    //     // )
    //     // formik.initialValues.id = dataTableProduct.find(
    //     //   (x) => x.id === itemSelectedProduct
    //     // ).id
    //     // formik.values.id = dataTableProduct.find(
    //     //   (x) => x.id === itemSelectedProduct
    //     // ).id
    //     // console.log(
    //     //   dataTableProduct.find((x) => x.id === itemSelectedProduct).id
    //     // )
    //     // setParentId(
    //     //   dataTableProduct.find((x) => x.id === itemSelectedProduct).parent_id
    //     // )
    //     // formik.values.parent_id = dataTableProduct.find(
    //     //   (x) => x.id === itemSelectedProduct
    //     // ).parent_id
    //     // formik.initialValues.id = dataTableProduct.find(
    //     //   (x) => x.id === itemSelectedProduct
    //     // ).parent_id
    //     // setNameProduct(
    //     //   dataTableProduct.find((x) => x.id === itemSelectedProduct).name
    //     // )
    //     // formik.values.name = dataTableProduct.find(
    //     //   (x) => x.id === itemSelectedProduct
    //     // ).name
    //     // setSelectedValueType(
    //     //   optionsType[
    //     //     optionsType
    //     //       .map((object) => object.value)
    //     //       .indexOf(
    //     //         dataTableProduct.find((x) => x.id === itemSelectedProduct)
    //     //           .type_trade
    //     //       )
    //     //   ]
    //     // )
    //     // setTypeTrade(
    //     //   dataTableProduct.find((x) => x.id === itemSelectedProduct).type_trade
    //     // )
    //     // formik.values.type_trade = dataTableProduct.find(
    //     //   (x) => x.id === itemSelectedProduct
    //     // ).type_trade
    //     // setSelectedValueUnit(
    //     //   optionsUnit[
    //     //     optionsUnit
    //     //       .map((object) => object.value)
    //     //       .indexOf(
    //     //         dataTableProduct.find((x) => x.id === itemSelectedProduct)
    //     //           .quantity_unit
    //     //       )
    //     //   ]
    //     // )
    //     // formik.values.quantity_unit = dataTableProduct.find(
    //     //   (x) => x.id === itemSelectedProduct
    //     // ).quantity_unit
    //     // setUnit(
    //     //   dataTableProduct.find((x) => x.id === itemSelectedProduct)
    //     //     .quantity_unit
    //     // )
    //     // setSelectedValueSubChapter(
    //     //   subchapters[
    //     //     subchapters
    //     //       .map((object) => object.value)
    //     //       .indexOf(
    //     //         dataTableProduct.find((x) => x.id === itemSelectedProduct)
    //     //           .subchapter_id
    //     //       )
    //     //   ]
    //     // )
    //     // formik.values.subchapter = dataTableProduct.find(
    //     //   (x) => x.id === itemSelectedProduct
    //     // ).subchapter_id
    //     // setSubChapterId(
    //     //   dataTableProduct.find((x) => x.id === itemSelectedProduct)
    //     //     .subchapter_id
    //     // )
    //     // console.log(
    //     //   dataTableProduct.find((x) => x.id === itemSelectedProduct)
    //     //     .subchapter_id
    //     // )
    //   }
    // }
  }, [itemSelectedProduct])

  return (
    <div
      className='modal modal-blur fade'
      id='add-product'
      tabIndex='-1'
      role='dialog'
      aria-hidden='true'
    >
      <div
        className='modal-dialog modal-lg modal-dialog-centered'
        role='document'
      >
        <div className='modal-content' style={{ borderColor: '#0073AD' }}>
          <div className='modal-header' style={{ borderColor: '#0073AD' }}>
            <h5 className='modal-title'>{titleModalProduct}</h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={hideModalProduct}
            ></button>
          </div>

          <form autoComplete='off' onSubmit={formik.handleSubmit}>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='mb-3'>
                    <label className='form-label'>Produit </label>
                    <Select
                      ref={selectlRefSubChapter}
                      value={selectedValueSubChapter}
                      options={subchapters}
                      getOptionLabel={(e) => e.value + '-' + e.label}
                      getOptionValue={(e) => e.value}
                      id='subchapter'
                      name='subchapter'
                      isSearchable={true}
                      isClearable={true}
                      placeholder='Sélectionner le produit'
                      onChange={handleChangeSubChapter}
                    />
                    {formik.errors.subchapter && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.subchapter}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='mb-3'>
                    <label className='form-label'>Id sous position</label>

                    <input
                      disabled={itemSelectedProduct != null ? true : false}
                      type='text'
                      className='form-control'
                      id='id'
                      name='id'
                      onChange={formik.handleChange}
                      value={formik.values.id}
                    />

                    {formik.errors.id && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.id}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='mb-3'>
                    <label className='form-label'>Code parent </label>
                    <input
                      type='text'
                      className='form-control'
                      id='parent_id'
                      name='parent_id'
                      onChange={formik.handleChange}
                      value={formik.values.parent_id}
                    />
                    {formik.errors.parent_id && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.parent_id}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='mb-3'>
                    <label className='form-label'>Type de sous position </label>
                    <Select
                      ref={selectlRefTypeProduct}
                      value={selectedValueType}
                      options={optionsType}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      id='type_trade'
                      name='type_trade'
                      isSearchable={true}
                      isClearable={true}
                      placeholder='Sélectionner le type'
                      onChange={handleChangeType}
                    />
                    {formik.errors.type_trade && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.type_trade}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='mb-3'>
                    <label className='form-label'>Unité</label>
                    <Select
                      ref={selectlRefUnit}
                      options={optionsUnit}
                      value={selectedValueUnit}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      id='quantity_unit'
                      name='quantity_unit'
                      isSearchable={true}
                      isClearable={true}
                      placeholder="Sélectionner l'unité"
                      onChange={handleChangeUnit}
                    />
                    {formik.errors.quantity_unit && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.quantity_unit}
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-lg-12'>
                  <div className='mb-3'>
                    <label className='form-label'>Nom sous position</label>
                    <textarea
                      rows='"'
                      className='form-control'
                      id='name'
                      name='name'
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                    {formik.errors.name && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.name}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer' style={{ borderColor: '#0073AD' }}>
              <a
                href='#'
                className='btn btn-link link-secondary'
                data-bs-dismiss='modal'
                ref={modalRefProduct}
                onClick={hideModalProduct}
              >
                Annuler
              </a>
              <button
                type='submit'
                className='btn btn-primary ms-auto'
                disabled={loadingSubmit ? true : false}
                style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
              >
                <div className='pe-2'>Enregister</div>
                {loadingSubmit ? (
                  <div className='spinner-border ' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                ) : null}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ModalAddProduct
