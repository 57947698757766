import React, { useState } from 'react'
import { IconUpload } from '@tabler/icons-react'
import ModalImportFile from '../../component/tools/administration/ModalImportFile'
import ModalSuccess from '../../component/tools/administration/ModalSuccess'
import ModalError from '../../component/tools/administration/ModalError'
const DataManagement = () => {
  const [show, setShow] = useState(false)
  const [showError, setShowError] = useState(false)
  const [message, setMessage] = useState('')
  const [titleMessage, settitleMessage] = useState('')
  const [messageError, setMessageError] = useState('')
  // modalSuccessRef.current.onclick()

  return (
    <>
      {/* Page header */}
      <div className='page-header d-print-none'>
        <div className='container-xl'>
          <div className='row g-2 align-items-center'>
            <div className='col'>
              {/* Page pre-title */}
              {/* <div className='page-pretitle'>Overview</div> */}
              <h2 className='page-title'>Données</h2>
            </div>
          </div>
        </div>
      </div>
      {/* Page body */}
      <div className='page-body'>
        <div className='container-xl'>
          <div className='row row-deck row-cards'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header'>
                  {/* <h3 className='card-title'>Card with action</h3> */}
                  <div className='card-actions'>
                    <a
                      href='#'
                      className='btn btn-primary'
                      data-bs-toggle='modal'
                      data-bs-target='#modal-import-file'
                      // onClick={() => handleAdduser()}
                      style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
                    >
                      <IconUpload
                        size={24}
                        className='icon'
                        style={{ color: '#ffffff' }}
                      />
                      Importer les données
                    </a>
                  </div>
                </div>
                <div className='card-body p-0'>
                  {/* {dataTable.length != 0 ? (
                    <DataTable
                      columns={columns}
                      data={dataTable}
                      pagination
                      striped
                      highlightOnHover
                      conditionalRowStyles={conditionalRowStyles}
                      progressPending={pending}
                      // customTheme={darkTheme}
                    />
                  ) : (
                    'There are no records to display'
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalImportFile
        setShow={setShow}
        setMessage={setMessage}
        setShowError={setShowError}
        setMessageError={setMessageError}
        settitleMessage={settitleMessage}
        // handleClick={handleClick}
        // setHandleClick={setHandleClick}
        // titleModalUser={titleModalUser}
        // itemSelected={itemSelected}
        // setItemSelected={setItemSelected}
        // dataTable={dataTable}
      />
      <ModalSuccess
        show={show}
        setShow={setShow}
        message={message}
        titleMessage={titleMessage}
      />
      <ModalError
        showError={showError}
        setShowError={setShowError}
        messageError={messageError}
        titleMessage={titleMessage}
      />
    </>
  )
}

export default DataManagement
