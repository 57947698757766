import React from 'react'

import Select from 'react-select'
const SelectContinent = ({
  continents,
  selectedValueContinent,
  handleChangeContinent,
}) => {
  return (
    <>
      <Select
        required
        value={selectedValueContinent}
        options={continents}
        getOptionLabel={(e) => e.value + '-' + e.label}
        getOptionValue={(e) => e.value}
        id='continent_id'
        name='continent_id'
        isSearchable={true}
        isClearable={true}
        placeholder='Sélectionner le continent'
        onChange={handleChangeContinent}
      />
    </>
  )
}

export default SelectContinent
