import { createContext, useState, useEffect } from 'react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import axiosInstance from '../utils/AxiosInstance'

import { getTTFB } from 'web-vitals'
const AuthContext = createContext()

export default AuthContext

export const AuthProvider = ({ children }) => {
  let navigate = useNavigate()
  const [dataChapter, setDataChapter] = useState([])
  // const [test, setTest] = useState()
  // Routes administration
  const [adminRoutes, setAdminRoutes] = useState([
    '/homeadmin',
    '/users',
    '/products',
    '/regions',
    '/datamanagement',
  ])
  // Usestate authTokens
  // const [authTokens, setAuthTokens] = useState(() =>
  //   localStorage.getItem('authTokens')
  //     ? JSON.parse(localStorage.getItem('authTokens'))
  //     : null
  // )
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem('tp_ac')
      ? JSON.parse(localStorage.getItem('tp_ac'))
      : null
  )

  const [user, setUser] = useState({})
  const [reload, setReload] = useState(true)

  function getUser(user_id) {
    axiosInstance
      .get('api/base/users/' + user_id + '/')
      .then((res) => {
        // console.log('getUser from authcontext', res.data)
        setUser(res.data)
      })
      .catch((e) => {
        console.log(' fatal error ')
      })
  }

  // Usestate userName
  const [userName, setUserName] = useState(() =>
    // localStorage.getItem('authTokens')
    //   ? JSON.parse(localStorage.getItem('authTokens')).user_name
    //   : null
    localStorage.getItem('user_name')
      ? JSON.parse(localStorage.getItem('user_name'))
      : null
  )

  // Usestate userPermissions
  const [userPermissions, setUserPermissions] = useState(() =>
    localStorage.getItem('authTokens')
      ? JSON.parse(localStorage.getItem('authTokens')).i
      : null
  )

  // Usestate useRole
  // const [userRole, setuserRole] = useState(() =>
  //   localStorage.getItem('authTokens')
  //     ? JSON.parse(localStorage.getItem('authTokens')).role
  //     : null
  // )

  const [loading, setLoading] = useState(true)

  // LogIn Function
  const loginUser = async (username, password) => {
    // e.preventDefault()
    // console.log('Form submitted')
    let response_token
    const response = await axiosInstance
      .post('/api/token/', {
        // user_name: e.target.user_name.value,
        // password: e.target.password.value,
        user_name: username,
        password: password,
      })
      .then((res) => {
        const data = res.data
        // console.log('call function get User with function login', data)

        setAuthTokens(data.access)

        setUserName(data.user_name)

        axiosInstance.defaults.headers['Authorization'] = 'JWT ' + data.access
        // localStorage.setItem('authTokens', JSON.stringify(data))
        localStorage.setItem('tp_ac', JSON.stringify(data.access))
        localStorage.setItem('tp_rf', JSON.stringify(data.refresh))
        localStorage.setItem('user_id', JSON.stringify(data.user_id))
        localStorage.setItem('user_name', JSON.stringify(data.user_name))
        getUser(data.user_id)
        // getUser(data.user_id)
        response_token = res
        // console.log('first_login', data.first_login)
        if (data.first_login === true) {
          // console.log('navigate to firstlogin', data.first_login)
          navigate('/firstlogin')
        } else {
          // console.log('navigate to /', data.first_login)
          navigate('/')
        }
      })
      .catch(function (error) {
        response_token = error
        // return 'test'
        // console.log(error)
        // if (
        //   error.response.status === 401 &&
        //   error.response.data ===
        //     'No active account found with the given credentials'
        // ) {
        //   return 'No active account found with the given credentials'
        // }
        // console.log(error.response.status)
        // console.log(error.response.data)
      })

    return response_token

    // console.log(response)
    // const data = await response.data
    // if (response.status === 200) {
    //   setAuthTokens(data)
    //   setUser(data.user_name)
    //   localStorage.setItem('authTokens', JSON.stringify(data))
    //   navigate('/')
    // } else {
    //   alert('Something went wrong')
    // }
    // console.log('data ', data.user_name)
  }

  // Logout Function
  const logoutUser = () => {
    setAuthTokens(null)
    setUserName(null)
    // localStorage.removeItem('authTokens')
    localStorage.removeItem('tp_ac')
    localStorage.removeItem('tp_rf')
    localStorage.removeItem('user_id')
    localStorage.removeItem('user_name')
    navigate('/login')
  }

  // Get routes administration
  // const getAdminRoutes = () => {
  //   // console.log('yes routes admin')
  // }

  // Function to get chapters
  // const getDataChapter = async () => {
  //   const response = axiosInstance
  //     .get('api/trade/chapter/')
  //     .then((res) => {
  //       const data = res.data
  //       // console.log(data)
  //       const arr = []

  //       data.map((x) => {
  //         return arr.push({ value: x.id, label: x.name })
  //       })
  //       setDataChapter(arr)
  //     })
  //     .catch(function (error) {
  //       console.log(error)
  //     })
  // }

  // const updateToken = async () => {
  //   console.log('upadte console log')
  //   const response = await fetch('http://127.0.0.1:8000/api/token/refresh/', {
  //     method: 'POST',
  //     headers: {
  //       'Content-type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       refresh: authTokens.refresh,
  //     }),
  //   })

  //   const data = await response.json()

  //   if (response.status === 200) {
  //     setAuthTokens(data)
  //     setUser(data.user_name)
  //     localStorage.setItem('authTokens', JSON.stringify(data))
  //   } else {
  //     logoutUser()
  //   }
  // }

  let contextData = {
    loginUser: loginUser,
    userName: userName,
    user: user,
    setUser: setUser,
    reload: reload,
    setReload: setReload,
    // userPermissions: userPermissions,
    // userRole: userRole,
    // setUser: setUser,
    logoutUser: logoutUser,
    // authTokens: authTokens,
    // setAuthTokens: setAuthTokens,
    adminRoutes: adminRoutes,
    setAdminRoutes: setAdminRoutes,
    // getAdminRoutes: getAdminRoutes,
    // dataChapter: dataChapter,
  }

  useEffect(() => {
    if (authTokens) {
      // setUserName(JSON.parse(localStorage.getItem('authTokens')).user_name)
      setUserName(JSON.parse(localStorage.getItem('user_name')))
      // setUserPermissions(JSON.parse(localStorage.getItem('authTokens')).i)
    }

    setLoading(false)
  }, [authTokens, loading])

  // useEffect(() => {
  //   // console.log(
  //   //   'useeffect autcontext to test getuser',
  //   //   'user_id' in localStorage.getItem('authTokens')
  //   // )
  //   // if (
  //   //   localStorage.getItem('authTokens') !== null &&
  //   //   localStorage.getItem('authTokens').hasOwnProperty('user_id')
  //   // ) {
  //   //   console.log('uuid is not null')
  //   //   try {
  //   //     // console.log('useEffect from authcontext to test console')
  //   //     let uuid = JSON.parse(localStorage.getItem('authTokens')).user_id
  //   //     console.log('call function get User with useeffect once')
  //   //     getUser(uuid)
  //   //   } catch (error) {
  //   //     console.log(error)
  //   //   }
  //   // }
  //   if (localStorage.getItem('user_id') !== null) {
  //     // console.log('uuid is not null')
  //     try {
  //       // console.log('useEffect from authcontext to test console')
  //       let uuid = JSON.parse(localStorage.getItem('user_id'))
  //       // console.log('call function get User with useeffect once')
  //       getUser(uuid)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  // }, [])

  useEffect(() => {
    if (localStorage.getItem('user_id') !== null) {
      // console.log('uuid is not null')
      try {
        // console.log('useEffect from authcontext to test console')
        let uuid = JSON.parse(localStorage.getItem('user_id'))
        // console.log('call function get User with useeffect once')
        getUser(uuid)
      } catch (error) {
        console.log(error)
      }
    }
  }, [reload])

  // useEffect(() => {
  //   getDataChapter()
  // }, [])

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  )
}
