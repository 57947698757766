import React, { useState, useContext, useRef } from 'react'
import DataTables from '../component/tools/DataTables'
import Filter from '../component/tools/Filter'
import { IconDownload, IconX } from '@tabler/icons-react'

import axiosInstance from '../utils/AxiosInstance'
import { saveAs } from 'file-saver'

// import Select from 'react-select'
// import SelectChapter from '../component/tools/SelectChapter'
// import SelectSubChapter from '../component/tools/SelectSubChapter'
// import SelectProduct from '../component/tools/SelectProduct'
// import AsyncSelect from 'react-select/async'
import AuthContext from '../context/AuthContext'
const Data = () => {
  const { user } = useContext(AuthContext)
  const buttonExcelFileRef = useRef()
  const [loadingFileExcel, setLoadingFileExcel] = useState(false)
  const [loading, setLoading] = useState(false)
  // console.log('Data', user.permissions['data_page'])
  // console.log('Data', user)
  const [searchFilter, setsearchFilter] = useState('')
  const [pending, setPending] = useState(true)
  const [titleTable, setTitleTable] = useState('')
  const [subTitleTable, setSubTitleTable] = useState('')
  const [titleUnit, setTitleUnit] = useState('Dollar Américain')
  // const subHideFilterRef = useRef()
  // const subChapterSelectRef = useRef()
  // const subProductSelectRef = useRef()
  // const selectRegionRef = useRef()
  // const selectByRef = useRef()
  // const [chapters, setChapters] = useState([])
  // const [subchapters, setSubChapters] = useState([])
  // const [products, setProducts] = useState([])
  // const [regions, setRegions] = useState([])
  // const [valuesBy, setValuesBy] = useState([
  //   { value: 'country', label: 'Pays' },
  //   { value: 'product', label: 'Produits' },
  //   { value: 'continent', label: 'Continents' },
  //   { value: 'economic_region', label: 'Régions économiques' },
  // ])
  const [selectRadioRegion, setSelectRadioRegion] = useState('countries')
  const [selectedValueChapter, setSelectedValueChapter] = useState(null)
  const [selectedValueSubChapter, setSelectedValueSubChapter] = useState([])
  const [selectedValueProduct, setSelectedValueProduct] = useState([])
  const [selectedValueRegion, setSelectedValueRegion] = useState(null)
  const [selectedValueYear, setSelectedValueYear] = useState(['2022', '2023'])
  const [selectedValueMonth, setSelectedValueMonth] = useState([])
  const [selectedValueTypeTrade, setSelectedValueTypeTrade] = useState('import')
  const [selectedValueBy, setSelectedValueBy] = useState(null)
  // const [isDisabledBy, setIsDisabledBy] = useState(false)
  const [selectedValueDisplay, setSelectedValueDisplay] = useState('value_usd')
  // const [indexselectby, setIndexselectBy] = useState(1)

  const [columns, setColumns] = useState([
    // {
    //   name: 'Pays',
    //   selector: (row) => row.title,
    //   // sortable: true,
    // },
    // {
    //   name: '2012',
    //   selector: (row) => row.year,
    //   // sortable: true,
    // },
  ])

  const [dataTable, setDataTable] = useState([])

  const [dataTableCopy, setDataTableCopy] = useState([])

  // Handle Export Excel
  const handleExportExcel = () => {
    setLoadingFileExcel(true)
    buttonExcelFileRef.current.disabled = true
    if (
      selectedValueChapter != null &&
      selectedValueRegion != null &&
      selectedValueTypeTrade != null &&
      selectedValueBy != null &&
      selectedValueDisplay != null &&
      selectedValueYear.length > 0 &&
      selectedValueMonth.length > 0
    ) {
      const response = axiosInstance
        .post(
          'api/trade/exportfileexcel/',
          {
            chapter: selectedValueChapter,
            subchapter: selectedValueSubChapter,
            product: selectedValueProduct,
            region_type: selectRadioRegion,
            region: selectedValueRegion,
            years: selectedValueYear,
            month: selectedValueMonth,
            type_trade: selectedValueTypeTrade,
            display_value: selectedValueDisplay,
            display_by: selectedValueBy,
            titleTable: titleTable,
            subTitleTable: subTitleTable,
            titleUnit: titleUnit,
          },
          { responseType: 'arraybuffer' }
        )
        .then((res) => {
          const data = res.data
         
          const blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const FileName = titleTable + '.xlsx'
          saveAs(blob, FileName)
          setLoadingFileExcel(false)
          buttonExcelFileRef.current.disabled = false
        })
        .catch(function (error) {
          setLoadingFileExcel(false)
          buttonExcelFileRef.current.disabled = false
          console.log(error)
        })
    }
  }

  // const optionsMonth = [
  //   { value: '01', label: '01' },
  //   { value: '02', label: '02' },
  //   { value: '03', label: '03' },
  //   { value: '04', label: '04' },
  //   { value: '05', label: '05' },
  //   { value: '06', label: '06' },
  //   { value: '07', label: '07' },
  //   { value: '08', label: '08' },
  //   { value: '09', label: '09' },
  //   { value: '10', label: '10' },
  //   { value: '11', label: '11' },
  //   { value: '12', label: '12' },
  // ]

  // const optionsYears = [
  //   { value: '2012', label: '2012' },
  //   { value: '2013', label: '2013' },
  //   { value: '2014', label: '2014' },
  //   { value: '2015', label: '2015' },
  //   { value: '2016', label: '2016' },
  //   { value: '2017', label: '2017' },
  //   { value: '2018', label: '2018' },
  //   { value: '2019', label: '2019' },
  //   { value: '2020', label: '2021' },
  //   { value: '2022', label: '2022' },
  //   { value: '2023', label: '2023' },
  // ]

  // const optionsTypeTrade = [
  //   { value: 'import', label: 'Importation' },
  //   { value: 'export', label: 'Exportation' },
  // ]

  // const optionsValues = [
  //   { value: 'value_usd', label: 'Valeur USD' },
  //   { value: 'value_dzd', label: 'Valeur DZD' },
  //   { value: 'weight', label: 'Poids' },
  // ]

  // const optionsBy = [
  //   { value: 'country', label: 'Pays' },
  //   { value: 'product', label: 'Produits' },
  //   { value: 'continent', label: 'Continents' },
  //   { value: 'economic_region', label: 'Régions économiques' },
  // ]

  // // handle selection Chapter
  // const handleChangeChapter = (value) => {
  //   // console.log(value.value)
  //   if (value) {
  //     setSelectedValueChapter(value.value)
  //   } else {
  //     setSelectedValueChapter(null)
  //   }

  //   subProductSelectRef.current.clearValue()
  //   setSelectedValueProduct([])
  //   subChapterSelectRef.current.clearValue()
  //   setSelectedValueSubChapter(null)
  // }

  // // handle selection SubChapter
  // const handleChangeSubChapter = (value) => {
  //   if (value) {
  //     setSelectedValueSubChapter(value.value)
  //   } else {
  //     setSelectedValueSubChapter(null)
  //   }
  //   subProductSelectRef.current.clearValue()
  //   setSelectedValueProduct([])
  // }

  // // handle selection Product
  // const handleChangeProduct = (value) => {
  //   if (value) {
  //     // setSelectedValueProduct(value.value)
  //     const arr = []
  //     value.map((x) => {
  //       return arr.push(x.value)
  //     })
  //     setSelectedValueProduct(arr)
  //   } else {
  //     setSelectedValueProduct([])

  //     // setSelectedValueProduct(null)
  //   }
  // }

  // // handle selection Region
  // const handleSelectRegion = (value) => {
  //   // console.log(value)
  //   if (value) {
  //     // if (value.value !== '00') {
  //     //   if (selectRadioRegion === 'countries') {
  //     //     setValuesBy([{ value: 'product', label: 'Produits' }])
  //     //   } else {
  //     //     setValuesBy([
  //     //       { value: 'country', label: 'Pays' },
  //     //       { value: 'product', label: 'Produits' },
  //     //     ])
  //     //   }

  //     //   selectByRef.current.clearValue()
  //     // } else {
  //     //   if (selectRadioRegion === 'countries') {
  //     //     setValuesBy([
  //     //       { value: 'country', label: 'Pays' },
  //     //       { value: 'product', label: 'Produits' },
  //     //     ])
  //     //   } else {
  //     //     setValuesBy([
  //     //       { value: 'product', label: 'Produits' },
  //     //       { value: 'continent', label: 'Continents' },
  //     //       { value: 'economic_region', label: 'Régions économiques' },
  //     //     ])
  //     //   }
  //     //   selectByRef.current.clearValue()
  //     //   // setIsDisabledBy(false)
  //     // }

  //     setSelectedValueRegion(value.value)
  //   } else {
  //     setSelectedValueRegion(null)
  //   }
  // }

  // // handle selection Radio region
  // const handleChangeRegion = (e) => {
  //   // selectByRef.current.clearValue()
  //   selectRegionRef.current.clearValue()
  //   setSelectRadioRegion(e.target.value)
  // }

  // // handle selection Year
  // const handleChangeYear = (value) => {
  //   // console.log(selectedValueYear)
  //   const arr = []
  //   value.map((x) => {
  //     return arr.push(x.value)
  //   })
  //   setSelectedValueYear(arr)
  // }

  // // handle selection Month
  // const handleChangeMonth = (value) => {
  //   // console.log(selectedValueYear)
  //   const arr = []
  //   value.map((x) => {
  //     return arr.push(x.value)
  //   })
  //   setSelectedValueMonth(arr)
  // }

  // // handle selection Type Trade
  // const handleChangeTapeTrade = (value) => {
  //   if (value) {
  //     setSelectedValueTypeTrade(value.value)
  //   } else {
  //     setSelectedValueTypeTrade(null)
  //   }
  // }

  // // handle selection By
  // const handleChangeBy = (value) => {
  //   if (value) {
  //     setSelectedValueBy(value.value)
  //   } else {
  //     setSelectedValueBy(null)
  //   }
  // }

  // // handle selection Display
  // const handleChangeDisplay = (value) => {
  //   if (value) {
  //     setSelectedValueDisplay(value.value)
  //   } else {
  //     setSelectedValueDisplay(null)
  //   }
  // }

  // // // Function to call api for getting chapitres
  // const GetChapters = async (e) => {
  //   const response = await axiosInstance
  //     .get('api/trade/chapter/')
  //     .then((res) => {
  //       const data = res.data
  //       const arr = [{ value: '00', label: 'Tous les chapitres' }]

  //       data.map((x) => {
  //         return arr.push({ value: x.id, label: x.name })
  //       })
  //       setChapters(arr)
  //     })
  //     .catch(function (error) {
  //       console.log(error)
  //     })
  // }

  // // Function to call api for getting subchapter by chpater
  // const GeTSubChapter = async (chapter_id) => {
  //   // console.log(chapter_id)
  //   if (chapter_id !== '00') {
  //     const response = await axiosInstance
  //       .get('api/trade/subchapterlist/' + chapter_id + '/')
  //       .then((res) => {
  //         const data = res.data
  //         const arr = [{ value: '00', label: 'Tous les produits' }]
  //         // console.log(data)

  //         data.map((x) => {
  //           return arr.push({ value: x.id, label: x.name })
  //         })
  //         setSubChapters(arr)
  //       })
  //       .catch(function (error) {
  //         console.log(error)
  //       })
  //   } else {
  //     setSubChapters([{ value: '00', label: 'Tous les produits' }])
  //   }
  // }

  // // Function to call api for getting product by subchpater
  // const GeTProduct = async (subchapter_id) => {
  //   // console.log(subchapter_id)
  //   if (subchapter_id !== '00') {
  //     const response = await axiosInstance
  //       .get('api/trade/productlist/' + subchapter_id + '/')
  //       .then((res) => {
  //         const data = res.data
  //         const arr = []
  //         // console.log(data)

  //         data.map((x) => {
  //           return arr.push({ value: x.id, label: x.name })
  //         })
  //         setProducts(arr)
  //       })
  //       .catch(function (error) {
  //         console.log(error)
  //       })
  //   } else {
  //     setProducts([{ value: '00', label: 'Toutes les sous-positions' }])
  //   }
  // }

  // // Function to call api for getting region countrizs or regions
  // const GetRegions = async (selectRadioRegion) => {
  //   // console.log(selectRadioRegion)
  //   let region = ''
  //   if (selectRadioRegion === 'countries') {
  //     region = 'country'
  //   } else {
  //     region = 'regionlist'
  //   }
  //   const response = await axiosInstance
  //     .get('api/trade/' + region + '/')
  //     .then((res) => {
  //       const data = res.data
  //       // console.log(data)
  //       const arr = []

  //       if (selectRadioRegion === 'countries') {
  //         arr.push({ value: '00', label: 'Aucun' })
  //         data.map((x) => {
  //           return arr.push({ value: x.code, label: x.name_fr })
  //         })
  //       } else {
  //         arr.push({ value: '00', label: 'Aucun' })
  //         data.map((x) => {
  //           return arr.push({ value: x.id, label: x.name_region })
  //         })
  //       }
  //       setRegions(arr)
  //     })
  //     .catch(function (error) {
  //       console.log(error)
  //     })
  //   // setValuesBy([])
  //   // selectRegionRef.current.setValue(regions[0])
  // }

  // // Call function GetChapitre
  // useEffect(() => {
  //   GetChapters()
  // }, [])

  // // Call function GetSubChpater
  // useEffect(() => {
  //   // console.log(selectedValue)
  //   GeTSubChapter(selectedValueChapter)
  // }, [selectedValueChapter])

  // // Call function GetProduct
  // useEffect(() => {
  //   // console.log(selectedValue)
  //   GeTProduct(selectedValueSubChapter)
  // }, [selectedValueSubChapter])

  // // Call function GetRegion
  // useEffect(() => {
  //   GetRegions(selectRadioRegion)
  // }, [selectRadioRegion])

  // // GET  DATA
  // useEffect(() => {
  //   if (
  //     selectedValueChapter != null &&
  //     selectedValueRegion != null &&
  //     selectedValueTypeTrade != null &&
  //     selectedValueBy != null &&
  //     selectedValueDisplay != null &&
  //     selectedValueYear.length > 0 &&
  //     selectedValueMonth.length > 0
  //   ) {
  //     setPending(true)
  //     // console.log('selectedValueChapter', selectedValueChapter)
  //     // console.log('selectedValueSubChapter', selectedValueSubChapter)
  //     // console.log('selectedValueProduct', selectedValueProduct)
  //     // console.log('selectRadioRegion', selectRadioRegion)
  //     // console.log('selectedValueRegion', selectedValueRegion)
  //     // console.log('selectedValueYear', selectedValueYear)
  //     // console.log('selectedValueTypeTrade', selectedValueTypeTrade)
  //     // console.log('selectedValueBy', selectedValueBy)
  //     // console.log('selectedValueDisplay', selectedValueDisplay)
  //     // setColumns([
  //     //   {
  //     //     name: 'Produit',
  //     //     selector: (row) => row.product,
  //     //     // sortable: true,
  //     //   },
  //     //   {
  //     //     name: '2013',
  //     //     selector: (row) => row.value_2013,
  //     //     // sortable: true,
  //     //   },
  //     // ])
  //     // setDataTable([
  //     //   {
  //     //     id: 1,
  //     //     product: 'produit1',
  //     //     value_2013: '500',
  //     //   },
  //     //   {
  //     //     id: 2,
  //     //     product: 'produit2',
  //     //     value_2013: '100',
  //     //   },
  //     // ])

  //     // Set columns name datatable
  //     // console.log(optionsYears)
  //     const arr_columns = []
  //     selectedValueYear.map((x) => {
  //       // console.log(x)
  //       return arr_columns.push({
  //         name: x,
  //         selector: (row) => row[optionsYears.find((y) => y.value === x).label],
  //         format: (row) =>
  //           Intl.NumberFormat().format(
  //             row[optionsYears.find((y) => y.value === x).label]
  //           ),

  //         sortable: true,
  //         right: true,
  //       })
  //     })
  //     arr_columns.unshift({
  //       name: valuesBy.find((x) => x.value === selectedValueBy).label,
  //       selector: (row) =>
  //         row[valuesBy.find((x) => x.value === selectedValueBy).value],
  //       wrap: true,
  //       // (row) => row.country_name
  //     })
  //     setColumns(arr_columns)

  //     // console.log(columns)
  //     const response = axiosInstance
  //       .post('api/trade/data/', {
  //         chapter: selectedValueChapter,
  //         subchapter: selectedValueSubChapter,
  //         product: selectedValueProduct,
  //         region_type: selectRadioRegion,
  //         region: selectedValueRegion,
  //         years: selectedValueYear,
  //         month: selectedValueMonth,
  //         type_trade: selectedValueTypeTrade,
  //         display_value: selectedValueDisplay,
  //         display_by: selectedValueBy,
  //       })
  //       .then((res) => {
  //         const data = res.data
  //         console.log(data)

  //         // Set rows datatable
  //         const arr_data_table = []
  //         const arr_data_table_test = []
  //         // selectedValueYear.map((y, index_year) => {
  //         //   // console.log(y)
  //         //   return arr_data_table_test.push({
  //         //     id: index_year,
  //         //     [y]: y,
  //         //   })
  //         // })
  //         // console.log(arr_data_table_test)
  //         // console.log(data[selectedValueYear[0]])
  //         // data['2012'].map((x, index) => {
  //         //   // console.log(index, x)
  //         //   return arr_data_table.push({
  //         //     id: index,
  //         //     [selectedValueBy]: data['country_name'][index],
  //         //     2012: x,
  //         //   })
  //         //   // console.log({
  //         //   //   id: index,
  //         //   //   country: x.country__name_fr,
  //         //   //   value_2012: x.value_usd,
  //         //   // })
  //         // })
  //         // selectedValueYear.map((x, index) => {
  //         //   return arr_data_table.push({ id: index, x: x, value_x: 1000 })
  //         //   // console.log(x)
  //         // })
  //         setPending(false)
  //         setDataTable(data)
  //         // subHideFilterRef.current.click()
  //       })
  //       .catch(function (error) {
  //         console.log(error)
  //       })
  //   }
  // }, [
  //   selectedValueChapter,
  //   selectedValueSubChapter,
  //   selectedValueProduct,
  //   selectRadioRegion,
  //   selectedValueRegion,
  //   selectedValueYear,
  //   selectedValueMonth,
  //   selectedValueTypeTrade,
  //   selectedValueBy,
  //   selectedValueDisplay,
  // ])

  // Function Clear Search Input
  const clearSearch = () => {
    
    setsearchFilter('')
    setDataTable(dataTableCopy)
  }

  // Handle Search Input
  const handleSerach = (e) => {
    setsearchFilter(e.target.value)
    let position_column = 0
    if (selectedValueYear.length >= 2) {
     
      position_column = position_column + selectedValueYear.length + 1
     
    } else {
      position_column = position_column + selectedValueYear.length
  
    }
    console.log(position_column)
    if (e.target.value === '') {
      
      setDataTable(dataTableCopy)
    } else {
      
      const filteredvalue = dataTableCopy.reduce((accumulator, value) => {
       
        let column_filtred = Object.keys(value)[position_column - 1]
       

        // if (
        //   value.country.toLowerCase().includes(e.target.value.toLowerCase())
        // ) {
        //   accumulator.push(value)
        // }
        if (
          value[column_filtred]
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          accumulator.push(value)
        }

        return accumulator
      }, [])
     
      if (filteredvalue.length > 0) {
        setDataTable(filteredvalue)
      }
    }
    
    // // setDataTableCopy(dataTable)
    // setDataTable(dataTableCopy.filter((value) => value.country === 'Allemagne'))
    
  }

  if (Object.keys(user).length > 0 && user.permissions['data_page']) {
    return (
      <div className='page-wrapper'>
        {/* Page header */}
        {/* <div className='page-header d-print-none'>
        <div className='container-xl'>
          <div className='row g-2 align-items-center'>
            <div className='col'>
              <h2 className='page-title'>Empty page</h2>
            </div>
          </div>
        </div>
      </div> */}
        {/* Page body */}
        <div className='page-body'>
          <div className='container-xl'>
            <Filter
              selectedValueChapter={selectedValueChapter}
              setSelectedValueChapter={setSelectedValueChapter}
              selectedValueSubChapter={selectedValueSubChapter}
              setSelectedValueSubChapter={setSelectedValueSubChapter}
              selectedValueProduct={selectedValueProduct}
              setSelectedValueProduct={setSelectedValueProduct}
              selectedValueRegion={selectedValueRegion}
              setSelectedValueRegion={setSelectedValueRegion}
              selectRadioRegion={selectRadioRegion}
              setSelectRadioRegion={setSelectRadioRegion}
              selectedValueYear={selectedValueYear}
              setSelectedValueYear={setSelectedValueYear}
              selectedValueMonth={selectedValueMonth}
              setSelectedValueMonth={setSelectedValueMonth}
              selectedValueTypeTrade={selectedValueTypeTrade}
              setSelectedValueTypeTrade={setSelectedValueTypeTrade}
              selectedValueDisplay={selectedValueDisplay}
              setSelectedValueDisplay={setSelectedValueDisplay}
              selectedValueBy={selectedValueBy}
              setSelectedValueBy={setSelectedValueBy}
              setTitleUnit={setTitleUnit}
              setDataTable={setDataTable}
              setDataTableCopy={setDataTableCopy}
              setColumns={setColumns}
              setPending={setPending}
              setTitleTable={setTitleTable}
              setSubTitleTable={setSubTitleTable}
              setLoading={setLoading}
            />
            {/* tabs */}
            <div className='row row-cards mb-4 '>
              <div className='col-12'>
                <div
                  className='card '
                  style={{
                    borderColor: '#0073AD',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                  }}
                >
                  <div className='card-body p-0'>
                    {dataTable.length !== 0 && (
                      <>
                        {/* Table Title */}
                        <div className='row'>
                          <div className='col text-center'>
                            <h3 className='card-title '>{titleTable}</h3>
                            <h4 className='card-title '>{subTitleTable}</h4>
                          </div>
                        </div>
                        {/* Export excel and search  */}
                        <div className='row'>
                          <div className='col-6'>
                            <div className='row  align-items-center py-2 px-2'>
                              <div className='col '>Unité : {titleUnit}</div>
                            </div>
                            <div className='py-2 px-2'>
                              <div className='btn-list'>
                                <button
                                  ref={buttonExcelFileRef}
                                  className='btn '
                                  type='button'
                                  onClick={() => handleExportExcel()}
                                  style={{
                                    backgroundColor: '#1d6f42',
                                    color: '#ffffff',
                                  }}
                                >
                                  <IconDownload size={24} className='icon' />

                                  <div className='pe-2'>Excel</div>
                                  {loadingFileExcel ? (
                                    <div
                                      className='spinner-border spinner-border-sm'
                                      role='status'
                                    >
                                      <span className='visually-hidden'>
                                        Loading...
                                      </span>
                                    </div>
                                  ) : null}
                                </button>
                                {/* <a
                                  buttonExcelFileRef={buttonExcelFileRef}
                                  className='btn '
                                  type='button'
                                  onClick={() => handleExportExcel()}
                                  style={{
                                    backgroundColor: '#1d6f42',
                                    color: '#ffffff',
                                  }}
                                ></a> */}
                              </div>
                            </div>
                          </div>

                          <div className='col-6'>
                            <div className='d-flex justify-content-end py-2 px-2'>
                              <div className=''>
                                <label className='form-label'>Recherche</label>
                                <div className='input-group input-group-flat'>
                                  <input
                                    type='text'
                                    className='form-control '
                                    required
                                    id='filter'
                                    name='filter'
                                    value={searchFilter}
                                    onChange={(e) => handleSerach(e)}
                                  />
                                  <span className='input-group-text'>
                                    <a
                                      role='button'
                                      class='link-secondary'
                                      title='Clear search'
                                      data-bs-toggle='tooltip'
                                      onClick={() => clearSearch()}
                                    >
                                      {searchFilter !== '' && (
                                        <IconX size={24} className='icon' />
                                      )}
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='mb-3'></div>
                        </div>
                      </>
                    )}
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#0073AD',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : dataTable.length === 0 ? (
                      <>
                        <div className='empty'>
                          <p className='empty-title'>Aucun résultat trouvé</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <DataTables
                          columns={columns}
                          data_table={dataTable}
                          selectedValueBy={selectedValueBy}
                          // pending={pending}
                          striped
                          highlightOnHover
                          pagination
                        />
                      </>
                    )}

                    {/* {dataTable.length === 0 ? (
                      <>
                        <div className='empty'>
                          <p className='empty-title'>Aucun résultat trouvé</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <DataTables
                          columns={columns}
                          data_table={dataTable}
                          selectedValueBy={selectedValueBy}
                          // pending={pending}
                          striped
                          highlightOnHover
                          pagination
                        />
                      </>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default Data
