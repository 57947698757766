import * as d3 from 'd3'

// show tooltip when hovering over a region
export const handleMouseOver = function (tooltipData, e) {
  console.log(e.target)
  d3.select('#tooltip')
    .style('opacity', 1)
    .style('background-color', 'green')
    .text(tooltipData)
  // console.log(tooltipData)
  d3.selectAll('.Country').style('opacity', 0.3)
  d3.select(e.target)
    // .transition()
    // .duration(200)
    .style('opacity', 1)
    .style('stroke', 'black')
}

// hide tooltip as mouse leaves region
export const handleMouseOut = function () {
  d3.select('#tooltip').style('opacity', 0)
}

// get mouse location so tooltip tracks cursor
export const handleMouseMove = function (event) {
  d3.select('#tooltip')
    .style('left', event.pageX + 10 + 'px')
    .style('top', event.pageY + 2 + 'px')
}
