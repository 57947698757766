import React from 'react'
import { IconAlertCircle } from '@tabler/icons-react'
const ModalError = ({
  showError,
  setShowError,
  messageError,
  titleMessage,
}) => {
  return (
    <div
      style={showError ? { display: 'block' } : { display: 'none' }}
      className={
        showError ? 'modal modal-blur fade  show ' : 'modal modal-blur fade '
      }
      // className='modal modal-blur fade modal-open show '
      // className='modal display-block '
      id='modal-success'
      tabIndex='-1'
      role={showError ? 'dialog' : ''}
      // role='dialog'
      aria-modal={showError ? 'true' : 'false'}
      // aria-modal='true'
      aria-hidden={showError ? 'false' : 'true'}
      // aria-hidden='false'
    >
      <div
        className='modal-dialog modal-sm modal-dialog-centered'
        role='document'
      >
        <div className='modal-content'>
          <div className='modal-status bg-danger'></div>
          <div className='modal-body text-center py-4'>
            <IconAlertCircle
              size={24}
              className='icon mb-2 text-danger icon-lg'
            />
            {/* Une erreur est survenue lors de l'importation du fichier */}
            <h3>{titleMessage}</h3>
            <div className='text-secondary'>{messageError}</div>
          </div>
          <div className='modal-footer'>
            <div className='w-100'>
              <div className='row'>
                {/* <div className='col'>
                  <a href='#' className='btn w-100' data-bs-dismiss='modal'>
                    Go to dashboard
                  </a>
                </div> */}
                <div className='col'>
                  <a
                    href='#'
                    className='btn btn-danger w-100'
                    data-bs-dismiss='modal'
                    onClick={() => setShowError(false)}
                  >
                    Fermer
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalError
