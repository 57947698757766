import React from 'react'
import Select from 'react-select'
const SelectSubChapter = ({
  subchapters,
  handleChangeSubChapter,
  subChapterSelectRef,
  selectedOptionSubChapter,
}) => {
  return (
    <div className='mb-2'>
      <label className='form-label'>Produit</label>
      <Select
        options={subchapters}
        getOptionLabel={(e) => e.value + '-' + e.label}
        getOptionValue={(e) => e.value}
        isMulti
        value={selectedOptionSubChapter}
        id='subchapter'
        name='subchapter'
        isSearchable={true}
        isClearable={true}
        placeholder='Sélectionner le produit'
        ref={subChapterSelectRef}
        onChange={handleChangeSubChapter}
        required
      />
    </div>
  )
}

export default SelectSubChapter
