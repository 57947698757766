import axios from 'axios'

// import dayjs from 'dayjs'

// const baseURL = 'http://127.0.0.1:8000'
// const baseURL = 'http://192.168.43.61:8000'
// const baseURL = 'http://197.140.141.9'
const baseURL = 'https://cepa.mpeche.gov.dz'

// const authTokens = localStorage.getItem('authTokens')
//   ? JSON.parse(localStorage.getItem('authTokens'))
//   : null

const access_token = localStorage.getItem('tp_ac')
  ? JSON.parse(localStorage.getItem('tp_ac'))
  : null

const axiosInstance = axios.create({
  baseURL,
  timeout: 10000,
  // headers: {
  //   Authorization: `JWT ${authTokens?.access}`,
  //   'Content-Type': 'application/json',
  //   accept: 'application/json',
  // },
  headers: {
    Authorization: `JWT ${access_token}`,
    'Content-Type': 'application/json',
    // accept: 'application/json',
  },
})

// // Function to call api for getting refresh tokens
const GetRefreshToken = (refreshToken) => {
  // console.log('get refresh')
  return axiosInstance
    .post('api/token/refresh/', { refresh: refreshToken })
    .then((response) => {
      // console.log('result get refresh token new token', response.data)

      const token = response.data.access

      const refreshtoken = response.data.refresh

      return [token, refreshtoken]
    })
    .catch((err) => {
      console.log(err)
    })
}

// axiosInstance.interceptors.request.use(async (req) => {
//   // console.log(authTokens)
//   // if (!authTokens) {
//   //   const authTokens = localStorage.getItem('authTokens')
//   //     ? JSON.parse(localStorage.getItem('authTokens'))
//   //     : null
//   //   req.headers.Authorization = `JWT ${authTokens?.access}`

//   //   // const exp = authTokens.exp
//   //   // const isExpired = dayjs.unix(exp).diff(dayjs()) < 1
//   //   // console.log('exp : ', isExpired)
//   // }
//   const exp = authTokens?.exp
//   const isExpired = dayjs.unix(exp).diff(dayjs()) < 1
//   // console.log(isExpired)
//   // console.log(exp)
//   return req
// }) async
let refreshing_token = null
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config
    // console.log('interceptors error', error)
    if (typeof error.response === 'undefined') {
      // alert(
      //   'A server/network error occurred. ' +
      //     'Looks like CORS might be the problem. ' +
      //     'Sorry about this - we will get it fixed shortly.'
      // )
      // console.log('A server/network error occurred')
      return Promise.reject(error)
    }
    if (
      error.response.status === 401 &&
      originalRequest.url === 'api/token/refresh/'
    ) {
      console.log('probleme refresh token')
      // console.log(window.location.href)
      // window.location.href = '/login/'
      // return Promise.reject(error)
      localStorage.removeItem('tp_ac')
      localStorage.removeItem('tp_rf')
      localStorage.removeItem('user_id')
      localStorage.removeItem('user_name')
      // window.location.href = '/login/'
      window.reload()
    }

    if (
      error.response.data.code === 'token_not_valid' &&
      error.response.status === 401 &&
      error.response.statusText === 'Unauthorized' &&
      !originalRequest._retry
    ) {
      const refreshToken = localStorage.getItem('tp_rf')
        ? JSON.parse(localStorage.getItem('tp_rf'))
        : null

      if (refreshToken) {
        originalRequest._retry = true
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]))
        const now = Math.ceil(Date.now() / 1000)
        if (tokenParts.exp > now) {
          // const [token, refreshtoken] = await
          // console.log('new token', token)
          // console.log('new refreshtoken', refreshtoken)
          try {
            refreshing_token = refreshing_token
              ? refreshing_token
              : GetRefreshToken(refreshToken)
            let [token, refreshtoken] = await refreshing_token
            refreshing_token = null
          
            localStorage.setItem('tp_ac', JSON.stringify(token))
            localStorage.setItem('tp_rf', JSON.stringify(refreshtoken))
            axiosInstance.defaults.headers['Authorization'] = 'JWT ' + token
            originalRequest.headers['Authorization'] = 'JWT ' + token
            // if (res.data.access_token) {
            //   access_token = res.data.access_token
            // }
            return axiosInstance(originalRequest)
          } catch (err) {
            return Promise.reject(err)
          }
        } else {
          console.log('Refresh token is expired', tokenParts.exp, now)
          localStorage.clear()
          window.location.reload()
          window.location.href = '/login/'
        }
      } else {
        console.log('Refresh token not available.')
        localStorage.clear()
        window.location.reload()
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error)
  }
)

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   async function (error) {
//     const originalRequest = error.config
//     console.log(originalRequest)

//     if (typeof error.response === 'undefined') {
//       // alert(
//       //   'A server/network error occurred. ' +
//       //     'Looks like CORS might be the problem. ' +
//       //     'Sorry about this - we will get it fixed shortly.'
//       // )
//       // console.log('A server/network error occurred')
//       return Promise.reject(error)
//     }

//     if (
//       error.response.status === 401 &&
//       originalRequest.url === baseURL + 'token/refresh/'
//     ) {
//       // console.log('probleme refresh token')
//       // console.log(window.location.href)
//       // window.location.href = '/login/'
//       // return Promise.reject(error)
//     }

//     if (
//       error.response.data.code === 'token_not_valid' &&
//       error.response.status === 401 &&
//       error.response.statusText === 'Unauthorized'
//     ) {
//       // const refreshToken = JSON.parse(
//       //   localStorage.getItem('authTokens')
//       // ).refresh

//       const refreshToken = localStorage.getItem('tp_rf')
//         ? JSON.parse(localStorage.getItem('tp_rf'))
//         : null

//       if (refreshToken) {
//         const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]))
//         // const exp = JSON.parse(localStorage.getItem('authTokens')).exp
//         // console.log(exp)
//         // exp date in token is expressed in seconds, while now() returns milliseconds:
//         const now = Math.ceil(Date.now() / 1000)
//         // console.log(tokenParts.exp)

//         if (tokenParts.exp > now) {
//           // console.log('Refresh token is not expired', tokenParts.exp, now)
//           const [token, refreshtoken] = GetRefreshToken(refreshToken)
//           localStorage.setItem('tp_ac', JSON.stringify(token))
//           localStorage.setItem('tp_rf', JSON.stringify(refreshtoken))
//           axiosInstance.defaults.headers['Authorization'] = 'JWT ' + token
//           originalRequest.headers['Authorization'] = 'JWT ' + token

//           return axiosInstance(originalRequest)

//           // console.log('get new tocken from refresh token')
//           // return await axiosInstance
//           //   .post('api/token/refresh/', { refresh: refreshToken })
//           //   .then((response) => {
//           //     console.log('result get refresh token new token', response.data)
//           //     localStorage.setItem(
//           //       'tp_ac',
//           //       JSON.stringify(response.data.access)
//           //     )
//           //     localStorage.setItem(
//           //       'tp_rf',
//           //       JSON.stringify(response.data.refresh)
//           //     )

//           //     // localStorage.setItem('authTokens', JSON.stringify(response.data))

//           //     axiosInstance.defaults.headers['Authorization'] =
//           //       'JWT ' + response.data.access
//           //     originalRequest.headers['Authorization'] =
//           //       'JWT ' + response.data.access

//           //     return axiosInstance(originalRequest)
//           //   })
//           //   .catch((err) => {
//           //     console.log(err)
//           //   })
//         } else {
//           console.log('Refresh token is expired', tokenParts.exp, now)
//           localStorage.clear()
//           window.location.reload()
//           window.location.href = '/login/'
//         }
//       } else {
//         console.log('Refresh token not available.')
//         localStorage.clear()
//         window.location.reload()
//       }
//     }

//     // specific error handling done elsewhere
//     return Promise.reject(error)
//   }
// )

export default axiosInstance
