import React, { useContext, useEffect, useState } from 'react'
// import Filter from '../component/tools/Filter'
import Select from 'react-select'
import * as d3 from 'd3'
// import { geoMercator } from 'd3'
import { geoCylindricalEqualArea } from 'd3-geo-projection'
import { countriesData } from '../helpers/CountriesData'
// import { countriesTopoJson } from '../helpers/CountriesTopojson'
import { IconPlus } from '@tabler/icons-react'
// import BarChart from './BarChart'
import CountryList from '../component/maps/CountryList'
// import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import axiosInstance from '../utils/AxiosInstance'
import AuthContext from '../context/AuthContext'
const Map = () => {
  const { user } = useContext(AuthContext)
  const [data, setData] = useState(null)
  const [allcountries, setAllcountriesa] = useState(null)
  const [selectedValueYear, setSelectedValueYear] = useState('2023')
  const [selectedValueTypeTrade, setSelectedValueTypeTrade] = useState('import')
  const [selectedValueDisplay, setSelectedValueDisplay] = useState('value_usd')

  // Set Option Years
  const currentYear = new Date().getFullYear()
  let optionsYears = []
  for (let i = currentYear - 11; i <= currentYear; i++) {
    optionsYears.push({ value: i.toString(), label: i.toString() })
  }
  optionsYears = optionsYears.reverse()

  //  Set Option Type
  const optionsTypeTrade = [
    { value: 'import', label: 'Importation' },
    { value: 'export', label: 'Exportation' },
  ]
  //  Set Option Display
  const optionsValues = [
    { value: 'value_usd', label: 'Valeur USD' },
    { value: 'value_dzd', label: 'Valeur DZD' },
    { value: 'weight', label: 'Poids' },
  ]

  // handle selection Year
  const handleChangeYear = (value) => {
    if (value) {
      setSelectedValueYear(value.value)
    } else {
      setSelectedValueYear(null)
    }
  }

  // handle selection Type Trade
  const handleChangeTapeTrade = (value) => {
    if (value) {
      setSelectedValueTypeTrade(value.value)
    } else {
      setSelectedValueTypeTrade(null)
    }
  }

  // handle selection Display
  const handleChangeDisplay = (value) => {
    console.log(value.value)

    if (value) {
      // if (value.value === 'value_dzd') {
      //   setTitleUnit('Dinar Algérien')
      // } else if (value.value === 'value_usd') {
      //   setTitleUnit('Dollar Américain')
      // } else {
      //   setTitleUnit('Quantité (Kg)')
      // }
      setSelectedValueDisplay(value.value)
    } else {
      setSelectedValueDisplay(null)
    }
  }

  const projection = geoCylindricalEqualArea()
  // .center([0, 10])
  // .scale(120)
  // .translate([500, 250])

  // Handle Add User
  const handleAdduser = (id) => {
    const geo_data = []
    // console.log('allcountries', allcountries)
    // console.log('countriesData', countriesData)
    // console.log(
    //   'countriesTopoJson',
    //   countriesTopoJson[0].objects.countries.geometries
    // )
    // countriesData.map((x) => {
    //   if (
    //     allcountries.filter((country) => country.alpha2 === x.alpha2).length ==
    //     0
    //   ) {
    //     console.log(
    //       x.alpha2,
    //       x.country,
    //       allcountries.filter((country) => country.alpha2 === x.alpha2)
    //     )
    //   }
    // })
    // allcountries.map((x) => {
    //   // console.log(x.alpha2)
    //   if (
    //     countriesData.filter((country) => country.alpha2 === x.alpha2).length ==
    //     0
    //   ) {
    //     console.log(
    //       x.alpha2,
    //       x.name_fr,
    //       countriesData.filter((country) => country.alpha2 === x.alpha2)
    //     )
    //   }
    // })
    // countriesData.map((x) => {
    //   // console.log(x)
    //   // if (
    //   //   countriesTopoJson[0].objects.countries.geometries.filter(
    //   //     (country) => country.properties.name === x.country
    //   //   )[0] !== undefined
    //   // ) {
    //   //   // console.log(
    //   //   //   x.country,
    //   //   //   countriesTopoJson[0].objects.countries.geometries.filter(
    //   //   //     (country) => country.properties.name === x.country
    //   //   //   )[0]
    //   //   // )
    //   //   // console.log(
    //   //   //   x.alpha2,
    //   //   //   countriesTopoJson[0].objects.countries.geometries.filter(
    //   //   //     (country) => country.properties.name === x.country
    //   //   //   )[0].properties.name
    //   //   // )
    //   //   countriesTopoJson[0].objects.countries.geometries.filter(
    //   //     (country) => country.properties.name === x.country
    //   //   )[0].properties.alpha2 = x.alpha2
    //   //   countriesTopoJson[0].objects.countries.geometries.filter(
    //   //     (country) => country.properties.name === x.country
    //   //   )[0].properties.alpha3 = x.alpha3
    //   // }
    //   // if (
    //   //   countriesTopoJson[0].objects.countries.geometries.filter(
    //   //     (country) => country.properties.name === x.country
    //   //   )[0] == undefined
    //   // ) {
    //   //   console.log(
    //   //     x.country,
    //   //     countriesTopoJson[0].objects.countries.geometries.filter(
    //   //       (country) => country.properties.name === x.country
    //   //     )[0]
    //   //   )
    //   // }
    // })

    // console.log('mapdata', data)
    //long lat Algeria
    const geo_algeria = [3, 28]
    // console.log('projection', projection)
    // countriesData.map((x) => {
    //   console.log(x.alpha2)
    // })

    // Prepare data for Arrow lines
    data.map((x) => {
      if (
        countriesData.filter(
          (country) => country.alpha2 === x.country.substring(0, 2)
        )[0] !== undefined
      ) {
        if (selectedValueTypeTrade === 'export') {
          geo_data.push([
            geo_algeria,
            [
              countriesData.filter(
                (country) => country.alpha2 === x.country.substring(0, 2)
              )[0].longitude,
              countriesData.filter(
                (country) => country.alpha2 === x.country.substring(0, 2)
              )[0].latitude,
            ],
          ])
        } else {
          geo_data.push([
            [
              countriesData.filter(
                (country) => country.alpha2 === x.country.substring(0, 2)
              )[0].longitude,
              countriesData.filter(
                (country) => country.alpha2 === x.country.substring(0, 2)
              )[0].latitude,
            ],
            geo_algeria,
          ])
        }

        // console.log(
        //   x.country.substring(0, 2),
        //   countriesData.filter(
        //     (country) => country.alpha2 === x.country.substring(0, 2)
        //   )[0]
        // )
      }

      // console.log(x.country.substring(0, 2))
    })

    // 28.938522148363884, 2.898676771627235
    // const boxHeight = 200

    // Define the circle variables
    // const circleX = 28.9
    // const circleY = boxHeight / 2
    // const radius = 40

    // Define the line variables
    // const lineLength = 200
    // const lineStart = [circleX + radius, circleY]
    // // const lineEnd = [lineStart[0] + lineLength, circleY]

    // Define the arrowhead marker variables
    const markerBoxWidth = 20
    const markerBoxHeight = 20
    const refX = markerBoxWidth / 2
    const refY = markerBoxHeight / 2
    // const markerWidth = markerBoxWidth / 2
    // const markerHeight = markerBoxHeight / 2
    const arrowPoints = [
      [0, 0],
      [0, 20],
      [20, 10],
    ]

    d3.selectAll('line').remove()
    // Add the arrowhead marker definition to the svg element
    d3.select('#map')
      .insert('defs')
      .append('marker')
      .attr('id', 'arrow')
      .attr('viewBox', [0, 0, markerBoxWidth, markerBoxHeight])
      .attr('refX', refX)
      .attr('refY', refY)
      .attr('markerWidth', markerBoxWidth)
      .attr('markerHeight', markerBoxHeight)
      .attr('orient', 'auto-start-reverse')
      .append('path')
      .attr('d', d3.line()(arrowPoints))
      .attr('stroke', 'black')

    // Add the first circle
    // d3.select('#map')
    //   .append('circle')
    //   .attr('cx', circleX)
    //   .attr('cy', circleY)
    //   .attr('r', radius)
    //   .style('fill', 'green')

    // Add the line with arrowhead at the end
    // d3.select('#map')
    //   .insert('path')
    //   .attr(
    //     'd',
    //     d3.line()([
    //       projection([(2.898676771627235, 28.938522148363884)]),
    //       projection([(2.898676771627235, 28.938522148363884)]),
    //     ])
    //   )
    //   .attr('stroke', 'black')
    //   .attr('marker-end', 'url(#arrow)')
    //   .attr('fill', 'none')

    // Add the second circle
    // d3.select('#map')
    //   .append('circle')
    //   .attr('cx', lineEnd[0] + markerWidth + radius)
    //   .attr('cy', circleY)
    //   .attr('r', radius)
    //   .style('fill', 'green')

    d3.select('#map')
      .selectAll('line')
      .data(geo_data)
      .enter()
      .insert('line')
      .attr('x1', (d) => projection(d[0])[0])
      .attr('y1', (d) => projection(d[0])[1])
      .attr('x2', (d) => projection(d[1])[0])
      .attr('y2', (d) => projection(d[1])[1])
      .attr('stroke-width', 1)
      .attr('marker-end', 'url(#arrow)')
      .attr('stroke', 'black')
  }

  useEffect(() => {
    const response = axiosInstance
      .post('api/trade/datamap/', {
        year: selectedValueYear,
        type_trade: selectedValueTypeTrade,
        display_value: selectedValueDisplay,
      })
      .then((res) => {
        setData(res.data)

        // subHideFilterRef.current.click()
      })
      .catch(function (error) {
        console.log(error)
      })

    const response2 = axiosInstance
      .get('api/trade/country/')
      .then((res) => {
        setAllcountriesa(res.data)

        // subHideFilterRef.current.click()
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [selectedValueYear, selectedValueTypeTrade, selectedValueDisplay])
  if (Object.keys(user).length > 0 && user.permissions['map_page']) {
    return (
      <div className='page-wrapper'>
        {/* Page body */}
        <div className='page-body'>
          <div className='container-xl'>
            {/* Filter here */}
            {/* <div className='row row-cards mb-4'>
            <div className='col-12'></div>
          </div> */}
            {/* <Filter /> */}
            <div className='col-12 '>
              <div className='card' style={{ borderColor: '#0073AD' }}>
                <div className='card-header'>
                  {/* year */}
                  <div className='col-sm-2 col-md-2'>
                    <div className='mb-2'>
                      <label className='form-label'>Année</label>
                      <Select
                        defaultValue={[optionsYears[0]]}
                        options={optionsYears}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        id='year'
                        name='year'
                        isSearchable={true}
                        isClearable={true}
                        placeholder='Sélectionner l"année'
                        // ref={subProductSelectRef}
                        onChange={handleChangeYear}
                      />
                    </div>
                  </div>

                  {/* Type */}
                  <div className='col-sm-2 col-md-2'>
                    <div className='mb-2'>
                      <label className='form-label'>Type</label>
                      <Select
                        defaultValue={[optionsTypeTrade[0]]}
                        options={optionsTypeTrade}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        id='type'
                        name='type'
                        isSearchable={true}
                        isClearable={true}
                        placeholder='Sélectionner le type'
                        // ref={subProductSelectRef}
                        onChange={handleChangeTapeTrade}
                      />
                    </div>
                  </div>

                  {/* Display */}
                  <div className='col-sm-4 col-md-4'>
                    <div className='mb-3'>
                      <label className='form-label'>Afficher</label>
                      <Select
                        defaultValue={[optionsValues[0]]}
                        options={optionsValues}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        id='value'
                        name='value'
                        isSearchable={true}
                        isClearable={true}
                        placeholder='Sélectionner la valeur'
                        // ref={subProductSelectRef}
                        onChange={handleChangeDisplay}
                      />
                    </div>
                  </div>

                  <div className='card-actions'>
                    <a
                      role='button'
                      className='btn btn-primary'
                      onClick={() => handleAdduser()}
                    >
                      <IconPlus size={24} className='icon' />
                      Ajouter
                    </a>
                  </div>
                </div>
                <div className='card-body text-center'>
                  <CountryList dataCountries={data} />
                </div>
              </div>

              {/* <svg width={width} height={height}>
            
            </svg> */}
              {/* <MapContainer
                  style={{ height: '500px' }}
                  center={[29.22889, 3.723136]}
                  zoom={5}
                  scrollWheelZoom={false}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  />
                 
                </MapContainer> */}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default Map
