import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import ModalAddProduct from './ModalAddProduct'
import {
  IconDotsVertical,
  IconPlus,
  IconRefresh,
  IconX,
} from '@tabler/icons-react'
import DataTables from '../DataTables'
import axiosInstance from '../../../utils/AxiosInstance'
import ModalSuccess from './ModalSuccess'
import ModalError from './ModalError'
const TabProduct = ({ seletedTab }) => {
  const [loading, setLoading] = useState(false)
  const [searchFilterProduct, setsearchFilterProduct] = useState('')
  const [show, setShow] = useState(false)
  const [showError, setShowError] = useState(false)
  const [message, setMessage] = useState('')
  const [titleMessage, settitleMessage] = useState('')
  const [messageError, setMessageError] = useState('')
  const [itemSelectedProduct, setItemSelectedProduct] = useState(null)
  const [refreshProduct, setRefreshProduct] = useState(true)
  const [titleModalProduct, settitleModalProduct] = useState('')
  const [selectedValueSubChapter, setSelectedValueSubChapter] = useState(null)
  const [subchapters, setSubChapters] = useState([])
  // Product Product
  const [columnsProduct, setColumnsProduct] = useState([
    {
      name: 'Id',
      selector: (row) => row.id,
      width: '120px',
      // sortable: true,
    },
    {
      name: 'Nom',
      selector: (row) => row.name,
      wrap: true,
      // sortable: true,
    },
    {
      name: 'Unité',
      selector: (row) => row.quantity_unit,
      wrap: true,
      width: '80px',
      // sortable: true,
    },
    {
      name: 'Type',
      selector: (row) => row.type_trade,
      wrap: true,
      width: '80px',
      // sortable: true,
    },
    {
      name: 'Code parent',
      selector: (row) => row.parent_id,
      wrap: true,
      width: '120px',
      // sortable: true,
    },
    {
      name: 'Actions',
      button: true,
      cell: (row) => (
        // <button
        //   className='btn btn-outline btn-xs'
        //   onClick={(e) => handleButtonClick(e, row.id)}
        // >
        //   Edit
        // </button>
        <div className='dropdown '>
          <a
            href='#'
            className='btn-action dropdown-toggle '
            data-bs-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <IconDotsVertical size={24} className='icon' />
          </a>
          <div className='dropdown-menu dropdown-menu-end'>
            <a
              className='dropdown-item btn'
              data-bs-toggle='modal'
              data-bs-target='#add-product'
              onClick={(e) => handleUpdateProduct(row.id)}
            >
              <IconRefresh size={24} className='icon' />
              Modifier
            </a>
          </div>
        </div>
      ),
    },
  ])

  // Product Datatable
  const [dataTableProduct, setDataTableProduct] = useState([])
  const [dataTableProductCopy, setDataTableProductCopy] = useState([])

  // Handle Add Product
  const handleAddProduct = (id) => {
    setItemSelectedProduct(null)
    settitleModalProduct('Nouvelle sous position')
  }

  // Handle Update Product
  const handleUpdateProduct = (id) => {
    setItemSelectedProduct(id)
    settitleModalProduct('Modification sous position')
  }

  // Function to call api for getting Product by subchapter
  const GetProduct = async (chapter_id) => {
    // console.log(chapter_id)
    //clear search input
    setsearchFilterProduct('')

    if (chapter_id !== '00') {
      const response = await axiosInstance
        .get('api/trade/getproduct/' + chapter_id + '/')
        .then((res) => {
          const data = res.data
          // console.log('subchapter : ', data)
          setDataTableProduct(data)
          setDataTableProductCopy(data)
        })
        .catch(function (error) {
          console.log(error)
        })
      setLoading(false)
    }
  }

  // Function to get SubChapter
  const getSubChapter = async () => {
    const response = await axiosInstance
      .get('api/trade/subchapter/')
      .then((res) => {
        const data = res.data
        // console.log(data)

        const arr = []

        data.map((x) => {
          return arr.push({ value: x.id, label: x.name })
        })
        setSubChapters(arr)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  // handle selection SubChapter
  const handleChangeSubChapter = (value) => {
    setLoading(true)
    if (value) {
      setSelectedValueSubChapter(value.value)
    } else {
      setSelectedValueSubChapter(null)
    }
  }

  // Function to clear search input
  const clearSearchProduct = () => {
    // console.log('clear search')
    setsearchFilterProduct('')
    setDataTableProduct(dataTableProductCopy)
  }

  // handle search
  const handleSerachProduct = (e) => {
    // console.log(dataTableSubChapteCopy)
    setsearchFilterProduct(e.target.value)
    if (e.target.value === '') {
      // console.log('videe')
      setDataTableProduct(dataTableProductCopy)
    } else {
      let filteredvalue_traited = []
      const filteredvalue = dataTableProductCopy.reduce(
        (accumulator, value) => {
          // console.log('value', value)
          // console.log(Object.keys(value))
          // console.log(Object.keys(value)[position_column - 1])

          // console.log(column_filtred)
          // console.log(value['name'])
          // console.log(
          //   value[column_filtred]
          //     .toLowerCase()
          //     .includes(e.target.value.toLowerCase())
          // )

          if (
            value['name'].toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            accumulator.push(value)
          }

          if (
            value['id'].toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            accumulator.push(value)
          }

          if (
            value['parent_id']
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          ) {
            accumulator.push(value)
          }

          return accumulator
        },
        []
      )
      // console.log(
      //   filteredvalue.filter(
      //     (item, index) => filteredvalue.indexOf(item) === index
      //   )
      // )
      filteredvalue_traited = filteredvalue.filter(
        (item, index) => filteredvalue.indexOf(item) === index
      )

      if (filteredvalue_traited.length > 0) {
        setDataTableProduct(filteredvalue_traited)
      }
      // console.log(filteredvalue)
    }
  }

  useEffect(() => {
    if (seletedTab === 'tabs-product') {
      getSubChapter()
    }
  }, [seletedTab])

  useEffect(() => {
    if (seletedTab === 'tabs-product') {
      GetProduct(selectedValueSubChapter)
    }
  }, [selectedValueSubChapter, refreshProduct, seletedTab])
  return (
    <>
      <div className='row g-2 align-items-center mb-2'>
        {/* SubChapter */}
        <div class='col-sm-4 col-md-4'>
          <Select
            options={subchapters}
            defaultValue={subchapters[0]}
            getOptionLabel={(e) => e.value + '-' + e.label}
            getOptionValue={(e) => e.value}
            id='chapter'
            name='chapter'
            isSearchable={true}
            isClearable={true}
            placeholder='Sélectionner le chapitre'
            onChange={handleChangeSubChapter}
          />
        </div>
        {/* Search */}
        <div class='col-sm-3 col-md-3'>
          <div className='input-group input-group-flat'>
            <input
              type='text'
              placeholder='Recherche'
              className='form-control '
              required
              id='filter'
              name='filter'
              value={searchFilterProduct}
              onChange={(e) => handleSerachProduct(e)}
            />
            <span className='input-group-text'>
              <a
                role='button'
                class='link-secondary'
                title='Clear search'
                data-bs-toggle='tooltip'
                onClick={() => clearSearchProduct()}
              >
                {searchFilterProduct !== '' && (
                  <IconX size={24} className='icon' />
                )}
              </a>
            </span>
          </div>
        </div>
        {/* Refresh and Add */}
        <div className='col-auto ms-auto d-print-none'>
          <div className='btn-list'>
            <button
              className='btn btn-primary'
              onClick={() => setRefreshProduct(!refreshProduct)}
              style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
            >
              <IconRefresh
                size={24}
                className='btn-icon-only'
                style={{ color: '#ffffff' }}
              />
            </button>
            <a
              href=''
              className='btn btn-primary'
              data-bs-toggle='modal'
              data-bs-target='#add-product'
              onClick={() => handleAddProduct()}
              style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
            >
              <IconPlus
                size={24}
                className='icon'
                style={{ color: '#ffffff' }}
              />
              Ajouter
            </a>
          </div>
        </div>
      </div>
      <div className='card' style={{ borderColor: '#0073AD' }}>
        <div className='card-body p-0'>
          {loading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                padding: '1rem',
                texAalign: 'center',
              }}
            >
              <div
                className='spinner-grow  '
                style={{
                  width: '3rem',
                  height: '3rem',
                  color: '#0073AD',
                }}
                role='status'
              >
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : dataTableProduct.length === 0 ? (
            <div className='empty'>
              <p class='empty-title'>Aucun résultat trouvé</p>
            </div>
          ) : (
            <DataTables
              columns={columnsProduct}
              data_table={dataTableProduct}
              // pending={pending}
            />
          )}
          {/* {dataTableProduct.length === 0 ? (
            <div className='empty'>
              <p class='empty-title'>Aucun résultat trouvé</p>
            </div>
          ) : (
            <DataTables
              columns={columnsProduct}
              data_table={dataTableProduct}
              // pending={pending}
            />
          )} */}
        </div>
      </div>
      <ModalAddProduct
        subchapters={subchapters}
        refreshProduct={refreshProduct}
        setRefreshProduct={setRefreshProduct}
        titleModalProduct={titleModalProduct}
        itemSelectedProduct={itemSelectedProduct}
        setItemSelectedProduct={setItemSelectedProduct}
        dataTableProduct={dataTableProduct}
        setShow={setShow}
        setMessage={setMessage}
        setShowError={setShowError}
        setMessageError={setMessageError}
        settitleMessage={settitleMessage}
        // handleClickChapter={handleClickChapter}
        // setHandleClickChapter={setHandleClickChapter}
      />
      <ModalSuccess
        show={show}
        setShow={setShow}
        message={message}
        titleMessage={titleMessage}
      />
      <ModalError
        showError={showError}
        setShowError={setShowError}
        messageError={messageError}
        titleMessage={titleMessage}
      />
    </>
  )
}

export default TabProduct
