import React from 'react'

import { IconAlertTriangle } from '@tabler/icons-react'
const ModalConfirmDelete = ({ show, setShow, itemSelected, confirmdelete }) => {
  const handleConfirmDelete = () => {
    confirmdelete(itemSelected)
  }

  const handleClose = () => {
    setShow(false)
  }

  // useEffect(() => {
  //   Delete()
  // }, [showModalConfirmDelete])

  return (
    <div
      // 'modal modal-blur fade'
      // {`modal ${showModalConfirmDelete ? ' modal-blur' : ''} fade`}

      className='modal modal-blur fade'
      id='modal-danger'
      tabIndex='-1'
      role='dialog'
      aria-hidden='true'
    >
      <div
        className='modal-dialog modal-sm modal-dialog-centered'
        role='document'
      >
        <div className='modal-content'>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='modal'
            aria-label='Close'
          ></button>
          <div className='modal-status bg-danger'></div>
          <div className='modal-body text-center py-4'>
            <IconAlertTriangle
              size={24}
              className='icon mb-2 text-danger icon-lg'
            />
            <h3>êtes-vous sûr ? </h3>
            <div className='text-secondary'>
              Voulez-vous vraiment supprimer cet utilisateur ?
            </div>
          </div>
          <div className='modal-footer'>
            <div className='w-100'>
              <div className='row'>
                <div className='col'>
                  <a
                    href='#'
                    className='btn w-100'
                    data-bs-dismiss='modal'
                    onClick={handleClose}
                  >
                    Annuler
                  </a>
                </div>
                <div className='col'>
                  <a
                    href='#'
                    className='btn btn-danger w-100'
                    data-bs-dismiss='modal'
                    onClick={handleConfirmDelete}
                  >
                    Supprimer
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div
    //   // className='modal show'
    //   // style={{ display: 'block', position: 'initial' }}
    //   className='modal modal-blur fade'
    //   id='modal-danger'
    //   tabIndex='-1'
    //   role='dialog'
    //   aria-hidden='true'
    // >

    // <>
    //   <Modal
    //     show={show}
    //     className='modal modal-sm '
    //     aria-labelledby='contained-modal-title-vcenter'
    //     centered
    //   >
    //     <button
    //       type='button'
    //       className='btn-close'
    //       data-bs-dismiss='modal'
    //       aria-label='Close'
    //       onClick={handleClose}
    //     ></button>
    //     <div className='modal-status bg-danger'></div>
    //     <Modal.Body className=' text-center py-4'>
    //       <svg
    //         xmlns='http://www.w3.org/2000/svg'
    //         className='icon mb-2 text-danger icon-lg'
    //         width='24'
    //         height='24'
    //         viewBox='0 0 24 24'
    //         strokeWidth='2'
    //         stroke='currentColor'
    //         fill='none'
    //         strokeLinecap='round'
    //         strokeLinejoin='round'
    //       >
    //         <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    //         <path d='M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z' />
    //         <path d='M12 9v4' />
    //         <path d='M12 17h.01' />
    //       </svg>
    //       <h3>Are you sure? </h3>
    //       <div className='text-secondary'>
    //         Do you really want to remove 84 files? What you've done cannot be
    //         undone.
    //       </div>
    //     </Modal.Body>
    //     <Modal.Footer>
    //       <div className='w-100'>
    //         <div className='row'>
    //           <div className='col'>
    //             <a
    //               href='#'
    //               className='btn w-100'
    //               data-bs-dismiss='modal'
    //               onClick={handleClose}
    //             >
    //               Annuler
    //             </a>
    //           </div>
    //           <div className='col'>
    //             <a
    //               href='#'
    //               className='btn btn-danger w-100'
    //               data-bs-dismiss='modal'
    //               onClick={handleConfirmDelete}
    //             >
    //               Delete 84 items
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //     </Modal.Footer>
    //   </Modal>
    //   {/* </div> */}
    // </>
  )
}

export default ModalConfirmDelete
