import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import {
  IconDatabase,
  IconHome,
  IconChartInfographic,
  IconMap,
} from '@tabler/icons-react'
import AuthContext from '../../context/AuthContext'
const Header = () => {
  const { user } = useContext(AuthContext)
  return (
    <header className='navbar-expand-md'>
      <div className='collapse navbar-collapse' id='navbar-menu'>
        <div className='navbar' style={{ backgroundColor: '#0073AD' }}>
          <div className='container-xl'>
            <ul className='navbar-nav'>
              {/* Home */}
              {user.first_login === false && (
                <li className='nav-item'>
                  <Link
                    to='home'
                    className='nav-link'
                    style={{ color: '#ffffff', fontWeight: 'bold' }}
                  >
                    <span
                      className='nav-link-icon-custom  d-md-none d-lg-inline-block'
                      style={{ color: '#ffffff' }}
                    >
                      <IconHome size={24} className='icon' />
                    </span>
                    <span className='nav-link-title'>Accueil</span>
                  </Link>
                </li>
              )}

              {/* Data */}
              {user.permissions &&
                user.permissions['data_page'] &&
                user.first_login === false && (
                  <li className='nav-item'>
                    <Link
                      to='data'
                      className='nav-link'
                      style={{ color: '#ffffff', fontWeight: 'bold' }}
                    >
                      <span
                        className='nav-link-icon-custom  d-md-none d-lg-inline-block'
                        style={{ color: '#ffffff' }}
                      >
                        <IconDatabase size={24} className='icon' />
                      </span>
                      <span className='nav-link-title'>Données</span>
                    </Link>
                  </li>
                )}
              {/* Chart */}
              {user.permissions &&
                user.permissions['chart_page'] &&
                user.first_login === false && (
                  <li className='nav-item'>
                    <Link
                      to='chart'
                      className='nav-link'
                      style={{ color: '#ffffff', fontWeight: 'bold' }}
                    >
                      <span
                        className='nav-link-icon-custom  d-md-none d-lg-inline-block'
                        style={{ color: '#ffffff' }}
                      >
                        <IconChartInfographic size={24} className='icon' />
                      </span>
                      <span className='nav-link-title'>Graphique</span>
                    </Link>
                  </li>
                )}
              {/* Map */}
              {user.permissions &&
                user.permissions['map_page'] &&
                user.first_login === false && (
                  <li className='nav-item'>
                    <Link
                      to='map'
                      className='nav-link'
                      style={{ color: '#ffffff', fontWeight: 'bold' }}
                    >
                      <span
                        className='nav-link-icon-custom d-md-none d-lg-inline-block'
                        style={{ color: '#ffffff' }}
                      >
                        <IconMap size={24} className='icon' />
                      </span>
                      <span className='nav-link-title'>Carte</span>
                    </Link>
                  </li>
                )}
              {/* Export */}
              {user.permissions &&
                user.permissions['export_page'] &&
                user.first_login === false && (
                  <li className='nav-item'>
                    <Link
                      to='export'
                      className='nav-link'
                      style={{ color: '#ffffff', fontWeight: 'bold' }}
                    >
                      <span
                        className='nav-link-icon-custom d-md-none d-lg-inline-block'
                        style={{ color: '#ffffff' }}
                      >
                        <IconMap size={24} className='icon' />
                      </span>
                      <span className='nav-link-title'>Export Fichier</span>
                    </Link>
                  </li>
                )}
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
