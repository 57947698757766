import React, { useState } from 'react'
import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import logo from '../assets/images/logo peche_250.png'
import AuthContext from '../context/AuthContext'
import * as Yup from 'yup'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { IconEye } from '@tabler/icons-react'
const Login = () => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [error, setError] = useState(false)
  const [hiddenPassword, setHiddenPassword] = useState(true)
  const { loginUser, userName, user } = useContext(AuthContext)

  const [initialValues, setInitialValues] = useState({
    user_name: '',
    password: '',
  })

  const validationSchema = Yup.object().shape({
    user_name: Yup.string()
      // .min(4, "Nom d'utilisateur doit être au moins 4 caractères")
      // .max(128, "Nom d'utilisateur est trop long !")
      .trim()
      .required("Le nom d'utilisateur est obligatoire"),
    // .test('user_name', "Le Nom d'utilisater exist déja", async (value) => {
    //   try {
    //     if (value != '' && value != null) {
    //       const res = await axiosInstance.post('serie/checkusername', {
    //         user_name: value,
    //       })
    //       return !res.data.username_exist
    //     } else return true
    //   } catch (err) {
    //     console.error(err)
    //   }
    // }),

    password: Yup.string()
      // .min(4, 'Prénom doit être au moins 4 caractères')
      // .max(128, 'Prénom est trop long !')
      .trim()
      .required('Le mot de passe est obligatoire'),

    // permissions: Yup.object().nullable(),
  })
  if (
    userName !== null &&
    Object.keys(user).length > 0 &&
    user.first_login === false
  ) {
    return <Navigate to='/home' />
  }
  // else if (
  //   userName !== null &&
  //   Object.keys(user).length > 0 &&
  //   user.first_login === true
  // ) {
  //   return <Navigate to='/firstlogin' />
  // }
  else {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          // let tst = loginUser(values.user_name, values.password)
          setLoading(true)
          const response = await loginUser(values.user_name, values.password)
          setLoading(false)
          if (response.code === 'ERR_BAD_REQUEST') {
            if (
              response.response.status === 401 &&
              response.response.data.detail ===
                'No active account found with the given credentials'
            ) {
              setError(true)
              setErrorMessage(
                'Aucun compte actif ne correspond aux identifiants fournis.'
              )
              console.log(
                'Aucun compte actif ne correspond aux identifiants fournis.'
              )
            }
          } else if (response.code === 'ERR_NETWORK') {
            setError(true)
            setErrorMessage('Erreur de réseau.')
            console.log('ERR_NETWORK')
          }

          // console.log('token', response) className='d-flex flex-column'
        }}
      >
        {({ values, errors, touched }) => (
          <div className='d-flex flex-column'>
            {/* <div className='container-xl   '>
              <div className='row '>
                <div
                  className='col-sm-6 col-lg-6  align-items-center justify-content-center'
                  style={{ backgroundColor: 'red' }}
                >
                  <div>
                    {' '}
                    <p className='fs-1'>
                      الجمهورية الجزائرية الديمقراطية الشعبية
                    </p>
                  </div>
                  <div>
                    {' '}
                    <p className='fs-1'>
                      وزارة الصيد البحري والمنتجات الصيدية
                    </p>{' '}
                  </div>
                </div>
                <div
                  className='col-sm-12 col-lg-12 justify-content-center'
                  style={{ backgroundColor: 'green' }}
                >
                  <div>
                    {' '}
                    <img src={logo} alt='' width={150} height={150} />
                  </div>
                </div>
              </div>
            </div> */}

            <div
              className='page page-center'
              // style={{ backgroundColor: 'green' }}
            >
              <div className='container container-tight py-4'>
                <div
                  className='d-flex justify-content-center flex-nowrap'
                  // style={{ backgroundColor: 'green' }}
                >
                  <div>
                    {' '}
                    <img src={logo} alt='' width={200} height={200} />
                  </div>
                </div>
              </div>
              <div className='container container-tight py-4'>
                {/* <div className='text-center mb-4'>
            <a href='.' className='navbar-brand navbar-brand-autodark'>
              <img
                src='./static/logo.svg'
                width='110'
                height='32'
                alt='Tabler'
                className='navbar-brand-image'
              />
            </a>
          </div> */}
                <div
                  className='card card-md '
                  style={{ borderColor: '#0073AD' }}
                >
                  <div className='card-body'>
                    <div className='text-center font-bold'>
                      <h1 style={{ fontSize: '2.5rem' }}>CEPA</h1>
                    </div>

                    <h2 className='h2 text-center mb-4'>
                      Connectez-vous à votre compte
                    </h2>
                    <Form>
                      {/* <form
                      // action='./'
                      // method='get'
                      autoComplete='off'
                      noValidate
                      onSubmit={loginUser}
                    > */}
                      <div className='mb-3'>
                        <label className='form-label'>Utilisateur</label>
                        <Field
                          type='text'
                          placeholder='h.mebarki'
                          className={
                            errors.user_name
                              ? 'form-control is-invalid'
                              : 'form-control '
                          }
                          id='user_name'
                          name='user_name'
                        />
                        {errors.user_name && touched.user_name ? (
                          <div className='invalid-feedback'>
                            {errors.user_name}
                          </div>
                        ) : null}
                        {/* <input
                          type='text'
                          className='form-control'
                          placeholder='h.mebarki'
                          autoComplete='off'
                          name='user_name'
                        /> */}
                      </div>
                      <div className='mb-2'>
                        <label className='form-label'>
                          Mot de passe
                          {/* <span className='form-label-description'>
                    <a href='./forgot-password.html'>I forgot password</a>
                  </span> */}
                        </label>
                        <div className='input-group '>
                          <Field
                            type={hiddenPassword ? 'password' : 'text'}
                            className={
                              errors.password
                                ? 'form-control is-invalid'
                                : 'form-control '
                            }
                            id='password'
                            name='password'
                          />
                          <button
                            className='btn'
                            type='button'
                            onClick={() => setHiddenPassword(!hiddenPassword)}
                          >
                            <IconEye size={24} className='btn-icon-only' />
                          </button>
                        </div>
                        <ErrorMessage
                          component='div'
                          name='password'
                          style={{
                            // display: 'none',
                            width: '100%',
                            color: '#d63939',
                            fontSize: '85.714285%',
                            marginTop: '0.25rem',
                          }}
                        />
                        {/* {errors.password && touched.password ? (
                          <div className='invalid-feedback'>
                            {errors.password}
                          </div>
                        ) : null} */}

                        {/* <div className='input-group input-group-flat'>
                           <Field
                            type={true ? 'text' : 'password'}
                            className={
                              errors.password
                                ? 'form-control is-invalid'
                                : 'form-control '
                            }
                            id='password'
                            name='password'
                          /> 

                           <span className='input-group-text'>
                            <a
                              role='button'
                              // className='link-secondary'
                              className='link-secondary'
                              title='Show password'
                              data-bs-toggle='tooltip'
                            >
                              <IconEye size={24} className='btn-icon-only' />
                            </a>
                          </span> 
                        </div> */}
                      </div>
                      <div className='form-footer'>
                        <button
                          type='submit'
                          disabled={loading ? true : false}
                          className='btn btn-primary w-100 mb-2'
                          style={{ backgroundColor: '#0073AD' }}
                        >
                          <div className='pe-2'>S'identifier</div>
                          {loading ? (
                            <div className='spinner-border ' role='status'>
                              <span className='visually-hidden'>
                                Loading...
                              </span>
                            </div>
                          ) : null}
                        </button>

                        {error ? (
                          <div
                            style={{
                              // display: 'none',
                              width: '100%',
                              color: '#d63939',
                              fontSize: '85.714285%',
                              marginTop: '0.25rem',
                            }}
                          >
                            {errorMessage}
                          </div>
                        ) : null}
                      </div>
                    </Form>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
      // <div className='d-flex flex-column'>
      //   <div className='container-xl   '>
      //     <div className='row '>
      //       <div className='col-sm-6 col-lg-6'>
      //         logo
      //         <img src={logo} alt='' />
      //       </div>
      //       <div className='col-sm-6 col-lg-6  align-items-center justify-content-center'>
      //         <div>
      //           {' '}
      //           <p className='fs-1'>الجمهورية الجزائرية الديمقراطية الشعبية</p>
      //         </div>
      //         <div>
      //           {' '}
      //           <p className='fs-1'>
      //             وزارة الصيد البحري والمنتجات الصيدية
      //           </p>{' '}
      //         </div>
      //       </div>
      //     </div>
      //   </div>

      //   <div className='page page-center'>
      //     <div className='container container-tight py-4'>
      //       {/* <div className='text-center mb-4'>
      //       <a href='.' className='navbar-brand navbar-brand-autodark'>
      //         <img
      //           src='./static/logo.svg'
      //           width='110'
      //           height='32'
      //           alt='Tabler'
      //           className='navbar-brand-image'
      //         />
      //       </a>
      //     </div> */}
      //       <div className='card card-md'>
      //         <div className='card-body'>
      //           <h2 className='h2 text-center mb-4'>
      //             Connectez-vous à votre compte
      //           </h2>
      //           <form
      //             // action='./'
      //             // method='get'
      //             autoComplete='off'
      //             noValidate
      //             onSubmit={loginUser}
      //           >
      //             <div className='mb-3'>
      //               <label className='form-label'>Utilisateur</label>
      //               <input
      //                 type='text'
      //                 className='form-control'
      //                 placeholder='h.mebarki'
      //                 autoComplete='off'
      //                 name='user_name'
      //               />
      //             </div>
      //             <div className='mb-2'>
      //               <label className='form-label'>
      //                 Mot de passe
      //                 {/* <span className='form-label-description'>
      //               <a href='./forgot-password.html'>I forgot password</a>
      //             </span> */}
      //               </label>
      //               <div className='input-group input-group-flat'>
      //                 <input
      //                   type='password'
      //                   className='form-control'
      //                   placeholder='Your password'
      //                   autoComplete='off'
      //                   name='password'
      //                 />
      //                 <span className='input-group-text'>
      //                   <a
      //                     href='#'
      //                     className='link-secondary'
      //                     title='Show password'
      //                     data-bs-toggle='tooltip'
      //                   >
      //                     {/* Download SVG icon from http://tabler-icons.io/i/eye  */}
      //                     <svg
      //                       xmlns='http://www.w3.org/2000/svg'
      //                       className='icon'
      //                       width='24'
      //                       height='24'
      //                       viewBox='0 0 24 24'
      //                       strokeWidth='2'
      //                       stroke='currentColor'
      //                       fill='none'
      //                       strokeLinecap='round'
      //                       strokeLinejoin='round'
      //                     >
      //                       <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      //                       <path d='M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
      //                       <path d='M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6' />
      //                     </svg>
      //                   </a>
      //                 </span>
      //               </div>
      //             </div>
      //             {/* <div className='mb-2'>
      //           <label className='form-check'>
      //             <input type='checkbox' className='form-check-input' />
      //             <span className='form-check-label'>
      //               Remember me on this device
      //             </span>
      //           </label>
      //         </div> */}
      //             <div className='form-footer'>
      //               <button type='submit' className='btn btn-primary w-100'>
      //                 S'identifier
      //               </button>
      //             </div>
      //           </form>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    )
  }
}

export default Login
