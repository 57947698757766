import React, { useContext, useEffect, useState } from 'react'

import DataTable from 'react-data-table-component'
import axiosInstance from '../../utils/AxiosInstance'
import ModalAddUser from '../../component/tools/administration/ModalAddUser'
import ModalConfirmDelete from '../../component/tools/administration/ModalConfirmDelete'
import ModalConfirm from '../../component/tools/administration/ModalConfirm'
import {
  IconDotsVertical,
  IconPlus,
  IconRefresh,
  IconTrash,
  IconX,
  IconEdit,
} from '@tabler/icons-react'
import ModalSuccess from '../../component/tools/administration/ModalSuccess'
import ModalError from '../../component/tools/administration/ModalError'
import AuthContext from '../../context/AuthContext'
const Users = () => {
  const [loading, setLoading] = useState(false)
  const { user } = useContext(AuthContext)
  const [searchFilterUser, setsearchFilterUser] = useState('')
  const [titleModalUser, settitleModalUser] = useState('')
  const [show, setShow] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [message, setMessage] = useState('')
  const [titleMessage, settitleMessage] = useState('')
  const [messageError, setMessageError] = useState('')
  // const modalConfirmDeleteRef = useRef()
  const [itemSelected, setItemSelected] = useState(null)
  const [pending, setPending] = useState(true)
  const [handleClick, setHandleClick] = useState(true)
  const [dataTable, setDataTable] = useState([])
  const [dataTableCopy, setDataTableCopy] = useState([])

  const [columns, setColumns] = useState([])
  // const columns = [
  //   {
  //     name: "Nom d'utilisateur",
  //     selector: (row) => row.user_name,
  //     // sortable: true,
  //   },
  //   {
  //     name: 'Nom',
  //     selector: (row) => row.last_name,
  //     // sortable: true,
  //   },
  //   {
  //     name: 'Prénom',
  //     selector: (row) => row.first_name,
  //     // sortable: true,
  //   },
  //   {
  //     name: 'Role',
  //     selector: (row) => row.role,
  //     // sortable: true,
  //     cell: (row) =>
  //       row.role === 'Utilisateur' ? (
  //         <span class='badge bg-green-lt'>{row.role}</span>
  //       ) : (
  //         <span class='badge bg-blue-lt'>{row.role}</span>
  //       ),
  //   },
  //   {
  //     name: 'Actions',
  //     button: true,
  //     cell: (row) => (
  //       // <button
  //       //   className='btn btn-outline btn-xs'
  //       //   onClick={(e) => handleButtonClick(e, row.id)}
  //       // >
  //       //   Edit
  //       // </button>
  //       <div className='dropdown '>
  //         <a
  //           href='#'
  //           className='btn-action dropdown-toggle '
  //           data-bs-toggle='dropdown'
  //           aria-haspopup='true'
  //           aria-expanded='false'
  //         >
  //           <IconDotsVertical size={24} className='icon' />
  //         </a>
  //         <div className='dropdown-menu dropdown-menu-end'>
  //           <a
  //             className='dropdown-item btn'
  //             onClick={(e) => handleShowdetails(e, row.id)}
  //           >
  //             <IconRefresh size={24} className='icon' />
  //             Afficher
  //           </a>
  //           <a
  //             className='dropdown-item btn'
  //             data-bs-toggle='modal'
  //             data-bs-target='#modal-user'
  //             onClick={(e) => handleUpdate(e, row.id)}
  //           >
  //             <IconRefresh size={24} className='icon' />
  //             Modifier
  //           </a>
  //           <a className='dropdown-item btn'>
  //             <IconRefresh
  //               size={24}
  //               className='icon'
  //               onClick={(e) => handleResetPassword(e, row.id)}
  //             />
  //             Réinitialiser
  //           </a>
  //           {/* <a
  //             className='dropdown-item btn'
  //             // className={getCssClass(row.id)}
  //             // className={
  //             //   dataTable.find((x) => x.id === row.id).is_active === true
  //             //     ? 'dropdown-item text-warning btn'
  //             //     : 'dropdown-item text-success btn'
  //             // }
  //             onClick={(e) => handleDisable(e, row.id)}
  //           >
  //             <IconUserOff size={24} className='icon' />
  //             {getCssClass(row.id) === 'dropdown-item text-warning btn'
  //               ? 'Désactiver'
  //               : 'Activer'}
  //           </a> */}
  //           <a
  //             className='dropdown-item text-danger btn'
  //             data-bs-toggle='modal'
  //             data-bs-target='#modal-danger'
  //             onClick={(e) => handleDelete(e, row.id)}
  //           >
  //             <IconTrash size={24} className='icon' />
  //             Supprimer
  //           </a>
  //         </div>
  //       </div>
  //     ),
  //   },
  // ]

  // Handle Add User
  const handleAdduser = (id) => {
    setItemSelected(null)
    settitleModalUser('Nouveau utilisateur')
  }

  // Handle Show Details
  // const handleShowdetails = (e, id) => {
  //   // e.preventDefault()
  //   // console.log('SHow Row Id', id)
  //   // console.log(dataTable)
  //   // console.log(data2)
  // }

  // Handle Update
  const handleUpdate = (e, id) => {
    setItemSelected(id)
   
    settitleModalUser('Modifier utilisateur')
  }

  // const handleClose = () => setShow(false)
  // Handle Delete
  const handleDelete = (e, id) => {
    setShow(true)
    setItemSelected(id)
  }

  // Function Delete
  const Delete = (id) => {
  
    const response = axiosInstance
      .delete('api/base/users/' + id + '/')
      .then((res) => {
        const data = res.data
       
        setHandleClick(!handleClick)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  // Handle Reset Password
  const handleResetPassword = (e, id) => {
    // e.preventDefault()
    setShowConfirm(true)
    setItemSelected(id)
  
  
  }

  // Function Reset Password
  const ResetPaasword = (id) => {
    
    const response = axiosInstance
      .put('api/base/resetpassword/' + id + '/')
      .then((res) => {
        const data = res.data
      
        setShowSuccess(true)
        settitleMessage("L'opération s'est terminée avec succès.")
        setMessage(
          'La réinitialisation du mot de passe a été effectué avec succès.'
        )
        // setHandleClick(!handleClick)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  // Handle Disable User
  // const handleDisable = (e, id) => {
  //   // e.preventDefault()
  //   // console.log('Disable Row Id', id)
  //   // console.log(dataTable)
  //   // console.log(data2)
  //   Disable(id)
  //   // let value = ''
  //   // if (dataTable.find((x) => x.id === id).is_active == false) {
  //   //   value = 'true'
  //   // } else {
  //   //   value = 'false'
  //   // }
  //   // console.log('handleDisabl', value)

  //   // const response = axiosInstance
  //   //   .put('api/base/users/' + id + '/', {
  //   //     is_active: value,
  //   //   })
  //   //   .then((res) => {
  //   //     const data = res.data
  //   //     console.log(data)
  //   //   })
  //   //   .catch(function (error) {
  //   //     console.log(error)
  //   //   })
  //   // setHandleClick(!handleClick)
  // }

  // Function Disable
  // const Disable = (id) => {
  //   // console.log(id)
  //   // console.log(dataTable)
  //   let value = ''
  //   if (dataTable.find((x) => x.id === id).is_active == false) {
  //     value = 'true'
  //   } else {
  //     value = 'false'
  //   }
  //   // console.log('handleDisabl', value)
  //   const response = axiosInstance
  //     .put('api/base/users/' + id + '/', {
  //       is_active: value,
  //     })
  //     .then((res) => {
  //       const data = res.data
  //       console.log(data)
  //     })
  //     .catch(function (error) {
  //       console.log(error)
  //     })
  //   setHandleClick(!handleClick)
  // }

  // Conditional Row Styles row.user_name === 'Admin'
  const conditionalRowStyles = [
    {
      when: (row) =>
        dataTable.find((x) => x.user_name === row.user_name).is_active ===
        false,
      style: {
        backgroundColor: '#e57373',
        // color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    // You can also pass a callback to style for additional customization
    // {
    //   when: (row) => row.email.includes('.com'),
    //   style: (row) => ({
    //     backgroundColor:
    //       row.phone.startsWith('9') || row.phone.startsWith('1')
    //         ? 'pink'
    //         : 'inerit',
    //   }),
    // },
  ]

  // const getCssClass = (id) => {
  //   // console.log('getCssClass : ', dataTable)
  //   if (dataTable.length !== 0) {
  //     if (dataTable.find((x) => x.id === id).is_active === true)
  //       return 'dropdown-item text-warning btn'
  //     else return 'dropdown-item text-success btn'
  //   }

  //   // return 'dropdown-item text-warning btn'
  // }

  // const data = [
  //   {
  //     id: 1,
  //     username: 'hichem',
  //     last_name: 'Mebarki',
  //     first_name: 'Hichem',
  //   },
  //   {
  //     id: 2,
  //     username: 'bilel',
  //     last_name: 'Mebarki',
  //     first_name: 'Bilel',
  //   },
  // ]

  // Function to clear search input
  const clearSearchUser = () => {
 
    setsearchFilterUser('')
    setDataTable(dataTableCopy)
  }

  // handle search User
  const handleSearchUser = (e) => {
    // console.log(dataTableSubChapteCopy)
    setsearchFilterUser(e.target.value)
    if (e.target.value === '') {
     
      setDataTable(dataTableCopy)
    } else {
      let filteredvalue_traited = []
      const filteredvalue = dataTableCopy.reduce((accumulator, value) => {
        

        if (
          value['user_name']
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          accumulator.push(value)
        }
        if (
          value['last_name']
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          accumulator.push(value)
        }
        if (
          value['first_name']
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          accumulator.push(value)
        }

        return accumulator
      }, [])
     
      filteredvalue_traited = filteredvalue.filter(
        (item, index) => filteredvalue.indexOf(item) === index
      )

      if (filteredvalue_traited.length > 0) {
        setDataTable(filteredvalue_traited)
      }
      
    }
  }

  // Function Get DATA Users
  const getData = async () => {
    setPending(true)
    setLoading(true)
    const response = await axiosInstance
      .get('api/base/users/')
      .then((res) => {
        const data = res.data
       
        setDataTable([...data])
        setDataTableCopy([...data])

        // Set array colmuns
        const arr_columns = [
          {
            name: "Nom d'utilisateur",
            selector: (row) => row.user_name,
            // sortable: true,
          },
          {
            name: 'Nom',
            selector: (row) => row.last_name,
            // sortable: true,
          },
          {
            name: 'Prénom',
            selector: (row) => row.first_name,
            // sortable: true,
          },
          {
            name: 'Role',
            selector: (row) => row.role,
            // sortable: true,
            cell: (row) =>
              row.role === 'Utilisateur' ? (
                <span class='badge bg-green-lt'>{row.role}</span>
              ) : (
                <span class='badge bg-blue-lt'>{row.role}</span>
              ),
          },
          {
            name: 'Actions',
            button: true,
            cell: (row) => (
              // <button
              //   className='btn btn-outline btn-xs'
              //   onClick={(e) => handleButtonClick(e, row.id)}
              // >
              //   Edit
              // </button>
              <div className='dropdown '>
                <a
                  href='#'
                  className='btn-action dropdown-toggle '
                  data-bs-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <IconDotsVertical size={24} className='icon' />
                </a>
                <div className='dropdown-menu dropdown-menu-end'>
                  {/* <a
                    className='dropdown-item btn'
                    onClick={(e) => handleShowdetails(e, row.id)}
                  >
                    <IconRefresh size={24} className='icon' />
                    Afficher
                  </a> */}
                  <a
                    className='dropdown-item btn'
                    data-bs-toggle='modal'
                    data-bs-target='#modal-user'
                    onClick={(e) => handleUpdate(e, row.id)}
                  >
                    <IconEdit size={24} className='icon' />
                    Modifier
                  </a>
                  <a
                    className='dropdown-item btn'
                    data-bs-toggle='modal'
                    data-bs-target='#modal-confirm'
                    onClick={(e) => handleResetPassword(e, row.id)}
                  >
                    <IconRefresh size={24} className='icon' />
                    Réinitialiser
                  </a>
                  {/* <a
                    className='dropdown-item btn'
                    // className={getCssClass(row.id)}
                    // className={
                    //   dataTable.find((x) => x.id === row.id).is_active === true
                    //     ? 'dropdown-item text-warning btn'
                    //     : 'dropdown-item text-success btn'
                    // }
                    onClick={(e) => handleDisable(e, row.id)}
                    // onClick={handleDisable(row.id)}
                  >
                    <IconUserOff size={24} className='icon' />
                    {getCssClass(row.id) === 'dropdown-item text-warning btn'
                      ? 'Désactiver'
                      : 'Activer'}
                   
                  </a> */}
                  <a
                    className='dropdown-item text-danger btn'
                    data-bs-toggle='modal'
                    data-bs-target='#modal-danger'
                    onClick={(e) => handleDelete(e, row.id)}
                  >
                    <IconTrash size={24} className='icon' />
                    Supprimer
                  </a>
                </div>
              </div>
            ),
          },
        ]

        setColumns(arr_columns)
        setPending(false)
        setLoading(false)
        // setColumns(arr_columns)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  // UseEffect GET  DATA
  useEffect(() => {
    getData()
  }, [handleClick])

  // Delete user
  // useEffect(() => {
  //   console.log(confirmDelete)
  //   Delete()
  // }, [confirmDelete])
  if (Object.keys(user).length > 0 && user.permissions['user_management']) {
    return (
      <>
        {/* Page header */}
        <div className='page-header d-print-none'>
          <div className='container-xl'>
            <div className='row g-2 align-items-center'>
              <div className='col'>
                {/* Page pre-title */}
                {/* <div className='page-pretitle'>Overview</div> */}
                <h2 className='page-title'>Utilisateurs</h2>
              </div>
            </div>
          </div>
        </div>
        {/* Page body */}
        <div className='page-body'>
          <div className='container-xl'>
            <div className='row row-deck row-cards'>
              <div className='col-12'>
                <div className='card' style={{ borderColor: '#0073AD' }}>
                  <div className='row g-2 align-items-center mb-2 p-2'>
                    {/* Search */}
                    <div class='col-sm-3 col-md-3'>
                      <div className='input-group input-group-flat'>
                        <input
                          type='text'
                          placeholder='Recherche'
                          className='form-control '
                          required
                          id='filter'
                          name='filter'
                          value={searchFilterUser}
                          onChange={(e) => handleSearchUser(e)}
                        />
                        <span className='input-group-text'>
                          <a
                            role='button'
                            class='link-secondary'
                            title='Clear search'
                            data-bs-toggle='tooltip'
                            onClick={() => clearSearchUser()}
                          >
                            {searchFilterUser !== '' && (
                              <IconX size={24} className='icon' />
                            )}
                          </a>
                        </span>
                      </div>
                    </div>
                    {/* Refresh and Add */}
                    <div className='col-auto ms-auto d-print-none'>
                      <div className='btn-list'>
                        <a
                          // href='#'
                          className='btn btn-primary'
                          data-bs-toggle='modal'
                          data-bs-target='#modal-user'
                          onClick={() => handleAdduser()}
                          style={{
                            backgroundColor: '#0073AD',
                            color: '#ffffff',
                          }}
                        >
                          <IconPlus
                            size={24}
                            className='icon'
                            style={{ color: '#ffffff' }}
                          />
                          Ajouter
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <div className='card-actions'>
                  <a
                    // href='#'
                    className='btn btn-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#modal-user'
                    onClick={() => handleAdduser()}
                    style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
                  >
                    <IconPlus
                      size={24}
                      className='icon'
                      style={{ color: '#ffffff' }}
                    />
                    Ajouter
                  </a>
                </div> */}
                  {/* <div className='card-header'>
                 
                  <div className='card-actions'>
                    <a
                      // href='#'
                      className='btn btn-primary'
                      data-bs-toggle='modal'
                      data-bs-target='#modal-user'
                      onClick={() => handleAdduser()}
                      style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
                    >
                      <IconPlus
                        size={24}
                        className='icon'
                        style={{ color: '#ffffff' }}
                      />
                      Ajouter
                    </a>
                  </div>
                </div> */}
                  <div className='card-body p-0'>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#d63939',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : dataTable.length !== 0 ? (
                      <DataTable
                        columns={columns}
                        data={dataTable}
                        fixedHeader
                        pagination
                        striped
                        highlightOnHover
                        conditionalRowStyles={conditionalRowStyles}
                        progressPending={pending}
                        // customTheme={darkTheme}
                      />
                    ) : (
                      <div className='empty'>
                        <p class='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    )}
                    {/* {dataTable.length !== 0 ? (
                      <DataTable
                        columns={columns}
                        data={dataTable}
                        fixedHeader
                        pagination
                        striped
                        highlightOnHover
                        conditionalRowStyles={conditionalRowStyles}
                        progressPending={pending}
                        // customTheme={darkTheme}
                      />
                    ) : (
                      <div className='empty'>
                        <p class='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalAddUser
          handleClick={handleClick}
          setHandleClick={setHandleClick}
          titleModalUser={titleModalUser}
          itemSelected={itemSelected}
          setItemSelected={setItemSelected}
          dataTable={dataTable}
          setShowSuccess={setShowSuccess}
          setMessage={setMessage}
          settitleMessage={settitleMessage}
          setMessageError={setMessageError}
          setShowError={setShowError}
        />

        <ModalSuccess
          show={showSuccess}
          setShow={setShowSuccess}
          message={message}
          titleMessage={titleMessage}
        />
        <ModalError
          showError={showError}
          setShowError={setShowError}
          messageError={messageError}
          titleMessage={titleMessage}
        />
        <ModalConfirmDelete
          // handleDelete={handleDelete}
          // refdelete={modalConfirmDeleteRef}
          confirmdelete={Delete}
          show={show}
          setShow={setShow}
          itemSelected={itemSelected}
          // handleClose={handleClose}
        />
        <ModalConfirm
          confirm={ResetPaasword}
          showConfirm={showConfirm}
          setShowConfirm={setShowConfirm}
          itemSelected={itemSelected}
        />
      </>
    )
  } else {
    return null
  }
}

export default Users
