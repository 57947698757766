import { useEffect, useState } from 'react'
// import { json } from 'd3'
import * as d3 from 'd3'
import { feature } from 'topojson-client'
import { countriesTopoJson } from './CountriesTopojson'

export const MapTools = () => {
  const [mapData, setMapData] = useState({})
  // console.log('data json', data)
  // const jsonUrl =
  //   'https://cdn.jsdelivr.net/npm/world-atlas@2/countries-50m.json'
  const test_topo = countriesTopoJson[0]
  // only fetch map data once and create a tooltip
  useEffect(() => {
    // json(test_topo)
    //   .then((topo) => {
    //     const { countries } = topo.objects
    //     // console.log(topo)
    //     setMapData(feature(topo, countries))
    //   })
    //   .catch((err) => {
    //     console.log('error occurred with loading map', err)
    //   })

    const { countries } = test_topo.objects
    setMapData(feature(test_topo, countries))
    // console.log(feature(test_topo, countries))

    // let ts = feature(jsonUrl, 'foo')
    // console.log(feature)

    /// tooltip creation
    d3.select('#map')
      .insert('div')
      .attr('id', 'tooltip')
      .attr('style', 'position: relative; opacity: 0')

    ///
  }, [])
  return { mapData }
}
