import React from 'react'
// import Select from 'react-select'
import { IconPlus } from '@tabler/icons-react'
// import DataTables from '../DataTables'
import SelectContinent from './SelectContinent'
// import axiosInstance from '../../../utils/AxiosInstance'
const TabContinent = ({ continents }) => {
  return (
    <>
      <div className='row g-2 align-items-center mb-2'>
        <div class='col-sm-4 col-md-4'>
          {/* <div class='page-pretitle'>Overview</div>
                          <h2 class='page-title'>Horizontal layout</h2> */}
          {/* <Select
            options={continents}
            // defaultValue={subchapters[0]}
            getOptionLabel={(e) => e.value + '-' + e.label}
            getOptionValue={(e) => e.value}
            id='continent_id'
            name='continent_id'
            isSearchable={true}
            isClearable={true}
            placeholder='Sélectionner le continent'
            // onChange={handleChangeSubChapter}
          /> */}
          <SelectContinent
            continents={continents}
            // handleChangeChapter={handleChangeChapter}
            // setChapters={setChapters}
          />
        </div>
        <div className='col-auto ms-auto d-print-none'>
          <div className='btn-list'>
            <a
              // href='#'
              role='button'
              className='btn btn-primary'
              // data-bs-toggle='modal'
              // data-bs-target='#add-product'
              // onClick={() => handleAddProduct()}
              style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
            >
              <IconPlus
                size={24}
                className='icon'
                style={{ color: '#ffffff' }}
              />
              Ajouter continent
            </a>
          </div>
        </div>
      </div>
      <div className='card' style={{ borderColor: '#0073AD' }}>
        <div className='card-body p-0'>
          {/* <DataTables
            columns={columnsProduct}
            data_table={dataTableProduct}
            // pending={pending}
          /> */}
        </div>
      </div>
      {/* <ModalAddProduct
        subchapters={subchapters}
        refreshProduct={refreshProduct}
        setRefreshProduct={setRefreshProduct}
        titleModalProduct={titleModalProduct}
        itemSelectedProduct={itemSelectedProduct}
        dataTableProduct={dataTableProduct}
        // handleClickChapter={handleClickChapter}
        // setHandleClickChapter={setHandleClickChapter}
      /> */}
    </>
  )
}

export default TabContinent
