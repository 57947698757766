import React from 'react'
import ReactDOM from 'react-dom/client'
// import { Helmet } from 'react-helmet'
import { HashRouter as Router } from 'react-router-dom'
import App from './App'
// import reportWebVitals from './reportWebVitals'
// // Bootstrap CSS
// import 'bootstrap/dist/css/bootstrap.min.css'
// // Bootstrap Bundle JS
// import 'bootstrap/dist/js/bootstrap.bundle.min'
//import css files template
import './assets/css/inter.css'
import './assets/css/demo.min.css?1685973381'
import './assets/css/tabler-vendors.min.css?1685973381'
import './assets/css/tabler.min.css?1685973381'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Router>
    <App />
  </Router>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
