import React, { useState } from 'react'
import {
  handleMouseOver,
  handleMouseOut,
  handleMouseMove,
} from '../../helpers/handleTooltip'
import * as d3 from 'd3'
const Country = (props) => {
  const { path, tooltipData, fill_color } = props
  const [isHovering, setIsHovering] = useState(false)
  // console.log('path', path)

  const handleMouseEnter = () => {
    setIsHovering(true)
  }

  const handleMouseLeave = (e) => {
    setIsHovering(false)
    d3.selectAll('.Country').style('opacity', 0.8)
    d3.select(e.target)
      // .transition()
      // .duration(200)
      .style('stroke', 'transparent')
  }
  return (
    <path
      className='Country'
      style={{
        // '#fff'
        // fill: isHovering ? 'red' : fill_color,
        fill: fill_color,
        opacity: '0.8',
        stroke: 'transparent',
        strokeWidth: '1px',
      }}
      d={path}
      onMouseOver={(e) => {
        handleMouseOver(tooltipData, e)
      }}
      onMouseOut={handleMouseOut}
      onMouseMove={(event) => {
        handleMouseMove(event)
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={(e) => handleMouseLeave(e)}
    />
  )
}

export default Country
