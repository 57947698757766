import React, { useEffect, useRef, useState } from 'react'
import axiosInstance from '../../../utils/AxiosInstance'
import { Formik, Field, Form, ErrorMessage } from 'formik'

import * as Yup from 'yup'
const ModalAddUser = ({
  handleClick,
  setHandleClick,
  titleModalUser,
  itemSelected,
  setItemSelected,
  dataTable,
  setShowSuccess,
  setMessage,
  settitleMessage,
  setMessageError,
  setShowError,
}) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [initialValues, setInitialValues] = useState({
    user_name: '',
    first_name: '',
    last_name: '',
    role: '',
    is_active: '',
    // permissions: {
    //   data_page: false,
    //   chart_page: false,
    //   map_page: false,
    //   user_management: false,
    //   product_management: false,
    //   region_management: false,
    // },
    permissions: [],
  })

  // const [roleSelected, setRoleSelected] = useState('')
  const modalRef = useRef()

  // handle change Role
  // const handleChangeRole = (e) => {
  //   setRole(e.target.value)
  // }

  // handle Click Sublit form
  // const handleClickSubmit = async (e) => {
  //   e.preventDefault()
  //   console.log(modalRef)

  //   console.log(userName)
  //   console.log(firstName)
  //   console.log(lastName)
  //   console.log(role)

  //   if (itemSelected == null) {
  //     const response = await axiosInstance
  //       .post('api/base/users/', {
  //         user_name: e.target.user_name.value,
  //         first_name: e.target.first_name.value,
  //         last_name: e.target.last_name.value,
  //         role: e.target.role.value,
  //       })
  //       .then((res) => {
  //         const data = res.data
  //         console.log('add user : ', data)
  //         modalRef.current.click()
  //         setUserName('')
  //         setFirstName('')
  //         setLastName('')
  //         setRole('')
  //         setHandleClick(!handleClick)
  //       })
  //       .catch(function (error) {
  //         console.log(error)
  //       })
  //     console.log('add user')
  //   } else {
  //     const response = await axiosInstance
  //       .put('api/base/users/' + itemSelected + '/', {
  //         user_name: e.target.user_name.value,
  //         first_name: e.target.first_name.value,
  //         last_name: e.target.last_name.value,
  //         role: e.target.role.value,
  //       })
  //       .then((res) => {
  //         const data = res.data
  //         console.log('update user : ', data)
  //         modalRef.current.click()
  //         setUserName('')
  //         setFirstName('')
  //         setLastName('')
  //         setRole('')
  //         setHandleClick(!handleClick)
  //       })
  //       .catch(function (error) {
  //         console.log(error)
  //       })
  //     console.log('update user')
  //   }

  //   // console.log(dataTable)
  //   // console.log(data2)
  // }

  const validationSchema = Yup.object().shape({
    user_name: Yup.string()
      .min(4, "Nom d'utilisateur doit être au moins 4 caractères")
      .max(128, "Nom d'utilisateur est trop long !")
      .trim()
      .required("Nom d'utilisateur est obligatoire"),
    // .test('user_name', "Le Nom d'utilisater exist déja", async (value) => {
    //   try {
    //     if (value != '' && value != null) {
    //       const res = await axiosInstance.post('serie/checkusername', {
    //         user_name: value,
    //       })
    //       return !res.data.username_exist
    //     } else return true
    //   } catch (err) {
    //     console.error(err)
    //   }
    // }),

    first_name: Yup.string()
      .min(4, 'Prénom doit être au moins 4 caractères')
      .max(128, 'Prénom est trop long !')
      .trim()
      .required('Prénom est obligatoire'),

    last_name: Yup.string()
      .min(4, 'Nom doit être au moins 4 caractères')
      .max(128, 'Nom est trop long !')
      .trim()
      .required('Nom est obligatoire'),

    role: Yup.string().required('Role est obligatoire'),
    is_active: Yup.string().required('Compte est obligatoire'),

    // permissions: Yup.object().nullable(),
  })

  // const formik = useFormik({
  //   initialValues: initialValues,
  //   validationSchema: validationSchema,
  //   validateOnChange: false,
  //   validateOnBlur: false,
  //   onSubmit: (values) => {
  //     console.log(values)
  //     // let newValues = validationSchema.cast(values)

  //     // axiosInstance
  //     //   .post('serie/user/0', newValues)
  //     //   .then((res) => {
  //     //     enqueueSnackbar('Utilisateur créer avec succès', {
  //     //       variant: 'success',
  //     //     })
  //     //     props.Load()
  //     //   })
  //     //   .catch((err) => {
  //     //     enqueueSnackbar("Une erreur c'est produit . . .", {
  //     //       variant: 'error',
  //     //     })
  //     //   })
  //     //   .finally(props.close(false))
  //   },
  // })

  useEffect(() => {
   
    setInitialValues({
      ...initialValues,
      user_name: '',
      first_name: '',
      last_name: '',
      role: '',
      is_active: '',
      permissions: [],
    })
   

    if (itemSelected != null) {
      if (dataTable.length !== 0) {
        let permissions_arr_2 = []
        if (dataTable.find((x) => x.id === itemSelected).permissions) {
          Object.keys(
            dataTable.find((x) => x.id === itemSelected).permissions
          ).map(function (key) {
            if (
              dataTable.find((x) => x.id === itemSelected).permissions[key] ===
              true
            ) {
              permissions_arr_2.push(key)
              // console.log(key)
            }
          })
         
        }
       

        setInitialValues({
          ...initialValues,
          user_name: dataTable.find((x) => x.id === itemSelected).user_name,
          last_name: dataTable.find((x) => x.id === itemSelected).last_name,
          first_name: dataTable.find((x) => x.id === itemSelected).first_name,
          role: dataTable.find((x) => x.id === itemSelected).role,
          is_active:
            dataTable.find((x) => x.id === itemSelected).is_active === true
              ? 'enable'
              : 'disable',
          permissions: permissions_arr_2,
        })

        // setFirstName(dataTable.find((x) => x.id === itemSelected).first_name)
        // setLastName(dataTable.find((x) => x.id === itemSelected).last_name)
        // setRole(dataTable.find((x) => x.id === itemSelected).role)
      }
    }
  }, [itemSelected, handleClick])

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        setLoadingSubmit(true)
       

        let prmissions_frontend = []
        prmissions_frontend.push(
          values.role.slice(0, 1).toLowerCase() +
            values.role.slice(-1).toLowerCase()
        )
        let permissions_server = {
          data_page: false,
          chart_page: false,
          map_page: false,
          export_page: false,
          user_management: false,
          product_management: false,
          region_management: false,
          data_management: false,
        }
        for (const key in permissions_server) {
          
          if (values.permissions.includes(key)) {
            permissions_server[key] = true
            prmissions_frontend.push(
              key.slice(0, 1) +
                key.slice(key.indexOf('_') + 1, key.indexOf('_') + 2)
            )
       
          }
        }

        // Update initialValues permissions with permissions_server
        values.permissions = permissions_server
        values.is_active = values.is_active === 'enable' ? 'true' : 'false'
        // resetForm()
     
        if (itemSelected == null) {
          const response = await axiosInstance
            .post('api/base/users/', values)
            .then((res) => {
              const data = res.data
              
              settitleMessage("L'opération s'est terminée avec succès.")
              setMessage(data.message)

              setInitialValues({
                ...initialValues,
                user_name: '',
                first_name: '',
                last_name: '',
                role: '',
                is_active: '',
                permissions: [],
              })
              resetForm()
              modalRef.current.click()

              setHandleClick(!handleClick)
              setItemSelected(null)
              setLoadingSubmit(false)
              setShowSuccess(true)
            })
            .catch(function (error) {
              console.log(error)
              settitleMessage("L'opération a échoué.")
              setMessageError(error.response.data.message)
              setInitialValues({
                ...initialValues,
                user_name: '',
                first_name: '',
                last_name: '',
                role: '',
                is_active: '',
                permissions: [],
              })
              resetForm()
              modalRef.current.click()

              setHandleClick(!handleClick)
              setItemSelected(null)
              setLoadingSubmit(false)
              setShowError(true)
            })
        } else {
          const response = await axiosInstance
            .put('api/base/users/' + itemSelected + '/', values)
            .then((res) => {
              const data = res.data
             
              settitleMessage("L'opération s'est terminée avec succès.")
              setMessage(data.message)
              setInitialValues({
                ...initialValues,
                user_name: '',
                first_name: '',
                last_name: '',
                role: '',
                is_active: '',
                permissions: [],
              })
              resetForm()
              modalRef.current.click()

              setHandleClick(!handleClick)
              setItemSelected(null)
              setLoadingSubmit(false)
              setShowSuccess(true)
            })
            .catch(function (error) {
              console.log(error)
              settitleMessage("L'opération a échoué.")
              setMessageError(error.response.data.message)
              setInitialValues({
                ...initialValues,
                user_name: '',
                first_name: '',
                last_name: '',
                role: '',
                is_active: '',
                permissions: [],
              })
              resetForm()
              modalRef.current.click()

              setHandleClick(!handleClick)
              setItemSelected(null)
              setLoadingSubmit(false)
              setShowError(true)
            })
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleReset,
        handleChange,
        setfieldvalue,
      }) => (
        <div
          className='modal modal-blur fade'
          id='modal-user'
          tabIndex='-1'
          role='dialog'
          aria-hidden='true'
        >
          <div
            className='modal-dialog modal-lg modal-dialog-centered'
            role='document'
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>{titleModalUser}</h5>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={handleReset}
                ></button>
              </div>
              <Form>
                {/* <form
                // action='./'
                // method='get'
                autoComplete='off'
                noValidate
                // onSubmit={handleClickSubmit}
                onSubmit={formik.handleSubmit}
              > */}
                <div className='modal-body'>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='mb-3'>
                        <label className='form-label'>Nom </label>
                        <Field
                          type='text'
                          className={
                            errors.last_name
                              ? 'form-control is-invalid'
                              : 'form-control '
                          }
                          id='last_name'
                          name='last_name'
                        />
                        {errors.last_name && touched.last_name ? (
                          <div className='invalid-feedback'>
                            {errors.last_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='mb-3'>
                        <label className='form-label'>Prénom</label>
                        <Field
                          type='text'
                          className={
                            errors.first_name
                              ? 'form-control is-invalid'
                              : 'form-control '
                          }
                          id='first_name'
                          name='first_name'
                        />
                        {errors.first_name && touched.first_name ? (
                          <div className='invalid-feedback'>
                            {errors.first_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div>
                        <label className='form-label'>Nom d'utilisateur</label>
                        <Field
                          type='text'
                          className={
                            errors.user_name
                              ? 'form-control is-invalid'
                              : 'form-control '
                          }
                          id='user_name'
                          name='user_name'
                        />
                        {errors.user_name && touched.user_name ? (
                          <div className='invalid-feedback'>
                            {errors.user_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div>
                        <div className='form-label'>Role</div>
                        <div>
                          <label className='form-check form-check-inline'>
                            <Field
                              className='form-check-input'
                              type='radio'
                              name='role'
                              value='Admin'
                              // onClick={(e) => {
                              //   setRoleSelected('Admin')
                              //   // if (itemSelected != null) {
                              //   //   console.log(e.target)
                              //   //   values.permissions = []
                              //   // }
                              // }}
                            />

                            <span className='form-check-label'>Admin</span>
                          </label>
                          <label className='form-check form-check-inline'>
                            <Field
                              className='form-check-input'
                              type='radio'
                              name='role'
                              value='Utilisateur'
                              // onClick={(e) => {
                              //   setRoleSelected('Utilisateur')
                              //   if (itemSelected != null) {
                              //     console.log(e.target)
                              //     values.permissions = []
                              //   }
                              // }}
                            />

                            <span className='form-check-label'>
                              Utilisateur
                            </span>
                          </label>
                        </div>
                      </div>
                      <ErrorMessage
                        component='div'
                        name='role'
                        style={{
                          // display: 'none',
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='modal-body'>
                  <div className='mb-3'>
                    <div className='form-label'>Compte</div>
                    <div>
                      <label className='form-check form-check-inline'>
                        <Field
                          type='radio'
                          className='form-check-input'
                          name='is_active'
                          value='enable'
                        />
                        {/* <input
                          className='form-check-input'
                          id='permissions.data_page'
                          name='permissions.data_page'
                          type='checkbox'
                          checked={values.permissions.data_page}
                          onChange={(e) => {
                            // values.permissions.data_page =
                            //   !values.permissions.data_page
                            Formik.setFieldValue(
                              'permissions.data_page',
                              !formik.values.permissions.data_page
                            )
                          }}
                        /> */}
                        <span className='form-check-label'>Activer</span>
                      </label>
                      <label className='form-check form-check-inline'>
                        <Field
                          type='radio'
                          className='form-check-input'
                          name='is_active'
                          value='disable'
                        />

                        <span className='form-check-label'>Désactiver</span>
                      </label>
                    </div>
                    <ErrorMessage
                      component='div'
                      name='is_active'
                      style={{
                        // display: 'none',
                        width: '100%',
                        color: '#d63939',
                        fontSize: '85.714285%',
                        marginTop: '0.25rem',
                      }}
                    />
                  </div>
                  <div className='mb-3'>
                    <div className='form-label'>Page</div>
                    <div>
                      <label className='form-check form-check-inline'>
                        <Field
                          type='checkbox'
                          className='form-check-input'
                          id='permissions'
                          name='permissions'
                          value='data_page'
                        />
                        {/* <input
                          className='form-check-input'
                          id='permissions.data_page'
                          name='permissions.data_page'
                          type='checkbox'
                          checked={values.permissions.data_page}
                          onChange={(e) => {
                            // values.permissions.data_page =
                            //   !values.permissions.data_page
                            Formik.setFieldValue(
                              'permissions.data_page',
                              !formik.values.permissions.data_page
                            )
                          }}
                        /> */}
                        <span className='form-check-label'>Données</span>
                      </label>
                      <label className='form-check form-check-inline'>
                        <Field
                          type='checkbox'
                          className='form-check-input'
                          id='permissions'
                          name='permissions'
                          value='chart_page'
                        />
                        {/* <input
                          className='form-check-input'
                          id='permissions.chart_page'
                          name='permissions.chart_page'
                          type='checkbox'
                          checked={formik.values.permissions.chart_page}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'permissions.chart_page',
                              !formik.values.permissions.chart_page
                            )
                          }}
                        /> */}
                        <span className='form-check-label'>Charts</span>
                      </label>
                      <label className='form-check form-check-inline'>
                        <Field
                          type='checkbox'
                          className='form-check-input'
                          id='permissions'
                          name='permissions'
                          value='map_page'
                        />
                        {/* <input
                          className='form-check-input'
                          id='permissions.map_page'
                          name='permissions.map_page'
                          type='checkbox'
                          checked={formik.values.permissions.map_page}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'permissions.map_page',
                              !formik.values.permissions.map_page
                            )
                          }}
                        /> */}
                        <span className='form-check-label'>Map</span>
                      </label>
                       <label className='form-check form-check-inline'>
                        <Field
                          type='checkbox'
                          className='form-check-input'
                          id='permissions'
                          name='permissions'
                          value='export_page'
                        />

                        <span className='form-check-label'>Export fichier</span>
                      </label>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <div className='form-label'>Permissions administration</div>
                    <div>
                      <label className='form-check form-check-inline'>
                        <Field
                          // disabled={values.role === 'Admin' ? false : true}
                          type='checkbox'
                          className='form-check-input'
                          name='permissions'
                          value='user_management'
                        />
                        {/* <input
                          className='form-check-input'
                          id='permissions.user_management'
                          name='permissions.user_management'
                          type='checkbox'
                          checked={formik.values.permissions.user_management}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'permissions.user_management',
                              !formik.values.permissions.user_management
                            )
                          }}
                        /> */}
                        <span className='form-check-label'>Utilisateurs</span>
                      </label>
                      <label className='form-check form-check-inline'>
                        <Field
                          // disabled={values.role === 'Admin' ? false : true}
                          type='checkbox'
                          className='form-check-input'
                          name='permissions'
                          value='product_management'
                        />
                        {/* <input
                          className='form-check-input'
                          id='permissions.produit_management'
                          name='permissions.produit_management'
                          type='checkbox'
                          checked={formik.values.permissions.produit_management}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'permissions.produit_management',
                              !formik.values.permissions.produit_management
                            )
                          }}
                        /> */}
                        <span className='form-check-label'>Produits</span>
                      </label>
                      <label className='form-check form-check-inline'>
                        <Field
                          // disabled={values.role === 'Admin' ? false : true}
                          type='checkbox'
                          className='form-check-input'
                          name='permissions'
                          value='region_management'
                          // checked={values.role !== 'Admin' && false}
                          // onChange={(e) => {
                          //   // values.permissions['region_management'] =
                          //   //   !e.target.checked
                          //   console.log(e.target)
                          //   console.log(values)
                          // }}
                        />
                        {/* <input
                          disabled={
                            roleSelected === 'Admin' || roleSelected === ''
                              ? false
                              : true
                          }
                          // checked={values.role !== 'Admin' && false}
                          className='form-check-input'
                          name='permissions.region_management'
                          type='checkbox'
                          // checked={values.permissions.region_management}
                          onChange={(e) => {
                            console.log(e.target.checked)

                            if (e.target.checked == true) {
                              values.permissions.push('region_management')
                            } else {
                              values.permissions = values.permissions.filter(
                                (x) => {
                                  return x !== 'region_management'
                                }
                              )
                            }
                            console.log(
                              'checkbox_region_management',
                              values.permissions
                            )
                            // setFieldValue(
                            //   'permissions.region_management',
                            //   !formik.values.permissions.region_management
                            // )
                          }}
                        /> */}
                        <span className='form-check-label'>Régions</span>
                      </label>
                      <label className='form-check form-check-inline'>
                        <Field
                          // disabled={values.role === 'Admin' ? false : true}
                          type='checkbox'
                          className='form-check-input'
                          name='permissions'
                          value='data_management'
                        />
                        {/* <input
                          className='form-check-input'
                          id='permissions.produit_management'
                          name='permissions.produit_management'
                          type='checkbox'
                          checked={formik.values.permissions.produit_management}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'permissions.produit_management',
                              !formik.values.permissions.produit_management
                            )
                          }}
                        /> */}
                        <span className='form-check-label'>Données</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className='modal-footer'>
                  <a
                    href='#'
                    className='btn btn-link link-secondary'
                    data-bs-dismiss='modal'
                    onClick={handleReset}
                    ref={modalRef}
                  >
                    Annuler
                  </a>
                  {/* <a
                href='#'
                className='btn btn-primary ms-auto'
                // data-bs-dismiss='modal'
                // onClick={handleClick}
              >
                
                Enregister
              </a> */}
                  <button
                    type='submit'
                    className='btn btn-primary ms-auto'
                    style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
                     disabled={loadingSubmit ? true : false}
                  >
                    <div className='pe-2'>Enregister</div>
                    {loadingSubmit ? (
                      <div className='spinner-border ' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    ) : null}
                  </button>
                </div>
                {/* </form> */}
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default ModalAddUser
