import React from 'react'
import DataTable from 'react-data-table-component'

const DataTables = ({ columns, data_table, pending, selectedValueBy }) => {
  const tableCustomStyles = {
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        // backgroundColor: '#51c88c',
        fontSize: '1rem',
        fontWeight: '600',
      },
    },
  }
  /* Theme Setting */
  // const darkTheme = {
  //   // title: {
  //   //   fontSize: '22px',
  //   //   fontColor: '#FFFFFF',
  //   //   backgroundColor: '#363640',
  //   // },
  //   // contextMenu: {
  //   //   backgroundColor: '#E91E63',
  //   //   fontColor: '#FFFFFF',
  //   // },
  //   // header: {
  //   //   fontSize: '12px',
  //   //   fontColor: '#FFFFFF',
  //   //   backgroundColor: '#363640',
  //   // },
  //   rows: {
  //     fontColor: '#E91E63',
  //     backgroundColor: '#E91E63',
  //     borderColor: 'rgba(255, 255, 255, .12)',
  //     hoverFontColor: 'black',
  //     hoverBackgroundColor: 'rgba(0, 0, 0, .24)',
  //   },
  //   // cells: {
  //   //   cellPadding: '10px',
  //   // },
  // }
  const conditionalRowStyles = [
    {
      when: (row) => row[selectedValueBy] === 'Total',
      style: {
        backgroundColor: '#94cbf7',
        fontSize: '0.9rem',
        fontWeight: '600',
        // color: 'white',
        // '&:hover': {
        //   cursor: 'pointer',
        // },
      },
    },
    {
      when: (row) => row[selectedValueBy] !== 'Total',
      style: {
        //backgroundColor: '#94cbf7',
        fontSize: '0.9rem',
        fontWeight: '550',
        // color: 'white',
        // '&:hover': {
        //   cursor: 'pointer',
        // },
      },
    },
    // You can also pass a callback to style for additional customization
    // {
    //   when: (row) => row.calories < 300,
    //   style: (row) => ({
    //     backgroundColor: row.isSpecia ? 'pink' : 'inerit',
    //   }),
    // },
  ]
  // const columns = [
  //   {
  //     name: 'Title',
  //     selector: (row) => row.title,
  //     // sortable: true,
  //   },
  //   {
  //     name: 'Year',
  //     selector: (row) => row.year,
  //     // sortable: true,
  //   },
  // ]

  // const data = [
  //   {
  //     id: 1,
  //     title: 'Beetlejuice',
  //     year: '1988',
  //   },
  //   {
  //     id: 2,
  //     title: 'Ghostbusters',
  //     year: '1984',
  //   },
  // ]
  // console.log('columns', columns)
  // console.log('data_table', data_table)
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'All',
  }

  if (columns.length > 0 && data_table.length > 0) {
    return (
      // id='table-default' className='table-responsive'
      <div>
        {/* columns={columns} data={data_table} responsive fixedHeader*/}
        <DataTable
          columns={columns}
          data={data_table}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          progressPending={pending}
          // dense
          highlightOnHover
          customStyles={tableCustomStyles}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    )
  } else {
    return <div>There are no records to displayNo DATA</div>
  }
}

export default DataTables
