import React, { useEffect, useRef, useState } from 'react'
import axiosInstance from '../../../utils/AxiosInstance'
// import Select from 'react-select'
import Select from 'react-select'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import SelectContinent from './SelectContinent'
const ModalCountry = ({
  titleModalCountry,
  itemSelectedCountry,
  dataTableCountry,
  refreshCountry,
  setRefreshCountry,
  setItemSelectedCountry,
  continents,
  // selectedValueContinent,
  // setSelectedValueContinent,
  // continentId,
  // setContinentId,
  // handleChangeContinent,
  setShow,
  setMessage,
  setShowError,
  setMessageError,
  settitleMessage,
}) => {
  const buttonSubmitRef = useRef()
  const modalRefCountry = useRef()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [selectedValueContinent, setSelectedValueContinent] = useState(null)
  const [continentId, setContinentId] = useState('')
  const [codeCountry, setCodeCountry] = useState('')
  const [alpha2, setAlpha2] = useState('')
  const [alpha3, setAlpha3] = useState('')
  const [nameFr, setNameFr] = useState('')
  // const [continents, setContinents] = useState([])

  const [initialValues, setInitialValues] = useState({
    code: '',
    alpha2: '',
    alpha3: '',
    name_fr: '',
    continent: '',
  })

  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .min(3, 'Code du pays doit être  3 caractères')
      .max(3, 'Code du pays est trop long !')
      .trim()
      .required('Code du pays est obligatoire'),
    alpha2: Yup.string()
      .min(2, 'alpha2 du pays doit être  2 caractères')
      .max(2, 'alpha2 du pays est trop long !')
      .trim()
      .required('alpha2 du pays est obligatoire'),
    alpha3: Yup.string()
      .min(3, 'alpha3 du pays doit être  3 caractères')
      .max(3, 'alpha3 du pays est trop long !')
      .trim()
      .required('alpha3 du pays est obligatoire'),
    name_fr: Yup.string().trim().required('Nom du pays  est obligatoire.'),
    continent: Yup.string().trim().required('Continent est obligatoire.'),
    // .test('user_name', "Le Nom d'utilisater exist déja", async (value) => {
    //   try {
    //     if (value != '' && value != null) {
    //       const res = await axiosInstance.post('serie/checkusername', {
    //         user_name: value,
    //       })
    //       return !res.data.username_exist
    //     } else return true
    //   } catch (err) {
    //     console.error(err)
    //   }
    // }),
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,

    onSubmit: (values, errors, touched) => {
      console.log(values)
      // alert(JSON.stringify(values, null, 2))

      setLoadingSubmit(true)
      if (itemSelectedCountry == null) {
        AddContinent(
          values.code,
          values.alpha2,
          values.alpha3,
          values.name_fr,
          values.continent
        )
      } else {
        UpdateContinent(
          values.code,
          values.alpha2,
          values.alpha3,
          values.name_fr,
          values.continent
        )
      }

      setItemSelectedCountry(null)
    },
  })

  // handle selection Continent
  const handleChangeContinent = (value) => {
    console.log(value)
    setSelectedValueContinent(value)
    if (value) {
      // setContinentId(value.value)
      formik.values.continent = value.value
    } else {
      // setContinentId('')
      formik.values.contiennt = ''
    }
  }

  // // Function to get Continent
  // const getContinent = async () => {
  //   const response = await axiosInstance
  //     .get('api/trade/continent/')
  //     .then((res) => {
  //       const data = res.data
  //       console.log('continent : ', data)

  //       const arr = []

  //       data.map((x) => {
  //         return arr.push({ value: x.id, label: x.name_fr })
  //       })
  //       setContinents(arr)
  //     })
  //     .catch(function (error) {
  //       console.log(error)
  //     })
  // }

  const AddContinent = async (code, alpha2, alpha3, name_fr, continent) => {
    const response = await axiosInstance
      .post('api/trade/country/', {
        code: code,
        alpha2: alpha2,
        alpha3: alpha3,
        name_fr: name_fr,
        continent: continent,
      })
      .then((res) => {
        const data = res.data
        settitleMessage("L'opération s'est terminée avec succès.")
        setMessage(data.message)
        console.log(data)
        modalRefCountry.current.click()
        setCodeCountry('')
        setAlpha2('')
        setAlpha3('')
        setNameFr('')
        setContinentId('')
        setSelectedValueContinent(null)
        setRefreshCountry(!refreshCountry)
        setLoadingSubmit(false)
        setShow(true)
      })
      .catch(function (error) {
        settitleMessage("L'opération a échoué.")
        setMessageError(error.response.data.message)
        console.log(error.response.data.message)
        modalRefCountry.current.click()
        setCodeCountry('')
        setAlpha2('')
        setAlpha3('')
        setNameFr('')
        setContinentId('')
        setSelectedValueContinent(null)
        setLoadingSubmit(false)
        setShowError(true)
      })
  }

  const UpdateContinent = async (code, alpha2, alpha3, name_fr, continent) => {
    const response = await axiosInstance
      .put('api/trade/country/' + code + '/', {
        alpha2: alpha2,
        alpha3: alpha3,
        name_fr: name_fr,
        continent_id: continent,
      })
      .then((res) => {
        const data = res.data
        settitleMessage("L'opération s'est terminée avec succès.")
        setMessage(data.message)
        console.log(data)
        modalRefCountry.current.click()
        setCodeCountry('')
        setAlpha2('')
        setAlpha3('')
        setNameFr('')
        setContinentId('')
        setSelectedValueContinent(null)
        setRefreshCountry(!refreshCountry)
        setShow(true)
      })
      .catch(function (error) {
        settitleMessage("L'opération a échoué.")
        setMessageError(error.response.data.message)
        console.log(error.response.data.message)
        modalRefCountry.current.click()
        setCodeCountry('')
        setAlpha2('')
        setAlpha3('')
        setNameFr('')
        setContinentId('')
        setSelectedValueContinent(null)
        setShowError(true)
      })
  }
  const handleClickAddUpdateCountry = async (e) => {
    e.preventDefault()
    // console.log(modalRef)
    // console.log(selectedValueChapter)
    console.log(codeCountry)
    console.log(alpha2)
    console.log(alpha3)
    console.log(nameFr)
    console.log(continentId)

    if (itemSelectedCountry == null) {
      // const response = await axiosInstance
      //   .post('api/trade/country/', {
      //     code: codeCountry,
      //     alpha2: alpha2,
      //     alpha3: alpha3,
      //     name_fr: nameFr,
      //     continent: continentId,
      //   })
      //   .then((res) => {
      //     const data = res.data
      //     settitleMessage("L'opération s'est terminée avec succès.")
      //     setMessage(data.message)
      //     console.log(data)
      //     modalRefCountry.current.click()
      //     setCodeCountry('')
      //     setAlpha2('')
      //     setAlpha3('')
      //     setNameFr('')
      //     setContinentId('')
      //     setSelectedValueContinent(null)
      //     setRefreshCountry(!refreshCountry)
      //     setShow(true)
      //   })
      //   .catch(function (error) {
      //     settitleMessage("L'opération a échoué.")
      //     setMessageError(error.response.data.message)
      //     console.log(error.response.data.message)
      //     modalRefCountry.current.click()
      //     setCodeCountry('')
      //     setAlpha2('')
      //     setAlpha3('')
      //     setNameFr('')
      //     setContinentId('')
      //     setSelectedValueContinent(null)
      //     setShowError(true)
      //   })
    } else {
      // const response = await axiosInstance
      //   .put('api/trade/country/' + codeCountry + '/', {
      //     alpha2: alpha2,
      //     alpha3: alpha3,
      //     name_fr: nameFr,
      //     continent_id: continentId,
      //   })
      //   .then((res) => {
      //     const data = res.data
      //     settitleMessage("L'opération s'est terminée avec succès.")
      //     setMessage(data.message)
      //     console.log(data)
      //     modalRefCountry.current.click()
      //     setCodeCountry('')
      //     setAlpha2('')
      //     setAlpha3('')
      //     setNameFr('')
      //     setContinentId('')
      //     setSelectedValueContinent(null)
      //     setRefreshCountry(!refreshCountry)
      //     setShow(true)
      //   })
      //   .catch(function (error) {
      //     settitleMessage("L'opération a échoué.")
      //     setMessageError(error.response.data.message)
      //     console.log(error.response.data.message)
      //     modalRefCountry.current.click()
      //     setCodeCountry('')
      //     setAlpha2('')
      //     setAlpha3('')
      //     setNameFr('')
      //     setContinentId('')
      //     setSelectedValueContinent(null)
      //     setShowError(true)
      //   })
    }
    setItemSelectedCountry(null)
  }

  // useEffect(() => {
  //   getContinent()
  // }, [])

  useEffect(() => {
    formik.initialValues = {
      code: '',
      alpha2: '',
      alpha3: '',
      name_fr: '',
    }
    formik.values.code = ''
    formik.values.alpha2 = ''
    formik.values.alpha3 = ''
    formik.values.name_fr = ''
    formik.resetForm()
    // setCodeCountry('')
    // setAlpha2('')
    // setAlpha3('')
    // setNameFr('')
    // setContinentId('')
    setSelectedValueContinent('')

    formik.values.code =
      (itemSelectedCountry !== null) & (dataTableCountry.length !== 0)
        ? dataTableCountry.find((x) => x.code === itemSelectedCountry).code
        : ''

    formik.values.alpha2 =
      (itemSelectedCountry !== null) & (dataTableCountry.length !== 0)
        ? dataTableCountry.find((x) => x.code === itemSelectedCountry).alpha2
        : ''

    formik.values.alpha3 =
      (itemSelectedCountry !== null) & (dataTableCountry.length !== 0)
        ? dataTableCountry.find((x) => x.code === itemSelectedCountry).alpha3
        : ''

    formik.values.name_fr =
      (itemSelectedCountry !== null) & (dataTableCountry.length !== 0)
        ? dataTableCountry.find((x) => x.code === itemSelectedCountry).name_fr
        : ''

    formik.values.continent =
      (itemSelectedCountry !== null) & (dataTableCountry.length !== 0)
        ? dataTableCountry.find((x) => x.code === itemSelectedCountry).continent
        : ''
    setSelectedValueContinent(
      (itemSelectedCountry !== null) & (dataTableCountry.length !== 0)
        ? continents[
            continents
              .map((object) => object.label)
              .indexOf(
                dataTableCountry.find((x) => x.code === itemSelectedCountry)
                  .continent
              )
          ]
        : ''
    )
    // if (itemSelectedCountry != null) {
    //   if (dataTableCountry.length !== 0) {
    //     setCodeCountry(
    //       dataTableCountry.find((x) => x.code === itemSelectedCountry).code
    //     )

    //     setAlpha2(
    //       dataTableCountry.find((x) => x.code === itemSelectedCountry).alpha2
    //     )
    //     setAlpha3(
    //       dataTableCountry.find((x) => x.code === itemSelectedCountry).alpha3
    //     )
    //     setNameFr(
    //       dataTableCountry.find((x) => x.code === itemSelectedCountry).name_fr
    //     )
    //     setSelectedValueContinent(
    //       continents[
    //         continents
    //           .map((object) => object.label)
    //           .indexOf(
    //             dataTableCountry.find((x) => x.code === itemSelectedCountry)
    //               .continent
    //           )
    //       ]
    //     )
    //     console.log(
    //       continents
    //         .map((object) => object.label)
    //         .indexOf(
    //           dataTableCountry.find((x) => x.code === itemSelectedCountry)
    //             .continent
    //         )
    //     )
    //     setContinentId(
    //       continents
    //         .map((object) => object.label)
    //         .indexOf(
    //           dataTableCountry.find((x) => x.code === itemSelectedCountry)
    //             .continent
    //         )
    //     )
    //     // console.log(continents)
    //     // console.log(
    //     //   dataTableCountry.find((x) => x.code === itemSelectedCountry).continent
    //     // )
    //   }
    // }
  }, [itemSelectedCountry])

  return (
    <div
      className='modal modal-blur fade'
      id='add-country'
      tabIndex='-1'
      role='dialog'
      aria-hidden='true'
    >
      <div
        className='modal-dialog modal-lg modal-dialog-centered'
        role='document'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>{titleModalCountry}</h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <form autoComplete='off' onSubmit={formik.handleSubmit}>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='mb-3'>
                    <label className='form-label'>Continent </label>
                    <Select
                      value={selectedValueContinent}
                      options={continents}
                      getOptionLabel={(e) => e.value + '-' + e.label}
                      getOptionValue={(e) => e.value}
                      id='continent'
                      name='continent'
                      isSearchable={true}
                      isClearable={true}
                      placeholder='Sélectionner le continent'
                      onChange={handleChangeContinent}
                    />
                    {formik.errors.continent && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.continent}
                      </div>
                    )}
                    {/* <SelectContinent
                      continents={continents}
                      selectedValueContinent={selectedValueContinent}
                      handleChangeContinent={handleChangeContinent}
                      // handleChangeChapter={handleChangeChapter}
                      // setChapters={setChapters}
                    /> */}
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='mb-3'>
                    <label className='form-label'>Code </label>
                    {/* <input
                      disabled={itemSelectedCountry != null ? true : false}
                      type='text'
                      className='form-control'
                      required
                      id='code_country'
                      name='code_country'
                      value={codeCountry}
                      onChange={(e) => setCodeCountry(e.target.value)}
                    /> */}
                    <input
                      disabled={itemSelectedCountry != null ? true : false}
                      type='text'
                      className='form-control'
                      id='code'
                      name='code'
                      onChange={formik.handleChange}
                      value={formik.values.code}
                    />
                    {formik.errors.code && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.code}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='mb-3'>
                    <label className='form-label'>Alpha2 </label>
                    {/* <input
                      // disabled={itemSelectedSubChapter != null ? true : false}
                      type='text'
                      className='form-control'
                      id='alpha2'
                      name='alpha2'
                      value={alpha2}
                      onChange={(e) => setAlpha2(e.target.value)}
                    /> */}
                    <input
                      type='text'
                      className='form-control'
                      id='alpha2'
                      name='alpha2'
                      onChange={formik.handleChange}
                      value={formik.values.alpha2}
                    />
                    {formik.errors.alpha2 && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.alpha2}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='mb-3'>
                    <label className='form-label'>Alpha3 </label>
                    {/* <input
                      // disabled={itemSelectedSubChapter != null ? true : false}
                      type='text'
                      className='form-control'
                      id='alpha3'
                      name='alpha3'
                      value={alpha3}
                      onChange={(e) => setAlpha3(e.target.value)}
                    /> */}
                    <input
                      type='text'
                      className='form-control'
                      id='alpha3'
                      name='alpha3'
                      onChange={formik.handleChange}
                      value={formik.values.alpha3}
                    />
                    {formik.errors.alpha3 && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.alpha3}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='mb-3'>
                    <label className='form-label'>Nom </label>
                    <input
                      type='text'
                      className='form-control'
                      id='name_fr'
                      name='name_fr'
                      onChange={formik.handleChange}
                      value={formik.values.name_fr}
                    />
                    {formik.errors.name_fr && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.name_fr}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <a
                href='#'
                className='btn btn-link link-secondary'
                data-bs-dismiss='modal'
                ref={modalRefCountry}
              >
                Annuler
              </a>

              <button
                disabled={loadingSubmit ? true : false}
                type='submit'
                className='btn btn-primary ms-auto'
                style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
              >
                <div className='pe-2'>Enregister</div>
                {loadingSubmit ? (
                  <div className='spinner-border ' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                ) : null}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ModalCountry
