import React, { useState } from 'react'
import ExcelIcon from '../assets/images/icons8-excel-48.svg'
import ModalExportFile from '../component/tools/ModalExportFile'
const Export = () => {
  const [click, setClick] = useState(false)
  // Handle Add SubChapter
  const handleClickFile = (id) => {
    setClick(!click)
  }
  return (
    <div className='page-wrapper'>
      <div className='page-body'>
        <div className='container-xl'>
          <div className='row row-cards'>
            <div className='col-md-4 col-xl-2'>
              <a
                className='card card-link'
                href='#'
                data-bs-toggle='modal'
                onClick={() => handleClickFile()}
                data-bs-target='#modal-export-file'
              >
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-auto'>
                      <span
                        className='avatar rounded'
                        // style={
                        //   'background-image: url(./static/avatars/001f.jpg)'
                        // }
                        // style={{backgroundImage=ExcelIcon}}
                      >
                        <img src={ExcelIcon} alt='' width={48} height={48} />
                      </span>
                    </div>
                    <div className='col d-flex  align-items-center '>
                      <span className='font-weight-medium'>Fichier</span>

                      {/* <div className='text-secondary'>Civil Engineer</div> */}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ModalExportFile
        click={click}
        setClick={setClick}
        // setShow={setShow}
        // setMessage={setMessage}
        // setShowError={setShowError}
        // setMessageError={setMessageError}
        // settitleMessage={settitleMessage}
        // handleClick={handleClick}
        // setHandleClick={setHandleClick}
        // titleModalUser={titleModalUser}
        // itemSelected={itemSelected}
        // setItemSelected={setItemSelected}
        // dataTable={dataTable}
      />
    </div>
  )
}

export default Export
