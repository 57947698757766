import React, { useContext } from 'react'
import Filter from '../component/tools/Filter'
import AuthContext from '../context/AuthContext'
const Chart = () => {
  const { user } = useContext(AuthContext)
  if (Object.keys(user).length > 0 && user.permissions['chart_page']) {
    return (
      <div className='page-wrapper'>
        {/* Page body */}
        <div className='page-body'>
          <div className='container-xl'>
            {/* Filter here */}
            {/* <div className='row row-cards mb-4'>
            <div className='col-12'></div>
          </div> */}
            <Filter />
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default Chart
