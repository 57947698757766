import React from 'react'
import { MapTools } from '../../helpers/MapTools'
import MapProjection from '../../helpers/MapProjection'
import { geoPath } from 'd3'

import Country from './Country'
const CountryList = ({ dataCountries }) => {
  // const width = '100%'
  // const height = '100%'
  // step 1: load geoJSON and create tooltip
  const { mapData } = MapTools()
  // console.log('mapData', mapData.length)
  if (Object.keys(mapData).length > 0 && dataCountries !== null) {
    // console.log(mapData.features)
    // compute a path function based on correct projections that we will use later
    const path = geoPath(MapProjection(mapData))
    // console.log('MapProjection', MapProjection)
    // console.log(path)

    // for each geoJSON coordinate, compute and pass in the equivalent svg path
    const Countries = mapData.features.map((data) => {
      // console.log(data.properties.alpha2)
      // let country_exist = false
      // console.log(
      //   data.properties.name,
      //   data.properties.alpha2,
      //   dataCountries.filter(
      //     (country) =>
      //       country.country.substring(0, 2) === data.properties.alpha2
      //   )[0]
      // )
      let country_exist =
        dataCountries.filter(
          (country) =>
            country.country.substring(0, 2) === data.properties.alpha2
        )[0] !== undefined
          ? true
          : false
      // const region_name = data.properties['NAME_ENG']
      return (
        <Country
          key={data.properties.name}
          path={path(data)}
          tooltipData={data.properties.name}
          fill_color={country_exist ? 'blue' : 'grey'}
        />
      )
    })

    return (
      <>
        <svg width='1000' height='500' id='map'>
          <g>{Countries}</g>
        </svg>
      </>
    )
  }
}

export default CountryList
