import React from 'react'
import Select from 'react-select'
const SelectProduct = ({
  products,
  value,
  subProductSelectRef,
  handleChangeProduct,
}) => {
  return (
    <div className='mb-2'>
      <label className='form-label'>Sous position tarifaire</label>
      <Select
        options={products}
        getOptionLabel={(e) => e.value + '-' + e.label}
        getOptionValue={(e) => e.value}
        id='product'
        name='product'
        value={value}
        isSearchable={true}
        isClearable={true}
        placeholder='Sélectionner la sous position'
        ref={subProductSelectRef}
        onChange={handleChangeProduct}
        isMulti
      />
    </div>
  )
}

export default SelectProduct
