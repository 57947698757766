import React from 'react'
import { IconAlertTriangle } from '@tabler/icons-react'
const ModalConfirm = ({
  showConfirm,
  setShowConfirm,
  itemSelected,
  confirm,
}) => {
  const handleConfirm = () => {
    confirm(itemSelected)
  }

  const handleClose = () => {
    setShowConfirm(false)
  }
  return (
    <div
      // 'modal modal-blur fade'
      // {`modal ${showModalConfirmDelete ? ' modal-blur' : ''} fade`}

      className='modal modal-blur fade'
      id='modal-confirm'
      tabIndex='-1'
      role='dialog'
      aria-hidden='true'
    >
      <div
        className='modal-dialog modal-sm modal-dialog-centered'
        role='document'
      >
        <div className='modal-content'>
          {' '}
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='modal'
            aria-label='Close'
          ></button>
          <div className='modal-status bg-warning'></div>
          <div className='modal-body text-center py-4'>
            <IconAlertTriangle
              size={24}
              className='icon mb-2 text-warning icon-lg'
            />
            <h3>êtes-vous sûr ? </h3>
            <div className='text-secondary'>
              Voulez-vous vraiment réinitialiser le mot de passe de cet
              utilisateur ?
            </div>
          </div>
          <div className='modal-footer'>
            <div className='w-100'>
              <div className='row'>
                <div className='col'>
                  <a
                    href='#'
                    className='btn w-100'
                    data-bs-dismiss='modal'
                    onClick={handleClose}
                  >
                    Annuler
                  </a>
                </div>
                <div className='col'>
                  <a
                    href='#'
                    className='btn btn-warning w-100'
                    data-bs-dismiss='modal'
                    onClick={handleConfirm}
                  >
                    Réinitialiser
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalConfirm
