import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import {
  IconUsers,
  IconHome,
  IconBrandProducthunt,
  IconTable,
} from '@tabler/icons-react'
import AuthContext from '../../context/AuthContext'
const SidebarAdmin = () => {
  const { user } = useContext(AuthContext)
  // console.log('user info from authcontext', user)
  return (
    <>
      {/* Sidebar */}
      <aside
        className='navbar navbar-vertical navbar-expand-lg'
        data-bs-theme='dark'
        style={{ backgroundColor: '#0073AD' }}
      >
        <div className='container-fluid'>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#sidebar-menu'
            aria-controls='sidebar-menu'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <h1 className='navbar-brand navbar-brand-autodark'>
            {/* <a href='.'>
              <img
                src='./static/logo.svg'
                width='110'
                height='32'
                alt='Tabler'
                className='navbar-brand-image'
              />
            </a> */}
            Administration
          </h1>

          <div className='collapse navbar-collapse' id='sidebar-menu'>
            <ul className='navbar-nav pt-lg-3'>
              <li className='nav-item'>
                <Link
                  to='home'
                  className='nav-link'
                  style={{ color: '#ffffff', fontWeight: 'bold' }}
                >
                  <span className='nav-link-icon d-md-none d-lg-inline-block'>
                    <IconHome
                      size={24}
                      className='icon'
                      style={{ color: '#ffffff' }}
                    />
                  </span>
                  <span className='nav-link-title'>Home</span>
                </Link>
                {/* <a
                  className='nav-link'
                  href='./'
                  style={{ color: '#ffffff', fontWeight: 'bold' }}
                ></a> */}
              </li>
              {user.permissions && user.permissions['user_management'] && (
                <li className='nav-item'>
                  <Link
                    to='users'
                    className='nav-link'
                    style={{ color: '#ffffff', fontWeight: 'bold' }}
                  >
                    <span className='nav-link-icon d-md-none d-lg-inline-block'>
                      <IconUsers
                        size={24}
                        className='icon'
                        style={{ color: '#ffffff' }}
                      />
                    </span>
                    <span className='nav-link-title'>Utilisateurs</span>
                  </Link>
                </li>
              )}
              {user.permissions && user.permissions['product_management'] && (
                <li className='nav-item'>
                  <Link
                    to='products'
                    className='nav-link'
                    style={{ color: '#ffffff', fontWeight: 'bold' }}
                  >
                    <span className='nav-link-icon d-md-none d-lg-inline-block'>
                      <IconBrandProducthunt
                        size={24}
                        className='icon'
                        style={{ color: '#ffffff' }}
                      />
                    </span>
                    <span className='nav-link-title'>Produits</span>
                  </Link>
                </li>
              )}
              {user.permissions && user.permissions['region_management'] && (
                <li className='nav-item'>
                  <Link
                    to='regions'
                    className='nav-link'
                    style={{ color: '#ffffff', fontWeight: 'bold' }}
                  >
                    <span className='nav-link-icon d-md-none d-lg-inline-block'>
                      <IconTable
                        size={24}
                        className='icon'
                        style={{ color: '#ffffff' }}
                      />
                    </span>
                    <span className='nav-link-title'>Régions</span>
                  </Link>
                </li>
              )}
              {user.permissions && user.permissions['data_management'] && (
                <li className='nav-item'>
                  <Link
                    to='datamanagement'
                    className='nav-link'
                    style={{ color: '#ffffff', fontWeight: 'bold' }}
                  >
                    <span className='nav-link-icon d-md-none d-lg-inline-block'>
                      <IconTable
                        size={24}
                        className='icon'
                        style={{ color: '#ffffff' }}
                      />
                    </span>
                    <span className='nav-link-title'>Données</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </aside>
    </>
  )
}

export default SidebarAdmin
