export const countriesTopoJson = [
  {
    type: 'Topology',
    objects: {
      countries: {
        type: 'GeometryCollection',
        geometries: [
          {
            type: 'Polygon',
            arcs: [[0, 1, 2, 3]],
            id: '716',
            properties: {
              name: 'Zimbabwe',
              alpha2: 'ZW',
              alpha3: 'ZWE',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-3, 4, 5, 6, 7, 8, 9]],
            id: '894',
            properties: {
              name: 'Zambia',
              alpha2: 'ZM',
              alpha3: 'ZMB',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[10, 11, 12]], [[13]], [[14]], [[15]], [[16]]],
            id: '887',
            properties: {
              name: 'Yemen',
              alpha2: 'YE',
              alpha3: 'YEM',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[17]],
              [[18, 19, 20, 21]],
              [[22]],
              [[23]],
              [[24]],
              [[25]],
              [[26]],
              [[27]],
            ],
            id: '704',
            properties: {
              name: 'Viet Nam',
              alpha2: 'VN',
              alpha3: 'VNM',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[28]], [[29]], [[30]], [[31]], [[32, 33, 34, 35]]],
            id: '862',
            properties: {
              name: 'Venezuela, Bolivarian Republic of',
              alpha2: 'VE',
              alpha3: 'VEN',
            },
          },
          {
            type: 'Polygon',
            arcs: [[36]],
            id: '336',
            properties: {
              name: 'Vatican',
              alpha2: 'VA',
              alpha3: 'VAT',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[37]],
              [[38]],
              [[39]],
              [[40]],
              [[41]],
              [[42]],
              [[43]],
              [[44]],
              [[45]],
              [[46]],
              [[47]],
              [[48]],
              [[49]],
              [[50]],
            ],
            id: '548',
            properties: {
              name: 'Vanuatu',
              alpha2: 'VU',
              alpha3: 'VUT',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[51, 52, 53, 54, 55], [56]], [[57]], [[58]]],
            id: '860',
            properties: {
              name: 'Uzbekistan',
              alpha2: 'UZ',
              alpha3: 'UZB',
            },
          },
          {
            type: 'Polygon',
            arcs: [[59, 60, 61]],
            id: '858',
            properties: {
              name: 'Uruguay',
              alpha2: 'UY',
              alpha3: 'URY',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[62]], [[63]], [[64]], [[65]], [[66]]],
            id: '583',
            properties: {
              name: 'Micronesia',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[67]], [[68]], [[69]], [[70]], [[71]]],
            id: '584',
            properties: {
              name: 'Marshall Islands',
              alpha2: 'MH',
              alpha3: 'MHL',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[72]], [[73]], [[74]], [[75]], [[76]], [[77]]],
            id: '580',
            properties: {
              name: 'N. Mariana Is.',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[78]], [[79]], [[80]]],
            id: '850',
            properties: {
              name: 'Virgin Islands, U.S.',
              alpha2: 'VI',
              alpha3: 'VIR',
            },
          },
          {
            type: 'Polygon',
            arcs: [[81]],
            id: '316',
            properties: {
              name: 'Guam',
              alpha2: 'GU',
              alpha3: 'GUM',
            },
          },
          {
            type: 'Polygon',
            arcs: [[82]],
            id: '016',
            properties: {
              name: 'American Samoa',
              alpha2: 'AS',
              alpha3: 'ASM',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[83]], [[84]], [[85]]],
            id: '630',
            properties: {
              name: 'Puerto Rico',
              alpha2: 'PR',
              alpha3: 'PRI',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[86]],
              [[87]],
              [[88]],
              [[89]],
              [[90]],
              [[91]],
              [[92]],
              [[93]],
              [[94]],
              [[95]],
              [[96]],
              [[97]],
              [[98]],
              [[99]],
              [[100]],
              [[101]],
              [[102, 103, 104, 105]],
              [[106]],
              [[107]],
              [[108]],
              [[109]],
              [[110]],
              [[111]],
              [[112]],
              [[113]],
              [[114]],
              [[115]],
              [[116]],
              [[117]],
              [[118]],
              [[119]],
              [[120]],
              [[121]],
              [[122]],
              [[123]],
              [[124]],
              [[125]],
              [[126]],
              [[127]],
              [[128]],
              [[129]],
              [[130]],
              [[131]],
              [[132]],
              [[133]],
              [[134]],
              [[135]],
              [[136]],
              [[137]],
              [[138]],
              [[139]],
              [[140]],
              [[141]],
              [[142]],
              [[143]],
              [[144]],
              [[145]],
              [[146]],
              [[147]],
              [[148]],
              [[149]],
              [[150]],
              [[151]],
              [[152]],
              [[153]],
              [[154]],
              [[155]],
              [[156]],
              [[157]],
              [[158]],
              [[159]],
              [[160]],
              [[161]],
              [[162]],
              [[163]],
              [[164, 165, 166, 167, 168]],
              [[169]],
              [[170]],
              [[171]],
              [[172]],
              [[173]],
              [[174]],
              [[175]],
              [[176]],
              [[177]],
              [[178]],
              [[179]],
              [[180]],
              [[181]],
              [[182]],
              [[183]],
              [[184]],
              [[185]],
              [[186]],
              [[187]],
              [[188]],
              [[189]],
              [[190]],
              [[191]],
              [[192]],
              [[193]],
              [[194]],
              [[195]],
              [[196]],
              [[197]],
              [[198]],
              [[199]],
              [[200]],
              [[201]],
              [[202]],
              [[203]],
              [[204]],
              [[205]],
              [[206]],
              [[207]],
              [[208]],
              [[209]],
              [[210]],
              [[211]],
              [[212]],
              [[213]],
              [[214]],
              [[215]],
              [[216]],
              [[217]],
              [[218]],
              [[219]],
            ],
            id: '840',
            properties: {
              name: 'United States of America',
              alpha2: 'US',
              alpha3: 'USA',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[220]], [[221]]],
            id: '239',
            properties: {
              name: 'S. Geo. and the Is.',
            },
          },
          {
            type: 'Polygon',
            arcs: [[222]],
            id: '086',
            properties: {
              name: 'Br. Indian Ocean Ter.',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[223]], [[224]]],
            id: '654',
            properties: {
              name: 'Saint Helena, Ascension and Tristan da Cunha',
              alpha2: 'SH',
              alpha3: 'SHN',
            },
          },
          {
            type: 'Polygon',
            arcs: [[225]],
            id: '612',
            properties: {
              name: 'Pitcairn Is.',
            },
          },
          {
            type: 'Polygon',
            arcs: [[226]],
            id: '660',
            properties: {
              name: 'Anguilla',
              alpha2: 'AI',
              alpha3: 'AIA',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[227]], [[228]], [[229]], [[230]], [[231]], [[232]]],
            id: '238',
            properties: {
              name: 'Falkland Islands (Malvinas)',
              alpha2: 'FK',
              alpha3: 'FLK',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[233]], [[234]], [[235]]],
            id: '136',
            properties: {
              name: 'Cayman Is.',
            },
          },
          {
            type: 'Polygon',
            arcs: [[236]],
            id: '060',
            properties: {
              name: 'Bermuda',
              alpha2: 'BM',
              alpha3: 'BMU',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[237]], [[238]], [[239]]],
            id: '092',
            properties: {
              name: 'Virgin Islands, British',
              alpha2: 'VG',
              alpha3: 'VGB',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[240]], [[241]], [[242]]],
            id: '796',
            properties: {
              name: 'Turks and Caicos Islands',
              alpha2: 'TC',
              alpha3: 'TCA',
            },
          },
          {
            type: 'Polygon',
            arcs: [[243]],
            id: '500',
            properties: {
              name: 'Montserrat',
              alpha2: 'MS',
              alpha3: 'MSR',
            },
          },
          {
            type: 'Polygon',
            arcs: [[244]],
            id: '832',
            properties: {
              name: 'Jersey',
              alpha2: 'JE',
              alpha3: 'JEY',
            },
          },
          {
            type: 'Polygon',
            arcs: [[245]],
            id: '831',
            properties: {
              name: 'Guernsey',
              alpha2: 'GG',
              alpha3: 'GGY',
            },
          },
          {
            type: 'Polygon',
            arcs: [[246]],
            id: '833',
            properties: {
              name: 'Isle of Man',
              alpha2: 'IM',
              alpha3: 'IMN',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[247]],
              [[248]],
              [[249]],
              [[250]],
              [[251]],
              [[252]],
              [[253]],
              [[254]],
              [[255]],
              [[256]],
              [[257]],
              [[258]],
              [[259]],
              [[260]],
              [[261]],
              [[262]],
              [[263]],
              [[264]],
              [[265]],
              [[266]],
              [[267]],
              [[268, 269]],
              [[270]],
            ],
            id: '826',
            properties: {
              name: 'United Kingdom',
              alpha2: 'GB',
              alpha3: 'GBR',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[271, 272, 273, 274, 275], [276]],
              [[277]],
              [[278]],
              [[279]],
              [[280]],
            ],
            id: '784',
            properties: {
              name: 'United Arab Emirates',
              alpha2: 'AE',
              alpha3: 'ARE',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291]],
              [[292]],
            ],
            id: '804',
            properties: {
              name: 'Ukraine',
              alpha2: 'UA',
              alpha3: 'UKR',
            },
          },
          {
            type: 'Polygon',
            arcs: [[293, 294, 295, 296, 297]],
            id: '800',
            properties: {
              name: 'Uganda',
              alpha2: 'UG',
              alpha3: 'UGA',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[298]], [[-55, 299, 300, 301, 302]]],
            id: '795',
            properties: {
              name: 'Turkmenistan',
              alpha2: 'TM',
              alpha3: 'TKM',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[303]],
              [[304, 305, 306, 307, 308, 309, 310]],
              [[311, 312, 313]],
            ],
            id: '792',
            properties: {
              name: 'Turkey',
              alpha2: 'TR',
              alpha3: 'TUR',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[314, 315, 316]], [[317]], [[318]]],
            id: '788',
            properties: {
              name: 'Tunisia',
              alpha2: 'TN',
              alpha3: 'TUN',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[319]], [[320]]],
            id: '780',
            properties: {
              name: 'Trinidad and Tobago',
              alpha2: 'TT',
              alpha3: 'TTO',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[321]], [[322]], [[323]]],
            id: '776',
            properties: {
              name: 'Tonga',
              alpha2: 'TO',
              alpha3: 'TON',
            },
          },
          {
            type: 'Polygon',
            arcs: [[324, 325, 326, 327]],
            id: '768',
            properties: {
              name: 'Togo',
              alpha2: 'TG',
              alpha3: 'TGO',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[328]], [[329, 330]], [[331, 332]]],
            id: '626',
            properties: {
              name: 'Timor-Leste',
              alpha2: 'TL',
              alpha3: 'TLS',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[333]],
              [[334]],
              [[335]],
              [[336, 337, 338, 339, 340, 341]],
              [[342]],
              [[343]],
              [[344]],
              [[345]],
              [[346]],
              [[347]],
            ],
            id: '764',
            properties: {
              name: 'Thailand',
              alpha2: 'TH',
              alpha3: 'THA',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[348]],
              [[349]],
              [[-8, 350, 351, 352, -294, 353, 354, 355, 356]],
              [[357]],
            ],
            id: '834',
            properties: {
              name: 'Tanzania, United Republic of',
              alpha2: 'TZ',
              alpha3: 'TZA',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-53, 358, 359, 360]], [[-57]], [[361]]],
            id: '762',
            properties: {
              name: 'Tajikistan',
              alpha2: 'TJ',
              alpha3: 'TJK',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[362]], [[363]]],
            id: '158',
            properties: {
              name: 'Taiwan, Province of China',
              alpha2: 'TW',
              alpha3: 'TWN',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-310, 364, 365, 366, 367, 368]],
            id: '760',
            properties: {
              name: 'Syrian Arab Republic',
              alpha2: 'SY',
              alpha3: 'SYR',
            },
          },
          {
            type: 'Polygon',
            arcs: [[369, 370, 371, 372, 373, 374]],
            id: '756',
            properties: {
              name: 'Switzerland',
              alpha2: 'CH',
              alpha3: 'CHE',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[375]],
              [[376]],
              [[377, 378, 379]],
              [[380]],
              [[381]],
              [[382]],
            ],
            id: '752',
            properties: {
              name: 'Sweden',
              alpha2: 'SE',
              alpha3: 'SWE',
            },
          },
          {
            type: 'Polygon',
            arcs: [[383, 384]],
            id: '748',
            properties: {
              name: 'eSwatini',
            },
          },
          {
            type: 'Polygon',
            arcs: [[385, 386, 387, 388]],
            id: '740',
            properties: {
              name: 'Suriname',
              alpha2: 'SR',
              alpha3: 'SUR',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-297, 389, 390, 391, 392, 393]],
            id: '728',
            properties: {
              name: 'S. Sudan',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-392, 394, 395, 396, 397, 398, 399, 400]],
            id: '729',
            properties: {
              name: 'Sudan',
              alpha2: 'SD',
              alpha3: 'SDN',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[401]], [[402]], [[403]]],
            id: '144',
            properties: {
              name: 'Sri Lanka',
              alpha2: 'LK',
              alpha3: 'LKA',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[404]],
              [[405]],
              [[406]],
              [[407]],
              [[408, 409, 410, 411, 412, 413]],
              [[414]],
              [[415]],
              [[416]],
              [[417]],
              [[418]],
              [[419]],
              [[420]],
            ],
            id: '724',
            properties: {
              name: 'Spain',
              alpha2: 'ES',
              alpha3: 'ESP',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[421, 422]],
              [[423]],
              [[424]],
              [[425]],
              [[426]],
              [[427]],
              [[428]],
              [[429]],
              [[430]],
              [[431]],
              [[432]],
            ],
            id: '410',
            properties: {
              name: 'Korea, Republic of',
              alpha2: 'KR',
              alpha3: 'KOR',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-1, 433, -385, 434, 435, 436, 437], [438]], [[439]]],
            id: '710',
            properties: {
              name: 'South Africa',
              alpha2: 'ZA',
              alpha3: 'ZAF',
            },
          },
          {
            type: 'Polygon',
            arcs: [[440, 441, 442, 443]],
            id: '706',
            properties: {
              name: 'Somalia',
              alpha2: 'SO',
              alpha3: 'SOM',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-443, 444, 445, 446]],
            properties: {
              name: 'Somaliland',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[447]],
              [[448]],
              [[449]],
              [[450]],
              [[451]],
              [[452]],
              [[453]],
              [[454]],
              [[455]],
              [[456]],
              [[457]],
              [[458]],
              [[459]],
              [[460]],
              [[461]],
              [[462]],
              [[463]],
              [[464]],
              [[465]],
              [[466]],
              [[467]],
            ],
            id: '090',
            properties: {
              name: 'Solomon Islands',
              alpha2: 'SB',
              alpha3: 'SLB',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-289, 468, 469, 470, 471]],
            id: '703',
            properties: {
              name: 'Slovakia',
              alpha2: 'SK',
              alpha3: 'SVK',
            },
          },
          {
            type: 'Polygon',
            arcs: [[472, 473, 474, 475, 476]],
            id: '705',
            properties: {
              name: 'Slovenia',
              alpha2: 'SI',
              alpha3: 'SVN',
            },
          },
          {
            type: 'Polygon',
            arcs: [[477]],
            id: '702',
            properties: {
              name: 'Singapore',
              alpha2: 'SG',
              alpha3: 'SGP',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[478, 479, 480]], [[481]]],
            id: '694',
            properties: {
              name: 'Sierra Leone',
              alpha2: 'SL',
              alpha3: 'SLE',
            },
          },
          {
            type: 'Polygon',
            arcs: [[482]],
            id: '690',
            properties: {
              name: 'Seychelles',
              alpha2: 'SC',
              alpha3: 'SYC',
            },
          },
          {
            type: 'Polygon',
            arcs: [[483, 484, 485, 486, 487, 488, 489, 490]],
            id: '688',
            properties: {
              name: 'Serbia',
              alpha2: 'RS',
              alpha3: 'SRB',
            },
          },
          {
            type: 'Polygon',
            arcs: [[491, 492, 493, 494, 495, 496, 497]],
            id: '686',
            properties: {
              name: 'Senegal',
              alpha2: 'SN',
              alpha3: 'SEN',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[498]],
              [[499]],
              [[-12, 500, 501, 502, 503, 504, 505, 506, -274, 507]],
              [[508]],
            ],
            id: '682',
            properties: {
              name: 'Saudi Arabia',
              alpha2: 'SA',
              alpha3: 'SAU',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[509]], [[510]]],
            id: '678',
            properties: {
              name: 'Sao Tome and Principe',
              alpha2: 'ST',
              alpha3: 'STP',
            },
          },
          {
            type: 'Polygon',
            arcs: [[511]],
            id: '674',
            properties: {
              name: 'San Marino',
              alpha2: 'SM',
              alpha3: 'SMR',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[512]], [[513]]],
            id: '882',
            properties: {
              name: 'Samoa',
              alpha2: 'WS',
              alpha3: 'WSM',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[514]], [[515]], [[516]]],
            id: '670',
            properties: {
              name: 'St. Vin. and Gren.',
            },
          },
          {
            type: 'Polygon',
            arcs: [[517]],
            id: '662',
            properties: {
              name: 'Saint Lucia',
              alpha2: 'LC',
              alpha3: 'LCA',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[518]], [[519]]],
            id: '659',
            properties: {
              name: 'St. Kitts and Nevis',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-295, -353, 520, 521]],
            id: '646',
            properties: {
              name: 'Rwanda',
              alpha2: 'RW',
              alpha3: 'RWA',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[522]],
              [[523]],
              [[524]],
              [[525]],
              [[526]],
              [[527]],
              [[528]],
              [[529]],
              [[530]],
              [[531]],
              [[532]],
              [[533]],
              [[534]],
              [[535]],
              [[536]],
              [[537]],
              [[538]],
              [
                [
                  539, 540, 541, 542, 543, 544, 545, 546, 547, -292, 548, 549,
                  550, 551, 552, 553, 554,
                ],
              ],
              [[555]],
              [[556]],
              [[557]],
              [[558]],
              [[559]],
              [[560]],
              [[561]],
              [[562]],
              [[563]],
              [[564]],
              [[565]],
              [[566]],
              [[567]],
              [[568]],
              [[569]],
              [[570]],
              [[571]],
              [[572]],
              [[573]],
              [[574]],
              [[575]],
              [[576]],
              [[577]],
              [[578]],
              [[579]],
              [[580]],
              [[581]],
              [[582]],
              [[583]],
              [[584]],
              [[585]],
              [[586]],
              [[587]],
              [[588]],
              [[589]],
              [[590]],
              [[591]],
              [[592]],
              [[593]],
              [[594]],
              [[595]],
              [[596]],
              [[597]],
              [[598]],
              [[599]],
              [[600]],
              [[601]],
              [[602]],
              [[603]],
              [[604]],
              [[605]],
              [[606]],
              [[607]],
              [[608]],
              [[609]],
              [[610]],
              [[611]],
              [[612]],
              [[613]],
              [[614]],
              [[615]],
              [[616]],
              [[617]],
              [[618]],
              [[619]],
              [[620]],
              [[621]],
              [[622]],
              [[623]],
              [[624]],
              [[625]],
              [[626]],
              [[627]],
              [[628]],
              [[629]],
              [[630]],
              [[631]],
              [[632]],
              [[633]],
              [[634, 635, 636, 637, 638]],
              [[-283, 639]],
            ],
            id: '643',
            properties: {
              name: 'Russian Federation',
              alpha2: 'RU',
              alpha3: 'RUS',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-285, 640, 641, -491, 642, -287, 643]],
            id: '642',
            properties: {
              name: 'Romania',
              alpha2: 'RO',
              alpha3: 'ROU',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-506, 644]],
            id: '634',
            properties: {
              name: 'Qatar',
              alpha2: 'QA',
              alpha3: 'QAT',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[645]],
              [[-413, 646]],
              [[647]],
              [[648]],
              [[649]],
              [[650]],
              [[651]],
              [[652]],
              [[653]],
            ],
            id: '620',
            properties: {
              name: 'Portugal',
              alpha2: 'PT',
              alpha3: 'PRT',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-290, -472, 654, 655, 656, 657, 658, -637, 659, 660]],
            id: '616',
            properties: {
              name: 'Poland',
              alpha2: 'PL',
              alpha3: 'POL',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[661]],
              [[662]],
              [[663]],
              [[664]],
              [[665]],
              [[666]],
              [[667]],
              [[668]],
              [[669]],
              [[670]],
              [[671]],
              [[672]],
              [[673]],
              [[674]],
              [[675]],
              [[676]],
              [[677]],
              [[678]],
              [[679]],
              [[680]],
              [[681]],
              [[682]],
              [[683]],
              [[684]],
              [[685]],
              [[686]],
              [[687]],
              [[688]],
              [[689]],
              [[690]],
              [[691]],
              [[692]],
              [[693]],
              [[694]],
              [[695]],
              [[696]],
              [[697]],
              [[698]],
              [[699]],
              [[700]],
              [[701]],
              [[702]],
              [[703]],
              [[704]],
              [[705]],
              [[706]],
              [[707]],
              [[708]],
            ],
            id: '608',
            properties: {
              name: 'Philippines',
              alpha2: 'PH',
              alpha3: 'PHL',
            },
          },
          {
            type: 'Polygon',
            arcs: [[709, 710, 711, 712, 713, 714]],
            id: '604',
            properties: {
              name: 'Peru',
              alpha2: 'PE',
              alpha3: 'PER',
            },
          },
          {
            type: 'Polygon',
            arcs: [[715, 716, 717]],
            id: '600',
            properties: {
              name: 'Paraguay',
              alpha2: 'PY',
              alpha3: 'PRY',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[718]],
              [[719]],
              [[720, 721]],
              [[722]],
              [[723]],
              [[724]],
              [[725]],
              [[726]],
              [[727]],
              [[728]],
              [[729]],
              [[730]],
              [[731]],
              [[732]],
              [[733]],
              [[734]],
              [[735]],
              [[736]],
              [[737]],
              [[738]],
              [[739]],
              [[740]],
              [[741]],
              [[742]],
              [[743]],
              [[744]],
            ],
            id: '598',
            properties: {
              name: 'Papua New Guinea',
              alpha2: 'PG',
              alpha3: 'PNG',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[745, 746, 747, 748]], [[749]], [[750]], [[751]], [[752]]],
            id: '591',
            properties: {
              name: 'Panama',
              alpha2: 'PA',
              alpha3: 'PAN',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[753]], [[754]]],
            id: '585',
            properties: {
              name: 'Palau',
              alpha2: 'PW',
              alpha3: 'PLW',
            },
          },
          {
            type: 'Polygon',
            arcs: [[755, 756, 757, 758, 759, 760]],
            id: '586',
            properties: {
              name: 'Pakistan',
              alpha2: 'PK',
              alpha3: 'PAK',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[761]], [[-13, -508, -273, 762]], [[-276, 763]], [[-277]]],
            id: '512',
            properties: {
              name: 'Oman',
              alpha2: 'OM',
              alpha3: 'OMN',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[-378, 764, -554, 765]],
              [[766]],
              [[767]],
              [[768]],
              [[769]],
              [[770]],
              [[771]],
              [[772]],
              [[773]],
              [[774]],
              [[775]],
              [[776]],
              [[777]],
              [[778]],
              [[779]],
              [[780]],
              [[781]],
              [[782]],
              [[783]],
              [[784]],
              [[785]],
              [[786]],
              [[787]],
              [[788]],
              [[789]],
              [[790]],
              [[791]],
              [[792]],
              [[793]],
              [[794]],
              [[795]],
              [[796]],
            ],
            id: '578',
            properties: {
              name: 'Norway',
              alpha2: 'NO',
              alpha3: 'NOR',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-422, 797, 798, -540, 799]], [[800]]],
            id: '408',
            properties: {
              name: "Korea, Democratic People's Republic of",
              alpha2: 'KP',
              alpha3: 'PRK',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[801]], [[802, 803, 804, 805, 806]]],
            id: '566',
            properties: {
              name: 'Nigeria',
              alpha2: 'NG',
              alpha3: 'NGA',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-807, 807, 808, 809, 810, 811, 812]],
            id: '562',
            properties: {
              name: 'Niger',
              alpha2: 'NE',
              alpha3: 'NER',
            },
          },
          {
            type: 'Polygon',
            arcs: [[813, 814, 815, 816]],
            id: '558',
            properties: {
              name: 'Nicaragua',
              alpha2: 'NI',
              alpha3: 'NIC',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[817]],
              [[818]],
              [[819]],
              [[820]],
              [[821]],
              [[822]],
              [[823]],
              [[824]],
              [[825]],
              [[826]],
              [[827]],
              [[828]],
              [[829]],
            ],
            id: '554',
            properties: {
              name: 'New Zealand',
              alpha2: 'NZ',
              alpha3: 'NZL',
            },
          },
          {
            type: 'Polygon',
            arcs: [[830]],
            id: '570',
            properties: {
              name: 'Niue',
              alpha2: 'NU',
              alpha3: 'NIU',
            },
          },
          {
            type: 'Polygon',
            arcs: [[831]],
            id: '184',
            properties: {
              name: 'Cook Islands',
              alpha2: 'CK',
              alpha3: 'COK',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[832, 833, 834]],
              [[835, 836]],
              [[837]],
              [[838]],
              [[839]],
              [[840]],
              [[841]],
              [[842]],
              [[843]],
              [[844]],
              [[845]],
              [[846]],
            ],
            id: '528',
            properties: {
              name: 'Netherlands',
              alpha2: 'NL',
              alpha3: 'NLD',
            },
          },
          {
            type: 'Polygon',
            arcs: [[847]],
            id: '533',
            properties: {
              name: 'Aruba',
              alpha2: 'AW',
              alpha3: 'ABW',
            },
          },
          {
            type: 'Polygon',
            arcs: [[848]],
            id: '531',
            properties: {
              name: 'Curaçao',
            },
          },
          {
            type: 'Polygon',
            arcs: [[849, 850]],
            id: '524',
            properties: {
              name: 'Nepal',
              alpha2: 'NP',
              alpha3: 'NPL',
            },
          },
          {
            type: 'Polygon',
            arcs: [[851]],
            id: '520',
            properties: {
              name: 'Nauru',
              alpha2: 'NR',
              alpha3: 'NRU',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-5, 852, -437, 853, 854]],
            id: '516',
            properties: {
              name: 'Namibia',
              alpha2: 'NA',
              alpha3: 'NAM',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-4, -10, 855, -356, 856, -435, -384, -434], [857], [858]],
            id: '508',
            properties: {
              name: 'Mozambique',
              alpha2: 'MZ',
              alpha3: 'MOZ',
            },
          },
          {
            type: 'Polygon',
            arcs: [[859, 860, 861]],
            id: '504',
            properties: {
              name: 'Morocco',
              alpha2: 'MA',
              alpha3: 'MAR',
            },
          },
          {
            type: 'Polygon',
            arcs: [[862, 863, 864, -861]],
            id: '732',
            properties: {
              name: 'Western Sahara',
              alpha2: 'EH',
              alpha3: 'ESH',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-487, 865, 866, 867, 868, 869]],
            id: '499',
            properties: {
              name: 'Montenegro',
              alpha2: 'ME',
              alpha3: 'MNE',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-542, 870]],
            id: '496',
            properties: {
              name: 'Mongolia',
              alpha2: 'MN',
              alpha3: 'MNG',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-286, -644]],
            id: '498',
            properties: {
              name: 'Moldova, Republic of',
              alpha2: 'MD',
              alpha3: 'MDA',
            },
          },
          {
            type: 'Polygon',
            arcs: [[871, 872]],
            id: '492',
            properties: {
              name: 'Monaco',
              alpha2: 'MC',
              alpha3: 'MCO',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[-167, 873, 874, 875, 876]],
              [[877]],
              [[878]],
              [[879]],
              [[880]],
              [[881]],
              [[882]],
              [[883]],
              [[884]],
              [[885]],
              [[886]],
              [[887]],
              [[888]],
              [[889]],
              [[890]],
              [[891]],
            ],
            id: '484',
            properties: {
              name: 'Mexico',
              alpha2: 'MX',
              alpha3: 'MEX',
            },
          },
          {
            type: 'Polygon',
            arcs: [[892]],
            id: '480',
            properties: {
              name: 'Mauritius',
              alpha2: 'MU',
              alpha3: 'MUS',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[893]], [[-498, 894, -864, 895, 896]]],
            id: '478',
            properties: {
              name: 'Mauritania',
              alpha2: 'MR',
              alpha3: 'MRT',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[897]], [[898]]],
            id: '470',
            properties: {
              name: 'Malta',
              alpha2: 'MT',
              alpha3: 'MLT',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-492, -897, 899, -810, 900, 901, 902]],
            id: '466',
            properties: {
              name: 'Mali',
              alpha2: 'ML',
              alpha3: 'MLI',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[903]], [[904]]],
            id: '462',
            properties: {
              name: 'Maldives',
              alpha2: 'MV',
              alpha3: 'MDV',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[-340, 905]],
              [[906, 907, 908, 909, 910]],
              [[911]],
              [[912]],
              [[913]],
              [[914]],
              [[915]],
              [[916]],
              [[917, 918]],
            ],
            id: '458',
            properties: {
              name: 'Malaysia',
              alpha2: 'MY',
              alpha3: 'MYS',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-859]], [[-858]], [[-9, -357, -856]]],
            id: '454',
            properties: {
              name: 'Malawi',
              alpha2: 'MW',
              alpha3: 'MWI',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[919]], [[920]], [[921]]],
            id: '450',
            properties: {
              name: 'Madagascar',
              alpha2: 'MG',
              alpha3: 'MDG',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-485, 922, 923, 924, 925]],
            id: '807',
            properties: {
              name: 'Macedonia, the former Yugoslav Republic of',
              alpha2: 'MK',
              alpha3: 'MKD',
            },
          },
          {
            type: 'Polygon',
            arcs: [[926, 927, 928]],
            id: '442',
            properties: {
              name: 'Luxembourg',
              alpha2: 'LU',
              alpha3: 'LUX',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-639, 929]], [[-636, 930, 931, 932, -660]]],
            id: '440',
            properties: {
              name: 'Lithuania',
              alpha2: 'LT',
              alpha3: 'LTU',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-371, 933]],
            id: '438',
            properties: {
              name: 'Liechtenstein',
              alpha2: 'LI',
              alpha3: 'LIE',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-315, 934, 935, -397, 936, -812, 937]],
            id: '434',
            properties: {
              name: 'Libya',
              alpha2: 'LY',
              alpha3: 'LBY',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-479, 938, 939, 940]],
            id: '430',
            properties: {
              name: 'Liberia',
              alpha2: 'LR',
              alpha3: 'LBR',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-439]],
            id: '426',
            properties: {
              name: 'Lesotho',
              alpha2: 'LS',
              alpha3: 'LSO',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-368, 941, 942]],
            id: '422',
            properties: {
              name: 'Lebanon',
              alpha2: 'LB',
              alpha3: 'LBN',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-932, 943, 944, -550, 945]],
            id: '428',
            properties: {
              name: 'Latvia',
              alpha2: 'LV',
              alpha3: 'LVA',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-21, 946, -337, 947, 948]],
            id: '418',
            properties: {
              name: "Lao People's Democratic Republic",
              alpha2: 'LA',
              alpha3: 'LAO',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-52, 949, 950, -359], [-58], [-59], [-362]],
            id: '417',
            properties: {
              name: 'Kyrgyzstan',
              alpha2: 'KG',
              alpha3: 'KGZ',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[951]], [[-504, 952, 953]]],
            id: '414',
            properties: {
              name: 'Kuwait',
              alpha2: 'KW',
              alpha3: 'KWT',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-486, -926, 954, -866]],
            properties: {
              name: 'Kosovo',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[955]],
              [[956]],
              [[957]],
              [[958]],
              [[959]],
              [[960]],
              [[961]],
              [[962]],
              [[963]],
              [[964]],
              [[965]],
              [[966]],
              [[967]],
              [[968]],
              [[969]],
              [[970]],
              [[971]],
              [[972]],
              [[973]],
            ],
            id: '296',
            properties: {
              name: 'Kiribati',
              alpha2: 'KI',
              alpha3: 'KIR',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[974]], [[-298, -394, 975, -441, 976, -354]]],
            id: '404',
            properties: {
              name: 'Kenya',
              alpha2: 'KE',
              alpha3: 'KEN',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[977]],
              [[978]],
              [[979]],
              [[-56, -303, 980, -544, 981, -950]],
            ],
            id: '398',
            properties: {
              name: 'Kazakhstan',
              alpha2: 'KZ',
              alpha3: 'KAZ',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-366, 982, -502, 983, 984, 985, 986]],
            id: '400',
            properties: {
              name: 'Jordan',
              alpha2: 'JO',
              alpha3: 'JOR',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[987]],
              [[988]],
              [[989]],
              [[990]],
              [[991]],
              [[992]],
              [[993]],
              [[994]],
              [[995]],
              [[996]],
              [[997]],
              [[998]],
              [[999]],
              [[1000]],
              [[1001]],
              [[1002]],
              [[1003]],
              [[1004]],
              [[1005]],
              [[1006]],
              [[1007]],
              [[1008]],
              [[1009]],
              [[1010]],
              [[1011]],
              [[1012]],
              [[1013]],
              [[1014]],
              [[1015]],
              [[1016]],
              [[1017]],
              [[1018]],
              [[1019]],
              [[1020]],
            ],
            id: '392',
            properties: {
              name: 'Japan',
              alpha2: 'JP',
              alpha3: 'JPN',
            },
          },
          {
            type: 'Polygon',
            arcs: [[1021]],
            id: '388',
            properties: {
              name: 'Jamaica',
              alpha2: 'JM',
              alpha3: 'JAM',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[-373, 1022, -475, 1023, 1024], [-37], [-512]],
              [[1025]],
              [[1026]],
              [[1027]],
              [[1028]],
              [[1029]],
              [[1030]],
              [[1031]],
            ],
            id: '380',
            properties: {
              name: 'Italy',
              alpha2: 'IT',
              alpha3: 'ITA',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-367, -987, 1032, -985, 1033, 1034, 1035, 1036, -942]],
            id: '376',
            properties: {
              name: 'Israel',
              alpha2: 'IL',
              alpha3: 'ISR',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-1036, 1037, 1038]], [[-986, -1033]]],
            id: '275',
            properties: {
              name: 'Palestine',
              alpha2: 'PS',
              alpha3: 'PSE',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[1039]], [[-269, 1040]]],
            id: '372',
            properties: {
              name: 'Ireland',
              alpha2: 'IE',
              alpha3: 'IRL',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-309, 1041, 1042, -953, -503, -983, -365]],
            id: '368',
            properties: {
              name: 'Iraq',
              alpha2: 'IQ',
              alpha3: 'IRQ',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1043]],
              [[-301, 1044, -759, 1045, -1042, -308, 1046, 1047, 1048, 1049]],
            ],
            id: '364',
            properties: {
              name: 'Iran, Islamic Republic of',
              alpha2: 'IR',
              alpha3: 'IRN',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1050]],
              [[1051]],
              [[1052]],
              [[1053]],
              [[1054]],
              [[1055]],
              [[1056]],
              [[1057]],
              [[1058]],
              [[1059]],
              [[1060]],
              [[1061]],
              [[1062]],
              [[1063]],
              [[1064]],
              [[1065]],
              [[1066]],
              [[1067]],
              [[1068]],
              [[1069]],
              [[1070]],
              [[1071]],
              [[1072]],
              [[1073]],
              [[1074]],
              [[1075]],
              [[1076]],
              [[-907, 1077]],
              [[1078]],
              [[1079]],
              [[1080]],
              [[-330, 1081, -333, 1082]],
              [[1083]],
              [[1084]],
              [[1085]],
              [[-721, 1086]],
              [[1087]],
              [[1088]],
              [[1089]],
              [[1090]],
              [[1091]],
              [[1092]],
              [[1093]],
              [[1094]],
              [[1095]],
              [[1096]],
              [[1097]],
              [[1098]],
              [[1099]],
              [[1100]],
              [[1101]],
              [[1102]],
              [[1103]],
              [[1104]],
              [[1105]],
              [[1106]],
              [[1107]],
              [[1108]],
              [[1109]],
              [[1110]],
              [[1111]],
              [[1112]],
              [[1113]],
              [[1114]],
              [[1115]],
              [[1116]],
              [[1117]],
              [[1118]],
              [[1119]],
              [[1120]],
              [[1121]],
              [[1122]],
              [[1123]],
              [[1124]],
              [[1125]],
              [[1126]],
              [[1127]],
              [[1128]],
              [[1129]],
              [[1130]],
              [[1131]],
              [[1132]],
              [[1133]],
              [[1134]],
              [[1135]],
              [[1136]],
              [[1137]],
              [[1138]],
              [[1139]],
              [[1140]],
              [[1141]],
              [[1142]],
              [[1143]],
              [[1144]],
              [[1145]],
              [[1146]],
              [[1147]],
              [[1148]],
              [[1149]],
              [[1150]],
              [[1151]],
              [[1152]],
              [[-918, 1153]],
              [[1154]],
              [[1155]],
              [[1156]],
              [[1157]],
              [[1158]],
              [[1159]],
              [[1160]],
              [[1161]],
              [[1162]],
              [[1163]],
              [[1164]],
              [[1165]],
              [[1166]],
              [[1167]],
              [[1168]],
              [[1169]],
              [[1170]],
              [[1171]],
              [[1172]],
              [[1173]],
              [[1174]],
              [[1175]],
              [[1176]],
              [[1177]],
              [[1178]],
              [[1179]],
              [[1180]],
              [[1181]],
              [[1182]],
              [[1183]],
            ],
            id: '360',
            properties: {
              name: 'Indonesia',
              alpha2: 'ID',
              alpha3: 'IDN',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[-757, 1184, 1185, -850, 1186, 1187, 1188, 1189, 1190, 1191]],
              [[1192]],
              [[1193]],
              [[1194]],
              [[1195]],
              [[1196]],
              [[1197]],
              [[1198]],
              [[1199]],
              [[1200]],
              [[1201]],
              [[1202]],
              [[1203]],
              [[1204]],
            ],
            id: '356',
            properties: {
              name: 'India',
              alpha2: 'IN',
              alpha3: 'IND',
            },
          },
          {
            type: 'Polygon',
            arcs: [[1205]],
            id: '352',
            properties: {
              name: 'Iceland',
              alpha2: 'IS',
              alpha3: 'ISL',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-288, -643, -490, 1206, -477, 1207, -469]],
            id: '348',
            properties: {
              name: 'Hungary',
              alpha2: 'HU',
              alpha3: 'HUN',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-817, 1208, 1209, 1210, 1211]], [[1212]], [[1213]]],
            id: '340',
            properties: {
              name: 'Honduras',
              alpha2: 'HN',
              alpha3: 'HND',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[1214, 1215]], [[1216]], [[1217]]],
            id: '332',
            properties: {
              name: 'Haiti',
              alpha2: 'HT',
              alpha3: 'HTI',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-33, 1218, -388, 1219]],
            id: '328',
            properties: {
              name: 'Guyana',
              alpha2: 'GY',
              alpha3: 'GUY',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[-494, 1220, 1221]],
              [[1222]],
              [[1223]],
              [[1224]],
              [[1225]],
              [[1226]],
              [[1227]],
            ],
            id: '624',
            properties: {
              name: 'Guinea-Bissau',
              alpha2: 'GW',
              alpha3: 'GNB',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-481, 1228, -1221, -493, -903, 1229, -939]],
            id: '324',
            properties: {
              name: 'Guinea',
              alpha2: 'GN',
              alpha3: 'GIN',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-876, 1230, 1231, -1211, 1232, 1233]],
            id: '320',
            properties: {
              name: 'Guatemala',
              alpha2: 'GT',
              alpha3: 'GTM',
            },
          },
          {
            type: 'Polygon',
            arcs: [[1234]],
            id: '308',
            properties: {
              name: 'Grenada',
              alpha2: 'GD',
              alpha3: 'GRD',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1235]],
              [[1236]],
              [[1237]],
              [[1238]],
              [[1239]],
              [[1240]],
              [[1241]],
              [[1242]],
              [[1243]],
              [[1244]],
              [[1245]],
              [[1246]],
              [[1247]],
              [[1248]],
              [[1249]],
              [[1250]],
              [[1251]],
              [[1252]],
              [[1253]],
              [[1254]],
              [[1255]],
              [[1256]],
              [[1257]],
              [[1258]],
              [[1259]],
              [[1260]],
              [[1261]],
              [[1262]],
              [[1263]],
              [[1264]],
              [[1265]],
              [[1266]],
              [[1267]],
              [[1268]],
              [[1269]],
              [[1270]],
              [[1271]],
              [[1272]],
              [[1273]],
              [[-313, 1274, 1275, -924, 1276]],
            ],
            id: '300',
            properties: {
              name: 'Greece',
              alpha2: 'GR',
              alpha3: 'GRC',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-327, 1277, 1278, 1279, 1280, 1281]],
            id: '288',
            properties: {
              name: 'Ghana',
              alpha2: 'GH',
              alpha3: 'GHA',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [
                [
                  -375, 1282, -927, 1283, -835, 1284, 1285, 1286, -656, 1287,
                  1288,
                ],
              ],
              [[1289]],
              [[-658, 1290]],
              [[1291]],
              [[1292]],
              [[1293]],
            ],
            id: '276',
            properties: {
              name: 'Germany',
              alpha2: 'DE',
              alpha3: 'DEU',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-305, 1294, -547, 1295, 1296]],
            id: '268',
            properties: {
              name: 'Georgia',
              alpha2: 'GE',
              alpha3: 'GEO',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-496, 1297]],
            id: '270',
            properties: {
              name: 'Gambia',
              alpha2: 'GM',
              alpha3: 'GMB',
            },
          },
          {
            type: 'Polygon',
            arcs: [[1298, 1299, 1300, 1301]],
            id: '266',
            properties: {
              name: 'Gabon',
              alpha2: 'GA',
              alpha3: 'GAB',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1302]],
              [
                [
                  -374, -1025, 1303, -873, 1304, -411, 1305, -409, 1306, 1307,
                  -928, -1283,
                ],
              ],
              [[1308]],
              [[1309]],
              [[1310]],
              [[1311]],
              [[1312]],
              [[1313]],
              [[1314]],
              [[-386, 1315, 1316]],
            ],
            id: '250',
            properties: {
              name: 'France',
              alpha2: 'FR',
              alpha3: 'FRA',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[1317]], [[1318]]],
            id: '666',
            properties: {
              name: 'St. Pierre and Miquelon',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[1319]], [[1320]]],
            id: '876',
            properties: {
              name: 'Wallis and Futuna',
              alpha2: 'WF',
              alpha3: 'WLF',
            },
          },
          {
            type: 'Polygon',
            arcs: [[1321, 1322]],
            id: '663',
            properties: {
              name: 'St-Martin',
            },
          },
          {
            type: 'Polygon',
            arcs: [[1323]],
            id: '652',
            properties: {
              name: 'St-Barthélemy',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1324]],
              [[1325]],
              [[1326]],
              [[1327]],
              [[1328]],
              [[1329]],
              [[1330]],
              [[1331]],
              [[1332]],
              [[1333]],
              [[1334]],
              [[1335]],
              [[1336]],
              [[1337]],
              [[1338]],
              [[1339]],
              [[1340]],
              [[1341]],
              [[1342]],
              [[1343]],
              [[1344]],
            ],
            id: '258',
            properties: {
              name: 'French Polynesia',
              alpha2: 'PF',
              alpha3: 'PYF',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[1345]], [[1346]], [[1347]], [[1348]], [[1349]], [[1350]]],
            id: '540',
            properties: {
              name: 'New Caledonia',
              alpha2: 'NC',
              alpha3: 'NCL',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[1351]], [[1352]], [[1353]]],
            id: '260',
            properties: {
              name: 'Fr. S. Antarctic Lands',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[1354]], [[1355]], [[1356]]],
            id: '248',
            properties: {
              name: 'Åland',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[-379, -766, -553, 1357]],
              [[1358]],
              [[1359]],
              [[1360]],
              [[1361]],
              [[1362]],
              [[1363]],
              [[1364]],
            ],
            id: '246',
            properties: {
              name: 'Finland',
              alpha2: 'FI',
              alpha3: 'FIN',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1365]],
              [[1366]],
              [[1367]],
              [[1368]],
              [[1369]],
              [[1370]],
              [[1371]],
              [[1372]],
              [[1373]],
              [[1374]],
              [[1375]],
              [[1376]],
              [[1377]],
              [[1378]],
              [[1379]],
              [[1380]],
              [[1381]],
              [[1382]],
              [[1383]],
              [[1384]],
            ],
            id: '242',
            properties: {
              name: 'Fiji',
              alpha2: 'FJ',
              alpha3: 'FJI',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-393, -401, 1385, 1386, -445, -442, -976]],
            id: '231',
            properties: {
              name: 'Ethiopia',
              alpha2: 'ET',
              alpha3: 'ETH',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-945, 1387, -551]], [[1388]], [[1389]], [[1390]]],
            id: '233',
            properties: {
              name: 'Estonia',
              alpha2: 'EE',
              alpha3: 'EST',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-400, 1391, 1392, -1386]], [[1393]], [[1394]]],
            id: '232',
            properties: {
              name: 'Eritrea',
              alpha2: 'ER',
              alpha3: 'ERI',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[1395]], [[-1301, 1396, 1397]]],
            id: '226',
            properties: {
              name: 'Eq. Guinea',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-1210, 1398, -1233]],
            id: '222',
            properties: {
              name: 'El Salvador',
              alpha2: 'SV',
              alpha3: 'SLV',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-398, -936, 1399, -1038, -1035, 1400]],
            id: '818',
            properties: {
              name: 'Egypt',
              alpha2: 'EG',
              alpha3: 'EGY',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[-714, 1401, 1402]],
              [[1403]],
              [[1404]],
              [[1405]],
              [[1406]],
              [[1407]],
              [[1408]],
              [[1409]],
              [[1410]],
            ],
            id: '218',
            properties: {
              name: 'Ecuador',
              alpha2: 'EC',
              alpha3: 'ECU',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-1215, 1411]],
            id: '214',
            properties: {
              name: 'Dominican Republic',
              alpha2: 'DO',
              alpha3: 'DOM',
            },
          },
          {
            type: 'Polygon',
            arcs: [[1412]],
            id: '212',
            properties: {
              name: 'Dominica',
              alpha2: 'DM',
              alpha3: 'DMA',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-446, -1387, -1393, 1413]],
            id: '262',
            properties: {
              name: 'Djibouti',
              alpha2: 'DJ',
              alpha3: 'DJI',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1414]],
              [[1415]],
              [[1416]],
              [[1417]],
              [[1418]],
              [[1419]],
              [[1420]],
              [[1421]],
              [[1422]],
              [[1423]],
              [[1424]],
              [[1425]],
              [[1426]],
              [[1427]],
              [[1428]],
              [[1429]],
              [[1430]],
            ],
            id: '304',
            properties: {
              name: 'Greenland',
              alpha2: 'GL',
              alpha3: 'GRL',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[1431]], [[1432]], [[1433]], [[1434]], [[1435]]],
            id: '234',
            properties: {
              name: 'Faeroe Is.',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1436]],
              [[-1286, 1437]],
              [[1438]],
              [[1439]],
              [[1440]],
              [[1441]],
              [[1442]],
              [[1443]],
              [[1444]],
              [[1445]],
              [[1446]],
              [[1447]],
            ],
            id: '208',
            properties: {
              name: 'Denmark',
              alpha2: 'DK',
              alpha3: 'DNK',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-471, 1448, -1288, -655]],
            id: '203',
            properties: {
              name: 'Czech Republic',
              alpha2: 'CZ',
              alpha3: 'CZE',
            },
          },
          {
            type: 'Polygon',
            arcs: [[1449, 1450]],
            properties: {
              name: 'N. Cyprus',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-1450, 1451]],
            id: '196',
            properties: {
              name: 'Cyprus',
              alpha2: 'CY',
              alpha3: 'CYP',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1452]],
              [[1453]],
              [[1454]],
              [[1455]],
              [[1456]],
              [[1457]],
              [[1458]],
            ],
            id: '192',
            properties: {
              name: 'Cuba',
              alpha2: 'CU',
              alpha3: 'CUB',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[-473, -1207, -489, 1459, 1460]],
              [[1461]],
              [[1462]],
              [[1463]],
              [[1464]],
              [[1465]],
              [[1466]],
              [[1467]],
              [[1468]],
              [[1469]],
              [[1470]],
              [[1471]],
              [[-869, 1472, 1473]],
            ],
            id: '191',
            properties: {
              name: 'Croatia',
              alpha2: 'HR',
              alpha3: 'HRV',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-1279, 1474]], [[-902, 1475, -1281, 1476, -940, -1230]]],
            id: '384',
            properties: {
              name: "Côte d'Ivoire",
              alpha2: 'CI',
              alpha3: 'CIV',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-748, 1477, -815, 1478]],
            id: '188',
            properties: {
              name: 'Costa Rica',
              alpha2: 'CR',
              alpha3: 'CRI',
            },
          },
          {
            type: 'Polygon',
            arcs: [
              [-7, 1479, 1480, 1481, 1482, 1483, -390, -296, -522, 1484, -351],
            ],
            id: '180',
            properties: {
              name: 'Congo, the Democratic Republic of the',
              alpha2: 'CD',
              alpha3: 'COD',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-1299, 1485, 1486, -1483, 1487, 1488]],
            id: '178',
            properties: {
              name: 'Congo',
              alpha2: 'CG',
              alpha3: 'COG',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[1489]], [[1490]], [[1491]]],
            id: '174',
            properties: {
              name: 'Comoros',
              alpha2: 'KM',
              alpha3: 'COM',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-35, 1492, -715, -1403, 1493, -746, 1494]], [[1495]]],
            id: '170',
            properties: {
              name: 'Colombia',
              alpha2: 'CO',
              alpha3: 'COL',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1496]],
              [[1497]],
              [[1498]],
              [[1499]],
              [[1500]],
              [[1501]],
              [[1502]],
              [[1503]],
              [[1504]],
              [[1505]],
              [[1506]],
              [
                [
                  -22, -949, 1507, -1189, 1508, -1187, -851, -1186, 1509, -761,
                  1510, -360, -951, -982, -543, -871, -541, -799, 1511, 1512,
                  1513, 1514, 1515,
                ],
              ],
              [[1516]],
            ],
            id: '156',
            properties: {
              name: 'China',
              alpha2: 'CN',
              alpha3: 'CHN',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-1515, 1517]],
            id: '446',
            properties: {
              name: 'Macao',
              alpha2: 'MO',
              alpha3: 'MAC',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-1513, 1518]], [[1519]], [[1520]]],
            id: '344',
            properties: {
              name: 'Hong Kong',
              alpha2: 'HK',
              alpha3: 'HKG',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1521]],
              [[1522]],
              [[-712, 1523, 1524, 1525]],
              [[1526, 1527]],
              [[1528]],
              [[1529]],
              [[1530]],
              [[1531]],
              [[1532]],
              [[1533]],
              [[1534]],
              [[1535]],
              [[1536]],
              [[1537]],
              [[1538]],
              [[1539]],
              [[1540]],
              [[1541]],
              [[1542]],
              [[1543]],
              [[1544]],
              [[1545]],
              [[1546]],
              [[1547]],
              [[1548]],
              [[1549]],
              [[1550]],
              [[1551]],
              [[1552]],
              [[1553]],
              [[1554]],
            ],
            id: '152',
            properties: {
              name: 'Chile',
              alpha2: 'CL',
              alpha3: 'CHL',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-396, 1555, 1556, -803, -813, -937]],
            id: '148',
            properties: {
              name: 'Chad',
              alpha2: 'TD',
              alpha3: 'TCD',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-391, -1484, -1487, 1557, -1556, -395]],
            id: '140',
            properties: {
              name: 'Central African Republic',
              alpha2: 'CF',
              alpha3: 'CAF',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1558]],
              [[1559]],
              [[1560]],
              [[1561]],
              [[1562]],
              [[1563]],
              [[1564]],
              [[1565]],
            ],
            id: '132',
            properties: {
              name: 'Cabo Verde',
              alpha2: 'CV',
              alpha3: 'CPV',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1566]],
              [[1567]],
              [[1568]],
              [[-106, 1569, -169, 1570]],
              [[1571]],
              [[1572]],
              [[1573]],
              [[1574]],
              [[1575]],
              [[1576]],
              [[1577]],
              [[1578]],
              [[1579]],
              [[1580]],
              [[1581]],
              [[1582]],
              [[1583]],
              [[1584]],
              [[1585]],
              [[1586]],
              [[1587]],
              [[1588]],
              [[1589]],
              [[-165, 1590]],
              [[1591]],
              [[1592]],
              [[1593]],
              [[1594]],
              [[1595]],
              [[1596]],
              [[1597]],
              [[1598]],
              [[1599]],
              [[1600]],
              [[1601]],
              [[1602]],
              [[1603]],
              [[1604]],
              [[1605]],
              [[1606]],
              [[1607]],
              [[1608]],
              [[1609]],
              [[1610]],
              [[1611]],
              [[1612]],
              [[1613]],
              [[1614]],
              [[-104, 1615]],
              [[1616]],
              [[1617]],
              [[1618]],
              [[1619]],
              [[1620]],
              [[1621]],
              [[1622]],
              [[1623]],
              [[1624]],
              [[1625]],
              [[1626]],
              [[1627]],
              [[1628]],
              [[1629]],
              [[1630]],
              [[1631]],
              [[1632]],
              [[1633]],
              [[1634]],
              [[1635]],
              [[1636]],
              [[1637]],
              [[1638]],
              [[1639]],
              [[1640]],
              [[1641]],
              [[1642]],
              [[1643]],
              [[1644]],
              [[1645]],
              [[1646]],
              [[1647]],
              [[1648]],
              [[1649]],
              [[1650]],
              [[1651]],
              [[1652]],
              [[1653]],
              [[1654]],
              [[1655]],
              [[1656]],
              [[1657]],
              [[1658]],
              [[1659]],
              [[1660]],
              [[1661]],
              [[1662]],
              [[1663]],
              [[1664]],
              [[1665]],
              [[1666]],
              [[1667]],
              [[1668]],
              [[1669]],
              [[1670]],
              [[1671]],
              [[1672]],
              [[1673]],
              [[1674]],
              [[1675]],
              [[1676]],
              [[1677]],
              [[1678]],
              [[1679]],
              [[1680]],
              [[1681]],
              [[1682]],
              [[1683]],
              [[1684]],
              [[1685]],
              [[1686]],
              [[1687]],
              [[1688]],
              [[1689]],
              [[1690]],
              [[1691]],
              [[1692]],
              [[1693]],
              [[1694]],
              [[1695]],
              [[1696]],
              [[1697]],
              [[1698]],
              [[1699]],
              [[1700]],
              [[1701]],
              [[1702]],
              [[1703]],
              [[1704]],
              [[1705]],
              [[1706]],
              [[1707]],
            ],
            id: '124',
            properties: {
              name: 'Canada',
              alpha2: 'CA',
              alpha3: 'CAN',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-804, -1557, -1558, -1486, -1302, -1398, 1708]],
            id: '120',
            properties: {
              name: 'Cameroon',
              alpha2: 'CM',
              alpha3: 'CMR',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[1709]], [[1710]], [[-20, 1711, -338, -947]]],
            id: '116',
            properties: {
              name: 'Cambodia',
              alpha2: 'KH',
              alpha3: 'KHM',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[-342, 1712, 1713, -1190, -1508, -948]],
              [[1714]],
              [[1715]],
              [[1716]],
              [[1717]],
              [[1718]],
              [[1719]],
              [[1720]],
              [[1721]],
              [[1722]],
              [[1723]],
              [[1724]],
              [[1725]],
              [[1726]],
              [[1727]],
              [[1728]],
              [[1729]],
              [[1730]],
              [[1731]],
            ],
            id: '104',
            properties: {
              name: 'Myanmar',
              alpha2: 'MM',
              alpha3: 'MMR',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-352, -1485, -521]],
            id: '108',
            properties: {
              name: 'Burundi',
              alpha2: 'BI',
              alpha3: 'BDI',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-328, -1282, -1476, -901, -809, 1732]],
            id: '854',
            properties: {
              name: 'Burkina Faso',
              alpha2: 'BF',
              alpha3: 'BFA',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-314, -1277, -923, -484, -642, 1733]],
            id: '100',
            properties: {
              name: 'Bulgaria',
              alpha2: 'BG',
              alpha3: 'BGR',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-910, 1734]], [[-909, 1735]]],
            id: '096',
            properties: {
              name: 'Brunei',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [
                [
                  -34, -1220, -387, -1317, 1736, -62, 1737, -716, 1738, -710,
                  -1493,
                ],
              ],
              [[1739]],
              [[1740]],
              [[1741]],
              [[1742]],
              [[1743]],
              [[1744]],
              [[1745]],
              [[1746]],
              [[1747]],
              [[1748]],
              [[1749]],
              [[1750]],
              [[1751]],
              [[1752]],
              [[1753]],
              [[1754]],
            ],
            id: '076',
            properties: {
              name: 'Brazil',
              alpha2: 'BR',
              alpha3: 'BRA',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-2, -438, -853]],
            id: '072',
            properties: {
              name: 'Botswana',
              alpha2: 'BW',
              alpha3: 'BWA',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-870, -1474, 1755, -1460, -488]],
            id: '070',
            properties: {
              name: 'Bosnia and Herzegovina',
              alpha2: 'BA',
              alpha3: 'BIH',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-711, -1739, -718, 1756, -1524]],
            id: '068',
            properties: {
              name: 'Bolivia, Plurinational State of',
              alpha2: 'BO',
              alpha3: 'BOL',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-1188, -1509]],
            id: '064',
            properties: {
              name: 'Bhutan',
              alpha2: 'BT',
              alpha3: 'BTN',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-325, -1733, -808, -806, 1757]],
            id: '204',
            properties: {
              name: 'Benin',
              alpha2: 'BJ',
              alpha3: 'BEN',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-875, 1758, -1231]], [[1759]], [[1760]]],
            id: '084',
            properties: {
              name: 'Belize',
              alpha2: 'BZ',
              alpha3: 'BLZ',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-833, -1284, -929, -1308, 1761, -836]],
            id: '056',
            properties: {
              name: 'Belgium',
              alpha2: 'BE',
              alpha3: 'BEL',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-291, -661, -933, -946, -549]],
            id: '112',
            properties: {
              name: 'Belarus',
              alpha2: 'BY',
              alpha3: 'BLR',
            },
          },
          {
            type: 'Polygon',
            arcs: [[1762]],
            id: '052',
            properties: {
              name: 'Barbados',
              alpha2: 'BB',
              alpha3: 'BRB',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[-1191, -1714, 1763]],
              [[1764]],
              [[1765]],
              [[1766]],
              [[1767]],
              [[1768]],
              [[1769]],
            ],
            id: '050',
            properties: {
              name: 'Bangladesh',
              alpha2: 'BD',
              alpha3: 'BGD',
            },
          },
          {
            type: 'Polygon',
            arcs: [[1770]],
            id: '048',
            properties: {
              name: 'Bahrain',
              alpha2: 'BH',
              alpha3: 'BHR',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1771]],
              [[1772]],
              [[1773]],
              [[1774]],
              [[1775]],
              [[1776]],
              [[1777]],
              [[1778]],
              [[1779]],
              [[1780]],
              [[1781]],
              [[1782]],
              [[1783]],
              [[1784]],
              [[1785]],
            ],
            id: '044',
            properties: {
              name: 'Bahamas',
              alpha2: 'BS',
              alpha3: 'BHS',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[-307, 1786, -1047]],
              [[-1049, 1787, -1296, -546, 1788], [1789]],
              [[1790]],
            ],
            id: '031',
            properties: {
              name: 'Azerbaijan',
              alpha2: 'AZ',
              alpha3: 'AZE',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-370, -1289, -1449, -470, -1208, -476, -1023, -372, -934]],
            id: '040',
            properties: {
              name: 'Austria',
              alpha2: 'AT',
              alpha3: 'AUT',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1791]],
              [[1792]],
              [[1793]],
              [[1794]],
              [[1795]],
              [[1796]],
              [[1797]],
              [[1798]],
              [[1799]],
              [[1800]],
              [[1801]],
              [[1802]],
              [[1803]],
              [[1804]],
              [[1805]],
              [[1806]],
              [[1807]],
              [[1808]],
              [[1809]],
              [[1810]],
              [[1811]],
              [[1812]],
              [[1813]],
              [[1814]],
              [[1815]],
              [[1816]],
              [[1817]],
              [[1818]],
              [[1819]],
              [[1820]],
              [[1821]],
              [[1822]],
              [[1823]],
              [[1824]],
              [[1825]],
              [[1826]],
              [[1827]],
              [[1828]],
              [[1829]],
              [[1830]],
              [[1831]],
              [[1832]],
            ],
            id: '036',
            properties: {
              name: 'Australia',
              alpha2: 'AU',
              alpha3: 'AUS',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[1833]], [[1834]], [[1835]]],
            properties: {
              name: 'Indian Ocean Ter.',
            },
          },
          {
            type: 'Polygon',
            arcs: [[1836]],
            id: '334',
            properties: {
              name: 'Heard I. and McDonald Is.',
            },
          },
          {
            type: 'Polygon',
            arcs: [[1837]],
            id: '574',
            properties: {
              name: 'Norfolk Island',
              alpha2: 'NF',
              alpha3: 'NFK',
            },
          },
          {
            type: 'Polygon',
            arcs: [[1838]],
            id: '036',
            properties: {
              name: 'Ashmore and Cartier Is.',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-306, -1297, -1788, -1048, -1787], [-1791]], [[-1790]]],
            id: '051',
            properties: {
              name: 'Armenia',
              alpha2: 'AM',
              alpha3: 'ARM',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[-61, 1839, -1525, -1757, -717, -1738]],
              [[-1527, 1840]],
              [[1841]],
              [[1842]],
            ],
            id: '032',
            properties: {
              name: 'Argentina',
              alpha2: 'AR',
              alpha3: 'ARG',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[1843]], [[1844]]],
            id: '028',
            properties: {
              name: 'Antigua and Barbuda',
              alpha2: 'AG',
              alpha3: 'ATG',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [[[-1482, 1845, -1488]], [[-6, -855, 1846, -1480]]],
            id: '024',
            properties: {
              name: 'Angola',
              alpha2: 'AO',
              alpha3: 'AGO',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-410, -1306]],
            id: '020',
            properties: {
              name: 'Andorra',
              alpha2: 'AD',
              alpha3: 'AND',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-316, -938, -811, -900, -896, -863, -860, 1847]],
            id: '012',
            properties: {
              name: 'Algeria',
              alpha2: 'DZ',
              alpha3: 'DZA',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-867, -955, -925, -1276, 1848]],
            id: '008',
            properties: {
              name: 'Albania',
              alpha2: 'AL',
              alpha3: 'ALB',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-54, -361, -1511, -760, -1045, -300]],
            id: '004',
            properties: {
              name: 'Afghanistan',
              alpha2: 'AF',
              alpha3: 'AFG',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-756, -1510, -1185]],
            properties: {
              name: 'Siachen Glacier',
            },
          },
          {
            type: 'MultiPolygon',
            arcs: [
              [[1849]],
              [[1850]],
              [[1851], [1852]],
              [[1853]],
              [[1854]],
              [[1855]],
              [[1856]],
              [[1857]],
              [[1858]],
              [[1859]],
              [[1860]],
              [[1861]],
              [[1862]],
              [[1863]],
              [[1864]],
              [[1865]],
              [[1866]],
              [[1867]],
              [[1868]],
              [[1869]],
              [[1870]],
              [[1871]],
              [[1872]],
              [[1873]],
              [[1874]],
              [[1875]],
              [[1876]],
              [[1877]],
              [[1878]],
              [[1879]],
              [[1880]],
              [[1881]],
              [[1882]],
              [[1883]],
              [[1884]],
              [[1885]],
              [[1886]],
              [[1887]],
              [[1888]],
              [[1889]],
              [[1890]],
              [[1891]],
              [[1892]],
              [[1893]],
              [[1894]],
              [[1895]],
              [[1896]],
              [[1897]],
              [[1898]],
              [[1899]],
              [[1900]],
              [[1901]],
              [[1902]],
              [[1903]],
              [[1904]],
              [[1905]],
              [[1906]],
              [[1907]],
              [[1908]],
              [[1909]],
              [[1910]],
              [[1911]],
              [[1912]],
              [[1913]],
              [[1914]],
              [[1915]],
              [[1916]],
              [[1917]],
              [[1918]],
              [[1919]],
              [[1920]],
              [[1921]],
              [[1922]],
              [[1923]],
              [[1924]],
              [[1925]],
              [[1926]],
              [[1927]],
              [[1928]],
              [[1929]],
              [[1930]],
              [[1931]],
              [[1932]],
              [[1933]],
              [[1934]],
              [[1935]],
              [[1936]],
              [[1937]],
              [[1938]],
              [[1939]],
              [[1940]],
              [[1941]],
              [[1942]],
              [[1943]],
              [[1944]],
              [[1945]],
              [[1946]],
              [[1947]],
              [[1948]],
              [[1949]],
              [[1950]],
              [[1951]],
              [[1952]],
              [[1953]],
              [[1954]],
              [[1955]],
              [[1956]],
              [[1957]],
            ],
            id: '010',
            properties: {
              name: 'Antarctica',
              alpha2: 'AQ',
              alpha3: 'ATA',
            },
          },
          {
            type: 'Polygon',
            arcs: [[-1322, 1958]],
            id: '534',
            properties: {
              name: 'Sint Maarten',
            },
          },
        ],
      },
      land: {
        type: 'GeometryCollection',
        geometries: [
          {
            type: 'MultiPolygon',
            arcs: [
              [
                [
                  853, 1846, 1480, 1845, 1488, 1299, 1396, 1708, 804, 1757, 325,
                  1277, 1474, 1279, 1476, 940, 479, 1228, 1221, 494, 1297, 496,
                  894, 864, 861, 1847, 316, 934, 1399, 1038, 1036, 942, 368,
                  310, 1294, 547, 281, 639, 283, 640, 1733, 311, 1274, 1848,
                  867, 1472, 1755, 1460, 473, 1023, 1303, 871, 1304, 411, 646,
                  413, 1306, 1761, 836, 833, 1284, 1437, 1286, 656, 1290, 658,
                  637, 929, 634, 930, 943, 1387, 551, 1357, 379, 764, 554, 799,
                  422, 797, 1511, 1518, 1513, 1517, 1515, 18, 1711, 338, 905,
                  340, 1712, 1763, 1191, 757, 1045, 1042, 953, 504, 644, 506,
                  274, 763, 271, 762, 10, 500, 983, 1033, 1400, 398, 1391, 1413,
                  446, 443, 976, 354, 856, 435,
                ],
                [980, 544, 1788, 1049, 301],
              ],
              [[13]],
              [[14]],
              [[15]],
              [[16]],
              [[17]],
              [[22]],
              [[23]],
              [[24]],
              [[25]],
              [[26]],
              [[27]],
              [[28]],
              [[29]],
              [[30]],
              [[31]],
              [
                [
                  388, 1315, 1736, 59, 1839, 1525, 712, 1401, 1493, 746, 1477,
                  815, 1208, 1398, 1233, 876, 167, 1570, 102, 1615, 104, 1569,
                  1590, 165, 873, 1758, 1231, 1211, 813, 1478, 748, 1494, 35,
                  1218,
                ],
              ],
              [[37]],
              [[38]],
              [[39]],
              [[40]],
              [[41]],
              [[42]],
              [[43]],
              [[44]],
              [[45]],
              [[46]],
              [[47]],
              [[48]],
              [[49]],
              [[50]],
              [[62]],
              [[63]],
              [[64]],
              [[65]],
              [[66]],
              [[67]],
              [[68]],
              [[69]],
              [[70]],
              [[71]],
              [[72]],
              [[73]],
              [[74]],
              [[75]],
              [[76]],
              [[77]],
              [[78]],
              [[79]],
              [[80]],
              [[81]],
              [[82]],
              [[83]],
              [[84]],
              [[85]],
              [[86]],
              [[87]],
              [[88]],
              [[89]],
              [[90]],
              [[91]],
              [[92]],
              [[93]],
              [[94]],
              [[95]],
              [[96]],
              [[97]],
              [[98]],
              [[99]],
              [[100]],
              [[101]],
              [[106]],
              [[107]],
              [[108]],
              [[109]],
              [[110]],
              [[111]],
              [[112]],
              [[113]],
              [[114]],
              [[115]],
              [[116]],
              [[117]],
              [[118]],
              [[119]],
              [[120]],
              [[121]],
              [[122]],
              [[123]],
              [[124]],
              [[125]],
              [[126]],
              [[127]],
              [[128]],
              [[129]],
              [[130]],
              [[131]],
              [[132]],
              [[133]],
              [[134]],
              [[135]],
              [[136]],
              [[137]],
              [[138]],
              [[139]],
              [[140]],
              [[141]],
              [[142]],
              [[143]],
              [[144]],
              [[145]],
              [[146]],
              [[147]],
              [[148]],
              [[149]],
              [[150]],
              [[151]],
              [[152]],
              [[153]],
              [[154]],
              [[155]],
              [[156]],
              [[157]],
              [[158]],
              [[159]],
              [[160]],
              [[161]],
              [[162]],
              [[163]],
              [[169]],
              [[170]],
              [[171]],
              [[172]],
              [[173]],
              [[174]],
              [[175]],
              [[176]],
              [[177]],
              [[178]],
              [[179]],
              [[180]],
              [[181]],
              [[182]],
              [[183]],
              [[184]],
              [[185]],
              [[186]],
              [[187]],
              [[188]],
              [[189]],
              [[190]],
              [[191]],
              [[192]],
              [[193]],
              [[194]],
              [[195]],
              [[196]],
              [[197]],
              [[198]],
              [[199]],
              [[200]],
              [[201]],
              [[202]],
              [[203]],
              [[204]],
              [[205]],
              [[206]],
              [[207]],
              [[208]],
              [[209]],
              [[210]],
              [[211]],
              [[212]],
              [[213]],
              [[214]],
              [[215]],
              [[216]],
              [[217]],
              [[218]],
              [[219]],
              [[220]],
              [[221]],
              [[222]],
              [[223]],
              [[224]],
              [[225]],
              [[226]],
              [[227]],
              [[228]],
              [[229]],
              [[230]],
              [[231]],
              [[232]],
              [[233]],
              [[234]],
              [[235]],
              [[236]],
              [[237]],
              [[238]],
              [[239]],
              [[240]],
              [[241]],
              [[242]],
              [[243]],
              [[244]],
              [[245]],
              [[246]],
              [[247]],
              [[248]],
              [[249]],
              [[250]],
              [[251]],
              [[252]],
              [[253]],
              [[254]],
              [[255]],
              [[256]],
              [[257]],
              [[258]],
              [[259]],
              [[260]],
              [[261]],
              [[262]],
              [[263]],
              [[264]],
              [[265]],
              [[266]],
              [[267]],
              [[269, 1040]],
              [[270]],
              [[277]],
              [[278]],
              [[279]],
              [[280]],
              [[292]],
              [[298]],
              [[303]],
              [[317]],
              [[318]],
              [[319]],
              [[320]],
              [[321]],
              [[322]],
              [[323]],
              [[328]],
              [[1082, 330, 1081, 331]],
              [[333]],
              [[334]],
              [[335]],
              [[342]],
              [[343]],
              [[344]],
              [[345]],
              [[346]],
              [[347]],
              [[348]],
              [[349]],
              [[357]],
              [[362]],
              [[363]],
              [[375]],
              [[376]],
              [[380]],
              [[381]],
              [[382]],
              [[401]],
              [[402]],
              [[403]],
              [[404]],
              [[405]],
              [[406]],
              [[407]],
              [[414]],
              [[415]],
              [[416]],
              [[417]],
              [[418]],
              [[419]],
              [[420]],
              [[423]],
              [[424]],
              [[425]],
              [[426]],
              [[427]],
              [[428]],
              [[429]],
              [[430]],
              [[431]],
              [[432]],
              [[439]],
              [[447]],
              [[448]],
              [[449]],
              [[450]],
              [[451]],
              [[452]],
              [[453]],
              [[454]],
              [[455]],
              [[456]],
              [[457]],
              [[458]],
              [[459]],
              [[460]],
              [[461]],
              [[462]],
              [[463]],
              [[464]],
              [[465]],
              [[466]],
              [[467]],
              [[477]],
              [[481]],
              [[482]],
              [[498]],
              [[499]],
              [[508]],
              [[509]],
              [[510]],
              [[512]],
              [[513]],
              [[514]],
              [[515]],
              [[516]],
              [[517]],
              [[518]],
              [[519]],
              [[522]],
              [[523]],
              [[524]],
              [[525]],
              [[526]],
              [[527]],
              [[528]],
              [[529]],
              [[530]],
              [[531]],
              [[532]],
              [[533]],
              [[534]],
              [[535]],
              [[536]],
              [[537]],
              [[538]],
              [[555]],
              [[556]],
              [[557]],
              [[558]],
              [[559]],
              [[560]],
              [[561]],
              [[562]],
              [[563]],
              [[564]],
              [[565]],
              [[566]],
              [[567]],
              [[568]],
              [[569]],
              [[570]],
              [[571]],
              [[572]],
              [[573]],
              [[574]],
              [[575]],
              [[576]],
              [[577]],
              [[578]],
              [[579]],
              [[580]],
              [[581]],
              [[582]],
              [[583]],
              [[584]],
              [[585]],
              [[586]],
              [[587]],
              [[588]],
              [[589]],
              [[590]],
              [[591]],
              [[592]],
              [[593]],
              [[594]],
              [[595]],
              [[596]],
              [[597]],
              [[598]],
              [[599]],
              [[600]],
              [[601]],
              [[602]],
              [[603]],
              [[604]],
              [[605]],
              [[606]],
              [[607]],
              [[608]],
              [[609]],
              [[610]],
              [[611]],
              [[612]],
              [[613]],
              [[614]],
              [[615]],
              [[616]],
              [[617]],
              [[618]],
              [[619]],
              [[620]],
              [[621]],
              [[622]],
              [[623]],
              [[624]],
              [[625]],
              [[626]],
              [[627]],
              [[628]],
              [[629]],
              [[630]],
              [[631]],
              [[632]],
              [[633]],
              [[645]],
              [[647]],
              [[648]],
              [[649]],
              [[650]],
              [[651]],
              [[652]],
              [[653]],
              [[661]],
              [[662]],
              [[663]],
              [[664]],
              [[665]],
              [[666]],
              [[667]],
              [[668]],
              [[669]],
              [[670]],
              [[671]],
              [[672]],
              [[673]],
              [[674]],
              [[675]],
              [[676]],
              [[677]],
              [[678]],
              [[679]],
              [[680]],
              [[681]],
              [[682]],
              [[683]],
              [[684]],
              [[685]],
              [[686]],
              [[687]],
              [[688]],
              [[689]],
              [[690]],
              [[691]],
              [[692]],
              [[693]],
              [[694]],
              [[695]],
              [[696]],
              [[697]],
              [[698]],
              [[699]],
              [[700]],
              [[701]],
              [[702]],
              [[703]],
              [[704]],
              [[705]],
              [[706]],
              [[707]],
              [[708]],
              [[718]],
              [[719]],
              [[721, 1086]],
              [[722]],
              [[723]],
              [[724]],
              [[725]],
              [[726]],
              [[727]],
              [[728]],
              [[729]],
              [[730]],
              [[731]],
              [[732]],
              [[733]],
              [[734]],
              [[735]],
              [[736]],
              [[737]],
              [[738]],
              [[739]],
              [[740]],
              [[741]],
              [[742]],
              [[743]],
              [[744]],
              [[749]],
              [[750]],
              [[751]],
              [[752]],
              [[753]],
              [[754]],
              [[761]],
              [[766]],
              [[767]],
              [[768]],
              [[769]],
              [[770]],
              [[771]],
              [[772]],
              [[773]],
              [[774]],
              [[775]],
              [[776]],
              [[777]],
              [[778]],
              [[779]],
              [[780]],
              [[781]],
              [[782]],
              [[783]],
              [[784]],
              [[785]],
              [[786]],
              [[787]],
              [[788]],
              [[789]],
              [[790]],
              [[791]],
              [[792]],
              [[793]],
              [[794]],
              [[795]],
              [[796]],
              [[800]],
              [[801]],
              [[817]],
              [[818]],
              [[819]],
              [[820]],
              [[821]],
              [[822]],
              [[823]],
              [[824]],
              [[825]],
              [[826]],
              [[827]],
              [[828]],
              [[829]],
              [[830]],
              [[831]],
              [[837]],
              [[838]],
              [[839]],
              [[840]],
              [[841]],
              [[842]],
              [[843]],
              [[844]],
              [[845]],
              [[846]],
              [[847]],
              [[848]],
              [[851]],
              [[877]],
              [[878]],
              [[879]],
              [[880]],
              [[881]],
              [[882]],
              [[883]],
              [[884]],
              [[885]],
              [[886]],
              [[887]],
              [[888]],
              [[889]],
              [[890]],
              [[891]],
              [[892]],
              [[893]],
              [[897]],
              [[898]],
              [[903]],
              [[904]],
              [[907, 1735, 1734, 910, 1077]],
              [[911]],
              [[912]],
              [[913]],
              [[914]],
              [[915]],
              [[916]],
              [[918, 1153]],
              [[919]],
              [[920]],
              [[921]],
              [[951]],
              [[955]],
              [[956]],
              [[957]],
              [[958]],
              [[959]],
              [[960]],
              [[961]],
              [[962]],
              [[963]],
              [[964]],
              [[965]],
              [[966]],
              [[967]],
              [[968]],
              [[969]],
              [[970]],
              [[971]],
              [[972]],
              [[973]],
              [[974]],
              [[977]],
              [[978]],
              [[979]],
              [[987]],
              [[988]],
              [[989]],
              [[990]],
              [[991]],
              [[992]],
              [[993]],
              [[994]],
              [[995]],
              [[996]],
              [[997]],
              [[998]],
              [[999]],
              [[1000]],
              [[1001]],
              [[1002]],
              [[1003]],
              [[1004]],
              [[1005]],
              [[1006]],
              [[1007]],
              [[1008]],
              [[1009]],
              [[1010]],
              [[1011]],
              [[1012]],
              [[1013]],
              [[1014]],
              [[1015]],
              [[1016]],
              [[1017]],
              [[1018]],
              [[1019]],
              [[1020]],
              [[1021]],
              [[1025]],
              [[1026]],
              [[1027]],
              [[1028]],
              [[1029]],
              [[1030]],
              [[1031]],
              [[1039]],
              [[1043]],
              [[1050]],
              [[1051]],
              [[1052]],
              [[1053]],
              [[1054]],
              [[1055]],
              [[1056]],
              [[1057]],
              [[1058]],
              [[1059]],
              [[1060]],
              [[1061]],
              [[1062]],
              [[1063]],
              [[1064]],
              [[1065]],
              [[1066]],
              [[1067]],
              [[1068]],
              [[1069]],
              [[1070]],
              [[1071]],
              [[1072]],
              [[1073]],
              [[1074]],
              [[1075]],
              [[1076]],
              [[1078]],
              [[1079]],
              [[1080]],
              [[1083]],
              [[1084]],
              [[1085]],
              [[1087]],
              [[1088]],
              [[1089]],
              [[1090]],
              [[1091]],
              [[1092]],
              [[1093]],
              [[1094]],
              [[1095]],
              [[1096]],
              [[1097]],
              [[1098]],
              [[1099]],
              [[1100]],
              [[1101]],
              [[1102]],
              [[1103]],
              [[1104]],
              [[1105]],
              [[1106]],
              [[1107]],
              [[1108]],
              [[1109]],
              [[1110]],
              [[1111]],
              [[1112]],
              [[1113]],
              [[1114]],
              [[1115]],
              [[1116]],
              [[1117]],
              [[1118]],
              [[1119]],
              [[1120]],
              [[1121]],
              [[1122]],
              [[1123]],
              [[1124]],
              [[1125]],
              [[1126]],
              [[1127]],
              [[1128]],
              [[1129]],
              [[1130]],
              [[1131]],
              [[1132]],
              [[1133]],
              [[1134]],
              [[1135]],
              [[1136]],
              [[1137]],
              [[1138]],
              [[1139]],
              [[1140]],
              [[1141]],
              [[1142]],
              [[1143]],
              [[1144]],
              [[1145]],
              [[1146]],
              [[1147]],
              [[1148]],
              [[1149]],
              [[1150]],
              [[1151]],
              [[1152]],
              [[1154]],
              [[1155]],
              [[1156]],
              [[1157]],
              [[1158]],
              [[1159]],
              [[1160]],
              [[1161]],
              [[1162]],
              [[1163]],
              [[1164]],
              [[1165]],
              [[1166]],
              [[1167]],
              [[1168]],
              [[1169]],
              [[1170]],
              [[1171]],
              [[1172]],
              [[1173]],
              [[1174]],
              [[1175]],
              [[1176]],
              [[1177]],
              [[1178]],
              [[1179]],
              [[1180]],
              [[1181]],
              [[1182]],
              [[1183]],
              [[1192]],
              [[1193]],
              [[1194]],
              [[1195]],
              [[1196]],
              [[1197]],
              [[1198]],
              [[1199]],
              [[1200]],
              [[1201]],
              [[1202]],
              [[1203]],
              [[1204]],
              [[1205]],
              [[1212]],
              [[1213]],
              [[1215, 1411]],
              [[1216]],
              [[1217]],
              [[1222]],
              [[1223]],
              [[1224]],
              [[1225]],
              [[1226]],
              [[1227]],
              [[1234]],
              [[1235]],
              [[1236]],
              [[1237]],
              [[1238]],
              [[1239]],
              [[1240]],
              [[1241]],
              [[1242]],
              [[1243]],
              [[1244]],
              [[1245]],
              [[1246]],
              [[1247]],
              [[1248]],
              [[1249]],
              [[1250]],
              [[1251]],
              [[1252]],
              [[1253]],
              [[1254]],
              [[1255]],
              [[1256]],
              [[1257]],
              [[1258]],
              [[1259]],
              [[1260]],
              [[1261]],
              [[1262]],
              [[1263]],
              [[1264]],
              [[1265]],
              [[1266]],
              [[1267]],
              [[1268]],
              [[1269]],
              [[1270]],
              [[1271]],
              [[1272]],
              [[1273]],
              [[1289]],
              [[1291]],
              [[1292]],
              [[1293]],
              [[1302]],
              [[1308]],
              [[1309]],
              [[1310]],
              [[1311]],
              [[1312]],
              [[1313]],
              [[1314]],
              [[1317]],
              [[1318]],
              [[1319]],
              [[1320]],
              [[1322, 1958]],
              [[1323]],
              [[1324]],
              [[1325]],
              [[1326]],
              [[1327]],
              [[1328]],
              [[1329]],
              [[1330]],
              [[1331]],
              [[1332]],
              [[1333]],
              [[1334]],
              [[1335]],
              [[1336]],
              [[1337]],
              [[1338]],
              [[1339]],
              [[1340]],
              [[1341]],
              [[1342]],
              [[1343]],
              [[1344]],
              [[1345]],
              [[1346]],
              [[1347]],
              [[1348]],
              [[1349]],
              [[1350]],
              [[1351]],
              [[1352]],
              [[1353]],
              [[1354]],
              [[1355]],
              [[1356]],
              [[1358]],
              [[1359]],
              [[1360]],
              [[1361]],
              [[1362]],
              [[1363]],
              [[1364]],
              [[1365]],
              [[1366]],
              [[1367]],
              [[1368]],
              [[1369]],
              [[1370]],
              [[1371]],
              [[1372]],
              [[1373]],
              [[1374]],
              [[1375]],
              [[1376]],
              [[1377]],
              [[1378]],
              [[1379]],
              [[1380]],
              [[1381]],
              [[1382]],
              [[1383]],
              [[1384]],
              [[1388]],
              [[1389]],
              [[1390]],
              [[1393]],
              [[1394]],
              [[1395]],
              [[1403]],
              [[1404]],
              [[1405]],
              [[1406]],
              [[1407]],
              [[1408]],
              [[1409]],
              [[1410]],
              [[1412]],
              [[1414]],
              [[1415]],
              [[1416]],
              [[1417]],
              [[1418]],
              [[1419]],
              [[1420]],
              [[1421]],
              [[1422]],
              [[1423]],
              [[1424]],
              [[1425]],
              [[1426]],
              [[1427]],
              [[1428]],
              [[1429]],
              [[1430]],
              [[1431]],
              [[1432]],
              [[1433]],
              [[1434]],
              [[1435]],
              [[1436]],
              [[1438]],
              [[1439]],
              [[1440]],
              [[1441]],
              [[1442]],
              [[1443]],
              [[1444]],
              [[1445]],
              [[1446]],
              [[1447]],
              [[1450, 1451]],
              [[1452]],
              [[1453]],
              [[1454]],
              [[1455]],
              [[1456]],
              [[1457]],
              [[1458]],
              [[1461]],
              [[1462]],
              [[1463]],
              [[1464]],
              [[1465]],
              [[1466]],
              [[1467]],
              [[1468]],
              [[1469]],
              [[1470]],
              [[1471]],
              [[1489]],
              [[1490]],
              [[1491]],
              [[1495]],
              [[1496]],
              [[1497]],
              [[1498]],
              [[1499]],
              [[1500]],
              [[1501]],
              [[1502]],
              [[1503]],
              [[1504]],
              [[1505]],
              [[1506]],
              [[1516]],
              [[1519]],
              [[1520]],
              [[1521]],
              [[1522]],
              [[1527, 1840]],
              [[1528]],
              [[1529]],
              [[1530]],
              [[1531]],
              [[1532]],
              [[1533]],
              [[1534]],
              [[1535]],
              [[1536]],
              [[1537]],
              [[1538]],
              [[1539]],
              [[1540]],
              [[1541]],
              [[1542]],
              [[1543]],
              [[1544]],
              [[1545]],
              [[1546]],
              [[1547]],
              [[1548]],
              [[1549]],
              [[1550]],
              [[1551]],
              [[1552]],
              [[1553]],
              [[1554]],
              [[1558]],
              [[1559]],
              [[1560]],
              [[1561]],
              [[1562]],
              [[1563]],
              [[1564]],
              [[1565]],
              [[1566]],
              [[1567]],
              [[1568]],
              [[1571]],
              [[1572]],
              [[1573]],
              [[1574]],
              [[1575]],
              [[1576]],
              [[1577]],
              [[1578]],
              [[1579]],
              [[1580]],
              [[1581]],
              [[1582]],
              [[1583]],
              [[1584]],
              [[1585]],
              [[1586]],
              [[1587]],
              [[1588]],
              [[1589]],
              [[1591]],
              [[1592]],
              [[1593]],
              [[1594]],
              [[1595]],
              [[1596]],
              [[1597]],
              [[1598]],
              [[1599]],
              [[1600]],
              [[1601]],
              [[1602]],
              [[1603]],
              [[1604]],
              [[1605]],
              [[1606]],
              [[1607]],
              [[1608]],
              [[1609]],
              [[1610]],
              [[1611]],
              [[1612]],
              [[1613]],
              [[1614]],
              [[1616]],
              [[1617]],
              [[1618]],
              [[1619]],
              [[1620]],
              [[1621]],
              [[1622]],
              [[1623]],
              [[1624]],
              [[1625]],
              [[1626]],
              [[1627]],
              [[1628]],
              [[1629]],
              [[1630]],
              [[1631]],
              [[1632]],
              [[1633]],
              [[1634]],
              [[1635]],
              [[1636]],
              [[1637]],
              [[1638]],
              [[1639]],
              [[1640]],
              [[1641]],
              [[1642]],
              [[1643]],
              [[1644]],
              [[1645]],
              [[1646]],
              [[1647]],
              [[1648]],
              [[1649]],
              [[1650]],
              [[1651]],
              [[1652]],
              [[1653]],
              [[1654]],
              [[1655]],
              [[1656]],
              [[1657]],
              [[1658]],
              [[1659]],
              [[1660]],
              [[1661]],
              [[1662]],
              [[1663]],
              [[1664]],
              [[1665]],
              [[1666]],
              [[1667]],
              [[1668]],
              [[1669]],
              [[1670]],
              [[1671]],
              [[1672]],
              [[1673]],
              [[1674]],
              [[1675]],
              [[1676]],
              [[1677]],
              [[1678]],
              [[1679]],
              [[1680]],
              [[1681]],
              [[1682]],
              [[1683]],
              [[1684]],
              [[1685]],
              [[1686]],
              [[1687]],
              [[1688]],
              [[1689]],
              [[1690]],
              [[1691]],
              [[1692]],
              [[1693]],
              [[1694]],
              [[1695]],
              [[1696]],
              [[1697]],
              [[1698]],
              [[1699]],
              [[1700]],
              [[1701]],
              [[1702]],
              [[1703]],
              [[1704]],
              [[1705]],
              [[1706]],
              [[1707]],
              [[1709]],
              [[1710]],
              [[1714]],
              [[1715]],
              [[1716]],
              [[1717]],
              [[1718]],
              [[1719]],
              [[1720]],
              [[1721]],
              [[1722]],
              [[1723]],
              [[1724]],
              [[1725]],
              [[1726]],
              [[1727]],
              [[1728]],
              [[1729]],
              [[1730]],
              [[1731]],
              [[1739]],
              [[1740]],
              [[1741]],
              [[1742]],
              [[1743]],
              [[1744]],
              [[1745]],
              [[1746]],
              [[1747]],
              [[1748]],
              [[1749]],
              [[1750]],
              [[1751]],
              [[1752]],
              [[1753]],
              [[1754]],
              [[1759]],
              [[1760]],
              [[1762]],
              [[1764]],
              [[1765]],
              [[1766]],
              [[1767]],
              [[1768]],
              [[1769]],
              [[1770]],
              [[1771]],
              [[1772]],
              [[1773]],
              [[1774]],
              [[1775]],
              [[1776]],
              [[1777]],
              [[1778]],
              [[1779]],
              [[1780]],
              [[1781]],
              [[1782]],
              [[1783]],
              [[1784]],
              [[1785]],
              [[1791]],
              [[1792]],
              [[1793]],
              [[1794]],
              [[1795]],
              [[1796]],
              [[1797]],
              [[1798]],
              [[1799]],
              [[1800]],
              [[1801]],
              [[1802]],
              [[1803]],
              [[1804]],
              [[1805]],
              [[1806]],
              [[1807]],
              [[1808]],
              [[1809]],
              [[1810]],
              [[1811]],
              [[1812]],
              [[1813]],
              [[1814]],
              [[1815]],
              [[1816]],
              [[1817]],
              [[1818]],
              [[1819]],
              [[1820]],
              [[1821]],
              [[1822]],
              [[1823]],
              [[1824]],
              [[1825]],
              [[1826]],
              [[1827]],
              [[1828]],
              [[1829]],
              [[1830]],
              [[1831]],
              [[1832]],
              [[1833]],
              [[1834]],
              [[1835]],
              [[1836]],
              [[1837]],
              [[1838]],
              [[1841]],
              [[1842]],
              [[1843]],
              [[1844]],
              [[1849]],
              [[1850]],
              [[1852], [1851]],
              [[1853]],
              [[1854]],
              [[1855]],
              [[1856]],
              [[1857]],
              [[1858]],
              [[1859]],
              [[1860]],
              [[1861]],
              [[1862]],
              [[1863]],
              [[1864]],
              [[1865]],
              [[1866]],
              [[1867]],
              [[1868]],
              [[1869]],
              [[1870]],
              [[1871]],
              [[1872]],
              [[1873]],
              [[1874]],
              [[1875]],
              [[1876]],
              [[1877]],
              [[1878]],
              [[1879]],
              [[1880]],
              [[1881]],
              [[1882]],
              [[1883]],
              [[1884]],
              [[1885]],
              [[1886]],
              [[1887]],
              [[1888]],
              [[1889]],
              [[1890]],
              [[1891]],
              [[1892]],
              [[1893]],
              [[1894]],
              [[1895]],
              [[1896]],
              [[1897]],
              [[1898]],
              [[1899]],
              [[1900]],
              [[1901]],
              [[1902]],
              [[1903]],
              [[1904]],
              [[1905]],
              [[1906]],
              [[1907]],
              [[1908]],
              [[1909]],
              [[1910]],
              [[1911]],
              [[1912]],
              [[1913]],
              [[1914]],
              [[1915]],
              [[1916]],
              [[1917]],
              [[1918]],
              [[1919]],
              [[1920]],
              [[1921]],
              [[1922]],
              [[1923]],
              [[1924]],
              [[1925]],
              [[1926]],
              [[1927]],
              [[1928]],
              [[1929]],
              [[1930]],
              [[1931]],
              [[1932]],
              [[1933]],
              [[1934]],
              [[1935]],
              [[1936]],
              [[1937]],
              [[1938]],
              [[1939]],
              [[1940]],
              [[1941]],
              [[1942]],
              [[1943]],
              [[1944]],
              [[1945]],
              [[1946]],
              [[1947]],
              [[1948]],
              [[1949]],
              [[1950]],
              [[1951]],
              [[1952]],
              [[1953]],
              [[1954]],
              [[1955]],
              [[1956]],
              [[1957]],
            ],
          },
        ],
      },
    },
    arcs: [
      [
        [58690, 38938],
        [-25, 33],
        [-34, 22],
        [-44, 9],
        [-57, -4],
        [-69, -18],
        [-75, 22],
        [-80, 62],
        [-67, 22],
        [-79, -27],
        [-4, -1],
      ],
      [
        [58156, 39058],
        [-13, 21],
        [-22, 45],
        [-36, 8],
        [-10, 11],
        [-8, 16],
        [-6, 22],
        [-2, 23],
        [6, 75],
        [-3, 8],
        [-10, 9],
        [-19, 9],
        [-48, 33],
        [-60, 33],
        [-97, 35],
        [-38, 10],
        [-9, 11],
        [-11, 27],
        [-19, 85],
        [-17, 56],
        [-42, 87],
        [-7, 27],
        [2, 69],
        [4, 55],
        [4, 47],
        [-2, 45],
        [-1, 54],
        [2, 37],
        [-6, 16],
        [-15, 11],
        [-43, 5],
        [-53, -2],
        [-1, 56],
        [-5, 86],
        [-10, 50],
        [-12, 26],
        [-24, 27],
        [-49, 37],
        [-66, 56],
        [-57, 83],
        [-64, 103],
        [-21, 18],
        [-24, 97],
        [-36, 166],
        [2, 55],
        [-5, 27],
        [-36, 82],
        [-7, 42],
        [-7, 43],
        [-56, 120],
        [-19, 52],
        [-15, 67],
        [-14, 53],
        [-13, 22],
        [-16, 36],
        [-11, 42],
        [-5, 31],
        [4, 41],
        [6, 29],
      ],
      [
        [57016, 41593],
        [53, -30],
        [30, -2],
        [23, 14],
        [28, -19],
        [34, -54],
        [37, -11],
        [39, 34],
        [54, -10],
        [68, -54],
        [56, -11],
        [67, 48],
        [60, 132],
        [56, 125],
        [55, 144],
        [34, 116],
        [48, 95],
        [65, 73],
        [65, 61],
        [100, 76],
        [21, 62],
        [6, 68],
        [0, 94],
        [5, 61],
        [11, 28],
        [16, 22],
        [22, 28],
        [66, 72],
        [55, 46],
        [68, 30],
        [73, 0],
        [71, 0],
        [41, 1],
      ],
      [
        [58443, 42832],
        [0, -91],
        [3, -102],
        [8, -10],
        [54, -2],
        [86, -8],
        [82, -7],
        [53, -74],
        [18, -15],
        [55, -20],
        [70, -124],
        [84, -11],
        [58, -39],
        [51, -42],
        [29, -51],
        [19, -12],
        [26, -4],
        [13, -4],
        [-3, -37],
        [-17, -62],
        [2, -89],
        [24, -123],
        [3, -107],
        [-8, -189],
        [1, -183],
        [2, -65],
        [4, -43],
        [5, -24],
        [-1, -27],
        [-14, -77],
        [-11, -81],
        [-1, -32],
        [-4, -23],
        [-8, -20],
        [-37, -37],
        [-6, -23],
        [0, -42],
        [4, -35],
        [14, -13],
        [17, -20],
        [6, -26],
        [0, -28],
        [-5, -51],
        [-15, -85],
        [15, -98],
        [17, -63],
        [22, -73],
        [10, -45],
        [-1, -33],
        [-3, -31],
        [-34, -134],
        [-25, -83],
        [-30, -89],
        [-40, -56],
        [-10, -27],
        [-4, -31],
        [1, -67],
        [-1, -69],
        [-34, -108],
        [21, -92],
        [-5, -9],
        [-12, -13],
        [-49, -104],
        [-49, -105],
        [-36, -77],
        [-42, -88],
        [-46, -98],
        [-39, -83],
        [-40, -60],
      ],
      [
        [57016, 41593],
        [-47, 92],
        [-25, 38],
        [-19, 14],
        [-55, 15],
        [-128, 21],
        [-13, -5],
        [-53, -18],
        [-66, -23],
        [-56, -22],
        [-60, -24],
      ],
      [
        [56494, 41681],
        [-55, 96],
        [-63, 108],
        [-65, 122],
        [-49, 95],
        [-24, 55],
        [-43, 72],
        [-31, 35],
        [-12, 18],
        [-30, 193],
        [-17, 177],
        [0, 133],
        [0, 185],
        [0, 185],
        [0, 185],
        [0, 184],
        [0, 185],
        [0, 185],
        [0, 185],
        [0, 185],
        [0, 90],
        [64, 0],
        [72, 0],
        [76, 0],
        [83, 0],
        [82, 0],
        [83, 0],
        [58, 0],
        [15, 1],
        [18, 6],
        [1, 18],
        [-24, 91],
        [2, 32],
        [6, 62],
        [10, 53],
        [13, 70],
        [1, 41],
        [-10, 134],
        [1, 75],
        [3, 78],
        [3, 74],
        [-4, 51],
        [4, 28],
        [8, 40],
        [4, 45],
        [5, 20],
        [-1, 18],
        [-5, 33],
        [-4, 76],
        [-6, 105],
        [-6, 75],
      ],
      [
        [56657, 45580],
        [10, -4],
        [21, -7],
        [10, -37],
        [6, -41],
        [14, -2],
        [37, -24],
        [13, -34],
        [8, -72],
        [-5, -37],
        [-12, -30],
        [12, -26],
        [25, -18],
        [14, 6],
        [42, 49],
        [16, 9],
        [22, 9],
        [20, 13],
        [55, 22],
        [30, 10],
        [17, 18],
        [12, 0],
        [9, -14],
        [-8, -51],
        [-3, -46],
        [11, -86],
        [8, -40],
        [18, -29],
        [13, -15],
        [14, -31],
        [30, 6],
        [65, -44],
        [20, -20],
        [28, -21],
        [19, -7],
        [68, -15],
        [25, -11],
        [46, -14],
        [37, -2],
        [27, 6],
        [18, 13],
        [11, 14],
        [5, 11],
        [8, 43],
        [14, 93],
        [5, 27],
        [14, 13],
        [18, 9],
        [10, -15],
        [12, -103],
        [51, -93],
        [18, -78],
        [13, -66],
        [11, -19],
        [19, -22],
        [32, -9],
        [28, -2],
        [58, -48],
        [47, -38],
        [34, -27],
        [15, -21],
        [11, -34],
        [6, -27],
        [10, -68],
        [11, -54],
        [18, -11],
        [16, -4],
        [16, -37],
        [12, -32],
        [24, -80],
        [17, -54],
        [6, -54],
        [20, -35],
        [27, -15],
        [25, -2],
        [14, 16],
        [36, 27],
        [28, 32],
        [20, 11],
        [12, -7],
        [9, -22],
        [5, -43],
        [1, -24],
        [20, -22],
        [14, 9],
        [6, 26],
        [0, 13],
        [0, 117],
        [0, 101],
        [0, 95],
        [0, 116],
        [0, 101],
        [0, 83],
        [0, 87],
        [-13, -5],
        [-16, -20],
        [-37, -2],
        [-14, -15],
        [-4, -23],
        [2, -29],
        [1, -39],
        [-5, -19],
        [-16, -7],
        [-24, 15],
        [-42, 20],
        [-35, 12],
        [-25, 53],
        [-35, 79],
        [-22, 40],
        [-54, 82],
        [-9, 17],
        [-17, 38],
        [-14, 66],
        [-7, 43],
        [-6, 33],
        [-8, 48],
        [13, 74],
        [19, 141],
        [13, 102],
        [7, 75],
        [27, 77],
        [2, 68],
        [-11, 88],
        [3, 49],
        [1, 123],
        [2, 104],
        [0, 50],
        [-7, 88],
        [-18, 98],
        [-39, 135],
        [0, 29],
        [24, 32],
        [36, 56],
        [18, 33],
        [22, 47],
        [10, 24],
        [21, 61],
        [13, 49],
        [5, 63],
        [-10, 61],
        [20, 12],
        [68, 21],
        [74, 23],
        [79, 25],
        [79, 25],
        [77, 25],
        [69, 22],
        [48, 15],
      ],
      [
        [58541, 47123],
        [8, -42],
        [14, -69],
        [18, -51],
        [21, -44],
        [18, -27],
        [12, -8],
        [76, 3],
        [27, -27],
        [24, -35],
        [6, -53],
        [16, -33],
        [17, -26],
        [7, -3],
        [12, 6],
        [21, 1],
        [19, -12],
        [9, -11],
        [1, -45],
        [5, -20],
        [26, -7],
        [26, -4],
        [26, -30],
        [27, -5],
        [32, -13],
        [15, -32],
        [33, -34],
        [41, -29],
        [30, -34],
        [16, -15],
      ],
      [
        [59144, 46424],
        [1, -16],
        [7, -29],
        [8, -20],
        [1, -31],
        [4, -28],
        [12, -7],
        [9, -2],
        [9, 20],
        [12, 0],
        [13, -13],
        [5, -33],
        [11, -44],
        [16, -30],
        [12, -29],
        [-4, -53],
        [-7, -49],
        [22, -47],
        [30, -46],
        [8, -20],
        [2, -67],
        [5, -23],
        [20, -56],
        [10, -37],
        [-1, -22],
        [-54, -111],
        [-17, -11],
        [-16, -6],
        [-15, -23],
        [-8, -23],
        [3, -13],
        [6, -38],
        [12, -60],
        [12, -42],
        [-10, -52],
        [-21, -89],
        [-10, -8],
        [-2, -68],
        [7, -24],
        [10, -20],
        [4, -46],
        [1, -63],
        [-1, -50],
        [-14, -130],
        [25, -112],
        [8, -13],
        [34, 0],
        [5, -10],
        [-8, -32],
        [-14, -33],
        [-9, -17],
        [-43, -38],
        [-62, -43],
        [-13, -41],
        [-8, -59],
        [7, -35],
        [8, -20],
        [-3, -52],
        [-5, -54],
        [2, -43],
        [-3, -38],
        [-8, -19],
        [-11, -57],
        [-13, -58],
        [-11, -26],
        [-15, -28],
        [-24, -23],
        [0, -11],
        [28, -27],
        [7, -18],
        [2, -13],
        [-6, -12],
        [-5, -17],
        [13, -18],
        [15, -15],
        [15, -38],
        [13, -54],
        [4, -18],
        [3, -8],
        [5, -1],
        [9, 8],
        [17, 30],
        [12, 10],
        [15, -42],
      ],
      [
        [59222, 43770],
        [-60, -41],
        [-31, -21],
        [-89, -62],
        [-78, -54],
        [-20, -10],
        [-41, -27],
        [-20, -16],
        [-70, -47],
        [-29, -23],
        [-24, -23],
        [-58, -35],
        [-55, -33],
        [-60, -34],
        [-67, -38],
        [-38, -27],
        [-25, -24],
        [-60, -47],
        [-3, -12],
        [1, -32],
        [8, -67],
        [15, -61],
        [12, -35],
        [8, -90],
        [5, -79],
      ],
      [
        [64745, 61433],
        [-140, -103],
        [-37, -45],
        [-33, -57],
        [-25, -70],
        [-18, -124],
        [13, -113],
        [-1, -60],
        [-36, -40],
        [-34, -29],
        [-37, -44],
        [-23, -11],
        [-19, -35],
        [-21, -25],
        [-78, -63],
        [-86, -50],
        [-135, -59],
        [-53, -64],
        [-47, -44],
        [-73, -13],
        [-99, -61],
        [-55, -48],
        [-69, -80],
        [-15, -25],
        [-12, -58],
        [-21, -51],
        [-42, -83],
        [-31, -42],
        [-20, -2],
        [-41, -23],
        [-47, -5],
        [-80, 29],
        [-21, -20],
        [-17, -33],
        [-61, -56],
        [-63, -114],
        [-46, -30],
        [-74, -36],
        [-52, -47],
        [-35, -19],
        [-44, -10],
        [-83, 5],
        [-79, -17],
        [-73, -32],
        [-34, -60],
        [-39, -96],
        [-64, -40],
        [-15, -34],
        [-20, -71],
        [-41, -18],
        [-38, -12],
        [-38, 31],
        [-72, -85],
        [-27, -15],
        [-41, -3],
        [-30, -18],
        [-21, 5],
        [-26, 34],
        [-56, 40],
        [-41, -26],
        [-3, 80],
        [-68, 247],
        [14, 215],
        [0, 30],
        [-13, 96],
        [-40, 87],
        [1, 111],
        [-14, 80],
        [-10, 81],
        [3, 22],
        [1, 20],
        [-21, 125],
        [-7, 26],
        [-2, 26],
        [6, 20],
        [0, 24],
        [-11, 38],
        [-11, 74],
        [-55, 57],
        [11, 54],
        [11, -19],
        [14, -16],
        [3, 35],
        [0, 26],
        [-23, 163],
        [34, 218],
        [-11, 195],
      ],
      [
        [61888, 61273],
        [52, 79],
        [13, 24],
        [8, 21],
        [12, 45],
        [17, 14],
        [6, 47],
        [-1, 24],
        [-11, 20],
        [-8, 55],
        [3, 69],
        [3, 29],
        [5, 53],
        [18, 20],
        [5, 16],
        [-14, 33],
        [1, 20],
        [31, 56],
        [12, 17],
        [20, 18],
        [16, -1],
        [18, -9],
        [16, -16],
        [15, -29],
        [17, -32],
        [25, -13],
        [17, 4],
        [15, -15],
        [11, 8],
        [14, 17],
        [21, -1],
        [20, 19],
        [55, 9],
        [53, -6],
        [56, 16],
        [56, -1],
        [55, -2],
        [13, -2],
        [12, -10],
        [47, -50],
        [36, -10],
        [72, -13],
        [77, -15],
        [66, -13],
        [57, 12],
        [47, 10],
        [12, -2],
        [14, -31],
        [29, -76],
        [26, -73],
        [47, -3],
        [30, 27],
        [33, 38],
        [20, 30],
        [23, 117],
        [15, 77],
        [34, 85],
        [29, 72],
        [39, 95],
        [21, 52],
        [42, 104],
        [39, 40],
        [77, 78],
        [76, 77],
        [49, 49],
        [42, 23],
        [70, 20],
        [83, 23],
        [82, 23],
        [88, 24],
        [98, 28],
        [67, 18],
        [86, 24],
        [71, 20],
        [63, 18],
        [66, 18],
      ],
      [
        [64438, 62785],
        [12, -57],
        [12, -58],
        [12, -58],
        [13, -57],
        [12, -58],
        [12, -57],
        [13, -58],
        [12, -57],
        [12, -58],
        [13, -57],
        [12, -58],
        [12, -57],
        [12, -58],
        [13, -57],
        [12, -58],
        [12, -58],
        [12, -56],
        [20, -18],
        [12, -54],
        [17, -75],
        [17, -76],
        [17, -76],
        [16, -76],
      ],
      [
        [61830, 60658],
        [-9, -13],
        [-2, 23],
        [5, 50],
        [9, 14],
        [7, -37],
        [-4, -20],
        [-6, -17],
      ],
      [
        [61876, 59737],
        [-18, -18],
        [12, 46],
        [13, 9],
        [4, -2],
        [-11, -35],
      ],
      [
        [61885, 59891],
        [-4, -13],
        [-5, 3],
        [-17, 31],
        [19, 34],
        [10, -32],
        [-3, -23],
      ],
      [
        [64934, 59122],
        [17, -7],
        [26, 20],
        [74, 3],
        [90, -65],
        [-17, -16],
        [-10, -24],
        [-39, -21],
        [-40, -49],
        [-114, -24],
        [-33, 13],
        [-28, 48],
        [-51, 62],
        [20, 40],
        [5, 18],
        [7, 17],
        [29, 30],
        [29, -4],
        [35, -41],
      ],
      [
        [78906, 57828],
        [5, -29],
        [-2, -66],
        [-11, -66],
        [4, -29],
        [-9, -18],
        [-18, 123],
        [-24, 53],
        [-5, 21],
        [14, -2],
        [24, 34],
        [12, 1],
        [10, -22],
      ],
      [
        [79992, 64232],
        [-13, -5],
        [-33, -1],
        [-28, -53],
        [-20, -22],
        [-30, -18],
        [-33, -30],
        [-9, -52],
        [-1, -38],
        [-5, -42],
        [-53, -61],
        [-15, 6],
        [-10, 23],
        [-15, -5],
        [-11, -11],
        [-12, 1],
        [-14, -13],
        [-18, 4],
        [-17, 19],
        [-10, 5],
        [-12, 1],
        [-2, -23],
        [17, -89],
        [5, -41],
        [-56, -120],
        [6, -78],
        [-15, -59],
        [-34, -48],
        [-64, -123],
        [-29, -3],
        [-22, -28],
        [-47, -202],
        [-1, -70],
        [-7, -50],
        [2, -49],
        [-21, -96],
        [-22, -41],
        [-4, -52],
        [30, -108],
        [4, -19],
        [17, -58],
        [9, -41],
        [14, -41],
        [49, -107],
        [22, -33],
        [26, -23],
        [48, -96],
        [24, -62],
        [-11, -41],
        [6, -89],
        [-35, 26],
        [5, -11],
        [40, -48],
        [61, -170],
        [53, -84],
        [54, -96],
        [17, -91],
        [48, -60],
        [54, -87],
        [-2, -19],
        [14, -24],
        [37, -46],
        [22, -49],
        [8, -46],
        [13, -7],
        [16, 11],
        [15, 4],
        [10, -3],
        [17, -51],
        [22, -46],
        [11, -41],
        [9, 5],
        [7, -6],
        [2, -35],
        [4, -23],
        [30, -67],
        [14, -63],
        [37, -103],
        [26, -58],
        [20, -33],
        [21, -28],
        [22, -114],
        [11, -103],
        [23, -114],
        [18, -50],
        [0, -95],
        [14, -97],
        [15, -65],
        [5, -67],
        [4, -33],
        [6, -25],
        [16, -114],
        [-4, -52],
        [-11, 51],
        [1, -152],
        [10, -80],
        [-5, -99],
        [11, -35],
        [19, -111],
        [13, -40],
        [-1, -137],
        [7, -69],
        [-18, 41],
        [-13, 47],
        [-17, -25],
        [-15, -36],
        [24, -147],
        [-28, 14],
        [3, -197],
        [11, -46],
        [1, -22],
        [-3, -27],
        [-8, 29],
        [-1, 30],
        [-5, -6],
        [1, -16],
        [-10, -35],
        [-2, -43],
        [9, -36],
        [2, -28],
        [-7, -35],
        [-11, -37],
        [-26, -5],
        [-6, -71],
        [-9, -76],
        [-46, -12],
        [-33, -67],
        [-42, -25],
        [-37, -67],
        [-40, -61],
        [-27, -8],
        [-22, -13],
        [-26, -102],
        [-44, -12],
        [-78, -83],
        [-26, -40],
        [-24, -16],
        [-34, -35],
        [-7, 13],
        [-12, 30],
        [-29, 15],
        [-15, 33],
        [-4, 43],
        [-4, 17],
        [-6, -24],
        [-5, -102],
        [-5, -23],
        [-13, -10],
        [-25, 29],
        [-23, 59],
        [-34, -41],
        [10, -5],
        [16, 3],
        [12, -10],
        [10, -39],
        [-1, -22],
        [-5, -25],
        [-32, -4],
        [-42, 9],
        [-7, -3],
        [38, -39],
        [35, -22],
        [16, -24],
        [0, -20],
        [-20, -32],
        [-15, -40],
        [-1, -25],
        [0, -27],
        [-17, -24],
        [-11, 5],
        [-30, 41],
        [-86, 163],
        [13, -46],
        [90, -185],
        [15, -61],
        [3, -43],
        [-10, -21],
        [-15, -26],
        [-29, -2],
        [-49, 69],
        [-77, 165],
        [-26, 22],
        [78, -188],
        [13, -46],
        [13, -53],
        [-4, -31],
        [-7, -30],
        [-185, -174],
        [-28, -76],
        [-22, -93],
        [-36, -51],
        [-21, -48],
        [-62, -26],
        [-34, 8],
        [35, 86],
        [-22, 32],
        [-1, 221],
        [9, 242],
        [16, 121],
        [23, 30],
        [30, 19],
        [0, 25],
        [-3, 30],
        [-15, 41],
        [-18, 19],
        [-25, 8],
        [-20, 51],
        [-15, -2],
        [-24, -17],
        [-14, 22],
        [-5, 34],
        [-22, 42],
        [-24, 41],
      ],
      [
        [79007, 57840],
        [11, 6],
        [13, 24],
        [14, 30],
        [35, 4],
        [35, -1],
        [9, 8],
        [14, 32],
        [23, 41],
        [18, 23],
        [4, 19],
        [-7, 43],
        [-4, 45],
        [7, 14],
        [31, -8],
        [35, -21],
        [8, -9],
        [20, 55],
        [6, 6],
        [13, 0],
        [34, 10],
        [34, 15],
        [16, -3],
        [15, -37],
        [12, -36],
        [6, -2],
        [17, 15],
        [15, -19],
        [30, -32],
        [18, -1],
        [-9, 73],
        [10, 52],
        [-2, 14],
        [-17, 24],
        [-57, 96],
        [-10, 29],
        [1, 45],
        [-2, 66],
        [-5, 41],
        [1, 24],
        [3, 20],
        [11, 8],
        [10, 2],
        [8, 17],
        [14, 44],
        [27, -4],
        [38, -25],
        [28, -15],
        [17, 3],
        [3, 6],
        [0, 23],
        [2, 100],
        [-1, 21],
        [23, 10],
        [37, 2],
        [19, 6],
        [18, 42],
        [46, 15],
        [34, 56],
        [30, 59],
        [14, 15],
        [19, 10],
        [14, -1],
        [18, -34],
        [14, 20],
        [17, 40],
        [9, 39],
        [5, 62],
        [-4, 96],
        [-8, 74],
        [-9, 57],
        [-2, 56],
        [20, 112],
        [17, 122],
        [-4, 49],
        [-18, 76],
        [-18, 93],
        [-20, 102],
        [-8, 15],
        [-5, 29],
        [-4, 33],
        [9, 104],
        [1, 36],
        [23, 47],
        [12, 55],
        [12, 60],
        [-4, 31],
      ],
      [
        [79866, 60313],
        [-2, 65],
        [3, 31],
        [-5, 26],
        [-7, 37],
        [4, 24],
        [17, 20],
        [9, 35],
        [12, 42],
        [6, 37],
        [-9, 32],
        [-16, 47],
        [-29, 43],
        [-33, 54],
        [-17, 34],
        [-13, 34],
        [-12, 40],
        [-6, 31],
        [6, 21],
        [48, 48],
        [8, 17],
        [5, 27],
        [-3, 26],
        [-13, 14],
        [-15, 10],
        [-22, 30],
        [-41, 82],
        [-19, 19],
        [-20, 24],
        [-10, 25],
        [-12, 68],
        [-5, 6],
        [-11, -20],
        [-15, -22],
        [-12, 4],
        [-11, 19],
        [-5, 26],
        [-12, 36],
        [-14, 29],
        [-3, 98],
        [-2, 32],
        [-7, 45],
        [-10, 16],
        [-11, 12],
        [-26, 81],
        [-17, 42],
        [-74, 114],
        [-9, 19],
        [-19, 47],
        [-35, 66],
        [-24, 54],
        [-18, 56],
        [-8, 48],
        [-3, 38],
        [-19, 54],
        [-17, 44],
        [-16, 15],
        [-18, 6],
        [-17, 26],
        [-31, 59],
        [-13, 39],
        [-8, 26],
        [0, 26],
        [7, 44],
        [9, 26],
        [1, 19],
        [-9, 16],
        [-34, 29],
        [-77, 43],
        [-29, 33],
        [-26, 42],
        [-20, 29],
        [-94, 122],
        [-28, 20],
        [-25, 22],
        [-7, 21],
        [1, 20],
        [10, 15],
        [27, 32],
        [10, 35],
        [-4, 47],
        [-10, 48],
        [5, 16],
        [9, 2],
        [17, 2],
        [37, 2],
        [80, -43],
        [11, 5],
        [44, 78],
        [16, 47],
        [4, 39],
        [8, 25],
        [23, 41],
        [0, 37],
        [-11, 50],
        [-11, 19],
        [-10, 8],
        [-32, -6],
        [-6, 11],
        [-4, 37],
        [-2, 23],
        [-10, 26],
        [-34, 23],
        [-29, 6],
        [-7, 10],
        [11, 25],
        [20, 25],
        [15, 15],
        [12, 26],
        [2, 27],
        [-15, 24],
        [-19, 26],
        [-31, 50],
        [-43, 54],
        [-26, 18],
        [-13, -2],
        [-48, -46],
        [-25, -30],
        [-21, -54],
        [-22, -11],
        [-23, 23],
        [-25, 25],
        [-70, 35],
        [-30, 29],
        [-61, 179],
        [-9, 37],
        [6, 41],
        [4, 59],
        [6, 38],
        [11, 37],
        [3, 32],
        [-3, 32],
        [-9, 18],
        [-11, 5],
        [-9, 8],
        [-8, 41],
        [-5, -5],
        [-7, -51],
        [-9, -18],
        [-12, -9],
        [-10, 8],
        [-5, 20],
        [-3, 35],
        [-6, 46],
        [-8, 30],
        [-26, 31],
        [-12, 40],
        [-40, 87],
        [-33, 61],
        [-15, 55],
      ],
      [
        [78368, 64734],
        [13, 20],
        [17, 30],
        [19, 46],
        [20, 58],
        [8, 35],
        [6, 15],
        [12, 10],
        [13, -6],
        [23, -23],
        [34, -30],
        [30, -35],
        [12, -36],
        [17, -34],
        [13, -10],
        [7, 2],
        [19, 26],
        [17, 26],
        [1, 29],
        [15, 26],
        [20, 43],
        [10, 30],
        [7, 3],
        [8, -9],
        [32, -90],
        [6, -6],
        [9, 14],
        [13, 70],
        [14, 28],
        [4, -7],
        [77, -134],
        [8, 2],
        [8, 6],
        [6, 20],
        [6, 46],
        [11, 50],
        [25, 27],
        [19, 6],
        [7, -24],
        [17, -32],
        [20, -5],
        [43, 58],
        [15, 9],
        [15, -1],
        [15, 2],
        [15, 22],
        [15, 29],
        [5, 58],
        [4, 51],
        [10, 21],
        [13, 14],
        [24, 19],
        [53, 50],
        [14, 24],
        [10, 13],
        [21, -21],
        [25, -42],
        [15, -31],
        [10, -34],
        [5, -29],
        [40, -24],
        [25, -35],
        [17, -27],
        [17, 1],
        [16, 7],
        [11, 22],
        [19, 0],
        [22, -3],
        [10, -8],
        [18, -50],
        [8, -7],
        [17, 4],
        [31, 17],
        [25, 9],
        [23, -20],
        [43, -55],
        [-12, -39],
        [-9, -42],
        [-19, -30],
        [-15, -7],
        [-8, -42],
        [-4, -61],
        [4, -31],
        [11, -10],
        [12, -20],
        [5, -27],
        [2, -61],
        [-1, -68],
        [2, -23],
        [9, 4],
        [9, 9],
        [18, -11],
        [23, -18],
        [14, -18],
        [12, 3],
        [10, -18],
        [4, -34],
        [12, -23],
        [32, -45],
        [26, -3],
        [22, -59],
        [23, 19],
        [10, -25],
        [47, 9],
        [33, 24],
        [12, -6],
        [30, -49],
        [18, -30],
      ],
      [
        [79615, 56844],
        [-8, -1],
        [-6, 12],
        [26, 37],
        [-3, -25],
        [1, -12],
        [-10, -11],
      ],
      [
        [79768, 57832],
        [-23, -35],
        [-2, 29],
        [20, 19],
        [7, 15],
        [6, 0],
        [-8, -28],
      ],
      [
        [79684, 63833],
        [-3, -11],
        [-14, 27],
        [-10, 12],
        [8, 37],
        [16, -40],
        [3, -25],
      ],
      [
        [79889, 64064],
        [-40, -72],
        [-15, 1],
        [13, 82],
        [7, 19],
        [24, -28],
        [11, -2],
      ],
      [
        [79866, 63897],
        [-15, -15],
        [-19, 2],
        [22, 28],
        [12, 35],
        [9, 12],
        [-1, -30],
        [-8, -32],
      ],
      [
        [79730, 63794],
        [-11, -3],
        [-22, 47],
        [11, 25],
        [26, -18],
        [5, -11],
        [0, -10],
        [-6, -22],
        [-3, -8],
      ],
      [
        [33105, 57107],
        [-33, -19],
        [0, 15],
        [9, 27],
        [17, 7],
        [7, 10],
        [11, 6],
        [6, -1],
        [7, -8],
        [-16, -15],
        [-8, -22],
      ],
      [
        [32264, 58254],
        [9, -75],
        [-3, -14],
        [-25, -51],
        [-21, -3],
        [-17, 1],
        [-13, 10],
        [-17, 33],
        [-16, -10],
        [-40, 12],
        [-11, 11],
        [15, 41],
        [28, 16],
        [10, 4],
        [8, -25],
        [20, -22],
        [23, -2],
        [6, 38],
        [32, 57],
        [12, -21],
      ],
      [
        [31885, 58125],
        [-15, -13],
        [-27, 13],
        [-14, 18],
        [9, 21],
        [22, 0],
        [21, -25],
        [4, -14],
      ],
      [
        [33056, 56951],
        [-17, -12],
        [-3, 58],
        [5, 15],
        [30, 47],
        [8, 8],
        [6, -9],
        [-2, -13],
        [14, -21],
        [-3, -26],
        [-16, -29],
        [-22, -18],
      ],
      [
        [33328, 56767],
        [52, -140],
        [1, -15],
        [-6, -18],
        [-32, -33],
        [-7, -17],
        [-12, -62],
        [-40, -35],
        [-28, -42],
        [-19, -38],
        [-10, -15],
        [-37, -9],
        [-12, -23],
        [-14, -72],
        [-11, -29],
        [-20, -35],
        [0, -22],
        [27, -78],
        [5, -24],
        [-9, -37],
        [1, -27],
        [14, -31],
        [17, -7],
        [16, 13],
        [20, -1],
        [13, -9],
        [6, -9],
        [1, -24],
        [-9, -51],
        [-12, -34],
        [-53, -50],
        [-23, -30],
        [-13, -22],
        [-29, 12],
        [-15, -1],
        [-11, -17],
        [-7, -14],
        [-19, -4],
        [-27, -8],
        [-11, -10],
        [-9, -25],
        [-7, -36],
        [6, -43],
        [8, -39],
        [0, -35],
        [6, -99],
        [-8, -23],
        [-18, -26],
        [-22, -46],
        [-24, -63],
        [4, -19],
        [58, -134],
        [59, -136],
        [59, -136],
      ],
      [
        [33127, 54839],
        [8, -6],
        [11, -16],
        [11, -47],
        [8, -50],
        [0, -26],
        [-6, -33],
        [-15, -38],
        [-17, -30],
        [-25, -26],
        [-21, -25],
        [-16, -64],
        [-11, -23],
        [-9, -9],
        [-18, -9],
        [-30, 2],
        [-20, 6],
        [-24, -49],
        [-31, -18],
        [-21, -65],
        [-74, -53],
        [-72, -40],
        [-20, -17],
        [-72, 34],
        [-17, -10],
        [-20, -32],
        [-18, -24],
        [-15, -1],
        [-13, -13],
        [-8, -45],
        [-7, -154],
        [-26, -45],
        [-31, 0],
        [-21, 53],
        [-25, 40],
        [-44, 96],
        [-12, 12],
        [-12, 0],
        [-41, -28],
        [-19, 12],
        [-16, 15],
        [-26, -5],
        [-46, -1],
        [-30, -1],
        [-14, 26],
        [-14, 53],
        [-9, 19],
        [-11, 16],
        [-17, 8],
        [-75, -1],
        [-14, 0],
        [-11, 11],
        [-14, 45],
        [-16, 22],
        [-18, 1],
        [-8, -25],
        [32, -83],
        [9, -44],
        [28, -65],
        [81, -137],
        [15, -43],
        [-1, -55],
        [-1, -85],
        [3, -80],
        [21, -115],
        [29, -118],
        [8, -74],
        [-5, -55],
        [-6, -30],
        [1, -13],
        [6, -12],
        [28, -17],
        [59, -10],
        [35, 0],
        [55, -13],
        [4, -41],
        [-6, -68],
        [-10, -39],
        [-9, -11],
        [-30, -9],
        [-31, -42],
        [-45, -41],
        [-26, -5],
        [-10, -8],
        [-9, -13],
        [-8, -15],
        [-9, -77],
        [-13, -88],
        [-25, -51],
        [-28, -43],
        [-28, -5],
        [-22, 3],
        [-11, -12],
        [-16, -35],
        [-23, -44],
        [-18, -23],
        [-24, 2],
        [-26, -22],
        [-32, -35],
        [-22, -29],
        [-18, -50],
        [-26, -52],
        [-27, -36],
        [-13, -45],
        [-18, -57],
        [-23, -2],
        [-2, 34],
        [11, 55],
        [-12, 48],
        [-22, 26],
        [-10, 7],
        [-10, -3],
        [-26, -23],
        [-32, -43],
        [-20, -31],
        [-17, -14],
        [-37, -13],
        [-30, -6],
        [-13, 9],
        [-23, 31],
        [-53, 98],
        [-71, 133],
      ],
      [
        [31423, 52547],
        [-5, 39],
        [3, 39],
        [-13, 58],
        [-8, 61],
        [-6, 21],
        [-2, 45],
        [-16, 83],
        [-12, 67],
        [-12, 35],
        [5, 29],
        [-5, 29],
        [-9, 24],
        [-14, 76],
        [5, 33],
        [-4, 33],
        [-12, 23],
        [-16, 24],
        [-22, 51],
        [-27, 48],
        [-13, 20],
        [-9, 7],
        [-8, 46],
        [-6, 14],
        [-14, 4],
        [-27, 19],
        [-26, -23],
        [-1, 35],
        [8, 22],
        [89, 170],
        [44, 78],
        [5, 11],
        [4, 18],
        [3, 24],
        [-10, 28],
        [-42, 131],
        [-15, 24],
        [-14, 21],
        [-16, 55],
        [-20, 128],
        [-14, 64],
        [-4, 49],
        [1, 56],
        [-6, 43],
        [-11, 30],
        [0, 91],
        [11, 153],
        [3, 116],
        [-6, 80],
        [11, 60],
        [26, 42],
        [14, 64],
        [3, 86],
        [16, 72],
        [28, 56],
        [10, 55],
        [-9, 54],
        [-3, 35],
        [-24, 35],
        [-44, 25],
        [-37, 3],
        [-22, -28],
        [-57, -25],
        [-91, -24],
        [-74, 0],
        [-56, 24],
        [-42, -8],
        [-29, -40],
        [-20, -9],
        [-12, 22],
        [-13, 6],
        [-20, -14],
        [-3, 7],
        [-43, 107],
        [-40, 100],
        [-46, 118],
        [-53, 137],
        [-10, 9],
        [-16, -1],
        [-22, -2],
        [-33, 14],
        [-23, 20],
        [-19, 19],
        [-33, 25],
        [-23, 4],
        [-20, -7],
        [-56, -48],
        [-32, -5],
        [-25, -1],
        [-67, 24],
        [-45, 4],
        [-53, -16],
        [-22, -9],
        [-32, 25],
        [-22, 37],
        [-20, 88],
        [-14, 69],
        [-25, 14],
        [-27, 12],
        [-13, 23],
        [-8, 40],
        [-2, 51],
        [2, 84],
        [3, 29],
        [4, 90],
        [15, 47],
        [9, 23],
        [-1, 38],
        [-8, 77],
        [-7, 55],
        [-30, 62],
        [-39, 79],
        [-17, 128],
        [-20, 150],
        [-15, 15],
        [-15, -8],
        [-15, 8],
        [-14, 60],
        [-13, 11],
        [-22, -21],
        [-16, -16],
        [-40, -15],
        [-8, 15],
        [3, 19],
        [17, 54],
        [20, 70],
        [23, 64],
        [21, 66],
        [16, 70],
        [11, 138],
        [7, 96],
        [20, 170],
        [36, 136],
        [14, 62],
        [33, 82],
        [15, 44],
        [20, 35],
        [55, 47],
        [65, 234],
        [15, 37],
        [67, 35],
        [51, 27],
        [37, 28],
        [13, 16],
        [10, 7],
      ],
      [
        [30189, 58676],
        [-9, -28],
        [-18, -34],
        [-20, -21],
        [-106, -53],
        [-11, -11],
        [-13, -22],
        [0, -50],
        [2, -39],
        [32, -129],
        [12, -32],
        [41, -70],
        [-9, -10],
        [-16, -1],
        [12, -92],
        [25, -63],
        [1, -39],
        [-19, -123],
        [-36, -74],
        [-25, -86],
        [-20, -34],
        [-44, -168],
        [34, -101],
        [4, -50],
        [29, -73],
        [19, -23],
        [12, -30],
        [-6, -49],
        [12, -67],
        [15, -35],
        [18, -14],
        [23, 0],
        [67, 44],
        [15, 20],
        [10, 36],
        [34, 73],
        [2, 93],
        [7, 113],
        [-8, 73],
        [-35, 104],
        [-15, 75],
        [-35, 69],
        [-21, 118],
        [-9, 37],
        [-6, 51],
        [-8, 91],
        [23, 32],
        [-2, 74],
        [57, 21],
        [123, 120],
        [77, 31],
        [87, 64],
        [20, 32],
        [17, 52],
        [14, 7],
        [45, -50],
        [22, 17],
        [9, 39],
        [-12, 75],
        [-26, 0],
        [-78, -27],
        [-8, 32],
        [0, 29],
        [-18, 89],
        [11, 68],
        [12, 55],
        [22, 22],
        [33, 24],
        [25, -37],
        [15, -35],
        [8, -33],
        [6, -92],
        [13, -93],
        [14, -64],
        [23, -49],
        [17, 4],
        [12, 8],
        [81, 11],
        [50, -33],
        [63, -17],
        [59, -71],
        [60, -85],
        [15, -63],
        [6, -59],
        [14, -40],
        [-14, -41],
        [7, -69],
        [18, -69],
        [26, -44],
        [74, -12],
        [81, 30],
        [125, 27],
        [40, 23],
        [206, 12],
        [39, -33],
        [4, -33],
        [0, -26],
        [67, -124],
        [54, -16],
        [46, -40],
        [48, -22],
        [52, -30],
        [30, 4],
        [22, 11],
        [26, 2],
        [184, 207],
        [98, -6],
        [15, 14],
        [13, 18],
        [-36, 32],
        [-82, 12],
        [-25, -21],
        [-14, 53],
        [27, -2],
        [91, 18],
        [105, -11],
        [85, 38],
        [43, 6],
        [24, -8],
        [68, 25],
        [128, -29],
        [101, 24],
        [-12, -34],
        [-33, -21],
        [-53, -7],
        [-41, -50],
        [-87, 9],
        [-61, -18],
        [19, -14],
        [0, -51],
        [9, -11],
        [8, 0],
        [21, -38],
        [6, -25],
        [7, -53],
        [-10, -56],
        [-12, -26],
        [25, 9],
        [14, 26],
        [-2, 27],
        [2, 31],
        [14, -10],
        [9, -14],
        [32, -148],
        [22, -78],
        [6, 3],
        [5, 3],
        [7, 15],
        [10, 36],
        [9, -23],
        [5, -9],
        [5, -3],
        [-5, 34],
        [6, 42],
        [-2, 15],
        [10, 3],
        [12, -5],
        [17, -12],
        [30, -49],
        [20, -51],
        [1, -28],
        [8, -16],
        [12, -16],
        [7, -26],
        [1, 41],
        [-8, 30],
        [-2, 34],
        [40, 1],
        [10, 45],
        [21, -27],
        [56, -123],
        [21, -21],
        [62, -24],
        [38, -59],
        [23, -53],
        [-14, -56],
        [-36, -28],
        [-14, -35],
        [-9, -34],
        [0, -35],
        [-11, -40],
        [-1, -14],
        [-7, -56],
        [-15, -68],
        [-19, -72],
        [-104, -2],
        [26, -29],
        [23, -22],
        [39, -57],
        [30, 45],
        [44, 3],
        [48, 49],
        [18, 8],
        [88, -26],
        [22, 36],
        [17, 11],
        [48, -7],
        [42, -39],
      ],
      [
        [53455, 75978],
        [-3, -1],
        [0, 2],
        [0, 3],
        [3, 0],
        [0, -4],
      ],
      [
        [96317, 43302],
        [18, -191],
        [21, 1],
        [11, 10],
        [12, 45],
        [5, 70],
        [11, 10],
        [14, -8],
        [-6, -22],
        [4, -56],
        [10, -31],
        [7, -6],
        [14, -146],
        [6, -31],
        [-1, -25],
        [-29, -54],
        [-44, 1],
        [-30, -33],
        [-19, 3],
        [0, 37],
        [-17, 29],
        [-19, 63],
        [5, 112],
        [-33, 208],
        [-1, 52],
        [12, 68],
        [11, 3],
        [15, -56],
        [23, -53],
      ],
      [
        [96503, 42571],
        [12, -13],
        [7, 0],
        [4, -28],
        [40, -56],
        [11, 2],
        [9, -31],
        [17, -15],
        [5, -31],
        [12, -32],
        [-21, -38],
        [-41, 10],
        [-24, -44],
        [-21, 11],
        [-4, 23],
        [3, 8],
        [-13, 58],
        [-5, 90],
        [-9, 52],
        [-9, 23],
        [-20, -20],
        [-8, -3],
        [-18, 43],
        [9, 87],
        [4, 25],
        [15, 5],
        [23, -23],
        [22, -103],
      ],
      [
        [96790, 42177],
        [8, -8],
        [-4, -24],
        [-39, 27],
        [-30, -10],
        [-9, 1],
        [-9, 22],
        [-7, 44],
        [3, 30],
        [13, 22],
        [5, 3],
        [10, -26],
        [8, -18],
        [9, -8],
        [19, -43],
        [23, -12],
      ],
      [
        [96748, 42432],
        [-32, -6],
        [-44, 18],
        [-18, 25],
        [-8, 25],
        [15, 19],
        [23, 9],
        [27, 57],
        [10, -22],
        [10, -64],
        [11, -20],
        [6, -19],
        [0, -22],
      ],
      [
        [96790, 41738],
        [27, -82],
        [11, -7],
        [-17, -59],
        [-34, -5],
        [-41, 15],
        [15, 20],
        [-8, 23],
        [-13, 5],
        [-14, -11],
        [-6, 4],
        [9, 38],
        [23, 53],
        [6, 4],
        [6, 1],
        [6, -5],
        [30, 6],
      ],
      [
        [96641, 42951],
        [-19, -26],
        [-34, 2],
        [-13, 15],
        [42, 80],
        [49, 17],
        [-25, -88],
      ],
      [
        [96725, 42643],
        [-5, -1],
        [-4, 27],
        [-16, 141],
        [10, 126],
        [7, -27],
        [23, -221],
        [-3, -36],
        [-12, -9],
      ],
      [
        [96718, 43013],
        [-5, -36],
        [-11, 41],
        [-7, 175],
        [3, 16],
        [6, 1],
        [14, -121],
        [0, -76],
      ],
      [
        [96449, 42785],
        [-5, -15],
        [-30, 37],
        [7, 36],
        [32, -12],
        [-4, -46],
      ],
      [
        [96550, 43628],
        [-11, -29],
        [-32, 9],
        [-7, 8],
        [2, 48],
        [8, 17],
        [19, 15],
        [25, -24],
        [-4, -44],
      ],
      [
        [96524, 43832],
        [-4, -6],
        [-7, 4],
        [-16, 70],
        [4, 23],
        [21, 22],
        [18, -38],
        [2, -22],
        [0, -18],
        [-3, -16],
        [-13, -6],
        [-2, -13],
      ],
      [
        [97192, 40214],
        [-9, -31],
        [-15, 0],
        [-20, 22],
        [4, 29],
        [22, 5],
        [6, -2],
        [12, -23],
      ],
      [
        [97080, 40587],
        [-15, -63],
        [-25, 15],
        [-24, 45],
        [-12, 40],
        [8, 75],
        [12, 14],
        [13, -5],
        [6, -74],
        [37, -47],
      ],
      [
        [97036, 40932],
        [-12, -27],
        [-12, 3],
        [-72, 64],
        [3, 27],
        [-3, 67],
        [8, 37],
        [20, 15],
        [15, -8],
        [10, -54],
        [22, -22],
        [-16, -18],
        [27, -41],
        [10, -43],
      ],
      [
        [69707, 76179],
        [9, 11],
        [16, 10],
        [26, -20],
        [23, -25],
        [5, -12],
        [-1, -13],
        [-55, -49],
        [-33, -23],
        [-15, -4],
        [-4, -7],
        [-11, -55],
        [-21, -10],
        [-27, -18],
        [-19, -25],
        [-30, -61],
        [-76, -89],
        [-1, -18],
        [7, -15],
        [24, -10],
        [33, -27],
        [18, -21],
        [48, 27],
        [12, -6],
        [13, -28],
        [13, -80],
        [22, -21],
        [28, -17],
        [18, -5],
        [23, -20],
        [32, -7],
        [21, 7],
        [26, -17],
        [4, 7],
        [3, 119],
        [22, -20],
        [13, 1],
        [11, 14],
        [6, 20],
        [4, 39],
        [-5, 39],
        [10, 18],
        [7, 4],
        [6, -5],
        [3, -10],
        [1, -35],
        [16, -15],
        [10, -9],
        [9, -30],
        [9, -29],
        [6, -67],
        [22, -4],
        [26, -13],
        [17, 13],
        [14, -8],
        [5, -32],
        [-1, -29],
        [2, -24],
        [7, -7],
        [23, 15],
        [19, 2],
        [18, -14],
        [22, -21],
        [32, -57],
        [10, -8],
        [48, -4],
        [10, -12],
        [17, 0],
        [17, 11],
        [40, -19],
        [1, -10],
        [-7, -14],
        [-94, -78],
        [-7, -24],
        [-19, -31],
        [-21, -17],
        [-10, -1],
        [-46, 31],
        [-5, -7],
        [-4, -13],
        [0, -14],
        [10, -32],
        [-5, -21],
        [-8, -15],
        [-29, 13],
        [-6, 9],
        [0, 9],
        [-11, 0],
        [-17, -9],
        [-33, -57],
        [-12, -29],
        [-4, -18],
        [-15, -10],
        [-16, -4],
        [-20, -27],
        [-22, -20],
        [-7, 15],
        [-5, 17],
        [-6, 5],
        [-13, -4],
        [-17, -1],
        [-17, 19],
        [-23, 20],
        [-20, 6],
        [-58, -9],
        [-29, -9],
        [-9, -9],
      ],
      [
        [69710, 75022],
        [-16, -3],
        [-69, -19],
        [-14, 7],
        [-10, 31],
        [-9, 33],
        [-18, 12],
        [-20, 9],
        [-7, 13],
        [-1, 16],
        [3, 16],
        [1, 8],
        [46, 63],
        [42, 57],
        [4, 4],
        [3, 11],
        [7, 20],
        [0, 10],
        [-31, 23],
        [-1, 10],
        [6, 11],
        [0, 14],
        [-22, 41],
        [-38, 65],
        [-11, 6],
        [-8, -4],
        [-15, -62],
        [-7, -16],
        [-43, -41],
        [-37, -29],
        [-64, -50],
        [-17, -15],
        [-12, 2],
        [-11, 10],
        [-37, 51],
        [-23, 17],
        [-16, -17],
        [-13, -25],
        [1, -51],
        [-15, -27],
        [-15, -13],
        [27, -137],
        [-2, -18],
        [-21, -5],
        [15, -52],
        [-13, -6],
        [-32, 12],
        [-44, 8],
        [-84, -23],
        [-6, -9],
        [-2, -11],
        [5, -11],
        [40, -1],
        [40, 6],
        [11, -10],
        [1, -17],
        [-4, -11],
        [-13, -2],
        [-29, -10],
        [-4, -11],
        [-1, -10],
        [10, -31],
        [11, -19],
        [2, -11],
        [-5, -10],
        [-6, -4],
        [-9, 15],
        [-6, -3],
        [-2, -13],
        [-3, -15],
        [-7, -11],
        [-13, 6],
        [-13, -5],
        [-8, -55],
        [-7, -62],
        [-22, -42],
        [-12, -15],
        [-18, -4],
        [-27, 5],
        [-16, 6],
        [-46, 9],
        [-47, 17],
        [-53, 16],
        [-49, -37],
        [-14, -22],
        [-9, -21],
        [-9, -10],
        [-21, -129],
        [2, -14],
        [12, -12],
        [60, -26],
        [9, -11],
        [5, -13],
        [2, -58],
        [5, -8],
        [21, -7],
        [30, 0],
        [23, 6],
        [24, -5],
        [16, -13],
        [8, -20],
        [4, -21],
        [-28, -127],
        [3, -47],
        [8, -66],
        [16, -52],
        [30, -51],
        [23, -33],
        [5, -15],
        [1, -24],
        [-4, -31],
        [-13, -48],
        [-16, -42],
        [-17, -18],
        [-24, -54],
        [-22, -66],
        [-41, -86],
        [-13, -49],
        [-5, -139],
        [-11, -42],
      ],
      [
        [68821, 73255],
        [-1, 16],
        [-15, 16],
        [-26, -3],
        [-17, 8],
        [-8, 18],
        [-21, -5],
        [-34, -28],
        [-34, 14],
        [-35, 58],
        [-67, 21],
        [-85, -13],
      ],
      [
        [68478, 73357],
        [-4, 63],
        [1, 81],
        [4, 108],
        [28, 84],
        [0, 16],
        [-6, 15],
        [-9, 14],
        [-51, 23],
        [-15, 13],
        [-20, 26],
        [-25, 28],
        [-22, 19],
        [-34, 26],
        [-32, 14],
        [-19, -11],
        [-17, -14],
        [-16, 0],
        [-16, 7],
        [-59, 64],
        [-90, 110],
        [-71, 76],
        [-45, 37],
        [-10, 12],
        [-25, 34],
        [-62, 93],
        [-41, -14],
        [-58, 61],
        [-53, 59],
        [-11, 15],
        [-60, 109],
        [-60, 71],
        [-65, 77],
        [-42, 48],
        [-71, 82],
        [-34, 49],
        [-12, 18],
        [-12, 35],
        [-18, 170],
        [-22, 78],
        [-30, 43],
        [-26, 82],
        [-22, 121],
        [-17, 79],
        [-14, 36],
        [-29, 40],
        [-43, 44],
        [-41, 21],
        [-15, -1],
        [-7, -5],
        [-9, -8],
        [-16, -33],
        [-24, -3],
        [-17, 1],
        [-17, 12],
        [-52, 10],
        [-18, 11],
        [-31, -1],
        [-67, -17],
        [-17, 3],
        [-70, 73],
        [-31, 29],
        [-6, 16],
        [0, 28],
        [10, 40],
        [9, 28],
        [-4, 30],
        [-13, 32],
        [0, 34],
        [9, 19],
        [19, -6],
        [7, 12],
        [-3, 18],
        [-10, 13],
        [-13, 29],
        [-40, 27],
        [-6, 11],
        [2, 12],
        [7, 14],
        [1, 28],
        [1, 37],
        [5, 19],
        [2, 15],
        [-6, 12],
        [-14, 14],
        [-21, 34],
        [-27, 4],
        [-86, -1],
        [-27, 13],
        [-22, 19],
        [-21, 73],
        [-11, 17],
        [-11, 7],
        [-24, 2],
        [-29, 7],
        [-15, 13],
        [-41, 66],
        [-39, 59],
        [-16, -56],
        [-15, -11],
        [-34, 5],
        [-26, 10],
        [-15, -13],
        [-15, -22],
        [2, -15],
        [12, -15],
        [24, -28],
        [36, -70],
        [16, -38],
        [2, -13],
        [-3, -10],
        [-5, -5],
        [-7, 0],
        [-9, 1],
        [-6, 11],
        [-2, 20],
        [-12, 30],
        [-12, 17],
        [-14, 11],
        [-19, 8],
        [-25, 14],
        [-13, 1],
        [-12, -17],
        [-11, -22],
        [-6, -49],
        [-19, -60],
        [-11, -24],
        [-36, -14],
        [-84, -5],
        [-26, -19],
        [-17, -22],
        [-32, -74],
        [-22, -24],
        [-19, -33],
        [5, -108],
        [10, -126],
        [16, -35],
        [10, -11],
        [2, -12],
        [-7, -11],
        [-8, -14],
        [-13, -25],
        [-15, 1],
        [-29, 6],
        [-24, 7],
        [-82, 7],
        [-66, 6],
        [-73, 7],
      ],
      [
        [65549, 75646],
        [0, 132],
        [0, 132],
        [0, 132],
        [-1, 133],
        [0, 132],
        [0, 132],
        [0, 132],
        [0, 133],
        [0, 132],
        [0, 132],
        [0, 132],
        [0, 132],
        [0, 133],
        [0, 132],
        [0, 132],
        [0, 132],
        [35, 17],
        [44, 20],
        [42, 20],
        [49, 24],
        [57, 27],
        [48, 22],
        [57, 27],
        [44, 21],
        [41, 20],
        [53, 23],
        [82, 36],
        [45, 19],
        [46, 21],
        [44, 19],
        [30, 7],
        [31, -27],
        [39, -38],
        [38, -38],
        [38, -38],
        [39, -38],
        [38, -38],
        [39, -38],
        [38, -38],
        [38, -38],
        [39, -37],
        [38, -38],
        [38, -38],
        [39, -38],
        [38, -38],
        [38, -38],
        [39, -38],
        [38, -38],
        [36, -35],
        [16, -26],
        [9, -58],
        [17, -46],
        [31, -49],
        [32, -51],
        [39, -68],
        [27, -46],
        [28, -48],
        [45, -78],
        [29, -49],
        [23, -2],
        [46, 12],
        [61, 15],
        [49, 13],
        [59, 15],
        [56, 14],
        [44, 11],
        [66, -9],
        [65, -8],
        [47, -6],
        [46, -6],
        [54, -7],
        [30, -4],
        [35, -4],
        [15, 11],
        [30, 25],
        [28, 22],
        [30, 24],
        [26, 12],
        [27, -38],
        [22, -43],
        [24, -46],
        [28, -44],
        [27, -27],
        [36, -35],
        [20, -61],
        [28, -81],
        [18, -53],
        [19, -55],
        [27, 22],
        [29, 23],
        [27, 21],
        [-4, -68],
        [-3, -61],
        [-4, -93],
        [-4, -77],
        [-9, -91],
        [-1, -69],
        [0, -61],
        [-1, -48],
        [51, -2],
        [38, -2],
        [47, -2],
        [5, -61],
        [6, -85],
        [10, -77],
        [8, -65],
        [12, -84],
        [6, -45],
        [12, -53],
        [11, -12],
        [18, -9],
        [62, 6],
        [52, 6],
        [41, 4],
        [43, 4],
        [58, 6],
        [19, -13],
        [17, 9],
        [19, 9],
        [16, -38],
        [8, -19],
        [11, -21],
        [15, -18],
        [-7, -40],
        [-9, -57],
        [-3, -30],
        [18, -32],
        [14, -18],
        [36, -38],
        [34, -21],
        [23, -6],
        [21, 8],
        [8, 21],
        [-2, 30],
        [-10, 31],
        [1, 37],
        [6, 27],
        [22, 49],
        [21, 46],
        [32, 47],
        [37, 47],
        [16, 34],
        [6, 59],
        [25, 34],
        [26, 20],
        [33, 18],
        [9, 29],
        [46, 50],
        [27, 25],
        [35, 15],
        [48, 32],
        [37, 39],
        [36, 72],
        [29, 47],
        [24, 29],
        [21, 1],
        [14, -23],
        [12, -2],
        [8, 11],
        [14, 30],
        [14, 35],
        [14, 15],
        [27, 8],
        [24, 23],
      ],
      [
        [69625, 75424],
        [-1, 14],
        [-8, 23],
        [-13, 13],
        [-6, -5],
        [5, -19],
        [15, -28],
        [8, 2],
      ],
      [
        [69779, 74822],
        [-14, -5],
        [-26, 1],
        [-14, 6],
        [9, 46],
        [0, 10],
        [-1, 1],
        [-7, 6],
        [-12, 19],
        [-3, 29],
        [4, 26],
        [8, 11],
        [5, -2],
        [16, -40],
        [14, -12],
        [27, -6],
        [-14, -40],
        [10, -42],
        [-2, -8],
      ],
      [
        [69938, 74855],
        [-7, -24],
        [-13, 6],
        [-11, 16],
        [4, 13],
        [15, 7],
        [8, 7],
        [7, 1],
        [-3, -26],
      ],
      [
        [35174, 32406],
        [-13, -21],
        [-15, -41],
        [-17, -97],
        [-58, -133],
        [-12, -76],
        [-62, -78],
        [-44, -89],
        [-29, 2],
        [-26, -38],
        [-149, -115],
        [-54, 22],
        [-39, -1],
        [-37, 51],
        [-84, 19],
        [-53, -21],
        [-71, -56],
        [-21, 1],
        [-15, 3],
        [-39, 23],
        [-21, 50],
        [-108, 57],
        [-88, 129],
        [-104, 2],
        [-79, -17],
        [-12, 18],
        [-8, 33],
        [-17, 48],
        [-68, 114],
        [-54, 113],
        [-10, 111],
        [7, 121],
        [16, 144],
        [-2, 44],
        [19, 26],
        [20, 5],
        [19, 37],
        [17, 56],
        [3, 43],
        [-13, 78],
        [-10, 110],
        [-10, 55],
      ],
      [
        [33833, 33138],
        [21, 86],
        [1, 42],
        [-12, 37],
        [-4, 38],
        [6, 39],
        [-1, 37],
        [-8, 36],
        [6, 30],
        [20, 23],
        [15, 36],
        [10, 49],
        [5, 37],
        [0, 26],
        [-6, 24],
        [-12, 22],
        [5, 46],
        [24, 67],
        [15, 60],
        [7, 52],
        [0, 43],
        [-8, 32],
        [3, 22],
        [15, 11],
        [6, 34],
        [-2, 84],
        [-15, 70],
        [11, 55],
        [33, 64],
        [18, 52],
        [1, 39],
        [10, 22],
      ],
      [
        [33997, 34453],
        [16, -42],
        [47, -11],
        [47, -2],
        [7, 11],
        [19, 69],
        [24, 20],
        [27, 5],
        [29, -3],
        [31, -46],
        [87, -150],
        [64, -104],
        [20, -50],
        [17, -36],
        [12, -35],
        [-5, -89],
        [1, -39],
        [3, -11],
        [14, -1],
        [22, 6],
        [18, 19],
        [15, 29],
        [14, 23],
        [11, 13],
        [4, 19],
        [7, 19],
        [6, 4],
        [13, -14],
        [30, -51],
        [23, -47],
        [6, -27],
        [9, -28],
        [9, -25],
        [7, -23],
        [23, -32],
        [23, -19],
        [15, 20],
        [39, -65],
        [85, -54],
        [16, -33],
        [15, -46],
        [30, -71],
        [41, -63],
        [34, -27],
        [32, -15],
        [18, -14],
        [12, -24],
        [20, -27],
        [12, -9],
        [4, -24],
        [13, -51],
        [13, -65],
        [14, -60],
        [31, -57],
        [36, -45],
        [36, -26],
        [21, -31],
        [9, -33],
        [-25, -48],
        [-27, -62],
        [-23, -48],
        [-25, -33],
        [-8, -23],
        [-5, -36],
        [0, -190],
        [-2, -70],
        [2, -19],
        [3, -13],
        [16, -18],
        [18, -16],
        [7, -3],
      ],
      [
        [95272, 54910],
        [3, -27],
        [-18, 13],
        [-2, 10],
        [10, 10],
        [7, -6],
      ],
      [
        [88372, 57315],
        [-21, -47],
        [-1, 16],
        [6, 28],
        [9, 32],
        [8, 19],
        [11, 6],
        [8, -27],
        [-9, -23],
        [-11, -4],
      ],
      [
        [92123, 56074],
        [-2, -7],
        [-17, 3],
        [-2, 4],
        [1, 3],
        [8, 4],
        [1, 10],
        [-4, 2],
        [4, 6],
        [6, 1],
        [4, -7],
        [2, -9],
        [-1, -10],
      ],
      [
        [92188, 56124],
        [-4, -3],
        [-3, 3],
        [1, 14],
        [2, 5],
        [4, 1],
        [8, -4],
        [1, -4],
        [-9, -12],
      ],
      [
        [93975, 55768],
        [-16, -13],
        [-20, 5],
        [-6, 47],
        [-10, 13],
        [2, 23],
        [15, 19],
        [30, -15],
        [11, -34],
        [-7, -22],
        [1, -23],
      ],
      [
        [97120, 55201],
        [-6, -17],
        [-6, 1],
        [6, 13],
        [4, 18],
        [6, 51],
        [14, 19],
        [10, 21],
        [-3, -22],
        [-15, -23],
        [-10, -61],
      ],
      [
        [96896, 56053],
        [-4, -9],
        [-27, 5],
        [-12, 11],
        [1, 9],
        [22, -8],
        [20, -8],
      ],
      [
        [97659, 55903],
        [11, -13],
        [20, 1],
        [19, -32],
        [-7, 2],
        [-10, 14],
        [-10, 6],
        [-12, -2],
        [-6, 5],
        [-5, 19],
      ],
      [
        [97527, 55955],
        [35, -30],
        [46, 14],
        [-7, -9],
        [-17, -8],
        [-12, -8],
        [-8, 0],
        [-9, 3],
        [-29, 21],
        [-17, 27],
        [4, 9],
        [14, -19],
      ],
      [
        [96357, 58267],
        [-7, -4],
        [-5, 4],
        [4, 8],
        [8, 1],
        [3, -2],
        [-3, -7],
      ],
      [
        [90474, 62651],
        [-9, -22],
        [-7, 16],
        [-2, 31],
        [13, -3],
        [4, -6],
        [1, -16],
      ],
      [
        [90475, 61255],
        [-6, -4],
        [-9, 2],
        [-6, 9],
        [-2, 15],
        [18, 1],
        [7, -12],
        [-2, -11],
      ],
      [
        [90350, 59998],
        [-13, -27],
        [-10, 6],
        [-6, 9],
        [-2, 15],
        [22, 15],
        [10, -5],
        [-1, -13],
      ],
      [
        [90493, 62257],
        [-14, -13],
        [17, 57],
        [5, 10],
        [8, -21],
        [-16, -33],
      ],
      [
        [90486, 60560],
        [-1, -15],
        [-14, 4],
        [-4, 6],
        [8, 52],
        [20, 24],
        [10, 5],
        [-9, -25],
        [-2, -27],
        [-8, -24],
      ],
      [
        [90461, 60466],
        [-12, -29],
        [-8, 45],
        [-1, 19],
        [10, 17],
        [7, -1],
        [4, -51],
      ],
      [
        [31987, 62401],
        [-21, -5],
        [-28, 27],
        [22, 10],
        [15, -6],
        [12, -26],
      ],
      [
        [32039, 62415],
        [-19, -15],
        [-12, 2],
        [-5, 6],
        [10, 18],
        [26, -11],
      ],
      [
        [32009, 62093],
        [24, -26],
        [28, 0],
        [-30, -25],
        [-56, -3],
        [1, 41],
        [33, 13],
      ],
      [
        [90205, 59480],
        [-12, -1],
        [-10, 20],
        [-3, 13],
        [-1, 66],
        [40, 57],
        [13, 55],
        [10, -5],
        [10, -9],
        [8, -16],
        [-44, -92],
        [-11, -88],
      ],
      [
        [2576, 43576],
        [-12, -5],
        [-14, 27],
        [28, 21],
        [8, 11],
        [34, -6],
        [-20, -8],
        [-24, -40],
      ],
      [
        [31630, 62468],
        [9, -12],
        [8, 2],
        [-6, 23],
        [6, 0],
        [53, -14],
        [34, -24],
        [35, -12],
        [3, -80],
        [-27, -32],
        [-18, -34],
        [-15, -41],
        [-38, -47],
        [-45, -15],
        [-31, -1],
        [-11, 1],
        [-11, 9],
        [-23, -8],
        [-29, 21],
        [-24, -5],
        [-48, 5],
        [-18, -19],
        [-18, -4],
        [-17, 4],
        [-14, 8],
        [-36, -1],
        [-15, 16],
        [6, 91],
        [1, 41],
        [-9, 35],
        [-10, 21],
        [-7, 25],
        [14, 17],
        [12, 24],
        [4, 37],
        [12, 9],
        [15, 4],
        [69, -17],
        [173, -10],
        [10, -3],
        [6, -14],
      ],
      [
        [31826, 62272],
        [-22, -3],
        [-14, 4],
        [-5, 17],
        [27, 16],
        [30, -2],
        [18, -10],
        [2, -6],
        [-36, -16],
      ],
      [
        [31146, 62246],
        [-2, -1],
        [-3, 1],
        [-1, 1],
        [-2, 6],
        [-8, 8],
        [-2, 8],
        [2, 9],
        [3, 3],
        [16, 1],
        [2, -1],
        [3, -6],
        [0, -4],
        [-1, -4],
        [-3, -10],
        [-1, -2],
        [-1, -3],
        [-1, -5],
        [-1, -1],
      ],
      [
        [13126, 84403],
        [-3, -8],
        [-36, 1],
        [-12, 7],
        [-5, 24],
        [3, 23],
        [8, 18],
        [10, 34],
        [8, 56],
        [52, -63],
        [16, -28],
        [8, -35],
        [-18, -13],
        [-22, -6],
        [-9, -10],
      ],
      [
        [13117, 84243],
        [-15, -2],
        [-16, 9],
        [-40, 47],
        [-1, 14],
        [6, 15],
        [22, 29],
        [10, 7],
        [54, -3],
        [17, -8],
        [4, -13],
        [0, -14],
        [-6, -13],
        [-1, -15],
        [3, -15],
        [-7, -15],
        [-30, -23],
      ],
      [
        [12691, 85385],
        [-2, -15],
        [-38, 2],
        [-38, 21],
        [-19, 27],
        [4, 13],
        [35, 11],
        [34, -26],
        [24, -33],
      ],
      [
        [9689, 86599],
        [-9, -14],
        [-24, 5],
        [-13, 9],
        [44, 37],
        [7, -8],
        [-5, -29],
      ],
      [
        [9843, 86300],
        [-14, -3],
        [20, 38],
        [27, 41],
        [26, 27],
        [32, 11],
        [-3, -20],
        [-43, -35],
        [-45, -59],
      ],
      [
        [8883, 86442],
        [-15, -17],
        [-55, 10],
        [12, 35],
        [42, 22],
        [46, -34],
        [-30, -16],
      ],
      [
        [7772, 86613],
        [-13, -2],
        [17, 28],
        [13, 56],
        [17, -8],
        [3, -10],
        [-28, -57],
        [-9, -7],
      ],
      [
        [5464, 83719],
        [-4, -45],
        [-38, 29],
        [-10, 14],
        [7, 11],
        [36, 2],
        [9, -11],
      ],
      [
        [5733, 83509],
        [-9, -3],
        [-8, 6],
        [-10, 33],
        [-1, 13],
        [20, -10],
        [7, -24],
        [1, -15],
      ],
      [
        [5690, 83612],
        [-1, -46],
        [-4, -7],
        [-8, 12],
        [-15, -13],
        [-9, 10],
        [4, 16],
        [-1, 12],
        [11, 1],
        [3, 23],
        [-2, 9],
        [6, 21],
        [8, 5],
        [8, -43],
      ],
      [
        [3831, 82789],
        [-4, -1],
        [-7, 14],
        [-1, 13],
        [5, 9],
        [13, 22],
        [9, 8],
        [11, 4],
        [3, -6],
        [-10, -26],
        [-12, -17],
        [-7, -20],
      ],
      [
        [1109, 81688],
        [-24, -12],
        [-31, 25],
        [3, 30],
        [34, -25],
        [18, -18],
      ],
      [
        [3858, 89992],
        [-10, -3],
        [0, 9],
        [32, 23],
        [58, 29],
        [-2, -6],
        [-31, -23],
        [-47, -29],
      ],
      [
        [2371, 88502],
        [5, -14],
        [29, 2],
        [40, -6],
        [45, -14],
        [45, 5],
        [56, 43],
        [33, 11],
        [34, 6],
        [37, -10],
        [35, -23],
        [14, -13],
        [11, -24],
        [7, -28],
        [11, -21],
        [67, -25],
        [42, -10],
        [10, -14],
        [9, -19],
        [36, -14],
        [37, 5],
        [20, -5],
        [63, -1],
        [77, -21],
        [-12, -56],
        [-25, -24],
        [-72, 7],
        [-71, -8],
        [-29, -28],
        [-25, -37],
        [-3, -35],
        [-14, -16],
        [-15, -7],
        [-12, 19],
        [-17, 60],
        [-11, 16],
        [-12, 11],
        [-35, 19],
        [-35, 12],
        [-21, 1],
        [-15, 21],
        [-8, 30],
        [-14, 15],
        [-28, 22],
        [-29, 17],
        [-89, 38],
        [-29, 5],
        [-30, -4],
        [-32, -17],
        [-31, -26],
        [-31, -19],
        [-33, -4],
        [-32, 11],
        [-29, 25],
        [-15, 18],
        [-8, 30],
        [1, 30],
        [4, 29],
        [16, 71],
        [27, 14],
        [51, -50],
      ],
      [
        [3851, 86626],
        [26, -29],
        [13, -1],
        [43, 8],
        [16, -6],
        [15, -12],
        [9, -19],
        [2, -33],
        [-7, -30],
        [2, -41],
        [-1, -18],
        [22, -24],
        [8, -32],
        [3, -34],
        [-49, -12],
        [-49, -2],
        [-43, -23],
        [-9, -17],
        [7, -26],
        [-11, -6],
        [-11, 5],
        [-21, 24],
        [-22, 11],
        [-79, 18],
        [-100, 69],
        [-42, 14],
        [-44, 50],
        [-39, 64],
        [25, 10],
        [26, 5],
        [116, -9],
        [14, 46],
        [15, 11],
        [36, 13],
        [35, 25],
        [15, 0],
        [16, -10],
        [32, 15],
        [17, 3],
        [14, -7],
      ],
      [
        [7528, 85151],
        [2, -31],
        [12, 3],
        [42, 33],
        [23, 10],
        [29, 1],
        [23, -15],
        [4, -11],
        [-2, -14],
        [-18, -27],
        [1, -18],
        [19, -33],
        [49, -18],
        [6, -10],
        [0, -12],
        [-34, -55],
        [-12, -12],
        [-9, -3],
        [-61, 9],
        [-55, 18],
        [-23, 3],
        [-8, -5],
        [-15, -17],
        [11, -5],
        [49, -4],
        [17, -25],
        [7, -18],
        [4, -20],
        [-10, -8],
        [-20, -6],
        [-25, 0],
        [-31, -22],
        [-17, -25],
        [-62, -7],
        [-47, -34],
        [-17, -17],
        [-6, -20],
        [-17, -15],
        [-41, -14],
        [24, -13],
        [4, -11],
        [1, -16],
        [-4, -13],
        [-31, -59],
        [-60, -48],
        [-15, 2],
        [-7, 6],
        [-5, 9],
        [-1, 9],
        [78, 98],
        [-3, 4],
        [-21, 4],
        [-34, 26],
        [-23, -17],
        [-5, 1],
        [6, 23],
        [15, 27],
        [-3, 8],
        [-8, 7],
        [-19, 4],
        [-31, 1],
        [-22, -6],
        [-15, -15],
        [-1, -6],
        [31, 2],
        [9, -7],
        [8, -14],
        [5, -16],
        [2, -17],
        [-7, -24],
        [-14, -30],
        [-22, 5],
        [-44, 67],
        [-20, 97],
        [-38, 75],
        [-2, 18],
        [11, 46],
        [38, 65],
        [42, 18],
        [29, 27],
        [28, 9],
        [18, -1],
        [24, -12],
        [10, -25],
        [-6, -12],
        [2, -6],
        [17, -15],
        [18, -52],
        [21, -47],
        [14, -19],
        [19, -12],
        [-19, 36],
        [-12, 44],
        [-5, 88],
        [-6, 23],
        [11, 6],
        [30, -3],
        [-1, 13],
        [-32, 29],
        [-19, 25],
        [-8, 19],
        [1, 17],
        [17, 25],
        [10, 7],
        [10, 3],
        [21, -5],
        [9, -8],
        [26, -56],
        [12, -17],
        [10, 0],
        [10, 9],
        [9, 18],
        [8, 12],
        [10, 3],
        [29, -8],
        [10, 3],
        [4, 14],
        [0, 25],
        [7, 9],
        [2, 18],
        [-16, 27],
        [18, 9],
        [61, -21],
        [25, -23],
        [-13, -42],
      ],
      [
        [13882, 84036],
        [-14, -30],
        [-10, -32],
        [-7, -35],
        [-3, -37],
        [2, -40],
        [5, -35],
        [17, -65],
        [6, -39],
        [1, -27],
        [-38, -92],
        [-13, -45],
        [1, -19],
      ],
      [
        [13829, 83540],
        [-27, -47],
        [-50, -64],
        [-23, -37],
      ],
      [
        [13729, 83392],
        [-11, 12],
        [-65, 10],
        [-24, 82],
        [-12, 64],
        [-19, 55],
        [0, 13],
        [17, 37],
        [65, 31],
        [1, 12],
        [-24, 8],
        [-6, 13],
        [-7, 60],
        [2, 53],
        [-2, 35],
        [-10, 72],
        [-17, 43],
        [-41, 86],
        [-4, 21],
        [18, 27],
        [12, 25],
        [-71, -43],
        [-96, -46],
        [-42, -32],
        [-9, -13],
        [-3, -11],
        [8, -30],
        [-1, -10],
        [-9, -18],
        [-10, -51],
        [-21, -53],
        [-10, -11],
        [-38, 20],
        [-10, 17],
        [-19, 70],
        [5, 19],
        [13, 15],
        [19, 34],
        [24, 52],
        [45, 133],
        [29, 1],
        [52, 26],
        [-82, 13],
        [-12, 7],
        [-11, 18],
        [-9, 29],
        [-17, 33],
        [-31, 11],
        [-13, 12],
        [-21, 39],
        [-14, 18],
        [-7, 22],
        [-1, 26],
        [-6, 13],
        [-21, 5],
        [-12, 9],
        [-3, 67],
        [-42, 17],
        [-18, 15],
        [-28, 42],
        [-7, 21],
        [-3, 17],
        [7, 46],
        [-3, 9],
        [-25, -5],
        [-153, 72],
        [8, 95],
        [-28, 125],
        [-31, 51],
        [6, 19],
        [7, 11],
        [13, 0],
        [59, -37],
        [56, -44],
        [7, 7],
        [-89, 92],
        [-22, 28],
        [-5, 33],
        [-1, 18],
        [7, 10],
        [84, -9],
        [4, 7],
        [-84, 27],
        [-17, 0],
        [-18, -39],
        [-9, -9],
        [-18, 2],
        [-6, 6],
        [-22, 47],
        [-20, 33],
        [-38, 45],
        [-7, 33],
        [-2, 47],
        [5, 45],
        [31, 103],
        [13, 18],
        [3, 11],
        [-10, -1],
        [-9, -10],
        [-25, -48],
        [-27, -78],
        [-21, -27],
        [-14, 6],
        [-20, 32],
        [-43, 39],
        [-50, 10],
        [-31, 40],
        [-46, 111],
        [-6, 55],
        [-6, 14],
        [-25, 18],
        [-16, 26],
        [-24, 135],
        [-31, 93],
        [-8, 50],
        [3, 49],
        [-4, 5],
        [-11, -38],
        [-3, -20],
        [-20, -5],
        [19, -39],
        [5, -19],
        [-10, 1],
        [-19, -5],
        [33, -66],
        [14, -102],
        [21, -76],
        [14, -62],
        [7, -47],
        [9, -44],
        [25, -99],
        [3, -20],
        [-3, -16],
        [-8, -19],
        [-14, -7],
        [-45, 13],
        [-17, 25],
        [-24, 44],
        [-34, 21],
        [-84, -10],
        [-6, 3],
        [0, 37],
        [10, 65],
        [-8, 26],
        [-44, 96],
        [1, 19],
        [60, 44],
        [-29, 3],
        [-24, -17],
        [-9, 11],
        [-14, 62],
        [-9, 23],
        [-5, 4],
        [-2, -58],
        [10, -31],
        [1, -17],
        [-1, -25],
        [-7, -18],
        [-11, -11],
        [-11, -2],
        [-20, 12],
        [-23, 23],
        [-19, 11],
        [-8, 10],
        [-9, 25],
        [-15, 20],
        [-74, 24],
        [-44, 30],
        [-3, -8],
        [13, -31],
        [2, -19],
        [-11, -5],
        [-20, -30],
        [6, -4],
        [21, 10],
        [23, -1],
        [39, -19],
        [35, -23],
        [12, -13],
        [6, -20],
        [4, -7],
        [34, -23],
        [2, -12],
        [-22, -35],
        [45, 3],
        [27, -12],
        [34, -56],
        [11, -30],
        [2, -40],
        [-7, -11],
        [-14, -8],
        [-92, -13],
        [-34, -48],
        [-7, -1],
        [-25, 13],
        [-46, 38],
        [-58, 36],
        [-131, 108],
        [-3, 5],
        [-3, 20],
        [-9, 11],
        [-17, 9],
        [-25, 27],
        [-32, 45],
        [-19, 36],
        [-8, 25],
        [-18, 29],
        [-59, 61],
        [-31, 23],
        [-28, 13],
        [-24, 3],
        [-6, 8],
        [11, 13],
        [2, 8],
        [-53, 13],
        [-50, 28],
        [-127, 80],
        [-65, 50],
        [-39, 24],
        [-16, 13],
        [-7, 11],
        [9, 12],
        [26, 12],
        [17, 13],
        [27, 51],
        [2, 16],
        [-14, 37],
        [-7, 33],
        [0, 19],
        [4, 18],
        [4, 12],
        [11, 12],
        [9, 6],
        [10, -4],
        [32, -46],
        [4, -17],
        [-1, -63],
        [9, -73],
        [3, 5],
        [3, 24],
        [2, 47],
        [4, 22],
        [6, 22],
        [12, 12],
        [36, -7],
        [17, 4],
        [-71, 33],
        [-44, 62],
        [-8, 7],
        [-24, 3],
        [-26, -26],
        [-66, -82],
        [-19, -14],
        [-83, -46],
        [-57, -9],
        [-63, 7],
        [-54, 15],
        [-136, 72],
        [-21, 17],
        [31, 44],
        [2, 14],
        [-11, 45],
        [-9, 13],
        [-13, 8],
        [-4, -6],
        [0, -13],
        [3, -25],
        [-10, -13],
        [-23, -14],
        [-39, -15],
        [-120, 37],
        [-124, 30],
        [-110, 7],
        [-156, -25],
        [-83, -24],
        [-48, -2],
        [-47, 4],
        [-4, 17],
        [21, 10],
        [-1, 12],
        [-27, 39],
        [-40, 23],
        [-55, 8],
        [-32, 11],
        [-8, 14],
        [-19, 14],
        [-31, 13],
        [-13, 23],
        [10, 71],
        [11, 43],
        [10, 30],
        [27, 48],
        [-9, -3],
        [-39, -36],
        [-33, -37],
        [-32, -48],
        [-18, -22],
        [-24, -20],
        [-37, 5],
        [-50, 30],
        [-43, 15],
        [-36, 1],
        [-15, 5],
        [25, 27],
        [14, 22],
        [19, 34],
        [5, 17],
        [-132, 5],
        [-5, 19],
        [0, 13],
        [-4, 11],
        [-19, 8],
        [-27, -6],
        [-43, -22],
        [-19, 17],
        [7, 9],
        [14, 7],
        [28, 31],
        [-38, 16],
        [-20, 18],
        [-10, 16],
        [1, 55],
        [10, 35],
        [87, 34],
        [-27, 13],
        [-56, -5],
        [-37, -29],
        [-43, -41],
        [-30, -16],
        [-15, 11],
        [-20, 3],
        [-25, -3],
        [-16, -11],
        [-9, -18],
        [-10, -12],
        [-11, -6],
        [-8, 2],
        [-12, 18],
        [-25, 12],
        [-12, 14],
        [-7, -9],
        [-9, -27],
        [-9, -13],
        [-42, -14],
        [-23, 2],
        [-28, 34],
        [-4, 12],
        [10, 29],
        [61, 115],
        [-6, -1],
        [-20, -18],
        [-39, -46],
        [-18, -14],
        [-30, -1],
        [-14, 5],
        [-17, -4],
        [-20, -12],
        [-13, -14],
        [-6, -15],
        [4, -2],
        [30, 17],
        [17, 4],
        [5, -8],
        [-24, -52],
        [-14, -50],
        [-13, -12],
        [-22, 2],
        [-24, -5],
        [0, -14],
        [44, -39],
        [16, -6],
        [20, -14],
        [3, -14],
        [-7, -38],
        [-6, -15],
        [-9, -8],
        [-36, 1],
        [-12, -4],
        [-24, -24],
        [-12, -19],
        [4, -2],
        [21, 16],
        [31, 9],
        [39, 1],
        [30, 9],
        [20, 16],
        [19, -4],
        [18, -26],
        [6, -22],
        [-8, -19],
        [-15, -13],
        [-23, -9],
        [-15, -12],
        [-6, -16],
        [-3, -24],
        [-1, -32],
        [6, -58],
        [-5, -7],
        [-8, -5],
        [-13, 0],
        [-12, -14],
        [-27, -76],
        [-9, -9],
        [-12, 8],
        [-10, 0],
        [-9, -10],
        [-19, -8],
        [-30, -5],
        [-25, 2],
        [-45, 16],
        [-18, 12],
        [-15, 19],
        [-40, -20],
        [-11, 9],
        [-25, 53],
        [-5, -3],
        [-5, -58],
        [-8, -20],
        [-25, -41],
        [-13, -71],
        [-4, -3],
        [-5, 11],
        [-15, 63],
        [-8, 14],
        [-23, -36],
        [-2, -14],
        [6, -47],
        [-6, -7],
        [-45, 25],
        [-11, 2],
        [-3, -5],
        [15, -37],
        [-1, -13],
        [-65, -71],
        [-17, 3],
        [-10, 7],
        [-12, -1],
        [-41, -27],
        [-11, 1],
        [-15, 16],
        [-7, -1],
        [-4, -16],
        [-1, -32],
        [-15, -30],
        [-49, -49],
        [-13, -23],
        [-9, -31],
        [-8, -3],
        [-28, 20],
        [-33, 13],
        [-5, -6],
        [10, -19],
        [-2, -12],
        [-14, -4],
        [-18, 2],
        [-22, 7],
        [-31, -8],
        [-40, -25],
        [-33, 0],
        [-46, 41],
        [-12, 3],
        [-4, 12],
        [9, 33],
        [13, 25],
        [9, 12],
        [44, 32],
        [50, 12],
        [31, 19],
        [39, 40],
        [20, 30],
        [40, 77],
        [-3, 6],
        [-9, 4],
        [-88, -73],
        [-13, -7],
        [-17, 1],
        [-70, 28],
        [-14, 12],
        [-11, 35],
        [20, 80],
        [13, 39],
        [35, 60],
        [44, 64],
        [15, 41],
        [24, 110],
        [-2, 51],
        [-10, 61],
        [-1, 36],
        [10, 12],
        [102, 56],
        [49, 43],
        [94, 62],
        [25, 0],
        [19, -22],
        [22, -17],
        [25, -13],
        [32, 1],
        [39, 16],
        [62, -6],
        [128, -41],
        [27, -2],
        [1, 5],
        [-19, 29],
        [-89, 17],
        [-37, 16],
        [-104, 74],
        [-24, 28],
        [10, 14],
        [26, 11],
        [8, 10],
        [4, 19],
        [15, 25],
        [25, 32],
        [39, 31],
        [75, 46],
        [-29, 2],
        [-54, -8],
        [-19, -9],
        [-36, -33],
        [-14, -23],
        [-20, -46],
        [-8, -8],
        [-37, -7],
        [-101, -4],
        [-17, 23],
        [-9, 4],
        [-13, -4],
        [-92, -59],
        [-34, -30],
        [-23, -35],
        [-37, -25],
        [-49, -16],
        [-37, -20],
        [-39, -40],
        [-14, -30],
        [0, -14],
        [9, -45],
        [-10, -9],
        [-22, -3],
        [-36, -30],
        [-76, -89],
        [-10, -32],
        [0, -11],
        [12, -25],
        [-8, -17],
        [-22, -25],
        [-48, -41],
        [-31, -16],
        [-20, -1],
        [-20, 6],
        [-35, 26],
        [-28, 2],
        [-2, -4],
        [39, -28],
        [39, -36],
        [24, -30],
        [10, -24],
        [0, -25],
        [-9, -25],
        [-27, -44],
        [-27, -13],
        [-70, -13],
        [-22, -11],
        [-7, -8],
        [48, -18],
        [4, -10],
        [-6, -37],
        [-13, -12],
        [-40, -22],
        [-35, -6],
        [-6, 4],
        [7, 29],
        [-2, 7],
        [-13, 6],
        [-19, -11],
        [-47, -43],
        [-5, -7],
        [17, -11],
        [-4, -10],
        [-26, -30],
        [-10, -21],
        [-17, -20],
        [-76, -64],
        [5, -15],
        [-19, -56],
        [-11, -49],
        [13, -20],
        [64, -24],
        [31, -6],
        [37, -17],
        [66, -45],
        [22, -30],
        [3, -14],
        [-2, -15],
        [-8, -21],
        [-21, -38],
        [-50, -58],
        [-22, -16],
        [-34, -13],
        [-12, -10],
        [-43, -54],
        [-12, -30],
        [2, -26],
        [-9, -18],
        [-56, -35],
        [2, -6],
        [20, -3],
        [-7, -31],
        [-3, -43],
        [-10, -8],
        [-36, 1],
        [-44, -17],
        [-3, -5],
        [-1, -31],
        [-118, -23],
        [-25, -59],
        [-14, -18],
        [-45, -43],
        [-29, -18],
        [-32, -10],
        [-17, -15],
        [-1, -18],
        [-9, -17],
        [-28, -26],
        [-14, -34],
        [-10, -5],
        [-51, -8],
        [-11, -11],
        [-5, -46],
        [-9, -1],
        [-19, 11],
        [-24, -9],
        [-53, -51],
        [-12, -18],
        [1, -10],
        [8, -10],
        [13, -31],
        [-1, -20],
        [-20, -58],
        [-8, -9],
        [-25, -14],
        [-10, -32],
        [-23, 4],
        [-19, -6],
        [-12, -21],
        [-14, -12],
        [-14, -4],
        [-18, -16],
        [-22, -31],
        [-20, -19],
        [-18, -9],
        [-18, -2],
        [-18, 5],
        [-16, -4],
        [-14, -11],
        [-13, -18],
        [-11, -50],
        [-14, -22],
        [-9, -4],
        [-17, 4],
        [-27, 10],
        [-28, -4],
        [-44, -30],
        [-14, -23],
        [28, -5],
        [14, -7],
        [-1, -7],
        [-14, -6],
        [-25, 1],
        [-14, -6],
        [-19, -13],
        [-45, -14],
        [-17, -10],
        [-34, -58],
        [-4, -13],
        [4, -3],
        [19, 6],
        [23, -10],
        [11, -12],
        [8, -15],
        [7, -29],
        [4, -4],
        [-43, -49],
        [-12, -21],
        [-8, -8],
        [-5, 6],
        [-6, 55],
        [-3, 9],
        [-10, 1],
        [-10, -17],
        [-22, -65],
        [-23, -32],
        [-178, -83],
        [-26, -19],
        [-5, -35],
        [-7, -31],
        [-12, -24],
        [-14, -16],
        [-3, 11],
        [1, 86],
        [-3, 18],
        [-18, 11],
        [-8, -2],
        [-11, -5],
        [-18, -18],
        [-10, -5],
        [-14, 1],
        [-23, -18],
        [-55, -59],
        [-36, -15],
        [-10, -12],
        [-15, -32],
        [-10, -12],
        [-15, -1],
        [-20, 10],
        [-16, -7],
        [-12, -24],
        [-13, -9],
        [-35, 17],
        [-15, -11],
        [-20, -31],
        [-21, -20],
        [-22, -9],
        [-56, -11],
        [-23, 7],
        [-5, 9],
        [1, 38],
        [9, 28],
        [9, 13],
        [11, 12],
        [17, 1],
        [31, -8],
        [-4, 9],
        [-11, 11],
        [-29, 19],
        [-28, 10],
        [-16, -6],
        [-23, -15],
        [-15, -17],
        [-8, -19],
        [-10, -63],
        [-6, -17],
        [-67, -111],
        [-26, -34],
        [-25, 2],
        [-13, -13],
        [-17, -27],
        [-17, -13],
        [-14, 1],
        [-12, 5],
        [-8, 9],
        [1, 9],
        [11, 8],
        [-4, 22],
        [-19, 37],
        [-13, 19],
        [-24, 3],
        [-4, -17],
        [8, -84],
        [-1, -19],
        [-15, -24],
        [-41, -27],
        [-12, 2],
        [-37, 53],
        [-34, 11],
        [-2, -17],
        [7, -36],
        [-8, -32],
        [-25, -31],
        [-19, -15],
        [-13, 1],
        [-1, 21],
        [12, 42],
        [3, 35],
        [-6, 28],
        [1, 22],
        [7, 15],
        [45, 41],
        [20, 7],
        [10, -11],
        [13, -1],
        [15, 6],
        [10, 14],
        [4, 19],
        [20, 26],
        [35, 31],
        [40, 57],
        [46, 83],
        [53, 72],
        [62, 60],
        [66, 48],
        [134, 66],
        [10, -4],
        [-12, -21],
        [8, -14],
        [13, -1],
        [49, 10],
        [20, 14],
        [6, -13],
        [-7, -17],
        [-30, -17],
        [1, -14],
        [43, -67],
        [13, -10],
        [12, 1],
        [4, 9],
        [-3, 48],
        [14, 9],
        [30, 2],
        [19, -6],
        [9, -15],
        [17, -12],
        [25, -10],
        [15, 3],
        [6, 16],
        [-10, 19],
        [-48, 40],
        [-13, 18],
        [-3, 23],
        [7, 31],
        [14, 45],
        [24, 60],
        [21, 42],
        [42, 47],
        [29, 23],
        [72, 72],
        [140, 73],
        [34, 47],
        [47, 52],
        [20, 13],
        [0, -20],
        [6, -18],
        [32, -12],
        [20, -4],
        [10, 4],
        [2, 19],
        [-4, 34],
        [-1, 33],
        [2, 30],
        [4, 24],
        [21, 43],
        [31, 49],
        [43, 57],
        [27, 26],
        [25, 14],
        [24, 25],
        [42, 58],
        [14, 10],
        [30, 11],
        [11, -5],
        [7, -14],
        [8, -10],
        [30, -8],
        [21, 13],
        [-4, 7],
        [-16, 5],
        [-11, 8],
        [-10, 35],
        [-20, 21],
        [-5, 24],
        [4, 37],
        [17, 87],
        [3, 90],
        [16, 51],
        [31, 19],
        [69, 13],
        [-40, 23],
        [-16, 0],
        [-26, 11],
        [-10, 56],
        [0, 40],
        [18, 47],
        [64, 80],
        [71, 55],
        [-10, 4],
        [-8, 17],
        [32, 110],
        [32, 98],
        [-43, -84],
        [-50, -64],
        [-145, -74],
        [-99, -63],
        [-47, -14],
        [-31, 15],
        [-24, 60],
        [-14, 22],
        [-18, 39],
        [8, 50],
        [14, 35],
        [31, 6],
        [34, -17],
        [31, -1],
        [-39, 34],
        [-56, 30],
        [-26, -9],
        [-19, -49],
        [-26, -34],
        [-23, 12],
        [-14, 14],
        [10, -41],
        [-18, -63],
        [-6, -43],
        [25, -115],
        [-5, -45],
        [-45, -21],
        [-37, 37],
        [-76, 145],
        [-27, 41],
        [-60, 69],
        [-20, -10],
        [-25, -34],
        [-24, -9],
        [-65, 50],
        [-30, 37],
        [-28, 46],
        [-44, -25],
        [-38, -30],
        [-44, -48],
        [-30, 0],
        [-81, -41],
        [-9, -1],
        [-11, -22],
        [-11, -10],
        [-10, -43],
        [-109, -33],
        [-108, 19],
        [38, 23],
        [42, 19],
        [37, 44],
        [-16, 60],
        [-3, 30],
        [1, 38],
        [40, 54],
        [-42, 0],
        [-27, -19],
        [-25, 40],
        [-12, 79],
        [29, 47],
        [13, 36],
        [12, 50],
        [1, 43],
        [-23, 72],
        [-63, 153],
        [-29, 115],
        [-50, 60],
        [37, 100],
        [41, 91],
        [54, 40],
        [-4, 6],
        [-30, 0],
        [-19, -5],
        [-18, -30],
        [-18, -22],
        [-56, -116],
        [-37, -56],
        [-23, -16],
        [38, -22],
        [6, -19],
        [7, -41],
        [-10, -51],
        [-10, -28],
        [-45, 2],
        [-40, -41],
        [-95, -44],
        [-128, -26],
        [-63, 3],
        [-65, 52],
        [0, 30],
        [3, 26],
        [-94, 90],
        [-54, 89],
        [-38, 2],
        [-33, 23],
        [-39, 37],
        [3, 30],
        [6, 21],
        [-24, 15],
        [-31, -2],
        [-36, 11],
        [94, 114],
        [32, 77],
        [27, 11],
        [34, -12],
        [47, -30],
        [40, -14],
        [14, -14],
        [15, -27],
        [-16, -45],
        [-14, -31],
        [17, 8],
        [50, 49],
        [37, 43],
        [17, -4],
        [12, -8],
        [20, -44],
        [25, -45],
        [56, 43],
        [30, 53],
        [-25, 24],
        [-31, 13],
        [-79, 19],
        [20, 15],
        [50, -2],
        [19, 15],
        [-20, 20],
        [-25, 18],
        [-68, -60],
        [-124, 3],
        [-87, 35],
        [-87, -6],
        [-13, 7],
        [-17, 19],
        [49, 45],
        [34, 25],
        [2, 14],
        [-20, 2],
        [-38, -12],
        [-17, 21],
        [3, 36],
        [-6, -4],
        [-15, -19],
        [-21, 10],
        [-18, 16],
        [9, 17],
        [19, 24],
        [-8, 3],
        [-17, -5],
        [-16, -31],
        [3, -25],
        [0, -36],
        [-28, -7],
        [-24, 5],
        [-17, 36],
        [-17, 78],
        [-48, 20],
        [-12, 39],
        [30, 51],
        [-13, 26],
        [-32, 8],
        [-37, -25],
        [-17, 22],
        [-3, 25],
        [-1, 36],
        [10, 3],
        [9, -7],
        [74, 20],
        [7, 10],
        [-59, 30],
        [-16, 31],
        [24, 18],
        [44, 2],
        [61, 19],
        [-25, 33],
        [-6, 18],
        [-5, 31],
        [10, 51],
        [72, 117],
        [71, 98],
        [22, 22],
        [32, 12],
        [30, -9],
        [31, -21],
        [6, 9],
        [-11, 9],
        [-13, 40],
        [43, 15],
        [26, 45],
        [2, 13],
        [-28, -18],
        [-29, -31],
        [-7, 31],
        [-7, 71],
        [12, 68],
        [10, 30],
        [24, 29],
        [69, 11],
        [13, -6],
        [2, 14],
        [-41, 42],
        [17, 34],
        [15, 17],
        [84, 27],
        [45, -9],
        [58, -31],
        [33, -39],
        [-5, -20],
        [-8, -12],
        [-17, -13],
        [-7, -10],
        [3, -8],
        [25, 24],
        [40, 28],
        [23, -12],
        [17, -23],
        [20, 1],
        [63, 19],
        [32, 20],
        [39, 53],
        [51, 34],
        [73, 108],
        [21, 44],
        [25, 7],
        [23, -4],
        [15, -37],
        [23, -10],
        [130, 9],
        [67, 16],
        [46, 35],
        [48, 59],
        [28, 40],
        [13, 52],
        [-17, 66],
        [-18, 56],
        [-23, 127],
        [-64, 83],
        [-46, 25],
        [-30, -3],
        [22, 53],
        [61, -6],
        [40, 11],
        [33, 26],
        [10, 19],
        [16, 40],
        [-5, 42],
        [-9, 23],
        [-22, 26],
        [-27, 37],
        [-18, 12],
        [-16, -1],
        [-78, -74],
        [-46, -2],
        [-35, 14],
        [-30, -42],
        [-85, -37],
        [-45, -38],
        [-84, -93],
        [-21, -43],
        [-26, -2],
        [-19, 82],
        [-91, 79],
        [-28, -27],
        [15, -25],
        [21, -17],
        [34, -8],
        [-15, -23],
        [-11, -32],
        [-34, 30],
        [-61, 43],
        [-63, 22],
        [-164, -3],
        [-108, -44],
        [-10, 9],
        [-10, 4],
        [-18, -11],
        [-8, -17],
        [-11, -12],
        [-22, -4],
        [-45, 7],
        [-85, 27],
        [-194, 41],
        [-50, 24],
        [-44, 59],
        [1, 40],
        [19, 17],
        [-1, 58],
        [-38, 15],
        [-77, 82],
        [-28, 35],
        [6, 4],
        [14, -9],
        [26, -7],
        [64, 11],
        [22, 53],
        [48, 16],
        [44, -8],
        [-10, 15],
        [-11, 11],
        [-114, 27],
        [-16, -8],
        [-205, 48],
        [-162, 84],
        [-13, 16],
        [-15, 36],
        [22, 35],
        [22, 17],
        [1, -20],
        [3, -19],
        [93, 45],
        [48, 58],
        [92, 10],
        [22, 16],
        [28, 31],
        [41, 54],
        [58, 28],
        [39, 25],
        [51, 15],
        [44, -25],
        [13, -3],
        [80, -5],
        [26, 11],
        [11, 8],
        [8, 12],
        [-78, 45],
        [8, 25],
        [10, 18],
        [91, 52],
        [70, 17],
        [37, -1],
        [108, 68],
        [59, 19],
        [112, 13],
        [92, 3],
        [25, -24],
        [-49, 5],
        [-22, -4],
        [15, -9],
        [18, -17],
        [-5, -22],
        [-31, -66],
        [3, -53],
        [-20, -17],
        [-19, -24],
        [94, -76],
        [146, -5],
        [79, 14],
        [45, -23],
        [38, -5],
        [103, 12],
        [78, -16],
        [33, 6],
        [72, 113],
        [28, 18],
        [31, -20],
        [40, -16],
        [25, 12],
        [21, -29],
        [-10, 61],
        [-14, 23],
        [-118, 41],
        [-78, -20],
        [-25, 23],
        [8, 47],
        [-84, 115],
        [-35, 24],
        [-42, 1],
        [-21, 40],
        [-18, 51],
        [36, 21],
        [33, 10],
        [30, -17],
        [33, -67],
        [32, -10],
        [-9, -68],
        [39, -62],
        [89, -57],
        [70, 21],
        [50, -1],
        [30, -12],
        [74, -52],
        [37, -6],
        [116, 27],
        [1, 51],
        [-9, 37],
        [-28, 23],
        [-78, -5],
        [-62, 38],
        [-52, -10],
        [-96, -58],
        [-48, 23],
        [-30, 31],
        [-49, 31],
        [-6, 60],
        [41, 69],
        [30, 32],
        [-27, 24],
        [-68, 17],
        [-119, -18],
        [-5, 24],
        [0, 25],
        [-48, -49],
        [-50, 10],
        [-66, -5],
        [-148, 43],
        [-52, 54],
        [-22, 43],
        [-40, 119],
        [-50, 75],
        [-351, 252],
        [-159, 64],
        [-77, 70],
        [-48, 17],
        [-46, 8],
        [-59, 22],
        [40, 28],
        [27, 10],
        [-28, -30],
        [21, -7],
        [35, 17],
        [18, 20],
        [27, 85],
        [28, 129],
        [-7, 51],
        [194, -10],
        [129, 8],
        [43, 12],
        [163, 20],
        [43, 14],
        [78, 43],
        [92, 77],
        [80, 101],
        [12, 27],
        [5, -7],
        [7, 5],
        [9, 38],
        [10, 90],
        [39, 85],
        [168, 193],
        [77, 77],
        [26, 35],
        [27, 25],
        [19, -24],
        [9, -7],
        [5, -11],
        [-16, -6],
        [-26, -25],
        [-36, -16],
        [-9, -9],
        [22, 2],
        [63, 18],
        [36, 22],
        [179, 41],
        [97, 66],
        [3, 15],
        [144, 84],
        [20, -4],
        [23, -10],
        [-40, -55],
        [28, -14],
        [-25, -66],
        [52, -1],
        [12, -30],
        [3, 26],
        [-1, 37],
        [4, 37],
        [8, 25],
        [36, -11],
        [83, 27],
        [-100, 4],
        [-60, 59],
        [-33, 1],
        [111, 87],
        [102, 53],
        [23, -1],
        [11, -10],
        [2, -16],
        [-22, -10],
        [-21, -19],
        [10, -16],
        [15, -3],
        [48, 14],
        [22, 17],
        [105, -2],
        [30, 13],
        [8, 12],
        [134, 2],
        [25, 9],
        [85, 46],
        [78, 57],
        [36, 31],
        [61, 79],
        [53, 51],
        [87, 51],
        [21, -6],
        [-28, -10],
        [-20, -22],
        [27, -29],
        [183, -59],
        [46, -4],
        [18, -35],
        [-15, -34],
        [-47, -38],
        [-95, -39],
        [29, -15],
        [19, -35],
        [28, -4],
        [46, 13],
        [35, 21],
        [74, 70],
        [24, 39],
        [17, 9],
        [62, -9],
        [35, -20],
        [40, -35],
        [-15, -34],
        [-16, -20],
        [52, -26],
        [57, -6],
        [55, -21],
        [77, 44],
        [60, 9],
        [57, -2],
        [73, 24],
        [125, -32],
        [31, 8],
        [50, -5],
        [53, -20],
        [19, -21],
        [-57, -44],
        [-9, -46],
        [20, -19],
        [36, -4],
        [4, -27],
        [23, -6],
        [112, 2],
        [-9, -13],
        [-5, -15],
        [-35, -34],
        [200, -20],
        [27, 19],
        [41, 8],
        [88, 26],
        [33, -12],
        [39, -26],
        [36, -6],
        [34, 6],
        [78, 38],
        [91, 2],
        [37, -13],
        [39, 6],
        [118, -44],
        [44, -5],
        [58, -57],
        [30, -1],
        [34, 24],
        [29, -1],
        [29, -23],
        [47, -7],
        [22, -37],
        [24, -13],
        [178, -27],
        [88, 13],
        [129, -4],
        [62, -17],
        [65, 2],
        [107, -63],
        [56, -9],
        [11, -15],
        [161, -15],
        [56, 33],
        [98, 8],
        [88, 28],
        [50, 0],
        [59, -7],
        [22, 3],
        [16, 12],
        [142, -47],
        [79, -55],
        [35, -40],
        [166, -57],
        [48, -32],
        [33, -36],
        [19, -3],
        [14, 10],
        [58, -3],
        [22, -5],
      ],
      [
        [10833, 91964],
        [0, -168],
        [0, -169],
        [0, -168],
        [0, -168],
        [0, -169],
        [0, -168],
        [0, -168],
        [0, -169],
        [0, -168],
        [0, -168],
        [0, -169],
        [0, -168],
        [0, -168],
        [0, -169],
        [0, -168],
        [0, -168],
        [0, -168],
        [0, -169],
        [0, -168],
        [0, -168],
        [0, -169],
        [0, -168],
        [0, -168],
        [0, -169],
        [0, -168],
        [0, -168],
        [0, -169],
        [0, -168],
        [0, -168],
        [0, -169],
        [0, -168],
        [0, -168],
        [66, -24],
        [66, -23],
        [20, 46],
        [71, -35],
        [62, -32],
        [40, 40],
        [43, 44],
        [58, 3],
        [65, 3],
        [43, 3],
        [0, -37],
        [-16, -62],
        [-14, -51],
        [40, -52],
        [48, -27],
        [46, -26],
        [20, -71],
        [50, -55],
        [37, -41],
        [36, -40],
        [52, -57],
        [37, -40],
        [48, -53],
        [29, -32],
        [13, -61],
        [17, -74],
        [-10, -44],
        [23, -7],
        [44, 49],
        [42, 30],
        [52, 38],
        [35, 25],
        [65, 1],
        [31, 74],
        [1, 103],
        [32, -2],
        [20, 14],
        [8, 31],
        [-20, 41],
        [62, 19],
        [45, 14],
        [65, 39],
        [63, 37],
        [30, -29],
        [29, -28],
        [59, -67],
        [4, -16],
        [-4, -32],
        [-6, -31],
        [35, -88],
        [11, -10],
        [29, -12],
        [35, -29],
        [15, -26],
        [50, -40],
        [9, -17],
        [4, -27],
        [9, -23],
        [9, -17],
        [9, -24],
        [22, -28],
        [41, -31],
        [29, -22],
        [40, -30],
        [41, -62],
        [36, -54],
        [40, -54],
        [-6, -42],
        [41, -66],
        [43, -84],
        [33, -74],
        [24, -41],
        [28, -60],
        [34, -74],
        [39, -83],
        [30, -54],
        [39, -75],
        [20, -45],
        [-13, -31],
        [-17, -38],
        [51, -18],
        [34, -12],
        [-8, -42],
        [-12, -56],
        [40, -22],
        [26, -15],
        [-5, -29],
        [15, -33],
        [2, -55],
        [48, 4],
        [21, 2],
        [29, -25],
        [38, -32],
        [38, -30],
        [32, -25],
        [42, -15],
        [53, -22],
        [25, -44],
        [48, -20],
        [18, -62],
        [55, -23],
        [33, 16],
        [11, -26],
        [9, -29],
        [3, -37],
        [-3, -36],
      ],
      [
        [4590, 83513],
        [27, -95],
        [12, -18],
        [17, -10],
        [24, -11],
        [15, -14],
        [12, -21],
        [2, -10],
        [-76, 38],
        [-48, -56],
        [-15, -7],
        [-136, -3],
        [-27, -10],
        [-18, -18],
        [-31, -52],
        [-16, -19],
        [-16, -12],
        [-36, -14],
        [-42, 2],
        [-22, 7],
        [-12, 24],
        [-11, 48],
        [0, 14],
        [5, 23],
        [38, 31],
        [12, 17],
        [49, 109],
        [14, 15],
        [16, 4],
        [41, -8],
        [36, 32],
        [77, 48],
        [17, 6],
        [55, 1],
        [16, -8],
        [11, -13],
        [10, -20],
      ],
      [
        [12971, 83838],
        [6, -16],
        [0, -11],
        [-40, -38],
        [-1, -8],
        [-9, -23],
        [-9, -9],
        [-15, -26],
        [-28, -27],
        [4, 83],
        [-28, 48],
        [28, 24],
        [19, -7],
        [31, -3],
        [30, 22],
        [12, -9],
      ],
      [
        [13517, 83571],
        [28, -75],
        [2, -27],
        [-28, -9],
        [-21, 4],
        [-11, 8],
        [-3, 13],
        [7, 38],
        [-14, 22],
        [-16, 8],
        [-15, -14],
        [0, 38],
        [11, 27],
        [-7, 36],
        [0, 28],
        [4, 9],
        [15, -1],
        [30, -29],
        [18, -76],
      ],
      [
        [13302, 84164],
        [-6, -96],
        [-11, 5],
        [-10, 1],
        [-22, -14],
        [-22, 6],
        [-11, 11],
        [-4, 12],
        [4, 28],
        [-12, 16],
        [-42, 6],
        [-16, 7],
        [-9, 30],
        [-2, 39],
        [7, 14],
        [21, 11],
        [16, 47],
        [10, 7],
        [35, 94],
        [17, -7],
        [31, -57],
        [39, -83],
        [-13, -77],
      ],
      [
        [13617, 83806],
        [-10, -63],
        [-19, -65],
        [-29, -35],
        [-21, 8],
        [-15, 28],
        [-14, -1],
        [-15, 6],
        [-8, 23],
        [8, 30],
        [-7, 23],
        [-8, -20],
        [-13, -19],
        [-33, -24],
        [-23, -47],
        [-11, -30],
        [-13, 33],
        [-9, 78],
        [-1, 33],
        [24, 50],
        [31, 48],
        [6, 142],
        [99, 71],
        [9, -4],
        [32, -53],
        [35, -74],
        [8, -34],
        [1, -58],
        [-4, -46],
      ],
      [
        [12954, 84679],
        [18, -18],
        [10, 19],
        [19, -1],
        [35, -17],
        [20, -25],
        [12, -29],
        [1, -17],
        [-3, -40],
        [2, -40],
        [-1, -20],
        [-5, -18],
        [-8, -13],
        [-8, -2],
        [-27, 36],
        [-31, 65],
        [-24, 20],
        [-1, -7],
        [7, -18],
        [19, -35],
        [3, -21],
        [14, -26],
        [6, -19],
        [3, -26],
        [0, -22],
        [-4, -19],
        [-6, -12],
        [-9, -6],
        [-47, 6],
        [-28, -13],
        [-33, 7],
        [-8, 11],
        [-5, 19],
        [-2, 46],
        [-9, 66],
        [2, 50],
        [-21, 46],
        [-18, 27],
        [-26, 25],
        [-18, 24],
        [5, 20],
        [27, 14],
        [44, -3],
        [95, -34],
      ],
      [
        [13094, 83464],
        [6, -8],
        [7, 5],
        [12, 21],
        [20, -4],
        [14, -7],
        [9, -8],
        [-5, -30],
        [-4, -49],
        [-8, -17],
        [-8, -24],
        [-28, 14],
        [-23, 31],
        [-33, 53],
        [-19, 38],
        [-1, 16],
        [-12, 12],
        [-22, 66],
        [-13, 52],
        [-21, 6],
        [-26, 15],
        [-10, 29],
        [7, 25],
        [37, 12],
        [55, -64],
        [9, -28],
        [20, -32],
        [3, -44],
        [10, -18],
        [24, -62],
      ],
      [
        [9335, 86664],
        [6, -16],
        [53, 4],
        [16, -3],
        [6, -7],
        [-7, -11],
        [-21, -14],
        [-60, -25],
        [-49, -33],
        [-6, 3],
        [-9, 36],
        [-9, 15],
        [-6, 20],
        [0, 7],
        [9, 14],
        [18, 20],
        [13, 8],
        [46, -18],
      ],
      [
        [8962, 86297],
        [-31, -8],
        [-7, 17],
        [16, 42],
        [13, 24],
        [10, 6],
        [35, 48],
        [39, 35],
        [36, 51],
        [37, 72],
        [6, 27],
        [17, 3],
        [28, -18],
        [17, -25],
        [-8, -20],
        [-92, -103],
        [-8, -13],
        [-8, -35],
        [-8, -13],
        [-12, -5],
        [-9, -15],
        [-5, -25],
        [-11, -14],
        [-18, -1],
        [-12, -7],
        [-6, -12],
        [-19, -11],
      ],
      [
        [8984, 86664],
        [0, -15],
        [-9, -14],
        [8, -27],
        [-14, -46],
        [-6, -29],
        [-8, -19],
        [-7, -7],
        [-8, 4],
        [-2, 11],
        [5, 16],
        [-18, -1],
        [-6, 40],
        [10, 13],
        [4, 17],
        [1, 12],
        [12, 51],
        [4, 3],
        [2, -12],
        [3, -3],
        [7, 5],
        [9, 22],
        [4, 3],
        [9, -24],
      ],
      [
        [8908, 86881],
        [-35, -5],
        [-16, 7],
        [-3, 8],
        [7, 29],
        [0, 12],
        [17, 5],
        [21, -14],
        [6, -14],
        [3, -28],
      ],
      [
        [7498, 84749],
        [-35, -19],
        [-7, 1],
        [-21, -38],
        [-17, -16],
        [-22, 30],
        [5, 46],
        [20, 30],
        [97, -10],
        [7, -9],
        [1, -7],
        [-8, -6],
        [-20, -2],
      ],
      [
        [7643, 85532],
        [-9, -4],
        [-20, 18],
        [-13, 19],
        [9, 14],
        [39, 30],
        [19, 0],
        [8, -4],
        [3, -10],
        [-2, -13],
        [-8, -18],
        [-26, -32],
      ],
      [
        [7433, 85166],
        [-8, -16],
        [-7, 4],
        [-16, 19],
        [-31, 28],
        [-15, 18],
        [-1, 8],
        [11, 9],
        [38, -22],
        [15, -20],
        [14, -28],
      ],
      [
        [6787, 83998],
        [-11, -19],
        [-21, 2],
        [-12, 6],
        [-3, 16],
        [32, 48],
        [7, 6],
        [6, -2],
        [3, -20],
        [-1, -37],
      ],
      [
        [7032, 84352],
        [-19, -14],
        [-6, 5],
        [-1, 11],
        [5, 18],
        [9, 18],
        [29, 34],
        [29, 23],
        [15, -2],
        [6, -14],
        [-19, -30],
        [-48, -49],
      ],
      [
        [7164, 84397],
        [-13, -1],
        [-21, 15],
        [3, 18],
        [29, 22],
        [29, -3],
        [3, -12],
        [-2, -14],
        [-2, -8],
        [-9, -8],
        [-17, -9],
      ],
      [
        [5365, 83706],
        [4, 0],
        [9, 4],
        [18, 33],
        [6, 1],
        [0, -10],
        [-8, -32],
        [14, -43],
        [13, -21],
        [-1, -7],
        [-34, -15],
        [-25, 11],
        [-14, -4],
        [-12, -15],
        [-9, 17],
        [-6, 79],
        [2, 14],
        [14, 27],
        [18, 13],
        [8, -4],
        [7, -11],
        [1, -11],
        [-5, -26],
      ],
      [
        [4917, 83436],
        [-6, -2],
        [-20, 17],
        [-7, 14],
        [-5, 20],
        [39, 30],
        [8, 0],
        [8, -16],
        [1, -14],
        [-11, -37],
        [-7, -12],
      ],
      [
        [4846, 83180],
        [-24, -13],
        [-26, 13],
        [-22, 25],
        [-2, 28],
        [49, -18],
        [10, -9],
        [15, -26],
      ],
      [
        [5591, 83599],
        [-17, -13],
        [-5, -16],
        [-13, -7],
        [-11, -13],
        [-37, -59],
        [-16, -11],
        [18, 51],
        [3, 16],
        [0, 10],
        [-5, 38],
        [10, -1],
        [9, 8],
        [18, 34],
        [16, 3],
        [17, 40],
        [9, 3],
        [4, -6],
        [-7, -24],
        [16, -22],
        [-4, -22],
        [-5, -9],
      ],
      [
        [3933, 82989],
        [-11, -10],
        [-8, -2],
        [-7, 6],
        [-28, -7],
        [-6, 4],
        [-13, 34],
        [-1, 18],
        [5, 14],
        [13, 13],
        [21, 11],
        [21, -2],
        [35, -32],
        [17, -18],
        [3, -12],
        [-12, -11],
        [-29, -6],
      ],
      [
        [4011, 83027],
        [-12, -4],
        [-3, 6],
        [-2, 25],
        [-9, 40],
        [18, 15],
        [11, 3],
        [4, -6],
        [13, -30],
        [13, -8],
        [9, -6],
        [-16, -9],
        [-26, -26],
      ],
      [
        [5300, 85585],
        [-20, -9],
        [-22, 5],
        [-17, 57],
        [13, 1],
        [28, 38],
        [60, 30],
        [15, 4],
        [-57, -126],
      ],
      [
        [2016, 86668],
        [60, -38],
        [39, 4],
        [30, -31],
        [13, -26],
        [-46, 19],
        [-66, -2],
        [-90, 77],
        [-32, 18],
        [7, 43],
        [35, 22],
        [17, -58],
        [33, -28],
      ],
      [
        [2733, 84783],
        [-29, -28],
        [-26, 10],
        [-7, 20],
        [-1, 9],
        [75, 22],
        [-12, -33],
      ],
      [
        [2863, 82285],
        [-4, -24],
        [-4, -8],
        [-43, 12],
        [-29, -4],
        [-3, 14],
        [3, 12],
        [45, 18],
        [18, 1],
        [12, -10],
        [5, -11],
      ],
      [
        [2574, 82131],
        [-18, -18],
        [-5, 7],
        [-3, 23],
        [10, 17],
        [30, 38],
        [21, -7],
        [6, -10],
        [0, -14],
        [-8, -19],
        [-10, -10],
        [-12, 0],
        [-11, -7],
      ],
      [
        [2093, 81953],
        [-21, -8],
        [-22, 9],
        [10, 30],
        [11, 16],
        [21, 20],
        [24, -9],
        [19, -25],
        [-42, -33],
      ],
      [
        [2846, 84466],
        [36, -11],
        [21, 7],
        [18, -6],
        [3, -13],
        [-31, -30],
        [-13, 2],
        [-37, 36],
        [3, 15],
      ],
      [
        [1031, 81677],
        [-17, -34],
        [-13, 15],
        [-5, 47],
        [10, 12],
        [27, -34],
        [-2, -6],
      ],
      [
        [1105, 81798],
        [-6, -17],
        [-28, 18],
        [-9, 15],
        [-2, 15],
        [8, 25],
        [22, 0],
        [12, -10],
        [12, -19],
        [4, -12],
        [-13, -15],
      ],
      [
        [792, 81633],
        [-8, -7],
        [-15, -6],
        [-42, 6],
        [-26, -2],
        [-28, -4],
        [-22, -10],
        [-4, 14],
        [1, 12],
        [92, 32],
        [22, 16],
        [13, 21],
        [12, 39],
        [10, 12],
        [6, -1],
        [13, -15],
        [-5, -20],
        [-11, -18],
        [-4, -17],
        [-4, -52],
      ],
      [
        [99603, 81748],
        [-17, -10],
        [-10, 25],
        [-1, 15],
        [10, 15],
        [17, -9],
        [10, -15],
        [-9, -21],
      ],
      [
        [99847, 81435],
        [-49, 0],
        [-97, 94],
        [-50, 29],
        [-28, 33],
        [13, 7],
        [60, -23],
        [49, -51],
        [27, -33],
        [31, -28],
        [34, -12],
        [10, -16],
      ],
      [
        [98255, 82004],
        [-18, -2],
        [-11, 20],
        [-60, 8],
        [6, 19],
        [26, 8],
        [39, 30],
        [33, -5],
        [-9, -28],
        [-6, -50],
      ],
      [
        [12508, 84879],
        [24, -63],
        [17, -49],
        [15, -59],
        [26, -122],
        [12, -46],
        [3, -26],
        [3, -66],
        [-4, -14],
        [-7, -13],
        [-2, -19],
        [7, -50],
        [1, -77],
        [-7, -43],
        [-8, -7],
        [-19, 14],
        [-15, 24],
        [-12, 24],
        [-28, 77],
        [-9, 36],
        [0, 25],
        [4, 19],
        [9, 12],
        [17, 31],
        [-3, 5],
        [-12, -7],
        [-25, -4],
        [-22, 24],
        [-17, 13],
        [3, 45],
        [-4, 12],
        [-34, -13],
        [-13, 12],
        [-3, 17],
        [1, 25],
        [6, 21],
        [32, 55],
        [-3, 11],
        [-15, 2],
        [-21, 19],
        [-9, 61],
        [-22, 35],
        [-14, -3],
        [-29, -99],
        [-15, -22],
        [-42, -14],
        [9, 28],
        [4, 25],
        [-15, 74],
        [-1, 29],
        [10, 21],
        [30, 9],
        [15, 13],
        [13, 20],
        [3, 20],
        [23, 53],
        [10, 10],
        [29, 0],
        [60, -59],
        [18, -8],
        [26, -38],
      ],
      [
        [12589, 85346],
        [70, -13],
        [52, 3],
        [47, -86],
        [29, -70],
        [17, -49],
        [10, -47],
        [13, -45],
        [-1, -7],
        [-28, 31],
        [-19, 62],
        [-10, 24],
        [-10, 11],
        [-10, 23],
        [-21, 59],
        [0, 17],
        [-9, 16],
        [-11, 6],
        [-12, -3],
        [-4, -5],
        [2, -41],
        [9, -46],
        [51, -99],
        [34, -57],
        [7, -18],
        [5, -52],
        [-15, -23],
        [18, -48],
        [-1, -9],
        [-4, -9],
        [-48, -21],
        [-45, -89],
        [-48, -52],
        [-23, -8],
        [-10, 9],
        [-11, 20],
        [-6, 26],
        [-1, 33],
        [12, 21],
        [24, 109],
        [0, 35],
        [-30, 50],
        [-18, 40],
        [-9, 57],
        [-17, 149],
        [-7, 48],
        [-11, 39],
        [-14, 32],
        [-10, 35],
        [-8, 38],
        [3, 15],
        [24, -20],
        [29, -55],
        [15, -36],
      ],
      [
        [12297, 85393],
        [40, -56],
        [0, -13],
        [-8, -38],
        [-22, -11],
        [6, -15],
        [17, -12],
        [11, 10],
        [42, 54],
        [13, 11],
        [8, 1],
        [51, -16],
        [44, -26],
        [13, -20],
        [8, -36],
        [-12, -79],
        [-37, -13],
        [-17, 1],
        [-18, 12],
        [-30, -28],
        [25, -20],
        [75, -5],
        [23, -44],
        [6, -34],
        [-16, -62],
        [-43, 17],
        [-37, 36],
        [-77, 51],
        [-19, 3],
        [-12, -9],
        [-4, -31],
        [1, -68],
        [-20, -34],
        [-61, 15],
        [-24, 51],
        [-22, 80],
        [-84, 96],
        [-23, 19],
        [-30, 57],
        [12, 45],
        [4, 26],
        [16, 7],
        [23, 20],
        [14, 44],
        [21, -36],
        [28, -34],
        [1, 32],
        [13, 26],
        [28, -1],
        [13, 5],
        [18, 24],
        [26, 12],
        [16, -14],
      ],
      [
        [12898, 84296],
        [53, -12],
        [48, 1],
        [17, -24],
        [10, -25],
        [7, -24],
        [1, -22],
        [-1, -15],
        [-6, -17],
        [2, -5],
        [94, -55],
        [44, -58],
        [18, -30],
        [10, -26],
        [19, -64],
        [39, -74],
        [21, -23],
        [11, -22],
        [-6, 0],
        [-28, 16],
        [-60, 50],
        [-5, -2],
        [-5, -27],
        [-9, -24],
        [-14, -17],
        [11, -5],
        [48, 11],
        [41, -49],
        [15, -8],
        [16, -35],
        [0, -14],
        [-9, -26],
        [-6, -10],
        [2, -7],
        [11, -4],
        [45, 7],
        [8, -12],
        [-7, -101],
        [6, -37],
        [0, -17],
        [-5, -22],
        [0, -19],
        [4, -19],
        [1, -18],
        [-12, -45],
        [-12, -8],
        [-19, 0],
        [-15, 13],
        [-22, 39],
        [-21, 60],
        [-8, 9],
        [-27, 9],
        [-5, 7],
        [-17, 1],
        [-13, 25],
        [2, 33],
        [-11, 33],
        [1, 15],
        [-12, 6],
        [-10, -9],
        [6, -33],
        [-6, -25],
        [-22, 11],
        [-36, 80],
        [-41, 65],
        [-17, 15],
        [5, 19],
        [20, 10],
        [17, -1],
        [3, 11],
        [-34, 63],
        [1, 18],
        [12, 31],
        [-15, 13],
        [-43, -9],
        [-15, 6],
        [-13, 26],
        [-7, 21],
        [-37, 4],
        [-14, -2],
        [-24, 33],
        [-12, 21],
        [5, 11],
        [22, 18],
        [13, -2],
        [25, -20],
        [10, 0],
        [25, 27],
        [4, 24],
        [18, 20],
        [-3, 21],
        [-10, 35],
        [-23, 10],
        [-46, -21],
        [-41, -32],
        [-16, 12],
        [-3, 20],
        [43, 55],
        [19, 30],
        [-4, 17],
        [-14, 23],
        [-1, 58],
        [9, 13],
      ],
      [
        [12781, 84587],
        [18, -40],
        [26, 4],
        [14, -30],
        [11, -46],
        [-8, -29],
        [-11, 7],
        [-13, -17],
        [-8, -56],
        [4, -55],
        [-4, -56],
        [-15, -57],
        [-3, -38],
        [-6, -11],
        [-7, -4],
        [-8, 10],
        [-12, 8],
        [-15, -32],
        [-19, 0],
        [-15, 73],
        [13, 121],
        [31, 25],
        [-18, 32],
        [-39, 39],
        [3, 21],
        [-29, 62],
        [-2, 14],
        [5, 52],
        [27, 46],
        [37, 8],
        [25, -20],
        [14, -17],
        [4, -14],
      ],
      [
        [7662, 85460],
        [10, -4],
        [10, 34],
        [8, 1],
        [33, -29],
        [20, 6],
        [13, -35],
        [12, -4],
        [10, 5],
        [7, -3],
        [-2, -38],
        [-24, -38],
        [-12, -9],
        [-15, 9],
        [-6, 4],
        [-10, 17],
        [-8, 21],
        [-5, 1],
        [-18, -25],
        [0, -13],
        [8, -19],
        [-1, -11],
        [-20, -6],
        [-20, 3],
        [-24, -16],
        [-5, 10],
        [-4, 29],
        [-7, -4],
        [-12, -35],
        [-12, -22],
        [-22, -18],
        [-5, -9],
        [-17, -1],
        [-24, -12],
        [-15, 2],
        [-90, 38],
        [-21, 14],
        [74, 87],
        [39, 34],
        [22, -2],
        [22, -11],
        [12, 2],
        [1, 39],
        [-21, 29],
        [1, 12],
        [46, 19],
        [18, -3],
        [19, -10],
        [18, -16],
        [17, -23],
      ],
      [
        [3343, 82571],
        [-85, -61],
        [-28, -45],
        [-21, -44],
        [-16, -24],
        [-12, -4],
        [-14, -11],
        [-28, -31],
        [-12, -3],
        [-90, -71],
        [-6, -1],
        [4, 18],
        [28, 26],
        [18, 24],
        [20, 40],
        [11, 14],
        [4, 20],
        [1, 40],
        [5, 15],
        [20, 30],
        [14, 17],
        [18, 6],
        [38, -5],
        [16, 15],
        [4, 11],
        [-9, 11],
        [-2, 18],
        [2, 32],
        [11, 28],
        [19, 25],
        [27, 19],
        [33, 13],
        [24, 1],
        [44, -29],
        [7, -13],
        [-11, -29],
        [-6, -27],
        [-28, -25],
      ],
      [
        [3718, 82891],
        [12, -27],
        [21, 17],
        [15, 24],
        [12, 31],
        [7, 12],
        [11, -16],
        [29, -22],
        [-25, -34],
        [-47, -51],
        [-16, -34],
        [-1, -15],
        [46, 12],
        [13, -2],
        [8, -12],
        [-13, -13],
        [-25, -13],
        [-21, -24],
        [-50, -42],
        [-19, -35],
        [-23, -14],
        [-30, -3],
        [-54, -23],
        [-32, -21],
        [-8, -12],
        [-11, -5],
        [-12, 1],
        [-13, -10],
        [-14, -19],
        [-12, -9],
        [-19, -2],
        [-11, -8],
        [-11, 0],
        [-31, 23],
        [-8, 14],
        [28, 27],
        [20, 9],
        [30, 4],
        [29, 25],
        [61, 34],
        [19, 18],
        [12, 63],
        [14, 11],
        [8, 25],
        [33, -1],
        [16, -28],
        [5, -4],
        [3, 3],
        [2, 22],
        [17, 16],
        [-10, 12],
        [-31, 15],
        [-23, 6],
        [-15, 0],
        [-13, 8],
        [-9, 17],
        [-5, 17],
        [1, 17],
        [8, 19],
        [14, 21],
        [17, 12],
        [36, 9],
        [32, 14],
        [17, 2],
        [13, -6],
        [3, -55],
      ],
      [
        [1791, 81875],
        [54, -23],
        [68, 2],
        [25, -5],
        [0, -7],
        [-43, -10],
        [-15, 4],
        [-38, -13],
        [-26, -3],
        [-58, 13],
        [-46, -9],
        [-12, 3],
        [-14, 11],
        [-16, 18],
        [-1, 12],
        [15, 4],
        [40, -15],
        [4, 8],
        [34, 14],
        [29, -4],
      ],
      [
        [1478, 81817],
        [-149, -24],
        [-22, 16],
        [22, 10],
        [27, 5],
        [56, 27],
        [69, 23],
        [54, 29],
        [47, 18],
        [13, 30],
        [-41, 16],
        [-8, 12],
        [19, 14],
        [16, 21],
        [39, 25],
        [34, -31],
        [8, -20],
        [-4, -25],
        [-7, -25],
        [-30, -13],
        [-4, -13],
        [16, -38],
        [-62, -33],
        [-93, -24],
      ],
      [
        [946, 81720],
        [2, -20],
        [32, 3],
        [10, -10],
        [0, -38],
        [-5, -11],
        [-4, -2],
        [-12, 8],
        [-13, -19],
        [-59, -48],
        [-18, 27],
        [-35, -42],
        [24, 108],
        [28, 16],
        [11, 12],
        [-3, 32],
        [13, 52],
        [28, -2],
        [13, -22],
        [0, -14],
        [-12, -30],
      ],
      [
        [589, 81595],
        [-6, -19],
        [-7, 0],
        [-37, 32],
        [-5, 11],
        [22, 15],
        [6, 11],
        [-3, 16],
        [-16, 21],
        [-30, 27],
        [-11, 20],
        [7, 12],
        [14, 7],
        [45, 2],
        [25, -34],
        [18, -12],
        [43, -8],
        [-22, -14],
        [-13, -13],
        [-15, -53],
        [-15, -21],
      ],
      [
        [99923, 81742],
        [-23, -14],
        [-26, 7],
        [-15, 23],
        [2, 27],
        [34, 29],
        [43, -37],
        [-15, -35],
      ],
      [
        [99281, 81729],
        [-24, -24],
        [-19, 24],
        [-3, 12],
        [36, 42],
        [28, 9],
        [11, 15],
        [12, 53],
        [20, 2],
        [10, -6],
        [-5, -25],
        [-16, -38],
        [0, -27],
        [-50, -37],
      ],
      [
        [98002, 82380],
        [48, -19],
        [33, 9],
        [41, -30],
        [52, -53],
        [-12, -10],
        [-13, -5],
        [-12, 0],
        [-40, -8],
        [-22, 2],
        [-40, -36],
        [-45, 26],
        [-15, 51],
        [-35, 12],
        [-28, 18],
        [51, 40],
        [37, 3],
      ],
      [
        [6783, 62794],
        [-12, -27],
        [-16, 2],
        [-55, 59],
        [-7, 32],
        [4, 148],
        [-21, 120],
        [-23, 91],
        [17, 47],
        [22, 37],
        [25, 69],
        [-21, 88],
        [6, 53],
        [11, 9],
        [59, -65],
        [117, -97],
        [31, -68],
        [6, -74],
        [21, -9],
        [11, -50],
        [30, -44],
        [11, -26],
        [-13, -40],
        [-56, -78],
        [-72, -34],
        [-62, -87],
        [-13, -56],
      ],
      [
        [6329, 64063],
        [59, -15],
        [14, 6],
        [10, -12],
        [48, -8],
        [9, -5],
        [-10, -30],
        [-31, -27],
        [-45, 24],
        [-75, 8],
        [3, 23],
        [7, 16],
        [1, 29],
        [10, -9],
      ],
      [
        [6531, 63901],
        [8, -11],
        [29, 16],
        [22, 5],
        [35, -38],
        [13, -26],
        [23, -27],
        [8, -21],
        [-6, -24],
        [-26, -40],
        [-36, -10],
        [-20, -17],
        [-28, 4],
        [-8, 7],
        [-3, 51],
        [-9, 55],
        [-17, -7],
        [-20, 19],
        [-21, 46],
        [-2, 27],
        [11, 44],
        [20, 5],
        [15, -24],
        [12, -34],
      ],
      [
        [6167, 64202],
        [9, -3],
        [13, 4],
        [4, -46],
        [14, -25],
        [5, -15],
        [-15, -16],
        [-30, -7],
        [-14, 13],
        [-15, 29],
        [-15, -8],
        [-3, 23],
        [-3, 6],
        [-11, -6],
        [10, -30],
        [-27, -2],
        [-9, 4],
        [-7, 34],
        [-28, 64],
        [0, 25],
        [-10, 31],
        [42, 8],
        [28, 53],
        [16, 5],
        [31, -85],
        [0, -24],
        [6, -23],
        [9, -9],
      ],
      [
        [5730, 64476],
        [-25, -32],
        [-14, 14],
        [-27, 5],
        [-10, 25],
        [-29, 22],
        [-11, 30],
        [17, 56],
        [41, 48],
        [63, -2],
        [14, -38],
        [1, -28],
        [-8, -31],
        [-4, -45],
        [-8, -24],
      ],
      [
        [5505, 64424],
        [-5, -26],
        [-10, 4],
        [-2, 23],
        [6, 31],
        [16, 27],
        [18, 41],
        [14, -6],
        [-8, -27],
        [-1, -29],
        [-20, -16],
        [-8, -22],
      ],
      [
        [6431, 63808],
        [-17, -16],
        [-18, 8],
        [-4, 39],
        [-17, 50],
        [30, 10],
        [17, -14],
        [9, -16],
        [11, -27],
        [-11, -34],
      ],
      [
        [29247, 77766],
        [13, 0],
        [65, 1],
        [115, 0],
        [116, 0],
        [115, 0],
        [116, 1],
        [116, 0],
        [115, 0],
        [116, 1],
        [27, 111],
        [26, 51],
        [34, -17],
        [19, 2],
        [21, 26],
        [17, 17],
        [11, -3],
        [9, -24],
        [8, -17],
        [9, 5],
        [8, 23],
        [0, 32],
        [10, 22],
        [13, 4],
        [12, -1],
        [6, 11],
        [-1, 16],
        [-4, 25],
        [1, 30],
        [30, 53],
        [36, 36],
        [12, 18],
        [4, 37],
        [21, 38],
        [10, 22],
        [2, 19],
        [-5, 24],
        [1, 44],
        [7, 54],
        [8, 58],
        [19, 52],
        [32, 57],
        [8, 75],
        [8, 80],
        [38, 77],
        [43, 87],
        [24, 50],
        [44, 91],
        [31, 64],
        [16, 30],
        [17, 35],
        [26, -10],
        [27, -11],
        [-4, -51],
        [5, -37],
        [12, -21],
        [18, -15],
        [14, -5],
        [17, 0],
        [44, 29],
        [52, 19],
        [29, 17],
        [5, 17],
        [13, 6],
        [21, -5],
        [39, -41],
        [45, -62],
        [35, -49],
        [2, -85],
        [0, -89],
        [1, -95],
        [1, -68],
        [0, -92],
        [1, -74],
        [1, -96],
        [1, -52],
        [5, -15],
        [-3, -20],
        [-2, -10],
        [1, -8],
        [2, -10],
        [-1, -15],
        [-4, -12],
        [-3, -15],
        [0, -25],
        [5, -15],
        [8, -8],
        [7, 0],
        [8, -9],
        [12, -16],
        [17, -13],
        [18, -5],
        [12, 3],
        [15, -8],
        [6, -22],
        [-3, -20],
        [-9, -10],
        [-9, -7],
        [-2, -16],
        [5, -16],
        [7, -14],
        [7, -25],
        [-3, -22],
        [-7, -18],
        [-3, -19],
        [6, -16],
        [15, -22],
        [9, -21],
        [14, -11],
        [7, 8],
        [5, 11],
        [6, 8],
        [10, -5],
        [12, -6],
        [13, -7],
      ],
      [
        [31354, 77862],
        [-2, -18],
        [8, -29],
        [6, -57],
        [-9, -26],
        [2, -34],
        [26, -10],
        [6, -10],
        [1, -13],
        [-57, -88],
        [-48, 13],
        [-26, -24],
        [-27, -6],
        [-12, -40],
        [-15, -8],
        [-20, 3],
        [-18, 11],
        [-13, -6],
        [-19, -70],
        [-16, 6],
        [-6, -26],
        [-8, -11],
        [-12, -9],
        [-10, 31],
        [-7, 30],
        [-9, 6],
        [-13, 8],
        [-13, 0],
        [-9, -5],
        [-11, -19],
        [-16, -16],
        [-12, 13],
        [-9, 23],
        [-8, -36],
        [-12, -38],
        [2, -44],
        [-5, -26],
        [-11, 7],
        [-11, 23],
        [-31, 18],
        [-25, -1],
        [5, 24],
        [24, 35],
        [-8, 7],
        [-11, -5],
        [-5, 5],
        [8, 32],
        [1, 35],
        [-10, -12],
        [-14, -37],
        [-31, -30],
        [1, -49],
        [-30, -102],
        [-1, -43],
        [-19, -34],
        [-25, -30],
        [-33, 9],
        [-25, -26],
        [-13, -29],
        [-11, -5],
        [-5, 38],
        [-5, 11],
        [-9, -55],
        [-9, -4],
        [-4, 40],
        [-4, 26],
        [-13, -23],
        [-9, -59],
        [-9, 5],
        [-2, 22],
        [-7, 7],
        [-2, -25],
        [3, -36],
        [-5, -19],
        [-8, 10],
        [-9, 17],
        [-15, -13],
        [-14, -5],
        [0, 17],
        [3, 22],
        [-27, -12],
        [-32, -39],
        [-26, -55],
        [9, -9],
        [10, -17],
        [-44, -84],
        [-44, -76],
        [-34, -123],
        [-14, -15],
        [-11, -22],
        [-13, -75],
        [-14, -66],
        [8, -30],
        [5, -30],
        [13, -30],
        [11, -3],
        [11, 5],
        [9, -1],
        [5, -13],
        [-2, -15],
        [-13, -4],
        [-26, -26],
        [-22, -11],
        [-11, -32],
        [-16, -37],
        [-32, -58],
        [13, -18],
        [50, -20],
        [22, -21],
        [34, -109],
        [-8, -10],
        [-3, -20],
        [30, -28],
        [9, -78],
        [25, -27],
        [36, -16],
        [45, 24],
        [37, 32],
        [-1, 27],
        [-24, 61],
        [-5, 29],
        [-18, 19],
        [-6, -16],
        [-11, 21],
        [-2, 12],
        [11, 5],
        [12, -2],
        [14, -11],
        [36, -67],
        [10, -89],
        [3, -56],
        [-4, -19],
        [-11, 4],
        [-20, -4],
        [-96, -29],
        [-21, -25],
        [-49, -28],
        [-3, 14],
        [3, 28],
        [-3, 59],
        [-10, 3],
        [-75, -96],
        [-30, -6],
        [-24, -28],
        [-6, 16],
        [-4, 71],
        [15, 61],
        [-8, -1],
        [-26, -36],
        [-11, 22],
        [-5, 24],
        [-8, 14],
        [-9, 5],
        [7, -53],
        [-17, -40],
        [-5, -104],
        [-22, -43],
        [-68, -27],
        [-45, 6],
        [-40, -9],
        [-53, -20],
        [-29, 12],
        [-30, -21],
        [-103, -6],
        [-21, 11],
        [-28, -39],
        [-44, -24],
        [-111, -88],
        [-25, -33],
        [-29, -50],
        [-21, -27],
        [-16, -9],
        [-10, -22],
        [-11, -15],
        [10, 50],
        [12, 43],
        [10, 82],
        [-3, 66],
        [-12, 28],
        [-12, 18],
        [14, -66],
        [2, -80],
        [-5, -47],
        [-27, -91],
        [-12, -21],
        [-13, -19],
        [-10, -8],
        [-10, -15],
        [-11, -23],
        [-10, -45],
        [6, -42],
        [53, -15],
        [15, 13],
        [7, -30],
        [4, -42],
        [-4, -45],
        [-9, -45],
        [-7, -57],
        [-5, -86],
        [-9, -78],
        [-1, 24],
        [5, 94],
        [-9, -10],
        [-6, -22],
        [-16, -121],
        [-22, -65],
        [-21, -45],
        [-21, 7],
        [5, -36],
        [-6, -18],
        [-5, -39],
        [-13, -26],
        [-12, 3],
        [-17, -18],
        [-6, -13],
        [-1, -26],
        [-11, -23],
        [-42, -119],
        [-35, -35],
        [-9, 5],
        [10, 56],
        [6, 57],
        [-22, 24],
        [-21, 13],
        [-23, -1],
        [-27, 44],
        [-34, 32],
        [-47, 86],
        [1, 24],
        [-1, 41],
        [14, 63],
        [14, 45],
        [19, 23],
        [56, 23],
        [14, 36],
        [8, 30],
        [-28, -52],
        [-41, -17],
        [-22, -19],
        [-18, -29],
        [-10, -37],
        [-24, -44],
        [2, -29],
        [4, -21],
        [-2, -44],
        [15, -43],
        [30, -70],
        [5, -109],
        [23, -72],
        [35, -85],
        [27, -24],
        [1, -32],
        [-12, -52],
        [-17, -24],
        [22, 5],
        [10, -12],
        [10, -43],
        [0, -44],
        [-4, -25],
        [-6, -10],
        [0, 25],
        [-5, 9],
        [-7, -11],
        [-5, -13],
        [-2, -49],
        [-5, -25],
        [-18, -7],
        [-19, -66],
        [-17, -37],
        [-67, -249],
        [2, -42],
        [-12, -14],
        [-19, -11],
        [-19, -25],
        [-12, -27],
        [-12, -74],
        [-22, -84],
        [-14, 35],
        [-4, 30],
        [7, 77],
        [24, 128],
        [26, 79],
        [21, 37],
        [16, 76],
        [-21, 12],
        [-32, -1],
        [6, 35],
        [9, 31],
        [-16, 31],
        [-10, 4],
        [-10, 12],
        [12, 26],
        [5, 27],
        [-3, 34],
        [5, 25],
        [-9, -4],
        [-13, -27],
        [-8, -10],
        [-5, 23],
        [-6, -5],
        [-4, -16],
        [-9, -9],
        [-18, 22],
        [-26, 25],
        [-15, 43],
        [-8, 34],
        [8, 61],
        [18, 10],
        [24, -9],
        [31, 0],
        [-4, 13],
        [-11, -2],
        [-33, 49],
        [-11, 30],
        [-18, 8],
        [-8, -29],
        [-9, -7],
        [11, 62],
        [15, 3],
        [22, 17],
        [-6, 36],
        [-14, 16],
        [-25, -20],
        [0, 26],
        [5, 32],
        [19, 0],
        [16, -10],
        [14, 52],
        [1, 24],
        [-24, -34],
        [-5, 73],
        [23, 72],
        [22, 31],
        [27, -1],
        [28, 5],
        [-17, 13],
        [-18, 7],
        [13, 28],
        [12, 5],
        [11, 24],
        [-27, -3],
        [3, 46],
        [-13, -9],
        [-16, -5],
        [-6, -19],
        [1, -33],
        [-4, -21],
        [-13, -18],
        [-20, -13],
        [-2, 23],
        [-7, 11],
        [-3, -50],
        [-5, -17],
        [-15, 47],
        [-5, -10],
        [1, -13],
        [-4, -23],
        [-13, -12],
        [1, -29],
        [-5, -16],
        [-42, 25],
        [-1, -8],
        [24, -56],
        [17, -18],
        [2, -30],
        [-15, -25],
        [-20, 21],
        [-4, -1],
        [11, -37],
        [7, -33],
        [-7, -27],
        [1, -33],
        [-1, -30],
        [-5, -26],
        [10, -121],
        [12, -33],
        [12, -32],
        [6, -29],
        [-12, -5],
        [-20, 25],
        [-17, 18],
        [-21, 59],
        [-4, 24],
        [-5, 19],
        [3, -43],
        [7, -48],
        [65, -107],
        [12, -42],
        [9, -32],
        [-2, -31],
        [-17, 22],
        [-15, 28],
        [-38, 32],
        [-49, 19],
        [-28, 74],
        [1, -31],
        [-7, -26],
        [-16, 32],
        [-11, 27],
        [-4, 29],
        [-20, -2],
        [-22, -25],
        [-22, 6],
        [-2, 50],
        [5, 27],
        [24, 62],
        [23, 33],
        [10, 41],
        [-4, 64],
        [-4, -65],
        [-13, -33],
        [-20, -24],
        [-27, -44],
        [-6, -41],
        [-8, -77],
        [11, -25],
        [11, -7],
        [34, 17],
        [18, -8],
        [39, -91],
        [72, -37],
        [27, -23],
        [22, -48],
        [32, -27],
        [25, -40],
        [1, -27],
        [-9, -30],
        [-3, -42],
        [-11, -27],
        [-26, -3],
        [-15, 7],
        [-84, 147],
        [-10, 14],
        [-31, 77],
        [-36, 41],
        [-11, -1],
        [52, -76],
        [21, -53],
        [37, -75],
        [26, -32],
        [20, -50],
        [18, -23],
        [50, -33],
        [-18, -24],
        [28, -20],
        [4, -37],
        [-3, -42],
        [-38, 16],
        [-1, -31],
        [3, -18],
        [-16, -15],
        [-24, 20],
        [-61, 113],
        [1, -15],
        [5, -18],
        [35, -72],
        [31, -44],
        [27, -20],
        [21, -36],
        [7, -22],
        [5, -34],
        [-15, -22],
        [-18, -13],
        [-17, 23],
        [-12, 24],
        [-27, 40],
        [-8, 46],
        [-20, -3],
        [-84, 58],
        [-68, 7],
        [7, -12],
        [8, -8],
        [54, -14],
        [22, -26],
        [44, -24],
        [26, -6],
        [11, -73],
        [35, -49],
        [5, -37],
        [25, -4],
        [43, 36],
        [28, -13],
        [40, -10],
        [9, -30],
        [7, -55],
        [14, -63],
        [37, -246],
        [55, -202],
        [7, -34],
        [-13, 30],
        [-41, 134],
        [-23, 96],
        [-23, 170],
        [-7, 38],
        [-8, 16],
        [-5, -13],
        [-2, -21],
        [4, -17],
        [-9, -56],
        [4, -26],
        [14, -26],
        [17, -67],
        [13, -89],
        [-18, 36],
        [-19, 19],
        [-29, 15],
        [-25, 26],
        [1, -37],
        [-2, -40],
        [-20, 12],
        [-14, 13],
        [12, -42],
        [-26, 13],
        [-17, -3],
        [-11, -38],
        [-15, -23],
        [-23, -7],
        [-33, 34],
        [-11, 42],
        [-4, 47],
        [-2, -55],
        [6, -58],
        [-2, -43],
        [32, -9],
        [30, 8],
        [40, -2],
        [26, 8],
        [16, 14],
        [38, -12],
        [3, -53],
        [-4, -52],
        [-3, -56],
        [11, 0],
        [12, 18],
        [6, 100],
        [35, 37],
        [12, -3],
        [11, -32],
        [4, -32],
        [4, -45],
        [-9, -68],
        [-53, -80],
        [-38, -74],
        [-20, -15],
        [-28, 8],
        [-32, 19],
        [-15, 4],
        [-12, -6],
        [-8, 22],
        [-4, 42],
        [-13, 14],
        [-9, -2],
        [-7, -44],
        [-29, -13],
        [-41, 19],
        [-42, 37],
        [18, -40],
        [105, -74],
        [12, -14],
        [11, -20],
        [-15, -32],
        [-11, -36],
        [-2, -28],
        [-4, -18],
        [-42, -48],
        [-23, 9],
        [-58, 86],
        [27, -75],
        [21, -31],
        [43, -17],
        [80, 28],
        [26, -31],
        [-22, -54],
        [-21, -37],
        [-28, -5],
        [-25, -10],
        [-7, -26],
        [-18, -2],
        [-27, -1],
        [-43, -2],
        [-23, 6],
        [-33, -54],
        [-12, -7],
        [-18, 10],
        [-7, 43],
        [-8, 21],
        [0, -80],
        [3, -22],
        [6, -16],
        [-38, -43],
        [-37, -54],
        [-13, -15],
        [-15, -27],
        [-30, -78],
        [-8, -57],
        [-11, -64],
        [-1, 29],
        [2, 48],
        [-8, 55],
        [-5, -101],
        [-12, -47],
        [-109, 3],
        [-47, -25],
        [-74, -86],
        [-22, -38],
        [-60, -145],
        [-16, -94],
        [-12, 40],
        [3, 29],
        [0, 24],
        [-15, -51],
        [15, -76],
        [-13, -28],
        [-40, -54],
        [-22, -8],
        [-25, -16],
        [-8, -52],
        [-33, -49],
        [-19, -22],
        [-36, 13],
        [11, -46],
        [-13, -36],
        [-23, -27],
        [-28, -18],
        [-16, 2],
        [-13, -9],
        [-11, -23],
        [-26, -21],
        [-28, 12],
        [-30, 7],
        [-18, -12],
        [29, -21],
        [16, -31],
        [-3, -41],
        [-8, -15],
        [-18, -22],
        [-8, 3],
        [-5, 20],
        [-6, 40],
        [-9, -9],
        [-1, -18],
        [-8, -7],
        [-25, 64],
        [1, -49],
        [9, -37],
        [9, -19],
        [8, -12],
        [3, -17],
        [-18, -42],
        [-9, -10],
        [-16, -7],
        [-10, -26],
        [3, -22],
        [-14, -49],
        [-34, -30],
        [-10, 1],
        [-9, -9],
        [5, -22],
        [9, -16],
        [-1, -14],
        [-9, -20],
        [-17, -6],
        [-10, -23],
        [3, -21],
        [6, -12],
        [-1, -21],
        [-21, -20],
        [-4, -21],
        [10, -6],
        [7, 6],
        [6, -4],
        [-12, -34],
        [-11, -21],
        [-10, -37],
        [-24, -11],
        [1, -12],
        [13, -11],
        [12, -28],
        [-22, -53],
        [-13, 4],
        [-8, 12],
        [-5, -42],
        [2, -22],
        [-5, -46],
        [-8, -55],
        [-6, -22],
        [1, -42],
        [4, -41],
        [13, -52],
        [20, -214],
        [13, -74],
        [24, -200],
        [41, -194],
        [57, -235],
        [93, -284],
        [11, -40],
        [-12, -35],
        [-4, -35],
        [-1, -54],
        [3, -52],
        [11, -64],
        [22, -98],
        [-12, 20],
        [-31, 140],
        [-4, 82],
        [5, 117],
        [-7, -3],
        [-6, -38],
        [-3, -44],
        [-8, -18],
        [-11, 68],
        [1, 31],
        [11, 36],
        [-3, 13],
        [-18, 18],
        [-4, 29],
        [2, 29],
        [-10, 15],
        [-8, -1],
        [5, -70],
        [9, -43],
        [11, -104],
        [17, -63],
        [10, -52],
        [118, -561],
        [28, -72],
        [10, -51],
        [11, -107],
        [2, -138],
        [-19, -252],
        [-5, -171],
        [-2, 5],
        [-2, 18],
        [-5, 2],
        [-16, -78],
        [-23, -71],
        [-8, -110],
        [-10, -56],
        [-33, -58],
        [-20, 1],
        [-50, -43],
        [-35, 11],
        [-42, -25],
        [-27, 3],
        [-16, 52],
        [3, 23],
        [6, 24],
        [11, 5],
        [36, -54],
        [7, 23],
        [-11, 27],
        [-21, 15],
        [-16, 17],
        [-31, 125],
        [-33, 85],
        [-6, 57],
        [-56, 35],
        [-41, 53],
        [-27, 94],
        [-15, 166],
        [-18, 19],
        [-8, 13],
        [18, 62],
        [19, 51],
        [-15, -13],
        [-11, -19],
        [-14, -45],
        [-10, -7],
        [-9, 7],
        [-11, 87],
        [3, 108],
        [15, 40],
        [-23, 2],
        [-23, -16],
        [3, -36],
        [-3, -20],
        [-18, 6],
        [-13, 12],
        [-17, 38],
        [-25, 71],
        [-49, 197],
        [-10, 27],
        [-17, 29],
        [8, 9],
        [14, 6],
        [32, 88],
        [25, 54],
        [9, 37],
        [-2, 16],
        [-11, 23],
        [-14, -20],
        [-7, 5],
        [-16, 47],
        [-16, 13],
        [-11, -10],
        [12, -38],
        [10, -14],
        [-4, -56],
        [-4, -18],
        [-10, -16],
        [-15, 9],
        [-7, -14],
        [-9, 15],
        [-9, 24],
        [-10, 40],
        [26, 225],
        [24, 144],
        [3, 163],
        [2, 24],
        [-2, 44],
        [-33, 94],
        [-145, 231],
        [-112, 273],
        [-97, 103],
        [-74, -23],
        [-12, -21],
        [-6, -26],
        [5, -31],
        [-7, -13],
        [-20, 2],
        [-26, -7],
        [-70, -72],
        [-25, 3],
        [-22, -19],
        [-17, -14],
        [-43, -8],
        [-37, -15],
        [-16, 8],
        [-10, 42],
        [0, 43],
        [8, -33],
        [13, -26],
        [6, 10],
        [2, 23],
        [-13, 45],
        [-41, 58],
        [-48, 84],
        [14, -3],
        [4, 18],
        [-15, 24],
        [7, 27],
        [10, 29],
        [-20, -4],
        [-18, -21],
        [-1, -24],
        [-3, -20],
        [-10, 3],
        [-18, 24],
        [-89, 68],
        [-77, 39],
        [59, 17],
        [32, -14],
        [-3, 21],
        [-8, 13],
        [-26, 17],
        [-32, -7],
        [-21, 8],
        [-21, -16],
        [-22, -25],
        [-21, -12],
        [-80, -18],
        [-65, -19],
        [11, 20],
        [11, 13],
        [38, 20],
        [6, 41],
        [-9, 39],
        [-10, -9],
        [-11, -31],
        [-13, 22],
        [-14, 0],
        [-4, -49],
        [-19, -33],
        [-8, -33],
        [-54, -26],
        [-7, 9],
        [16, 31],
        [-1, 18],
        [-19, -15],
        [-30, -60],
        [-106, -19],
        [5, 13],
        [23, 3],
        [32, 19],
        [-7, 32],
        [-12, 34],
        [-11, 4],
        [-8, 21],
        [1, 64],
        [-7, 38],
        [-18, 39],
        [-6, -8],
        [-12, -66],
        [-11, -87],
        [-5, -28],
        [-32, -2],
        [-28, 6],
        [-95, -11],
        [-35, 30],
        [-15, 6],
        [-9, -1],
        [-41, -27],
        [-48, -21],
        [-11, 7],
        [-16, 1],
        [-34, -71],
        [-40, -33],
        [-102, 60],
        [-25, 47],
        [-22, 10],
        [-28, 6],
        [-29, -58],
        [-23, -79],
        [36, -44],
        [30, -21],
        [50, 18],
        [28, 38],
        [23, -1],
        [11, 8],
        [10, 20],
        [19, -16],
        [1, -16],
        [-14, -22],
        [-17, -19],
        [-11, -22],
        [20, -45],
        [31, -15],
        [12, 7],
        [7, 50],
        [19, 32],
        [26, -7],
        [-3, -20],
        [3, -19],
        [12, -33],
        [-1, -47],
        [2, -11],
        [-28, -20],
        [-21, -8],
        [-17, -27],
        [9, -15],
        [-17, -14],
        [-11, 6],
        [-6, -6],
        [-2, -16],
        [-9, -16],
        [13, -46],
        [26, -30],
        [19, -38],
        [74, -50],
        [18, 1],
        [18, -50],
        [14, -18],
        [14, -8],
        [-1, -35],
        [-25, -25],
        [-7, -31],
        [-6, -17],
        [-11, 22],
        [-11, 15],
        [-26, -47],
        [-13, -10],
        [6, 51],
        [-10, 20],
        [-15, 52],
        [-21, 31],
        [-16, 11],
        [-12, 20],
        [-14, 8],
        [-13, -2],
        [-21, 12],
        [-1, 27],
        [-6, 20],
        [-16, 25],
        [-78, 45],
        [-1, -19],
        [6, -14],
        [11, -9],
        [13, -19],
        [0, -54],
        [-6, -23],
        [-2, -33],
        [-5, -34],
        [-10, -26],
        [-21, -18],
        [-10, 15],
        [-15, 72],
        [-22, 23],
        [-34, 2],
        [-23, -16],
        [-25, -70],
        [-21, -11],
        [-70, 36],
        [-80, 55],
        [2, 18],
        [13, 6],
        [24, -7],
        [-1, 19],
        [-25, 61],
        [-4, 28],
        [3, 34],
        [-8, -1],
        [-15, -28],
        [-51, 24],
        [-14, 28],
        [-30, 81],
        [-42, 3],
        [-19, 49],
        [-35, -20],
        [-17, -23],
        [-15, -35],
        [6, -19],
        [15, -29],
        [-7, -14],
        [-49, -21],
        [-114, 24],
        [-34, 21],
        [-44, 46],
        [-62, 37],
        [-30, 6],
        [-29, -7],
        [-86, -4],
        [-19, -10],
        [-17, -16],
        [-11, 18],
        [-5, 31],
        [10, 5],
        [11, 19],
        [10, 36],
        [1, 22],
        [-7, 15],
        [-13, 1],
        [-29, -95],
        [16, -53],
        [-1, -19],
        [-58, -11],
        [-132, -107],
        [-51, -58],
        [2, 19],
        [62, 75],
        [-21, 12],
        [-36, -19],
        [-13, 7],
        [15, 62],
        [-4, 55],
        [-26, 1],
        [-16, -43],
        [-11, 1],
        [-14, 19],
        [-11, -6],
        [8, -98],
        [16, -41],
        [13, -52],
        [-36, -64],
        [-34, -53],
        [-3, -51],
        [-34, -66],
        [-32, -38],
        [-74, -88],
        [-22, -19],
        [-33, -42],
        [-47, -30],
        [-44, -49],
        [-15, -8],
        [28, 42],
        [34, 41],
        [-29, -6],
        [-44, 19],
        [-28, 1],
        [0, -15],
        [-21, -21],
        [-21, 31],
        [-9, 21],
        [-5, 18],
        [-9, 4],
        [-9, -8],
        [32, -127],
        [14, -5],
        [15, -13],
        [-19, -29],
        [-20, -23],
        [-32, -15],
        [-27, 47],
        [-6, -59],
        [-3, -58],
        [-9, -14],
        [-15, -22],
        [-7, 16],
        [-4, 23],
        [-9, -20],
        [-14, -15],
        [-22, -3],
        [-17, -8],
        [0, -24],
        [4, -24],
        [30, 19],
        [-11, -62],
        [-27, -62],
        [-23, -14],
        [-34, 9],
        [-8, -6],
        [-8, -13],
        [40, -96],
        [-25, -145],
        [-17, -52],
        [-11, -7],
        [-12, -2],
        [-44, 47],
        [-24, 36],
        [21, -98],
        [58, -29],
        [3, -37],
        [-1, -31],
        [-11, -38],
        [-11, -49],
        [8, -35],
        [9, -85],
        [8, -39],
        [8, -119],
        [10, -51],
        [52, -189],
        [18, -2],
        [2, -20],
        [-1, -40],
      ],
      [
        [23015, 66797],
        [-38, -11],
        [-16, -18],
        [-3, -15],
        [-2, -8],
        [-5, 1],
        [-18, 11],
        [-41, 53],
        [-59, 34],
        [-78, 13],
        [-54, 27],
        [-28, 41],
        [-30, 24],
        [-32, 8],
        [-26, 22],
        [-20, 37],
        [-30, 23],
        [-39, 10],
        [-26, 28],
        [-18, 68],
        [0, 1],
        [-16, 112],
        [-20, 71],
        [-39, 88],
        [-4, 11],
        [0, 15],
        [4, 51],
        [-4, 36],
        [-12, 30],
        [-3, 32],
        [7, 33],
        [0, 40],
        [-6, 46],
        [-25, 51],
        [-44, 54],
        [-37, 79],
        [-31, 104],
        [-31, 72],
        [-30, 40],
        [-21, 49],
        [-11, 58],
        [-4, 34],
        [5, 9],
        [-19, 64],
        [-42, 120],
        [-24, 87],
        [-6, 55],
        [-27, 66],
        [-47, 76],
        [-25, 49],
        [-7, 35],
        [-73, 100],
        [-21, 62],
        [-17, 20],
        [-19, -2],
        [-10, 6],
        [-1, 14],
        [-6, 1],
        [-12, -13],
        [-39, -3],
        [-66, 8],
        [-48, 17],
        [-29, 27],
        [-21, -4],
        [-12, -34],
        [-25, -21],
        [-38, -10],
        [-33, -62],
        [-28, -116],
        [-12, -74],
        [3, -33],
        [-7, -24],
        [-18, -15],
        [-19, -33],
        [-18, -52],
        [-22, -25],
        [-25, 1],
        [-46, 40],
        [-67, 79],
        [-52, 48],
        [-38, 19],
        [-34, 36],
        [-29, 54],
        [-27, 36],
        [-24, 18],
        [-29, 60],
        [-33, 102],
        [-16, 78],
        [0, 83],
        [-43, 181],
        [-23, 78],
        [-17, 36],
        [-33, 43],
        [-49, 50],
        [-67, 100],
        [-83, 150],
        [-58, 90],
        [-35, 30],
        [-30, 54],
        [-25, 78],
        [-25, 49],
        [-2, 2],
        [-3, 1],
        [-61, 1],
        [-61, 1],
        [-61, 0],
        [-61, 1],
        [-61, 1],
        [-61, 0],
        [-61, 1],
        [-61, 1],
        [0, -65],
        [0, -65],
        [-1, -65],
        [0, -64],
        [-98, -1],
        [-98, 0],
        [-98, -1],
        [-98, 0],
        [-99, 0],
        [-98, -1],
        [-98, 0],
        [-98, 0],
        [-132, 85],
        [-132, 85],
        [-131, 85],
        [-132, 86],
        [-132, 85],
        [-132, 85],
        [-131, 85],
        [-132, 86],
        [13, 32],
        [18, 87],
        [-32, -6],
        [-79, -13],
        [-80, -12],
        [-79, -12],
        [-80, -13],
        [-79, -12],
        [-80, -12],
        [-79, -13],
        [-80, -12],
      ],
      [
        [17464, 70583],
        [0, 4],
        [-2, 63],
        [-13, 22],
        [-17, -14],
        [-7, 82],
        [4, 39],
        [-2, 38],
        [-16, 92],
        [-41, 113],
        [-89, 140],
        [-46, 47],
        [-35, 59],
        [-23, 16],
        [-28, 5],
        [-9, -27],
        [-32, 18],
        [5, 66],
        [-32, 92],
        [-25, 10],
        [-65, -6],
        [-87, 50],
        [-25, 30],
        [-9, 54],
        [-41, 47],
        [-53, 46],
        [-30, -11],
        [-39, 7],
        [-55, 33],
        [-33, 4],
        [-63, -9],
        [-23, 7],
        [-22, 41],
        [-24, 21],
        [5, 51],
        [-3, 47],
        [4, 36],
        [-11, 79],
        [9, 73],
        [-8, 26],
        [-13, 21],
        [-42, 30],
        [-7, 37],
        [7, 52],
        [-11, 35],
        [-35, 32],
        [-32, 73],
        [-40, 39],
        [-17, 67],
        [-25, 42],
        [-8, 36],
        [-56, 131],
        [-59, 102],
        [-9, 58],
        [-2, 81],
        [23, 49],
        [12, 43],
        [-1, 39],
        [-4, 29],
        [-20, 51],
        [-79, 30],
        [-64, 124],
        [-4, 96],
        [-25, 98],
        [0, 63],
        [-4, 69],
        [19, 15],
        [17, -6],
        [-1, -27],
        [5, -49],
        [20, -37],
        [19, -16],
        [18, -36],
        [13, -11],
        [13, -3],
        [-7, 23],
        [-8, 15],
        [-9, 48],
        [-18, 61],
        [-20, 33],
        [-11, 61],
        [-9, 15],
        [-5, 22],
        [20, 27],
        [27, 19],
        [36, 6],
        [103, -9],
        [21, 15],
        [19, -5],
        [13, 2],
        [-28, 16],
        [-16, -5],
        [-18, 3],
        [-37, -3],
        [-15, 7],
        [-16, 19],
        [-11, 2],
        [-34, -33],
        [-15, 4],
        [-36, 36],
        [-15, 5],
        [-25, -20],
        [-4, -90],
        [8, -66],
        [-15, -7],
        [-17, 27],
        [-27, 17],
        [-22, 25],
        [-32, 46],
        [-16, 17],
        [-18, -39],
        [-1, 18],
        [9, 45],
        [-3, 75],
        [28, -60],
        [-8, 42],
        [-22, 47],
        [-17, 16],
        [-20, 83],
        [-47, 50],
        [-38, 80],
        [-77, 134],
        [-5, 117],
        [-28, 148],
        [12, 85],
        [-1, 59],
        [-14, 91],
        [-15, 49],
        [-62, 135],
        [-60, 90],
        [-9, 69],
        [-4, 69],
        [13, 62],
        [12, 64],
        [8, 17],
        [3, -7],
        [-2, -13],
        [8, -5],
        [3, 29],
        [6, 15],
        [-9, 2],
        [1, 9],
        [5, 18],
        [18, 85],
        [-1, 107],
        [19, 131],
        [-1, 44],
        [-12, 93],
        [-13, 56],
        [-23, 40],
        [10, 58],
        [0, 55],
        [-40, 80],
        [-15, 104],
        [-3, 45],
        [4, 116],
        [-11, 50],
        [-27, 82],
        [12, 72],
        [12, 43],
        [30, 190],
        [7, 15],
        [13, 0],
        [22, 32],
        [-10, 8],
        [-16, -16],
        [14, 75],
        [15, 64],
        [10, 24],
        [5, 209],
        [9, 160],
        [14, 53],
        [-5, 55],
        [6, 74],
        [-4, 74],
        [31, 359],
        [-4, 43],
        [9, 59],
        [-9, 153],
        [4, 171],
        [-8, 22],
        [-4, 24],
        [8, 3],
        [14, -25],
        [66, 0],
        [42, 24],
        [16, -8],
        [17, -31],
        [23, -7],
        [28, 6],
        [-9, 8],
        [-13, 2],
        [-29, 29],
        [-17, 28],
        [-51, -1],
        [-11, 18],
        [-58, -18],
        [-17, 19],
        [-32, -13],
        [8, 54],
        [-2, 68],
        [2, 66],
        [8, -48],
        [19, -52],
        [10, 59],
        [6, 72],
        [-19, 29],
        [-32, 20],
        [-11, 68],
        [75, 58],
        [-40, 12],
        [-15, 26],
        [-20, 4],
        [-1, -20],
        [-6, -27],
        [-7, 35],
        [-2, 41],
        [-8, 70],
        [-31, 113],
        [-18, 147],
        [-23, 72],
        [-45, 69],
        [-12, 40],
        [-11, 103],
        [7, 77],
        [-9, 54],
        [22, -3],
        [56, -42],
        [71, -34],
        [21, -24],
        [34, -19],
        [189, -28],
        [13, 3],
        [24, 17],
        [11, -2],
        [27, -40],
        [14, -4],
        [18, 2],
        [14, 7],
        [23, 28],
        [3, -11],
        [-1, -25],
        [8, -36],
        [17, -47],
        [7, -29],
        [-34, -82],
        [-7, -2],
        [-1, 28],
        [-4, 5],
        [-64, -139],
        [-22, -66],
        [-2, -29],
        [0, -18],
        [9, -4],
        [21, 7],
        [30, 27],
        [1, 6],
        [-28, -10],
        [-13, 0],
        [1, 31],
        [4, 14],
        [18, 47],
        [19, 27],
        [28, 30],
        [16, 24],
        [11, 35],
        [30, 43],
        [6, 11],
        [-2, 35],
        [2, 7],
        [15, -5],
        [6, -60],
        [-4, -27],
        [-26, -32],
        [-3, -12],
        [5, -44],
        [-5, -4],
        [-10, 5],
        [-3, -3],
        [25, -48],
        [8, -38],
        [1, -33],
        [-7, -65],
        [-7, -10],
        [-12, 3],
        [-17, 21],
        [-3, -7],
        [-13, -50],
        [-5, 4],
        [-8, 60],
        [-4, 4],
        [-25, -27],
        [-11, -26],
        [-8, -41],
        [-11, -20],
        [31, -4],
        [28, 8],
        [23, -19],
        [8, -1],
        [21, 19],
        [6, 14],
        [17, 62],
        [9, 11],
        [13, 1],
        [12, 9],
        [19, 34],
        [0, 14],
        [-6, 77],
        [2, 43],
        [-4, 14],
        [-8, 14],
        [1, 14],
        [6, 23],
        [1, 21],
        [-6, 18],
        [3, 21],
        [17, 45],
        [3, 20],
        [22, 45],
        [-6, 18],
        [-15, 22],
        [-10, 19],
        [-10, 30],
        [-8, 10],
        [-2, -4],
        [11, -50],
        [-3, -3],
        [-27, 27],
        [-7, 16],
        [-3, 23],
        [2, 17],
        [15, 17],
        [18, 6],
        [-2, 15],
        [-22, 46],
        [-15, 21],
        [-11, 10],
        [-15, 3],
        [-7, 7],
        [-2, 11],
        [3, 15],
        [9, 4],
        [23, -6],
        [13, 11],
        [-1, 18],
        [-4, 10],
        [1, 66],
        [-9, 53],
        [-5, 9],
        [-5, 1],
        [-6, -7],
        [-14, -2],
        [-10, 18],
        [-10, 34],
        [-18, 80],
      ],
      [
        [15892, 80064],
        [28, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [118, 0],
        [119, 0],
        [118, 0],
        [66, 0],
        [1, 121],
        [1, 96],
        [60, -11],
        [18, -18],
        [5, -8],
        [-1, -27],
        [5, -80],
        [10, -67],
        [26, -80],
        [0, -1],
        [2, -31],
        [8, -20],
        [15, -18],
        [58, -22],
        [99, -26],
        [57, -30],
        [13, -33],
        [27, -14],
        [40, 7],
        [28, 14],
        [23, 32],
        [34, 7],
        [28, -2],
        [29, -4],
        [15, -4],
        [45, -26],
        [29, -20],
        [41, -39],
        [23, -17],
        [11, -40],
        [13, -51],
        [18, 0],
        [14, 30],
        [35, 5],
        [47, -21],
        [41, -60],
        [58, -53],
        [36, -27],
        [36, 0],
        [47, 27],
        [49, 51],
        [35, 9],
        [21, -5],
        [12, -40],
        [15, -15],
        [38, 4],
        [80, -8],
        [63, 11],
        [15, -23],
        [13, -36],
        [25, -11],
        [35, 11],
        [63, -9],
        [26, -2],
        [51, 14],
        [24, 16],
        [34, 26],
        [46, 36],
        [79, 62],
        [65, 23],
        [61, -45],
        [48, -39],
        [18, -16],
        [49, -40],
        [70, -57],
        [79, -65],
        [80, -65],
        [69, -57],
        [49, -40],
        [19, -16],
        [54, -46],
        [54, -46],
        [54, -46],
        [53, -46],
        [54, -46],
        [54, -46],
        [54, -46],
        [54, -46],
        [14, -77],
        [13, -75],
        [31, -54],
        [29, -49],
        [17, 2],
        [17, 21],
        [11, 10],
        [18, 2],
        [40, 18],
        [12, -4],
        [7, -9],
        [0, -14],
        [-1, -11],
        [-6, -23],
        [9, -42],
        [2, -47],
        [6, -36],
        [16, -46],
        [15, -36],
        [18, -7],
        [41, 21],
        [26, 8],
        [15, -3],
        [25, -34],
        [13, -20],
        [3, -17],
        [-34, -102],
        [54, -51],
        [60, -55],
        [73, -66],
        [44, -41],
        [58, -58],
        [10, -82],
        [8, -70],
        [11, -96],
        [11, -99],
        [11, -99],
        [11, -104],
        [13, -115],
        [11, -102],
        [12, -111],
        [17, -145],
        [-15, -56],
        [-32, -121],
        [-28, -110],
        [-3, -32],
        [-20, -160],
        [-16, -66],
        [-27, -38],
        [-28, -38],
        [-34, -62],
        [-38, -31],
        [-19, -18],
        [-10, -28],
        [-11, -63],
        [2, -96],
        [31, -82],
        [45, -46],
        [49, -45],
        [70, 0],
        [63, 60],
        [66, 63],
        [59, 57],
        [71, 67],
        [64, 61],
        [69, 22],
        [96, 30],
        [121, 38],
        [59, 43],
        [76, 57],
        [87, 64],
        [76, 56],
        [38, 31],
        [27, 36],
        [7, 26],
        [-2, 15],
        [-7, 15],
        [-9, 11],
        [-9, 9],
        [-4, 12],
        [-1, 26],
        [-5, 14],
        [-5, 11],
        [2, 99],
        [-7, 31],
        [-24, 78],
        [47, 35],
        [43, 32],
        [35, 24],
        [73, 4],
        [67, -1],
        [94, 0],
        [78, -1],
        [92, 0],
        [53, -1],
        [71, 1],
        [34, 90],
        [31, 81],
        [34, 76],
        [60, 91],
        [17, 16],
        [10, 35],
        [36, 34],
        [40, 31],
        [16, 30],
        [7, 17],
        [109, 158],
        [62, 73],
        [50, 41],
        [39, 19],
        [26, -2],
        [15, 2],
      ],
      [
        [29858, 75452],
        [-20, -37],
        [18, -4],
        [16, 11],
        [14, 22],
        [34, 30],
        [29, 13],
        [9, 3],
        [13, -21],
        [28, 17],
        [28, 9],
        [-121, -96],
        [-25, -11],
        [-36, -28],
        [-33, -21],
        [-24, -7],
        [-120, -71],
        [-10, -2],
        [-10, 7],
        [-99, -36],
        [-40, -5],
        [-37, -12],
        [27, 29],
        [1, 11],
        [-7, 9],
        [-14, -3],
        [-15, -30],
        [-24, -10],
        [-5, 33],
        [8, 26],
        [11, 24],
        [24, 38],
        [34, 24],
        [17, 21],
        [12, -18],
        [2, 25],
        [10, 14],
        [10, 8],
        [24, 0],
        [12, 4],
        [10, 8],
        [9, 2],
        [27, -12],
        [25, 4],
        [21, 15],
        [22, 5],
        [56, 4],
        [57, 11],
        [23, 21],
        [47, 56],
        [28, 16],
        [-43, -66],
        [-23, -30],
      ],
      [
        [31059, 77380],
        [-16, -11],
        [-18, 4],
        [0, -30],
        [-2, -11],
        [-19, 16],
        [-8, 10],
        [1, 40],
        [17, 38],
        [14, 15],
        [17, -10],
        [13, -43],
        [1, -18],
      ],
      [
        [29392, 75185],
        [-13, -2],
        [13, 55],
        [24, 25],
        [9, -5],
        [0, -19],
        [-3, -17],
        [-16, -26],
        [-14, -11],
      ],
      [
        [30414, 75677],
        [-77, -28],
        [-12, 18],
        [19, 8],
        [24, 43],
        [16, 5],
        [25, -24],
        [5, -22],
      ],
      [
        [30210, 75743],
        [-13, -15],
        [-16, 2],
        [8, 22],
        [3, 31],
        [8, 34],
        [4, 11],
        [9, 9],
        [-3, -94],
      ],
      [
        [30561, 75613],
        [-21, -9],
        [-49, 21],
        [40, 18],
        [7, 6],
        [5, 27],
        [1, 13],
        [15, -57],
        [2, -19],
      ],
      [
        [28990, 72497],
        [-4, -12],
        [-19, 64],
        [19, -21],
        [4, -17],
        [0, -14],
      ],
      [
        [29015, 72142],
        [-37, -16],
        [-3, 6],
        [42, 32],
        [14, 116],
        [2, 53],
        [-7, 95],
        [1, 19],
        [6, -30],
        [7, -88],
        [-3, -67],
        [-12, -97],
        [-10, -23],
      ],
      [
        [28949, 72113],
        [-50, -41],
        [-6, 3],
        [33, 29],
        [23, 9],
      ],
      [
        [28749, 71798],
        [-7, -6],
        [25, 72],
        [50, 91],
        [14, 14],
        [-42, -78],
        [-40, -93],
      ],
      [
        [27212, 67081],
        [-10, -15],
        [-20, 11],
        [-11, 20],
        [-5, 38],
        [17, -41],
        [7, -9],
        [22, -4],
      ],
      [
        [27199, 67138],
        [-1, -34],
        [-14, 57],
        [-9, 62],
        [13, -20],
        [11, -65],
      ],
      [
        [27671, 66325],
        [-55, -108],
        [6, 27],
        [22, 58],
        [7, 28],
        [14, 17],
        [14, 31],
        [1, 37],
        [20, 25],
        [6, 4],
        [-35, -119],
      ],
      [
        [24502, 68836],
        [-11, -8],
        [-46, 50],
        [-3, 21],
        [23, 20],
        [14, -2],
        [22, -25],
        [8, -8],
        [3, -10],
        [-2, -16],
        [-8, -22],
      ],
      [
        [23051, 67915],
        [-6, -1],
        [14, 47],
        [2, 19],
        [22, 59],
        [12, 9],
        [5, -25],
        [-23, -42],
        [-26, -66],
      ],
      [
        [23121, 68060],
        [-10, -3],
        [12, 31],
        [21, 16],
        [45, 60],
        [18, 4],
        [10, 20],
        [4, 3],
        [-3, -25],
        [-36, -36],
        [-61, -70],
      ],
      [
        [22957, 67568],
        [-9, -33],
        [3, 50],
        [22, 112],
        [46, 147],
        [20, 25],
        [-53, -162],
        [-29, -139],
      ],
      [
        [23600, 68632],
        [-14, -6],
        [61, 89],
        [12, 29],
        [16, -1],
        [-27, -50],
        [-48, -61],
      ],
      [
        [23008, 66911],
        [-4, -26],
        [-23, 125],
        [-37, 282],
        [-2, 161],
        [6, 56],
        [10, -228],
        [41, -289],
        [9, -81],
      ],
      [
        [16581, 71442],
        [-14, -1],
        [-23, 6],
        [8, 13],
        [12, 10],
        [4, -7],
        [13, -21],
      ],
      [
        [16823, 70977],
        [-13, -1],
        [-17, 5],
        [-8, 31],
        [13, 2],
        [13, -4],
        [10, -24],
        [2, -9],
      ],
      [
        [17125, 70753],
        [-16, -6],
        [-18, 12],
        [-16, 56],
        [-17, 43],
        [9, 13],
        [14, -42],
        [35, -64],
        [9, -12],
      ],
      [
        [16654, 71381],
        [-19, -8],
        [-15, 8],
        [-23, 55],
        [50, 7],
        [21, -24],
        [3, -7],
        [-17, -31],
      ],
      [
        [16699, 71474],
        [57, -30],
        [30, 14],
        [6, -14],
        [-4, -12],
        [-69, -23],
        [-21, 16],
        [-2, 21],
        [-7, 21],
        [10, 7],
      ],
      [
        [17125, 71074],
        [14, -42],
        [-20, 5],
        [-21, -3],
        [-6, 24],
        [-7, 32],
        [-4, 8],
        [-14, 3],
        [-1, 3],
        [-2, 15],
        [4, 8],
        [45, -36],
        [12, -17],
      ],
      [
        [15894, 79880],
        [3, -13],
        [-11, -12],
        [-8, -2],
        [-12, 20],
        [-6, 2],
        [5, -30],
        [-2, -10],
        [-26, 18],
        [-5, 15],
        [8, 16],
        [16, 16],
        [6, 2],
        [32, -22],
      ],
      [
        [15830, 79781],
        [7, -19],
        [-30, 12],
        [-13, 11],
        [-3, 11],
        [-5, 34],
        [2, 12],
        [13, 4],
        [25, -43],
        [4, -22],
      ],
      [
        [15952, 79583],
        [13, -76],
        [6, 31],
        [38, -54],
        [0, -27],
        [-4, -9],
        [-8, -3],
        [-8, 8],
        [-6, 19],
        [-9, 10],
        [-18, 6],
        [-9, 21],
        [-4, 15],
        [0, 42],
        [-5, 14],
        [-10, 2],
        [-9, 11],
        [-14, 29],
        [-2, 8],
        [7, 24],
        [15, 41],
        [11, 19],
        [7, -2],
        [9, -13],
        [10, -22],
        [-2, -15],
        [-41, -31],
        [-2, -7],
        [21, -9],
        [7, -7],
        [7, -25],
      ],
      [
        [30176, 75740],
        [-8, -11],
        [-3, 28],
        [6, 32],
        [5, 0],
        [3, -17],
        [-3, -32],
      ],
      [
        [29407, 74700],
        [-32, -87],
        [-1, 17],
        [41, 108],
        [-8, -38],
      ],
      [
        [29074, 73668],
        [-13, -10],
        [43, 116],
        [24, 96],
        [11, 34],
        [-10, -68],
        [-19, -62],
        [-36, -106],
      ],
      [
        [28737, 71805],
        [-6, -1],
        [-11, 6],
        [-15, 12],
        [-4, 9],
        [15, -3],
        [21, -23],
      ],
      [
        [27407, 66042],
        [-9, -12],
        [-4, 4],
        [0, 17],
        [-11, 39],
        [0, 10],
        [27, -38],
        [1, -10],
        [-4, -10],
      ],
      [
        [27547, 66130],
        [-5, 0],
        [2, 9],
        [11, 16],
        [4, -6],
        [0, -9],
        [-12, -10],
      ],
      [
        [27600, 66188],
        [-7, -3],
        [11, 25],
        [3, -2],
        [-7, -20],
      ],
      [
        [27487, 66080],
        [-12, -13],
        [-13, 10],
        [14, 13],
        [43, 15],
        [-16, -18],
        [-16, -7],
      ],
      [
        [27383, 69683],
        [-12, -140],
        [-5, 50],
        [-1, 48],
        [9, 28],
        [9, 14],
      ],
      [
        [27342, 66013],
        [-18, -6],
        [15, 23],
        [5, 34],
        [8, -26],
        [0, -17],
        [-10, -8],
      ],
      [
        [27726, 67556],
        [4, -42],
        [-25, 98],
        [-32, 154],
        [-17, 120],
        [12, -33],
        [11, -66],
        [47, -231],
      ],
      [
        [27282, 65981],
        [-7, -1],
        [-1, 9],
        [12, 11],
        [9, -1],
        [-1, -12],
        [-12, -6],
      ],
      [
        [25308, 68958],
        [-15, -30],
        [1, 12],
        [11, 30],
        [8, 11],
        [-5, -23],
      ],
      [
        [25400, 69248],
        [-3, -6],
        [-25, 12],
        [-15, 11],
        [-2, 11],
        [41, -20],
        [4, -8],
      ],
      [
        [25535, 69269],
        [-24, -12],
        [-36, 1],
        [-8, 4],
        [15, 8],
        [43, 11],
        [10, -12],
      ],
      [
        [25215, 69172],
        [1, -27],
        [-13, 14],
        [-20, 1],
        [8, 9],
        [7, 9],
        [3, 10],
        [25, 33],
        [-7, -25],
        [-4, -24],
      ],
      [
        [25325, 69013],
        [-7, -18],
        [7, 87],
        [-11, 74],
        [12, -32],
        [4, -39],
        [-5, -72],
      ],
      [
        [26414, 68918],
        [-28, -21],
        [-30, 15],
        [19, 3],
        [13, -6],
        [35, 30],
        [18, 22],
        [21, 9],
        [-48, -52],
      ],
      [
        [15874, 79034],
        [-3, -11],
        [-4, 1],
        [-8, 23],
        [-1, 16],
        [7, 12],
        [10, -34],
        [-1, -7],
      ],
      [
        [16001, 79144],
        [-1, -13],
        [-10, -10],
        [-6, 2],
        [0, 16],
        [-3, 2],
        [-12, -18],
        [1, 36],
        [6, 39],
        [5, 1],
        [7, -26],
        [13, -29],
      ],
      [
        [15973, 79259],
        [-2, -11],
        [-15, 13],
        [-5, 12],
        [1, 27],
        [3, 18],
        [3, 4],
        [9, -8],
        [3, -4],
        [3, -51],
      ],
      [
        [15883, 79741],
        [-4, -6],
        [-15, 8],
        [-9, 13],
        [-3, 16],
        [6, 30],
        [7, 8],
        [5, -2],
        [2, -27],
        [13, -28],
        [-2, -12],
      ],
      [
        [30938, 77301],
        [-11, -11],
        [-11, 3],
        [0, 29],
        [3, 9],
        [4, 5],
        [6, -8],
        [9, -27],
      ],
      [
        [42704, 18182],
        [1, -33],
        [-43, 30],
        [-10, 14],
        [14, 19],
        [27, 0],
        [7, -11],
        [4, -19],
      ],
      [
        [39693, 20699],
        [27, -28],
        [22, 19],
        [22, -2],
        [12, -10],
        [12, -3],
        [16, -1],
        [27, -47],
        [-11, -41],
        [29, 8],
        [26, -35],
        [12, 3],
        [5, 14],
        [17, 16],
        [11, -22],
        [14, -41],
        [18, -12],
        [15, -44],
        [12, -55],
        [11, -8],
        [19, -1],
        [20, 8],
        [-8, -48],
        [3, -42],
        [32, -30],
        [-19, -17],
        [-20, -24],
        [-41, -19],
        [-11, 8],
        [-35, 42],
        [-17, 50],
        [-37, 71],
        [-8, 21],
        [-9, 13],
        [-34, 9],
        [-30, 17],
        [-24, 36],
        [-8, 22],
        [-10, 15],
        [-33, -1],
        [-21, 17],
        [-21, 23],
        [-94, 67],
        [-37, -7],
        [-17, 19],
        [0, 33],
        [20, 20],
        [-82, 8],
        [-29, 12],
        [20, 7],
        [114, 1],
        [43, 6],
        [3, -15],
        [38, -29],
        [36, -3],
      ],
      [
        [70136, 47593],
        [-6, -23],
        [-12, -10],
        [-5, 58],
        [-17, 41],
        [7, 0],
        [15, -21],
        [6, -56],
        [5, 17],
        [-1, 17],
        [3, 16],
        [-2, 18],
        [-9, 28],
        [3, 5],
        [13, -23],
        [2, -19],
        [-2, -48],
      ],
      [
        [48418, 42627],
        [-25, -3],
        [2, 27],
        [19, 29],
        [13, -4],
        [0, -33],
        [-9, -16],
      ],
      [
        [46009, 47249],
        [-9, -1],
        [-3, 5],
        [-2, 14],
        [5, 22],
        [4, 13],
        [7, -2],
        [8, -15],
        [8, -13],
        [-4, -12],
        [-14, -11],
      ],
      [
        [14364, 37789],
        [-3, -9],
        [-6, 8],
        [-6, 16],
        [-2, 18],
        [6, 10],
        [7, -6],
        [4, -18],
        [0, -19],
      ],
      [
        [32499, 62339],
        [-44, -29],
        [2, 17],
        [35, 40],
        [13, -3],
        [-6, -25],
      ],
      [
        [33652, 22309],
        [43, -33],
        [53, 11],
        [22, -9],
        [13, -28],
        [-7, -26],
        [-17, 4],
        [-15, -7],
        [3, -35],
        [10, -14],
        [56, -38],
        [10, -2],
        [-1, 15],
        [-10, 26],
        [-4, 28],
        [9, 24],
        [14, 7],
        [64, 11],
        [15, -11],
        [32, -66],
        [-30, -9],
        [-12, -28],
        [26, -12],
        [20, -19],
        [-11, -28],
        [-2, -14],
        [-46, -19],
        [-40, -13],
        [-19, -33],
        [-33, -24],
        [-96, -41],
        [11, -34],
        [1, -16],
        [-4, -44],
        [-133, 52],
        [-18, -5],
        [36, -90],
        [-26, -16],
        [-26, 10],
        [-24, -7],
        [-15, -65],
        [-38, 42],
        [-32, 58],
        [-1, 33],
        [32, 62],
        [-10, 26],
        [73, 83],
        [13, 25],
        [23, 14],
        [23, 5],
        [10, 11],
        [-1, 20],
        [-10, 35],
        [1, 57],
        [58, 77],
        [-8, 49],
        [18, 1],
      ],
      [
        [33253, 22199],
        [41, -11],
        [37, 40],
        [25, 13],
        [21, -9],
        [15, -24],
        [21, 4],
        [61, 25],
        [8, -9],
        [21, 29],
        [19, -13],
        [14, -25],
        [-7, -30],
        [-17, -19],
        [-10, -26],
        [-13, -21],
        [-21, -19],
        [-16, -31],
        [-40, -73],
        [-57, -94],
        [-19, -8],
        [-40, -6],
        [-17, 7],
        [-14, -2],
        [-12, -51],
        [-18, -38],
        [-9, -8],
        [-19, -4],
        [-8, -5],
        [-7, -14],
        [-50, 3],
        [-35, 24],
        [-41, 52],
        [55, 64],
        [48, -3],
        [39, 43],
        [32, 21],
        [13, 22],
        [14, 17],
        [0, 22],
        [-11, 10],
        [-14, -1],
        [-14, -10],
        [-34, -12],
        [-23, 25],
        [15, 10],
        [17, -1],
        [52, 24],
        [10, 10],
        [-16, 33],
        [-31, 21],
        [-26, 34],
        [-4, 13],
        [1, 20],
        [-14, 41],
        [15, 2],
        [19, -26],
        [44, -36],
      ],
      [
        [33050, 22012],
        [20, -8],
        [20, 3],
        [-11, -59],
        [-9, -28],
        [-24, 2],
        [-23, 39],
        [-8, 20],
        [26, 15],
        [9, 16],
      ],
      [
        [33767, 21882],
        [1, -50],
        [-22, 16],
        [-8, 24],
        [12, 17],
        [11, -1],
        [6, -6],
      ],
      [
        [33302, 22237],
        [-38, 0],
        [-8, 19],
        [1, 47],
        [28, 4],
        [29, -20],
        [-2, -20],
        [-10, -30],
      ],
      [
        [33421, 21755],
        [-18, -11],
        [-5, 5],
        [-5, 22],
        [-1, 28],
        [-2, 12],
        [12, -4],
        [20, -22],
        [-1, -30],
      ],
      [
        [27397, 62988],
        [9, -11],
        [11, 7],
        [4, 12],
        [42, -9],
        [7, -24],
        [-33, -1],
        [-14, -15],
        [-8, -3],
        [-28, 4],
        [-4, 55],
        [8, 6],
        [6, -21],
      ],
      [
        [27783, 63195],
        [-2, -3],
        [-9, 2],
        [-21, -23],
        [-8, 1],
        [2, 9],
        [4, 7],
        [5, 6],
        [5, 2],
        [14, 5],
        [7, 1],
        [4, -6],
        [-1, -1],
      ],
      [
        [27827, 63197],
        [-13, -8],
        [-10, 3],
        [22, 24],
        [6, 8],
        [5, 4],
        [5, 0],
        [7, -5],
        [0, -3],
        [-22, -23],
      ],
      [
        [32019, 70445],
        [-25, -20],
        [-7, 2],
        [-5, 7],
        [26, 19],
        [21, 46],
        [7, -3],
        [-17, -51],
      ],
      [
        [32112, 62479],
        [-7, -4],
        [-5, 1],
        [-1, 8],
        [5, 23],
        [28, 2],
        [-20, -30],
      ],
      [
        [32057, 62443],
        [-22, -2],
        [-6, 7],
        [12, 18],
        [23, 2],
        [6, -4],
        [-13, -21],
      ],
      [
        [32142, 62638],
        [4, -19],
        [-3, 0],
        [-15, 13],
        [-13, 1],
        [-5, 4],
        [-2, 7],
        [24, 1],
        [10, -7],
      ],
      [
        [30094, 64380],
        [-1, -8],
        [-16, 23],
        [-30, 0],
        [-5, 30],
        [12, 5],
        [38, -11],
        [9, -26],
        [-7, -13],
      ],
      [
        [30033, 64424],
        [-5, -7],
        [-16, 20],
        [-2, 16],
        [-6, 1],
        [-10, 14],
        [3, 19],
        [22, 1],
        [9, -52],
        [5, -12],
      ],
      [
        [29907, 64430],
        [32, -32],
        [19, 5],
        [2, -7],
        [-11, -7],
        [-2, -6],
        [-31, -9],
        [-9, 2],
        [-2, 22],
        [2, 32],
      ],
      [
        [32736, 61486],
        [-1, -34],
        [-19, 10],
        [-1, 30],
        [9, 31],
        [5, 3],
        [7, -40],
      ],
      [
        [49439, 80202],
        [2, -29],
        [-12, -7],
        [-10, 10],
        [-21, 0],
        [-20, -6],
        [5, 52],
        [38, -7],
        [18, -13],
      ],
      [
        [49302, 80353],
        [-10, -38],
        [-26, 13],
        [-2, 10],
        [29, 22],
        [6, 0],
        [3, -7],
      ],
      [
        [48774, 83055],
        [-56, -73],
        [-23, 13],
        [-19, -6],
        [-6, 2],
        [11, 26],
        [13, 61],
        [24, 24],
        [29, 64],
        [23, 17],
        [9, -2],
        [5, -6],
        [11, -71],
        [-16, -26],
        [-5, -23],
      ],
      [
        [49258, 81579],
        [-20, -24],
        [-66, -24],
        [-28, -25],
        [-50, -56],
        [-9, -5],
        [-75, 14],
        [-56, 72],
        [-35, 30],
        [-15, 4],
        [-15, -9],
        [-33, -9],
        [-33, 1],
        [17, 34],
        [23, 19],
        [-51, 13],
        [-15, 10],
        [-16, 23],
        [-40, 4],
        [-19, -6],
        [-33, -31],
        [-51, -33],
        [-62, 46],
        [-12, 20],
        [0, 39],
        [-9, 31],
        [-17, 10],
        [22, 40],
        [26, 27],
        [58, 27],
        [89, 62],
        [49, 27],
        [46, 46],
        [19, 28],
        [14, 39],
        [13, 47],
        [20, 39],
        [-19, 9],
        [-9, 29],
        [3, 29],
        [8, 26],
        [-7, 33],
        [-14, 34],
        [1, 26],
        [3, 29],
        [-35, -2],
        [-36, -9],
        [-32, -20],
        [-31, -27],
        [-27, -5],
        [0, 22],
        [12, 27],
        [31, 38],
        [34, 32],
        [12, 25],
        [9, 28],
        [17, 23],
        [44, 43],
        [83, 48],
        [13, 3],
        [33, -6],
        [32, 7],
        [28, 18],
        [29, 4],
        [63, -51],
        [-19, 78],
        [28, 18],
        [41, -70],
        [15, -7],
        [32, 10],
        [-13, 12],
        [-14, 1],
        [-19, 10],
        [-15, 23],
        [-27, 71],
        [2, 42],
        [17, 44],
        [20, 41],
        [-16, 8],
        [-14, 15],
        [-3, 41],
        [5, 35],
        [35, 32],
        [10, 48],
        [5, 53],
        [-6, 24],
        [-35, -4],
        [-17, -10],
        [-15, -16],
        [-16, 1],
        [-43, 59],
        [-25, 44],
        [-44, 93],
        [-6, 56],
        [35, 120],
        [55, 77],
        [64, 27],
        [-12, 5],
        [-98, 1],
        [-33, -10],
        [-30, -31],
        [-17, -10],
        [-17, -3],
        [-17, -16],
        [-15, -22],
        [-17, -14],
        [-33, 4],
        [-16, -5],
        [-11, 13],
        [-9, 21],
        [-13, 5],
        [-14, -6],
        [-29, -28],
        [-30, -17],
        [-36, 18],
        [-48, 33],
        [-9, -12],
        [-11, -31],
        [-6, -47],
        [-33, 41],
        [-29, 56],
        [-10, 34],
        [0, 39],
        [15, 16],
        [17, -14],
        [25, 93],
        [50, 121],
        [18, 35],
        [12, 46],
        [-2, 31],
        [-11, 25],
        [-46, 58],
        [0, 48],
        [5, 53],
        [13, 32],
        [5, 6],
        [62, -1],
        [-24, 17],
        [-48, 48],
        [1, 17],
        [11, 45],
        [-5, -5],
        [-10, -20],
        [-20, -50],
        [-12, -12],
        [-34, -12],
        [-6, -24],
        [-6, -7],
        [-17, -2],
        [-5, -23],
        [-4, -2],
        [-5, 25],
        [0, 41],
        [7, 38],
        [13, 29],
        [49, 67],
        [-24, -21],
        [-55, -62],
        [-28, -40],
        [-7, -14],
        [-3, -12],
        [0, -13],
        [13, -72],
        [-4, -33],
        [-47, -219],
        [-9, -22],
        [-8, -11],
        [-8, -3],
        [-23, 4],
        [-11, 17],
        [0, 18],
        [5, 28],
        [19, 104],
        [9, 29],
        [13, 27],
        [27, 47],
        [0, 3],
        [-19, -9],
        [-8, 3],
        [-5, 9],
        [3, 139],
        [15, 46],
        [6, 67],
        [13, 57],
        [15, 42],
        [12, 53],
        [17, 24],
        [5, 36],
        [19, 39],
        [15, 41],
        [-8, -4],
        [-96, -106],
        [-25, -20],
        [-33, 5],
        [-26, 12],
        [-20, 26],
        [-9, 48],
        [-24, 1],
        [-21, 9],
        [0, 6],
        [27, 27],
        [44, 9],
        [41, 42],
        [-37, 29],
        [3, 9],
        [32, 24],
        [40, 82],
        [9, 75],
        [-20, 35],
        [-7, 23],
        [-38, 26],
        [-7, 33],
        [5, 18],
        [12, 18],
        [19, 14],
        [30, 14],
        [-27, 14],
        [-10, 17],
        [-8, 24],
        [0, 15],
        [14, 63],
        [8, 26],
        [16, 33],
        [72, -2],
        [8, 15],
        [8, 1],
        [37, -14],
        [-6, 15],
        [-60, 79],
        [-5, 15],
        [17, 42],
        [1, 19],
        [-2, 21],
        [5, 15],
        [19, 8],
        [58, -1],
        [14, 7],
        [-6, 21],
        [-14, 27],
        [-2, 22],
        [3, 20],
        [1, 41],
        [2, 17],
        [14, 27],
        [11, 8],
        [15, 5],
        [32, -9],
        [12, -11],
        [14, -26],
        [10, 3],
        [40, 27],
        [12, 4],
        [16, -32],
        [68, 26],
        [91, 11],
        [55, 17],
        [58, 6],
        [54, 19],
        [57, -9],
        [2, -11],
        [-3, -15],
        [-14, -42],
        [2, -47],
        [-3, -15],
        [-7, -17],
        [-21, -33],
        [-55, -47],
        [-101, -108],
        [-60, -54],
        [-8, -26],
        [-4, -36],
        [35, -7],
        [14, -12],
        [-8, -18],
        [-53, -63],
        [-16, -58],
        [41, 2],
        [33, 11],
        [67, 36],
        [62, 27],
        [30, 1],
        [59, -21],
        [13, -1],
        [25, 10],
        [25, 1],
        [170, -6],
        [47, 12],
        [32, -15],
        [26, -37],
        [25, -68],
        [-1, -11],
        [-15, -31],
        [-28, -39],
        [-24, -54],
        [-7, -29],
        [-4, -32],
        [-8, -29],
        [-47, -138],
        [-47, -76],
        [-20, -55],
        [-26, -43],
        [-24, -27],
        [-26, -18],
        [-76, -20],
        [-21, -13],
        [-25, -24],
        [-27, -12],
        [31, 2],
        [31, 13],
        [56, 5],
        [65, -46],
        [-6, -37],
        [-26, -30],
        [-59, -5],
        [-55, -65],
        [-25, -20],
        [-26, -10],
        [-33, 3],
        [-60, 17],
        [-26, 18],
        [24, -29],
        [26, -16],
        [156, -37],
        [9, 4],
        [49, 39],
        [66, 0],
        [126, -71],
        [36, -55],
        [52, -78],
        [28, -31],
        [21, -28],
        [12, -41],
        [25, -138],
        [27, -134],
        [37, -146],
        [16, -40],
        [22, -28],
        [110, -66],
        [24, -21],
        [43, -63],
        [41, -67],
        [38, -51],
        [41, -41],
        [-20, -22],
        [-14, -34],
        [11, -46],
        [16, -44],
        [33, -71],
        [30, -77],
        [-11, 12],
        [-11, 6],
        [-16, -1],
        [-15, 3],
        [-28, 24],
        [-27, 30],
        [-53, -12],
        [-29, 5],
        [-26, 0],
        [49, -17],
        [53, -2],
        [117, -129],
        [40, -76],
        [23, -101],
        [-16, -46],
        [-25, -29],
        [-23, -34],
        [-22, -38],
        [65, -56],
        [14, 2],
        [15, 8],
        [13, 19],
        [24, 46],
        [12, 16],
        [40, 6],
        [34, -3],
        [34, -10],
        [30, 3],
        [60, -20],
        [30, -18],
        [77, -80],
        [16, -44],
        [8, -57],
        [1, -63],
        [-13, -58],
        [-15, -52],
        [-9, -67],
        [-6, -25],
        [-9, -18],
        [-41, -54],
        [-27, -21],
        [-11, 9],
        [-12, -1],
        [-1, -13],
        [12, -27],
        [1, -33],
        [-24, -24],
        [-25, -10],
        [-40, 13],
        [-57, -45],
        [41, -23],
        [8, -25],
        [-10, -43],
        [-25, -20],
        [-29, -8],
        [-29, -2],
        [-24, -11],
        [-23, -20],
        [29, 11],
        [20, -10],
        [13, -36],
        [11, -11],
        [57, -15],
        [34, 0],
        [68, 9],
        [32, -1],
        [12, -6],
        [0, -30],
        [-5, -75],
        [-9, -15],
        [-89, -62],
        [-19, -44],
        [-5, -26],
        [-52, 4],
        [-24, -27],
        [-43, -19],
        [-32, -20],
        [-32, -25],
        [-27, -7],
        [-113, 30],
        [-69, -3],
        [-93, -26],
        [-24, 5],
        [-35, 24],
        [-37, 17],
        [-42, 7],
        [-37, 23],
        [23, -44],
        [-51, -42],
        [-23, -8],
        [-24, 1],
        [-50, -12],
        [-46, 6],
        [7, -30],
        [12, -26],
        [-9, -11],
        [-11, -3],
        [-87, 20],
        [-13, -4],
        [-10, -18],
        [-32, 9],
        [-31, 31],
        [-33, 21],
        [-34, 10],
        [-28, -4],
        [-112, -48],
        [-23, -49],
        [-11, -69],
        [-16, -61],
        [-27, -47],
        [-31, -7],
        [-30, 33],
        [-56, 36],
        [-20, 25],
        [-6, 1],
        [-6, -9],
        [-22, -11],
        [-23, 0],
        [-35, -10],
        [-62, -29],
        [-25, -20],
        [-53, -55],
        [-11, -15],
        [-19, -55],
        [-30, -10],
        [-27, 35],
        [-31, 13],
        [-32, -13],
        [-20, -18],
        [-9, 15],
        [-1, 31],
        [24, 38],
        [64, 28],
        [55, 74],
        [28, 45],
        [10, 25],
        [14, 16],
        [17, 6],
        [9, 28],
        [77, 112],
        [7, 25],
        [4, 46],
        [6, 44],
        [63, 29],
        [30, 93],
        [8, 7],
        [88, 17],
        [65, -1],
        [65, -18],
        [33, -2],
        [33, 7],
        [26, 25],
        [45, 90],
        [25, 40],
        [29, 36],
        [27, 41],
        [44, 76],
        [-30, -26],
        [-36, -42],
      ],
      [
        [48834, 82558],
        [11, -11],
        [30, 2],
        [-10, -24],
        [-32, -27],
        [-22, -26],
        [-26, -22],
        [-13, 25],
        [-15, -1],
        [-22, 49],
        [-4, 72],
        [29, 19],
        [41, -1],
        [33, -55],
      ],
      [
        [49291, 85962],
        [-31, -1],
        [16, 34],
        [19, 9],
        [36, -4],
        [-6, -15],
        [-34, -23],
      ],
      [
        [49710, 86701],
        [-7, -7],
        [-27, 59],
        [20, 67],
        [24, -2],
        [4, -18],
        [-2, -16],
        [-13, -2],
        [-1, -5],
        [4, -31],
        [0, -36],
        [-2, -9],
      ],
      [
        [49636, 86714],
        [6, -40],
        [14, 10],
        [22, -39],
        [11, 0],
        [18, 16],
        [-4, -36],
        [-18, -101],
        [-6, -17],
        [-3, -31],
        [-4, -6],
        [-6, -61],
        [-12, -21],
        [-11, -48],
        [-4, -5],
        [-16, 19],
        [16, 74],
        [6, 43],
        [-4, 22],
        [-9, 20],
        [-24, 1],
        [-20, -9],
        [-4, 12],
        [-1, 16],
        [-5, 5],
        [-27, -1],
        [-7, 4],
        [-6, 15],
        [-1, 12],
        [25, 9],
        [22, -4],
        [34, 24],
        [-21, 78],
        [-28, 7],
        [-6, 8],
        [5, 13],
        [15, 7],
        [24, 40],
        [14, 6],
        [17, -1],
        [-2, -41],
      ],
      [
        [49784, 86873],
        [0, -7],
        [-14, -49],
        [0, -18],
        [-23, 2],
        [-4, 5],
        [-4, 28],
        [3, 30],
        [3, 8],
        [7, 3],
        [7, -6],
        [12, 15],
        [6, 0],
        [7, -11],
      ],
      [
        [49120, 85710],
        [-16, -7],
        [-15, 0],
        [-25, 33],
        [-9, 25],
        [2, 16],
        [10, 5],
        [24, -8],
        [12, -28],
        [1, -18],
        [3, -7],
        [15, -7],
        [-2, -4],
      ],
      [
        [49186, 85680],
        [-3, -2],
        [-10, 11],
        [-17, 38],
        [27, 7],
        [12, -5],
        [-5, -16],
        [-4, -33],
      ],
      [
        [49150, 85846],
        [-3, -14],
        [21, 0],
        [30, -12],
        [19, -2],
        [15, -15],
        [-8, -28],
        [-10, -8],
        [-10, -1],
        [-36, 28],
        [-48, -12],
        [-10, 4],
        [-6, 7],
        [-2, 10],
        [0, 20],
        [-3, 6],
        [-17, -19],
        [-8, 2],
        [-4, 9],
        [-2, 19],
        [2, 26],
        [10, 38],
        [17, 8],
        [26, -5],
        [29, -21],
        [9, -13],
        [0, -11],
        [-11, -16],
      ],
      [
        [49241, 85936],
        [-24, -14],
        [-10, 12],
        [-2, 37],
        [-29, 16],
        [-14, 10],
        [-10, 18],
        [2, 6],
        [19, 8],
        [32, -34],
        [13, -28],
        [23, -8],
        [3, -4],
        [-3, -19],
      ],
      [
        [48164, 84438],
        [-16, -4],
        [-1, 9],
        [28, 39],
        [17, 6],
        [6, -4],
        [-12, -22],
        [-22, -24],
      ],
      [
        [48581, 83783],
        [-35, 0],
        [-12, 5],
        [-15, 14],
        [-17, 79],
        [6, 28],
        [7, 13],
        [7, 11],
        [19, 5],
        [18, -15],
        [7, -14],
        [15, -54],
        [3, -46],
        [-3, -26],
      ],
      [
        [48395, 84299],
        [-111, -32],
        [-38, 3],
        [-4, 16],
        [8, 10],
        [31, 10],
        [13, 77],
        [-47, 36],
        [-3, 10],
        [4, 17],
        [5, 7],
        [29, 18],
        [12, 4],
        [10, -2],
        [21, -21],
        [23, -43],
        [30, -7],
        [21, -19],
        [-4, -84],
      ],
      [
        [48297, 84061],
        [10, -74],
        [10, -46],
        [0, -16],
        [-9, -22],
        [-45, -29],
        [-15, 0],
        [0, 7],
        [10, 30],
        [-9, 33],
        [4, 26],
        [-4, 4],
        [-9, -3],
        [-33, -41],
        [-11, -4],
        [-1, 8],
        [8, 34],
        [1, 22],
        [5, 14],
        [9, 13],
        [11, 10],
        [8, 1],
        [9, -10],
        [27, 28],
        [24, 15],
      ],
      [
        [48341, 83994],
        [-6, -6],
        [-14, 1],
        [-5, 10],
        [-3, 14],
        [0, 26],
        [8, 19],
        [36, 28],
        [-16, 10],
        [-1, 7],
        [10, 24],
        [39, 36],
        [10, 7],
        [10, -1],
        [-20, -65],
        [-48, -110],
      ],
      [
        [48278, 85462],
        [-36, -100],
        [-13, -3],
        [-13, -25],
        [-37, -28],
        [33, 0],
        [9, -10],
        [0, -19],
        [-6, -12],
        [-43, -46],
        [-29, -18],
        [-31, -48],
        [-16, 0],
        [-16, -31],
        [-13, -13],
        [-7, 0],
        [-9, 6],
        [-19, 30],
        [35, 30],
        [4, 16],
        [24, 18],
        [-2, 5],
        [-39, 24],
        [-15, 17],
        [2, 8],
        [18, 19],
        [-9, 2],
        [-6, 10],
        [-10, 4],
        [-4, 10],
        [-1, 24],
        [2, 26],
        [12, 11],
        [4, 12],
        [5, 3],
        [17, -6],
        [18, -20],
        [20, 8],
        [24, -4],
        [1, 5],
        [-18, 49],
        [3, 10],
        [10, 12],
        [55, 35],
        [68, 60],
        [17, 9],
        [5, -8],
        [7, -31],
        [-1, -41],
      ],
      [
        [48255, 84656],
        [-8, -7],
        [-10, 1],
        [-11, 10],
        [-13, 27],
        [30, 19],
        [13, -11],
        [4, -13],
        [0, -14],
        [-5, -12],
      ],
      [
        [48293, 84968],
        [-1, -26],
        [-5, -30],
        [7, -32],
        [1, -22],
        [12, -8],
        [7, -10],
        [52, -12],
        [49, 4],
        [9, -10],
        [1, -15],
        [-8, -16],
        [-27, -30],
        [-33, -48],
        [-10, -10],
        [-11, -1],
        [-7, 5],
        [-6, 86],
        [-35, -11],
        [-29, 1],
        [-16, 10],
        [-11, 20],
        [-22, 52],
        [-65, 21],
        [-18, 28],
        [-6, 18],
        [3, 9],
        [13, 21],
        [17, -7],
        [11, 4],
        [6, 10],
        [0, 8],
        [-9, 18],
        [0, 6],
        [66, 23],
        [5, 37],
        [15, 3],
        [16, -12],
        [23, -38],
        [6, -46],
      ],
      [
        [47998, 85070],
        [31, -32],
        [-25, -54],
        [-38, 0],
        [-54, 39],
        [0, 8],
        [4, 12],
        [8, 10],
        [9, 2],
        [13, -7],
        [19, 11],
        [15, -4],
        [18, 15],
      ],
      [
        [47986, 84743],
        [-12, -3],
        [-15, 3],
        [-10, 9],
        [-9, 35],
        [-2, 22],
        [4, 40],
        [-1, 47],
        [32, 2],
        [8, -7],
        [5, -142],
        [0, -6],
      ],
      [
        [47939, 84657],
        [-24, -8],
        [-9, 4],
        [-2, 8],
        [6, 20],
        [19, 7],
        [13, -11],
        [2, -10],
        [-5, -10],
      ],
      [
        [48272, 83000],
        [-23, 3],
        [-17, -10],
        [-11, -9],
        [-10, 1],
        [-30, -3],
        [-29, 0],
        [-4, 15],
        [5, 46],
        [-6, 12],
        [-27, 6],
        [-10, 11],
        [-16, 31],
        [-3, 15],
        [-2, 20],
        [-16, 26],
        [-20, 19],
        [-12, 1],
        [-23, -31],
        [-19, -31],
        [7, -15],
        [6, -20],
        [-11, -15],
        [-31, -34],
        [-5, -13],
        [-9, -7],
        [-15, 10],
        [-37, -2],
        [-17, 5],
        [-20, 25],
        [-49, 17],
        [-9, 39],
        [-9, 7],
        [-56, 68],
        [-7, 23],
        [7, 13],
        [21, 21],
        [70, 34],
        [11, 13],
        [2, 12],
        [-21, 14],
        [-18, 15],
        [-6, 10],
        [-1, 9],
        [11, 11],
        [21, 1],
        [16, -5],
        [13, 10],
        [24, 10],
        [15, 13],
        [14, 33],
        [14, 30],
        [1, 16],
        [13, 57],
        [6, 14],
        [44, 37],
      ],
      [
        [47994, 83578],
        [11, -21],
        [22, -5],
        [20, 19],
        [23, 59],
        [16, 3],
        [18, -4],
        [35, 7],
        [62, 27],
        [28, 1],
        [39, -14],
        [29, 0],
        [26, -42],
        [14, -66],
        [32, -66],
        [43, -57],
        [1, -34],
        [-15, -19],
        [-32, -23],
        [1, -25],
        [20, 13],
        [18, 5],
        [44, -5],
        [15, -26],
        [10, -37],
        [6, -31],
        [-4, -34],
        [-11, 11],
        [-12, 30],
        [-13, 14],
        [-16, 7],
        [7, -41],
        [-3, -56],
        [7, -5],
        [21, -1],
        [-14, -56],
        [-28, -16],
        [-33, -6],
        [-8, -20],
        [-6, -26],
        [-17, -38],
        [-22, -22],
        [-28, 4],
        [-28, 18],
      ],
      [
        [49704, 81042],
        [-24, -20],
        [-7, -23],
        [-6, -9],
        [-15, -6],
        [-15, -1],
        [-58, 47],
        [-14, -2],
        [13, 22],
        [37, 17],
        [20, 23],
        [47, -22],
        [22, -26],
      ],
      [
        [65638, 66618],
        [18, -46],
        [2, -318],
        [5, -22],
      ],
      [
        [65663, 66232],
        [-10, -4],
        [-11, -24],
        [-13, -37],
        [-17, -19],
        [-14, -22],
        [-14, -27],
        [-11, -6],
        [-16, 34],
        [-10, 35],
        [2, 8],
        [8, 2],
        [3, 18],
        [-5, 27],
        [-10, 9],
        [-13, 1],
        [-13, -11],
        [-13, -24],
        [-8, -24],
        [-1, -50],
        [4, -57],
        [-1, -27],
        [-7, -34],
        [-2, -50],
        [5, -39],
        [4, -23],
        [1, -19],
        [-13, -62],
        [11, -11],
        [36, -5],
        [11, -41],
        [7, -29],
        [-2, -17],
        [-26, -13],
        [-31, -14],
        [-23, 4],
        [-42, -18],
        [-22, -29],
        [7, -19],
        [7, -13],
        [4, -39],
        [-7, -54],
        [-11, -53],
        [-15, -66],
        [-17, -75],
        [-23, -114],
        [-19, -90],
        [-2, -64],
        [0, -42],
        [-2, -84],
      ],
      [
        [65329, 64921],
        [-19, -46],
        [-4, -2],
        [-22, 6],
        [-7, 2],
        [-21, 5],
        [-33, 8],
        [-42, 11],
        [-51, 13],
        [-56, 14],
        [-59, 15],
        [-62, 16],
        [-62, 16],
        [-60, 15],
        [-56, 14],
        [-50, 13],
        [-43, 10],
        [-32, 9],
        [-21, 5],
        [-8, 2],
        [-23, 6],
        [-13, 31],
        [-15, 38],
        [-15, 37],
        [-15, 38],
        [-16, 38],
        [-15, 38],
        [-15, 37],
        [-16, 38],
        [-15, 38],
        [-15, 38],
        [-15, 37],
        [-16, 38],
        [-15, 38],
        [-15, 37],
        [-16, 38],
        [-15, 38],
        [-15, 38],
        [-10, 25],
        [-6, 28],
        [-1, 75],
        [0, 16],
      ],
      [
        [64324, 65832],
        [10, 30],
        [5, -21],
        [12, -29],
        [19, 7],
        [9, -5],
        [7, -103],
        [14, -37],
        [18, -15],
        [59, -8],
        [36, 14],
        [73, 67],
        [38, 25],
        [105, -5],
        [84, -28],
        [131, -16],
        [26, 4],
        [70, 54],
        [44, 48],
        [26, 14],
        [17, 46],
        [11, 60],
        [10, 39],
        [13, 19],
        [12, 33],
        [9, 55],
        [25, 54],
        [97, 133],
        [57, 113],
        [5, 36],
        [32, 55],
        [24, 59],
        [117, 171],
        [23, 70],
        [14, 79],
        [1, 6],
      ],
      [
        [65577, 66856],
        [10, 3],
        [14, -12],
        [2, -59],
        [-5, -56],
        [-1, -59],
        [-2, -32],
        [11, -26],
        [18, -11],
        [8, 1],
        [6, 13],
      ],
      [
        [65633, 66379],
        [2, 25],
        [-3, 13],
        [-12, 2],
        [-5, -22],
        [-2, -31],
        [9, -2],
        [11, 15],
      ],
      [
        [64814, 65816],
        [-21, -3],
        [-18, 22],
        [39, 29],
        [11, 13],
        [11, 27],
        [9, -23],
        [-10, -36],
        [-7, -16],
        [-14, -13],
      ],
      [
        [64615, 65834],
        [-5, -4],
        [-4, 31],
        [0, 9],
        [13, 15],
        [7, -26],
        [-11, -25],
      ],
      [
        [64979, 65770],
        [0, -20],
        [-28, 6],
        [-7, -10],
        [-24, 5],
        [-22, 14],
        [15, 24],
        [40, 28],
        [17, -26],
        [9, -21],
      ],
      [
        [65129, 65923],
        [-3, -12],
        [-8, 1],
        [-19, 11],
        [-7, 16],
        [13, 19],
        [5, 1],
        [8, -20],
        [11, -16],
      ],
      [
        [60614, 78969],
        [-10, -6],
        [-97, 9],
        [-18, -3],
        [-61, -10],
        [-56, -91],
        [-34, 1],
        [-48, -24],
        [-32, -29],
        [-38, -64],
        [-29, 28],
        [-36, 0],
        [-36, -18],
        [-42, -42],
        [-24, -8],
        [-47, 12],
        [-55, -24],
        [-118, -140],
        [-40, -102],
        [-15, -20],
        [-20, -25],
        [-21, -13],
        [-11, 1],
        [56, 73],
        [17, 27],
        [3, 20],
        [1, 33],
        [-17, 40],
        [-47, -100],
        [-26, -14],
        [-33, -30],
        [-2, -67],
        [4, -50],
        [14, -63],
        [26, -83],
      ],
      [
        [59722, 78187],
        [-15, -3],
        [-41, 36],
        [-2, 58],
        [-29, 49],
        [-46, 0],
        [-20, -6],
        [-27, 55],
        [-36, 23],
        [-27, -7],
        [-28, 10],
        [-61, 58],
        [-41, 7],
        [-1, -42],
        [-17, -29],
      ],
      [
        [59331, 78396],
        [-26, -10],
        [-20, -13],
        [-46, 40],
        [-17, 29],
        [-72, -30],
        [-40, 4],
        [-89, -28],
        [-41, 27],
        [-82, 76],
        [-30, 15],
        [-27, -3],
        [-14, 24],
        [18, 13],
        [20, 1],
        [21, 9],
        [6, 13],
        [-1, 25],
        [-43, 19],
        [-39, 5],
        [-25, 22],
        [-19, 26],
        [44, 0],
        [45, -19],
        [71, -7],
        [64, -20],
        [16, 25],
        [37, 42],
        [7, 14],
        [-62, -29],
        [-63, 18],
        [-23, 26],
        [-20, 39],
        [-8, 43],
        [5, 41],
        [-6, 73],
        [-21, 65],
        [-8, 36],
        [-22, 32],
        [22, -73],
        [8, -48],
        [13, -44],
        [-3, -118],
        [-8, -41],
        [-26, -11],
        [-34, 6],
        [-35, 13],
        [9, 65],
        [-18, -22],
        [-27, -64],
        [-23, -9],
        [-50, 7],
        [-95, -42],
        [-7, -45],
        [-14, -62],
        [-14, -36],
        [-4, -21],
        [-40, -93],
        [-5, -9],
        [-76, -128],
        [-10, -10],
        [-49, -30],
        [-30, -26],
        [-22, -12],
        [-38, 13],
        [-15, -19],
        [-8, -23],
        [0, -47],
        [19, -34],
        [16, -114],
        [-6, -48],
      ],
      [
        [58251, 77914],
        [-15, 31],
        [-23, 33],
        [-46, 28],
        [-50, -10],
        [-54, -47],
        [-37, -18],
        [-20, 12],
        [-12, 0],
        [-4, -14],
        [1, -11],
        [6, -9],
        [-1, -6],
        [-7, -4],
        [-86, 34],
        [-38, 31],
        [-29, 60],
      ],
      [
        [57836, 78024],
        [15, 19],
        [12, 8],
        [45, 5],
        [8, 6],
        [1, 14],
        [3, 18],
        [-1, 26],
        [-5, 28],
        [19, 40],
        [30, 34],
        [17, 33],
        [2, 49],
        [31, 24],
        [27, 41],
        [7, 45],
        [10, 28],
        [-18, 64],
        [-3, 43],
        [-1, 36],
        [8, 19],
        [26, 23],
        [27, 17],
        [11, -2],
        [4, -11],
        [1, -72],
        [5, -2],
        [9, 9],
        [14, 43],
        [9, -12],
        [15, -5],
        [11, 11],
        [7, -1],
        [9, -11],
        [18, -16],
        [17, -5],
        [14, 10],
        [11, 19],
        [13, -7],
        [24, -50],
        [11, 6],
        [55, 10],
        [9, 14],
        [6, 12],
        [-57, 67],
        [3, 49],
        [2, 57],
        [-7, 34],
        [-11, 27],
        [-44, 31],
        [-34, 32],
        [-7, 15],
        [-1, 18],
        [-2, 30],
        [-13, 25],
        [-1, 21],
        [8, 33],
        [3, 35],
        [-3, 14],
        [-8, 12],
        [-15, 1],
        [-21, 20],
        [-13, 28],
        [-37, 39],
        [-12, 7],
        [-7, 19],
        [-3, 24],
        [8, 29],
        [10, 45],
        [7, 42],
        [0, 25],
        [-5, 62],
        [-19, 47],
        [-9, 6],
        [-16, -13],
        [-17, -11],
        [-14, 10],
        [-16, 26],
        [-26, 71],
        [-48, 15],
        [-19, 3],
        [-19, -34],
        [-6, 10],
        [-5, 22],
        [-10, 9],
        [-13, -10],
        [-4, 9],
        [6, 30],
        [-16, 15],
        [-37, -1],
        [-19, 11],
        [-3, 23],
        [-11, 14],
        [-21, 7],
        [-20, 19],
        [-20, 29],
        [-29, 19],
        [-39, 9],
        [-3, 3],
        [-4, 5],
        [-25, -20],
        [-15, -16],
        [-19, 10],
        [-30, -36],
        [-61, -2],
        [-30, 3],
        [-15, 8],
        [-57, -53],
        [-6, -20],
      ],
      [
        [57394, 79642],
        [-13, -7],
        [-36, -10],
        [-38, -15],
        [-8, -52],
        [-12, -29],
        [-20, -41],
        [-71, -14],
        [-61, -21],
        [-62, -12],
        [-82, -51],
        [-27, -44],
        [-26, -13],
        [-24, -3],
        [-15, 25],
        [-52, 66],
        [-20, 32],
        [-26, 9],
        [-29, -5],
        [-28, -16],
        [-29, -3],
        [-32, 23],
        [-4, -3],
        [-12, -3],
        [-82, 27],
        [-7, 5],
        [-4, 1],
        [-11, 2],
        [-61, -3],
        [-57, 54],
        [-18, 2],
        [-13, -22],
        [-10, -25],
        [-40, -24],
        [-10, -10],
      ],
      [
        [56354, 79462],
        [-6, 7],
        [-2, 22],
        [3, 18],
        [-6, 18],
        [-15, 20],
        [-4, 8],
        [-19, -1],
        [-5, -2],
        [-2, 0],
        [-26, 18],
        [-17, 41],
        [-27, 22],
        [-20, 7],
        [-9, 17],
        [-6, 24],
        [-7, 18],
        [-1, 1],
        [-4, 27],
        [-6, 3],
        [-1, 1],
        [-27, -5],
      ],
      [
        [56147, 79726],
        [3, 94],
        [43, 67],
        [10, 35],
        [16, 74],
        [12, 34],
        [14, 29],
        [11, 27],
        [4, 24],
      ],
      [
        [56260, 80110],
        [12, 3],
        [33, -22],
        [30, -11],
        [9, 11],
        [3, 14],
        [-1, 10],
        [-24, 32],
        [-15, 20],
        [-1, 12],
        [5, 28],
        [3, 31],
        [-3, 34],
        [-17, 75],
        [-3, 32],
        [16, 39],
        [51, 92],
        [17, 34],
        [23, 42],
        [64, 100],
        [40, 59],
        [27, 32],
        [40, 56],
        [17, 29],
        [73, 19],
        [8, 27],
        [14, 29],
        [10, 13],
        [1, 50],
        [-13, 61],
        [-11, 21],
        [-8, 15],
        [5, 14],
        [8, 4],
        [10, 2],
        [12, 14],
        [-2, 16],
        [-31, 39],
        [-13, 30],
        [-21, 77],
        [-42, 80],
        [-13, 26],
        [-2, 25],
        [6, 24],
        [-6, 31],
        [-15, 40],
      ],
      [
        [56556, 81519],
        [3, 4],
        [-2, 49],
        [11, 11],
        [17, 7],
        [23, -3],
        [20, -7],
        [25, -23],
        [7, 4],
        [41, 42],
        [43, 64],
        [12, 36],
        [11, 17],
        [37, 9],
        [32, 4],
        [20, -1],
        [51, 6],
        [30, 7],
        [25, 11],
        [56, 5],
        [87, -8],
        [57, 0],
        [39, -6],
        [95, -34],
        [35, -6],
        [17, -18],
        [31, -7],
        [57, -18],
        [50, -9],
        [34, 4],
        [19, -5],
        [35, -80],
        [8, -9],
        [14, -2],
        [29, 7],
        [41, -3],
        [25, -17],
        [-4, -47],
        [7, -7],
        [11, 2],
        [14, 27],
        [11, 28],
        [8, 9],
        [42, -19],
        [19, 3],
        [18, 21],
        [11, 4],
        [30, -15],
        [37, -11],
        [30, -1],
        [19, -11],
        [13, -50],
        [12, -10],
        [11, -3],
        [18, 44],
        [15, 18],
        [22, 12],
        [14, 6],
        [10, 15],
        [13, 16],
        [11, 1],
        [10, -6],
        [10, -21],
        [16, -48],
        [19, -49],
        [13, -17],
        [34, 15],
        [24, 15],
        [42, 3],
        [56, 10],
        [43, 14],
        [27, -2],
        [17, -16],
        [25, -29],
        [6, -43],
        [33, -30],
        [26, -5],
        [9, 31],
        [15, 21],
        [-5, 30],
        [-3, 37],
        [-11, 35],
        [-8, 37],
        [14, 53],
        [15, 47],
        [8, 25],
        [25, 47],
        [25, 33],
        [37, 54],
        [28, 18],
        [24, -8],
        [14, -8],
        [36, 32],
        [63, 2],
        [53, -4],
      ],
      [
        [58823, 81855],
        [5, -1],
        [26, -17],
        [27, -14],
        [19, -1],
        [22, 4],
        [27, 18],
        [18, 18],
        [22, 91],
        [8, 13],
        [12, 7],
        [20, 1],
        [39, -17],
        [44, -15],
        [26, 2],
        [69, 49],
        [39, 7],
        [46, -11],
        [44, -1],
        [34, 7],
        [24, -17],
        [28, -37],
        [26, -55],
        [27, -101],
        [79, -115],
        [2, -22],
        [-7, -15],
        [-39, -14],
        [-33, -7],
        [-1, -20],
        [9, -21],
        [15, -32],
        [2, -40],
        [-1, -37],
        [7, -32],
        [12, -14],
        [2, -16],
        [-15, -20],
        [-4, -12],
        [6, -7],
        [71, -4],
        [35, -20],
        [27, -17],
        [13, -2],
        [30, 11],
        [34, 8],
        [20, 1],
        [8, -13],
        [7, -35],
        [12, -34],
        [11, -10],
        [19, 1],
        [12, -1],
        [7, -13],
        [-8, -20],
        [2, -21],
        [9, -27],
        [10, -60],
        [10, -18],
        [6, -23],
        [0, -27],
        [-8, -23],
        [-6, -18],
        [6, -41],
        [21, -46],
        [16, -11],
        [13, -41],
        [23, -13],
        [34, 34],
        [26, 18],
        [33, -10],
        [30, -6],
        [20, -24],
        [15, -32],
        [17, -18],
        [18, 9],
        [36, -9],
        [17, -26],
        [16, -15],
        [22, 21],
        [17, 27],
        [64, 27],
        [40, 7],
        [11, 5],
        [23, 20],
        [24, 13],
        [23, -4],
        [21, -40],
        [23, -28],
        [6, -45],
        [28, -61],
        [68, -83],
        [27, -26],
        [18, 5],
        [10, 7],
        [4, 8],
        [4, 41],
        [9, 15],
        [14, 1],
        [53, -51],
        [28, -6],
        [27, -1],
        [36, -39],
        [39, -35],
        [30, -3],
        [25, 13],
        [16, 8],
        [11, -13],
        [9, -29],
        [16, -23],
        [18, -7],
        [26, -1],
        [46, -45],
        [43, -45],
        [26, -3],
        [23, 14],
        [20, 3],
        [14, -11],
        [4, -20],
        [-10, -26],
        [0, -38],
        [19, -36],
        [0, -36],
        [-5, -32],
        [-11, -29],
        [-26, -41],
        [-24, -38],
        [-36, -16],
        [-21, -16],
        [6, -28],
        [13, -26],
        [31, -21],
        [34, -15],
        [5, -17],
        [-6, -9],
        [-22, -7],
        [-31, 8],
        [-10, -15],
        [-15, -25],
        [-9, -44],
        [-7, -41],
        [33, -11],
        [20, -17],
        [6, -34],
        [7, -37],
        [2, -34],
        [-11, -16],
        [-1, -17],
        [5, -9],
        [15, -3],
        [11, -8],
        [1, -18],
        [-21, -40],
        [-20, -76],
        [-11, -41],
        [1, -44],
        [-12, -25],
        [-21, -2],
        [-75, -4],
        [-64, 2],
        [-28, 6],
        [-44, 4],
        [-22, -11],
        [-28, -70],
        [-22, -28],
        [-36, -25],
        [-40, -7],
        [-22, -30],
        [-8, -45],
        [-1, -41],
        [-3, -20],
        [-9, -18],
        [-3, -13],
        [2, -14],
        [9, -5],
        [11, -6],
        [0, -10],
        [-4, -13],
        [-13, -14],
        [-5, -22],
        [1, -23],
        [2, -25],
      ],
      [
        [58892, 78458],
        [38, -29],
        [-39, 8],
        [-86, 27],
        [-38, 25],
        [-10, 28],
        [-5, 38],
        [21, -40],
        [15, -18],
        [104, -39],
      ],
      [
        [59417, 51265],
        [-30, 0],
        [-50, 0],
        [-49, 0],
        [-49, 0],
        [-50, 0],
        [-49, 0],
        [-50, 0],
        [-49, 0],
        [-49, 0],
        [-50, 0],
        [-49, 0],
        [-50, 0],
        [-49, 0],
        [-49, 0],
        [-50, 0],
        [-49, 0],
        [-49, 0],
        [-30, 0],
        [-5, 2],
        [-4, 3],
        [-19, -8],
        [-19, -25],
        [-21, -11],
        [-22, 5],
        [-3, -3],
      ],
      [
        [58474, 51228],
        [-11, 1],
        [-16, 1],
        [-14, -6],
        [-11, -23],
        [-12, -37],
        [-20, -44],
        [-16, -38],
        [-13, -28],
        [-31, -45],
        [-17, -13],
        [-8, 2],
        [-5, 8],
        [-10, 58],
        [-6, 9],
        [-60, -29],
        [-9, -1],
      ],
      [
        [58215, 51043],
        [1, 18],
        [-4, 136],
        [-1, 83],
        [8, 52],
        [4, 60],
        [1, 52],
        [11, 90],
        [-4, 54],
        [14, 189],
        [4, 31],
        [5, 91],
        [9, 28],
        [8, 11],
        [10, 56],
        [20, 90],
        [14, 46],
        [-3, 101],
        [2, 68],
        [3, 16],
        [29, 25],
        [38, 63],
        [15, 75],
        [23, 47],
        [43, 31],
        [1, 0],
        [129, 256],
        [60, 138],
        [26, 70],
        [1, 26],
        [5, 33],
        [-11, 26],
        [-12, 24],
        [-4, 21],
        [-11, 11],
        [-16, 0],
        [-10, 15],
        [-11, 31],
        [-12, 20],
        [-37, -2],
        [-28, 32],
        [0, 43],
        [12, 85],
        [21, 98],
        [1, 26],
        [-3, 23],
        [-5, 20],
        [-10, 20],
        [-9, 23],
        [7, 70],
        [14, 69],
        [11, 34],
        [11, 38],
        [-4, 32],
        [-15, 15],
      ],
      [
        [58566, 53853],
        [8, 31],
        [17, 52],
        [33, 52],
        [29, 35],
        [19, 0],
        [38, -27],
        [34, -33],
        [19, -2],
        [22, 14],
        [47, 58],
        [11, -18],
        [14, -36],
        [15, -58],
        [30, -27],
        [14, -18],
        [10, -6],
        [6, 5],
        [11, 46],
        [13, 25],
        [26, 32],
        [55, 25],
        [39, 7],
        [17, 6],
        [28, 15],
        [44, 47],
        [44, -61],
        [47, -12],
        [46, 1],
        [14, 18],
        [8, 14],
        [48, 100],
        [65, 136],
      ],
      [
        [59437, 54274],
        [44, -191],
        [14, -11],
        [-2, -17],
        [-3, -16],
        [28, -46],
        [35, -24],
        [12, -23],
        [2, -26],
        [-12, -112],
        [2, -31],
        [11, -112],
        [21, -25],
        [19, -113],
        [37, -47],
        [5, -14],
        [9, -55],
        [11, -59],
        [9, -14],
        [5, -4],
        [11, -63],
        [-6, -36],
        [9, -108],
        [14, -96],
        [3, -116],
        [1, -51],
        [-1, -31],
        [-3, -44],
        [-7, -25],
        [-12, -25],
        [-13, -39],
        [-11, -41],
        [-7, -21],
        [5, -62],
        [-1, -16],
        [-3, -8],
        [-17, -10],
        [-22, -17],
        [-13, -16],
        [-18, -32],
        [-15, -34],
        [-20, -101],
        [-33, -78],
        [-5, -26],
        [-31, -47],
        [-14, -57],
        [-9, -71],
        [-12, -51],
        [-26, -69],
        [-6, -110],
        [1, -220],
        [-7, -249],
        [1, -99],
      ],
      [
        [64752, 74195],
        [-3, -27],
        [-15, 81],
        [-7, 89],
        [9, 26],
        [11, -2],
        [-10, -32],
        [15, -135],
      ],
      [
        [68478, 73357],
        [-14, -2],
        [-34, 13],
        [-67, 27],
        [-58, 54],
        [-38, 35],
        [-6, -5],
        [-16, -24],
        [-12, -30],
        [-9, -57],
        [-15, -67],
        [-70, -3],
        [-59, -5],
        [-39, -26],
        [-37, -35],
        [-10, -42],
        [-8, -54],
        [-22, -124],
        [-20, -113],
        [-10, -73],
        [-15, -50],
        [-42, -66],
        [-49, -44],
        [-25, -21],
        [-12, -26],
        [-2, -25],
        [-9, -7],
        [-20, 4],
        [-21, -4],
        [-46, -26],
        [-50, -31],
        [-60, -32],
        [-34, 0],
        [-14, -7],
        [-6, -16],
        [6, -29],
        [6, -23],
        [5, -28],
        [-14, -24],
        [-9, -40],
        [-8, -70],
        [-21, -21],
        [-34, -35],
        [-38, -45],
        [-9, -9],
        [-22, -13],
        [-21, 4],
        [-20, 7],
        [-21, -12],
        [-22, -35],
        [-10, 11],
        [-5, 35],
        [-11, 23],
        [-35, 52],
        [-29, 36],
        [-13, 3],
        [-27, -10],
        [-33, -7],
        [-28, 8],
        [-21, 15],
        [-34, 50],
        [-12, 28],
        [-9, 20],
        [-23, -5],
      ],
      [
        [67017, 72361],
        [-7, 23],
        [-1, 26],
        [7, 33],
        [-2, 61],
        [-13, 43],
        [-15, 20],
        [2, 13],
        [7, 30],
        [8, 27],
        [0, 53],
        [-11, 57],
        [-4, 82],
        [3, 81],
        [-14, 40],
        [-114, 0],
        [-102, -3],
        [-6, 9],
        [-39, 102],
        [-33, 77],
        [-31, 45],
        [-73, 56],
        [-35, 23],
        [-29, 43],
        [-25, 46],
        [-6, 64],
        [-5, 21],
        [-7, 17],
        [-8, 8],
        [-9, -2],
        [-84, 74],
        [-34, 20],
        [-32, -16],
        [-14, -3],
        [-28, 21],
        [-32, -28],
        [-13, -2],
        [-19, 7],
        [-16, 10],
        [-42, 68],
        [-36, 27],
        [-26, 18],
        [-49, 25],
        [-53, 14],
        [-27, 11],
        [-19, 15],
        [-5, 9],
        [0, 25],
        [-1, 32],
        [-7, 24],
        [-13, 28],
        [-19, 22],
        [-31, -4],
        [-48, 2],
        [-37, 21],
        [-29, 4],
        [-35, -4],
        [-29, 0],
        [-20, -16],
        [-12, -18],
        [-8, -55],
        [-7, -9],
        [-12, -4],
        [-16, 3],
        [-33, 0],
        [-58, 10],
        [-73, 2],
        [-55, -28],
        [-43, -40],
        [-42, -45],
        [-49, -72],
        [-14, -32],
        [-29, -127],
        [-12, -18],
        [-17, -15],
        [-17, -2],
        [-33, -19],
        [-44, -31],
        [-30, -13],
        [-77, 7],
      ],
      [
        [64976, 73354],
        [-5, 40],
        [-14, 148],
        [-7, 148],
        [1, 69],
        [8, 138],
        [-1, 69],
        [-3, 63],
        [3, 61],
        [6, 69],
        [4, 71],
        [-5, 49],
        [-15, 39],
        [-25, 49],
        [-4, 29],
        [-2, 33],
        [-24, 3],
        [-22, 34],
        [-17, 18],
        [-39, 20],
        [-19, 1],
        [-18, -14],
        [-13, -30],
        [-9, 47],
        [0, 49],
        [31, 102],
        [19, -30],
        [24, -12],
        [30, -2],
        [29, 8],
        [-6, 35],
        [-13, 20],
        [-17, 15],
        [-6, 46],
        [2, 48],
        [8, 45],
        [-9, 18],
        [-14, 11],
        [-32, -1],
        [-42, 12],
        [-42, 5],
        [-10, -53],
        [23, -70],
        [-19, 34],
        [-19, 46],
        [-26, 81],
        [-16, 96],
        [-3, 103],
        [14, 85],
        [18, 80],
        [11, 102],
        [15, 101],
        [15, -45],
        [17, -40],
        [24, -38],
        [13, -9],
        [39, -15],
        [25, 6],
        [27, 22],
        [26, -7],
        [22, -42],
        [20, -46],
        [29, -10],
        [61, 33],
        [29, 8],
        [25, -15],
        [13, -3],
        [13, 2],
        [-11, 42],
        [-5, 40],
        [15, 21],
        [48, -23],
        [31, 16],
        [8, 8],
        [7, 10],
        [3, 35],
        [-1, 36],
        [-4, 33],
        [-9, 29],
        [-22, 42],
        [-86, 98],
        [-28, 39],
        [-24, 50],
        [-16, 72],
        [-12, 74],
        [-11, 55],
        [-30, 129],
        [-12, 15],
        [-14, 7],
        [-36, 4],
        [-36, -10],
        [-58, -22],
        [-34, 7],
        [-15, -13],
        [-39, -55],
        [-18, -46],
        [-25, -106],
        [19, -34],
        [0, -22],
        [-15, -157],
        [9, -76],
        [-3, -6],
        [-7, 18],
        [-22, 78],
        [-38, 94],
        [-32, 145],
      ],
      [
        [64581, 75910],
        [56, 94],
        [49, 67],
        [39, 40],
        [12, 10],
        [54, 34],
        [70, 30],
        [51, 22],
        [67, 19],
        [22, 4],
        [32, -1],
        [26, -18],
        [16, -14],
        [56, -57],
        [57, -59],
        [49, -65],
        [13, -26],
        [8, -32],
        [6, -31],
        [41, -99],
        [17, -45],
        [24, -59],
        [20, -29],
        [19, -35],
        [13, -29],
        [14, -14],
        [17, -6],
        [37, 10],
        [44, 18],
        [27, 8],
        [12, -1],
      ],
      [
        [57213, 74963],
        [-64, -18],
        [-19, 17],
        [20, 35],
        [37, 22],
        [12, 2],
        [16, -34],
        [-2, -24],
      ],
      [
        [61530, 75758],
        [18, -11],
        [35, -15],
        [22, -18],
        [12, -5],
        [28, 37],
        [43, -1],
        [37, -5],
        [19, -6],
        [23, -12],
        [29, -9],
        [11, 18],
        [17, 51],
        [6, 7],
        [5, 5],
        [21, 4],
        [20, -4],
        [9, -9],
        [9, -41],
        [24, -15],
        [42, -66],
        [25, -26],
        [6, -11],
        [-8, -13],
        [3, -17],
        [15, -21],
        [20, -8],
        [23, 3],
        [12, -8],
        [8, -12],
        [2, -17],
        [0, -11],
      ],
      [
        [62066, 75522],
        [4, -25],
        [18, -34],
        [20, -21],
        [11, -23],
        [18, -77],
        [8, -43],
        [-3, -42],
        [-13, -42],
        [-27, -53],
        [7, -22],
        [6, -29],
        [-2, -22],
        [19, -67],
        [9, -42],
        [-7, -10],
        [-5, -13],
        [35, -32],
        [41, -28],
        [18, -5],
        [48, 13],
        [31, 2],
        [31, -25],
        [44, -63],
        [48, -81],
        [10, -25],
      ],
      [
        [62435, 74713],
        [4, -11],
        [10, -19],
      ],
      [
        [62449, 74683],
        [-10, 0],
        [-16, 18],
        [-38, 50],
        [-20, -22],
        [-17, -37],
        [-18, -141],
        [-15, -15],
        [-27, 1],
        [-32, 4],
        [-22, -7],
        [-6, -9],
        [3, -15],
        [7, -23],
        [4, -29],
        [2, -24],
        [11, -22],
        [16, -21],
        [1, -21],
        [-3, -30],
        [-3, -22],
        [-4, -13],
        [7, -35],
        [17, -41],
        [11, -15],
        [-4, -78],
        [6, -35],
        [5, -48],
        [-2, -79],
        [3, -19],
        [5, -7],
        [16, -3],
        [15, -7],
        [6, -13],
        [-1, -10],
        [-19, -36],
        [-2, -26],
        [-6, -37],
        [-6, -21],
        [-17, -41],
        [-11, -41],
        [-5, -34],
        [4, -16],
        [31, -5],
        [17, -24],
        [45, -49],
        [8, -20],
        [-12, -30],
        [0, -12],
        [6, -16],
        [3, -28],
        [-1, -31],
        [0, -41],
        [8, -7],
        [31, -38],
        [22, -39],
        [1, -12],
        [-11, -30],
        [3, -35],
        [-1, -8],
      ],
      [
        [62434, 73238],
        [-9, 13],
        [-17, 5],
        [-18, 1],
        [-11, -10],
        [-20, -27],
        [-26, -30],
        [-21, -28],
        [-12, -19],
        [-10, 3],
        [-8, 17],
        [-4, 23],
        [1, 87],
        [-4, 27],
        [-10, 19],
        [-12, 11],
        [-13, 6],
        [-15, 1],
        [-20, -26],
        [-29, -26],
        [-44, 2],
        [-30, 5],
        [-15, 5],
        [-58, 40],
        [-12, 1],
        [-22, 17],
        [-25, 13],
        [-44, -25],
        [-19, 6],
        [-26, 21],
        [-9, -6],
        [-29, -64],
        [-50, -70],
        [-27, -11],
      ],
      [
        [61766, 73219],
        [-13, 69],
        [-12, 27],
        [-6, 4],
        [-13, 8],
        [-9, -5],
        [-30, -47],
        [-48, -29],
        [-40, -17],
        [-64, -22],
        [-48, -10],
        [-21, -1],
        [-45, 9],
        [-40, 14],
        [-40, -1],
        [-30, -6],
        [-71, -51],
        [-121, -105],
        [-92, -51],
        [-51, -21],
        [-40, -11],
        [-69, -1],
        [-56, 8],
        [-39, -1],
        [-22, 13],
        [-30, 42],
        [-38, 43],
        [-16, 9],
        [-22, 9],
        [-32, 4],
        [-79, -61],
        [-25, -17],
        [-27, -13],
        [-54, -37],
        [-25, -21],
        [-30, 2],
        [-39, 6],
        [-33, -2],
        [-23, 29],
        [-12, 32],
        [-46, 20],
        [-33, 5],
        [-8, -14],
        [-9, -44],
        [-14, -112],
        [-2, -29],
        [29, -111],
        [-2, -17],
        [-20, -6],
        [-24, -2],
        [-16, -10],
        [-12, -18],
        [-8, -97],
        [-27, -18],
        [-14, -20],
        [-13, -60],
        [-7, -1],
        [-45, 45],
        [-20, 4],
      ],
      [
        [59970, 72532],
        [17, 47],
        [-19, 93],
        [-21, 86],
        [20, 56],
        [41, 67],
        [44, 79],
        [0, 48],
        [-3, 37],
        [-12, 25],
        [-24, 34],
        [-40, -36],
        [-29, -40],
        [-18, -8],
        [-21, -23],
        [-10, -41],
        [-24, -32],
        [-40, -13],
        [-60, 35],
        [-65, 52],
        [-37, 42],
        [-30, 10],
        [-28, -18],
        [-84, -104],
        [-77, -152],
        [-19, -26],
        [-72, -65],
        [-48, -22],
        [-22, 5],
        [-95, -29],
        [-48, -4],
        [-37, -34],
        [-72, 37],
        [-44, 48],
        [-26, 48],
        [-42, 105],
        [-31, 49],
        [-67, 45],
        [-119, 108],
        [-31, 12],
        [-80, 16],
        [-85, 10],
        [-18, -40],
        [-6, -156],
        [-15, -43],
        [-6, -81],
        [-10, -24],
        [-17, -15],
        [-25, 26],
        [-18, 11],
        [-41, -33],
        [-82, -47],
        [-28, -7],
        [-94, 59],
        [-35, 38],
        [-22, 42],
        [-8, 71],
        [-14, 40],
        [-2, 28],
        [-5, 31],
        [-19, 13],
        [-21, -24],
        [-22, 1],
        [-27, 15],
        [-65, 59],
        [-50, 5],
        [-30, -73],
        [-24, -23],
        [-25, -7],
        [-2, 21],
        [20, 47],
        [-78, -9],
        [-41, -35],
        [-32, 5],
        [-24, 16],
        [3, 20],
        [25, 7],
        [21, 16],
        [84, 13],
        [20, 13],
        [21, 51],
        [40, 44],
        [5, 19],
        [-31, 0],
        [-129, -13],
        [-89, 7],
        [-10, -21],
        [-14, -4],
        [-3, 60],
        [14, 27],
        [19, -3],
        [46, 24],
        [-4, 49],
        [-33, 34],
        [-7, 19],
        [-24, 5],
        [-20, 23],
        [-4, 59],
        [-15, 65],
        [-23, 31],
        [3, 17],
        [41, 22],
        [8, 90],
        [-6, 56],
        [-20, 4],
        [-60, 44],
        [-18, -4],
        [-20, 48],
        [-35, 34],
        [-17, -12],
        [-11, -16],
        [-16, 7],
        [-26, 30],
        [-27, 17],
        [-12, 20],
        [15, 53],
        [20, -1],
        [4, 42],
        [-16, 70],
        [2, 36],
        [17, 9],
        [20, -6],
        [21, -42],
        [6, -41],
        [-4, -39],
        [13, -38],
        [9, -10],
        [6, 41],
        [9, 7],
        [12, -17],
        [25, -9],
        [66, 24],
        [12, 21],
        [-48, -2],
        [-17, 19],
        [-20, 44],
        [-11, 40],
        [-3, 19],
        [-6, 29],
        [7, 15],
        [33, 23],
        [29, 64],
        [-12, 18],
        [-14, 9],
        [-15, -6],
        [-14, 22],
        [-2, 30],
        [12, 25],
        [1, 34],
        [-38, 83],
        [-10, 18],
        [8, 28],
        [29, 45],
        [27, 57],
        [-4, 19],
        [-20, 7],
        [-95, -24],
        [-37, -21],
        [-66, -10],
        [-5, 31],
        [2, 28],
        [15, 50],
        [-2, 125],
        [9, 67],
        [37, 20],
        [45, 100],
        [73, 117],
        [76, -3],
        [30, 33],
        [45, 2],
        [9, -24],
        [5, -22],
        [40, -33],
        [70, 5],
        [17, 13],
        [16, 18],
        [-32, 57],
        [10, 17],
        [29, 2],
        [32, -14],
        [2, -13],
        [-9, -18],
        [-10, -32],
        [10, -6],
        [90, 19],
        [95, -15],
        [30, 8],
        [75, 0],
        [13, 19],
        [-22, 25],
        [-22, 9],
        [-15, 12],
        [-15, 18],
        [47, 55],
        [27, 11],
        [126, 34],
        [94, 17],
        [1, 13],
        [-14, 0],
        [-121, 28],
        [-29, 22],
        [-40, 52],
        [-9, 15],
        [-10, 25],
        [6, 55],
        [7, 43],
        [15, 25],
        [48, 4],
        [166, -44],
        [119, 27],
        [129, -65],
        [123, 13],
        [26, 29],
        [31, 93],
        [174, 156],
        [61, 80],
        [66, 45],
        [112, 49],
        [94, 65],
        [27, 7],
        [225, -31],
        [155, -4],
        [71, 62],
        [42, -21],
        [-4, -22],
        [-8, -19],
        [3, -38],
        [24, -55],
        [24, -38],
        [73, -55],
        [100, 46],
        [16, -5],
        [21, -13],
        [35, -147],
        [28, -52],
        [35, -36],
        [29, -7],
        [21, 37],
        [17, 15],
        [36, 6],
        [60, -50],
        [21, -53],
        [101, -40],
        [93, -20],
        [40, -45],
        [131, -44],
        [49, 7],
        [82, 46],
        [159, 51],
        [106, -71],
        [29, -9],
        [25, 6],
        [35, -20],
        [38, 11],
        [117, 84],
        [37, 48],
        [39, 12],
        [34, 29],
        [92, 93],
        [27, 54],
      ],
      [
        [57781, 76018],
        [-7, -65],
        [17, -73],
        [41, -100],
        [41, -51],
        [167, -126],
        [31, -11],
        [-7, -51],
        [-10, -46],
        [-11, -30],
        [-49, -20],
        [-135, 56],
        [-34, 6],
        [-24, -12],
        [-45, -40],
        [-49, 13],
        [-69, -23],
        [-19, -77],
        [-48, -88],
        [-79, -71],
        [-56, -38],
        [-84, -136],
        [-39, -80],
        [-16, -15],
        [-19, -13],
        [6, 39],
        [10, 35],
        [-2, 26],
        [0, 38],
        [28, 44],
        [26, 31],
        [76, 58],
        [20, 47],
        [-60, -1],
        [-60, -11],
        [-38, 7],
        [-33, -4],
        [-11, 42],
        [-8, 25],
      ],
      [
        [57232, 75303],
        [9, 7],
        [11, 6],
        [19, 44],
        [18, 33],
        [25, 41],
        [6, 24],
        [0, 23],
        [-6, 16],
        [-1, 19],
        [-1, 27],
        [2, 55],
        [57, 60],
        [18, 6],
        [6, 28],
        [-4, 63],
        [-8, 51],
        [-10, 4],
        [-14, 15],
        [-9, 17],
        [-14, 19],
        [-25, 12],
      ],
      [
        [57311, 75873],
        [-1, 16],
        [3, 16],
        [9, 17],
        [42, 14],
        [5, 12],
        [5, 29],
        [9, 29],
        [10, 10],
        [17, -1],
        [34, 7],
        [23, 9],
        [24, 21],
        [12, 18],
        [50, 11],
        [14, 9],
        [14, -8],
        [19, -31],
        [31, -45],
        [17, -15],
        [13, 6],
        [22, 17],
        [22, 0],
        [17, -3],
        [9, 14],
        [13, 3],
        [37, -10],
      ],
      [
        [53195, 70957],
        [0, -16],
        [-10, -109],
        [-2, -39],
        [-2, -67],
        [0, -80],
        [22, -68],
        [1, -30],
        [-9, -34],
        [-41, -39],
        [-52, -51],
        [-46, -49],
        [-49, -53],
        [-15, -34],
        [-25, -26],
        [-21, -27],
        [-3, -25],
        [-15, -48],
        [-19, -38],
        [-47, -18],
        [-8, -12],
        [-22, -57],
        [-10, -23],
        [-13, -47],
        [16, -123],
        [20, -126],
        [4, -52],
        [-1, -44],
        [-11, -47],
        [-25, -68],
        [-18, -49],
        [-35, -89],
        [-11, -22],
        [-24, -26],
        [-47, -35],
        [-33, -30],
      ],
      [
        [52644, 69256],
        [-17, 136],
        [-15, 116],
        [-12, 95],
        [-21, 169],
        [-17, 143],
        [-18, 143],
        [-16, 129],
        [-16, 130],
        [-7, 19],
        [-49, 62],
        [-45, 57],
        [-46, 64],
        [-51, 70],
        [-8, 88],
        [-26, 133],
        [-27, 74],
        [-10, 19],
        [-55, 48],
        [-32, 35],
        [-9, 20],
        [-6, 55],
        [-23, 107],
        [-26, 97],
        [-9, 66],
        [-1, 83],
        [5, 60],
        [11, 26],
        [54, 74],
        [25, 90],
        [31, 34],
        [26, 25],
        [22, 30],
        [19, 47],
        [15, 51],
        [2, 54],
        [7, 87],
        [9, 61],
        [23, 68],
        [-9, 55],
        [-12, 60],
        [3, 103],
        [-3, 42],
        [-10, 37],
        [-10, 48],
        [0, 39],
        [10, 104],
        [7, 80],
        [12, 103],
        [-5, 29],
        [-8, 21],
        [-26, 23],
        [-1, 14],
        [7, 15],
        [38, 50],
        [21, 74],
        [17, 16],
        [27, 26],
        [-1, 29],
        [-6, 31],
      ],
      [
        [52382, 73120],
        [68, 35],
        [66, 91],
        [23, 22],
        [152, 84],
        [19, -6],
        [22, -12],
        [-6, -31],
        [-9, -25],
        [13, -44],
        [18, 27],
        [-4, 18],
        [-1, 23],
        [31, 2],
        [28, -3],
        [30, -26],
        [-2, -100],
        [40, -97],
        [-11, -48],
        [33, -29],
        [29, 35],
        [15, 51],
        [54, 29],
        [52, 74],
        [28, 8],
        [6, -61],
        [14, -54],
        [-19, -18],
        [-25, -57],
        [-47, -144],
        [-43, -42],
        [-33, -56],
        [-10, -39],
        [-3, -46],
        [8, -82],
        [23, -84],
        [28, -51],
        [26, -15],
        [61, -80],
        [-1, -47],
        [9, -57],
        [3, -68],
        [21, -55],
        [-45, -119],
        [-25, -86],
        [-49, -118],
        [-43, -77],
        [-93, -115],
        [-23, -38],
        [-15, -39],
        [-7, -41],
        [3, -49],
        [30, -119],
        [41, -70],
        [41, -38],
        [72, 16],
        [-2, -46],
        [5, -55],
        [29, 3],
        [20, 8],
        [16, 54],
        [36, -37],
        [18, -111],
        [30, -35],
        [3, -13],
        [-10, -9],
        [-9, -13],
        [9, -9],
        [29, -14],
        [17, 9],
        [29, -24],
      ],
      [
        [53132, 71862],
        [-43, -41],
        [9, 36],
        [28, 43],
        [7, -10],
        [-1, -28],
      ],
      [
        [53043, 71268],
        [-7, -3],
        [-13, -16],
        [-8, -1],
        [-20, 17],
        [-7, 0],
        [-10, 12],
        [3, 68],
        [3, 19],
        [49, 2],
        [27, -40],
        [4, -10],
        [1, -12],
        [-12, -22],
        [-10, -14],
      ],
      [
        [33123, 58282],
        [-15, -6],
        [2, 23],
        [26, 40],
        [41, 26],
        [10, 1],
        [-6, -35],
        [-58, -49],
      ],
      [
        [33052, 57680],
        [-45, -32],
        [-118, -8],
        [-48, 12],
        [-37, -9],
        [67, 70],
        [8, 30],
        [29, 6],
        [9, 9],
        [9, 155],
        [-4, 38],
        [-5, 20],
        [-12, 15],
        [-26, 20],
        [-5, 11],
        [17, 17],
        [35, 9],
        [26, 19],
        [55, 4],
        [26, 16],
        [45, 5],
        [-22, -71],
        [-10, -27],
        [4, -65],
        [-5, -43],
        [6, -55],
        [13, -37],
        [-9, -35],
        [-1, -54],
        [-2, -20],
      ],
      [
        [1344, 39648],
        [4, 0],
        [4, 17],
        [15, 7],
        [-1, -19],
        [-21, -59],
        [-12, 23],
        [-37, 38],
        [-8, 29],
        [13, 23],
        [-2, -18],
        [6, -8],
        [21, -4],
        [19, -15],
        [-12, -6],
        [11, -8],
      ],
      [
        [1680, 41106],
        [-11, -34],
        [-5, 0],
        [-12, 20],
        [-5, 13],
        [19, 40],
        [10, 3],
        [12, -13],
        [0, -12],
        [-8, -17],
      ],
      [
        [1413, 39573],
        [-2, -87],
        [-13, 40],
        [-2, 18],
        [14, 27],
        [3, 2],
      ],
      [
        [50250, 58175],
        [-8, -62],
        [-14, -76],
        [-10, -25],
        [-6, -186],
        [4, -16],
        [4, -4],
        [46, -64],
        [60, -82],
        [43, -59],
        [4, -19],
        [0, -123],
        [1, -105],
        [9, -60],
        [1, -59],
        [11, -44],
        [40, -85],
        [9, -50],
        [1, -161],
        [1, -122],
        [5, -166],
        [0, -139],
        [0, -175],
        [0, -205],
        [0, -215],
        [-26, -2],
        [14, -67],
        [2, -60],
        [4, -20],
        [-7, -29],
        [6, -45],
        [11, -16],
        [29, -90],
        [9, -75],
        [-46, -26],
        [3, -19],
      ],
      [
        [50450, 55424],
        [-86, -41],
        [-35, -33],
      ],
      [
        [50329, 55350],
        [0, 32],
        [-13, 6],
        [-15, 11],
        [-10, 17],
        [-13, 38],
        [-5, 29],
        [-20, 5],
        [-25, 33],
        [-24, 39],
        [-8, 38],
        [2, 17],
        [-3, 18],
        [-9, 7],
        [-21, 86],
        [-13, 35],
        [-7, 28],
        [3, 22],
        [-3, 29],
        [4, 23],
        [11, 14],
        [4, 17],
        [1, 37],
        [7, 74],
        [4, 74],
        [-12, 20],
        [-15, 6],
        [-8, 21],
        [-3, 34],
        [0, 30],
        [30, 104],
        [-6, 241],
        [4, 37],
        [13, 25],
        [12, 29],
        [-1, 29],
        [-19, 72],
        [-37, 55],
        [-19, 45],
        [-10, 40],
        [-2, 21],
        [22, 32],
        [10, 21],
        [2, 25],
        [-10, 46],
        [2, 81],
        [9, 61],
        [8, 79],
        [0, 24],
        [-22, 47],
        [-12, 6],
        [-9, -3],
        [-23, -31],
        [-8, -4],
        [-5, 10],
        [-3, 12],
        [8, 18],
        [-3, 24],
        [7, 20],
        [14, 9],
        [5, 10],
        [-20, 10],
        [-2, 13],
        [1, 14],
        [6, 2],
        [6, -1],
        [3, 10],
        [3, 67],
        [3, 23],
        [2, 47],
        [3, 179],
        [5, 19],
        [0, 13],
        [-13, 9],
        [-32, 48],
        [-19, 37],
        [-17, 38],
        [-14, 25],
        [-27, 38],
        [-7, 25],
        [-2, 24],
        [9, 49],
        [13, 52],
        [6, 75],
        [-4, 20],
        [-18, 35],
      ],
      [
        [49980, 58246],
        [64, -27],
        [90, -45],
        [2, -8],
        [0, -13],
        [16, 0],
        [26, 16],
        [72, 6],
      ],
      [
        [84901, 47154],
        [-19, -99],
        [-20, 21],
        [22, 55],
        [10, 17],
        [7, 6],
      ],
      [
        [84740, 46363],
        [-9, 75],
        [-10, 33],
        [-8, 18],
        [-3, 23],
        [0, 23],
        [5, 11],
        [34, 3],
        [14, 38],
        [0, 47],
        [-7, 15],
        [-7, 7],
        [-35, -35],
        [-10, 7],
        [-6, 12],
        [2, 51],
      ],
      [
        [84700, 46691],
        [29, 48],
        [25, 88],
        [17, 34],
        [40, 33],
        [16, 9],
        [118, 48],
        [28, 3],
        [74, -1],
        [100, 10],
        [24, 7],
        [32, 21],
        [31, 26],
        [16, 21],
        [18, 14],
        [25, -18],
        [44, -14],
        [11, -13],
        [11, -17],
        [-50, -92],
        [-56, -76],
        [-34, -23],
        [-35, -15],
        [-27, -29],
        [-23, -46],
        [-29, -26],
        [-32, -9],
        [-28, -14],
        [-26, -27],
        [-35, -46],
        [-14, -5],
        [-15, 1],
        [-29, -17],
        [-91, -67],
        [-55, -73],
        [-40, -63],
      ],
      [
        [84454, 46462],
        [45, 49],
        [68, 38],
      ],
      [
        [84567, 46549],
        [-2, -28],
        [-7, -44],
        [-10, -20],
        [-16, -37],
        [-10, -8],
        [-41, 8],
        [-5, -5],
        [-7, 3],
        [-11, 24],
        [-4, 20],
      ],
      [
        [78501, 58569],
        [-5, -60],
        [-15, 24],
        [3, 31],
        [7, 13],
        [10, -8],
      ],
      [
        [78451, 58749],
        [1, -14],
        [-14, 10],
        [-5, -5],
        [-17, 4],
        [-8, 80],
        [2, 19],
        [11, -6],
        [17, -40],
        [8, -27],
        [5, -21],
      ],
      [
        [77335, 56395],
        [-3, -43],
        [-11, 1],
        [-12, -27],
        [-5, -4],
        [-10, 86],
        [11, 121],
        [6, 18],
        [8, -32],
        [23, -15],
        [-10, -69],
        [3, -36],
      ],
      [
        [77811, 63546],
        [-2, -34],
        [7, -7],
        [9, 15],
        [13, 39],
        [13, 22],
        [14, 5],
        [16, -27],
        [16, -57],
        [17, -33],
        [7, -3],
        [6, -26],
        [1, -26],
        [-8, -53],
        [-13, -62],
        [-20, -76],
        [7, -64],
        [26, -53],
        [31, -31],
        [33, 9],
        [17, 15],
        [14, 25],
        [14, 12],
        [16, 3],
        [53, -18],
        [16, -18],
        [2, -35],
        [-6, -92],
        [8, -67],
        [15, -70],
        [1, -65],
        [-18, -106],
        [-15, -101],
        [-17, -48],
        [-12, -32],
        [-4, -21],
        [1, -20],
        [12, -30],
        [12, -39],
        [3, -38],
        [-1, -46],
        [-9, -62],
        [-31, -137],
        [-26, -122],
        [14, -25],
        [24, -18],
        [17, -18],
        [17, 12],
        [37, 72],
        [32, 54],
        [39, 54],
        [2, 5],
        [35, 40],
        [15, 36],
        [9, 47],
        [12, 18],
        [16, -11],
        [20, 20],
        [24, 51],
        [19, 24],
        [13, -4],
        [23, -32],
        [33, -59],
        [30, -36],
        [26, -11],
        [13, -22],
        [-1, -33],
        [6, -21],
        [12, -9],
        [5, 4],
        [-1, 16],
        [12, 23],
        [25, 31],
        [25, 18],
        [26, 5],
        [17, 25],
        [11, 63],
        [16, 48],
        [14, 22],
        [17, 11],
        [5, 15],
        [-9, 20],
        [1, 19],
        [10, 21],
        [22, 8],
        [34, -3],
        [39, -20],
        [45, -38],
        [30, -13],
        [14, 14],
        [28, -59],
        [41, -131],
        [35, -100],
        [29, -67],
        [31, -52],
        [32, -36],
        [24, -49],
        [21, -93],
        [-16, -128],
        [-4, -111],
        [2, -137],
        [19, -104],
        [36, -73],
        [21, -59],
        [6, -44],
        [28, -39],
        [51, -32],
        [21, -29],
        [-9, -26],
        [0, -30],
        [7, -35],
        [17, -28],
        [28, -23],
        [17, -24],
        [5, -25],
        [-1, -40],
        [-6, -57],
        [-12, -43],
        [-17, -30],
        [-2, -24],
        [-4, -36],
        [0, -74],
        [12, -50],
        [4, -63],
        [-7, -51],
        [-6, -105],
        [-1, -41],
        [-6, -34],
        [-15, -34],
        [-22, -32],
        [-27, -28],
        [-17, -12],
      ],
      [
        [79217, 60107],
        [-4, -6],
        [-12, -32],
        [-15, -31],
        [-11, 0],
        [-8, 16],
        [-6, 20],
        [-4, 44],
        [-25, 22],
        [-28, 14],
        [-56, -22],
        [-46, -12],
        [-51, 15],
        [-48, -19],
        [-20, -3],
        [-23, 3],
        [-22, 0],
        [-22, 7],
        [-39, 27],
        [-15, -2],
        [-32, -23],
        [-33, -15],
        [-31, -11],
        [-47, -46],
        [-34, -67],
        [-10, -47],
        [-17, -48],
        [-23, -75],
        [-30, -72],
        [-21, -33],
        [6, -19],
        [-6, -24],
        [-32, -10],
        [-26, -4],
        [-5, -12],
        [3, -145],
        [9, -55],
        [17, -66],
        [11, -36],
        [8, -108],
        [2, -91],
        [36, -58],
        [21, -44],
        [14, -38],
        [-5, -25],
        [-8, -74],
        [8, -95],
        [51, -206],
        [4, -15],
      ],
      [
        [78592, 58586],
        [-6, -2],
        [-8, 40],
        [-26, 67],
        [-8, 71],
        [-30, 79],
        [-16, 31],
        [-6, -26],
        [-9, -28],
        [-30, 40],
        [-25, 43],
        [-23, 81],
        [-3, -19],
        [-6, -17],
        [-26, 64],
        [-28, 51],
        [-25, 19],
        [-15, 17],
        [-15, 27],
        [-31, 28],
        [-78, -40],
        [-98, 31],
        [-38, -30],
        [-16, 19],
        [-9, 35],
        [9, 59],
        [2, 125],
        [12, 88],
        [-6, 67],
        [6, 31],
        [4, 43],
        [-15, 17],
        [-70, 34],
        [-15, 27],
        [-18, -31],
        [-84, -17],
        [-31, -26],
        [-29, -49],
        [-8, -64],
        [17, -41],
        [11, -73],
        [-30, -158],
        [-5, -46],
        [12, -194],
        [-5, -106],
        [-16, -71],
        [-26, -63],
        [-11, -109],
        [-20, -50],
        [-28, -114],
        [-18, -143],
        [-13, -66],
        [-8, -122],
        [-56, -184],
        [-13, -104],
        [-20, -40],
        [7, -31],
        [1, -52],
        [-7, -139],
        [-2, -115],
        [8, -62],
        [27, -122],
        [-6, -36],
        [-3, -50],
        [22, -23],
        [17, -7],
        [91, 58],
        [31, -15],
        [12, -54],
        [8, -47],
        [15, -254],
        [8, -48],
        [19, -45],
        [20, -47],
        [7, 8],
        [1, 18],
        [1, 20],
        [19, -48],
        [14, -90],
        [48, -476],
        [14, -61],
        [11, -63],
        [-29, 31],
        [-8, 105],
        [-8, 45],
        [-11, 6],
        [-16, 0],
        [-1, 18],
        [12, 35],
        [-2, 41],
        [-17, 34],
        [-27, -27],
        [1, -74],
        [12, -57],
        [46, -127],
        [15, -53],
        [18, -15],
        [27, 8],
        [32, -54],
        [25, -50],
        [63, -77],
        [38, 8],
        [41, 19],
        [27, -5],
        [27, -20],
        [32, -64],
        [52, -161],
        [84, -134],
      ],
      [
        [78361, 55438],
        [-9, -33],
        [-4, -50],
        [-33, -68],
        [-5, -39],
        [-13, -50],
        [-23, -26],
        [-19, -5],
        [-12, 4],
        [-8, 10],
        [-13, 47],
        [-7, 14],
        [-6, 4],
        [-42, -33],
        [-41, -35],
        [-7, -33],
        [-11, -37],
        [-12, -15],
        [-10, -3],
        [-9, 22],
        [-15, 28],
        [-12, 27],
        [3, 43],
        [23, 64],
        [3, 44],
        [-3, 77],
        [-6, 44],
        [-7, 1],
        [-11, 7],
        [-32, -7],
        [-16, 50],
        [-7, 54],
        [-10, 20],
        [-11, 12],
        [-24, -19],
        [-18, 11],
        [-61, 48],
        [-23, 76],
        [-13, 2],
        [-11, -8],
        [-4, -18],
        [-7, -88],
        [-5, -27],
      ],
      [
        [77810, 55553],
        [-69, 178],
        [-48, 73],
        [6, 132],
        [-14, 26],
        [-18, 2],
        [-14, 37],
        [12, 79],
        [-19, -15],
        [-26, 3],
        [-21, 21],
        [-16, 109],
        [-11, 33],
        [-22, 58],
        [-29, 0],
        [-10, 27],
        [2, 70],
        [-21, 43],
        [-28, 36],
        [-23, 20],
        [-24, 114],
        [-19, 28],
        [-16, 22],
        [-22, -15],
        [-7, -41],
        [-15, -39],
        [-16, 5],
        [-16, 22],
        [-18, 114],
        [-4, 69],
        [5, 129],
        [23, 116],
        [13, 185],
        [20, 117],
        [13, 39],
        [20, 159],
        [39, 204],
      ],
      [
        [77417, 57713],
        [4, 43],
        [8, 49],
        [6, 46],
        [2, 73],
        [-5, 38],
        [0, 22],
        [8, 27],
        [28, 46],
        [38, 76],
        [46, 107],
        [47, 163],
        [23, 95],
        [10, 34],
        [10, 10],
        [16, 33],
        [11, 36],
        [1, 18],
        [-26, 178],
        [-16, 58],
        [-9, 68],
        [-4, 50],
        [-6, 45],
        [3, 43],
        [-9, 26],
        [-21, 34],
        [-22, 50],
        [-13, 82],
        [0, 46],
        [-13, 40],
        [-5, 42],
        [8, 40],
        [11, 34],
        [-1, 152],
        [-4, 46],
        [-6, 81],
        [-14, 61],
        [-20, 72],
        [-22, 59],
        [-59, 107],
        [-42, 71],
        [-21, 66],
        [-26, 74],
        [-19, 54],
        [-24, 68],
        [-12, 93],
        [-7, 99],
        [4, 33],
        [11, 21],
        [15, 18],
        [12, 4],
        [34, 45],
        [24, -4],
        [5, 10],
        [2, 21],
        [-3, 90],
        [2, 120],
        [4, 98],
        [5, 64],
        [63, 75],
        [13, 33],
        [6, 35],
        [0, 31],
        [-5, 24],
        [-9, 14],
        [-41, -65],
        [-8, 15],
        [-19, 106],
        [-8, 32],
        [-11, 39],
        [-13, 54],
        [-2, 94],
        [-9, 46],
        [-50, 99],
        [-23, 53],
        [-31, 77],
        [-37, 92],
        [-38, 86],
        [-18, 54],
        [-6, 12],
        [-2, 21],
        [11, 59],
        [-5, 59],
        [-19, 78],
        [-8, 49],
        [2, 19],
        [-9, 7],
        [-21, -4],
        [-20, 36],
        [-20, 78],
        [-1, 14],
        [6, -1],
        [24, -13],
        [9, 2],
        [17, 18],
        [26, 19],
        [16, 6],
        [5, 9],
        [2, 19],
        [-13, 179],
        [2, 37],
        [25, 77],
        [-3, 78],
        [7, 112],
        [27, 77],
        [21, 35],
        [7, 55],
        [9, 12],
        [17, -5],
        [36, -41],
        [15, -2],
        [22, 1],
        [23, 3],
        [11, 4],
        [74, 40],
        [16, 5],
        [16, -5],
        [11, 2],
        [12, 18],
        [8, 33],
        [9, 103],
        [5, 19],
        [10, 15],
        [16, 10],
        [18, -1],
        [24, -20],
        [15, -1],
        [17, 8],
        [15, 15],
        [9, 18],
        [5, 22],
        [-4, 41],
        [-11, 53],
        [3, 6],
        [20, -11],
        [30, -13],
        [22, 3],
        [15, 9],
        [14, 25],
        [19, 23],
        [17, -5],
        [14, -21],
        [33, -36],
      ],
      [
        [77798, 57428],
        [-3, -9],
        [-11, 18],
        [-8, 21],
        [-4, 26],
        [17, -1],
        [8, -25],
        [1, -30],
      ],
      [
        [77797, 57365],
        [1, -33],
        [-6, -39],
        [-25, -23],
        [-9, 31],
        [2, 49],
        [4, 12],
        [25, -3],
        [8, 6],
      ],
      [
        [77683, 55599],
        [-5, -3],
        [-10, 47],
        [13, 67],
        [13, -82],
        [-11, -29],
      ],
      [
        [77386, 56413],
        [-3, -10],
        [-14, 110],
        [20, -29],
        [-3, -71],
      ],
      [
        [77307, 57057],
        [-16, -7],
        [2, 32],
        [4, 20],
        [8, 5],
        [3, -34],
        [-1, -16],
      ],
      [
        [77521, 56216],
        [7, -70],
        [-10, 15],
        [-8, 30],
        [0, 44],
        [2, 7],
        [9, -26],
      ],
      [
        [60971, 48286],
        [21, -123],
        [-3, -23],
        [-15, -14],
        [-8, -1],
        [-9, 20],
        [-7, 41],
        [-11, -10],
        [-19, 50],
        [-20, 2],
        [-17, 59],
        [7, 51],
        [-4, 88],
        [21, 45],
        [12, 76],
        [13, -52],
        [3, -80],
        [18, -95],
        [15, -29],
        [3, -5],
      ],
      [
        [61073, 49017],
        [2, -30],
        [-5, -27],
        [1, -87],
        [-1, -58],
        [-16, -80],
        [-13, -28],
        [-12, 8],
        [-9, 13],
        [-8, 22],
        [15, 147],
        [-7, 107],
        [30, -10],
        [23, 23],
      ],
      [
        [58541, 47123],
        [-8, 51],
        [-19, 77],
        [-26, 109],
        [-20, 89],
        [-22, 96],
        [-9, 70],
        [-17, 78],
        [-28, 96],
        [-14, 37],
        [-16, 33],
        [-40, 65],
        [-45, 64],
        [-25, 43],
        [-33, 128],
        [-14, 47],
        [-9, 81],
        [-8, 85],
        [3, 34],
        [30, 109],
        [3, 31],
        [-4, 42],
        [-14, 87],
        [-11, 56],
        [-8, 49],
        [-15, 81],
        [-22, 111],
        [-5, 49],
        [0, 36],
        [12, 96],
        [10, 99],
        [0, 28],
      ],
      [
        [58167, 49280],
        [87, -4],
        [15, 22],
        [49, 63],
        [56, 128],
        [11, 54],
        [22, 82],
        [23, 40],
        [8, 29],
        [6, 44],
        [7, 37],
        [29, 56],
        [28, 42],
        [-2, 18],
        [-4, 12],
        [5, 11],
        [15, 22],
        [30, 20],
        [6, 43],
        [0, 48],
        [-5, 28],
        [1, 31],
        [-5, 17],
        [-19, 5],
        [-29, 24],
        [-25, 10],
        [-17, 14],
        [-6, 11],
        [-2, 29],
        [4, 32],
        [3, 9],
        [6, 34],
        [-11, 20],
        [-2, 10],
        [5, 16],
        [25, 108],
        [6, 15],
      ],
      [
        [58487, 50460],
        [11, 2],
        [17, 13],
        [16, 6],
        [14, -5],
        [9, 6],
        [9, 14],
        [7, 42],
        [6, 70],
        [-3, 57],
        [-13, 44],
        [-3, 68],
        [6, 90],
        [-5, 75],
        [-14, 60],
        [-14, 36],
        [-22, 17],
        [-34, 92],
        [-11, 44],
        [2, 28],
        [9, 9],
      ],
      [
        [59417, 51265],
        [21, 0],
        [20, -21],
        [22, -26],
        [60, -69],
        [59, -68],
        [59, -69],
        [59, -68],
        [59, -69],
        [60, -68],
        [59, -69],
        [59, -69],
        [59, -68],
        [59, -69],
        [60, -68],
        [59, -69],
        [59, -68],
        [59, -69],
        [60, -68],
        [59, -69],
        [28, -33],
        [4, -14],
        [5, -62],
        [3, -39],
        [-1, -35],
        [-16, -58],
        [-5, -31],
        [0, -21],
        [4, -8],
        [13, -3],
        [12, -14],
        [4, -11],
        [9, -44],
        [11, -22],
        [25, -37],
        [42, -64],
        [43, -64],
        [43, -63],
        [42, -64],
        [43, -64],
        [43, -63],
        [42, -64],
        [43, -64],
        [21, -31],
        [8, -8],
      ],
      [
        [60894, 49140],
        [-5, -49],
        [-22, -117],
        [-1, -49],
        [-9, -58],
        [-8, -38],
        [-22, -165],
        [-19, -62],
        [-25, -145],
        [-5, -111],
        [15, -78],
        [5, -72],
        [30, -72],
        [23, -25],
        [17, -33],
        [28, -74],
        [17, -75],
        [51, -37],
        [20, -83],
        [-7, -58],
        [-24, -48],
        [-22, -77],
        [-18, -102],
        [-1, -155],
        [13, 23],
        [27, -38],
        [3, -114],
        [-28, -134],
        [-8, -62],
        [-2, -53],
        [21, -160],
        [30, -81],
        [-2, -26],
        [-8, -21],
        [53, -144],
        [-5, -125],
        [20, -97],
        [9, -84],
        [13, -65],
        [2, -44],
        [-16, -50],
        [39, -12],
        [22, -41],
        [11, -39],
        [28, 2],
        [15, -26],
        [22, -22],
        [47, -65],
        [13, -33],
        [5, -19],
        [3, -12],
      ],
      [
        [61239, 45815],
        [-32, -50],
        [-50, -79],
        [-50, -77],
        [-47, -52],
        [-34, -25],
        [-37, -13],
        [-34, -33],
        [-33, -50],
        [-42, -26],
        [-51, 0],
        [-53, -36],
        [-53, -67],
        [-31, -39],
        [-50, 59],
        [-38, 19],
        [-44, -2],
        [-27, -8],
        [-10, -12],
        [-8, -36],
        [-7, -59],
        [-29, -57],
        [-51, -55],
        [-47, -20],
        [-43, 14],
        [-29, 22],
        [-15, 32],
        [-23, 15],
        [-29, -3],
        [-28, -22],
        [-27, -43],
        [-44, -18],
        [-59, 5],
        [-31, 21],
        [-5, 35],
        [-26, 42],
        [-47, 47],
        [-35, 1],
        [-23, -45],
        [-20, -29],
        [-19, -12],
        [-16, -1],
        [-15, 9],
        [-9, 3],
        [-66, 5],
        [-62, -2],
      ],
      [
        [59710, 45173],
        [-2, 20],
        [-4, 46],
        [-13, 41],
        [-11, 24],
        [-14, 6],
        [-7, -1],
        [-6, 19],
        [-7, 41],
        [-11, 35],
        [-14, 29],
        [-8, 27],
        [-3, 25],
        [2, 27],
        [13, 67],
        [4, 47],
        [-1, 47],
        [-7, 49],
        [-15, 58],
        [2, 16],
        [-5, 40],
        [-1, 28],
        [3, 34],
        [-3, 45],
        [-13, 97],
        [0, 25],
        [-13, 47],
        [-41, 111],
        [-2, 14],
        [-65, 112],
        [-25, 24],
        [-10, -21],
        [-3, -19],
        [3, -36],
        [-2, -18],
        [-3, -8],
        [-15, 1],
        [-10, 4],
        [-24, 30],
        [-19, 8],
        [-47, -6],
        [-17, -7],
        [-13, 7],
        [-25, 51],
        [-29, 11],
        [-27, 3],
        [-43, 58],
        [-10, -3],
        [-5, -4],
      ],
      [
        [61030, 47247],
        [-15, -7],
        [-6, 7],
        [-9, 24],
        [16, 21],
        [16, 39],
        [36, 59],
        [12, 38],
        [5, 8],
        [-3, -45],
        [-20, -99],
        [-18, -7],
        [-14, -38],
      ],
      [
        [69710, 75022],
        [1, -11],
        [-4, -19],
        [-58, -32],
        [-26, -28],
        [-6, -49],
        [-7, -14],
        [-12, -11],
        [-11, -3],
        [-18, 57],
        [-20, 12],
        [-29, 20],
        [-56, 40],
        [-29, 17],
        [-56, -26],
        [-66, -35],
        [-10, -21],
        [-7, -23],
        [1, -17],
        [4, -23],
        [-3, -18],
        [-12, -6],
        [-19, 22],
        [-16, 12],
        [-8, -29],
        [-9, -53],
        [-4, -38],
        [14, -54],
        [5, -82],
        [26, 5],
        [20, 0],
        [37, 24],
        [19, 0],
        [29, -10],
        [52, -2],
        [40, 4],
        [10, -2],
        [9, 16],
        [11, -6],
        [10, -18],
        [41, 22],
        [30, 3],
        [19, -6],
        [11, -7],
        [19, -53],
        [15, -34],
        [19, -11],
        [57, 10],
        [17, 47],
        [14, 12],
        [24, 3],
        [19, 9],
        [16, 20],
        [21, 16],
        [18, 4],
        [9, -13],
        [4, -16],
        [-3, -21],
        [-1, -22],
        [12, -15],
        [35, -3],
        [17, -14],
        [1, -26],
        [-3, -41],
        [15, -17],
        [7, -1],
        [52, 43],
        [14, 1],
        [12, -24],
        [17, -29],
        [23, -30],
        [6, 4],
        [10, 34],
        [20, 36],
        [36, 12],
        [21, 11],
        [21, 5],
        [65, -14],
        [21, -2],
        [44, 3],
        [35, 7],
        [28, 22],
        [15, 17],
        [23, 10],
        [29, -1],
        [16, -5],
      ],
      [
        [70453, 74567],
        [1, -30],
        [-4, -57],
        [-4, -40],
        [23, -72],
        [15, -34],
        [14, -25],
        [3, -19],
        [-3, -16],
        [-18, -15],
        [-7, -17],
        [-3, -18],
        [6, -21],
        [11, -68],
        [13, -53],
        [19, -26],
        [28, -17],
        [15, 4],
        [11, 40],
        [18, 30],
        [16, -2],
        [25, 1],
        [66, -34],
        [64, -52],
        [18, -29],
        [7, -32],
        [-18, -75],
        [1, -47],
        [4, -51],
        [15, -38],
        [13, -65],
        [3, -53],
        [6, -16],
        [4, -19],
        [-7, -49],
        [-5, -50],
        [6, -16],
        [20, -25],
        [31, -45],
        [6, -38],
        [-11, -24],
        [-20, -29],
        [-25, -25],
        [-8, -11],
      ],
      [
        [70802, 73289],
        [-4, 6],
        [-12, 26],
        [-28, 41],
        [-20, 21],
        [-38, -7],
        [-22, 8],
        [-26, 13],
        [-25, -2],
        [-16, -24],
        [-10, -25],
        [-25, -8],
        [-35, -19],
        [-56, -30],
        [-26, 5],
        [-8, 13],
        [6, 17],
        [19, 22],
        [5, 26],
        [-4, 25],
        [-17, 7],
        [-7, 4],
        [-8, 5],
        [-34, 15],
        [-28, -6],
        [-47, -31],
        [-88, -81],
        [-38, -55],
        [-28, -82],
        [-83, -27],
        [-57, -47],
        [-59, -78],
        [-39, -41],
        [-19, -6],
        [-19, 7],
        [-19, 21],
        [-18, 65],
        [-16, 98],
        [-11, 64],
        [6, 84],
        [7, 94],
        [7, 96],
        [11, 111],
        [10, 40],
        [0, 26],
        [-8, 13],
        [-18, 0],
        [-27, -15],
        [-20, -3],
        [-11, 10],
        [1, 52],
        [14, 93],
        [-21, 79],
        [-57, 64],
        [-48, 22],
        [-40, -20],
        [-33, -50],
        [-27, -82],
        [-28, -68],
        [-29, -52],
        [-21, -25],
        [-7, -10],
        [-4, -22],
        [16, -69],
        [-1, -58],
        [-18, -48],
        [-19, -22],
        [-21, 2],
        [-16, 11],
        [-13, 20],
        [-33, 5],
        [-54, -9],
        [-37, -23],
        [-20, -39],
        [-6, -50],
        [8, -62],
        [-4, -48],
        [-17, -34],
        [-14, -19],
        [-10, -5],
        [-24, 29],
        [-36, 62],
        [-25, 34],
        [-14, 5],
        [-7, -3],
        [-8, -7],
        [-5, -8],
        [-4, -18],
        [-12, -7],
        [-16, 5],
        [-15, -5],
        [-9, -20],
        [-25, -23],
        [-44, -27],
        [-25, -28],
        [-4, -30],
        [-6, -14],
        [-14, 5],
        [-40, -41],
        [-30, 13],
        [-35, 53],
        [-19, 44],
        [-2, 18],
      ],
      [
        [69639, 74783],
        [-25, -22],
        [-15, 3],
        [-11, 21],
        [-8, 20],
        [-2, 12],
        [4, -1],
        [19, -9],
        [27, -6],
        [10, -6],
        [1, -12],
      ],
      [
        [83613, 64873],
        [-17, -68],
        [-14, -71],
        [-6, -68],
        [1, -69],
        [-4, -63],
        [-7, -62],
        [-27, 18],
        [-15, 44],
        [-3, 73],
        [-20, 88],
        [-7, 26],
        [-28, 49],
        [-26, 25],
        [-20, 36],
        [3, -3],
        [-15, 49],
        [-11, 52],
        [-23, 148],
        [-8, 36],
        [-10, 32],
        [-3, 33],
        [3, 36],
        [10, 53],
        [6, 54],
        [-5, 74],
        [2, 73],
        [8, 32],
        [130, 443],
        [36, 94],
        [22, 47],
        [18, 52],
        [17, 66],
        [22, 60],
        [15, 19],
        [75, 54],
        [23, 52],
        [19, 16],
        [21, -1],
        [14, -25],
        [12, -29],
        [13, -16],
        [33, -28],
        [15, -28],
        [6, -48],
        [-20, -45],
        [-10, -41],
        [-2, -45],
        [4, -61],
        [0, -61],
        [-25, -143],
        [-27, -90],
        [-7, -44],
        [-9, -111],
        [-16, -111],
        [-13, -140],
        [-22, -145],
        [-13, -61],
        [-16, -58],
        [-37, -109],
        [-42, -90],
      ],
      [
        [82890, 65968],
        [12, -38],
        [-5, -24],
        [-38, 13],
        [-2, 23],
        [14, -4],
        [19, 30],
      ],
      [
        [61766, 73219],
        [0, -8],
        [-3, -20],
        [-31, -57],
        [-43, -78],
        [-30, -49],
        [-52, -83],
        [-38, -18],
        [-65, -30],
        [-17, -29],
        [-17, -46],
        [-9, -64],
        [-3, -40],
        [-2, -75],
        [16, -77],
        [14, -75],
        [2, -49],
        [-1, -48],
        [-14, -52],
        [-16, -71],
        [-9, -80],
        [-4, -150],
        [0, -128],
        [-2, -21],
        [-26, -90],
        [-31, -106],
        [-15, -24],
        [-68, -32],
        [-74, -77],
        [-84, -87],
        [-75, -78],
        [-79, -83],
        [-83, -85],
        [-59, -61],
        [-78, -82],
      ],
      [
        [60770, 71066],
        [-72, -78],
        [-72, -79],
        [-56, -60],
        [-83, -95],
        [-49, -56],
        [-73, -82],
        [-63, -72],
        [-75, -85],
        [-94, 25],
        [-30, 15],
        [-25, 40],
        [-18, 22],
        [-44, 22],
        [-29, 77],
        [-17, 27],
        [-30, 12],
      ],
      [
        [59940, 70699],
        [4, 27],
        [16, 47],
        [15, 50],
        [-8, 28],
        [-3, 23],
        [-1, 29],
        [10, 27],
        [-5, 33],
        [-8, 33],
        [-6, 16],
        [0, 30],
        [4, 23],
        [5, 36],
      ],
      [
        [59963, 71101],
        [13, 19],
        [3, 20],
        [11, 20],
        [16, 16],
        [3, 13],
        [-2, 7],
        [-15, 15],
        [-9, 25],
        [8, 38],
        [5, 11],
        [9, 18],
        [20, 28],
        [16, 4],
        [14, 0],
        [23, -2],
        [18, -5],
        [5, 7],
        [-1, 9],
        [-22, 23],
        [-1, 18],
        [5, 19],
        [16, 30],
        [19, 23],
        [9, 4],
        [22, 44],
        [14, 50],
        [-22, 122],
        [-14, 19],
        [-22, 17],
        [-13, 3],
        [-1, 8],
        [17, 30],
        [13, 27],
        [-14, 26],
        [-24, 12],
        [-9, -27],
        [-32, -2],
        [-48, 0],
      ],
      [
        [59993, 71790],
        [-22, 129],
        [-3, 55],
        [1, 65],
        [15, 94],
        [-7, 43],
        [-1, 30],
        [-4, 40],
        [-38, 87],
        [21, 160],
        [15, 39],
      ],
      [
        [52645, 79218],
        [8, -7],
        [20, -26],
        [-4, -43],
        [-23, -70],
      ],
      [
        [52646, 79072],
        [-12, -56],
        [-1, -44],
        [2, -20],
        [4, 0],
        [22, -3],
      ],
      [
        [52661, 78949],
        [11, 0],
        [34, -11],
        [28, -17],
        [6, -19],
        [3, -22],
        [33, -30],
        [38, -19],
        [13, 6],
        [47, 71],
        [19, -12],
        [11, -38],
        [-1, -19],
      ],
      [
        [52903, 78839],
        [-13, -75],
        [-2, -41],
        [11, -26],
        [1, -21],
        [-3, -19],
        [-19, -2],
        [-25, 11],
        [-21, 32],
        [-17, -4],
        [-14, -8],
        [-7, -31],
        [-6, -36],
        [2, -21],
        [10, -15],
        [8, -34],
        [5, -43],
        [5, -20],
        [-5, -8],
        [-13, -6],
        [-11, 5],
        [-20, 52],
        [-9, 20],
        [-15, 3],
        [-27, -12],
        [-41, -29],
        [-17, 0],
        [-14, 6],
        [-13, 24],
        [-11, 47],
        [-4, 30],
        [-8, -1],
        [-26, 9],
        [-12, -12],
        [0, -48],
        [-3, -61],
        [-13, -38],
        [-37, -68],
        [-13, -29],
        [-6, -21],
        [-1, -18],
        [6, -32],
        [7, -30],
        [-6, -18],
        [-19, -9],
        [-14, 19],
        [-5, 32],
        [-30, 45],
        [13, 37],
        [-2, 10],
        [-49, 19],
        [-21, 28],
        [-30, 50],
        [-6, 21],
        [2, 69],
        [-2, 17],
        [-4, 8],
        [-14, 0],
        [-20, -24],
        [-19, -36],
        [-38, -41],
        [-4, -8],
        [13, -40],
        [-1, -15],
        [-30, -63],
        [-6, -21],
        [-39, -39],
        [-18, -15],
        [-54, 29],
        [-15, 4],
        [-25, -20],
        [-34, -18],
        [-55, -19],
        [-21, 14],
        [-9, 13],
      ],
      [
        [51950, 78298],
        [-5, 19],
        [-14, 33],
        [-16, 20],
        [-11, 22],
        [-14, 24],
        [-9, 19],
        [12, 64],
        [-9, 22],
        [-5, 32],
        [3, 22],
        [-5, 5],
        [-50, 12],
        [-42, -4],
        [-29, -21],
        [-25, -35],
        [-3, -8],
        [2, -6],
        [12, -32],
        [-20, -35],
        [-32, -26],
        [-22, -3],
        [-10, 5],
        [0, 37],
        [18, 13],
        [17, 24],
        [5, 34],
        [2, 23],
        [-17, 29],
        [2, 17],
        [11, 34],
        [6, 29],
        [9, 25],
        [34, 42],
        [35, 41],
        [5, 45],
        [3, 54],
        [5, 13],
        [47, 32],
        [11, 13],
        [6, 18],
        [37, 60],
        [37, 60],
        [7, 20],
        [6, 12],
        [0, 10],
        [-4, 7],
        [-18, 5],
        [-6, 19],
        [19, 34],
        [24, 21],
        [23, 0],
        [9, -9],
        [-1, -11],
        [10, -12],
        [18, -4],
        [21, 4],
        [22, 13],
        [13, 30],
        [7, 23],
        [34, 26],
      ],
      [
        [52115, 79258],
        [23, -13],
        [63, -4],
        [47, 7],
        [29, 18],
        [36, 0],
        [24, -10],
        [4, 1],
        [7, 3],
        [6, 9],
        [23, 7],
        [3, 8],
        [-1, 8],
        [-4, 4],
        [-28, -4],
        [-11, 6],
        [-2, 15],
        [9, 25],
        [20, 20],
        [18, 5],
        [12, -5],
        [31, -38],
        [7, -2],
        [5, 7],
        [6, 4],
        [11, -7],
        [11, -24],
        [2, -4],
        [69, 9],
        [15, 0],
        [47, -42],
        [48, -43],
      ],
      [
        [55298, 85158],
        [-23, -14],
        [-13, -40],
        [-19, -7],
        [-17, -14],
        [-7, -128],
        [33, -49],
        [-18, -7],
        [-17, -14],
        [-11, -22],
        [-12, -47],
        [-45, -26],
        [-17, -19],
        [-25, -44],
        [-13, -63],
        [-25, -27],
        [-29, -6],
        [17, 52],
        [22, 42],
        [-21, 28],
        [-13, 46],
        [-16, 34],
        [13, 39],
        [-7, 63],
        [2, 62],
        [19, 32],
        [22, 25],
        [34, 59],
        [37, 42],
        [51, 19],
        [23, -17],
        [10, 38],
        [17, 9],
        [15, -9],
        [33, -37],
      ],
      [
        [54591, 84268],
        [-15, -29],
        [-12, 2],
        [-9, 39],
        [-2, 99],
        [5, 49],
        [61, 178],
        [27, 14],
        [38, 109],
        [10, 48],
        [17, 44],
        [10, 39],
        [8, 15],
        [17, -7],
        [8, -7],
        [-18, -23],
        [2, -29],
        [-1, -13],
        [-48, -128],
        [-12, -83],
        [-17, -21],
        [-69, -296],
      ],
      [
        [53163, 85850],
        [23, -73],
        [20, -10],
        [28, 19],
        [19, 53],
        [11, 81],
        [13, 76],
        [-15, 81],
        [-17, 72],
        [-1, 21],
        [43, 60],
        [13, 49],
        [14, 47],
        [16, 16],
        [23, 4],
        [27, 9],
        [34, 31],
        [30, 42],
        [24, 38],
        [8, 76],
        [0, 39],
        [10, 28],
        [10, 55],
        [-9, 55],
        [-31, 83],
        [-36, 117],
        [-5, 63],
        [16, 12],
        [32, 11],
        [60, 3],
        [6, 7],
        [6, 28],
        [13, 38],
        [15, 28],
        [10, 39],
        [4, 36],
        [-34, 54],
        [-45, 55],
        [-30, 18],
        [-54, 46],
        [-38, 39],
        [22, 148],
        [16, 109],
        [3, 27],
        [0, 41],
        [-52, 177],
        [2, 39],
        [5, 35],
        [-6, 61],
        [-3, 54],
        [9, 16],
        [21, 30],
        [-20, 47],
        [-2, 4],
        [-38, 117],
        [59, 115],
        [-11, 60],
        [36, 44],
        [64, 99],
        [36, 56],
        [7, 9],
        [29, 25],
        [54, 29],
        [60, 14],
        [27, 0],
        [103, -20],
        [80, -15],
        [12, 15],
        [17, 32],
        [22, 45],
        [1, 50],
        [-7, 73],
        [-12, 44],
        [-57, 29],
        [-62, 39],
        [76, 124],
        [53, 86],
        [66, 129],
        [21, 54],
        [15, 22],
        [19, 198],
        [13, 56],
        [11, 29],
        [0, 30],
        [-7, 50],
        [-19, 114],
        [104, 14],
        [34, 7],
        [32, 14],
        [61, 35],
        [31, 31],
        [-17, 106],
        [37, 36],
        [91, 125],
        [98, 119],
        [46, 46],
        [5, 22],
        [4, 35],
        [-21, 56],
        [-22, 35],
        [-43, 65],
        [19, 46],
        [31, 9],
        [42, 18],
        [32, 39],
        [4, 5],
        [55, 154],
        [107, 77],
        [43, 43],
        [67, -32],
        [97, -48],
        [44, 71],
        [14, 26],
        [15, 39],
        [-6, 67],
        [-3, 87],
        [5, 34],
        [39, 16],
        [21, 4],
        [108, -36],
        [28, 1],
        [51, -5],
        [57, -16],
        [120, -42],
        [50, -17],
        [28, -4],
        [24, 20],
        [51, 50],
        [-76, 37],
        [50, 38],
        [26, 38],
        [22, 46],
        [8, 55],
        [-3, 29],
        [-16, 20],
        [-46, 50],
        [105, 7],
        [36, 2],
      ],
      [
        [55728, 91610],
        [76, -33],
        [3, -7],
        [0, -17],
        [3, -18],
        [74, -45],
        [21, -23],
        [45, -37],
        [12, -19],
        [42, -23],
        [30, -24],
        [35, -20],
        [41, -31],
        [55, -25],
        [46, -8],
        [117, -42],
        [20, -13],
        [33, -30],
        [35, -34],
        [23, -69],
        [38, -4],
        [10, -24],
        [33, -41],
        [46, -36],
        [-2, -12],
        [-36, -34],
        [-4, -45],
        [3, -58],
        [12, -47],
        [-2, -14],
        [-9, -16],
        [-10, -26],
        [-4, -22],
        [1, -11],
        [3, -6],
        [20, 0],
        [34, -5],
        [20, -10],
        [12, -55],
        [-4, -10],
        [-29, -25],
        [-9, -19],
        [-1, -29],
        [6, -31],
        [9, -36],
        [23, -37],
        [31, -40],
        [20, -32],
        [9, -23],
        [4, -16],
        [-14, -20],
        [-12, -40],
        [-3, -45],
        [-5, -30],
        [-27, -41],
        [-19, -14],
        [-5, -22],
        [-3, -36],
        [6, -44],
        [2, -29],
        [6, -22],
        [8, -14],
        [43, -25],
        [25, -50],
        [15, -41],
        [29, -106],
      ],
      [
        [56709, 89749],
        [-73, -13],
        [-56, 26],
        [-27, -13],
        [-48, -1],
        [-55, -10],
        [-19, -21],
        [-14, -8],
        [-51, 29],
        [-48, 49],
        [-35, -37],
        [-23, -7],
        [-20, 33],
        [-18, 6],
        [-10, -12],
        [-8, -29],
        [-14, -24],
        [-3, -14],
        [-2, -60],
        [-4, -14],
        [-46, 8],
        [2, -16],
        [10, -8],
        [5, -10],
        [-17, -13],
        [-47, 2],
        [-4, -14],
        [13, -22],
        [-10, -19],
        [-10, -8],
        [-55, -12],
        [-32, 3],
        [-9, -12],
        [-3, -16],
        [6, -16],
        [14, -9],
        [5, -10],
        [-1, -21],
        [-12, -4],
        [-33, 38],
        [-10, -2],
        [7, -20],
        [19, -22],
        [11, -22],
        [10, -26],
        [-2, -21],
        [-41, -65],
        [-37, -41],
        [-27, -37],
        [-16, -39],
        [19, -20],
        [20, -29],
        [15, -55],
        [17, -49],
        [35, -46],
        [-7, -27],
        [-8, -21],
        [-58, -47],
        [-66, -70],
        [-71, -178],
        [-24, -24],
        [-62, -30],
        [-23, -30],
        [-46, -35],
        [-81, -29],
        [-37, -42],
        [-16, -43],
        [-19, -3],
        [-18, 17],
        [-24, 12],
        [-3, -28],
        [1, -21],
        [-39, 31],
        [-19, -28],
        [-14, -47],
        [-56, -63],
        [-61, 11],
        [-6, -11],
        [16, -8],
        [2, -10],
        [-11, -5],
        [-17, 0],
        [-25, -12],
        [-17, 1],
        [-8, -30],
        [-13, -37],
        [-34, -15],
        [-18, -3],
        [-9, -20],
        [53, -5],
        [-4, -17],
        [-1, -17],
        [-6, -19],
        [-60, -27],
        [-9, -21],
        [-12, -13],
        [-27, 0],
        [1, 13],
        [4, 13],
        [-39, -1],
        [-12, 31],
        [-8, -8],
        [4, -25],
        [11, -25],
        [11, -38],
        [-9, -24],
        [-10, -11],
        [7, -11],
        [21, -8],
        [9, -15],
        [-25, -13],
        [-32, -44],
        [-32, -1],
        [-20, -28],
        [-21, 0],
        [-17, 18],
        [-27, 15],
        [-9, -27],
        [-2, -20],
        [16, -53],
        [29, -41],
        [28, -18],
        [-20, -12],
        [-15, -26],
        [-17, -83],
        [-10, -33],
        [-10, -57],
        [6, -49],
        [6, -24],
        [13, -32],
        [-36, 4],
        [-39, 19],
        [6, -39],
        [-24, -47],
        [4, -40],
        [5, -27],
        [-7, -44],
        [11, -14],
        [6, -26],
        [-10, -20],
        [5, -17],
        [1, -59],
        [8, -92],
        [-3, -20],
        [21, -80],
        [-5, -29],
        [-3, -36],
        [31, -34],
        [27, 0],
        [27, 1],
        [10, -9],
        [11, -24],
        [8, -29],
        [23, 2],
        [36, 24],
        [23, 6],
        [16, -46],
        [42, -59],
        [24, -27],
        [42, -14],
        [43, -48],
        [-6, -58],
        [18, -20],
        [52, -22],
        [18, -31],
        [9, -27],
        [14, -21],
        [16, -66],
        [-6, -41],
        [-21, -14],
        [-49, -44],
        [-22, -32],
        [-17, -20],
        [-49, -44],
        [-18, -8],
        [-17, -22],
        [-16, -10],
        [-15, 6],
        [-55, -41],
        [4, -18],
        [42, -7],
        [22, 9],
        [17, 20],
        [18, 5],
        [16, -4],
        [18, 16],
        [14, 7],
        [14, -8],
        [16, -39],
        [-33, -20],
        [-23, -1],
        [-12, -64],
        [-14, -27],
        [-10, -13],
        [-52, -27],
        [-35, -35],
        [-40, -27],
        [-18, 6],
        [-26, -29],
        [-59, -33],
        [-31, -45],
        [-68, -40],
        [-34, -32],
        [-95, -2],
        [-89, 7],
        [-29, -16],
        [29, -4],
        [20, -16],
        [25, 7],
        [57, -8],
        [29, -8],
        [38, -54],
        [-28, -19],
        [-48, -14],
        [18, -76],
        [15, -51],
        [-20, -31],
        [-1, -140],
        [-27, -2],
        [-12, -58],
        [9, -30],
        [-1, -69],
        [6, -42],
        [13, -39],
        [-6, -41],
        [-43, -95],
        [1, -44],
        [8, -27],
        [6, -42],
        [-20, -81],
        [-14, -69],
        [-16, -57],
        [-37, -69],
        [-18, -51],
        [-43, -160],
        [-21, -32],
        [-26, -24],
        [-29, 22],
        [-27, 13],
        [-32, -2],
        [-51, -18],
        [-77, 12],
        [-74, -6],
        [-19, -16],
        [11, -58],
        [-28, -8],
        [-26, 17],
        [-24, -20],
        [-20, -22],
        [-39, -51],
        [-13, -32],
        [-3, -59],
        [20, -54],
        [18, -62],
        [-46, -76],
        [-26, -2],
        [-76, 20],
        [-135, -47],
        [-121, 38],
        [15, 40],
        [0, 30],
        [6, 45],
        [4, 47],
        [-1, 32],
        [-9, 33],
        [-29, 44],
        [-68, 147],
        [-19, 62],
        [-14, 26],
        [10, 1],
        [55, -33],
        [13, 4],
        [13, 13],
        [-16, 47],
        [-14, 22],
        [-10, 32],
        [33, 9],
        [23, -2],
        [17, 37],
        [-10, 58],
        [-25, 19],
        [-21, 7],
        [-40, 93],
        [-42, 48],
        [-75, 184],
        [-27, 127],
        [-26, -12],
        [-12, 55],
        [-9, 53],
        [-2, 38],
        [-40, 22],
        [-1, 27],
        [-8, 120],
        [-42, 16],
        [-28, 68],
        [-5, 128],
        [-28, 23],
        [-23, -7],
        [1, 32],
        [5, 30],
        [-13, 117],
        [-4, 108],
        [-11, 32],
        [-6, 38],
        [5, 33],
        [8, 19],
        [28, 5],
        [26, -29],
      ],
      [
        [55321, 85208],
        [-5, -36],
        [-15, 3],
        [-13, 26],
        [27, 41],
        [40, -2],
        [14, -9],
        [-48, -23],
      ],
      [
        [55115, 85846],
        [-12, -6],
        [-6, 2],
        [7, 27],
        [6, 11],
        [19, 11],
        [5, -2],
        [-19, -43],
      ],
      [
        [55165, 86100],
        [-7, -19],
        [-7, 23],
        [3, 5],
        [4, 23],
        [14, 12],
        [21, -7],
        [0, -6],
        [-20, -19],
        [-8, -12],
      ],
      [
        [58874, 36890],
        [6, -8],
        [25, -27],
        [2, -53],
        [-2, -60],
        [-5, -38],
        [2, -38],
        [8, -59],
        [8, -41],
        [2, -184],
      ],
      [
        [58920, 36382],
        [-9, 9],
        [-16, 7],
        [-8, -3],
        [-8, -83],
        [-6, -122],
        [4, -77],
        [-60, -2],
        [-76, 8],
        [-54, 33],
        [-59, 73],
        [-35, 113],
        [-15, 71],
        [-21, 4],
        [-4, 12],
        [-2, 87],
        [1, 91],
        [4, 25],
        [39, 112],
        [25, 69],
        [15, 68],
        [33, 79],
        [36, 51],
        [13, 7],
        [9, -2],
        [62, -70],
        [65, -66],
        [14, 8],
        [7, 6],
      ],
      [
        [34956, 54930],
        [-23, -41],
        [-25, -58],
        [-34, -100],
        [2, -32],
        [-8, -25],
        [-2, -45],
        [3, -51],
        [8, -33],
        [4, -62],
        [-6, -57],
        [2, -33],
        [7, -52],
        [6, -55],
        [-1, -23],
        [8, -18],
        [8, -18],
        [-3, -49],
        [27, -88],
        [16, -39],
        [23, -37],
        [9, -37],
        [13, -44],
        [8, -5],
        [4, -18],
        [-4, -34],
        [-1, -47],
        [-15, -55],
        [-35, -100],
        [-4, -24],
        [9, -83],
        [-5, -68],
        [-2, -33],
        [-17, -60],
        [-40, -145],
        [-23, -27],
        [-14, -42],
        [-9, 0],
        [-10, -4],
        [-4, -5],
      ],
      [
        [34828, 53183],
        [-12, 0],
        [-10, 19],
        [-2, 22],
        [-5, 25],
        [-12, 8],
        [-24, -9],
        [-7, 6],
        [-14, 27],
        [-11, 30],
        [-3, 28],
        [-8, -3],
        [-18, -25],
        [-12, -6],
        [-9, 7],
        [-11, -2],
        [-28, -27],
        [-16, -7],
        [-11, -27],
        [-76, -13],
        [-20, -7],
        [-45, 48],
        [-12, 15],
        [-6, 3],
        [-5, -3],
        [-5, -11],
        [-8, -60],
        [-6, -16],
        [-12, -14],
        [-12, -24],
        [-2, -23],
        [18, -13],
        [15, -45],
        [16, -36],
        [13, -33],
        [-2, -36],
        [-2, -51],
        [-10, -18],
        [-15, -8],
        [-58, 25],
        [-44, 22],
        [-19, 5],
        [-8, 5],
      ],
      [
        [34310, 52961],
        [-11, 19],
        [-11, 18],
        [-18, 6],
        [-22, 12],
        [-15, 45],
        [-17, 64],
        [-5, 29],
        [-13, 28],
        [-13, 40],
        [-3, 36],
        [-10, 32],
        [-5, 11],
        [-7, 45],
        [-2, 16],
        [-3, 2],
        [-5, 14],
        [-11, 47],
        [-2, 12],
        [-4, 4],
        [-12, 34],
        [-9, 11],
        [-4, 17],
        [1, 47],
        [-5, 23],
        [-2, 43],
        [0, 17],
        [-5, 20],
        [-8, 12],
        [-1, 32],
        [-2, 77],
        [-4, 14],
        [-34, -1],
        [-3, -8],
        [-15, -4],
        [-16, -1],
        [-15, 10],
        [-12, 14],
        [-3, 17],
        [2, 54],
        [-20, 41],
        [-31, 50],
        [-9, 64],
        [-12, 40],
        [-34, 84],
        [-7, 57],
        [0, 41],
        [13, 37],
        [17, 65],
        [6, 60],
        [6, 31],
        [8, 40],
        [8, 53],
        [-6, 32],
        [-10, 32],
        [-3, 23],
        [10, 35],
        [10, 24],
        [11, 4],
        [14, 15],
        [12, 21],
        [17, 5],
        [22, 2],
        [44, 0],
        [22, 9],
        [7, 17],
        [-1, 33],
        [12, 29],
        [11, 13],
        [5, 9],
        [1, 11],
        [-3, 10],
        [-5, 7],
        [-12, 2],
        [-11, 51],
        [7, 22],
        [10, 41],
        [2, 23],
        [15, 37],
      ],
      [
        [34112, 55039],
        [4, -12],
        [11, 67],
        [2, 53],
        [8, 54],
        [14, 63],
        [24, 31],
        [140, -32],
        [64, -30],
        [82, -52],
        [12, -55],
        [0, 55],
        [-4, 56],
        [23, 40],
        [50, 14],
        [75, -19],
        [64, 23],
        [87, -3],
        [133, -45],
        [59, -31],
        [25, -28],
        [4, -50],
        [-2, -64],
        [-10, -62],
        [-21, -82],
      ],
      [
        [58566, 53853],
        [-6, 25],
        [-6, 23],
        [-11, 29],
        [-16, 12],
        [-14, -6],
        [-17, -6],
        [-8, 17],
        [-1, 40],
        [-5, 37],
        [-8, 28],
        [-24, 28],
        [-63, 56],
        [-48, 113],
        [-25, 52],
        [-17, 34],
        [-25, 89],
        [-29, 61],
        [-35, 28],
        [-23, -14],
        [-23, -65],
        [-45, -61],
        [-20, -3],
        [-26, 34],
        [-33, 23],
        [-59, 11],
        [-24, -29],
        [-32, -47],
        [-27, -28],
        [-17, -4],
        [-15, 12],
        [-18, 6],
        [-15, 1],
        [-32, 42],
        [-16, 32],
        [-11, 30],
        [-18, 21],
        [-21, 17],
        [-15, 27],
        [-7, 34],
        [-12, 43],
        [-15, 39],
        [-48, 70],
        [-15, 41],
        [-10, 41],
      ],
      [
        [57611, 54786],
        [-19, 44],
        [-21, 60],
        [-7, 87],
        [-1, 70],
        [-4, 32],
        [-9, 33],
        [-11, 27],
        [-16, 31],
        [-40, 45],
        [-40, 52],
        [-20, 31],
        [-36, 11],
        [-23, 30],
        [-18, 65],
        [-8, 53],
        [-18, 41],
        [-8, 29],
        [-5, 34],
        [15, 104],
        [-21, 37],
        [-32, 47],
        [-23, 52],
        [-14, 48],
        [-41, 63],
        [-90, 95],
        [-51, 60],
        [-29, 54],
        [-24, 53],
        [-3, 22],
        [16, 53],
        [3, 43],
        [-13, 48],
        [-54, 91],
        [-43, 99],
        [-32, 31],
        [-78, 28],
        [-23, 11],
        [-23, 19],
        [-23, 45],
        [-8, 53],
        [11, 84],
        [-7, 26],
        [-13, 7],
      ],
      [
        [56707, 56834],
        [4, 18],
        [14, 41],
        [24, 27],
        [65, 42],
        [3, 16],
        [2, 53],
        [5, 26],
        [22, 73],
        [3, 29],
        [1, 63],
        [3, 29],
        [7, 21],
        [17, 37],
        [6, 22],
        [3, 48],
        [-2, 94],
        [9, 38],
        [41, 86],
        [11, 38],
        [3, 34],
        [0, 35],
        [2, 35],
        [12, 33],
        [11, 11],
        [30, 10],
        [20, -7],
        [143, 59],
        [16, -8],
        [8, -35],
        [-1, -55],
        [3, -27],
        [7, -19],
        [23, -27],
        [15, -44],
        [9, -16],
        [23, -31],
        [106, -253],
        [30, -24],
        [29, 9],
        [57, 52],
        [29, 14],
        [202, -15],
        [22, 8],
        [1, -2],
        [31, -127],
        [15, -29],
        [221, -1],
        [-4, 36],
        [2, 40],
        [26, 52],
        [13, 26],
        [6, 9],
        [34, 38],
        [33, 25],
        [64, 29],
        [24, 46],
        [13, 42],
        [0, 82],
        [9, 14],
        [15, 19],
        [74, 74],
        [13, 16],
        [131, -172],
        [73, -136],
        [5, -7],
        [3, -2],
        [4, 4],
        [4, 7],
        [5, 4],
        [3, 2],
        [32, 1],
        [59, 7],
        [20, 16],
        [119, 243],
        [31, 78],
        [8, 16],
        [17, 55],
        [18, 95],
        [4, 11],
        [131, 227],
        [4, 18],
        [1, 15],
        [-19, 76],
        [-5, 39],
        [0, 60],
        [3, 93],
        [-1, 59],
        [0, 7],
        [-2, 9],
        [-1, 3],
        [-73, 168],
        [185, 2],
        [0, 14],
        [0, 7],
        [0, 7],
        [-4, 20],
        [-2, 26],
        [0, 8],
        [0, 7],
        [1, 14],
        [0, 7],
        [0, 4],
        [0, 3],
        [0, 3],
        [1, 3],
        [132, -4],
        [-1, -47],
        [-16, -112],
        [0, -66],
        [-4, -77],
        [-1, -6],
        [-3, -16],
        [-4, -10],
        [-3, -9],
        [-1, -3],
        [-2, -6],
        [0, -8],
        [28, -427],
        [-1, -11],
        [-1, -7],
        [-8, -27],
        [-1, -9],
        [-1, -6],
        [3, -5],
        [61, -46],
        [3, -3],
        [3, -4],
        [22, -55],
        [120, -202],
        [4, -10],
        [12, -64],
        [2, -10],
        [0, -15],
        [0, -12],
        [-3, -38],
        [1, -17],
        [2, -12],
        [2, -13],
        [0, -4],
        [-1, -3],
        [0, -6],
        [-18, -74],
        [-6, -52],
        [-2, -44],
        [1, -25],
        [2, -17],
        [1, -4],
        [1, -2],
        [1, -2],
        [1, -1],
        [51, 0],
        [1, 0],
      ],
      [
        [59466, 57293],
        [-1, -24],
        [2, -116],
        [2, -102],
        [3, -167],
        [0, -43],
        [-2, -55],
        [-6, -21],
        [-15, -31],
        [-18, -28],
        [-47, -7],
        [-39, 1],
        [-27, 6],
        [-38, 3],
        [-36, -6],
        [-13, -24],
        [-19, -83],
        [-28, -122],
        [-14, -51],
        [-4, -30],
        [4, -18],
        [19, -26],
        [40, -36],
        [46, -21],
        [34, -9],
        [24, -10],
        [18, -12],
        [66, -93],
        [21, -43],
        [12, -38],
        [2, -41],
        [10, -41],
        [38, -81],
        [21, -47],
        [57, -61],
        [22, -68],
        [21, -33],
        [20, -36],
        [11, -53],
        [25, -154],
        [16, -81],
        [17, -66],
        [7, -108],
        [13, -48],
        [14, -58],
        [23, -53],
        [25, -41],
        [4, -11],
      ],
      [
        [59796, 55006],
        [-51, -104],
        [-57, -116],
        [-66, -135],
        [-73, -147],
        [-56, -116],
        [-56, -114],
      ],
      [
        [56707, 56834],
        [-28, 15],
        [-35, 11],
        [-67, 13],
        [-27, 19],
        [-12, 29],
        [4, 73],
        [-7, 16],
        [-11, 13],
        [-7, 32],
        [1, 38],
        [36, 85],
        [7, 45],
        [6, 158],
        [4, 55],
        [-3, 65],
        [-28, 119],
        [-25, 83],
        [-40, 123],
        [-16, 41],
        [-81, 169],
        [-9, 25],
        [-20, 72],
      ],
      [
        [56349, 58133],
        [10, 63],
        [12, 94],
        [1, 43],
        [-5, 45],
        [-21, 33],
        [-18, 4],
        [-8, 17],
        [-16, 25],
        [-15, 19],
        [-14, 37],
        [-10, 52],
        [7, 184],
        [-5, 25],
        [-20, 7],
        [-5, 13],
        [1, 36],
        [-12, 105],
        [-12, 87],
        [7, 48],
        [-18, 66],
        [-33, 28],
        [-31, -9],
        [-33, -13],
        [-20, 4],
        [-14, 12],
        [-10, 24],
        [-5, 28],
        [5, 43],
        [18, 79],
        [23, 64],
        [46, 59],
        [13, 31],
        [7, 35],
        [1, 40],
        [-3, 42],
        [-5, 38],
        [-14, 51],
        [-13, 60],
        [0, 40],
        [6, 29],
        [13, 35],
        [24, 39],
        [6, 8],
        [16, 21],
        [13, 15],
        [34, 42],
        [8, 19],
        [-3, 24],
        [-8, 20],
        [-14, 27],
        [-2, 33],
        [-4, 57],
        [-7, 37],
        [-5, 26],
        [9, 20],
        [14, 28],
        [18, 17],
        [28, 15],
        [11, 19],
        [3, 38],
        [-1, 37],
        [10, 27],
        [14, 57],
        [10, 27],
        [18, 30],
        [19, 38],
        [8, 43],
        [2, 43],
        [-10, 127],
        [21, 54],
        [27, 44],
        [38, -3],
        [60, 9],
        [40, 19],
        [29, -1],
        [66, -24],
        [5, 6],
        [2, 5],
        [3, 34],
        [0, 85],
        [0, 257],
        [0, 257],
        [0, 257],
        [0, 257],
        [0, 257],
        [0, 257],
        [0, 256],
        [0, 257],
      ],
      [
        [56661, 63073],
        [0, 72],
        [0, 72],
        [0, 72],
        [0, 72],
        [68, 0],
        [69, 0],
        [68, 0],
        [69, 0],
        [1, 1],
        [0, 1],
        [1, 1],
        [1, 1],
        [0, 287],
        [0, 287],
        [0, 287],
        [0, 287],
      ],
      [
        [56938, 64513],
        [107, 0],
        [106, 0],
        [106, 0],
        [106, 0],
        [106, 0],
        [106, 0],
        [106, 0],
        [106, 0],
        [106, 0],
        [107, 0],
        [106, 0],
        [106, 0],
        [106, 0],
        [106, 0],
        [106, -1],
        [106, 0],
        [33, 0],
        [14, 5],
        [27, 107],
        [12, 8],
        [17, -6],
        [7, -25],
        [-6, -37],
        [-9, -51],
        [52, 0],
        [91, 0],
        [91, 0],
        [92, 0],
        [91, 0],
        [91, 0],
        [91, 0],
        [91, 0],
        [91, 0],
        [91, 0],
        [92, 0],
        [91, 0],
        [91, 0],
        [91, 0],
        [91, 0],
        [91, 0],
        [91, 1],
      ],
      [
        [60241, 64514],
        [4, -132],
        [12, -105],
        [43, -150],
        [36, -81],
        [13, -44],
        [1, -21],
        [-1, -19],
        [-11, 22],
        [-19, 15],
        [-3, -70],
        [5, -50],
        [4, -94],
        [15, -101],
        [-11, -93],
        [2, -158],
        [19, -190],
        [-4, -120],
        [32, -283],
        [30, -156],
        [17, -39],
        [19, -20],
        [36, -14],
        [53, -79],
        [43, -85],
        [15, -44],
        [20, -48],
        [14, 9],
        [9, 12],
        [13, -39],
        [67, -84],
        [10, -39],
      ],
      [
        [60724, 62214],
        [-24, -38],
        [-28, -66],
        [-7, -26],
        [-3, -16],
        [-3, -20],
        [-8, -19],
        [-16, -27],
        [-6, -12],
        [-4, -18],
        [-9, -12],
        [-11, -1],
        [-9, -8],
        [-14, -12],
        [-20, 6],
        [-21, -12],
        [-8, -14],
        [-16, -13],
        [-17, -3],
        [-5, -4],
        [-16, -22],
        [-19, -30],
        [-23, -19],
        [-8, -6],
        [-10, -21],
        [-16, -103],
        [-12, -27],
        [-19, -3],
        [-26, 0],
        [-22, -9],
        [-30, 12],
        [-14, -2],
        [-4, -22],
        [-6, -89],
        [1, -38],
        [-12, -45],
        [-13, -56],
        [5, -95],
        [2, -94],
        [-24, -142],
        [-4, -32],
        [-24, -113],
        [-13, -41],
        [-31, -210],
        [-13, -65],
        [-26, -68],
        [6, -110],
        [6, -118],
        [6, -110],
        [9, -166],
      ],
      [
        [60145, 60055],
        [-22, -155],
        [1, -84],
        [-16, -124],
        [-12, -58],
        [-11, -34],
        [-10, -35],
        [-17, -78],
        [-14, -102],
        [-7, -105],
        [0, -61],
        [-3, -28],
        [-5, -17],
        [-33, -12],
        [-46, -12],
        [-25, -14],
        [-17, -22],
        [-21, -49],
        [-40, -137],
        [-22, -83],
        [-33, -115],
        [-39, -81],
        [-8, -39],
        [-7, -73],
        [-14, -116],
        [-13, -83],
        [2, -66],
        [-12, -115],
        [2, -56],
        [-14, -31],
        [-18, -30],
        [-13, -7],
        [-27, 34],
        [-20, 34],
        [-8, 9],
        [-18, -21],
        [-21, -32],
        [-25, -75],
        [-19, -75],
        [11, -159],
        [-1, -35],
        [-5, -38],
        [-30, -119],
        [-7, -37],
        [-11, -72],
        [-11, -124],
        [0, -30],
      ],
      [
        [72213, 57390],
        [-17, -6],
        [-14, 38],
        [-3, 17],
        [3, 11],
        [4, 6],
        [5, -2],
        [6, -36],
        [16, -28],
      ],
      [
        [72187, 57056],
        [8, -43],
        [-23, 29],
        [-15, 25],
        [-6, 20],
        [32, -22],
        [4, -9],
      ],
      [
        [72217, 57495],
        [26, -3],
        [29, 2],
        [20, -8],
        [34, -89],
        [93, -159],
        [51, -162],
        [4, -35],
        [7, -30],
        [12, -9],
        [11, -14],
        [50, -156],
        [6, -31],
        [-1, -34],
        [3, -25],
        [14, -13],
        [16, -6],
        [11, -24],
        [14, -124],
        [0, -39],
        [3, -17],
        [64, -193],
        [4, -24],
        [-1, -18],
        [2, -15],
        [12, -34],
        [20, -92],
        [9, -21],
        [12, -81],
        [1, -154],
        [-4, -69],
        [-12, -84],
        [-14, -81],
        [-16, -59],
        [-21, -50],
        [-71, -106],
        [-21, -22],
        [-93, -66],
        [-68, -63],
        [-64, -17],
        [-63, 34],
        [-48, 83],
        [-24, 122],
        [-17, 127],
        [-25, 141],
        [-18, 435],
        [-9, 122],
        [-15, 155],
        [2, 67],
        [10, 64],
        [0, -141],
        [9, -17],
        [7, 18],
        [7, 146],
        [5, 62],
        [25, 161],
        [1, 29],
        [-5, 61],
        [1, 30],
        [38, 113],
        [9, 66],
        [5, 67],
        [-2, 73],
        [-7, 72],
        [31, -23],
        [17, -25],
        [17, -17],
        [14, 9],
        [16, 0],
        [-11, 39],
        [-36, 36],
        [-59, 22],
        [-18, 29],
        [-7, 25],
        [3, 29],
        [5, 11],
      ],
      [
        [50442, 74119],
        [-6, -7],
        [-18, 7],
        [-28, 0],
        [-1, 23],
        [4, 16],
        [5, 17],
        [17, -33],
        [27, -6],
        [0, -17],
      ],
      [
        [50873, 74763],
        [27, -19],
        [28, 17],
        [15, -5],
        [15, -9],
        [3, -37],
        [-13, -41],
        [-18, -41],
        [-16, -45],
        [-13, -52],
        [-25, -31],
        [-23, -18],
        [-48, 38],
        [-28, 10],
        [-8, 14],
        [-7, 58],
        [-12, 18],
        [-19, 8],
        [-16, -14],
        [-21, -31],
        [-12, 31],
        [-17, 5],
        [-7, 19],
        [0, 23],
        [115, 139],
        [33, 31],
        [71, 36],
        [11, -5],
        [-9, -22],
        [0, -9],
        [9, -10],
        [-2, -17],
        [-9, -14],
        [-4, -27],
      ],
      [
        [51192, 74793],
        [-5, -7],
        [-85, 67],
        [-28, 7],
        [-7, 10],
        [1, 35],
        [2, 15],
        [57, 7],
        [46, -24],
        [25, -67],
        [2, -11],
        [-8, -32],
      ],
      [
        [50401, 74261],
        [-10, -35],
        [-42, 12],
        [-10, 15],
        [9, 40],
        [13, 5],
        [0, 28],
        [13, 29],
        [60, 23],
        [14, -20],
        [3, -28],
        [-36, -61],
        [-14, -8],
      ],
      [
        [49501, 76847],
        [1, -20],
        [10, -28],
        [12, -10],
        [24, -14],
        [18, -2],
        [25, -7],
        [17, -16],
        [1, -24],
        [-5, -28],
        [-10, -25],
        [-6, -20],
        [6, -11],
        [9, -9],
        [9, -2],
        [7, 3],
        [5, 15],
        [9, 19],
        [5, 2],
        [0, -10],
        [4, -13],
        [31, -23],
        [67, -41],
        [26, -1],
        [22, -5],
        [6, -17],
        [43, -64],
        [10, 2],
        [19, -1],
        [23, 5],
        [16, 12],
        [11, -2],
        [12, -13],
        [14, -10],
        [19, -21],
        [16, -26],
        [11, -9],
        [67, 18],
        [15, -16],
        [16, 0],
        [18, 4],
        [39, -8],
        [32, 2],
        [3, 7],
        [3, 57],
        [5, 21],
        [7, 5],
        [19, -4],
        [68, -34],
        [28, -21],
        [27, -17],
        [24, -2],
        [15, -11],
        [22, -55],
      ],
      [
        [50396, 76379],
        [-3, -27],
        [1, -10],
        [3, -19],
        [-1, -21],
        [6, -14],
        [10, -2],
        [14, 5],
        [14, 8],
        [26, 23],
        [7, 4],
      ],
      [
        [50473, 76326],
        [43, -27],
        [19, -17],
        [7, -20],
        [9, -19],
        [13, -3],
        [18, 19],
        [29, 20],
        [48, -18],
        [54, -26],
        [23, -3],
        [1, 13],
        [4, 18],
        [9, 8],
        [13, 3],
        [19, 9],
        [21, 16],
        [21, 6],
        [23, -11],
        [28, -10],
        [17, 0],
      ],
      [
        [50892, 76284],
        [7, -36],
        [14, -14],
        [5, -31],
        [-24, -17],
        [-15, -2],
        [-4, -54],
        [6, -16],
        [14, -14],
        [4, -16],
        [3, -80],
        [-28, -48],
        [-40, -54],
        [-193, -173],
        [-46, -84],
        [-17, -19],
        [-143, -53],
        [-101, -57],
        [-48, -20],
        [-60, -98],
        [-28, -40],
        [23, -11],
        [26, -47],
        [-9, -21],
        [-38, -32],
        [-17, -10],
        [-9, 5],
        [-9, -4],
        [-64, -170],
        [-58, -123],
        [-32, -53],
        [-32, -79],
        [-70, -206],
        [-1, -59],
        [35, -204],
        [19, -54],
        [28, -45],
        [53, -38],
        [12, -38],
        [-18, -36],
        [-52, -64],
        [-91, -86],
        [-39, -68],
        [-8, -66],
        [-27, -30],
        [-10, -92],
        [-16, -61],
        [-4, -20],
        [-17, -47],
        [-2, -33],
        [28, -47],
        [-14, -20],
        [-14, -9],
        [-32, -5],
        [-108, -6],
        [-87, -100],
        [-44, -89],
        [-39, -165],
        [-48, -98],
        [-21, -18],
        [-33, 43],
        [-41, 7],
        [-40, -15],
        [-20, -33],
        [-33, -19],
        [-32, 16],
        [-68, 9],
        [-31, -2],
        [-48, -27],
        [-41, 18],
        [-69, 9],
        [-150, -21],
        [-18, -11],
        [-19, -41],
        [-48, -70],
        [-72, -3],
        [-66, -45],
        [-16, -29],
        [-28, -79],
        [-9, -58],
        [-5, -1],
        [-7, 14],
        [-11, -4],
        [-5, -45],
        [-25, -20],
        [-20, -7],
        [-51, 36],
        [-42, 54],
        [-22, 3],
        [-36, 84],
        [-16, 54],
        [-11, 57],
        [2, 22],
        [-3, 19],
        [-32, 23],
        [-8, 53],
        [24, 68],
        [19, 30],
        [12, 8],
        [-29, -3],
        [-21, -44],
        [-27, 71],
        [-109, 138],
        [7, 31],
        [-1, 18],
        [-18, -37],
        [-13, -10],
        [-56, 6],
        [-64, -17],
      ],
      [
        [47942, 73259],
        [-17, 144],
        [-8, 55],
        [-2, 35],
        [17, 82],
        [18, 34],
        [24, 69],
        [30, 58],
        [31, 13],
        [14, 9],
        [11, 44],
        [7, 38],
        [-5, 4],
        [-36, -8],
        [-66, 159],
        [2, 26],
        [8, 38],
        [6, 47],
        [1, 38],
        [17, 32],
        [26, 32],
        [22, 46],
        [11, 46],
        [3, 41],
        [-13, 29],
        [-36, 16],
        [-37, 117],
        [-8, 73],
        [-8, 8],
        [-23, 33],
        [-22, 62],
        [-3, 10],
        [23, 11],
        [93, 1],
        [20, 13],
        [3, 5],
        [17, 49],
        [18, 80],
        [4, 49],
        [-6, 20],
        [-31, 49],
        [-1, 15],
        [5, 23],
        [18, 25],
        [25, 28],
        [14, 25],
        [-3, 19],
        [-8, 20],
        [-1, 18],
        [5, 23],
        [1, 79],
        [4, 20],
        [-5, 71],
        [-6, 58],
        [-20, 75],
        [4, 17],
        [9, 14],
        [29, 26],
        [24, 62],
        [35, 51],
        [45, 41],
        [31, 46],
        [13, 35],
        [9, 10],
        [-3, 16],
        [-6, 24],
        [-18, 23],
        [-23, 13],
        [-26, 0],
        [-16, 4],
        [-4, 19],
        [1, 49],
        [-1, 49],
        [-5, 22],
        [-12, 17],
        [-24, -5],
        [-20, 14],
        [-16, 3],
        [-9, -10],
        [-45, 3],
        [-19, 8],
        [-14, 9],
        [-8, -5],
        [-5, -10],
        [-1, -15],
        [-3, -19],
        [-17, -18],
        [-37, -18],
        [-30, 2],
        [-28, 12],
        [-9, 10],
        [-13, 8],
        [-57, -10],
        [-7, 7],
        [-19, -18],
        [-29, -22],
        [-16, -1],
        [-6, 4],
        [-2, 10],
        [-12, 34],
        [3, 18],
        [23, 53],
        [-2, 12],
        [-10, 17],
        [-8, 25],
        [-3, 12],
        [-15, 2],
        [-15, -13],
        [-60, -26],
        [-14, -9],
        [-26, -26],
        [-27, -39],
      ],
      [
        [47561, 76002],
        [-20, -8],
        [-8, 12],
        [-2, 91],
        [32, 60],
        [22, 37],
        [-10, 7],
        [-24, -1],
        [1, 29],
        [12, 13],
        [11, 31],
        [-13, 14],
        [-10, 20],
        [0, 53],
        [3, 22],
        [-3, 23],
        [-49, -32],
        [-13, 5],
        [0, 40],
        [27, 60],
        [3, 18],
        [-32, 9],
        [-24, 30],
        [-14, 26],
        [-16, 38],
        [0, 34],
        [16, 79],
        [23, 24],
        [20, 14],
        [42, 55],
        [57, -10],
        [36, 11],
        [32, 28],
        [19, 7],
        [29, 24],
        [-1, 33],
        [-10, 25],
        [9, 23],
        [33, 29],
        [37, 37],
        [42, 7],
        [43, 34],
        [29, -22],
        [25, 7],
        [29, -25],
        [38, -58],
        [56, -24],
        [45, 19],
        [78, 3],
        [40, -7],
        [70, 14],
        [40, -5],
        [64, 29],
        [51, -36],
        [97, -17],
        [58, -30],
        [162, -49],
        [59, -1],
        [82, 28],
        [35, 21],
        [32, -13],
        [47, 24],
        [23, -4],
        [29, -35],
        [104, -46],
        [27, 39],
        [20, 9],
        [74, -24],
        [75, -49],
        [39, -3],
        [57, 13],
        [46, 32],
        [9, 4],
      ],
      [
        [45462, 68190],
        [-23, -131],
        [-22, -52],
        [-13, -17],
        [-32, -14],
        [-38, 82],
        [-19, 83],
        [-11, 26],
        [17, 21],
        [25, -3],
        [54, 17],
        [11, 7],
        [55, 84],
        [55, 10],
        [1, -27],
        [-60, -86],
      ],
      [
        [46190, 68497],
        [-19, -38],
        [-21, 13],
        [10, 83],
        [9, 25],
        [39, 36],
        [32, 15],
        [9, 38],
        [11, 15],
        [11, -23],
        [-9, -26],
        [-6, -84],
        [-22, -26],
        [-44, -28],
      ],
      [
        [46056, 68069],
        [-38, -65],
        [-38, 15],
        [-6, 11],
        [38, 16],
        [34, 50],
        [22, 110],
        [35, 121],
        [7, 52],
        [13, 20],
        [19, 2],
        [8, -4],
        [9, -27],
        [0, -61],
        [-10, -102],
        [-18, -89],
        [-75, -49],
      ],
      [
        [45722, 68057],
        [-2, -45],
        [6, -44],
        [-1, -69],
        [-13, -36],
        [-35, -37],
        [-26, 6],
        [-15, 15],
        [-27, 60],
        [-1, 61],
        [25, 41],
        [10, 51],
        [64, -9],
        [6, 9],
        [4, 3],
        [5, -6],
      ],
      [
        [45226, 67984],
        [-11, -5],
        [-14, 15],
        [-14, 45],
        [10, 34],
        [8, 16],
        [13, -3],
        [23, -25],
        [8, -25],
        [0, -16],
        [-23, -36],
      ],
      [
        [45031, 67862],
        [-27, -94],
        [-34, 35],
        [-8, 12],
        [-7, 19],
        [33, 4],
        [33, 47],
        [10, -23],
      ],
      [
        [45046, 68256],
        [-7, -5],
        [-7, 46],
        [-33, 111],
        [20, 50],
        [37, 1],
        [15, -34],
        [5, -36],
        [-7, -21],
        [2, -42],
        [-4, -27],
        [-21, -43],
      ],
      [
        [85175, 73606],
        [9, 11],
        [0, 16],
        [0, 51],
        [25, 36],
        [34, 73],
        [17, 40],
        [20, 38],
        [22, 25],
        [22, 11],
        [35, 5],
        [66, -4],
        [13, 4],
        [46, 4],
        [11, -7],
        [33, -4],
        [38, 5],
        [18, 11],
        [18, 18],
        [15, 33],
        [15, 62],
        [17, 48],
        [10, 9],
      ],
      [
        [85659, 74091],
        [68, -258],
        [64, -166],
        [56, -121],
        [79, -232],
        [23, -124],
        [2, -77],
        [13, -106],
        [-11, -60],
        [3, -96],
        [-5, -49],
        [-9, -36],
        [-1, -70],
        [3, -37],
        [1, -49],
        [6, -19],
        [9, -7],
        [14, 18],
        [18, 7],
        [-3, -59],
        [-22, -150],
        [-18, -109],
        [-25, -95],
        [-32, -87],
        [-38, -34],
        [-27, -13],
        [-51, -4],
        [-43, 15],
        [-36, -11],
        [-15, -18],
        [-11, -31],
        [8, -48],
        [-1, -36],
        [-16, 3],
        [-31, 21],
        [-34, 2],
        [-16, 11],
        [-16, 51],
        [-17, -2],
        [-29, -31],
        [-44, -6],
        [-15, -17],
        [-5, -21],
        [6, -26],
        [22, -35],
        [-7, -36],
        [-23, -18],
        [-19, 44],
        [-12, 43],
        [-13, 2],
        [-20, -12],
        [-4, -46],
        [10, -32],
        [15, -36],
        [-22, -42],
        [-5, -30],
        [-16, -21],
        [-42, 47],
        [6, 34],
        [18, 33],
        [3, 34],
        [-6, 20],
        [-61, -86],
        [-37, -96],
        [-19, 7],
        [-9, 25],
        [-11, 10],
        [-40, -63],
        [-8, -49],
        [-14, -2],
        [-7, 21],
        [0, 45],
        [-7, 37],
        [-41, 56],
        [-19, 48],
        [10, 27],
        [34, -15],
        [28, 2],
        [-6, 23],
        [-9, 11],
        [19, 12],
        [15, 27],
        [-13, 7],
        [-19, -15],
        [-16, 7],
        [-6, 63],
        [-20, 65],
        [-10, 63],
        [19, 36],
        [10, 56],
        [18, 81],
        [9, 27],
        [25, 19],
        [9, 21],
        [-14, 11],
        [-22, 9],
        [1, 24],
        [15, 12],
        [16, 26],
        [32, 32],
        [10, 59],
        [-9, 15],
        [-20, 14],
        [5, 30],
        [8, 23],
        [-3, 14],
        [-24, 38],
        [-16, 36],
        [5, 40],
        [-4, 60],
        [2, 51],
        [-1, 28],
        [-11, 62],
        [-5, 63],
        [-16, -10],
        [-12, -15],
        [-44, 22],
        [-14, 1],
        [-5, 47],
        [15, 57],
        [38, 50],
        [21, 6],
        [16, 22],
        [26, 7],
        [30, -34],
        [27, -7],
        [15, -59],
        [9, -12],
        [2, 22],
        [22, 25],
        [5, 19],
        [-4, 11],
        [-25, 10],
        [-23, 73],
        [-3, 32],
        [-9, 20],
        [13, 59],
        [-26, 67],
        [-13, 21],
        [2, 59],
        [-14, 39],
        [-8, 21],
        [-4, 36],
        [4, 16],
        [12, 6],
        [3, 15],
      ],
      [
        [85761, 71888],
        [-27, -36],
        [-35, 48],
        [-8, 26],
        [26, 39],
        [23, 44],
        [15, 3],
        [6, -124],
      ],
      [
        [85573, 71892],
        [-3, -56],
        [-20, -3],
        [-11, 36],
        [-13, -17],
        [-6, -1],
        [-10, 45],
        [-2, 36],
        [23, 26],
        [14, -16],
        [20, -8],
        [8, -42],
      ],
      [
        [85064, 71641],
        [-18, -9],
        [-10, 20],
        [-7, 6],
        [4, 26],
        [29, 51],
        [6, 17],
        [27, -10],
        [10, -27],
        [-13, -42],
        [-28, -32],
      ],
      [
        [85144, 73580],
        [-2, -76],
        [-15, 4],
        [-10, 7],
        [-5, 15],
        [-10, 71],
        [11, 29],
        [23, -23],
        [8, -27],
      ],
      [
        [85208, 71626],
        [5, -27],
        [-22, 5],
        [-12, 26],
        [1, 23],
        [14, 3],
        [14, -30],
      ],
      [
        [85499, 71782],
        [-3, -18],
        [-14, 27],
        [14, 30],
        [3, -39],
      ],
      [
        [85115, 72864],
        [-4, -38],
        [-18, 25],
        [-5, 82],
        [19, -24],
        [8, -45],
      ],
      [
        [85047, 71849],
        [-4, -14],
        [-12, 4],
        [-12, 40],
        [-5, 31],
        [-13, 18],
        [20, 27],
        [25, -49],
        [1, -57],
      ],
      [
        [86365, 73432],
        [-13, -18],
        [-15, 18],
        [-2, 18],
        [8, 15],
        [18, 10],
        [9, -14],
        [-5, -29],
      ],
      [
        [85090, 70981],
        [-13, -13],
        [-11, 8],
        [-3, 6],
        [-14, 33],
        [-4, 17],
        [10, 32],
        [38, 53],
        [99, 51],
        [18, 2],
        [39, -21],
        [9, -42],
        [-7, -35],
        [-9, -24],
        [-46, -40],
        [-35, -19],
        [-71, -8],
      ],
      [
        [58690, 38938],
        [2, -30],
        [2, -14],
        [13, -80],
        [20, -119],
        [13, -111],
        [18, -151],
        [0, -84],
        [4, -33],
        [16, -41],
        [20, -70],
        [7, -39],
        [7, -30],
        [21, -56],
        [16, -85],
        [14, -113],
        [12, -54],
        [4, -27],
        [5, -49],
        [0, -102],
        [0, -118],
        [0, -133],
        [1, -109],
        [-2, -55],
        [1, -157],
        [-18, -82],
        [2, -64],
        [6, -42],
      ],
      [
        [58920, 36382],
        [24, 4],
        [42, -17],
        [35, 2],
        [31, 2],
        [52, 3],
        [30, 0],
      ],
      [
        [59134, 36376],
        [-10, -133],
        [-40, -208],
        [-13, -95],
        [-34, -341],
        [-44, -172],
        [-25, -71],
        [-72, -126],
        [-20, -25],
        [-18, -17],
        [-31, -14],
        [-123, -254],
        [-46, -123],
        [-41, -178],
        [-40, -98],
        [-60, -210],
        [-53, -161],
        [-51, -147],
        [-88, -203],
        [-39, -59],
        [-27, -26],
        [-70, -118],
        [-99, -189],
        [-75, -168],
        [-113, -190],
        [-65, -84],
        [-99, -164],
        [-27, -24],
        [-111, -152],
        [-79, -93],
        [-129, -107],
        [-51, -30],
        [-122, 28],
        [-51, -15],
        [-43, -65],
        [-4, -93],
        [-18, -14],
        [-27, 4],
        [-85, 39],
        [-46, -7],
        [-27, -50],
        [-22, -63],
        [-64, -3],
        [-115, 65],
        [-135, 40],
        [-31, 4],
        [-65, -48],
        [-23, -7],
        [-95, 10],
        [-53, 31],
        [-51, 0],
        [-38, -26],
        [-47, -8],
        [-127, -175],
        [-66, 0],
        [-56, -21],
        [-28, 1],
        [-53, 24],
        [-19, -1],
        [-30, -11],
        [-30, -31],
        [-68, -13],
        [-26, -27],
        [-115, -159],
        [-26, 6],
        [-22, 11],
        [-59, 1],
        [-68, 86],
        [-26, -6],
        [7, 26],
        [2, 45],
        [-14, 32],
        [-10, 14],
        [-26, -3],
        [-14, 39],
        [-41, 3],
        [-14, -9],
        [-20, -2],
        [-1, 39],
        [1, 24],
        [-1, 38],
        [-5, 46],
        [-16, 15],
        [-12, 6],
        [-28, -3],
        [-20, -5],
        [-10, -14],
        [-10, -33],
        [0, -103],
        [-15, 29],
        [-16, 62],
        [-5, 66],
        [6, 78],
        [31, 29],
        [-3, 53],
        [-6, 45],
        [-35, 117],
        [-13, 54],
        [-29, 36],
        [-23, 87],
        [-23, 32],
        [-9, 61],
        [-22, 49],
        [-8, 77],
        [12, 44],
        [20, 24],
        [20, -38],
        [24, 15],
        [35, 56],
        [21, 85],
        [1, 135],
        [-5, 85],
        [-28, 219],
        [-13, 50],
        [-63, 157],
        [-72, 210],
        [-92, 330],
        [-44, 199],
        [-66, 401],
        [-59, 227],
        [-72, 212],
        [-9, 14],
      ],
      [
        [54568, 35358],
        [11, 26],
        [39, 49],
        [17, 13],
        [10, -6],
        [9, 13],
        [9, 33],
        [2, 31],
        [4, 44],
        [9, 27],
        [9, 52],
        [16, 34],
        [34, 22],
        [26, -30],
        [11, -29],
        [5, -38],
        [11, -18],
        [18, 1],
        [13, -24],
        [8, -48],
        [-2, -35],
        [-10, -22],
        [1, -28],
        [14, -35],
        [5, -34],
        [9, -45],
        [47, -25],
        [23, -15],
        [39, -5],
        [38, -20],
        [35, -34],
        [58, -9],
        [80, 18],
        [66, -8],
        [52, -34],
        [38, -6],
        [24, 21],
        [10, 31],
        [-3, 41],
        [11, 25],
        [26, 11],
        [21, 31],
        [16, 50],
        [37, 40],
        [57, 32],
        [29, -1],
        [0, 81],
        [0, 256],
        [0, 256],
        [0, 257],
        [0, 256],
        [0, 256],
        [0, 256],
        [0, 256],
        [0, 242],
      ],
      [
        [55550, 37570],
        [13, -17],
        [88, -128],
        [24, -68],
        [11, -43],
        [38, -155],
        [28, -140],
        [23, -105],
        [2, -48],
        [3, -47],
        [4, -23],
        [-3, -25],
        [-16, -57],
        [-16, -44],
        [-20, -60],
        [-2, -79],
        [6, -93],
        [12, -46],
        [16, -15],
        [36, 23],
        [23, -7],
        [32, -18],
        [107, 11],
        [13, -6],
        [40, -6],
        [14, 7],
        [12, 20],
        [14, 56],
        [12, 18],
        [23, 9],
        [27, 15],
        [22, 33],
        [35, 110],
        [70, 98],
        [22, 23],
        [14, 26],
        [11, 36],
        [25, 123],
        [19, 103],
        [6, 48],
        [17, 79],
        [20, 50],
        [19, 27],
        [10, 7],
        [26, 13],
        [32, 13],
        [34, -14],
        [37, -31],
        [42, -51],
        [42, -64],
        [20, -32],
        [21, -15],
        [37, -5],
        [25, 1],
        [38, -63],
        [19, -4],
        [44, -19],
        [53, -20],
        [34, 2],
        [36, 34],
        [26, 2],
        [33, -3],
        [37, 10],
        [27, 14],
        [21, 30],
        [18, 33],
        [21, 97],
        [12, 78],
        [19, 89],
        [23, 122],
        [8, 85],
        [8, 23],
        [33, 26],
        [28, 18],
        [74, 33],
        [15, 18],
        [14, 40],
        [32, 68],
        [40, 57],
        [21, 32],
        [37, 275],
        [5, 34],
        [27, 73],
        [17, 31],
        [11, 0],
        [15, 20],
        [20, 37],
        [24, 24],
        [28, 9],
        [18, 25],
        [8, 41],
        [14, 20],
        [21, -1],
        [11, 13],
        [3, 28],
        [12, 24],
        [22, 19],
        [11, 23],
        [1, 27],
        [26, 66],
        [51, 103],
        [47, 58],
        [45, 12],
        [42, 21],
        [40, 32],
        [30, 49],
        [19, 67],
        [32, 38],
        [65, 11],
      ],
      [
        [57982, 34503],
        [45, 36],
        [21, 23],
        [15, 18],
        [19, 28],
        [7, 68],
        [5, 58],
        [15, 28],
        [15, 19],
        [13, 30],
        [15, 72],
        [10, 70],
        [2, 29],
        [-6, 30],
        [-10, 31],
        [-9, 43],
        [-12, 6],
        [-23, 24],
        [-33, 48],
        [-29, 42],
        [-27, 61],
        [-11, 9],
        [-26, 42],
        [-12, 23],
        [-8, 28],
        [-7, 10],
        [-12, -8],
        [-31, -12],
        [-66, -49],
        [-41, -46],
        [-35, -53],
        [-36, -21],
        [-26, -18],
        [-21, -61],
        [-20, -58],
        [-17, -51],
        [-10, -24],
        [-9, -15],
        [-10, -33],
        [-18, -55],
        [-18, -37],
        [-24, -20],
        [-31, -26],
        [-11, -15],
        [-1, -22],
        [11, -52],
        [11, -49],
        [17, -59],
        [13, -42],
        [20, -52],
        [12, -31],
        [-1, -51],
        [4, -18],
        [6, -21],
        [6, -6],
        [6, -7],
        [17, -15],
        [4, -10],
        [12, -17],
        [11, -32],
        [22, -44],
        [24, -33],
        [41, -14],
        [32, -10],
        [11, 6],
        [11, 27],
        [9, 34],
        [3, 43],
        [10, 23],
        [39, 111],
        [21, 41],
        [13, 2],
        [17, 8],
        [21, 4],
        [16, -4],
        [4, 2],
        [25, 14],
      ],
      [
        [60515, 24801],
        [-12, -11],
        [-56, 10],
        [-6, 22],
        [17, 34],
        [9, 14],
        [29, -7],
        [24, -28],
        [4, -9],
        [-9, -25],
      ],
      [
        [61536, 50866],
        [2, 47],
        [-5, 24],
        [-26, 71],
        [-49, 132],
        [-38, 99],
        [-38, 102],
        [0, 82],
        [0, 243],
        [-1, 485],
        [-1, 486],
        [-1, 485],
        [0, 243],
        [-1, 99],
        [4, 16],
        [44, 89],
        [57, 118],
        [76, 224],
        [41, 122],
        [34, 101],
      ],
      [
        [61634, 54134],
        [9, 31],
        [30, 61],
        [56, 37],
        [36, 6],
        [121, 46],
        [18, 19],
        [11, 21],
        [10, 48],
        [23, 68],
        [31, 47],
        [58, 61],
        [56, 52],
        [13, 8],
        [68, 33],
        [17, 11],
        [28, 11],
        [10, 1],
        [95, -12],
        [74, -9],
        [77, -9],
        [8, 7],
        [53, 121],
        [85, 192],
        [54, 123],
        [84, 189],
        [64, 137],
        [72, 151],
        [69, 139],
        [83, 166],
        [52, 105],
        [82, 162],
        [77, 155],
        [69, 137],
      ],
      [
        [63327, 56449],
        [41, 130],
        [40, 127],
        [44, 136],
        [52, 165],
        [49, 154],
        [40, 126],
        [0, 65],
        [0, 140],
        [0, 96],
        [0, 265],
        [0, 161],
        [0, 155],
        [0, 159],
      ],
      [
        [63593, 58328],
        [35, 7],
        [90, 41],
        [71, 63],
        [130, 45],
        [99, 114],
        [17, 55],
        [30, 70],
        [43, 23],
        [111, -82],
        [18, -6],
        [-7, -50],
        [-3, -50],
        [-23, -88],
        [-15, -98],
        [11, -149],
        [5, -242],
        [-3, -35],
        [-7, -34],
        [-3, -28],
        [-12, -9],
        [-5, -16],
        [9, -6],
        [34, 26],
        [0, 29],
        [2, 14],
        [28, -32],
        [21, -13],
        [5, -31],
        [-1, -20],
        [-32, 9],
        [-17, 16],
        [-48, -26],
        [-29, -29],
        [-9, -47],
        [-7, -190],
        [-11, -123],
        [-3, -162],
        [-38, -108],
        [-14, -76],
        [-57, -152],
        [-31, -130],
        [-9, -64],
        [-51, -178],
        [-70, -137],
        [-25, -174],
        [-25, -110],
        [-28, -99],
        [-62, -177],
        [-31, -122],
        [-40, -213],
        [-12, -135],
        [-111, -391],
        [-115, -312],
        [-72, -263],
        [-129, -305],
        [-176, -393],
        [-230, -467],
        [-62, -95],
        [-252, -288],
        [-163, -241],
        [-83, -164],
        [-88, -143],
        [-69, -136],
        [-210, -460],
        [-22, -43],
        [-20, -41],
        [-27, -77],
        [-18, -31],
        [-50, -131],
        [-31, -69],
        [-36, -67],
        [-14, -47],
        [-11, -55],
        [-12, -31],
        [-31, -130],
        [-28, -86],
        [-28, -67],
      ],
      [
        [63327, 56449],
        [-95, 0],
        [-92, 0],
        [-91, 0],
        [-16, 17],
        [-77, 53],
        [-97, 67],
        [-120, 84],
        [-85, 59],
        [-92, 62],
        [-92, 63],
        [-73, 50],
        [-90, 61],
        [-79, 54],
        [-11, 13],
        [-44, 82],
        [-57, 107],
        [-11, 2],
        [-27, 23],
        [-25, 58],
        [-25, 74],
        [-23, 93],
        [-11, 63],
        [-31, 26],
        [-15, 50],
        [-28, 74],
        [-20, 36],
        [-7, 31],
        [-9, 65],
        [-17, 70],
        [-15, 44],
        [-4, 19],
        [1, 12],
        [29, 95],
        [13, 34],
        [15, 33],
        [12, 33],
        [4, 23],
      ],
      [
        [61922, 58179],
        [35, 112],
        [31, 99],
        [24, 77],
      ],
      [
        [62012, 58467],
        [54, -88],
        [53, -180],
        [62, -144],
        [85, -135],
        [33, -45],
        [30, -24],
        [155, 4],
        [109, 122],
        [100, 89],
        [33, 18],
        [58, -24],
        [64, -7],
        [57, -27],
        [29, 7],
        [114, 103],
        [71, 101],
        [48, 42],
        [20, 1],
        [66, -36],
        [85, 15],
        [117, 87],
        [37, 18],
        [28, 1],
        [64, -39],
        [9, 2],
      ],
      [
        [94357, 46942],
        [-13, -8],
        [-20, 21],
        [-9, 19],
        [4, 30],
        [12, 12],
        [13, -20],
        [1, -21],
        [12, -33],
      ],
      [
        [96368, 45123],
        [-24, -9],
        [-10, 2],
        [-16, 50],
        [12, 11],
        [18, -4],
        [5, -29],
        [15, -21],
      ],
      [
        [96147, 45646],
        [-22, -10],
        [-7, 3],
        [-17, -6],
        [-18, -41],
        [-13, 6],
        [-10, -2],
        [-8, 34],
        [0, 17],
        [13, -3],
        [6, 33],
        [14, 17],
        [32, 7],
        [28, -10],
        [10, -8],
        [-9, -30],
        [1, -7],
      ],
      [
        [94604, 45047],
        [-20, -20],
        [-17, 10],
        [-14, 15],
        [-11, 44],
        [-23, 28],
        [-34, 11],
        [-14, 19],
        [-3, 10],
        [-24, 8],
        [-6, 24],
        [2, 25],
        [3, 13],
        [22, -12],
        [103, -117],
        [25, -36],
        [11, -22],
      ],
      [
        [94873, 46298],
        [4, -62],
        [-2, -21],
        [-21, 45],
        [-10, -16],
        [-9, 22],
        [1, 46],
        [1, 50],
        [-4, 38],
        [-11, 55],
        [12, -9],
        [39, -148],
      ],
      [
        [94218, 46587],
        [-4, -1],
        [-13, 7],
        [-16, 2],
        [-9, 20],
        [11, 29],
        [15, 18],
        [6, -4],
        [7, -12],
        [14, -5],
        [2, -37],
        [-13, -17],
      ],
      [
        [94490, 46661],
        [16, -8],
        [8, 1],
        [18, -31],
        [25, -46],
        [-10, -23],
        [-20, 12],
        [-7, -5],
        [-2, 3],
        [-4, 23],
        [-22, 23],
        [-19, 2],
        [-3, 27],
        [20, 22],
      ],
      [
        [93822, 47095],
        [18, -47],
        [20, -105],
        [-4, -36],
        [-14, -3],
        [-4, -22],
        [-20, 51],
        [-26, 14],
        [-19, 31],
        [-6, 62],
        [-2, 39],
        [-15, 7],
        [-42, -10],
        [-14, -34],
        [-19, 11],
        [-4, 30],
        [3, 29],
        [26, 29],
        [5, 38],
        [26, 64],
        [15, 11],
        [31, -23],
        [3, -92],
        [11, -30],
        [31, -14],
      ],
      [
        [93944, 46761],
        [-6, -2],
        [-7, 23],
        [15, 62],
        [8, -50],
        [4, -19],
        [-14, -14],
      ],
      [
        [93918, 46840],
        [-27, -45],
        [-20, 15],
        [-17, 39],
        [6, 47],
        [3, 13],
        [8, 2],
        [8, 10],
        [9, 21],
        [29, -17],
        [8, -11],
        [-18, -29],
        [6, -9],
        [4, -14],
        [1, -22],
      ],
      [
        [93718, 46823],
        [0, -8],
        [-15, 16],
        [-34, 78],
        [6, 26],
        [31, 50],
        [10, 7],
        [8, -31],
        [-7, -46],
        [-10, -12],
        [-5, -43],
        [16, -37],
      ],
      [
        [93523, 47279],
        [-5, -8],
        [-9, 31],
        [-7, 10],
        [0, 34],
        [-28, 57],
        [-2, 39],
        [16, 38],
        [22, -22],
        [22, -48],
        [25, -16],
        [-5, -32],
        [-23, -57],
        [-6, -26],
      ],
      [
        [93500, 47136],
        [-3, -15],
        [-15, 71],
        [1, 36],
        [3, 23],
        [5, 7],
        [12, -79],
        [-3, -43],
      ],
      [
        [93658, 47172],
        [-6, -9],
        [-30, 4],
        [-23, 59],
        [0, 44],
        [18, 40],
        [22, 8],
        [12, -16],
        [11, -34],
        [4, -43],
        [-3, -38],
        [-5, -15],
      ],
      [
        [93288, 47754],
        [-28, -13],
        [-17, 18],
        [7, 44],
        [10, 23],
        [35, -41],
        [-7, -31],
      ],
      [
        [93789, 46797],
        [0, -20],
        [-16, 6],
        [-36, 31],
        [-1, 14],
        [20, 5],
        [15, -4],
        [12, -18],
        [6, -14],
      ],
      [
        [94374, 46501],
        [61, -92],
        [27, 8],
        [80, -2],
        [47, -66],
        [28, -30],
        [16, -59],
        [19, -14],
        [12, -30],
        [7, -55],
        [-5, -9],
        [-24, -20],
        [-18, -9],
        [-47, 20],
        [-44, 42],
        [-89, 5],
        [-41, 12],
        [-14, 17],
        [-13, 21],
        [-21, 51],
        [-17, 61],
        [-2, 35],
        [-2, 68],
        [5, 24],
        [17, 25],
        [18, -3],
      ],
      [
        [94410, 46927],
        [0, -14],
        [-37, 48],
        [-28, 59],
        [-81, 64],
        [-17, 33],
        [-15, 4],
        [-41, 54],
        [-41, 36],
        [-25, 47],
        [-6, 19],
        [-15, 11],
        [-25, 51],
        [-25, 34],
        [-9, 62],
        [-24, 43],
        [-6, 19],
        [77, -35],
        [36, -68],
        [30, -38],
        [11, -28],
        [27, -38],
        [25, -4],
        [24, -38],
        [23, -10],
        [18, -20],
        [114, -172],
        [-14, -46],
        [15, -33],
        [9, -40],
      ],
      [
        [93745, 47620],
        [9, -20],
        [-21, -35],
        [-29, 19],
        [-6, 19],
        [0, 11],
        [-20, -7],
        [-40, 17],
        [-54, 82],
        [-58, 156],
        [-56, 86],
        [-11, 26],
        [-1, 45],
        [8, 17],
        [34, -19],
        [45, -71],
        [74, -73],
        [20, -38],
        [12, -90],
        [13, -27],
        [40, -69],
        [21, -16],
        [11, -3],
        [9, -10],
      ],
      [
        [94652, 47053],
        [69, -171],
        [-3, -31],
        [-9, -19],
        [-3, -58],
        [8, -22],
        [19, -10],
        [32, -62],
        [13, -75],
        [1, -23],
        [14, -34],
        [0, -72],
        [30, -100],
        [3, -48],
        [-3, -22],
        [-12, 13],
        [-37, 113],
        [-41, 49],
        [-5, 21],
        [-42, 66],
        [-28, 111],
        [-30, 198],
        [14, 47],
        [-34, 96],
        [1, 25],
        [15, -6],
        [10, 2],
        [5, 11],
        [13, 1],
      ],
      [
        [94920, 45859],
        [35, -34],
        [20, 6],
        [30, -23],
        [23, 13],
        [15, -30],
        [36, -118],
        [0, -38],
        [24, -27],
        [-20, -5],
        [-28, 14],
        [-22, -10],
        [-22, 23],
        [-38, 12],
        [-33, 27],
        [-69, 87],
        [0, 43],
        [-11, 21],
        [-3, 54],
        [-25, 17],
        [-29, 3],
        [-2, 26],
        [5, 45],
        [21, -1],
        [26, -19],
        [50, -65],
        [12, -12],
        [5, -9],
      ],
      [
        [56147, 79726],
        [-6, -7],
        [-95, -32],
        [-13, 5],
        [-13, 18],
        [-7, 14],
        [-5, 9],
        [-8, 26],
        [-11, 19],
        [-16, 15],
        [-15, 17],
        [-19, 1],
        [-52, -25],
        [-36, -2],
        [-23, 8],
        [-32, 15],
        [-62, 2],
        [-43, -13],
        [-4, -18],
        [-39, -115],
        [-57, -43],
        [-50, -43],
        [-14, -9],
        [-25, 14],
        [-28, 25],
        [-23, 14],
        [-17, -6],
        [-19, -29],
        [-8, -30],
        [-56, -22],
        [-98, -12],
        [-34, -29],
        [-11, -36],
        [-1, -26],
        [9, -23],
        [-11, -27],
        [-4, -11],
        [-69, -6],
        [-92, -8],
        [-55, 2],
        [-52, 2],
        [-35, 23],
        [-43, 45],
        [-45, 59],
        [-5, 2],
        [-6, 6],
        [-29, 4],
        [-7, -3],
      ],
      [
        [54763, 79496],
        [-17, 19],
        [-5, 25],
        [-27, 66],
        [-30, 109],
        [0, 32],
        [11, 35],
        [11, 28],
        [1, 21],
        [2, 6],
      ],
      [
        [54709, 79837],
        [9, 45],
        [21, 60],
        [20, 35],
        [15, 11],
        [30, -10],
        [52, -9],
        [39, 8],
        [37, 27],
        [20, 23],
        [18, 25],
        [5, 16],
        [8, 7],
        [31, 14],
        [9, 17],
        [4, 31],
        [3, 35],
        [6, 26],
        [8, 19],
        [57, 45],
        [5, 16],
        [9, 16],
        [17, 17],
        [16, 25],
        [17, 15],
        [22, -1],
        [21, 3],
        [16, 9],
        [7, 1],
      ],
      [
        [55231, 80363],
        [29, -8],
        [5, -28],
        [3, -30],
        [51, 2],
        [28, 64],
        [14, 8],
        [24, 22],
        [15, 20],
        [10, -13],
        [16, -41],
        [16, -33],
        [9, -13],
        [1, -10],
        [10, -6],
        [18, -4],
        [12, -10],
        [4, -31],
        [0, -28],
        [-6, -20],
        [-3, -18],
        [13, -7],
        [19, 7],
        [13, 10],
        [39, -23],
        [14, 51],
        [15, 27],
        [21, 12],
        [18, 16],
        [17, 11],
        [11, 0],
        [5, 4],
        [15, -1],
        [16, -5],
        [23, 6],
        [31, -13],
        [20, -23],
        [19, -8],
        [22, 1],
        [15, 13],
        [22, 45],
        [16, 0],
        [24, 7],
        [35, -1],
        [80, -9],
        [21, -18],
        [49, -22],
        [22, -25],
        [9, -31],
        [5, -21],
        [51, -32],
        [75, -42],
        [18, -5],
      ],
      [
        [54587, 78628],
        [-24, 14],
        [-30, 6],
        [-5, -7],
        [-12, -8],
        [-6, -14],
        [4, -55],
        [-7, -9],
        [-34, 5],
        [-11, -6],
        [-18, -38],
        [-19, -16],
        [-23, -11],
        [-18, -14],
        [-22, -12],
        [-19, -7],
        [-8, -17],
        [-4, -18],
        [1, -18],
        [19, -35],
        [3, -37],
        [-2, -46],
        [-5, -24],
        [-7, -16],
        [-48, -21],
        [-49, -38],
        [-1, -8],
        [22, -34],
        [1, -8],
        [-18, -19],
        [-2, -19],
        [2, -22],
        [10, -22],
        [3, -21],
        [-27, -14],
        [-36, 5],
        [-43, 28],
        [-16, -4],
        [-14, -15],
        [-15, 7],
        [-17, 17],
        [-23, 36],
        [-12, 23],
        [-4, 23],
        [-7, 4],
        [-9, -7],
        [-8, -29],
        [-22, -51],
        [-16, -14],
        [-24, 3],
        [-34, -1],
        [-21, -5],
        [-26, 19],
        [-6, -4],
        [0, -12],
        [-10, -19],
        [-15, -12],
        [-74, 28],
        [-10, 23],
      ],
      [
        [53771, 78062],
        [17, 11],
        [23, 30],
      ],
      [
        [53811, 78103],
        [15, -4],
        [19, 7],
        [9, 12],
        [-13, 38],
        [-30, 47],
        [-16, 17],
        [-22, 12],
        [-4, 13],
        [12, 73],
        [-4, 11],
        [-25, -4],
        [-6, 8],
        [-2, 13],
        [2, 17],
        [17, 29],
        [19, 25],
        [5, 14],
        [-1, 11],
        [-24, 12],
        [-15, 11],
        [-11, 4],
        [-8, -6],
        [-6, 7],
        [-6, 21],
        [6, 32],
        [21, 30],
        [24, 26],
        [21, 20],
        [11, 8],
        [6, 33],
      ],
      [
        [53805, 78640],
        [12, -3],
        [25, -2],
        [27, -8],
        [25, -9],
        [22, -12],
        [47, -12],
        [42, -7],
        [13, -7],
        [10, 1],
        [13, -10],
        [8, 7],
        [5, 13],
        [23, 16],
        [22, 21],
        [14, 26],
        [9, 21],
        [14, 14],
        [16, 5],
        [14, 7],
        [60, 10],
        [62, -8],
        [30, 14],
        [24, 26],
        [35, 7],
        [2, 0],
        [53, -19],
        [4, 11],
        [2, 5],
        [-1, 55],
        [17, 25],
        [16, 11],
      ],
      [
        [54470, 78838],
        [53, -4],
        [7, -17],
        [2, -26],
        [5, -35],
        [9, -10],
        [5, -14],
        [-1, -24],
        [10, -18],
        [24, -49],
        [3, -13],
      ],
      [
        [78880, 52610],
        [-42, -38],
        [-47, 34],
        [15, 57],
        [32, 13],
        [25, -18],
        [14, -13],
        [10, -16],
        [-7, -19],
      ],
      [
        [47143, 56730],
        [-1, -17],
        [-8, -83],
        [-12, -71],
        [-8, -17],
        [-36, -19],
        [-15, -31],
        [-13, -101],
        [-8, -79],
        [-12, -13],
        [-52, -114],
        [-34, -43],
        [-24, -38],
        [-22, -48],
        [-28, -47],
        [-31, -80],
        [-21, -82],
        [-15, -26],
      ],
      [
        [46803, 55821],
        [-11, 23],
        [-52, 82],
        [-54, 55],
        [-116, 91],
        [-39, 25],
        [2, 33],
        [13, 59],
        [-22, 69],
        [9, 51],
        [-9, 0],
        [-16, -31],
        [-36, 9],
        [-23, 44],
        [-19, 15],
        [-9, 22],
        [-12, 115],
        [-9, 52],
        [-17, 32],
        [-36, 8],
        [-15, 69],
        [-19, 54],
        [3, 34],
        [16, -2],
        [13, -24],
        [20, -10],
        [25, 58],
        [23, 32],
        [5, 28],
        [-3, 15],
        [-14, -24],
        [-37, 6],
        [-9, -21],
        [-17, -7],
        [-13, 69],
        [1, 40],
        [5, 45],
        [38, 7],
        [3, 14],
        [-26, 10],
        [-33, 52],
        [-6, 35],
      ],
      [
        [46307, 57055],
        [16, 12],
        [16, -5],
        [13, -8],
        [15, 13],
        [14, 20],
        [8, 25],
        [11, 67],
        [35, 22],
        [21, 41],
        [20, 64],
        [9, 45],
        [8, 22],
        [6, 19],
        [3, 22],
        [9, 19],
        [10, 47],
        [6, 44],
        [20, 20],
        [42, 19],
        [38, -32],
        [61, 27],
        [3, 41],
        [56, 1],
        [66, 0],
        [55, 1],
        [19, -11],
        [7, -30],
        [18, -47],
        [19, -33],
        [23, -72],
        [28, -84],
        [29, -75],
        [19, -41],
        [2, -14],
        [-1, -17],
        [-10, -38],
        [-8, -42],
        [1, -15],
        [6, -8],
        [31, -13],
        [2, -46],
        [1, -64],
        [14, -60],
        [15, -44],
        [-1, -16],
        [-35, -75],
        [-13, -74],
        [-7, -21],
        [-3, -17],
        [7, -8],
        [9, 5],
        [14, -6],
        [13, -2],
        [17, 27],
        [28, 68],
        [10, 8],
        [21, -6],
      ],
      [
        [46520, 56126],
        [-4, -15],
        [-18, 37],
        [-96, 56],
        [27, 29],
        [66, 9],
        [20, -17],
        [9, -15],
        [3, -27],
        [-7, -57],
      ],
      [
        [65427, 49139],
        [1, -53],
        [-13, 18],
        [-4, 34],
        [-18, 26],
        [-9, 24],
        [20, 29],
        [23, -78],
      ],
      [
        [56306, 77325],
        [-10, -10],
        [-11, -15],
        [-7, -26],
        [-1, -42],
        [-36, -33],
        [-14, -7],
        [-6, -21],
        [-9, -62],
        [1, -47],
        [5, -23],
        [2, -20],
        [12, -23],
        [10, -37],
        [7, -48],
        [16, -37],
        [39, -37],
        [20, -21],
        [14, -31],
        [11, -27],
        [33, -38],
        [-3, -26],
        [-7, -26],
        [-7, -12],
        [-16, -33],
        [-16, -19],
        [-26, -59],
        [-41, -3],
        [-10, -5],
        [-16, -16],
        [-7, -29],
        [7, -23],
        [-1, -24],
        [-7, -46],
        [10, -50],
        [14, -23],
        [2, -13],
        [-2, -23],
        [-22, -47],
        [-6, -17],
        [-22, -9],
      ],
      [
        [56206, 76217],
        [-7, 4],
        [-11, 17],
        [-11, 4],
        [-26, -19],
        [-26, -11],
        [-21, 9],
        [-20, 1],
        [-14, -8],
        [-11, -3],
        [-21, -21],
        [-33, -14],
        [-16, 3],
      ],
      [
        [55989, 76179],
        [-6, 19],
        [-6, 27],
        [3, 13],
        [22, 21],
        [3, 21],
        [31, 99],
        [6, 32],
        [0, 11],
        [-8, 7],
        [-17, 0],
        [-76, 40],
        [4, 46],
        [-22, 25],
        [-24, 22],
        [-4, 25],
        [-27, 50],
        [-19, 28],
        [-25, 14],
        [-22, 21],
        [-12, 12],
        [-6, 23],
        [0, 14],
        [-7, 14],
        [-10, -2],
        [-17, -18],
        [-22, -16],
        [-4, -12],
        [8, -28],
        [6, -17],
        [-3, -17],
        [-7, -21],
        [-41, -47],
        [-5, -16],
        [8, -26],
        [-5, -13],
        [-34, -17],
      ],
      [
        [55651, 76513],
        [1, 14],
        [-3, 23],
        [-19, 25],
        [-28, 19],
        [-63, 65],
        [-23, 9],
        [-22, 7],
        [-30, 32],
        [-16, 5],
        [-18, 22],
        [-38, 76],
        [-32, 41],
        [-22, 20],
        [-6, 21],
        [-2, 20],
        [1, 7],
      ],
      [
        [55331, 76919],
        [17, 30],
        [13, 4],
        [16, 1],
        [11, -15],
        [15, -3],
        [8, 19],
        [4, 27],
        [-2, 35],
        [-35, 82],
        [-29, 57],
        [-4, 12],
        [7, 11],
        [10, 5],
        [11, -4],
        [29, -4],
        [28, 5],
        [9, 14],
        [0, 18],
        [-10, 18],
        [-32, 46],
        [-26, 41],
        [-29, 32],
        [-23, 12],
        [-6, 16],
        [-3, 18],
        [3, 31],
        [1, 40],
        [5, 25],
        [20, 47],
        [19, 51],
        [12, 48],
        [6, 45],
        [-2, 13],
        [-10, 9],
        [-21, 10],
        [-29, -9],
        [-25, -16],
        [-10, -1],
      ],
      [
        [55279, 77689],
        [-3, 20],
        [4, 9],
        [8, -1],
        [6, -4],
        [7, 9],
        [4, 27],
        [-10, 94],
        [18, 9],
        [1, 13],
        [1, 12],
        [19, -16],
        [27, 0],
        [24, 3],
        [4, 9],
        [-1, 14],
        [-5, 10],
        [-8, 9],
        [-6, 13],
        [-16, 5],
        [-50, 34],
        [-24, 36],
        [0, 39],
        [7, 21],
        [9, 7],
        [-2, 7],
        [-29, 18],
        [-10, 24],
        [9, 32],
        [-15, 64],
        [-15, 40],
        [15, 17],
        [2, 24],
        [1, 14],
      ],
      [
        [55251, 78301],
        [6, 0],
        [25, 16],
        [8, 13],
        [6, 16],
        [5, 4],
        [17, -17],
        [17, -2],
        [19, 11],
        [15, 15],
        [17, 12],
        [8, 8],
        [10, 14],
        [21, 39],
        [23, 8],
        [30, -10],
        [34, -4],
        [25, 9],
        [63, -11],
        [13, -9],
        [9, -10],
      ],
      [
        [55622, 78403],
        [17, -34],
        [16, -43],
        [22, -20],
        [26, -24],
        [13, -17],
        [20, -52],
        [16, -25],
        [5, 1],
        [5, 7],
        [4, 5],
        [4, -5],
        [0, -16],
        [1, -34],
        [-3, -38],
        [5, -35],
        [0, -11],
        [-3, -10],
        [0, -9],
        [6, -9],
        [21, -23],
        [20, -36],
        [22, -26],
        [22, -16],
        [13, -1],
        [22, -29],
        [43, -21],
        [14, -7],
        [9, -12],
        [7, -14],
        [0, -14],
        [-6, -7],
        [-10, -20],
        [-3, -25],
        [-7, -6],
        [-7, 0],
        [-5, -8],
        [1, -10],
        [6, -10],
        [9, -9],
        [17, -9],
        [17, -14],
        [0, -10],
        [-4, -11],
        [-21, -5],
        [-16, -2],
        [-8, -4],
        [1, -21],
        [45, -21],
        [21, -20],
        [10, -26],
        [29, -17],
        [47, -8],
        [33, -27],
        [18, -45],
        [30, 11],
        [42, 67],
        [41, 17],
        [40, -32],
        [22, -26],
        [4, -20],
        [-10, -9],
        [-22, 4],
        [-19, -13],
        [-14, -29],
        [-2, -31],
        [10, -33],
        [14, -23],
        [19, -13],
        [9, -17],
        [2, -22],
        [4, -6],
      ],
      [
        [46588, 60373],
        [26, -93],
        [-5, -44],
        [-6, -65],
        [15, -47],
        [17, -31],
        [12, -28],
        [14, -39],
        [2, -77],
        [-2, -56],
        [9, -26],
        [7, -32],
        [-1, -26],
        [-5, -24],
        [-17, -31],
        [-3, -58],
        [27, -71],
        [17, -38],
        [0, -22],
        [5, -24],
        [13, -28],
        [8, 7],
        [8, 23],
        [4, 15],
        [24, -7],
        [11, -7],
        [14, -46],
        [6, -30],
        [4, -39],
        [15, -48],
        [14, -34],
        [2, -21],
        [13, -28],
        [-8, -64],
        [1, -32],
        [-8, -85],
        [-2, -41],
        [0, -15],
        [19, -30],
        [-2, -43],
      ],
      [
        [46836, 58988],
        [-19, 8],
        [-32, 5],
        [-65, -23],
        [-23, 9],
        [-43, -3],
        [-30, -12],
        [-39, -28],
        [-30, 7],
        [-16, 22],
        [-21, -1],
        [-24, 11],
        [-26, 22],
        [-23, 10],
        [-26, 40],
        [-11, 7],
        [-9, -11],
        [-7, -13],
        [-7, -8],
        [-14, 7],
        [-5, 27],
        [5, 26],
        [1, 19],
        [-7, 11],
        [-15, 4],
        [-25, -1],
        [-40, 9],
        [-9, 5],
        [-90, 6],
      ],
      [
        [46186, 59143],
        [-93, 1],
        [-79, 1],
        [-100, 1],
        [-70, 0],
        [-66, 1],
        [-50, -53],
        [-55, -56],
        [-73, -31],
        [-85, 12],
        [-27, -9],
        [-28, -25],
        [-21, -18],
        [-29, -11],
        [-37, 9],
        [-16, -6],
      ],
      [
        [45357, 58959],
        [-9, 26],
        [-11, 42],
        [7, 31],
        [23, 20],
        [34, 26],
        [19, -14],
        [10, 0],
        [2, 16],
        [-3, 9],
        [-26, 22],
        [-14, 30],
        [-11, -17],
        [-10, -37],
        [-8, -10],
        [-12, -11],
        [-6, 25],
        [-3, 24],
        [5, 19],
        [-2, 104],
        [3, 55],
        [-2, 49],
      ],
      [
        [45343, 59368],
        [16, 32],
        [16, 20],
        [60, 2],
        [57, 1],
        [54, -1],
        [55, -1],
        [6, 97],
        [17, 8],
        [26, 10],
        [49, 12],
        [54, 11],
        [12, 19],
        [9, 33],
        [6, 28],
        [11, 13],
        [15, -10],
        [20, -15],
        [21, -24],
        [23, -21],
        [16, -14],
        [38, -34],
        [65, -48],
        [53, -19],
        [65, 35],
        [46, 22],
        [6, 42],
        [-7, 41],
        [-35, 37],
        [-47, -4],
        [-15, -10],
        [-22, -13],
        [-13, -4],
        [-22, 8],
        [-28, 33],
        [-18, 32],
        [-25, 15],
        [-29, 16],
        [-47, 66],
        [-25, 12],
        [-23, 4],
        [-45, -13],
        [-44, -36],
        [-23, -81],
        [-43, 1],
        [-93, 3],
        [-86, 2],
        [-70, -6],
      ],
      [
        [45399, 59669],
        [-7, 59],
        [-17, 47],
        [-27, 40],
        [-6, 37],
        [9, 33],
        [26, 26],
        [6, 19],
        [-13, -2],
        [-21, -18],
        [-14, 0],
        [-1, 51],
        [-23, 66],
        [-26, 112],
        [-30, 46],
        [-24, 91],
        [-26, 35],
        [-24, 16],
        [-20, -3],
        [-7, -42],
        [-25, 60],
        [34, 21],
        [73, 75],
        [85, 215],
        [76, 253],
        [9, 60],
      ],
      [
        [45406, 60966],
        [10, 46],
        [6, 103],
        [11, 62],
        [10, 12],
        [13, 47],
        [15, 83],
        [18, 46],
        [19, 9],
        [16, -4],
        [11, -17],
        [31, -10],
        [53, -4],
        [41, 12],
        [29, 29],
        [38, 14],
        [47, 1],
        [25, 12],
        [3, 24],
        [6, 7],
        [10, -10],
        [9, 4],
        [8, 17],
        [9, 1],
        [9, -14],
        [39, -5],
        [70, 6],
        [65, -44],
        [60, -93],
        [31, -62],
        [1, -31],
        [10, -31],
        [18, -31],
        [16, -6],
        [15, 20],
        [12, -3],
        [8, -24],
        [17, -5],
        [19, 15],
        [14, -5],
        [2, -14],
        [3, -8],
        [9, -3],
        [13, -19],
        [17, -49],
        [14, -69],
        [11, -88],
        [14, -48],
        [18, -8],
        [10, -18],
        [2, -22],
        [5, -14],
        [9, -8],
        [15, 5],
        [18, -30],
        [19, -65],
        [3, -29],
        [-3, -16],
        [1, -11],
        [12, -11],
        [12, -21],
        [10, -32],
        [21, -29],
        [32, -24],
        [23, -37],
        [15, -50],
        [29, -41],
        [6, -5],
      ],
      [
        [60250, 66464],
        [-7, 0],
        [-20, 39],
        [-11, 29],
        [-12, 19],
        [-53, 39],
        [-8, 25],
        [9, 25],
        [5, -25],
        [10, -14],
        [44, -36],
        [49, -76],
        [9, -7],
        [-15, -18],
      ],
      [
        [61663, 61471],
        [21, -3],
        [-9, 19],
        [-2, 9],
        [10, 26],
        [30, -55],
        [-1, -64],
        [-2, -15],
        [-8, 14],
        [-6, 13],
        [-2, 15],
        [-8, 16],
        [-30, -10],
        [-18, 17],
        [-27, 55],
        [-7, 39],
        [11, 8],
        [12, 19],
        [7, 31],
        [-7, 32],
        [16, -5],
        [9, -33],
        [1, -75],
        [3, -16],
        [-5, -17],
        [12, -20],
      ],
      [
        [61888, 61273],
        [-3, 46],
        [-16, 68],
        [-1, 49],
        [-8, 48],
        [-14, 37],
        [-26, 38],
        [-3, 53],
        [-19, 52],
        [-25, 42],
        [-15, 77],
        [-10, 103],
        [-67, 135],
        [-84, 124],
        [-26, 71],
        [-42, 143],
        [-21, 113],
        [-56, 130],
        [-2, 50],
        [-9, 61],
        [-13, 68],
        [-7, 54],
        [-57, 235],
        [-18, 37],
        [-16, 53],
        [-4, 40],
        [-5, 22],
        [-39, 39],
        [-38, 99],
        [-111, 157],
        [-55, 15],
        [-43, 56],
        [-32, 74],
        [-34, 126],
        [-60, 136],
        [-50, 194],
        [16, 71],
        [-1, 49],
        [-16, 84],
        [-17, 64],
        [-12, 61],
        [10, 88],
        [3, 98],
        [10, 52],
        [7, 57],
        [-9, 115],
        [-17, 61],
        [2, 41],
        [-19, 20],
        [-16, 45],
        [16, -1],
        [-29, 62],
        [-11, 34],
        [-11, 84],
        [-14, 64],
        [-45, 146],
        [-22, 89],
        [-49, 114],
        [-53, 85],
        [-33, 38],
        [-16, 35],
        [-28, 2],
        [-30, 50],
        [-21, 1],
        [-26, 8],
        [-31, 97],
        [-26, 90],
        [-44, 118],
        [11, 31],
        [13, 50],
        [-6, 65],
        [-7, 44],
        [-19, 81],
        [-64, 202],
        [-17, 29],
        [-27, 34],
        [-16, 88],
        [-8, 78],
        [-44, 38],
        [-74, 282],
        [-44, 99],
        [-17, 66],
        [-50, 109],
        [-24, 109],
        [-51, 100],
        [-44, 173],
        [-67, 174],
        [-29, 30],
        [-69, 12],
        [-30, 13],
        [-27, -38],
        [-2, 48],
        [19, 67],
        [26, 140],
        [6, 123],
        [42, 364],
      ],
      [
        [59708, 68751],
        [59, -18],
        [49, -16],
        [71, -23],
        [74, -23],
        [43, -14],
        [14, 6],
        [60, 89],
        [54, 81],
        [32, 98],
        [31, 96],
        [14, 20],
        [48, 17],
        [76, 29],
        [75, 28],
        [5, 9],
        [18, 77],
        [22, 97],
        [5, 10],
        [5, 10],
        [54, 55],
        [32, 33],
        [-46, 97],
        [-44, 92],
        [-49, 103],
        [-41, 80],
        [-63, 120],
        [-40, 79],
        [71, 37],
        [77, 40],
        [78, 41],
        [94, 49],
        [73, 38],
        [110, 57],
        [53, 28],
        [10, 7],
        [41, 68],
      ],
      [
        [60873, 70348],
        [62, -19],
        [93, -29],
        [90, -27],
        [95, -32],
        [31, -27],
        [91, -96],
        [60, -63],
        [69, -74],
        [87, -91],
        [59, -63],
        [77, -81],
        [59, -92],
        [76, -117],
        [82, -128],
        [68, -100],
        [94, -137],
        [93, -135],
        [90, -133],
        [73, -106],
        [91, -134],
        [8, -5],
        [92, -15],
        [125, -21],
        [125, -20],
        [113, -19],
        [49, 19],
      ],
      [
        [62925, 68603],
        [53, -12],
        [72, -17],
        [43, -11],
        [82, -21],
        [25, -88],
        [9, -61],
        [8, -60],
        [24, -54],
        [56, 1],
        [49, 1],
        [61, 2],
        [49, 1],
      ],
      [
        [63456, 68284],
        [15, -54],
        [7, -54],
        [29, -128],
        [41, -100],
        [9, -36],
        [7, -55],
        [-7, -21],
        [-3, -23],
        [30, -55],
        [51, -46],
        [19, -12],
        [22, -21],
        [-17, -31],
        [30, -74],
        [34, -74],
        [37, -17],
        [50, -113],
        [74, -73],
        [46, -96],
        [-4, -2],
        [-14, 10],
        [-16, 13],
        [-5, -12],
        [0, -40],
        [5, -47],
        [23, -41],
        [21, -29],
        [8, -56],
        [-17, -120],
        [-5, 1],
        [-11, 10],
        [-12, 2],
        [-6, -7],
        [14, -86],
        [13, -66],
        [17, -52],
        [14, -77],
        [11, -32],
        [49, -82],
        [14, -68],
        [14, -127],
        [30, -70],
        [17, -55],
        [22, -46],
      ],
      [
        [64112, 66122],
        [14, -63],
        [20, -49],
        [11, -12],
        [15, -5],
        [20, 0],
        [23, 12],
        [25, 12],
      ],
      [
        [64240, 66017],
        [20, -24],
        [20, 3],
        [2, -23],
        [-13, -31],
        [-17, -78],
        [24, -13],
        [22, -6],
        [17, -13],
        [9, 0],
      ],
      [
        [65329, 64921],
        [20, -65],
        [17, -54],
        [23, -75],
        [25, -79],
        [24, -75],
        [17, -57],
        [-9, -58],
        [-10, -64],
        [-10, -63],
        [-11, -64],
        [-10, -64],
        [-10, -64],
        [-10, -64],
        [-10, -63],
        [-11, -64],
        [-10, -64],
        [-10, -64],
        [-10, -63],
        [-10, -64],
        [-10, -64],
        [-11, -64],
        [-10, -63],
        [-10, -64],
        [-12, -77],
        [-30, -20],
        [-47, -33],
        [-48, -33],
        [-48, -33],
        [-48, -34],
        [-48, -33],
        [-47, -33],
        [-48, -33],
        [-48, -33],
        [-48, -33],
        [-48, -33],
        [-47, -33],
        [-48, -33],
        [-48, -33],
        [-48, -33],
        [-47, -33],
        [-48, -33],
        [-39, -27],
      ],
      [
        [60165, 66654],
        [-3, -8],
        [-12, 21],
        [1, 44],
        [10, 25],
        [-1, -34],
        [5, -35],
        [0, -13],
      ],
      [
        [52062, 52746],
        [-11, -15],
        [-12, 12],
        [-3, 23],
        [16, 44],
        [7, 11],
        [6, -9],
        [4, -12],
        [1, -18],
        [-8, -36],
      ],
      [
        [51849, 51912],
        [-28, -42],
        [-10, 11],
        [-7, 29],
        [-8, 64],
        [3, 30],
        [13, 35],
        [28, 34],
        [17, 3],
        [17, -46],
        [0, -47],
        [-25, -71],
      ],
      [
        [53468, 77131],
        [-17, -4],
        [-8, 24],
        [12, 27],
        [18, 4],
        [3, -21],
        [-8, -30],
      ],
      [
        [2130, 44086],
        [31, -54],
        [12, -72],
        [-13, -69],
        [-30, 17],
        [-42, -15],
        [-15, 5],
        [-34, 85],
        [-23, 38],
        [-10, 35],
        [30, -4],
        [44, 24],
        [50, 10],
      ],
      [
        [2374, 43751],
        [-76, 0],
        [-38, 26],
        [-13, 0],
        [-33, 55],
        [-5, 28],
        [17, 19],
        [36, 10],
        [70, -41],
        [11, -37],
        [16, -4],
        [13, -16],
        [3, -26],
        [-1, -14],
      ],
      [
        [33007, 59422],
        [-8, -9],
        [-21, 39],
        [3, 45],
        [12, 25],
        [12, 14],
        [12, 2],
        [4, -38],
        [-3, -52],
        [-11, -26],
      ],
      [
        [32992, 59328],
        [-4, -6],
        [2, 0],
        [-2, -5],
        [-1, 5],
        [-3, 2],
        [-2, 1],
        [-4, 0],
        [0, 5],
        [4, -3],
        [7, 3],
        [0, 4],
        [-2, 4],
        [-1, 3],
        [2, 5],
        [8, 10],
        [3, 5],
        [1, -2],
        [0, -2],
        [0, -2],
        [1, -2],
        [-4, -8],
        [-5, -17],
      ],
      [
        [32962, 59156],
        [-2, -1],
        [-3, 2],
        [1, 2],
        [3, 1],
        [1, 4],
        [0, 5],
        [0, 6],
        [2, 3],
        [2, 1],
        [1, -2],
        [1, -6],
        [-2, -4],
        [-1, -3],
        [-1, -5],
        [-2, -3],
      ],
      [
        [33084, 59805],
        [-15, -61],
        [-31, 38],
        [-3, 48],
        [3, 29],
        [18, 54],
        [15, 36],
        [10, 12],
        [6, -47],
        [-3, -109],
      ],
      [
        [32630, 61705],
        [-14, -12],
        [-12, 17],
        [3, 40],
        [11, 1],
        [11, -18],
        [1, -28],
      ],
      [
        [32602, 61773],
        [-7, -8],
        [-13, 35],
        [-20, 10],
        [-18, 21],
        [0, 4],
        [0, 11],
        [4, 12],
        [9, 9],
        [22, -28],
        [11, -36],
        [10, -17],
        [2, -13],
      ],
      [
        [58487, 50460],
        [-7, 3],
        [-13, 11],
        [-21, 36],
        [-38, -20],
        [-10, 1],
        [-14, -18],
        [-12, -21],
        [-7, -1],
        [-7, 3],
        [-33, 42],
        [-12, -1],
        [-5, -120],
        [-5, -67],
        [-7, -30],
        [-23, -29],
        [-24, -16],
        [-13, 1],
        [-52, -9],
        [-21, 0],
        [-11, 10],
        [-15, 68],
        [-27, 30],
        [-27, 14],
        [-10, -4],
        [-10, -35],
        [-4, -32],
      ],
      [
        [58059, 50276],
        [-26, 22],
        [-7, 27],
        [-1, 46],
        [-10, 62],
        [6, 27],
        [10, 17],
        [21, 33],
        [33, 46],
        [7, 22],
        [4, 37],
        [-2, 84],
        [-3, 72],
        [4, 26],
        [15, 55],
        [19, 57],
        [24, 59],
        [14, 6],
        [18, 23],
        [19, 34],
        [11, 12],
      ],
      [
        [90522, 76877],
        [4, -3],
        [5, 0],
        [5, 1],
        [2, -6],
        [1, -11],
        [-3, -1],
        [-7, -2],
        [-4, -1],
        [-2, 8],
        [-1, 6],
        [-4, 4],
        [0, 4],
        [4, 1],
      ],
      [
        [90654, 76972],
        [-7, -3],
        [-12, 3],
        [-4, 3],
        [2, 5],
        [8, 8],
        [6, -3],
        [5, -2],
        [2, -11],
      ],
      [
        [90567, 76848],
        [-3, -1],
        [-2, 7],
        [6, 8],
        [11, 9],
        [4, -5],
        [-4, -7],
        [-5, -4],
        [-7, -7],
      ],
      [
        [88104, 83582],
        [-34, -100],
        [-24, -1],
        [-18, 21],
        [-39, -8],
        [-14, 5],
        [23, 31],
        [55, 48],
        [23, -1],
        [22, 10],
        [6, -5],
      ],
      [
        [91830, 85840],
        [-22, -7],
        [-11, 16],
        [-1, 11],
        [34, 25],
        [21, 36],
        [13, -22],
        [4, -15],
        [-38, -44],
      ],
      [
        [83405, 93945],
        [-70, -26],
        [-60, 0],
        [-42, 46],
        [33, 18],
        [57, 7],
        [31, -6],
        [44, -29],
        [7, -10],
      ],
      [
        [84594, 94383],
        [-17, -1],
        [-32, 15],
        [-8, 21],
        [0, 10],
        [26, 8],
        [33, -5],
        [25, -19],
        [4, -7],
        [-31, -22],
      ],
      [
        [79519, 96892],
        [-33, -4],
        [-36, 12],
        [10, 26],
        [81, 4],
        [30, 39],
        [51, -2],
        [14, -11],
        [8, -13],
        [-1, -17],
        [-11, 0],
        [-48, -2],
        [-9, -9],
        [-56, -23],
      ],
      [
        [79837, 96337],
        [-32, 0],
        [-9, 27],
        [27, 33],
        [34, 0],
        [29, -10],
        [10, -5],
        [10, -12],
        [4, -18],
        [-73, -15],
      ],
      [
        [77107, 95967],
        [-15, -9],
        [-29, 4],
        [-24, 22],
        [-9, 35],
        [20, 9],
        [57, -61],
      ],
      [
        [76903, 95736],
        [-15, -6],
        [-13, 4],
        [-4, 7],
        [1, 29],
        [25, 38],
        [1, 12],
        [12, 6],
        [31, -7],
        [15, -16],
        [2, -7],
        [-21, -38],
        [-34, -22],
      ],
      [
        [77815, 97703],
        [-61, -7],
        [7, 40],
        [4, 11],
        [31, 6],
        [20, -10],
        [44, -7],
        [-45, -33],
      ],
      [
        [71320, 94150],
        [-26, -4],
        [-118, 22],
        [-43, 27],
        [16, 18],
        [31, 0],
        [140, -63],
      ],
      [
        [72974, 94522],
        [-27, -20],
        [-37, 11],
        [-27, 13],
        [-14, 19],
        [14, 10],
        [40, 7],
        [24, -7],
        [21, -22],
        [6, -11],
      ],
      [
        [73543, 94734],
        [-13, -34],
        [-78, 17],
        [-11, 14],
        [42, 21],
        [38, 12],
        [54, 2],
        [-32, -32],
      ],
      [
        [74070, 95035],
        [23, -11],
        [73, 17],
        [14, -6],
        [20, -24],
        [-31, -45],
        [-23, -18],
        [-66, 10],
        [-83, 1],
        [-37, 25],
        [20, 26],
        [48, 16],
        [28, 15],
        [14, -6],
      ],
      [
        [66475, 98677],
        [-60, -7],
        [-105, 12],
        [-30, 14],
        [7, 13],
        [68, 18],
        [54, 4],
        [57, -19],
        [26, -23],
        [-17, -12],
      ],
      [
        [86301, 76210],
        [-8, 15],
        [-2, 26],
        [-9, 25],
        [-18, 34],
        [-7, 35],
      ],
      [
        [86257, 76345],
        [16, 18],
        [-3, 32],
        [-15, 30],
        [-23, 6],
        [-5, 8],
        [1, 16],
        [8, 16],
        [11, 14],
        [24, 19],
        [40, 14],
        [22, 12],
        [19, 3],
        [20, -6],
        [18, 18],
        [17, 11],
        [4, 31],
        [1, 47],
        [6, 14],
        [8, 20],
        [11, 26],
        [10, 67],
        [8, 46],
        [5, 23],
        [1, 32],
        [-5, 20],
        [-10, 13],
        [-7, 8],
        [-1, 36],
        [1, 48],
        [-3, 31],
        [11, 172],
        [12, 39],
        [-36, 229],
        [-11, 73],
        [-7, 37],
        [-16, 54],
        [-10, 27],
        [4, 26],
        [14, 25],
        [14, 12],
        [40, 6],
        [12, 9],
        [49, 28],
        [11, 17],
        [26, 40],
        [10, 31],
        [11, 40],
        [24, 21],
        [15, 36],
        [16, 13],
        [16, -31],
        [19, -17],
        [25, -11],
        [31, -13],
        [51, -25],
        [51, -21],
        [33, -17],
        [16, -7],
        [32, -12],
        [14, -8],
        [13, -10],
        [21, 26],
        [28, 33],
        [-5, 51],
        [5, 58],
        [20, 100],
        [23, 29],
        [12, 5],
        [12, 11],
        [23, 19],
        [8, 26],
        [-5, 31],
        [8, 31],
        [2, 30],
        [8, 39],
        [11, 11],
        [15, 13],
        [12, 20],
        [10, 31],
        [7, 35],
        [-3, 41],
        [14, 26],
        [23, 22],
        [8, 14],
        [4, 35],
        [1, 16],
        [6, 18],
        [-4, 36],
        [-6, 40],
        [26, 66],
        [18, 57],
        [4, 84],
        [2, 13],
        [7, 40],
        [4, 16],
        [14, 52],
        [19, 34],
        [-4, 38],
        [-7, 37],
        [1, 25],
        [16, 29],
        [10, 15],
        [8, 21],
        [14, 9],
        [12, 5],
        [28, 5],
        [16, 22],
        [15, 22],
        [28, 58],
        [9, 35],
        [6, 17],
        [-15, 50],
        [-13, 42],
        [-16, 58],
        [-7, 27],
        [11, 35],
        [11, 22],
        [6, 19],
        [3, 33],
        [-4, 25],
        [-28, 39],
        [-30, 19],
        [-34, 8],
        [-11, 2],
        [-24, -7],
        [-101, -50],
        [-48, -38],
        [-27, -43],
        [-29, -21],
        [-47, 3],
        [-43, 2],
        [-35, -24],
        [-40, -49],
        [-29, -22],
        [-18, 4],
        [-19, -33],
        [-21, -70],
        [-24, -31],
        [-27, 9],
        [-64, -7],
        [-101, -22],
        [-63, 1],
        [-26, 24],
        [-40, 3],
        [-55, -18],
        [-33, -3],
        [-12, 10],
        [-8, 29],
        [-4, 48],
        [-19, 50],
        [-32, 51],
        [-6, 63],
        [21, 73],
        [5, 50],
        [-12, 27],
        [-4, 24],
        [-25, 31],
        [-17, 52],
        [-12, 16],
        [3, 45],
        [15, 54],
        [-18, 50],
        [-55, 3],
        [-44, 15],
        [-44, 46],
        [-68, 131],
        [-34, 46],
        [-22, 5],
        [-16, 21],
        [-10, 37],
        [-16, 1],
        [-16, 0],
        [-9, -16],
        [-11, -5],
        [-18, 15],
        [-17, 1],
        [-18, -11],
        [-15, 7],
        [-13, 26],
        [-23, 17],
        [-33, 9],
        [-13, 17],
        [5, 28],
        [-6, 20],
        [-18, 13],
        [-49, -3],
        [-80, -20],
        [-66, 5],
        [-52, 31],
        [-29, 28],
        [-5, 26],
        [-15, 25],
        [-24, 24],
        [-14, 42],
        [-2, 58],
        [5, 56],
        [22, 79],
        [-54, 51],
        [-16, 30],
        [3, 25],
        [-2, 20],
        [-10, 15],
        [1, 23],
        [10, 32],
        [0, 41],
        [-11, 50],
        [-30, 70],
        [-49, 90],
        [-27, 66],
        [-4, 42],
        [-6, 33],
        [-10, 18],
        [-5, 31],
        [4, 34],
        [-6, 22],
        [-7, 21],
        [1, 33],
        [-9, 22],
        [-18, 13],
        [-6, 25],
        [4, 53],
        [-13, 46],
        [-40, 83],
        [-12, 61],
        [-3, 54],
        [-17, 27],
        [-1, 24],
        [-2, 42],
        [-11, 11],
        [-6, 15],
        [5, 17],
        [-8, 22],
        [-21, 26],
        [-9, 22],
        [-3, 21],
        [-10, 15],
        [-31, 16],
        [-8, 21],
        [2, 19],
        [6, 17],
        [4, 11],
        [-1, 14],
        [-2, 13],
        [-13, 17],
        [-17, 19],
        [-19, 41],
        [-25, 11],
        [-15, 0],
        [-14, 23],
        [5, 14],
        [-1, 28],
        [-12, 22],
        [-15, 9],
        [-14, -6],
        [-34, 21],
        [-55, 47],
        [-42, 22],
        [-29, -4],
        [-18, -14],
        [-6, -25],
        [-20, 3],
        [-48, 44],
        [-48, 11],
        [-27, 24],
        [-21, 40],
        [-20, 17],
        [-19, -7],
        [-44, 27],
        [-70, 61],
        [-37, 20],
        [-14, -11],
        [-7, 0],
        [-12, 2],
        [-18, 0],
        [-32, 15],
        [-43, -7],
        [-55, -27],
        [-59, -17],
        [-64, -6],
        [-37, 3],
        [-12, 13],
        [-69, -19],
        [-96, -40],
        [-94, -38],
        [-117, -19],
        [-78, -64],
        [-78, -118],
        [-57, -74],
        [-34, -30],
        [-14, -40],
        [6, -49],
        [29, -17],
        [53, 14],
        [44, -7],
        [38, -28],
        [12, -42],
        [-14, -56],
        [4, -56],
        [22, -54],
        [2, -63],
        [-19, -71],
        [-48, -72],
        [-76, -72],
        [-47, -71],
        [-28, -102],
        [-42, -90],
        [-16, -50],
        [-3, -41],
        [-17, -45],
        [-31, -48],
        [-17, -48],
        [-3, -49],
        [-15, -44],
        [-29, -39],
        [-17, -42],
        [-7, -45],
        [-11, -29],
        [-15, -16],
        [8, -15],
        [30, -15],
        [13, -43],
        [-6, -71],
        [-18, -51],
        [-31, -31],
        [-47, -20],
        [-62, -9],
        [-85, -68],
        [-73, -88],
        [-87, -103],
        [-17, 0],
        [-32, 13],
        [-61, 42],
        [-65, 9],
        [-62, 40],
        [-37, 25],
        [-57, 50],
      ],
      [
        [82411, 80543],
        [-14, 31],
        [-22, 25],
        [-56, 33],
        [-37, 18],
        [-23, 1],
        [-58, -34],
        [-36, -27],
        [-22, -14],
        [-36, 3],
        [-44, 6],
        [-18, 9],
        [-25, 21],
        [-49, 64],
        [-26, 45],
        [-35, 26],
        [-38, 29],
        [-19, 7],
        [-33, -2],
        [-47, 8],
        [-25, 10],
        [-21, -9],
        [-42, -31],
        [-52, -59],
        [-41, -23],
        [-44, -32],
        [-36, -37],
        [-35, -39],
        [-43, -44],
        [-20, -61],
        [-10, -44],
        [-40, -27],
        [-30, -26],
        [-30, -9],
        [-56, 14],
        [-33, -10],
        [-83, -52],
        [-40, -5],
        [-28, -7],
        [-27, -3],
        [-45, -13],
        [-17, -9],
        [-23, -10],
        [-26, 7],
        [-37, -29],
        [-104, -80],
        [-33, -13],
        [-22, -3],
        [-28, 28],
        [-28, 19],
        [-30, -2],
        [-34, -26],
        [-57, 20],
        [-68, 19],
        [-61, 18],
        [-21, 15],
        [-60, 22],
        [-88, 1],
        [-52, 0],
        [-33, -8],
        [-26, 11],
        [-32, 32],
        [-54, 74],
        [-32, 21],
        [-17, 19],
        [-7, 30],
        [-12, 4],
        [-8, 21],
        [0, 29],
        [-1, 62],
        [4, 44],
        [-9, 13],
        [-36, 7],
        [-43, 14],
        [-79, 2],
        [-32, 1],
        [-25, 25],
        [-28, 31],
        [-28, 64],
        [-24, 29],
        [-40, 37],
        [-38, 10],
        [-57, -7],
        [-42, -7],
        [-37, 16],
        [-24, 20],
        [-34, 22],
        [-51, 5],
        [-42, 16],
        [-44, 18],
        [-32, -7],
        [-22, -18],
        [-26, -23],
        [-33, -4],
        [-81, -24],
        [-24, -14],
        [-36, -6],
        [-32, -18],
        [-26, -35],
        [-22, -26],
        [-28, -9],
        [-33, 2],
        [-29, 8],
        [-15, 3],
        [-22, -13],
        [-25, -9],
        [-38, 15],
        [-21, 13],
        [-32, 8],
        [-20, 37],
        [-20, 15],
        [-34, 6],
        [-50, 18],
        [-26, 20],
        [-23, 11],
        [-38, 43],
        [-21, 37],
        [-17, 6],
        [-20, 5],
        [-13, 23],
        [-1, 29],
        [5, 18],
        [3, 30],
        [-11, 29],
        [-11, 13],
        [-6, 22],
        [3, 42],
        [-4, 42],
        [-4, 44],
        [-12, 32],
        [-3, 63],
        [5, 26],
        [-1, 30],
        [-12, 23],
        [-37, 17],
        [-44, 22],
        [-70, 27],
        [-29, 2],
        [-23, -11],
        [-22, 13],
        [-22, 22],
        [-39, 23],
        [-50, 30],
        [-54, 33],
        [-48, 29],
        [-19, 8],
        [-66, 2],
        [-54, 4],
        [-32, 11],
        [-37, 41],
        [-19, 26],
        [-30, 12],
        [-22, 4],
        [-35, 13],
        [-64, 44],
        [-23, 21],
        [-16, 0],
        [-21, 38],
        [-18, 9],
        [-13, -27],
        [-13, -65],
        [-11, -30],
        [-34, -60],
        [-80, -48],
        [-13, -25],
        [-8, -23],
        [-11, -32],
        [-4, -42],
        [-10, -12],
        [-23, -1],
        [-18, -19],
        [-14, -42],
        [-11, -17],
        [-7, -39],
        [1, -17],
        [-2, -19],
        [-2, -30],
        [-21, -66],
        [-3, -38],
        [8, -24],
        [18, -33],
        [9, -18],
        [3, -22],
        [0, -28],
        [11, -38],
        [8, -33],
        [13, -24],
        [19, -20],
        [21, -7],
        [16, -13],
        [4, -27],
        [-5, -37],
        [-7, -69],
        [-14, -43],
        [-8, -28],
        [-14, -42],
        [-5, -17],
        [-27, -36],
        [-19, -10],
        [-23, -29],
        [-19, -2],
        [-18, 1],
        [-19, -7],
        [-18, -13],
        [-13, -39],
        [-34, -40],
        [-16, -19],
        [-42, -6],
        [-20, 18],
        [-11, 25],
        [-14, 14],
        [-17, 31],
        [-76, 16],
        [-20, -7],
        [-12, -12],
        [-15, 9],
        [-11, 15],
        [-11, -5],
        [-23, -8],
        [-19, 2],
        [-23, 31],
        [-33, 16],
        [-13, 10],
        [-13, 0],
        [-8, -15],
        [-15, -8],
        [-10, 18],
        [-13, 13],
        [-18, 0],
        [-22, -27],
        [-39, -13],
        [-13, -19],
        [-22, 3],
        [-16, 15],
        [-15, 1],
        [-46, 0],
        [-15, -5],
        [-19, 15],
        [-8, 27],
        [-23, 21],
        [-33, 2],
        [-26, -3],
        [-12, -8],
        [-17, -3],
        [-14, 37],
        [-19, 26],
        [-10, 19],
        [-16, 8],
        [-13, 24],
        [-2, 47],
        [-8, 59],
        [-9, 61],
        [-10, 26],
        [-49, 9],
        [-23, -2],
        [-54, 5],
        [-38, 4],
        [-10, 1],
        [-34, 7],
        [-32, 6],
        [-32, 4],
        [-14, -5],
        [-33, -2],
        [-26, 29],
        [-11, 34],
        [-2, 19],
        [-6, 19],
        [-23, 6],
        [-22, -6],
        [-11, -39],
        [-16, -16],
        [-15, 3],
        [-13, 21],
        [-26, 23],
        [-17, 22],
        [-20, 35],
        [-16, -8],
        [-5, -22],
        [-3, -21],
        [-21, -43],
        [-24, -5],
        [-41, 3],
        [-43, -2],
        [-27, -16],
        [-20, -29],
        [-10, -23],
        [-21, -8],
        [-21, -23],
        [-9, -31],
        [-20, 1],
        [-11, -4],
        [-19, -6],
        [-48, -17],
        [-11, -4],
        [-29, -30],
        [-22, -23],
        [-21, -10],
        [-13, -27],
        [-17, -21],
        [-38, -6],
        [-42, -26],
        [-15, -9],
        [-24, -20],
        [-34, -8],
        [-14, -6],
        [-13, -14],
        [-8, -49],
        [-28, -17],
        [-37, -3],
        [-28, -26],
        [-2, -46],
        [9, -42],
        [-4, -19],
        [-21, -11],
        [-29, -22],
        [-22, -28],
        [-26, -1],
        [-16, 10],
        [-11, -18],
        [-7, -37],
        [-19, -18],
        [-28, -16],
        [-11, 6],
        [-7, 14],
        [-12, 18],
        [-10, -7],
        [-1, -26],
        [-8, -19],
        [-24, -2],
        [-18, 11],
        [-13, 12],
        [-25, -2],
        [-26, -5],
        [-16, 6],
        [-15, -6],
        [-41, -12],
        [-16, -41],
        [0, -48],
        [-5, -24],
        [-24, -21],
        [-12, -19],
        [-14, -13],
        [-33, -1],
        [-1, 0],
      ],
      [
        [74392, 80162],
        [-14, 2],
        [-26, -11],
        [-26, -8],
        [-17, -6],
        [-11, -18],
        [-16, -8],
        [-26, 5],
      ],
      [
        [74256, 80118],
        [-8, 35],
        [-17, 40],
        [-24, 14],
        [-21, 8],
        [-20, 19],
        [-13, 20],
        [-39, 95],
        [-27, 41],
        [-25, 3],
        [-3, 27],
        [14, 27],
        [18, 22],
        [0, 31],
        [-15, 16],
        [-18, -5],
        [-25, -35],
        [-29, -40],
        [-35, -29],
        [-14, -24],
        [-17, -27],
        [-24, 4],
        [-18, -2],
        [-15, -2],
        [-11, 29],
        [-15, 4],
        [-106, 28],
        [-35, 11],
        [-23, -14],
        [-16, 9],
        [-6, 28],
        [-21, 50],
        [-16, 41],
        [-21, 41],
        [-8, 33],
        [7, 34],
        [-3, 30],
        [-18, 15],
        [-23, 2],
        [-65, 64],
        [-30, 9],
        [-27, 12],
        [-22, 0],
        [-18, 28],
        [-17, 86],
        [-6, 48],
        [-21, 49],
        [-27, 41],
        [-16, 57],
        [-23, 25],
        [-40, 39],
        [-38, 28],
        [-62, 34],
        [-23, 0],
        [-32, -3],
        [-18, -16],
        [-21, -37],
        [-28, -2],
        [-44, 0],
        [-11, -14],
        [-7, -25],
        [-23, -31],
        [-33, -25],
        [-46, 8],
        [-32, -13],
        [-32, -5],
        [-45, 32],
        [-51, -1],
        [-33, -15],
        [-46, 1],
        [-10, 18],
        [6, 30],
        [-4, 27],
        [-8, 22],
        [-6, 27],
        [-19, 6],
        [-54, -11],
        [-15, 12],
        [2, 27],
        [9, 33],
        [8, 43],
        [-3, 26],
        [-28, -4],
        [-17, 3],
        [-9, 30],
        [-16, 23],
        [-18, 1],
        [-21, 5],
        [-24, -9],
        [-12, -30],
        [-16, -5],
        [-16, -8],
        [-12, -11],
        [-7, -27],
        [3, -25],
        [5, -55],
        [-8, -29],
        [-22, -16],
        [-20, 3],
        [-14, -7],
        [-26, -31],
        [-12, -11],
        [-3, -19],
        [-2, -28],
        [-22, 10],
        [-35, 104],
        [-40, 118],
        [-45, 125],
        [-24, 67],
        [-89, 216],
        [-43, 103],
        [-76, 178],
        [-68, 162],
        [-77, 168],
        [-46, 95],
        [-48, 101],
        [-17, 27],
        [-26, 36],
        [-65, 69],
        [-94, 99],
        [-87, 87],
        [-68, 69],
        [-17, 30],
        [-8, 17],
        [-7, 18],
        [-10, 34],
        [0, 22],
        [64, -4],
        [14, 22],
        [24, 80],
        [13, 69],
        [-22, -3],
        [-40, -28],
        [-21, -21],
        [-12, -9],
        [-64, -14],
        [-35, -31],
        [-72, -52],
        [-52, -30],
        [-10, -5],
        [-61, -10],
        [-11, -12],
        [-1, -27],
        [-5, -30],
        [-43, -44],
        [-47, -38],
        [-17, -5],
        [-29, 9],
        [-14, -5],
        [-43, -41],
        [-64, -62],
        [-6, -25],
        [0, -30],
        [-7, -27],
        [-14, -10],
        [-21, 24],
        [-19, 28],
        [-39, 20],
        [-58, 5],
        [-36, -10],
        [-24, -16],
        [-48, -61],
        [-18, -13],
        [-10, 4],
        [-2, 30],
        [-10, 21],
        [-12, 32],
        [6, 63],
        [26, 60],
        [43, 33],
        [35, 35],
        [10, 38],
        [-1, 27],
        [-13, 12],
        [-13, 2],
        [-8, -13],
        [-23, -26],
        [-35, -21],
        [-29, -4],
        [-13, 1],
        [-31, 13],
        [-57, 73],
        [-48, 10],
        [-33, 6],
        [-11, -7],
        [-5, -18],
        [3, -20],
        [7, -19],
        [-4, -16],
        [-15, -11],
        [-24, -20],
        [-11, 13],
        [-6, 51],
        [1, 40],
        [-16, 34],
        [-17, 52],
        [-23, 31],
        [-22, -10],
        [-11, -44],
        [-17, -15],
        [-33, 9],
        [-58, -25],
        [-95, -11],
        [-68, 31],
        [-11, 28],
        [28, 59],
        [2, 53],
        [0, 48],
        [7, 35],
        [-16, 66],
        [-38, 136],
        [-22, 102],
        [-34, 77],
        [-14, 25],
        [-70, -13],
        [-19, -17],
        [-13, -23],
        [-22, -17],
        [-31, -12],
        [-26, 8],
        [-29, 13],
        [-31, 27],
        [-36, 36],
        [-69, 28],
        [-68, 9],
        [-75, 10],
        [-38, -18],
        [-36, -29],
        [-52, -59],
        [-24, -6],
        [-38, -5],
        [-27, -15],
        [6, -26],
        [5, -36],
        [-10, -29],
        [-15, -15],
        [-22, -10],
        [-37, -3],
        [-31, -6],
        [-38, -41],
        [-58, -10],
        [-63, -15],
        [-44, -23],
        [-96, -29],
        [-55, -13],
        [-93, -28],
        [-74, -5],
        [-11, 20],
        [-58, -43],
        [-64, 3],
        [-11, -18],
        [-16, -16],
        [-17, -8],
        [-22, -20],
        [-13, -43],
        [-9, -45],
        [-20, -13],
        [-25, 16],
        [-19, 16],
        [-33, -16],
        [-44, -10],
        [-35, 6],
        [-18, 13],
        [-73, -21],
        [-37, -26],
        [-8, -13],
        [-9, -8],
        [-43, -17],
        [-35, 5],
        [-6, -1],
        [-33, -13],
        [-47, -22],
        [-33, -7],
        [-29, 0],
        [-18, -18],
        [-14, -20],
        [-123, -21],
        [-12, -14],
        [-25, -18],
        [-127, -6],
        [-11, -13],
        [-4, -15],
        [-16, -4],
        [-92, 28],
        [-74, 31],
        [-28, -17],
        [-24, -32],
        [-9, -47],
        [0, -40],
        [0, -34],
        [-11, -25],
        [-24, -31],
        [-2, -20],
        [33, -23],
        [42, -18],
        [24, 8],
        [21, 13],
        [17, -4],
        [13, -15],
        [4, -18],
        [-2, -13],
        [-8, -9],
        [-27, -17],
        [-25, 6],
        [-23, -12],
        [-12, -22],
        [-6, -40],
        [10, -29],
        [31, -7],
        [35, -21],
        [39, -9],
        [23, 3],
        [30, -31],
        [69, -38],
        [18, -29],
        [1, -30],
        [-13, -23],
        [-18, -13],
        [-23, 7],
        [-47, 8],
        [-52, 5],
        [-37, 10],
        [-54, -4],
        [-44, -9],
        [-11, -23],
        [-18, -42],
        [-14, -23],
        [-25, -44],
        [-8, -39],
        [13, -61],
        [44, -101],
        [4, -33],
        [-16, -33],
        [-30, -27],
        [-44, -48],
        [-47, -2],
        [-21, -12],
        [-53, -58],
        [-47, -28],
        [-10, -25],
        [11, -25],
        [59, -32],
        [30, -35],
        [8, -40],
        [13, -30],
        [46, -20],
        [95, -46],
        [6, -5],
        [6, -21],
        [97, -29],
        [13, -16],
        [40, -52],
        [8, -54],
        [-20, -54],
        [-13, -84],
        [-21, -75],
        [-45, -49],
        [-79, -46],
        [-85, -18],
        [-36, 3],
        [-23, 6],
        [-38, 14],
        [-28, 38],
        [-21, 37],
        [-15, 9],
        [-15, -6],
        [-13, -23],
        [-19, -63],
        [-21, -62],
        [-17, -22],
        [-63, -30],
        [-7, 11],
        [7, 41],
        [-8, 13],
        [-12, 9],
        [-78, 16],
        [-30, 11],
        [-22, 5],
        [-28, 10],
        [-19, 25],
        [-42, 76],
        [-32, 59],
        [-53, 53],
        [-47, 11],
        [-4, -6],
        [-36, -2],
        [-57, 13],
        [-3, -1],
        [-18, -25],
        [-13, -38],
        [-18, -32],
        [-26, -17],
        [-32, -4],
        [-37, 34],
        [-37, 51],
        [-46, 17],
        [-45, -11],
        [-17, -8],
        [-47, -30],
        [-15, 14],
        [-21, 9],
        [-46, -48],
        [-50, -53],
        [-11, -39],
        [-15, -37],
        [-34, -34],
        [-36, -30],
        [-31, -11],
        [-40, 11],
        [-51, 37],
        [-46, 45],
        [-50, 72],
        [-41, 42],
        [-39, 32],
        [-23, 8],
        [-19, -12],
        [-8, -26],
        [5, -20],
        [12, -18],
        [9, -57],
        [3, -69],
        [-4, -40],
        [-11, -23],
        [-11, -9],
        [-11, 3],
        [-13, 25],
        [-7, 52],
        [-7, 61],
        [-34, 77],
        [-30, 47],
        [-14, 26],
        [-27, 43],
        [-24, 26],
        [-50, 31],
        [-24, 22],
        [-43, 85],
        [-24, 26],
        [-31, 21],
        [-25, 7],
        [-5, -5],
        [-53, -12],
        [-37, 1],
        [-23, 16],
        [-24, 2],
        [-2, 0],
        [-26, -10],
        [-5, 0],
        [-13, 1],
        [-20, 17],
        [-21, 48],
        [-25, 50],
        [-31, 16],
        [-59, -21],
        [-65, -68],
        [-46, -41],
        [-37, -1],
        [-22, -6],
        [-14, 2],
        [-12, 13],
        [-3, 25],
        [-6, 31],
        [-30, 30],
        [-40, 20],
        [-38, 22],
        [-24, 6],
        [-11, -32],
        [-31, -49],
        [-35, -48],
        [-45, -79],
        [-13, -27],
        [-17, -19],
        [-40, -20],
        [-48, -33],
        [-30, -38],
        [-43, -17],
        [-47, -10],
        [-21, -33],
        [-12, -53],
        [-16, -48],
        [-73, -72],
        [-40, -47],
        [-30, -25],
        [-20, 3],
        [-22, 7],
        [-9, -4],
        [12, -36],
        [9, -113],
        [14, -72],
        [10, -41],
        [9, -33],
        [7, -50],
        [-9, -29],
        [-14, -20],
        [-45, -31],
        [-46, -26],
        [-27, 17],
        [-31, 42],
        [-12, 22],
        [-33, 71],
        [-59, 109],
        [-40, 55],
        [-30, 21],
        [-26, -7],
        [-21, -25],
        [-15, -23],
        [-13, -26],
        [-9, -32],
        [1, -45],
        [-1, -47],
        [-13, -33],
        [-33, -36],
        [-38, -49],
        [-29, -90],
        [-18, -112],
        [-6, -78],
        [14, -37],
        [28, -29],
        [18, -30],
        [4, -29],
        [-5, -30],
        [-15, -35],
        [-30, -39],
        [-42, -95],
        [-26, -133],
        [15, -93],
        [53, -51],
        [42, -23],
        [17, -30],
        [15, -60],
        [-2, -62],
        [-5, -42],
        [10, -41],
        [20, -48],
        [25, -30],
        [27, 16],
        [26, 20],
        [33, -8],
        [93, -16],
        [48, -9],
        [16, -21],
        [30, -69],
        [38, -77],
        [39, -78],
        [14, -34],
        [31, -93],
        [33, -84],
        [35, -104],
        [-2, -28],
        [-19, -11],
        [-30, 2],
        [-23, 16],
        [-13, 12],
        [-11, 4],
        [-13, -5],
        [-11, -13],
        [-5, -20],
        [2, -28],
        [9, -26],
        [12, -16],
        [7, -6],
        [46, -34],
        [51, -38],
        [63, -54],
        [13, -7],
      ],
      [
        [63675, 78534],
        [4, -26],
        [-34, -5],
        [-4, -31],
        [-8, -23],
        [-75, -51],
        [-19, 0],
        [-16, -8],
        [1, -33],
        [4, -30],
        [13, -32],
        [-6, -14],
        [-11, -5],
        [-14, 10],
        [-14, 17],
        [-14, 4],
        [-14, -4],
        [-64, -91],
        [-27, -23],
        [-30, -9],
        [-61, -34],
        [-19, 2],
        [-17, 12],
        [-15, -17],
        [-4, -42],
        [-17, 29],
        [-18, 23],
        [-8, 7],
        [-4, -4],
        [17, -45],
        [1, -41],
        [-4, -23],
        [-7, -21],
        [-10, -12],
        [-11, -7],
        [-6, -73],
        [-12, -44],
        [-15, -40],
        [-21, -72],
        [-16, -31],
        [-13, -37],
        [-9, -51],
        [-12, 12],
        [-10, 22],
        [-6, -29],
        [-7, -25],
        [-32, -37],
        [-24, -36],
        [-11, -55],
        [-2, -33],
        [3, -30],
        [9, -17],
        [46, -20],
        [29, -25],
        [28, -47],
        [30, -40],
        [21, -51],
        [15, -64],
        [19, -123],
        [9, -129],
        [28, 161],
        [24, 29],
        [-6, -46],
        [-16, -69],
        [-17, -101],
        [-5, -74],
        [6, -64],
        [0, -30],
        [-13, -106],
        [7, -20],
        [11, -19],
        [29, -36],
        [21, -54],
        [5, -75],
        [12, -20],
        [14, -18],
        [72, -150],
        [41, -100],
        [21, -58],
        [22, -73],
        [12, -17],
        [14, -11],
        [27, -34],
      ],
      [
        [63492, 75947],
        [-15, -38],
        [-25, -67],
        [-11, -35],
        [-25, -33],
        [-44, -35],
        [-24, -15],
        [-25, -72],
        [-29, -69],
        [-19, -8],
        [-56, 11],
        [-19, 6],
        [-57, 31],
        [-16, 19],
        [-15, 81],
        [-17, 34],
        [-22, 23],
        [-15, 19],
        [-6, 19],
        [-16, 28],
        [-30, 42],
        [-21, 40],
        [-16, 11],
        [-21, -14],
        [-12, -4],
        [-5, 7],
        [-4, 33],
        [-30, 12],
      ],
      [
        [62897, 75973],
        [-6, 8],
        [-39, 32],
        [-16, 17],
        [-14, 2],
        [-31, 9],
        [-27, 16],
        [-12, 20],
        [-18, 22],
        [-33, 29],
        [-24, 26],
        [-1, 17],
        [15, 71],
        [10, 68],
        [-6, 13],
        [-14, 11],
        [-25, 11],
        [-61, -4],
        [-38, 69],
        [-13, 15],
        [-25, 11],
        [-35, 21],
        [-20, 15],
        [-6, -6],
        [-22, -75],
        [-22, 54],
        [-13, 14],
        [-19, 8],
        [-20, 0],
        [-49, -26],
        [-36, -28],
        [-27, -22],
        [-27, -12],
        [-13, -17],
        [-37, 3],
        [-18, 13],
        [-6, 14],
        [3, 23],
        [13, 26],
        [1, 14],
        [-5, 11],
        [-44, 35],
        [-18, 22],
        [-59, 30],
        [-71, 53],
        [-25, 35],
        [-3, 24],
        [-28, 24],
        [-36, 21],
        [-28, -6],
        [-26, -2],
        [-41, 39],
        [-38, 3],
        [-44, -12],
        [-10, -5],
        [-10, -5],
        [-130, 16],
        [-34, 33],
        [-28, 33],
        [-77, 24],
        [-39, 25],
        [-39, 36],
        [-42, 31],
        [-36, -13],
        [-49, 18],
        [-54, 15],
        [-18, -9],
        [-17, -40],
        [-13, -37],
      ],
      [
        [61104, 76854],
        [-29, 31],
        [-99, 146],
        [-52, 98],
        [-170, 225],
        [-22, 17],
        [-90, 33],
        [-37, 26],
        [-91, 161],
        [-41, -22],
        [-37, 6],
        [-21, 14],
        [-24, 23],
        [-16, 30],
        [-19, 68],
        [-22, 38],
        [-72, 56],
        [-82, 33],
        [-6, 14],
        [-3, 20],
        [71, 38],
        [19, 22],
        [-36, 29],
        [-14, 5],
        [-11, 13],
        [20, 22],
        [20, 10],
        [31, -25],
        [35, -46],
        [31, -18],
        [14, 22],
        [106, 39],
        [7, 30],
        [0, 34],
        [-11, -2],
        [-6, 8],
        [0, 38],
        [16, 51],
        [48, 84],
        [25, 116],
        [23, 27],
        [16, -18],
        [-1, -27],
        [3, -20],
        [15, 39],
        [14, 53],
        [36, 0],
        [24, -9],
        [26, 6],
        [-49, 88],
        [-66, 87],
        [-28, -6],
        [-18, 13],
        [-29, 73],
        [-12, 60],
        [28, -2],
        [28, -9],
        [53, 42],
        [20, 6],
        [31, -13],
        [44, -8],
        [-3, 39],
        [-14, 47],
        [53, 34],
        [47, 19],
        [91, 68],
        [40, 12],
        [5, 15],
        [1, 20],
        [-13, 54],
        [-14, 40],
        [-48, 2],
        [-26, -55],
        [-72, -19],
        [-33, 4],
        [26, 36],
        [25, 13],
        [8, 15],
        [-52, -13],
        [-25, -37],
        [-76, -48],
      ],
      [
        [58823, 81855],
        [-2, 14],
        [-19, 55],
        [-11, 24],
        [-13, 13],
        [-7, 15],
        [0, 66],
        [2, 61],
        [9, 8],
        [-25, 50],
        [-2, 38],
        [12, 19],
        [0, 16],
        [-8, 22],
        [-25, 37],
        [-25, 41],
        [-16, 33],
        [-11, 15],
        [13, 26],
        [17, 45],
        [6, 26],
        [9, 6],
        [40, 4],
        [29, -1],
        [22, -9],
        [8, -22],
        [21, -23],
        [57, -10],
        [24, 1],
        [30, 21],
        [49, 48],
        [12, 34],
        [30, 24],
        [18, 10],
        [17, 4],
        [2, 20],
        [-1, 28],
        [-6, 17],
        [-60, 57],
        [-8, 18],
        [-5, 22],
        [8, 21],
        [-1, 23],
        [-69, 51],
        [-58, 9],
        [-47, -3],
        [-19, 10],
        [8, 26],
        [12, 46],
        [3, 38],
        [-3, 17],
        [-9, 15],
        [-46, 32],
        [-62, 48],
        [-29, 56],
        [-15, 57],
        [-17, 35],
        [-31, 23],
        [2, 14],
        [21, 54],
        [-1, 9],
        [-9, 13],
        [-38, 27],
        [-51, 51],
        [-3, 13],
        [4, 31],
        [7, 32],
        [11, 14],
        [30, 64],
        [0, 21],
        [-5, 29],
        [-23, 49],
        [-17, 32],
        [-1, 16],
        [3, 14],
        [11, 17],
        [11, 22],
        [2, 73],
        [0, 26],
        [-7, 15],
        [-7, 7],
        [-16, -4],
        [-22, 12],
        [-16, 19],
        [-10, 6],
        [-11, 20],
        [-31, 39],
        [-6, 11],
        [-61, 33],
        [-53, -5],
        [-30, 6],
        [-15, -8],
        [-16, -21],
        [-22, -15],
        [-17, 0],
        [-15, -11],
        [-41, -38],
        [-19, 23],
        [-17, 34],
        [6, 29],
        [7, 27],
        [-1, 18],
        [-6, 15],
        [-25, 17],
        [-55, 31],
        [-15, 0],
        [-24, -11],
        [-42, -34],
        [-15, 7],
        [-14, 27],
        [-15, 34],
        [-20, 18],
        [-44, -2],
        [-4, -1],
        [-21, -20],
        [-9, 2],
        [-38, 50],
      ],
      [
        [57818, 84183],
        [7, 27],
        [8, 41],
        [-3, 31],
        [-6, 41],
        [-16, 72],
        [-2, 20],
        [-27, 31],
        [-4, 26],
        [-14, 34],
        [-14, 22],
        [-3, 47],
        [-9, 17],
        [-12, 8],
        [-41, -14],
        [-5, 2],
        [20, 76],
        [2, 44],
        [12, 47],
        [15, 18],
        [4, 16],
        [2, 30],
        [-2, 27],
        [-9, 13],
        [-35, 30],
        [-37, 35],
        [-8, 45],
        [-11, 10],
        [-33, 2],
      ],
      [
        [57597, 84981],
        [1, 13],
        [5, 36],
        [8, 31],
        [25, 33],
        [6, 23],
        [8, 20],
        [59, 24],
        [6, 9],
        [1, 8],
        [-3, 8],
        [-13, 12],
        [-14, 17],
        [-8, 46],
        [-20, 71],
        [-19, 48],
        [-4, 28],
        [5, 33],
        [7, 31],
        [0, 31],
        [-29, 172],
        [2, 31],
        [8, 31],
        [14, 26],
        [30, 34],
        [38, 62],
        [25, 81],
        [14, 49],
        [11, 11],
        [22, 3],
        [8, 15],
        [4, 8],
        [19, 9],
        [6, 9],
        [-5, 17],
        [-19, 29],
        [-14, 18],
      ],
      [
        [57781, 86108],
        [14, 40],
        [-5, 54],
        [-9, 44],
        [12, 33],
        [21, 3],
        [22, -36],
        [34, -18],
        [25, 24],
        [8, 46],
        [18, 20],
        [24, -18],
        [40, -7],
        [33, 3],
        [22, 10],
        [10, 15],
        [9, 27],
        [18, 34],
        [19, 23],
        [145, -26],
        [126, -47],
        [9, 18],
        [5, 30],
        [-32, 26],
        [-23, 14],
        [-29, 54],
        [-42, 43],
        [-42, 4],
        [-55, -15],
        [-84, 9],
        [-71, 81],
        [-47, 25],
        [-34, 62],
        [-8, 33],
        [36, -28],
        [5, 29],
        [3, 40],
        [-20, 24],
        [-18, 14],
        [-93, -61],
        [-106, -20],
      ],
      [
        [57721, 86714],
        [98, 120],
        [71, 87],
        [14, 14],
        [31, 23],
        [26, 24],
        [21, 33],
        [71, 63],
        [72, 69],
        [67, 90],
        [24, 28],
        [31, 30],
        [67, 96],
        [22, 26],
        [82, 120],
        [48, 59],
        [24, 34],
        [103, 113],
        [69, 91],
        [28, 50],
        [27, 71],
        [15, 49],
        [27, 63],
        [1, 21],
        [-8, 19],
        [-20, 30],
        [-28, 35],
        [-25, 43],
        [-18, 38],
        [-57, 53],
        [-89, 67],
        [-66, 50],
        [-101, 107],
        [-18, 26],
        [4, 7],
        [57, 33],
        [57, 83],
        [25, 42],
        [6, 32],
        [0, 37],
        [-4, 34],
        [-7, 21],
        [-27, 27],
        [-78, 48],
        [-19, 44],
        [-15, 47],
        [0, 19],
        [37, 50],
        [2, 25],
        [-5, 26],
        [-10, 19],
        [-80, 22],
        [-23, 24],
        [-18, 38],
        [-9, 33],
        [-1, 19],
        [6, 22],
        [27, 23],
        [25, 16],
        [5, 22],
        [-1, 23],
        [-4, 11],
        [-50, 11],
        [-5, 6],
        [-1, 8],
        [2, 10],
        [28, 41],
        [3, 79],
        [26, 55],
        [-29, 32],
        [2, 5],
        [44, 17],
        [41, 4],
        [18, 7],
        [2, 25],
        [-4, 35],
        [-42, 136],
        [-9, 39],
        [-28, 50],
        [-23, 33],
        [-14, 24],
        [-22, 47],
        [-13, 47],
        [-22, 54],
        [-26, 48],
        [-22, 46],
        [-55, 88],
        [-8, 25],
        [1, 22],
        [5, 23],
        [44, 73],
        [40, 60],
        [51, 71],
        [49, 59],
        [53, 69],
        [13, 70],
        [-2, 12],
        [-44, 37],
        [-82, 101],
        [-51, 77],
        [-78, 32],
        [-104, 41],
        [-35, 93],
        [-25, 79],
        [2, 29],
        [76, 134],
        [7, 25],
        [-1, 15],
        [-8, 9],
        [-11, 6],
        [-70, 4],
        [-11, 18],
        [42, 14],
        [35, 19],
        [58, 28],
        [18, 7],
      ],
      [
        [58045, 91602],
        [43, 16],
        [14, 12],
        [11, 15],
        [40, 100],
        [10, 16],
        [123, 36],
        [45, 18],
        [26, 23],
        [12, 19],
        [9, 21],
        [7, 24],
        [2, 22],
        [-10, 28],
        [6, 3],
        [13, -1],
        [42, -28],
        [66, -30],
        [48, -2],
        [20, 5],
        [10, 13],
        [7, 26],
        [0, 27],
        [-15, 75],
      ],
      [
        [58574, 92040],
        [50, -8],
        [112, -46],
        [26, 5],
        [34, 13],
        [34, 55],
        [25, 9],
        [33, -12],
        [9, 14],
        [-17, 46],
        [4, 22],
        [113, -48],
        [48, -36],
        [105, -32],
        [18, -17],
        [2, -30],
        [-5, -25],
        [-23, -14],
        [-44, 2],
        [-161, 39],
        [-23, -24],
        [19, -20],
        [47, -25],
        [13, -43],
        [72, 6],
        [69, -16],
        [32, 5],
        [6, -14],
        [-22, -36],
        [10, -9],
        [77, 34],
        [36, 10],
        [19, -9],
        [3, -29],
        [-13, -36],
        [-1, -28],
        [-24, -66],
        [-36, -21],
        [-16, -27],
        [54, 17],
        [28, 18],
        [53, 92],
        [16, 12],
        [152, 1],
        [34, -5],
        [142, -44],
        [40, -3],
        [46, 5],
        [16, 20],
        [16, 6],
        [158, -48],
        [211, -109],
        [309, -179],
        [174, -160],
        [20, -34],
        [63, -20],
        [14, 14],
        [35, -12],
        [205, -146],
        [70, -7],
        [-9, 31],
        [-12, 29],
        [18, -7],
        [24, -21],
        [38, -57],
        [48, -42],
        [48, -64],
        [41, -24],
        [36, -9],
        [30, -18],
        [56, -17],
        [26, -155],
        [20, -34],
        [0, -68],
        [36, -28],
        [27, -5],
        [-1, -51],
        [-22, -119],
        [-24, -51],
        [-186, -219],
        [-116, -84],
        [-226, -97],
        [-176, -36],
        [-72, -3],
        [-138, 18],
        [-75, 20],
        [-93, 55],
        [-86, 27],
        [-60, 12],
        [-110, 5],
        [-239, 54],
        [-41, 19],
        [-150, 105],
        [-60, -30],
        [-35, -5],
        [-24, 36],
        [10, 10],
        [5, 12],
        [-85, 30],
        [-70, 2],
        [-37, 26],
        [-46, 19],
        [-20, -12],
        [-11, 0],
        [-92, 46],
        [-41, 37],
        [-43, 65],
        [10, 23],
        [13, 15],
        [-148, 38],
        [-140, 5],
        [25, -18],
        [60, -10],
        [39, -26],
        [44, -37],
        [-10, -50],
        [62, -50],
        [47, -48],
        [2, -14],
        [18, -10],
        [70, -14],
        [12, -44],
        [-11, -17],
        [9, -25],
        [53, -27],
        [31, -7],
        [38, -17],
        [-17, -33],
        [-32, -22],
        [-33, -10],
        [16, -8],
        [41, 3],
        [152, -55],
        [80, -56],
        [81, -102],
        [26, -50],
        [2, -28],
        [-4, -27],
        [-12, -30],
        [-5, -31],
        [-28, -88],
        [-20, -31],
        [-38, -35],
        [36, -68],
        [37, -63],
        [37, -105],
        [7, -42],
        [1, -65],
        [33, -26],
        [-13, -10],
        [-13, -18],
        [3, -84],
        [46, -69],
        [70, -45],
        [41, -9],
        [59, 18],
        [43, -25],
        [96, -84],
        [43, -89],
        [18, -18],
        [97, -34],
        [72, -20],
        [111, -54],
        [19, -2],
        [54, 47],
        [92, 32],
        [29, 44],
        [-3, 37],
        [-23, 67],
        [-7, 65],
        [-31, 27],
        [-28, 18],
        [-86, -14],
        [-39, 3],
        [-30, 17],
        [-40, 47],
        [-75, 113],
        [-40, 37],
        [-13, 24],
        [-14, 32],
        [2, 53],
        [33, -2],
        [37, 30],
        [27, 106],
        [46, 14],
        [25, -1],
        [108, -50],
        [134, -132],
        [29, -14],
        [31, -2],
        [51, 4],
        [9, -18],
        [27, -20],
        [20, -3],
        [123, -42],
        [142, -82],
        [53, 3],
        [21, 46],
        [4, 20],
        [59, 46],
        [40, 8],
        [57, -17],
        [10, 13],
        [-19, 68],
        [-26, 59],
        [-39, 37],
        [-68, 111],
        [-28, 54],
        [-13, 57],
        [9, 50],
        [10, 36],
        [142, 89],
        [51, 53],
        [50, 69],
        [23, 14],
        [83, 19],
        [111, 59],
        [85, 78],
        [84, 119],
        [36, 31],
        [28, -2],
        [38, -19],
        [42, -35],
        [57, -6],
        [55, 5],
        [64, -3],
        [88, -54],
        [14, -17],
        [14, -23],
        [-28, -45],
        [-3, -29],
        [23, 14],
        [32, 6],
        [29, -9],
        [28, -25],
        [20, -28],
        [25, -24],
        [7, 32],
        [4, 28],
        [-13, 70],
        [34, 99],
        [26, 43],
        [48, 110],
        [-14, 71],
        [-2, 82],
        [-7, 38],
        [-31, 55],
        [-61, 39],
        [-61, 13],
        [-20, 38],
        [4, 43],
        [17, 63],
        [50, 133],
        [52, 188],
        [2, 43],
        [-5, 24],
        [4, 24],
        [-7, 57],
        [-9, 42],
        [-213, 163],
        [-13, 15],
        [-7, 22],
        [23, 5],
        [16, -1],
        [160, -76],
        [35, -4],
        [251, 21],
        [123, -18],
        [103, -39],
        [74, -108],
        [76, -99],
        [70, -86],
        [2, -70],
        [-73, -14],
        [-70, -4],
        [-180, -35],
        [-43, -39],
        [-120, -122],
        [-11, -37],
        [11, -36],
        [55, -38],
        [118, -57],
        [52, -113],
        [37, -57],
        [28, -22],
        [27, -5],
        [60, -1],
        [42, -13],
        [12, -11],
        [17, 11],
        [38, 4],
        [224, 60],
        [44, 26],
        [15, 40],
        [16, 133],
        [20, 46],
        [19, 57],
        [-7, 35],
        [-2, 40],
        [112, 38],
        [104, 26],
        [50, -8],
        [13, 28],
        [-32, 55],
        [-19, 28],
        [16, 12],
        [24, -15],
        [32, -9],
        [56, 9],
        [215, 113],
        [84, 63],
        [50, 25],
        [80, 57],
        [38, 19],
        [67, 7],
        [71, 23],
        [78, 42],
        [105, 36],
        [17, 2],
        [21, -5],
        [43, -42],
        [-16, -24],
        [-12, -25],
        [21, -14],
        [17, -6],
        [21, 7],
        [22, 17],
        [54, 26],
        [15, 33],
        [-21, 13],
        [-27, 49],
        [-32, 11],
        [-26, -2],
        [95, 71],
        [202, 105],
        [108, 48],
        [107, 4],
        [85, -6],
        [-32, -16],
        [-140, -22],
        [-21, -11],
        [0, -13],
        [34, -8],
        [14, -16],
        [-11, -18],
        [-11, -6],
        [-16, -54],
        [-21, -43],
        [44, -56],
        [4, -58],
        [-28, -31],
        [-39, 12],
        [-34, -20],
        [-62, -14],
        [-14, -18],
        [-9, -26],
        [40, -6],
        [31, 2],
        [110, -17],
        [15, -2],
        [37, 18],
        [37, 4],
        [45, 6],
        [23, 11],
        [23, -13],
        [44, -51],
        [40, 10],
        [17, 99],
        [63, 62],
        [74, 50],
        [72, 5],
        [69, 35],
        [33, 7],
        [64, -14],
        [96, -3],
        [81, -30],
        [60, -8],
        [88, 51],
        [203, 143],
        [17, -33],
        [33, 48],
        [157, 50],
        [38, 2],
        [1, -20],
        [14, -44],
        [30, -27],
        [42, -64],
        [-20, -17],
        [-21, -10],
        [-31, -43],
        [-3, -99],
        [59, -25],
        [81, -29],
        [34, 1],
        [28, 16],
        [9, 9],
        [10, 15],
        [8, 29],
        [5, 22],
        [-21, 55],
        [8, 58],
        [74, -4],
        [91, 17],
        [41, 33],
        [49, 63],
        [33, 52],
        [-21, 92],
        [-53, -20],
        [-91, 199],
        [-47, 77],
        [30, 36],
        [78, 23],
        [71, 74],
        [27, 15],
        [29, 3],
        [210, -51],
        [239, -12],
        [203, -39],
        [230, -81],
        [112, -57],
        [93, -63],
        [-9, -45],
        [38, 13],
        [82, -40],
        [56, -16],
        [57, -24],
        [22, -32],
        [75, -23],
        [78, -43],
        [14, -8],
        [95, -32],
        [68, -11],
        [41, -69],
        [136, -100],
        [25, -38],
        [119, -63],
        [59, -52],
        [37, 20],
        [91, 126],
        [54, 144],
        [32, 77],
        [-60, 3],
        [-45, -22],
        [-29, 5],
        [-32, 23],
        [-52, 58],
        [-66, 97],
        [-13, 106],
        [-18, 35],
        [-64, 28],
        [-42, 32],
        [-156, 63],
        [-28, -22],
        [-8, -34],
        [-10, -25],
        [-16, 32],
        [-10, 29],
        [0, 46],
        [8, 59],
        [26, 99],
        [40, -5],
        [21, 15],
        [26, 44],
        [-12, 37],
        [-14, 28],
        [3, 43],
        [25, 118],
        [11, 138],
        [-21, 34],
        [-19, 23],
        [-89, -24],
        [-33, 13],
        [-8, 26],
        [-2, 21],
        [25, 35],
        [25, 59],
        [-43, -13],
        [-15, 23],
        [36, 34],
        [42, 82],
        [99, 38],
        [74, 37],
        [116, 78],
        [86, 77],
        [56, 98],
        [38, 93],
        [62, 218],
        [58, 160],
        [98, 165],
        [61, 15],
        [23, -3],
        [4, -12],
        [-14, -14],
        [-4, -20],
        [26, -7],
        [42, -1],
        [79, 10],
        [134, -6],
        [234, 14],
        [33, -7],
        [87, -48],
        [47, 6],
        [96, -23],
        [52, -26],
        [50, -31],
        [-7, -120],
        [-10, -80],
        [-35, -152],
        [-14, -39],
        [-56, -110],
        [-26, -72],
        [-42, -50],
        [-60, -35],
        [-8, -21],
        [-5, -31],
        [59, -87],
        [140, -90],
        [34, -108],
        [8, -81],
        [-9, -210],
        [-13, -31],
        [-26, -34],
        [-25, -40],
        [16, -59],
        [20, -219],
        [4, -178],
        [-16, -61],
        [-8, -129],
        [0, -43],
        [14, -64],
        [28, -54],
        [37, -34],
        [105, -63],
        [99, -76],
        [7, -24],
        [5, -29],
        [-35, -30],
        [-55, -78],
        [-35, -65],
        [-3, -53],
        [12, -67],
        [-6, -63],
        [-23, -56],
        [-33, -41],
        [-99, -63],
        [-207, -334],
        [-50, -39],
        [-84, 13],
        [23, -48],
        [29, -68],
        [-4, -45],
        [-54, 3],
        [-77, -48],
        [-35, -32],
        [-60, -17],
        [-45, 17],
        [-50, 31],
        [7, 26],
        [10, 12],
        [35, 19],
        [34, 25],
        [-17, 5],
        [-14, 0],
        [-38, -32],
        [-45, -7],
        [-52, 40],
        [-41, 43],
        [-19, 9],
        [-38, -18],
        [-146, 8],
        [-38, -7],
        [-18, -16],
        [10, -13],
        [11, -24],
        [15, -48],
        [14, -36],
        [60, -39],
        [81, -15],
        [78, -48],
        [99, -34],
        [224, 14],
        [59, -4],
        [58, -15],
        [97, -50],
        [42, 4],
        [71, 45],
        [17, 101],
        [9, 31],
        [257, 142],
        [48, 31],
        [77, 77],
        [25, 52],
        [29, 140],
        [25, 50],
        [167, 161],
        [26, 41],
        [5, 76],
        [-3, 51],
        [-10, 50],
        [-30, 84],
        [-34, 49],
        [-33, 67],
        [24, 138],
        [28, 53],
        [151, 63],
        [129, 23],
        [144, 43],
        [58, 9],
        [40, -7],
        [41, -47],
        [36, -69],
        [104, -105],
        [35, -72],
        [7, -89],
        [-4, -216],
        [-20, -94],
        [41, -24],
        [20, -22],
        [51, -31],
        [27, -31],
        [27, -11],
        [59, -7],
        [168, 11],
        [92, 6],
        [-9, 14],
        [-14, 11],
        [-78, 4],
        [-110, 23],
        [-159, 42],
        [-19, 91],
        [4, 56],
        [36, 106],
        [25, 18],
        [31, 7],
        [36, 15],
        [-11, 67],
        [-14, 61],
        [-34, 85],
        [-37, 157],
        [-51, 1],
        [-39, 31],
        [-189, 92],
        [-179, 68],
        [-123, 9],
        [-39, -7],
        [-102, -71],
        [-66, -15],
        [-125, 31],
        [-107, -17],
        [-40, 17],
        [-16, 31],
        [32, 127],
        [-17, 49],
        [-47, 66],
        [-28, 51],
        [5, 56],
        [70, 214],
        [29, 56],
        [75, 100],
        [38, 76],
        [-9, 44],
        [-161, 239],
        [-43, 85],
        [-19, 27],
        [-39, 32],
        [-60, 38],
        [-18, 34],
        [163, 231],
        [74, 40],
        [103, 25],
        [50, 22],
        [87, 47],
        [52, 39],
        [17, 31],
        [11, 37],
        [2, 91],
        [-11, 73],
        [-14, 41],
        [-31, 52],
        [-31, 59],
        [22, 15],
        [22, 9],
        [58, -1],
        [60, -32],
        [30, -64],
        [35, -60],
        [0, -40],
        [-3, -31],
        [15, -44],
        [13, -18],
        [14, -31],
        [-13, -25],
        [-14, -12],
        [-27, -35],
        [-43, -108],
        [-33, -14],
        [-8, -84],
        [71, -92],
        [-9, -69],
        [-14, -23],
        [-39, -37],
        [5, -30],
        [10, -21],
        [111, -44],
        [105, -27],
        [176, -9],
        [51, -43],
        [19, 30],
        [165, -7],
        [133, -105],
        [70, -32],
        [57, -11],
        [116, 13],
        [20, 9],
        [19, 30],
        [-54, -2],
        [-24, -14],
        [-22, 1],
        [-38, 11],
        [-27, 18],
        [-29, 35],
        [-48, 104],
        [-84, 33],
        [-57, -14],
        [-62, 7],
        [-102, 56],
        [-68, 21],
        [-121, 61],
        [-34, 25],
        [-27, 51],
        [-31, 84],
        [-20, 47],
        [26, 9],
        [83, 46],
        [124, 15],
        [53, -16],
        [136, -94],
        [63, -3],
        [114, 41],
        [13, 26],
        [-26, 53],
        [-34, 27],
        [-66, 13],
        [-80, -24],
        [-23, 20],
        [8, 28],
        [9, 21],
        [43, 5],
        [30, 16],
        [65, 58],
        [72, 28],
        [71, 10],
        [261, -8],
        [148, -91],
        [144, -41],
        [63, -31],
        [17, -5],
        [15, -21],
        [11, -48],
        [182, -129],
        [42, -17],
        [116, -6],
        [130, 30],
        [59, 3],
        [63, -9],
        [33, -16],
        [36, -30],
        [-19, -38],
        [-17, -23],
        [-36, -64],
        [-16, -18],
        [-118, -73],
        [-47, -19],
        [-13, -96],
        [-6, -21],
        [-5, -34],
        [22, -68],
        [5, -42],
        [-18, -58],
        [-30, -62],
        [6, -50],
        [10, -67],
        [5, 20],
        [-2, 30],
        [8, 35],
        [54, 85],
        [39, 115],
        [40, 30],
        [36, 7],
        [40, -34],
        [11, -46],
        [2, -70],
        [-8, -65],
        [-30, -100],
        [-50, -70],
        [-16, -39],
        [23, -37],
        [25, -29],
        [29, -9],
        [33, 3],
        [8, 9],
        [6, 28],
        [-10, 36],
        [-6, 33],
        [61, 25],
        [57, 14],
        [45, 42],
        [11, 28],
        [10, 46],
        [-23, 72],
        [-20, 54],
        [-68, 129],
        [-51, 66],
        [32, 99],
        [53, 110],
        [20, 27],
        [6, 17],
        [6, 30],
        [-5, 31],
        [-6, 20],
        [-54, 83],
        [-39, 27],
        [-123, 16],
        [-31, 13],
        [-90, 85],
        [-11, 19],
        [-20, 61],
        [-7, 15],
        [-25, 16],
        [-83, 35],
        [-58, 15],
        [-84, 4],
        [-51, 18],
        [-76, 56],
        [-8, 19],
        [-22, 73],
        [-17, 37],
        [5, 28],
        [27, 58],
        [19, 51],
        [-23, 44],
        [-33, 14],
        [-36, 21],
        [-15, 40],
        [-9, 43],
        [-1, 34],
        [-6, 39],
        [17, 33],
        [38, 34],
        [-9, 24],
        [5, 31],
        [247, 41],
        [96, 11],
        [480, 4],
        [34, 12],
        [209, 20],
        [89, 24],
        [94, -25],
        [34, 1],
        [69, 7],
        [45, 51],
        [102, 20],
        [170, 21],
        [84, -4],
        [19, -15],
        [19, -21],
        [-92, -62],
        [-92, -56],
        [-76, -24],
        [-74, -49],
        [-7, -19],
        [-2, -12],
        [2, -39],
        [5, -25],
        [77, -32],
        [59, -44],
        [57, -32],
        [45, -19],
        [11, 11],
        [-165, 104],
        [-42, 23],
        [-17, 25],
        [8, 38],
        [17, 17],
        [26, 19],
        [17, 9],
        [62, 20],
        [206, 27],
        [49, 51],
        [21, 30],
        [56, 31],
        [-19, 13],
        [-46, 8],
        [-36, 18],
        [-142, 183],
        [-35, 28],
        [-109, 20],
        [-49, 21],
        [50, 62],
        [59, 16],
        [40, -4],
        [35, -17],
        [65, -51],
        [92, 22],
        [-35, 23],
        [-58, 26],
        [-54, 42],
        [-77, 37],
        [-86, 24],
        [-90, 10],
        [25, 55],
        [50, -7],
        [16, 17],
        [23, 33],
        [125, -77],
        [59, 21],
        [50, 35],
        [104, 93],
        [14, 42],
        [-50, 23],
        [-41, 11],
        [-56, -2],
        [-5, 25],
        [24, 33],
        [45, 13],
        [139, -36],
        [232, 93],
        [63, 45],
        [161, 58],
        [79, -7],
        [164, 77],
        [227, 33],
        [132, 1],
        [102, 42],
        [156, 15],
        [54, 17],
        [263, 43],
        [146, 34],
        [23, 27],
        [-131, -22],
        [-31, 20],
        [-27, -13],
        [-20, -19],
        [-63, 34],
        [-17, -9],
        [-13, -18],
        [-23, -6],
        [-27, 4],
        [-9, 41],
        [32, 55],
        [37, -29],
        [43, 42],
        [27, 1],
        [81, -26],
        [54, 27],
        [72, 13],
        [79, -12],
        [33, 3],
        [19, 25],
        [129, -22],
        [89, 15],
        [61, -2],
        [95, -13],
        [43, -18],
        [-25, -42],
        [-92, -68],
        [25, -11],
        [53, 31],
        [159, 46],
        [26, -9],
        [-18, -40],
        [-11, -17],
        [106, 23],
        [91, 50],
        [40, 9],
        [41, -31],
        [39, 28],
        [9, 28],
        [69, 6],
        [28, 26],
        [49, 19],
        [40, 8],
        [89, 36],
        [31, -11],
        [59, -9],
        [56, -17],
        [105, -40],
        [14, -15],
        [13, -3],
        [30, -28],
        [-22, -42],
        [-24, -60],
        [-45, -28],
        [27, -3],
        [19, 8],
        [36, 40],
        [32, 28],
        [-8, 119],
        [-57, 60],
        [-44, 20],
        [-102, 63],
        [-34, 27],
        [-46, 28],
        [18, 17],
        [196, -22],
        [100, 11],
        [108, -7],
        [144, 27],
        [61, -28],
        [69, 1],
        [80, -23],
        [24, 27],
        [-131, 29],
        [-58, -3],
        [-21, 15],
        [22, 34],
        [25, 51],
        [-25, 44],
        [-24, 24],
        [-4, 45],
        [23, 52],
        [54, 22],
        [30, 42],
        [63, 55],
        [303, 179],
        [145, 68],
        [55, 9],
        [64, -6],
        [126, 57],
        [47, 0],
        [175, -45],
        [42, -33],
        [96, -26],
        [111, -14],
        [51, -21],
        [25, -24],
        [21, -33],
        [-90, -22],
        [-97, -66],
        [-132, -36],
        [-163, -24],
        [-34, -18],
        [311, -5],
        [90, 4],
        [19, -57],
        [30, -2],
        [90, 27],
        [54, 2],
        [102, -20],
        [21, 11],
        [44, 1],
        [94, -25],
        [42, -37],
        [-67, -60],
        [-69, -53],
        [-84, -90],
        [-26, 7],
        [-44, 2],
        [8, -45],
        [74, 2],
        [40, -20],
        [92, 25],
        [130, -8],
        [27, 7],
        [50, 28],
        [13, 52],
        [22, 33],
        [43, 11],
        [47, -10],
        [80, 0],
        [203, 17],
        [170, -22],
        [136, 27],
        [178, -20],
        [78, -21],
        [57, -37],
        [52, -11],
        [42, -29],
        [43, -42],
        [-21, -34],
        [-21, -23],
        [56, 25],
        [43, 6],
        [32, -15],
        [57, -15],
        [18, -95],
        [17, -18],
        [15, -33],
        [-21, -30],
        [-18, -14],
        [45, 3],
        [63, 32],
        [13, 11],
        [16, 24],
        [-24, 23],
        [-21, 14],
        [27, 11],
        [52, -4],
        [26, -42],
        [17, -38],
        [38, -127],
        [81, 17],
        [4, -38],
        [-34, -87],
        [-36, -64],
        [-14, -14],
        [-23, -3],
        [9, 34],
        [-14, 20],
        [-21, 12],
        [-74, 12],
        [-138, 79],
        [-37, 8],
        [-8, -4],
        [-4, -7],
        [77, -54],
        [63, -95],
        [57, 28],
        [23, -5],
        [31, -45],
        [56, -18],
        [47, -30],
        [-31, -91],
        [-192, -160],
        [-203, -93],
        [-90, -65],
        [-158, -47],
        [-113, -64],
        [-144, -47],
        [-42, -51],
        [-107, -32],
        [8, -17],
        [12, -18],
        [-13, -30],
        [-15, -23],
        [-83, -47],
        [-121, -32],
        [-243, -195],
        [-121, -40],
        [-137, -2],
        [-29, -18],
        [-104, -123],
        [-32, -26],
        [-136, -13],
        [-142, -201],
        [-79, -68],
        [-69, -37],
        [72, 7],
        [85, 27],
        [99, 65],
        [26, 30],
        [14, 34],
        [29, 27],
        [45, 19],
        [176, 21],
        [72, -8],
        [106, 6],
        [70, 36],
        [41, 13],
        [37, 4],
        [19, 26],
        [62, 5],
        [143, 34],
        [21, 12],
        [46, 51],
        [85, -19],
        [61, 10],
        [159, 91],
        [96, 34],
        [26, 24],
        [-19, 17],
        [-22, 12],
        [-92, -32],
        [-83, -10],
        [-94, 8],
        [-13, 13],
        [-11, 32],
        [30, 47],
        [26, 28],
        [60, 37],
        [49, 13],
        [183, -40],
        [38, -5],
        [21, 66],
        [58, -3],
        [58, -11],
        [-25, -14],
        [-64, -21],
        [20, -48],
        [28, -33],
        [112, -47],
        [95, -21],
        [70, 1],
        [110, 20],
        [17, 14],
        [23, 38],
        [-28, 72],
        [27, -9],
        [27, -18],
        [42, -44],
        [40, -75],
        [25, -35],
        [-15, -37],
        [-57, -71],
        [33, -39],
        [59, -26],
        [0, -116],
        [-4, -56],
        [-29, -61],
        [-34, -26],
        [-33, -38],
        [8, -36],
        [8, -22],
        [35, -42],
        [98, -13],
        [13, 11],
        [-23, 13],
        [-66, 20],
        [-26, 16],
        [-23, 38],
        [27, 42],
        [29, 31],
        [35, 71],
        [11, 50],
        [-7, 52],
        [23, 24],
        [35, 25],
        [17, 5],
        [16, 11],
        [-25, 13],
        [-23, 6],
        [-48, 32],
        [-8, 41],
        [96, 17],
        [57, 29],
        [210, 13],
        [144, 55],
        [322, -15],
        [226, -45],
        [317, -5],
        [117, -30],
        [11, -11],
        [7, -22],
        [-50, -9],
        [-83, 0],
        [-22, -56],
        [14, -70],
        [148, -74],
        [129, -31],
        [90, -49],
        [48, -4],
        [188, 5],
        [110, -25],
        [100, 19],
        [109, 0],
        [38, -5],
        [40, -37],
        [64, -10],
        [77, -1],
        [43, 7],
        [17, 9],
        [-6, 15],
        [-64, 22],
        [7, 27],
        [25, 6],
        [107, -36],
        [44, -6],
        [39, 27],
        [29, 49],
        [16, 33],
        [17, 17],
        [14, 2],
        [14, 10],
        [-34, 39],
        [-33, 49],
        [-7, 32],
        [-10, 16],
        [-4, 59],
        [30, 60],
        [21, 17],
        [85, -23],
        [38, 36],
        [24, 14],
        [102, 24],
        [43, -2],
        [71, -23],
        [228, -110],
        [-5, -42],
        [54, 12],
        [26, 17],
        [61, 11],
        [41, 18],
        [11, -7],
        [14, -17],
        [-10, -25],
        [-14, -25],
        [9, -15],
        [12, -3],
        [61, -31],
        [79, 57],
        [32, 55],
        [22, 11],
        [197, -38],
        [59, -21],
        [12, -12],
        [8, -20],
        [32, -22],
        [39, -12],
        [-5, -18],
        [-2, -19],
        [92, -3],
        [40, -16],
        [44, -25],
        [-5, -29],
        [11, -17],
        [41, -2],
        [11, 3],
        [-13, -39],
        [-55, -42],
        [-33, -16],
        [-38, -28],
        [21, -5],
        [95, -8],
        [59, -56],
        [6, -41],
        [-37, -16],
        [-84, -52],
        [-50, -21],
        [-35, -2],
        [-25, -7],
        [36, -22],
        [158, -6],
        [46, -28],
        [36, -70],
        [0, -86],
        [-36, -43],
        [-97, -7],
        [-127, 96],
        [-78, 37],
        [-109, 71],
        [-21, -12],
        [32, -60],
        [51, -34],
        [92, -93],
        [154, -192],
        [36, 16],
        [21, 24],
        [10, 31],
        [-9, 43],
        [25, -21],
        [23, -38],
        [46, -64],
        [-62, 3],
        [-80, -21],
        [-29, -26],
        [22, -35],
        [59, -5],
        [25, -49],
        [46, -60],
        [103, -164],
        [73, -31],
        [71, -68],
        [71, -32],
        [37, -2],
        [25, 42],
        [20, -15],
        [19, -76],
        [34, -33],
        [38, -2],
        [31, 13],
        [45, 36],
        [36, 42],
        [58, 116],
        [38, 58],
        [36, 24],
        [-13, 28],
        [4, 33],
        [26, 77],
        [36, 91],
        [27, 49],
        [66, 97],
        [25, 18],
        [18, -32],
        [14, -42],
        [10, -18],
        [10, -7],
        [81, -85],
        [82, -67],
        [73, -33],
        [115, -32],
        [167, 5],
        [30, 42],
        [58, 32],
        [94, 16],
        [55, 39],
        [91, 11],
        [57, -6],
        [88, -28],
        [197, -89],
        [55, -33],
        [29, -35],
        [64, -52],
        [41, -25],
        [40, -18],
        [14, 5],
        [-5, 15],
        [-20, 15],
        [-16, 19],
        [47, 19],
        [5, 15],
        [17, 12],
        [62, 11],
        [-61, 19],
        [-20, 3],
        [-29, 11],
        [2, 27],
        [19, 19],
        [15, 36],
        [19, 23],
        [32, 18],
        [24, 3],
        [57, -23],
        [41, 42],
        [30, -3],
        [63, -43],
        [56, -64],
        [31, 0],
        [87, 26],
        [98, 1],
        [-13, 38],
        [-68, 83],
        [8, 106],
        [-47, 24],
        [-54, 14],
        [78, 27],
        [58, 87],
        [46, 8],
        [48, 16],
        [-15, 11],
        [-143, 9],
        [-31, -10],
        [-21, -26],
        [-71, 0],
        [-8, 59],
        [-2, 37],
        [93, 79],
        [35, 17],
        [236, -2],
        [71, 15],
        [104, 39],
        [-27, 25],
        [-3, 50],
        [-89, 73],
        [7, 16],
        [9, 11],
        [27, 0],
        [140, -19],
        [58, -39],
        [151, -40],
        [404, -13],
        [45, -14],
        [174, -17],
        [73, -19],
        [175, -23],
        [80, -17],
        [63, -25],
        [103, -15],
        [47, -17],
        [-5, -53],
        [-213, 7],
        [-71, 17],
        [-88, 2],
        [-33, -8],
        [-53, -44],
        [-63, -23],
        [-53, -4],
        [35, -38],
        [49, -10],
        [158, 49],
        [432, 24],
        [66, -4],
        [-7, -34],
        [-58, -65],
        [-55, -50],
        [-80, -52],
        [-30, 0],
        [63, 110],
        [-26, 6],
        [-24, -3],
        [-70, 46],
        [-11, 2],
        [-14, -11],
        [0, -16],
        [-13, -64],
        [26, -27],
        [0, -46],
        [-97, -29],
        [-38, 3],
        [-40, 18],
        [-17, 0],
        [-5, -15],
        [8, -27],
        [-1, -13],
        [-15, -22],
        [-8, -23],
        [24, -26],
        [31, -7],
        [171, 29],
        [75, 36],
        [82, 65],
        [146, 159],
        [65, 54],
        [37, 21],
        [48, 8],
        [269, -17],
        [156, -34],
        [149, -51],
        [74, -42],
        [54, -57],
        [10, -24],
        [5, -32],
        [-37, -30],
        [-167, -10],
        [-64, -17],
        [-24, -19],
        [-3, -11],
        [-8, -17],
        [12, -14],
        [75, -2],
        [72, -13],
        [100, -36],
        [15, -12],
        [32, -35],
        [10, -6],
        [149, 6],
        [10, -12],
        [9, -26],
        [-39, -40],
        [-39, -27],
        [-80, -64],
        [40, 23],
        [161, 55],
        [41, 10],
        [49, -4],
        [121, -50],
        [50, -39],
        [92, -112],
        [-26, -12],
        [-66, -12],
        [208, -85],
        [80, 1],
        [184, 24],
        [93, 1],
        [172, 54],
        [171, 35],
        [157, 2],
        [83, 33],
        [220, -1],
        [211, -11],
        [164, -20],
        [185, -60],
        [180, -83],
        [105, -82],
        [21, -25],
        [30, -57],
        [13, -48],
        [13, -65],
        [-6, -51],
        [-26, -36],
        [-16, -45],
        [2, -53],
        [-31, -69],
        [29, -49],
        [80, -32],
        [172, -42],
        [47, -29],
        [6, -85],
        [14, -71],
        [15, -136],
        [29, -35],
        [47, -32],
        [9, -45],
        [-59, -144],
        [-37, -27],
        [-39, -40],
        [69, 14],
        [35, 53],
        [38, 97],
        [36, 16],
        [19, 31],
        [0, 92],
        [-23, 79],
        [0, 57],
        [15, 46],
        [114, 95],
        [61, 38],
        [58, 22],
        [158, 19],
        [72, 19],
        [82, -13],
        [58, 5],
        [67, 20],
        [59, -10],
        [98, -63],
        [347, -14],
        [61, -22],
        [233, -27],
        [18, 0],
        [52, 31],
        [154, 108],
        [64, -7],
        [26, -17],
        [27, -43],
        [28, -27],
        [23, -75],
        [20, -102],
        [33, -18],
        [46, -6],
        [100, -38],
        [101, -48],
        [29, -92],
        [54, -77],
        [126, 7],
        [132, 16],
        [127, 126],
        [0, 52],
        [-31, 74],
        [-47, 72],
        [-37, 112],
        [-117, 25],
        [11, 33],
        [44, 39],
        [40, 61],
        [6, 46],
        [-11, 98],
        [106, -7],
        [106, -11],
        [201, -44],
        [163, -18],
        [86, -28],
        [52, -32],
        [62, -23],
        [21, 57],
        [23, 14],
        [82, -33],
        [60, -9],
        [103, 4],
        [130, -15],
        [141, 3],
        [127, 25],
        [47, -5],
        [52, -20],
        [84, -53],
        [143, -71],
        [130, -20],
        [150, -66],
        [141, -25],
        [113, -38],
        [16, -14],
        [5, -21],
        [8, -18],
        [89, -20],
        [165, -143],
        [-99962, -16],
        [56, -25],
        [56, -20],
        [23, 6],
        [12, -2],
        [32, -35],
        [21, -16],
        [113, -41],
        [51, -45],
        [42, -52],
        [-21, 10],
        [-38, 34],
        [4, -39],
        [12, -27],
        [61, -26],
        [64, -20],
        [40, -24],
        [14, -21],
        [8, -38],
        [-10, -33],
        [37, 12],
        [35, 30],
        [-18, 23],
        [-117, 81],
        [-25, 27],
        [34, -14],
        [158, -103],
        [43, -39],
        [-18, -8],
        [-13, -23],
        [14, -10],
        [19, 8],
        [31, 4],
        [31, -13],
        [35, -27],
        [73, -32],
        [434, -254],
        [10, -44],
        [12, -21],
        [7, -26],
        [2, -43],
        [-39, -51],
        [61, 5],
        [9, 6],
        [16, 21],
        [17, 14],
        [24, -17],
        [19, -34],
        [-6, -46],
        [-17, -38],
        [-2, -63],
        [15, -54],
        [15, -22],
        [13, -26],
        [3, -76],
        [-27, -34],
        [-15, -59],
        [17, -6],
        [52, -5],
        [18, -11],
        [30, -27],
        [7, -26],
        [7, -36],
        [9, -34],
        [7, -16],
        [8, 3],
        [30, 46],
        [14, 14],
        [34, 12],
        [19, -53],
        [-13, -88],
        [11, 0],
        [8, 10],
        [11, 23],
        [15, 14],
        [19, 32],
        [16, 39],
        [-19, 31],
        [-21, 20],
        [-51, 10],
        [-26, 24],
        [-10, 29],
        [26, 12],
        [22, 21],
        [15, 52],
        [-4, 28],
        [-6, 27],
        [-12, 39],
        [-19, 23],
        [-36, 11],
        [-16, 22],
        [-25, -1],
        [-25, 6],
        [-9, 8],
        [1, 16],
        [28, 7],
        [157, 2],
        [57, 22],
        [25, -6],
        [26, -16],
        [94, -21],
        [-3, -10],
        [-16, -10],
        [-27, -46],
        [-6, -26],
        [-1, -34],
        [24, -6],
        [24, 14],
        [-12, 27],
        [-3, 33],
        [10, 14],
        [13, 3],
        [24, -24],
        [27, -8],
        [89, -10],
        [26, 3],
        [8, 13],
        [-18, 14],
        [-116, 33],
        [-2, 17],
        [108, -22],
        [48, -20],
        [48, -15],
        [67, 5],
        [66, -24],
        [63, -65],
        [58, -82],
        [59, -49],
        [61, -36],
        [103, -99],
        [13, -8],
        [10, -14],
        [-19, -16],
        [-17, -25],
        [34, 17],
        [33, 12],
        [17, -3],
        [15, -12],
        [10, -21],
        [5, -20],
        [-14, -18],
        [99, -4],
        [30, -12],
        [14, -49],
        [-28, -34],
        [-17, 5],
        [-16, 14],
        [-15, 1],
        [-44, -14],
        [-67, -46],
        [-38, -37],
        [-7, -24],
        [6, -65],
        [-5, -31],
        [-29, -20],
        [-64, 12],
        [-29, 13],
        [-33, 17],
        [-31, 24],
        [-41, 39],
        [-12, 4],
        [-8, -10],
        [13, -24],
        [28, -31],
        [47, -40],
        [22, -45],
        [-14, -23],
        [-18, -5],
        [-13, 1],
        [-41, 14],
        [-29, 3],
        [-90, -13],
        [-32, -8],
        [-11, 7],
        [-3, 19],
        [-48, 15],
        [-29, 2],
        [-13, 6],
        [-11, 21],
        [-32, 30],
        [-48, 11],
        [-31, 2],
        [-17, -7],
        [63, -39],
        [56, -67],
        [-11, -13],
        [-7, -14],
        [31, -1],
        [21, 4],
        [5, -17],
        [-16, -71],
        [-11, -15],
        [-98, -16],
        [25, -12],
        [25, -3],
        [29, 3],
        [26, -13],
        [17, -44],
        [3, -46],
        [-25, -27],
        [-27, -21],
        [-53, -33],
        [-56, -14],
        [-29, 3],
        [-28, -7],
        [-19, -17],
        [-5, -17],
        [24, 11],
        [28, -6],
        [27, -21],
        [-3, -17],
        [-26, -18],
        [-5, -14],
        [9, -24],
        [-3, -20],
        [13, -11],
        [30, -4],
        [36, -14],
        [36, -20],
        [14, -16],
        [12, -24],
        [4, -24],
        [-6, -11],
        [-82, -4],
        [-12, 3],
        [-5, 28],
        [-10, 22],
        [-31, 16],
        [-12, -11],
        [9, -79],
        [-12, -24],
        [-14, -18],
        [-41, -10],
        [-33, 6],
        [-28, 37],
        [0, 32],
        [19, 19],
        [0, 26],
        [-6, 30],
        [-18, -35],
        [-23, -29],
        [-35, -37],
        [-18, -4],
        [-17, 4],
        [-47, 26],
        [-29, 23],
        [-56, 74],
        [-32, 34],
        [-70, 46],
        [-72, 34],
        [-57, 22],
        [-31, -3],
        [-30, -9],
        [-39, 5],
        [-13, 8],
        [-11, 19],
        [-11, 10],
        [-54, 46],
        [-38, 37],
        [-2, 25],
        [8, 30],
        [-7, 73],
        [-18, 69],
        [-48, 68],
        [-126, 44],
        [-104, 31],
        [-37, 7],
        [-33, -6],
        [-87, -57],
        [-59, -8],
        [-170, -3],
        [-28, 6],
        [-26, 24],
        [-6, 33],
        [8, 59],
        [-1, 25],
        [-7, 9],
        [-9, -1],
        [-33, 24],
        [-31, 40],
        [-25, 41],
        [-16, 56],
        [22, 3],
        [31, -14],
        [5, 13],
        [10, 51],
        [21, 24],
        [9, 18],
        [14, 68],
        [2, 49],
        [-24, -26],
        [-39, -68],
        [-18, -20],
        [-14, -9],
        [-13, -4],
        [-30, 13],
        [-22, 17],
        [-1, 65],
        [-10, 17],
        [-10, -12],
        [-4, -24],
        [-28, -4],
        [-13, -10],
        [7, -39],
        [-3, -33],
        [-27, -13],
        [-53, -7],
        [-19, 32],
        [-16, -45],
        [-12, -51],
        [-2, -67],
        [17, -56],
        [25, -27],
        [53, -40],
        [23, -28],
        [6, -37],
        [-2, -33],
        [-28, -42],
        [-18, -34],
        [-33, -81],
        [-19, -33],
        [-82, -69],
        [99951, -19],
        [-49, -66],
        [-56, -57],
        [-83, -23],
        [-126, -87],
        [-49, -16],
        [-65, 40],
        [-149, 26],
        [-47, 35],
        [-68, 88],
        [-23, 13],
        [-21, 35],
        [-82, 39],
        [-72, -25],
        [-58, 19],
        [-20, -14],
        [30, -12],
        [54, -11],
        [82, 5],
        [27, -9],
        [24, -31],
        [28, -49],
        [-21, -32],
        [-22, -11],
        [-66, 36],
        [-76, -5],
        [-36, 9],
        [-102, 60],
        [-78, -67],
        [-107, -35],
        [-83, -4],
        [-152, -53],
        [41, -2],
        [111, 38],
        [65, 0],
        [96, 21],
        [51, 24],
        [24, 23],
        [31, 23],
        [31, -11],
        [22, -24],
        [14, -35],
        [14, -45],
        [-18, -24],
        [-18, -12],
        [-22, -33],
        [102, 56],
        [62, -33],
        [31, 5],
        [58, 49],
        [93, 32],
        [11, -6],
        [11, -16],
        [-15, -94],
        [6, -74],
        [71, -81],
        [73, -47],
        [26, -2],
        [24, 9],
        [9, 43],
        [18, 32],
        [23, -29],
        [19, -31],
        [27, -76],
        [-1, -23],
        [-6, -45],
        [23, -20],
        [32, -6],
        [12, -71],
        [11, -101],
        [-14, -9],
        [-16, 0],
        [-51, -26],
        [7, -18],
        [52, -10],
        [15, -21],
        [-11, -47],
        [3, -21],
        [18, -5],
        [12, 28],
        [-3, 39],
        [5, 17],
        [35, -81],
        [0, -31],
        [30, -36],
        [85, -54],
        [15, -24],
        [5, -40],
        [-21, -12],
        [-20, -28],
        [12, -40],
        [22, -32],
        [36, -12],
        [17, -52],
        [0, -49],
        [-26, -43],
        [-53, -59],
        [-31, -24],
        [-12, -42],
        [-3, -44],
        [-21, 2],
        [-23, 19],
        [-262, 110],
        [-99, 21],
        [-87, 2],
        [-16, 7],
        [1, 26],
        [5, 24],
        [13, 29],
        [-6, 26],
        [-11, 2],
        [-11, -20],
        [-24, 0],
        [-23, 23],
        [-19, -7],
        [-9, -32],
        [-7, -17],
        [0, -20],
        [12, -18],
        [50, -20],
        [-8, -18],
        [-70, -14],
        [-57, -18],
        [-74, -54],
        [-30, -38],
        [-198, -93],
        [-48, -32],
        [-21, -4],
        [-27, -11],
        [-21, -39],
        [-110, -55],
        [-23, 5],
        [-29, -46],
        [-27, -26],
        [-63, -3],
        [-41, -13],
        [-88, -67],
        [-55, 21],
        [-65, -91],
        [-72, -87],
        [-21, 0],
        [-55, 36],
        [-14, -19],
        [9, -35],
        [19, -37],
        [-11, -10],
        [-22, 10],
        [-16, 2],
        [-12, -11],
        [2, -27],
        [-31, -33],
        [-24, -3],
        [-28, -11],
        [-10, -29],
        [9, -32],
        [-50, -36],
        [-41, -48],
        [-19, -8],
        [-22, -21],
        [-24, -16],
        [-28, 3],
        [-67, -67],
        [-150, -117],
        [-42, -15],
        [-53, -36],
        [-5, -23],
        [0, -30],
        [-21, -48],
        [-25, -122],
        [-8, -22],
        [-12, -25],
        [-55, 12],
        [-48, 46],
        [-15, 22],
        [-8, 25],
        [-3, 40],
        [-9, 19],
        [-11, 9],
        [-55, 99],
        [-95, 68],
        [-14, 23],
        [-121, -18],
        [-33, -1],
        [-58, 17],
        [-90, -11],
        [-109, -37],
        [-33, -23],
        [-111, -36],
        [-73, -57],
        [-142, -208],
        [-34, -43],
        [-16, -9],
        [-24, -4],
        [-10, 42],
        [-4, 33],
        [9, 63],
        [17, 52],
        [17, 96],
        [5, 39],
        [12, 41],
        [-48, -3],
        [-66, -71],
        [-100, -69],
        [-46, -18],
        [-36, -41],
        [-26, -5],
        [-30, -15],
        [-3, -89],
        [-15, -48],
        [-18, -10],
        [-28, -2],
        [-21, 19],
        [-30, 71],
        [-40, 37],
        [-24, 7],
        [-18, -9],
        [-35, -48],
        [-38, -44],
        [6, 50],
        [-33, 19],
        [-29, 11],
        [-36, 2],
        [-11, -7],
        [-14, -29],
        [-33, -37],
        [-22, -15],
        [-23, -30],
        [-13, -31],
        [-12, -44],
        [-14, -107],
        [1, -125],
        [-53, -99],
        [-20, 10],
        [-10, -6],
        [-10, -13],
        [18, -55],
        [-10, -19],
        [-9, -13],
        [-25, -14],
        [-56, -81],
        [-53, -52],
        [-87, -151],
        [-25, -100],
        [-25, -113],
        [12, -55],
        [10, -36],
        [16, -25],
        [28, -27],
        [59, -29],
        [-5, -19],
        [0, -16],
        [21, 28],
        [15, 79],
        [37, 26],
        [18, -1],
        [118, -63],
        [23, -27],
        [-4, -60],
        [-7, -28],
        [-22, -42],
        [-42, -50],
        [-49, -69],
        [-5, -43],
        [0, -23],
        [13, -81],
        [1, -46],
        [-6, -85],
        [3, -37],
        [13, -31],
        [20, -20],
        [35, 11],
        [33, -9],
        [25, -22],
        [-4, -71],
        [15, -67],
        [11, -124],
        [-21, -34],
        [-20, -21],
        [-39, -54],
        [-21, -6],
        [-37, 18],
        [-58, 96],
        [23, 57],
        [50, 40],
        [23, 28],
        [17, 42],
        [-26, -8],
        [-18, -18],
        [-57, 8],
        [-23, -20],
        [-28, -32],
        [11, -80],
        [-19, -15],
        [-35, -27],
        [-52, -34],
        [-17, -23],
        [-45, -143],
        [-41, -107],
        [-15, -92],
        [2, -79],
        [15, -88],
        [11, -38],
        [48, -82],
        [23, -64],
        [7, -77],
        [-38, -37],
        [-67, -89],
        [-28, -10],
        [-92, 2],
        [-46, 45],
        [-54, -11],
        [-45, -22],
        [-71, -63],
        [-63, -82],
        [-60, -57],
        [-18, -34],
        [-24, -71],
        [-22, -129],
        [8, -64],
        [12, -30],
        [11, -39],
        [-16, -61],
        [0, -38],
        [29, -61],
        [6, -84],
        [-21, -2],
        [-49, 60],
        [-52, 4],
        [-124, -69],
        [-52, -40],
        [-57, -80],
        [-17, 15],
        [-12, 46],
        [-21, 20],
        [-26, -10],
        [-11, -43],
        [36, -20],
        [13, -28],
        [-21, -107],
        [-15, -36],
        [6, -95],
        [-2, -45],
        [-8, -45],
        [-37, -123],
        [-63, -162],
        [-78, -118],
        [-54, -41],
        [-27, -31],
        [-12, -41],
        [-80, -113],
        [-98, -119],
        [-28, -21],
        [-6, 45],
        [-3, 44],
        [-12, 59],
        [-36, 49],
        [-6, 40],
        [-6, 54],
        [-3, 252],
        [-31, 262],
        [-3, 82],
        [-38, 67],
        [-21, 70],
        [-12, 68],
        [-3, 81],
        [-41, 425],
        [-13, 106],
        [-55, 342],
        [-24, 198],
        [-16, 192],
        [-2, 86],
        [24, 257],
        [21, 160],
        [74, 359],
        [11, 33],
        [12, 17],
        [128, 138],
        [56, 75],
        [33, 80],
        [36, 101],
        [-4, 55],
        [-4, 31],
        [-14, 36],
        [-30, 41],
        [11, 18],
        [12, 14],
        [31, 15],
        [64, -31],
        [65, 13],
        [60, 127],
        [86, -19],
        [65, 22],
        [18, -9],
        [13, 43],
        [36, 46],
        [66, 68],
        [97, 82],
        [48, 55],
        [27, 52],
        [40, 50],
        [39, 62],
        [71, 186],
        [140, 154],
        [55, 88],
        [45, 31],
        [40, 15],
        [101, 126],
        [64, 105],
        [85, 72],
        [25, 46],
        [44, 108],
        [18, 26],
        [55, 41],
        [124, 71],
        [72, 71],
        [106, 10],
        [31, 28],
        [33, 16],
        [35, 23],
        [-43, 63],
        [10, 33],
        [8, 16],
        [76, 74],
        [31, 60],
        [-4, 25],
        [-5, 19],
        [-47, 24],
        [9, 55],
        [13, 48],
        [38, 40],
        [13, 94],
        [2, 99],
        [37, 142],
        [22, 32],
        [87, 71],
        [20, 2],
        [60, -24],
        [66, -12],
        [23, -22],
        [5, 19],
        [-3, 24],
        [18, 9],
        [38, -10],
        [-5, 27],
        [-98, 14],
        [-70, 31],
        [-63, 60],
        [-41, 17],
        [-45, -5],
        [-257, -84],
        [-12, -23],
        [-12, -32],
        [16, -47],
        [-13, -21],
        [-12, -14],
        [-14, -31],
        [-12, -61],
        [3, -59],
        [-31, -92],
        [-3, -57],
        [55, -32],
        [14, -21],
        [-16, -32],
        [-17, -19],
        [-14, -24],
        [-11, -9],
        [-15, -6],
        [-20, 31],
        [-18, 62],
        [-29, 4],
        [-10, -10],
        [-5, -24],
        [-25, -2],
        [-28, 12],
        [-32, -5],
        [-57, -70],
        [-319, -333],
        [-34, -40],
        [-42, -80],
        [-79, -8],
        [-31, -18],
        [-23, -24],
        [-31, -16],
        [1, 30],
        [6, 22],
        [7, 59],
        [43, 111],
        [-27, 12],
        [-27, 1],
        [-50, -23],
        [-34, -37],
        [-26, 8],
        [13, 33],
        [31, 66],
        [-9, 61],
        [-9, 33],
        [13, 18],
        [65, 123],
        [23, 64],
        [20, 84],
        [1, 26],
        [-4, 32],
        [-19, 5],
        [-16, 0],
        [-128, -83],
        [-47, -23],
        [-15, 36],
        [-21, 15],
        [-35, 61],
        [-30, 9],
        [-31, -4],
        [-70, -41],
        [-77, -23],
        [-59, 8],
        [-51, -35],
        [-23, -6],
        [-75, 24],
        [-91, 2],
        [-28, -30],
        [-79, -41],
        [-54, -64],
        [-28, -20],
        [-31, -28],
        [-14, -120],
        [-41, -39],
        [-38, -29],
        [-80, -92],
        [-56, -126],
        [-38, -54],
        [-81, -76],
        [-126, -100],
        [-110, -162],
        [-38, -122],
        [-14, -4],
        [-27, -25],
        [-7, -60],
        [1, -41],
        [-17, -33],
        [-17, -42],
        [18, -26],
        [16, -5],
        [24, 4],
        [63, 34],
        [108, -52],
        [54, -52],
        [-4, -52],
        [2, -46],
        [-40, 3],
        [-53, -4],
        [-34, -27],
        [-68, 43],
        [-23, -16],
        [-36, -46],
        [-65, -19],
        [-33, 23],
        [-55, 63],
        [-93, -6],
        [-24, -71],
        [-21, 2],
        [-33, -7],
        [-55, -82],
        [-18, -8],
        [-67, 16],
        [-48, 42],
        [-23, 2],
        [-43, -19],
        [-21, -50],
        [-107, -25],
        [-105, 5],
        [-57, 119],
        [107, 47],
        [63, -10],
        [72, 8],
        [75, 37],
        [-26, 31],
        [-18, 7],
        [-45, -4],
        [-40, 23],
        [-87, 116],
        [-38, 21],
        [-49, 13],
        [-38, 1],
        [-14, -8],
        [-20, -28],
        [-13, -27],
        [-12, -9],
        [-26, 5],
        [-31, 22],
        [-37, -7],
        [16, 17],
        [35, 18],
        [-58, 20],
        [-37, 28],
        [-34, 7],
        [-156, 69],
        [-60, -6],
        [-37, -19],
        [-63, -56],
        [17, -41],
        [14, -19],
        [8, -22],
        [-20, -4],
        [-58, -4],
        [-35, 33],
        [-25, -48],
        [10, -43],
        [40, 15],
        [21, -18],
        [-14, -49],
        [-52, -15],
        [-65, 3],
        [-65, 87],
        [-107, -15],
        [-51, -56],
        [-49, -13],
        [-131, 56],
        [-66, 5],
        [-74, 48],
        [-26, -15],
        [-47, -121],
        [-63, -29],
        [-32, 16],
        [-29, 76],
        [-20, 25],
        [-56, 23],
        [-298, -22],
        [-100, 19],
        [-70, 2],
        [-96, -39],
        [-92, 15],
        [-170, -75],
        [-70, -50],
        [-84, -88],
        [-76, -147],
        [-42, -55],
        [-71, -70],
        [-100, -64],
        [-54, -65],
        [-30, -53],
        [-52, -200],
        [-14, -30],
        [-123, -72],
        [-39, -80],
        [-17, -20],
        [-51, -34],
        [-31, -56],
        [-17, -16],
        [-73, -40],
        [-60, -100],
        [-85, -70],
        [-123, -194],
        [-11, -23],
        [-10, -52],
        [-19, -38],
        [-106, -169],
        [-33, -16],
        [-53, -79],
        [-54, -47],
        [-49, -56],
        [-61, -59],
        [-92, -67],
        [-31, -39],
        [-48, -90],
        [-118, -111],
        [-59, -27],
        [-77, -98],
        [-8, -23],
        [-6, -36],
        [13, -63],
        [19, -14],
        [31, -9],
        [115, -62],
        [107, 17],
        [95, 0],
        [37, 6],
        [23, -2],
        [8, -34],
        [-1, -63],
        [-14, -57],
        [-11, -168],
        [-13, -75],
        [10, -73],
        [23, -13],
        [23, 33],
        [37, 5],
        [38, -15],
        [28, 117],
        [-23, 18],
        [-22, 42],
        [13, 31],
        [66, 57],
        [41, 5],
        [39, -4],
        [-43, -73],
        [-17, -15],
        [-13, -4],
        [-18, -14],
        [38, -42],
        [41, -32],
        [59, -14],
        [-14, -25],
        [-39, -22],
        [-36, -91],
        [-56, -44],
        [-26, -30],
        [9, -19],
        [21, -4],
        [114, 12],
        [59, 25],
        [84, 71],
        [35, 105],
        [32, 29],
        [9, 0],
        [11, -7],
        [1, -74],
        [-45, -83],
        [-32, -47],
        [-12, -40],
        [19, 0],
        [37, 8],
        [16, 19],
        [42, 101],
        [11, 72],
        [6, 100],
        [-5, 60],
        [3, 43],
        [-16, 43],
        [11, 13],
        [113, -59],
        [60, -14],
        [108, 48],
        [24, -12],
        [18, -29],
        [89, -89],
        [17, -29],
        [29, -108],
        [95, -125],
        [88, -59],
        [3, -23],
        [56, -69],
        [44, -24],
        [7, -62],
        [-20, -50],
        [-41, -48],
        [-82, 44],
        [-13, -2],
        [10, -28],
        [59, -81],
        [47, -34],
        [3, -108],
        [-6, -59],
        [-31, -66],
        [10, -38],
        [44, -56],
        [22, -22],
        [22, -32],
        [-28, -70],
        [-5, -78],
        [-30, -35],
        [-36, -76],
        [-55, -62],
        [-26, -119],
        [-42, -105],
        [-5, -104],
        [-7, -37],
        [-34, -108],
        [-13, -146],
        [17, -239],
        [8, -14],
        [16, -14],
        [-3, -17],
        [-8, -11],
        [-33, -71],
        [0, -49],
        [13, -37],
        [2, -95],
        [-24, -153],
        [-9, -24],
        [-10, -39],
        [-4, -36],
        [-7, -22],
        [-4, -39],
        [7, -34],
        [12, -17],
        [-43, -110],
        [-15, -144],
        [-16, -58],
        [-31, -57],
        [-66, -83],
        [-24, -52],
        [-43, -66],
        [-41, -51],
        [-57, -145],
        [-46, -145],
        [-116, -188],
        [-15, -46],
        [-9, -50],
        [-30, -84],
        [-15, -116],
        [-35, -46],
        [-29, -122],
        [-94, -186],
        [-23, -63],
        [-72, -103],
        [-77, -142],
        [-96, -128],
        [-18, -53],
        [-37, -58],
        [-40, -90],
        [-58, -90],
        [-12, -60],
        [-19, -42],
        [-43, -28],
        [-31, -39],
        [-95, -231],
        [-12, -42],
        [-2, -37],
        [-62, -86],
        [-35, -92],
        [-60, -57],
        [-62, -78],
        [-149, -144],
        [-41, -54],
        [-83, -68],
        [-34, -1],
        [-72, -37],
        [-47, -38],
        [-28, 14],
        [-17, 50],
        [-21, -2],
        [-16, -7],
        [-43, 48],
        [-37, -3],
        [-26, 22],
        [-50, -15],
        [9, 205],
        [-7, 43],
        [-21, -40],
        [-57, -72],
        [-23, -14],
        [-22, 0],
        [9, 44],
        [31, 62],
        [-10, 10],
        [-10, 3],
        [-40, -27],
        [-20, -30],
        [-58, -119],
        [-34, -100],
        [-28, -29],
        [-13, -43],
        [-24, -41],
        [-37, 11],
        [-22, -7],
        [-53, 23],
        [-13, -10],
        [35, -77],
        [-29, -113],
        [-12, -14],
      ],
      [
        [63178, 98417],
        [127, -23],
        [95, 6],
        [29, -3],
        [28, -7],
        [28, -16],
        [38, -35],
        [0, -49],
        [-17, -2],
        [-161, 22],
        [-74, 51],
        [-20, 5],
        [-29, -13],
        [-25, -31],
        [-27, -8],
        [-31, -39],
        [-29, 5],
        [-15, -4],
        [-37, -27],
        [-93, 0],
        [-15, -12],
        [-30, -38],
        [-38, -10],
        [-66, -6],
        [-22, 21],
        [-10, 33],
        [-15, 17],
        [-92, -19],
        [-69, 13],
        [-67, 22],
        [-68, 8],
        [61, 23],
        [334, 48],
        [131, 12],
        [62, 34],
        [92, 22],
        [25, 0],
      ],
      [
        [63966, 98460],
        [42, -10],
        [103, 2],
        [32, -14],
        [149, -84],
        [38, -2],
        [31, -30],
        [-154, -49],
        [-52, -36],
        [-189, -8],
        [-121, -17],
        [-26, -15],
        [12, -26],
        [-58, -29],
        [-191, -4],
        [-24, -9],
        [-37, -31],
        [3, -5],
        [65, -8],
        [10, -6],
        [9, -14],
        [5, -20],
        [-9, -26],
        [-24, -4],
        [-26, 3],
        [-60, 20],
        [-7, -7],
        [-6, -14],
        [-19, -28],
        [-22, -8],
        [-61, 21],
        [-20, -6],
        [-19, -13],
        [-24, -6],
        [-56, -4],
        [-29, 17],
        [25, 23],
        [70, 35],
        [-23, 15],
        [-70, 4],
        [-55, -9],
        [-28, -24],
        [-26, -5],
        [-72, 2],
        [-40, 31],
        [-30, 12],
        [-26, 25],
        [211, 83],
        [70, 32],
        [67, 16],
        [87, 9],
        [27, 10],
        [27, 4],
        [17, -6],
        [44, -29],
        [129, 5],
        [27, 24],
        [2, 56],
        [-13, 33],
        [27, 63],
        [74, 25],
        [171, 34],
        [43, 2],
      ],
      [
        [68823, 95758],
        [-111, -44],
        [-66, -30],
        [-65, -21],
        [-66, -15],
        [-104, -36],
        [-184, -46],
        [-116, -37],
        [-127, -30],
        [-134, -39],
        [-134, -27],
        [-33, -2],
        [-96, -38],
        [-75, -16],
        [-272, -85],
        [-125, -63],
        [-36, -5],
        [-36, 3],
        [-30, -20],
        [-28, -33],
        [-59, -34],
        [-30, -31],
        [-30, -24],
        [-18, -8],
        [-34, 2],
        [-16, -2],
        [-55, -27],
        [-10, -22],
        [59, -14],
        [13, -24],
        [-17, -16],
        [-38, -23],
        [-22, -23],
        [-40, -23],
        [-27, -7],
        [-65, 1],
        [-4, -29],
        [11, -18],
        [-6, -16],
        [-22, -15],
        [-21, 2],
        [-99, 45],
        [-16, -15],
        [-7, -32],
        [-3, -34],
        [-13, -25],
        [-17, -13],
        [-31, -13],
        [-109, 21],
        [-9, -20],
        [17, -25],
        [23, -54],
        [5, -22],
        [-13, -36],
        [-49, -56],
        [-187, -67],
        [3, -23],
        [21, -44],
        [5, -27],
        [-8, -26],
        [-24, -21],
        [-28, 0],
        [-15, 4],
        [-43, 29],
        [-37, 7],
        [-7, -14],
        [49, -39],
        [21, -51],
        [-23, -27],
        [-90, -61],
        [-47, -80],
        [-92, -35],
        [-57, -5],
        [-56, 10],
        [-53, 18],
        [-135, 7],
        [-75, 20],
        [-76, 36],
        [-66, -3],
        [-56, -18],
        [-74, -39],
        [-47, 75],
        [20, 35],
        [-101, 90],
        [-22, 40],
        [25, 19],
        [31, 13],
        [59, 36],
        [59, 29],
        [61, 9],
        [10, 5],
        [25, 43],
        [27, 35],
        [25, 20],
        [28, 33],
        [89, 134],
        [21, 10],
        [184, 26],
        [16, 8],
        [-53, 27],
        [-54, -1],
        [-25, 9],
        [-14, 19],
        [-8, 22],
        [21, 16],
        [71, 81],
        [84, 58],
        [78, 35],
        [-19, 9],
        [-25, 23],
        [-95, -6],
        [-37, 32],
        [-12, 19],
        [-3, 19],
        [31, 25],
        [32, 15],
        [35, -4],
        [35, -13],
        [28, -15],
        [27, -24],
        [23, 1],
        [85, 84],
        [-13, 19],
        [-5, 29],
        [9, 14],
        [41, 13],
        [27, 5],
        [59, -6],
        [85, -18],
        [7, 9],
        [21, 56],
        [21, 30],
        [86, 50],
        [-5, 15],
        [-4, 25],
        [100, 33],
        [65, 33],
        [63, 45],
        [32, 9],
        [32, 2],
        [65, 19],
        [121, 22],
        [71, 22],
        [23, 48],
        [45, 17],
        [90, 7],
        [35, -3],
        [20, -20],
        [39, 1],
        [15, 11],
        [16, 17],
        [-5, 29],
        [-1, 37],
        [34, 23],
        [13, 5],
        [102, 9],
        [60, -4],
        [125, -28],
        [65, -7],
        [87, 9],
        [52, -5],
        [154, 42],
        [261, 39],
        [68, 28],
        [67, 33],
        [34, 7],
        [35, 2],
        [31, 11],
        [61, 28],
        [30, 7],
        [33, 0],
        [30, 20],
        [26, 43],
        [29, 33],
        [79, 43],
        [134, 60],
        [121, 23],
        [75, 25],
        [32, 2],
        [102, -12],
        [130, -33],
        [59, -36],
        [49, -47],
        [10, -17],
        [9, -30],
        [-14, -28],
        [-9, -28],
        [11, -22],
        [-95, -71],
        [-93, -78],
        [-16, -17],
        [-111, -27],
      ],
      [
        [65366, 94071],
        [130, -23],
        [97, -7],
        [59, -18],
        [22, -14],
        [-9, -36],
        [-17, -14],
        [-41, -47],
        [-6, -28],
        [7, -45],
        [-6, -33],
        [-13, -24],
        [-11, -10],
        [-73, 0],
        [-27, -13],
        [-1, -26],
        [-5, -29],
        [-24, -42],
        [-48, -13],
        [-11, -16],
        [4, -27],
        [-17, -21],
        [1, -32],
        [9, -18],
        [2, -37],
        [33, -54],
        [-6, -22],
        [-26, -43],
        [-8, -53],
        [-21, -46],
        [48, -38],
        [21, -50],
        [19, -53],
        [57, -105],
        [62, -94],
        [114, -137],
        [122, -104],
        [48, -29],
        [116, -48],
        [20, -16],
        [19, -21],
        [-49, -39],
        [-51, -14],
        [-5, -18],
        [-28, -10],
        [-138, 34],
        [-7, 5],
        [-15, 24],
        [-16, 18],
        [-35, 3],
        [-35, -11],
        [21, -22],
        [23, -7],
        [40, -41],
        [-17, -16],
        [-18, -2],
        [-82, 55],
        [-7, -7],
        [-8, -16],
        [-40, 18],
        [-10, -13],
        [-31, -6],
        [-25, 15],
        [0, 19],
        [-5, 10],
        [-125, -15],
        [-52, 0],
        [-51, 7],
        [-61, 36],
        [-11, -16],
        [-2, -19],
        [-23, 8],
        [-51, 29],
        [-37, 12],
        [-133, 28],
        [-94, 34],
        [24, 16],
        [40, 8],
        [1, 21],
        [-7, 29],
        [-1, 29],
        [23, 20],
        [52, -9],
        [-7, 32],
        [25, 6],
        [47, -18],
        [18, 11],
        [-75, 41],
        [-82, 58],
        [8, 21],
        [-29, 5],
        [-30, -1],
        [-25, 34],
        [4, 45],
        [22, 31],
        [-13, 6],
        [-127, -27],
        [-64, 6],
        [-72, 18],
        [-66, -26],
        [-67, -9],
        [-35, 9],
        [-34, 20],
        [-28, 26],
        [-22, 45],
        [-20, 74],
        [-3, 28],
        [4, 62],
        [11, 27],
        [28, 52],
        [19, 16],
        [43, 25],
        [22, 6],
        [51, -12],
        [51, -1],
        [22, 14],
        [21, 25],
        [15, 32],
        [35, 18],
        [10, 10],
        [11, 20],
        [12, 31],
        [2, 27],
        [10, 26],
        [32, 39],
        [-12, 24],
        [5, 16],
        [21, 29],
        [-64, 7],
        [-22, 13],
        [-21, 19],
        [6, 18],
        [8, 13],
        [64, 48],
        [28, 15],
        [31, 7],
        [31, 0],
        [33, -5],
        [32, 7],
        [-34, 33],
        [-3, 22],
        [-13, 53],
        [3, 25],
        [14, 21],
        [30, 24],
        [43, 8],
        [34, 12],
        [33, 19],
        [31, 3],
        [63, -12],
        [31, 2],
        [34, 11],
        [97, 40],
        [36, 11],
        [38, -3],
        [50, -15],
        [55, -28],
      ],
      [
        [76812, 98545],
        [10, -26],
        [36, -21],
        [18, -21],
        [183, -67],
        [80, -8],
        [36, -17],
        [10, -20],
        [-3, -37],
        [-31, 0],
        [-22, -12],
        [-124, -15],
        [-30, -22],
        [-24, -45],
        [13, -9],
        [12, -13],
        [37, -77],
        [10, -12],
        [37, -11],
        [-33, -29],
        [-35, -19],
        [-366, -37],
        [-249, -15],
        [-83, -16],
        [-27, 2],
        [-65, -29],
        [-127, -38],
        [-61, 0],
        [-181, 53],
        [-222, 45],
        [-31, 25],
        [-55, 15],
        [-71, 12],
        [-31, 51],
        [45, 35],
        [58, 34],
        [97, 12],
        [92, 20],
        [69, 49],
        [43, 48],
        [78, 51],
        [-135, -13],
        [-51, 7],
        [5, 17],
        [28, 36],
        [15, 12],
        [49, 19],
        [35, 36],
        [81, 25],
        [39, 4],
        [38, -1],
        [70, 12],
        [70, 18],
        [66, 10],
        [65, 5],
        [63, 14],
        [62, 28],
        [27, 48],
        [179, 5],
        [28, -11],
        [22, -29],
        [26, -10],
        [31, -6],
        [79, -48],
        [15, -14],
      ],
      [
        [77131, 98017],
        [64, -37],
        [31, -41],
        [-30, -11],
        [-28, -28],
        [-13, -34],
        [-37, -27],
        [-10, -43],
        [17, -8],
        [20, 12],
        [41, 41],
        [53, 28],
        [58, -16],
        [23, 6],
        [40, 39],
        [-7, 33],
        [16, 20],
        [18, 5],
        [75, -4],
        [119, -17],
        [21, -17],
        [29, -10],
        [17, -16],
        [53, -13],
        [26, -12],
        [35, -28],
        [32, -42],
        [-40, -22],
        [-22, -40],
        [-9, -9],
        [-7, -15],
        [-3, -35],
        [-6, -30],
        [-7, -13],
        [-5, -17],
        [5, -45],
        [-12, -35],
        [-40, -27],
        [-41, -1],
        [-61, 18],
        [-18, -1],
        [-17, -7],
        [76, -38],
        [56, -56],
        [65, -13],
        [18, -6],
        [23, -52],
        [8, -26],
        [-108, -62],
        [-28, -11],
        [-172, -9],
        [-113, -18],
        [-36, 4],
        [-63, 15],
        [-42, -6],
        [-60, 10],
        [-37, 0],
        [-85, 23],
        [-88, 38],
        [-17, 18],
        [-18, 12],
        [-105, 10],
        [-23, 8],
        [-153, -9],
        [-26, 6],
        [-47, 50],
        [-27, 1],
        [-84, -29],
        [-31, 2],
        [-64, 19],
        [-38, 24],
        [-6, 8],
        [-4, 29],
        [-38, 15],
        [-46, 52],
        [-27, 54],
        [-128, 28],
        [-77, 7],
        [-58, -2],
        [-56, 21],
        [93, 78],
        [123, 41],
        [53, 31],
        [61, 42],
        [25, 65],
        [103, 40],
        [27, 14],
        [36, 31],
        [12, 4],
        [81, -38],
        [16, 7],
        [15, 17],
        [29, 19],
        [100, 3],
        [85, -8],
        [32, 8],
        [39, -3],
        [195, 28],
        [130, 8],
        [24, -5],
      ],
      [
        [78578, 97496],
        [-27, -45],
        [-11, -40],
        [-83, -133],
        [-10, -23],
        [49, 20],
        [44, 45],
        [27, 38],
        [30, 24],
        [34, 0],
        [35, 9],
        [65, 31],
        [66, 14],
        [36, -1],
        [34, -15],
        [22, -35],
        [24, -28],
        [87, -21],
        [13, -7],
        [8, -24],
        [-7, -25],
        [50, -25],
        [69, 11],
        [37, -7],
        [36, -14],
        [17, -22],
        [14, -27],
        [15, -39],
        [9, -41],
        [-8, -55],
        [-134, -84],
        [-25, -8],
        [-62, 6],
        [-61, -9],
        [-161, -44],
        [-199, -1],
        [-57, -39],
        [-17, 1],
        [-17, 7],
        [-16, 13],
        [-121, -11],
        [-136, -7],
        [-136, -1],
        [-45, -28],
        [-139, -55],
        [-127, -42],
        [-66, -10],
        [-96, 11],
        [-30, 14],
        [-29, 21],
        [42, 27],
        [30, 54],
        [37, 32],
        [94, 61],
        [11, 24],
        [18, 51],
        [11, 20],
        [15, 18],
        [11, 22],
        [2, 33],
        [6, 28],
        [37, 42],
        [27, 20],
        [29, 6],
        [71, -8],
        [22, 3],
        [-16, 13],
        [-11, 50],
        [2, 16],
        [10, 31],
        [18, 15],
        [18, 10],
        [11, 42],
        [-5, 15],
        [27, 20],
        [13, 27],
        [32, 16],
        [65, 13],
        [3, 34],
        [10, 21],
        [14, 7],
        [33, 6],
        [18, -1],
        [24, -34],
        [26, -28],
        [34, -6],
        [34, 2],
        [-20, 33],
        [1, 35],
        [12, 22],
        [16, 10],
        [34, 2],
        [107, -24],
        [70, -35],
        [16, -18],
        [-13, -10],
        [-31, -6],
        [-16, -10],
      ],
      [
        [88902, 95523],
        [28, -11],
        [34, 7],
        [32, -15],
        [30, -61],
        [14, -16],
        [15, -11],
        [15, -5],
        [45, -2],
        [20, 12],
        [15, 28],
        [-1, 28],
        [-4, 29],
        [0, 39],
        [7, 35],
        [10, 21],
        [13, 14],
        [74, 43],
        [52, 42],
        [71, -16],
        [72, -38],
        [127, -80],
        [59, -23],
        [71, -21],
        [72, -8],
        [35, 5],
        [69, 22],
        [35, 2],
        [436, -160],
        [15, -13],
        [14, -19],
        [-94, -24],
        [-61, -42],
        [-21, -29],
        [24, -24],
        [20, -32],
        [-133, -96],
        [-53, -25],
        [-54, -8],
        [-110, 23],
        [-64, -1],
        [-62, 20],
        [-69, 58],
        [-29, 29],
        [-25, 40],
        [-8, 64],
        [10, 55],
        [37, 16],
        [32, 35],
        [5, 15],
        [-17, 31],
        [-108, 5],
        [-68, -17],
        [-61, -18],
        [18, -117],
        [13, -37],
        [18, -27],
        [98, -123],
        [22, -17],
        [65, -24],
        [55, -53],
        [-97, -59],
        [-43, -17],
        [-42, -10],
        [-26, 5],
        [-26, 12],
        [-28, 28],
        [-24, 30],
        [-31, 23],
        [-66, -5],
        [-61, -20],
        [-61, -14],
        [-180, -24],
        [-55, -15],
        [-55, -5],
        [-71, 28],
        [-70, 39],
        [-22, 1],
        [-20, -11],
        [-17, -24],
        [-9, -38],
        [-23, -51],
        [-29, -36],
        [-31, -16],
        [-32, -2],
        [-33, 10],
        [-32, 16],
        [-215, 56],
        [-25, 17],
        [-24, 25],
        [-65, 79],
        [-32, 19],
        [-33, 8],
        [-64, 40],
        [-59, 64],
        [-12, 20],
        [-4, 32],
        [10, 23],
        [51, -11],
        [34, 1],
        [-21, 119],
        [15, 112],
        [25, 19],
        [97, -13],
        [-31, 37],
        [-26, 49],
        [16, 27],
        [18, 19],
        [42, 15],
        [56, 7],
        [17, 12],
        [16, 19],
        [31, 20],
        [62, 8],
        [107, 40],
        [29, -1],
        [27, -21],
        [26, -30],
        [28, -16],
        [88, -39],
        [60, -35],
        [85, -71],
      ],
      [
        [90776, 95259],
        [73, -4],
        [107, 43],
        [14, 1],
        [260, -15],
        [22, -15],
        [2, -30],
        [-8, -15],
        [-4, -22],
        [32, -21],
        [84, -4],
        [53, 19],
        [156, -10],
        [128, -14],
        [49, -32],
        [38, -17],
        [31, -20],
        [23, 12],
        [22, 20],
        [18, 4],
        [18, -4],
        [-49, -122],
        [-18, -14],
        [-69, -30],
        [-137, -42],
        [-67, -13],
        [-152, 0],
        [-209, 16],
        [-57, 15],
        [-34, 18],
        [-64, 43],
        [-31, 16],
        [-103, 14],
        [-32, 8],
        [-60, 37],
        [-62, 30],
        [-154, 49],
        [10, 56],
        [20, 56],
        [24, 50],
        [26, 45],
        [28, 13],
        [59, -41],
        [-1, -47],
        [14, -33],
      ],
      [
        [89655, 83175],
        [60, -145],
        [2, -32],
        [-5, -33],
        [-11, -42],
        [-4, -44],
        [7, -39],
        [-5, -10],
        [49, -176],
        [35, -111],
        [11, -45],
        [7, -48],
        [11, -99],
        [2, -151],
        [-3, -50],
        [-8, -49],
        [-8, -29],
        [-18, -20],
        [-8, -54],
        [-4, -153],
        [9, -80],
        [17, -56],
        [12, -60],
        [2, -64],
        [5, -29],
        [27, -36],
        [11, -28],
        [3, -40],
        [2, -59],
        [4, -13],
        [13, -18],
        [56, -426],
        [22, -129],
        [64, -223],
        [26, -135],
        [17, -64],
        [11, -68],
        [9, -69],
        [19, -76],
        [25, -74],
        [49, -67],
        [22, -37],
        [5, -30],
        [2, -103],
        [-11, 22],
        [-14, 79],
        [-24, 45],
        [-35, 54],
        [-35, 48],
        [-44, 80],
        [-21, 23],
        [-23, 16],
        [-41, 18],
        [-24, 2],
        [-98, -12],
        [-40, -16],
        [-37, -37],
        [-21, -54],
        [-16, -108],
        [-89, -386],
        [-21, -101],
        [-8, -108],
        [3, -85],
        [6, -31],
        [26, -84],
        [21, -49],
        [15, -21],
        [18, -14],
        [11, -17],
        [10, -23],
        [18, -57],
        [23, -128],
        [25, -91],
        [11, -28],
        [28, 7],
        [18, -1],
        [18, -8],
        [10, -23],
        [15, -101],
        [11, -98],
        [1, -26],
        [-20, -75],
        [-5, -32],
        [-3, -34],
        [-5, -27],
        [-9, -23],
        [-3, 111],
        [-14, 79],
        [-5, 68],
        [-19, 47],
        [-65, 20],
        [-61, 7],
        [-9, 9],
        [-14, 29],
        [-15, 23],
        [-16, 3],
        [-16, -9],
        [-27, -33],
        [-21, -51],
        [-15, -55],
        [-13, -59],
        [-27, -155],
        [-16, -51],
        [-20, -48],
        [-17, 26],
        [-15, 30],
        [-9, 43],
        [-4, 48],
        [-24, 161],
        [10, 140],
        [41, 194],
        [7, 63],
        [-6, 60],
        [-9, 60],
        [-6, 113],
        [1, 25],
        [14, 65],
        [17, 62],
        [20, 54],
        [9, 64],
        [-13, 159],
        [-29, 108],
        [-37, 102],
        [-7, 28],
        [-2, 27],
        [32, 128],
        [11, 62],
        [13, 134],
        [12, 74],
        [9, 74],
        [3, 373],
        [-3, 55],
        [-20, 117],
        [-1, 67],
        [9, 84],
        [13, 65],
        [17, 63],
        [0, 129],
        [-32, 119],
        [-24, 52],
        [-37, 63],
        [-28, 35],
        [-14, 27],
        [14, 9],
        [10, 21],
        [-24, 33],
        [-15, 50],
        [-2, 196],
        [6, 49],
        [18, 55],
        [16, 59],
        [14, 137],
        [5, 141],
        [-9, 57],
        [-4, 116],
        [8, 29],
        [31, 38],
        [49, 23],
        [11, -7],
        [38, -45],
        [14, -2],
        [15, 5],
        [29, 21],
        [16, 52],
        [-21, 29],
        [12, 37],
        [35, 13],
        [3, 32],
        [-13, 4],
        [11, 46],
        [7, 45],
        [-10, 42],
        [-57, 104],
        [-36, 76],
        [60, -1],
        [18, 14],
        [14, 32],
        [7, 33],
        [19, -13],
      ],
      [
        [0, 93051],
        [43, 8],
        [43, 15],
        [40, 3],
        [40, -9],
        [41, 2],
        [40, 15],
        [32, -2],
        [33, -9],
        [122, -21],
        [23, -7],
        [39, -27],
        [22, -10],
        [22, -16],
        [23, -27],
        [43, -29],
        [65, -34],
        [14, -10],
        [10, -26],
        [-7, -30],
        [-83, -57],
        [-67, -15],
        [-129, -15],
        [-175, -43],
        [-72, -12],
        [-25, 2],
        [-63, 28],
        [-74, 12],
        [99966, -10],
        [-65, -44],
        [-137, -11],
        [-81, -31],
        [-19, -2],
        [-40, 103],
        [-6, 26],
        [15, 34],
        [41, 42],
        [17, 30],
        [96, 54],
        [86, 71],
        [47, 11],
        [47, 33],
        [-99967, 8],
      ],
      [
        [59948, 89390],
        [9, -23],
        [3, -37],
        [-9, -24],
        [5, -20],
        [-18, -14],
        [-27, 46],
        [-17, 1],
        [-17, 20],
        [-8, 33],
        [15, 9],
        [7, -6],
        [33, 24],
        [24, -9],
      ],
      [
        [61864, 90265],
        [-10, -8],
        [-55, 27],
        [-5, 21],
        [2, 8],
        [22, 6],
        [23, -7],
        [17, -27],
        [6, -20],
      ],
      [
        [64695, 92951],
        [25, -42],
        [22, -31],
        [19, 3],
        [14, -16],
        [4, -32],
        [-38, -54],
        [-6, -20],
        [16, -18],
        [4, -10],
        [-27, -8],
        [-5, 25],
        [-15, 24],
        [-32, 19],
        [-13, 16],
        [-14, 38],
        [-53, 40],
        [-34, -6],
        [-38, 18],
        [-11, 8],
        [-3, 23],
        [16, 19],
        [60, 16],
        [29, -1],
        [31, -16],
        [-2, 20],
        [3, 8],
        [13, -3],
        [35, -20],
      ],
      [
        [96677, 83274],
        [11, -30],
        [-112, 106],
        [-52, 56],
        [-13, 36],
        [19, 0],
        [23, -34],
        [32, -16],
        [48, -46],
        [44, -72],
      ],
      [
        [94643, 92639],
        [-19, -10],
        [-40, 8],
        [-19, 18],
        [1, 42],
        [2, 6],
        [33, -6],
        [22, -23],
        [20, -35],
      ],
      [
        [94851, 91532],
        [-12, -1],
        [9, 39],
        [1, 17],
        [-27, 28],
        [-50, 21],
        [-13, 17],
        [-3, 50],
        [11, 78],
        [-23, 42],
        [8, 37],
        [59, 41],
        [24, 31],
        [27, 25],
        [4, -3],
        [27, -24],
        [-2, -52],
        [-20, -37],
        [-46, -14],
        [-6, -25],
        [6, -44],
        [1, -57],
        [5, -51],
        [28, -52],
        [6, -26],
        [-3, -25],
        [-11, -15],
      ],
      [
        [92467, 95691],
        [-27, -20],
        [-64, 33],
        [24, 18],
        [43, 12],
        [10, -6],
        [8, -13],
        [6, -24],
      ],
      [
        [91430, 96001],
        [-209, -6],
        [14, 16],
        [75, 40],
        [191, 21],
        [-38, -20],
        [-18, -41],
        [-15, -10],
      ],
      [
        [88321, 93034],
        [-69, -49],
        [-27, 6],
        [-28, 24],
        [-15, 5],
        [-15, -4],
        [-17, -10],
        [-22, -2],
        [-51, 26],
        [-5, 16],
        [5, 8],
        [14, 8],
        [10, 0],
        [32, 13],
        [149, 5],
        [11, -3],
        [21, -23],
        [7, -20],
      ],
      [
        [79915, 96849],
        [-25, -28],
        [-35, -14],
        [-38, 23],
        [-95, -1],
        [-163, 25],
        [47, 16],
        [257, 13],
        [18, -3],
        [34, -31],
      ],
      [
        [81243, 95979],
        [43, -39],
        [8, -25],
        [-13, -6],
        [-8, -9],
        [-3, -18],
        [-12, -1],
        [-38, 19],
        [-27, 31],
        [-40, 7],
        [-42, 31],
        [-9, 13],
        [11, 4],
        [75, -8],
        [32, 14],
        [23, -13],
      ],
      [
        [76814, 95782],
        [22, -9],
        [-6, -24],
        [-29, 7],
        [-38, -12],
        [1, -20],
        [-15, -33],
        [-53, 20],
        [-73, 3],
        [-46, 19],
        [-102, 12],
        [3, 27],
        [16, 16],
        [59, -23],
        [54, 26],
        [101, -13],
        [33, 19],
        [73, -15],
      ],
      [
        [76745, 96213],
        [-9, -11],
        [-12, -9],
        [-33, 6],
        [-65, -16],
        [-25, 9],
        [-24, 18],
        [-88, -6],
        [-26, 4],
        [42, 22],
        [120, 24],
        [188, 62],
        [9, -30],
        [0, -14],
        [-38, -34],
        [-39, -25],
      ],
      [
        [74864, 96306],
        [-59, -3],
        [-34, 15],
        [-10, 10],
        [16, 26],
        [23, 17],
        [93, 6],
        [17, -18],
        [-4, -15],
        [-42, -38],
      ],
      [
        [70738, 93820],
        [-6, -5],
        [-14, 10],
        [-42, 15],
        [-71, 39],
        [-22, 27],
        [11, 30],
        [16, 20],
        [58, 12],
        [53, -5],
        [35, -7],
        [66, -27],
        [-61, -17],
        [-26, -37],
        [3, -22],
        [11, -13],
        [-11, -20],
      ],
      [
        [70973, 94156],
        [-45, -14],
        [9, 26],
        [54, 37],
        [100, 19],
        [30, -8],
        [4, -6],
        [-42, -39],
        [-21, -13],
        [-89, -2],
      ],
      [
        [73207, 94511],
        [-14, -14],
        [-13, 2],
        [-11, -7],
        [-70, 21],
        [-95, 9],
        [24, 22],
        [68, 13],
        [101, -17],
        [30, -19],
        [-20, -10],
      ],
      [
        [68706, 91894],
        [-22, -50],
        [-46, 3],
        [-14, 11],
        [-6, 9],
        [52, 54],
        [32, -2],
        [4, -25],
      ],
      [
        [68488, 92477],
        [3, -23],
        [-15, 8],
        [-19, 26],
        [-11, 32],
        [-4, 64],
        [7, 17],
        [6, 9],
        [6, -2],
        [-1, -40],
        [28, -91],
      ],
      [
        [69450, 90150],
        [-49, -7],
        [-54, 44],
        [-51, 86],
        [10, 21],
        [31, -7],
        [51, -2],
        [33, -14],
        [44, -9],
        [-5, -40],
        [0, -16],
        [14, -17],
        [-14, -30],
        [-10, -9],
      ],
      [
        [63903, 97968],
        [-23, -8],
        [-106, 44],
        [-9, 13],
        [91, 41],
        [102, -6],
        [16, -20],
        [3, -7],
        [-69, -36],
        [-5, -21],
      ],
      [
        [64280, 97893],
        [7, -7],
        [-1, -6],
        [-99, 6],
        [-173, -4],
        [-100, 32],
        [106, 32],
        [56, 7],
        [72, 27],
        [89, -26],
        [-5, -23],
        [1, -11],
        [24, -10],
        [23, -17],
      ],
      [
        [64098, 98529],
        [-39, -4],
        [-27, 3],
        [-29, 22],
        [-10, 11],
        [-2, 11],
        [26, 2],
        [12, 11],
        [4, 8],
        [20, 6],
        [34, 1],
        [45, -12],
        [19, -24],
        [-44, -21],
        [-9, -14],
      ],
      [
        [65410, 98083],
        [-79, -27],
        [-40, 1],
        [-20, 16],
        [32, 23],
        [41, 17],
        [31, -5],
        [23, -8],
        [12, -17],
      ],
      [
        [65115, 98198],
        [-39, -30],
        [-129, 32],
        [11, 16],
        [14, 6],
        [0, 16],
        [-12, 12],
        [5, 24],
        [83, -18],
        [8, -7],
        [56, -12],
        [9, -24],
        [-6, -15],
      ],
      [
        [66580, 97900],
        [-100, -19],
        [-35, 6],
        [-10, 9],
        [-19, 9],
        [-50, 12],
        [7, 33],
        [16, 7],
        [150, 37],
        [72, -21],
        [30, -51],
        [-61, -22],
      ],
      [
        [66283, 98526],
        [39, -30],
        [15, -33],
        [24, -20],
        [8, -38],
        [-20, -31],
        [-60, -6],
        [-99, -2],
        [-97, 16],
        [-52, 56],
        [-96, 15],
        [-54, 58],
        [56, 17],
        [68, -8],
        [109, 50],
        [15, -3],
        [24, -11],
        [88, -19],
        [32, -11],
      ],
      [
        [63328, 78045],
        [-4, -10],
        [-13, 53],
        [-1, 32],
        [8, 17],
        [11, -54],
        [-1, -38],
      ],
      [
        [63962, 91696],
        [5, -56],
        [-17, -23],
        [-16, -6],
        [-7, 35],
        [-13, 15],
        [-48, -41],
        [-22, -46],
        [-60, -66],
        [-123, -47],
        [-75, -20],
        [-68, -6],
        [-63, 42],
        [-35, 79],
        [-6, 24],
        [-4, 32],
        [1, 33],
        [4, 50],
        [7, 49],
        [26, 44],
        [60, 52],
        [59, 34],
        [31, 8],
        [75, 2],
        [214, -117],
        [48, -30],
        [27, -41],
      ],
      [
        [67603, 98329],
        [-52, -2],
        [-51, 9],
        [-67, 29],
        [-67, 34],
        [20, 18],
        [63, 23],
        [82, 42],
        [139, 9],
        [67, 0],
        [67, 10],
        [19, 21],
        [13, 41],
        [12, 22],
        [15, 17],
        [74, 14],
        [63, -1],
        [63, -16],
        [40, -14],
        [38, -28],
        [20, -23],
        [-6, -28],
        [3, -23],
        [18, -21],
        [-122, -65],
        [-125, -36],
        [-326, -32],
      ],
      [
        [66098, 97996],
        [-43, -11],
        [-113, 21],
        [-17, 11],
        [-14, 20],
        [-19, 78],
        [-1, 23],
        [-7, 16],
        [-28, 28],
        [-20, 13],
        [18, 15],
        [124, -11],
        [266, -6],
        [136, -28],
        [40, -16],
        [39, -26],
        [-238, -14],
        [-32, -12],
        [1, -29],
        [-9, -26],
        [-25, -3],
        [-58, -43],
      ],
      [
        [67268, 98406],
        [17, -23],
        [-10, -37],
        [-22, -27],
        [-10, -38],
        [-86, -9],
        [-24, -9],
        [-23, -29],
        [-87, -18],
        [-65, -49],
        [-91, 9],
        [-124, 34],
        [-105, -28],
        [-69, -8],
        [-85, 42],
        [-11, 10],
        [-5, 29],
        [5, 26],
        [23, 55],
        [30, 31],
        [15, 10],
        [12, 19],
        [34, 11],
        [105, 7],
        [39, -6],
        [12, -21],
        [57, 2],
        [94, 13],
        [137, 20],
        [79, 18],
        [70, -4],
        [70, -11],
        [18, -19],
      ],
      [
        [66983, 98473],
        [-87, -12],
        [-141, 15],
        [-73, 17],
        [6, 8],
        [19, 10],
        [122, 41],
        [242, 9],
        [30, -30],
        [-26, -23],
        [-92, -35],
      ],
      [
        [64866, 98032],
        [-184, -7],
        [-62, 4],
        [-8, 7],
        [-15, 6],
        [-58, 6],
        [-36, 29],
        [16, 8],
        [85, 12],
        [29, 12],
        [10, 17],
        [38, 31],
        [92, 6],
        [40, -6],
        [5, -20],
        [39, -25],
        [101, -32],
        [-20, -23],
        [-35, -3],
        [-37, -22],
      ],
      [
        [65855, 98128],
        [12, -20],
        [-1, -71],
        [-13, -31],
        [2, -26],
        [-26, -13],
        [-218, 3],
        [-108, 6],
        [-25, 10],
        [61, 34],
        [19, 23],
        [-6, 67],
        [10, 12],
        [175, -6],
        [14, 19],
        [66, 1],
        [38, -8],
      ],
      [
        [66058, 98816],
        [14, -23],
        [43, -13],
        [117, -11],
        [35, -26],
        [-53, -19],
        [-143, -10],
        [15, -38],
        [29, -28],
        [-29, -33],
        [-40, -16],
        [-88, -19],
        [-81, 24],
        [-94, 35],
        [-42, -23],
        [-44, -14],
        [-41, 2],
        [-48, 27],
        [-132, -21],
        [-40, 23],
        [-29, 48],
        [87, 10],
        [104, -15],
        [69, 48],
        [88, 21],
        [70, 51],
        [33, 17],
        [76, -3],
        [25, 4],
        [73, 13],
        [26, -11],
      ],
      [
        [65199, 98568],
        [209, -55],
        [194, 5],
        [84, -17],
        [122, -50],
        [182, -53],
        [35, -16],
        [-31, -21],
        [-213, -53],
        [-138, -18],
        [-120, -3],
        [-48, 6],
        [-48, 38],
        [-117, 28],
        [-125, -8],
        [-13, 15],
        [-25, 11],
        [-43, 2],
        [-86, 15],
        [-6, 34],
        [54, 17],
        [35, 1],
        [14, 48],
        [60, 73],
        [24, 1],
      ],
      [
        [67680, 98853],
        [-34, -8],
        [-179, 7],
        [-86, 14],
        [-12, 8],
        [-4, 7],
        [-114, 12],
        [49, 16],
        [142, 7],
        [254, -19],
        [16, -13],
        [5, -8],
        [-37, -23],
      ],
      [
        [66193, 98914],
        [-92, -12],
        [-13, 9],
        [-3, 6],
        [10, 15],
        [11, 29],
        [42, 17],
        [313, 16],
        [41, -17],
        [-15, -26],
        [0, -12],
        [-294, -25],
      ],
      [
        [75745, 97744],
        [-68, -5],
        [-80, 5],
        [-130, 61],
        [-85, 26],
        [-70, 40],
        [-15, 44],
        [44, 28],
        [54, 11],
        [91, 2],
        [117, -4],
        [116, -29],
        [247, -31],
        [90, -21],
        [-56, -51],
        [-61, -19],
        [-63, -27],
        [-64, -19],
        [-67, -11],
      ],
      [
        [75435, 98583],
        [-96, -45],
        [-346, 29],
        [-16, 20],
        [-5, 13],
        [47, 25],
        [288, -9],
        [103, -9],
        [25, -24],
      ],
      [
        [89169, 94469],
        [-140, -47],
        [-27, 2],
        [-63, 48],
        [-23, 103],
        [26, 31],
        [29, 11],
        [30, 6],
        [123, 4],
        [26, -5],
        [26, -13],
        [12, -19],
        [5, -24],
        [-14, -68],
        [-10, -29],
      ],
      [
        [89495, 94409],
        [69, -25],
        [57, -28],
        [196, -135],
        [18, -28],
        [15, -35],
        [8, -123],
        [-11, -9],
        [-72, -6],
        [-98, 14],
        [-70, 5],
        [-68, 0],
        [-60, 17],
        [-147, 16],
        [-115, 45],
        [-120, 33],
        [-25, 4],
        [-75, -10],
        [-102, -43],
        [-28, -3],
        [-39, 0],
        [-27, 40],
        [65, 14],
        [65, 5],
        [63, 14],
        [59, 47],
        [29, 38],
        [51, 85],
        [28, 31],
        [28, 20],
        [30, 6],
        [33, -3],
        [103, 19],
        [70, 6],
        [70, -11],
      ],
      [
        [87763, 95281],
        [-57, -16],
        [-81, 5],
        [6, 42],
        [14, 19],
        [19, 46],
        [-9, 35],
        [5, 42],
        [10, 33],
        [23, 45],
        [25, -27],
        [17, -40],
        [15, -20],
        [62, -39],
        [12, -12],
        [-52, -48],
        [-5, -21],
        [16, -27],
        [-20, -17],
      ],
      [
        [87832, 94420],
        [-21, -17],
        [-20, 25],
        [-93, 76],
        [-23, 35],
        [-51, 34],
        [-13, 12],
        [-4, 30],
        [67, -19],
        [113, -75],
        [62, -60],
        [-17, -41],
      ],
      [
        [88316, 83578],
        [25, -23],
        [39, 4],
        [10, -15],
        [-31, -25],
        [-22, -51],
        [-7, -47],
        [-9, -18],
        [-25, -23],
        [-22, -30],
        [-19, -19],
        [-17, -6],
        [-37, 99],
        [-18, 28],
        [-52, -47],
        [-12, -1],
        [12, 58],
        [30, 63],
        [15, 9],
        [30, 85],
        [9, 19],
        [93, -50],
        [8, -10],
      ],
      [
        [96999, 91923],
        [-79, -5],
        [-158, 54],
        [-56, 28],
        [-42, 36],
        [-48, 25],
        [-9, 10],
        [7, 21],
        [14, 16],
        [54, 43],
        [38, 19],
        [45, 4],
        [283, -76],
        [12, -16],
        [4, -14],
        [-4, -30],
        [-24, -6],
        [-9, -20],
        [-10, -61],
        [-5, -16],
        [-13, -12],
      ],
      [
        [95453, 85600],
        [-45, -54],
        [-7, 9],
        [-4, 12],
        [-1, 19],
        [41, 36],
        [42, 91],
        [16, 75],
        [-5, 25],
        [-2, 24],
        [123, 47],
        [87, 75],
        [16, -3],
        [15, -63],
        [9, -81],
        [-12, -49],
        [-94, -28],
        [-88, -54],
        [-91, -81],
      ],
      [
        [96291, 83432],
        [-2, -84],
        [-34, 43],
        [-16, 34],
        [-23, 6],
        [-16, 16],
        [-26, 41],
        [-31, 54],
        [-10, 27],
        [-4, 34],
        [-21, 31],
        [-67, 60],
        [22, 7],
        [28, 26],
        [78, -16],
        [18, -7],
        [-13, -40],
        [5, -44],
        [43, -92],
        [21, -32],
        [28, -25],
        [20, -39],
      ],
      [
        [93002, 80248],
        [-27, -25],
        [-28, 15],
        [0, 50],
        [59, 153],
        [21, -9],
        [-5, -37],
        [-22, -57],
        [7, -69],
        [-5, -21],
      ],
      [
        [93445, 81023],
        [-11, -13],
        [-11, 3],
        [-36, 36],
        [-8, 17],
        [13, 30],
        [45, 45],
        [22, -1],
        [9, -10],
        [-1, -53],
        [-22, -54],
      ],
      [
        [93310, 80819],
        [-35, -58],
        [-52, -14],
        [-25, -19],
        [-19, -39],
        [-14, -21],
        [-30, 12],
        [-13, 19],
        [0, 68],
        [-13, 30],
        [6, 19],
        [30, -3],
        [30, 44],
        [69, 18],
        [25, 47],
        [31, 117],
        [33, 41],
        [26, 9],
        [8, -58],
        [-7, -64],
        [-15, -62],
        [-35, -86],
      ],
      [
        [92799, 79948],
        [-10, -30],
        [-12, 10],
        [-2, 9],
        [27, 35],
        [10, 41],
        [20, 1],
        [8, -8],
        [-6, -20],
        [-35, -38],
      ],
      [
        [93234, 81118],
        [-26, -7],
        [-11, 16],
        [-8, 18],
        [-5, 26],
        [29, 12],
        [19, -14],
        [4, -38],
        [-2, -13],
      ],
      [
        [92527, 79356],
        [-13, -33],
        [-14, 4],
        [-5, 9],
        [18, 39],
        [8, 7],
        [6, -26],
      ],
      [
        [92222, 78857],
        [-52, -63],
        [-17, 0],
        [-9, 24],
        [-2, 13],
        [42, 10],
        [48, 84],
        [35, 55],
        [19, 19],
        [16, -1],
        [-80, -141],
      ],
      [
        [91579, 78134],
        [-41, -29],
        [-26, 1],
        [61, 142],
        [36, 21],
        [46, 84],
        [97, 103],
        [11, 7],
        [56, -3],
        [-88, -113],
        [-11, -45],
        [-39, -48],
        [-28, -16],
        [-20, -23],
        [-54, -81],
      ],
      [
        [91277, 77947],
        [-52, -40],
        [-42, -18],
        [-71, -84],
        [-26, -46],
        [-36, -19],
        [-35, 11],
        [-10, -19],
        [-3, -33],
        [-13, -29],
        [-70, -91],
        [-29, -72],
        [-30, -13],
        [-56, -73],
        [10, 63],
        [11, 30],
        [47, 56],
        [3, 60],
        [26, 51],
        [51, 52],
        [35, 67],
        [28, 18],
        [31, 56],
        [32, 20],
        [-3, 43],
        [14, 48],
        [11, -3],
        [26, -67],
        [20, -2],
        [54, 14],
        [80, 116],
        [26, 21],
        [19, 4],
        [11, -10],
        [4, -14],
        [-1, -17],
        [-6, -25],
        [10, -29],
        [-13, -22],
        [-53, -4],
      ],
      [
        [90753, 77041],
        [-9, -16],
        [-20, 14],
        [1, 32],
        [2, 9],
        [57, 28],
        [16, -18],
        [4, -15],
        [-51, -34],
      ],
      [
        [90612, 77475],
        [42, -42],
        [58, 9],
        [-14, -38],
        [-22, 1],
        [-39, -55],
        [-34, -7],
        [-17, -13],
        [-55, -82],
        [-8, -32],
        [-33, -62],
        [-50, -55],
        [-9, -104],
        [-32, 42],
        [-4, 42],
        [10, 35],
        [57, 74],
        [23, 41],
        [7, 34],
        [22, 36],
        [10, 32],
        [14, 14],
        [48, 131],
        [26, -1],
      ],
      [
        [81496, 94700],
        [-10, -27],
        [-15, -21],
        [-11, -26],
        [-19, -19],
        [-59, -24],
        [-46, -54],
        [-8, -5],
        [-163, 30],
        [-26, 9],
        [-53, 33],
        [-75, 31],
        [-39, 46],
        [19, 8],
        [19, 4],
        [67, -6],
        [19, 14],
        [10, 39],
        [1, 23],
        [5, 18],
        [22, 13],
        [241, -40],
        [93, -23],
        [28, -23],
      ],
      [
        [71180, 97725],
        [34, -21],
        [26, 16],
        [248, -50],
        [53, -18],
        [11, -13],
        [-217, -8],
        [-44, 3],
        [-4, 29],
        [-49, 1],
        [-85, 19],
        [-23, 27],
        [-5, 11],
        [27, 11],
        [28, -7],
      ],
      [
        [72229, 98414],
        [-258, -21],
        [-25, 13],
        [-8, 8],
        [36, 44],
        [30, 21],
        [164, 8],
        [131, -14],
        [41, -13],
        [-15, -26],
        [-8, -9],
        [-88, -11],
      ],
      [
        [69631, 93948],
        [-82, -27],
        [-22, -2],
        [-50, 7],
        [-22, -11],
        [-33, 27],
        [2, 24],
        [16, 25],
        [9, 31],
        [-6, 78],
        [42, 49],
        [56, 19],
        [164, 22],
        [23, -6],
        [33, -15],
        [25, -18],
        [33, -43],
        [26, -18],
        [40, -34],
        [12, -33],
        [-2, -30],
        [-75, -6],
        [-130, -25],
        [-59, -14],
      ],
      [
        [71564, 93485],
        [-135, -5],
        [-67, 9],
        [-10, 11],
        [9, 28],
        [69, 42],
        [31, 27],
        [32, 46],
        [56, 38],
        [47, 0],
        [147, -45],
        [21, -28],
        [3, -13],
        [-58, -38],
        [-41, -14],
        [-63, -48],
        [-41, -10],
      ],
      [
        [72083, 93733],
        [-20, -6],
        [-152, 23],
        [-53, 30],
        [-16, 27],
        [6, 24],
        [141, 117],
        [54, -33],
        [15, -31],
        [36, -38],
        [-1, -86],
        [-10, -27],
      ],
      [
        [72825, 95287],
        [10, -19],
        [4, -21],
        [-12, -6],
        [-36, 1],
        [-20, -54],
        [-20, 9],
        [-13, 31],
        [-45, -21],
        [-12, 5],
        [-21, 24],
        [-13, 5],
        [-9, 16],
        [59, 48],
        [36, -25],
        [24, 1],
        [-5, 29],
        [1, 22],
        [30, 9],
        [40, 1],
        [2, -55],
      ],
      [
        [66791, 92128],
        [9, -29],
        [-1, -53],
        [-11, -39],
        [-31, -6],
        [-31, -16],
        [-53, 17],
        [-29, -11],
        [-30, -1],
        [-24, 6],
        [-25, 8],
        [-16, 11],
        [1, 30],
        [-22, 43],
        [-34, 14],
        [-31, 5],
        [-35, 13],
        [-17, -6],
        [-22, -16],
        [-14, 5],
        [-76, 92],
        [-12, 21],
        [-8, 24],
        [-11, 15],
        [-26, 64],
        [13, 29],
        [26, 19],
        [18, 5],
        [32, 43],
        [59, 18],
        [12, -3],
        [11, -13],
        [61, -44],
        [33, -29],
        [28, -35],
        [30, -30],
        [89, -51],
        [60, -50],
        [61, -35],
        [16, -15],
      ],
      [
        [55821, 83685],
        [-27, -55],
        [-74, -116],
        [23, -15],
        [27, -5],
        [32, -22],
        [30, -3],
        [53, 18],
        [10, 100],
        [3, 90],
      ],
      [
        [55898, 83677],
        [17, 0],
        [26, 6],
        [16, -23],
        [30, -23],
        [35, -20],
        [54, -34],
        [55, -22],
        [18, -2],
        [58, 3],
        [61, -3],
        [17, -51],
        [30, -24],
        [25, -34],
        [1, -18],
        [-33, -119],
        [-7, -40],
        [-2, -40],
        [13, -51],
        [11, -28],
      ],
      [
        [56323, 83154],
        [-9, -4],
        [-157, 6],
        [-148, 9],
        [-137, 9],
        [-132, 9],
        [-127, 8],
        [-79, 8],
        [-78, 7],
        [-11, 7],
      ],
      [
        [55445, 83213],
        [43, 49],
        [28, 52],
        [23, 67],
        [3, 46],
        [6, 52],
        [37, 21],
        [80, -3],
        [35, 25],
        [44, 62],
        [46, 74],
        [15, 32],
      ],
      [
        [55805, 83690],
        [16, -5],
      ],
      [
        [59722, 78187],
        [6, -19],
        [66, -146],
        [31, -54],
        [24, -22],
        [28, -3],
        [53, 46],
        [23, 7],
        [50, -18],
        [18, 31],
        [26, 16],
        [33, 2],
        [38, -13],
        [41, -23],
        [-17, -52],
        [-17, -41],
        [-7, -45],
        [-9, -51],
        [-46, -23],
        [-48, 3],
        [-52, -15],
        [-18, 20],
        [-12, 18],
        [-23, 18],
        [-30, 10],
        [-27, -12],
        [-32, -69],
        [-56, -47],
        [-19, -54],
        [-56, 12],
        [-47, -10],
        [-69, -49],
        [-52, -106],
        [-57, -66],
        [-46, -21],
        [-43, 7],
        [-28, 20],
        [-57, 69],
        [4, 25],
        [8, 13],
        [10, 35],
        [23, 131],
        [-3, 43],
        [-13, 66],
        [-45, 52],
        [-36, -9],
        [-21, 13],
        [-75, 89],
        [-40, 6],
        [-45, -18],
        [-16, 13],
        [-13, 31],
        [89, 109],
        [88, 90],
        [38, 9],
        [52, 42],
        [55, 63],
        [-8, 49],
        [-12, 37],
      ],
      [
        [58251, 77914],
        [-5, -38],
        [-3, -24],
        [-12, -99],
        [-8, -37],
        [-13, -42],
        [-142, -49],
        [9, 23],
        [-3, 42],
        [-6, 31],
        [13, 29],
        [-31, 10],
        [-14, -15],
        [-11, -28],
        [9, -62],
        [-15, -35],
        [-6, -19],
        [-1, -46],
        [-9, -20],
        [-2, -21],
        [23, 5],
        [-10, -39],
        [-43, -76],
        [-15, -45],
        [4, -180],
        [-19, -107],
        [-1, -32],
      ],
      [
        [57940, 77040],
        [-45, -1],
        [-14, 2],
        [-42, 16],
        [-48, 29],
        [-28, 55],
        [-18, 40],
        [-40, -18],
        [-8, 5],
        [-11, 19],
        [-31, 13],
        [-37, 0],
        [-85, 72],
        [-9, 13],
        [-67, -13],
        [-99, -35],
        [-76, -45],
        [-79, -78],
        [-32, -60],
        [-37, -32],
        [-52, -23],
        [-94, 9],
        [-97, 30],
        [-105, 32],
        [-57, -18],
        [-77, 13],
        [-115, 39],
        [-86, 12],
        [-85, -23],
        [-14, 17],
        [-4, 20],
        [4, 28],
        [12, 23],
        [20, 17],
        [11, 17],
        [1, 18],
        [-23, 29],
        [-47, 39],
        [-20, 24],
      ],
      [
        [55622, 78403],
        [11, 14],
        [63, 19],
        [29, -19],
        [14, 7],
        [13, 16],
        [7, 12],
        [1, 14],
        [6, 16],
        [22, 8],
        [56, -10],
        [23, 23],
        [8, 13],
        [5, 27],
        [6, 23],
        [20, 12],
        [0, 20],
        [-3, 22],
        [12, 50],
        [7, 20],
        [11, 8],
        [14, 15],
        [24, 33],
        [-6, 28],
        [5, 21],
        [25, 51],
        [19, 49],
        [0, 25],
        [3, 22],
        [16, 23],
        [18, 31],
        [23, 96],
        [9, 16],
        [15, 18],
        [11, 18],
        [1, 63],
        [11, 18],
        [21, 21],
        [20, 33],
        [17, 38],
        [12, 19],
        [17, 4],
        [19, 16],
        [20, 5],
        [20, -7],
        [13, 4],
        [19, 19],
        [48, 71],
        [7, 14],
      ],
      [
        [57394, 79642],
        [26, 2],
        [20, -4],
        [32, -26],
        [22, -32],
        [9, -26],
        [19, -36],
        [20, -51],
        [22, -68],
        [4, -34],
        [9, -37],
        [16, -45],
        [31, -50],
        [5, -10],
        [14, -35],
        [27, -78],
        [23, -31],
        [20, -34],
        [9, -34],
        [15, -31],
        [33, -41],
        [27, -37],
        [22, -107],
        [15, -50],
        [10, -38],
        [-5, -76],
        [6, -33],
        [-12, -60],
        [-23, -120],
        [-5, -95],
        [4, -52],
        [0, -33],
        [6, -21],
        [6, -44],
        [1, -38],
        [-8, -11],
        [-12, -9],
        [-4, -8],
        [10, -17],
        [14, -32],
        [14, -36],
      ],
      [
        [64112, 66122],
        [8, 35],
        [3, 22],
        [-19, 167],
        [-6, 128],
        [2, 26],
        [11, 30],
        [19, 66],
        [9, 65],
        [28, 148],
        [29, 57],
        [43, 42],
        [35, -82],
        [43, -63],
        [8, -70],
        [-13, -57],
        [-11, -90],
        [7, -42],
        [2, -36],
        [12, -61],
        [11, -78],
        [2, -55],
        [-6, -51],
        [-15, -42],
        [-29, -129],
        [-9, -13],
        [-36, -22],
      ],
      [
        [45224, 70776],
        [38, -30],
        [35, 14],
        [43, -39],
        [23, -9],
        [-20, -27],
        [-21, -36],
        [-50, 9],
        [-42, 34],
        [-15, 26],
        [-5, 23],
        [14, 35],
      ],
      [
        [47942, 73259],
        [-24, -6],
        [-95, -94],
        [-29, 0],
        [-55, 41],
        [-96, 14],
        [-32, 12],
        [-39, -27],
        [-30, 1],
        [-25, -35],
        [-17, 10],
        [20, 77],
        [31, 152],
        [-1, 93],
        [7, 81],
        [-8, 80],
        [-16, 50],
        [21, 130],
        [-2, 67],
        [-19, 84],
        [59, -13],
        [-19, 34],
        [-18, 20],
        [-17, -4],
        [-15, 1],
        [-50, -33],
        [-25, -10],
        [-8, 6],
        [3, 52],
        [-13, 68],
        [20, 18],
        [24, 5],
        [20, 29],
        [12, 33],
        [-7, 57],
        [18, 55],
        [40, 46],
        [-21, -7],
        [-24, -29],
        [-38, -104],
        [-12, -53],
        [-33, -17],
        [-29, -9],
        [-14, 6],
        [-18, 13],
        [-2, 39],
        [2, 31],
        [12, 62],
        [4, 88],
        [18, 78],
        [-2, 21],
        [-5, 31],
        [16, 30],
        [19, 20],
        [28, 68],
        [40, 160],
        [47, 170],
        [-4, 21],
        [-10, 15],
        [4, 46],
        [28, 200],
        [11, 26],
        [13, 59],
        [3, 94],
        [5, 65],
        [-1, 33],
        [-4, 39],
        [-18, 75],
        [-18, 159],
        [-2, 53],
        [15, 26],
        [-25, 4],
        [-11, 35],
        [2, 38],
        [28, 63],
      ],
      [
        [43048, 73133],
        [-2, -11],
        [-15, 5],
        [-20, -4],
        [-11, 31],
        [10, 13],
        [22, 3],
        [11, -14],
        [5, -23],
      ],
      [
        [41350, 74542],
        [-12, -27],
        [-21, 10],
        [-7, 10],
        [6, 59],
        [17, 14],
        [17, -24],
        [0, -42],
      ],
      [
        [42479, 74103],
        [-6, -6],
        [-58, 16],
        [-16, 21],
        [-7, 39],
        [10, 13],
        [25, 8],
        [37, -7],
        [24, -28],
        [0, -36],
        [-9, -20],
      ],
      [
        [42283, 74052],
        [-13, -7],
        [-74, 44],
        [-26, 21],
        [-34, 51],
        [96, -62],
        [51, -47],
      ],
      [
        [42044, 74034],
        [-29, -1],
        [-27, 44],
        [40, 23],
        [12, -14],
        [8, -16],
        [6, -21],
        [-10, -15],
      ],
      [
        [42181, 73993],
        [23, -23],
        [-35, -5],
        [-11, -11],
        [-29, 16],
        [-33, -3],
        [-22, 31],
        [-5, 33],
        [11, 20],
        [30, 0],
        [71, -58],
      ],
      [
        [42875, 73640],
        [17, -4],
        [89, 9],
        [24, -6],
        [-3, -43],
        [-17, -17],
        [-52, -11],
        [-82, 27],
        [-27, 37],
        [-4, 27],
        [0, 12],
        [17, 10],
        [38, -41],
      ],
      [
        [55231, 80363],
        [-1, 17],
        [-6, 42],
        [-59, 83],
        [-8, 35],
        [3, 13],
        [-4, 22],
        [-13, 13],
        [-47, 16],
        [-12, -9],
        [-11, 9],
        [-17, 20],
        [-29, 16],
        [-3, 9],
        [-11, 14],
        [-6, 2],
        [-3, -9],
        [-9, -12],
        [-30, -16],
        [-12, 7],
        [-11, 13],
        [-13, 29],
        [-18, 25],
        [-15, 9],
        [-9, 14],
        [-2, 10],
        [34, 21],
        [7, 21],
        [-4, 39],
        [-5, 5],
        [-13, -13],
        [-28, -11],
        [-26, -6],
        [-13, 0],
        [-73, 71],
        [-48, 22],
        [-28, 6],
        [-3, -7],
        [13, -40],
        [22, -49],
        [-1, -13],
        [-26, -20],
        [-15, -9],
        [-18, -17],
        [-15, -24],
        [-12, -10],
        [-12, 2],
        [-11, 12],
        [-31, 73],
        [-38, 55],
        [-4, 13],
        [-12, 3],
        [-17, 12],
        [-6, 18],
        [9, 17],
        [11, 17],
        [21, 10],
        [6, 9],
        [4, 14],
        [7, 19],
        [-2, 6],
        [-14, 21],
        [-22, 20],
        [-60, -15],
        [-16, -10],
        [-9, 13],
        [-7, 21],
        [-16, 3],
        [-20, 19],
        [-25, 18],
        [-24, 5],
        [-50, 26],
        [-19, 1],
        [-11, 9],
        [-12, 20],
        [-10, 21],
        [-5, 44],
        [-37, 19],
        [-37, 13],
        [-2, -7],
        [1, -43],
        [-2, -24],
        [-24, -14],
        [-24, -2],
      ],
      [
        [54113, 81139],
        [2, 8],
        [28, 78],
        [13, 50],
        [15, 91],
        [-18, 72],
        [-5, 33],
        [-8, 16],
        [-50, 35],
        [-4, 12],
        [8, 48],
        [-4, 20],
        [-12, 21],
        [-16, 41],
        [-7, 36],
        [21, 42],
        [5, 30],
        [9, 42],
        [6, 23],
        [1, 7],
        [-13, 16],
        [-3, 23],
        [3, 33],
        [-7, 25],
        [-18, 15],
        [-11, 21],
        [-6, 27],
        [5, 41],
        [13, 56],
        [-29, 67],
        [-72, 79],
        [-35, 55],
        [3, 32],
        [15, 28],
        [28, 26],
        [21, 45],
        [11, 54],
        [1, 10],
        [1, 39],
        [-33, 157],
        [-5, 39],
        [-4, 48],
        [-2, 13],
      ],
      [
        [53960, 82793],
        [64, -33],
        [26, -19],
        [-3, 21],
        [-5, 18],
        [3, 27],
        [-2, 40],
        [-57, 20],
        [-38, 7],
      ],
      [
        [53948, 82874],
        [-5, 28],
        [4, 18],
      ],
      [
        [53947, 82920],
        [11, -11],
        [37, -4],
        [92, 54],
        [159, 70],
        [170, 66],
        [40, 7],
        [40, 14],
        [14, 24],
        [15, 17],
        [23, 43],
        [51, 68],
        [91, 24],
        [34, 32],
        [70, 45],
        [162, 50],
        [67, 11],
        [66, 1],
        [59, -39],
        [62, -49],
        [12, -30],
        [-34, 19],
        [-49, 44],
        [-18, 2],
        [42, -134],
        [22, -47],
        [47, -36],
        [39, -11],
        [119, 21],
        [43, 28],
        [12, 14],
      ],
      [
        [56323, 83154],
        [16, 23],
        [20, -3],
        [23, -14],
        [11, -11],
        [4, -12],
        [3, -13],
        [13, -3],
        [23, -10],
        [31, -24],
        [24, -23],
        [23, -33],
        [8, -37],
        [0, -42],
        [-1, -27],
        [2, -11],
      ],
      [
        [56523, 82914],
        [32, -196],
        [53, -189],
        [19, -92],
        [8, -49],
        [6, -70],
        [2, -50],
        [0, -28],
        [-4, -38],
        [-16, -23],
        [-101, -65],
        [-20, -20],
        [-29, -51],
        [-28, -52],
        [-6, -18],
        [-2, -11],
        [6, -18],
        [36, -27],
        [37, -23],
        [12, -17],
        [26, -21],
        [10, -19],
        [6, -17],
        [-1, -39],
        [-12, -54],
        [5, -40],
        [-12, -27],
        [-10, -30],
        [-2, -53],
        [18, -58],
      ],
      [
        [83638, 62566],
        [43, -30],
        [94, -108],
        [34, -27],
        [36, -20],
        [28, -5],
        [26, 24],
        [10, 25],
        [20, 67],
        [20, 8],
        [13, -24],
        [9, -33],
        [5, -47],
        [-6, -50],
        [-20, -44],
        [-12, -54],
        [-8, -177],
        [0, -53],
        [7, -51],
        [17, -81],
        [9, -23],
        [26, -29],
        [7, -22],
        [1, -39],
        [4, -35],
        [17, -13],
        [14, -18],
        [-5, -38],
        [-9, -39],
        [-12, -97],
        [-55, -223],
        [-3, -48],
        [-22, -96],
        [-45, -16],
        [-52, -46],
        [-28, -36],
        [-25, -47],
        [-10, -62],
        [8, -28],
        [5, -30],
        [0, -32],
        [-8, -27],
        [-25, -65],
        [-10, -54],
        [-11, -24],
        [-6, -29],
        [2, -33],
        [10, -29],
        [30, -125],
        [33, -121],
        [7, -14],
        [2, -16],
        [-19, -32],
        [1, -58],
        [5, -58],
        [29, -142],
        [4, -38],
        [10, -31],
        [14, -30],
        [17, -24],
        [46, -42],
        [18, -9],
        [19, -1],
        [4, 28],
        [17, 10],
        [-4, 28],
        [-20, 38],
        [0, 21],
        [10, 16],
        [13, 9],
        [28, 42],
        [29, 34],
        [38, -3],
        [37, -19],
        [27, -19],
        [22, -37],
        [22, -62],
        [16, -69],
        [-1, -33],
        [-3, -33],
        [0, -33],
        [12, -22],
        [36, -1],
        [18, 51],
        [3, 58],
        [-13, 22],
        [6, 28],
        [11, 22],
        [16, -19],
        [15, -36],
        [56, -39],
        [14, -1],
        [11, -7],
        [25, -28],
        [12, -21],
        [-14, -45],
        [-56, -10],
        [-16, -34],
        [17, -68],
        [26, -55],
        [17, -45],
        [15, -49],
        [0, -45],
        [-9, -46],
        [24, 3],
        [23, -9],
        [32, -40],
        [10, -3],
        [10, 6],
        [-1, -141],
        [-22, -129],
        [-27, 16],
        [-23, 54],
        [4, 67],
        [15, 64],
        [-8, 14],
        [-15, -6],
        [-17, -14],
        [-19, -5],
        [-30, 8],
        [-62, 70],
        [-26, 7],
        [-6, 31],
        [2, 67],
        [-25, 80],
        [-4, 28],
        [-8, 23],
        [-74, 86],
        [-9, 15],
        [-23, 69],
        [-52, 98],
        [-14, 10],
        [-16, 3],
        [-5, -25],
        [7, -38],
        [3, -33],
        [-1, -35],
        [2, -27],
        [25, -54],
        [3, -26],
        [17, -70],
        [2, -82],
        [-21, -34],
        [-24, 38],
        [-1, 31],
        [-4, 29],
        [-25, 74],
        [-8, 16],
        [-48, 74],
        [-37, 80],
        [-82, 86],
        [-9, 5],
        [-15, -7],
        [-13, -10],
        [-40, -43],
        [-14, -29],
        [-1, -46],
        [-28, -36],
        [-39, -5],
        [-30, 23],
        [-25, 45],
        [-21, 2],
        [-25, 71],
        [-31, 9],
        [-26, -55],
        [-5, 109],
        [0, 111],
        [7, 33],
        [13, 27],
        [65, 116],
        [8, 38],
        [-3, 50],
        [-15, 40],
        [-23, 25],
        [-27, 10],
        [-19, 23],
        [-15, 38],
        [-11, -67],
        [10, -98],
        [2, -65],
        [-9, -24],
        [-17, 0],
        [-16, 7],
        [-11, 23],
        [-9, 67],
        [-23, 43],
        [-9, 63],
        [-10, 9],
        [-21, -5],
        [-16, 29],
        [-10, 74],
        [-2, 78],
        [-9, 66],
        [-13, 64],
        [-7, 52],
        [-12, 235],
        [-2, 21],
        [-6, 18],
        [-15, 27],
        [-11, 32],
        [-2, 27],
        [3, 115],
        [5, 28],
        [11, 13],
        [16, -22],
        [12, -28],
        [15, -14],
        [14, -17],
        [25, -69],
        [10, -10],
        [31, 2],
        [18, 8],
        [9, 25],
        [5, 30],
        [1, 35],
        [-18, 103],
        [-6, 74],
        [0, 67],
        [5, 67],
        [24, 112],
        [3, 77],
        [-2, 104],
        [4, 61],
        [-1, 36],
        [-14, 55],
        [-4, 60],
        [41, 302],
        [13, 58],
        [9, 61],
        [4, 80],
        [31, 22],
        [29, 33],
        [15, -3],
        [15, -8],
        [36, 17],
        [13, 1],
      ],
      [
        [82586, 56704],
        [-26, -42],
        [3, 52],
        [7, 49],
        [26, 99],
        [19, 31],
        [31, 78],
        [18, 38],
        [42, 75],
        [39, 82],
        [13, 6],
        [14, 1],
        [12, 9],
        [25, 45],
        [63, 147],
        [53, 110],
        [54, 139],
        [26, 41],
        [7, 15],
        [49, 128],
        [16, 18],
        [17, 14],
        [12, 17],
        [11, 22],
        [17, 56],
        [8, 65],
        [-5, 37],
        [-10, 54],
        [13, 74],
        [9, 34],
        [35, 150],
        [10, 31],
        [14, -19],
        [2, -27],
        [-7, -64],
        [0, -31],
        [8, -33],
        [-10, -53],
        [25, -142],
        [19, -89],
        [1, -30],
        [-26, -53],
        [-15, -16],
        [-33, -15],
        [-15, -15],
        [-23, -44],
        [-15, -57],
        [-4, -30],
        [-7, -23],
        [-68, -39],
        [-31, -25],
        [-15, -19],
        [-7, -31],
        [5, -55],
        [-57, -199],
        [-18, -51],
        [-19, -45],
        [-24, -31],
        [-33, -19],
        [-27, -39],
        [-18, -68],
        [-22, -61],
        [-28, -45],
        [-30, -40],
        [-28, -30],
        [-30, -20],
        [-9, -27],
        [-6, -33],
        [-14, -16],
        [-15, -8],
        [-28, -33],
      ],
      [
        [84026, 58533],
        [32, -29],
        [32, 25],
        [31, -7],
        [26, -38],
        [-9, -24],
        [-1, -27],
        [57, 58],
        [16, -3],
        [-1, -54],
        [-3, -46],
        [-7, -44],
        [-12, -52],
        [-17, -46],
        [-21, -33],
        [-26, -21],
        [-12, -19],
        [-4, -28],
        [1, -35],
        [-6, -32],
        [-27, -14],
        [-42, -62],
        [-90, -40],
        [-25, -27],
        [-16, -36],
        [-17, -32],
        [-10, -8],
        [-4, 15],
        [-1, 13],
        [13, 84],
        [-3, 35],
        [-6, 33],
        [4, 66],
        [16, 62],
        [8, 68],
        [3, 132],
        [12, 182],
        [-1, 22],
        [-9, 25],
        [-35, 20],
        [-14, 19],
        [7, 36],
        [13, 25],
        [18, -1],
        [16, -23],
        [57, -48],
        [30, -40],
        [27, -51],
      ],
      [
        [84202, 57064],
        [-18, -6],
        [-20, 3],
        [-13, 28],
        [-22, 122],
        [-26, 30],
        [-30, 22],
        [-15, 19],
        [-14, 23],
        [-42, 122],
        [-3, 74],
        [7, 42],
        [13, 38],
        [14, 10],
        [35, 1],
        [18, 5],
        [40, 56],
        [3, 22],
        [0, 92],
        [-4, 64],
        [-10, 62],
        [11, 29],
        [14, 28],
        [14, 55],
        [3, 39],
        [0, 41],
        [4, 29],
        [12, 14],
        [54, 45],
        [10, 3],
        [71, -41],
        [14, -62],
        [1, -20],
        [-11, -69],
        [-9, -46],
        [-24, -71],
        [-18, -77],
        [-13, -115],
        [-8, -38],
        [-22, -72],
        [-7, -40],
        [0, -86],
        [-4, -32],
        [0, -31],
        [44, -143],
        [4, -23],
        [0, -26],
        [-8, -32],
        [-18, -55],
        [-10, -19],
        [-17, -14],
      ],
      [
        [84603, 58377],
        [20, -20],
        [22, 8],
        [27, 45],
        [30, -16],
        [18, -68],
        [9, -25],
        [5, -44],
        [-2, -106],
        [-7, -95],
        [6, -20],
        [14, -17],
        [12, -22],
        [10, -27],
        [7, -30],
        [2, -74],
        [18, -62],
        [2, -24],
        [-4, -25],
        [-29, 5],
        [-3, -21],
        [1, -27],
        [-10, 17],
        [-17, 60],
        [-16, 26],
        [5, -98],
        [5, -48],
        [1, -47],
        [-27, 36],
        [-33, 23],
        [-9, 19],
        [4, 61],
        [-1, 31],
        [-14, 64],
        [16, 140],
        [0, 29],
        [-3, 28],
        [-13, 57],
        [-21, 47],
        [-13, 0],
        [-32, -33],
        [-16, 11],
        [-9, 131],
        [-13, 127],
        [-9, 32],
        [-7, 34],
        [6, 28],
        [13, -11],
        [17, -34],
        [21, -19],
        [10, -16],
        [7, -30],
      ],
      [
        [84788, 59059],
        [20, -47],
        [4, -34],
        [-2, -38],
        [9, -16],
        [16, -5],
        [20, -19],
        [15, -35],
        [-9, -32],
        [3, -46],
        [-16, -59],
        [2, -105],
        [9, -33],
        [1, -33],
        [-2, -36],
        [4, -28],
        [24, -96],
        [5, -32],
        [-8, -25],
        [-2, -24],
        [15, -2],
        [21, -40],
        [12, -53],
        [-3, -13],
        [-17, 41],
        [-13, 6],
        [-55, -11],
        [-33, 17],
        [-22, 2],
        [-21, 70],
        [-19, 12],
        [-15, 31],
        [-25, 79],
        [-8, 46],
        [18, 46],
        [5, 37],
        [-1, 36],
        [-17, -6],
        [-14, 12],
        [-17, 44],
        [-7, 25],
        [-13, 22],
        [-20, 50],
        [-30, 20],
        [-11, 14],
        [-24, 42],
        [-16, 53],
        [-17, 92],
        [-9, 95],
        [76, -25],
        [76, 5],
        [86, 22],
        [25, -26],
      ],
      [
        [83528, 59607],
        [14, -5],
        [45, 18],
        [18, -9],
        [12, -33],
        [15, -10],
        [12, -17],
        [23, 29],
        [22, -33],
        [20, -63],
        [24, -44],
        [22, -33],
        [5, -25],
        [-14, -40],
        [-4, -50],
        [2, -55],
        [16, -114],
        [-5, -31],
        [-18, -44],
        [-12, -49],
        [1, -20],
        [-5, -16],
        [-1, -35],
        [-11, 7],
        [-9, -5],
        [-9, -16],
        [-15, -33],
        [-23, 10],
        [-10, 10],
        [-3, 29],
        [-7, 20],
        [-9, 12],
        [-24, 50],
        [-11, 38],
        [-1, 40],
        [-6, 37],
        [-12, 33],
        [-16, 26],
        [-6, 24],
        [-2, 29],
        [-1, 75],
        [-24, 92],
        [-8, 23],
        [-21, 22],
        [-18, 30],
        [-8, 29],
        [-7, 48],
        [-5, 7],
        [-14, -3],
        [-13, 7],
        [3, 35],
        [14, 25],
        [19, 3],
        [51, -14],
        [14, -11],
      ],
      [
        [85001, 57212],
        [22, -35],
        [30, 9],
        [-1, -87],
        [5, -26],
        [27, -74],
        [4, -61],
        [-16, -59],
        [-12, -27],
        [-22, -40],
        [0, -18],
        [9, -20],
        [31, -12],
        [23, -32],
        [4, -91],
        [22, -71],
        [-1, -31],
        [-9, -128],
        [3, -54],
        [16, -44],
        [14, -19],
        [8, -27],
        [6, -75],
        [-1, -128],
        [-2, -44],
        [-10, -42],
        [-30, -94],
        [-40, -75],
        [-22, 5],
        [-6, -22],
        [13, -68],
        [-5, -145],
        [-9, -100],
        [-13, 51],
        [-9, 53],
        [-9, 140],
        [-10, 64],
        [-16, 57],
        [-7, 52],
        [-16, 48],
        [-22, 125],
        [-14, -7],
        [-23, -34],
        [-5, -23],
        [-3, -36],
        [-6, -32],
        [-27, -51],
        [-22, -60],
        [-17, -67],
        [-6, -61],
        [15, -47],
        [15, -20],
        [21, -42],
        [7, -20],
        [23, -139],
        [-1, -141],
        [-17, -63],
        [-42, -119],
        [-30, -37],
        [-17, 19],
        [-13, 72],
        [-2, 29],
        [9, 68],
        [1, 62],
        [-10, 21],
        [-12, -4],
        [-4, -9],
        [-27, -81],
        [-12, -21],
        [-17, -2],
        [-13, 5],
        [-81, 71],
        [-66, 70],
        [-51, 65],
        [-38, 99],
        [-8, 74],
        [0, 77],
        [-17, 113],
        [-2, 38],
        [2, 37],
        [16, 70],
        [20, 35],
        [12, 25],
        [9, 28],
        [6, 37],
        [-2, 37],
        [-7, 23],
        [-32, 82],
        [-27, 50],
        [-57, 45],
        [-13, 24],
        [-14, 19],
        [-16, 8],
        [-16, 0],
        [-16, -14],
        [-5, -29],
        [2, -27],
        [-2, -26],
        [-24, -148],
        [-30, 32],
        [-29, 38],
        [-7, 26],
        [-4, 32],
        [-5, 21],
        [-6, 19],
        [-14, -49],
        [-16, -39],
        [-20, -10],
        [-21, 0],
        [-7, 17],
        [-7, 94],
        [-22, 30],
        [-27, -7],
        [-33, -52],
        [-6, -19],
        [-7, -45],
        [-36, -127],
        [-19, -98],
        [-21, -96],
        [-9, -31],
        [-13, -21],
        [-19, 9],
        [-18, 23],
        [-17, 61],
        [6, 72],
        [19, 45],
        [15, 49],
        [19, 171],
        [1, 61],
        [4, 26],
        [31, 77],
        [26, 48],
        [13, 10],
        [57, 28],
        [23, 23],
        [36, 0],
        [30, 13],
        [24, 37],
        [2, 38],
        [-2, 40],
        [6, 24],
        [9, 21],
        [12, 27],
        [15, 20],
        [40, 15],
        [14, 17],
        [10, 26],
        [16, 50],
        [17, -12],
        [18, -20],
        [33, -16],
        [28, -42],
        [19, -66],
        [3, -32],
        [5, -108],
        [-7, -25],
        [-28, -51],
        [13, -5],
        [36, 46],
        [19, 18],
        [45, 24],
        [11, 16],
        [7, 24],
        [16, 66],
        [12, 71],
        [9, 29],
        [13, 23],
        [13, 4],
        [47, -48],
        [31, 23],
        [8, 73],
        [7, 106],
        [6, 29],
        [17, 28],
        [21, -9],
        [28, -38],
        [27, -13],
        [9, 31],
        [10, 61],
        [10, -1],
        [36, -20],
        [34, 13],
        [10, 73],
        [-7, 78],
        [-27, 226],
        [16, 51],
        [14, 1],
        [34, -60],
        [65, -81],
        [22, -50],
        [14, -61],
      ],
      [
        [84269, 57286],
        [-11, -15],
        [-4, 38],
        [3, 51],
        [21, 179],
        [-5, 45],
        [36, 100],
        [22, 93],
        [32, 99],
        [5, 51],
        [29, 97],
        [27, 134],
        [-1, 45],
        [7, 22],
        [4, 33],
        [0, 29],
        [20, 50],
        [6, -33],
        [-6, -64],
        [1, -30],
        [3, -14],
        [0, -60],
        [-7, -91],
        [7, -105],
        [-13, -107],
        [-15, -48],
        [-21, -34],
        [-24, -21],
        [-25, -53],
        [-15, -62],
        [-3, -57],
        [-39, -192],
        [-34, -80],
      ],
      [
        [83866, 62727],
        [-17, -42],
        [-10, 12],
        [10, 40],
        [0, 14],
        [8, 31],
        [15, 11],
        [13, -31],
        [-19, -35],
      ],
      [
        [83755, 62996],
        [3, -52],
        [-17, 1],
        [-24, 32],
        [-3, 16],
        [1, 13],
        [4, 11],
        [36, -21],
      ],
      [
        [83877, 63574],
        [-5, -7],
        [-8, 3],
        [8, 55],
        [14, 15],
        [11, -6],
        [-20, -60],
      ],
      [
        [83854, 63814],
        [-13, -47],
        [-11, 0],
        [2, 26],
        [14, 55],
        [5, -1],
        [3, -33],
      ],
      [
        [83655, 55342],
        [15, -41],
        [19, 11],
        [30, -12],
        [6, -22],
        [-1, -14],
        [-32, -40],
        [-21, 42],
        [-38, -29],
        [-18, 17],
        [-24, -15],
        [-15, 33],
        [6, 31],
        [39, 51],
        [34, -12],
      ],
      [
        [82521, 56384],
        [-14, -44],
        [-16, 50],
        [1, 71],
        [5, 19],
        [24, 11],
        [0, -107],
      ],
      [
        [83309, 57883],
        [-34, -18],
        [-8, 56],
        [24, 51],
        [28, -21],
        [16, -20],
        [-8, -18],
        [-18, -30],
      ],
      [
        [83360, 58852],
        [15, -9],
        [11, 8],
        [10, 31],
        [9, -45],
        [22, -37],
        [-7, -38],
        [-20, -4],
        [-19, 8],
        [-21, -15],
        [-25, 9],
        [-14, 35],
        [-17, 63],
        [-9, 12],
        [1, 26],
        [6, 16],
        [-3, 4],
        [2, 12],
        [3, 8],
        [5, 3],
        [13, -28],
        [32, -42],
        [6, -17],
      ],
      [
        [83343, 58584],
        [-21, -19],
        [-5, 12],
        [-3, 28],
        [0, 20],
        [-20, 104],
        [15, 15],
        [11, -12],
        [12, -16],
        [10, -9],
        [10, -32],
        [-2, -23],
        [3, -22],
        [-10, -46],
      ],
      [
        [84068, 57875],
        [-7, -8],
        [-7, 2],
        [-16, -21],
        [-6, 39],
        [5, 66],
        [25, 50],
        [6, 16],
        [7, 10],
        [8, 1],
        [8, -20],
        [2, -30],
        [-16, -90],
        [-9, -15],
      ],
      [
        [84609, 57480],
        [-3, -21],
        [-22, 2],
        [-8, -3],
        [-20, -54],
        [-12, -14],
        [-66, -18],
        [-52, 14],
        [-18, 30],
        [-12, 49],
        [-3, 33],
        [13, 35],
        [12, 24],
        [42, 46],
        [10, 35],
        [22, 43],
        [45, 14],
        [4, -11],
        [6, -6],
        [9, -2],
        [23, -35],
        [25, -23],
        [-6, -85],
        [7, -28],
        [4, -25],
      ],
      [
        [83402, 54871],
        [-7, -35],
        [-9, -16],
        [-12, 9],
        [-9, 18],
        [-5, -27],
        [-24, -10],
        [-15, -41],
        [-23, -12],
        [-15, 6],
        [2, 37],
        [43, 54],
        [27, 21],
        [23, 39],
        [12, 5],
        [6, -33],
        [6, -15],
      ],
      [
        [83914, 55546],
        [-28, -8],
        [-9, 0],
        [-22, 59],
        [-2, 26],
        [-18, 29],
        [6, 29],
        [23, 7],
        [40, 38],
        [64, -59],
        [10, -21],
        [-20, -13],
        [-14, -56],
        [-30, -31],
      ],
      [
        [85016, 57468],
        [-4, -3],
        [-15, 45],
        [2, 51],
        [21, 76],
        [15, -67],
        [0, -30],
        [-2, -15],
        [14, -37],
        [-10, -19],
        [-21, -1],
      ],
      [
        [84913, 57554],
        [-5, -16],
        [-6, 33],
        [-17, 31],
        [-15, 53],
        [-11, 16],
        [7, 42],
        [1, 68],
        [16, 31],
        [7, 10],
        [11, 33],
        [6, 2],
        [5, -28],
        [-11, -85],
        [16, -100],
        [-6, -62],
        [3, -14],
        [-1, -14],
      ],
      [
        [83408, 59764],
        [0, -39],
        [-47, 57],
        [-1, 20],
        [1, 14],
        [5, 9],
        [25, -21],
        [17, -40],
      ],
      [
        [83864, 59642],
        [18, -1],
        [5, 5],
        [33, -48],
        [-2, -24],
        [4, -33],
        [-19, -55],
        [-3, -19],
        [-11, -18],
        [-35, 44],
        [-13, 27],
        [-4, 56],
        [14, 81],
        [13, -15],
      ],
      [
        [83914, 58959],
        [-22, -143],
        [-15, 49],
        [6, 31],
        [-13, 26],
        [-3, 24],
        [5, 31],
        [13, 29],
        [3, 94],
        [29, 30],
        [11, 1],
        [-4, -23],
        [1, -43],
        [-11, -106],
      ],
      [
        [84070, 58933],
        [-14, -13],
        [-29, 56],
        [-17, 26],
        [-4, 15],
        [13, 21],
        [37, 0],
        [19, -39],
        [3, -24],
        [-8, -42],
      ],
      [
        [84244, 59247],
        [24, -88],
        [-26, 60],
        [-30, 41],
        [-31, 67],
        [-23, 24],
        [-6, 14],
        [2, 28],
        [17, 5],
        [7, -2],
        [45, -119],
        [21, -30],
      ],
      [
        [84381, 59017],
        [1, -51],
        [-10, 19],
        [-34, 99],
        [-9, 36],
        [9, 24],
        [25, -37],
        [18, -90],
      ],
      [
        [84365, 58921],
        [53, -69],
        [37, -116],
        [4, -89],
        [-3, -35],
        [-17, 39],
        [-38, 54],
        [-26, 12],
        [-8, 10],
        [3, 30],
        [-17, 27],
        [-2, 11],
        [-15, 12],
        [-23, 61],
        [-16, 12],
        [-15, -13],
        [-35, -91],
        [-37, -64],
        [-1, 25],
        [15, 79],
        [10, 128],
        [6, 39],
        [-8, 57],
        [-1, 51],
        [28, -24],
        [35, -23],
        [27, -33],
        [4, -22],
        [40, -68],
      ],
      [
        [84542, 59695],
        [-8, -37],
        [-9, 13],
        [-12, -2],
        [-21, -32],
        [-33, 43],
        [-5, 33],
        [24, 73],
        [0, 109],
        [8, 27],
        [9, 19],
        [11, 11],
        [23, -75],
        [8, -10],
        [22, -34],
        [-5, -70],
        [2, -41],
        [-14, -27],
      ],
      [
        [83937, 59935],
        [-1, -23],
        [-60, 85],
        [-3, 15],
        [0, 13],
        [4, 14],
        [60, -104],
      ],
      [
        [83897, 60486],
        [5, -20],
        [-5, 1],
        [-4, -4],
        [-13, -41],
        [14, -77],
        [-9, -56],
        [-16, -4],
        [-6, 6],
        [3, 28],
        [4, 12],
        [-3, 37],
        [-10, 23],
        [-7, 44],
        [-12, 27],
        [6, 43],
        [36, 5],
        [17, -24],
      ],
      [
        [84531, 57952],
        [-7, -3],
        [13, 61],
        [10, -9],
        [3, -6],
        [0, -28],
        [-19, -15],
      ],
      [
        [84799, 57593],
        [2, -29],
        [-35, 75],
        [-8, 53],
        [12, -2],
        [16, -20],
        [13, -77],
      ],
      [
        [84148, 56111],
        [3, -14],
        [-1, -14],
        [-29, -27],
        [-9, 1],
        [-3, 44],
        [7, 21],
        [14, -18],
        [12, 21],
        [6, -14],
      ],
      [
        [84939, 55853],
        [-4, -32],
        [-17, 77],
        [-7, 19],
        [9, 65],
        [19, -32],
        [0, -97],
      ],
      [
        [84613, 58463],
        [-35, -4],
        [-15, 26],
        [-19, 78],
        [21, 16],
        [21, -4],
        [15, -27],
        [15, -52],
        [-3, -33],
      ],
      [
        [84668, 57109],
        [-8, -34],
        [-31, 28],
        [-8, 25],
        [4, 29],
        [15, 10],
        [8, 0],
        [15, -31],
        [5, -27],
      ],
      [
        [83680, 62835],
        [-1, -39],
        [-14, 21],
        [-3, 29],
        [1, 21],
        [6, 26],
        [9, -23],
        [2, -35],
      ],
      [
        [83294, 58482],
        [6, -31],
        [-8, -46],
        [-7, -10],
        [-8, 19],
        [-20, 14],
        [-1, 24],
        [10, 0],
        [18, 24],
        [10, 6],
      ],
      [
        [84376, 58342],
        [16, -76],
        [-22, 0],
        [-8, 56],
        [10, 18],
        [4, 2],
      ],
      [
        [83974, 59060],
        [-8, -18],
        [-5, 3],
        [-4, 31],
        [8, 21],
        [3, -2],
        [6, -35],
      ],
      [
        [84991, 57369],
        [-5, -15],
        [-8, 31],
        [7, 68],
        [5, 11],
        [7, -43],
        [-6, -52],
      ],
      [
        [84681, 58522],
        [-5, -30],
        [-8, 8],
        [-7, 14],
        [-11, 44],
        [-3, 33],
        [16, -18],
        [9, -33],
        [9, -18],
      ],
      [
        [82598, 56575],
        [-19, -14],
        [-4, 36],
        [2, 35],
        [14, -3],
        [6, -11],
        [1, -43],
      ],
      [
        [84360, 57164],
        [2, -60],
        [-25, -18],
        [-21, 16],
        [-13, 36],
        [0, 13],
        [11, -1],
        [26, 31],
        [8, 7],
        [12, -24],
      ],
      [
        [30565, 49403],
        [-2, -38],
        [-9, -15],
        [-14, -3],
        [-21, 26],
        [-15, -6],
        [-15, -2],
        [-22, 31],
        [-7, 31],
        [-17, 25],
        [-35, -10],
        [-29, -1],
        [-24, 6],
        [-22, -8],
        [-18, -33],
        [-14, -38],
        [-16, -31],
        [-48, -22],
        [-25, 0],
        [-23, -21],
        [-33, -8],
        [-23, -18],
        [-41, -10],
        [-49, -10],
        [-28, -28],
        [-10, -12],
        [-28, -39],
        [-49, -62],
        [-26, -21],
        [-33, -67],
        [-38, -62],
        [-25, -33],
        [-37, -16],
        [-16, -16],
        [-5, -20],
        [3, -24],
        [-6, -60],
        [-12, -111],
        [-3, -54],
        [-2, -26],
        [-25, -89],
        [-26, -83],
        [-13, -55],
        [-8, -40],
        [9, -34],
        [10, -60],
        [9, -48],
        [3, -32],
        [-3, -38],
        [-11, -34],
        [-18, -22],
        [-23, -7],
        [-49, -60],
        [-54, -89],
        [-18, -41],
        [-5, -39],
        [-8, -62],
        [4, -31],
        [9, -22],
        [10, -52],
        [1, -27],
        [-8, -15],
        [-16, -3],
        [-13, -5],
        [-11, -14],
        [-10, 4],
        [-10, -7],
        [0, -22],
        [3, -25],
        [-1, -26],
        [-7, -17],
        [-5, -12],
        [5, -17],
        [10, -15],
        [14, -25],
        [21, -48],
        [15, -9],
        [13, -16],
        [1, -27],
        [-4, -27],
        [-12, -12],
        [-1, -23],
        [16, -28],
        [10, -21],
        [11, -30],
        [9, -41],
        [0, -27],
        [11, -34],
        [6, -28],
        [0, -27],
        [17, -27],
        [15, -20],
        [10, -18],
        [11, -12],
        [2, -20],
        [-1, -30],
        [15, -50],
        [28, -38],
        [22, -55],
        [14, -39],
        [27, -64],
        [1, -73],
        [-33, -84],
        [-33, -84],
        [54, 3],
        [56, -2],
        [58, -24],
        [39, -23],
        [23, -11],
        [17, -26],
        [8, -42],
        [7, -34],
        [1, -50],
        [25, -40],
        [-2, -38],
        [-1, -54],
        [11, -1],
        [71, 0],
        [78, 0],
        [74, 10],
        [28, 13],
        [34, 65],
        [21, 20],
        [20, 30],
        [24, 56],
        [19, 25],
        [16, 31],
        [23, 31],
        [10, 23],
        [8, 8],
        [19, 15],
        [-8, -30],
        [-6, -31],
        [-2, -44],
        [9, -49],
        [-8, -36],
        [-12, -32],
        [0, -85],
        [0, -121],
        [0, -104],
        [-1, -129],
        [0, -147],
        [0, -98],
        [13, 20],
        [17, 17],
        [23, -45],
        [16, -20],
        [14, -4],
        [15, 1],
        [19, 10],
        [43, 37],
        [29, 31],
        [34, -2],
        [46, -12],
        [26, 1],
      ],
      [
        [30672, 45534],
        [35, -125],
        [25, -91],
        [29, -105],
        [24, -84],
        [35, -127],
        [31, -110],
        [32, -118],
        [37, -133],
        [-11, -34],
        [-10, -27],
        [1, -46],
        [-15, -24],
        [-15, -15],
        [-19, -38],
        [-12, -34],
        [-1, -47],
        [3, -242],
        [-3, -66],
        [-10, -56],
        [-10, -29],
        [-6, -22],
        [15, -56],
        [13, -113],
        [10, -22],
        [13, -46],
        [6, -44],
        [-3, -16],
        [-25, -21],
        [-9, -18],
        [-3, -64],
        [-11, -23],
        [-18, -31],
        [-12, -35],
        [-10, -24],
        [-10, -14],
        [-5, -42],
        [-7, -44],
        [-23, -28],
        [-4, -53],
        [0, -44],
        [12, -43],
        [40, -92],
        [4, -22],
        [-23, -56],
        [-13, -38],
        [-32, -117],
        [-1, -22],
        [8, -55],
        [49, -238],
        [8, -19],
        [15, -23],
        [24, 3],
        [37, -26],
        [18, -29],
        [2, -15],
        [-4, -9],
        [-20, -20],
        [-22, -26],
        [-7, -24],
        [-2, -39],
        [5, -57],
        [-9, -18],
        [-22, -23],
        [-18, -32],
        [-19, -53],
        [-32, -81],
        [-11, -22],
        [-5, -28],
        [-18, -9],
        [-34, -55],
        [-5, -28],
        [5, -27],
        [17, -22],
        [12, -32],
        [3, -41],
        [0, -26],
      ],
      [
        [30691, 41759],
        [-21, -39],
        [-27, -44],
        [-34, -9],
        [-13, -22],
        [3, -47],
        [11, -66],
        [0, -52],
        [-10, -60],
        [-24, -65],
        [-37, -44],
        [-35, -24],
        [-27, 0],
        [-27, -5],
        [-11, -7],
      ],
      [
        [30439, 41275],
        [-20, 39],
        [-91, 130],
        [-34, 69],
        [-32, 33],
        [-78, 111],
        [-8, 36],
        [-9, 114],
        [-11, 32],
        [-26, 41],
        [-68, 55],
        [-26, 28],
        [-27, 50],
        [-40, 36],
        [-44, 72],
        [-26, 59],
        [-29, 38],
        [-91, 54],
        [-45, 54],
        [-85, 76],
        [-38, 49],
        [-91, 59],
        [-27, 28],
        [-90, 138],
        [-62, 46],
        [-51, 77],
        [-153, 166],
        [-24, 53],
        [-23, 81],
        [-34, 49],
        [-38, 112],
        [-57, 66],
        [-54, 87],
        [-20, 80],
        [-36, 101],
        [-11, 54],
        [-32, 53],
        [-2, 107],
        [-22, 49],
        [16, 24],
        [17, 11],
        [21, 165],
        [-12, 83],
        [-56, 151],
        [-21, 72],
        [-15, 93],
        [-22, 55],
        [-34, 116],
        [-21, 102],
        [-45, 75],
        [-12, 27],
        [-7, 38],
        [-25, 26],
        [-1, 79],
        [-17, 150],
        [-25, 76],
        [-90, 140],
        [-2, 55],
        [-7, 99],
        [-20, 106],
        [-99, 332],
        [-26, 99],
        [-25, 161],
        [-22, 91],
        [-25, 162],
        [-37, 123],
        [-24, 107],
        [-25, 133],
        [-2, 71],
        [-45, 122],
        [-24, 112],
        [-42, 94],
        [-42, 72],
        [-18, 50],
        [-58, 240],
        [-8, 71],
        [-40, 132],
        [-40, 95],
        [-25, 76],
        [-32, 69],
        [-195, 212],
        [-69, 88],
        [-23, 42],
        [-10, 66],
        [4, 38],
        [20, 36],
        [28, -27],
        [17, 11],
        [13, 47],
        [1, 72],
        [-17, 92],
        [-63, 177],
        [5, 38],
        [12, 42],
        [-24, 86],
        [-27, 68],
        [-13, 53],
        [15, 200],
        [14, 51],
        [95, 203],
        [26, 86],
        [40, 54],
        [42, 82],
        [49, 62],
      ],
      [
        [27687, 49891],
        [8, -11],
        [7, -10],
        [0, -21],
        [2, -18],
        [5, -17],
        [1, -32],
        [6, -21],
        [1, -23],
        [0, -33],
        [-3, -16],
        [3, -28],
        [11, -52],
        [-4, -16],
        [-11, -11],
        [-10, -14],
        [-10, -32],
        [-15, 1],
        [-22, 14],
        [-15, -18],
        [-5, -35],
        [4, -28],
        [2, -27],
        [9, -23],
        [28, -2],
        [-25, -73],
        [-12, -33],
        [3, -21],
        [15, -18],
        [11, -2],
        [25, 27],
        [17, 39],
        [10, 22],
        [16, 9],
        [21, -18],
        [28, -36],
        [33, -32],
        [13, -18],
        [24, 5],
        [20, 7],
        [17, -26],
        [17, -22],
        [4, -76],
        [13, -55],
        [15, -42],
        [19, -51],
        [18, -17],
        [22, 0],
        [31, -19],
        [12, 12],
        [11, 35],
        [5, 20],
        [16, 9],
        [1, 23],
        [-3, 28],
        [5, 32],
        [13, 28],
        [33, 42],
        [15, 18],
        [4, 25],
        [6, 35],
        [-3, 19],
        [-6, 23],
        [1, 34],
        [9, 45],
        [12, 52],
        [11, 67],
        [4, 31],
        [12, 20],
        [4, 29],
        [6, 34],
        [14, 38],
        [0, 41],
        [6, 18],
        [0, 46],
        [15, 94],
        [0, 20],
        [6, 5],
        [11, -6],
        [9, -22],
        [3, -21],
        [4, -9],
        [9, 2],
        [10, 12],
        [-1, 19],
        [-7, 18],
        [-2, 11],
        [3, 18],
        [15, 38],
        [17, 44],
        [36, 92],
        [22, 38],
        [56, 40],
        [42, 30],
        [41, 29],
        [55, 42],
        [78, 58],
        [56, 42],
        [50, 76],
        [39, 58],
        [33, 50],
        [42, 64],
        [56, 138],
        [40, 95],
        [28, 70],
        [20, 44],
        [16, 123],
        [18, 142],
        [8, 62],
        [3, 22],
        [8, -9],
        [9, -15],
        [10, -1],
        [11, 1],
        [6, 8],
        [-9, 141],
        [1, 31],
        [5, 37],
        [-1, 20],
        [-17, 28],
        [-28, 56],
        [-11, 50],
        [-7, 43],
        [-19, 27],
        [-20, 25],
        [1, 20],
        [12, 0],
        [30, -20],
        [22, 7],
        [16, 2],
        [15, 20],
      ],
      [
        [29087, 51781],
        [17, 38],
        [11, 0],
        [13, -5],
        [23, -39],
        [14, -22],
        [17, -19],
        [15, -6],
        [15, -2],
        [9, 1],
        [6, -25],
        [7, -31],
        [18, -21],
        [21, -21],
        [17, -34],
        [11, -23],
        [14, -28],
        [13, -36],
        [12, -64],
        [6, -43],
        [7, -24],
        [-2, -24],
        [14, -45],
        [11, -24],
        [18, -16],
        [35, -18],
        [19, -40],
        [16, -16],
        [18, -41],
        [16, -12],
        [20, 2],
        [19, -20],
        [15, -39],
        [10, -49],
        [15, -27],
        [8, -51],
        [-9, -58],
        [8, -32],
        [16, -25],
        [25, -27],
        [23, 7],
        [12, -9],
        [7, -25],
        [5, -29],
        [10, -70],
        [5, -45],
        [-10, -43],
        [-3, -30],
        [5, -41],
        [24, -19],
        [22, -16],
        [13, -31],
        [15, -9],
        [21, 2],
        [27, 8],
        [15, 18],
        [10, 5],
        [11, -8],
        [24, -17],
        [29, -19],
        [26, 11],
        [23, 5],
        [23, 11],
        [23, 33],
        [19, -2],
        [14, 22],
        [19, 35],
        [17, 36],
        [14, 8],
        [23, -17],
        [31, -25],
        [18, -31],
        [13, -9],
        [14, -23],
        [27, 0],
        [29, 12],
        [23, 39],
        [24, 12],
        [16, 10],
        [15, -6],
        [58, -71],
        [16, -37],
        [20, -7],
        [17, -20],
        [27, -22],
        [15, -22],
        [19, -14],
        [14, -31],
        [22, -19],
        [20, -11],
        [8, -25],
        [0, -16],
        [-2, -12],
        [-21, -65],
        [-39, -129],
        [-36, -116],
        [-56, -182],
        [-32, -102],
        [8, -4],
        [49, -45],
        [12, -1],
        [18, 11],
        [12, 18],
        [11, 2],
        [11, -17],
        [17, -22],
        [11, -65],
        [9, -31],
        [20, -25],
        [21, -40],
        [15, -42],
      ],
      [
        [33844, 40227],
        [6, -42],
        [4, -32],
        [9, -23],
        [9, -31],
        [9, -17],
        [7, -28],
        [-2, -33],
        [4, -41],
        [4, -37],
        [5, -9],
        [13, -10],
        [7, -33],
        [-5, -16],
        [2, -19],
        [5, -19],
        [-5, -18],
        [2, -14],
        [9, -12],
        [9, -46],
        [1, -78],
        [-10, -42],
        [-7, -34],
        [-2, -21],
        [6, -31],
        [-9, -36],
        [-11, -44],
        [2, -30],
        [2, -29],
        [1, -30],
        [3, -29],
        [-4, -30],
        [-4, -27],
        [-1, -30],
        [4, -35],
        [-8, -32],
        [-5, -23],
        [-1, -23],
        [8, -36],
        [21, -15],
        [16, -4],
        [16, 19],
        [12, 6],
        [22, -17],
        [20, -31],
        [26, -4],
        [23, -5],
        [17, -10],
        [26, 11],
        [27, -11],
        [31, -17],
        [26, -15],
        [25, 3],
        [20, 2],
        [20, 17],
        [19, -1],
        [15, 30],
        [8, 27],
        [8, 19],
        [21, 15],
        [14, -10],
        [12, -49],
        [21, -29],
        [9, -21],
        [15, -9],
        [34, -2],
        [21, 2],
        [24, -15],
        [16, 0],
        [14, -27],
        [13, -32],
        [1, -59],
        [12, -46],
        [16, -17],
        [8, -29],
        [-2, -40],
        [-8, -40],
        [1, -44],
        [8, -40],
        [0, -40],
        [6, -40],
        [11, -34],
        [4, -55],
        [-2, -40],
        [7, -23],
        [3, -33],
        [-5, -26],
        [-2, -36],
        [1, -33],
        [6, -26],
        [16, -35],
        [5, -61],
        [0, -42],
        [7, -49],
        [14, -23],
        [22, -8],
        [26, -7],
        [31, 11],
        [28, 13],
        [15, 14],
        [31, 36],
        [26, 21],
        [14, 13],
        [13, 10],
        [27, -23],
        [24, -29],
        [20, -40],
        [35, -43],
        [-6, -11],
        [-15, -36],
        [1, -42],
        [10, -61],
        [-9, -127],
        [-28, -196],
        [-11, -114],
        [4, -32],
        [-10, -57],
        [-38, -123],
        [-1, -82],
      ],
      [
        [34829, 37110],
        [-5, -248],
        [-13, -174],
        [-21, -129],
        [-20, -69],
        [-17, -8],
        [-13, -21],
        [-8, -33],
        [-14, -27],
        [-21, -22],
        [-11, -24],
        [-2, -26],
        [-20, -16],
        [-38, -8],
        [-23, -21],
        [-6, -34],
        [-13, -27],
        [-19, -20],
        [-9, -33],
        [1, -46],
        [-11, -40],
        [-23, -33],
        [-21, -1],
        [-19, 31],
        [-25, 21],
        [-33, 10],
        [-26, -8],
        [-22, -26],
        [-19, -41],
        [-17, -57],
        [-18, -9],
        [-21, 38],
        [-26, 11],
        [-31, -15],
        [-25, 5],
        [-18, 26],
        [-28, 3],
        [-39, -20],
        [-77, 23],
        [-117, 65],
        [-99, 25],
        [-121, -23],
        [-11, 68],
        [7, 37],
        [19, 27],
        [13, 32],
        [5, 35],
        [13, 27],
        [23, 18],
        [9, 19],
        [-3, 19],
        [4, 16],
        [13, 15],
        [7, 23],
        [2, 31],
        [5, 15],
        [8, 12],
        [1, 21],
        [-4, 67],
        [0, 55],
        [6, 43],
        [8, 25],
        [5, 7],
        [5, 15],
        [2, 26],
        [8, 24],
        [38, 49],
        [15, 27],
        [1, 24],
        [6, 33],
        [23, 71],
        [7, 34],
        [1, 16],
        [8, 17],
        [27, 40],
        [16, 37],
        [2, 35],
        [-7, 39],
        [-16, 45],
        [-49, 110],
        [-38, 50],
        [-49, 42],
        [-33, 13],
        [-15, -14],
        [-16, 11],
        [-16, 38],
        [-27, 29],
        [-57, 33],
        [-128, 129],
        [-52, 62],
        [-17, 39],
        [-48, 69],
        [-79, 100],
        [-61, 48],
        [-42, -2],
        [-67, 29],
        [-93, 61],
        [-54, 59],
        [-14, 57],
        [-35, 57],
        [-54, 57],
        [-28, 38],
        [-2, 18],
        [-16, 24],
        [-31, 29],
        [-33, 50],
        [-36, 71],
        [-38, 109],
        [-41, 148],
        [-44, 100],
        [-47, 51],
        [-23, 35],
        [0, 16],
        [-7, 16],
      ],
      [
        [32597, 39035],
        [6, 29],
        [17, 112],
        [25, 164],
        [25, 169],
        [31, 199],
        [0, 141],
        [0, 149],
        [43, 122],
        [30, 87],
        [27, 83],
        [26, 142],
        [18, 94],
        [68, 23],
        [116, 49],
        [57, 24],
        [122, 52],
        [123, 53],
        [130, 3],
        [125, 3],
        [97, -117],
        [74, -90],
        [82, -99],
        [5, -21],
        [6, -83],
        [-6, -96],
      ],
      [
        [92490, 49103],
        [-21, -44],
        [-13, 41],
        [-16, 36],
        [-13, 36],
        [-17, 79],
        [0, 40],
        [4, 42],
        [1, 43],
        [-8, 87],
        [-19, 78],
        [-68, 189],
        [-21, 49],
        [-24, 44],
        [-16, 11],
        [-31, 11],
        [-14, 8],
        [-26, 31],
        [-24, 36],
        [-60, 106],
        [-31, 30],
        [-17, 37],
        [-94, 120],
        [-27, 28],
        [-34, 0],
        [-28, 24],
        [22, 15],
        [5, 40],
        [-5, 41],
        [47, -67],
        [50, -58],
        [14, -47],
        [25, -3],
        [45, -38],
        [30, -35],
        [29, -40],
        [33, -58],
        [62, -45],
        [9, -17],
        [32, -75],
        [42, -64],
        [14, -35],
        [177, -301],
        [30, -85],
        [2, -58],
        [-6, -22],
        [-19, -49],
        [1, -58],
        [-6, -51],
        [-16, -52],
      ],
      [
        [92198, 49368],
        [14, -12],
        [42, 60],
        [22, -42],
        [28, -20],
        [30, -12],
        [-12, -86],
        [4, -40],
        [7, -40],
        [-1, -59],
        [-13, -52],
        [-26, -76],
        [-12, -15],
        [-13, -8],
        [-43, -6],
        [-8, -40],
        [3, -43],
        [23, -57],
        [18, -63],
        [-18, -59],
        [-30, -40],
        [-29, -21],
        [-47, 12],
        [-50, -5],
        [-10, -22],
        [0, -37],
        [-7, -28],
        [-9, -25],
        [-25, -53],
        [-29, -46],
        [-38, -45],
        [-13, -10],
        [-35, -7],
        [-31, -26],
        [-13, -25],
        [-15, -20],
        [-33, -22],
        [-32, -43],
        [-12, -8],
        [-66, -7],
        [-95, -2],
        [-28, -5],
        [-27, 6],
        [-15, 17],
        [-32, 79],
        [-28, 26],
        [-30, 0],
        [-41, -28],
        [-8, 6],
        [-80, 116],
        [-25, 28],
        [-26, 21],
        [-32, 15],
        [-30, 23],
        [-18, 55],
        [2, 72],
        [24, 42],
        [37, -21],
        [14, 0],
        [14, 12],
        [16, -4],
        [17, -10],
        [60, 15],
        [34, -22],
        [34, -29],
        [32, -6],
        [32, 6],
        [43, 33],
        [14, -4],
        [42, 0],
        [36, 44],
        [14, 177],
        [9, 60],
        [13, 14],
        [9, -4],
        [13, -30],
        [-17, -38],
        [-8, -29],
        [-2, -71],
        [9, -69],
        [22, -54],
        [32, -7],
        [29, 36],
        [32, 7],
        [30, -35],
        [30, 6],
        [14, 23],
        [16, 10],
        [16, 4],
        [14, 13],
        [20, 60],
        [13, 67],
        [19, 52],
        [52, 88],
        [15, 11],
        [17, 6],
        [37, -4],
        [27, 32],
        [2, 70],
        [-4, 72],
        [-31, 167],
        [-2, 27],
        [4, 30],
        [9, 27],
        [31, 0],
        [32, -9],
        [13, -26],
        [14, -20],
      ],
      [
        [89159, 46590],
        [0, 8],
        [0, 117],
        [0, 117],
        [0, 117],
        [0, 117],
        [0, 117],
        [0, 117],
        [0, 117],
        [0, 117],
        [0, 117],
        [0, 118],
        [0, 96],
        [-16, 38],
        [-15, 57],
        [3, 74],
        [19, 92],
        [9, 61],
        [0, 50],
        [0, 117],
        [0, 117],
        [0, 117],
        [0, 117],
        [0, 118],
        [0, 117],
        [0, 117],
        [0, 117],
        [0, 117],
        [0, 117],
        [0, 117],
        [0, 117],
        [0, 117],
        [0, 117],
        [-1, 118],
        [0, 117],
        [0, 117],
        [0, 70],
        [0, 39],
        [0, 2],
      ],
      [
        [89158, 50339],
        [9, 0],
        [28, -1],
        [22, -9],
        [140, -125],
        [41, -50],
        [14, -12],
        [14, -1],
        [14, -5],
        [62, -70],
        [94, -69],
        [99, -67],
        [31, -14],
        [31, -6],
        [69, -23],
        [37, -21],
        [53, -82],
        [27, -25],
        [25, -46],
        [35, -50],
        [15, -12],
        [15, -6],
        [35, -2],
        [35, 9],
        [15, -4],
        [14, -9],
        [13, -17],
        [6, -33],
        [22, -47],
        [31, -20],
        [29, -42],
        [27, -50],
        [19, -50],
        [22, -43],
        [34, -18],
        [35, -2],
        [120, -253],
        [6, -39],
        [1, -165],
        [-13, -129],
        [30, -40],
        [40, -15],
        [58, -28],
        [55, -41],
        [175, -174],
        [24, -15],
        [35, -6],
        [36, 3],
        [13, -9],
        [26, -32],
        [14, -20],
        [24, -57],
        [21, -61],
        [9, -17],
        [11, -14],
        [6, -33],
        [9, -103],
        [-3, -64],
        [-9, -24],
        [-28, -11],
        [-99, -11],
        [-65, 12],
        [-46, -65],
        [-2, -28],
        [4, -27],
        [40, -137],
        [23, -121],
        [20, -50],
        [29, -40],
        [26, -48],
        [24, -54],
        [50, -95],
        [27, -36],
        [31, -21],
        [53, -74],
        [7, -33],
        [16, -103],
        [6, -69],
        [1, -29],
        [4, -26],
        [46, -63],
        [11, -18],
        [20, -140],
        [16, -65],
        [27, -24],
        [31, 2],
        [85, 42],
        [12, 1],
        [16, -10],
        [14, -23],
        [4, -63],
        [-13, -66],
        [-4, -64],
        [17, -52],
        [43, -41],
        [16, -12],
        [78, -13],
        [30, -11],
        [30, -17],
        [11, -16],
        [-8, -28],
        [-15, -14],
        [-18, -6],
        [-29, -20],
        [1, -36],
        [16, -38],
        [15, -45],
        [12, -17],
        [14, -11],
        [33, -15],
        [33, -22],
        [22, -22],
        [22, -15],
        [48, -10],
        [36, -29],
        [51, 12],
        [-44, -47],
        [-15, -11],
        [-53, 17],
        [-10, -18],
        [22, -50],
        [32, -34],
        [12, -19],
        [-9, -23],
        [-37, -46],
        [-16, -6],
        [-29, -4],
        [-50, 20],
        [-35, 25],
        [-9, 34],
        [-10, 20],
        [-31, 49],
        [-23, 26],
        [-28, 9],
        [-30, -1],
        [-53, 28],
        [-116, 20],
        [-27, 12],
        [-35, 39],
        [-16, 6],
        [-18, -12],
        [-44, -8],
        [-13, 3],
        [-32, 33],
        [-33, 12],
        [-14, -9],
        [-14, -3],
        [-44, 24],
        [-34, 10],
        [-28, 33],
        [-15, 30],
        [-17, 28],
        [-16, 70],
        [-24, 67],
        [-31, 54],
        [-65, 89],
        [-13, 22],
        [-25, 81],
        [1, 54],
        [9, 54],
        [-14, -19],
        [-16, 3],
        [-44, 36],
        [-18, 42],
        [-30, 116],
        [-19, 62],
        [-44, 108],
        [-13, 64],
        [-18, 56],
        [-12, 21],
        [-9, 24],
        [-9, 32],
        [-12, 21],
        [-62, 49],
        [-11, 15],
        [-12, 8],
        [-46, 5],
        [-26, 8],
        [-50, 39],
        [-26, 12],
        [-31, 7],
        [-30, 15],
        [-15, 15],
        [-10, 25],
        [-6, 59],
        [-25, -7],
        [-25, 10],
        [-24, 21],
        [-24, 13],
        [-17, -18],
        [-5, -47],
        [-8, -2],
        [-14, 9],
        [-7, -5],
        [-16, -22],
        [-12, -29],
        [-23, 4],
        [-47, 30],
        [-21, 18],
        [-18, 34],
        [-15, 38],
        [-16, 29],
        [-19, 22],
        [24, -51],
        [56, -228],
        [-14, -4],
        [-14, 6],
        [13, -44],
        [-15, -6],
        [-15, 0],
        [-32, 19],
        [-31, 6],
        [-10, -9],
        [7, -16],
        [11, -49],
        [9, -50],
        [-46, -23],
        [-46, -14],
        [-52, -27],
        [-53, -2],
        [-27, 16],
        [-28, 9],
        [-26, -9],
        [-25, -20],
        [-21, 3],
        [-14, 36],
        [-6, 27],
        [-8, 23],
        [-20, -4],
        [-19, -12],
        [33, -2],
        [10, -30],
        [8, -36],
        [23, -32],
        [29, 19],
        [61, -5],
        [60, -57],
        [14, -7],
        [13, -11],
        [31, -56],
        [24, -51],
        [19, -59],
        [4, -23],
        [-1, -61],
        [-6, -30],
        [-39, -43],
        [-41, -33],
        [-61, -63],
        [-59, -73],
        [-31, 15],
        [-28, 38],
        [-10, 10],
        [-29, 21],
        [-18, 7],
        [-69, -16],
        [-70, -8],
        [-30, 1],
        [-28, 12],
        [-32, 23],
        [-31, -11],
        [-21, -26],
        [-23, -4],
        [-44, 59],
      ],
      [
        [91117, 48486],
        [-11, -4],
        [-5, 26],
        [-26, 23],
        [-26, 70],
        [1, 61],
        [3, 17],
        [15, 1],
        [57, -70],
        [6, -22],
        [-4, -66],
        [-10, -36],
      ],
      [
        [90560, 49120],
        [-19, -17],
        [-13, 13],
        [-6, 38],
        [5, 36],
        [16, 29],
        [10, 9],
        [12, -20],
        [4, -38],
        [-9, -50],
      ],
      [
        [92408, 50038],
        [-7, -51],
        [-17, 30],
        [-12, 42],
        [8, 20],
        [19, 11],
        [9, -52],
      ],
      [
        [92249, 50145],
        [-3, -29],
        [-9, 1],
        [-24, 57],
        [-4, 15],
        [5, 14],
        [28, -41],
        [7, -17],
      ],
      [
        [91601, 50948],
        [-1, -21],
        [-20, 11],
        [-6, -3],
        [-34, 60],
        [0, 37],
        [9, 31],
        [15, -5],
        [25, -39],
        [12, -71],
      ],
      [
        [91966, 46071],
        [12, 0],
        [20, 43],
        [17, 13],
        [11, -20],
        [-18, -137],
        [-16, 21],
        [-60, 38],
        [-2, 54],
        [-15, 18],
        [-10, 53],
        [-20, 58],
        [-4, 38],
        [12, -16],
        [12, -38],
        [53, -79],
        [-2, -25],
        [10, -21],
      ],
      [
        [91762, 46374],
        [-4, -14],
        [-16, 10],
        [-46, 80],
        [7, 59],
        [21, 30],
        [31, -33],
        [10, -49],
        [3, -27],
        [-6, -56],
      ],
      [
        [91973, 46812],
        [5, -41],
        [-22, 44],
        [10, 50],
        [2, 27],
        [-1, 15],
        [-22, 26],
        [11, 42],
        [13, 14],
        [7, 4],
        [0, -59],
        [6, -27],
        [-9, -95],
      ],
      [
        [92855, 45298],
        [-4, -31],
        [-10, 10],
        [-30, -16],
        [-16, 4],
        [-9, 28],
        [-3, 13],
        [27, -10],
        [-5, 31],
        [38, -16],
        [12, -13],
      ],
      [
        [89884, 46957],
        [-12, -2],
        [-49, 39],
        [-12, 29],
        [57, -7],
        [15, -7],
        [3, -40],
        [-2, -12],
      ],
      [
        [90881, 48714],
        [-15, -3],
        [-26, 54],
        [-6, 21],
        [3, 28],
        [32, 39],
        [21, -35],
        [4, -75],
        [-13, -29],
      ],
      [
        [90851, 50714],
        [93, -38],
        [6, 1],
        [-1, 13],
        [1, 4],
        [6, -10],
        [-2, -27],
        [-15, -7],
        [-13, 3],
        [-10, -15],
        [-26, -52],
        [-18, 9],
        [-22, -12],
        [-38, -1],
        [-50, 23],
        [-13, -20],
        [-18, 6],
        [-17, -22],
        [-8, 1],
        [-4, 32],
        [1, 16],
        [20, 14],
        [-3, 49],
        [17, 25],
        [29, -3],
        [27, 17],
        [58, -6],
      ],
      [
        [91787, 50309],
        [-55, -8],
        [-20, 9],
        [-18, 33],
        [-16, 52],
        [-16, 12],
        [-7, 11],
        [39, 39],
        [35, 12],
        [56, -49],
        [6, -25],
        [0, -16],
        [-1, -53],
        [-3, -17],
      ],
      [
        [91813, 46459],
        [39, -48],
        [21, 14],
        [12, -7],
        [25, -55],
        [1, -40],
        [4, -34],
        [-1, -15],
        [-14, -21],
        [1, 24],
        [-10, 4],
        [-37, -1],
        [-28, 15],
        [-39, 4],
        [16, 36],
        [4, 14],
        [-21, 59],
        [0, 28],
        [1, 15],
        [17, 8],
        [9, 0],
      ],
      [
        [92397, 46682],
        [16, -9],
        [33, 4],
        [11, -33],
        [16, -11],
        [13, -15],
        [12, -22],
        [0, -13],
        [-3, -12],
        [-7, -10],
        [2, -23],
        [-12, 3],
        [-16, -12],
        [-30, 27],
        [-11, 6],
        [-3, 24],
        [-19, 39],
        [-35, 28],
        [18, 23],
        [15, 6],
      ],
      [
        [92648, 45232],
        [46, -30],
        [16, -34],
        [-17, -15],
        [-40, -10],
        [-10, 20],
        [-39, 21],
        [-6, 37],
        [-19, -13],
        [10, 27],
        [-25, 29],
        [-7, 40],
        [-1, 16],
        [28, -19],
        [64, -69],
      ],
      [
        [89885, 46869],
        [5, -25],
        [-40, 35],
        [-39, 58],
        [-19, 15],
        [-13, 38],
        [24, -28],
        [41, -27],
        [41, -66],
      ],
      [
        [91076, 50527],
        [-9, -30],
        [-21, 3],
        [-9, 9],
        [15, 6],
        [6, 25],
        [7, 8],
        [11, -21],
      ],
      [
        [92209, 50212],
        [-6, 0],
        [-1, 46],
        [4, 24],
        [16, -16],
        [2, -42],
        [-15, -12],
      ],
      [
        [91915, 45757],
        [-4, -45],
        [-22, 13],
        [-5, 10],
        [4, 28],
        [20, 1],
        [7, -7],
      ],
      [
        [92682, 49481],
        [-2, -13],
        [-17, 15],
        [14, 30],
        [6, 2],
        [-1, -34],
      ],
      [
        [93321, 47991],
        [-7, -54],
        [-5, -9],
        [-7, 20],
        [-24, -20],
        [-11, -22],
        [-13, -17],
        [-28, 4],
        [-27, 15],
        [-26, 28],
        [-23, 35],
        [-23, 55],
        [-14, 57],
        [7, 66],
        [-9, 60],
        [-44, 43],
        [-10, 14],
        [-19, 59],
        [-19, 26],
        [-25, 52],
        [-6, 23],
        [-11, 66],
        [-3, 40],
        [9, 116],
        [-4, 58],
        [12, -5],
        [13, -23],
        [15, -16],
        [35, -11],
        [27, -46],
        [25, -90],
        [4, -30],
        [8, -21],
        [26, -38],
        [14, -25],
        [26, -98],
        [15, -21],
        [17, -9],
        [16, -14],
        [27, -43],
        [24, -49],
        [17, -51],
        [12, -55],
        [9, -70],
      ],
      [
        [92957, 48713],
        [-6, -4],
        [-12, 72],
        [-2, 54],
        [-4, 40],
        [-6, 24],
        [18, 43],
        [8, 12],
        [13, -23],
        [2, -51],
        [11, -43],
        [-8, -95],
        [-14, -29],
      ],
      [
        [28507, 56830],
        [-5, -8],
        [-16, -45],
        [-8, -39],
        [20, -41],
        [6, -44],
        [11, -47],
        [17, -47],
        [20, -89],
        [4, -35],
        [-5, -23],
        [-19, -14],
        [-17, -41],
        [-5, -51],
        [3, -25],
        [-52, -80],
        [-13, -13],
        [-9, 12],
        [-11, 40],
        [-13, 33],
        [-8, 11],
        [-4, 1],
        [-4, -8],
        [-2, -17],
        [7, -76],
        [-6, -30],
        [-17, -24],
        [-20, -123],
      ],
      [
        [28361, 56007],
        [-8, 16],
        [-67, 165],
        [-58, 205],
        [-12, 93],
        [15, 6],
        [14, -3],
        [8, 15],
        [9, 27],
        [-7, 63],
        [28, 47],
        [11, 32],
        [7, -3],
        [19, -55],
        [26, -31],
        [33, -46],
        [21, -10],
        [-26, 47],
        [-44, 63],
        [-13, 42],
        [-12, 57],
        [-17, -25],
        [-8, -21],
        [-9, -12],
        [-8, 15],
        [-1, 18],
        [-26, 4],
        [-7, 17],
        [-7, 9],
        [3, -36],
        [5, -22],
        [-2, -27],
        [-9, -1],
        [-7, 27],
        [-9, 25],
        [-13, 105],
        [-29, 49],
        [-14, 16],
        [-11, 7],
        [-17, 33],
        [-22, 18],
        [-29, 52],
        [-37, 37],
        [-44, 14],
        [-54, -9],
        [-19, -20],
        [-12, -27],
        [-6, -12],
        [-32, -30],
        [-12, -43],
        [-7, -37],
        [-16, -42],
        [18, -25],
        [-104, -142],
        [-21, -20],
        [-47, -15],
        [-11, -15],
        [-14, -28],
        [-2, -42],
        [2, -37],
        [14, -28],
        [12, -17],
        [29, -85],
        [52, -106],
        [9, -39],
        [8, -57],
        [-15, -27],
        [-12, -11],
        [-49, -5],
        [-17, -23],
        [-7, -35],
        [-18, -29],
        [-64, -28],
        [-49, -3],
        [-16, 33],
        [-4, 92],
        [-33, 158],
        [-8, 108],
        [-8, -13],
        [-18, -13],
        [-6, -27],
        [-5, -80],
        [-6, -27],
        [-14, 2],
        [-28, 29],
        [-37, 26],
        [-48, 170],
        [-5, 32],
        [-9, 38],
        [-37, 16],
        [-32, 29],
        [-34, 4],
        [-17, -16],
        [-18, 21],
        [-3, 46],
        [-36, -21],
        [-47, 7],
        [-41, 20],
        [-28, -10],
        [-24, -33],
        [4, -85],
        [-7, -16],
      ],
      [
        [26978, 56492],
        [-1, 34],
        [-9, 40],
        [-10, 33],
        [-20, 34],
        [-1, 12],
        [8, 18],
        [38, 49],
        [4, 21],
        [1, 43],
        [-4, 41],
        [-17, 60],
        [10, 38],
        [19, 30],
        [20, 24],
        [4, 10],
        [-4, 20],
        [-12, 22],
        [-27, 38],
        [-16, 3],
        [-1, 108],
        [1, 116],
        [4, 11],
        [10, 7],
        [8, 18],
        [5, 34],
        [11, 12],
        [22, -26],
        [22, -24],
        [9, 8],
        [7, 11],
        [5, 12],
        [1, 10],
      ],
      [
        [27065, 57359],
        [18, -31],
        [36, -54],
        [2, -27],
        [-3, -26],
        [10, -74],
        [18, -10],
        [19, 14],
        [5, -14],
        [-4, -13],
        [-9, -15],
        [-3, -64],
        [31, -30],
        [15, -26],
        [51, 13],
        [19, -7],
        [13, 7],
        [-14, 51],
        [-19, 38],
        [1, 17],
        [15, -13],
        [11, -25],
        [25, -32],
        [46, -111],
        [53, -26],
        [42, 3],
        [39, 15],
        [63, 43],
        [45, 78],
        [36, 34],
        [116, 74],
        [42, 77],
        [17, 10],
        [17, 10],
        [36, 58],
        [20, 46],
        [21, 22],
        [62, -16],
        [39, -22],
        [28, 3],
        [27, -15],
        [11, -33],
        [12, -14],
        [65, 3],
        [54, -16],
        [117, -98],
        [70, -97],
        [37, -103],
        [90, -133],
      ],
      [
        [28084, 56609],
        [-6, -24],
        [-13, 54],
        [2, 14],
        [-1, 49],
        [13, 13],
        [9, 1],
        [7, -7],
        [5, -58],
        [-4, -26],
        [-12, -16],
      ],
      [
        [27332, 56067],
        [-15, -3],
        [-31, 24],
        [-23, 48],
        [-2, 15],
        [1, 16],
        [12, 49],
        [17, 17],
        [6, 0],
        [16, -57],
        [-11, -21],
        [5, -35],
        [22, -26],
        [3, -27],
      ],
      [
        [27157, 57246],
        [-3, -27],
        [-21, 49],
        [12, 8],
        [5, -1],
        [7, -29],
      ],
      [
        [28037, 56597],
        [-12, -25],
        [-5, 24],
        [9, 25],
        [3, 0],
        [5, -24],
      ],
      [
        [86436, 53586],
        [-6, -3],
        [-4, 2],
        [0, 8],
        [4, 9],
        [6, 4],
        [4, -3],
        [0, -8],
        [-4, -9],
      ],
      [
        [87387, 56095],
        [-17, -12],
        [-8, 44],
        [3, 51],
        [11, 39],
        [12, 13],
        [2, 4],
        [12, 51],
        [3, -28],
        [-8, -93],
        [-9, -37],
        [-1, -32],
      ],
      [
        [71323, 72385],
        [13, -52],
        [19, -78],
        [13, -51],
        [14, -58],
        [7, -29],
        [13, -50],
      ],
      [
        [71402, 72067],
        [-5, -27],
        [-9, -41],
        [-30, -30],
        [-30, -23],
        [-7, -12],
        [-3, -18],
        [-14, -35],
        [-28, -29],
        [-24, 3],
        [-15, 8],
        [-79, -51],
        [-36, 2],
        [-29, -33],
        [-21, -30],
        [-42, -33],
        [-29, -1],
        [-43, 20],
        [-52, 37],
        [-21, 22],
        [-19, -1],
        [-47, 5],
        [-45, 18],
        [-54, 22],
        [-27, 10],
        [-55, 19],
        [-35, -26],
        [-33, -23],
        [-26, -16],
        [-22, -71],
        [-9, -26],
        [-10, -36],
        [-5, -25],
        [4, -31],
        [32, -21],
        [13, -30],
        [2, -26],
        [-11, -27],
        [-10, -21],
        [0, -19],
        [5, -18],
        [8, -14],
        [45, -9],
        [27, 0],
        [10, -8],
        [2, -25],
        [-10, -34],
        [-38, -28],
        [-22, -29],
        [-7, -39],
        [1, -30],
        [7, -21],
        [18, -23],
        [17, -27],
        [6, -22],
        [-3, -30],
        [-6, -41],
        [-19, -48],
        [-16, -34],
        [-1, -12],
        [4, -18],
        [12, -27],
        [22, -39],
        [27, -31],
        [17, -9],
        [5, -8],
        [6, -37],
        [2, -38],
        [-7, -29],
        [13, -24],
        [36, 1],
        [29, -10],
        [13, 10],
        [8, -8],
        [-5, -86],
        [4, -51],
        [7, -15],
        [29, -21],
        [55, 3],
        [33, -24],
        [36, -28],
        [19, -31],
        [8, -22],
        [-2, -37],
        [-20, -43],
        [-32, -21],
        [-19, -9],
        [-92, -81],
        [-29, -34],
        [-22, -41],
        [-8, -31],
        [-4, -30],
        [19, -108],
        [4, -34],
        [-17, -118],
        [-4, -43],
        [6, -31],
        [19, -11],
        [5, -26],
        [2, -19],
        [-35, -43],
        [-35, -39],
        [-12, 0],
        [-34, -71],
        [-58, -144],
        [-30, -49],
        [-2, -23],
        [-3, -24],
        [12, -41],
        [2, -34],
        [-13, -35],
        [-21, -40],
        [-42, -34],
        [-53, -36],
        [-24, -21],
        [-18, -93],
        [-16, -94],
        [-8, -34],
        [-28, -108],
        [-50, -158],
        [-13, -35],
        [-77, -76],
        [-79, -83],
        [-14, -32],
        [-16, -75],
        [-15, -83],
        [-14, -44],
        [-50, -97],
        [-17, -75],
        [-5, -49],
        [-42, -27],
        [-49, -26],
        [-70, -9],
        [-29, -13],
        [-86, -68],
        [-22, -3],
        [-16, 12],
        [-13, 23],
        [-12, 38],
        [-6, 59],
        [-16, 26],
        [-23, 23],
        [-23, 1],
        [-24, -25],
        [-20, -27],
        [-14, -23],
        [-14, -26],
        [-26, -89],
        [-43, -128],
        [-48, -92],
        [-17, -28],
        [-11, -21],
        [-15, -31],
        [-9, -30],
        [-12, -97],
        [-6, -86],
        [3, -19],
        [7, -17],
        [26, -25],
        [37, -41],
        [49, -24],
        [41, -4],
        [16, -18],
        [9, -24],
        [2, -20],
        [-2, -71],
        [-4, -77],
        [-16, -82],
        [1, -47],
        [6, -46],
        [45, -117],
        [17, -12],
        [34, -3],
        [16, 2],
        [18, 12],
        [13, -8],
        [9, -14],
        [2, -24],
        [-1, -117],
        [14, -53],
        [27, -72],
        [22, -82],
        [20, -99],
        [19, -77],
        [8, -40],
        [-13, -20],
        [-7, -20],
        [-2, -27],
        [3, -29],
        [-2, -20],
        [9, -25],
        [11, -8],
        [0, -17],
        [-17, -23],
        [-15, 1],
        [-12, -10],
        [-23, -48],
        [-10, -9],
        [-14, -4],
        [-16, 4],
        [-22, 19],
        [-7, 30],
        [3, 32],
        [-5, 18],
        [-16, -3],
        [-56, -32],
        [-53, -40],
        [-9, -27],
        [-12, -28],
        [-25, -12],
        [-35, -3],
        [-25, 4],
        [-23, 30],
        [-21, 28],
        [-32, 1],
        [-58, -4],
        [-32, 0],
        [-19, 10],
        [-18, -7],
        [-24, 11],
        [-10, -15],
        [-10, -1],
        [-8, 26],
        [-5, 2],
        [-6, -4],
        [-5, -8],
        [-4, -15],
        [-1, -174],
        [-38, 1],
        [-27, 1],
        [-30, -10],
        [-27, -13],
        [-14, -16],
        [-19, -25],
      ],
      [
        [68934, 65585],
        [-4, -34],
        [-10, -26],
        [-13, 38],
        [-9, 17],
        [-9, -13],
        [-14, 2],
        [-26, 42],
        [-11, -43],
        [-42, -9],
        [-5, 32],
        [-1, 30],
        [-23, -22],
        [-17, 34],
        [-7, 45],
        [-6, 12],
        [-8, 15],
        [-17, 15],
        [-16, 48],
        [-1, 51],
        [-4, 60],
        [-33, 224],
        [-20, 21],
        [-110, 39],
        [-6, 40],
        [8, 105],
        [-3, 66],
        [-36, 88],
        [-10, 61],
        [-29, 52],
        [-29, 15],
        [-30, -7],
        [-15, -20],
        [-9, -35],
        [63, 8],
        [14, -13],
        [17, -23],
        [-18, 1],
        [-21, 11],
        [-26, -1],
        [-98, -26],
        [-56, -37],
        [-76, 11],
        [-96, -36],
        [-79, -2],
        [-33, -71],
        [-18, 12],
        [-14, 18],
        [-109, 56],
        [-7, 23],
        [-18, 17],
        [-20, -30],
        [-15, -5],
        [-59, 25],
        [-46, -19],
        [-17, -32],
        [-1, -50],
        [-57, 10],
        [-32, 15],
        [-43, -17],
        [-98, 23],
        [-25, -6],
        [-36, -33],
        [-15, -26],
        [-21, -10],
        [-18, 36],
        [-14, 16],
        [-13, -10],
        [-18, -30],
        [-50, -14],
        [-46, 4],
        [-49, 28],
        [6, 9],
      ],
      [
        [67107, 66360],
        [8, 48],
        [7, 172],
        [8, 62],
        [-2, 34],
        [2, 10],
        [19, 31],
        [4, 12],
        [8, 88],
        [8, 98],
        [9, 35],
        [7, 9],
        [61, 44],
        [11, 29],
        [31, -7],
        [3, 7],
        [3, 34],
        [14, 36],
        [21, 30],
        [15, 11],
        [54, 19],
        [32, 26],
        [10, 2],
        [85, -6],
        [18, 10],
        [3, 9],
        [5, 99],
        [15, 16],
        [3, 8],
        [-5, 69],
        [3, 45],
        [17, 27],
        [-1, 16],
        [-12, 32],
        [-17, 21],
        [-8, 5],
        [-70, -20],
        [-29, 7],
        [-14, 12],
        [-2, 9],
        [2, 20],
        [1, 32],
        [10, 51],
        [3, 30],
        [-8, 175],
        [-12, 116],
        [6, 115],
        [-1, 24],
        [-2, 5],
        [-9, 0],
        [-43, -10],
        [-36, 74],
        [-22, 30],
        [-62, 36],
        [-27, 7],
        [-40, 32],
        [-37, 70],
        [-37, 72],
        [-15, 45],
        [-17, 78],
        [-47, 149],
        [0, 39],
        [-6, 23],
        [-46, 98],
        [-33, 70],
        [-53, 112],
      ],
      [
        [66900, 69042],
        [106, -63],
        [83, -48],
        [133, -78],
        [103, -60],
        [29, -10],
        [303, 52],
        [112, -40],
        [36, -22],
        [5, 13],
        [15, 27],
        [26, 27],
        [35, 21],
        [36, 12],
        [51, 1],
        [34, -1],
        [25, -7],
        [49, 4],
        [24, 10],
        [81, 43],
        [54, 29],
        [82, 44],
        [60, 33],
        [15, 17],
        [15, 32],
        [8, 28],
        [-19, 43],
        [-2, 38],
        [12, 48],
        [7, 74],
        [-2, 104],
        [-4, 61],
        [17, 112],
        [14, 63],
        [28, 30],
        [19, 19],
        [8, 13],
        [8, 15],
        [30, 86],
        [27, 40],
        [26, 24],
        [29, -3],
        [25, -33],
        [47, -15],
        [46, 10],
        [40, 25],
        [18, 20],
        [21, 18],
        [-1, 20],
        [-24, 18],
        [-14, 25],
        [-5, 31],
        [14, 18],
        [31, 6],
        [77, 74],
        [31, 49],
        [9, 23],
        [15, 3],
        [29, -23],
        [34, -8],
        [22, 23],
        [21, 5],
        [21, -25],
        [11, -29],
        [20, -36],
        [24, -7],
        [29, 19],
        [30, 41],
        [29, 58],
        [26, 56],
        [-7, 180],
        [-4, 107],
        [14, 55],
        [19, 35],
        [13, 53],
        [-1, 47],
        [14, 40],
        [13, 107],
        [19, 26],
        [37, 17],
        [61, 11],
        [47, 49],
        [47, 52],
        [7, 46],
        [-18, 50],
        [-24, 95],
        [-22, 57],
        [-52, 103],
        [6, 63],
        [29, 26],
        [72, -44],
        [20, -9],
        [25, -6],
        [66, 1],
        [54, 17],
        [57, 39],
        [11, 41],
        [-1, 48],
        [1, 40],
        [1, 56],
        [-20, 35],
        [-12, 32],
        [-4, 25],
        [14, 14],
        [14, 26],
        [13, 47],
        [31, 57],
        [19, 51],
        [18, 24],
        [27, 33],
        [17, 48],
        [8, 29],
        [16, 29],
        [5, 18],
        [-4, 17],
        [-8, 21],
        [-9, 23],
        [0, 23],
        [7, 24],
        [8, 22],
        [-3, 30],
        [-5, 50],
        [-14, 29],
        [-10, 68],
        [-16, 68],
        [-8, 27],
        [-15, 34],
        [-34, 35],
        [-10, 24],
        [13, 46],
        [22, 29],
        [42, 70],
        [23, 48],
        [21, 34],
        [27, -5],
        [15, 3],
        [14, 31],
        [27, 28],
        [49, 57],
        [17, 39],
        [26, 19],
        [21, 5],
        [29, 13],
        [28, 21],
        [25, 16],
        [40, 3],
        [63, 9],
        [35, 10],
        [81, 8],
        [89, 3],
        [11, 1],
        [38, -21],
        [26, -17],
        [11, 2],
        [43, 41],
        [66, 50],
        [30, 22],
      ],
      [
        [70705, 73169],
        [17, 8],
        [25, 0],
        [21, -14],
        [21, -19],
        [13, -15],
        [16, 9],
        [30, 11],
        [25, -8],
        [56, -35],
        [8, -17],
        [13, -84],
        [10, -7],
        [32, 19],
        [26, -10],
        [29, -27],
        [19, -26],
        [12, -28],
        [14, -46],
        [5, -36],
        [6, -44],
        [-1, -123],
        [-10, -20],
        [-8, -26],
        [2, -23],
        [9, -18],
        [18, -12],
        [17, -8],
        [9, -19],
        [12, -70],
        [9, -10],
        [20, 0],
        [38, 15],
        [32, 24],
        [14, 5],
        [3, -66],
        [19, -25],
        [26, -29],
        [11, -10],
      ],
      [
        [66311, 63489],
        [-17, -8],
        [-6, 4],
        [1, 73],
        [40, 91],
        [27, 106],
        [19, -94],
        [-33, -53],
        [-17, -91],
        [-14, -28],
      ],
      [
        [65663, 66232],
        [28, -152],
        [42, -142],
        [37, -78],
        [38, -106],
        [59, -98],
        [27, -33],
        [108, -69],
        [60, -25],
        [82, -25],
        [57, -53],
        [19, -3],
        [29, 15],
        [22, -1],
        [54, -73],
        [16, -69],
        [23, -36],
        [20, -57],
        [13, -60],
        [45, -92],
        [33, -103],
        [33, -76],
        [29, -47],
        [44, -19],
        [36, -21],
        [4, -51],
        [-4, -67],
        [-7, -49],
        [-33, -96],
        [-8, -59],
        [-37, -97],
        [-41, -163],
        [-18, -37],
        [-66, -84],
        [-48, -102],
        [-57, -176],
        [-43, -174],
        [-17, -56],
        [-35, -12],
        [-23, 5],
        [-15, 17],
        [6, 47],
        [4, 54],
        [-21, -6],
        [-19, -11],
        [-43, -131],
        [-24, -57],
        [-5, -73],
        [-12, -94],
        [-16, -86],
        [-8, -73],
        [0, -41],
        [13, -101],
        [1, -103],
        [7, -62],
        [6, -74],
        [-20, -23],
        [-18, -11],
        [-68, -8],
        [-70, -24],
        [-61, -43],
        [-37, -43],
        [-47, -95],
        [-29, -243],
        [-47, -103],
        [-31, -21],
        [-76, -9],
        [-106, -28],
        [-38, -25],
        [-62, -148],
        [-5, -47],
        [12, -34],
        [4, -37],
        [-5, -35],
        [-29, -94],
        [-30, -68],
        [-81, -43],
        [-30, 25],
        [-27, 13],
        [-53, 2],
        [-86, -17],
        [-31, -50],
        [-50, -36],
        [-46, -55],
        [-87, -21],
        [-59, -43],
      ],
      [
        [65577, 66856],
        [24, 83],
        [9, 13],
        [8, -6],
        [22, 9],
        [11, 45],
        [9, 25],
        [10, -3],
        [4, -14],
        [-3, -69],
        [0, -57],
        [-12, -175],
        [-13, -30],
        [-6, -25],
        [-2, -34],
      ],
      [
        [53163, 85850],
        [-1, 17],
        [-5, 22],
        [-65, 22],
        [-12, -1],
        [-25, 13],
        [-15, 4],
        [-31, 8],
        [-25, 64],
        [-28, 54],
        [-3, 22],
        [0, 101],
        [-8, 44],
        [-2, 49],
        [-17, -39],
        [9, -63],
        [-21, -26],
        [-26, -13],
        [2, -37],
        [11, -7],
        [3, -38],
        [-7, -56],
        [-52, -126],
        [-11, -13],
        [-7, -17],
        [-27, 11],
        [-34, -35],
        [-32, -5],
        [-12, 39],
        [-46, 53],
        [-22, -3],
        [20, -26],
        [19, -34],
        [-11, -22],
        [-11, -14],
        [-18, -8],
        [-67, -44],
        [23, -29],
        [-20, -34],
        [-23, -5],
        [-13, -15],
        [-4, -21],
        [-69, -61],
        [-113, -155],
        [-59, -44],
        [-40, -46],
        [-36, 2],
        [-45, -39],
        [-114, -34],
        [-75, 15],
        [-53, -13],
        [-28, 26],
        [-4, 19],
        [2, 10],
        [5, 13],
        [-10, 5],
        [-21, 2],
        [-9, -13],
        [-1, -29],
        [-10, -8],
        [-39, 17],
        [-10, 15],
        [14, 30],
        [25, 28],
        [-5, 5],
        [-5, 17],
        [-11, 2],
        [-35, -4],
        [-29, 5],
        [-93, 62],
        [-21, 33],
        [-75, 52],
        [-34, 56],
        [-19, 61],
        [2, 56],
        [9, 87],
        [15, 22],
        [68, -31],
        [68, -51],
        [10, 3],
        [22, 40],
        [41, 32],
        [-12, 9],
        [-61, -37],
        [-23, 21],
        [-36, 42],
        [0, 21],
        [17, 22],
        [5, 29],
        [-8, 28],
        [4, 37],
        [27, 40],
        [41, 40],
        [30, 39],
        [31, 24],
        [-4, 8],
        [-34, -15],
        [-34, -26],
        [-39, -44],
        [-48, -35],
        [-35, -13],
        [-17, -12],
        [-26, -11],
        [-27, -50],
        [-29, -21],
        [-53, -3],
        [-11, 37],
        [15, 131],
        [16, 64],
        [17, 45],
        [27, 7],
        [20, 34],
        [16, 0],
        [13, -16],
        [54, -15],
        [26, 43],
        [35, 6],
        [62, 42],
        [-1, 8],
        [-42, -9],
        [-26, -2],
        [-37, -10],
        [-20, 7],
        [-9, 32],
        [15, 29],
        [59, 68],
        [21, 30],
        [11, 28],
        [-2, 19],
        [10, 39],
        [58, 69],
        [47, 32],
        [15, -28],
        [-13, -84],
        [0, -35],
        [38, 123],
        [16, 30],
        [19, 20],
        [45, 14],
        [13, 20],
        [-53, -7],
        [-127, -47],
        [-54, -42],
        [-15, -32],
        [-37, -49],
        [-17, -31],
        [-8, -47],
        [-21, -25],
        [-29, -9],
        [-39, -59],
        [-17, -48],
        [-39, -37],
        [-25, -29],
        [-8, -11],
        [-14, -28],
        [-11, -2],
        [-10, 16],
        [-1, 37],
        [3, 59],
        [19, 42],
        [9, 42],
        [-12, 38],
        [9, 24],
        [16, 0],
        [31, -11],
        [33, 1],
        [54, 31],
        [-9, 18],
        [-23, 2],
        [-44, -2],
        [-36, 29],
        [-29, 59],
        [-13, 79],
        [9, 23],
        [105, 81],
        [28, 36],
        [-16, 4],
        [-39, -45],
        [-57, -27],
        [-35, 38],
        [-19, 41],
        [-11, 87],
        [4, 45],
        [-4, 59],
        [24, 19],
        [27, -10],
        [26, -4],
        [61, 5],
        [133, 35],
        [85, -21],
        [35, 2],
        [54, 31],
        [46, 3],
        [35, -23],
        [19, -27],
        [3, -36],
        [16, -23],
        [11, 7],
        [-9, 29],
        [-2, 43],
        [140, 50],
        [17, 19],
        [-56, 7],
        [-16, 45],
        [30, 68],
        [-3, 9],
        [-31, -36],
        [-15, -51],
        [6, -40],
        [-6, -19],
        [-29, -9],
        [-64, -3],
        [-41, 17],
        [-38, 10],
        [-13, 13],
        [4, 29],
        [-7, 6],
        [-16, -26],
        [-14, -52],
        [-30, -12],
        [-84, 19],
        [-121, -11],
        [-54, -26],
        [-35, 3],
        [-61, 46],
        [-24, 36],
        [-8, 73],
        [3, 33],
        [47, 13],
        [24, -1],
        [22, 17],
        [-19, 12],
        [-28, 22],
        [-19, 44],
        [-29, 14],
        [-19, 38],
        [-5, 57],
        [6, 40],
        [15, 13],
        [37, -9],
        [97, 6],
        [91, -40],
        [62, -23],
        [125, 12],
        [73, 36],
        [-13, 10],
        [-80, -21],
        [-73, 1],
        [-130, 40],
        [-53, 14],
        [-57, -6],
        [-30, 12],
        [-18, 40],
        [13, 77],
        [27, 17],
        [15, -20],
        [18, -2],
        [18, 32],
        [17, 19],
        [14, 41],
        [51, 39],
        [22, 3],
        [31, 18],
        [20, -5],
        [12, -18],
        [16, -15],
        [35, 2],
        [103, 31],
        [11, 10],
        [20, 25],
        [-65, -11],
        [-54, -18],
        [-35, -5],
        [-5, 22],
        [13, 21],
        [20, 21],
        [10, 37],
        [22, 16],
        [24, -1],
        [50, 7],
        [35, 9],
        [60, -6],
        [90, -14],
        [58, -34],
        [21, 3],
        [23, 9],
        [11, 12],
        [-46, 14],
        [-2, 21],
        [5, 15],
        [74, 28],
        [81, 6],
        [-14, 23],
        [-177, -34],
        [-46, 23],
        [-36, 0],
        [-24, -13],
        [-68, -16],
        [-13, 11],
        [13, 40],
        [41, 65],
        [3, 16],
        [19, 16],
        [106, 37],
        [51, 44],
        [23, 6],
        [22, -3],
        [35, 5],
        [67, -13],
        [30, -55],
        [28, -17],
        [87, -69],
        [-4, 20],
        [-75, 93],
        [-29, 23],
        [-21, 46],
        [7, 43],
        [24, 29],
        [86, 15],
        [15, 17],
        [2, 29],
        [-13, 19],
        [-32, -1],
        [-26, 12],
        [-7, 31],
        [10, 21],
        [50, 38],
        [27, 13],
        [47, 13],
        [81, -30],
        [6, -16],
        [-22, -38],
        [2, -21],
        [20, -3],
        [46, 64],
        [55, 9],
        [23, 14],
        [26, 9],
        [38, -58],
        [16, -19],
        [12, -7],
        [12, -48],
        [12, -3],
        [17, 24],
        [30, 13],
        [42, 8],
        [69, -12],
        [32, 9],
        [15, -1],
        [-14, 43],
        [-10, 12],
        [15, 39],
        [15, 15],
        [48, 27],
        [46, 12],
        [30, 26],
        [40, 24],
        [-6, 18],
        [-12, 22],
        [-26, 2],
        [-10, 12],
        [33, 28],
        [45, 32],
        [-8, 12],
        [-34, 14],
        [-26, -10],
        [-38, -25],
        [-45, -39],
        [15, -11],
        [22, -32],
        [-31, -43],
        [-166, -115],
        [-78, -34],
        [-37, 5],
        [-9, 32],
        [-16, 23],
        [-18, 48],
        [-31, -1],
        [-17, -11],
        [-8, 16],
        [13, 51],
        [26, 40],
        [44, 31],
        [20, 36],
        [20, 59],
        [63, 55],
        [91, 138],
        [75, 44],
        [27, 48],
        [44, 21],
        [38, 37],
        [29, 4],
        [54, 34],
        [30, 40],
        [-20, 2],
        [-47, -26],
        [-27, -10],
        [2, 43],
        [13, 44],
        [39, 40],
        [186, 117],
        [19, -19],
        [22, -35],
        [56, 8],
        [64, 66],
        [49, 72],
        [-26, -12],
        [-29, -30],
        [-57, -41],
        [-26, -6],
        [-15, 5],
        [-8, 28],
        [-20, 8],
        [-18, -6],
        [-18, 20],
        [-3, 48],
        [23, 71],
        [19, 47],
        [20, 36],
        [78, 101],
        [17, 56],
        [36, 29],
        [46, -7],
        [14, 9],
        [-16, 36],
        [-50, 29],
        [-3, 18],
        [167, 47],
        [80, -1],
        [24, 23],
        [43, 15],
        [33, 29],
        [-17, 13],
        [-81, -26],
        [-51, -13],
        [-23, 0],
        [-18, -9],
        [-64, -3],
        [-14, 115],
        [10, 63],
        [24, -2],
        [5, 60],
        [28, 35],
        [39, 8],
        [19, 15],
        [28, 31],
        [46, -7],
        [48, 7],
        [-12, 14],
        [-59, 19],
        [-14, 32],
        [20, 17],
        [23, 13],
        [20, 3],
        [40, 62],
        [24, 27],
        [26, -5],
        [38, 28],
        [37, -10],
        [35, 18],
        [49, 12],
        [178, 5],
        [5, 25],
        [-38, 6],
        [-132, 7],
        [-67, -1],
        [-29, -7],
        [-10, 9],
        [2, 16],
        [24, 24],
        [12, 26],
        [49, 65],
        [58, 44],
        [44, -11],
        [46, -42],
        [34, -5],
        [15, -13],
        [25, -57],
        [11, -2],
        [-5, 55],
        [32, 44],
        [-8, 13],
        [-49, -16],
        [-37, 16],
        [-29, 34],
        [-8, 31],
        [18, 30],
        [17, 15],
        [-11, 18],
        [-74, -48],
        [-51, -11],
        [-21, 7],
        [11, 43],
        [-6, 34],
        [70, 85],
        [23, 9],
        [39, -6],
        [35, -24],
        [30, 4],
        [32, 12],
        [-5, 23],
        [-69, 9],
        [-18, 19],
        [7, 19],
        [47, 19],
        [48, 36],
        [54, 10],
        [43, 27],
        [8, -6],
        [8, -11],
        [15, -99],
        [39, -81],
        [15, -3],
        [-15, 69],
        [14, 20],
        [17, 15],
        [6, 17],
        [-20, 6],
        [-17, 24],
        [-24, 79],
        [9, 21],
        [51, 41],
        [64, 10],
        [68, -29],
        [24, -1],
        [40, 8],
        [67, 24],
        [40, 10],
        [20, 0],
        [5, 12],
        [-19, 8],
        [-6, 7],
        [-15, 5],
        [-62, -13],
        [-172, 4],
        [-16, 14],
        [-3, 24],
        [18, 34],
        [20, 20],
        [65, 34],
        [68, 5],
        [72, 61],
        [28, 45],
        [15, 71],
        [44, 57],
        [111, 32],
        [4, 14],
        [-12, 30],
        [1, 53],
        [31, 63],
        [20, 21],
        [9, 3],
        [24, -21],
        [29, -43],
        [45, -24],
        [59, -5],
        [16, 12],
        [-46, 25],
        [-35, 32],
        [-3, 33],
        [17, 17],
        [25, -2],
        [33, 4],
        [30, 21],
        [4, 16],
        [1, 20],
        [8, 22],
        [44, 50],
        [136, 33],
        [10, -14],
        [-7, -97],
        [-16, -63],
        [0, -46],
        [27, 46],
        [35, 126],
        [27, 59],
        [30, 34],
        [22, 8],
        [21, 17],
        [28, 11],
        [9, -14],
        [9, -31],
        [-16, -110],
        [2, -35],
        [-17, -46],
        [-65, -104],
        [3, -13],
        [15, 5],
        [25, 17],
        [80, 98],
        [70, -12],
        [1, 8],
        [-22, 29],
        [-28, 28],
        [-8, 34],
        [4, 91],
        [21, 37],
        [60, -4],
        [37, 5],
        [17, -17],
        [36, 1],
        [25, 66],
        [50, 6],
        [44, -44],
        [52, -29],
        [43, -42],
        [12, 12],
        [-23, 98],
        [-25, 35],
        [-54, 19],
        [-58, 44],
        [-15, 19],
        [3, 15],
        [50, 14],
        [68, -16],
        [59, 36],
        [17, -10],
        [46, 19],
        [28, -25],
        [17, 7],
        [11, 35],
        [73, 21],
        [46, -20],
        [25, -20],
        [12, -39],
        [17, -78],
        [36, -42],
        [23, -21],
        [27, -5],
        [12, 21],
        [-24, 25],
        [-7, 24],
        [12, 59],
        [14, 23],
        [78, 87],
        [66, 46],
        [39, 4],
        [68, 102],
        [20, 18],
        [18, 5],
        [-5, 24],
        [-37, 16],
        [-2, 31],
        [50, 38],
        [60, 63],
        [30, 4],
        [18, -17],
        [59, -29],
        [35, -33],
        [26, -16],
        [17, 3],
        [14, 25],
        [17, 11],
        [37, -6],
        [22, -16],
        [18, -2],
        [15, -9],
        [4, -21],
        [-32, -23],
        [-55, -61],
        [-54, -69],
        [-18, -36],
        [-17, -95],
        [-43, -61],
        [-3, -43],
        [17, -20],
        [47, 16],
        [57, 57],
        [15, 60],
        [143, 164],
        [68, 91],
        [76, 75],
        [43, 15],
        [21, -49],
        [-17, -65],
        [-32, -41],
        [24, -19],
        [-5, -50],
        [-7, -27],
        [-5, -28],
        [0, -26],
        [23, 7],
        [89, 52],
        [23, 56],
        [21, 42],
        [10, 36],
        [35, 34],
        [66, 0],
        [3, 14],
        [-80, 47],
        [-9, 22],
        [27, 28],
        [73, 55],
        [38, -6],
        [23, -13],
        [91, -9],
        [69, -39],
        [-2, -61],
        [-16, -26],
        [-15, -15],
        [-89, -47],
        [-15, -22],
        [28, -8],
        [60, 23],
        [16, -21],
        [-19, -53],
        [-4, -78],
        [-7, -46],
        [0, -42],
        [8, -23],
        [24, 90],
        [8, 22],
        [36, 34],
        [13, 68],
        [34, 81],
        [39, 47],
        [23, 13],
        [75, -2],
        [33, -18],
        [28, -39],
        [21, -16],
        [67, -17],
        [24, -21],
        [4, -12],
        [16, -3],
        [45, 30],
        [30, 5],
        [48, -46],
        [-10, -35],
        [3, -11],
        [58, 2],
        [48, -13],
        [92, -71],
        [10, -33],
        [-5, -40],
        [-132, -44],
        [-57, -42],
        [-94, -16],
        [-318, 28],
        [7, -31],
        [221, -66],
        [13, -19],
        [-7, -40],
        [-1, -33],
        [5, -22],
        [16, -20],
        [27, -10],
        [55, 5],
        [27, -11],
        [19, 17],
        [7, 55],
        [16, 12],
        [31, -16],
        [13, -59],
        [9, -6],
        [15, 42],
        [31, -3],
        [33, 3],
        [43, -7],
      ],
      [
        [58045, 91602],
        [-20, 22],
        [-17, 34],
        [4, 33],
        [50, 64],
        [46, 45],
        [13, 16],
        [27, 46],
        [-54, 114],
        [-94, 34],
        [-108, 53],
        [-40, 28],
        [-62, 58],
        [-43, 52],
        [-40, 1],
        [-43, -13],
        [-68, -47],
        [-39, -24],
        [-22, -7],
        [-5, -1],
        [-49, 14],
        [-54, 3],
        [-43, -4],
        [-16, -7],
        [-61, -76],
        [-42, -39],
        [-23, -14],
        [-17, -22],
        [-14, -37],
        [-31, -128],
        [-23, -48],
        [-5, -30],
        [5, -89],
        [-5, -50],
        [-28, -40],
        [-20, -19],
        [-27, -4],
        [-34, -10],
        [-30, -24],
        [-21, -32],
        [-24, -73],
        [-40, -26],
        [-39, 7],
        [-28, 27],
        [-59, 21],
        [-44, 13],
        [-49, 28],
        [-44, 22],
        [-39, 4],
        [-23, -27],
        [-18, -26],
        [-68, -21],
        [-39, -16],
        [-50, -4],
        [-20, 19],
        [-72, 12],
        [-86, 14],
        [-25, 0],
        [-8, 32],
        [-23, 46],
        [-61, 79],
        [-25, 28],
        [-48, 65],
        [-55, 67],
        [-7, 2],
        [-37, 2],
        [-54, -2],
        [-34, -15],
        [-22, -20],
        [-4, -15],
        [21, -61],
        [-6, -16],
        [-11, -7],
        [-49, 17],
        [-59, -1],
        [-15, -19],
      ],
      [
        [51377, 87030],
        [-25, -8],
        [-19, 6],
        [7, 55],
        [10, 9],
        [15, 4],
        [16, -30],
        [-4, -36],
      ],
      [
        [51412, 86582],
        [1, -68],
        [-25, 5],
        [-12, 26],
        [-3, 17],
        [2, 39],
        [-6, 41],
        [7, 20],
        [10, 3],
        [16, -36],
        [10, -47],
      ],
      [
        [58321, 92048],
        [-53, -17],
        [-6, 14],
        [11, 22],
        [14, 44],
        [22, -2],
        [22, -17],
        [17, -20],
        [-27, -24],
      ],
      [
        [53324, 89646],
        [-18, -18],
        [-35, 5],
        [-3, 15],
        [9, 31],
        [21, 13],
        [27, -3],
        [9, -13],
        [-10, -30],
      ],
      [
        [52352, 88517],
        [-31, -1],
        [-20, 13],
        [46, 25],
        [72, 25],
        [6, 15],
        [9, 2],
        [12, -19],
        [2, -26],
        [-8, -13],
        [-88, -21],
      ],
      [
        [52250, 88327],
        [-27, 0],
        [-32, 9],
        [-21, 19],
        [-3, 16],
        [38, 21],
        [37, 12],
        [18, -23],
        [1, -37],
        [-11, -17],
      ],
      [
        [56511, 92635],
        [-6, -18],
        [-9, -18],
        [-23, -18],
        [-66, -74],
        [-38, -11],
        [-13, -12],
        [-15, -7],
        [-48, 10],
        [-14, -15],
        [-14, -10],
        [-34, -4],
        [-21, 4],
        [-53, 27],
        [-31, 29],
        [-17, 26],
        [49, -1],
        [17, 6],
        [33, -5],
        [19, 26],
        [42, -3],
        [80, 18],
        [29, -10],
        [67, 60],
        [21, -2],
        [32, 17],
        [13, -15],
      ],
      [
        [57107, 92823],
        [74, -22],
        [25, 0],
        [37, -41],
        [19, 4],
        [-3, -25],
        [-37, -12],
        [-58, -7],
        [-9, -5],
        [-49, 4],
        [-28, 33],
        [-47, 9],
        [0, 11],
        [30, 25],
        [46, 26],
      ],
      [
        [56559, 92482],
        [5, -27],
        [2, -23],
        [-26, -32],
        [-59, -42],
        [3, -11],
        [-20, -11],
        [-31, -8],
        [-17, 8],
        [2, 36],
        [-5, 11],
        [-23, -15],
        [-24, 19],
        [-1, 18],
        [7, 16],
        [23, 25],
        [37, 16],
        [25, -6],
        [83, 65],
        [9, -14],
        [10, -25],
      ],
      [
        [56671, 92492],
        [-53, -23],
        [-31, 20],
        [-13, 20],
        [-1, 45],
        [7, 27],
        [25, 15],
        [16, -11],
        [4, -8],
        [29, -9],
        [34, -28],
        [-17, -48],
      ],
      [
        [53853, 91166],
        [17, -10],
        [43, 3],
        [8, -4],
        [-6, -16],
        [-19, -18],
        [-39, -11],
        [-18, -27],
        [-12, -9],
        [-34, -1],
        [-20, -6],
        [-25, -24],
        [-20, 18],
        [-5, -13],
        [-4, -23],
        [-11, -6],
        [-34, -9],
        [-8, 53],
        [16, 20],
        [12, 22],
        [19, 4],
        [17, -2],
        [30, 50],
        [42, 14],
        [26, 1],
        [25, -6],
      ],
      [
        [53603, 90941],
        [-41, -31],
        [15, 56],
        [22, 56],
        [30, 32],
        [16, -12],
        [-7, -27],
        [0, -27],
        [-7, -13],
        [-28, -34],
      ],
      [
        [54224, 91556],
        [36, -58],
        [16, -34],
        [-13, -64],
        [-35, -32],
        [-54, -6],
        [-38, 3],
        [-24, 16],
        [-3, 17],
        [-14, 5],
        [-37, -22],
        [-25, -3],
        [-32, 17],
        [-9, 28],
        [34, 35],
        [16, 27],
        [38, -3],
        [10, -8],
        [21, -5],
        [13, 32],
        [-3, 23],
        [10, 15],
        [46, -11],
        [0, 61],
        [17, 5],
        [8, -3],
        [13, -13],
        [9, -22],
      ],
      [
        [55490, 92290],
        [15, -7],
        [14, 4],
        [11, -6],
        [23, -30],
        [25, -12],
        [2, -15],
        [-23, -15],
        [-31, -4],
        [-32, 5],
        [-9, 19],
        [-10, 31],
        [-27, 31],
        [-4, 27],
        [23, 5],
        [23, -33],
      ],
      [
        [55771, 92217],
        [-15, -14],
        [-23, -5],
        [-12, 8],
        [-17, 6],
        [-20, -3],
        [-16, 25],
        [1, 20],
        [23, 28],
        [45, 16],
        [36, -6],
        [10, -9],
        [-12, -66],
      ],
      [
        [55348, 92203],
        [25, -31],
        [22, 3],
        [6, 12],
        [15, 6],
        [30, -17],
        [-4, -28],
        [-42, -36],
        [-30, -50],
        [-38, -12],
        [-18, 6],
        [-35, -29],
        [-27, -31],
        [-28, -38],
        [-2, -20],
        [-5, -15],
        [-104, -15],
        [-37, -10],
        [-40, 12],
        [-19, 26],
        [6, 14],
        [40, 5],
        [1, 24],
        [10, 14],
        [13, 8],
        [9, 30],
        [16, 8],
        [30, -7],
        [20, 22],
        [11, 3],
        [14, -18],
        [6, 25],
        [-6, 23],
        [3, 15],
        [38, 40],
        [17, 29],
        [24, 19],
        [22, -3],
        [7, 28],
        [-7, 28],
        [3, 18],
        [20, 45],
        [22, 2],
        [11, -40],
        [1, -65],
      ],
      [
        [53474, 89805],
        [-22, -2],
        [0, 23],
        [13, 22],
        [20, 14],
        [26, 4],
        [29, 2],
        [9, -12],
        [-17, -16],
        [-58, -35],
      ],
      [
        [53449, 89886],
        [-25, -4],
        [4, 26],
        [21, 24],
        [8, 16],
        [4, 20],
        [18, 14],
        [26, -19],
        [1, -31],
        [-13, -30],
        [-44, -16],
      ],
      [
        [53119, 89208],
        [-14, -16],
        [-33, 13],
        [-63, -10],
        [-26, 15],
        [20, 31],
        [58, 32],
        [31, -2],
        [31, -39],
        [-4, -24],
      ],
      [
        [54861, 91933],
        [34, -33],
        [15, 10],
        [29, 3],
        [22, -11],
        [18, -21],
        [22, -1],
        [13, -27],
        [7, -36],
        [-16, -27],
        [-22, -12],
        [-6, -31],
        [9, -44],
        [-49, -15],
        [-58, -7],
        [-22, 21],
        [-45, -38],
        [-46, -60],
        [-21, -7],
        [-2, 19],
        [-32, 13],
        [-42, 1],
        [1, 14],
        [8, 10],
        [36, 15],
        [7, 30],
        [-7, 54],
        [7, 26],
        [1, 19],
        [23, 21],
        [77, -10],
        [9, 20],
        [-6, 13],
        [-39, 22],
        [6, 15],
        [28, 14],
        [28, 2],
        [8, 22],
        [1, 10],
        [4, 6],
      ],
      [
        [54377, 91336],
        [4, -4],
        [38, 56],
        [41, 17],
        [3, 19],
        [16, 19],
        [-2, 31],
        [9, 24],
        [21, 7],
        [13, 8],
        [15, 5],
        [27, -20],
        [15, -22],
        [19, -50],
        [-8, -48],
        [-50, -38],
        [-40, -17],
        [-41, -43],
        [-20, -35],
        [-17, -8],
        [-11, 3],
        [-10, 9],
        [-21, 0],
        [-22, -31],
        [-68, -25],
        [-27, 7],
        [-1, 31],
        [-16, -3],
        [-26, -36],
        [-25, -12],
        [-16, -4],
        [-31, 14],
        [-83, -63],
        [-78, -11],
        [-25, 7],
        [0, 38],
        [50, 49],
        [41, 34],
        [142, 23],
        [88, 101],
        [21, 109],
        [21, 39],
        [-10, 23],
        [-24, 4],
        [-1, 34],
        [12, 37],
        [47, 51],
        [25, 22],
        [42, 62],
        [20, 14],
        [23, 0],
        [23, -16],
        [-4, -33],
        [-34, -60],
        [-50, -51],
        [6, -37],
        [20, -30],
        [5, -51],
        [1, -50],
        [-38, -66],
        [-9, -33],
      ],
      [
        [47512, 92649],
        [-25, -4],
        [-15, 13],
        [37, 35],
        [124, 66],
        [49, 63],
        [95, 22],
        [6, -35],
        [-6, -44],
        [-84, -35],
        [-92, -23],
        [-89, -58],
      ],
      [
        [55338, 94695],
        [-33, -23],
        [-51, 34],
        [-33, 43],
        [18, 16],
        [89, 3],
        [22, -23],
        [4, -13],
        [-16, -37],
      ],
      [
        [57465, 97147],
        [-41, -1],
        [-75, 42],
        [-14, 37],
        [13, 15],
        [36, 1],
        [57, -51],
        [61, -15],
        [-37, -28],
      ],
      [
        [59034, 97994],
        [-263, -22],
        [-27, 16],
        [427, 63],
        [22, 6],
        [80, 8],
        [68, -14],
        [-20, -11],
        [-287, -46],
      ],
      [
        [56002, 97117],
        [38, -14],
        [83, 3],
        [45, -98],
        [26, -103],
        [41, -8],
        [80, 15],
        [70, 7],
        [36, -8],
        [65, -30],
        [28, -21],
        [-24, -17],
        [-59, -19],
        [-10, -55],
        [59, -20],
        [98, -47],
        [56, -6],
        [98, 19],
        [93, -37],
        [92, -45],
        [-215, -56],
        [-19, -16],
        [-29, -42],
        [-32, -35],
        [-29, -20],
        [-64, -35],
        [-35, -13],
        [-78, 3],
        [-29, -14],
        [-27, -28],
        [-27, -21],
        [-69, -5],
        [-35, 28],
        [12, 9],
        [5, 17],
        [-13, 40],
        [66, 40],
        [15, 23],
        [-13, 8],
        [-18, -2],
        [-48, 12],
        [-14, 0],
        [-40, -24],
        [-55, -16],
        [-55, -4],
        [-224, -31],
        [-34, 11],
        [-15, 61],
        [91, 31],
        [14, 53],
        [23, 35],
        [26, 23],
        [50, 60],
        [12, 4],
        [-123, 48],
        [-48, 31],
        [-53, 61],
        [-17, 50],
        [-71, 42],
        [9, 54],
        [-52, -4],
        [-41, 38],
        [38, 21],
        [190, 24],
        [113, 24],
        [43, -1],
      ],
      [
        [55804, 98069],
        [28, -6],
        [153, 2],
        [30, -14],
        [11, -34],
        [24, -12],
        [32, -3],
        [81, -42],
        [28, -6],
        [24, 23],
        [19, 58],
        [0, 68],
        [-7, 33],
        [9, 21],
        [27, 8],
        [34, -2],
        [34, 12],
        [29, 21],
        [31, 3],
        [67, -16],
        [18, -13],
        [-18, -25],
        [-7, -37],
        [-31, -75],
        [66, -4],
        [93, 15],
        [24, 22],
        [50, 35],
        [53, -5],
        [25, 4],
        [13, 15],
        [5, 18],
        [29, -3],
        [40, -34],
        [19, -6],
        [34, 9],
        [13, 0],
        [34, -14],
        [157, -25],
        [54, -14],
        [24, -12],
        [23, -8],
        [167, 1],
        [118, -9],
        [43, -20],
        [37, -38],
        [14, -88],
        [-33, -24],
        [-239, -108],
        [-60, -35],
        [-29, -32],
        [-48, -70],
        [-24, -21],
        [-112, -34],
        [-26, -3],
        [-84, 16],
        [-25, -2],
        [-102, -36],
        [-36, -22],
        [-34, -27],
        [-51, -13],
        [-53, 7],
        [-237, 14],
        [-32, 20],
        [-26, 37],
        [47, 48],
        [-265, -18],
        [-292, 10],
        [-15, 6],
        [-13, 19],
        [-100, 12],
        [-75, 15],
        [-64, 26],
        [-62, 33],
        [20, 15],
        [20, 9],
        [54, 4],
        [48, -4],
        [85, 0],
        [20, 33],
        [34, 10],
        [27, 24],
        [-90, 15],
        [-94, 2],
        [-62, -20],
        [-73, -9],
        [-66, -1],
        [-127, 6],
        [-60, 14],
        [-83, 36],
        [-28, 21],
        [-11, 16],
        [-9, 24],
        [95, 21],
        [36, 17],
        [36, 24],
        [-142, 13],
        [-60, 19],
        [-59, 29],
        [48, 16],
        [192, 13],
        [51, -11],
        [50, -21],
        [56, -12],
        [53, 27],
        [-50, 12],
        [-46, 45],
        [-9, 22],
        [6, 17],
        [23, 3],
        [18, -7],
        [67, -42],
        [51, -14],
        [14, 39],
        [2, 19],
        [-9, 15],
        [-24, 28],
        [-21, 35],
        [33, 9],
        [33, -4],
        [70, -24],
        [71, -17],
        [32, -16],
        [61, -42],
        [56, -29],
      ],
      [
        [54662, 97872],
        [15, -1],
        [14, 6],
        [10, 16],
        [11, 9],
        [71, -11],
        [99, -32],
        [30, -16],
        [41, -33],
        [34, -55],
        [-27, -40],
        [-35, -37],
        [-12, -21],
        [13, -30],
        [-6, -29],
        [-13, -26],
        [54, 29],
        [114, 94],
        [17, 6],
        [18, -3],
        [51, -19],
        [46, -48],
        [11, -16],
        [8, -20],
        [5, -24],
        [-3, -28],
        [-4, -19],
        [-25, -12],
        [-11, -12],
        [26, -1],
        [30, -15],
        [27, -31],
        [31, -13],
        [111, 11],
        [73, -17],
        [39, -52],
        [62, 12],
        [0, 27],
        [13, 12],
        [82, -9],
        [43, -13],
        [43, -27],
        [-74, -45],
        [61, -43],
        [103, -31],
        [61, -33],
        [12, -14],
        [10, -18],
        [-40, -24],
        [-41, -13],
        [-104, -2],
        [-93, -17],
        [-172, -12],
        [-26, -7],
        [-6, -7],
        [-10, -21],
        [-66, -47],
        [-64, -58],
        [-26, -35],
        [-20, -49],
        [-8, -29],
        [15, -29],
        [-4, -30],
        [-48, -23],
        [-31, 0],
        [-38, 4],
        [-38, -13],
        [-2, -20],
        [2, -28],
        [-9, -85],
        [-12, -65],
        [-18, -59],
        [-19, -33],
        [-25, -9],
        [-81, -5],
        [-62, -57],
        [-50, -100],
        [-26, -39],
        [-55, -62],
        [10, -22],
        [17, -24],
        [-30, -43],
        [-46, -48],
        [1, -19],
        [16, -34],
        [7, -35],
        [-35, -30],
        [-66, -16],
        [-66, 17],
        [-32, 21],
        [-30, 32],
        [-32, 22],
        [-33, 13],
        [-127, 72],
        [-117, 114],
        [-108, 45],
        [-69, 21],
        [-34, 20],
        [-33, 28],
        [-28, 31],
        [-27, 39],
        [-12, 24],
        [-3, 36],
        [9, 21],
        [12, 11],
        [85, 9],
        [31, -5],
        [30, -19],
        [27, -7],
        [63, 94],
        [357, 54],
        [115, 9],
        [115, 0],
        [-18, 25],
        [-15, 33],
        [-17, 7],
        [-87, -18],
        [-133, -19],
        [-65, 0],
        [-67, 13],
        [-67, -7],
        [-69, -28],
        [-69, -17],
        [-68, -7],
        [-143, 3],
        [-35, 14],
        [-48, 33],
        [-11, 17],
        [-10, 21],
        [-9, 63],
        [10, 17],
        [14, 9],
        [15, 6],
        [32, 1],
        [31, -11],
        [72, -36],
        [-17, 39],
        [208, 46],
        [96, 40],
        [49, 6],
        [50, -2],
        [-11, 21],
        [0, 20],
        [35, 16],
        [25, 7],
        [77, 8],
        [174, -2],
        [62, 11],
        [47, 28],
        [-50, -10],
        [-50, -2],
        [-23, 6],
        [-53, 24],
        [-24, 31],
        [68, 63],
        [24, 30],
        [-70, -5],
        [-23, -10],
        [-80, -58],
        [-60, -26],
        [-73, -12],
        [-73, 1],
        [-16, 8],
        [-22, 39],
        [-7, 20],
        [3, 11],
        [23, 31],
        [12, 34],
        [-2, 29],
        [-17, 5],
        [-27, -28],
        [-25, -39],
        [-33, -20],
        [-35, 6],
        [-15, 15],
        [-13, 23],
        [-13, 9],
        [-15, 0],
        [-31, -9],
        [-31, -18],
        [11, -25],
        [3, -29],
        [-14, -23],
        [-10, -28],
        [32, -17],
        [26, -28],
        [-39, -13],
        [-38, -18],
        [-34, -29],
        [-36, -23],
        [-56, -2],
        [-70, -12],
        [-141, -5],
        [-66, 37],
        [-12, 17],
        [-13, 12],
        [-44, 19],
        [-63, 57],
        [-50, 64],
        [-33, 6],
        [-49, 21],
        [-27, 19],
        [-25, 24],
        [-8, 29],
        [3, 26],
        [29, 12],
        [-69, 29],
        [-68, 39],
        [25, 13],
        [25, 6],
        [202, -46],
        [14, 6],
        [22, 23],
        [-8, 7],
        [-34, 5],
        [-46, 0],
        [-11, 5],
        [-18, 24],
        [-16, 30],
        [-6, 20],
        [-3, 23],
        [34, 35],
        [19, 32],
        [-29, 14],
        [-83, -1],
        [-28, -4],
        [10, -45],
        [-26, -31],
        [-51, -25],
        [-36, 12],
        [-28, 60],
        [-37, 41],
        [-14, 26],
        [-10, 38],
        [-15, 27],
        [-27, 33],
        [-3, 20],
        [3, 16],
        [20, 34],
        [-15, 28],
        [-19, 25],
        [-1, 14],
        [18, 18],
        [16, 6],
        [17, -2],
        [51, -21],
        [28, -25],
        [9, 2],
        [19, 38],
        [25, 8],
        [100, 12],
        [111, -48],
        [29, -10],
        [23, -4],
        [-12, 21],
        [-7, 28],
        [17, 10],
        [89, -24],
        [42, 1],
        [98, 33],
        [163, 16],
        [62, -25],
        [3, -14],
        [-2, -18],
        [-3, -5],
        [-36, -22],
        [-206, -17],
        [-134, -66],
        [183, 10],
        [33, -7],
        [14, -55],
        [13, -5],
        [48, -8],
        [32, -16],
        [32, -31],
        [34, -21],
        [21, 3],
        [7, 22],
        [-8, 27],
        [-5, 30],
        [3, 33],
        [5, 27],
        [39, 19],
        [56, 62],
        [59, 42],
        [66, -19],
        [62, -53],
        [55, -74],
        [53, -80],
        [60, -99],
        [29, -35],
        [27, -8],
        [121, -103],
        [13, -3],
        [-25, 78],
        [-62, 133],
        [-43, 102],
        [-9, 39],
        [-7, 54],
        [3, 16],
        [5, 14],
        [31, 59],
        [40, 28],
        [-12, 40],
        [10, 31],
        [42, 24],
        [39, 2],
        [38, -19],
        [73, -65],
      ],
      [
        [53125, 97125],
        [3, -40],
        [45, 4],
        [53, -42],
        [58, -22],
        [17, -16],
        [12, -20],
        [35, -40],
        [17, -42],
        [-42, -4],
        [-58, 60],
        [-47, 34],
        [-60, 29],
        [-48, 2],
        [-21, 12],
        [-78, 105],
        [-15, 24],
        [-44, 39],
        [-20, 48],
        [0, 38],
        [60, -9],
        [52, -24],
        [45, -54],
        [9, -16],
        [-21, -22],
        [21, -27],
        [27, -17],
      ],
      [
        [55205, 98099],
        [-60, -32],
        [-100, 25],
        [12, 25],
        [23, 15],
        [64, -6],
        [61, -27],
      ],
      [
        [58068, 97299],
        [83, -4],
        [83, 9],
        [14, -9],
        [-107, -31],
        [-119, 16],
        [-107, 4],
        [-127, -33],
        [-42, 13],
        [65, 33],
        [70, 10],
        [11, 20],
        [27, 4],
        [93, 2],
        [56, -34],
      ],
      [
        [85175, 73606],
        [-3, 5],
        [-14, 4],
        [-56, 47],
        [-46, -29],
        [-12, -37],
        [-12, -12],
        [-19, 73],
        [-30, 2],
        [-48, 65],
        [-21, -13],
        [-5, -26],
        [-26, -60],
        [-37, -48],
        [-12, -7],
        [-13, 3],
        [2, 14],
        [-15, 55],
        [-58, 22],
        [-21, 23],
        [-11, 6],
        [57, 61],
        [15, 11],
        [-11, 14],
        [-12, 7],
        [-47, -9],
        [-24, 20],
        [-36, -7],
        [-24, 16],
        [51, 60],
        [2, 36],
        [-1, 27],
        [26, 80],
        [26, 44],
        [67, 62],
        [30, 9],
        [21, -3],
        [17, 6],
        [-18, 24],
        [-18, 11],
        [-35, -2],
        [-36, 36],
        [-3, 38],
        [70, 240],
        [1, 22],
        [-11, 58],
        [-3, 57],
        [-51, 33],
        [-22, 4],
        [-64, 64],
        [-26, 33],
        [-10, -10],
        [-2, -51],
        [-9, -12],
        [-17, -10],
        [-9, 59],
        [-14, 42],
        [-42, 44],
        [-16, 23],
        [8, 52],
        [-4, 4],
      ],
      [
        [84544, 74886],
        [7, 58],
        [26, 45],
        [64, 79],
        [17, 37],
        [33, 44],
        [14, -1],
        [16, 4],
        [4, 19],
        [4, 15],
        [13, 13],
        [31, 25],
        [36, 31],
        [28, 9],
        [35, 48],
        [14, 21],
        [15, 0],
        [3, 10],
        [9, 24],
        [11, 16],
        [15, 3],
        [25, 12],
        [32, 7],
        [22, 40],
        [7, 29],
        [14, 31],
        [31, 34],
        [20, 51],
        [24, 55],
        [11, 18],
        [10, 3],
        [7, 21],
        [7, 59],
        [11, 56],
        [6, 27],
        [27, 30],
        [7, 14],
        [6, 5],
        [12, -4],
        [16, 17],
        [16, 19],
        [14, -7],
        [15, -15],
        [15, -32],
        [7, -25],
        [12, -21],
        [2, -30],
        [12, -14],
        [25, -6],
        [42, -21],
        [27, -1],
        [15, -16],
        [32, -8],
        [64, 12],
        [26, -7],
        [11, -19],
        [17, -16],
        [10, -1],
        [14, 27],
        [16, 42],
        [10, 32],
        [-1, 26],
        [-9, 28],
        [-21, 25],
        [-14, 40],
        [-13, 41],
        [-8, 14],
        [-6, 20],
        [-1, 30],
        [4, 21],
        [32, 14],
        [41, 8],
        [33, -9],
        [56, 6],
        [34, 12],
        [25, -2],
        [23, 0],
        [11, 18],
        [32, 42],
        [16, 15],
        [17, 29],
        [3, 30],
        [3, 24],
        [10, 26],
        [17, 32],
        [14, 15],
        [16, -2],
        [17, -15],
        [11, -14],
        [12, 4],
        [10, 25],
        [7, 5],
        [19, 2],
        [6, 16],
        [8, 74],
        [7, 59],
        [2, 40],
        [17, 68],
        [6, 41],
        [10, 19],
        [12, -1],
        [10, -12],
        [12, -7],
        [17, 6],
        [12, -10],
        [7, -22],
        [25, -15],
        [2, -11],
        [0, -74],
        [13, -34],
        [18, -31],
        [25, -29],
        [14, -6],
        [8, -20],
      ],
      [
        [86301, 76210],
        [-14, -16],
        [-19, 10],
        [-31, 6],
        [-39, -51],
        [-22, -17],
        [-16, -50],
        [-31, -30],
        [-17, -31],
        [-22, -54],
        [-14, -53],
        [-33, -54],
        [-20, -67],
        [-1, -58],
        [21, -59],
        [2, -51],
        [-15, -104],
        [9, -110],
        [-10, -43],
        [-102, -76],
        [-26, -37],
        [-38, -98],
        [-46, -36],
        [-28, -40],
        [-39, -24],
        [-26, -69],
        [-27, -39],
        [-33, -24],
        [-25, -30],
        [-55, -2],
        [-39, -21],
        [-27, -58],
        [-83, -66],
        [-12, -49],
        [6, -77],
        [0, -58],
        [-7, -49],
        [-18, 14],
        [-10, -16],
        [-11, -44],
        [4, -51],
        [28, -17],
        [23, -20],
        [33, -11],
        [24, -24],
        [52, -107],
        [42, -47],
        [11, -17],
        [24, -24],
        [22, -37],
        [13, -33],
      ],
      [
        [84695, 74617],
        [-16, -17],
        [0, 30],
        [12, 25],
        [12, 3],
        [-8, -41],
      ],
      [
        [52027, 54388],
        [-26, -18],
        [-18, 4],
        [24, 77],
        [12, -17],
        [16, -7],
        [-8, -39],
      ],
      [
        [53779, 59737],
        [44, -124],
        [47, -133],
        [36, -104],
      ],
      [
        [53906, 59376],
        [27, -268],
        [3, -51],
        [2, -23],
        [2, -21],
        [3, -37],
        [21, -16],
        [40, -7],
        [29, -26],
        [17, -44],
        [1, -7],
        [9, -34],
        [3, -24],
        [-3, -71],
        [-6, -90],
        [-10, -58],
        [6, -79],
        [-2, -34],
        [-4, -23],
        [-18, -27],
        [-24, -26],
        [-57, -76],
        [-17, -12],
        [-24, -2],
        [-21, -19],
        [-25, -41],
        [-53, -154],
        [-46, -154],
        [-16, -128],
        [-17, -122],
        [-40, -78],
        [-6, -44],
        [-2, -25],
        [-1, -59],
        [-5, -97],
        [-6, -47],
        [-7, -14],
        [-43, -30],
        [-25, -35],
        [-15, -71],
        [-5, -77],
        [-9, -87],
        [-5, -76],
        [-7, -40],
        [-14, -42],
        [-22, -44],
        [-19, -26],
        [-50, -16],
        [-26, -101],
        [-21, -79],
        [-1, -32],
        [-21, -164],
        [-36, -124],
        [-3, -44],
        [0, -35],
        [-45, -109],
        [-12, -32],
        [-12, -42],
        [12, -41],
        [12, -37],
        [2, -12],
        [-20, -35],
        [-36, -60],
        [-22, -36],
        [-5, -20],
        [-3, -90],
        [-6, -25],
        [-14, -33],
        [-21, -37],
        [-22, -28],
        [-24, -20],
        [-23, -7],
        [-13, 12],
        [-8, 27],
        [-13, 111],
        [-7, 24],
        [-15, 21],
        [-30, 61],
        [-30, 61],
        [-36, 43],
        [-8, -3],
        [-6, -12],
        [-11, -61],
        [-10, -23],
        [-19, -8],
        [-33, 0],
        [-25, 8],
        [-5, 13],
        [-5, 26],
        [-7, 22],
        [-29, -43],
        [-46, -68],
        [-15, -12],
        [-11, -13],
        [-15, -64],
        [-18, -68],
        [-24, -35],
        [-23, -30],
        [-14, -26],
        [-19, -31],
        [-37, -77],
        [-50, -102],
        [-17, -53],
        [-18, -78],
        [-10, -87],
        [-11, -96],
        [-16, -153],
        [-24, -87],
        [-20, -69],
        [-16, -54],
        [-8, -45],
      ],
      [
        [52376, 54582],
        [-3, 1],
        [-8, -19],
        [-23, 13],
        [-11, 39],
        [-14, 6],
        [-25, 57],
        [-5, -9],
        [26, -145],
        [-10, -57],
        [-73, -1],
        [-64, -19],
        [-43, 1],
        [-22, 21],
        [-10, 54],
        [-3, -5],
        [-3, -30],
        [-13, -22],
        [-49, -5],
        [-22, 37],
        [-17, 42],
        [-19, 18],
        [3, -17],
        [22, -41],
        [-3, -58],
        [-39, -67],
        [-25, -4],
        [-16, 29],
        [-8, 47],
        [-4, 70],
        [-10, 46],
        [-6, 0],
        [5, -42],
        [2, -34],
        [0, -71],
        [19, -55],
        [-29, -17],
        [-11, -1],
        [-23, -1],
        [-4, 20],
        [-5, 46],
        [-6, 12],
        [-7, -78],
        [-15, -5],
        [-10, 1],
        [-46, -17],
        [-10, 3],
        [-2, 14],
        [6, 22],
        [-2, 35],
        [-15, -27],
        [-3, -54],
        [-9, -8],
        [-27, 7],
        [-29, 28],
        [-18, 28],
        [-30, 39],
        [-58, 111],
        [-10, 49],
        [-17, 61],
        [-12, 62],
        [-18, 105],
        [5, 8],
        [14, -9],
        [7, 15],
        [-25, 12],
        [-5, 12],
        [-1, 37],
        [1, 45],
        [19, 16],
        [18, 8],
        [8, 27],
        [5, 28],
        [-45, -42],
        [-43, 47],
        [-9, 29],
        [4, 22],
        [21, 3],
        [29, -1],
        [17, 21],
        [-10, 8],
        [-19, -1],
        [-7, 14],
        [0, 34],
        [-6, -7],
        [-8, -31],
        [-29, -22],
        [-16, 22],
        [-2, 50],
        [-4, 23],
        [-14, 17],
        [-50, 132],
        [-63, 110],
        [-57, 76],
        [-84, 36],
        [-178, -2],
        [-10, 11],
        [11, 17],
        [16, 12],
        [57, 61],
        [-10, 8],
        [-59, -38],
        [-21, -4],
        [-26, -74],
        [-156, -12],
        [-19, -3],
      ],
      [
        [50751, 55512],
        [1, 33],
        [7, 97],
        [5, 38],
        [6, 29],
        [-6, 34],
        [-6, 47],
        [-3, 74],
        [8, 22],
        [2, 28],
        [-1, 43],
        [0, 145],
        [4, 16],
        [5, 12],
        [0, 20],
        [-9, 37],
        [-9, 43],
        [0, 61],
        [-3, 60],
        [-6, 27],
        [4, 102],
        [3, 128],
        [-3, 57],
        [6, 40],
        [3, 100],
        [0, 97],
        [11, 153],
        [35, 7],
        [40, 13],
        [19, 60],
        [10, 77],
        [-3, 75],
        [8, 25],
        [16, 41],
        [29, 58],
        [-1, 64],
        [8, 20],
        [14, 15],
        [20, 8],
        [23, 32],
        [12, 56],
        [12, 89],
        [-19, 63],
        [0, 13],
        [8, 34],
        [11, 34],
        [10, 10],
        [22, -8],
        [3, 3],
        [4, 10],
        [14, 99],
        [-2, 27],
        [-20, 66],
        [-3, 47],
        [-3, 70],
        [-5, 62],
        [-6, 23],
        [-11, 20],
        [-5, 13],
        [-42, 126],
        [1, 60],
        [18, 76],
        [11, 37],
      ],
      [
        [50998, 58580],
        [16, 21],
        [3, 17],
        [-4, 22],
        [-8, 16],
        [-2, 34],
        [2, 23],
        [6, 25],
        [-2, 53],
        [0, 80],
        [3, 118],
        [0, 71],
        [35, 53],
        [49, 89],
        [25, 91],
        [14, 70],
        [17, 232],
        [12, 14],
        [14, 11],
        [50, 84],
        [38, 31],
        [29, 18],
        [44, 16],
        [28, -6],
        [49, -4],
        [40, 8],
        [33, 46],
        [15, 13],
        [21, 8],
        [96, -62],
        [96, -59],
        [18, 6],
        [14, -8],
        [24, -32],
        [36, -68],
        [21, -44],
        [10, -26],
        [50, -148],
        [18, -37],
        [18, -20],
        [19, -7],
        [15, 2],
        [13, 17],
        [19, 33],
        [28, 15],
        [23, -3],
        [120, 133],
        [12, 1],
        [34, -10],
        [39, -18],
        [100, -134],
        [82, -87],
        [58, -29],
        [67, -21],
        [116, -6],
        [87, 187],
        [32, 41],
        [39, 37],
        [12, 6],
        [68, 28],
        [135, 24],
        [125, -10],
        [25, -8],
        [54, -24],
        [82, -61],
        [36, -59],
        [55, -9],
        [40, 11],
        [14, 58],
        [40, 76],
        [29, 31],
        [31, 40],
        [49, 49],
        [40, 22],
        [37, 57],
        [28, 17],
        [50, 2],
      ],
      [
        [50998, 58580],
        [-17, 53],
        [-23, 37],
        [-25, 16],
        [-17, 27],
        [-9, 37],
        [-33, 73],
        [-75, 144],
        [-8, 3],
        [-12, 6],
        [-22, -17],
        [-13, -24],
        [-9, -9],
        [-14, -1],
        [-36, -19],
        [-28, -23],
        [-1, -19],
        [14, -109],
        [-7, -59],
      ],
      [
        [50663, 58696],
        [-13, 28],
        [-41, 110],
        [-29, 81],
        [-5, 18],
        [-4, 28],
        [3, 12],
        [11, 9],
        [27, 11],
        [5, 8],
        [1, 23],
        [-4, 41],
        [-15, 57],
        [-15, 37],
        [-8, 7],
        [-16, 2],
        [-17, -5],
        [-32, -46],
        [-14, -9],
        [-33, 4],
        [-30, 9],
        [-18, 24],
        [-53, 91],
        [-59, 96],
        [-25, 13],
        [-5, 10],
        [-4, 74],
        [1, 89],
        [3, 23],
        [25, -14],
        [26, -6],
        [8, 16],
        [-21, 32],
        [-30, 32],
        [-11, 48],
        [-9, 17],
        [-13, 17],
        [-16, 9],
        [-15, 14],
        [-11, 14],
        [-17, 6],
        [-19, 10],
        [-26, 79],
        [-26, 76],
        [-16, 60],
        [-5, 36],
        [8, 62],
        [-8, 24],
        [-29, 62],
        [-24, 59],
        [6, 89],
        [5, 75],
        [0, 47],
        [4, 27],
      ],
      [
        [50060, 60432],
        [3, 30],
        [16, 10],
        [41, -1],
        [79, -14],
        [64, 16],
        [3, 3],
        [45, 80],
        [50, 84],
        [74, 8],
        [81, 9],
        [64, 4],
        [92, 7],
        [75, 5],
        [86, 7],
        [3, 38],
        [5, 10],
        [9, 1],
        [63, -21],
        [60, -20],
        [4, 73],
        [53, 92],
        [30, 18],
        [7, 16],
        [9, 31],
        [6, 48],
        [3, 34],
        [11, 28],
        [8, 52],
        [11, 90],
        [29, 95],
        [17, 129],
        [3, 125],
        [3, 95],
        [9, 19],
        [0, 168],
        [-1, 170],
        [0, 143],
        [0, 178],
        [-1, 156],
        [0, 169],
        [0, 152],
        [0, 101],
      ],
      [
        [51174, 62870],
        [60, 24],
        [63, 25],
        [92, 36],
        [99, 39],
        [108, 43],
        [25, 26],
        [81, 146],
        [37, 66],
        [74, 130],
        [56, 101],
        [72, 128],
        [76, 129],
        [61, 103],
        [95, 117],
        [144, 176],
        [144, 176],
        [144, 175],
        [143, 176],
        [144, 176],
        [144, 176],
        [144, 176],
        [144, 176],
      ],
      [
        [53324, 65390],
        [145, -67],
        [137, -64],
        [138, -64],
        [33, -35],
        [73, -125],
        [94, -160],
        [4, -3],
        [4, 0],
        [91, 94],
        [117, 123],
      ],
      [
        [54160, 65089],
        [31, -333],
        [23, -285],
        [2, -183],
        [1, -47],
        [9, -32],
        [22, -33],
        [87, -263],
        [-19, -46],
        [13, -81],
        [23, -35],
        [72, -157],
        [10, -31],
        [-4, -25],
        [-51, -185],
        [-9, -45],
        [-10, -235],
        [-7, -167],
        [-10, -228],
        [-11, -273],
        [-10, -230],
        [-12, -304],
        [-12, -289],
        [-73, -158],
        [-129, -280],
        [-105, -229],
        [-53, -153],
        [-103, -298],
        [-46, -194],
        [-36, -100],
        [-18, -44],
        [16, -141],
        [28, -248],
      ],
      [
        [26900, 60479],
        [-7, -21],
        [-9, -14],
        [-18, -69],
        [-6, -6],
        [-1, 51],
        [-10, 7],
        [-13, -18],
        [-7, -26],
        [11, -35],
        [10, 0],
        [11, -9],
        [31, -236],
        [-7, -42],
        [-19, -65],
        [-18, -56],
        [-18, -35],
        [-23, -148],
        [-20, -241],
        [14, -217],
        [-7, -200],
        [6, -48],
        [2, -59],
        [-15, -10],
        [-8, 1],
        [-9, 37],
        [1, 31],
        [9, 38],
        [4, 50],
        [-5, 27],
        [-9, -58],
        [-15, -26],
        [-10, -9],
        [-10, -29],
        [10, -55],
        [14, -40],
        [4, -29],
        [-5, -34],
        [-3, -117],
        [-5, 3],
        [-5, 16],
        [-14, 1],
        [-2, -47],
        [1, -26],
        [-12, -21],
        [-4, -20],
        [10, -14],
        [11, -9],
        [13, 2],
        [12, -58],
        [3, -47],
        [-26, -44],
        [-9, -36],
        [-14, -43],
        [-8, -43],
        [-3, -31],
        [10, -98],
        [18, -69],
        [15, -44],
        [20, -10],
      ],
      [
        [26766, 58131],
        [-5, -46],
        [-15, -29],
        [-27, -25],
        [-30, -4],
        [-49, 23],
        [-20, 3],
        [-8, 12],
        [-3, 23],
        [-14, 34],
        [-25, 45],
        [-15, -3],
        [-24, 10],
        [-41, 31],
        [-18, 4],
        [-27, -27],
        [-31, -34],
        [-75, 54],
        [-53, 38],
        [-47, 35],
        [-13, 13],
        [-10, -3],
        [-9, -18],
        [-10, -32],
        [-4, -9],
        [-5, -9],
        [-6, -2],
      ],
      [
        [26182, 58215],
        [0, 15],
        [-24, 64],
        [-36, 76],
        [-141, 234],
        [-52, 140],
        [-28, 101],
        [-27, 53],
        [-76, 107],
        [-17, 43],
        [-76, 143],
        [-57, 85],
        [-1, 35],
        [24, 45],
        [11, -2],
        [13, -32],
        [20, -36],
        [10, -1],
        [14, 17],
        [0, 17],
      ],
      [
        [25739, 59319],
        [78, 7],
        [13, 10],
        [14, 26],
        [8, 37],
        [1, 35],
        [3, 26],
        [12, 25],
        [23, 7],
        [17, 3],
        [5, 17],
        [-5, 54],
        [-9, 131],
        [-2, 37],
        [3, 27],
        [7, 10],
        [34, 6],
        [65, -11],
        [13, 9],
        [26, 74],
        [24, 55],
        [17, 25],
        [14, 7],
        [15, -49],
        [55, -69],
        [9, 4],
        [6, 4],
        [1, 10],
        [0, 32],
        [13, 29],
        [29, 26],
        [28, 47],
        [29, 66],
        [25, 39],
        [21, 12],
        [8, 18],
        [-5, 24],
        [1, 35],
        [9, 46],
        [12, 26],
        [16, 7],
        [6, 14],
        [-3, 22],
        [3, 23],
        [15, 39],
        [34, 33],
        [20, -12],
        [17, -44],
        [23, -30],
        [30, -16],
        [23, 6],
        [17, 28],
        [15, 8],
        [13, -11],
        [7, 6],
        [1, 24],
        [7, 5],
        [13, -13],
        [12, -3],
        [10, 6],
        [4, 12],
        [2, 11],
        [7, 9],
        [26, -9],
        [29, 14],
        [33, 35],
        [21, 16],
        [11, -4],
        [12, 18],
        [15, 40],
        [34, 18],
        [71, -9],
      ],
      [
        [98087, 28064],
        [32, -3],
        [29, 43],
        [31, 34],
        [32, 28],
        [49, 66],
        [12, 9],
        [32, 13],
        [14, 15],
        [15, 4],
        [-14, -39],
        [-17, -13],
        [-3, -14],
        [10, -22],
        [-15, -31],
        [0, -39],
        [-18, -46],
        [28, 18],
        [10, 30],
        [-5, 17],
        [12, 34],
        [18, 16],
        [-7, 25],
        [0, 20],
        [23, -7],
        [11, 0],
        [9, 8],
        [16, 4],
        [4, -23],
        [21, 3],
        [-8, -28],
        [-16, -33],
        [-4, -20],
        [-27, -33],
        [-18, -14],
        [28, -4],
        [40, 44],
        [24, 39],
        [-1, -48],
        [-19, -44],
        [-17, -28],
        [-19, -8],
        [-18, -23],
        [-9, -36],
        [1, -25],
        [5, -19],
        [19, -32],
        [-21, -63],
        [24, 8],
        [13, -12],
        [18, -36],
        [-11, -42],
        [-8, -22],
        [-47, -88],
        [-20, -44],
        [-24, -29],
        [1, -47],
        [-14, -34],
        [-70, -117],
        [-12, -25],
        [-55, -186],
        [-35, -78],
        [-20, -27],
        [-21, -22],
        [-51, -36],
        [-23, -43],
        [-25, -35],
        [-26, -8],
        [1, -15],
        [17, -9],
        [13, -23],
        [-10, -26],
        [-19, -16],
        [-19, -5],
        [-10, -17],
        [46, 12],
        [13, -13],
        [3, -29],
        [5, -26],
        [11, -34],
        [39, -21],
        [35, -10],
        [7, -16],
        [5, -54],
        [-6, -27],
        [-8, -18],
        [-12, -6],
        [-28, -3],
        [-29, 12],
        [-19, 33],
        [-54, -11],
        [-15, -7],
        [-7, 6],
        [30, 35],
        [-16, 19],
        [-13, 8],
        [-14, -12],
        [-9, -18],
        [-3, -30],
        [-10, -17],
        [-15, -5],
        [-21, 25],
        [-21, 35],
        [-30, 36],
        [4, -22],
        [24, -54],
        [12, -36],
        [-28, -29],
        [-28, -22],
        [-25, -13],
        [-22, -20],
        [-27, -32],
        [-15, -11],
        [-39, -1],
        [-21, -10],
        [-7, -42],
        [-15, -27],
        [-34, -5],
        [12, -8],
        [8, -14],
        [-23, -126],
        [-5, -53],
        [-4, -89],
        [-14, -83],
        [-41, 0],
        [6, -15],
        [31, -23],
        [-6, -36],
        [-34, -64],
        [-14, -38],
        [-14, -90],
        [-20, -84],
        [-32, -95],
        [0, -17],
        [11, -24],
        [13, -21],
        [1, -30],
        [-4, -16],
        [-15, -4],
        [-13, -10],
        [-71, -26],
        [-24, -29],
        [-19, -53],
        [-22, -45],
        [-74, -100],
        [-44, -83],
        [-9, -24],
        [-12, -18],
        [-95, -39],
        [-68, -6],
        [-37, 10],
        [-36, 20],
        [-19, 7],
        [-38, -12],
        [-16, -14],
        [-30, 13],
        [-23, -10],
        [-7, 10],
        [-9, 25],
        [5, 32],
        [-6, 24],
        [-15, 17],
        [-10, 19],
        [-12, 13],
        [-31, 6],
        [-49, -9],
        [-16, 1],
        [-34, 80],
        [-11, 20],
        [-39, 25],
        [-14, -3],
        [-21, -43],
        [-13, -7],
        [-74, -5],
        [-75, 14],
        [-28, 16],
        [-5, 37],
        [57, 102],
        [-17, -14],
        [-35, -41],
        [-22, 6],
        [21, 45],
        [2, 20],
        [-4, 23],
        [-30, -38],
        [-33, -5],
        [-4, 35],
        [3, 41],
        [7, 11],
        [89, 22],
        [33, 14],
        [14, 22],
        [-54, 7],
        [-3, 31],
        [7, 25],
        [46, 41],
        [-33, -11],
        [-38, 4],
        [3, 43],
        [9, 34],
        [40, 1],
        [-13, 23],
        [-1, 33],
        [11, 2],
        [39, -44],
        [29, -16],
        [-12, 33],
        [2, 21],
        [7, 9],
        [24, 7],
        [-7, 6],
        [-22, 8],
        [-26, 25],
        [-3, 26],
        [1, 31],
        [28, 42],
        [17, -25],
        [20, 7],
        [-15, 19],
        [-9, 30],
        [6, 19],
        [60, 78],
        [15, -75],
        [4, 25],
        [1, 24],
        [-7, 20],
        [1, 21],
        [7, 18],
        [25, 17],
        [34, 58],
        [25, 26],
        [20, -17],
        [13, -23],
        [-2, 23],
        [-9, 19],
        [-3, 53],
        [45, 81],
        [49, 78],
        [48, 82],
        [25, 29],
        [54, 34],
        [34, -14],
        [9, 3],
        [51, 58],
        [21, 16],
        [19, -21],
        [12, -8],
        [-12, 54],
        [10, 24],
        [42, 44],
        [54, 45],
        [40, 19],
        [30, 30],
        [18, 1],
        [-3, 23],
        [3, 22],
        [16, -2],
        [5, 9],
        [-14, 12],
        [44, 44],
        [24, 48],
        [13, 11],
        [11, 14],
        [14, 34],
        [17, 11],
        [15, -5],
        [11, -17],
        [-6, 28],
        [-20, 15],
        [22, 24],
        [22, 16],
        [21, -12],
        [21, -19],
        [-21, 30],
        [-3, 18],
        [25, 21],
        [14, 6],
        [19, -39],
        [-2, 31],
        [4, 28],
        [28, 45],
        [36, 75],
        [11, -26],
        [2, -31],
        [-2, -39],
        [8, 14],
        [2, 34],
        [-5, 61],
        [45, 113],
        [8, 12],
        [10, 8],
        [16, 3],
        [-5, 17],
        [-12, 17],
        [12, 57],
        [8, 65],
        [10, 63],
        [17, 62],
        [18, 102],
        [14, 22],
        [38, 7],
        [16, 15],
        [28, 37],
        [32, 67],
        [18, 54],
        [23, 140],
        [13, 147],
        [37, 108],
        [54, 79],
        [48, 60],
        [19, 12],
        [33, 4],
        [32, -17],
        [-59, -14],
        [-6, -36],
        [-2, -35],
        [7, -33],
        [11, -28],
        [28, -27],
        [33, -16],
        [15, -61],
        [3, -72],
        [5, -62],
        [13, -54],
      ],
      [
        [98309, 28304],
        [-38, -34],
        [2, 23],
        [7, 51],
        [17, 26],
        [8, 1],
        [17, 20],
        [-1, -43],
        [-12, -44],
      ],
      [
        [96317, 25543],
        [-1, -28],
        [-3, -14],
        [-10, 0],
        [-14, 3],
        [-14, 13],
        [-10, -4],
        [-7, 5],
        [10, 32],
        [33, 17],
        [12, -14],
        [4, -10],
      ],
      [
        [96382, 25818],
        [12, -70],
        [-25, 14],
        [-11, 20],
        [20, 35],
        [4, 1],
      ],
      [
        [96706, 24848],
        [0, -23],
        [-29, 9],
        [1, -26],
        [23, -14],
        [8, -18],
        [24, 5],
        [5, -28],
        [-5, -24],
        [-16, -19],
        [-47, -9],
        [-31, -36],
        [-26, 6],
        [-7, -3],
        [-30, -39],
        [-34, -12],
        [-9, 3],
        [5, 34],
        [25, 33],
        [0, 31],
        [7, 25],
        [24, 18],
        [0, 33],
        [16, 29],
        [-10, 63],
        [6, 56],
        [47, 3],
        [53, -97],
      ],
      [
        [98761, 30944],
        [2, -31],
        [-21, 11],
        [-8, 24],
        [-24, 24],
        [-4, 8],
        [-2, 48],
        [12, 23],
        [2, 10],
        [6, 4],
        [10, -26],
        [19, -36],
        [8, -59],
      ],
      [
        [98129, 31719],
        [5, -26],
        [15, 18],
        [11, 30],
        [19, 30],
        [-3, -48],
        [10, -11],
        [61, -34],
        [13, -28],
        [13, -8],
        [7, 16],
        [9, 8],
        [22, -18],
        [50, -49],
        [4, -17],
        [-2, -25],
        [2, -27],
        [7, -21],
        [17, -5],
        [22, 31],
        [10, 4],
        [15, -45],
        [6, -25],
        [-3, 1],
        [10, -25],
        [12, -25],
        [22, -74],
        [-3, -26],
        [-6, -23],
        [20, -68],
        [-13, -5],
        [-40, 12],
        [1, -14],
        [23, -50],
        [20, -70],
        [15, -43],
        [55, -132],
        [-8, -47],
        [1, -31],
        [-7, -26],
        [19, -70],
        [-12, -22],
        [-8, -72],
        [-8, -12],
        [1, -26],
        [22, -7],
        [13, -11],
        [12, -21],
        [7, 25],
        [10, 7],
        [26, -34],
        [55, -34],
        [15, -13],
        [8, -27],
        [5, -67],
        [11, -29],
        [21, -6],
        [23, 9],
        [7, 25],
        [-5, 65],
        [-16, 104],
        [0, 34],
        [2, 33],
        [-3, 34],
        [-9, 32],
        [-8, 24],
        [-12, 21],
        [4, 32],
        [17, 14],
        [10, -27],
        [9, -32],
        [42, -97],
        [26, 7],
        [2, -40],
        [17, -41],
        [10, -47],
        [12, -143],
        [19, -133],
        [35, -59],
        [4, -28],
        [-21, 15],
        [-7, -9],
        [2, -14],
        [20, -25],
        [23, -13],
        [14, 2],
        [14, -9],
        [90, -87],
        [43, -34],
        [109, -56],
        [31, -4],
        [17, 2],
        [33, 19],
        [29, 34],
        [25, 52],
        [22, 59],
        [23, 28],
        [27, 23],
        [14, 21],
        [14, 15],
        [73, -7],
        [25, -30],
        [32, -24],
        [16, -18],
        [-5, -38],
        [-19, -56],
        [-15, -61],
        [-13, -138],
        [-9, -141],
        [-13, -61],
        [-24, -48],
        [-27, -34],
        [-30, -17],
        [-12, -79],
        [-6, -93],
        [1, -24],
        [10, -18],
        [4, -28],
        [-16, -56],
        [-9, 8],
        [-13, 47],
        [-12, 19],
        [-36, 15],
        [-37, 7],
        [-32, -5],
        [-31, -19],
        [-47, -41],
        [-14, -21],
        [-13, -25],
        [-21, -58],
        [-5, -71],
        [1, -38],
        [7, -28],
        [40, -40],
        [-39, -136],
        [-35, -143],
        [-20, -40],
        [-23, -38],
        [-21, -85],
        [-38, -74],
        [-25, -56],
        [-20, -59],
        [-17, -62],
        [-37, -88],
        [-16, -58],
        [-22, -48],
        [-40, -61],
        [-42, -53],
        [-67, -73],
        [-18, -24],
        [-20, -18],
        [-24, 21],
        [-5, 23],
        [-6, 50],
        [-5, 18],
        [-31, 15],
        [-41, -24],
        [-7, 5],
        [-2, 11],
        [0, 73],
        [7, 20],
        [-9, 12],
        [-10, -5],
        [-3, -18],
        [6, -16],
        [-23, -20],
        [-25, -1],
        [-7, 8],
        [-2, 13],
        [6, 22],
        [8, 20],
        [45, 91],
        [47, 122],
        [40, 130],
        [11, 67],
        [15, 125],
        [-12, 51],
        [-16, 49],
        [-40, 94],
        [-55, 53],
        [-35, 7],
        [-33, 20],
        [-31, 45],
        [-29, 53],
        [-56, 43],
        [-60, 34],
        [-34, 48],
        [-8, 29],
        [-5, 33],
        [0, 30],
        [5, 32],
        [6, 24],
        [11, 17],
        [63, 62],
        [67, 35],
        [12, -1],
        [12, 6],
        [17, 21],
        [30, 48],
        [8, 33],
        [6, 104],
        [10, 102],
        [17, 116],
        [26, 73],
        [9, 44],
        [-11, 73],
        [10, 27],
        [12, 17],
        [13, 9],
        [-23, 69],
        [-26, 104],
        [-6, 32],
        [4, 32],
        [7, 31],
        [-17, 8],
        [-10, 30],
        [-24, 101],
        [7, 16],
        [14, -11],
        [20, -73],
        [4, 38],
        [16, 23],
        [16, 12],
        [18, 2],
        [-40, 82],
        [-14, -4],
        [-18, -12],
        [-19, -8],
        [-18, 7],
        [-17, 18],
        [-8, 34],
        [-11, 66],
        [-7, 24],
        [-53, 135],
        [16, 4],
        [43, -67],
        [8, 21],
        [7, 31],
        [-3, 35],
        [-10, 26],
        [-15, 17],
        [-1, 30],
        [12, 28],
        [-1, 20],
        [-24, 40],
        [-10, 4],
        [-5, -18],
        [7, -28],
        [-6, -3],
        [-61, 73],
        [-18, 58],
        [-15, 65],
        [-2, -26],
        [2, -37],
        [24, -74],
        [39, -82],
        [7, -22],
        [-6, -29],
        [-14, -8],
        [-11, 18],
        [-18, 71],
        [-13, 35],
        [-148, 365],
        [19, 48],
        [29, 41],
        [7, 18],
        [5, 22],
        [-13, 3],
        [-11, -10],
        [-13, -18],
        [-11, -22],
        [-15, -47],
        [-7, -11],
        [-17, 33],
        [-7, 20],
        [0, 24],
        [-4, 16],
        [-13, 5],
        [-19, 48],
        [-12, 24],
        [20, 47],
        [1, 62],
        [-21, 65],
        [-24, 60],
        [-47, 96],
        [-43, 102],
        [47, 13],
        [47, 2],
        [-22, -61],
        [10, -35],
        [15, -30],
        [32, -91],
        [3, -27],
        [16, -26],
        [8, -21],
      ],
      [
        [96993, 21602],
        [15, -29],
        [-29, -13],
        [-14, 11],
        [-10, 13],
        [-5, 19],
        [15, -2],
        [14, 8],
        [14, -7],
      ],
      [
        [96172, 22602],
        [6, -48],
        [-16, -1],
        [-32, 14],
        [-9, 21],
        [-7, 5],
        [-12, -24],
        [-18, -1],
        [-5, 8],
        [8, 25],
        [44, 49],
        [8, 61],
        [-1, 19],
        [35, 5],
        [8, -7],
        [3, -9],
        [-2, -11],
        [-14, -20],
        [0, -23],
        [3, -24],
        [-11, -12],
        [6, -21],
        [6, -6],
      ],
      [
        [1062, 26647],
        [-10, -15],
        [-17, 1],
        [-30, -59],
        [2, 44],
        [-9, 17],
        [-26, -4],
        [-4, -10],
        [17, -12],
        [4, -6],
        [-17, -25],
        [17, -55],
        [15, 2],
        [14, -43],
        [0, -13],
        [-33, -16],
        [-17, -23],
        [-16, 1],
        [-7, 4],
        [-9, 41],
        [0, 17],
        [19, 31],
        [11, 30],
        [-9, 28],
        [-22, 19],
        [-48, -9],
        [-11, 6],
        [24, 39],
        [26, -5],
        [28, 28],
        [108, -13],
      ],
      [
        [1062, 26312],
        [-12, -5],
        [1, 33],
        [-4, 21],
        [21, 7],
        [9, -26],
        [-15, -30],
      ],
      [
        [2089, 46900],
        [-1, -1],
        [-1, 6],
        [-2, 7],
        [-1, 7],
        [0, 1],
        [3, -6],
        [2, -7],
        [0, -7],
      ],
      [
        [2448, 46454],
        [0, -2],
        [-2, 3],
        [-2, 5],
        [-1, 6],
        [1, 1],
        [2, -4],
        [1, -4],
        [1, -5],
      ],
      [
        [2832, 40850],
        [-28, -31],
        [-12, 37],
        [11, 48],
        [13, 12],
        [8, 2],
        [11, -45],
        [-3, -23],
      ],
      [
        [5628, 39602],
        [-9, 0],
        [-12, 4],
        [-7, 3],
        [-1, 5],
        [3, 16],
        [6, 9],
        [12, -2],
        [8, -11],
        [1, -19],
        [-1, -5],
      ],
      [
        [51664, 81077],
        [-28, 1],
        [-26, 1],
        [-14, 3],
        [-15, 9],
        [-7, 18],
        [-8, 21],
        [2, 14],
        [25, 37],
        [4, 11],
        [-3, 5],
        [3, 17],
        [19, 56],
        [2, 23],
        [-8, 16],
        [-13, 9],
        [-40, 17],
        [-19, 23],
        [-8, 21],
        [-9, 6],
        [-13, -7],
        [-33, -8],
        [-27, 11],
        [-32, 39],
        [-7, 35],
        [-4, 26],
        [-8, 10],
        [-11, -14],
        [-13, -22],
        [-27, -2],
        [-7, 5],
        [-2, 12],
        [-1, 11],
        [-8, 14],
        [-7, 8],
        [-34, -40],
        [-13, 1],
        [-16, 15],
        [-8, 15],
        [-17, -9],
        [-16, -18],
        [6, -35],
        [-9, -6],
        [-19, 3],
        [-22, 14],
      ],
      [
        [51173, 81443],
        [-24, 9],
        [-37, 24],
        [-51, -20],
        [-36, 24],
        [-29, 2],
        [-19, 19],
        [-19, 31],
        [14, 21],
        [13, 7],
        [54, 4],
        [40, -13],
        [71, -68],
        [18, 1],
        [19, 8],
        [-10, 19],
        [-18, 9],
        [-26, 18],
        [-21, 26],
        [49, 8],
        [-7, 13],
        [-6, 23],
        [-52, 79],
        [9, 22],
        [13, 46],
        [16, 38],
        [13, 10],
        [22, 27],
        [46, 80],
        [30, 65],
        [22, 76],
        [32, 212],
        [10, 36],
        [15, 40],
        [20, -8],
        [13, -11],
        [48, 30],
        [83, 78],
        [24, 68],
        [24, 31],
        [95, 62],
        [52, 18],
        [81, 5],
        [59, 11],
        [70, 4],
        [27, -38],
        [15, -28],
        [25, -15],
        [39, -11],
      ],
      [
        [51999, 82535],
        [-3, -55],
        [1, -108],
        [-3, -19],
        [-18, -46],
        [-18, -81],
        [-5, -54],
        [-5, -11],
        [-74, 1],
        [-10, -10],
        [-2, -11],
        [4, -14],
        [-2, -14],
        [-6, -11],
        [3, -18],
        [13, -20],
        [23, -13],
        [25, -1],
        [13, 2],
        [9, -14],
        [10, -22],
        [-1, -29],
        [-4, -37],
        [-11, -35],
        [-34, -40],
        [-16, -14],
        [-14, -8],
        [-7, -10],
        [-3, -14],
        [1, -12],
        [24, -32],
        [-1, -7],
        [-7, -17],
        [-9, -16],
        [-62, -33],
        [-26, 3],
        [-14, -16],
        [-5, -4],
        [-16, 16],
        [-37, 17],
        [-13, -6],
        [-8, -10],
        [-23, -11],
        [-16, -18],
        [0, -23],
        [29, -60],
        [10, -12],
        [0, -23],
        [14, -28],
        [15, -35],
        [1, -22],
        [-1, -23],
        [-8, -32],
        [-25, -75],
        [0, -15],
        [2, -11],
        [9, -3],
        [6, -6],
        [-2, -10],
        [-47, -52],
        [-6, -9],
        [-19, 3],
        [-3, -9],
        [2, -14],
        [8, -13],
        [17, -6],
        [14, -13],
        [12, -26],
        [-16, -89],
      ],
      [
        [51173, 81443],
        [-4, -22],
        [-10, -24],
        [-37, -34],
        [-39, -23],
        [-19, 3],
        [-14, 12],
        [-7, 12],
        [-21, 12],
        [-28, 6],
        [-18, -13],
        [-12, -12],
        [-11, 2],
        [-8, 10],
        [-7, 16],
        [-8, 50],
      ],
      [
        [50930, 81438],
        [21, 9],
        [46, 4],
        [35, -18],
        [46, -8],
        [36, 24],
        [28, -21],
        [31, 15],
      ],
      [
        [51759, 82667],
        [-39, -20],
        [-10, 4],
        [3, 6],
        [34, 12],
        [12, -2],
      ],
      [
        [51647, 82637],
        [-55, -9],
        [-19, 7],
        [-3, 6],
        [15, 4],
        [47, 1],
        [14, -6],
        [1, -3],
      ],
      [
        [51419, 82550],
        [-52, -42],
        [-4, 6],
        [33, 37],
        [23, -1],
      ],
      [
        [51479, 82595],
        [-26, -5],
        [-12, 8],
        [63, 23],
        [39, 7],
        [7, -3],
        [-71, -30],
      ],
      [
        [51357, 82413],
        [-28, -41],
        [-17, 12],
        [-4, 9],
        [8, 32],
        [41, 53],
        [0, -65],
      ],
      [
        [51096, 81646],
        [28, -31],
        [5, -10],
        [2, -11],
        [-34, -12],
        [-37, 38],
        [-24, -9],
        [-9, 18],
        [0, 12],
        [25, 9],
        [44, -4],
      ],
      [
        [51870, 82708],
        [-25, -2],
        [7, 15],
        [24, 12],
        [13, 0],
        [-19, -25],
      ],
      [
        [31054, 58838],
        [-14, -64],
        [-8, 28],
        [-1, 52],
        [-6, 20],
        [-11, 12],
        [-6, 17],
        [0, 26],
        [42, -41],
        [4, -50],
      ],
      [
        [32517, 61921],
        [-7, -12],
        [-6, 1],
        [-4, 11],
        [0, 20],
        [5, -6],
        [3, -2],
        [1, -4],
        [8, -8],
      ],
      [
        [32435, 61994],
        [-2, -2],
        [-4, 5],
        [1, 10],
        [3, 4],
        [2, -3],
        [2, -7],
        [-2, -7],
      ],
      [
        [30583, 59015],
        [1, -16],
        [-13, 9],
        [-17, 35],
        [-17, 27],
        [4, 29],
        [4, 10],
        [18, -27],
        [17, -50],
        [3, -17],
      ],
      [
        [30902, 58789],
        [-14, -8],
        [-54, 56],
        [-44, 90],
        [-1, 47],
        [11, -4],
        [12, -18],
        [17, -64],
        [52, -42],
        [21, -57],
      ],
      [
        [74474, 67897],
        [11, -16],
        [2, -25],
        [-2, -29],
        [-12, -61],
        [-10, -44],
        [-13, -91],
        [-11, -158],
        [3, -28],
        [33, -91],
        [12, -69],
        [2, -48],
        [-14, -80],
        [-16, -90],
        [-8, -20],
        [-9, -7],
        [-40, 31],
        [-28, -4],
        [-32, -18],
        [-34, 4],
        [-27, 10],
        [-35, -36],
        [-34, 20],
        [-21, 22],
        [-15, 63],
        [-6, 8],
        [-70, -66],
        [-17, -4],
        [-44, 35],
        [-36, 35],
        [-13, 10],
        [-35, 14],
        [-31, 8],
        [-34, 22],
        [-42, -29],
        [-17, 3],
        [-16, 20],
        [-8, 42],
        [-2, 40],
        [-15, 27],
        [-22, 6],
        [-31, -24],
        [-45, -33],
        [-15, 6],
        [-13, 9],
        [-5, 9],
        [-7, 37],
        [-7, 9],
        [-10, 1],
        [-19, 9],
        [-23, 27],
        [-70, 66],
        [-9, 29],
        [0, 65],
        [-3, 27],
        [-9, 28],
        [-36, 28],
        [-70, 46],
        [-38, 37],
        [-19, -17],
        [-35, -16],
        [-19, -33],
        [-23, 11],
        [-54, 34],
        [-29, 6],
        [-18, -12],
        [-4, -20],
        [-22, -23],
        [-21, 18],
        [-41, 25],
        [-37, 13],
        [-55, 30],
        [-6, 44],
        [-10, 45],
        [-13, 7],
        [-50, -8],
        [-45, 48],
        [-49, 63],
        [-21, 20],
        [-13, 8],
        [-12, -8],
        [-14, -15],
        [-12, -4],
        [-26, 27],
        [-34, 38],
        [-42, 47],
        [-48, 66],
        [-20, 37],
        [-9, 28],
        [-11, 27],
        [-42, 43],
        [-34, 34],
        [-40, 41],
        [-7, 8],
        [-15, 24],
        [-23, 31],
        [-20, 9],
        [-6, -17],
        [-4, -18],
        [-17, 4],
        [-26, 32],
        [-28, 32],
        [-21, 31],
        [-22, 31],
        [-5, 23],
        [9, 71],
        [13, 62],
        [11, 13],
        [17, 41],
        [7, 71],
        [-1, 61],
        [18, 85],
        [23, 91],
        [41, 98],
        [18, 32],
        [20, 22],
        [37, 72],
        [8, 12],
        [17, 19],
        [16, 4],
        [12, -9],
      ],
      [
        [72502, 69218],
        [13, -37],
        [15, -36],
        [18, 2],
        [22, 30],
        [45, 141],
        [63, 29],
        [59, -15],
        [52, -20],
        [16, -47],
        [10, -50],
        [6, -25],
        [17, -29],
        [74, -71],
        [43, -64],
        [59, -85],
        [45, -37],
        [39, -3],
        [22, -34],
        [33, -66],
        [29, -77],
        [35, -71],
        [24, 3],
        [33, 23],
        [41, 30],
        [24, -15],
        [22, -20],
        [7, -36],
        [13, -69],
        [15, -72],
        [23, -25],
        [28, -37],
        [15, -30],
        [51, -53],
        [8, -22],
        [10, -15],
        [13, -10],
        [10, -11],
        [16, -3],
        [60, 32],
        [15, -4],
        [10, -6],
        [0, -12],
        [-11, -50],
        [-9, -65],
        [9, -32],
        [25, -14],
        [55, -9],
        [75, 0],
        [22, -32],
        [23, -49],
        [22, -84],
        [9, -36],
        [12, -10],
        [19, 14],
        [3, 35],
        [1, 51],
        [16, 18],
        [11, -14],
        [12, -40],
        [30, -36],
        [23, -17],
        [21, 6],
        [9, 14],
        [10, 70],
        [17, 10],
        [21, -5],
        [8, -14],
        [9, -28],
        [25, -13],
        [26, -18],
        [24, -22],
        [33, -52],
        [42, -10],
        [48, 1],
        [25, -1],
        [19, -4],
        [17, 4],
        [49, 37],
        [20, 3],
        [25, -5],
        [24, -7],
      ],
      [
        [96376, 51545],
        [-5, -20],
        [-6, 3],
        [-3, 13],
        [2, 14],
        [7, 6],
        [5, -5],
        [0, -11],
      ],
      [
        [57016, 41593],
        [-12, 3],
        [-85, -19],
        [-33, -25],
        [-73, -108],
        [-15, 14],
        [-17, 22],
        [-15, 7],
        [-32, -27],
        [-32, -31],
        [-35, -44],
        [-29, -43],
        [-10, -23],
        [-45, -89],
        [-15, -15],
        [-13, -6],
        [-5, 4],
        [-6, 38],
        [-28, 90],
        [-45, 117],
        [-13, 12],
        [-9, 4],
        [-33, -6],
        [-96, -33],
        [-82, -28],
        [-124, -47],
        [-134, -39],
        [-83, -24],
        [-71, -7],
        [0, -116],
        [0, -235],
        [0, -235],
        [0, -235],
        [0, -235],
        [1, -235],
        [0, -235],
        [0, -235],
        [0, -235],
        [0, -102],
        [-2, -22],
        [-41, 0],
        [-94, 0],
        [-78, 0],
        [-63, 0],
        [0, -140],
        [0, -165],
        [0, -165],
        [0, -165],
        [0, -165],
        [0, -165],
        [0, -166],
        [0, -165],
        [0, -165],
        [1, -124],
        [0, -15],
      ],
      [
        [54568, 35358],
        [-31, 47],
        [-91, 175],
        [-32, 46],
        [-48, 107],
        [-105, 334],
        [-15, 64],
        [-20, 161],
        [-23, 120],
        [-3, 69],
        [11, 39],
        [-6, 53],
        [-12, 47],
        [-36, 62],
        [-10, 208],
        [-24, 134],
        [5, 110],
        [-11, 101],
        [-1, 65],
        [5, 123],
        [-19, 141],
        [-39, 138],
        [-35, 199],
        [-5, 88],
        [3, 235],
        [-6, 95],
        [0, 113],
        [-14, 117],
        [-6, 63],
        [10, 51],
        [6, -16],
        [10, -8],
        [7, 67],
        [1, 59],
        [-17, 146],
        [-39, 149],
        [-97, 244],
        [-24, 92],
        [-13, 77],
        [-108, 321],
        [-46, 226],
        [-33, 196],
        [-35, 90],
        [-162, 634],
        [-36, 101],
        [-65, 121],
        [-15, 41],
        [-25, 115],
        [-48, 155],
        [-12, 144],
        [-4, 164],
        [6, 125],
      ],
      [
        [53261, 41906],
        [45, 14],
        [31, 33],
        [28, 2],
        [27, -26],
        [29, -2],
        [12, 4],
        [52, -3],
        [30, 30],
        [36, 30],
        [20, 26],
        [29, 27],
        [39, 28],
        [21, -3],
        [27, -10],
        [36, -10],
        [20, -19],
        [24, -58],
        [36, -53],
        [28, -32],
        [31, -42],
        [9, -16],
        [14, -9],
        [8, -3],
        [58, 7],
        [53, 6],
        [56, 0],
        [106, -1],
        [107, 0],
        [106, 0],
        [106, -1],
        [106, 0],
        [107, 0],
        [106, -1],
        [106, 0],
        [44, 0],
        [76, -2],
        [80, -2],
        [8, -3],
        [9, -12],
        [8, -10],
        [28, -73],
        [36, -77],
        [30, -36],
        [36, -22],
        [33, -8],
        [32, 5],
        [52, -9],
        [73, -25],
        [76, -8],
        [78, 11],
        [55, -14],
        [32, -38],
        [33, -25],
        [33, -13],
        [45, 8],
        [57, 28],
        [49, -4],
        [22, -21],
        [14, 0],
        [83, 30],
        [68, 25],
        [101, 39],
        [83, 32],
        [123, 47],
        [87, 34],
      ],
      [
        [59222, 43770],
        [12, -17],
        [40, -142],
        [32, -83],
        [37, -77],
        [6, 4],
        [10, 18],
        [19, 7],
        [57, 18],
        [23, 1],
        [14, 20],
        [30, 15],
        [34, 9],
        [12, -10],
        [36, -99],
        [5, -77],
        [8, -110],
        [2, -54],
        [-1, -72],
        [-4, -90],
        [-29, -104],
        [-6, -51],
        [-16, -80],
        [-21, -39],
        [-10, -33],
        [1, -33],
        [11, -28],
        [24, -51],
        [8, -32],
        [-2, -29],
        [0, -40],
        [6, -27],
        [6, -16],
        [25, -26],
        [23, -65],
        [41, -78],
        [48, -111],
        [23, -34],
        [18, -8],
        [9, -38],
        [-5, -43],
        [-14, -25],
        [6, -35],
        [7, -19],
        [9, -9],
        [22, -2],
        [19, 7],
        [5, 12],
        [-2, 167],
        [-15, 97],
        [-14, 38],
        [-3, 7],
        [5, 32],
        [16, 75],
        [14, 74],
        [8, 31],
        [10, 19],
        [67, 20],
        [31, 17],
        [12, 21],
        [10, 58],
        [8, 160],
        [3, 151],
        [-7, 88],
        [10, 133],
        [15, 82],
        [-8, 17],
        [-5, 111],
        [-44, 118],
        [-56, 152],
        [-31, 82],
        [-36, 94],
        [-65, 146],
        [-29, 52],
        [-16, 21],
        [-52, 17],
        [-14, 28],
        [-14, 45],
        [-5, 83],
        [0, 62],
        [-6, 105],
        [-11, 149],
        [-5, 44],
        [-15, 112],
        [-14, 107],
        [-1, 26],
        [5, 26],
        [24, 79],
        [18, 55],
        [8, 31],
        [14, 83],
        [4, 40],
        [11, 18],
        [46, 8],
        [37, -2],
      ],
      [
        [61239, 45815],
        [15, -60],
        [26, -54],
        [-15, -31],
        [-19, -28],
        [30, -38],
        [-22, -57],
        [-3, -40],
        [6, -15],
        [5, -24],
        [-15, -65],
        [-20, -50],
        [-5, -38],
        [18, -68],
        [-9, -119],
        [17, -108],
        [4, -55],
        [6, -37],
        [-8, -67],
        [2, -111],
        [4, -46],
        [-10, -57],
        [17, -20],
        [9, -63],
        [-3, -71],
        [-5, -38],
        [-29, -46],
        [-4, -18],
        [1, -27],
        [36, -1],
        [2, -42],
        [-3, -33],
        [2, -63],
        [-5, -40],
        [8, -46],
        [-10, -51],
        [4, -40],
        [0, -51],
        [9, -130],
        [1, -160],
        [2, -25],
        [13, -18],
        [18, -9],
        [0, -44],
        [-21, -58],
        [-1, -35],
        [3, -50],
        [22, 68],
        [14, -1],
        [12, -26],
        [-2, -39],
        [4, -20],
        [-2, -38],
        [7, -48],
        [-3, -42],
        [-16, -29],
        [-21, -50],
        [-4, -48],
        [2, -30],
        [-14, -10],
        [-7, -19],
        [10, -45],
        [-1, -39],
        [-25, -123],
        [-68, -167],
        [-30, -59],
        [-27, -65],
        [0, -27],
        [-3, -23],
        [-32, -92],
        [-34, -15],
        [-20, -25],
        [15, -81],
        [-22, -19],
        [-39, -64],
        [-106, -122],
        [-17, -29],
        [-27, -75],
        [-35, -19],
        [-20, -21],
        [-36, -8],
        [-12, 6],
        [-12, -3],
        [-10, -16],
        [-70, -53],
        [-66, -42],
        [-16, -19],
        [-11, -26],
        [-58, -41],
        [-91, -103],
        [-74, -97],
        [-54, -98],
        [-14, -15],
        [-17, -33],
        [-5, -50],
        [-6, -28],
        [-40, -103],
        [-60, -121],
        [-11, -34],
        [-24, -67],
        [-2, -44],
        [-22, -14],
        [-18, 42],
        [-7, -81],
        [-15, -6],
        [-16, 17],
        [-40, -41],
        [-35, -46],
        [-56, -98],
        [-80, -191],
        [-116, -183],
        [-16, -5],
        [-10, 0],
        [-37, 64],
        [-20, 5],
        [18, -38],
        [12, -32],
        [-3, -61],
        [1, -93],
        [-14, -181],
        [2, -40],
        [16, -51],
        [31, -62],
        [30, -79],
        [37, -224],
        [3, -115],
        [39, -147],
        [1, -64],
        [16, -159],
        [-1, -128],
        [-3, -79],
        [19, -33],
        [7, 30],
        [-2, 50],
        [5, 80],
        [10, 35],
        [11, -5],
        [3, -38],
        [7, -33],
        [3, -31],
        [0, -43],
        [-14, -162],
        [4, -66],
        [19, -110],
        [-22, -128],
        [-33, -301],
        [-1, -52],
        [7, -23],
        [18, -7],
        [6, 38],
        [11, 0],
        [5, -23],
        [-14, -139],
        [-15, -61],
        [-51, -149],
        [-27, -64],
        [-46, -63],
        [-106, -98],
        [-215, -142],
        [-85, -70],
        [-50, -42],
        [-108, -132],
        [-47, -88],
        [-19, -102],
        [-19, -47],
        [-18, -59],
        [16, -50],
        [16, -39],
        [18, -25],
        [10, -22],
        [12, -16],
        [13, 80],
        [6, 25],
        [11, 2],
        [-6, -98],
        [-13, -333],
        [-1, -11],
      ],
      [
        [59622, 44922],
        [-5, 17],
        [-9, 8],
        [-10, -7],
        [-4, -21],
        [11, -27],
        [12, 0],
        [5, 30],
      ],
      [
        [59644, 44866],
        [7, 13],
        [3, 17],
        [0, 16],
        [-5, 11],
        [-7, 6],
        [-9, -4],
        [-4, -22],
        [-1, -31],
        [6, -10],
        [10, 4],
      ],
      [
        [49383, 72064],
        [8, -43],
        [16, -34],
        [59, -78],
        [35, -48],
        [1, -17],
        [-12, -39],
        [-4, -27],
        [9, -29],
        [21, -35],
        [2, -17],
        [-5, -20],
        [-11, -37],
        [23, -111],
        [4, -107],
        [-6, -76],
        [0, -44],
        [3, -37],
        [20, -87],
        [-13, -143],
        [15, -78],
        [21, -63],
        [11, -113],
        [17, -53],
        [27, -47],
        [15, -16],
        [30, -39],
        [22, -32],
        [13, -48],
        [-27, -40],
        [-22, -36],
        [-6, -38],
        [10, -61],
        [0, -33],
        [-14, -11],
        [-56, 3],
        [-44, 3],
        [-50, 3],
        [-71, 6],
        [-44, 4],
        [-61, 5],
        [-20, -3],
        [-56, -17],
        [-39, -12],
        [-6, -4],
        [-13, -15],
        [-8, -45],
        [-8, -52],
        [-8, -23],
        [-117, -74],
        [-46, -11],
        [-26, 8],
        [-19, -6],
        [-16, -16],
        [-6, -24],
        [-1, -31],
        [4, -31],
        [11, -43],
        [2, -44],
        [-7, -30],
        [-2, -31],
        [-3, -33],
        [6, -18],
        [11, -3],
        [11, -15],
        [16, -14],
        [14, -26],
        [-1, -38],
        [-11, -21],
        [-10, -11],
        [-44, -10],
        [-35, -8],
        [-45, -60],
        [-48, -64],
        [-58, -42],
        [-25, -12],
        [-44, -30],
        [-53, -50],
        [-26, -80],
        [-32, -93],
        [-32, -62],
        [-43, -58],
        [-40, -23],
        [-51, -28],
        [-63, -21],
        [-45, -8],
        [-14, -4],
        [-39, -2],
        [-20, 5],
        [-14, 2],
        [-6, -6],
        [-2, -15],
        [-1, -33],
        [-3, -38],
        [-12, -32],
        [-9, -15],
        [-11, -6],
        [-33, 9],
        [-28, 10],
        [-66, 14],
        [-13, -3],
        [-5, -4],
        [-21, -22],
        [-32, -46],
        [-22, -41],
        [-16, -18],
        [-38, -10],
        [-17, -15],
        [-72, -101],
        [-15, -24],
        [-74, -88],
        [-21, -29],
        [-17, -28],
        [-44, -65],
        [-28, -28],
        [-5, -17],
        [-2, -40],
        [0, -87],
        [0, -84],
        [0, -122],
        [0, -122],
        [0, -140],
      ],
      [
        [47587, 67774],
        [-37, 0],
        [0, -1],
        [1, -24],
        [8, -48],
        [3, -40],
        [-4, -25],
        [-4, -32],
        [2, -31],
        [6, -33],
        [6, -34],
        [0, -23],
        [-11, -18],
        [-27, -9],
        [-31, -8],
        [-23, 0],
        [-34, 5],
        [-22, -1],
        [-19, 0],
        [-16, -5],
        [-21, -22],
        [-23, -35],
        [-29, -46],
        [-17, -29],
        [-23, -6],
        [-23, 0],
        [-22, 23],
        [-14, 12],
        [-10, -1],
        [-15, -16],
        [-19, -12],
        [-17, 0],
        [-29, 24],
        [-34, 34],
        [-20, 17],
        [-29, 6],
        [-29, 11],
        [-20, -5],
        [-26, 0],
        [-34, -23],
        [-29, -17],
        [-31, -18],
        [-36, -16],
        [9, -51],
        [12, -28],
        [0, -35],
        [-6, -30],
        [-17, -28],
        [-20, -37],
        [-11, -29],
        [-12, -39],
        [-8, -24],
        [-15, -37],
        [-13, -47],
        [-4, -30],
        [-6, -33],
        [-10, -11],
        [-35, -9],
        [-22, -11],
        [-19, -12],
        [-8, -20],
        [-1, -3],
        [-5, -40],
        [0, -29],
        [-6, -23],
        [-8, -57],
        [-11, -53],
        [-9, -69],
        [-8, -57],
        [-11, -93],
        [-11, -86],
        [-14, -81],
        [-11, -51],
        [-9, -29],
        [-19, -35],
        [-17, -22],
        [-19, -30],
        [-22, -28],
        [-31, -35],
        [-25, -29],
        [-10, -13],
        [-12, -16],
        [-20, -40],
        [-16, -57],
        [-11, -47],
        [-20, -74],
        [-14, -41],
        [-8, -22],
        [-22, -23],
        [-25, -18],
        [-28, -23],
        [-22, -23],
        [-31, -23],
        [-19, -23],
        [-14, -34],
        [-11, -41],
        [-14, -58],
        [-11, -63],
        [-6, -40],
        [-16, -139],
        [-6, -80],
        [-6, -52],
        [-8, -64],
        [-5, -97],
        [0, -81],
        [-6, -46],
        [-3, -35],
        [-14, -40],
        [-11, -29],
        [-19, -40],
        [-17, -23],
        [-5, -23],
        [-17, -29],
        [-17, -46],
        [-14, -29],
        [3, -23],
        [3, -40],
        [-8, -41],
        [-9, -46],
        [-22, -57],
        [-25, -29],
        [-36, -6],
        [-50, 0],
        [-39, 6],
        [-47, 0],
        [-42, 11],
        [-39, 12],
        [-47, 6],
        [-33, 0],
        [-42, -12],
        [-108, 0],
        [-42, -6],
        [-61, -23],
        [-15, -5],
      ],
      [
        [45276, 64182],
        [21, 276],
        [38, 149],
        [30, 66],
        [47, 35],
        [43, 150],
        [16, 138],
        [28, 64],
        [9, 50],
        [-11, 38],
        [27, 75],
        [32, 114],
        [15, 73],
        [38, 113],
        [5, 25],
        [-4, 29],
        [-15, -25],
        [-16, -41],
        [-19, -33],
        [8, 40],
        [15, 60],
        [34, 62],
        [53, 69],
        [110, 234],
        [42, 41],
        [37, 98],
        [14, 88],
        [4, 200],
        [13, 106],
        [24, 83],
        [29, 150],
        [22, 68],
        [15, 136],
        [16, 53],
        [28, 24],
        [40, 69],
        [60, 42],
        [71, 89],
        [33, 53],
        [23, 79],
        [24, 158],
        [42, 166],
        [22, 125],
        [1, 2],
        [37, 66],
        [26, 83],
        [43, 37],
        [90, 18],
        [134, 69],
        [120, 104],
        [34, 42],
        [37, 83],
        [60, 108],
        [114, 130],
        [52, 72],
        [79, 182],
        [53, 150],
        [44, 97],
        [30, 86],
        [21, 87],
        [12, 140],
        [-8, 55],
        [-33, 89],
        [-23, 24],
        [-6, 42],
        [12, 75],
        [0, 128],
        [7, 204],
        [37, 165],
        [91, 217],
        [17, 88],
        [10, 142],
        [1, 50],
        [114, 200],
        [67, 154],
        [23, 37],
        [59, 70],
        [205, 154],
        [116, 109],
        [68, 80],
        [40, 94],
        [112, 371],
        [110, 521],
        [9, 61],
        [49, 17],
        [35, 7],
        [28, 19],
        [34, 40],
        [33, -16],
        [-16, -27],
        [0, -64],
        [23, -75],
        [41, -85],
        [75, -107],
        [58, -43],
        [83, -26],
        [96, 47],
        [54, 1],
        [27, 20],
        [28, -30],
        [55, -9],
        [52, 16],
        [40, 45],
        [25, 51],
        [4, -25],
        [1, -28],
        [8, -16],
        [15, -66],
        [9, -25],
        [30, 4],
        [26, -13],
        [59, 6],
        [57, -11],
      ],
      [
        [47587, 67774],
        [0, -96],
        [0, -118],
      ],
      [
        [47587, 67560],
        [1, -96],
        [0, -114],
        [0, -114],
        [0, -130],
        [0, -129],
        [0, -94],
        [0, -66],
        [-57, 0],
        [-51, 0],
        [-52, 0],
        [-52, 0],
        [-52, 0],
        [-51, 0],
        [-52, 0],
        [-52, 0],
        [-52, 0],
        [-51, 0],
        [-52, 0],
        [-52, 0],
        [-52, 0],
        [-52, 0],
        [-51, 0],
        [-52, 0],
        [-52, 0],
        [-41, 0],
        [0, -69],
        [0, -78],
        [0, -79],
        [0, -78],
        [0, -78],
        [0, -79],
        [0, -78],
        [0, -79],
        [0, -78],
        [0, -79],
        [0, -78],
        [0, -78],
        [0, -79],
        [0, -78],
        [0, -79],
        [0, -78],
        [0, -79],
        [0, -69],
        [-2, -63],
        [-17, -19],
        [-40, -33],
        [-40, -34],
        [-52, -16],
        [-17, -11],
        [-33, -46],
        [-44, -59],
        [-37, -51],
        [-25, -67],
        [-9, -37],
        [-4, -39],
        [3, -37],
        [14, -74],
        [3, -37],
        [2, -65],
        [3, -71],
        [2, -76],
        [3, -76],
        [2, -81],
        [3, -81],
        [2, -82],
        [2, -61],
        [3, -76],
        [-42, 0],
        [-64, 0],
        [-63, -1],
        [-64, 0],
        [-64, 0],
        [-64, 0],
        [-63, 0],
        [-64, 0],
        [-64, -1],
        [-63, 0],
        [-64, 0],
        [-64, 0],
        [-63, 0],
        [-64, 0],
        [-64, -1],
        [-64, 0],
        [-63, 0],
        [-36, 0],
        [-11, -108],
        [-10, -77],
        [-6, -63],
        [4, -53],
      ],
      [
        [45264, 63828],
        [-14, 29],
        [25, 300],
        [1, 25],
      ],
      [
        [55651, 76513],
        [-36, -17],
        [-7, -25],
        [-17, 3],
        [-21, 0],
        [-7, -16],
        [10, -27],
        [7, -31],
        [-5, -43],
        [-2, -6],
      ],
      [
        [55573, 76351],
        [-5, 2],
        [-30, -25],
        [-22, -12],
        [-20, -6],
        [-9, 12],
        [-5, 17],
        [1, 47],
        [-4, 16],
        [-6, 7],
        [-14, -11],
        [-16, -36],
        [-15, -42],
        [-22, -45],
        [-18, -42],
        [-19, -53],
        [-14, -44],
        [14, -25],
        [9, -35],
        [-3, -26],
        [3, -15],
        [-5, -46],
        [-1, -28],
      ],
      [
        [55372, 75961],
        [-43, 46],
        [-18, 64],
        [-63, 109],
        [-73, 74],
        [-4, 12],
        [4, 14],
        [4, 11],
        [-15, 1],
        [-11, -9],
        [-10, 3],
      ],
      [
        [55143, 76286],
        [-11, 27],
        [-11, 24],
        [0, 22],
      ],
      [
        [55121, 76359],
        [5, 2],
        [7, 9],
        [15, 23],
        [3, 13],
        [-1, 18],
        [-21, 60],
        [-3, 38],
        [-3, 72],
        [4, 17],
        [8, 8],
        [38, 9],
        [-1, 56],
        [2, 17],
        [8, 23],
        [5, 21],
        [21, 31],
        [28, 36],
        [12, 1],
        [11, -5],
        [12, -31],
        [14, 4],
        [2, 37],
        [-17, 49],
        [-9, 31],
        [3, 18],
        [6, 9],
        [15, -6],
        [14, -9],
        [10, 6],
        [14, 5],
        [8, -2],
      ],
      [
        [82411, 80543],
        [-26, -80],
        [-52, -161],
        [-44, -135],
        [-23, -77],
        [-17, -58],
        [-18, -56],
        [-3, -33],
        [-19, -54],
        [-37, -64],
        [-8, -70],
        [1, -63],
        [-3, -57],
        [-41, -35],
        [-32, -32],
        [10, -107],
        [16, -41],
        [26, -43],
        [28, -35],
        [24, -30],
        [27, 14],
        [22, 45],
        [44, 40],
        [24, 1],
        [16, -9],
        [38, -3],
        [38, 14],
        [31, 3],
        [39, -9],
        [14, -10],
        [33, -17],
        [35, -38],
        [25, -43],
        [18, -8],
        [9, 14],
        [20, 37],
        [27, 37],
        [34, 60],
        [26, 45],
        [20, 7],
        [38, 0],
        [18, 11],
        [29, 6],
        [26, -17],
        [71, -9],
        [20, -23],
        [34, -70],
        [19, -37],
        [33, -19],
        [21, -13],
        [18, -10],
        [17, -18],
        [5, -22],
        [7, -33],
        [11, -19],
        [20, -19],
        [16, -11],
        [4, -25],
        [5, -11],
        [14, -17],
        [42, -72],
        [20, -20],
        [31, -41],
        [13, -35],
        [1, -36],
        [8, -28],
        [20, -41],
        [10, -29],
        [-4, -38],
        [4, -34],
        [-8, -35],
        [-34, -25],
        [-11, -13],
        [-24, -1],
        [-41, 13],
        [-39, -7],
        [-47, 14],
        [-37, 31],
        [-20, 25],
        [-32, 14],
        [-15, -7],
        [-18, -32],
        [-21, 5],
        [-19, -5],
        [-49, 6],
        [-26, 8],
        [-42, -22],
        [-24, -7],
        [-45, -27],
        [-27, -47],
        [-20, -11],
        [-19, 2],
        [-14, 17],
        [-21, 21],
        [-30, -1],
        [-9, -9],
        [-4, -19],
        [-10, -58],
        [0, -26],
        [-6, -17],
        [-18, -6],
        [-32, 2],
        [-49, 4],
        [-33, 15],
        [-20, -7],
        [-27, -31],
        [-20, -5],
        [-15, -14],
        [-13, -46],
        [-20, -29],
        [-24, -36],
        [-26, -77],
        [-14, -44],
        [4, -24],
        [3, -28],
        [-12, -33],
        [-24, -30],
        [-20, -6],
        [-29, -29],
        [-40, -53],
        [-30, -44],
        [-40, -11],
        [-27, -11],
        [-62, -14],
        [-15, -3],
        [-68, -7],
        [-50, 24],
        [-26, -4],
        [-24, -13],
        [-11, -15],
        [-5, -28],
        [-4, -25],
        [-19, -40],
        [-38, -53],
        [-32, -35],
        [-24, -45],
        [-14, -17],
        [-27, -17],
        [-15, -10],
        [-35, -40],
        [-28, -36],
        [-18, -10],
        [-22, 9],
        [-14, 3],
        [-43, 14],
        [-29, 2],
        [-41, 9],
        [-95, 42],
        [-31, 20],
        [-27, 54],
        [-24, 27],
        [-34, 3],
        [-49, 0],
        [-23, 10],
        [-37, -10],
        [-41, -54],
        [-19, -41],
        [-17, -41],
        [-20, -89],
        [-9, -60],
        [-8, -33],
        [-21, -53],
        [-3, -30],
        [8, -26],
        [16, -29],
        [9, -46],
        [23, -49],
        [22, -38],
        [43, -61],
        [12, -33],
        [14, -36],
        [3, -36],
        [-2, -24],
        [-16, -18],
        [-29, -9],
        [-15, -25],
        [-21, -33],
        [-26, -39],
        [-13, -2],
        [-14, -10],
        [-74, -48],
        [-27, -13],
        [-22, -16],
        [-27, -49],
        [-20, -36],
        [-25, -48],
        [-11, -21],
        [-23, -48],
        [-30, -55],
        [-16, -30],
        [-9, -17],
        [-8, -23],
        [-31, -18],
        [-26, -19],
        [-38, -28],
        [-55, -32],
        [-45, -30],
        [-29, -25],
        [-42, -31],
        [-29, -10],
        [-57, 1],
        [-72, -8],
        [-52, -6],
        [-39, 7],
        [-59, 5],
        [-45, 6],
        [-30, -12],
        [-72, -12],
        [-16, -3],
        [-126, -30],
        [-56, -16],
        [-52, -7],
        [-37, -12],
        [-22, -14],
        [-31, -21],
        [-17, -9],
        [-56, -41],
        [-125, -84],
        [-84, -69],
        [-13, -12],
        [-57, -48],
        [-32, -19],
        [-23, -43],
        [-18, -27],
        [-19, -12],
        [-34, 28],
        [-24, -2],
        [-77, 11],
        [0, 125],
        [-53, -17],
        [-86, -29],
        [-79, -26],
        [-73, 60],
        [-56, 47],
        [-48, 40],
        [-74, 26],
        [-65, 23],
        [-116, 38],
        [-51, 33],
        [-26, 45],
        [-46, 99],
        [-15, 20],
        [-22, 14],
        [-24, 8],
        [-50, 0],
        [-62, 8],
        [-88, 21],
        [-71, 16],
        [-120, 32],
        [-29, 3],
        [-62, -27],
        [-81, -36],
        [-145, 28],
        [-64, 13],
        [-130, 26],
        [-147, 30],
        [-142, 31],
        [-104, -17],
        [-57, -9],
        [-67, -14],
        [-9, 15],
        [-3, 59],
        [-12, 46],
        [-36, 50],
        [-25, 47],
        [-46, 63],
        [-15, 40],
        [-5, 62],
        [-43, 162],
        [-27, 109],
        [-6, 22],
        [-12, 36],
        [-15, 18],
        [-32, 12],
        [-8, 19],
        [0, 38],
        [5, 52],
        [6, 38],
        [-5, 9],
        [-83, -10],
        [-51, 25],
        [-43, 27],
        [-60, 70],
        [-36, 27],
        [-46, 73],
        [-67, 17],
        [-25, 28],
        [-32, 62],
        [-27, 40],
        [-39, 25],
        [-62, 23],
        [-105, 21],
        [-35, 9],
        [-58, -14],
        [-43, -2],
        [-70, 16],
        [-40, 19],
        [-49, 0],
        [-32, 0],
        [-43, 4],
        [-20, 13],
        [-19, 15],
        [-36, -4],
        [-25, 16],
        [-24, 28],
        [-24, 13],
        [-27, 0],
        [-11, -13],
        [-10, 1],
        [-7, 39],
        [-25, 62],
        [-4, 28],
        [-15, 32],
        [-9, 29],
        [3, 40],
        [10, 78],
        [24, 71],
        [16, 18],
        [10, 21],
        [20, 37],
        [12, 29],
        [-2, 40],
        [-14, 41],
        [-10, 54],
        [2, 31],
        [15, 37],
        [17, 81],
        [-1, 21],
        [-7, 17],
        [-2, 38],
        [-3, 51],
        [-21, 77],
        [-11, 41],
        [-20, 18],
        [-23, 11],
        [-20, 55],
        [-25, 66],
        [-16, 41],
        [-5, 25],
        [-3, 45],
        [-12, 56],
        [-12, 30],
        [-9, 23],
        [-5, 34],
        [-5, 12],
        [-34, 15],
        [-24, 25],
        [-10, 33],
        [-4, 27],
        [-7, 16],
        [-19, 5],
        [-14, -24],
        [-22, -12],
        [-14, 2],
        [-15, 14],
        [-9, 16],
        [-15, 17],
        [-22, 55],
        [-22, 14],
        [-42, -2],
        [-37, -26],
        [-22, 4],
        [-19, 9],
        [-22, 27],
        [-14, 23],
        [-22, 7],
        [-44, 40],
        [-29, 28],
        [-3, 56],
        [-14, 39],
        [-28, 11],
        [-29, 39],
        [-42, 22],
        [-27, 16],
        [-23, 10],
        [-3, 15],
        [1, 13],
        [11, 21],
        [11, 20],
        [2, 19],
        [-9, 16],
        [-23, 17],
        [-31, 15],
        [-6, 26],
        [-19, 26],
        [4, 21],
        [14, 16],
        [15, 12],
        [3, 19],
        [-10, 19],
        [-5, 28],
        [2, 20],
        [-3, 27],
      ],
      [
        [52066, 77044],
        [-17, -10],
      ],
      [
        [52049, 77034],
        [1, 12],
        [4, 7],
        [5, 3],
        [6, -5],
        [1, -7],
      ],
      [
        [23015, 66797],
        [-5, -119],
        [-17, -97],
        [-55, -203],
        [-24, -126],
        [-44, -360],
        [-14, -236],
        [-3, -111],
        [-4, -15],
        [4, -17],
        [-10, -245],
        [5, -210],
        [-3, -32],
        [-17, -63],
        [-11, -88],
        [5, -39],
        [-1, -27],
        [17, -133],
        [5, -100],
        [50, -171],
        [28, -60],
        [34, -51],
        [13, -30],
        [-6, -72],
        [-14, -37],
        [-6, -59],
        [-7, 49],
        [2, 62],
        [11, 34],
        [1, 25],
        [-21, 26],
        [-37, 86],
        [-45, 153],
        [32, -244],
        [11, -39],
        [9, -16],
        [14, -17],
        [4, -27],
        [0, -19],
        [40, -170],
        [45, -175],
        [3, -48],
        [18, -58],
        [114, -246],
        [70, -184],
        [25, -174],
        [15, -54],
        [7, -75],
        [46, -83],
        [14, -54],
        [24, -30],
        [20, -90],
        [38, -53],
        [-9, -1],
        [-33, 27],
        [2, -18],
        [28, -37],
        [54, -37],
        [13, 0],
        [-21, 19],
        [-18, 26],
        [6, 4],
        [38, -32],
        [105, -11],
        [47, -75],
        [60, -32],
        [32, -96],
        [38, -100],
        [24, -5],
        [19, 0],
        [56, 17],
        [88, 63],
        [30, 30],
        [59, 42],
        [90, 8],
        [28, -12],
        [67, 26],
        [33, 32],
        [11, 29],
        [5, 22],
        [62, 30],
        [13, 6],
        [63, 6],
        [31, 11],
        [35, 7],
        [26, -45],
        [0, -22],
        [-17, -21],
        [8, -20],
        [31, -34],
        [56, -13],
        [19, 5],
        [26, 49],
        [46, 48],
        [-1, 55],
        [-9, 31],
        [-13, 2],
        [-3, 17],
        [9, 40],
        [-2, 14],
        [-29, -39],
        [-6, 0],
        [3, 18],
        [6, 15],
        [83, 85],
        [22, 35],
        [28, 31],
        [60, 115],
        [13, 218],
        [12, 38],
        [40, 67],
        [4, 20],
        [3, 45],
        [-1, 114],
        [2, 90],
        [-2, 102],
        [7, 90],
        [7, 26],
        [23, 145],
        [47, 64],
        [82, 76],
        [19, 13],
        [261, 80],
        [37, 20],
        [45, 52],
        [33, 17],
        [60, -1],
        [18, 7],
        [4, 7],
        [0, 7],
        [11, 7],
        [34, -7],
        [65, -31],
        [24, -8],
        [58, -36],
        [63, -15],
        [10, 6],
        [8, 11],
        [6, 22],
        [-6, 18],
        [-6, -1],
        [-11, -10],
        [-13, -1],
        [-25, 15],
        [5, 13],
        [25, -1],
        [17, 6],
        [24, 22],
        [26, -16],
        [35, -75],
        [24, -24],
        [2, -108],
        [4, -19],
        [8, -29],
        [-12, -84],
        [-13, -69],
        [-17, -57],
        [-37, -89],
        [-45, -71],
        [-56, -159],
        [-13, -75],
        [1, -59],
        [9, -58],
        [-3, -21],
        [-6, -22],
        [-12, 2],
        [-22, -28],
        [-28, -82],
        [-1, -25],
        [13, -23],
        [16, 11],
        [21, 1],
        [12, 7],
        [12, -2],
        [-3, -47],
        [-13, -33],
        [-8, -11],
        [-15, -5],
        [-17, -19],
        [-9, -18],
        [1, -54],
        [9, -4],
        [20, 40],
        [12, -2],
        [2, -17],
        [-26, -139],
        [-16, -143],
        [-23, -82],
        [-8, -121],
        [-11, -51],
        [-14, -51],
        [-8, 3],
        [-21, 96],
        [-23, 25],
        [-4, 23],
        [12, 117],
        [-6, 65],
        [-11, -3],
        [-15, -35],
        [-19, -31],
        [0, -45],
        [-22, -73],
        [-6, -25],
      ],
      [
        [25473, 62483],
        [-21, 6],
        [-25, -3],
        [-17, -18],
        [-18, -89],
        [-43, -126],
        [-18, -62],
        [-14, -21],
        [-11, -8],
        [-13, 15],
        [-29, 34],
        [-24, -17],
        [-8, -39],
        [1, -50],
      ],
      [
        [25233, 62105],
        [-59, 0],
        [-99, 0],
        [-126, 0],
        [-122, 0],
        [-102, 1],
        [0, -113],
        [-1, -100],
        [0, -112],
        [-56, 1],
        [-60, 1],
        [5, -12],
        [20, -21],
        [27, -50],
        [31, -78],
        [38, -63],
        [44, -46],
        [29, -46],
        [14, -44],
        [8, -38],
        [0, -32],
        [16, -24],
        [29, -17],
        [15, -28],
        [0, -22],
        [-9, -52],
        [-3, -57],
        [4, -52],
        [-21, -1],
        [-51, 0],
        [-76, 0],
        [-71, 0],
        [-56, 0],
        [-84, 0],
        [-23, -80],
        [-38, -132],
        [-35, -119],
        [-29, -101],
        [-5, -26],
        [0, -22],
        [36, -94],
        [-6, -27],
        [-13, -15],
        [-4, -22],
        [1, -36],
        [-9, -47],
        [3, -33],
        [5, -41],
        [-8, -35],
        [-6, -34],
        [-7, -15],
      ],
      [
        [24379, 60221],
        [-8, 13],
        [-74, 157],
        [-78, 172],
        [-30, 56],
        [-29, 43],
        [-40, 79],
        [-104, 174],
        [-54, 80],
        [-50, 95],
        [-45, 53],
        [-45, 34],
        [-20, 20],
        [-17, 26],
        [-10, 2],
        [-5, -35],
        [16, -18],
        [19, -15],
        [14, -1],
        [16, -13],
        [46, -48],
        [7, -25],
        [-130, 97],
        [-53, 9],
        [-6, 15],
        [27, 50],
        [-9, 18],
        [-9, 3],
        [-28, -35],
        [-11, -2],
        [-2, 23],
        [1, 21],
        [-18, 32],
        [-11, -1],
        [-10, -22],
        [-25, -42],
        [1, -17],
        [49, -18],
        [16, -10],
        [-3, -11],
        [-42, 0],
        [-51, -19],
        [-92, -116],
        [-86, -50],
        [-122, -113],
        [-54, -5],
        [-29, -18],
        [-82, 43],
        [-105, 105],
        [-158, 33],
        [-107, 138],
        [-106, 57],
        [-67, 132],
        [-41, 6],
        [-26, 21],
        [-96, 48],
        [-95, 32],
        [-93, 115],
        [-61, 37],
        [-52, 46],
        [-115, 79],
        [-43, 43],
        [-41, 68],
        [-66, 69],
        [-28, 59],
        [-32, 21],
        [-45, 109],
        [-23, 47],
        [-20, 21],
        [-21, 8],
        [-62, -9],
        [-91, 48],
        [-43, 12],
        [-88, 72],
        [-118, 80],
        [-39, 91],
        [-32, 86],
        [-60, 113],
        [-37, 48],
        [-64, 57],
        [-36, 46],
        [-55, 35],
        [-93, 91],
        [-29, 77],
        [-18, 68],
        [-49, 83],
        [-55, 156],
        [-14, 57],
        [-10, 88],
        [-13, 51],
        [-15, 39],
        [8, 29],
        [27, 36],
        [46, 8],
        [33, 39],
        [4, 32],
        [-2, 19],
        [-21, 49],
        [-26, 13],
        [-20, 1],
        [-5, 18],
        [15, 20],
        [18, 48],
        [25, 58],
        [18, 53],
        [4, 75],
        [-3, 76],
        [7, 63],
        [-62, 74],
        [-7, 31],
        [-19, 84],
        [-34, 98],
        [1, 195],
        [-41, 173],
        [-42, 86],
        [-22, 30],
        [-59, 134],
        [-46, 77],
        [-46, 146],
        [-45, 93],
        [-58, 156],
        [-41, 78],
        [-189, 262],
        [11, 0],
        [55, -64],
        [9, 5],
        [2, 32],
        [-7, 37],
        [-10, 9],
        [-15, -8],
        [-20, 8],
        [-10, 12],
        [-29, 8],
        [-38, 44],
        [-16, 45],
        [-1, 52],
        [-54, 110],
        [-20, 62],
        [10, -5],
        [14, -25],
        [15, -7],
        [17, 0],
        [12, 10],
        [-4, 18],
        [-12, 15],
        [-78, 58],
        [-26, 41],
        [-64, 68],
        [-15, 24],
        [-10, 65],
        [-16, 3],
        [-13, -18],
        [-38, -18],
        [-10, 21],
        [-1, 20],
        [27, 22],
        [24, 61],
        [0, 22],
        [-14, -25],
        [-20, -28],
        [-21, -15],
        [-32, -13],
        [-16, 9],
        [-14, 14],
        [-22, 54],
        [-11, 176],
        [20, 61],
        [23, 61],
        [20, 35],
        [11, -27],
        [12, -3],
        [-9, 31],
        [-19, 28],
        [-7, 29],
        [-1, 26],
        [-9, 48],
        [-57, 102],
        [-54, -8],
        [-22, 3],
        [-20, 39],
        [-17, 65],
        [-9, 55],
        [-1, 29],
        [-5, 29],
        [-93, 48],
        [-28, 41],
        [-27, 51],
        [-12, 42],
        [-11, 32],
        [-9, 54],
        [-7, 63],
        [11, 82],
        [13, 39],
        [-64, 30],
        [-24, 1],
        [-21, -16],
        [-18, 21],
        [-37, 24],
        [-45, 85],
        [-53, 155],
        [-57, 50],
        [-19, 54],
        [-24, 48],
        [-20, 60],
        [-4, 26],
        [-6, 15],
        [-29, 42],
        [-32, 71],
        [-9, 57],
        [-8, 87],
        [-22, 31],
        [-21, 14],
        [-5, 41],
        [2, 24],
        [-7, 44],
        [-44, 105],
        [-23, 87],
        [-12, 27],
        [-11, 39],
        [-6, 81],
        [-18, 101],
        [-36, 120],
        [-29, 82],
        [-15, 82],
        [7, 83],
        [-5, 51],
        [-4, 12],
        [3, 17],
        [10, -9],
        [8, 15],
        [-1, 53],
        [-10, 16],
        [-29, 17],
        [-12, 11],
        [-70, 22],
        [-39, 30],
        [-3, 70],
        [-19, 32],
        [-16, 20],
        [-52, 41],
        [-9, -21],
        [-7, -39],
        [-21, -8],
        [-19, -2],
        [-32, 27],
        [-79, 103],
        [-17, 17],
        [-25, 9],
        [-12, 16],
        [-53, 55],
        [11, -29],
        [15, -30],
        [14, -87],
        [-16, -63],
        [-10, -220],
        [11, -44],
        [23, -70],
        [16, -112],
        [5, -82],
        [14, -66],
        [-4, -155],
        [5, -47],
        [22, -77],
        [41, -73],
        [9, -38],
        [53, -55],
        [33, -72],
        [65, -98],
        [20, -42],
        [58, -152],
        [2, -46],
        [11, -56],
        [33, 12],
        [15, -42],
        [-2, -20],
        [4, -15],
        [17, 3],
        [15, -11],
        [31, -166],
        [17, -22],
        [21, -10],
        [24, -18],
        [1, -42],
        [-1, -34],
        [21, -49],
        [-4, -66],
        [17, -56],
        [-3, -55],
        [7, -43],
        [50, -97],
        [62, -77],
        [13, -101],
        [26, -93],
        [25, -23],
        [27, -39],
        [-4, -40],
        [2, -24],
        [35, -73],
        [6, -94],
        [30, -61],
        [9, -4],
        [6, 9],
        [-22, 61],
        [-10, 40],
        [-2, 63],
        [8, 8],
        [62, -99],
        [7, -74],
        [21, -42],
        [1, -56],
        [13, -34],
        [4, -49],
        [20, -80],
        [1, -112],
        [10, -82],
        [40, -125],
        [32, -26],
        [6, -61],
        [33, -159],
        [38, -87],
        [19, -73],
        [3, -45],
        [-14, -68],
        [-2, -47],
        [21, -143],
        [31, -73],
        [35, -18],
        [6, -10],
        [-3, -20],
        [12, -18],
        [13, 23],
        [7, 32],
        [-7, 37],
        [-1, 27],
        [6, 19],
        [11, 3],
        [67, -98],
        [11, -38],
        [25, -43],
        [23, -55],
        [9, -42],
        [19, -36],
        [9, -81],
        [46, -37],
        [25, -68],
        [2, -43],
        [-12, -110],
        [-11, -31],
        [-37, -47],
        [-27, -56],
        [-27, -34],
        [-27, -21],
        [-23, 4],
        [-23, 65],
        [-26, 193],
        [-18, 41],
        [-12, 61],
        [-20, 50],
        [-75, 76],
        [-37, 81],
        [-37, 53],
        [-39, 78],
        [-106, 129],
        [-44, 66],
        [-29, 65],
        [-19, -1],
        [-14, -7],
        [-6, 18],
        [-1, 33],
        [-6, 22],
        [-62, 98],
        [-13, 55],
        [-3, 62],
        [14, 162],
        [6, 95],
        [-4, 49],
        [-6, 6],
        [-6, 27],
        [-2, 78],
        [-15, 84],
        [-56, 174],
        [-41, 34],
        [-37, 25],
        [-101, 154],
        [-27, 76],
        [-7, 44],
        [-3, 89],
        [-14, -52],
        [-19, -38],
        [-42, 3],
        [-48, -43],
        [-29, 40],
        [-15, 46],
        [-24, 55],
        [-26, 11],
        [-17, 2],
        [-31, 68],
        [-26, 21],
        [-36, 9],
        [-32, 34],
        [-9, 38],
        [-5, 53],
        [-12, 32],
        [-49, 62],
        [-39, 69],
        [-38, 45],
        [-11, 36],
        [-1, 25],
        [59, -7],
        [71, -27],
        [34, 8],
        [21, 25],
        [20, 18],
        [3, -19],
        [-4, -37],
        [20, -33],
        [26, -27],
        [19, 3],
        [-18, 29],
        [-12, 60],
        [5, 22],
        [0, 29],
        [-26, -6],
        [-4, 15],
        [23, 45],
        [25, 120],
        [13, 118],
        [-27, 103],
        [-46, 72],
        [-98, 210],
        [-59, 108],
        [-17, 40],
        [-16, 19],
        [-48, 25],
        [-40, 60],
        [-71, 85],
        [-30, 44],
        [-21, 104],
        [-16, 14],
        [5, 71],
        [-7, 127],
        [-12, 32],
        [-38, 31],
        [-9, 86],
        [-2, 82],
        [-8, 57],
        [-65, 96],
        [-3, 46],
        [0, 44],
        [-7, 44],
        [-35, 91],
        [-42, 79],
        [-14, 38],
        [-2, 77],
        [-15, 21],
        [6, 5],
        [13, -2],
        [8, 10],
        [1, 54],
        [-63, 84],
        [-18, 116],
        [-34, 61],
        [-8, 23],
        [-18, 109],
      ],
      [
        [25850, 63538],
        [-15, -18],
        [-7, 64],
        [11, 61],
        [14, 36],
        [28, 4],
        [18, 12],
        [2, -16],
        [-15, -48],
        [-36, -95],
      ],
      [
        [20416, 64291],
        [-8, -47],
        [-21, 19],
        [-8, 30],
        [-1, 48],
        [11, 9],
        [17, -21],
        [4, -14],
        [6, -24],
      ],
      [
        [19190, 62638],
        [-17, -12],
        [-24, 36],
        [6, 27],
        [14, 19],
        [14, -35],
        [7, -35],
      ],
      [
        [19287, 66246],
        [8, -65],
        [-15, 10],
        [-18, 35],
        [-13, 44],
        [1, 20],
        [3, 4],
        [26, -26],
        [8, -22],
      ],
      [
        [18568, 68578],
        [-2, -10],
        [-29, 35],
        [-64, 122],
        [-23, 61],
        [-4, 28],
        [2, 64],
        [22, -8],
        [25, -42],
        [12, -40],
        [0, -45],
        [48, -21],
        [7, -98],
        [6, -46],
      ],
      [
        [18008, 68012],
        [-4, -19],
        [-47, 39],
        [26, 67],
        [-4, 70],
        [12, 15],
        [10, -23],
        [13, -90],
        [-6, -59],
      ],
      [
        [18832, 68551],
        [-21, -136],
        [-21, 2],
        [-44, 43],
        [-5, 27],
        [17, 157],
        [13, 21],
        [39, 21],
        [6, -19],
        [4, -47],
        [12, -69],
      ],
      [
        [17155, 68514],
        [-12, -22],
        [-32, 120],
        [0, 29],
        [9, 15],
        [15, -3],
        [0, -30],
        [13, -26],
        [5, -24],
        [2, -59],
      ],
      [
        [25913, 64077],
        [4, -28],
        [-4, 4],
        [-7, 21],
        [-4, 26],
        [4, 1],
        [3, -9],
        [4, -15],
      ],
      [
        [24532, 62601],
        [-31, -13],
        [-6, 13],
        [63, 58],
        [11, -2],
        [4, -8],
        [-33, -28],
        [-8, -20],
      ],
      [
        [19498, 65755],
        [-6, -3],
        [-14, 31],
        [-6, 75],
        [3, 8],
        [27, -93],
        [-1, -11],
        [-3, -7],
      ],
      [
        [18140, 70106],
        [-9, -2],
        [-17, 26],
        [1, 25],
        [4, 2],
        [17, -21],
        [6, -19],
        [-2, -11],
      ],
      [
        [19139, 66831],
        [3, -20],
        [-2, -6],
        [-11, 14],
        [-19, -86],
        [-6, -8],
        [12, 118],
        [12, 17],
        [13, 3],
        [-2, -32],
      ],
      [
        [18972, 65894],
        [-3, -27],
        [-84, 108],
        [20, 10],
        [23, -8],
        [44, -83],
      ],
      [
        [18873, 65982],
        [-6, -7],
        [-24, 67],
        [-3, 46],
        [-10, 19],
        [-24, 15],
        [21, 93],
        [17, 193],
        [8, -35],
        [-18, -195],
        [1, -26],
        [8, -24],
        [10, -40],
        [1, -44],
        [16, -40],
        [3, -22],
      ],
      [
        [66014, 40043],
        [-36, -17],
        [-39, 6],
        [-15, 31],
        [-3, 13],
        [13, 12],
        [-1, 39],
        [7, 63],
        [8, 26],
        [20, 23],
        [8, 51],
        [17, 34],
        [22, 4],
        [22, -63],
        [16, -66],
        [-3, -65],
        [-16, -25],
        [-5, -38],
        [-15, -28],
      ],
      [
        [45451, 63194],
        [-17, -56],
        [-8, 22],
        [-3, 37],
        [15, 52],
        [8, 28],
        [14, 9],
        [-9, -92],
      ],
      [
        [45406, 60966],
        [0, 258],
        [15, 97],
        [5, 85],
        [33, 187],
        [38, 153],
        [36, 204],
        [14, 197],
        [-5, 193],
        [-11, 172],
        [-18, 113],
        [-17, 164],
        [-26, 87],
        [-47, 76],
        [-11, 44],
        [11, 16],
        [29, 12],
        [18, 59],
        [-38, -23],
        [44, 181],
        [14, 123],
        [-2, 81],
        [9, 50],
        [-35, 108],
        [-26, 136],
        [-14, 22],
        [-14, 11],
        [-1, -32],
        [-8, -29],
        [-17, 18],
        [-29, 99],
        [-41, 161],
        [-15, 17],
        [-12, -23],
        [-7, -21],
        [-14, -134],
      ],
      [
        [47587, 67560],
        [53, -63],
        [52, -64],
        [52, -64],
        [52, -64],
        [53, -63],
        [52, -64],
        [52, -64],
        [52, -63],
        [53, -64],
        [52, -64],
        [52, -63],
        [52, -64],
        [53, -64],
        [52, -64],
        [52, -63],
        [52, -64],
        [44, -53],
        [67, -86],
        [63, -80],
        [63, -81],
      ],
      [
        [48660, 66241],
        [-97, 0],
        [-130, 0],
        [-89, 0],
        [-91, 0],
        [-85, -1],
        [7, -131],
        [8, -143],
        [8, -143],
        [8, -144],
        [8, -143],
        [8, -143],
        [8, -143],
        [8, -143],
        [8, -144],
        [7, -143],
        [8, -143],
        [8, -143],
        [8, -144],
        [8, -143],
        [8, -143],
        [8, -143],
        [8, -144],
        [8, -143],
        [8, -143],
        [8, -143],
        [7, -144],
        [8, -143],
        [8, -143],
        [8, -143],
        [8, -144],
        [8, -143],
        [8, -143],
        [8, -143],
        [8, -143],
        [8, -144],
        [8, -143],
        [7, -143],
        [8, -143],
        [8, -139],
        [33, -73],
        [42, -92],
        [-12, -129],
        [-15, -155],
        [-16, -169],
        [-58, 0],
        [-57, 0],
        [-57, 0],
        [-56, 0],
        [-57, 0],
        [-57, 0],
        [-56, 0],
        [-57, 0],
        [-57, 0],
        [-56, 0],
        [-57, 0],
        [-57, 0],
        [-56, 0],
        [-57, 0],
        [-57, 0],
        [-56, 0],
        [-57, 0],
        [-53, 0],
        [-32, 4],
        [-12, 13],
        [-4, 87],
        [-10, -5],
        [-11, -26],
        [-6, -28],
        [2, -36],
        [-2, -31],
        [-36, -12],
        [-49, -21],
        [-52, -16],
        [-52, 6],
        [-18, 7],
        [-19, 12],
        [-42, 12],
        [-22, 2],
        [-26, -3],
        [-31, -7],
        [-10, -16],
        [-23, -66],
        [-22, -75],
        [-15, 0],
        [-16, 42],
        [-45, 78],
        [-55, 102],
        [-24, 51],
        [-14, 7],
        [-26, -37],
        [-22, -35],
        [-23, -50],
        [-11, -47],
        [-8, -57],
        [-4, -66],
        [-8, -78],
        [-19, -62],
        [-23, -47],
        [-16, -22],
        [-7, -13],
        [-49, 37],
      ],
      [
        [54046, 72495],
        [-10, -19],
        [-26, 1],
        [-24, 29],
        [0, 61],
        [27, -12],
        [25, -41],
        [8, -19],
      ],
      [
        [53975, 72596],
        [-16, -9],
        [-17, 17],
        [-4, 11],
        [23, 9],
        [12, -8],
        [4, -15],
        [-2, -5],
      ],
      [
        [48660, 66241],
        [85, -110],
        [77, -104],
        [91, -124],
        [91, -123],
        [91, -123],
        [91, -124],
        [90, -123],
        [91, -123],
        [91, -124],
        [91, -123],
        [91, -123],
        [91, -124],
        [91, -123],
        [91, -123],
        [91, -124],
        [91, -123],
        [91, -123],
        [91, -124],
        [41, -55],
        [3, -23],
        [4, -46],
        [-2, -52],
        [0, -43],
        [12, -29],
        [23, -31],
        [89, -91],
        [7, -18],
        [3, -37],
        [11, -47],
        [19, -27],
        [22, -20],
        [26, -14],
        [81, -14],
        [17, -22],
        [35, -84],
        [19, -16],
        [54, -25],
        [38, -13],
        [17, -8],
        [35, -23],
        [38, -38],
        [20, -35],
        [0, -11],
        [0, -30],
        [0, -91],
        [7, -50],
        [8, -36],
        [-1, -22],
        [-9, -16],
        [-8, -19],
        [-4, -25],
        [-11, -32],
        [-9, -36],
        [4, -27],
        [15, -18],
        [23, -34],
        [19, -14],
        [9, -1],
        [12, 1],
        [11, 4],
        [68, 26],
        [63, 25],
        [88, 34],
      ],
      [
        [50060, 60432],
        [-58, 42],
        [-68, 43],
        [-47, -27],
        [-8, 10],
        [-6, 18],
        [-23, 10],
        [-36, -5],
        [-26, -12],
        [-41, -64],
        [-31, -55],
        [-8, -13],
        [-43, -33],
        [-80, -78],
        [-46, -57],
        [-10, -11],
        [-21, -13],
        [-31, -3],
        [-26, -14],
        [-23, -151],
        [-16, -15],
        [-95, 61],
        [-19, -9],
        [-17, -18],
        [-53, -88],
        [-27, -71],
        [-14, -95],
        [2, -29],
        [0, -32],
        [-9, -18],
        [-13, -7],
        [-12, 1],
        [-44, 20],
        [-14, -9],
        [-6, -46],
        [1, -102],
        [-10, -69],
        [-26, -22],
        [-20, -27],
        [-16, -8],
        [-14, 7],
        [-77, 103],
        [-26, 17],
        [-29, -12],
        [-28, -44],
        [-12, -28],
        [-17, -34],
        [-20, -45],
        [5, -39],
        [14, -44],
        [10, -55],
        [-1, -50],
        [-70, -70],
        [6, -24],
        [10, -28],
        [0, -51],
        [-2, -89],
        [-14, -33],
        [-18, -31],
        [-12, -42],
        [-11, -20],
        [-20, -25],
        [-27, -25],
        [-48, -23],
        [-38, -15],
        [-14, -14],
        [-20, -30],
        [-16, -36],
        [-4, -39],
        [3, -44],
        [6, -37],
        [7, -25],
        [4, -31],
        [-6, -85],
        [-14, -97],
        [-13, -44],
        [-21, -24],
        [-19, -27],
        [6, -64],
        [4, -92],
        [-6, -73],
        [0, -45],
        [-8, -48],
        [-5, -33],
      ],
      [
        [48465, 57848],
        [-9, 8],
        [-38, -3],
        [-42, -26],
        [-14, -20],
        [-3, -27],
        [-10, -19],
        [-13, -20],
        [-13, -26],
        [-23, 4],
        [-22, 18],
        [-11, 17],
        [-1, 10],
        [7, 25],
        [6, 27],
        [1, 18],
        [-8, 43],
        [-6, 47],
        [3, 23],
        [-6, 70],
        [-3, 3],
        [-29, -18],
        [-10, -4],
        [-6, -8],
        [-2, -13],
        [7, -44],
        [-5, -8],
        [-16, 1],
        [-23, 15],
        [-25, 40],
        [-6, -13],
        [-3, -32],
        [-1, -38],
        [6, -69],
        [-7, -25],
        [-16, 5],
        [-23, 0],
        [-19, -7],
        [-13, -2],
        [-8, -24],
        [-4, -28],
        [8, -31],
        [-1, -12],
        [-6, -12],
        [-8, -7],
        [-6, 0],
        [-18, 34],
        [-22, 13],
        [-50, 19],
        [-6, 47],
        [-8, 1],
        [-12, 24],
        [-11, 32],
        [-10, -1],
        [-8, -9],
        [-28, 3],
        [-24, -49],
        [-18, -61],
        [-20, -29],
        [-21, -13],
        [-8, 0],
      ],
      [
        [47780, 57697],
        [4, 38],
        [-3, 28],
        [-6, 25],
        [-62, 67],
        [-10, 28],
        [-9, 76],
        [-7, 76],
        [1, 44],
        [4, 40],
        [-2, 31],
        [-6, 24],
        [-19, 22],
        [-20, 11],
        [-24, -30],
        [-12, -5],
        [-11, 2],
        [-6, 10],
        [1, 16],
        [27, 81],
        [13, 34],
        [16, 26],
        [10, 14],
        [7, 20],
        [1, 15],
        [-3, 12],
        [-17, 14],
        [-28, 38],
        [-14, 4],
        [-12, 18],
        [-13, 59],
        [-6, 11],
        [-13, 6],
        [-12, 15],
        [1, 77],
        [0, 66],
        [-27, 108],
        [-10, 67],
        [-13, 69],
        [-12, 33],
        [-22, 27],
        [-26, 19],
        [-24, 4],
        [-18, -6],
        [-8, -8],
        [0, -13],
        [15, -44],
        [2, -24],
        [-2, -24],
        [-5, -16],
        [-13, -2],
        [-23, -13],
        [-28, -27],
        [-19, -22],
        [-16, -58],
        [-11, -8],
        [-18, 8],
        [-53, 42],
        [-44, 35],
        [-30, 21],
        [-18, -13],
        [-9, -7],
        [-26, -23],
        [-34, -86],
        [-8, -28],
        [-7, -9],
        [-9, -15],
        [-9, 0],
        [-7, 10],
        [-3, 6],
        [-17, 62],
        [-20, 68],
        [-15, 30],
        [-20, 2],
        [-17, -22],
        [-18, -43],
        [-22, -41],
        [-14, -12],
        [-13, 7],
        [-30, 51],
        [-22, 36],
        [-3, 18],
        [8, 28],
        [8, 42],
        [8, 34],
        [8, 15],
      ],
      [
        [70393, 53704],
        [-6, -1],
        [-4, 10],
        [1, 14],
        [5, 10],
        [7, 1],
        [4, -9],
        [-2, -14],
        [-5, -11],
      ],
      [
        [70419, 54242],
        [-4, -6],
        [-5, 2],
        [-2, 7],
        [3, 10],
        [3, 13],
        [3, 14],
        [4, 7],
        [3, -2],
        [0, -8],
        [-2, -11],
        [-1, -14],
        [-2, -12],
      ],
      [
        [78361, 55438],
        [48, -22],
        [18, -18],
        [54, -178],
        [71, -126],
        [30, -46],
        [23, -23],
        [32, -67],
        [28, -84],
        [61, -237],
        [10, -105],
        [5, -159],
        [-14, -240],
        [-16, -119],
        [3, -57],
        [22, -86],
        [-6, -82],
        [4, -68],
        [-2, -189],
        [13, -55],
        [15, -36],
        [76, -112],
        [6, -41],
        [37, -143],
        [70, -310],
        [19, -139],
        [-2, -38],
        [-8, -15],
        [-21, -14],
        [-17, 27],
        [-6, 20],
        [2, 24],
        [-7, 24],
        [-16, 28],
        [-10, 26],
        [3, -42],
        [0, -55],
        [-21, -5],
        [-28, 17],
        [-34, -15],
        [-40, -68],
        [-19, -2],
        [-15, 58],
        [-8, 39],
        [-12, 28],
        [-127, 142],
        [-47, 37],
        [-50, 107],
        [-112, 119],
        [-71, 116],
        [-30, 71],
        [-73, 64],
        [-31, 74],
        [-16, 15],
        [-15, 27],
        [16, 72],
        [-7, 76],
        [-8, 64],
        [-51, 126],
        [-25, 88],
        [-49, 87],
        [-19, 51],
        [-18, 58],
        [11, 21],
        [11, 12],
        [-10, 43],
        [-27, 74],
        [-13, 85],
        [0, 160],
        [-39, 226],
        [-34, 313],
        [6, 110],
        [-9, 119],
        [-22, 114],
        [-29, 82],
        [-11, 67],
      ],
      [
        [82659, 54245],
        [-11, 1],
        [-24, 12],
        [-48, 61],
        [-49, 22],
        [-71, 2],
        [-41, 8],
        [-16, -9],
        [-14, 0],
        [-10, 12],
        [-11, 6],
        [-27, -36],
        [-13, 11],
        [-14, 16],
        [-23, 5],
        [-28, -5],
        [-32, -37],
        [-34, 34],
        [-10, -1],
        [-7, -8],
        [-15, -46],
        [-29, -35],
        [-14, -64],
        [-9, -61],
        [-7, -21],
        [-3, -119],
        [-4, -57],
        [7, -76],
        [-1, -32],
        [-13, -49],
        [-2, -11],
        [-6, -77],
        [2, -21],
        [-1, -25],
        [-11, -54],
        [-20, -15],
        [-20, -9],
        [-18, 19],
        [-16, -30],
        [-20, -46],
        [-8, -31],
        [0, -29],
        [2, -19],
        [-4, -20],
        [-1, -21],
        [1, -30],
        [14, -13],
        [14, -26],
        [-1, -25],
        [-7, -17],
        [-18, -27],
        [-33, -55],
        [-37, -47],
        [-14, -11],
        [-5, -22],
        [-2, -29],
        [8, -64],
        [7, -18],
        [5, -23],
        [-5, -26],
        [-4, -23],
        [-14, -15],
        [-13, -10],
        [-5, -19],
        [-7, -76],
        [-8, -40],
        [-18, -59],
        [-6, -27],
        [-9, -9],
        [-35, 28],
        [-31, -17],
        [-41, -11],
        [-35, 2],
        [-27, -12],
        [-19, -31],
        [-21, -40],
        [-22, -29],
        [-16, -14],
        [-30, 41],
        [-16, -3],
        [-27, 14],
        [-65, 47],
        [-16, 13],
        [-17, 2],
        [-5, 13],
        [3, 23],
        [-3, 29],
        [-13, 11],
        [-129, -4],
        [-38, -26],
        [-25, -20],
        [-18, -23],
        [-5, -59],
        [-11, -54],
        [-14, -58],
        [-43, -17],
        [-32, -59],
        [-11, -7],
        [-21, 9],
        [-24, 5],
        [-17, -17],
        [-17, 1],
        [-55, 28],
        [-51, 4],
        [-30, -14],
        [-16, -5],
        [-89, -81],
        [-31, -9],
        [-12, 12],
        [-17, 33],
        [-24, 32],
        [-55, 112],
        [-21, 26],
        [-14, 27],
        [-13, 32],
        [-18, 35],
        [-17, 24],
        [-23, 48],
        [-22, 53],
        [-5, 93],
        [-18, 17],
        [-7, 24],
        [-2, 28],
        [25, 76],
      ],
      [
        [80452, 53011],
        [18, -80],
        [7, -18],
        [40, -54],
        [33, -27],
        [36, -11],
        [37, -2],
        [15, 4],
        [14, 10],
        [14, -11],
        [76, -88],
        [30, -15],
        [31, 6],
        [13, -8],
        [44, -67],
        [13, -8],
        [22, 5],
        [-28, 30],
        [-18, 22],
        [-8, 41],
        [4, 44],
        [18, 29],
        [12, 31],
        [5, 94],
        [8, 48],
        [14, 46],
        [5, 43],
        [-16, 34],
        [-4, 57],
        [3, 47],
        [10, 33],
        [15, -22],
        [15, -19],
        [15, 2],
        [11, 7],
        [2, 25],
        [-3, 43],
        [1, 78],
        [19, 63],
        [31, 43],
        [29, 21],
        [108, 35],
        [172, 89],
        [51, 35],
        [19, 18],
        [15, 25],
        [27, 80],
        [50, 125],
        [35, 103],
        [74, 151],
        [59, 139],
        [8, 26],
        [9, 76],
        [1, 36],
        [-2, 36],
        [8, 17],
        [12, 10],
        [3, 0],
      ],
      [
        [81684, 54488],
        [8, -16],
        [21, -22],
        [15, -28],
        [10, -37],
        [8, -34],
        [0, -29],
        [10, -24],
        [26, -4],
        [8, -30],
        [18, -52],
        [17, -37],
        [10, -14],
        [13, 7],
        [19, 34],
        [14, 42],
        [10, 56],
        [-8, 9],
        [14, 42],
        [2, 22],
        [-6, 21],
        [-8, 20],
        [-3, 51],
        [-5, 66],
        [-4, 29],
        [11, 22],
        [22, 27],
        [22, 31],
        [23, 25],
      ],
      [
        [81951, 54665],
        [1, -45],
        [-1, -75],
        [7, -63],
        [15, -110],
        [18, -15],
        [21, -10],
        [12, 3],
        [8, 7],
        [2, 9],
        [-13, 44],
        [-3, 102],
        [-11, 67],
        [-17, 67],
        [-7, 19],
      ],
      [
        [81983, 54665],
        [65, 19],
        [14, 21],
        [26, 46],
        [10, 26],
        [7, 58],
        [-32, 34],
        [-12, 44],
        [-1, 48],
        [38, 88],
        [13, 21],
        [6, -31],
        [17, -8],
        [15, -1],
        [16, 2],
        [22, 44],
        [12, 64],
        [39, 91],
        [14, 70],
        [8, 72],
        [99, 226],
        [12, 36],
        [59, 227],
        [7, 7],
        [16, -22],
        [4, -72],
        [-2, -31],
        [-9, -47],
        [-6, -49],
        [7, 1],
        [28, 30],
        [29, 79],
        [17, 69],
        [14, 30],
        [28, -17],
        [6, -12],
        [-2, -49],
        [3, -29],
        [11, -61],
        [24, -37],
        [33, -24],
        [31, -34],
        [10, -22],
        [6, -27],
        [7, -45],
        [0, -44],
        [-22, -44],
        [10, -71],
        [-2, -41],
        [-7, -35],
        [-33, -33],
        [88, 33],
        [22, 18],
        [30, 47],
        [16, -41],
        [15, -69],
        [-12, -18],
        [-38, -26],
        [-2, -10],
        [13, -36],
        [16, 3],
        [31, 24],
        [29, 38],
        [14, 0],
        [15, -8],
        [29, -24],
        [16, -20],
        [13, -26],
        [9, -53],
        [33, -19],
        [68, -75],
        [12, -7],
        [14, -1],
        [35, 9],
        [13, -10],
        [9, -27],
        [3, -34],
        [-1, -36],
        [-4, -27],
        [-8, -22],
        [-25, -34],
        [-61, -45],
        [-66, -34],
        [-34, 2],
        [-47, 29],
        [-17, -3],
        [-17, -14],
        [-21, -92],
        [39, -92],
        [66, -96],
        [9, -24],
        [-2, -29],
        [-11, -18],
        [-14, -9],
        [-37, -16],
        [-38, -11],
        [-30, -17],
        [-31, -21],
        [-31, 7],
        [-43, 43],
        [-12, 3],
        [-13, -22],
        [-13, -60],
        [-8, -17],
      ],
      [
        [78950, 53416],
        [-14, -6],
        [-7, 4],
        [-5, 23],
        [11, 51],
        [4, 9],
        [11, -56],
        [0, -25],
      ],
      [
        [77857, 54893],
        [-7, -16],
        [-20, 9],
        [4, 94],
        [11, 12],
        [18, -17],
        [8, -16],
        [-14, -66],
      ],
      [
        [77735, 55567],
        [19, -62],
        [-9, -27],
        [-5, -8],
        [-12, 9],
        [-11, -24],
        [-11, -4],
        [-11, 42],
        [-13, 17],
        [-3, 30],
        [18, 5],
        [10, -10],
        [20, 20],
        [8, 12],
      ],
      [
        [82539, 55972],
        [-17, -31],
        [-6, 37],
        [1, 47],
        [23, 44],
        [33, 8],
        [4, -35],
        [-4, -40],
        [-7, -21],
        [-27, -9],
      ],
      [
        [78143, 53564],
        [-14, -10],
        [0, 15],
        [2, 21],
        [10, 20],
        [5, -12],
        [-3, -34],
      ],
      [
        [80941, 53234],
        [-9, -8],
        [-13, 21],
        [-3, 175],
        [9, 15],
        [6, -2],
        [7, -32],
        [-1, -76],
        [1, -68],
        [3, -25],
      ],
      [
        [82745, 54254],
        [-39, -11],
        [-27, 1],
      ],
      [
        [82679, 54244],
        [5, 20],
        [-1, 27],
        [13, 7],
        [15, -6],
        [34, -38],
      ],
      [
        [63760, 44681],
        [13, -60],
        [15, -58],
        [46, -140],
        [20, -53],
        [17, -57],
        [8, -114],
        [30, -178],
        [28, -266],
        [8, -273],
        [9, -126],
        [21, -118],
        [36, -122],
        [11, -136],
        [-21, -140],
        [-32, -132],
        [-8, -25],
        [-15, -34],
        [-6, 1],
        [-26, 34],
        [-20, 56],
        [-26, 132],
        [-10, 66],
        [-10, 11],
        [-31, -6],
        [-22, -41],
        [-4, -27],
        [5, -74],
        [8, -66],
        [4, -68],
        [0, -85],
        [9, -26],
        [12, -22],
        [12, -55],
        [3, -133],
        [-8, -67],
        [-22, -58],
        [2, -32],
        [8, -32],
        [-8, -20],
        [-29, -25],
        [-11, -22],
        [-16, -59],
        [-25, -120],
        [-3, -61],
        [16, -186],
        [-5, -132],
        [-32, -252],
        [-18, -119],
        [-26, -143],
        [-40, -189],
        [-39, -237],
        [-34, -243],
        [-25, -147],
        [-28, -144],
        [-38, -255],
        [-33, -258],
        [-48, -285],
        [-68, -317],
        [-7, -41],
        [-14, -163],
        [-15, -140],
        [-18, -140],
        [-37, -230],
        [-5, -71],
        [-8, -68],
        [-36, -144],
        [-16, -54],
        [-10, -57],
        [-6, -72],
        [-11, -70],
        [-27, -129],
        [-39, -110],
        [-27, -40],
        [-58, -59],
        [-30, -11],
        [-65, -2],
        [-64, -33],
        [-66, -64],
        [-63, -73],
        [-25, -35],
        [-27, -20],
        [-83, -4],
        [-26, 16],
        [-84, 120],
        [-32, 20],
        [-62, 17],
        [-18, 10],
        [-17, 16],
        [-25, 62],
        [-50, 54],
        [-12, 16],
        [-7, 37],
        [-5, 39],
        [-13, 45],
        [-10, 84],
        [-16, 59],
        [-45, 104],
        [-5, 33],
        [-4, 110],
        [2, 75],
        [-5, 136],
        [5, 65],
        [16, 58],
        [-6, 62],
        [-17, 66],
        [-6, 68],
        [-13, 62],
        [-48, 112],
        [-11, 55],
        [-8, 57],
        [-18, 178],
        [-2, 62],
        [3, 130],
        [7, 68],
        [11, 47],
        [3, 35],
        [8, 30],
        [11, 24],
        [7, 28],
        [18, 168],
        [23, 37],
        [33, 21],
        [27, 44],
        [16, 59],
        [15, 121],
        [42, 121],
        [15, 63],
        [34, 96],
        [30, 135],
        [9, 64],
        [7, 65],
        [8, 143],
        [5, 71],
        [-1, 70],
        [-17, 72],
        [-41, 132],
        [-1, 24],
        [3, 98],
        [-4, 70],
        [-15, 71],
        [-19, 66],
        [-19, 124],
        [-10, 205],
        [2, 74],
        [-5, 65],
        [-14, 63],
        [10, 109],
        [123, 397],
        [4, 47],
        [-5, 121],
        [2, 71],
        [5, 26],
        [9, 15],
        [21, 7],
        [99, 18],
        [13, 12],
        [25, 33],
        [34, 65],
        [15, 19],
        [14, -7],
        [8, -28],
        [11, -15],
        [40, 29],
        [16, 1],
        [16, -5],
        [7, 27],
        [4, 36],
        [6, 26],
        [11, 14],
        [51, 8],
        [33, 11],
        [43, 25],
        [9, -5],
        [34, -91],
        [11, -8],
        [13, -4],
        [12, 17],
        [-28, 48],
        [-4, 26],
        [1, 31],
        [15, 65],
        [25, 50],
        [56, 76],
        [57, 88],
        [17, 6],
        [14, -14],
        [11, -103],
        [-1, -17],
        [9, -3],
        [10, 13],
        [10, 42],
        [0, 34],
        [-7, 34],
        [-4, 28],
        [0, 26],
        [29, 61],
        [23, 58],
        [10, 70],
        [10, 32],
        [24, 36],
        [7, -6],
        [6, -29],
        [3, -31],
        [-6, -31],
        [-9, -31],
        [-4, -40],
        [14, -8],
        [13, 10],
        [19, 73],
        [21, 70],
        [13, 36],
        [16, 25],
        [27, -5],
        [26, -15],
        [-43, 73],
        [-10, 101],
        [50, 174],
        [1, 37],
        [7, 11],
        [3, 14],
        [-26, 59],
        [-5, 29],
        [4, 44],
        [12, 40],
        [12, 27],
        [16, 11],
        [13, -15],
        [28, -49],
        [19, -7],
        [23, 46],
        [18, 58],
        [28, 40],
        [32, 25],
        [49, 91],
        [32, 191],
        [2, 56],
        [-7, 67],
        [-11, 65],
        [-19, 80],
        [5, 18],
        [27, -11],
        [9, 11],
        [28, 71],
        [48, 136],
        [16, 0],
        [13, -25],
        [5, -37],
        [10, -28],
        [32, -65],
        [16, -48],
      ],
      [
        [63428, 44145],
        [0, -21],
        [-36, 8],
        [-6, 72],
        [18, 3],
        [4, 29],
        [11, 4],
        [11, -64],
        [-2, -31],
      ],
      [
        [63871, 42106],
        [-32, -106],
        [9, 89],
        [36, 127],
        [11, 10],
        [-24, -120],
      ],
      [
        [56206, 76217],
        [43, -86],
        [23, -34],
        [28, -27],
        [32, -19],
        [11, -19],
        [20, -91],
        [10, -34],
        [13, -11],
        [3, -10],
        [1, -13],
        [-15, -64],
        [-6, -144],
        [-4, -11],
      ],
      [
        [56365, 75654],
        [-16, 0],
        [-21, -3],
        [-8, -11],
        [-8, -77],
        [-34, -22],
        [-30, -13],
        [-26, 3],
        [-45, 18],
        [-15, 2],
        [-13, -10],
        [-40, -5],
        [-18, -14],
        [-42, -90],
        [-42, -32],
        [-14, -15],
        [-33, 20],
        [-15, 2],
        [-22, -23],
        [-49, -3],
        [-13, -4],
        [-38, -3],
      ],
      [
        [55823, 75374],
        [-2, 12],
        [-7, 18],
        [-17, 9],
        [-36, -7],
        [-9, 13],
        [-15, 77],
        [-11, 12],
        [-13, 26],
        [-22, 83],
        [-1, 37],
        [2, 32],
        [-12, 74],
        [7, 19],
        [11, 12],
        [0, 30],
        [-3, 46],
        [14, 90],
        [3, 6],
      ],
      [
        [55712, 75963],
        [4, -4],
        [32, -7],
        [8, 11],
        [6, 18],
        [1, 66],
        [8, 30],
        [78, 58],
        [23, 2],
        [18, -27],
        [14, -17],
        [8, 1],
        [3, 17],
        [10, 33],
        [16, 19],
        [48, 16],
      ],
      [
        [51699, 80714],
        [-3, -15],
        [1, -35],
        [8, -34],
        [18, -34],
        [14, -25],
        [19, -20],
        [33, -19],
        [13, -4],
        [1, -25],
        [-2, -27],
        [-11, -15],
        [-11, -21],
        [-8, -26],
        [-8, -50],
        [-1, -35],
      ],
      [
        [51762, 80329],
        [-19, 14],
        [-10, 10],
        [-17, 3],
        [-17, -8],
        [-12, -18],
        [-18, -5],
        [-14, 5],
        [-9, 13],
        [-7, 8],
        [-22, 8],
        [-9, 19],
      ],
      [
        [51608, 80378],
        [7, 9],
        [6, 15],
        [5, 19],
        [7, 19],
        [-21, 50],
        [-5, 16],
        [-17, 28],
        [0, 14],
        [4, 14],
        [-1, 11],
        [2, 25],
        [12, 24],
        [8, 30],
        [14, 40],
        [31, 49],
        [21, -8],
        [10, 0],
        [6, -17],
        [2, -2],
      ],
      [
        [55805, 83690],
        [32, 66],
        [12, 43],
        [8, 61],
        [8, 20],
        [0, -28],
        [-3, -46],
        [-20, -80],
        [-21, -41],
      ],
      [
        [55898, 83677],
        [0, 4],
        [-9, 42],
        [10, 64],
        [-19, 93],
        [-30, 113],
        [-2, 121],
        [-2, 27],
      ],
      [
        [55846, 84141],
        [74, 68],
        [94, 73],
        [22, 6],
        [86, 44],
        [12, 3],
        [78, -8],
        [62, -10],
        [51, 1],
        [29, 11],
        [26, -9],
        [20, -33],
        [22, 4],
        [21, 21],
        [116, -19],
        [26, 0],
        [29, -3],
        [54, -19],
        [32, -18],
        [68, 11],
        [30, 0],
        [15, 7],
        [47, 49],
        [21, 9],
        [19, 8],
        [17, -7],
        [11, -42],
        [35, -72],
        [38, -13],
        [106, -27],
        [21, -15],
        [59, -64],
        [36, -31],
        [22, -25],
        [35, -49],
        [20, -35],
        [33, -27],
        [39, -18],
        [15, -3],
      ],
      [
        [57387, 83909],
        [-1, -26],
        [-7, -44],
        [-13, -56],
        [-14, -44],
        [-3, -17],
        [10, -14],
        [52, -7],
        [22, -7],
        [4, -12],
        [-11, -15],
        [-17, -13],
        [-7, -12],
        [-13, -42],
        [-86, 5],
        [-12, -9],
        [-5, -19],
        [-4, -23],
        [-12, -28],
        [-23, -23],
        [-35, -9],
        [-29, -16],
        [-22, -49],
        [-16, -67],
        [0, -47],
        [2, -26],
        [-2, -15],
        [-11, -17],
        [-18, -43],
        [-14, -48],
        [-6, -26],
        [3, -12],
        [16, -1],
        [24, -10],
        [13, -19],
        [4, -22],
        [0, -24],
        [-4, -13],
        [-19, -10],
        [-30, 0],
        [-17, 12],
        [-4, 9],
        [8, 22],
        [-6, 29],
        [-12, 16],
        [-25, -24],
        [-24, 0],
        [-29, -21],
        [-19, -34],
        [-18, -13],
        [-49, 7],
        [-13, -15],
        [-10, -69],
        [-6, -14],
        [-40, 3],
        [-40, -28],
        [-45, -22],
        [-22, 15],
        [-13, 18],
        [-24, -3],
        [-27, -8],
        [-17, 4],
        [-20, -1],
        [-39, -14],
        [-48, 4],
        [-21, 12],
      ],
      [
        [52646, 79072],
        [3, -9],
        [1, -12],
        [3, -12],
        [1, -16],
        [4, -16],
        [8, -15],
        [3, -14],
        [-4, -18],
        [-4, -11],
      ],
      [
        [53195, 70957],
        [43, -37],
        [43, -14],
        [130, -136],
        [40, -17],
        [91, -16],
        [107, 56],
        [40, 10],
        [71, -52],
        [31, -15],
        [52, -4],
        [89, -47],
        [22, -17],
        [52, -75],
        [25, -23],
        [184, -69],
        [25, -46],
        [26, -87],
        [1, -109],
        [14, -79],
        [23, -102],
        [28, -72],
        [30, -61],
        [35, -37],
        [81, -56],
        [91, -21],
        [92, -8],
        [158, -76],
        [133, -89],
        [33, -44],
        [67, -43],
        [134, -208],
        [74, -72],
        [52, -14],
        [46, 13],
        [83, 72],
        [34, 43],
        [84, 180],
        [27, 94],
        [11, 66],
        [-3, 67],
        [-10, 61],
        [-23, 63],
        [-17, 84],
        [-9, 151],
        [13, 104],
        [16, 63],
        [25, 64],
        [69, 122],
        [70, 86],
        [122, 113],
        [71, 1],
        [30, 12],
        [58, 80],
        [24, 3],
        [33, -20],
        [97, 6],
        [42, -22],
        [51, -50],
        [64, -31],
        [45, -30],
        [48, -40],
        [11, -98],
        [-5, -29],
        [-1, -38],
        [50, -68],
        [142, -32],
        [28, -18],
        [39, -52],
        [25, -16],
        [97, -7],
        [57, 11],
        [54, -18],
        [20, -18],
        [21, -40],
        [25, -99],
        [10, -33],
      ],
      [
        [56986, 70077],
        [-11, -16],
        [-15, -35],
        [-10, -30],
        [-26, -50],
        [-21, -53],
        [2, -79],
        [5, -79],
        [14, -78],
        [13, -86],
        [-4, -56],
        [-11, -70],
        [-12, -58],
        [-42, -119],
        [-7, -28],
        [3, -41],
        [25, -141],
        [2, -44],
        [16, -138],
        [14, -112],
        [15, -87],
        [2, -25],
        [0, -129],
        [0, -129],
        [0, -130],
        [0, -129],
        [0, -129],
        [0, -130],
        [0, -129],
        [0, -129],
        [0, -130],
        [0, -129],
        [0, -129],
        [0, -130],
        [0, -129],
        [0, -130],
        [0, -129],
        [0, -129],
        [0, -130],
        [0, -129],
        [0, -129],
        [0, -130],
        [0, -129],
        [0, -129],
        [0, -130],
        [0, -129],
        [0, -129],
        [0, -130],
        [0, -129],
        [0, -130],
        [0, -129],
        [0, -129],
        [0, -130],
        [0, -129],
      ],
      [
        [56661, 63073],
        [-133, 137],
        [-134, 136],
        [-133, 136],
        [-133, 136],
        [-133, 137],
        [-133, 136],
        [-133, 136],
        [-133, 137],
        [-133, 136],
        [-133, 136],
        [-133, 136],
        [-133, 137],
        [-133, 136],
        [-133, 136],
        [-134, 137],
        [-133, 136],
        [-92, 94],
        [-99, -92],
        [-77, -72],
        [-103, -95],
      ],
      [
        [53324, 65390],
        [-26, 102],
        [-30, 114],
        [-40, 142],
        [-24, 87],
        [-8, 14],
        [-111, 69],
        [-117, 67],
        [-69, -41],
        [-12, 3],
        [-19, 26],
        [-20, 35],
        [-10, 49],
        [-28, 66],
        [-25, 150],
        [-2, 119],
        [-6, 43],
        [-61, 168],
        [-55, 153],
        [-37, 102],
        [-7, 46],
        [4, 57],
        [15, 51],
        [54, 60],
        [48, 66],
        [7, 45],
        [3, 125],
        [-16, 39],
        [-12, 74],
        [-11, 101],
        [-2, 64],
        [22, 128],
        [25, 134],
        [-16, 149],
        [-12, 297],
        [8, 235],
        [-7, 85],
        [-4, 36],
        [-16, 110],
        [-21, 114],
        [-9, 40],
        [-26, 92],
        [-43, 114],
        [-22, 70],
        [30, 37],
        [28, 29],
      ],
      [
        [47143, 56730],
        [14, 3],
        [24, 17],
        [14, -8],
        [6, -23],
        [3, -20],
        [72, 51],
        [6, 11],
        [4, -2],
        [9, -47],
        [5, 3],
        [5, 14],
        [5, 1],
        [5, -6],
        [6, -22],
        [9, -19],
        [16, -14],
        [10, -19],
        [-2, -49],
        [4, -49],
        [7, -11],
        [4, -28],
        [1, -32],
        [4, -17],
        [3, -32],
        [-1, -34],
        [2, -24],
        [12, -41],
        [7, -53],
        [0, -37],
        [-4, -39],
        [-8, -35],
        [-13, -39],
        [-1, -16],
        [8, -10],
        [12, -2],
        [10, 8],
        [25, -18],
        [14, -25],
        [12, -32],
        [10, -16],
        [5, -20],
        [18, 6],
        [21, 19],
        [4, 9],
        [7, -5],
        [13, -2],
        [10, 35],
        [7, 40],
        [17, 43],
        [8, 16],
        [2, 28],
        [1, 36],
        [6, 30],
        [13, 17],
        [15, 0],
        [8, -6],
        [4, -30],
        [11, -23],
        [10, -15],
      ],
      [
        [47642, 56197],
        [5, -7],
        [9, -18],
        [8, -60],
        [31, -194],
        [-2, -54],
        [-6, -35],
        [0, -35],
        [-2, -33],
        [-19, -56],
        [-56, -114],
        [4, -10],
        [13, -13],
        [14, -6],
        [11, 3],
        [14, -28],
        [16, -36],
        [16, -18],
        [23, -17],
        [20, -2],
        [17, 7],
        [24, -7],
        [26, -30],
        [9, -49],
        [7, -42],
        [9, -22],
        [1, -36],
        [18, -33],
        [26, -6],
        [34, -38],
        [9, 2],
        [4, 5],
        [4, -8],
        [8, -109],
        [7, -58],
        [-3, -23],
        [-5, -19],
        [0, -88],
        [-16, -51],
        [-2, -56],
        [-5, -18],
        [-16, -16],
        [0, -42],
        [-4, -52],
        [-2, -55],
        [4, -144],
        [1, -107],
        [8, -20],
      ],
      [
        [47904, 54349],
        [-32, 9],
        [-94, 82],
        [-73, 47],
        [-242, 267],
        [-68, 108],
        [-77, 160],
        [-173, 322],
        [-39, 51],
        [-50, 25],
        [-31, 28],
        [-21, 30],
        [-18, 89],
        [-43, 53],
        [-80, 75],
        [-60, 126],
      ],
      [
        [59963, 71101],
        [-8, -10],
        [-15, -26],
        [-14, -22],
        [-30, -33],
        [-7, -20],
        [-6, 18],
        [-13, -12],
        [-11, -75],
        [-23, -26],
        [-29, 3],
        [-23, 7],
        [-32, -5],
      ],
      [
        [59752, 70900],
        [13, 44],
        [13, 57],
        [13, 77],
        [24, 64],
        [49, 217],
        [28, 87],
        [10, 125],
        [43, 109],
        [33, 32],
        [15, 31],
        [0, 47],
      ],
      [
        [55846, 84141],
        [-9, 109],
        [5, 217],
        [11, 108],
        [51, 63],
        [26, 49],
        [15, 65],
        [5, 61],
        [10, 49],
        [75, 144],
        [60, 15],
        [80, 40],
        [90, 33],
        [17, -42],
        [9, -32],
        [108, -117],
        [27, -40],
        [42, -135],
        [100, -68],
        [79, 22],
        [34, 33],
        [63, 61],
        [28, 45],
        [6, 43],
        [-11, 184],
        [-17, 80],
        [6, 50],
      ],
      [
        [56756, 85178],
        [11, -2],
        [27, 24],
        [88, 44],
        [17, 2],
        [20, 9],
        [56, 34],
        [18, -18],
        [14, -20],
        [9, -1],
        [4, 8],
        [-2, 13],
        [4, 10],
        [16, -6],
        [65, -55],
        [24, -13],
        [17, -4],
        [20, -26],
        [55, -18],
        [7, -13],
        [4, -17],
        [51, -71],
        [23, -35],
        [46, -33],
        [20, -7],
        [79, 33],
        [23, 11],
        [18, 1],
        [19, -18],
        [42, -23],
        [39, -8],
        [7, 2],
      ],
      [
        [57818, 84183],
        [-8, 2],
        [-24, -8],
        [-38, -32],
        [-56, -78],
        [-14, -17],
        [-15, -59],
        [-3, -6],
        [-33, 2],
        [-9, 2],
        [-33, -1],
        [-71, 15],
        [-28, -10],
        [-36, -60],
        [-14, -9],
        [-42, -8],
        [-7, -7],
      ],
      [
        [79866, 60313],
        [-15, -23],
        [-14, -59],
        [-10, -4],
        [-24, 21],
        [-9, -11],
        [-15, -43],
        [-27, -47],
        [-13, 0],
        [-9, 5],
        [-11, -20],
        [-15, -36],
        [-7, 1],
        [-26, -9],
        [-10, 12],
        [-12, 30],
        [-21, 31],
        [-18, 22],
        [-10, 15],
        [-9, 26],
        [-8, 16],
        [-15, -36],
        [-26, -35],
        [-24, 7],
        [-12, 5],
        [-9, -50],
        [-7, -9],
        [-44, -9],
        [-8, -8],
        [7, -46],
        [26, -79],
        [7, -45],
        [-16, -73],
        [-45, 2],
        [-20, 30],
        [-19, 41],
        [-7, 21],
        [-57, 41],
        [-39, -28],
        [-12, 1],
        [-18, 30],
        [-11, 23],
        [-11, 34],
        [-6, 34],
        [0, 16],
      ],
      [
        [77811, 63546],
        [2, 32],
        [15, 125],
        [18, 81],
        [22, 38],
        [22, 16],
        [24, -6],
        [20, 7],
        [16, 19],
        [-1, 12],
        [-19, 3],
        [-8, 21],
        [4, 41],
        [8, 26],
        [13, 13],
        [13, 40],
        [12, 70],
        [15, 35],
        [18, 1],
        [30, 30],
        [42, 59],
        [16, 57],
      ],
      [
        [78093, 64266],
        [16, -26],
        [-6, -66],
        [9, -14],
        [4, -23],
        [-2, -37],
        [3, -32],
        [7, -15],
        [9, -8],
        [45, 27],
        [27, 2],
        [12, -17],
        [10, -11],
        [13, -9],
        [11, -11],
        [6, 3],
        [15, 28],
        [5, 5],
        [1, 13],
        [-11, 25],
        [-11, 21],
        [0, 46],
        [5, 80],
        [1, 41],
        [-1, 100],
        [-2, 27],
        [-10, 33],
        [-27, 61],
        [-7, 38],
        [-5, 38],
        [1, 24],
        [-7, 27],
        [-4, 26],
        [13, 13],
        [14, 29],
        [7, 45],
        [7, 33],
        [10, 14],
        [9, 5],
        [6, -3],
        [23, -59],
        [28, 30],
        [22, -1],
        [19, -15],
        [10, -19],
      ],
      [
        [69707, 76179],
        [-15, 26],
        [0, 27],
        [16, 46],
        [14, 23],
        [6, 44],
        [20, 29],
        [20, 47],
        [25, 38],
        [46, 28],
        [25, -9],
        [24, 7],
        [38, 23],
        [7, 1],
        [15, 1],
        [96, -36],
        [32, -1],
        [74, -46],
        [34, -10],
        [24, -13],
        [11, -20],
        [18, -25],
        [93, -19],
        [26, -13],
        [9, -22],
        [26, -27],
        [23, -6],
        [-20, 106],
        [8, 63],
        [30, 173],
        [15, 26],
        [30, 23],
        [46, 26],
        [18, 36],
        [38, -4],
        [16, 3],
        [12, 6],
        [6, 21],
        [43, -35],
        [72, -71],
        [54, -45],
        [64, -43],
        [88, -39],
        [75, -13],
        [13, 10],
        [30, 59],
        [14, 2],
        [26, -5],
        [79, -3],
        [81, -3],
        [38, 6],
        [83, 25],
        [12, 1],
        [19, -2],
        [50, -33],
        [37, -5],
        [25, 0],
        [14, -2],
        [31, 1],
        [50, -4],
        [61, -22],
        [75, 4],
        [23, 4],
        [42, -4],
        [32, -20],
        [42, -22],
        [26, -9],
        [17, -5],
        [31, -2],
        [19, 7],
        [11, -10],
        [10, -53],
        [26, -35],
        [20, -34],
        [17, -36],
        [17, -15],
        [30, -1],
        [57, -10],
        [33, -15],
        [41, -63],
        [39, -65],
      ],
      [
        [72280, 76146],
        [5, -35],
        [5, -40],
        [-3, -10],
        [-5, -6],
        [-86, -10],
        [-19, -11],
        [-20, -56],
        [-73, -45],
        [-42, -23],
        [-17, 1],
        [-40, -37],
        [-113, -91],
        [-55, -58],
        [-28, -24],
        [-22, -27],
        [-4, -27],
        [-1, -25],
        [-62, -118],
        [-46, -15],
        [-39, 3],
        [-27, -18],
        [-38, -18],
        [-83, 12],
        [-28, -2],
        [-55, 17],
        [-21, -9],
        [-24, -24],
        [-32, -95],
        [-13, -22],
        [-6, -21],
        [-5, -46],
        [-12, -49],
        [-16, -38],
        [-11, -36],
        [-23, -34],
        [-22, -22],
        [-17, 45],
        [-14, -13],
        [-14, -18],
        [-26, 6],
        [-16, -9],
        [-37, -39],
        [-54, 1],
        [-6, 14],
        [-11, 108],
        [-9, 51],
        [-8, 11],
        [-10, 2],
        [-77, -85],
        [-36, -15],
        [-30, -3],
        [-39, 25],
        [-8, -6],
        [-7, -14],
        [-3, -17],
        [12, -48],
        [-3, -10],
        [-18, 1],
        [-24, -11],
        [-19, -22],
        [-56, -78],
        [-47, -26],
        [-43, -10],
        [-18, -9],
        [-8, -9],
        [-15, -37],
        [-15, -58],
        [-8, -29],
        [-6, -16],
        [1, -22],
        [12, -27],
        [9, -63],
        [-2, -16],
        [-9, -26],
        [-14, -25],
        [-30, -16],
        [-23, -7],
      ],
      [
        [63409, 68907],
        [-16, -13],
        [-10, 6],
        [-11, 31],
        [-17, 77],
        [10, 29],
        [-1, 12],
        [2, 9],
        [5, 6],
        [6, 36],
        [7, 12],
        [12, -25],
        [33, -88],
        [0, -37],
        [-2, -14],
        [-18, -41],
      ],
      [
        [62925, 68603],
        [45, 94],
        [21, 51],
        [38, 109],
        [19, 78],
        [19, 87],
        [16, 67],
        [4, 12],
        [9, 23],
        [21, 24],
        [30, 22],
        [51, 9],
        [36, 1],
        [8, -1],
        [22, -11],
        [63, -54],
      ],
      [
        [63327, 69114],
        [-2, -21],
        [9, -64],
        [20, -69],
        [17, -56],
        [2, -27],
        [-15, 4],
        [-12, 11],
        [-22, 11],
        [-42, -75],
        [-26, -40],
        [0, -14],
        [34, -16],
        [25, 1],
        [17, 11],
        [15, -18],
        [10, -46],
        [4, -37],
        [23, -133],
        [19, -45],
        [24, -80],
        [9, -41],
        [5, -35],
        [15, -51],
      ],
      [
        [55712, 75963],
        [5, 26],
        [-2, 55],
        [-15, 91],
        [-10, 30],
        [-22, 30],
        [-16, 19],
        [-30, 17],
        [-15, 50],
        [-23, 57],
        [-11, 13],
      ],
      [
        [98046, 53645],
        [-17, -19],
        [-6, 3],
        [21, 27],
        [0, -3],
        [2, -8],
      ],
      [
        [98011, 53600],
        [-20, -22],
        [-6, 12],
        [8, 0],
        [14, 22],
        [16, 1],
        [-2, -11],
        [-10, -2],
      ],
      [
        [98060, 52906],
        [1, -14],
        [-16, 37],
        [-9, 23],
        [0, 10],
        [5, -6],
        [5, -12],
        [3, -9],
        [11, -29],
      ],
      [
        [98063, 52832],
        [-10, -3],
        [7, 9],
        [2, 11],
        [-1, 36],
        [4, -3],
        [1, -15],
        [1, -21],
        [-4, -14],
      ],
      [
        [98064, 52426],
        [15, -23],
        [-2, -15],
        [-5, -18],
        [-20, -46],
        [-6, 4],
        [19, 42],
        [8, 27],
        [-11, 21],
        [-5, -5],
        [-1, 0],
        [-4, 20],
        [4, 0],
        [8, -7],
      ],
      [
        [98474, 51381],
        [-9, -16],
        [-4, 14],
        [5, 18],
        [-8, 73],
        [-12, 10],
        [-8, 22],
        [4, 0],
        [12, -20],
        [10, -9],
        [6, -48],
        [4, -44],
      ],
      [
        [98547, 51145],
        [2, -30],
        [-7, 4],
        [-2, 11],
        [-2, 30],
        [-7, 30],
        [8, -8],
        [6, -23],
        [2, -14],
      ],
      [
        [98065, 52616],
        [-7, -2],
        [5, 11],
        [31, 13],
        [3, 4],
        [5, -7],
        [0, -7],
        [-2, -3],
        [-16, -1],
        [-12, -6],
        [-7, -2],
      ],
      [
        [97097, 51339],
        [-3, -1],
        [-5, 6],
        [0, 7],
        [4, 4],
        [5, -6],
        [-1, -10],
      ],
      [
        [2163, 49244],
        [1, -4],
        [4, 1],
        [4, 1],
        [-2, -4],
        [-8, -2],
        [-3, 10],
        [4, 8],
        [4, 1],
        [1, -2],
        [-1, -2],
        [-1, 0],
        [-3, -2],
        [0, -5],
      ],
      [
        [2476, 50037],
        [-1, -5],
        [-2, 4],
        [1, 6],
        [2, 6],
        [1, -3],
        [-1, -8],
      ],
      [
        [2435, 49272],
        [-3, -3],
        [-3, 1],
        [-2, 4],
        [0, 6],
        [3, 4],
        [3, -1],
        [3, -6],
        [-1, -5],
      ],
      [
        [1524, 49150],
        [3, -8],
        [0, -4],
        [-1, 1],
        [-5, 2],
        [-1, 5],
        [3, -3],
        [2, 1],
        [-4, 6],
        [-2, 5],
        [-3, 2],
        [0, 3],
        [3, -1],
        [5, -9],
      ],
      [
        [6957, 49488],
        [-1, -4],
        [-4, 5],
        [-6, 9],
        [-6, 9],
        [0, 4],
        [8, 5],
        [10, 5],
        [2, -6],
        [-2, -9],
        [-1, -18],
      ],
      [
        [7838, 45252],
        [-2, -9],
        [-5, 3],
        [-2, 12],
        [-1, 12],
        [2, 11],
        [2, -1],
        [4, -12],
        [2, -16],
      ],
      [
        [6704, 48601],
        [-6, -2],
        [-8, 0],
        [-3, 7],
        [-1, 6],
        [3, 1],
        [2, -2],
        [11, -1],
        [3, -4],
        [-1, -5],
      ],
      [
        [6294, 52912],
        [46, -67],
        [-20, -5],
        [-48, 32],
        [-44, 66],
        [13, 15],
        [7, -24],
        [20, -22],
        [12, 46],
        [7, 11],
        [-35, 48],
        [14, -3],
        [33, -32],
        [-5, -65],
      ],
      [
        [5739, 54103],
        [22, -49],
        [-4, -24],
        [-16, 2],
        [-7, 8],
        [12, 5],
        [2, 9],
        [-6, 14],
        [-7, 10],
        [-5, 0],
        [-2, -20],
        [-8, 16],
        [5, 15],
        [6, 10],
        [8, 4],
      ],
      [
        [2306, 50249],
        [9, -11],
        [7, -15],
        [4, -20],
        [-1, -5],
        [-5, 0],
        [-6, 6],
        [-5, 9],
        [-2, 2],
        [-1, 2],
        [6, -1],
        [6, -9],
        [5, -4],
        [-1, 10],
        [-5, 18],
        [-4, 6],
        [-4, 5],
        [-5, 3],
        [-4, -3],
        [-1, -2],
        [-1, 5],
        [1, 3],
        [1, 4],
        [6, -3],
      ],
      [
        [61387, 50599],
        [-11, -5],
        [6, 33],
        [30, 43],
        [13, -10],
        [2, -10],
        [-1, -8],
        [-5, -9],
        [-34, -34],
      ],
      [
        [59796, 55006],
        [-1, -19],
        [0, -27],
        [7, -16],
        [10, -11],
        [15, 12],
        [12, 16],
        [13, 3],
        [77, -43],
        [12, -38],
        [0, -40],
        [3, -30],
        [-6, -29],
        [-6, -90],
        [2, -82],
        [23, -60],
        [20, -48],
        [16, -67],
        [13, -21],
        [16, -10],
        [53, -3],
        [78, -4],
        [75, -4],
        [7, -2],
        [16, -9],
        [69, -91],
        [63, -82],
        [54, -72],
        [52, -70],
        [51, -68],
        [39, -57],
        [39, -17],
        [62, -8],
        [44, -3],
        [40, -23],
        [60, -22],
        [44, -12],
        [27, -12],
        [75, -14],
        [12, 8],
        [33, 63],
        [37, 101],
        [15, 56],
        [47, 56],
        [84, 77],
        [59, 55],
        [66, 55],
        [30, -48],
        [41, -76],
        [18, -38],
        [15, -17],
        [23, -11],
        [27, 0],
        [15, 2],
        [30, 10],
        [71, 9],
        [41, -1],
      ],
      [
        [61536, 50866],
        [-40, -99],
        [-33, -45],
        [-45, -21],
        [-13, 4],
        [-18, 14],
        [-7, -24],
        [-5, -37],
        [-10, 8],
        [-7, 11],
        [4, -66],
        [5, -32],
        [-7, -44],
        [-22, -38],
        [-2, -32],
        [-47, -85],
        [-66, -10],
        [-35, -42],
        [-16, -34],
        [-12, -75],
        [5, -116],
        [-19, -88],
        [-3, -45],
        [-35, -57],
        [-15, -53],
        [-11, -54],
        [-10, -24],
        [-12, -120],
        [-16, -73],
        [-4, -25],
        [-4, -22],
        [-12, -43],
        [-8, -29],
        [-6, -20],
        [-41, -187],
        [-32, -85],
        [-24, 10],
        [-17, -33],
        [-2, -15],
      ],
      [
        [63939, 77681],
        [-9, -17],
        [-15, 3],
        [-28, 61],
        [8, 62],
        [10, 13],
        [14, 8],
        [2, -13],
        [-20, -28],
        [-2, -35],
        [17, -39],
        [23, -15],
      ],
      [
        [63975, 77748],
        [-10, -8],
        [-6, 37],
        [11, 31],
        [15, 4],
        [-5, -49],
        [-5, -15],
      ],
      [
        [64633, 78001],
        [-5, -6],
        [-18, 16],
        [-12, 26],
        [15, 31],
        [14, -5],
        [9, -33],
        [-3, -29],
      ],
      [
        [64581, 75910],
        [-7, 60],
        [-3, 94],
        [1, 30],
        [15, 79],
        [16, 54],
        [13, 56],
        [5, 73],
        [-11, 118],
        [-13, 26],
        [-16, 9],
        [-17, 2],
        [-30, -5],
        [-14, -9],
        [-25, 40],
        [-30, 6],
        [-16, -11],
        [-16, -6],
        [-17, 11],
        [-15, 24],
        [-10, 25],
        [-7, 29],
        [-23, 57],
        [-24, 31],
        [-28, 7],
        [-46, -1],
        [-15, 3],
        [-1, 33],
        [6, 72],
        [0, 38],
        [-3, 35],
        [-8, 29],
        [-10, 25],
        [-27, 42],
        [-21, 58],
        [-35, 120],
        [-30, 135],
        [-13, 21],
        [-27, 21],
        [-60, 17],
        [-39, 18],
        [-15, 17],
        [-7, 29],
        [1, 32],
        [3, 38],
        [9, 31],
        [31, 25],
        [67, 5],
        [58, -3],
        [53, -56],
        [17, -13],
        [18, -4],
        [37, 18],
        [19, 5],
        [46, -6],
        [-14, 27],
        [-17, 14],
        [-18, -1],
        [-16, 11],
        [-25, 52],
        [-45, 59],
        [-10, 24],
        [-3, 39],
        [8, 34],
        [32, 34],
        [26, 47],
        [13, 63],
        [11, 28],
        [23, 45],
        [34, -8],
        [54, 32],
        [87, -6],
        [105, 9],
        [29, -3],
        [67, -32],
        [39, -14],
        [46, -7],
        [34, 14],
        [-32, 44],
        [-69, 51],
        [-17, 44],
        [31, 119],
        [43, 109],
        [26, 129],
        [-7, 128],
        [-13, 35],
        [4, 42],
        [15, 35],
        [11, 35],
        [-9, 42],
        [-19, 65],
        [-10, 22],
        [-33, 35],
        [-66, 2],
        [-54, 19],
        [-17, -16],
        [-10, -24],
        [-13, -15],
        [-42, -32],
        [-14, -6],
        [-14, 6],
        [-21, 36],
        [-18, -4],
        [-56, 22],
        [-26, 49],
        [-10, 7],
        [-90, 38],
        [-32, 8],
        [-71, -40],
        [-52, -52],
        [-15, -7],
        [-27, -32],
        [-15, -6],
        [-16, 6],
        [-14, -2],
        [-32, -49],
        [-57, -57],
        [-28, -35],
        [-31, -23],
        [-35, -14],
        [-36, -2],
        [-13, -13],
        [-41, -4],
        [-25, -11],
        [-1, -19],
        [5, -43],
        [-21, 15],
        [-22, -30],
        [7, -28],
      ],
      [
        [74256, 80118],
        [-26, 11],
        [-51, 3],
        [-30, -7],
        [-15, -4],
        [-21, -24],
        [-16, -24],
        [-6, -40],
        [8, -45],
        [-11, -94],
        [-15, -36],
        [-32, -61],
        [-19, -14],
        [-30, -11],
        [-30, -18],
        [-58, -18],
        [-63, -9],
        [-22, -13],
        [-16, -42],
        [-12, -35],
        [-7, -27],
        [-17, -88],
        [-11, -78],
        [10, -98],
        [8, -108],
        [-1, -37],
        [16, -56],
        [7, -34],
        [-3, -48],
        [-22, -38],
        [-13, -51],
        [-13, -21],
        [-36, -10],
        [-34, -6],
        [-34, -43],
        [-27, -30],
        [-43, -38],
        [-20, -7],
        [-11, 19],
        [-8, 43],
        [-14, 19],
        [-21, 2],
        [-17, 0],
        [-53, 12],
        [-35, -1],
        [-25, -9],
        [-30, -5],
        [-51, 16],
        [-33, 13],
        [-22, 13],
        [-53, 38],
        [-70, 45],
        [-28, 13],
        [-17, -14],
        [-3, -25],
        [-4, -62],
        [-8, -39],
        [-49, -197],
        [-30, -137],
        [-38, -131],
        [-12, -88],
        [-23, -112],
        [-22, -81],
        [-9, -44],
        [-1, -18],
        [4, -25],
        [35, -28],
        [36, -17],
        [8, -10],
        [4, -29],
        [-1, -47],
        [-7, -44],
        [-11, -35],
        [-10, -17],
        [-12, -2],
        [-23, 23],
        [-20, 25],
        [-16, 7],
        [-40, -13],
        [-37, -20],
        [-12, 0],
        [-22, 12],
        [-21, 25],
        [-9, 49],
        [-19, 22],
        [-24, -22],
        [-75, -37],
        [-82, -44],
        [-51, -23],
        [-21, 3],
        [-40, -5],
        [-35, -12],
        [-26, -17],
        [-52, -24],
        [-47, -16],
        [-30, -36],
        [-22, -35],
        [1, -13],
        [16, -20],
        [18, -17],
        [36, 4],
        [35, 3],
        [30, -22],
        [26, -14],
        [7, -18],
        [-7, -18],
        [-15, -4],
        [-6, -12],
        [3, -17],
        [-10, -43],
        [-5, -65],
        [5, -65],
        [3, -59],
        [-2, -30],
        [-1, -43],
        [11, -29],
        [10, -55],
        [18, -34],
        [27, -119],
        [16, -70],
        [15, -79],
        [-11, -42],
        [1, -25],
        [17, -21],
        [8, -40],
        [8, -25],
        [-2, -24],
        [-8, -10],
        [-37, 15],
        [-31, -24],
        [-32, -25],
        [-4, -13],
        [-1, -14],
        [3, -13],
        [19, -22],
        [26, -14],
        [-2, -22],
        [-32, -10],
        [-48, -34],
        [-13, -36],
        [-11, -39],
        [-1, -24],
        [5, -61],
        [8, -69],
        [13, -72],
        [1, -22],
        [-7, -16],
        [-6, -10],
      ],
      [
        [60770, 71066],
        [20, -127],
        [19, -125],
        [20, -129],
        [20, -125],
        [-21, -12],
        [16, -96],
        [28, 15],
        [29, 11],
        [13, -62],
        [-41, -68],
      ],
      [
        [59708, 68751],
        [9, 76],
        [-3, 40],
      ],
      [
        [59714, 68867],
        [14, 134],
        [8, 63],
        [5, 47],
        [20, 94],
        [-3, 31],
        [5, 109],
        [-2, 21],
        [9, 60],
        [17, 86],
        [17, 74],
        [7, 33],
        [17, 71],
        [16, 86],
        [-9, 47],
        [-2, 10],
        [6, 54],
        [8, 89],
      ],
      [
        [59847, 69976],
        [4, 48],
        [9, 63],
        [17, 54],
        [-8, 126],
        [1, 68],
        [11, 78],
        [-6, 91],
      ],
      [
        [59875, 70504],
        [5, 129],
        [1, 12],
        [6, 16],
        [4, 8],
        [35, 27],
        [14, 3],
      ],
      [
        [87046, 72697],
        [-12, -21],
        [-24, 7],
        [-14, 31],
        [5, 35],
        [25, 27],
        [23, -54],
        [-3, -25],
      ],
      [
        [88428, 73630],
        [-26, -2],
        [-7, 6],
        [16, 14],
        [1, 24],
        [10, 42],
        [0, 13],
        [-21, 2],
        [1, 48],
        [16, 48],
        [43, 75],
        [11, 14],
        [2, -33],
        [-13, -77],
        [-3, -28],
        [34, -6],
        [-22, -93],
        [-42, -47],
      ],
      [
        [87480, 71594],
        [-30, -49],
        [-26, 3],
        [-13, 22],
        [-4, 27],
        [25, 43],
        [21, 60],
        [19, 27],
        [16, 15],
        [12, -1],
        [-27, -84],
        [7, -63],
      ],
      [
        [86283, 69275],
        [-32, -12],
        [-17, 13],
        [-16, 71],
        [30, 45],
        [41, -44],
        [8, -13],
        [-14, -60],
      ],
      [
        [86377, 69352],
        [-24, -6],
        [-1, 34],
        [19, 75],
        [2, 55],
        [18, 70],
        [8, 15],
        [6, 6],
        [6, -22],
        [-7, -85],
        [-18, -65],
        [-9, -77],
      ],
      [
        [86133, 70408],
        [-22, -20],
        [-3, 19],
        [-9, 9],
        [16, 28],
        [0, 12],
        [-11, 19],
        [12, 71],
        [-3, 30],
        [44, 12],
        [8, -29],
        [1, -87],
        [-33, -64],
      ],
      [
        [85910, 71499],
        [-18, -23],
        [-8, 35],
        [8, 102],
        [34, -21],
        [0, -31],
        [-16, -62],
      ],
      [
        [85940, 71632],
        [-6, -28],
        [-19, 19],
        [-8, 18],
        [17, 87],
        [-2, 34],
        [1, 16],
        [35, 45],
        [6, -8],
        [2, -13],
        [-3, -20],
        [2, -43],
        [-27, -71],
        [2, -36],
      ],
      [
        [85740, 70727],
        [10, -15],
        [16, 9],
        [13, 2],
        [9, -8],
        [11, -40],
        [4, -24],
        [-20, -3],
        [-9, -5],
        [-11, -30],
        [-16, 11],
        [-10, 14],
        [-2, 19],
        [5, 70],
      ],
      [
        [86216, 70520],
        [-24, -3],
        [-11, 7],
        [-4, 18],
        [35, 37],
        [26, -6],
        [-11, -34],
        [-11, -19],
      ],
      [
        [89186, 77956],
        [-11, -37],
        [-14, 55],
        [-3, 58],
        [8, 0],
        [16, -9],
        [3, -28],
        [1, -39],
      ],
      [
        [89248, 77833],
        [-19, -4],
        [-23, 24],
        [-3, 30],
        [17, 24],
        [16, -9],
        [16, -31],
        [5, -16],
        [-9, -18],
      ],
      [
        [88744, 76083],
        [-6, -3],
        [-7, 5],
        [-6, 43],
        [5, 23],
        [18, 16],
        [17, 5],
        [-14, -80],
        [-7, -9],
      ],
      [
        [87319, 71706],
        [-5, -11],
        [-5, 3],
        [-18, -26],
        [-4, 25],
        [-13, 17],
        [-2, 13],
        [40, 9],
        [13, -7],
        [-6, -23],
      ],
      [
        [88737, 71846],
        [-3, -27],
        [-15, 6],
        [-7, 18],
        [1, 32],
        [16, 0],
        [8, -29],
      ],
      [
        [85854, 70760],
        [-7, -6],
        [-9, 52],
        [-6, 18],
        [10, 10],
        [21, 94],
        [4, -37],
        [8, -37],
        [8, -6],
        [-8, -27],
        [-12, -10],
        [-9, -51],
      ],
      [
        [85969, 70980],
        [-19, -27],
        [-15, 0],
        [13, 32],
        [2, 15],
        [11, 43],
        [21, 15],
        [9, 2],
        [-17, -44],
        [-5, -36],
      ],
      [
        [86054, 71283],
        [-20, -24],
        [-14, 19],
        [7, 51],
        [5, 17],
        [16, -17],
        [6, -46],
      ],
      [
        [86826, 71494],
        [-8, -23],
        [-24, 7],
        [10, 20],
        [7, 24],
        [6, 5],
        [4, -26],
        [5, -7],
      ],
      [
        [86740, 71396],
        [13, -21],
        [32, 9],
        [4, -6],
        [-9, -19],
        [-14, -19],
        [-26, 14],
        [-16, 1],
        [-2, 31],
        [2, 12],
        [16, -2],
      ],
      [
        [86032, 70078],
        [-9, -10],
        [6, 45],
        [22, 40],
        [2, -26],
        [-21, -49],
      ],
      [
        [89496, 67175],
        [-5, -1],
        [-18, 61],
        [5, 3],
        [10, -9],
        [12, -36],
        [-4, -18],
      ],
      [
        [89950, 77256],
        [35, -3],
        [16, 2],
        [26, -8],
        [106, -88],
        [32, -11],
        [33, -1],
        [23, 7],
        [20, 24],
        [64, 106],
        [67, 97],
        [8, -4],
        [0, -27],
        [-5, -29],
        [-30, -89],
        [-33, -119],
        [-7, -60],
        [11, -59],
        [20, -49],
        [17, -66],
        [19, -93],
        [26, -11],
        [14, -2],
        [27, 28],
        [25, 35],
        [22, 4],
        [22, -6],
        [-30, -24],
        [-28, -30],
        [-24, -57],
        [-9, -10],
        [-28, 3],
        [-16, -2],
        [-32, -24],
        [-29, -27],
        [-27, -33],
        [-30, -17],
        [-32, -4],
        [-49, -27],
        [-32, -2],
        [-59, 23],
        [-29, -6],
        [-63, -53],
        [-58, -77],
        [-50, -86],
        [-42, -104],
        [-17, -54],
        [-10, -60],
        [-2, -40],
        [-4, -38],
        [-9, -27],
        [-12, -22],
        [-35, 13],
        [-57, 55],
        [-110, 81],
        [-117, 123],
        [-66, 62],
        [-123, -19],
        [-117, -118],
        [-11, 10],
        [-44, 81],
        [-22, 32],
        [-26, 9],
        [-19, -1],
        [-19, -6],
        [-26, -41],
        [-10, -30],
        [-7, -34],
        [-2, -24],
        [3, -24],
        [25, -53],
        [31, -40],
        [13, -8],
        [30, 3],
        [14, -4],
        [49, -80],
        [55, -74],
        [12, -25],
        [-20, -26],
        [-22, -13],
        [-26, 3],
        [-25, 10],
        [-44, 32],
        [-18, -27],
        [-29, -56],
        [-16, -60],
        [-13, -28],
        [-32, -36],
        [-34, -19],
        [-17, 6],
        [-14, 23],
        [-7, 27],
        [-4, 32],
        [7, 69],
        [17, 62],
        [7, 63],
        [-14, 89],
        [-9, 18],
        [-36, 53],
        [-17, 50],
        [-4, 63],
        [2, 35],
        [9, 77],
        [9, 39],
        [16, 13],
        [18, 8],
        [28, 27],
        [30, 36],
        [29, 41],
        [29, 51],
        [15, 55],
        [-25, 68],
        [-5, 40],
        [4, 38],
        [26, 20],
        [27, -15],
        [55, -56],
        [11, -5],
        [37, -3],
        [51, -12],
        [30, 3],
        [14, 8],
        [22, 46],
        [10, 59],
        [-4, 75],
        [0, 75],
        [14, 61],
        [43, 99],
        [12, 57],
        [4, 141],
        [16, 62],
        [12, 64],
        [6, 135],
        [-17, 129],
        [-18, 64],
        [-20, 60],
        [3, 58],
        [16, 53],
        [0, 16],
        [4, 14],
        [31, 11],
        [14, 11],
        [14, 26],
        [16, 15],
        [12, -15],
        [10, -26],
        [43, -65],
        [68, -116],
        [80, -176],
        [50, -86],
        [53, -78],
        [59, -79],
        [63, -69],
        [39, -32],
        [29, -52],
        [18, -8],
      ],
      [
        [86437, 71199],
        [37, -17],
        [16, -1],
        [14, 7],
        [23, 23],
        [23, 17],
        [17, -9],
        [14, -20],
        [8, -28],
        [-4, -30],
        [-26, -63],
        [-22, -68],
        [50, -13],
        [50, 1],
        [-12, -42],
        [-1, -36],
        [15, -18],
        [13, -23],
        [-4, -21],
        [-7, -21],
        [27, -32],
        [-2, -21],
        [-7, -22],
        [-68, -145],
        [-20, -73],
        [-13, -81],
        [-13, -59],
        [-9, -61],
        [-8, -66],
        [-12, -68],
        [4, -61],
        [-4, -62],
        [-34, -153],
        [-25, 3],
        [-30, 18],
        [-19, -3],
        [-10, -34],
        [17, -70],
        [-54, -83],
        [-60, -56],
        [-1, 26],
        [6, 20],
        [9, 16],
        [6, 19],
        [9, 66],
        [-5, 65],
        [-18, 83],
        [-1, 29],
        [12, 12],
        [9, 4],
        [4, 11],
        [1, 27],
        [-6, 21],
        [-17, 6],
        [-17, 1],
        [-11, -31],
        [-16, -59],
        [-8, -59],
        [3, -33],
        [7, -29],
        [22, -49],
        [-6, -29],
        [-9, -22],
        [-77, 50],
        [-17, 4],
        [-14, 11],
        [-15, 67],
        [32, 16],
        [9, 8],
        [3, 22],
        [5, 65],
        [-15, 55],
        [-12, 19],
        [-11, 23],
        [7, 46],
        [-4, 58],
        [-1, 81],
        [6, 14],
        [29, 16],
        [21, 44],
        [19, 49],
        [27, 87],
        [22, 94],
        [-21, 5],
        [-18, 17],
        [19, 45],
        [-6, 56],
        [-29, 69],
        [-17, 81],
        [-26, 36],
        [-14, 13],
        [-17, -19],
        [-14, -23],
        [13, -53],
        [-1, -46],
        [2, -47],
        [13, -2],
        [16, 11],
        [13, -8],
        [7, -24],
        [2, -32],
        [-5, -31],
        [-12, -15],
        [-15, 1],
        [-14, 17],
        [-12, 24],
        [-27, 13],
        [-28, -28],
        [-28, -58],
        [-23, -29],
        [11, 42],
        [5, 47],
        [-11, 32],
        [-27, 54],
        [-6, 31],
        [-2, 38],
        [5, 37],
        [28, -43],
        [14, -53],
        [20, -23],
        [25, -1],
        [-19, 79],
        [-7, 20],
        [-28, 35],
        [-37, 59],
        [-23, 29],
        [8, 62],
        [14, 12],
        [12, -3],
        [39, -22],
        [4, 31],
        [-6, 16],
        [-3, 20],
        [26, 26],
        [42, 22],
        [9, 11],
        [8, 22],
        [10, 11],
        [30, 0],
        [25, 21],
        [20, 58],
        [5, 31],
        [8, 27],
        [51, 46],
        [13, 7],
        [34, -6],
        [32, -26],
        [16, -55],
        [13, -60],
        [33, -40],
      ],
      [
        [87321, 71576],
        [38, -24],
        [39, 6],
        [0, -105],
        [5, -35],
        [11, -32],
        [-5, -46],
        [18, -16],
        [-53, -52],
        [-48, -70],
        [-20, -47],
        [-17, -50],
        [-11, -53],
        [-6, -58],
        [-16, 23],
        [-46, 93],
        [-29, 26],
        [-47, 13],
        [-15, -3],
        [-96, -87],
        [-13, -63],
        [-26, -96],
        [-12, -32],
        [-14, -9],
        [-10, -16],
        [-11, -82],
        [-30, -51],
        [-18, -1],
        [-31, 14],
        [-14, -8],
        [19, 81],
        [-30, 9],
        [-30, -1],
        [0, 52],
        [-18, 30],
        [13, 39],
        [0, 31],
        [8, 18],
        [3, 25],
        [-1, 22],
        [-18, 6],
        [-12, 16],
        [2, 57],
        [-10, 2],
        [-26, -10],
        [-54, -44],
        [-15, 0],
        [23, 31],
        [48, 43],
        [21, 25],
        [48, 69],
        [29, 33],
        [16, 58],
        [5, 36],
        [10, 31],
        [9, 49],
        [15, 17],
        [27, 43],
        [15, -4],
        [17, -53],
        [23, -40],
        [16, 3],
        [30, 21],
        [14, 5],
        [34, -3],
        [30, 26],
        [13, 30],
        [4, 37],
        [-11, 63],
        [15, -6],
        [14, 3],
        [33, 40],
        [34, 23],
        [35, 6],
        [40, -22],
        [39, -36],
      ],
      [
        [89229, 75675],
        [11, -11],
        [52, 29],
        [-10, -88],
        [-5, -89],
        [4, -148],
        [4, -67],
        [9, -65],
        [22, -46],
        [29, -33],
        [42, -105],
        [23, -129],
        [16, -63],
        [11, -66],
        [4, -30],
        [0, -30],
        [-3, -41],
        [4, -33],
        [-5, -105],
        [-19, -121],
        [-2, -62],
        [-16, -12],
        [-10, -29],
        [-9, -13],
        [-9, -10],
        [-14, -3],
        [-9, -11],
        [-4, -33],
        [-6, -31],
        [-12, -28],
        [-9, -31],
        [-8, -75],
        [-3, -77],
        [-11, -54],
        [-28, -14],
        [-32, 1],
        [-40, -25],
        [-9, -15],
        [-32, -94],
        [-9, -56],
        [0, -59],
        [9, -73],
        [12, -72],
        [9, -133],
        [-10, -203],
        [-9, -65],
        [-20, -44],
        [-16, -20],
        [-13, -25],
        [-17, -66],
        [-29, -132],
        [-2, -34],
        [0, -34],
        [-8, -45],
        [-5, -44],
        [5, -51],
        [9, -48],
        [38, -123],
        [15, -36],
        [17, -34],
        [-65, -36],
        [-12, -17],
        [-39, -70],
        [-12, -67],
        [1, -73],
        [-7, -27],
        [-10, -23],
        [-12, -15],
        [-43, -34],
        [-28, -33],
        [-27, -52],
        [-11, -28],
        [-21, 9],
        [-13, 24],
        [12, 31],
        [-3, 35],
        [6, 93],
        [-7, 37],
        [22, 28],
        [10, 45],
        [24, 35],
        [16, 32],
        [3, 26],
        [-15, 28],
        [-15, 20],
        [-22, 0],
        [-21, -6],
        [-13, -27],
        [-5, -36],
        [1, -16],
        [-2, -15],
        [-32, -49],
        [4, -52],
        [10, -26],
        [12, -13],
        [-4, -17],
        [-15, -42],
        [-11, -4],
        [-20, 58],
        [-25, 32],
        [-31, 0],
        [-31, -12],
        [-25, -39],
        [-8, -32],
        [-5, -33],
        [2, -81],
        [-10, -68],
        [-19, -59],
        [-10, -22],
        [-23, -40],
        [-17, -5],
        [-12, 18],
        [-9, 28],
        [12, 101],
        [-1, 57],
        [28, 29],
        [-23, 41],
        [-28, 16],
        [-39, -21],
        [-11, -25],
        [-8, -33],
        [-21, -41],
        [-24, -39],
        [-26, -66],
        [-18, -79],
        [-58, 26],
        [-32, 6],
        [-32, -2],
        [-57, 9],
        [-63, -16],
        [-71, -30],
        [4, 22],
        [59, 47],
        [2, 14],
        [-6, 26],
        [-14, 1],
        [-35, -9],
        [-18, 4],
        [-8, 28],
        [-12, 12],
        [-7, -12],
        [2, -54],
        [-9, -7],
        [-11, 14],
        [4, 42],
        [-8, 61],
        [-1, 39],
        [12, 32],
        [-12, 14],
        [-14, -5],
        [-17, -16],
        [-14, -22],
        [-32, -112],
        [-12, -64],
        [23, -52],
        [63, -72],
        [10, -17],
        [1, -31],
        [-8, -32],
        [-17, -15],
        [-69, -24],
        [-59, -46],
        [-18, -47],
        [-54, -183],
        [-43, -125],
        [-62, -43],
        [-67, 39],
        [-16, 43],
        [-14, 54],
        [-25, 48],
        [-22, 53],
        [-13, 63],
        [2, 101],
        [-10, 61],
        [9, 16],
        [37, 37],
        [12, 21],
        [21, 48],
        [8, 27],
        [1, 41],
        [-17, 21],
        [-44, -1],
        [-43, -13],
        [-31, 18],
        [-41, 49],
        [-12, 11],
        [-43, 3],
        [-31, -9],
        [-31, -18],
        [-32, -6],
        [-11, -9],
        [-37, -60],
        [-29, -38],
        [-26, -19],
        [-55, -5],
        [-28, -12],
        [-29, -20],
        [-8, 1],
        [-30, -27],
        [-35, -24],
        [-19, -24],
        [-34, 16],
        [-68, -43],
        [-33, -5],
        [-34, 23],
        [-31, 38],
        [-30, -16],
        [-21, -56],
        [-10, -113],
        [-12, -50],
        [-4, -61],
        [-15, 10],
        [-91, 109],
        [-6, 4],
        [-74, -19],
        [-19, -9],
        [-23, -22],
        [-25, -10],
        [-23, 16],
        [-22, 26],
        [-21, -8],
        [-22, -18],
        [-8, 165],
        [4, 21],
        [14, 29],
        [14, 25],
        [36, 9],
        [36, -9],
        [25, 12],
        [22, 32],
        [23, 47],
        [26, 37],
        [35, 30],
        [34, 34],
        [30, 48],
        [28, 52],
        [26, 39],
        [28, 32],
        [43, 77],
        [57, 87],
        [22, 64],
        [13, 18],
        [49, 35],
        [65, 28],
        [31, -2],
        [30, -56],
        [17, 8],
        [16, 14],
        [34, 8],
        [34, -9],
        [34, 0],
        [33, 7],
        [65, 19],
        [34, 22],
        [33, 29],
        [118, 20],
        [82, 48],
        [12, -3],
        [13, -11],
        [1, -36],
        [-10, -39],
        [10, -24],
        [16, -14],
        [76, -5],
        [22, -8],
        [32, 26],
        [30, 33],
        [31, 44],
        [22, 49],
        [-20, 61],
        [-4, 67],
        [17, 73],
        [24, 62],
        [30, 37],
        [27, 42],
        [54, 121],
        [40, 98],
        [14, 121],
        [-8, 142],
        [34, 106],
        [33, 18],
        [66, 49],
        [34, 14],
        [5, -21],
        [-1, -28],
        [-51, -89],
        [-30, -36],
        [-17, -12],
        [-16, -16],
        [-7, -31],
        [26, -53],
        [7, -38],
        [-2, -37],
        [1, -34],
        [30, -36],
        [34, -12],
        [14, 0],
        [13, 10],
        [39, 89],
        [8, 15],
        [111, 65],
        [55, 50],
        [30, 13],
        [28, 26],
        [64, 100],
        [23, 46],
        [21, 51],
        [18, 59],
        [13, 64],
        [18, 40],
        [101, 95],
        [32, 52],
        [11, 25],
        [12, 73],
        [9, 75],
        [12, 59],
        [16, 56],
        [22, 57],
        [25, 52],
        [15, 54],
        [21, 128],
        [10, 72],
        [7, 25],
        [11, 22],
        [9, 27],
        [7, 31],
        [3, 30],
        [5, 93],
        [-3, 72],
        [-16, 61],
        [-14, 17],
        [-15, 1],
        [-23, -5],
        [-19, 24],
        [4, 22],
        [20, 4],
        [13, 12],
        [9, 21],
        [18, 66],
        [11, 71],
        [1, 31],
        [-14, 58],
        [-11, 69],
        [0, 37],
        [12, 43],
        [17, 34],
        [16, 9],
        [17, 2],
        [15, 13],
        [14, 20],
        [8, 21],
        [13, 59],
        [4, 33],
        [-7, 90],
        [8, 24],
        [11, 15],
        [16, -11],
        [15, -2],
        [19, 3],
        [17, -9],
        [4, -23],
        [11, -151],
        [6, -21],
        [13, -16],
        [14, 3],
        [13, 23],
        [8, 32],
        [17, 6],
        [51, -34],
        [18, 24],
        [11, 37],
        [11, 66],
        [-5, 60],
        [-13, 21],
        [-12, -4],
        [-11, -16],
        [-13, -9],
        [-75, -31],
        [1, 66],
        [16, 99],
        [9, 31],
        [12, 15],
        [32, -17],
        [15, -11],
        [34, -48],
      ],
      [
        [84525, 65965],
        [-16, -91],
        [-14, -13],
        [-14, 7],
        [-14, 51],
        [10, 19],
        [14, -10],
        [11, 4],
        [25, 74],
        [7, -12],
        [-9, -29],
      ],
      [
        [84413, 65829],
        [-18, -8],
        [-21, 10],
        [-19, 2],
        [0, 18],
        [20, 17],
        [0, 25],
        [5, 13],
        [46, -30],
        [-2, -22],
        [-11, -25],
      ],
      [
        [84845, 66096],
        [-24, -15],
        [-25, 8],
        [4, 81],
        [9, -11],
        [5, -28],
        [19, -16],
        [12, -19],
      ],
      [
        [85627, 67196],
        [-27, -27],
        [-10, -31],
        [-25, -11],
        [-24, -44],
        [-23, -9],
        [0, -35],
        [10, -30],
        [-15, -6],
        [-17, -36],
        [-1, -27],
        [6, -22],
        [-1, -11],
        [-20, -31],
        [-22, -2],
        [-1, 35],
        [2, 25],
        [20, 63],
        [0, 73],
        [19, 8],
        [7, 10],
        [29, 52],
        [6, 22],
        [-16, 21],
        [1, 25],
        [4, 11],
        [24, -8],
        [10, -19],
        [5, -2],
        [14, 14],
        [6, 25],
        [27, 50],
        [11, 49],
        [21, -41],
        [-6, -52],
        [-14, -39],
      ],
      [
        [85832, 67811],
        [-12, -11],
        [-15, 15],
        [-5, 66],
        [7, 32],
        [12, 7],
        [11, -57],
        [7, -24],
        [-5, -28],
      ],
      [
        [85958, 68092],
        [-24, -47],
        [-25, 42],
        [-31, 29],
        [15, 7],
        [8, 11],
        [1, 18],
        [20, 27],
        [40, 20],
        [12, 2],
        [14, 19],
        [5, 17],
        [6, 9],
        [25, 24],
        [7, -28],
        [-1, -21],
        [-19, -12],
        [-19, -29],
        [-17, -36],
        [-16, -15],
        [-5, -11],
        [4, -26],
      ],
      [
        [85922, 68032],
        [2, -13],
        [-27, 11],
        [-11, 53],
        [18, -10],
        [6, -18],
        [12, -23],
      ],
      [
        [88844, 70884],
        [-5, -6],
        [-13, 19],
        [-2, 17],
        [2, 10],
        [9, 2],
        [18, -20],
        [-9, -22],
      ],
      [
        [28538, 62475],
        [34, -21],
        [35, -11],
        [15, 0],
        [14, -7],
        [32, -49],
        [26, -28],
        [97, -60],
        [33, -105],
        [6, -33],
        [-25, -19],
        [-32, -7],
        [-30, -1],
        [-28, 20],
        [-12, 16],
        [-29, 7],
        [7, 14],
        [-13, 7],
        [-16, -2],
        [-12, -40],
        [-14, -32],
        [-25, 3],
        [-10, 27],
        [-13, -12],
        [-11, -20],
        [-13, -75],
        [-21, 37],
        [-23, 31],
        [-28, 13],
        [-57, 2],
        [-28, 11],
        [-22, 63],
        [-9, 18],
        [-23, 17],
        [-22, 72],
        [-8, 10],
        [-62, 16],
        [-12, 40],
        [4, 36],
        [20, 44],
        [10, 12],
        [34, -1],
        [32, 13],
        [14, 19],
        [15, 12],
        [117, -32],
        [27, 0],
        [26, -5],
      ],
      [
        [52903, 78839],
        [7, -6],
        [28, -1],
        [31, -4],
        [19, -31],
        [19, -10],
        [28, -3],
        [18, 4],
        [9, 11],
        [11, 36],
        [19, 45],
        [31, 22],
        [52, 5],
        [27, 8],
        [27, -1],
        [20, -7],
        [21, 1],
        [54, 31],
        [56, 25],
        [8, -4],
        [1, -9],
        [-10, -18],
        [-10, -25],
        [7, -29],
        [31, -57],
        [17, -44],
        [17, -33],
        [25, -17],
        [33, -11],
        [28, -4],
        [30, -12],
        [100, -31],
        [51, -8],
        [39, -2],
        [58, -20],
      ],
      [
        [53811, 78103],
        [17, 23],
        [-43, 82],
        [-19, 0],
        [-26, -35],
        [-72, 36],
        [-14, -15],
        [-10, -28],
        [-25, -35],
        [-35, -15],
        [-40, -38],
        [-41, -27],
        [-32, -21],
        [-18, 4],
        [29, 44],
        [-13, 1],
        [-38, -31],
        [-22, -27],
        [-7, -44],
        [-7, -74],
        [17, -19],
        [30, -97],
        [36, -41],
        [-7, -40],
        [-9, -31],
        [-22, -27],
        [-19, 20],
        [-11, 0],
        [-8, -64],
        [16, -168],
        [25, -119],
        [25, -51],
        [57, -81],
        [60, -42],
        [108, -136],
        [59, -42],
        [15, -24],
        [36, -104],
        [31, -121],
        [33, -189],
        [24, -94],
        [48, -105],
        [100, -151],
        [90, -111],
        [84, -68],
        [66, -12],
        [155, 15],
        [27, -6],
        [29, -19],
        [6, -47],
        [-10, -32],
        [-33, -33],
        [-33, -46],
        [-4, -63],
        [31, -44],
        [150, -117],
        [153, -98],
        [48, -50],
        [55, -78],
        [134, -107],
        [22, -52],
        [82, -111],
        [36, -87],
        [7, -67],
        [-17, -67],
        [-8, -48],
        [-14, -48],
        [-35, 18],
        [-39, 49],
        [-59, 197],
        [-108, 20],
        [-22, 15],
        [-39, 34],
        [-2, 22],
        [-10, 28],
        [-9, 10],
        [-42, 6],
        [-28, -32],
        [-34, -76],
        [-38, -109],
        [-39, -160],
        [-2, -64],
        [21, -63],
        [63, -35],
        [48, -56],
        [32, -58],
        [3, -140],
        [14, -80],
        [-21, -45],
        [-41, 11],
        [-54, -29],
        [-39, -51],
        [-16, -49],
        [4, -128],
        [-8, -48],
        [-73, -92],
        [-38, -94],
        [-10, -39],
        [-14, -45],
        [-93, -1],
        [-21, 55],
        [-1, 81],
        [16, 50],
        [34, 24],
        [22, 104],
        [-7, 75],
        [14, 33],
        [12, 23],
        [26, 14],
        [37, 13],
        [3, 105],
        [-28, 48],
        [-10, 66],
        [-14, 124],
        [-47, 157],
        [-25, 140],
        [-19, 69],
        [-30, 37],
        [-54, -1],
        [-27, 10],
        [-96, 98],
        [-6, 15],
        [0, 25],
        [16, 40],
        [-10, 52],
        [-12, 50],
        [-18, 43],
        [-21, 22],
        [-43, -14],
        [-15, -10],
        [-27, 4],
        [-21, -19],
        [-12, -1],
        [33, 75],
        [-9, 17],
        [-33, 31],
        [-45, 5],
        [-12, 4],
        [-8, -20],
        [-8, 11],
        [1, 33],
        [-53, 150],
        [-35, 61],
        [-17, 11],
        [-32, -13],
        [-54, 27],
        [-32, 6],
        [-17, -7],
        [-27, -19],
        [-13, 12],
        [-5, 20],
        [-48, 63],
        [-61, 35],
        [-118, 197],
        [-36, 74],
        [-75, 81],
        [-47, 119],
        [-39, 43],
        [-56, 35],
        [-13, -4],
        [-17, -13],
        [-13, -2],
        [-10, 15],
        [10, 16],
        [12, 7],
        [-4, 45],
        [-64, 118],
        [-38, 37],
        [-10, 24],
        [-8, 32],
        [-8, 20],
        [-18, 13],
        [-15, -3],
        [-21, 8],
        [1, 57],
        [4, 43],
        [-3, 37],
        [-20, 96],
        [-36, 82],
        [-20, 195],
        [-17, 55],
        [-39, 42],
        [-88, 46],
        [-122, 126],
        [-26, 2],
        [-74, 49],
        [-46, 8],
        [-59, -43],
        [-72, -121],
        [-59, -125],
        [-21, -25],
        [-75, -43],
        [-67, -20],
      ],
      [
        [52081, 77054],
        [-1, 32],
        [-2, 24],
        [11, 27],
        [19, 31],
        [17, 40],
        [7, 28],
        [-3, 19],
        [-8, 28],
        [-11, 2],
        [-63, -23],
        [-15, 6],
        [-47, 36],
        [-50, 46],
        [-19, 32],
        [-7, 32],
        [5, 21],
        [-4, 20],
        [-10, 27],
        [9, 31],
        [16, 39],
        [8, 26],
        [13, 7],
        [6, 16],
        [-10, 64],
        [-6, 10],
        [-9, 8],
        [-14, 1],
        [-24, 13],
        [-18, 22],
        [-4, 30],
        [-9, 28],
        [-16, 27],
        [-1, 28],
        [17, 15],
        [25, 1],
        [17, -6],
        [39, 46],
        [14, 4],
        [13, 10],
        [10, 63],
        [9, 19],
        [2, 11],
        [-8, 13],
        [-31, 45],
        [-15, 46],
        [-22, 51],
        [-21, 23],
        [-4, 18],
        [-1, 23],
        [5, 19],
        [37, 31],
        [23, 34],
      ],
      [
        [52887, 76530],
        [9, -22],
        [1, -13],
        [-6, -15],
        [3, -33],
        [-24, 28],
        [-35, -14],
        [-21, 3],
        [-6, 24],
        [5, 15],
        [33, 3],
        [11, 7],
        [20, -3],
        [10, 20],
      ],
      [
        [53871, 75291],
        [-12, -5],
        [-7, 6],
        [-4, 9],
        [5, 22],
        [25, -13],
        [-1, -12],
        [-6, -7],
      ],
      [
        [53347, 73016],
        [-13, -6],
        [-18, 19],
        [-1, 28],
        [3, 9],
        [22, -13],
        [6, -26],
        [1, -11],
      ],
      [
        [54326, 73859],
        [-19, -66],
        [-9, -25],
        [-67, -160],
        [-7, -37],
        [-5, -40],
        [-7, -35],
        [-9, -34],
        [-9, -42],
        [1, -48],
        [4, -23],
        [8, -16],
        [13, -14],
        [10, -22],
        [-16, -21],
        [18, -40],
        [14, -24],
        [2, -24],
        [0, -24],
        [-30, -46],
        [-12, -24],
        [-8, -31],
        [-3, -31],
        [3, -28],
        [-1, -28],
        [-30, 4],
        [-32, 17],
        [-31, -8],
        [-45, 33],
        [-16, 5],
        [-15, 13],
        [-38, 100],
        [-30, 43],
        [-32, 33],
        [-33, 2],
        [-33, -4],
        [-29, 20],
        [-59, 68],
        [-63, 55],
        [-27, 35],
        [-12, 24],
        [-14, 16],
        [-36, 16],
        [-33, 37],
        [-14, 2],
        [-32, -4],
        [-16, 2],
        [-16, 13],
        [-32, 44],
        [-20, 60],
        [-5, 26],
        [14, 69],
        [17, 65],
        [15, 19],
        [17, 13],
        [11, 20],
        [9, 23],
        [32, -68],
        [15, -17],
        [14, 4],
        [26, 24],
        [2, 27],
        [29, 35],
        [36, 0],
        [17, -6],
        [9, -31],
        [14, -9],
        [16, -5],
        [53, -59],
        [15, -9],
        [15, -2],
        [41, 25],
        [31, 9],
        [66, -13],
        [36, 15],
        [25, 1],
        [36, 23],
        [28, 38],
        [15, 9],
        [15, 3],
        [38, -2],
        [38, -9],
        [16, 9],
        [13, 25],
        [16, 11],
        [17, -8],
        [44, 43],
        [19, 2],
        [18, -16],
        [-16, -27],
      ],
      [
        [52675, 75392],
        [14, -37],
        [31, -151],
        [3, -32],
        [-6, -34],
        [-8, -23],
        [-31, -76],
        [5, -63],
        [11, -39],
        [2, -43],
        [-6, -54],
        [-19, -328],
        [-9, -58],
        [-6, -50],
        [-21, -16],
        [-28, 17],
        [-34, 28],
        [-16, -2],
        [-16, -10],
        [-13, 9],
        [-13, 16],
        [-9, -113],
        [-16, -46],
        [-23, -29],
        [-23, -2],
        [-23, 10],
        [-19, 0],
        [-15, 21],
        [-12, 38],
        [-18, 47],
        [-19, 55],
        [-2, 49],
        [-3, 109],
        [5, 24],
        [8, 23],
        [4, 49],
        [-3, 43],
        [6, 15],
        [11, -15],
        [8, 5],
        [-1, 22],
        [3, 40],
        [-15, 34],
        [-24, 11],
        [-2, 35],
        [2, 35],
        [13, 23],
        [4, 30],
        [1, 94],
        [-18, 34],
        [-6, 52],
        [-9, 33],
        [-16, 34],
        [-18, 27],
        [-12, 26],
        [-2, 69],
        [6, 58],
        [6, 24],
        [6, -3],
        [18, -29],
        [15, -6],
        [29, -7],
        [29, 9],
        [35, 26],
        [34, 31],
        [49, 93],
        [30, 18],
        [16, 25],
        [5, 33],
        [13, 8],
        [15, -32],
        [19, -3],
        [29, -26],
        [12, -26],
        [11, -30],
        [10, -13],
        [11, -8],
        [2, -7],
        [-9, -7],
        [-10, -35],
        [6, -10],
        [16, -19],
      ],
      [
        [52355, 74347],
        [-16, -57],
        [-17, 40],
        [-1, 35],
        [3, 10],
        [20, -15],
        [11, -13],
      ],
      [
        [52301, 75483],
        [-9, -26],
        [-13, 2],
        [5, 19],
        [12, 39],
        [15, 13],
        [6, -11],
        [-7, -23],
        [-9, -13],
      ],
      [
        [59875, 70504],
        [-19, 3],
        [-23, 28],
        [-4, 25],
        [-7, 8],
        [-16, 3],
        [-31, 13],
        [-35, -43],
        [-15, -70],
        [-4, -33],
        [-12, -70],
        [5, -42],
        [2, -55],
        [3, -45],
        [-3, -27],
        [-7, -15],
        [2, -10],
        [6, -4],
        [19, 12],
        [21, -12],
        [20, -23],
        [1, -15],
        [-14, -9],
        [-33, -35],
        [-23, -41],
        [-6, -38],
        [-16, -81],
        [2, -16],
        [8, -10],
        [54, 9],
        [48, 32],
        [37, 35],
        [12, -2],
      ],
      [
        [59714, 68867],
        [-19, -44],
      ],
      [
        [59695, 68823],
        [-10, 50],
        [-21, 142],
        [-16, 98],
        [-21, 121],
        [-36, 147],
        [-3, 35],
        [-8, 51],
        [-25, 134],
        [-20, 96],
        [-23, 123],
      ],
      [
        [59512, 69820],
        [29, 48],
        [0, 41],
        [49, 94],
        [-1, 9],
        [-13, 25],
      ],
      [
        [59576, 70037],
        [2, 4],
        [54, 175],
        [35, 173],
        [33, 241],
        [23, 122],
        [20, 81],
        [9, 67],
      ],
      [
        [59512, 69820],
        [-9, 48],
        [-4, 18],
      ],
      [
        [59499, 69886],
        [52, 92],
        [25, 59],
      ],
      [
        [47236, 82899],
        [-1, -17],
        [-21, 21],
        [-10, 22],
        [-56, 11],
        [23, 22],
        [12, -6],
        [40, -1],
        [11, -10],
        [2, -42],
      ],
      [
        [48272, 83000],
        [12, -21],
        [5, -21],
        [-20, -7],
        [-22, 4],
        [-10, -14],
        [-1, -26],
        [8, -34],
        [14, -24],
        [11, -55],
        [10, -60],
        [14, -37],
        [3, -45],
        [-2, -22],
        [3, -40],
        [-6, -14],
        [4, -38],
        [18, -78],
        [7, -43],
        [5, -94],
        [-12, -36],
        [-16, -33],
        [-11, -40],
        [-8, -43],
        [-5, -70],
        [-36, -81],
        [-15, -20],
        [-18, -13],
        [39, -56],
        [-32, -26],
        [-34, -8],
        [-38, 14],
        [-23, -1],
        [-22, -19],
        [-8, -11],
        [-7, 6],
        [-14, 46],
        [-11, -48],
        [-22, -15],
        [-37, 3],
        [-62, -13],
        [-24, -14],
        [-10, -21],
        [-8, -25],
        [-10, -14],
        [-11, -8],
        [-48, -18],
        [-9, -8],
        [-23, -40],
        [-29, -23],
        [-24, -7],
        [-21, 23],
        [-9, 14],
        [-10, 8],
        [-33, -2],
        [10, -7],
        [7, -16],
        [3, -32],
        [-4, -31],
        [-16, -15],
        [-19, -3],
        [-31, -32],
        [-41, -9],
        [-22, -30],
        [-134, -50],
        [-7, 0],
        [-19, 13],
        [-20, 5],
        [-20, -4],
        [-56, -28],
        [-28, 6],
        [35, 69],
        [47, 35],
        [5, 10],
        [-16, 4],
        [-88, -24],
        [-31, -21],
        [-31, -6],
        [14, 32],
        [40, 43],
        [21, 21],
        [14, 8],
        [14, 25],
        [42, 29],
        [-135, -59],
        [-35, 7],
        [-8, 16],
        [-28, -7],
        [-10, 40],
        [40, 61],
        [24, 26],
        [29, 14],
        [27, 20],
        [10, 25],
        [-13, 8],
        [-82, -6],
        [-39, 5],
        [2, 20],
        [8, 22],
        [40, 37],
        [22, 6],
        [20, -4],
        [19, -9],
        [15, -13],
        [46, 8],
        [-19, 23],
        [-3, 49],
        [-15, 16],
        [19, 22],
        [21, 14],
        [36, 47],
        [13, 7],
        [71, 11],
        [76, 24],
        [76, 34],
        [-39, 19],
        [-19, 25],
        [-30, -51],
        [-21, -19],
        [-61, -10],
        [-19, 6],
        [-27, 15],
        [-9, -6],
        [-8, -12],
        [-40, -24],
        [-42, -6],
        [49, 45],
        [62, 76],
        [14, 25],
        [20, 42],
        [-6, 18],
        [-13, 11],
        [45, 87],
        [16, 15],
        [29, 3],
        [22, 14],
        [9, 0],
        [8, 5],
        [19, 26],
        [-29, 16],
        [-29, 9],
        [-92, -9],
        [-12, 2],
        [-12, 8],
        [-7, 11],
        [-6, 30],
        [-6, 6],
        [-21, 0],
        [-20, -9],
        [-15, 1],
        [-14, 13],
        [23, 30],
        [-29, 7],
        [-29, -6],
        [-25, 9],
        [0, 19],
        [11, 19],
        [-15, 18],
        [-3, 23],
        [16, 11],
        [16, -4],
        [35, 17],
        [43, 8],
        [-37, 16],
        [-15, 14],
        [-1, 22],
        [3, 18],
        [44, 32],
        [46, 13],
        [-4, 21],
        [4, 22],
        [-47, 7],
        [-46, -16],
        [5, 43],
        [11, 38],
        [2, 25],
        [-2, 27],
        [-22, -11],
        [-3, 38],
        [-9, 26],
        [-32, -18],
        [1, 35],
        [9, 24],
        [17, 11],
        [17, -5],
        [30, 0],
        [30, 19],
        [43, 4],
        [69, -5],
        [47, -52],
        [12, 9],
        [19, 33],
        [9, 3],
        [71, -14],
        [44, -18],
        [12, 6],
        [-7, 35],
        [-15, 25],
        [19, 33],
        [23, 22],
        [16, 11],
        [36, 14],
        [15, 13],
        [11, 42],
        [16, 35],
        [-90, -18],
        [-85, 41],
        [14, 29],
        [18, 17],
        [31, 13],
        [3, 15],
        [15, 13],
        [26, 33],
        [-9, 43],
        [5, 32],
        [19, 21],
        [6, 30],
        [8, 22],
        [38, 8],
        [37, 20],
        [13, -2],
        [43, 5],
        [15, -8],
        [-4, 36],
        [27, 4],
        [10, -7],
        [5, -25],
        [12, -17],
        [3, -28],
        [-8, -22],
        [-13, -17],
        [12, -17],
        [-19, -31],
        [21, 13],
        [29, 31],
        [-1, 25],
        [-5, 31],
        [-9, 28],
        [4, 31],
        [17, 20],
        [43, 10],
        [-18, 35],
        [16, 3],
        [18, -7],
        [25, -28],
        [26, -21],
        [28, -17],
        [-27, -35],
        [-32, -23],
        [-13, -26],
      ],
      [
        [62434, 73238],
        [0, -4],
        [9, -41],
        [23, -153],
        [13, -19],
        [15, -16],
        [11, -23],
        [4, -23],
        [-1, -35],
        [0, -41],
        [6, -31],
        [9, -24],
        [8, -12],
        [12, -1],
        [14, -6],
        [9, -24],
        [31, -174],
        [3, -22],
        [13, -7],
        [21, 3],
        [21, -18],
        [24, -28],
        [21, -53],
        [15, -9],
        [46, 8],
        [63, -8],
        [29, -28],
        [-3, -16],
        [-23, -19],
        [-39, -22],
        [-12, -38],
        [-7, -48],
        [1, -27],
        [10, -30],
        [28, -60],
        [2, -21],
        [5, -30],
        [5, -21],
        [-6, -40],
        [-25, -27],
        [-34, -30],
        [-67, -132],
        [-5, -29],
        [0, -78],
        [-7, -23],
        [-21, 1],
        [-17, 4],
        [0, -28],
        [-11, -36],
        [-6, -32],
        [25, -75],
        [4, -40],
        [-4, -36],
        [-23, -63],
        [-13, -42],
        [3, -9],
        [18, -17],
        [56, -138],
        [17, -48],
        [24, 13],
        [9, -1],
        [7, -8],
        [4, -16],
        [0, -21],
        [-6, -31],
        [30, -12],
        [11, -32],
        [35, -107],
        [-2, -32],
        [-16, -50],
        [0, -34],
        [3, -30],
        [5, -11],
        [52, -4],
        [22, -12],
        [53, -55],
        [61, -84],
        [50, -69],
        [43, -58],
        [45, 4],
        [13, -11],
        [11, -18],
        [13, -48],
        [26, -109],
        [22, -37],
        [34, -87],
        [32, -82],
        [-21, -111],
        [-20, -116],
        [0, -149],
        [0, -80],
        [43, -3],
        [49, -4],
        [0, -96],
        [0, -96],
        [1, -110],
        [14, -5],
        [23, -23],
        [9, -36],
        [13, -19],
        [14, -3],
        [15, -18],
        [14, -32],
        [5, -24],
        [-4, -16],
        [3, -29],
        [10, -42],
        [13, -19],
        [18, -24],
      ],
      [
        [63484, 69102],
        [-25, -14],
        [-28, 11],
        [-59, 48],
        [-19, 2],
        [-25, -19],
        [-1, -16],
      ],
      [
        [65607, 67350],
        [-26, -69],
        [-39, -58],
        [-17, 18],
        [-12, 0],
        [-28, -23],
        [-20, -3],
        [-37, -40],
        [-33, -20],
        [-23, 2],
        [-8, 4],
        [-5, 27],
        [0, 10],
        [15, -5],
        [51, 36],
        [64, 58],
        [6, 26],
        [-10, 43],
        [3, 9],
        [41, -21],
        [46, 42],
        [39, 11],
        [19, -29],
        [-26, -18],
      ],
      [
        [67017, 72361],
        [5, -38],
        [-1, -23],
        [-9, -23],
        [-6, -29],
        [-7, -36],
        [-3, -28],
        [-13, -14],
        [-11, -9],
        [1, -36],
        [6, -31],
        [6, -36],
        [-7, -25],
        [-5, -28],
        [-10, -46],
        [3, -38],
        [-11, -33],
        [-14, -28],
        [-9, -23],
        [-2, -33],
        [-10, -11],
        [-19, -27],
        [-12, -19],
        [-18, -5],
        [-3, -16],
        [2, -15],
        [8, -9],
        [11, -33],
        [24, -57],
        [-68, -7],
        [-21, -51],
        [-23, -72],
        [11, -145],
        [-11, -75],
        [7, -42],
        [17, -29],
        [23, -16],
        [42, -1],
        [28, -12],
        [3, -19],
        [-16, -28],
        [-26, -54],
        [-14, -23],
        [-43, -107],
        [0, -45],
        [4, -37],
        [19, -116],
        [18, -112],
        [33, -202],
        [0, -46],
        [-11, -105],
        [0, -63],
        [4, -82],
        [-3, -43],
        [8, -95],
        [9, -7],
        [72, -18],
        [65, -17],
        [87, -23],
        [27, -56],
        [16, -122],
        [-1, -92],
        [-7, -47],
        [-63, -134],
        [-63, -136],
        [-63, -135],
        [-73, -156],
      ],
      [
        [67107, 66360],
        [-15, -4],
        [-12, -24],
        [-22, -30],
        [-47, 23],
        [-37, 24],
        [-124, 57],
        [-13, 28],
        [-8, 48],
        [-21, 13],
        [-31, -72],
        [-104, 42],
        [-36, -13],
        [-21, 22],
        [-57, 2],
        [-44, 45],
        [-64, -31],
        [-50, -6],
        [-69, 79],
        [-74, 22],
        [-60, -7],
        [-31, 6],
        [-50, 29],
        [-24, 29],
        [-39, -22],
        [-18, 41],
        [-110, 38],
        [-21, 74],
        [-15, 68],
        [-1, 70],
        [-27, 123],
        [-9, 177],
        [-10, 70],
        [-15, 60],
        [-20, 51],
        [-27, 55],
        [-24, 22],
        [-103, 42],
        [-20, -6],
        [-46, -27],
        [-49, -61],
        [-81, -34],
        [-17, -26],
        [-20, -59],
        [-26, -35],
        [-36, 9],
        [-39, -35],
        [-72, -97],
        [-38, -29],
        [-32, 2],
        [-34, 46],
        [-76, 62],
        [-49, 20],
        [-69, -14],
        [-32, 11],
        [-56, 72],
        [-14, 53],
        [-32, 35],
        [-99, 79],
        [-81, 105],
        [-15, 39],
        [-10, 59],
        [-35, 71],
        [-79, 58],
        [-45, 61],
        [-52, 14],
        [-49, -2],
        [-21, 11],
        [-20, 27],
        [-67, 127],
        [0, 51],
        [-41, 124],
        [-10, 45],
        [-9, 123],
        [-11, 32],
        [-43, 51],
        [-7, 33],
        [10, 44],
        [0, 34],
        [-23, 31],
        [-33, 17],
        [-8, 38],
        [6, 73],
        [-5, 47],
        [-30, 73],
        [-43, 76],
        [-44, 111],
        [-17, 28],
        [-11, 73],
        [-16, 87],
        [-24, 6],
        [-119, -104],
        [-35, 59],
        [-104, 102],
        [-8, 15],
        [-7, 23],
        [13, 14],
        [13, 5],
        [26, -18],
        [16, 21],
        [-6, 35],
        [-26, 21],
        [-36, -2],
        [10, -32],
        [-34, -30],
        [-7, -41],
        [5, -49],
        [3, -70],
        [-14, -33],
        [-10, -16],
        [-45, -4],
        [-21, -31],
        [-14, -7],
      ],
      [
        [62449, 74683],
        [5, -13],
        [45, -118],
        [20, -35],
        [12, -29],
        [8, -33],
        [13, -23],
        [18, -12],
        [23, -32],
        [15, -25],
        [25, -51],
        [26, -20],
        [96, -37],
        [54, -17],
      ],
      [
        [62809, 74238],
        [15, -5],
        [41, 25],
        [48, -4],
      ],
      [
        [62913, 74254],
        [18, -1],
        [64, 105],
        [19, 36],
        [38, 18],
        [21, 42],
        [34, 50],
        [42, 48],
        [38, 43],
        [30, 26],
        [53, 61],
        [33, 21],
        [29, -1],
        [43, -71],
        [36, -65],
        [11, -28],
        [-18, -25],
        [-34, -25],
        [-6, -18],
        [-2, -23],
        [1, -22],
        [5, -18],
        [41, -42],
        [5, -23],
        [0, -23],
        [-5, -15],
        [-9, -8],
        [-29, -12],
        [-24, -13],
        [-9, -14],
        [-7, -16],
        [1, -17],
        [7, -20],
        [51, -55],
        [5, -20],
        [10, -27],
        [13, -17],
        [21, -4],
        [10, -11],
        [48, -101],
        [12, -7],
        [57, 22],
        [8, -1],
      ],
      [
        [63574, 73983],
        [1, -25],
        [8, -143],
        [7, -74],
        [9, -72],
        [16, -66],
        [18, -62],
        [25, -39],
        [56, -47],
        [27, -13],
        [71, -9],
        [71, -21],
        [41, -22],
        [13, -15],
        [11, -23],
        [34, -110],
        [54, -78],
        [110, -117],
        [53, -39],
        [179, -74],
        [119, 4],
        [328, 142],
        [110, 36],
        [41, 0],
        [-25, -28],
        [-41, -17],
        [25, -20],
        [38, -3],
        [18, 3],
        [13, 18],
        [2, 30],
        [-2, 30],
        [-18, 132],
        [-10, 93],
      ],
      [
        [77077, 52687],
        [61, -162],
        [24, -22],
        [33, -74],
        [8, -25],
        [-9, -52],
        [-7, -147],
        [-15, -37],
        [-38, 18],
        [-1, 26],
        [-22, 111],
        [-39, 62],
        [-16, 3],
        [-10, 63],
        [-20, 76],
        [-60, 137],
        [46, -1],
        [22, 33],
        [5, 27],
        [4, 7],
        [34, -43],
      ],
      [
        [77545, 50818],
        [-26, -3],
        [-55, 69],
        [-13, 31],
        [-3, 42],
        [-52, 159],
        [-7, 37],
        [20, 131],
        [54, 31],
        [18, -22],
        [6, -59],
        [30, -106],
        [10, -57],
        [8, -26],
        [3, -19],
        [-2, -14],
        [22, -68],
        [15, -39],
        [2, -64],
        [-30, -23],
      ],
      [
        [82399, 46881],
        [-35, -120],
        [13, -19],
        [7, -18],
        [-58, -25],
        [-24, 13],
        [-14, -3],
        [-59, 22],
        [-42, 28],
        [-5, 21],
        [3, 26],
        [13, -8],
        [32, -4],
        [13, 11],
        [0, 77],
        [-5, 100],
        [44, 82],
        [24, 33],
        [27, 20],
        [68, -46],
        [11, -12],
        [9, -18],
        [4, -30],
        [-26, -130],
      ],
      [
        [82068, 47145],
        [28, -31],
        [40, -89],
        [3, -25],
        [-12, -24],
        [-28, -38],
        [-63, -58],
        [-10, -28],
        [-14, -54],
        [-3, -23],
        [-4, -13],
        [-7, -9],
        [-14, -8],
        [-15, 12],
        [13, 34],
        [1, 42],
        [-10, 39],
        [-14, 32],
        [-29, 44],
        [-30, 40],
        [-31, 19],
        [-33, 9],
        [-12, 19],
        [-19, 49],
        [-6, 27],
        [-3, 28],
        [2, 27],
        [8, 1],
        [32, -6],
        [59, -32],
        [30, -2],
        [16, 7],
        [43, 62],
        [11, -1],
        [41, -27],
        [30, -23],
      ],
      [
        [79456, 50881],
        [10, -40],
        [13, -35],
        [10, -39],
        [13, -185],
        [43, -159],
        [126, -63],
        [-21, -25],
        [-10, -23],
        [-8, -27],
        [-18, -110],
        [1, -24],
        [11, -37],
        [3, -41],
        [-16, 0],
        [-17, 10],
        [-15, 15],
        [-13, 20],
        [-14, 16],
        [-15, 10],
        [-26, 32],
        [-34, 22],
        [-36, 17],
        [-17, 47],
        [-8, 58],
        [9, 86],
        [-9, 24],
        [-13, 21],
        [-15, 62],
        [-6, 73],
        [-22, 28],
        [-30, 17],
        [-13, 14],
        [-58, -26],
        [-14, 6],
        [-12, 20],
        [-32, 21],
        [1, 40],
        [15, 32],
        [35, 33],
        [16, 27],
        [3, 36],
        [-6, 26],
        [3, 28],
        [11, 27],
        [13, 21],
        [34, 27],
        [16, -48],
        [7, -40],
        [10, -30],
        [14, 42],
        [-9, 72],
        [27, 16],
        [26, 1],
        [19, -20],
        [13, -31],
        [5, -44],
      ],
      [
        [84216, 49221],
        [6, -124],
        [-2, -32],
        [-15, 47],
        [-6, 10],
        [-4, -10],
        [-6, -5],
        [-8, 0],
        [-10, -48],
        [-1, -45],
        [-8, -30],
        [-4, -101],
        [3, -28],
        [12, 14],
        [7, 3],
        [27, -39],
        [15, -28],
        [-4, -34],
        [-19, -35],
        [-21, -15],
        [-16, 15],
        [-5, -7],
        [-9, -18],
        [-8, -24],
        [3, -24],
        [-19, -68],
        [-10, -19],
        [-22, 21],
        [-14, -18],
        [-11, 1],
        [-16, 69],
        [0, 32],
        [15, 36],
        [1, 26],
        [7, 29],
        [17, 40],
        [10, 29],
        [0, 19],
        [8, 72],
        [2, 30],
        [5, 32],
        [8, 66],
        [1, 122],
        [26, 101],
        [25, 28],
        [11, 5],
        [-2, -27],
        [31, -68],
      ],
      [
        [84067, 48807],
        [-7, -38],
        [-15, -30],
        [-13, -2],
        [-12, 6],
        [-23, 26],
        [-6, -27],
        [-18, 1],
        [-6, 35],
        [12, 105],
        [19, 39],
        [-2, 41],
        [-15, 88],
        [10, 46],
        [43, 34],
        [37, 42],
        [12, 9],
        [11, -32],
        [5, -149],
        [-40, -118],
        [8, -76],
      ],
      [
        [80087, 53968],
        [-38, -21],
        [-22, 30],
        [24, 36],
        [8, 3],
        [6, 7],
        [2, 15],
        [-43, 24],
        [-12, 21],
        [-12, 54],
        [0, 34],
        [55, 91],
        [13, 10],
        [2, -38],
        [38, -95],
        [2, -64],
        [-1, -23],
        [-22, -84],
      ],
      [
        [80057, 50116],
        [-5, -61],
        [-6, -23],
        [-24, -30],
        [-7, -18],
        [-22, 3],
        [-3, 32],
        [-7, 21],
        [-23, 25],
        [-6, -6],
        [-4, -37],
        [-45, -26],
        [-13, -2],
        [6, 49],
        [-11, 38],
        [-1, 47],
        [-2, 21],
        [-6, 12],
        [11, 32],
        [-1, 37],
        [12, 39],
        [7, 95],
        [47, 21],
        [10, -17],
        [56, -21],
        [39, -58],
        [21, -77],
        [-23, -96],
      ],
      [
        [87606, 51467],
        [59, -22],
        [21, 1],
        [21, -25],
        [26, 12],
        [14, -8],
        [49, -88],
        [24, -58],
        [35, -50],
        [26, -17],
        [-19, -45],
        [-39, -24],
        [-15, -1],
        [-30, 27],
        [-25, -5],
        [-21, 34],
        [-4, 52],
        [-21, 119],
        [-29, -34],
        [-33, 54],
        [-9, 1],
        [-3, -9],
        [-14, 19],
        [-12, 37],
        [-1, 30],
      ],
      [
        [87631, 50926],
        [110, -29],
        [29, 4],
        [63, -12],
        [52, -38],
        [91, -7],
        [28, -12],
        [21, -26],
        [-51, -22],
        [-24, -20],
        [-45, -10],
        [-38, 10],
        [-27, -12],
        [-10, 20],
        [-39, 20],
        [-51, 41],
        [-105, 49],
        [-5, 30],
        [1, 14],
      ],
      [
        [86336, 51840],
        [48, -24],
        [11, 4],
        [70, -64],
        [11, -31],
        [-4, -21],
        [10, -29],
        [-22, -43],
        [-11, -5],
        [-12, 16],
        [-22, 10],
        [-25, -18],
        [-17, 13],
        [-14, 40],
        [-24, 24],
        [-35, 84],
        [-17, -3],
        [5, -33],
        [14, -21],
        [20, -64],
        [11, -6],
        [11, 2],
        [16, -27],
        [-1, -41],
        [-40, -16],
        [-14, 30],
        [-3, 55],
        [-23, -19],
        [-9, -19],
        [-7, -2],
        [-15, 57],
        [-43, 3],
        [-29, 30],
        [14, 32],
        [2, 30],
        [19, 17],
        [19, -15],
        [19, 22],
        [14, -6],
        [10, 14],
        [38, 9],
        [25, 15],
      ],
      [
        [85681, 53025],
        [-44, -10],
        [-10, 27],
        [-12, 124],
        [31, 99],
        [40, 58],
        [27, 15],
        [9, 1],
        [24, -71],
        [-18, -144],
        [-21, -73],
        [-26, -26],
      ],
      [
        [85597, 50886],
        [-17, -23],
        [-8, -7],
        [-41, 16],
        [-48, -3],
        [-50, -22],
        [-29, 18],
        [-18, 30],
        [1, 32],
        [17, 78],
        [37, 60],
        [16, 10],
        [26, -16],
        [45, -45],
        [36, -54],
        [32, -41],
        [1, -33],
      ],
      [
        [85014, 50431],
        [-4, -11],
        [-17, 31],
        [-11, 88],
        [-10, 24],
        [-11, 83],
        [3, 24],
        [14, 35],
        [11, 0],
        [8, -21],
        [-5, -90],
        [25, -114],
        [-3, -49],
      ],
      [
        [85006, 50812],
        [85, -19],
        [-12, -21],
        [-92, -33],
        [-33, 6],
        [-100, -20],
        [-12, 1],
        [-2, 32],
        [-11, 23],
        [16, 20],
        [21, 4],
        [55, -7],
        [85, 14],
      ],
      [
        [84713, 50860],
        [26, -20],
        [9, 0],
        [9, 24],
        [5, 4],
        [12, -12],
        [2, -39],
        [17, 6],
        [13, -14],
        [4, -9],
        [-1, -39],
        [-50, -6],
        [-36, -32],
        [-48, 28],
        [-54, -48],
        [-33, -16],
        [-28, 1],
        [-25, 84],
        [14, 99],
        [11, 16],
        [18, 8],
        [50, 5],
        [85, -40],
      ],
      [
        [86478, 47235],
        [-4, -7],
        [-35, 8],
        [-19, 0],
        [2, 43],
        [-10, 33],
        [14, 48],
        [0, 56],
        [15, 7],
        [2, 32],
        [17, 84],
        [10, 19],
        [15, 7],
        [17, 49],
        [10, 15],
        [10, 37],
        [15, 17],
        [-2, 32],
        [9, 17],
        [23, 13],
        [16, -15],
        [10, -33],
        [-26, -40],
        [13, -99],
        [-19, -108],
        [-12, -33],
        [-23, -27],
        [-6, -27],
        [-27, -53],
        [-8, -46],
        [-2, -19],
        [-5, -10],
      ],
      [
        [85222, 47426],
        [3, -28],
        [0, -13],
        [-33, -9],
        [-32, -31],
        [-17, -36],
        [-13, -46],
        [-44, 19],
        [-39, 3],
        [-18, 16],
        [-19, -1],
        [-24, -14],
        [-35, -40],
        [-8, -3],
        [3, 60],
        [10, 37],
        [36, 88],
        [31, -19],
        [36, -6],
        [40, 18],
        [29, 39],
        [40, 21],
        [33, -52],
        [21, -3],
      ],
      [
        [84603, 47153],
        [7, -35],
        [22, 20],
        [21, 4],
        [47, -3],
        [35, -8],
        [21, -15],
        [2, -70],
        [-10, -15],
        [-181, -53],
        [-18, 17],
        [-7, 17],
        [20, 52],
        [-9, 25],
        [10, 40],
        [22, 27],
        [18, -3],
      ],
      [
        [86389, 51085],
        [-10, -16],
        [-34, 15],
        [-17, 35],
        [-12, 48],
        [-8, 39],
        [-2, 32],
        [-10, 38],
        [1, 14],
        [62, 40],
        [12, -15],
        [26, -1],
        [12, -29],
        [-8, -127],
        [-12, -73],
      ],
      [
        [76803, 54855],
        [34, -5],
        [63, 31],
        [35, -3],
        [32, -23],
        [30, -13],
        [72, 17],
        [14, -5],
        [13, -13],
        [11, -20],
        [33, -75],
        [56, -92],
        [16, -59],
        [9, -67],
        [6, -15],
        [63, -127],
        [7, -54],
        [-9, -73],
        [19, -59],
        [61, -55],
        [36, -40],
        [8, -24],
        [5, -29],
        [21, -44],
        [25, -28],
        [78, -74],
        [103, -156],
        [59, -74],
        [48, -112],
        [18, -54],
        [14, -58],
        [29, -84],
        [50, -105],
        [13, -31],
        [13, -46],
        [16, -43],
        [19, -39],
        [22, -30],
        [22, -10],
        [37, -75],
        [20, -24],
        [-3, 59],
        [-17, 51],
        [0, 32],
        [3, 27],
        [14, 24],
        [16, 6],
        [31, -21],
        [49, -89],
        [21, -52],
        [16, -72],
        [13, -75],
        [20, -37],
        [27, -13],
        [31, -5],
        [28, -23],
        [65, -104],
        [22, -48],
        [16, -57],
        [12, -68],
        [7, -71],
        [4, -16],
        [42, -85],
        [22, -36],
        [27, -18],
        [78, -19],
        [28, -30],
        [23, -49],
        [10, -50],
        [-17, -44],
        [-61, -68],
        [-66, -47],
        [64, 16],
        [32, 20],
        [30, 31],
        [30, 39],
        [46, 55],
        [17, 11],
        [21, -4],
        [18, -16],
        [28, -53],
        [26, -57],
        [20, -66],
        [12, -73],
        [-22, -39],
        [-33, -28],
        [-45, -71],
        [-4, -28],
        [9, -18],
        [-11, -52],
        [25, -32],
        [4, -28],
        [-22, -39],
        [2, -24],
        [26, -103],
        [13, -24],
        [40, -52],
        [60, -53],
        [34, -25],
        [38, -19],
        [17, 1],
        [28, 8],
        [6, -20],
        [12, -102],
        [6, -64],
        [9, -137],
        [11, -57],
        [-1, -69],
        [15, -59],
        [30, -38],
        [32, -31],
        [15, -30],
        [0, -45],
        [-5, -36],
        [-11, -28],
        [-33, -60],
        [-6, -25],
        [-5, -65],
        [6, -30],
        [13, -2],
        [10, 16],
        [40, 87],
        [11, 16],
        [15, 12],
        [15, 8],
        [72, 0],
        [31, -13],
        [27, -29],
        [25, -36],
        [88, -228],
        [40, -126],
        [3, -31],
        [1, -33],
        [-7, -25],
        [-37, -86],
        [-4, -23],
        [-12, -94],
        [2, -67],
        [13, -29],
        [9, -30],
        [0, -28],
        [-25, -139],
        [-2, -23],
        [15, -226],
        [1, -61],
        [-3, -77],
        [2, -124],
        [-19, -384],
        [-4, -23],
        [-15, -59],
        [-20, 1],
        [-16, 10],
        [-12, 22],
        [-6, 28],
        [-9, 23],
        [-48, 71],
        [-13, -12],
        [-49, -88],
        [-13, -13],
        [-16, 11],
        [-25, 26],
        [-81, 93],
        [-5, -30],
        [-1, -40],
        [16, -101],
        [2, -44],
        [-15, -9],
        [-8, 2],
        [-33, 59],
        [-31, 65],
        [-35, 87],
        [-26, 42],
        [-23, 46],
        [-66, 177],
        [-17, 27],
        [-101, 124],
        [-20, 30],
        [-26, 51],
        [-28, 46],
        [-61, 72],
        [-106, 184],
        [-46, 105],
        [-51, 170],
        [-17, 43],
        [-86, 128],
        [-47, 77],
        [-20, 44],
        [-45, 155],
        [-14, 52],
        [-16, 46],
        [-28, 37],
        [-24, 44],
        [-49, 140],
        [-15, 55],
        [-11, 61],
        [2, 120],
        [-103, 366],
        [-26, 114],
        [-23, 158],
        [-6, 16],
        [-56, 142],
        [-19, 45],
        [-24, 43],
        [-20, 50],
        [-38, 162],
        [-15, 45],
        [-20, 33],
        [-73, 61],
        [-27, 34],
        [-22, 48],
        [-13, 62],
        [-14, 131],
        [-35, 199],
        [-38, 267],
        [-26, 119],
        [-30, 94],
        [-9, 21],
        [-133, 169],
        [-22, 25],
        [-24, 15],
        [-34, 11],
        [-27, 43],
        [-10, 78],
        [-6, 105],
        [-7, 63],
        [-7, 35],
        [-56, 75],
        [-21, 58],
        [-18, 65],
        [-17, 50],
        [-61, 172],
        [-21, 45],
        [-26, 32],
        [-76, 33],
        [-23, 29],
        [-37, 98],
        [-22, 50],
        [-67, 109],
        [-114, 230],
        [-23, 57],
        [-18, 60],
        [-14, 64],
        [-48, 177],
        [3, 36],
        [8, 37],
        [-1, 31],
        [-6, 31],
        [1, 27],
        [15, 16],
        [32, 21],
        [34, -2],
        [31, -9],
        [30, -17],
        [29, -38],
        [51, -94],
        [30, -33],
        [33, -15],
        [67, -22],
      ],
      [
        [84106, 46882],
        [-40, -20],
        [-24, -20],
        [-23, -26],
        [-15, -5],
        [-27, -2],
        [-37, 5],
        [-26, -9],
        [-71, -66],
        [-28, -6],
        [-24, -16],
        [-8, 26],
        [-10, 19],
        [-24, 4],
        [-24, -1],
        [-24, -59],
        [-38, 13],
        [-15, -6],
        [-14, -12],
        [-14, -5],
        [-15, 4],
        [-56, 45],
        [-64, 27],
        [-64, -10],
        [-55, 25],
        [-30, -19],
        [-29, -28],
        [-8, 29],
        [-11, 26],
        [-9, 37],
        [0, 44],
        [3, 30],
        [8, 27],
        [5, 29],
        [3, 31],
        [12, -15],
        [12, 5],
        [38, 34],
        [37, 50],
        [34, 19],
        [20, 5],
        [16, -10],
        [17, 4],
        [18, 11],
        [28, -39],
        [11, -8],
        [38, -3],
        [34, -22],
        [30, -34],
        [44, -31],
        [27, -42],
        [20, -15],
        [15, -5],
        [13, 6],
        [18, 28],
        [20, 12],
        [18, 0],
        [32, 7],
        [14, 7],
        [15, 15],
        [15, -9],
        [13, -15],
        [54, -74],
        [17, -2],
        [30, 16],
        [10, 20],
        [4, 31],
        [9, 25],
        [11, 22],
        [14, 17],
        [40, 28],
        [28, 28],
        [19, 48],
        [-44, 20],
        [9, 34],
        [15, 20],
        [20, -7],
        [17, -27],
        [7, -102],
        [-14, -15],
        [-8, -15],
        [-6, -20],
        [-26, -38],
        [10, -46],
        [-7, -20],
        [-10, -9],
      ],
      [
        [83336, 46442],
        [13, -25],
        [45, -50],
        [7, -21],
        [3, -35],
        [9, -26],
        [21, -4],
        [22, 5],
        [16, -16],
        [15, -26],
        [21, -50],
        [19, -56],
        [23, -31],
        [14, -46],
        [-8, -41],
        [-30, -57],
        [-16, -12],
        [-21, -4],
        [-35, -34],
        [-12, 18],
        [-39, 12],
        [-30, 24],
        [-26, 45],
        [-15, 47],
        [-19, 43],
        [-33, 28],
        [-58, 83],
        [-37, 7],
        [-15, -6],
        [-15, 0],
        [-77, 37],
        [-12, 22],
        [-9, 28],
        [-9, 27],
        [-5, 31],
        [10, 27],
        [10, 19],
        [43, 32],
        [31, 10],
        [35, -2],
        [53, 10],
        [51, -16],
        [15, 12],
        [25, 34],
        [9, -12],
        [11, -31],
      ],
      [
        [82844, 47051],
        [14, -22],
        [13, 2],
        [26, 34],
        [16, 13],
        [17, 1],
        [17, -6],
        [16, -25],
        [6, -40],
        [6, -13],
        [10, 49],
        [13, 14],
        [14, 8],
        [22, -3],
        [17, -23],
        [16, -69],
        [-1, -60],
        [6, -22],
        [11, -17],
        [8, -23],
        [-7, -24],
        [-7, -11],
        [-21, -11],
        [-9, 4],
        [-9, 17],
        [-10, 6],
        [-23, -6],
        [-21, -13],
        [3, -22],
        [17, -10],
        [5, -11],
        [-1, -14],
        [-7, -3],
        [-22, 19],
        [-15, -3],
        [-54, -26],
        [-14, 1],
        [-9, 24],
        [1, 63],
        [-6, 17],
        [-40, -77],
        [-12, -19],
        [-17, -9],
        [-16, 3],
        [-59, -46],
        [-18, 6],
        [-18, 0],
        [-62, -50],
        [-33, -14],
        [-17, -1],
        [-17, 4],
        [-15, -4],
        [-14, -21],
        [-28, -17],
        [-28, 13],
        [-25, 18],
        [-23, 23],
        [-5, 29],
        [1, 35],
        [10, 48],
        [-7, 84],
        [5, 39],
        [9, 38],
        [15, 14],
        [18, 2],
        [31, 34],
        [28, 45],
        [16, -4],
        [37, -31],
        [22, -4],
        [37, 5],
        [15, -19],
        [6, -44],
        [8, -16],
        [11, -11],
        [26, -74],
        [24, 4],
        [21, -14],
        [38, 45],
        [28, -1],
        [8, 34],
        [-17, 37],
        [-20, 31],
        [-11, 6],
        [-12, -2],
        [-11, 5],
        [-45, 67],
        [-14, 37],
        [-8, 43],
        [5, 31],
        [31, 28],
        [15, 7],
        [55, -19],
        [9, -16],
        [14, -68],
        [11, -29],
      ],
      [
        [84691, 52416],
        [-53, -98],
        [-17, -47],
        [-14, -51],
        [-21, -56],
        [-24, -50],
        [-12, -15],
        [-29, -27],
        [-17, -10],
        [-32, -4],
        [-97, -39],
        [-31, -5],
        [-32, 2],
        [-60, 10],
        [-12, 5],
        [-24, 51],
        [-27, 40],
        [-24, 5],
        [-24, -4],
        [-175, -3],
        [-61, -8],
        [-61, -18],
        [-33, 8],
        [-33, 21],
        [-24, 7],
        [-25, -2],
        [-115, -31],
        [-29, 3],
        [-58, 39],
        [-34, 8],
        [-33, -10],
        [-31, -36],
        [-11, -23],
        [-32, -81],
        [-18, -58],
        [-14, -73],
        [-11, -75],
        [-7, -61],
        [0, -64],
        [5, -72],
        [9, -71],
        [10, -55],
        [39, -126],
        [8, -17],
        [44, -36],
        [25, -45],
        [25, -126],
        [17, -65],
        [17, 0],
        [19, 4],
        [33, -8],
        [33, -17],
        [32, 39],
        [17, 73],
        [18, 55],
        [43, 103],
        [25, 48],
        [15, 15],
        [16, -6],
        [13, -28],
        [16, -22],
        [32, -11],
        [33, 7],
        [34, 34],
        [12, 20],
        [11, 26],
        [29, 22],
        [69, 0],
        [36, -8],
        [64, 9],
        [-1, 19],
        [-12, 20],
        [-4, 16],
        [12, 11],
        [41, 23],
        [42, 17],
        [31, -12],
        [27, -33],
        [11, -34],
        [4, -41],
        [-10, -105],
        [-5, -25],
        [-22, -12],
        [-20, 14],
        [-21, 54],
        [-28, 20],
        [-41, -16],
        [-14, -16],
        [-13, -22],
        [-23, -56],
        [-19, -64],
        [-41, -100],
        [-48, -86],
        [-23, -33],
        [-26, -23],
        [-83, -57],
        [-22, -42],
        [-17, -55],
        [-19, -19],
        [-22, -6],
        [-16, 10],
        [-33, 31],
        [-11, -25],
        [-2, -39],
        [16, -14],
        [27, -43],
        [20, -61],
        [13, -13],
        [29, -20],
        [12, -19],
        [22, -52],
        [34, -122],
        [12, -66],
        [19, -53],
        [58, -91],
        [3, -26],
        [-3, -30],
        [4, -27],
        [21, -52],
        [5, -34],
        [-23, -43],
        [-1, -62],
        [-14, -83],
        [-3, -28],
        [0, -26],
        [10, -24],
        [11, -18],
        [16, -10],
        [14, -17],
        [26, -65],
        [14, -17],
        [8, -23],
        [0, -36],
        [12, -20],
        [11, -37],
        [16, -9],
        [8, 10],
        [6, 16],
        [14, -6],
        [8, -25],
        [4, -33],
        [2, -37],
        [-1, -69],
        [-7, -24],
        [-15, 1],
        [-27, 28],
        [-1, -20],
        [2, -20],
        [-14, -7],
        [-16, 3],
        [-40, -3],
        [-73, -42],
        [-26, -26],
        [-17, -46],
        [-1, -31],
        [6, -68],
        [-9, -23],
        [-34, -9],
        [-47, 18],
        [-28, 18],
        [-16, 15],
        [-21, 45],
        [-8, 58],
        [16, 172],
        [4, 22],
        [7, 19],
        [8, 31],
        [2, 37],
        [-22, 45],
        [-34, 18],
        [-29, 37],
        [-111, 209],
        [-6, 21],
        [0, 34],
        [4, 33],
        [37, 114],
        [4, 22],
        [5, 91],
        [-1, 74],
        [-4, 75],
        [-17, 46],
        [-31, 15],
        [-32, 2],
        [-31, -15],
        [-30, -37],
        [-57, -79],
        [-22, -46],
        [-2, -60],
        [13, -58],
        [17, -54],
        [8, -58],
        [13, -207],
        [-1, -23],
        [-14, -60],
        [-6, -135],
        [6, -190],
        [10, -116],
        [-4, -63],
        [-26, -136],
        [-8, -75],
        [-1, -31],
        [31, -142],
        [7, -56],
        [4, -58],
        [-33, 28],
        [-15, -1],
        [-16, -9],
        [-34, -9],
        [-35, -1],
        [-12, -11],
        [-25, -38],
        [-15, -15],
        [-13, -3],
        [-44, 47],
        [-27, 52],
        [-24, 56],
        [-4, 63],
        [8, 66],
        [12, 70],
        [24, 116],
        [-1, 78],
        [8, 64],
        [14, 62],
        [5, 58],
        [3, 224],
        [-3, 20],
        [-33, 133],
        [-4, 22],
        [0, 36],
        [3, 34],
        [1, 31],
        [-7, 24],
        [-14, 22],
        [-16, 9],
        [-34, -9],
        [-68, -36],
        [-20, 31],
        [-15, 49],
        [-10, 68],
        [-5, 71],
        [2, 67],
        [10, 65],
        [-8, 45],
        [-12, 49],
        [0, 25],
        [7, 23],
        [12, 18],
        [15, 11],
        [14, 19],
        [37, 66],
        [12, 58],
        [1, 72],
        [10, 68],
        [19, 63],
        [22, 58],
        [7, 60],
        [-11, 96],
        [5, 43],
        [-4, 51],
        [0, 50],
        [13, 95],
        [42, 194],
        [40, 103],
        [16, 27],
        [21, -47],
        [16, -57],
        [1, 51],
        [-5, 50],
        [-16, 117],
        [-14, 228],
        [4, 21],
        [14, -3],
        [15, 20],
        [7, 36],
        [-15, 84],
        [0, 30],
        [28, 119],
        [24, 43],
        [10, 27],
        [6, 73],
        [12, 27],
        [16, 20],
        [20, 50],
        [11, 63],
        [7, 5],
        [8, 2],
        [12, -55],
        [14, -22],
        [28, -18],
        [24, 21],
        [7, 27],
        [9, 24],
        [14, 25],
        [12, 28],
        [14, 66],
        [18, 59],
        [12, 21],
        [15, 13],
        [16, 8],
        [16, 1],
        [35, -37],
        [21, -7],
        [20, 2],
        [14, -6],
        [10, -17],
        [9, -34],
        [11, -29],
        [10, -14],
        [12, -7],
        [76, 12],
        [67, -33],
        [91, -8],
        [32, -19],
        [30, -26],
        [36, -44],
        [14, -10],
        [15, 2],
        [19, 42],
        [14, 10],
        [15, 1],
        [59, -8],
        [158, -52],
        [23, 8],
        [96, 99],
        [38, 93],
        [34, 26],
        [11, 43],
        [7, 51],
        [12, 13],
        [29, 15],
        [11, 15],
        [21, 62],
        [24, 56],
        [11, 16],
        [34, -8],
        [15, -25],
        [19, -81],
        [-3, -14],
        [-22, -40],
        [-7, -17],
        [-25, -115],
        [-17, -56],
        [-21, -50],
      ],
      [
        [79825, 48382],
        [28, -66],
        [25, -35],
        [29, -19],
        [30, -2],
        [30, -8],
        [35, -25],
        [35, -11],
        [17, 4],
        [16, 13],
        [11, 1],
        [10, -12],
        [25, -56],
        [27, -51],
        [6, -26],
        [18, -123],
        [20, -35],
        [29, -10],
        [33, 0],
        [33, -5],
        [77, -29],
        [30, 4],
        [27, 29],
        [24, -19],
        [65, -34],
        [32, -8],
        [36, 10],
        [37, 2],
        [17, -10],
        [17, -15],
        [14, -6],
        [15, 1],
        [26, 29],
        [18, 52],
        [14, 67],
        [11, 69],
        [7, 30],
        [10, 26],
        [14, 17],
        [14, 11],
        [38, -7],
        [8, -16],
        [42, -118],
        [8, -10],
        [45, -8],
        [12, 4],
        [27, 24],
        [16, 2],
        [28, -29],
        [13, -25],
        [13, -18],
        [70, -19],
        [28, -50],
        [13, -7],
        [49, 6],
        [34, -5],
        [29, -13],
        [13, -72],
        [11, -73],
        [7, -25],
        [28, -25],
        [12, -23],
        [-3, -73],
        [3, -70],
        [61, -60],
        [65, -35],
        [69, -4],
        [70, 12],
        [35, 15],
        [45, 26],
        [9, 0],
        [87, -80],
        [8, -12],
        [9, -60],
        [0, -62],
        [-16, -149],
        [-1, -41],
        [1, -41],
        [18, -89],
        [9, -25],
        [31, -47],
        [1, -25],
        [-4, -24],
        [-35, 17],
        [-21, 20],
        [-12, 33],
        [-17, 19],
        [-33, -7],
        [-61, 34],
        [-69, 52],
        [-122, 110],
        [-33, -1],
        [-32, -14],
        [-33, -28],
        [-35, -20],
        [-26, -7],
        [-26, 5],
        [-65, 27],
        [-66, 17],
        [-168, 11],
        [-47, 25],
        [-79, 12],
        [-63, 22],
        [-62, 30],
        [-158, 149],
        [-51, 36],
        [-159, 71],
        [-24, 6],
        [-58, -5],
        [-36, 21],
        [-32, 0],
        [-47, -23],
        [-15, -17],
        [-18, -35],
        [-33, 2],
        [-32, 7],
        [-84, 33],
        [-31, 21],
        [-30, 30],
        [-28, 40],
        [-14, 14],
        [-72, 41],
        [-60, 14],
        [-122, 18],
        [-27, 12],
        [-22, 15],
        [-12, 33],
        [1, 42],
        [9, 36],
        [12, 36],
        [8, 34],
        [-89, 73],
        [-71, 40],
        [-30, 7],
        [-31, 0],
        [-34, -8],
        [-34, 4],
        [-16, 11],
        [-17, 5],
        [-16, -9],
        [-13, 3],
        [-4, 33],
        [9, 28],
        [17, 32],
        [10, 6],
        [4, -50],
        [5, -10],
        [16, -11],
        [6, 3],
        [27, 64],
        [8, 31],
        [13, 85],
        [14, -16],
        [14, 10],
        [8, 13],
        [23, 196],
        [19, 58],
        [26, 47],
        [12, 12],
        [26, -29],
        [51, -11],
        [30, -20],
        [31, -2],
        [29, -10],
        [42, -34],
        [15, 3],
        [14, 11],
        [23, 38],
        [9, 60],
        [32, -31],
        [48, -12],
        [11, -17],
      ],
      [
        [82659, 54245],
        [-3, -5],
        [-19, -16],
        [-9, -33],
        [27, -51],
        [1, -34],
        [21, -30],
        [24, -46],
        [1, -16],
        [9, -21],
        [4, -25],
        [-17, -26],
        [-24, -5],
        [-17, 24],
        [-16, 30],
        [-4, -37],
        [-13, -21],
        [-45, 6],
        [-32, 0],
        [-32, -10],
        [16, -5],
        [14, -12],
        [50, -96],
        [11, -35],
        [-17, -70],
        [8, -29],
        [19, -16],
        [24, -35],
        [17, -4],
        [12, -19],
        [0, -34],
        [7, -32],
        [-19, -12],
        [19, -8],
        [17, -16],
        [-9, -16],
        [-7, -20],
        [7, -10],
        [23, -19],
        [10, -16],
        [6, -45],
        [22, -73],
        [41, -95],
        [9, -34],
        [0, -32],
        [-6, -27],
        [-24, -32],
        [-19, -42],
        [-2, -15],
        [-26, -20],
        [12, -14],
        [9, -19],
        [18, -59],
        [42, -95],
        [22, -35],
        [87, -129],
        [46, -57],
        [60, -128],
        [31, -30],
        [6, -36],
        [-26, -54],
        [-38, -28],
        [-62, -15],
        [-62, 20],
        [-32, 15],
        [-28, 32],
        [-22, 63],
        [-29, 35],
        [12, -39],
        [7, -39],
        [-4, -43],
        [-11, -33],
        [-20, -25],
        [-21, -20],
        [-9, -14],
        [-53, -224],
        [-9, -60],
        [-16, -252],
        [0, -71],
        [24, -133],
        [2, -69],
        [5, -30],
        [-4, -25],
        [-11, -15],
        [-46, -41],
        [-32, -34],
        [-26, -48],
        [-21, -59],
        [-19, -44],
        [-25, -20],
        [-18, 3],
        [-14, 20],
        [-10, 38],
        [-6, 42],
        [-4, -31],
        [1, -30],
        [8, -33],
        [4, -34],
        [-5, -35],
        [-11, -28],
        [-29, -30],
        [-16, -26],
        [-2, -46],
        [-8, -26],
        [-11, -20],
        [-40, -46],
        [-9, -18],
        [-7, -24],
        [22, 4],
        [19, -4],
        [2, -45],
        [6, -34],
        [-8, -75],
        [-30, -50],
        [15, -11],
        [14, -16],
        [30, -12],
        [10, -53],
        [-4, -64],
        [-6, -58],
        [-21, -16],
        [-14, 11],
        [-14, -1],
        [-10, -17],
        [-2, -30],
        [19, 15],
        [-1, -75],
        [-5, -72],
        [-7, -40],
        [-11, -33],
        [-18, -10],
        [-16, 24],
        [-4, -28],
        [5, -24],
        [24, -58],
        [-15, -13],
        [-10, -20],
        [-5, -29],
        [-26, -66],
        [-11, -49],
        [-5, -52],
        [-12, -41],
        [-194, -180],
        [-156, -151],
        [-12, 10],
        [-7, 23],
        [-6, 235],
        [-19, 121],
        [-3, 68],
        [-22, -61],
        [-14, 6],
        [-15, 16],
        [-11, 19],
        [0, 26],
        [11, 75],
        [-14, -41],
        [-16, -31],
        [-16, 3],
        [-14, 16],
        [-5, 24],
        [-7, 4],
        [-35, -67],
        [-45, -35],
        [-25, 0],
        [-20, 21],
        [1, 50],
        [-2, 50],
        [-5, 29],
        [-13, 10],
        [-11, -4],
        [-32, -25],
        [-12, 3],
        [-7, -13],
        [-85, 180],
        [-18, -146],
        [-59, -78],
        [-44, -45],
        [-43, 17],
        [-45, 29],
        [-44, -35],
        [-47, -86],
        [-13, -13],
        [-14, 1],
        [-10, 11],
        [3, 65],
        [1, 64],
        [-4, 145],
        [-4, 28],
        [-13, 40],
        [-18, 28],
        [-11, -21],
        [-9, -28],
        [-36, 1],
        [-35, 23],
        [-30, -13],
        [-60, -58],
        [-32, -8],
        [-17, 12],
        [-11, 31],
        [6, 29],
        [14, 21],
        [-25, -17],
        [-21, -29],
        [-9, -19],
        [-10, 10],
        [-26, 65],
        [-54, -24],
        [-8, -8],
        [-13, -22],
        [-13, 11],
        [-7, 24],
        [-2, 136],
        [-28, 262],
        [-7, 134],
        [-6, 31],
        [-32, 49],
        [1, 69],
        [16, 57],
        [4, 68],
        [-5, 73],
        [-10, 71],
        [-12, 54],
        [-18, 46],
        [-24, 52],
        [-30, 39],
        [-63, 43],
        [-33, -4],
        [-13, 17],
        [-8, 23],
        [3, 43],
        [12, 30],
        [15, 7],
        [2, 17],
        [-33, 35],
        [-26, 48],
        [-9, 28],
        [-2, 31],
        [0, 73],
        [8, 46],
        [4, 25],
        [8, 76],
        [18, 24],
        [-3, 14],
        [-8, 10],
        [-11, 25],
        [-9, 29],
        [-20, 49],
        [-36, 60],
        [-6, 102],
        [-5, 150],
        [3, 68],
        [11, 128],
        [21, 40],
        [16, 12],
        [12, 17],
        [-10, 2],
        [-9, -6],
        [-15, -4],
        [12, 114],
        [6, 33],
        [25, 64],
        [30, 57],
        [12, 67],
        [17, 58],
        [70, 61],
      ],
      [
        [85480, 52331],
        [21, -13],
        [21, 4],
        [10, 26],
        [3, 33],
        [11, 62],
        [24, 42],
        [17, 7],
        [12, 18],
        [-2, 46],
        [1, 45],
        [18, 48],
        [57, 68],
        [31, 24],
        [42, 7],
        [5, -25],
        [-5, -37],
        [8, -56],
        [-4, -150],
        [-10, -21],
        [-42, -52],
        [-47, -42],
        [-13, -17],
        [-12, -42],
        [1, -41],
        [38, -54],
        [59, -52],
        [13, -24],
        [8, -40],
        [2, -45],
        [14, -21],
        [20, -10],
        [13, -22],
        [11, -30],
        [-100, 70],
        [-26, 31],
        [-32, 4],
        [-31, 9],
        [-32, 27],
        [-34, 6],
        [-16, -19],
        [-7, -38],
        [-4, -43],
        [8, -53],
        [-1, -32],
        [-6, -58],
        [25, -171],
        [31, -137],
        [45, -142],
        [23, -49],
        [25, -44],
        [-41, 13],
        [-12, 48],
        [-52, 47],
        [-10, 28],
        [-34, 135],
        [-10, 25],
        [-31, 46],
        [-14, 33],
        [-5, 46],
        [4, 48],
        [-2, 66],
        [1, 65],
        [7, 81],
        [-11, 28],
        [-15, 26],
        [-17, 62],
        [-5, 70],
        [1, 40],
        [7, 35],
        [10, 31],
        [2, 30],
        [-25, 44],
        [-25, 124],
        [-2, 65],
        [32, 124],
        [-1, 60],
        [7, 36],
        [3, 38],
        [17, 83],
        [28, 70],
        [47, 99],
        [18, 21],
        [20, 14],
        [2, -24],
        [-4, -21],
        [-34, -101],
        [-5, -22],
        [-1, -43],
        [17, -24],
        [18, -51],
        [3, -68],
        [1, -72],
        [-4, -73],
        [-7, -25],
        [-28, -73],
        [-65, -85],
        [-5, -21],
        [0, -24],
        [12, -29],
        [15, -22],
      ],
      [
        [86042, 50192],
        [64, -64],
        [33, -9],
        [56, 8],
        [21, -6],
        [53, -82],
        [15, -56],
        [4, -48],
        [9, -46],
        [13, -11],
        [15, -5],
        [20, -66],
        [4, -21],
        [-15, -166],
        [-63, 63],
        [-60, 71],
        [-26, 27],
        [-69, 60],
        [-11, 21],
        [-8, 27],
        [-30, 37],
        [-60, 6],
        [-23, -1],
        [-9, -6],
        [2, -20],
        [0, -40],
        [-15, -12],
        [-37, 26],
        [-34, 9],
        [-29, 25],
        [-39, 14],
        [-4, 12],
        [3, 19],
        [-1, 18],
        [-9, 6],
        [-18, -3],
        [-17, -17],
        [-14, -21],
        [-20, -55],
        [-11, -21],
        [-34, -9],
        [-14, 5],
        [-13, 14],
        [-39, 101],
        [-13, 22],
        [-14, 18],
        [-14, 8],
        [-13, -16],
        [-8, -31],
        [-3, -37],
        [-4, -22],
        [-17, -59],
        [-13, -36],
        [-5, 6],
        [7, 57],
        [0, 32],
        [-9, 34],
        [-5, 35],
        [65, 165],
        [24, 40],
        [103, 13],
        [61, -8],
        [34, 4],
        [22, 12],
        [19, -5],
        [4, -33],
        [12, -24],
        [16, 2],
        [29, 25],
        [26, 40],
        [15, 17],
        [16, 3],
        [16, -3],
        [16, -9],
        [43, -34],
      ],
      [
        [85238, 50064],
        [46, -45],
        [10, -29],
        [9, -35],
        [9, -20],
        [10, -15],
        [18, -31],
        [5, -46],
        [-4, -93],
        [-21, -8],
        [-19, -14],
        [-40, -54],
        [-20, -10],
        [-21, -4],
        [-15, -14],
        [-15, -6],
        [-39, 30],
        [-38, 35],
        [-54, 61],
        [-10, 15],
        [-9, 32],
        [-25, 59],
        [-6, 38],
        [-2, 106],
        [6, 25],
        [11, 13],
        [36, -25],
        [24, 26],
        [69, 22],
        [71, -2],
        [14, -11],
      ],
      [
        [84740, 46363],
        [-19, -30],
        [-10, -58],
        [-34, -54],
        [-37, -89],
        [-29, -46],
        [-26, -53],
        [-23, -36],
        [-28, -13],
        [-42, -7],
        [-56, -65],
        [-32, -28],
        [-31, -2],
        [-28, 21],
        [-11, 24],
        [2, 31],
        [10, 28],
        [12, 22],
        [7, 29],
        [-33, 37],
        [-2, 27],
        [12, 75],
        [9, 76],
        [12, 52],
        [46, 93],
        [28, 46],
        [17, 19],
      ],
      [
        [84567, 46549],
        [36, 20],
        [20, 22],
        [17, 32],
        [51, 53],
        [9, 15],
      ],
      [
        [87429, 48555],
        [-2, -22],
        [-1, -41],
        [5, -38],
        [-12, -39],
        [11, -57],
        [2, -29],
        [-1, -41],
        [-3, -18],
        [-8, -54],
        [-9, -19],
        [-6, -5],
        [-7, -16],
        [-54, 18],
        [-24, 37],
        [-21, 40],
        [-4, 17],
        [-25, 46],
        [-6, 16],
        [0, 25],
        [20, 7],
        [21, -1],
        [-1, 22],
        [13, 80],
        [-33, 51],
        [-6, 21],
        [12, 15],
        [26, -18],
        [32, 89],
        [10, 19],
        [4, 50],
        [18, 6],
        [13, -6],
        [8, -31],
        [3, -27],
        [-3, -24],
        [15, -13],
        [13, -60],
      ],
      [
        [87370, 48132],
        [-4, -41],
        [-5, -45],
        [-25, -51],
        [-16, -78],
        [-9, -19],
        [-34, -35],
        [-30, 43],
        [-9, 37],
        [13, 172],
        [13, -6],
        [9, 1],
        [2, 12],
        [-19, 19],
        [-4, 98],
        [1, 38],
        [15, 8],
        [18, -29],
        [24, -52],
        [27, -40],
        [33, -32],
      ],
      [
        [88481, 47077],
        [-66, -76],
        [-87, 13],
        [-31, 2],
        [-51, -19],
        [-11, 15],
        [10, 71],
        [41, 190],
        [49, 168],
        [20, 43],
        [29, 41],
        [31, 33],
        [69, 34],
        [62, -7],
        [9, -13],
        [27, -56],
        [18, -44],
        [7, -63],
        [-27, -107],
        [-29, -102],
        [-49, -80],
        [-21, -43],
      ],
      [
        [89159, 46590],
        [-14, 19],
        [-39, 64],
        [-34, 74],
        [-23, 68],
        [-25, 62],
        [-108, 184],
        [-27, 61],
        [-6, 16],
        [3, 16],
        [12, 32],
        [22, 92],
        [-23, -57],
        [-27, -45],
        [-40, -3],
        [-39, -11],
        [-37, -27],
        [-37, -10],
        [-18, 14],
        [-11, 34],
        [-6, 35],
        [-3, 36],
        [-15, -59],
        [-31, -33],
        [-41, -69],
        [-12, 14],
        [-7, 27],
        [-3, 27],
        [8, 29],
        [6, 31],
        [8, 73],
        [19, 44],
        [12, 84],
        [8, 30],
        [4, 30],
        [-11, 34],
        [-18, 12],
        [-13, 20],
        [-15, 57],
        [-9, 20],
        [-16, 23],
        [-13, 28],
        [14, 20],
        [19, 8],
        [15, -1],
        [27, -13],
        [13, 0],
        [31, 21],
        [-17, -7],
        [-18, 1],
        [-57, 37],
        [-35, 38],
        [-33, 77],
        [0, 15],
        [23, 14],
        [51, 16],
        [-16, 39],
        [-23, 34],
        [-8, 61],
        [-15, 38],
        [-34, 61],
        [-23, 64],
        [-19, 129],
        [-20, 98],
        [5, 35],
        [17, 25],
        [-26, 3],
        [-23, 19],
        [12, 47],
        [27, 28],
        [-25, -7],
        [-23, -14],
        [-11, -2],
        [-11, 4],
        [-6, 19],
        [-1, 27],
        [3, 48],
        [-4, 46],
        [-21, 22],
        [-17, 33],
        [-10, 13],
        [-13, -1],
        [-12, 22],
        [-10, 32],
        [-126, 139],
        [-8, 40],
        [-11, -18],
        [-12, -8],
        [-14, 23],
        [-16, 15],
        [-16, -2],
        [-15, 12],
        [-16, 7],
        [-17, 1],
        [-66, 43],
        [-63, 68],
        [-51, 29],
        [-31, 38],
        [-33, 31],
        [-73, 30],
        [-74, 20],
        [-27, 1],
        [-22, -7],
        [-22, 2],
        [-122, 147],
        [-21, 67],
        [2, 39],
        [6, 33],
        [50, 9],
        [-36, 9],
        [-14, -4],
        [-29, -27],
        [-16, -2],
        [-22, 18],
        [-21, 22],
        [-35, -20],
        [-17, 34],
        [-7, 35],
        [-9, 17],
        [-13, -2],
        [-17, -13],
        [-18, 2],
        [-11, 25],
        [-8, 32],
        [-12, 23],
        [-15, 17],
        [-24, 42],
        [-12, 56],
        [1, 98],
        [4, 36],
        [24, 57],
        [16, 54],
        [-20, 6],
        [-19, -25],
        [-8, -25],
        [-3, -31],
        [-2, -103],
        [-15, -30],
        [-22, 2],
        [6, -60],
        [-9, -57],
        [-26, -67],
        [-2, -31],
        [4, -33],
        [-6, -32],
        [-42, -94],
        [-14, -5],
        [-32, 1],
        [-32, -15],
        [-15, 22],
        [-13, 28],
        [-9, 34],
        [-13, 70],
        [-10, 71],
        [32, 88],
        [-11, 80],
        [-21, 68],
        [-55, 94],
        [-57, 90],
        [-26, 18],
        [-43, 8],
        [-13, 9],
        [-13, 33],
        [-10, 39],
        [26, 17],
        [46, 46],
        [26, -3],
        [70, -24],
        [21, -23],
        [20, -13],
        [48, 75],
        [38, 99],
        [24, 21],
        [20, 7],
        [20, -9],
        [41, -34],
        [32, -16],
        [23, -4],
        [12, -30],
        [13, -14],
        [3, 46],
        [12, 54],
        [22, 17],
        [12, 3],
        [8, 14],
        [-2, 50],
        [-30, 6],
        [17, 43],
        [14, 21],
        [5, 21],
        [1, 26],
        [-59, -50],
        [-62, -21],
        [-36, 5],
        [-37, 1],
        [-73, -33],
        [-27, 1],
        [-65, 13],
        [-36, 17],
        [-27, -13],
        [-27, -1],
        [-28, 38],
        [-23, 48],
        [-12, 34],
        [-16, 25],
        [-7, 33],
        [-17, 126],
        [-2, 89],
        [-27, 2],
        [-28, 9],
        [-121, 85],
        [-15, -21],
        [-17, -11],
        [-17, -4],
        [-17, 5],
        [-17, 13],
        [1, 23],
        [13, 58],
        [12, 21],
        [17, 17],
        [11, 30],
        [18, 92],
        [1, 31],
        [-2, 32],
        [1, 24],
        [11, 12],
        [46, 30],
        [95, 45],
        [24, 27],
        [20, 43],
        [24, 26],
        [10, 27],
        [12, 21],
        [74, 57],
        [32, 4],
        [32, -6],
        [65, -34],
        [61, -54],
        [53, -72],
        [57, -52],
        [70, -8],
        [35, 5],
        [34, -7],
        [14, -15],
        [24, -44],
        [-7, -29],
        [-4, -60],
        [13, -58],
        [20, -58],
        [16, -62],
        [3, -30],
        [-6, -65],
        [-5, -31],
        [-20, -53],
        [-11, -58],
        [7, -71],
        [4, -71],
        [-1, -66],
        [4, -65],
        [10, -65],
        [47, -180],
        [27, -122],
        [9, 68],
        [-2, 76],
        [9, 27],
        [14, 15],
        [17, -16],
        [4, -30],
        [2, -67],
        [14, -131],
        [19, -6],
        [21, 20],
        [3, -40],
        [-1, -74],
        [10, -59],
        [8, -23],
        [34, -48],
        [15, -9],
        [44, -12],
        [33, -3],
        [32, 17],
        [21, 44],
        [18, 47],
        [65, 110],
        [18, 53],
        [18, 80],
        [7, 18],
        [63, 87],
        [8, 31],
        [9, 59],
        [14, 58],
        [10, 30],
        [62, 28],
        [64, 16],
        [64, 53],
        [27, 46],
        [2, 30],
        [-14, 53],
        [-1, 23],
        [15, 23],
        [56, 67],
        [66, 69],
        [53, 47],
        [29, 0],
        [27, -42],
        [28, -34],
        [150, -101],
        [24, -31],
        [21, -42],
        [30, -29],
        [33, -14],
        [31, -27],
        [28, -35],
        [64, -64],
        [86, -79],
        [21, -5],
        [80, 4],
        [14, -15],
        [13, -21],
        [12, -5],
        [91, -14],
        [14, -15],
        [13, -21],
        [8, -57],
        [62, -2],
      ],
      [
        [88581, 47010],
        [-14, -7],
        [-69, 17],
        [-8, 24],
        [-1, 12],
        [16, 24],
        [15, 40],
        [24, 14],
        [10, 0],
        [28, -94],
        [-1, -30],
      ],
      [
        [78251, 53040],
        [16, -47],
        [3, -31],
        [-11, -35],
        [-4, -54],
        [-33, -42],
        [-28, 10],
        [-9, 15],
        [-18, 82],
        [2, 69],
        [11, 27],
        [26, -4],
        [27, 38],
        [18, -28],
      ],
      [
        [78451, 52413],
        [-13, -18],
        [-15, 28],
        [-13, 39],
        [-6, 41],
        [-6, 68],
        [-2, 48],
        [8, 28],
        [5, -1],
        [23, -28],
        [15, -49],
        [9, -15],
        [1, -45],
        [-5, -52],
        [-1, -44],
      ],
      [
        [78469, 52683],
        [2, -74],
        [-20, 20],
        [-17, 29],
        [-25, 22],
        [-32, 7],
        [-23, 19],
        [-16, 34],
        [0, 16],
        [1, 13],
        [5, 10],
        [119, -67],
        [6, -29],
      ],
      [
        [78618, 52273],
        [-5, -22],
        [-11, 16],
        [-54, 25],
        [-18, 2],
        [-47, 28],
        [-14, 14],
        [-10, 19],
        [3, 35],
        [7, 21],
        [4, 59],
        [12, 24],
        [23, -44],
        [26, -38],
        [15, -17],
        [46, -38],
        [16, -19],
        [7, -65],
      ],
      [
        [78657, 52344],
        [-9, -17],
        [-14, 4],
        [-14, 20],
        [-20, 35],
        [-21, 31],
        [-28, 20],
        [-17, 5],
        [-6, 8],
        [6, 60],
        [18, 4],
        [58, -56],
        [19, -31],
        [28, -83],
      ],
      [
        [78689, 52155],
        [-31, -4],
        [-9, 8],
        [4, 54],
        [9, 33],
        [14, -1],
        [16, -49],
        [-3, -41],
      ],
      [
        [78735, 52225],
        [-5, -7],
        [-24, 73],
        [6, 42],
        [6, 11],
        [13, -26],
        [10, -27],
        [8, -32],
        [-14, -34],
      ],
      [
        [78895, 52523],
        [18, -25],
        [14, 16],
        [-1, -21],
        [-3, -21],
        [-17, -59],
        [-28, 13],
        [-7, 19],
        [-2, 15],
        [4, 9],
        [3, 29],
        [12, 0],
        [7, 25],
      ],
      [
        [79051, 52543],
        [1, -43],
        [16, -21],
        [4, -32],
        [-3, -51],
        [-14, -59],
        [-7, -15],
        [-20, 12],
        [-6, 19],
        [-3, 16],
        [3, 11],
        [-15, 14],
        [10, 22],
        [-7, 32],
        [-40, -20],
        [-12, -1],
        [-2, 36],
        [2, 15],
        [31, 45],
        [18, 9],
        [20, -10],
        [24, 21],
      ],
      [
        [79104, 51741],
        [8, -9],
        [10, 30],
        [19, -41],
        [11, -21],
        [15, -20],
        [-15, -1],
        [-6, -19],
        [-4, -4],
        [-59, 66],
        [-38, -21],
        [-25, 19],
        [-8, 14],
        [14, 36],
        [13, 83],
        [26, -21],
        [6, -26],
        [-2, -7],
        [17, -16],
        [18, -42],
      ],
      [
        [79020, 51650],
        [26, -56],
        [6, -20],
        [-13, -31],
        [-10, -44],
        [-6, -9],
        [-20, 16],
        [-14, -43],
        [-9, 69],
        [-20, 44],
        [12, 44],
        [5, 4],
        [6, -2],
        [6, -11],
        [31, 39],
      ],
      [
        [78815, 51642],
        [-36, -20],
        [-41, 15],
        [5, 34],
        [20, 41],
        [17, -2],
        [31, -27],
        [12, -23],
        [-8, -18],
      ],
      [
        [80474, 51163],
        [-55, -59],
        [-13, 3],
        [-10, 21],
        [6, 113],
        [7, 34],
        [39, 4],
        [23, -17],
        [12, -18],
        [5, -38],
        [-2, -23],
        [-12, -20],
      ],
      [
        [81623, 47750],
        [-6, -9],
        [-47, 5],
        [-30, -47],
        [-21, -14],
        [-76, 0],
        [-9, 6],
        [-7, 0],
        [-4, -10],
        [-24, 7],
        [-77, 42],
        [-10, 38],
        [12, 42],
        [27, 58],
        [56, 12],
        [252, 4],
        [27, -51],
        [3, -16],
        [-55, -35],
        [-11, -32],
      ],
      [
        [87489, 51200],
        [-13, -11],
        [-15, 12],
        [-15, 44],
        [5, 34],
        [17, 23],
        [15, -23],
        [4, -30],
        [11, -2],
        [-9, -47],
      ],
      [
        [85346, 51557],
        [-17, -15],
        [-19, 1],
        [-4, 61],
        [6, 78],
        [18, 13],
        [27, -16],
        [-10, -20],
        [8, -42],
        [-9, -60],
      ],
      [
        [85434, 51659],
        [32, -86],
        [-21, -82],
        [15, -46],
        [40, -2],
        [10, -17],
        [7, -21],
        [4, -28],
        [-10, -23],
        [-23, -20],
        [-26, 29],
        [-7, 28],
        [-5, 10],
        [-35, -21],
        [-10, -2],
        [-6, 39],
        [8, 55],
        [-24, 25],
        [-23, 57],
        [-1, 24],
        [9, 39],
        [-1, 32],
        [13, 3],
        [23, -43],
        [11, 40],
        [9, 17],
        [11, -7],
      ],
      [
        [86923, 48443],
        [-22, -50],
        [21, 117],
        [4, 59],
        [20, 36],
        [29, 179],
        [7, -5],
        [10, -17],
        [-15, -131],
        [-41, -92],
        [-13, -96],
      ],
      [
        [86890, 48472],
        [-17, -55],
        [-11, 19],
        [-7, 1],
        [-4, 32],
        [4, 68],
        [-14, 76],
        [19, -1],
        [5, -23],
        [6, -8],
        [18, -73],
        [1, -36],
      ],
      [
        [85631, 49726],
        [-7, -21],
        [-16, -14],
        [-14, 1],
        [5, 21],
        [-4, 11],
        [-10, -5],
        [-16, -16],
        [-20, -33],
        [-13, 16],
        [-2, 26],
        [1, 11],
        [24, 45],
        [29, 8],
        [40, 43],
        [18, -2],
        [-4, -27],
        [-6, -20],
        [-4, -20],
        [-1, -24],
      ],
      [
        [85226, 54166],
        [-11, -12],
        [-18, 4],
        [-2, 30],
        [18, 52],
        [12, 56],
        [-13, 14],
        [-12, 35],
        [-1, 41],
        [11, 76],
        [15, -6],
        [14, -33],
        [6, -62],
        [10, -47],
        [-20, -64],
        [-9, -84],
      ],
      [
        [84904, 53822],
        [-7, -18],
        [-34, 32],
        [2, 51],
        [-4, 25],
        [-9, 27],
        [-4, 26],
        [4, 28],
        [20, -36],
        [12, -57],
        [16, -55],
        [4, -23],
      ],
      [
        [86350, 47051],
        [-24, -18],
        [16, 45],
        [52, 104],
        [18, -19],
        [25, -4],
        [-37, -47],
        [-38, -19],
        [-12, -42],
      ],
      [
        [86065, 47261],
        [-16, -53],
        [-18, 3],
        [-34, 71],
        [2, 50],
        [3, 16],
        [13, 5],
        [43, -15],
        [9, -40],
        [-2, -37],
      ],
      [
        [85506, 47177],
        [48, -23],
        [28, 3],
        [6, -21],
        [-27, -49],
        [-56, 38],
        [-10, 40],
        [11, 12],
      ],
      [
        [86208, 50869],
        [4, -34],
        [16, -32],
        [-5, -49],
        [-7, -7],
        [4, -23],
        [7, -17],
        [-13, -12],
        [-10, 6],
        [-15, -16],
        [-10, -22],
        [-32, -9],
        [-11, 20],
        [-57, 24],
        [-37, 53],
        [-5, 16],
        [71, 62],
        [32, 17],
        [26, -1],
        [33, 23],
        [9, 1],
      ],
      [
        [78435, 48687],
        [-23, -3],
        [-42, 71],
        [-7, 22],
        [12, 21],
        [13, -2],
        [48, -45],
        [9, -22],
        [-10, -42],
      ],
      [
        [77895, 50009],
        [11, -84],
        [-33, 58],
        [1, 40],
        [-5, 26],
        [-20, 33],
        [-15, 40],
        [-7, 96],
        [5, 20],
        [13, 1],
        [58, -126],
        [4, -21],
        [-1, -45],
        [-8, -14],
        [-3, -24],
      ],
      [
        [77834, 50264],
        [-20, -47],
        [-33, 1],
        [-6, 29],
        [1, 70],
        [-8, 22],
        [0, 9],
        [6, 40],
        [6, 9],
        [53, -98],
        [1, -35],
      ],
      [
        [77733, 50493],
        [2, -15],
        [-45, 50],
        [-22, 14],
        [-19, 56],
        [5, 26],
        [1, 37],
        [3, 15],
        [14, 5],
        [18, -27],
        [13, -66],
        [23, -61],
        [7, -34],
      ],
      [
        [77349, 51537],
        [-17, -27],
        [-25, 26],
        [9, 37],
        [4, 51],
        [15, 41],
        [5, 47],
        [-29, 130],
        [14, 5],
        [12, -14],
        [19, -87],
        [17, -52],
        [-7, -70],
        [-17, -87],
      ],
      [
        [76795, 53202],
        [-18, -5],
        [-16, 12],
        [-14, 33],
        [-75, 96],
        [-23, 1],
        [-16, 25],
        [-20, 8],
        [-21, 64],
        [-5, 35],
        [16, 16],
        [9, 35],
        [25, -15],
        [28, -62],
        [29, -23],
        [8, -12],
        [14, -34],
        [66, -85],
        [7, -28],
        [5, -29],
        [1, -32],
      ],
      [
        [83900, 48710],
        [-18, -15],
        [-33, 66],
        [-14, 54],
        [3, 31],
        [10, 26],
        [5, 7],
        [-2, 28],
        [13, 14],
        [15, -2],
        [9, -38],
        [11, -10],
        [6, -36],
        [-5, -125],
      ],
      [
        [84225, 51168],
        [6, -36],
        [-10, -31],
        [11, -59],
        [28, 78],
        [27, 10],
        [15, -13],
        [10, -15],
        [6, -30],
        [-10, -63],
        [-18, -30],
        [-22, -5],
        [-11, 37],
        [-15, 4],
        [-10, -81],
        [-5, -12],
        [-13, -10],
        [-12, 22],
        [-2, 12],
        [17, 37],
        [-9, 108],
        [-13, -20],
        [-34, -99],
        [-26, -44],
        [-8, 23],
        [-14, 67],
        [6, 86],
        [21, 58],
        [18, -4],
        [52, 18],
        [15, -8],
      ],
      [
        [83850, 51608],
        [12, -25],
        [-7, -30],
        [-9, 7],
        [-26, 0],
        [-9, -2],
        [-12, -18],
        [-7, 0],
        [5, 27],
        [21, 41],
        [14, -6],
        [18, 6],
      ],
      [
        [83479, 48215],
        [-11, -96],
        [-6, 33],
        [-2, 88],
        [-7, 43],
        [5, 49],
        [-1, 126],
        [8, 58],
        [16, -74],
        [4, -38],
        [-6, -189],
      ],
      [
        [82048, 47827],
        [-22, -10],
        [-21, 21],
        [0, 27],
        [5, 25],
        [32, 13],
        [17, 0],
        [18, -18],
        [12, -18],
        [6, -21],
        [-34, -1],
        [-13, -18],
      ],
      [
        [82306, 49614],
        [-59, -107],
        [-9, 28],
        [5, 109],
        [-17, 67],
        [2, 51],
        [11, 89],
        [15, 68],
        [34, 46],
        [8, 5],
        [-2, -83],
        [7, -31],
        [2, -27],
        [-3, -23],
        [6, -105],
        [4, -26],
        [-8, -33],
        [4, -28],
      ],
      [
        [84152, 45559],
        [-26, -1],
        [-8, 6],
        [-3, 51],
        [8, 28],
        [60, 37],
        [23, 34],
        [34, 70],
        [20, 18],
        [5, 8],
        [4, -1],
        [3, -54],
        [9, -31],
        [1, -17],
        [-30, -27],
        [-26, -62],
        [-59, -41],
        [-15, -18],
      ],
      [
        [84523, 47045],
        [-17, -36],
        [-11, -62],
        [-11, -19],
        [-22, -11],
        [-14, 62],
        [-24, -3],
        [12, 54],
        [11, 21],
        [16, 0],
        [7, -22],
        [4, -4],
        [36, 92],
        [7, 1],
        [6, -4],
        [5, -11],
        [-5, -58],
      ],
      [
        [84423, 47077],
        [-39, -15],
        [-24, -72],
        [-19, 1],
        [-11, -32],
        [-2, -14],
        [1, -12],
        [-2, -13],
        [-8, -12],
        [-17, 20],
        [-16, -25],
        [-6, -7],
        [-23, 30],
        [-21, -2],
        [-6, 5],
        [26, 52],
        [36, 50],
        [6, 18],
        [-14, 5],
        [-8, 7],
        [-2, 12],
        [23, 7],
        [16, 2],
        [12, -16],
        [8, 1],
        [48, 58],
        [20, -14],
        [14, -14],
        [8, -20],
      ],
      [
        [84254, 47030],
        [-6, -25],
        [-75, 2],
        [2, 33],
        [21, 36],
        [7, 12],
        [23, 11],
        [33, -20],
        [-5, -49],
      ],
      [
        [87420, 48070],
        [-15, -6],
        [-8, 48],
        [13, 12],
        [14, -29],
        [-4, -25],
      ],
      [
        [78728, 52447],
        [2, -32],
        [-19, 8],
        [-13, 37],
        [11, 26],
        [7, 10],
        [7, -36],
        [5, -13],
      ],
      [
        [78840, 52304],
        [2, -17],
        [-25, 34],
        [-1, 24],
        [3, 11],
        [15, -26],
        [6, -26],
      ],
      [
        [78955, 52323],
        [-18, -17],
        [-22, 53],
        [1, 12],
        [2, 9],
        [4, 6],
        [13, -27],
        [16, -10],
        [4, -26],
      ],
      [
        [79080, 51877],
        [2, -14],
        [-13, 16],
        [-8, 10],
        [-5, 8],
        [-29, 79],
        [12, -5],
        [32, -69],
        [9, -25],
      ],
      [
        [79523, 53661],
        [-1, -39],
        [-19, 23],
        [-4, 44],
        [7, 14],
        [13, -8],
        [4, -34],
      ],
      [
        [79377, 53492],
        [-12, -2],
        [-3, 17],
        [1, 29],
        [-2, 24],
        [-3, 17],
        [0, 30],
        [11, -15],
        [8, -14],
        [10, -10],
        [7, -6],
        [4, -5],
        [-7, -42],
        [-14, -23],
      ],
      [
        [80246, 53516],
        [-14, -30],
        [-14, 19],
        [22, 61],
        [5, 4],
        [1, -54],
      ],
      [
        [80264, 50910],
        [-32, -25],
        [-10, 55],
        [21, 16],
        [22, -14],
        [-1, -32],
      ],
      [
        [79853, 50172],
        [-11, -14],
        [-7, 14],
        [-2, 16],
        [5, 20],
        [15, 2],
        [7, -6],
        [-7, -32],
      ],
      [
        [79690, 50111],
        [-5, -11],
        [-15, 6],
        [-11, 16],
        [-7, 15],
        [-2, 16],
        [15, 20],
        [32, -20],
        [-7, -42],
      ],
      [
        [81780, 47733],
        [-4, -22],
        [-14, 5],
        [-13, 38],
        [6, 10],
        [8, 4],
        [9, -4],
        [8, -31],
      ],
      [
        [81310, 48495],
        [-6, -20],
        [-26, 1],
        [-5, 24],
        [17, 41],
        [12, 3],
        [10, -15],
        [-2, -34],
      ],
      [
        [79236, 48018],
        [-17, -13],
        [-13, 11],
        [-6, 16],
        [19, 40],
        [10, 10],
        [9, 3],
        [5, -22],
        [-7, -45],
      ],
      [
        [77037, 53038],
        [-2, -13],
        [-29, 61],
        [-32, 34],
        [13, 8],
        [27, -9],
        [11, -9],
        [10, -30],
        [2, -42],
      ],
      [
        [76489, 55191],
        [-6, -16],
        [-16, 8],
        [-18, 52],
        [6, 10],
        [12, -5],
        [21, -12],
        [2, -20],
        [-1, -17],
      ],
      [
        [84332, 50861],
        [-19, -4],
        [-13, 17],
        [1, 85],
        [12, 18],
        [5, -3],
        [4, -25],
        [5, -23],
        [10, -21],
        [-5, -44],
      ],
      [
        [84233, 49473],
        [-27, -69],
        [-19, 4],
        [-23, 45],
        [-6, 50],
        [-1, 18],
        [16, 28],
        [52, -9],
        [9, -25],
        [-1, -42],
      ],
      [
        [84401, 50716],
        [5, -23],
        [-17, 1],
        [-7, 43],
        [1, 24],
        [18, -45],
      ],
      [
        [84208, 50796],
        [-20, -47],
        [-2, 25],
        [4, 23],
        [5, 16],
        [9, 9],
        [4, -26],
      ],
      [
        [83184, 46808],
        [-11, -6],
        [-11, 8],
        [4, 52],
        [-6, 34],
        [11, 28],
        [3, 48],
        [5, 15],
        [6, -15],
        [4, -10],
        [5, -5],
        [13, -1],
        [3, -21],
        [-1, -19],
        [-5, -21],
        [-15, -23],
        [-11, -25],
        [6, -39],
      ],
      [
        [83075, 47097],
        [-12, -1],
        [-2, 23],
        [4, 24],
        [12, 11],
        [5, 0],
        [8, -22],
        [2, -11],
        [-8, -15],
        [-9, -9],
      ],
      [
        [82113, 46791],
        [-8, -20],
        [-22, 35],
        [-6, 16],
        [17, 23],
        [6, 3],
        [14, -24],
        [-1, -33],
      ],
      [
        [82745, 54254],
        [9, -55],
        [2, -21],
        [-52, -29],
        [-31, 68],
        [6, 27],
      ],
      [
        [82682, 53732],
        [-3, -19],
        [-24, 47],
        [-7, 33],
        [3, 27],
        [25, 2],
        [12, -17],
        [-6, -73],
      ],
      [
        [84458, 48402],
        [-3, -28],
        [-10, 32],
        [-9, 15],
        [1, 34],
        [13, -12],
        [8, -41],
      ],
      [
        [84340, 48806],
        [-1, -58],
        [-11, 3],
        [-9, 20],
        [-3, 20],
        [1, 15],
        [5, 13],
        [18, -13],
      ],
      [
        [83548, 47742],
        [-29, -3],
        [-8, 5],
        [-3, 56],
        [32, -24],
        [10, -2],
        [-2, -32],
      ],
      [
        [82654, 47023],
        [-7, -1],
        [-12, 11],
        [5, 24],
        [-7, 40],
        [2, 32],
        [16, 18],
        [33, 2],
        [1, -24],
        [-31, -102],
      ],
      [
        [82339, 49847],
        [-10, -99],
        [-17, 56],
        [19, 67],
        [9, 13],
        [-1, -37],
      ],
      [
        [85380, 52298],
        [-9, -18],
        [-9, 6],
        [-6, 24],
        [2, 18],
        [8, 11],
        [9, -8],
        [5, -33],
      ],
      [
        [87325, 50619],
        [-8, -8],
        [-3, 25],
        [5, 33],
        [5, 6],
        [6, -2],
        [8, -12],
        [-13, -42],
      ],
      [
        [87102, 49397],
        [14, -31],
        [-33, 24],
        [-47, 51],
        [-3, 34],
        [40, -52],
        [29, -26],
      ],
      [
        [86362, 51395],
        [-7, -30],
        [-14, -19],
        [-119, -36],
        [10, 21],
        [5, 21],
        [8, 11],
        [12, -3],
        [6, 7],
        [5, 2],
        [7, -3],
        [12, 9],
        [24, -6],
        [25, 5],
        [-2, 28],
        [28, -7],
      ],
      [
        [86284, 51538],
        [-16, -1],
        [-28, 25],
        [16, 22],
        [11, 5],
        [9, 13],
        [5, 0],
        [12, -11],
        [7, -19],
        [-16, -34],
      ],
      [
        [85985, 51735],
        [-12, -2],
        [-10, 34],
        [-28, 37],
        [-17, 65],
        [65, -107],
        [2, -27],
      ],
      [
        [85403, 51839],
        [-1, -17],
        [-9, 24],
        [-6, 6],
        [6, 62],
        [4, 11],
        [5, -43],
        [1, -43],
      ],
      [
        [85394, 52213],
        [-10, -7],
        [-3, 2],
        [-3, 24],
        [5, 39],
        [12, 0],
        [5, -6],
        [1, -29],
        [-7, -23],
      ],
      [
        [85360, 51393],
        [-3, -12],
        [-29, 15],
        [-8, 8],
        [15, 82],
        [14, -2],
        [12, -78],
        [-1, -13],
      ],
      [
        [85755, 49800],
        [0, -25],
        [-2, -8],
        [-15, 9],
        [-11, -1],
        [-10, 44],
        [3, 10],
        [20, -12],
        [7, -5],
        [8, -12],
      ],
      [
        [85711, 49777],
        [-48, -30],
        [11, 56],
        [6, 15],
        [24, -15],
        [7, -26],
      ],
      [
        [85199, 54075],
        [1, -25],
        [-17, 56],
        [-7, 65],
        [14, -23],
        [15, -49],
        [-6, -24],
      ],
      [
        [85236, 54013],
        [-5, -6],
        [-10, 15],
        [-6, 17],
        [1, 17],
        [7, 9],
        [14, -26],
        [0, -15],
        [-1, -11],
      ],
      [
        [84835, 53370],
        [-3, -13],
        [-11, 68],
        [9, 34],
        [12, -13],
        [3, -12],
        [-11, -32],
        [1, -32],
      ],
      [
        [86661, 47694],
        [-4, -28],
        [-12, 15],
        [-11, 33],
        [-17, 5],
        [-13, 8],
        [-7, 16],
        [47, 7],
        [17, -56],
      ],
      [
        [85741, 47705],
        [-13, -15],
        [-20, 30],
        [-6, 13],
        [13, 29],
        [14, 9],
        [9, -13],
        [4, -13],
        [-2, -14],
        [1, -26],
      ],
      [
        [85393, 47451],
        [-17, -13],
        [5, 43],
        [-1, 34],
        [29, -11],
        [0, -27],
        [-3, -10],
        [-13, -16],
      ],
      [
        [84281, 45908],
        [-25, -20],
        [0, 42],
        [20, 54],
        [17, 18],
        [10, -22],
        [1, -9],
        [-26, -20],
        [3, -43],
      ],
      [
        [83856, 45742],
        [-14, -7],
        [-30, 17],
        [-6, 10],
        [25, 28],
        [20, 39],
        [23, 4],
        [14, -8],
        [-5, -47],
        [-27, -36],
      ],
      [
        [87449, 48136],
        [-7, -4],
        [0, 28],
        [8, 25],
        [8, 14],
        [9, 1],
        [-18, -64],
      ],
      [
        [87409, 47955],
        [-5, -9],
        [-7, 18],
        [-1, 12],
        [10, 32],
        [9, 18],
        [11, 1],
        [-3, -26],
        [-14, -46],
      ],
      [
        [85551, 50151],
        [-14, -48],
        [-24, 2],
        [-4, 7],
        [29, 30],
        [13, 9],
      ],
      [
        [85445, 49933],
        [7, -25],
        [-28, 16],
        [-12, 25],
        [12, 15],
        [7, 4],
        [14, -35],
      ],
      [
        [84160, 46920],
        [-9, -34],
        [-16, 10],
        [4, 33],
        [8, 19],
        [22, 28],
        [22, 5],
        [13, -10],
        [4, -11],
        [-34, -11],
        [-14, -29],
      ],
      [
        [71402, 72067],
        [33, 36],
        [35, 37],
        [36, 38],
        [41, 44],
        [35, 37],
        [28, 31],
      ],
      [
        [71610, 72290],
        [1, -2],
        [2, -5],
        [12, -14],
        [12, -6],
        [14, 13],
        [18, 10],
        [9, -6],
        [1, -17],
        [-11, -82],
        [1, -33],
        [18, -66],
        [23, -109],
        [22, -102],
        [12, -66],
        [13, -28],
        [52, -28],
        [43, -23],
        [26, -37],
        [28, -36],
        [20, -22],
        [9, -29],
        [2, -25],
        [-1, -18],
        [-11, -22],
        [-50, -59],
        [-6, -18],
        [-1, -24],
        [10, -73],
        [6, -45],
        [1, -92],
        [4, -86],
        [17, -40],
        [15, -25],
        [8, -24],
        [18, -31],
        [15, -24],
        [13, -14],
        [6, -31],
        [-3, -37],
        [-6, -32],
        [2, -17],
        [10, -12],
        [16, -32],
        [2, -47],
        [-1, -32],
        [6, -29],
        [2, -32],
        [-5, -80],
        [1, -33],
        [0, -4],
        [-14, -2],
        [-12, -12],
        [-17, -51],
        [-19, -13],
        [-22, -4],
        [-22, 31],
        [-19, 32],
        [-5, 18],
        [-4, 34],
        [-10, 23],
        [-20, -11],
        [-29, -4],
        [-31, -8],
        [-6, -7],
        [-1, -15],
        [8, -30],
        [7, -40],
        [3, -56],
        [9, -37],
        [3, -12],
        [50, -111],
        [13, -23],
        [3, -14],
        [-4, -41],
        [-9, -47],
        [2, -38],
        [16, -41],
        [14, -29],
        [-13, -39],
        [-8, -45],
        [9, -21],
        [-5, -65],
        [4, -12],
        [10, -5],
        [15, 4],
        [15, 18],
        [13, 3],
        [7, -5],
        [11, 49],
        [9, 7],
        [17, -13],
        [35, -93],
        [30, -78],
        [8, -15],
        [6, -9],
        [29, -41],
        [20, -26],
        [27, 10],
        [36, 1],
        [22, -25],
        [12, -17],
        [1, -3],
        [2, 0],
        [43, -62],
        [19, 5],
        [12, -18],
        [4, -43],
        [-6, -46],
        [2, -21],
        [19, -4],
        [41, -30],
        [37, -26],
        [19, -9],
        [20, -19],
        [18, -32],
        [35, -40],
        [31, -31],
        [7, -42],
      ],
      [
        [74474, 67897],
        [-3, 20],
        [3, 16],
        [9, 9],
        [37, 12],
        [42, 24],
        [17, 13],
        [12, 14],
        [13, 20],
        [12, 0],
        [38, -30],
        [13, -19],
        [7, -58],
        [5, -22],
        [-5, -58],
        [-22, -142],
        [4, -53],
        [19, -38],
        [16, -27],
      ],
      [
        [74691, 67578],
        [-2, -11],
        [-34, -46],
        [-6, -24],
        [7, -24],
        [14, -20],
        [6, -20],
        [6, -60],
        [17, -16],
        [34, -39],
        [30, -28],
        [51, 18],
        [14, -12],
        [25, -14],
        [20, -4],
        [11, -10],
        [7, -9],
        [-1, -13],
        [1, -12],
        [28, -3],
        [15, -7],
        [49, 13],
        [50, 17],
        [23, 54],
        [11, 4],
        [28, 20],
        [29, -22],
        [31, -32],
        [17, -9],
        [33, -5],
        [32, 4],
        [77, 14],
        [43, -7],
        [39, 44],
        [8, 0],
        [17, -34],
        [43, -3],
        [22, 16],
        [25, 13],
        [16, 4],
        [12, 0],
        [15, -3],
        [15, 12],
        [6, 23],
        [-1, 34],
        [-11, 38],
        [-9, 22],
        [-1, 12],
        [3, 27],
        [7, 39],
        [15, 44],
        [-11, 43],
        [-15, 49],
        [-11, 5],
        [-28, -12],
        [-30, 3],
        [-23, 29],
        [-18, 37],
        [-4, 31],
        [5, 38],
        [8, 34],
        [2, 13],
      ],
      [
        [75453, 67833],
        [22, 0],
        [31, -7],
        [24, -10],
        [19, 0],
        [34, 45],
        [16, 2],
        [18, 9],
        [7, 8],
        [6, -6],
        [20, -6],
        [20, 3],
        [18, 12],
        [19, 19],
        [32, 40],
        [7, 23],
        [0, 21],
        [-6, 14],
        [-6, 7],
        [2, 18],
        [14, 31],
        [50, 47],
        [42, 57],
        [24, 43],
        [10, 52],
        [14, 57],
        [13, 22],
        [30, 14],
        [84, 21],
        [27, 23],
        [39, 42],
        [20, 33],
        [11, 27],
        [1, 30],
        [26, 10],
        [23, 48],
        [28, 49],
        [48, 41],
        [43, 56],
        [15, -9],
        [16, -26],
        [8, -29],
        [2, -15],
        [55, -18],
        [9, 3],
        [40, -26],
        [38, -32],
        [20, -8],
        [10, 1],
        [9, 10],
        [10, 28],
        [10, 20],
        [7, 8],
        [-1, 32],
        [54, 61],
        [49, 45],
        [42, 32],
        [12, -13],
        [14, -25],
        [18, -62],
        [11, -16],
        [29, 9],
        [5, -7],
        [-5, -22],
        [-19, -28],
        [-25, -26],
        [-16, -20],
        [5, -68],
        [-1, -24],
        [7, -7],
        [51, 67],
        [25, 14],
        [9, -16],
        [2, -37],
        [21, -74],
        [8, -38],
        [-51, -91],
        [-19, -47],
        [1, -16],
        [-1, -16],
        [-14, -24],
        [1, -9],
        [11, -15],
        [13, -11],
        [6, 1],
        [11, 22],
        [48, 31],
        [14, -6],
        [34, -48],
        [16, -3],
        [41, -14],
        [27, 18],
        [19, -16],
        [40, -60],
        [9, -11],
      ],
      [
        [77033, 68097],
        [-3, -36],
        [-2, -40],
        [10, -32],
        [1, -28],
        [-2, -25],
        [-8, -18],
        [-23, -10],
        [-18, -30],
        [-31, -45],
        [-24, -35],
        [-17, -32],
        [-7, -32],
        [2, -42],
        [5, -43],
        [56, -159],
        [0, -28],
        [-18, -8],
        [-23, 18],
        [-21, 26],
        [-22, 68],
        [-19, 21],
        [-18, 4],
        [-109, -35],
        [-23, -10],
        [-36, -25],
        [-25, -52],
        [-18, -47],
        [-19, -18],
        [-28, -37],
        [-76, -112],
        [-44, -48],
        [-29, -18],
        [-20, -25],
        [-11, -42],
        [-8, -29],
        [-3, -73],
        [5, -90],
        [11, -58],
        [6, -12],
        [1, -17],
        [-11, -31],
        [-15, -26],
        [-7, -17],
        [-6, -82],
        [-13, -40],
        [-23, -59],
        [-21, -45],
        [-33, -35],
        [-13, -28],
        [-12, -52],
        [-7, -44],
        [0, -16],
        [4, -14],
        [13, -16],
        [17, -15],
        [8, -23],
        [1, -28],
        [-12, -68],
        [-22, -95],
        [-26, -74],
        [-26, -71],
        [-6, -24],
        [-23, -87],
        [-21, -120],
        [-13, -81],
        [-12, -56],
        [-15, -2],
        [-18, 18],
        [-43, 23],
        [-27, 19],
        [-21, 17],
        [-14, 0],
        [-19, -12],
        [-19, -7],
        [-12, 8],
        [-27, 50],
        [-8, -6],
        [-5, -24],
        [18, -143],
        [12, -53],
        [-2, -88],
        [-5, -109],
        [-7, -119],
        [-4, -28],
        [-12, -31],
        [-15, -9],
        [-14, 13],
        [-11, -3],
        [-3, -20],
        [3, -52],
        [-14, -58],
        [-10, -51],
        [3, -49],
        [5, -49],
        [16, -108],
        [0, -39],
        [-3, -36],
        [-9, -14],
        [-14, 2],
        [-7, -15],
        [-6, -22],
        [-16, -81],
        [-16, -9],
        [-15, 12],
        [-23, 55],
        [-14, 16],
        [-9, -1],
        [-4, -15],
        [-6, -32],
        [-6, -22],
        [-16, -19],
      ],
      [
        [75714, 64503],
        [-3, 40],
        [-8, 209],
        [-7, 66],
        [-5, 92],
        [-7, 28],
        [-10, 51],
        [-10, 43],
        [-9, 19],
        [-5, 81],
        [-2, 99],
        [0, 47],
        [-13, 97],
        [-12, 110],
        [-16, -4],
        [-10, 26],
        [-7, 0],
        [-23, -25],
        [-18, 8],
        [-14, -3],
        [0, -51],
        [3, -54],
        [-6, -19],
        [-36, -64],
        [-9, -42],
        [1, -45],
        [4, -59],
        [-6, -31],
        [-16, -28],
        [-21, -14],
        [-18, 7],
        [-12, 24],
        [-11, 62],
        [-10, 34],
        [-10, 8],
        [-8, -9],
        [-1, -39],
        [0, -33],
        [-2, -3],
        [-6, 5],
        [-6, 16],
        [-17, 155],
        [-25, 119],
        [-1, 46],
        [8, 59],
        [11, 91],
        [29, 56],
        [4, 24],
        [5, 19],
        [7, 4],
        [37, -5],
        [13, 9],
        [11, 27],
        [16, 21],
        [16, 9],
        [13, 3],
        [20, -21],
        [9, 12],
        [6, 38],
        [9, 37],
        [5, 18],
        [14, 8],
        [18, 2],
        [5, 7],
        [5, 13],
        [4, 49],
        [23, 111],
        [8, 49],
        [1, 8],
        [-1, 55],
        [7, 8],
        [37, -27],
        [16, 1],
        [9, 11],
        [3, 20],
        [-5, 23],
        [-26, 41],
        [-47, 55],
        [-43, 34],
        [-80, -5],
        [-79, -11],
        [-23, 6],
        [-29, 15],
        [-70, -2],
        [-86, -9],
        [-32, 5],
        [-17, 0],
        [-32, -6],
        [-52, 16],
        [-37, 20],
        [-32, 22],
        [-38, 20],
        [-9, 0],
        [-5, 7],
        [-4, 18],
        [-1, 23],
        [8, 106],
        [-7, 161],
        [6, 59],
        [-31, 132],
        [-11, 25],
        [-15, 1],
        [-9, -17],
        [-3, -31],
        [5, -35],
        [-12, -38],
        [-23, -13],
        [-27, 13],
        [-22, 18],
        [-29, 40],
        [-21, 55],
        [-2, 61],
        [-10, 40],
        [-13, 19],
        [-10, 5],
        [-9, -4],
        [-8, -21],
        [7, -22],
        [9, -30],
        [-3, -20],
        [-8, -3],
        [-12, 9],
        [-19, -5],
        [-19, 16],
        [-11, 1],
        [-11, 6],
        [0, 35],
        [-17, 45],
        [-28, 50],
        [-28, 31],
        [-14, -4],
        [-6, -35],
        [1, -12],
        [10, -7],
        [14, -20],
        [3, -20],
        [-2, -19],
        [-17, -33],
        [-13, -31],
        [-27, -46],
        [-23, -52],
        [-6, -40],
        [-9, -36],
        [-4, -39],
        [6, -27],
        [12, -17],
        [29, -13],
        [31, -52],
        [24, -72],
        [14, -21],
        [26, -24],
        [48, -3],
        [8, -20],
        [7, -52],
        [9, -18],
        [25, -25],
        [2, -18],
        [-6, -21],
        [-11, -16],
        [-20, -11],
        [-20, -4],
        [-19, 6],
        [-29, 5],
        [-33, 0],
        [-23, -131],
        [-16, -46],
        [-10, 1],
        [-25, 22],
        [-11, -4],
        [-29, -116],
        [-4, -28],
        [-2, -21],
        [16, -45],
        [18, -37],
        [22, -14],
        [17, 11],
        [14, -15],
        [17, -37],
        [28, -25],
        [40, -12],
        [23, -29],
        [2, -25],
        [-1, -26],
        [-4, -67],
        [-4, -39],
        [-21, -102],
        [-16, -87],
        [8, -42],
        [6, -17],
        [5, -13],
        [17, -33],
        [12, -32],
        [-10, -83],
        [6, -22],
        [23, -14],
        [25, -11],
        [9, -14],
        [-22, -84],
        [5, -59],
        [9, -55],
        [6, -90],
        [1, -9],
        [-2, -23],
        [15, -69],
        [21, -136],
        [2, -51],
        [-1, -54],
      ],
      [
        [74736, 64569],
        [-7, -90],
        [-22, 1],
        [20, -60],
        [6, -44],
        [3, -60],
        [-24, -7],
        [-16, 7],
        [-14, 52],
        [-7, -48],
        [-24, -44],
        [-9, 22],
        [-5, 23],
        [-1, 41],
        [13, 157],
        [-2, 17],
        [-7, 12],
        [-13, 6],
        [-5, 32],
        [-21, -167],
        [9, -68],
        [-4, -32],
        [-38, -26],
        [-39, 63],
        [-5, 20],
        [-3, -35],
        [-7, -43],
        [-36, 8],
        [-19, 33],
        [12, 58],
        [23, 137],
        [4, 62],
        [-30, 45],
        [-26, 27],
        [-15, 63],
        [6, -69],
        [14, -24],
        [20, -17],
        [21, -35],
        [-15, -43],
        [-15, -27],
        [-29, -101],
        [-34, -57],
        [-41, -42],
        [-132, -63],
        [-28, -25],
        [-41, -78],
        [-26, -74],
        [-5, -75],
        [15, -81],
        [12, -127],
        [10, -26],
        [-14, -47],
        [-25, -49],
        [-20, -66],
        [2, -37],
        [-5, -24],
        [-70, -82],
        [-15, -47],
        [-19, -48],
        [-23, 27],
        [-14, 0],
        [19, -38],
        [-3, -25],
        [-6, -14],
        [-18, -14],
        [-101, -60],
        [-77, -57],
        [-22, 3],
        [4, 17],
        [14, 15],
        [-1, 66],
        [-15, 12],
        [-12, 4],
        [-59, -79],
        [-23, -79],
        [5, -15],
        [13, 4],
        [39, 44],
        [19, -12],
        [1, -18],
        [-60, -68],
        [-126, -220],
        [-6, -44],
        [-17, -49],
        [-22, -46],
        [-41, -112],
        [-78, -167],
        [-21, -62],
        [-125, -128],
        [-23, -39],
        [-51, -125],
        [-53, -102],
        [-62, -85],
        [-106, -108],
        [-65, -102],
        [-20, -68],
        [-2, -25],
        [7, -33],
        [12, -30],
        [3, -25],
        [-6, -44],
        [-3, -24],
        [-19, -60],
        [-33, -43],
        [-105, -90],
        [-14, 3],
        [-86, 18],
        [-32, -17],
        [-13, -42],
        [-30, -174],
        [-28, -46],
        [-11, -42],
        [-4, -29],
        [-17, 1],
        [-14, 13],
        [-11, -10],
        [-12, 59],
        [-21, 12],
        [-17, 4],
        [-73, -59],
        [-25, -47],
        [-53, -223],
        [-14, -144],
        [13, -159],
        [18, -127],
        [4, -57],
        [-2, -75],
        [-10, -36],
        [-6, -43],
        [8, -88],
        [23, -116],
        [5, -48],
        [1, -51],
        [17, -115],
        [-12, 20],
        [-9, 49],
        [-21, 62],
        [-26, -62],
        [14, -44],
        [49, -53],
        [15, -44],
        [-32, -386],
        [-24, -138],
        [-29, -90],
        [-16, -34],
        [-34, -142],
        [-24, -172],
        [-5, -67],
        [11, -74],
        [-12, -43],
        [-16, -34],
        [30, 15],
        [10, -40],
        [3, -42],
        [1, -246],
        [-3, -257],
        [-23, -11],
        [-25, -2],
        [-22, 7],
        [-16, 10],
        [-39, -14],
        [-21, -28],
        [-17, -47],
        [1, -81],
        [-72, -203],
        [-16, -67],
        [-6, -65],
        [10, -34],
        [18, -35],
        [24, -14],
        [47, -14],
        [23, -19],
        [15, -34],
        [-55, 36],
        [-65, 8],
        [-155, -95],
        [-41, -66],
        [-23, -57],
        [-15, -131],
        [-3, -88],
        [-18, -73],
        [-81, -112],
        [-51, -34],
        [-19, -30],
        [-60, 39],
        [-66, 98],
        [-27, 53],
        [-97, 253],
        [-18, 32],
        [-19, 108],
        [-4, 41],
        [-5, 16],
        [-9, 11],
        [-5, 16],
        [-21, 124],
        [-9, 130],
        [-14, 144],
        [11, -10],
        [17, -47],
        [8, -70],
        [1, -96],
        [12, -11],
        [11, 9],
        [-31, 222],
        [-28, 55],
        [-7, 4],
        [-7, 36],
        [-1, 44],
        [2, 22],
        [-21, 72],
        [-8, 44],
        [-48, 220],
        [-22, 157],
        [-33, 175],
        [-22, 62],
        [-34, 135],
        [-28, 63],
        [-30, 84],
        [-24, 38],
        [-9, 19],
        [-70, 292],
        [-21, 162],
        [-18, 76],
        [-9, 58],
        [-25, 247],
        [0, 44],
        [-3, 49],
        [-17, 105],
        [-31, 113],
        [-9, 70],
        [1, 28],
        [-20, 110],
        [-4, 50],
        [-13, 47],
        [-15, 42],
        [-16, 34],
        [-37, 112],
        [-14, 27],
        [-25, 72],
        [-18, 134],
        [-23, 52],
        [36, 0],
        [-22, 49],
        [-11, 32],
        [-12, 20],
        [17, 50],
        [-27, -1],
        [-15, 30],
        [-20, 93],
        [-37, 105],
        [-6, 57],
        [-32, 177],
        [-27, 426],
        [-26, 189],
        [2, 54],
        [-30, 165],
        [-15, 110],
        [-6, 93],
        [-8, 61],
        [-7, 121],
        [-12, 39],
        [-1, 23],
        [8, 55],
        [22, 85],
        [8, 54],
        [-10, 77],
        [-20, -80],
        [-18, -23],
        [-9, 60],
        [0, 80],
        [-2, 20],
        [5, 27],
        [49, -13],
        [-56, 49],
        [-6, 29],
        [-3, 22],
        [12, 40],
        [-20, 33],
        [-8, 103],
        [-6, 24],
        [-2, 19],
        [11, 142],
        [48, 280],
        [4, 63],
        [-5, 90],
        [-10, 71],
        [-5, 76],
        [-3, 19],
        [-17, 7],
        [-16, 28],
        [-19, 112],
        [17, 36],
        [13, 21],
        [-18, -9],
        [-15, 3],
        [29, 52],
        [25, 39],
        [59, 46],
        [25, 30],
        [-37, -27],
        [-38, -10],
        [-83, 6],
        [14, 104],
        [14, 35],
        [16, 19],
        [-23, -6],
        [-27, 9],
        [9, 106],
        [21, 22],
        [22, 5],
        [28, 15],
        [-30, 17],
        [-31, 9],
        [-37, -18],
        [-34, 13],
        [-42, 0],
        [17, -14],
        [17, -33],
        [-8, -57],
        [-9, -36],
        [-23, -24],
        [-18, -38],
        [-6, -32],
        [-10, -24],
        [18, -16],
        [19, -12],
        [11, -26],
        [13, -39],
        [-1, -75],
        [-49, -177],
        [-17, -39],
        [-124, -107],
        [-48, -58],
        [-104, -75],
        [-40, -14],
        [-44, 15],
        [-66, 57],
        [-99, 147],
        [-26, 48],
        [-79, 189],
        [-57, 99],
        [-44, 93],
        [-54, 88],
        [-51, 118],
        [-10, 54],
        [3, 54],
        [19, 30],
        [22, -12],
        [18, -46],
        [12, -21],
        [11, -8],
        [76, 71],
        [29, -3],
        [20, 35],
        [25, -7],
        [52, 55],
        [22, 3],
        [26, 11],
        [42, 140],
        [31, 89],
        [20, 19],
        [-1, 22],
        [-5, 28],
        [-16, -7],
        [-10, -27],
        [-8, -33],
        [-8, -19],
        [-24, 18],
        [-17, -3],
        [-20, -11],
        [-75, -52],
        [-31, -47],
        [-20, -9],
        [-119, 51],
        [-117, 118],
        [-49, 79],
        [-31, 100],
        [-31, 120],
        [10, 33],
        [48, 72],
        [42, 56],
        [-37, -25],
        [-41, -35],
        [-20, -24],
        [-22, -51],
        [-31, -12],
        [-11, 76],
        [-8, 74],
      ],
      [
        [76080, 55778],
        [-17, -48],
        [-33, 145],
        [-15, 9],
        [0, 69],
        [8, 28],
        [38, 30],
        [10, -17],
        [20, -134],
        [-11, -82],
      ],
      [
        [76036, 56080],
        [-26, -54],
        [-12, 33],
        [5, 22],
        [11, 13],
        [11, 17],
        [11, -31],
      ],
      [
        [75872, 56595],
        [8, -22],
        [-15, 4],
        [-15, 32],
        [4, 31],
        [6, 12],
        [12, -57],
      ],
      [
        [75956, 56381],
        [-22, -1],
        [-6, 24],
        [-9, 26],
        [6, 25],
        [12, 6],
        [16, -40],
        [4, -28],
        [-1, -12],
      ],
      [
        [75982, 56484],
        [-13, -22],
        [-4, 3],
        [-1, 16],
        [-1, 12],
        [-2, 20],
        [-2, 37],
        [11, 30],
        [10, -6],
        [-5, -31],
        [7, -59],
      ],
      [
        [75774, 57106],
        [-13, -4],
        [-7, 20],
        [-1, 23],
        [7, 15],
        [6, 7],
        [7, -1],
        [7, -39],
        [-6, -21],
      ],
      [
        [75694, 57923],
        [-8, -20],
        [-29, 15],
        [3, 60],
        [-7, 58],
        [5, 24],
        [21, 42],
        [18, 18],
        [12, -56],
        [5, -55],
        [-20, -86],
      ],
      [
        [75747, 58399],
        [-13, -12],
        [-14, 15],
        [11, 23],
        [2, 47],
        [13, -26],
        [0, -30],
        [1, -17],
      ],
      [
        [75756, 58488],
        [-7, -14],
        [-9, 15],
        [-25, 104],
        [-5, 66],
        [-7, 23],
        [9, 33],
        [12, 11],
        [6, 37],
        [3, 57],
        [10, 46],
        [5, 13],
        [20, 0],
        [6, 6],
        [-3, 44],
        [-12, 20],
        [-4, 12],
        [0, 106],
        [3, 43],
        [8, 30],
        [-6, 64],
        [4, 24],
        [15, 33],
        [7, 72],
        [-6, 21],
        [14, 110],
        [-1, 73],
        [19, 74],
        [29, 33],
        [9, 1],
        [1, -62],
        [3, -21],
        [-17, -37],
        [16, -49],
        [-2, -17],
        [-6, -39],
        [-11, -37],
        [-15, -16],
        [-11, -50],
        [-7, -19],
        [22, -53],
        [7, -180],
        [-16, -49],
        [-19, -10],
        [4, -120],
        [-3, -26],
        [-19, -59],
        [-4, -26],
        [-11, -24],
        [5, -30],
        [9, -13],
        [0, -25],
        [-8, -64],
        [-1, -72],
        [-11, -59],
      ],
      [
        [75837, 58776],
        [13, -79],
        [-22, 35],
        [-8, 24],
        [11, 17],
        [6, 3],
      ],
      [
        [75754, 59253],
        [-9, -37],
        [-1, 80],
        [4, 10],
        [4, 3],
        [6, -8],
        [-4, -48],
      ],
      [
        [70216, 58296],
        [-2, -4],
        [0, 10],
        [2, 17],
        [3, 11],
        [1, -1],
        [0, -11],
        [-2, -15],
        [-2, -7],
      ],
      [
        [70296, 56606],
        [-4, -7],
        [-4, -3],
        [-3, 1],
        [-1, 7],
        [0, 6],
        [4, -7],
        [5, 6],
        [5, 18],
        [1, 6],
        [1, -3],
        [-1, -10],
        [-3, -14],
      ],
      [
        [45682, 89993],
        [32, -3],
        [52, 20],
        [22, 13],
        [53, 45],
        [32, 13],
        [49, -3],
        [23, 3],
        [1, -5],
        [-30, -18],
        [-24, -6],
        [-35, -27],
        [-32, -62],
        [-25, -30],
        [1, -13],
        [29, -24],
        [32, -13],
        [30, 12],
        [13, -5],
        [12, -17],
        [6, -18],
        [2, -17],
        [-6, -36],
        [-17, -37],
        [-24, -30],
        [3, -10],
        [19, -5],
        [92, 20],
        [10, -1],
        [5, -10],
        [1, -19],
        [5, -16],
        [9, -15],
        [-3, -15],
        [-40, -48],
        [48, 30],
        [37, 9],
        [65, -15],
        [26, -18],
        [15, -30],
        [23, 10],
        [9, -1],
        [15, -17],
        [0, -18],
        [-10, -27],
        [-4, -24],
        [-11, -10],
        [-21, -7],
        [-6, -9],
        [9, -18],
        [14, -18],
        [19, -1],
        [3, -9],
        [1, -10],
        [-3, -12],
        [-6, -8],
        [-10, -4],
        [-13, -13],
        [48, -29],
        [6, -10],
        [1, -16],
        [-4, -17],
        [-8, -18],
        [-15, -11],
        [-34, -2],
        [-22, -12],
        [7, -20],
        [0, -26],
        [-6, -29],
        [-28, -46],
        [-26, -24],
        [-25, -15],
        [-45, 5],
        [-24, 12],
        [2, -39],
        [-25, -24],
        [5, -20],
        [9, -10],
        [-5, -26],
        [-11, -26],
        [-20, -27],
        [-23, -17],
        [-45, -21],
        [-38, -35],
        [-26, -14],
        [-65, 1],
        [-67, -22],
        [-93, -47],
        [-64, -38],
        [-48, -43],
        [-65, -69],
        [-48, -30],
        [-27, -7],
        [-54, -7],
        [-45, -19],
        [-150, -35],
        [-50, -19],
        [-7, -18],
        [-21, -27],
        [-1, -9],
        [9, -8],
        [2, -9],
        [-19, -32],
        [-37, -22],
        [-17, 0],
        [-21, 20],
        [-10, -1],
        [-3, -3],
        [0, -6],
        [12, -24],
        [-23, -10],
        [-97, -28],
        [-166, 20],
        [-65, 22],
        [-81, 33],
        [-49, 9],
        [-68, 2],
        [-56, 47],
        [-26, 29],
        [-2, 12],
        [3, 14],
        [5, 9],
        [9, 5],
        [19, 0],
        [2, 5],
        [-14, 23],
        [-14, -8],
        [-36, -33],
        [-16, 2],
        [-21, 16],
        [-1, 16],
        [-41, 6],
        [-36, 20],
        [-36, 28],
        [-5, 11],
        [17, 16],
        [-3, 3],
        [-13, 3],
        [-26, -5],
        [-39, -36],
        [-17, -9],
        [-257, -8],
        [-65, -4],
        [-13, -5],
        [-11, 23],
        [-10, 53],
        [-4, 34],
        [3, 17],
        [9, 20],
        [14, -4],
        [13, -15],
        [12, -23],
        [14, -11],
        [89, 28],
        [37, 18],
        [15, 18],
        [18, 29],
        [20, 16],
        [9, 14],
        [18, 46],
        [13, 21],
        [14, 16],
        [18, 10],
        [40, 7],
        [-27, 11],
        [-24, 0],
        [-85, -48],
        [-28, 0],
        [1, 7],
        [12, 14],
        [29, 24],
        [-20, 1],
        [-8, 11],
        [-1, 22],
        [15, 37],
        [69, 47],
        [24, 7],
        [7, 10],
        [-9, 7],
        [-14, 5],
        [-70, -49],
        [-50, -17],
        [-15, 3],
        [-26, 19],
        [-8, 9],
        [-12, 22],
        [2, 13],
        [24, 38],
        [-4, 8],
        [-17, 3],
        [-44, 36],
        [-71, -4],
        [-174, 21],
        [-36, -9],
        [-59, -30],
        [-36, -10],
        [-16, 6],
        [-15, 16],
        [-14, 22],
        [-12, 27],
        [5, 19],
        [23, 11],
        [17, 5],
        [47, -6],
        [58, 19],
        [37, 4],
        [10, 3],
        [22, 20],
        [11, 5],
        [16, -7],
        [8, -14],
        [59, 22],
        [20, 10],
        [2, 7],
        [9, 8],
        [28, -12],
        [24, 0],
        [29, 8],
        [52, 3],
        [115, 2],
        [18, 18],
        [8, 15],
        [10, 39],
        [-4, 8],
        [-73, -36],
        [-16, 1],
        [-84, 19],
        [-30, 21],
        [10, 18],
        [44, 37],
        [46, 30],
        [67, 32],
        [16, 13],
        [2, 15],
        [-45, 26],
        [-85, -7],
        [-22, 31],
        [-70, 19],
        [-47, -12],
        [-25, 19],
        [-61, -26],
        [-134, -38],
        [-54, -27],
        [-28, -8],
        [-33, 21],
        [-57, 25],
        [-64, 7],
        [-6, 14],
        [37, 44],
        [26, 8],
        [26, -4],
        [49, -30],
        [34, -10],
        [-43, 45],
        [1, 17],
        [-3, 26],
        [-13, 11],
        [-13, 29],
        [5, 9],
        [17, 4],
        [34, -10],
        [82, -50],
        [40, 9],
        [22, 18],
        [29, 14],
        [-8, 7],
        [-70, 1],
        [-38, 10],
        [-19, 14],
        [-17, 25],
        [6, 11],
        [20, 10],
        [60, -3],
        [-40, 43],
        [-27, 24],
        [-3, 12],
        [2, 15],
        [3, 10],
        [6, 5],
        [69, -25],
        [15, -1],
        [-14, 16],
        [-30, 24],
        [-2, 9],
        [13, 7],
        [6, 14],
        [1, 11],
        [21, 9],
        [21, 1],
        [21, -9],
        [66, -46],
        [10, -13],
        [3, -17],
        [-3, -22],
        [2, -8],
        [26, 7],
        [21, -9],
        [10, 2],
        [26, 32],
        [17, -7],
        [11, -15],
        [3, -14],
        [2, -18],
        [-5, -39],
        [1, -5],
        [18, 22],
        [31, 1],
        [4, 11],
        [1, 41],
        [-3, 34],
        [-3, 7],
        [-101, 48],
        [-17, 11],
        [-22, 24],
        [5, 11],
        [19, 11],
        [30, 4],
        [68, -1],
        [7, 5],
        [-13, 13],
        [-32, 8],
        [-7, 7],
        [-4, 14],
        [-38, -8],
        [-42, 0],
        [-40, 8],
        [-1, 11],
        [16, 15],
        [33, 26],
        [15, 7],
        [46, -5],
        [46, 8],
        [37, -9],
        [29, -26],
        [42, -45],
        [57, -29],
        [5, -9],
        [30, -24],
        [60, -63],
        [60, -37],
        [3, -10],
        [-10, -11],
        [-23, -13],
        [5, -7],
        [31, -9],
        [21, -25],
        [2, -11],
        [-20, -78],
        [-10, -16],
        [-13, -9],
        [-56, 15],
        [14, -25],
        [40, -26],
        [9, -15],
        [-6, -14],
        [4, -4],
        [15, 8],
        [6, -8],
        [-3, -24],
        [-6, -20],
        [-10, -16],
        [3, -7],
        [16, 2],
        [14, -4],
        [23, -22],
        [19, -67],
        [9, -21],
        [7, 19],
        [8, 49],
        [8, 25],
        [7, 1],
        [7, 8],
        [5, 15],
        [11, 55],
        [38, 41],
        [18, 12],
        [16, 3],
        [9, -5],
        [28, -43],
        [17, -7],
        [9, 2],
        [12, 29],
        [15, 56],
        [3, 63],
        [-8, 69],
        [5, 49],
        [18, 30],
        [23, 9],
        [29, -12],
        [22, -18],
        [42, -68],
        [34, -36],
        [29, -39],
        [15, -12],
        [29, -7],
        [7, 3],
        [6, 9],
        [2, 15],
        [-6, 98],
        [8, 31],
        [12, 22],
        [53, 12],
        [28, 14],
        [28, 22],
        [22, 12],
        [19, 2],
        [19, -9],
        [19, -19],
        [31, -37],
        [39, -61],
        [50, -46],
        [26, -73],
        [5, -13],
        [6, -1],
        [7, 9],
        [4, 14],
        [1, 32],
        [-14, 43],
        [-46, 109],
        [-1, 20],
        [6, 17],
        [33, 1],
        [75, -9],
        [25, -17],
        [51, -66],
        [15, -16],
        [8, -5],
        [4, 9],
        [20, 12],
        [13, 15],
        [23, 36],
        [51, 66],
        [10, 2],
        [15, -5],
        [26, -18],
        [12, -13],
        [24, -11],
        [25, 4],
        [35, 23],
        [38, 14],
        [14, 32],
        [2, 15],
        [-31, 97],
        [13, 20],
        [68, 24],
        [59, 2],
        [14, -7],
        [38, -47],
        [25, -23],
        [13, -19],
        [3, -42],
        [15, -16],
        [29, -17],
      ],
      [
        [55251, 78301],
        [-1, 0],
        [-19, -12],
        [-31, -7],
        [-16, 5],
        [-28, -54],
        [-8, -10],
        [-27, -17],
        [-22, -8],
        [-19, 7],
        [-7, 0],
        [-84, 3],
        [-43, 12],
        [-28, 21],
        [-19, 23],
        [-9, 27],
        [-21, 15],
        [-34, 6],
        [-27, 26],
        [-19, 46],
        [-26, 37],
        [-32, 27],
        [-26, 38],
        [-19, 50],
        [-34, 44],
        [-50, 40],
        [-15, 8],
      ],
      [
        [54470, 78838],
        [44, 62],
        [22, 18],
        [26, -3],
        [8, 5],
        [2, 9],
        [4, 21],
        [3, 19],
        [2, 18],
        [-3, 10],
        [-12, 3],
        [-6, 45],
        [6, 17],
        [6, 12],
        [-7, 54],
        [2, 18],
        [20, 3],
        [16, 12],
        [14, 13],
        [4, 17],
        [11, 34],
        [-10, 42],
        [-58, 27],
        [-3, 11],
        [13, 12],
        [15, 17],
        [8, 13],
        [11, 2],
        [16, -7],
        [28, -30],
        [10, -5],
        [11, 9],
        [11, 2],
        [30, -1],
        [26, 7],
        [-6, 32],
        [0, 24],
        [-4, 19],
        [3, 20],
        [10, 16],
        [3, 36],
        [17, 25],
      ],
      [
        [25739, 59319],
        [2, 61],
        [-23, 25],
        [-12, 50],
        [-11, 34],
        [3, 21],
        [-1, 24],
        [-31, 19],
        [-30, -14],
        [-17, 9],
        [-12, 13],
      ],
      [
        [25607, 59561],
        [21, 30],
        [2, 18],
        [-7, 14],
        [-7, 8],
        [2, 34],
        [4, 40],
        [12, 94],
        [-4, 17],
        [-20, 28],
        [-25, 3],
        [-27, -9],
        [-13, 14],
        [-12, 32],
        [-20, 16],
        [-34, -26],
        [-37, -39],
        [-11, -14],
        [-10, 2],
        [-4, 29],
        [-2, 35],
        [-2, 8],
        [-20, 12],
        [-23, 9],
        [-11, 10],
        [-11, 23],
        [-28, 30],
        [-6, 22],
        [-37, 52],
        [-7, 25],
        [-8, 19],
        [-18, 23],
        [-14, -5],
        [-46, 29],
        [-7, 3],
      ],
      [
        [25177, 60147],
        [6, 26],
        [15, 39],
        [32, 45],
        [2, 36],
        [-8, 68],
        [-8, 45],
        [4, 20],
        [10, 80],
        [8, 19],
        [46, 40],
        [4, 6],
        [37, 57],
        [40, 63],
        [42, 69],
        [47, 78],
        [26, 45],
        [12, 20],
      ],
      [
        [25492, 60903],
        [27, -16],
        [21, 37],
        [12, 12],
        [29, 44],
        [9, 10],
        [48, 18],
        [23, -1],
        [21, -44],
        [16, -25],
        [30, 21],
        [26, 5],
        [105, -42],
        [42, 19],
        [76, 4],
        [35, -11],
        [49, 59],
        [31, 12],
        [37, 27],
        [-5, 29],
        [-9, 12],
        [56, -12],
        [83, -60],
        [89, 11],
        [32, 32],
        [21, 9],
        [91, -61],
        [24, -47],
        [19, -4],
        [14, 10],
        [4, 10],
        [-18, 10],
        [-8, 15],
        [72, -29],
        [135, -222],
        [3, -18],
        [-58, 65],
        [-31, -5],
        [-8, -10],
        [2, -36],
        [3, -17],
        [13, -2],
        [10, 10],
        [24, -12],
        [15, -24],
        [19, -36],
        [12, -40],
        [12, -1],
        [12, 24],
        [23, 3],
        [15, -26],
        [11, 1],
        [-15, 42],
        [-35, 41],
        [9, 1],
        [77, -74],
        [21, -92],
        [19, -21],
        [18, -29],
      ],
      [
        [25994, 61277],
        [-44, -45],
        [-14, 1],
        [20, 35],
        [33, 30],
        [28, 14],
        [23, -6],
        [-46, -29],
      ],
      [
        [26147, 61325],
        [-21, -33],
        [-4, 15],
        [10, 31],
        [13, 17],
        [12, -1],
        [-3, -14],
        [-7, -15],
      ],
      [
        [30061, 63201],
        [6, -17],
        [13, -116],
        [1, -38],
        [-13, -56],
        [2, -22],
        [28, -52],
        [0, -18],
        [-3, -19],
        [-24, -49],
        [-18, -34],
        [6, -39],
        [15, -36],
        [2, -31],
        [-5, -41],
        [-22, -50],
        [-12, -18],
        [-34, -2],
        [-3, -7],
        [16, -49],
        [19, -56],
        [31, -43],
        [7, -41],
        [-8, -38],
        [-1, -95],
      ],
      [
        [30064, 62234],
        [-23, 46],
        [-26, 38],
        [-16, 15],
        [-16, 10],
        [-123, -5],
        [-14, -7],
        [-11, -12],
        [-11, -6],
        [-34, -12],
        [-34, -2],
        [-79, 31],
        [-31, 16],
        [-31, 10],
        [-36, -3],
        [-36, -10],
        [-29, -22],
        [-21, -40],
        [-4, -36],
        [-13, -10],
        [-29, 59],
        [-27, 41],
        [-30, 31],
        [-62, 45],
        [-12, 27],
        [-5, 32],
        [26, 101],
        [28, 19],
        [16, 3],
        [35, -12],
        [35, -23],
        [31, -15],
        [49, -6],
        [27, -25],
        [187, -38],
        [35, -12],
        [14, 4],
        [12, 15],
        [10, 27],
        [12, 21],
        [56, 4],
        [11, 9],
        [8, 29],
        [0, 29],
        [-33, 40],
        [-51, 86],
        [-45, 103],
        [20, 34],
        [-8, 63],
        [8, 58],
        [10, 57],
        [-44, 49],
        [-53, 49],
        [-73, 15],
        [-22, 13],
        [-12, 36],
        [11, 49],
        [23, 28],
        [27, 16],
        [28, 12],
        [67, 14],
        [67, -16],
        [57, -50],
        [59, -40],
        [73, -13],
        [34, -14],
        [15, 12],
      ],
      [
        [29776, 62659],
        [-5, -40],
        [-71, 48],
        [-57, 61],
        [2, 33],
        [30, 7],
        [28, -20],
        [41, -40],
        [32, -49],
      ],
      [
        [29815, 63385],
        [12, -13],
        [-5, -17],
        [-28, 10],
        [-29, 19],
        [-9, -5],
        [-6, 2],
        [-17, 18],
        [15, 14],
        [15, 4],
        [17, -1],
        [35, -31],
      ],
      [
        [33328, 56767],
        [10, -9],
        [40, -92],
        [23, -20],
        [4, 0],
        [0, 24],
        [21, -10],
        [52, -63],
        [77, -103],
        [108, -195],
        [31, -75],
        [20, -35],
        [33, -85],
        [9, -41],
        [-1, -166],
        [-28, -112],
        [-7, -84],
        [-2, -113],
        [-16, -64],
        [22, 35],
        [6, 101],
        [19, 62],
        [24, 67],
        [33, 16],
        [35, -28],
        [28, -5],
        [24, -20],
        [53, -108],
        [52, -86],
        [18, -68],
        [55, -34],
        [32, -54],
        [10, -47],
        [7, -122],
        [-11, -185],
        [3, -9],
      ],
      [
        [34310, 52961],
        [-12, -8],
        [-10, -12],
        [-15, 9],
        [-21, -5],
        [-21, -12],
        [-19, -7],
        [-37, 21],
        [-11, 2],
        [-8, 9],
        [-15, 40],
        [-8, 5],
        [-19, -19],
        [-24, -13],
        [-12, 3],
        [-14, -14],
        [-12, -18],
        [-25, -78],
        [-12, -27],
        [-14, -13],
        [-27, 1],
        [-29, -3],
        [-21, -19],
        [-21, -10],
        [-10, -1],
        [-3, -42],
        [-5, -20],
        [-6, -12],
        [-16, -3],
        [-14, 1],
        [-9, 18],
        [-15, 9],
        [-15, 7],
        [-9, 10],
        [-7, -3],
        [-6, -18],
        [-5, -15],
        [-4, -28],
        [-22, -9],
        [-9, -16],
        [5, -52],
        [-2, -20],
        [-5, -16],
        [-26, -4],
        [-22, 2],
        [-12, -20],
        [-16, -22],
        [-10, -4],
        [-11, 1],
        [-15, 26],
        [-14, 32],
        [-37, 23],
        [-36, 18],
        [-24, 51],
        [-6, 25],
        [-11, 11],
        [-28, 61],
        [-16, 39],
        [-17, 10],
        [-19, 17],
        [0, 28],
        [-1, 27],
        [-8, 11],
        [-12, 7],
        [-4, 15],
        [1, 36],
        [2, 92],
        [-3, 88],
        [-26, 30],
        [-11, 21],
        [-20, 130],
        [-9, 58],
        [-1, 44],
        [7, 129],
        [7, 56],
        [20, 113],
        [12, 38],
        [1, 28],
        [-2, 37],
        [-6, 72],
        [35, 46],
        [14, 19],
        [3, 30],
        [18, 39],
        [8, 37],
        [7, 29],
        [-2, 15],
        [-8, 9],
        [-10, 27],
        [-19, 79],
        [-7, 16],
        [-6, 22],
        [3, 35],
        [7, 38],
        [-1, 16],
        [-11, 21],
        [-25, 34],
        [-20, 2],
        [-16, 13],
        [-23, 1],
        [-18, 4],
        [-10, 13],
        [2, 21],
        [4, 16],
        [16, 40],
        [10, 42],
        [2, 42],
        [3, 55],
        [4, 47],
        [3, 54],
        [-25, 35],
        [-7, 29],
        [-10, 25],
        [-11, 0],
        [-17, 11],
        [-26, -33],
        [-21, 6],
        [-14, -13],
        [-32, 3],
        [-21, 16],
        [-25, -11],
      ],
      [
        [46186, 59143],
        [-1, -46],
        [16, -66],
        [-2, -49],
        [-7, -47],
        [-6, -18],
        [-9, -11],
        [-25, 1],
        [-10, -10],
        [-17, -18],
        [0, -21],
        [13, -21],
        [11, -28],
        [13, -23],
        [21, -25],
        [2, -29],
        [1, -72],
        [-1, -57],
        [-61, -41],
        [-47, -7],
        [-40, 4],
        [-17, -17],
        [-35, -43],
        [-42, -25],
        [-22, -2],
        [-10, -15],
        [-17, -44],
        [-46, -192],
        [-15, -46],
        [-12, -30],
      ],
      [
        [45821, 58145],
        [-14, 40],
        [11, 76],
        [-12, -1],
        [-23, -61],
        [-12, -2],
        [2, 72],
        [-13, 3],
        [-15, -5],
        [-21, 37],
        [-2, 28],
        [1, 40],
        [13, 25],
        [-2, 10],
        [-12, 3],
        [-14, -6],
        [-8, 11],
        [14, 51],
        [49, 43],
        [24, 5],
        [26, 9],
        [-14, 37],
        [-30, 15],
        [-24, -10],
        [-12, -27],
        [-15, -5],
        [-25, 63],
        [1, 31],
        [9, 38],
        [14, 16],
        [57, 0],
        [22, 21],
        [9, 4],
        [8, 19],
        [-2, 12],
        [-9, 1],
        [-21, -25],
        [-69, 10],
        [-22, -15],
        [-38, -58],
        [-47, -31],
        [-34, 13],
        [11, 77],
        [-5, 10],
        [-10, 13],
        [-50, -25],
        [-38, 35],
        [-15, 43],
        [3, 53],
        [17, 36],
        [3, 18],
        [-19, 3],
        [-34, -22],
        [-77, 86],
      ],
      [
        [45523, 58213],
        [-22, -8],
        [-10, 28],
        [-2, 11],
        [12, 10],
        [5, 0],
        [9, 21],
        [11, 14],
        [5, 5],
        [5, -1],
        [4, -46],
        [-5, -20],
        [-12, -14],
      ],
      [
        [45584, 58227],
        [-3, -16],
        [-16, 2],
        [4, 16],
        [-4, 5],
        [5, 49],
        [2, 7],
        [8, -18],
        [1, -8],
        [3, -37],
      ],
      [
        [45631, 58303],
        [0, -23],
        [-11, 4],
        [-4, 7],
        [7, 43],
        [11, 19],
        [12, -3],
        [4, -6],
        [-2, -16],
        [-6, -14],
        [-11, -11],
      ],
      [
        [45679, 58488],
        [-2, -13],
        [-16, 11],
        [23, 49],
        [15, 8],
        [-1, -37],
        [-11, -8],
        [-8, -10],
      ],
      [
        [45582, 58447],
        [-13, -18],
        [-13, 9],
        [-7, 16],
        [1, 29],
        [15, 41],
        [14, -6],
        [3, -71],
      ],
      [
        [45559, 58687],
        [-15, -70],
        [-17, 7],
        [-13, 42],
        [-1, 18],
        [36, 6],
        [10, -3],
      ],
      [
        [46307, 57055],
        [-3, 17],
        [10, 53],
        [-8, 28],
        [-28, 55],
        [-2, 27],
        [-9, 34],
        [-36, 71],
        [-35, -4],
        [10, 59],
        [-1, 79],
        [-11, 43],
        [3, 44],
        [-7, -2],
        [-11, -31],
        [-18, 10],
        [-38, 47],
        [-18, 46],
        [-3, 38],
        [-4, 15],
        [-11, -8],
        [-24, 1],
        [-71, 69],
        [-51, 174],
        [-1, 39],
        [7, 67],
        [-1, 19],
        [-23, -45],
        [-5, 30],
        [-18, 70],
        [-5, 40],
        [-17, 17],
        [-14, 4],
        [-10, -14],
        [-14, -81],
        [-11, 0],
        [-10, 18],
        [2, 61],
      ],
      [
        [47780, 57697],
        [-6, -22],
        [-18, -33],
        [-17, -26],
        [-5, -28],
        [3, -53],
        [0, -119],
        [2, -103],
        [14, -38],
        [16, -19],
        [19, 4],
        [18, 7],
        [-1, -62],
        [-5, -69],
        [22, -22],
        [11, -21],
        [6, -19],
        [-35, -37],
        [-10, -22],
        [-4, -57],
        [1, -54],
        [46, -38],
        [18, -45],
        [8, -46],
        [3, -88],
        [-4, -20],
        [-12, 0],
        [-14, 27],
        [-10, 26],
        [-12, 0],
        [-24, 6],
        [-26, 10],
        [-33, -2],
        [-12, -5],
        [-8, -16],
        [-2, -27],
        [-3, -89],
        [11, -20],
        [21, -22],
        [14, -9],
        [12, 3],
        [9, -15],
        [2, -38],
        [-7, -28],
        [-11, -26],
        [-15, -67],
        [3, -25],
        [0, -37],
        [-25, -98],
        [-7, -19],
        [-33, 19],
        [-22, 7],
        [-16, -25],
      ],
      [
        [25233, 62105],
        [-3, -140],
        [-3, -162],
        [-2, -119],
        [-3, -159],
        [-3, -162],
        [-5, -222],
        [-2, -143],
        [1, -3],
        [33, 7],
        [49, -6],
        [12, 0],
      ],
      [
        [25307, 60996],
        [15, -12],
        [12, -4],
        [24, -32],
        [30, -25],
        [18, 50],
        [-9, 29],
        [-8, 15],
        [1, 14],
        [102, -128],
      ],
      [
        [25177, 60147],
        [-6, 6],
        [-10, 2],
        [-23, -10],
        [-11, -2],
        [-9, -11],
        [-1, -25],
        [6, -40],
        [3, -21],
        [-7, -9],
        [-28, -25],
        [-11, -23],
        [-11, -38],
        [-12, -15],
        [-13, 3],
        [-9, -6],
        [-19, -27],
        [-30, -54],
        [-15, -40],
        [-1, -30],
        [3, -27],
      ],
      [
        [24973, 59755],
        [-106, 95],
        [-36, 16],
        [-150, -2],
        [-64, 37],
        [-73, 72],
        [-50, 66],
        [-115, 182],
      ],
      [
        [32856, 58762],
        [-18, -2],
        [7, 21],
        [2, 37],
        [10, 44],
        [15, 30],
        [15, -8],
        [-6, -98],
        [-25, -24],
      ],
      [
        [57738, 72899],
        [-6, -9],
        [-14, 26],
        [0, 14],
        [14, 16],
        [7, 3],
        [2, -10],
        [0, -23],
        [-3, -17],
      ],
      [
        [55725, 73953],
        [4, -67],
        [19, -12],
        [26, -60],
        [-2, -31],
        [-6, -10],
        [-43, 28],
        [-10, -13],
        [-13, 5],
        [-7, 34],
        [1, 11],
        [-8, 20],
        [-5, 9],
        [-17, -26],
        [-11, -5],
        [0, 24],
        [16, 66],
        [7, 11],
        [13, -22],
        [10, 8],
        [8, 36],
        [1, 36],
        [3, 11],
        [14, -53],
      ],
      [
        [55802, 73620],
        [29, -56],
        [-23, 14],
        [-26, -39],
        [-31, 45],
        [-20, 46],
        [-4, 18],
        [20, 43],
        [19, -44],
        [22, -7],
        [14, -20],
      ],
      [
        [55746, 74083],
        [-11, -5],
        [-9, 3],
        [-9, -2],
        [-8, -11],
        [1, 45],
        [10, 57],
        [11, 33],
        [17, 15],
        [7, -26],
        [-1, -92],
        [-8, -17],
      ],
      [
        [55766, 73922],
        [-14, -6],
        [-17, 55],
        [-7, 38],
        [7, 2],
        [7, -5],
        [8, -14],
        [0, -15],
        [3, -15],
        [7, -19],
        [6, -21],
      ],
      [
        [55577, 74557],
        [6, -32],
        [-35, 20],
        [-25, 29],
        [-21, 71],
        [-45, 81],
        [0, 24],
        [17, 18],
        [36, 12],
        [15, -13],
        [9, -13],
        [3, -16],
        [-20, -31],
        [-5, -14],
        [16, -28],
        [0, -11],
        [7, -55],
        [8, -20],
        [20, -16],
        [14, -6],
      ],
      [
        [56504, 74284],
        [15, -62],
        [15, -21],
        [31, -25],
        [15, -4],
        [52, -45],
        [62, -8],
        [8, -13],
        [7, -35],
        [13, -27],
        [3, -22],
        [-7, -23],
        [9, -72],
        [16, -68],
        [23, -33],
        [29, -10],
        [28, 1],
        [7, -14],
        [-3, -59],
        [-12, -24],
        [-9, -5],
        [-9, 6],
        [-7, 14],
        [-8, 7],
        [-16, 1],
        [-12, 24],
        [-29, 33],
        [-5, 19],
        [-1, 31],
        [-13, 22],
        [-11, 43],
        [-11, 11],
        [-6, 22],
        [-1, 9],
        [-43, 6],
        [-35, 0],
        [-30, 24],
        [-9, 63],
        [-18, 17],
        [-13, 18],
        [-11, 25],
        [-29, 45],
        [-31, 39],
        [-30, 25],
        [-32, 16],
        [-26, -19],
        [-15, 4],
        [-3, 13],
        [33, 27],
        [44, 50],
        [31, 16],
        [15, 2],
        [29, -44],
      ],
      [
        [56605, 74374],
        [-12, -20],
        [-20, 9],
        [-20, 65],
        [52, -54],
      ],
      [
        [56635, 74399],
        [-13, -7],
        [13, 46],
        [23, 24],
        [-9, -38],
        [-14, -25],
      ],
      [
        [56854, 74198],
        [-30, -14],
        [-8, 2],
        [7, 18],
        [0, 7],
        [-29, 33],
        [4, 42],
        [3, 11],
        [22, -22],
        [5, -37],
        [26, -40],
      ],
      [
        [56881, 75238],
        [-35, -20],
        [-37, 39],
        [1, 23],
        [19, 47],
        [10, 14],
        [27, -4],
        [15, -32],
        [4, -15],
        [-5, -26],
        [1, -26],
      ],
      [
        [56541, 73689],
        [-11, -14],
        [-12, 1],
        [-9, 5],
        [-4, 11],
        [5, 6],
        [7, 23],
        [6, 6],
        [9, -3],
        [5, -9],
        [4, -26],
      ],
      [
        [56403, 72689],
        [-3, -25],
        [-28, 17],
        [-8, 26],
        [-2, 57],
        [8, 28],
        [5, 9],
        [13, -32],
        [27, -47],
        [-12, -33],
      ],
      [
        [57548, 72272],
        [-10, -32],
        [-11, 27],
        [5, 31],
        [-13, 50],
        [24, 74],
        [0, 36],
        [18, 19],
        [-4, -62],
        [-14, -49],
        [14, -40],
        [7, -46],
        [-16, -8],
      ],
      [
        [57505, 73132],
        [-28, -8],
        [5, 46],
        [-13, 36],
        [21, -20],
        [14, -24],
        [7, -5],
        [-2, -15],
        [-4, -10],
      ],
      [
        [57485, 72999],
        [-8, -1],
        [10, 28],
        [29, 38],
        [43, 34],
        [14, 3],
        [24, -21],
        [-44, -34],
        [-12, -18],
        [-32, -4],
        [-24, -25],
      ],
      [
        [57096, 73137],
        [-25, -22],
        [-17, 32],
        [-10, 50],
        [46, 72],
        [11, -6],
        [6, -19],
        [-1, -65],
        [-10, -42],
      ],
      [
        [57021, 73195],
        [-22, -44],
        [-18, 5],
        [-8, 20],
        [12, 42],
        [24, 24],
        [11, -7],
        [-1, -31],
        [2, -9],
      ],
      [
        [57078, 72806],
        [-13, -30],
        [-18, 11],
        [7, 11],
        [5, 15],
        [0, 22],
        [-5, 13],
        [3, 5],
        [17, -22],
        [4, -25],
      ],
      [
        [57050, 72968],
        [-5, -9],
        [-21, 37],
        [-8, 21],
        [10, 18],
        [31, -42],
        [-7, -25],
      ],
      [
        [57451, 73623],
        [34, -19],
        [9, 2],
        [16, -6],
        [5, -35],
        [-22, -6],
        [-37, -32],
        [-15, 7],
        [-19, 28],
        [-30, 3],
        [-9, 8],
        [16, 33],
        [29, 16],
        [23, 1],
      ],
      [
        [57230, 73461],
        [-13, -2],
        [4, 23],
        [25, 40],
        [34, 2],
        [32, 20],
        [7, 0],
        [-15, -31],
        [-25, -30],
        [-49, -22],
      ],
      [
        [57183, 73035],
        [-25, -4],
        [-8, 4],
        [15, 10],
        [11, 10],
        [5, 13],
        [25, 23],
        [16, 29],
        [18, -20],
        [-23, -13],
        [-34, -52],
      ],
      [
        [57350, 72917],
        [-22, -14],
        [-14, -29],
        [-17, 21],
        [0, 28],
        [18, -9],
        [13, 16],
        [-4, 18],
        [15, -9],
        [11, -22],
      ],
      [
        [56765, 73488],
        [-19, -28],
        [-3, 42],
        [12, 44],
        [16, 3],
        [6, -19],
        [-12, -42],
      ],
      [
        [56787, 73354],
        [-16, -17],
        [6, 40],
        [-8, 21],
        [7, 17],
        [10, 15],
        [5, -15],
        [9, -24],
        [-13, -37],
      ],
      [
        [56815, 73020],
        [0, -34],
        [-1, -12],
        [-57, -16],
        [5, 38],
        [3, 13],
        [19, -18],
        [7, 9],
        [3, 10],
        [21, 10],
      ],
      [
        [56928, 73440],
        [-1, -60],
        [-8, 1],
        [-4, 9],
        [0, 23],
        [3, 36],
        [10, -9],
      ],
      [
        [56942, 73594],
        [-9, -39],
        [-21, 45],
        [-24, 31],
        [-9, 27],
        [-14, 16],
        [-4, 36],
        [17, 15],
        [8, 1],
        [18, -44],
        [28, -5],
        [-2, -27],
        [8, -35],
        [4, -21],
      ],
      [
        [57015, 73501],
        [-10, -37],
        [-18, 6],
        [-29, 40],
        [-10, 18],
        [-5, 18],
        [12, 2],
        [14, -19],
        [37, -10],
        [9, -18],
      ],
      [
        [56866, 73111],
        [-5, -3],
        [-7, 25],
        [-2, 22],
        [3, 13],
        [10, 2],
        [13, -43],
        [-12, -16],
      ],
      [
        [57248, 73858],
        [-27, -33],
        [-29, 47],
        [-5, 15],
        [21, 19],
        [11, 30],
        [-8, 36],
        [-31, 53],
        [-1, 38],
        [46, 16],
        [27, -33],
        [14, -3],
        [-5, -31],
        [2, -10],
        [2, -96],
        [-13, -13],
        [-2, -26],
        [-2, -9],
      ],
      [
        [57336, 74498],
        [-5, -34],
        [38, -57],
        [13, -36],
        [5, -35],
        [-3, -10],
        [-15, 19],
        [-12, 6],
        [4, -25],
        [13, -21],
        [-22, -13],
        [-22, 1],
        [-64, 30],
        [-14, 32],
        [38, 48],
        [8, 19],
        [-27, -2],
        [-29, -57],
        [-46, 25],
        [-14, 23],
        [-4, 12],
        [19, 51],
        [32, -2],
        [17, 11],
        [21, 16],
        [1, 24],
        [50, 6],
        [18, -31],
      ],
      [
        [57134, 75130],
        [-31, -15],
        [-35, 47],
        [34, 19],
        [15, -14],
        [11, -16],
        [6, -21],
      ],
      [
        [57065, 74874],
        [-10, -19],
        [-8, -34],
        [-4, -47],
        [-16, -2],
        [-10, 10],
        [-3, 18],
        [-1, 23],
        [-7, -1],
        [-6, -25],
        [-5, -11],
        [-16, -2],
        [-18, 15],
        [1, 33],
        [-4, 38],
        [2, 14],
        [49, 3],
        [14, -28],
        [18, 16],
        [7, 18],
        [21, 11],
        [-4, -30],
      ],
      [
        [57056, 73397],
        [-27, -3],
        [2, 44],
        [10, 11],
        [31, -22],
        [-1, -14],
        [-15, -16],
      ],
      [
        [56812, 73228],
        [-11, -9],
        [-17, 13],
        [5, 32],
        [11, 13],
        [13, -10],
        [2, -14],
        [-3, -25],
      ],
      [
        [57734, 72539],
        [-21, -12],
        [-6, 2],
        [-9, 26],
        [12, 65],
        [-11, 41],
        [-1, 18],
        [17, 24],
        [11, 36],
        [27, 40],
        [72, 46],
        [17, 5],
        [-1, -37],
        [-24, -92],
        [-21, -46],
        [6, -37],
        [-34, -11],
        [-34, -68],
      ],
      [
        [56625, 72312],
        [19, -4],
        [26, 1],
        [6, 3],
        [16, 34],
        [20, 1],
        [9, -34],
        [-20, -15],
        [-5, -9],
        [4, -7],
        [16, -13],
        [21, 5],
        [1, -26],
        [4, -22],
        [11, -13],
        [11, -2],
        [26, 4],
        [25, 8],
        [25, 17],
        [26, 9],
        [79, -9],
        [28, -36],
        [53, -5],
        [50, -19],
        [26, 13],
        [45, 12],
        [7, -13],
        [-6, -82],
        [3, -24],
        [13, -11],
        [12, 5],
        [16, 27],
        [37, 21],
        [39, 0],
        [33, 54],
        [10, 3],
        [-6, -26],
        [-5, -63],
        [-7, -37],
        [-3, -29],
        [-22, -15],
        [-33, -3],
        [-61, 6],
        [-60, -10],
        [-113, -28],
        [-113, -14],
        [-15, 9],
        [0, 37],
        [-3, 25],
        [-7, 18],
        [-35, 15],
        [-33, 26],
        [-130, 36],
        [-31, 14],
        [-50, -8],
        [-18, 1],
        [-13, 13],
        [-9, 22],
        [-4, 69],
        [7, 69],
        [10, 18],
        [5, -21],
        [13, -9],
        [12, 21],
        [0, 31],
        [6, 29],
        [9, -12],
        [7, -45],
        [16, -12],
      ],
      [
        [57232, 75303],
        [-7, 24],
        [-43, 43],
        [-100, 25],
        [-48, 32],
        [-21, -6],
        [-40, 36],
        [-28, -16],
        [-59, -63],
        [-31, 7],
        [-34, 38],
        [-22, 7],
        [-26, -20],
        [-42, -73],
        [-42, -36],
        [-38, 14],
        [-51, 0],
        [-5, -41],
        [10, -28],
        [27, -48],
        [-13, -37],
        [10, -36],
        [18, -7],
        [28, 2],
        [50, -47],
        [22, -50],
        [14, -54],
        [-30, 39],
        [-21, 37],
        [-28, 14],
        [-40, 32],
        [-25, 5],
        [-26, -22],
        [-3, -25],
        [29, -47],
        [26, -29],
        [14, -23],
        [9, -52],
        [-5, -17],
        [-10, -17],
        [-31, 33],
        [-47, 116],
        [-66, 23],
        [-11, -24],
        [13, -61],
        [9, -24],
        [58, -67],
        [-5, -14],
        [-8, -6],
        [-65, 38],
        [-19, 58],
        [-4, 73],
        [-59, 50],
        [-56, 55],
        [-13, 53],
        [12, 19],
        [8, 38],
        [-31, -7],
        [-19, -24],
        [-32, -23],
        [-1, -39],
        [5, -36],
        [-10, -52],
        [-10, -90],
        [6, -48],
        [68, -136],
        [23, -99],
        [16, -37],
        [35, -42],
        [36, -76],
        [15, -41],
        [11, -65],
        [-30, -41],
        [-18, -2],
        [-9, 19],
        [13, 44],
        [-2, 28],
        [-47, 42],
        [-20, -15],
        [-22, -27],
        [13, -51],
        [14, -34],
        [8, -46],
        [28, 4],
        [-38, -52],
        [-35, -27],
        [-35, -1],
        [-23, -5],
        [-7, -13],
        [18, -10],
        [15, -1],
        [24, -28],
        [68, -34],
        [33, -42],
        [32, -4],
        [32, -78],
        [56, -21],
        [31, -79],
        [43, -16],
        [36, -29],
        [11, -27],
        [5, -50],
        [2, -107],
        [8, -79],
        [0, -25],
        [-2, -37],
        [-9, -19],
        [-14, 0],
        [-26, 58],
        [-40, 61],
        [-42, 73],
        [-12, 13],
        [-10, 1],
        [-23, -25],
        [-63, -19],
        [-29, -26],
        [-11, -6],
        [-4, -14],
        [14, -15],
        [17, -33],
        [0, -45],
        [14, -56],
        [18, -14],
        [24, 1],
        [13, -10],
        [4, -22],
        [14, -26],
        [9, -19],
        [-1, -13],
        [-66, -37],
        [-13, -16],
        [-12, -9],
        [-17, 18],
        [-1, 44],
        [-22, 23],
        [-21, 21],
        [-25, 9],
        [-21, 30],
        [-14, -25],
        [11, -85],
        [24, -60],
        [40, -158],
        [18, -93],
        [4, -46],
        [-9, -75],
        [19, -56],
        [14, -57],
        [-15, 2],
        [-13, 20],
        [-21, 24],
        [-42, 92],
        [-15, 57],
        [-17, 4],
        [-30, -8],
        [-34, -122],
        [1, -70],
        [-18, 17],
        [-14, 22],
        [1, 76],
        [-1, 32],
        [-40, 104],
        [-19, 12],
        [-8, 35],
        [-15, 38],
        [-19, -8],
        [-16, -15],
        [-4, -56],
        [-2, -51],
        [-11, -38],
        [-43, 72],
        [-43, 126],
        [-1, 68],
        [31, 63],
        [-4, 45],
        [-30, 89],
        [-43, 57],
        [-24, 17],
        [-11, 60],
        [-23, 31],
        [-19, 15],
        [-4, 22],
        [6, 16],
        [45, 62],
        [27, 97],
        [13, 5],
        [27, -23],
        [31, 6],
        [25, 57],
        [21, 31],
        [36, -4],
        [80, -76],
        [87, -44],
        [43, -38],
        [25, -38],
        [13, -8],
        [20, -5],
        [-1, 28],
        [-6, 25],
        [17, 14],
        [46, -1],
        [9, 14],
        [8, 21],
        [-9, 24],
        [-16, 12],
        [-16, 3],
        [-11, 8],
        [-17, -8],
        [-28, 19],
        [-14, 16],
        [-8, 16],
        [-47, 32],
        [-45, 54],
        [-10, -31],
        [-19, -16],
        [-25, -3],
        [-73, 35],
        [-45, -27],
        [-24, -6],
        [-19, -1],
        [-23, -12],
        [-26, -7],
        [-23, 50],
        [-9, 39],
        [-7, 7],
        [-1, -37],
        [-7, -29],
        [-33, -16],
        [-20, 23],
        [-15, 68],
        [-18, 87],
        [-33, 70],
        [-27, 18],
        [-3, 39],
        [3, 30],
        [32, 8],
        [50, -32],
        [11, 6],
        [11, 15],
        [-2, 33],
        [-7, 29],
        [-14, 2],
        [-10, -4],
        [-31, 6],
        [-39, -16],
        [-19, 15],
        [-6, 19],
        [-33, 46],
        [-29, 62],
        [-46, 41],
        [-31, 126],
        [-25, 55],
        [-28, 40],
      ],
      [
        [55555, 74717],
        [6, 0],
        [11, -6],
        [19, -22],
        [21, -5],
        [12, 15],
        [7, 13],
        [4, 21],
        [2, 26],
        [3, 8],
        [16, -3],
        [5, 6],
        [0, 23],
        [-10, 28],
        [-10, 35],
        [0, 16],
        [8, 7],
        [13, 15],
        [6, 19],
        [14, 9],
        [19, 2],
        [22, 8],
        [15, 20],
        [2, 19],
        [9, 55],
        [5, 27],
        [10, 24],
        [5, 33],
        [10, 31],
        [21, 13],
        [19, 15],
        [14, 40],
        [8, 34],
        [0, 20],
        [-12, 35],
        [-9, 33],
        [3, 43],
      ],
      [
        [56365, 75654],
        [30, -6],
        [37, -2],
        [23, 36],
        [37, 3],
        [17, 5],
        [28, -8],
        [28, 1],
        [35, 15],
        [33, 25],
        [26, -2],
        [10, 4],
        [6, 5],
        [7, 34],
        [48, 2],
        [17, -3],
        [27, -1],
        [28, 18],
        [8, -2],
        [14, -49],
        [8, -14],
        [15, -13],
        [34, -37],
        [6, 10],
        [14, 12],
        [41, -17],
        [39, -28],
        [33, -42],
        [36, 12],
        [40, 21],
        [27, 6],
        [28, 3],
        [17, 8],
        [38, -10],
        [40, 22],
        [19, 20],
        [6, 29],
        [-3, 50],
        [-9, 49],
        [-10, 19],
        [-3, 19],
        [5, 18],
        [6, 12],
        [26, 11],
        [34, -16],
      ],
      [
        [50329, 55350],
        [-22, -22],
        [-16, -33],
        [-12, -50],
        [-16, -55],
        [-55, -29],
        [-22, -1],
        [-114, -1],
        [-108, -109],
        [-61, -39],
        [-38, -61],
        [-51, -44],
        [-36, -53],
        [-74, -25],
        [-122, -83],
        [-38, -33],
        [-38, -58],
        [-63, -68],
        [-24, 1],
        [-49, 63],
        [-37, 32],
        [-90, 49],
        [-67, 18],
        [-33, 21],
        [-8, 4],
      ],
      [
        [49135, 54774],
        [7, 23],
      ],
      [
        [49142, 54797],
        [19, 1],
      ],
      [
        [49161, 54798],
        [20, -7],
        [14, 18],
        [22, 2],
        [6, 18],
        [2, 46],
        [0, 37],
        [7, 16],
        [2, 44],
        [-11, 96],
        [-7, 11],
        [-39, 14],
        [-3, 19],
        [-7, 21],
        [-8, 49],
        [-8, 74],
        [-14, 92],
        [-27, 151],
        [-6, 54],
        [-5, 54],
        [-1, 66],
        [6, 24],
        [-1, 33],
        [-2, 34],
        [18, 77],
        [37, 94],
        [7, 34],
        [7, 24],
        [1, 34],
        [7, 110],
        [17, 132],
        [11, 51],
        [7, 27],
        [9, 44],
        [3, 21],
        [33, 52],
        [16, 14],
        [3, 20],
        [-5, 23],
        [2, 15],
        [8, 8],
        [12, 6],
        [9, 21],
        [-14, 164],
        [-11, 163],
        [-1, 14],
        [-7, 23],
        [-6, 67],
        [-12, 39],
        [-16, 12],
        [1, 37],
        [16, 63],
        [4, 37],
        [-8, 11],
        [-1, 28],
        [5, 47],
        [-2, 28],
      ],
      [
        [49251, 57304],
        [-3, 31],
        [-17, 71],
        [-4, 51],
        [9, 29],
        [-1, 65],
        [-9, 100],
        [-1, 63],
        [6, 26],
        [-3, 25],
        [-12, 24],
        [-1, 23],
        [10, 23],
        [-1, 17],
        [-13, 13],
        [-11, 31],
        [-10, 48],
        [2, 78],
        [19, 144],
        [2, 12],
        [22, -1],
        [0, -6],
        [68, 2],
        [77, 1],
        [92, 2],
        [83, 2],
        [4, 6],
        [14, 8],
        [84, -15],
        [53, 8],
        [22, -5],
        [17, -10],
        [36, 6],
        [20, -3],
        [14, -36],
        [6, 0],
        [8, 15],
        [15, 18],
        [15, 13],
        [11, 28],
        [6, 22],
        [10, -5],
        [13, 2],
        [10, 18],
        [3, 27],
        [64, -29],
      ],
      [
        [52115, 79258],
        [-14, 8],
        [-10, 38],
        [3, 58],
        [15, 76],
        [4, 56],
        [-7, 35],
        [9, 54],
        [25, 71],
        [16, 75],
        [9, 78],
        [12, 52],
        [23, 36],
        [56, 100],
        [5, 8],
        [-2, 50],
        [-15, 7],
        [-22, 15],
        [-56, 18],
        [-52, 11],
        [-24, 14],
        [-21, 38],
        [-13, 1],
        [-25, -14],
        [-32, -9],
        [-22, 8],
        [-15, -2],
        [-8, -7],
        [-4, 6],
        [-6, 33],
        [-12, 9],
        [-18, 7],
        [-12, -3],
        [-8, -16],
        [-12, -12],
        [-12, 4],
        [-35, 75],
        [-9, 17],
        [-3, 15],
        [-8, 28],
        [-22, 27],
        [-21, 9],
        [-10, -3],
      ],
      [
        [51699, 80714],
        [1, 11],
        [15, 53],
        [52, 49],
        [-5, 48],
        [-1, 30],
        [-13, 19],
        [-25, 8],
        [-7, 13],
        [-3, 14],
        [19, 29],
        [-23, 24],
        [-10, 24],
        [-31, 30],
        [-4, 11],
      ],
      [
        [51999, 82535],
        [-13, 26],
        [-27, 28],
        [6, 59],
        [9, 45],
        [27, 57],
        [22, 15],
        [96, 9],
        [105, -4],
        [44, -84],
        [-16, -44],
        [25, -20],
        [13, 7],
        [9, 38],
        [6, 42],
        [9, 13],
        [33, -32],
        [12, -21],
        [0, -69],
        [12, 93],
        [-9, 66],
        [6, 63],
        [14, 34],
        [12, 21],
        [77, -23],
        [86, 12],
        [32, -25],
        [73, -123],
        [24, -20],
        [31, -6],
        [-42, 26],
        [-89, 149],
        [-26, 19],
        [-41, 5],
        [-26, 15],
        [-16, 23],
        [-4, 20],
        [1, 150],
        [-16, 22],
        [-20, 8],
        [-12, -10],
        [-25, -1],
        [-5, 34],
        [6, 26],
        [51, 17],
        [33, 23],
        [2, 41],
        [-22, 32],
        [-25, 58],
        [-30, 56],
        [-3, 64],
      ],
      [
        [52408, 83469],
        [52, -1],
        [13, -3],
        [78, -30],
        [19, -21],
        [24, -1],
        [44, 20],
        [33, 8],
        [12, -12],
        [18, -5],
        [4, 0],
      ],
      [
        [52705, 83424],
        [2, -10],
        [40, -16],
        [17, -24],
        [19, -37],
        [2, -54],
        [-24, -38],
        [-20, -24],
        [76, 9],
        [8, -22],
        [11, -24],
        [41, 17],
        [103, -70],
        [63, 34],
        [16, 2],
        [14, -57],
        [-16, -57],
        [-55, -61],
        [13, -38],
        [17, -8],
        [52, 8],
        [82, -37],
        [17, 11],
        [67, 86],
        [26, 18],
        [88, 14],
        [16, 33],
        [35, 33],
        [23, 36],
        [55, 70],
        [56, -13],
        [33, -13],
        [37, -7],
        [33, -74],
        [83, -81],
        [77, 7],
        [27, -77],
        [12, -96],
        [24, -30],
        [20, -19],
        [63, -21],
        [2, -1],
      ],
      [
        [54113, 81139],
        [-3, -9],
        [-9, -14],
        [-12, -2],
        [-18, 10],
        [-12, 13],
        [3, 34],
        [-8, 3],
        [-10, 20],
        [-4, 23],
        [-11, 9],
        [-39, 10],
        [-13, 6],
        [-10, -5],
        [-8, -15],
        [5, -15],
        [8, -14],
        [21, -22],
        [-2, -9],
        [-47, -21],
        [-29, -23],
        [-27, -12],
        [-28, -23],
        [-55, -26],
        [-40, -7],
        [-8, -6],
        [-15, -44],
        [-10, -9],
        [-10, 4],
        [-7, 8],
        [-10, -6],
        [-9, -15],
        [-11, -6],
        [-8, 1],
        [-16, -39],
        [-46, -11],
        [-5, -20],
        [-9, -23],
        [-6, -5],
        [-21, 9],
        [-29, 5],
        [-16, -13],
        [-20, -7],
        [-24, -2],
        [-26, -25],
        [-27, -44],
        [-14, -39],
        [-8, -14],
        [-13, 36],
        [-16, 26],
        [-11, 13],
        [-10, 0],
        [-2, -6],
        [0, -19],
        [10, -32],
        [13, -21],
        [2, -16],
        [8, -29],
        [19, -32],
        [30, -25],
        [20, -25],
        [15, -35],
        [0, -10],
        [-4, -14],
        [-7, -13],
        [-6, -18],
        [-17, -35],
        [5, -15],
        [14, -19],
        [12, -23],
        [15, -37],
        [21, -66],
        [14, -27],
        [19, -28],
        [18, -21],
        [28, 1],
        [30, -41],
        [33, -58],
        [24, -27],
        [17, -8],
        [14, -22],
        [12, -30],
        [5, -18],
        [11, -12],
        [30, 2],
        [38, -48],
        [23, -35],
        [13, -28],
      ],
      [
        [53837, 79934],
        [-3, -11],
        [-2, -35],
        [0, -38],
        [-3, -19],
        [-17, -26],
        [-9, -6],
        [-5, -5],
        [-52, 34],
        [-4, -6],
        [-4, -4],
        [-14, -99],
        [-9, -18],
        [-15, -18],
        [-30, -17],
        [-20, -7],
        [-17, -8],
        [-51, -41],
        [-23, -25],
        [-15, -31],
        [0, -18],
        [25, -52],
        [29, -55],
        [0, -47],
        [-13, -36],
        [-3, -14],
        [9, -5],
        [16, -2],
        [13, -6],
        [6, -25],
        [-2, -44],
        [-5, -41],
        [-4, -17],
        [-13, -2],
        [-25, 18],
        [-19, 21],
        [-8, 12],
        [0, 15],
        [4, 10],
        [-7, 19],
        [-24, 17],
        [-25, -8],
        [-19, -11],
        [-12, 0],
        [-13, 17],
        [-20, 13],
        [-27, 8],
        [-16, 9],
        [-4, -5],
        [2, -36],
        [-5, -16],
        [-130, -21],
        [-40, -19],
        [-28, -25],
        [-22, -11],
        [-5, -16],
        [-21, -20],
        [-24, -6],
        [-6, 6],
        [-15, -9],
        [-26, -9],
        [-17, 3],
        [-8, 16],
        [-16, 25],
        [-7, 18],
        [1, 11],
        [-37, 2],
        [-23, 14],
        [-49, -4],
        [-12, 6],
        [-2, -6],
        [-8, -71],
        [-9, -30],
        [-16, -30],
        [-20, -17],
        [-16, -3],
        [1, 22],
        [4, 27],
        [-12, 6],
        [-17, 3],
        [-8, 8],
        [2, 20],
        [-4, 12],
        [-7, 14],
        [-18, 18],
        [-36, 27],
        [-25, 14],
        [-10, -14],
        [-18, -15],
        [-28, 5],
        [-7, -6],
      ],
      [
        [53808, 83169],
        [7, -39],
        [-8, -19],
        [-31, 32],
        [-32, 0],
        [-18, -51],
        [-14, -2],
        [-49, 46],
        [-7, 23],
        [-2, 18],
        [7, 65],
        [-1, 20],
        [15, 22],
        [2, 32],
        [27, 34],
        [24, 1],
        [8, -28],
        [11, -20],
        [40, -22],
        [6, -10],
        [4, -14],
        [-19, -27],
        [-6, -14],
        [6, -23],
        [30, -24],
      ],
      [
        [53948, 82874],
        [-12, 2],
        [-34, -6],
        [-34, 9],
        [-7, 34],
        [6, 34],
        [-14, 22],
        [-13, 14],
        [-2, 19],
        [2, 20],
        [59, -54],
        [48, -48],
      ],
      [
        [53134, 83189],
        [-43, -1],
        [-16, 23],
        [-17, 6],
        [9, 29],
        [12, 10],
        [41, -19],
        [13, -36],
        [1, -12],
      ],
      [
        [52307, 83402],
        [-6, -12],
        [3, 82],
        [30, 86],
        [13, -2],
        [-13, -23],
        [-4, -16],
        [-5, -33],
        [2, -17],
        [70, -5],
        [-8, -15],
        [-71, -10],
        [-11, -35],
      ],
      [
        [52385, 83359],
        [-11, -14],
        [-26, 2],
        [-15, 13],
        [5, 14],
        [14, 11],
        [11, 2],
        [18, -7],
        [4, -21],
      ],
      [
        [61530, 75758],
        [53, 108],
        [16, 65],
        [1, 39],
        [0, 49],
        [-27, 102],
        [-24, 144],
        [-25, 151],
        [-19, 45],
        [-81, 52],
        [-19, 59],
        [-62, 77],
        [-87, 33],
        [-17, 14],
        [-76, 96],
        [-59, 62],
      ],
      [
        [62897, 75973],
        [-7, -20],
        [-16, -38],
        [-13, -19],
        [-14, -3],
        [-14, -8],
        [-5, -20],
        [0, -26],
        [2, -19],
        [4, -7],
        [14, -6],
        [14, -54],
        [22, -28],
        [34, -31],
        [31, -36],
        [15, -33],
        [-3, -23],
        [-10, -50],
        [-25, -41],
        [-22, -10],
        [-7, 3],
        [-14, 13],
        [-28, 32],
        [-30, 25],
        [-24, -8],
        [-15, -10],
        [-31, 12],
        [-35, 21],
        [-19, 22],
        [-8, 16],
        [5, 28],
        [-81, 50],
        [-40, 14],
        [-17, -15],
        [-60, -76],
      ],
      [
        [62500, 75628],
        [-7, -8],
        [-46, -10],
        [0, -7],
        [10, -16],
        [-2, -5],
        [-77, -2],
        [-25, -10],
        [-68, 13],
        [-23, -6],
        [-19, -12],
        [-47, -13],
        [-32, -16],
        [-41, -9],
        [-43, 0],
        [-14, -5],
      ],
      [
        [45343, 59368],
        [-2, 49],
        [-15, 111],
        [21, 48],
        [22, 29],
        [15, -23],
        [5, -45],
        [12, -31],
        [39, -20],
        [40, 14],
        [24, -6],
        [-1, 25],
        [8, 33],
        [48, 15],
        [50, 9],
        [52, 20],
        [41, -1],
        [12, 6],
        [-3, 8],
        [-36, 10],
        [-78, -23],
        [-80, -7],
        [-60, -60],
        [-24, 6],
        [-25, 60],
        [-9, 74],
      ],
      [
        [53692, 53088],
        [-1, -40],
        [-22, -99],
        [-11, -76],
        [-2, -81],
        [6, -65],
        [10, -46],
        [7, -51],
        [-5, -35],
        [-11, -15],
        [8, -18],
        [16, -4],
        [27, 15],
        [42, 27],
        [55, 39],
        [36, 21],
        [60, -13],
        [32, -15],
        [16, -28],
        [18, -116],
        [8, -17],
        [15, -50],
        [12, -59],
        [2, -30],
        [-1, -22],
        [-12, -32],
        [-14, -47],
        [-5, -29],
        [-11, -21],
        [-14, -21],
        [-40, -8],
        [-6, -13],
        [-12, -50],
        [-21, -43],
        [-9, -40],
        [-9, -53],
        [2, -67],
        [-4, -96],
        [-5, -64],
        [11, -23],
        [48, -16],
        [9, -13],
        [13, -40],
        [16, -38],
        [43, -23],
        [17, -29],
        [14, -32],
        [2, -26],
        [-10, -104],
        [-10, -99],
        [4, -76],
        [4, -73],
        [5, -105],
        [-2, -65],
        [-13, -39],
        [0, -31],
        [6, -37],
        [-11, -103],
        [-7, -17],
        [-20, -20],
        [-10, -27],
        [-3, -44],
        [-11, -59],
        [-10, -22],
        [0, -27],
        [10, -21],
        [0, -30],
        [-20, -37],
        [-11, -28],
        [-26, -14],
        [-30, 14],
        [-7, 21],
        [7, 32],
        [-2, 25],
        [-11, 27],
        [-16, 69],
        [-14, 15],
        [-7, -28],
        [-25, -53],
        [-42, -67],
        [-30, -6],
        [-55, 21],
        [-47, 32],
        [-21, 79],
        [-14, 65],
        [-20, 76],
        [-22, 36],
        [-24, 23],
        [-10, 1],
        [-34, -42],
        [-10, -16],
        [0, -36],
        [3, -33],
        [5, -16],
        [5, -21],
        [-1, -33],
        [-6, -44],
        [-2, -49],
        [-106, -47],
        [-19, 17],
        [-13, 22],
        [-16, -4],
        [-46, -25],
        [-17, 18],
        [-17, 12],
        [-7, -10],
        [-1, -21],
        [8, -114],
        [-3, -44],
        [-10, -57],
        [-6, -38],
        [29, -11],
        [10, -18],
        [10, -29],
        [13, -27],
        [1, -16],
        [-15, -30],
        [-6, -37],
        [8, -28],
        [19, -30],
        [28, -32],
        [14, -20],
        [-2, -19],
        [-13, -40],
        [-5, -33],
        [-9, -31],
        [2, -28],
        [13, -26],
        [-2, -23],
        [-8, -18],
        [-18, 4],
        [-14, -3],
        [-13, 8],
        [-42, 90],
        [-9, 3],
        [-60, -70],
        [-15, -28],
        [-12, -41],
        [-17, -89],
      ],
      [
        [53091, 49587],
        [-27, 51],
        [-24, 95],
        [-27, 58],
        [-58, 94],
        [-15, 69],
        [-66, 153],
        [-95, 152],
        [-69, 133],
        [-10, 29],
        [12, -3],
        [66, -66],
        [9, 7],
        [7, 15],
        [-28, 34],
        [-27, 28],
        [-26, 17],
        [-26, -2],
        [-14, 28],
        [-9, 43],
        [-5, 36],
        [-11, 38],
        [-37, 79],
        [-9, 30],
        [-19, 41],
        [12, 6],
        [39, -40],
        [3, 16],
        [-3, 23],
        [-39, 38],
        [-22, 2],
        [-5, 27],
        [3, 30],
        [-28, 115],
        [-29, 85],
        [-4, 41],
        [78, -186],
        [11, -4],
        [13, 2],
        [33, 21],
        [-6, 25],
        [-15, 26],
        [-14, -12],
        [-19, -2],
        [-9, 11],
        [-5, 19],
        [19, 90],
        [-8, -4],
        [-6, -16],
        [-10, -8],
        [-16, -5],
        [-39, 49],
        [-34, 130],
        [-9, 27],
        [-9, 46],
        [-9, 18],
        [-39, 186],
        [15, -14],
        [18, -53],
        [35, 11],
        [13, 31],
        [12, -1],
        [12, 7],
        [15, 29],
        [45, 128],
        [12, 169],
        [-4, 100],
        [-7, 100],
        [15, 32],
        [6, -21],
        [3, -36],
        [7, -26],
        [16, -23],
        [29, -7],
        [46, -36],
        [16, -24],
        [4, 47],
        [53, 40],
        [-16, 14],
        [-47, -15],
        [-64, 59],
        [-21, 38],
        [-20, 72],
        [-20, 38],
        [1, 34],
        [46, 31],
        [12, -4],
        [5, -37],
        [12, -15],
        [5, 5],
        [2, 31],
        [0, 86],
        [-14, 122],
        [5, 23],
      ],
      [
        [52664, 52437],
        [12, 9],
        [11, 16],
        [8, 3],
        [16, -3],
        [8, -29],
        [4, -15],
        [15, -7],
        [13, -15],
        [11, 4],
        [10, 17],
        [13, 4],
        [42, 0],
        [38, -1],
        [75, 0],
        [76, -1],
        [75, 0],
        [57, 0],
        [0, 69],
        [0, 108],
        [-1, 127],
        [0, 122],
        [0, 113],
        [-1, 133],
      ],
      [
        [53146, 53091],
        [3, 38],
        [4, 16],
        [-1, 22],
        [58, 2],
        [106, -10],
        [46, 1],
        [13, -1],
        [58, 6],
        [47, -8],
        [20, -10],
        [18, -4],
        [56, -6],
        [73, 7],
        [25, -2],
        [14, -18],
        [6, -36],
      ],
      [
        [52633, 76500],
        [-7, -84],
        [5, -25],
        [10, -18],
        [5, -19],
        [8, -225],
        [-2, -18],
        [-34, -91],
        [-7, -26],
        [-2, -113],
        [-6, -30],
        [-12, -30],
        [-21, -96],
        [-19, -43],
        [-51, 53],
        [-30, 22],
        [-14, 25],
        [-10, 17],
        [6, 23],
        [14, 23],
        [2, 19],
        [-32, 21],
        [-15, 14],
        [0, 24],
        [11, 38],
        [-5, 32],
        [-18, -2],
        [-15, 5],
        [-1, 17],
        [10, 21],
        [14, 27],
        [-1, 30],
        [-16, 13],
        [-15, 25],
        [-6, 33],
        [12, 23],
        [18, 15],
        [-13, 34],
        [-10, 1],
        [-7, 7],
        [6, 16],
        [14, 24],
        [21, 71],
        [28, 33],
        [50, 22],
        [14, 9],
        [12, 25],
        [14, 16],
        [16, -2],
        [16, -9],
        [9, -11],
        [8, 11],
        [6, 31],
        [-4, 27],
        [2, 75],
        [9, 42],
        [15, 3],
        [13, -24],
        [-1, -20],
        [5, -49],
        [1, -32],
      ],
      [
        [52081, 77054],
        [-15, -10],
      ],
      [
        [52049, 77034],
        [-32, -21],
        [-23, -21],
        [-88, -127],
        [-41, -38],
        [-8, -22],
        [-8, -42],
        [-24, -36],
        [-22, -17],
        [-52, -18],
        [-53, -38],
        [-23, 16],
        [-62, -1],
        [-38, 46],
        [-74, 29],
        [-24, 67],
        [-33, 4],
        [-22, -2],
        [-13, 10],
        [-4, 23],
        [0, 22],
        [-23, -10],
        [-18, 0],
        [-11, -9],
        [-8, -10],
        [-10, 7],
        [-6, -3],
        [1, -13],
        [-22, -3],
        [-23, 8],
        [-61, 35],
        [-9, 5],
        [-42, 13],
        [-17, 14],
        [-14, 35],
        [-11, 10],
        [-6, 7],
        [-39, -17],
        [-14, -27],
        [-21, -32],
        [-146, -155],
        [-27, -64],
        [-31, -96],
        [-2, -44],
        [13, -142],
        [30, -75],
        [4, -18],
      ],
      [
        [50473, 76326],
        [3, 13],
        [7, 18],
        [0, 11],
        [-9, 16],
        [-39, 18],
        [-18, 4],
        [-12, -12],
        [-9, -15],
      ],
      [
        [49501, 76847],
        [45, 17],
        [41, 73],
        [39, 263],
        [28, 311],
        [20, 58],
        [26, 17],
        [-21, 42],
        [-13, -21],
        [-5, -23],
        [-7, -12],
        [15, 285],
        [11, 105],
        [19, 109],
        [39, -43],
        [32, -44],
        [17, -39],
        [21, -127],
        [15, -27],
        [24, -26],
        [-9, 29],
        [-17, 22],
        [-25, 170],
        [-16, 48],
        [-25, 40],
        [-80, 85],
        [-8, 17],
        [-4, 32],
        [27, -1],
        [23, -16],
        [-3, 18],
        [-7, 19],
        [-10, 69],
        [-9, 161],
        [1, 28],
        [-4, 34],
        [-26, 7],
        [-20, 2],
        [-22, 13],
        [-110, 95],
        [-37, 98],
        [-39, 72],
        [-9, 32],
        [1, 32],
        [20, 67],
        [-18, 43],
        [-17, 8],
        [-15, 21],
        [14, 35],
        [11, 23],
        [22, 6],
        [30, -8],
        [28, -20],
        [21, -5],
        [-64, 54],
        [-105, -18],
        [-23, 7],
        [-19, 12],
        [-7, 40],
        [15, 18],
        [13, 34],
        [-15, 23],
        [-20, 9],
        [-31, -1],
        [-29, -7],
        [-7, 14],
        [17, 37],
        [-15, 14],
        [-20, -7],
        [-29, -7],
        [-28, 11],
        [-26, 42],
        [-17, 0],
        [-12, -5],
        [-18, 16],
        [-19, 4],
        [-13, -5],
        [-18, 24],
        [-109, 49],
        [-47, 6],
        [-43, -22],
        [-24, 7],
        [-18, 32],
        [-14, 52],
        [-70, 41],
        [14, 27],
        [32, 6],
        [37, 19],
        [14, 23],
        [-29, 28],
        [-22, 7],
        [-9, 10],
        [-9, 24],
        [13, 12],
        [9, -6],
        [26, -4],
        [45, 6],
        [-16, 25],
        [-18, 6],
        [-8, 6],
        [-36, 3],
        [-17, -9],
        [-37, 4],
        [-9, 27],
        [-3, 23],
        [11, 51],
        [53, 46],
        [131, 51],
        [56, -7],
        [40, 9],
        [47, 31],
        [20, 28],
        [67, 16],
        [63, -29],
        [59, -109],
        [28, -37],
        [68, 64],
        [102, -2],
        [21, -36],
        [8, 30],
        [19, 36],
        [15, -16],
        [8, -22],
        [107, 6],
        [17, 6],
        [-29, 26],
        [-23, 62],
        [-5, 229],
        [-30, 64],
        [-34, 102],
        [-16, 60],
        [-1, 21],
        [5, 30],
        [42, -1],
        [32, -8],
        [62, 23],
        [30, -16],
        [-2, -47],
        [9, -60],
        [11, -28],
        [15, -33],
        [50, 3],
        [54, -19],
        [68, -3],
        [99, -34],
        [42, 20],
        [41, 41],
        [78, 27],
        [7, 14],
        [-45, -6],
        [-42, 26],
        [-5, 29],
        [5, 25],
        [16, 58],
        [120, 92],
        [85, 28],
        [89, 50],
        [45, 52],
        [30, 68],
        [10, 14],
        [12, 13],
        [-12, 24],
        [8, 256],
        [9, 46],
        [17, 38],
        [27, 29],
        [40, 32],
        [148, 44],
        [22, 17],
      ],
      [
        [50701, 81276],
        [3, -27],
        [11, -35],
        [7, -19],
        [-6, -25],
        [5, -21],
        [20, -37],
        [25, -35],
        [22, -23],
        [7, 3],
        [16, 6],
        [28, 23],
        [24, 7],
        [13, -17],
        [7, -10],
        [15, -40],
        [4, -41],
        [7, -34],
        [12, -14],
        [44, -5],
        [33, -13],
        [9, -11],
        [11, -76],
        [6, -11],
        [9, 9],
        [8, 12],
        [11, 2],
        [19, -5],
        [26, -1],
        [26, -8],
        [36, -44],
        [-1, -14],
        [-7, -25],
        [-3, -20],
        [6, -8],
        [10, -20],
        [-2, -24],
        [-10, -17],
        [-3, -14],
        [0, -9],
        [3, -7],
        [8, -6],
        [53, -9],
        [49, 9],
        [31, 24],
        [5, 25],
        [9, 29],
        [18, 25],
        [13, 8],
        [12, -10],
        [-20, -102],
        [14, -26],
        [2, -39],
        [6, -34],
        [17, 1],
        [21, -6],
        [15, -13],
        [18, -20],
        [25, -19],
        [18, -6],
        [6, -16],
        [15, -18],
        [22, -37],
        [20, -25],
        [10, 0],
        [19, 10],
        [28, 6],
        [22, -1],
      ],
      [
        [49672, 78285],
        [-10, -50],
        [-18, 46],
        [-25, 41],
        [-5, 37],
        [0, 10],
        [29, -27],
        [29, -57],
      ],
      [
        [62549, 44368],
        [-17, -5],
        [-8, 15],
        [-5, 36],
        [5, 35],
        [1, 28],
        [-14, 49],
        [14, 28],
        [12, -32],
        [6, -2],
        [18, -23],
        [-5, -42],
        [1, -13],
        [-8, -42],
        [0, -32],
      ],
      [
        [65499, 39550],
        [-40, -17],
        [-27, 6],
        [-54, 49],
        [-15, 33],
        [-21, 91],
        [5, 32],
        [17, 57],
        [38, 23],
        [41, -9],
        [18, -15],
        [21, -66],
        [28, -68],
        [-4, -80],
        [-7, -36],
      ],
      [
        [33103, 60192],
        [-2, -33],
        [-7, -6],
        [-11, 27],
        [-45, -4],
        [-7, 25],
        [-1, 11],
        [22, 42],
        [-26, 11],
        [-10, 18],
        [-22, 87],
        [2, 26],
        [9, 13],
        [15, 2],
        [28, -28],
        [20, -40],
        [7, -1],
        [3, -11],
        [-4, -29],
        [12, -24],
        [5, -17],
        [12, -69],
      ],
      [
        [32964, 61192],
        [-32, -6],
        [-22, 5],
        [-5, 41],
        [11, 35],
        [-8, 42],
        [5, 25],
        [11, 17],
        [18, -22],
        [3, -32],
        [11, -29],
        [51, -61],
        [-43, -15],
      ],
      [
        [32891, 61063],
        [-22, -26],
        [-11, 8],
        [-14, 50],
        [-9, 138],
        [7, 22],
        [5, 9],
        [30, -17],
        [12, -19],
        [13, -13],
        [-7, -25],
        [4, -103],
        [-8, -24],
      ],
      [
        [32991, 60996],
        [-15, -2],
        [-7, 5],
        [-2, 34],
        [12, 24],
        [7, 6],
        [10, -27],
        [3, -22],
        [-8, -18],
      ],
      [
        [34956, 54930],
        [20, 30],
        [27, 152],
        [19, 54],
        [20, 7],
        [109, -126],
        [51, -11],
        [103, -68],
        [38, -88],
        [86, -145],
        [45, -45],
        [1, -39],
        [-10, -60],
        [29, 53],
        [45, -84],
        [13, -41],
        [14, -76],
        [-5, -49],
        [-6, -25],
        [-1, -19],
        [14, 27],
        [7, 21],
        [3, 51],
        [10, 63],
        [15, 1],
        [12, -38],
        [24, -163],
        [9, -33],
        [4, -52],
        [-2, -23],
        [2, -22],
      ],
      [
        [35652, 54182],
        [-9, -12],
        [-23, -27],
        [-11, -37],
        [-6, -34],
        [-14, -24],
        [-14, -30],
        [-4, -24],
        [-13, -19],
        [-3, -32],
        [-32, -112],
        [-13, -50],
        [-19, -54],
        [-11, -20],
        [-16, -32],
        [-8, -36],
        [0, -39],
        [-11, -45],
        [-6, -40],
        [-10, -23],
        [-28, -124],
        [-1, -43],
        [-7, -26],
        [-19, -59],
        [-13, -36],
        [-23, -27],
        [-25, -29],
        [-9, -31],
        [-17, -17],
        [-12, -1],
        [-20, 12],
        [-28, 5],
        [-13, -3],
        [-7, 16],
        [-9, 36],
        [-13, 25],
        [-9, -9],
        [-19, -25],
        [-21, -15],
        [-15, 5],
        [-33, 17],
        [-15, 9],
        [-4, 16],
        [-5, 11],
        [-7, -5],
        [-10, -19],
        [-13, -20],
        [-19, -26],
        [-40, -48],
        [-11, -17],
        [-11, 10],
        [-17, 9],
        [-18, 1],
        [-39, 30],
        [-22, 22],
        [-10, 28],
        [-12, 11],
        [-7, 8],
      ],
      [
        [34402, 78779],
        [-6, -5],
        [-19, 8],
        [9, 18],
        [7, 5],
        [9, 2],
        [4, -5],
        [-1, -13],
        [-3, -10],
      ],
      [
        [34370, 78823],
        [-24, -25],
        [-9, 14],
        [2, 17],
        [13, 39],
        [-1, 11],
        [-14, 76],
        [2, 13],
        [4, 5],
        [21, -16],
        [3, -21],
        [-10, -46],
        [7, -31],
        [9, -23],
        [-3, -13],
      ],
      [
        [1066, 44162],
        [-4, -4],
        [-5, 22],
        [7, 34],
        [6, 12],
        [6, -26],
        [-10, -38],
      ],
      [
        [543, 43595],
        [-16, -4],
        [-16, 7],
        [-9, 33],
        [4, 14],
        [10, -7],
        [10, -24],
        [17, -11],
        [0, -8],
      ],
      [
        [32497, 62251],
        [-32, 0],
      ],
      [
        [32465, 62251],
        [3, 13],
        [14, 14],
        [11, -2],
        [4, -5],
        [0, -20],
      ],
      [
        [32546, 62140],
        [-4, -1],
        [-3, 5],
        [-3, 9],
        [-2, 8],
        [1, 6],
        [2, -3],
        [18, -5],
        [-2, -7],
        [-3, -7],
        [-4, -5],
      ],
      [
        [7926, 42247],
        [-5, -4],
        [-6, 10],
        [-2, 16],
        [2, 26],
        [13, -17],
        [6, -11],
        [-8, -20],
      ],
      [
        [8385, 41736],
        [-9, -15],
        [-11, 11],
        [-5, 14],
        [-2, 15],
        [2, 19],
        [26, -3],
        [8, -8],
        [-9, -33],
      ],
      [
        [7942, 42120],
        [-11, 0],
        [-10, 9],
        [2, 59],
        [3, 12],
        [15, -20],
        [13, -52],
        [-12, -8],
      ],
      [
        [8522, 41653],
        [40, -27],
        [7, -44],
        [-8, -29],
        [-21, 7],
        [-10, 16],
        [-14, 52],
        [-39, -12],
        [-27, 11],
        [-15, 67],
        [0, 31],
        [6, 19],
        [29, 20],
        [36, -15],
        [13, -38],
        [3, -58],
      ],
      [
        [11382, 46258],
        [42, -30],
        [13, 3],
        [-13, -29],
        [-42, -16],
        [-14, -15],
        [-16, 10],
        [-9, 34],
        [39, 43],
      ],
      [
        [11372, 46122],
        [-20, -45],
        [-1, 48],
        [8, 6],
        [7, 0],
        [6, -9],
      ],
      [
        [11486, 45785],
        [-10, -9],
        [-1, 61],
        [13, -12],
        [5, -9],
        [-2, -17],
        [-5, -14],
      ],
      [
        [11091, 46710],
        [-27, -14],
        [-13, 3],
        [-10, 47],
        [3, 29],
        [5, 9],
        [46, -11],
        [4, -22],
        [-1, -20],
        [-7, -21],
      ],
      [
        [11090, 46413],
        [-6, -11],
        [-11, 35],
        [-2, 14],
        [20, 18],
        [11, -9],
        [-12, -47],
      ],
      [
        [10886, 41557],
        [2, -11],
        [-11, 1],
        [-3, 4],
        [1, 20],
        [7, 25],
        [6, 21],
        [11, 20],
        [21, 19],
        [11, 8],
        [3, -5],
        [-4, -2],
        [-35, -41],
        [-10, -29],
        [-5, -20],
        [1, -6],
        [5, -4],
      ],
      [
        [11234, 46693],
        [-18, -5],
        [-3, 29],
        [6, 15],
        [8, 7],
        [13, -9],
        [8, -12],
        [-1, -11],
        [-13, -14],
      ],
      [
        [10921, 41255],
        [3, -21],
        [-6, 10],
        [-22, 20],
        [-2, 17],
        [27, -26],
      ],
      [
        [10880, 41365],
        [2, -16],
        [-10, 11],
        [-10, 29],
        [-17, 36],
        [-4, 15],
        [13, -14],
        [9, -21],
        [17, -40],
      ],
      [
        [12140, 41160],
        [-5, -12],
        [-1, 12],
        [-7, 6],
        [-11, 12],
        [-15, 14],
        [-8, 3],
        [-4, 8],
        [6, 4],
        [9, -6],
        [12, -13],
        [15, -13],
        [9, -15],
      ],
      [
        [11952, 41277],
        [0, -11],
        [-26, 55],
        [10, -4],
        [16, -40],
      ],
      [
        [11526, 39828],
        [-8, -11],
        [3, 15],
        [-6, 32],
        [-6, 5],
        [6, 9],
        [9, -25],
        [2, -25],
      ],
      [
        [10413, 42571],
        [-5, -7],
        [7, 46],
        [7, 6],
        [-9, -45],
      ],
      [
        [10155, 42269],
        [15, -28],
        [-20, 19],
        [-25, 8],
        [9, 5],
        [15, 0],
        [6, -4],
      ],
      [
        [10119, 42260],
        [-11, -3],
        [-27, 34],
        [11, 0],
        [15, -21],
        [12, -10],
      ],
      [
        [9708, 42709],
        [-2, -26],
        [-5, 25],
        [-17, 40],
        [-7, 18],
        [8, -3],
        [23, -54],
      ],
      [
        [9587, 42436],
        [1, -10],
        [-6, 1],
        [-10, 29],
        [-4, 25],
        [-6, 29],
        [-9, 21],
        [-1, 19],
        [0, 30],
        [10, -46],
        [9, -37],
        [8, -31],
        [8, -30],
      ],
      [
        [95611, 40180],
        [31, -36],
        [34, 15],
        [42, -57],
        [108, -172],
        [37, -37],
        [23, -14],
        [16, -28],
        [16, -40],
        [20, -28],
        [9, -26],
        [2, -36],
        [8, -22],
        [37, -57],
        [22, -50],
        [32, -26],
        [13, -30],
        [17, -14],
        [18, -31],
        [30, -24],
        [68, -88],
        [53, -84],
        [26, -52],
        [29, -46],
        [36, -37],
        [34, -42],
        [17, -99],
        [-9, -35],
        [-20, -18],
        [-18, -1],
        [-17, -12],
        [-56, 64],
        [-14, 9],
        [-15, -4],
        [-8, 14],
        [-6, 21],
        [-35, 24],
        [-32, 37],
        [-9, 26],
        [-5, 32],
        [-8, 19],
        [-45, 28],
        [-31, 31],
        [-22, 44],
        [-34, 31],
        [-54, 63],
        [-27, 20],
        [-24, 31],
        [-65, 115],
        [-23, 21],
        [-20, 51],
        [-55, 120],
        [-27, 50],
        [-29, 44],
        [-22, 52],
        [-17, 61],
        [-40, 88],
        [-5, 38],
        [2, 38],
        [-10, 25],
        [-16, 15],
        [-8, 26],
        [1, 35],
        [5, 18],
        [40, -60],
      ],
      [
        [96539, 38811],
        [-9, -22],
        [-11, 5],
        [-8, 8],
        [-6, 12],
        [6, 44],
        [24, -22],
        [4, -25],
      ],
      [
        [94430, 40718],
        [-4, -12],
        [-2, 92],
        [8, 34],
        [5, -71],
        [-7, -43],
      ],
      [
        [96669, 39498],
        [13, -10],
        [22, 1],
        [-5, -98],
        [-32, -16],
        [-11, 1],
        [-7, 21],
        [-18, 14],
        [1, 33],
        [-18, 76],
        [31, 11],
        [17, 20],
        [0, -18],
        [2, -22],
        [5, -13],
      ],
      [
        [96262, 39919],
        [-15, -5],
        [18, 52],
        [1, 33],
        [7, 63],
        [-1, 22],
        [12, -3],
        [12, -18],
        [-14, -16],
        [-5, -28],
        [0, -34],
        [6, -7],
        [-9, -37],
        [-12, -22],
      ],
      [
        [96499, 39653],
        [-15, -4],
        [-20, 41],
        [-39, 21],
        [-17, 36],
        [-11, 43],
        [22, 11],
        [22, 58],
        [-15, 22],
        [-26, 3],
        [3, 23],
        [42, 27],
        [18, -16],
        [8, -18],
        [-2, -92],
        [19, -29],
        [20, -65],
        [-1, -18],
        [-8, -43],
      ],
      [
        [69217, 23554],
        [23, -4],
        [13, 6],
        [62, 76],
        [16, 2],
        [-2, -59],
        [16, -26],
        [-20, -6],
        [-38, 2],
        [-9, -33],
        [39, -42],
        [19, -6],
        [15, 0],
        [29, 10],
        [23, 15],
        [36, 36],
        [22, 14],
        [41, 0],
        [21, 34],
        [10, 10],
        [24, -1],
        [21, -13],
        [13, -31],
        [7, -37],
        [-5, -37],
        [-15, -36],
        [-26, -22],
        [6, -26],
        [-7, -13],
        [-13, -1],
        [-12, 6],
        [-16, 31],
        [-20, 16],
        [-48, -1],
        [-22, -2],
        [-3, -23],
        [-12, -18],
        [-12, -10],
        [-16, 4],
        [-3, -10],
        [9, -24],
        [21, -31],
        [36, -22],
        [21, -5],
        [3, 42],
        [26, 4],
        [23, -12],
        [16, -30],
        [-13, -10],
        [-12, -16],
        [-3, -21],
        [-23, -23],
        [-13, -2],
        [-44, 11],
        [-26, 25],
        [-6, 18],
        [-16, 7],
        [-18, -23],
        [-19, -5],
        [-37, 19],
        [-35, 31],
        [-22, 12],
        [-33, 8],
        [-19, -71],
        [-26, -30],
        [-33, -3],
        [-16, 6],
        [-9, 28],
        [2, 29],
        [5, 29],
        [11, 29],
        [6, 32],
        [-2, 30],
        [-12, 22],
        [6, 40],
        [-12, 30],
        [4, 23],
        [20, 16],
        [-9, 13],
        [-10, 4],
        [-7, 18],
        [-6, 22],
        [7, 41],
        [12, 39],
        [-2, 45],
        [19, 42],
        [17, 47],
        [12, 19],
        [15, 3],
        [7, -13],
        [3, -26],
        [-6, -17],
        [14, -7],
        [4, -55],
        [-9, -22],
        [-1, -22],
        [-19, -46],
        [5, -37],
        [37, -16],
      ],
      [
        [69244, 23583],
        [-17, -5],
        [-5, 19],
        [1, 25],
        [-10, 20],
        [-5, 22],
        [4, 20],
        [28, 3],
        [28, -7],
        [8, -35],
        [-21, -48],
        [-11, -14],
      ],
      [
        [64398, 25092],
        [-20, -5],
        [-18, 11],
        [-11, 32],
        [23, 27],
        [12, -19],
        [9, -20],
        [5, -26],
      ],
      [
        [55552, 86607],
        [9, 0],
        [3, 5],
        [15, -4],
        [23, -22],
        [4, -12],
        [16, -6],
        [5, -13],
        [-18, -39],
        [-11, 0],
        [-8, 4],
        [-15, -4],
        [-8, -7],
        [-3, -16],
        [0, -34],
        [-65, -7],
        [-15, 10],
        [-20, 77],
        [4, 20],
        [14, 8],
        [12, 2],
        [1, -41],
        [18, 4],
        [5, 27],
        [1, 20],
        [-4, 9],
        [-12, 8],
        [-7, 13],
        [10, 21],
        [18, 9],
        [16, -28],
        [12, -4],
      ],
      [
        [55461, 86513],
        [2, -13],
        [-11, 3],
        [-8, -4],
        [-6, -16],
        [-12, 5],
        [-5, 23],
        [9, 34],
        [22, 2],
        [9, -34],
      ],
      [
        [55725, 86428],
        [-2, -13],
        [-23, -3],
        [-10, 12],
        [-21, -2],
        [-3, 6],
        [8, 12],
        [17, 8],
        [22, -3],
        [12, -17],
      ],
      [
        [57721, 86714],
        [-10, -2],
        [-26, -20],
        [-40, -5],
        [-17, -14],
        [-61, 42],
        [-11, 3],
        [-36, -11],
        [-34, -31],
        [-64, -9],
        [-32, -10],
        [-20, -14],
        [-4, 33],
        [9, 43],
        [14, 29],
        [1, 18],
        [-10, -1],
        [-21, -42],
        [-11, -49],
        [-21, -25],
        [-48, -10],
        [-47, 39],
        [-23, 0],
        [14, -28],
        [10, -31],
        [-1, -17],
        [-25, 3],
        [-28, -19],
        [-25, -27],
        [-11, 0],
        [-17, 38],
        [-30, -18],
        [-26, -24],
        [-52, -7],
        [-31, -31],
        [-55, -22],
        [-30, 1],
        [-69, -25],
        [-23, -40],
        [-20, -14],
        [-29, 12],
        [-88, -19],
        [-84, -25],
        [-36, 1],
        [-36, 11],
        [-38, -35],
        [-40, -47],
        [-45, -16],
        [-16, 6],
        [13, 24],
        [29, 25],
        [21, 35],
        [2, 28],
        [-13, 12],
        [-19, 3],
        [-24, 30],
        [-23, 64],
        [-13, 3],
        [-6, -17],
        [-7, -49],
        [-7, -14],
        [-12, -11],
        [-15, -12],
        [-14, -5],
        [-51, 1],
        [-7, 24],
        [0, 11],
        [9, 32],
        [-7, 6],
        [7, 26],
        [12, -2],
        [14, 4],
        [7, 13],
        [0, 16],
        [-20, 4],
        [-1, 11],
        [18, 45],
        [2, 12],
        [-7, 3],
        [-11, -5],
        [-73, 14],
        [-90, 57],
        [-22, 3],
        [-14, 51],
        [-21, -6],
        [-32, -30],
        [-24, 22],
        [-25, 15],
        [-7, 24],
        [0, 34],
        [-2, 41],
        [-7, 47],
        [-5, 68],
        [5, 53],
        [20, 39],
        [8, 25],
        [9, 64],
        [3, 74],
        [-6, 26],
        [2, 17],
        [16, 0],
        [-4, 14],
        [-7, 8],
        [-8, 17],
        [7, 9],
        [19, 0],
        [2, 6],
        [2, 8],
        [-15, 43],
        [-2, 21],
        [-21, 62],
        [-23, 60],
        [-36, 43],
        [13, 71],
        [14, 64],
        [-3, 31],
        [-5, 37],
        [-44, 41],
        [-7, 58],
        [-10, 63],
        [4, 38],
        [7, 29],
        [14, 29],
        [73, 92],
        [4, 48],
        [50, 4],
        [-23, 42],
        [-6, 24],
        [-1, 29],
        [71, 19],
        [27, -16],
        [62, 20],
        [55, 38],
        [-1, 20],
        [-8, 18],
        [-12, 35],
        [8, 10],
        [21, -7],
        [-10, 17],
        [2, 18],
        [22, -7],
        [36, 51],
        [1, 39],
        [63, 21],
        [71, 79],
        [33, 25],
        [32, 18],
        [69, 80],
        [29, 3],
        [15, 54],
        [58, 72],
        [17, 9],
        [27, 64],
        [71, 75],
        [45, 95],
        [25, 33],
        [8, 36],
        [28, 3],
        [25, 27],
        [54, 18],
        [53, -5],
        [22, -12],
        [21, 4],
        [-2, 32],
        [-15, 20],
        [12, 19],
        [28, 14],
        [-2, 32],
        [-7, 20],
        [-23, 25],
        [12, 58],
        [2, 63],
        [11, 73],
        [-29, 39],
        [-112, 65],
        [-21, -2],
        [-24, 8],
        [-26, 50],
        [11, 43],
        [2, 16],
        [-11, -1],
        [-16, -21],
        [-36, -23],
        [-46, 18],
        [-23, -4],
      ],
      [
        [56109, 86599],
        [-20, -3],
        [-29, 29],
        [-3, 11],
        [11, 6],
        [-8, 23],
        [3, 10],
        [22, -18],
        [12, -21],
        [-12, -5],
        [20, -22],
        [4, -10],
      ],
      [
        [55893, 88272],
        [3, -11],
        [17, 3],
        [21, 20],
        [16, -9],
        [-2, -28],
        [-10, 1],
        [-3, 4],
        [-14, -16],
        [-2, -9],
        [-16, -7],
        [-29, 28],
        [-18, 45],
        [42, 0],
        [-4, -11],
        [-1, -10],
      ],
      [
        [56159, 86618],
        [35, -13],
        [15, 5],
        [17, -30],
        [-29, -20],
        [-2, -24],
        [12, -14],
        [4, -22],
        [-29, 0],
        [-13, 18],
        [-6, 23],
        [-13, 16],
        [-18, 13],
        [9, 16],
        [5, 24],
        [13, 8],
      ],
      [
        [55958, 86710],
        [-4, -27],
        [-19, 3],
        [-19, -5],
        [-15, 27],
        [-9, 45],
        [3, 9],
        [12, 10],
        [9, -24],
        [42, -38],
      ],
      [
        [56064, 86486],
        [-28, -20],
        [-10, 5],
        [3, 33],
        [16, 15],
        [28, 2],
        [-9, -35],
      ],
      [
        [56007, 86467],
        [-24, -6],
        [-15, 17],
        [6, 12],
        [17, 14],
        [18, -2],
        [4, -16],
        [-6, -19],
      ],
      [
        [56902, 89280],
        [-42, -19],
        [-33, 12],
        [-1, 37],
        [21, 18],
        [37, 7],
        [52, -18],
        [7, -10],
        [-29, -7],
        [-12, -20],
      ],
      [
        [99999, 42529],
        [-42, -77],
        [-15, -39],
        [-13, -44],
        [-36, -47],
        [-15, -63],
        [1, -63],
        [36, 66],
        [40, 54],
        [12, 11],
        [13, 0],
        [-1, -19],
        [-6, -18],
        [-5, -48],
        [11, -45],
        [-30, 5],
        [-29, -4],
        [-35, -25],
        [-34, -11],
        [-13, -1],
        [-13, 9],
        [-8, 13],
        [-6, 29],
        [-6, 5],
        [-28, -1],
        [-40, -59],
        [-14, -49],
        [-16, -3],
        [-18, 11],
        [-23, -38],
        [-26, -14],
        [-12, 32],
        [-7, 40],
        [-10, 29],
        [-29, 7],
        [4, 36],
        [8, 15],
        [7, 21],
        [5, 24],
        [14, -16],
        [14, -9],
        [16, 18],
        [17, 1],
        [17, 53],
        [26, 33],
        [37, 26],
        [37, 19],
        [19, 3],
        [18, 11],
        [32, 50],
        [21, 25],
        [24, 15],
        [22, 9],
        [20, -8],
        [17, 5],
        [42, 35],
        [0, -9],
      ],
      [
        [99521, 41836],
        [0, -26],
        [8, -11],
        [8, -2],
        [21, -48],
        [31, -42],
        [19, -32],
        [1, -28],
        [-6, -29],
        [8, -51],
        [4, -54],
        [14, -86],
        [-20, -16],
        [-30, -2],
        [-7, -15],
        [-11, 8],
        [-25, -6],
        [-25, -28],
        [-23, -38],
        [-27, 0],
        [-30, -8],
        [-30, 5],
        [-21, 21],
        [-38, 22],
        [-49, 19],
        [-21, 15],
        [-17, 25],
        [-16, 63],
        [-3, 31],
        [3, 30],
        [15, 10],
        [12, 15],
        [1, 19],
        [6, 14],
        [7, 5],
        [3, 9],
        [-5, 32],
        [-1, 29],
        [29, 53],
        [31, 45],
        [56, 42],
        [34, -4],
        [52, 33],
        [17, 15],
        [16, -10],
        [9, -24],
      ],
      [
        [0, 42174],
        [30, 49],
        [9, 7],
        [10, -45],
        [-12, -49],
        [-30, -43],
        [-7, -22],
        [0, 103],
      ],
      [
        [99999, 42071],
        [-21, -21],
        [-8, 21],
        [10, 50],
        [19, 52],
        [0, -42],
        [0, -60],
      ],
      [
        [99579, 40913],
        [0, -25],
        [-36, -16],
        [-12, 20],
        [-8, 4],
        [-21, -36],
        [-6, -15],
        [-2, -11],
        [-6, -6],
        [-39, -17],
        [-17, 17],
        [12, 12],
        [14, 23],
        [14, -3],
        [15, 22],
        [14, 33],
        [21, 8],
        [14, 13],
        [24, -10],
        [19, -13],
      ],
      [
        [281, 41487],
        [-8, -8],
        [-6, 2],
        [-7, 9],
        [-4, 16],
        [9, 14],
        [13, -16],
        [3, -17],
      ],
      [
        [344, 41339],
        [-3, -10],
        [-15, 17],
        [-6, 12],
        [16, 9],
        [8, -3],
        [0, -25],
      ],
      [
        [486, 41501],
        [-16, -6],
        [-14, 25],
        [9, 26],
        [13, -7],
        [7, -25],
        [1, -13],
      ],
      [
        [290, 41893],
        [-7, -20],
        [-6, 7],
        [8, 33],
        [1, 15],
        [-12, 17],
        [-1, 12],
        [3, 8],
        [15, -20],
        [9, -15],
        [1, -8],
        [-2, -15],
        [-9, -14],
      ],
      [
        [407, 40802],
        [-3, -5],
        [-8, 6],
        [-6, 8],
        [-1, 8],
        [3, 10],
        [6, 6],
        [3, -2],
        [-2, -3],
        [-3, -4],
        [0, -11],
        [5, -6],
        [4, -1],
        [2, -6],
      ],
      [
        [56, 40932],
        [0, -17],
        [-4, 1],
        [-5, 8],
        [-2, -4],
        [-2, -5],
        [-1, -12],
        [-1, -7],
        [-4, 3],
        [0, 11],
        [1, 9],
        [2, 12],
        [7, 11],
        [9, -10],
      ],
      [
        [99231, 41965],
        [-15, -9],
        [8, 45],
        [8, 15],
        [5, 3],
        [9, 3],
        [-4, -32],
        [-11, -25],
      ],
      [
        [99839, 41839],
        [-10, -16],
        [-4, 79],
        [9, 0],
        [7, -8],
        [4, -20],
        [-6, -35],
      ],
      [
        [99818, 41415],
        [-2, -5],
        [-24, 46],
        [0, 19],
        [5, 16],
        [9, 15],
        [9, -26],
        [7, -44],
        [-4, -21],
      ],
      [
        [99673, 41630],
        [-14, -10],
        [-8, 35],
        [11, 35],
        [12, 4],
        [6, -36],
        [-7, -28],
      ],
      [
        [99999, 42315],
        [-4, -1],
        [0, 11],
        [-99995, 19],
        [16, 28],
        [12, 5],
        [-8, -27],
        [0, -14],
        [-20, -21],
        [99999, 0],
      ],
      [
        [0, 42538],
        [9, 15],
        [6, 0],
        [-3, -13],
        [-12, -11],
        [0, 9],
      ],
      [
        [99199, 44639],
        [-10, -6],
        [-18, 5],
        [-4, 9],
        [6, 2],
        [11, 6],
        [14, -3],
        [3, -6],
        [-2, -7],
      ],
      [
        [98507, 39346],
        [-2, -7],
        [-8, 2],
        [-2, 13],
        [5, 7],
        [7, -4],
        [0, -11],
      ],
      [
        [358, 39937],
        [0, -1],
        [-1, 0],
        [-2, 2],
        [-2, 4],
        [0, 4],
        [0, 4],
        [1, 0],
        [2, -4],
        [1, -4],
        [1, -5],
      ],
      [
        [60145, 60055],
        [5, 1],
        [38, 28],
        [37, 5],
        [36, -20],
        [23, -5],
        [11, 10],
        [10, 26],
        [9, 41],
        [15, 23],
        [20, 5],
        [26, -47],
        [43, -125],
        [11, -7],
        [7, 3],
        [21, 100],
        [17, 78],
        [31, 144],
        [18, 83],
        [16, -24],
        [17, -42],
        [18, -20],
        [20, -12],
        [10, -2],
        [12, -17],
        [44, -103],
        [15, -24],
        [20, -2],
        [86, 33],
        [51, 60],
        [7, 24],
        [14, 0],
        [17, -27],
        [7, -25],
        [11, -34],
        [20, -5],
        [49, 24],
        [23, 14],
        [21, -12],
        [26, -9],
        [16, 0],
        [38, -34],
        [47, 11],
        [22, -2],
        [22, -15],
        [37, -53],
        [47, -65],
        [68, -47],
        [14, -19],
        [33, -74],
        [51, -142],
        [67, -136],
        [73, -107],
        [39, -75],
        [26, -91],
        [26, -83],
        [26, -35],
        [24, -29],
        [26, -63],
        [18, -52],
        [24, -60],
      ],
      [
        [61771, 59024],
        [-27, -82],
        [-37, -110],
        [-42, -127],
        [-13, -32],
        [-37, -77],
        [-7, -22],
        [-7, -56],
        [0, -101],
        [5, -130],
        [4, -119],
        [21, -14],
        [23, -9],
        [27, 16],
        [31, 13],
        [40, 8],
        [43, 24],
        [26, 20],
        [27, -2],
        [24, -21],
        [12, -19],
        [17, -6],
        [21, 1],
      ],
      [
        [56756, 85178],
        [2, 23],
        [37, 113],
        [7, 89],
        [13, 13],
        [4, 12],
        [-6, 29],
        [-38, 18],
        [-15, -3],
        [-14, -30],
        [-14, -22],
        [-34, -14],
        [-29, 23],
        [-67, 32],
        [-18, 41],
        [-4, 42],
        [-35, 40],
        [-15, 48],
        [6, 33],
        [32, 22],
        [9, 19],
        [-40, -3],
        [-9, 5],
        [-2, 17],
        [-18, 58],
        [16, 23],
        [7, 22],
        [-13, 19],
        [4, 22],
        [10, 22],
        [-6, 50],
        [40, 27],
        [40, 19],
        [83, 10],
        [-8, 46],
        [34, 2],
        [57, 56],
        [56, -10],
        [82, 38],
        [157, 0],
        [22, 22],
        [-4, 22],
        [0, 24],
        [30, -7],
        [49, 4],
        [186, -46],
        [45, 0],
        [63, -47],
        [34, -13],
        [101, 0],
        [154, -21],
        [31, 32],
        [3, 9],
      ],
      [
        [56282, 85611],
        [20, -14],
        [18, 4],
        [18, 10],
        [40, -9],
        [92, -71],
        [8, -19],
        [-54, -8],
        [-13, -22],
        [-13, -15],
        [-15, -5],
        [-27, -30],
        [-35, -29],
        [-8, -17],
        [-64, 3],
        [-35, -11],
        [-29, -33],
        [-11, -62],
        [-21, -49],
        [-21, -18],
        [-22, -3],
        [-6, 19],
        [3, 18],
        [46, 69],
        [10, 23],
        [-23, 10],
        [-20, 24],
        [-42, 28],
        [-8, 22],
        [10, 2],
        [10, 7],
        [11, 19],
        [5, 21],
        [-34, 64],
        [18, 10],
        [21, -2],
        [22, -19],
        [24, 22],
        [11, 3],
        [17, -8],
        [17, 42],
        [40, 14],
        [20, 13],
        [20, -3],
      ],
      [
        [56367, 85729],
        [-23, -28],
        [-13, 11],
        [-7, 14],
        [-30, -65],
        [-33, -11],
        [-19, 13],
        [2, 24],
        [-19, 63],
        [-29, 19],
        [-40, 1],
        [-30, 26],
        [113, 18],
        [12, 30],
        [23, 32],
        [17, 3],
        [15, -7],
        [2, -25],
        [4, -10],
        [51, -13],
        [20, -41],
        [8, -50],
        [-24, -4],
      ],
      [
        [56484, 85570],
        [-23, -6],
        [-55, 41],
        [13, 27],
        [15, 11],
        [47, -17],
        [6, -42],
        [-3, -14],
      ],
      [
        [60724, 62214],
        [84, -333],
        [34, -196],
        [30, -206],
        [23, -308],
        [21, -157],
        [34, -78],
        [23, -146],
        [21, -6],
        [14, -40],
        [25, -138],
        [18, -51],
        [9, 44],
        [-1, 25],
        [-7, 43],
        [7, 54],
        [14, 33],
        [31, -44],
        [18, -34],
        [5, -68],
        [7, -37],
        [33, -80],
        [28, -23],
        [37, -6],
        [30, -17],
        [25, -29],
        [46, -81],
        [104, -71],
        [85, -216],
        [49, -151],
        [163, -227],
        [28, -110],
        [15, -106],
        [34, 5],
        [59, -117],
        [17, -89],
        [48, -32],
        [9, 52],
        [23, -43],
        [9, -67],
      ],
      [
        [61976, 59163],
        [-31, -26],
        [-34, -24],
        [-4, 1],
        [-12, -31],
        [-16, -84],
        [-17, -25],
        [-10, -2],
        [-53, 79],
        [-8, 4],
        [-11, -15],
        [-9, -16],
      ],
      [
        [61132, 61107],
        [9, -56],
        [-27, 21],
        [-5, 12],
        [12, 22],
        [3, 13],
        [8, -12],
      ],
      [
        [61150, 60884],
        [11, -30],
        [8, 3],
        [7, 10],
        [4, 21],
        [44, -42],
        [-3, -29],
        [-26, -1],
        [-30, 12],
        [-28, -4],
        [-33, 12],
        [-8, 48],
        [21, -23],
        [11, 6],
        [2, 6],
        [-15, 33],
        [-21, 6],
        [1, 26],
        [10, 10],
        [6, 12],
        [-13, 36],
        [24, -9],
        [15, -21],
        [10, -25],
        [3, -57],
      ],
      [
        [52426, 54008],
        [7, -3],
        [41, 2],
        [12, -30],
        [-1, -45],
        [-43, -131],
        [-8, -55],
        [-17, -46],
        [-14, -4],
        [-49, 27],
        [-9, 17],
        [-3, 22],
        [5, 52],
        [4, 16],
        [23, 10],
        [8, 9],
        [13, 56],
        [4, 51],
        [10, 39],
        [17, 13],
      ],
      [
        [52664, 52437],
        [2, 13],
        [-25, 35],
        [-18, 3],
        [-16, 11],
        [13, 90],
        [17, 80],
        [25, 61],
        [13, 14],
        [4, 30],
        [20, 99],
        [25, 80],
        [-8, 81],
        [6, 136],
      ],
      [
        [52722, 53170],
        [7, -4],
        [1, -13],
        [2, -19],
        [9, -16],
        [31, -27],
        [91, 0],
        [54, 0],
        [80, 0],
        [85, 0],
        [64, 0],
      ],
      [
        [25607, 59561],
        [-7, -8],
        [5, -58],
        [-16, -35],
        [-14, -25],
        [-26, -7],
        [-44, -2],
        [-66, 28],
        [-48, 39],
        [-26, 0],
        [8, -13],
        [21, -8],
        [27, -27],
        [-8, -8],
        [-99, 57],
        [-114, 112],
        [-68, 18],
        [-78, 30],
        [-46, 71],
        [-35, 30],
      ],
      [
        [56986, 70077],
        [21, -70],
        [43, -12],
        [142, 62],
        [157, -62],
        [86, -24],
        [133, -54],
        [81, -95],
        [23, -12],
        [58, 2],
        [38, -56],
        [152, -27],
        [81, -62],
        [46, -50],
        [28, -15],
        [24, 2],
        [33, 19],
        [42, 35],
        [45, 48],
        [94, 125],
        [33, 22],
        [22, -6],
        [27, 2],
        [11, 33],
        [14, 23],
        [8, 27],
        [15, 31],
        [48, 9],
        [98, 54],
        [-11, -25],
        [-89, -61],
        [38, -8],
        [39, 21],
        [45, 13],
        [8, 26],
        [6, 49],
        [9, 6],
        [30, -9],
        [92, -74],
        [23, -2],
        [65, 41],
        [13, 9],
        [21, -23],
        [48, -93],
        [-17, 2],
        [-51, 80],
        [-4, -40],
        [-29, -70],
        [36, -30],
        [30, -11],
        [16, -39],
        [10, -35],
        [29, 15],
        [21, 47],
        [-11, 27],
        [-8, 27],
        [10, 1],
        [20, -23],
        [58, -89],
        [20, -19],
        [22, 3],
        [48, 26],
        [13, -4],
        [63, 33],
        [8, -25],
        [10, -24],
        [51, 27],
        [80, 0],
        [66, 29],
        [76, 71],
        [6, 11],
      ],
      [
        [59695, 68823],
        [-15, -26],
        [-32, -93],
        [-33, -296],
        [-47, -231],
        [-5, -144],
        [-8, -52],
        [-23, -73],
        [-27, -72],
        [-49, 37],
        [-79, 126],
        [-46, 120],
        [-49, 77],
        [-47, 103],
        [-13, 73],
        [1, 48],
        [-21, 115],
        [-15, 55],
        [-57, 123],
        [-16, 65],
        [-13, 29],
        [-12, 41],
        [-21, 160],
        [-23, 101],
        [-25, -28],
        [4, -43],
        [-22, -59],
        [-14, -68],
        [11, -56],
        [46, -85],
        [10, -37],
        [11, -80],
        [-2, -110],
        [7, -37],
        [35, -81],
        [13, -48],
        [7, -42],
        [12, -38],
        [34, -71],
        [50, -134],
        [47, -91],
        [35, -44],
        [14, -44],
        [3, -113],
        [-2, -55],
        [30, -101],
        [11, -52],
        [29, -42],
        [13, -48],
        [12, -78],
        [18, -231],
        [26, -56],
        [77, -303],
        [66, -192],
        [32, -144],
        [48, -174],
        [95, -383],
        [56, -118],
        [22, -66],
        [41, -51],
        [44, -74],
        [-42, 7],
        [-10, -5],
        [-15, -12],
        [-7, -45],
        [-3, -37],
        [5, -194],
        [11, -98],
        [37, -188],
        [28, -56],
        [14, -36],
        [18, -27],
        [88, -63],
        [52, -136],
        [115, -170],
        [11, -47],
        [0, -11],
      ],
      [
        [27687, 49891],
        [6, 8],
        [40, 29],
        [17, 29],
        [20, 26],
        [18, 41],
        [11, 39],
        [28, 180],
        [26, 114],
        [-5, 54],
        [-21, 74],
        [-5, 109],
        [2, 33],
        [-3, 25],
        [-14, -45],
        [4, -160],
        [-13, -72],
        [-17, -18],
        [-12, 13],
        [7, 117],
        [-13, -22],
        [-21, -79],
        [-34, -59],
        [-1, -19],
        [-9, -25],
        [-26, 23],
        [-20, 24],
        [-65, 132],
        [-43, 28],
        [-25, 46],
        [-6, 19],
        [-3, 27],
        [26, 27],
        [28, 37],
        [2, 82],
        [0, 65],
        [-20, 109],
        [9, 144],
        [-5, 56],
        [-23, 119],
        [17, 60],
        [60, 44],
        [20, 29],
        [13, 95],
        [14, 56],
        [27, -22],
        [21, 2],
        [-28, 21],
        [-24, 85],
        [-3, 40],
        [44, 116],
        [24, 30],
        [28, 62],
        [25, 93],
        [6, 147],
        [-10, 105],
        [-8, 111],
        [15, 28],
        [36, 15],
        [30, 36],
        [15, 33],
        [36, -5],
        [41, 51],
        [66, 26],
        [91, 58],
        [20, 52],
        [-9, 92],
      ],
      [
        [28094, 52681],
        [9, -12],
        [25, -44],
        [16, -43],
        [26, -27],
        [21, -22],
        [55, -88],
        [37, -44],
        [40, -41],
        [57, -42],
        [36, 7],
        [8, -32],
        [7, -34],
        [13, -19],
        [21, -17],
        [12, -5],
        [4, -9],
        [12, -122],
        [8, -17],
        [29, -20],
        [35, -7],
        [14, 4],
        [31, -34],
        [23, -16],
        [25, -12],
        [17, -3],
        [8, 5],
        [3, 12],
        [14, -2],
        [21, -16],
        [30, -3],
        [19, 15],
        [2, 25],
        [2, 43],
        [7, 15],
        [21, 25],
        [11, -5],
        [57, -54],
        [11, -19],
        [15, -38],
        [26, -55],
        [29, -36],
        [44, -15],
        [42, -58],
        [50, -40],
      ],
      [
        [27741, 50130],
        [-5, -22],
        [-27, 2],
        [-7, 7],
        [0, 25],
        [6, 81],
        [7, 34],
        [22, 32],
        [18, 16],
        [23, -3],
        [25, -29],
        [-29, -55],
        [-16, -8],
        [-6, -7],
        [-11, -73],
      ],
      [
        [28080, 52564],
        [-15, -4],
        [-7, 28],
        [19, 32],
        [6, 6],
        [-3, -62],
      ],
      [
        [24907, 51398],
        [-15, -1],
        [-43, 56],
        [3, 55],
        [17, 37],
        [56, 18],
        [23, -34],
        [-2, -66],
        [-19, -48],
        [-15, -9],
        [-5, -8],
      ],
      [
        [25161, 51318],
        [-33, -24],
        [-11, 11],
        [-7, 11],
        [-2, 15],
        [19, 35],
        [17, 20],
        [16, 41],
        [29, 24],
        [9, -6],
        [5, -8],
        [2, -14],
        [-9, -33],
        [-18, -23],
        [-17, -49],
      ],
      [
        [24604, 51577],
        [-28, -10],
        [-24, 20],
        [-10, 31],
        [-2, 46],
        [2, 15],
        [52, 16],
        [17, -38],
        [0, -57],
        [-7, -23],
      ],
      [
        [24882, 51071],
        [-11, -1],
        [-16, 24],
        [12, 45],
        [13, -11],
        [9, -12],
        [5, -18],
        [-12, -27],
      ],
      [
        [24646, 51857],
        [18, -37],
        [9, -106],
        [56, -112],
        [7, -62],
        [-5, -29],
        [2, -11],
        [27, -44],
        [18, -47],
        [-30, -108],
        [-62, -46],
        [-67, 2],
        [-13, 12],
        [-18, 41],
        [-4, 37],
        [11, 35],
        [34, 54],
        [53, 48],
        [6, 37],
        [-21, 35],
        [-14, 71],
        [-34, 50],
        [-16, 152],
        [-11, 8],
        [-23, -21],
        [-11, 18],
        [-2, 10],
        [25, 35],
        [5, 24],
        [36, 12],
        [15, -20],
        [9, -38],
      ],
      [
        [24840, 51650],
        [-13, -17],
        [-52, 20],
        [-16, 33],
        [13, 46],
        [11, 18],
        [31, -17],
        [32, -51],
        [-6, -32],
      ],
      [
        [30061, 63201],
        [12, 10],
        [8, 34],
        [11, 31],
        [14, 17],
        [17, 10],
        [32, -1],
        [44, -27],
        [13, 1],
        [43, 24],
        [35, 14],
        [34, -16],
        [13, -21],
        [28, -33],
        [14, -10],
        [43, 1],
        [12, -3],
        [37, -55],
        [30, -22],
        [18, -1],
        [32, 21],
        [16, -1],
        [18, -47],
        [4, -67],
        [15, -61],
        [24, -39],
        [115, 16],
        [25, -32],
        [-8, -27],
        [-17, -14],
        [-54, 6],
        [-24, -3],
        [-5, -26],
        [0, -25],
        [32, -6],
        [31, -12],
        [32, -20],
        [33, -13],
        [36, -9],
        [36, -14],
        [61, -49],
        [66, -109],
        [18, -25],
        [12, -34],
        [-6, -43],
        [-24, -69],
        [-13, -22],
        [-20, -14],
        [-13, -28],
        [-13, -49],
        [-8, -4],
        [-9, 2],
        [-16, 28],
        [-12, 42],
        [-32, 39],
        [-38, -5],
        [-56, 24],
        [-34, -12],
        [-34, -2],
        [-35, 12],
        [-35, 4],
        [-35, -15],
        [-34, -25],
        [-12, -17],
        [-22, -39],
        [-12, -15],
        [-82, -20],
        [-24, 29],
        [-22, 40],
        [-32, 5],
        [-46, -30],
        [-28, -11],
        [-12, -14],
        [-3, -14],
        [0, -56],
        [-7, -33],
        [-45, -127],
        [-25, -90],
        [-10, -28],
        [-12, -6],
        [-23, 52],
        [-14, 19],
        [-17, 9],
        [-7, 27],
        [0, 39],
        [-5, 38],
        [-10, 29],
        [-16, 20],
      ],
      [
        [32977, 60627],
        [-26, -13],
        [-11, 100],
        [-18, 72],
        [3, 45],
        [3, 17],
        [38, -28],
        [12, -33],
        [7, -89],
        [-8, -71],
      ],
      [
        [61976, 59163],
        [4, -27],
        [47, -114],
        [15, -56],
        [16, -102],
        [-9, -56],
        [-12, -37],
        [-18, -33],
        [-62, -81],
        [-69, -52],
        [-44, -103],
        [-33, 7],
        [5, -39],
        [12, -5],
        [19, 8],
        [38, 30],
        [34, 14],
        [37, 1],
        [33, -13],
        [23, -38],
      ],
      [
        [41679, 99979],
        [267, -35],
        [141, -40],
        [30, 1],
        [191, -15],
        [182, -19],
        [302, -51],
        [42, -16],
        [-32, -14],
        [-75, -9],
        [-386, -16],
        [-700, -21],
        [-401, -39],
        [-127, -2],
        [-11, -59],
        [54, -3],
        [89, 8],
        [314, 59],
        [117, 9],
        [219, -5],
        [285, -22],
        [114, 8],
        [205, -6],
        [239, 17],
        [283, 37],
        [77, -82],
        [104, -81],
        [83, 9],
        [70, -5],
        [24, -26],
        [39, -12],
        [80, 6],
        [245, -23],
        [168, -42],
        [63, -19],
        [30, -28],
        [18, -22],
        [-27, -28],
        [-105, -49],
        [-133, -45],
        [-179, -34],
        [-207, -22],
        [-1588, -73],
        [-54, -17],
        [-31, -44],
        [21, -57],
        [74, -9],
        [173, 32],
        [300, 31],
        [221, -2],
        [527, -26],
        [155, -68],
        [82, -105],
        [183, 24],
        [39, 18],
        [29, 31],
        [22, 33],
        [18, 36],
        [19, 24],
        [21, 13],
        [45, 11],
        [105, 13],
        [274, 13],
        [66, -4],
        [48, -49],
        [10, -28],
        [3, -38],
        [-1, -45],
        [-6, -55],
        [-24, -54],
        [-76, -94],
        [-61, -52],
        [-66, -37],
        [-126, -84],
        [-44, -23],
        [-138, -99],
        [-35, -45],
        [-2, -34],
        [24, -6],
        [40, 31],
        [15, 23],
        [26, 23],
        [205, 62],
        [44, 17],
        [133, 74],
        [86, 28],
        [70, 29],
        [37, 20],
        [206, 146],
        [107, 43],
        [112, 0],
        [20, -73],
        [135, -12],
        [59, 3],
        [94, -18],
        [41, -14],
        [70, -8],
        [73, -17],
        [63, 18],
        [19, 12],
        [62, 54],
        [83, 47],
        [77, 59],
        [26, 15],
        [41, 13],
        [42, 5],
        [115, 28],
        [29, 2],
        [62, -9],
        [273, -5],
        [150, -14],
        [208, -40],
        [145, -21],
        [67, -20],
        [97, -41],
        [79, -43],
        [37, -13],
        [-2, -13],
        [-27, -19],
        [-195, -66],
        [-64, -45],
        [-185, -83],
        [-90, -29],
        [-98, -11],
        [-109, -2],
        [-71, -12],
        [-10, -12],
        [50, -34],
        [22, -25],
        [-3, -22],
        [-53, -32],
        [-20, -8],
        [-192, -24],
        [-97, -41],
        [-126, -5],
        [-89, 5],
        [-123, -44],
        [48, -36],
        [44, -16],
        [137, -32],
        [1, -19],
        [-66, -38],
        [-89, -44],
        [-105, -31],
        [-40, -5],
        [-50, 8],
        [-46, -2],
        [-101, -14],
        [-97, -2],
        [-173, 20],
        [-93, 23],
        [-49, 8],
        [-62, -2],
        [-24, -9],
        [-98, -56],
        [-48, -38],
        [-31, -39],
        [-13, -42],
        [5, -46],
        [12, -32],
        [19, -17],
        [23, -10],
        [41, -6],
        [89, 6],
        [35, -3],
        [11, -10],
        [19, -29],
        [-3, -28],
        [-17, -39],
        [-12, -45],
        [-9, -52],
        [5, -29],
        [36, -6],
        [17, 2],
        [19, -9],
        [23, -21],
        [17, -22],
        [11, -23],
        [-6, -19],
        [-23, -15],
        [-52, -17],
        [-128, -33],
        [-13, -10],
        [-10, -21],
        [-7, -30],
        [-16, -28],
        [-23, -25],
        [-22, -15],
        [-42, -6],
        [-54, -1],
        [-61, -14],
        [-144, -84],
        [-2, -9],
        [54, -27],
        [-2, -24],
        [-67, -101],
        [-18, -50],
        [-14, -68],
        [-24, -58],
        [-33, -47],
        [-33, -55],
        [-31, -61],
        [5, -47],
        [41, -33],
        [56, 26],
        [69, 86],
        [74, 38],
        [81, -12],
        [70, -17],
        [90, -34],
        [76, -21],
        [65, -28],
        [26, -23],
        [27, -33],
        [1, -20],
        [-48, -12],
        [-16, 4],
        [-119, 54],
        [-58, 14],
        [-77, -16],
        [-67, -25],
        [60, -99],
        [65, -45],
        [117, -21],
        [62, -22],
        [44, -28],
        [35, -14],
        [47, 6],
        [64, 28],
        [88, 1],
        [40, -13],
        [28, -26],
        [13, -47],
        [-3, -70],
        [-9, -52],
        [-16, -35],
        [-32, -47],
        [-27, -9],
        [-37, 3],
        [-35, 9],
        [-32, 17],
        [-49, 13],
        [-98, 14],
        [-98, 31],
        [-56, 8],
        [-117, -4],
        [-127, -20],
        [-5, -25],
        [-182, -89],
        [-37, 1],
        [-50, 30],
        [-71, 30],
        [-41, 0],
        [-62, -38],
        [-15, -14],
        [1, -14],
        [45, -32],
        [18, -7],
        [24, -6],
        [80, -8],
        [36, -8],
        [33, -100],
        [52, -61],
        [23, -13],
        [19, -5],
        [65, 3],
        [83, 20],
        [28, -16],
        [61, -20],
        [36, -6],
        [43, 1],
        [49, -7],
        [67, -57],
        [-26, -72],
        [42, -57],
        [67, -59],
        [15, -21],
        [7, -39],
        [1, -26],
        [5, -24],
        [9, -21],
        [9, -42],
        [9, -62],
        [-2, -51],
        [-15, -39],
        [-27, -29],
        [-42, -18],
        [-34, 5],
        [-26, 27],
        [-37, 29],
        [-48, 31],
        [-80, 3],
        [-117, -90],
        [-52, -5],
        [-42, -9],
        [-46, -39],
        [-66, -24],
        [-59, 9],
        [-103, 46],
        [37, -30],
        [54, -36],
        [33, -19],
        [25, -4],
        [27, 4],
        [39, 15],
        [88, 41],
        [23, 6],
        [20, -3],
        [16, -12],
        [19, -35],
        [21, -58],
        [-3, -49],
        [-26, -41],
        [-23, -26],
        [-18, -12],
        [0, -9],
        [49, -10],
        [69, 53],
        [22, 66],
        [32, 76],
        [56, 25],
        [64, -25],
        [52, -71],
        [72, -131],
        [31, -14],
        [39, -31],
        [17, -39],
        [-4, -45],
        [-9, -33],
        [-12, -21],
        [-15, -13],
        [-27, -8],
        [-50, -7],
        [-111, 14],
        [-58, 0],
        [7, -45],
        [-117, -39],
        [-133, -15],
        [-125, 30],
        [-104, 47],
        [34, 65],
        [20, 72],
        [-51, 48],
        [-11, 1],
        [18, -74],
        [-15, -28],
        [-57, -35],
        [-40, -15],
        [-3, -10],
        [19, -8],
        [13, -15],
        [6, -23],
        [-7, -23],
        [-19, -24],
        [-11, -19],
        [-2, -16],
        [18, -20],
        [38, -22],
        [41, -11],
        [191, -5],
        [77, -13],
        [182, -53],
        [8, -17],
        [-31, -96],
        [-17, -92],
        [-35, -17],
        [-191, -4],
        [-62, -14],
        [-90, -42],
        [-87, -51],
        [-45, -1],
        [-178, 45],
        [-68, 29],
        [-147, 84],
        [-110, 128],
        [-51, -53],
        [-30, -26],
        [-32, -13],
        [-31, -2],
        [-30, 9],
        [-34, 20],
        [-56, 47],
        [-68, 46],
        [-47, 22],
        [-1, -6],
        [27, -27],
        [41, -32],
        [103, -92],
        [37, -23],
        [-4, -16],
        [-65, -15],
        [-79, -31],
        [-39, -24],
        [-60, -56],
        [-20, -9],
        [-90, -14],
        [-29, 3],
        [-67, 32],
        [-99, 20],
        [-59, 18],
        [-82, 33],
        [29, -36],
        [157, -55],
        [17, -17],
        [-32, -33],
        [-20, -12],
        [-37, -3],
        [-56, 7],
        [-56, -1],
        [-58, -10],
        [-24, -12],
        [8, -15],
        [13, -14],
        [20, -12],
        [18, 0],
        [44, 38],
        [33, 2],
        [88, -9],
        [89, 29],
        [64, 13],
        [48, 3],
        [175, 44],
        [37, 50],
        [58, 20],
        [131, 15],
        [126, -8],
        [65, -6],
        [53, -47],
        [70, -34],
        [59, -37],
        [69, -13],
        [40, -50],
        [111, -57],
        [71, -12],
        [44, -27],
        [4, -114],
        [5, -49],
        [-19, -136],
        [-57, -31],
        [11, -72],
        [-15, -58],
        [-57, 22],
        [-58, 38],
        [-139, 59],
        [-130, 38],
        [-50, 35],
        [-59, 26],
        [-81, 109],
        [-52, 135],
        [-23, 67],
        [-44, 4],
        [-57, -18],
        [-49, -19],
        [-24, -32],
        [-168, -45],
        [-61, -30],
        [-35, 0],
        [-125, -45],
        [50, -22],
        [23, -3],
        [51, 12],
        [31, 16],
        [114, 44],
        [92, 7],
        [33, 19],
        [70, 26],
        [48, 8],
        [7, -7],
        [5, -12],
        [33, -165],
        [-14, -44],
        [-38, -19],
        [-80, -31],
        [-21, -15],
        [23, -27],
        [76, 25],
        [50, 27],
        [26, -10],
        [43, -41],
        [49, -21],
        [123, -64],
        [59, -35],
        [82, -34],
        [93, -47],
        [24, -17],
        [85, -24],
        [18, -8],
        [36, -83],
        [29, -10],
        [85, -5],
        [-15, -32],
        [-83, -72],
        [-43, -22],
        [-10, -15],
        [4, -25],
        [2, -42],
        [17, -78],
        [20, 72],
        [13, 34],
        [19, 10],
        [17, 2],
        [58, 32],
        [58, -17],
        [15, -81],
        [8, -76],
        [-5, -66],
        [6, -101],
        [-2, -35],
        [14, -29],
        [14, -124],
        [14, -37],
        [-29, -33],
        [-88, -14],
        [-35, 16],
        [-87, -6],
        [0, 30],
        [-5, 33],
        [0, 24],
        [-6, 21],
        [-4, 122],
        [-25, -31],
        [-1, -24],
        [-7, -25],
        [-15, -132],
        [-22, -32],
        [-70, 8],
        [-69, -5],
        [-38, 5],
        [-129, 60],
        [-50, 54],
        [-44, 82],
        [-28, 76],
        [-10, 71],
        [-31, 57],
        [-51, 45],
        [-61, 36],
        [-70, 28],
        [-62, 35],
        [-53, 44],
        [-58, 33],
        [-64, 25],
        [-90, 10],
        [-133, -4],
        [-89, 27],
        [-22, -2],
        [-21, -14],
        [16, -40],
        [103, -18],
        [79, -5],
        [105, 3],
        [64, -10],
        [24, -23],
        [16, -42],
        [9, -59],
        [-21, -47],
        [-76, -52],
        [-39, -25],
        [-117, -47],
        [-39, -10],
        [-97, -4],
        [-75, 5],
        [-98, 23],
        [-55, 5],
        [-115, 3],
        [-27, -8],
        [30, -25],
        [49, -14],
        [34, -17],
        [4, -32],
        [-13, -48],
        [-13, -33],
        [-21, -25],
        [-75, -47],
        [-31, -15],
        [-141, -50],
        [-10, -10],
        [33, 2],
        [89, 17],
        [26, 0],
        [146, -43],
        [116, 2],
        [236, 40],
        [19, -1],
        [16, -6],
        [15, -15],
        [16, -23],
        [-19, -23],
        [-54, -22],
        [-84, -22],
        [-36, -15],
        [-35, -22],
        [-64, -53],
        [-19, -56],
        [68, -21],
        [30, 27],
        [36, 59],
        [33, 35],
        [76, 24],
        [94, -12],
        [72, 14],
        [148, 58],
        [26, 4],
        [217, -34],
        [197, -65],
        [103, -25],
        [138, -14],
        [246, 7],
        [22, -11],
        [-8, -23],
        [-14, -20],
        [-41, -27],
        [-50, -18],
        [-31, -5],
        [-27, -13],
        [-59, -13],
        [-15, -10],
        [21, -45],
        [-10, -7],
        [-52, -1],
        [-88, -29],
        [-72, 2],
        [-15, -5],
        [14, -10],
        [14, -21],
        [16, -32],
        [-9, -22],
        [-32, -13],
        [-24, -4],
        [-85, 18],
        [-14, -3],
        [13, -13],
        [7, -20],
        [2, -28],
        [-21, -23],
        [-43, -18],
        [-81, -51],
        [-35, -15],
        [-74, -12],
        [-15, -7],
        [30, -39],
        [-4, -16],
        [-49, -42],
        [-76, -27],
        [-10, -14],
        [-7, -38],
        [-6, -15],
        [-20, -22],
        [-71, -42],
        [-51, -21],
        [-25, -17],
        [-31, -28],
        [-40, -16],
        [-47, -1],
        [-45, -11],
        [-74, -30],
        [-51, -10],
        [-163, -53],
        [-76, -8],
        [-66, -19],
        [-137, -50],
        [-64, -16],
        [-45, -19],
        [-49, -5],
        [-80, 12],
        [-43, 1],
        [-27, -8],
        [-24, -15],
        [-40, -42],
        [-34, -4],
        [-112, 34],
        [3, -15],
        [29, -36],
        [-1, -26],
        [-67, -26],
        [-36, -7],
        [-52, 11],
        [-70, 28],
        [-90, 59],
        [-109, 89],
        [-53, 30],
        [4, -29],
        [12, -28],
        [20, -27],
        [5, -20],
        [-13, -12],
        [-16, -6],
        [-20, 0],
        [-3, -7],
        [33, -43],
        [25, -44],
        [-2, -41],
        [-31, -39],
        [-26, -23],
        [-22, -7],
        [-130, -98],
        [-37, -13],
        [-16, -12],
        [-14, -18],
        [-38, -81],
        [-15, -25],
        [-30, -32],
        [-13, -6],
        [-4, -13],
        [6, -21],
        [-9, -34],
        [-22, -49],
        [-76, -133],
        [-61, -125],
        [-27, -41],
        [-20, -17],
        [-12, 6],
        [-30, -3],
        [-15, -22],
        [-13, -40],
        [-15, -30],
        [-16, -21],
        [-122, -89],
        [-32, -17],
        [-28, 11],
        [-34, -4],
        [-70, 47],
        [-12, 17],
        [-45, 39],
        [2, -20],
        [8, -11],
        [6, -17],
        [16, -20],
        [34, -105],
        [-27, -22],
        [-25, -25],
        [-63, -42],
        [-68, -70],
        [-25, -19],
        [-5, 57],
        [3, 17],
        [-39, 27],
        [1, -19],
        [-4, -19],
        [-27, -74],
        [-8, -13],
        [-14, 3],
        [-30, -14],
        [-30, 7],
        [-26, 33],
        [-11, 18],
        [-47, -48],
        [-23, 1],
        [-4, -40],
        [-22, -37],
        [-30, -16],
        [-41, 1],
        [-25, -21],
        [-55, 23],
        [-13, 44],
        [44, 60],
        [12, 25],
        [-8, 30],
        [11, 39],
        [84, 125],
        [57, 63],
        [-3, 12],
        [-77, 13],
        [-68, 22],
        [-66, 8],
        [-29, -8],
        [46, -36],
        [66, -35],
        [-33, -34],
        [-27, -36],
        [-29, -97],
        [-19, -40],
        [-72, 46],
        [-33, 16],
        [21, -51],
        [63, -48],
        [4, -16],
        [0, -57],
        [-121, -50],
        [-125, -8],
        [-91, -14],
        [-152, -18],
        [-59, 0],
        [-5, -19],
        [149, -89],
        [22, -16],
        [-21, -30],
        [-31, -19],
        [-48, -65],
        [-25, -23],
        [-63, -31],
        [-115, 35],
        [-59, -16],
        [-57, 11],
        [-1, -38],
        [17, -27],
        [17, -69],
        [38, 6],
        [48, 21],
        [38, -52],
        [24, -87],
        [43, -45],
        [19, -34],
        [8, -33],
        [-27, -32],
        [-55, -46],
        [-62, -8],
        [4, -36],
        [-27, -26],
        [-56, 7],
        [-26, 18],
        [-28, 9],
        [-112, 10],
        [113, -70],
        [40, -32],
        [18, 19],
        [39, 5],
        [58, -18],
        [-10, -118],
        [26, -95],
        [3, -21],
        [-62, -57],
        [-1, -54],
        [-36, -15],
        [-40, 4],
        [-2, -59],
        [-27, -36],
        [5, -23],
        [8, -20],
        [-25, -38],
        [-22, -45],
        [-31, -39],
        [-17, 4],
        [-50, -3],
        [-60, 3],
        [-49, 52],
        [-20, 17],
        [-23, 11],
        [9, -33],
        [14, -20],
        [45, -37],
        [82, -46],
        [-2, -33],
        [-22, -14],
        [-52, -89],
        [-18, -1],
        [-23, -23],
        [-72, 7],
        [-30, 9],
        [-88, -5],
        [-30, 7],
        [-26, -3],
        [24, -25],
        [51, -23],
        [57, -23],
        [87, -17],
        [-3, -32],
        [-22, -27],
        [13, -39],
        [-10, -29],
        [-4, -34],
        [-20, -78],
        [23, -54],
        [26, -26],
        [-3, -35],
        [12, -55],
        [-38, -50],
        [-33, 2],
        [-45, -11],
        [-16, -22],
        [73, -20],
        [-7, -38],
        [-19, -46],
        [-21, -100],
        [-42, -172],
        [-20, -171],
        [-91, -141],
        [-32, -3],
        [-8, -6],
        [-45, 7],
        [-69, 33],
        [-54, 10],
        [-36, 1],
        [-5, -16],
        [30, -27],
        [46, -10],
        [37, -18],
        [66, -16],
        [23, -31],
        [16, -33],
        [-3, -19],
        [0, -21],
        [12, -117],
        [-31, -40],
        [-24, -37],
        [-82, 5],
        [-15, 13],
        [-79, 38],
        [5, -18],
        [57, -52],
        [20, -26],
        [-13, -5],
        [-23, -2],
        [-33, -18],
        [-58, 10],
        [3, 26],
        [11, 27],
        [-27, -4],
        [-29, -14],
        [-18, 5],
        [-14, -1],
        [-8, 13],
        [-12, 53],
        [14, 27],
        [48, 69],
        [15, 37],
        [-13, 16],
        [-34, -39],
        [-36, -63],
        [-16, -38],
        [-22, -8],
        [-55, 20],
        [-158, 88],
        [5, 53],
        [-2, 45],
        [46, 5],
        [34, 20],
        [30, 23],
        [33, 43],
        [31, 71],
        [-4, 6],
        [-90, -91],
        [-56, -30],
        [-27, -6],
        [-14, 14],
        [-44, 29],
        [-29, 13],
        [-67, 22],
        [-12, 11],
        [-19, 9],
        [-27, 93],
        [35, 113],
        [23, 32],
        [15, 38],
        [9, 50],
        [-6, 22],
        [-20, -8],
        [-9, -17],
        [1, -27],
        [-11, -18],
        [-80, -43],
        [-79, -35],
        [-38, -33],
        [-24, -26],
        [-19, -25],
        [-29, 2],
        [-41, -5],
        [-27, -16],
        [-41, 10],
        [-26, 24],
        [-32, 3],
        [-35, -12],
        [-25, 1],
        [2, -16],
        [16, -41],
        [-27, -3],
        [-52, -1],
        [-26, 11],
        [-20, 16],
        [-17, 22],
        [10, 28],
        [83, 52],
        [38, 30],
        [-24, 10],
        [-80, -9],
        [-14, 7],
        [-53, -4],
        [2, 77],
        [-13, 19],
        [-1, 9],
        [-18, 21],
        [-18, 6],
        [-11, 7],
        [-90, 18],
        [-12, 43],
        [-6, 44],
        [-17, 55],
        [-43, 14],
        [-24, 24],
        [19, 24],
        [8, 31],
        [-20, 14],
        [-13, 22],
        [2, 14],
        [-16, 38],
        [-5, 30],
        [18, 27],
        [51, 32],
        [17, 13],
        [9, 14],
        [58, 23],
        [-50, 16],
        [-31, 3],
        [-23, -8],
        [-21, -31],
        [-19, -20],
        [-77, -7],
        [-11, 11],
        [-5, 43],
        [4, 34],
        [32, 47],
        [-37, 23],
        [-33, 8],
        [-39, 22],
        [-35, 23],
        [-30, 27],
        [-29, 31],
        [-10, 4],
        [11, 34],
        [6, 27],
        [1, 58],
        [-12, 25],
        [26, 50],
        [36, 54],
        [78, 82],
        [-83, -39],
        [-68, -86],
        [-15, -4],
        [-5, 16],
        [-26, 55],
        [-19, 15],
        [-9, 17],
        [-39, 29],
        [-17, 23],
        [-24, 44],
        [-34, 52],
        [-48, 103],
        [-78, 119],
        [-20, 67],
        [25, 84],
        [-27, 58],
        [74, 27],
        [106, 31],
        [55, 25],
        [32, 7],
        [68, 5],
        [22, 25],
        [-37, -6],
        [-25, 3],
        [-2, 12],
        [9, 21],
        [6, 27],
        [-13, -5],
        [-66, -46],
        [-97, -37],
        [-76, -21],
        [-13, 1],
        [-26, -13],
        [-16, -3],
        [-11, 3],
        [-27, 36],
        [-8, 23],
        [49, 63],
        [36, 86],
        [48, 56],
        [33, 7],
        [57, -3],
        [20, -5],
        [-7, 33],
        [3, 16],
        [46, 20],
        [53, 8],
        [38, -6],
        [24, -39],
        [31, -72],
        [41, -24],
        [-2, 34],
        [-21, 45],
        [-8, 68],
        [-27, 29],
        [-22, 14],
        [-61, -7],
        [-36, 50],
        [-9, 19],
        [0, 24],
        [-37, 72],
        [-12, 36],
        [-19, 47],
        [-10, 3],
        [13, -60],
        [17, -42],
        [24, -93],
        [12, -38],
        [-16, -29],
        [-31, -30],
        [-27, -17],
        [-64, -21],
        [14, 46],
        [8, 45],
        [-32, -16],
        [-30, -33],
        [-10, -45],
        [-20, -41],
        [-57, -101],
        [-23, -56],
        [-21, -27],
        [-24, -8],
        [-21, 22],
        [-18, 51],
        [-9, 41],
        [-1, 104],
        [3, 49],
        [-10, 65],
        [-31, 153],
        [-7, 54],
        [-52, 29],
        [-1, 9],
        [-14, 31],
        [-10, 31],
        [9, 12],
        [12, 8],
        [78, 46],
        [58, 51],
        [70, 80],
        [28, 25],
        [102, 19],
        [45, 3],
        [-1, 14],
        [-15, 6],
        [-68, -4],
        [-92, -32],
        [-16, -12],
        [-40, -50],
        [-31, -27],
        [-87, -62],
        [-56, 0],
        [-58, 74],
        [-65, -14],
        [-44, 5],
        [-13, 11],
        [-10, 102],
        [36, 119],
        [-38, 1],
        [-8, 5],
        [-20, 27],
        [-13, 8],
        [9, 14],
        [94, 56],
        [141, 111],
        [61, 43],
        [37, 19],
        [29, 21],
        [34, 47],
        [11, 20],
        [20, 16],
        [40, 19],
        [44, 28],
        [72, 63],
        [9, 23],
        [-15, 5],
        [-34, -21],
        [-68, -58],
        [-49, -32],
        [-166, -145],
        [-71, -52],
        [-38, -34],
        [-30, -32],
        [-32, -23],
        [-33, -14],
        [-71, -10],
        [-35, -12],
        [-21, 9],
        [-10, 69],
        [7, 40],
        [-3, 40],
        [18, 58],
        [26, 40],
        [11, 22],
        [5, 16],
        [55, 42],
        [30, 18],
        [21, 42],
        [121, 15],
        [31, -1],
        [16, 5],
        [13, 13],
        [-12, 9],
        [-36, 7],
        [-97, -2],
        [-89, 8],
        [-40, 7],
        [-20, -4],
        [-32, 12],
        [-35, 23],
        [-55, 86],
        [22, 110],
        [2, 53],
        [69, 46],
        [37, 15],
        [53, 35],
        [71, 59],
        [84, 36],
        [43, 7],
        [36, -6],
        [131, -51],
        [68, -10],
        [60, 13],
        [75, -18],
        [132, -74],
        [25, 11],
        [-7, 18],
        [-148, 78],
        [1, 23],
        [38, 6],
        [40, 22],
        [-22, 14],
        [-98, -12],
        [-29, -18],
        [-95, -10],
        [-49, 16],
        [-45, 8],
        [-67, 33],
        [-56, -11],
        [-35, -13],
        [-62, -13],
        [-23, -8],
        [-122, -106],
        [-51, -22],
        [-37, 7],
        [26, 69],
        [7, 27],
        [0, 30],
        [11, 40],
        [62, 77],
        [40, 84],
        [16, 53],
        [31, 6],
        [42, -8],
        [126, -34],
        [105, -40],
        [77, -11],
        [51, -1],
        [22, 13],
        [17, 22],
        [7, 15],
        [5, 32],
        [6, 12],
        [17, 13],
        [34, 49],
        [11, 34],
        [-11, 20],
        [-24, -2],
        [-45, -13],
        [-6, -6],
        [1, -10],
        [-44, -54],
        [-47, -12],
        [-110, -18],
        [-50, -1],
        [-89, 25],
        [-13, 9],
        [-9, 19],
        [-109, -5],
        [-33, -5],
        [-26, 2],
        [13, 32],
        [34, 35],
        [48, 114],
        [41, 29],
        [80, 27],
        [84, -4],
        [145, -88],
        [44, -8],
        [40, 7],
        [96, 30],
        [18, 12],
        [34, 36],
        [40, 63],
        [-2, 15],
        [-61, -35],
        [-33, -10],
        [-28, 0],
        [26, 115],
        [10, 87],
        [9, 22],
        [82, -7],
        [111, 12],
        [26, 19],
        [0, 9],
        [-45, 11],
        [-21, 25],
        [-38, -8],
        [-50, -16],
        [-62, 2],
        [5, 37],
        [46, 79],
        [5, 36],
        [18, 72],
        [1, 37],
        [24, 36],
        [72, 25],
        [31, 16],
        [1, 16],
        [-43, 64],
        [11, 18],
        [34, 16],
        [13, 11],
        [-9, 8],
        [-31, 7],
        [-49, -14],
        [-53, -7],
        [-47, 21],
        [-37, 10],
        [-24, -3],
        [-63, -36],
        [-23, -1],
        [-27, 10],
        [-183, 31],
        [-22, 11],
        [-65, 54],
        [-54, 36],
        [-72, 39],
        [-93, 30],
        [-114, 20],
        [-68, 19],
        [-34, 27],
        [-58, 59],
        [-44, 50],
        [-8, 24],
        [26, 30],
        [26, 22],
        [50, 18],
        [85, -6],
        [46, -8],
        [50, -17],
        [38, -3],
        [79, 4],
        [80, -10],
        [48, -12],
        [63, -25],
        [172, -106],
        [72, -37],
        [32, -4],
        [129, -39],
        [20, 0],
        [53, 19],
        [6, 12],
        [-18, 11],
        [-57, 10],
        [-67, 43],
        [-42, 34],
        [-4, 57],
        [5, 32],
        [10, 15],
        [8, 48],
        [-39, 29],
        [-27, 9],
        [-72, 43],
        [-6, 10],
        [34, 5],
        [34, -3],
        [73, -21],
        [38, -3],
        [28, 8],
        [3, 9],
        [-44, 24],
        [-56, 44],
        [-115, 6],
        [-75, -5],
        [-48, 15],
        [-51, 24],
        [-32, 7],
        [-67, -16],
        [-34, -1],
        [-31, 5],
        [-30, 76],
        [8, 23],
        [24, 10],
        [18, 25],
        [12, 26],
        [40, 25],
        [217, 56],
        [55, 42],
        [-2, 7],
        [-37, -8],
        [-48, -19],
        [-31, -4],
        [-128, 24],
        [-20, -5],
        [-52, -35],
        [-70, -38],
        [-32, 2],
        [-44, 23],
        [-7, 16],
        [-3, 19],
        [48, 26],
        [15, 14],
        [31, 38],
        [-2, 18],
        [-52, -10],
        [-7, 17],
        [1, 30],
        [-5, 37],
        [-13, 37],
        [-43, 57],
        [-18, 14],
        [-15, 21],
        [-33, 77],
        [10, 19],
        [29, 14],
        [5, 7],
        [-69, -12],
        [-7, -13],
        [13, -20],
        [9, -26],
        [6, -32],
        [9, -29],
        [25, -31],
        [20, -16],
        [33, -43],
        [14, -48],
        [-4, -25],
        [-26, -26],
        [-40, -27],
        [-13, -23],
        [-5, -23],
        [-32, -21],
        [-19, 8],
        [-16, 1],
        [18, -37],
        [12, -39],
        [-14, -38],
        [-38, -24],
        [-20, 0],
        [-41, -19],
        [-103, -10],
        [-36, 4],
        [-66, 20],
        [-78, 10],
        [-31, 26],
        [-40, 47],
        [-21, 42],
        [0, 38],
        [11, 27],
        [22, 17],
        [27, 109],
        [38, 88],
        [96, 91],
        [27, 33],
        [9, 17],
        [0, 14],
        [-15, 8],
        [-118, -112],
        [-73, -12],
        [-21, 25],
        [6, 45],
        [13, 11],
        [59, -5],
        [23, 25],
        [-37, 38],
        [-39, 10],
        [-9, 9],
        [40, 29],
        [93, -2],
        [21, 20],
        [34, 22],
        [37, 40],
        [14, 34],
        [3, 29],
        [-8, 23],
        [-1, 23],
        [7, 24],
        [-10, 26],
        [-26, 28],
        [-57, 28],
        [-17, -31],
        [-18, -13],
        [-25, -3],
        [-24, 13],
        [-24, 5],
        [-24, 12],
        [-24, 3],
        [-10, 10],
        [-6, 26],
        [-1, 34],
        [28, 16],
        [39, 13],
        [26, 23],
        [17, 34],
        [3, 38],
        [-12, 40],
        [-32, 37],
        [-58, -36],
        [-23, -9],
        [-6, 26],
        [-8, 19],
        [-24, 25],
        [-33, 18],
        [-31, 13],
        [-1, 19],
        [7, 21],
        [14, 24],
        [18, 52],
        [20, -5],
        [16, 5],
        [-9, 42],
        [-16, 36],
        [-19, 20],
        [-1, 9],
        [-4, 10],
        [-14, 25],
        [-16, 19],
        [-29, 52],
        [-20, 19],
        [-26, 11],
        [-28, 0],
        [-45, -13],
        [-83, -16],
        [-66, -8],
        [-11, 4],
        [33, 20],
        [49, 21],
        [64, 14],
        [19, 34],
        [-7, 29],
        [2, 28],
        [-17, 30],
        [17, 16],
        [58, 16],
        [27, 3],
        [26, 21],
        [-74, 50],
        [-77, 33],
        [-20, 14],
        [-17, 22],
        [-15, 30],
        [-24, 31],
        [-33, 31],
        [-48, 30],
        [-125, 54],
        [-42, 38],
        [-40, 57],
        [-19, 25],
        [-21, 18],
        [-87, 43],
        [-10, 18],
        [89, 50],
        [9, 20],
        [-36, 61],
        [-38, 44],
        [-41, 16],
        [-60, 8],
        [-56, 20],
        [-50, 31],
        [-51, 23],
        [-76, 22],
        [-126, 56],
        [-195, 59],
        [-87, 35],
        [-52, 13],
        [-68, 3],
        [-132, 33],
        [-111, 10],
        [-69, -4],
        [-22, 5],
        [-51, 33],
        [-79, 19],
        [-41, -7],
        [-51, -36],
        [-62, -35],
        [-32, -4],
        [-49, 32],
        [-24, 22],
        [-23, 8],
        [-23, -8],
        [-43, -29],
        [-41, -21],
        [-61, -25],
        [-49, -12],
        [-63, -3],
        [-16, -9],
        [-24, -1],
        [-32, 8],
        [-31, 17],
        [-28, 24],
        [-25, 14],
        [-22, 2],
        [-50, -13],
        [-63, -37],
        [-29, -9],
        [-24, 3],
        [-30, 12],
        [-59, 30],
        [-33, -3],
        [-24, -10],
        [6, -25],
        [56, -59],
        [50, -42],
        [-42, -5],
        [-368, 57],
        [-46, 14],
        [-68, 34],
        [-56, 21],
        [-96, 54],
        [-74, 30],
        [-24, 22],
        [-6, 16],
        [23, 22],
        [149, 72],
        [56, 14],
        [116, 18],
        [27, 11],
        [9, 8],
        [-30, 16],
        [-151, -6],
        [-135, 11],
        [-117, 28],
        [-21, 10],
        [-19, 17],
        [-20, 26],
        [5, 28],
        [28, 32],
        [16, 22],
        [5, 11],
        [-147, -77],
        [-60, -28],
        [-48, 9],
        [-34, 13],
        [-16, 14],
        [1, 16],
        [5, 11],
        [10, 7],
        [-78, 33],
        [-35, 25],
        [-4, 26],
        [28, 27],
        [27, 19],
        [27, 12],
        [72, 11],
        [263, 20],
        [188, -19],
        [64, 64],
        [43, 21],
        [127, 21],
        [195, 3],
        [138, -12],
        [64, -15],
        [88, -33],
        [5, 10],
        [-21, 30],
        [-2, 25],
        [36, 43],
        [16, 27],
        [-11, 29],
        [-38, 29],
        [-69, 38],
        [-36, 3],
        [-41, -9],
        [-49, -21],
        [-102, -53],
        [-49, -11],
        [-80, -3],
        [-44, 7],
        [-43, 8],
        [-67, 28],
        [-25, 5],
        [-29, -12],
        [-35, -30],
        [-34, -21],
        [-32, -12],
        [-30, -5],
        [-43, 2],
        [-173, 47],
        [-40, 21],
        [-2, 31],
        [-53, 30],
        [-60, 5],
        [-8, 11],
        [77, 46],
        [57, 19],
        [-10, 6],
        [-82, 1],
        [-56, -25],
        [-32, -4],
        [-74, -1],
        [-77, 13],
        [-33, 11],
        [-34, 25],
        [-38, 14],
        [-115, 21],
        [-26, 12],
        [-25, 19],
        [-94, 55],
        [-57, 40],
        [-8, 22],
        [66, 49],
        [3, 12],
        [-28, 21],
        [-12, 15],
        [10, 22],
        [57, 47],
        [22, 13],
        [103, 28],
        [104, 40],
        [37, 9],
        [34, 3],
        [136, -3],
        [42, 10],
        [36, 20],
        [58, 20],
        [123, 30],
        [272, 46],
        [18, 6],
        [1, 8],
        [-24, 24],
        [-5, 12],
        [55, 21],
        [126, 33],
        [86, 16],
        [55, 2],
        [45, 7],
        [63, 21],
        [35, 4],
        [214, 8],
        [95, -12],
        [46, 1],
        [30, 8],
        [40, 24],
        [74, 59],
        [38, 36],
        [37, 56],
        [48, 88],
        [35, 85],
        [24, 83],
        [18, 51],
        [13, 18],
        [44, 23],
        [47, 18],
        [79, 16],
        [-7, 7],
        [-34, 12],
        [-31, 5],
        [-29, -3],
        [-53, -18],
        [-69, -13],
        [-67, 2],
        [-48, -5],
        [-44, -17],
        [-71, -14],
        [-48, 3],
        [-86, 25],
        [-43, 5],
        [-110, -3],
        [-32, 10],
        [-28, 17],
        [-23, 25],
        [-16, 32],
        [2, 34],
        [40, 60],
        [15, 16],
        [107, 68],
        [66, 31],
        [66, 24],
        [48, 13],
        [45, 7],
        [43, 14],
        [80, 47],
        [82, 40],
        [102, 75],
        [50, 20],
        [173, 32],
        [47, 1],
        [40, -9],
        [38, -16],
        [106, -74],
        [9, 2],
        [-19, 28],
        [-39, 84],
        [7, 34],
        [61, 37],
        [25, 6],
        [64, -2],
        [103, -11],
        [70, -13],
        [52, -20],
        [63, -13],
        [32, 1],
        [23, 9],
        [33, 32],
        [43, 54],
        [17, 66],
        [-8, 78],
        [-13, 58],
        [-16, 36],
        [9, 30],
        [52, 36],
        [48, 26],
        [114, 38],
        [92, 9],
        [55, -2],
        [72, -18],
        [100, -10],
        [91, -34],
        [146, -78],
        [97, -39],
        [81, -18],
        [79, -29],
        [117, -64],
        [62, -27],
        [36, -10],
        [32, -1],
        [-12, 18],
        [-56, 37],
        [-85, 42],
        [-191, 75],
        [-104, 53],
        [-94, 60],
        [-69, 36],
        [-126, 35],
        [2, 14],
        [151, 50],
        [278, 44],
        [313, 33],
        [105, -3],
        [184, 14],
        [18, 22],
        [39, 9],
        [172, 30],
        [49, 0],
        [76, -15],
        [80, -27],
        [37, -24],
        [51, -42],
        [25, -59],
        [-4, -186],
        [1, -36],
        [10, -13],
        [35, 20],
        [42, 38],
        [37, 26],
        [29, 41],
        [20, 56],
        [12, 41],
        [-49, 46],
        [-2, 76],
        [24, 41],
        [69, 0],
        [284, -140],
        [111, -31],
        [127, -75],
        [149, 8],
        [137, -9],
        [60, 3],
        [30, 11],
        [-42, 32],
        [-195, 85],
        [-88, 67],
        [-62, 84],
        [-15, 45],
        [47, 8],
        [217, -1],
        [327, -39],
        [418, -133],
        [205, -45],
        [369, -154],
        [111, -23],
        [45, -5],
        [34, 19],
        [21, 21],
        [1, 27],
        [-18, 35],
        [-10, 39],
        [-3, 46],
        [25, 89],
        [60, 30],
        [26, 33],
        [-24, 59],
        [-70, 41],
        [-271, 105],
        [-1, 13],
        [55, 14],
        [81, 8],
        [671, -25],
        [116, -10],
        [50, -9],
        [21, -11],
        [28, -6],
        [144, 15],
        [-3, 26],
        [-18, 16],
        [-779, 45],
        [-145, 16],
        [-74, 1],
        [-77, -12],
        [-158, -6],
        [-73, 2],
        [-95, 54],
        [86, 64],
        [72, -1],
        [137, -25],
        [82, 35],
        [129, 29],
        [128, 10],
        [278, 63],
        [52, 5],
        [65, -3],
        [143, -16],
        [57, -15],
        [65, -33],
        [38, -10],
        [45, -2],
        [61, -15],
        [89, 48],
        [81, 52],
        [92, 33],
        [131, -19],
        [83, -25],
        [75, -30],
        [107, -16],
        [182, -102],
        [34, 0],
        [16, 8],
        [17, 19],
        [6, 28],
        [22, 38],
        [-16, 14],
        [-152, 42],
        [-29, 17],
        [-29, 26],
        [0, 22],
        [28, 18],
        [30, 8],
        [98, -8],
        [32, 6],
        [31, 15],
        [37, 27],
        [29, 7],
        [66, 1],
        [101, -18],
        [88, -1],
        [32, 8],
        [5, 17],
        [8, 11],
        [10, 5],
        [321, 1],
        [79, 4],
        [63, 13],
        [76, 2],
        [66, -8],
        [83, -17],
        [81, 1],
        [122, 27],
        [115, 13],
        [634, -4],
        [208, -16],
      ],
      [
        [35352, 92133],
        [93, -47],
        [98, -32],
        [9, -15],
        [8, -22],
        [2, -12],
        [-3, -11],
        [-6, -10],
        [7, -12],
        [21, -16],
        [2, -19],
        [-25, -31],
        [-34, -35],
        [-183, -72],
        [-64, -13],
        [-160, -49],
        [-49, 2],
        [-11, 2],
        [-30, 22],
        [-42, 20],
        [-19, 16],
        [-17, 23],
        [7, 14],
        [30, 5],
        [44, 0],
        [65, 16],
        [-18, 15],
        [-17, 9],
        [-12, 19],
        [-27, -3],
        [-20, 11],
        [-38, 7],
        [-101, 7],
        [-66, 19],
        [-20, 11],
        [-17, 20],
        [-15, 28],
        [22, 109],
        [15, 27],
        [34, 9],
        [84, -24],
        [11, 11],
        [-92, 40],
        [-33, 23],
        [-10, 19],
        [-6, 28],
        [0, 16],
        [4, 16],
        [8, 17],
        [23, 22],
        [92, 35],
        [102, -12],
        [175, -43],
        [22, -10],
        [54, -37],
        [103, -113],
      ],
      [
        [35829, 91907],
        [-43, -20],
        [-9, 5],
        [-9, 15],
        [-22, 70],
        [-7, 34],
        [5, 41],
        [-8, 29],
        [39, 35],
        [32, 5],
        [43, -8],
        [72, -35],
        [-5, -11],
        [-16, -18],
        [-44, -24],
        [-15, -53],
        [-3, -27],
        [2, -20],
        [-12, -18],
      ],
      [
        [35129, 92765],
        [-26, -4],
        [-75, 29],
        [-12, 11],
        [-5, 14],
        [3, 16],
        [24, 30],
        [45, 43],
        [32, 8],
        [20, -27],
        [20, -37],
        [2, -18],
        [-1, -22],
        [-5, -19],
        [-9, -15],
        [-13, -9],
      ],
      [
        [34717, 93773],
        [-39, -39],
        [-32, -23],
        [-70, -66],
        [-12, -3],
        [-18, 9],
        [-15, 18],
        [-26, 4],
        [-9, 11],
        [-4, 9],
        [-12, 6],
        [-18, 3],
        [-30, -7],
        [-27, 7],
        [-21, 29],
        [38, 20],
        [24, 17],
        [91, 6],
        [25, -7],
        [16, 0],
        [25, 4],
        [54, 21],
        [8, 10],
        [48, -12],
        [4, -17],
      ],
      [
        [30092, 96385],
        [-99, -5],
        [-97, 22],
        [-34, 17],
        [2, 27],
        [14, 9],
        [53, 9],
        [44, 2],
        [29, -4],
        [70, -16],
        [50, -16],
        [33, -5],
        [-9, -24],
        [-56, -16],
      ],
      [
        [37537, 99126],
        [-56, -10],
        [-118, 61],
        [-186, 61],
        [-164, 40],
        [-154, 107],
        [-12, 38],
        [21, 33],
        [135, 5],
        [108, 15],
        [274, -66],
        [138, -56],
        [46, -45],
        [-7, -92],
        [-25, -91],
      ],
      [
        [42935, 92696],
        [10, -34],
        [1, -16],
        [-2, -14],
        [-6, -9],
        [-13, -9],
        [25, -22],
        [7, -15],
        [2, -12],
        [-16, -24],
        [-110, -31],
        [-31, -15],
        [-38, -37],
        [-47, -31],
        [-16, 0],
        [-18, 33],
        [-74, 24],
        [-139, -13],
        [-162, -30],
        [-58, -14],
        [-29, 7],
        [-9, 12],
        [0, 15],
        [19, 47],
        [8, 12],
        [37, 15],
        [25, 41],
        [-8, 44],
        [10, 62],
        [25, 10],
        [64, -22],
        [41, -5],
        [73, -3],
        [99, 8],
        [79, 25],
        [144, 71],
        [25, -1],
        [19, -25],
        [13, -12],
        [43, -20],
        [7, -12],
      ],
      [
        [44815, 98989],
        [-29, -18],
        [-73, 7],
        [-94, 52],
        [-62, 43],
        [-5, 50],
        [32, 22],
        [50, 4],
        [69, -43],
        [71, -58],
        [41, -59],
      ],
      [
        [45107, 97825],
        [-117, -66],
        [-174, 5],
        [-103, 31],
        [-30, 45],
        [40, 51],
        [125, 41],
        [156, 25],
        [142, -15],
        [20, -51],
        [-59, -66],
      ],
      [
        [44723, 96758],
        [-37, -20],
        [-25, 61],
        [-22, 81],
        [-5, 92],
        [57, 45],
        [29, 11],
        [20, -11],
        [-5, -40],
        [0, -82],
        [19, -55],
        [-31, -82],
      ],
      [
        [44838, 95646],
        [-32, -16],
        [-108, 239],
        [0, 87],
        [7, 66],
        [49, 5],
        [42, -36],
        [20, -137],
        [22, -208],
      ],
      [
        [44999, 95280],
        [22, -61],
        [10, -56],
        [35, -35],
        [73, 5],
        [29, -66],
        [-53, -26],
        [-214, 10],
        [-88, -5],
        [-61, 41],
        [2, 71],
        [8, 71],
        [61, 41],
        [51, -36],
        [62, 26],
        [63, 20],
      ],
      [
        [45012, 96567],
        [-54, 0],
        [-20, 15],
        [13, 27],
        [75, 85],
        [25, 7],
        [37, -9],
        [11, -44],
        [-24, -44],
        [-63, -37],
      ],
      [
        [39713, 89591],
        [-43, 0],
        [-14, 45],
        [4, 50],
        [49, 15],
        [26, -34],
        [-10, -50],
        [-12, -26],
      ],
      [
        [37148, 86855],
        [-32, -70],
        [-32, 15],
        [-16, 31],
        [-31, 15],
        [-34, -4],
        [-1, 12],
        [110, 74],
        [53, 20],
        [-4, -31],
        [-10, -27],
        [-3, -35],
      ],
      [
        [35645, 92658],
        [-37, -2],
        [-86, 11],
        [-7, 6],
        [-1, 13],
        [12, 37],
        [38, 5],
        [45, -20],
        [49, -29],
        [6, -13],
        [-19, -8],
      ],
      [
        [48160, 87445],
        [-6, -22],
        [-7, 1],
        [-26, 26],
        [-21, 15],
        [-7, 13],
        [-6, 21],
        [12, 2],
        [14, -4],
        [36, -20],
        [10, -20],
        [1, -12],
      ],
      [
        [48139, 87237],
        [5, -18],
        [-6, 2],
        [-19, 20],
        [-33, 48],
        [-11, 39],
        [-2, 18],
        [8, -2],
        [7, -16],
        [31, -11],
        [8, -8],
        [0, -19],
        [10, -23],
        [2, -30],
      ],
      [
        [48003, 87637],
        [25, -22],
        [9, -16],
        [-14, -15],
        [-18, -4],
        [-21, 3],
        [-34, 17],
        [-12, 38],
        [24, -1],
        [28, 7],
        [13, -7],
      ],
      [
        [48158, 87688],
        [-7, -77],
        [-12, 0],
        [-20, 22],
        [-15, 4],
        [-5, -11],
        [1, -14],
        [8, -9],
        [24, -52],
        [2, -15],
        [-3, -7],
        [-23, 15],
        [-57, 67],
        [-44, 110],
        [60, 18],
        [43, -29],
        [48, -22],
      ],
      [
        [48220, 87706],
        [-13, -42],
        [-20, 7],
        [-5, 4],
        [-5, 11],
        [3, 31],
        [-1, 45],
        [22, -37],
        [19, -19],
      ],
      [
        [53491, 83977],
        [0, -58],
        [-7, -17],
        [-10, -11],
        [-28, -11],
        [-24, -17],
        [-22, -29],
        [-7, -41],
        [16, -30],
        [31, -16],
        [8, -58],
        [-26, -28],
        [-64, -28],
        [-7, -68],
        [2, -54],
        [-1, -39],
        [-5, -54],
        [-52, -24],
        [-34, 82],
        [0, 33],
        [-11, 38],
        [-1, 33],
        [-12, 52],
        [-50, 14],
        [-19, 2],
        [-27, -9],
        [-6, 3],
        [-33, 72],
        [6, 79],
        [-17, 40],
        [-3, 18],
        [1, 20],
        [-14, 16],
        [-18, 9],
        [-8, 44],
        [20, 11],
        [48, -5],
        [15, 3],
        [13, 9],
        [39, 73],
        [-1, 16],
        [4, 21],
        [42, 8],
        [19, -28],
        [-3, -46],
        [2, -57],
        [26, -16],
        [10, -3],
        [10, 43],
        [8, 21],
        [10, 12],
        [4, 39],
        [-6, 24],
        [-13, 17],
        [48, 49],
        [50, 38],
        [29, 2],
        [29, -9],
        [27, -13],
        [15, -11],
        [8, -18],
        [-18, -43],
        [-5, -23],
        [12, -77],
      ],
      [
        [52408, 83469],
        [-3, 48],
        [-6, 34],
        [-18, 51],
        [27, 12],
        [-5, 100],
        [-10, 52],
        [-75, 53],
        [-60, 51],
        [14, 174],
        [6, 46],
        [-23, 91],
        [3, 105],
        [9, 164],
        [19, 7],
        [14, -1],
        [53, -30],
        [22, -3],
        [15, -26],
        [18, -11],
        [13, 28],
        [5, 48],
        [42, 62],
        [30, 23],
        [20, 11],
        [20, -25],
        [16, -28],
        [4, 61],
        [12, 117],
        [-40, 19],
        [-33, -16],
        [-32, -74],
        [-29, -94],
        [-47, -8],
        [-37, -27],
        [-34, 28],
        [-22, 24],
        [0, 35],
        [5, 22],
        [39, 76],
        [54, 73],
        [53, -1],
        [39, 23],
        [24, 3],
        [72, -5],
        [38, 16],
        [33, 34],
        [73, 141],
        [41, 59],
        [82, 21],
        [76, 68],
        [22, 1],
        [-36, -51],
        [-6, -19],
        [-4, -30],
        [25, -66],
        [-5, -40],
        [2, -78],
        [-24, -41],
        [-28, -87],
        [-12, -13],
        [-2, -101],
        [3, -25],
        [-4, -92],
        [28, -38],
        [29, -20],
        [99, 1],
        [10, -16],
        [13, -29],
        [-9, -49],
        [-11, -36],
        [-28, -31],
        [-37, -23],
        [-23, -1],
        [-31, 44],
        [-15, -14],
        [-15, -23],
        [-26, -119],
        [-12, -81],
        [-6, -7],
        [-15, 12],
        [-25, 1],
        [-31, -19],
        [16, -17],
        [17, -30],
        [-7, -15],
        [-28, -16],
        [-24, -32],
        [-11, -25],
        [-31, -29],
        [-19, -37],
        [9, -46],
        [4, -40],
        [9, -45],
        [-8, -35],
        [-38, -51],
        [-14, -45],
        [32, 1],
        [21, -10],
        [12, -13],
        [12, -19],
        [-8, -23],
        [10, -59],
      ],
      [
        [52956, 83876],
        [12, -30],
        [14, -64],
        [23, -72],
        [-10, -30],
        [7, -38],
        [-7, -41],
        [-44, -46],
        [-51, -2],
        [-52, 22],
        [-74, 44],
        [-6, 24],
        [-10, 13],
        [-20, 74],
        [1, 92],
        [37, 11],
        [81, 43],
        [18, -6],
        [20, -23],
        [23, -1],
        [32, 32],
        [6, -2],
      ],
      [
        [53155, 83462],
        [50, -36],
        [33, 2],
        [22, -14],
        [6, -23],
        [2, -51],
        [-24, -15],
        [-26, 5],
        [-36, -19],
        [-117, 83],
        [2, 69],
        [4, 27],
        [56, 7],
        [28, -35],
      ],
      [
        [52981, 83381],
        [-12, -3],
        [-17, 46],
        [-2, 15],
        [20, 30],
        [12, 34],
        [33, 52],
        [19, 61],
        [7, -1],
        [-8, -55],
        [-43, -151],
        [-9, -28],
      ],
      [
        [53485, 83505],
        [-10, -9],
        [-43, 7],
        [-48, -40],
        [-18, 12],
        [7, 26],
        [5, 9],
        [16, 11],
        [11, 16],
        [4, 25],
        [10, -14],
        [30, -5],
        [14, -8],
        [12, -12],
        [10, -18],
      ],
      [
        [53518, 83868],
        [-26, -24],
        [-6, 1],
        [-9, 34],
        [14, 20],
        [8, 17],
        [6, 0],
        [8, -19],
        [5, -29],
      ],
      [
        [52912, 83437],
        [-19, -6],
        [-21, 12],
        [-35, 48],
        [-4, 12],
        [18, -8],
        [23, -25],
        [18, -5],
        [25, -21],
        [-5, -7],
      ],
      [
        [52794, 83459],
        [-29, -8],
        [-14, 14],
        [-28, 5],
        [-9, 89],
        [2, 5],
        [14, -6],
        [47, -41],
        [16, -45],
        [1, -13],
      ],
      [
        [52946, 83976],
        [-5, -11],
        [-17, 11],
        [-2, 37],
        [6, 34],
        [-7, 30],
        [8, 19],
        [25, -45],
        [7, -21],
        [-9, -25],
        [-6, -29],
      ],
      [
        [53070, 84822],
        [-12, -13],
        [-38, 19],
        [17, 26],
        [42, 13],
        [24, -4],
        [-27, -27],
        [-6, -14],
      ],
      [
        [54190, 83537],
        [-10, -10],
        [-46, 17],
        [-56, 40],
        [9, 78],
        [14, 34],
        [102, -88],
        [1, -33],
        [-14, -38],
      ],
      [
        [54709, 79837],
        [-7, 13],
        [-13, 48],
        [-14, 6],
        [-19, 4],
        [-14, 7],
        [-31, 28],
        [-16, 8],
        [-18, 2],
        [-18, -16],
        [-13, -19],
        [-41, 0],
        [-45, 9],
        [-65, 63],
        [-16, 1],
        [-18, -3],
        [-28, 15],
        [-55, 41],
        [-26, 9],
        [-16, -5],
        [-14, -9],
        [-11, -2],
        [-6, 14],
        [-20, 16],
        [-21, 2],
        [-6, -10],
        [-6, -90],
        [-7, -32],
        [-28, 1],
        [-10, -15],
        [-22, -44],
        [-5, -41],
        [-38, 8],
        [-18, 7],
        [-16, -6],
        [-18, -23],
        [-49, 2],
        [-39, 13],
        [-17, 52],
        [-18, 21],
        [-22, 18],
        [-8, 4],
      ],
      [
        [59445, 72041],
        [-11, -4],
        [-17, 16],
        [-10, 5],
        [-10, -15],
        [-11, -11],
        [-10, -5],
        [-8, -2],
        [-14, -11],
        [-17, 3],
        [-25, 9],
        [-14, -22],
        [-3, 3],
        [-2, 55],
        [-9, 23],
        [-11, 13],
        [-16, -6],
        [-22, 2],
        [-16, 10],
        [-31, -16],
        [-26, -17],
        [-18, -17],
        [-14, 1],
        [-24, 16],
        [-18, 17],
        [-2, 14],
      ],
      [
        [59086, 72102],
        [17, -6],
        [30, 12],
        [13, 56],
        [4, 65],
        [50, -19],
        [52, -9],
        [42, -4],
        [41, 11],
        [126, 69],
        [36, 41],
        [23, 14],
        [38, 34],
        [40, 19],
        [-25, -39],
        [-145, -174],
        [-10, -52],
        [7, -35],
        [20, -44],
      ],
      [
        [59445, 72041],
        [5, -11],
        [8, -33],
        [-32, -10],
        [-31, -3],
        [-18, 4],
        [-17, -1],
        [-51, -95],
        [-28, -32],
        [-33, -19],
        [-33, -11],
        [-17, -1],
        [-15, -12],
        [-10, -22],
        [0, -22],
        [-5, -17],
        [-18, 4],
        [-8, 34],
        [-13, 15],
        [-32, -8],
        [-16, 1],
        [-52, 33],
        [-16, 13],
        [-10, 28],
        [-27, 101],
        [-4, 75],
        [25, -19],
        [23, 23],
        [23, 38],
        [27, 15],
        [16, -7],
      ],
      [
        [27267, 65185],
        [73, -26],
        [59, 7],
        [28, 16],
        [-3, -16],
        [26, -40],
        [10, -3],
        [38, 20],
        [99, 8],
        [10, -11],
        [18, -39],
        [25, -24],
        [26, -18],
        [28, -5],
        [27, 8],
        [26, -4],
        [32, -37],
        [10, -5],
        [28, 10],
        [-8, -34],
        [48, -49],
        [36, -95],
        [25, -39],
        [28, -35],
        [23, -24],
        [25, -11],
        [79, 5],
        [18, -3],
        [17, -14],
        [15, -5],
        [9, 5],
        [151, -149],
        [48, -79],
        [30, -41],
        [63, -59],
        [25, -13],
        [14, 7],
        [-3, 14],
        [-18, 33],
        [-3, 12],
        [24, -11],
        [43, -67],
        [12, -24],
        [21, -23],
        [22, -17],
        [-11, -26],
        [-17, -3],
        [-34, 11],
        [27, -43],
        [5, -31],
        [12, -3],
        [19, 35],
        [11, 29],
        [48, -75],
        [25, -34],
        [-6, -20],
        [-2, -20],
        [28, 18],
        [11, -2],
        [10, -11],
        [12, -32],
        [26, -7],
        [27, 1],
        [55, -27],
        [51, -54],
        [49, -11],
        [49, -2],
        [24, -28],
        [11, -39],
        [-12, -27],
        [-7, -28],
        [18, -35],
        [-39, -15],
        [-6, -21],
        [2, -23],
        [8, -12],
        [23, 11],
        [33, -10],
        [51, -8],
        [35, 7],
        [71, -24],
        [21, -13],
        [42, -44],
        [19, -30],
        [42, -79],
        [35, -31],
        [31, -8],
        [11, 5],
        [10, -8],
        [9, -11],
        [8, -35],
        [-5, -37],
        [-17, -29],
        [-10, -22],
        [-44, -2],
        [-62, -10],
        [-60, -32],
        [-29, -26],
        [-14, -17],
        [-31, -15],
        [-2, 13],
        [0, 17],
        [-8, 31],
        [-7, -28],
        [-12, -21],
        [-19, -17],
        [-73, -1],
        [-29, 23],
        [-30, 17],
        [-109, 16],
        [-27, -1],
        [-73, -18],
        [-73, -9],
        [-31, -11],
        [-30, -16],
        [-59, 0],
        [-70, -18],
        [-70, -4],
        [45, 131],
        [95, 125],
        [17, 27],
        [13, 35],
        [3, 26],
        [-4, 23],
        [-23, 39],
        [-4, 29],
        [-7, 19],
        [-33, 17],
        [-33, 10],
        [-35, 0],
        [-73, 13],
        [-39, 1],
        [-33, 27],
        [-55, 96],
        [-26, 26],
        [-13, 22],
        [-10, 24],
        [-13, 140],
        [-11, 68],
        [-17, 58],
        [-25, 45],
        [-27, 15],
        [-101, -38],
        [-24, 5],
        [-23, 13],
        [-154, 91],
        [-63, 50],
        [-26, 25],
        [-22, 35],
        [-23, 58],
        [-25, 52],
        [0, -21],
        [-4, -14],
        [-129, -6],
        [-20, 12],
        [-13, 14],
        [-10, 21],
        [-7, 42],
        [-12, 35],
        [-4, -38],
        [-6, -34],
        [-17, -20],
        [-20, -3],
        [-24, 46],
        [-104, 10],
        [-9, 8],
        [-34, 44],
        [-30, 56],
        [29, 19],
        [60, 26],
        [13, 18],
        [8, 21],
        [-5, 33],
        [-12, 24],
        [-13, 14],
        [-13, 9],
        [-18, 4],
        [-232, 5],
        [-13, -17],
        [-21, -37],
        [-41, -46],
        [-28, -49],
        [-10, -25],
        [-12, -18],
        [-29, -30],
        [-24, -46],
        [-30, -21],
        [-16, 13],
        [-16, 0],
        [-11, -12],
        [-13, -5],
        [-59, -6],
        [-9, -11],
        [-8, -34],
        [-10, -64],
        [-9, -21],
        [-30, -8],
        [-28, -18],
        [-58, -62],
        [-15, -9],
        [3, 45],
        [-3, 44],
        [-16, 2],
        [-19, -7],
        [-15, -13],
        [-29, -32],
        [-14, -9],
        [-14, 17],
        [3, 21],
        [95, 79],
        [11, 6],
        [17, -6],
        [17, 3],
        [13, 22],
        [-16, 105],
        [6, 71],
        [22, 55],
        [45, 83],
        [21, 27],
        [219, 174],
        [22, 9],
        [142, 35],
        [22, 12],
        [66, 51],
        [69, 21],
        [73, -16],
      ],
      [
        [28425, 64488],
        [-11, -18],
        [-13, 26],
        [-8, 2],
        [-11, 10],
        [-21, 29],
        [-5, 29],
        [17, 2],
        [23, -5],
        [39, -16],
        [-4, -34],
        [-6, -25],
      ],
      [
        [28326, 64680],
        [-6, -10],
        [-15, 22],
        [-22, 9],
        [-13, 33],
        [-12, 13],
        [-1, 12],
        [20, 9],
        [14, -4],
        [16, -26],
        [9, -46],
        [10, -12],
      ],
      [
        [28158, 64834],
        [38, -13],
        [13, 8],
        [13, 2],
        [13, -5],
        [19, -48],
        [-16, -6],
        [-13, 0],
        [-10, 8],
        [-34, 3],
        [-23, 14],
        [-12, 12],
        [-6, 14],
        [18, 11],
      ],
      [
        [28367, 64589],
        [-10, -2],
        [-27, 24],
        [-9, 20],
        [10, 27],
        [2, 30],
        [4, 1],
        [4, -35],
        [22, -15],
        [1, -8],
        [13, -30],
        [-10, -12],
      ],
      [
        [27066, 64269],
        [-26, -31],
        [-55, -43],
        [-30, -1],
        [-30, 16],
        [-20, 36],
        [-12, 35],
        [1, 17],
        [19, -28],
        [16, -14],
        [13, 9],
        [10, 16],
        [-31, 114],
        [2, 25],
        [24, 62],
        [65, -19],
        [11, -11],
        [10, -40],
        [14, -31],
        [17, -83],
        [2, -29],
      ],
      [
        [27958, 64898],
        [1, -15],
        [-49, 42],
        [-21, 44],
        [-8, 10],
        [13, 1],
        [55, -72],
        [9, -10],
      ],
      [
        [55279, 77689],
        [-18, -2],
        [-29, 10],
        [-14, 18],
        [-2, 19],
        [0, 17],
        [-9, 29],
        [-23, 29],
        [-49, 5],
        [-18, 9],
        [-18, 11],
        [-20, 8],
        [-19, -1],
        [-22, -8],
        [-40, 13],
        [-13, -17],
        [-20, -20],
        [-18, 0],
        [-34, 46],
        [-10, 3],
        [-30, -23],
        [-12, -1],
        [-9, 7],
        [-40, 18],
        [-18, 3],
        [-14, -8],
        [-23, 9],
        [-58, 61],
        [-35, -46],
        [-73, 11],
        [-21, -31],
        [-25, -60],
        [-20, -29],
        [-17, 11],
        [-20, 26],
        [-36, 67],
        [-18, 13],
        [-21, 3],
        [-18, -8],
        [-10, -14],
        [-7, -98],
        [-7, -87],
        [0, -53],
        [40, -48],
        [47, -83],
        [15, -10],
        [7, -27],
        [11, -70],
        [12, -79],
        [24, -52],
        [22, -37],
        [26, -33],
        [33, -52],
        [27, -56],
        [7, -21],
        [52, -75],
        [51, -76],
        [46, -27],
        [7, -14],
        [0, -59],
        [5, -22],
        [30, -61],
        [62, -90],
        [7, -21],
        [2, -15],
        [-4, -12],
        [-16, -12],
      ],
      [
        [54884, 76577],
        [-13, 13],
        [-58, 88],
        [-55, 56],
        [-63, 104],
        [-84, 41],
        [-58, 46],
        [-34, -7],
        [-39, -14],
        [-23, -1],
        [-17, 9],
        [-12, 28],
        [2, 22],
        [-2, 28],
        [-34, 46],
        [-46, 44],
        [-43, 56],
        [-87, 151],
        [-18, 49],
        [17, 9],
        [13, 0],
        [15, 10],
        [24, 0],
        [28, -10],
        [-25, 32],
        [-31, 32],
        [-80, 127],
        [-24, 59],
        [-3, 65],
        [6, 88],
        [-14, 63],
        [-62, 82],
        [-23, 43],
        [-45, 25],
        [-21, -2],
        [-12, -32],
        [-9, -71],
        [-40, -93],
        [-14, -41],
        [-21, -52],
        [-18, -4],
        [-11, 5],
        [-33, 88],
        [-32, 68],
        [-4, 31],
        [-3, 40],
        [-24, 144],
        [17, 20],
      ],
      [
        [54625, 76610],
        [51, -16],
        [38, 8],
        [34, -10],
        [21, -19],
        [5, -9],
        [-28, -1],
        [-31, 8],
        [-35, -19],
        [-31, 10],
        [-12, 12],
        [-8, 15],
        [-4, 21],
      ],
      [
        [54776, 76685],
        [-20, -6],
        [-123, 4],
        [-36, 12],
        [-40, 31],
        [-9, 9],
        [41, 9],
        [37, -9],
        [12, -22],
        [101, -18],
        [37, -10],
      ],
      [
        [54662, 76768],
        [-44, -1],
        [-38, 10],
        [-19, 18],
        [2, 15],
        [6, 25],
        [42, -3],
        [65, -18],
        [15, -21],
        [-4, -9],
        [-25, -16],
      ],
      [
        [54230, 77224],
        [5, -20],
        [-35, 38],
        [-13, 26],
        [-3, 11],
        [46, -55],
      ],
      [
        [54113, 77751],
        [-34, -12],
        [-16, 22],
        [-4, 18],
        [-29, 6],
        [-17, 25],
        [-3, 11],
        [24, 28],
        [13, 45],
        [16, -27],
        [20, -51],
        [11, -14],
        [19, -51],
      ],
      [
        [54119, 77625],
        [7, -25],
        [-26, 23],
        [-23, 9],
        [-5, 17],
        [3, 14],
        [5, 14],
        [18, -2],
        [3, -14],
        [18, -36],
      ],
      [
        [54218, 77382],
        [-7, -16],
        [-18, 29],
        [-16, 20],
        [-12, 23],
        [-23, 30],
        [-8, 34],
        [-34, 69],
        [-6, 19],
        [18, -28],
        [14, -18],
        [12, -4],
        [30, -44],
        [30, -57],
        [35, -49],
        [-8, -2],
        [-7, -6],
      ],
      [
        [54219, 77143],
        [4, -8],
        [-1, -6],
        [-14, 8],
        [-4, -2],
        [-68, 126],
        [-7, 24],
        [24, -29],
        [66, -113],
      ],
      [
        [54269, 77173],
        [19, -43],
        [-18, 9],
        [-18, 27],
        [-11, 28],
        [28, -21],
      ],
      [
        [54024, 77568],
        [-2, -22],
        [-17, 28],
        [-9, 51],
        [-21, 82],
        [-3, 23],
        [11, 23],
        [0, 23],
        [-15, 72],
        [12, 11],
        [8, 2],
        [3, -50],
        [7, -29],
        [20, -35],
        [-4, -58],
        [4, -83],
        [4, -18],
        [2, -20],
      ],
      [
        [54891, 76479],
        [37, -39],
        [-111, 51],
        [13, 5],
        [12, 1],
        [49, -18],
      ],
      [
        [55143, 76286],
        [-51, 54],
        [-48, 61],
        [-93, 94],
        [-67, 23],
        [-91, 76],
        [-59, 27],
        [23, 6],
        [26, 0],
        [140, -101],
        [-16, 27],
      ],
      [
        [54907, 76553],
        [20, 10],
        [17, -7],
        [11, -33],
        [22, -22],
        [35, -38],
        [22, -29],
        [50, -53],
        [12, -7],
        [25, -15],
      ],
      [
        [49135, 54774],
        [-37, 15],
        [8, 19],
        [36, -11],
      ],
      [
        [48465, 57848],
        [17, -38],
        [22, -26],
        [34, 3],
        [24, -15],
        [21, -30],
        [14, -65],
        [15, -47],
        [7, -67],
        [24, -35],
        [19, -17],
        [26, -48],
        [27, -25],
        [27, 6],
        [13, -26],
        [21, -18],
        [20, -1],
        [18, 56],
        [24, 22],
        [61, 45],
        [23, 20],
        [25, 13],
        [58, 4],
        [54, -13],
        [27, -11],
        [18, 8],
        [18, -27],
        [18, -56],
        [14, -18],
        [15, -19],
        [12, -44],
        [13, -44],
        [7, -19],
        [16, -44],
        [14, 0],
        [14, 18],
        [6, 14],
      ],
      [
        [49161, 54798],
        [-2, 12],
        [-11, 4],
        [-29, 26],
        [5, 83],
        [-13, 4],
        [-11, -11],
        [-20, -101],
        [-10, -17],
        [-146, 52],
        [-31, 42],
        [-38, 9],
        [-66, -5],
        [-54, -12],
        [-16, -25],
        [137, 15],
        [15, -3],
        [7, -16],
        [-173, -33],
        [-67, -19],
        [-19, 5],
        [-15, 32],
        [-72, 4],
        [-15, -11],
        [-8, -23],
        [28, 5],
        [44, 1],
        [12, -18],
        [-139, -24],
        [-97, -45],
        [-41, -33],
        [-135, -110],
        [-83, -52],
        [-22, -20],
        [-37, -53],
        [-48, -34],
        [-54, -64],
        [-33, -14],
      ],
      [
        [26978, 56492],
        [-19, 64],
        [-26, 61],
        [-23, 37],
        [-2, 88],
        [-9, 48],
        [-34, 44],
        [-30, 30],
        [-21, -6],
        [13, -50],
        [34, -65],
        [3, -25],
        [-1, -33],
        [-23, 5],
        [-21, 14],
        [-26, 4],
        [-17, 20],
        [-36, 77],
        [26, 66],
        [8, 43],
        [-1, 90],
        [-6, 43],
        [-28, 67],
        [-44, 72],
        [-61, 60],
        [-29, 47],
        [-73, 37],
        [-27, 24],
        [-22, 45],
        [-3, 33],
        [8, 50],
        [-20, 63],
        [-86, 125],
        [-48, 46],
        [-11, 26],
        [-7, 9],
        [7, -86],
        [21, -52],
        [55, -48],
        [15, -28],
        [6, -37],
        [-32, -70],
        [-16, -18],
        [-5, -38],
        [-10, -12],
        [-11, 22],
        [-45, 110],
        [-86, 53],
        [-16, 32],
        [-32, 101],
        [-14, 91],
        [5, 61],
        [35, 96],
        [11, 41],
        [-2, 26],
        [1, 37],
        [-13, 26],
        [-33, 35],
        [-21, 27],
        [6, 14],
        [38, 37],
        [2, 33],
        [0, 11],
      ],
      [
        [26766, 58131],
        [7, -23],
        [8, -36],
        [3, -46],
        [36, -155],
        [28, -86],
        [62, -158],
        [26, -29],
        [45, -127],
        [16, -21],
        [9, -37],
        [46, -31],
        [13, -23],
      ],
      [
        [56657, 45580],
        [-11, -11],
        [-6, -30],
        [-1, -23],
        [-19, -18],
        [-38, 4],
        [-38, 17],
        [-27, 5],
        [-18, -4],
        [-67, -57],
        [-22, -7],
        [-73, 4],
        [-41, 12],
        [-30, 2],
        [-20, -18],
        [-26, -42],
        [-22, -22],
        [-10, 2],
        [-6, 18],
        [-9, 24],
        [-2, 63],
        [-11, 69],
        [7, 36],
        [21, 27],
        [8, 53],
        [-7, 81],
        [0, 56],
        [6, 33],
        [-8, 79],
        [-22, 126],
        [-30, 102],
        [-39, 79],
        [-25, 76],
        [-12, 72],
        [4, 173],
        [12, 153],
        [9, 121],
        [-2, 203],
        [-27, 132],
        [-6, 142],
        [15, 152],
        [3, 104],
        [-10, 53],
        [-7, 8],
        [-9, 5],
        [-66, 5],
        [-89, 7],
        [-78, 2],
        [-84, 2],
        [-14, 20],
        [-6, 35],
        [0, 35],
        [17, 108],
        [-2, 9],
        [-30, 2],
        [-81, -18],
        [-54, -17],
        [-34, -6],
        [-59, -29],
        [-37, -62],
        [-12, -78],
        [1, -64],
        [-2, -47],
        [-17, -49],
        [-14, -56],
        [0, -30],
        [-8, -149],
        [-55, -20],
        [-55, 0],
        [-13, 1],
        [-68, 36],
        [-25, 0],
        [-22, -19],
        [-42, -18],
        [-39, -13],
        [-40, -45],
        [-11, -4],
        [-27, 23],
        [-37, -2],
        [-38, -11],
        [-17, -5],
        [-12, 14],
        [-35, 111],
        [-46, 149],
        [-25, 94],
        [-10, 24],
        [-16, 32],
        [-22, 61],
        [-9, 58],
        [4, 55],
        [-13, 73],
        [-29, 93],
        [-20, 89],
        [-9, 85],
        [-2, 72],
        [4, 60],
        [-5, 45],
        [-16, 28],
        [-9, 37],
        [-7, 15],
        [-13, 34],
        [-29, 38],
        [-33, 20],
        [-70, 0],
        [-93, 1],
        [-84, -3],
        [-93, -3],
        [-94, -3],
        [-26, -6],
        [-72, -2],
        [-58, 10],
        [-21, 6],
        [-38, 5],
        [-59, 1],
        [-32, -4],
        [-77, 0],
        [-7, -1],
        [-12, -11],
        [-33, 15],
        [-32, -5],
      ],
      [
        [53630, 48464],
        [-19, 17],
        [-39, -11],
        [-19, -13],
        [-31, -48],
        [-46, -25],
        [-17, 2],
        [-12, 8],
        [-27, 53],
        [-20, 50],
        [-8, 28],
      ],
      [
        [53392, 48525],
        [12, 7],
        [36, 11],
        [27, 5],
        [6, 14],
        [4, 156],
        [1, 159],
        [-10, 21],
        [-9, 12],
        [-1, 11],
        [14, 20],
        [20, 23],
        [6, 11],
        [22, 42],
        [43, 97],
        [33, 24],
        [30, 25],
        [5, 10],
      ],
      [
        [53631, 49173],
        [4, 19],
        [14, -2],
        [4, -9],
        [7, -20],
        [12, -29],
        [21, -34],
        [22, -37],
        [11, -5],
        [17, 19],
        [21, 28],
        [30, 20],
        [7, 19],
        [4, 40],
        [2, 44],
        [3, 51],
        [6, 7],
        [11, 5],
        [20, -15],
        [9, -15],
        [16, 0],
        [10, 14],
        [19, 25],
        [25, 10],
        [25, 24],
        [25, 31],
        [12, 3],
        [12, -40],
        [11, -29],
        [2, -17],
        [-11, -34],
        [-12, -45],
        [10, -55],
        [3, -54],
        [-1, -32],
        [9, -13],
        [6, -7],
        [9, 8],
        [17, -2],
        [21, -17],
        [21, 2],
        [20, 20],
        [37, 81],
        [54, 141],
        [44, 88],
        [36, 36],
        [24, 43],
        [12, 48],
        [21, 33],
        [43, 26],
        [32, 29],
        [33, 97],
        [44, 174],
        [12, 156],
        [7, 94],
        [-4, 326],
        [-3, 107],
        [7, 58],
        [16, 40],
        [44, 85],
        [30, 70],
        [23, 81],
        [44, 186],
        [19, 60],
        [8, 26],
        [27, 50],
        [37, 43],
        [47, 38],
        [73, 129],
        [59, 130],
        [-8, 157],
        [13, 129],
        [32, 166],
        [11, 174],
        [-11, 184],
        [4, 151],
        [31, 175],
        [13, 65],
        [4, 106],
        [-1, 169],
        [39, 232],
        [37, 138],
        [41, 155],
        [15, 94],
        [21, 125],
        [-3, 100],
      ],
      [
        [55169, 53846],
        [-4, 116],
        [10, 158],
        [-3, 94],
        [-15, 81],
        [7, 51],
        [30, 21],
        [36, 81],
        [66, 212],
        [71, 104],
        [49, 32],
        [52, -3],
        [33, -19],
        [16, -33],
        [39, -50],
        [62, -66],
        [46, -83],
        [26, -83],
        [20, -46],
        [25, -15],
        [40, 6],
        [45, -19],
        [48, -47],
        [29, -17],
        [10, 12],
        [23, -7],
        [52, -38],
        [42, 21],
        [61, -16],
        [143, -68],
        [7, 11],
        [4, 3],
        [12, 27],
        [31, 137],
        [26, 85],
        [12, 31],
        [31, 45],
        [35, 11],
        [35, -4],
        [28, -19],
        [26, -23],
        [29, 0],
        [30, 22],
        [44, 40],
        [46, 26],
        [40, 29],
        [65, 50],
        [26, 23],
        [33, 10],
        [91, -46],
        [59, 30],
        [24, -9],
        [51, 33],
        [10, 22],
        [32, 111],
        [35, 33],
        [52, -17],
        [30, -17],
        [98, -48],
        [127, -49],
        [38, -8],
        [15, -5],
        [13, 7],
        [42, 63],
        [14, 9],
        [12, -1],
        [80, -51],
      ],
      [
        [58059, 50276],
        [0, -22],
        [1, -24],
        [13, -30],
        [25, -60],
        [19, -56],
        [1, -49],
        [-4, -82],
        [-1, -48],
        [2, -64],
        [0, -121],
        [-1, -86],
        [3, -44],
        [30, -106],
        [13, -118],
        [7, -86],
      ],
      [
        [53692, 53088],
        [67, -1],
        [66, -2],
        [73, 1],
        [70, 1],
        [55, -3],
        [26, 25],
        [25, -38],
        [12, -9],
        [5, 3],
        [9, -27],
        [31, 3],
        [5, -6],
        [3, -33],
        [29, 1],
        [14, -8],
        [12, 1],
        [17, 19],
        [12, -6],
        [22, -25],
        [15, -21],
        [22, 7],
        [51, -4],
        [39, -20],
        [39, -57],
        [26, -33],
        [23, -48],
        [9, 9],
        [8, 13],
        [5, 6],
        [-1, 41],
        [-13, 71],
        [-5, 59],
        [3, 49],
        [10, 35],
        [17, 22],
        [2, 33],
        [0, 4],
      ],
      [
        [54495, 53150],
        [19, 79],
        [19, 78],
        [22, 92],
        [19, 75],
        [-2, 37],
        [1, 56],
        [4, 62],
        [-1, 37],
        [6, 25],
        [13, 93],
        [7, 53],
        [12, 25],
        [17, 17],
        [25, 1],
        [66, 11],
        [62, 24],
        [21, 11],
        [38, 39],
        [15, 2],
        [13, -15],
        [75, -45],
        [20, -17],
        [8, 2],
        [11, -3],
        [17, -1],
        [18, 6],
        [10, -6],
        [14, -29],
        [9, 3],
        [12, 21],
        [23, 22],
        [43, 25],
        [7, -11],
        [15, -54],
        [16, -19],
      ],
      [
        [53631, 49173],
        [-7, 9],
        [-21, 39],
        [-25, 61],
        [-10, 9],
        [-13, -1],
        [-22, -22],
        [-22, -35],
        [-39, -33],
        [-32, -18],
        [-3, -22],
        [-8, -39],
        [-11, -23],
        [-28, -8],
        [-11, -34],
        [-25, -66],
        [-16, -30],
      ],
      [
        [53338, 48960],
        [-4, 13],
        [-10, 16],
        [-21, 51],
        [-20, 63],
        [-5, 29],
        [-6, 17],
        [-1, 64],
        [-30, 75],
        [-77, 135],
        [-8, 40],
        [-65, 124],
      ],
      [
        [62354, 44883],
        [14, -79],
        [0, -60],
        [-6, -19],
        [-13, 12],
        [-23, 48],
        [-43, 46],
        [20, 4],
        [12, -4],
        [12, 4],
        [8, 26],
        [1, 16],
        [11, 12],
        [7, -6],
      ],
      [
        [62163, 44753],
        [19, -35],
        [-54, 15],
        [-8, 31],
        [-1, 24],
        [20, -5],
        [24, -30],
      ],
      [
        [62073, 44987],
        [-5, -8],
        [-25, 33],
        [-15, 8],
        [-21, 53],
        [8, 184],
        [7, 24],
        [5, 10],
        [12, 3],
        [14, -23],
        [-4, -119],
        [19, -79],
        [12, -63],
        [-7, -23],
      ],
      [
        [31423, 52547],
        [-53, -26],
        [-4, 4],
        [-3, 15],
        [1, 109],
        [0, 124],
        [-9, 51],
        [-24, 81],
        [-31, 108],
        [-9, 31],
        [-14, 18],
        [-16, 2],
        [-11, -7],
        [-16, -20],
        [-15, -22],
        [-28, -65],
        [-29, -76],
        [-17, -17],
        [-16, -7],
        [-15, 2],
        [-12, 21],
        [-13, 41],
        [-14, 55],
        [-18, 18],
        [-7, -17],
        [-6, -32],
        [-4, -32],
        [12, -41],
        [10, -32],
        [-18, 1],
        [-56, 0],
        [-66, 0],
        [-65, 0],
        [-58, 0],
        [-55, 0],
        [-21, 3],
        [-21, 18],
        [-20, 9],
        [-10, -1],
        [-20, -18],
        [-24, -3],
        [-17, -17],
        [-14, 2],
        [0, -95],
        [0, -135],
        [-1, -144],
        [15, 11],
        [13, -1],
        [10, -10],
        [27, 8],
        [14, -4],
        [14, -4],
        [13, -1],
        [8, -11],
        [11, 2],
        [12, 13],
        [13, -8],
        [15, -20],
        [10, -31],
        [8, -37],
        [9, -20],
        [-1, -35],
        [0, -28],
        [-3, -24],
        [4, -15],
        [2, -16],
        [0, -9],
        [-5, -4],
        [-11, -3],
        [-12, -3],
        [-8, 1],
        [-6, 14],
        [-6, 2],
        [-9, -2],
        [-9, 9],
        [-8, 18],
        [-14, 18],
        [-15, -8],
        [-11, -9],
        [-11, -11],
        [-9, -12],
        [-10, 3],
        [-13, -9],
        [-10, -14],
        [-14, -10],
        [-15, -6],
        [-18, -5],
        [-17, -2],
        [-19, -4],
        [-1, -76],
        [-2, -148],
        [-1, -99],
        [0, -90],
        [7, -33],
        [34, -70],
        [26, -37],
        [22, -41],
        [23, -17],
        [9, -16],
        [6, -25],
        [3, -27],
        [3, -23],
        [-3, -24],
        [-5, -23],
        [2, -24],
        [8, -19],
        [2, -24],
        [6, -23],
        [3, -23],
        [7, -16],
        [8, -12],
        [11, -19],
        [2, -17],
        [-2, -21],
        [0, -15],
        [11, -35],
        [3, -25],
        [-5, -29],
        [-5, -101],
        [-12, -116],
        [-8, -88],
        [-12, -143],
        [-15, -167],
        [-18, -204],
        [-17, -201],
        [-18, -195],
        [-15, -176],
        [-17, -194],
        [-10, -117],
        [-5, -20],
      ],
      [
        [28094, 52681],
        [-8, 40],
        [-38, 57],
        [19, 74],
        [46, 56],
        [60, -44],
        [7, 87],
        [-22, 76],
        [4, 144],
        [7, 29],
        [16, 39],
        [20, 26],
        [12, 8],
        [21, -13],
        [13, 29],
        [49, -13],
        [15, 12],
        [10, 20],
        [12, 14],
        [15, 35],
        [9, 40],
        [7, 16],
        [17, -6],
        [1, 18],
        [9, 23],
        [30, 53],
        [-1, 23],
        [-8, 51],
        [2, 19],
        [17, 6],
        [21, 15],
        [10, 48],
        [14, 42],
        [15, 63],
        [17, 4],
        [9, 72],
        [22, 64],
        [46, 189],
        [-13, -4],
        [-11, -26],
        [-13, 3],
        [-14, 15],
        [4, 85],
        [-8, 10],
        [-23, -65],
        [-19, 67],
        [-2, 40],
        [8, 40],
        [-1, 27],
        [-31, -20],
        [2, 25],
        [19, 26],
        [9, 27],
        [17, 29],
        [7, 44],
        [4, 69],
        [7, 74],
        [-5, 36],
        [-9, 31],
        [-8, 137],
        [2, 80],
        [-4, 62],
        [-8, 54],
        [-37, 69],
        [59, 80],
        [21, 60],
        [-27, 124],
        [-35, 105],
        [-1, 62],
        [10, -8],
        [11, 2],
        [11, 132],
        [-3, 41],
        [-19, 67],
        [-24, 1],
        [-21, 83],
        [-13, 19],
        [-9, 52],
        [-34, 102],
        [-27, 53],
      ],
      [
        [28507, 56830],
        [8, -12],
        [23, -83],
        [37, -53],
        [38, -87],
        [16, -59],
        [12, -11],
        [11, -22],
        [-5, -16],
        [-12, -17],
        [-3, -35],
        [8, -19],
        [8, -12],
        [22, 8],
        [12, 40],
        [-8, 178],
        [-13, 89],
        [-15, 27],
        [-13, 35],
        [9, 27],
        [24, 12],
        [31, 31],
        [115, 170],
        [39, 159],
        [30, 58],
        [34, 37],
        [41, -9],
        [33, 20],
        [10, 51],
        [-9, 69],
        [-13, 41],
        [12, 61],
        [13, 91],
        [-1, 76],
        [16, 46],
        [-6, 18],
        [-23, -37],
        [-18, -16],
        [10, 30],
        [33, 76],
        [16, 115],
        [13, 48],
        [46, 67],
        [10, 32],
        [34, 50],
        [56, 108],
        [22, 30],
        [108, -69],
        [34, 4],
        [-6, -13],
        [-16, -4],
        [-23, -19],
        [-6, -41],
        [15, -44],
        [17, -12],
        [14, 28],
        [14, 80],
        [22, 88],
        [6, 92],
        [15, 32],
        [24, 11],
        [41, -18],
        [32, -19],
        [33, -3],
        [101, 14],
        [164, 240],
        [77, 52],
        [47, 50],
        [31, 99],
        [8, 74],
        [22, 28],
        [24, 0],
        [11, 18],
        [3, 23],
        [57, 64],
        [33, 8],
        [28, -1],
        [65, -56],
        [29, -98],
        [5, -68],
        [-40, -74],
        [-10, -32],
      ],
      [
        [28301, 53307],
        [-7, -13],
        [-14, 23],
        [-5, 29],
        [8, 21],
        [12, -7],
        [5, -18],
        [1, -35],
      ],
      [
        [82828, 65953],
        [-10, -34],
        [-16, 5],
        [0, 25],
        [-4, 7],
        [5, 23],
        [3, 7],
        [18, -20],
        [4, -13],
      ],
      [
        [83850, 69983],
        [-23, -16],
        [-72, 49],
        [-51, 55],
        [-31, 65],
        [-4, 28],
        [35, -5],
        [35, -24],
        [8, -36],
        [14, -11],
        [9, -21],
        [65, -49],
        [10, -15],
        [5, -20],
      ],
      [
        [83970, 69103],
        [-4, -12],
        [-34, 33],
        [-37, 7],
        [-13, 29],
        [-2, 46],
        [39, -2],
        [48, -41],
        [11, -21],
        [-8, -39],
      ],
      [
        [83936, 68939],
        [-1, -11],
        [-24, 37],
        [-11, 7],
        [5, 21],
        [16, 5],
        [13, -47],
        [2, -12],
      ],
      [
        [84000, 69062],
        [-2, -27],
        [-8, 4],
        [-10, 47],
        [6, 12],
        [14, -3],
        [0, -33],
      ],
      [
        [83283, 66507],
        [-21, -27],
        [-13, 13],
        [0, 36],
        [7, 32],
        [-8, 23],
        [7, 28],
        [16, 8],
        [5, -17],
        [9, -10],
        [3, -9],
        [0, -18],
        [-9, -30],
        [7, -16],
        [-3, -13],
      ],
      [
        [80662, 63993],
        [10, -20],
        [28, 14],
        [5, -25],
        [-1, -12],
        [-9, -29],
        [-23, 22],
        [-23, -5],
        [-16, 2],
        [-5, 14],
        [13, 28],
        [21, 11],
      ],
      [
        [83680, 68021],
        [-24, -13],
        [-9, 0],
        [0, 42],
        [20, 39],
        [8, -13],
        [5, -21],
        [0, -34],
      ],
      [
        [81542, 64979],
        [3, -27],
        [-22, 41],
        [-6, 2],
        [-11, 15],
        [-6, 25],
        [17, 1],
        [16, -30],
        [9, -27],
      ],
      [
        [81330, 64286],
        [-6, -11],
        [-8, 20],
        [-2, 30],
        [-6, 16],
        [14, 21],
        [6, 22],
        [15, -4],
        [7, -7],
        [-14, -23],
        [-3, -10],
        [-3, -54],
      ],
      [
        [81289, 64308],
        [-27, -12],
        [-6, 2],
        [10, 30],
        [24, 21],
        [-1, -41],
      ],
      [
        [78093, 64266],
        [3, 8],
        [-6, 72],
        [1, 17],
        [-3, 6],
        [-11, 6],
        [-17, -11],
        [-51, -47],
        [-44, -87],
        [-20, -19],
        [-20, -8],
        [-24, 15],
        [-27, 10],
        [-37, -22],
        [-19, 10],
        [-9, 18],
        [-7, 27],
        [4, 34],
        [-2, 25],
        [-16, 13],
        [-17, 11],
        [-10, 32],
        [-5, 36],
        [4, 47],
        [3, 50],
        [-9, 23],
        [-26, 12],
        [-64, 23],
        [-57, 12],
        [-24, -6],
        [-19, 6],
        [-11, 9],
        [-6, 16],
        [0, 22],
        [9, 52],
        [11, 51],
        [26, 73],
        [2, 51],
        [-2, 59],
        [13, 79],
        [23, 59],
        [11, 18],
        [-3, 26],
        [-9, 24],
        [-13, 13],
        [-21, 16],
        [-34, 4],
        [-45, 16],
        [-54, 35],
        [6, 67],
        [0, 42],
        [-7, 34],
        [-11, 24],
        [-6, 22],
        [10, 60],
        [-13, 65],
        [-14, 27],
        [-15, 33],
        [-2, 37],
        [7, 34],
        [37, 73],
        [0, 17],
        [-9, -1],
        [-10, -2],
        [-50, -26],
        [-6, 16],
        [-18, 10],
        [-37, 2],
        [-43, -5],
        [-54, -26],
        [-50, -45],
        [-22, -32],
        [-20, -19],
        [-15, -7],
        [-19, 14],
        [2, 45],
        [34, 82],
        [4, 56],
        [-10, 49],
        [-1, 38],
        [-12, 25],
        [-17, 12],
        [-9, 28],
        [0, 80],
        [15, 83],
        [24, 26],
        [15, 13],
        [4, 16],
        [-8, 57],
        [1, 37],
        [15, 72],
        [14, 54],
        [28, -9],
        [12, 13],
        [13, 19],
        [15, 33],
        [10, 38],
        [12, 90],
        [8, 13],
        [35, -15],
        [10, 11],
        [19, 52],
        [18, 64],
        [26, 20],
        [18, 2],
        [9, 21],
        [-1, 31],
        [-17, 50],
        [-8, 39],
        [2, 24],
        [26, 15],
        [6, 29],
        [-4, 63],
        [11, 75],
        [6, 89],
        [2, 66],
        [0, 50],
        [-3, 53],
        [-4, 96],
        [-11, 84],
        [2, 32],
        [-2, 102],
        [-7, 86],
        [-14, 16],
        [-26, 28],
        [-15, 5],
        [-12, -10],
        [-5, -30],
        [-11, -28],
        [-15, 6],
        [-6, 29],
        [-10, 37],
        [-30, 175],
        [-4, 50],
        [-5, 51],
        [-10, 25],
        [-11, 14],
        [-25, 59],
        [-13, 25],
        [-6, 4],
        [-14, -4],
        [-13, 0],
        [-11, 29],
        [-9, 36],
        [-10, 18],
        [-17, 9],
        [-17, -3],
        [-10, -31],
        [-7, -18],
        [-12, -41],
        [-21, -58],
        [-10, -21],
      ],
      [
        [75453, 67833],
        [-1, 24],
        [3, 70],
        [-10, 17],
        [-31, 17],
        [-35, 23],
        [-17, 25],
        [-9, 8],
        [-13, -4],
        [-21, -26],
        [-20, -30],
        [-16, -3],
        [-16, 15],
        [-16, 18],
        [-53, 26],
        [-24, 4],
        [-42, -5],
        [-35, 6],
        [-5, 8],
        [0, 14],
        [5, 29],
        [3, 27],
        [-4, 16],
        [-35, 20],
        [-33, 14],
        [-34, 5],
        [-23, -10],
        [-23, -22],
        [-18, -39],
        [-27, -17],
        [-32, -29],
        [-16, -28],
        [-24, -58],
        [-34, -72],
        [-31, -71],
        [-16, -68],
        [-21, -43],
        [-22, -31],
        [-16, -85],
      ],
      [
        [71610, 72290],
        [-21, -9],
        [-42, -5],
        [-14, 1],
        [-21, 1],
        [-42, 19],
        [-57, 25],
        [-58, 35],
        [-32, 28],
      ],
      [
        [70705, 73169],
        [-4, 5],
        [-8, 15],
        [-34, 46],
        [-1, 12],
        [52, 45],
        [31, 18],
        [16, 13],
        [3, -2],
        [8, -22],
        [20, -13],
        [14, 3],
      ],
      [
        [84544, 74886],
        [-3, 5],
        [-23, -51],
        [-45, -48],
        [-96, -10],
        [-30, 34],
        [-11, -24],
        [-9, -31],
        [-25, -11],
        [-39, -2],
        [-22, -21],
        [-12, -23],
        [-54, -8],
        [-20, -31],
        [-34, -11],
        [-140, -135],
        [-30, -57],
        [-29, -66],
        [-21, -34],
        [-18, -23],
        [-16, -10],
        [-17, -23],
        [-16, -3],
        [-17, 11],
        [-19, -4],
        [-12, -28],
        [10, -36],
        [-5, -16],
        [-37, -19],
        [-55, -13],
        [-23, -24],
        [-8, -14],
        [-12, -7],
        [-12, 47],
        [-4, 62],
        [23, 15],
        [20, 8],
        [116, 86],
        [-14, 64],
        [10, 28],
        [26, 45],
        [17, 23],
        [-10, 8],
        [-75, -15],
        [-44, 1],
        [-22, 5],
        [7, 39],
        [-4, 38],
        [-5, 15],
        [38, 44],
        [18, 11],
        [13, -1],
        [-1, 27],
        [-11, 40],
        [12, 52],
        [79, 61],
        [19, 55],
        [31, 51],
        [58, 128],
        [4, 22],
        [16, 60],
        [3, 24],
        [-26, 35],
        [-11, 49],
        [-78, 89],
        [-7, 76],
        [-7, -3],
        [-12, -53],
        [-10, -18],
        [-36, -1],
        [-18, 20],
        [-100, 13],
        [-25, -34],
        [-23, -53],
        [-22, -38],
        [-23, -20],
        [-19, -35],
        [-81, -206],
        [-31, -16],
        [-144, -124],
        [-72, -49],
        [-56, -87],
        [-19, -52],
        [-17, -58],
        [-10, -88],
        [-51, -107],
        [-18, -23],
        [-18, -9],
        [-23, 3],
        [-21, -7],
        [-35, 10],
        [-43, -34],
        [-48, -29],
        [-42, 73],
        [-30, 19],
        [-48, -21],
        [-23, -33],
        [-47, -162],
        [-17, -93],
        [1, -38],
        [27, -116],
        [31, -64],
        [69, -74],
        [147, -51],
        [34, 18],
        [37, 0],
        [39, -48],
        [24, -80],
        [3, -55],
        [0, -19],
        [9, -16],
        [5, -27],
        [-15, -23],
        [-12, -12],
        [-10, -84],
        [0, -94],
        [12, -31],
        [32, -44],
        [49, -36],
        [45, -8],
        [86, 17],
        [35, 57],
        [-2, 24],
        [1, 32],
        [76, 83],
        [43, 74],
        [-7, 19],
        [-8, 13],
        [8, 8],
        [23, 5],
        [106, 76],
        [83, -62],
        [47, -72],
        [47, -13],
        [33, -36],
        [37, -32],
        [49, -2],
        [41, -7],
        [13, 30],
        [13, 19],
        [14, -4],
        [17, -38],
        [47, -30],
        [43, 2],
        [30, 11],
        [18, -14],
        [-26, -49],
        [4, -79],
        [-20, -25],
        [-19, -40],
        [11, -26],
        [10, -12],
        [-1, -32],
        [-17, -18],
        [-32, -48],
        [-19, 1],
        [-9, 9],
        [-6, 18],
        [-4, 27],
        [-12, 18],
        [-31, 7],
        [-33, -6],
        [-73, -71],
        [-71, -57],
        [-75, -45],
        [-25, -28],
        [-18, -8],
        [-30, 22],
        [-19, -2],
        [-4, -14],
        [24, -40],
        [6, -31],
        [-3, -23],
        [-13, -11],
        [-20, 18],
        [-18, -25],
        [-8, -42],
        [0, -99],
        [-12, -22],
        [-33, -12],
        [-35, -32],
        [-13, 15],
        [-5, 17],
        [4, 46],
        [-4, 22],
        [-16, -1],
        [-25, -13],
        [-18, -31],
        [-6, -18],
        [24, -58],
        [23, -6],
        [6, -13],
        [-19, -29],
        [-45, -42],
        [-8, -36],
        [-13, -34],
        [-19, -27],
        [-13, -28],
        [-15, -15],
        [-25, -17],
        [-31, -68],
        [-23, -65],
        [-27, -32],
        [-21, -109],
        [-38, -58],
        [-14, -94],
        [10, -58],
        [41, 1],
        [21, -21],
        [44, -76],
        [52, -49],
        [53, -28],
        [66, -71],
        [19, -29],
        [15, -61],
        [29, -174],
        [20, -86],
        [2, -46],
        [31, -85],
        [33, -146],
        [37, -127],
        [8, -99],
        [-13, -46],
        [1, -59],
        [37, -55],
        [85, -63],
        [13, -18],
        [17, -31],
        [0, -95],
        [13, -31],
        [12, -18],
        [51, -41],
        [21, -33],
        [23, -54],
        [6, -48],
        [3, -65],
        [-29, -2],
        [-23, 7],
        [-91, 84],
        [-24, 3],
        [-33, -12],
        [-48, 16],
        [-51, 93],
        [-36, 28],
        [-39, 15],
        [-93, -81],
        [-24, 6],
        [-7, -9],
        [-11, -14],
        [44, -17],
        [43, 26],
        [42, 39],
        [60, -21],
        [10, -35],
        [10, -59],
        [42, -40],
        [33, -18],
        [41, -52],
        [41, -83],
        [86, -95],
        [35, -90],
        [13, -59],
        [12, -83],
        [-30, -27],
        [-26, -4],
        [-41, -13],
        [-30, -29],
        [-31, -52],
        [-86, -82],
        [-17, -51],
        [-11, -44],
        [-21, -22],
        [-54, 21],
        [-50, -2],
        [-56, -60],
        [-14, -24],
        [9, 4],
        [9, 8],
        [25, -9],
        [40, 32],
        [38, -98],
        [76, 16],
        [71, 82],
        [27, 1],
        [23, -13],
        [25, -32],
        [69, -142],
        [37, -16],
        [37, -33],
        [20, -4],
        [18, -10],
        [-49, -52],
        [-64, -113],
        [-28, -27],
        [-19, -30],
        [51, 15],
        [37, 54],
        [18, 13],
        [15, -12],
        [7, -67],
        [-14, -205],
        [-18, -3],
        [-17, 56],
        [-20, 17],
        [-18, -11],
        [-33, 0],
        [-13, -25],
        [-11, -36],
        [20, -7],
        [40, -62],
        [4, -33],
        [-11, -22],
        [-28, 9],
        [34, -46],
        [-9, -48],
        [-11, -19],
        [-20, -12],
        [-12, -42],
        [18, -69],
        [18, -89],
        [2, -43],
        [-28, 18],
        [-43, -54],
        [-23, -4],
        [-16, 71],
        [-19, -11],
        [-13, -21],
        [-18, -77],
        [-21, -69],
        [-19, -19],
        [-22, 5],
        [-18, -2],
        [5, -18],
        [19, -23],
        [0, -27],
        [-41, -84],
        [-7, -33],
        [1, -28],
        [-21, -34],
        [11, -57],
        [-6, -40],
        [-19, -54],
        [-19, -36],
        [-24, -58],
        [-29, -33],
        [-39, -122],
        [-11, -61],
        [-3, -63],
        [-13, -21],
        [-20, -28],
        [-24, 14],
        [-1, 42],
        [-10, 4],
        [-6, 27],
        [-2, 35],
        [3, 28],
        [-10, -8],
        [-6, -33],
        [-16, -27],
        [-16, 11],
        [-18, 22],
        [1, -32],
        [9, -31],
        [4, -32],
        [24, -6],
        [17, -37],
        [13, -55],
        [2, -21],
        [10, -25],
        [1, -21],
        [-23, -20],
        [-29, -36],
        [-35, -63],
        [-29, -42],
        [-26, 0],
        [-15, 5],
        [-23, 24],
        [-26, 10],
        [35, -85],
        [19, -15],
        [24, 3],
        [23, 32],
        [33, -3],
        [8, -49],
        [-9, -55],
        [-18, -72],
        [-3, -62],
        [22, -88],
        [1, -27],
        [-9, -13],
        [-26, 23],
        [-21, 29],
        [-22, -7],
        [-22, 12],
        [-23, -10],
        [-10, -21],
        [7, -34],
        [20, -28],
        [12, -43],
        [-14, -15],
        [-58, 10],
        [-14, -8],
        [-17, -47],
        [11, -71],
        [-13, -43],
        [-24, -11],
        [-32, -35],
        [-19, -8],
        [1, -15],
        [14, -16],
        [8, -21],
        [-18, -72],
        [-26, -23],
        [-42, 12],
        [-32, -17],
        [-28, 31],
        [-30, 1],
        [-20, -39],
        [-2, -45],
        [-20, -4],
        [-11, 3],
        [-15, -3],
        [2, -24],
        [8, -21],
        [41, -10],
        [7, -30],
        [2, -47],
        [-43, -80],
        [-18, -54],
        [-27, 1],
        [-20, -43],
        [-11, -60],
        [-14, 12],
        [-31, -9],
        [-10, -28],
        [8, -12],
        [1, -20],
        [-13, -67],
        [-14, -18],
        [-6, 27],
        [-4, 42],
        [-11, 3],
        [-18, -39],
        [-22, -28],
        [-18, -11],
        [-14, 26],
        [-34, 13],
        [-13, -112],
        [-29, -40],
        [-13, -13],
        [-23, -4],
        [15, -15],
        [5, -29],
        [-9, -28],
        [-23, -6],
        [-13, -22],
        [-5, -100],
        [-14, -35],
        [-35, -2],
        [-26, 23],
        [-8, -19],
        [-4, -17],
        [-14, -18],
        [-26, -5],
        [-59, -45],
        [-26, 13],
        [-32, 17],
        [-23, -17],
        [-7, -34],
        [-10, -26],
        [-32, 0],
        [-26, 32],
        [-26, 24],
        [-29, -20],
        [-22, -42],
        [-27, -14],
        [-5, -26],
        [-12, -13],
        [-29, 5],
        [-11, 65],
        [-16, 9],
        [-16, -32],
        [-6, -26],
        [-8, -19],
        [3, -53],
        [-16, -1],
        [-21, 32],
        [-23, 6],
        [-20, -30],
      ],
      [
        [81740, 64827],
        [-11, 8],
        [-11, 6],
        [-18, 0],
        [-7, -8],
        [-13, -5],
        [-9, -16],
        [-1, -2],
      ],
      [
        [81670, 64810],
        [-23, 11],
        [-29, 44],
        [-20, 73],
        [-26, 39],
        [-12, 35],
        [-4, 62],
        [-5, 29],
        [2, 33],
        [7, 29],
        [-28, -15],
        [-20, -27],
        [4, -34],
        [-5, -32],
        [-31, -16],
        [2, -14],
        [2, -14],
        [24, -43],
        [5, -36],
        [10, -20],
        [19, -56],
        [-1, -110],
        [11, -31],
        [-4, -30],
        [-7, -42],
        [-1, 0],
        [-6, 12],
      ],
      [
        [81534, 64657],
        [-9, -2],
        [-3, -14],
        [-1, -13],
      ],
      [
        [81521, 64628],
        [-1, -1],
        [-16, -9],
        [-14, -8],
        [-11, -11],
        [-17, -32],
        [-33, -8],
        [-16, 76],
        [-23, -51],
        [-6, -104],
        [-9, -18],
        [-14, -15],
        [-26, 37],
        [-23, -25],
        [-18, -25],
        [-8, -22],
        [-13, -25],
        [-25, 24],
        [-21, 36],
        [5, 27],
        [-2, 17],
        [-10, 14],
        [-10, -2],
        [5, -35],
        [4, -67],
        [-10, -19],
        [-14, -15],
        [-31, 12],
        [-21, 25],
        [-26, 21],
        [-22, 4],
        [-5, -42],
        [-15, -35],
        [-13, -4],
        [-14, 6],
        [-18, -37],
        [-8, -27],
        [-22, -29],
        [-58, -13],
        [-21, -29],
        [-27, 5],
        [-21, -7],
        [-13, 2],
        [-10, 15],
        [-13, 0],
        [-5, -47],
        [-33, -20],
        [-30, -5],
        [-33, -62],
        [-24, -37],
        [-17, -4],
        [-13, 13],
        [-7, 56],
        [-6, 6],
        [-4, -52],
        [-6, -43],
        [-12, -24],
        [-38, -54],
        [-11, -54],
        [7, -49],
        [51, -12],
        [7, -27],
        [-5, -22],
        [-13, -19],
        [-3, -28],
        [55, -88],
        [2, -34],
        [-9, -19],
        [-10, -41],
        [-29, -35],
        [-62, -18],
        [-51, 18],
        [-16, 40],
        [1, 28],
        [13, -8],
        [14, 3],
        [-4, 26],
        [-6, 15],
        [-24, 23],
        [-19, 62],
        [4, 51],
        [-11, 40],
        [-11, 34],
        [-12, 20],
        [-6, 25],
        [12, 78],
        [-7, 45],
        [22, 56],
        [6, 63],
        [39, 22],
        [3, 60],
        [-29, 2],
        [-19, 44],
        [-5, -18],
        [-15, -2],
        [-26, 84],
        [-8, 11],
        [-12, 2],
        [6, -90],
        [-30, -33],
        [-25, -15],
        [-35, -6],
        [-20, -10],
        [-18, 8],
        [4, 27],
        [10, 33],
        [-9, 27],
        [-20, 20],
        [-30, -1],
        [-21, 6],
        [-20, -2],
        [-8, 12],
        [-19, 42],
        [-17, 26],
        [-7, 26],
        [7, 31],
        [-6, 18],
        [-32, 2],
        [1, -43],
        [3, -52],
        [9, -39],
        [-6, -22],
        [-16, -15],
        [-17, 42],
        [-8, 10],
        [-9, -2],
        [-6, -41],
        [-15, -37],
        [-28, 4],
        [-22, -23],
        [-26, -10],
      ],
      [
        [80802, 63359],
        [13, -26],
        [9, -37],
        [8, -68],
        [4, -63],
        [-28, -40],
        [-25, -16],
        [-50, -154],
        [-11, -48],
        [-8, -21],
        [-3, -21],
        [1, -21],
        [-13, -74],
        [-12, -91],
        [-7, -37],
        [-14, -28],
        [-19, -15],
        [-11, -2],
        [-11, -8],
        [-27, -49],
        [-30, -38],
        [5, -17],
        [0, -16],
        [-13, -18],
        [-14, 3],
        [-42, -14],
        [-16, -28],
        [-16, -51],
        [-6, -7],
        [-25, -12],
        [-20, -5],
        [-33, 36],
        [-16, 11],
        [-44, 15],
        [-43, 24],
        [-30, 28],
        [-61, 69],
        [-7, 123],
        [-11, 67],
        [0, 24],
        [4, 206],
        [4, 22],
        [8, 20],
        [27, 46],
        [31, 37],
        [44, 76],
        [33, 35],
        [27, 50],
        [-16, -2],
        [-12, 6],
        [12, 43],
        [12, 23],
        [14, 12],
        [29, -9],
        [27, 9],
        [19, 38],
        [19, 8],
        [71, -12],
        [49, 17],
        [24, 35],
        [12, 2],
        [36, -10],
        [14, -37],
        [-1, 25],
        [1, 23],
        [7, -2],
        [47, -45],
        [0, 55],
        [3, 15],
        [15, 23],
        [7, -1],
        [19, -44],
        [17, -26],
        [23, -13],
      ],
      [
        [81534, 64657],
        [6, -13],
        [0, -1],
        [-13, -11],
        [-4, -3],
        [-2, -1],
      ],
      [
        [81740, 64827],
        [1, -2],
        [6, -22],
        [-2, -24],
        [11, -12],
        [3, -23],
        [-12, -13],
        [-1, -28],
        [-6, -17],
        [-36, 30],
        [-29, 16],
        [-27, -6],
        [-9, 18],
        [-2, 18],
        [31, 32],
        [2, 16],
      ],
      [
        [81730, 64637],
        [-7, -9],
        [-19, 42],
        [-1, 14],
        [15, 2],
        [16, -19],
        [0, -17],
        [-4, -13],
      ],
      [
        [81665, 64637],
        [-33, 0],
        [-7, 5],
        [-4, 13],
        [12, 22],
        [45, 30],
        [-11, -32],
        [-2, -38],
      ],
      [
        [19644, 36209],
        [-43, -18],
        [2, 32],
        [11, 27],
        [31, -16],
        [15, -3],
        [-16, -22],
      ],
      [
        [28110, 32461],
        [-50, -12],
        [-2, 3],
        [3, 10],
        [11, 18],
        [14, 21],
        [3, 1],
        [5, -2],
        [6, -4],
        [15, -14],
        [4, -10],
        [-1, -8],
        [-8, -3],
      ],
      [
        [30691, 41759],
        [5, -66],
        [38, -87],
        [12, -99],
        [9, -13],
        [52, -49],
        [1, -12],
        [-8, -18],
        [-7, -24],
        [5, -34],
        [9, -46],
        [4, -42],
        [5, -44],
        [6, -68],
        [4, -61],
        [13, -90],
        [3, -56],
        [0, -34],
        [10, -33],
        [20, -39],
        [28, -39],
        [22, -47],
        [16, -31],
        [21, -26],
        [15, -23],
        [6, -16],
        [2, -13],
        [-6, -13],
        [-25, -61],
        [-34, -92],
        [0, -12],
        [33, -66],
        [5, -27],
        [0, -37],
        [-11, -45],
        [-35, -14],
        [-8, -12],
        [-1, -15],
        [8, -19],
        [-1, -44],
        [13, -49],
        [-7, -16],
        [-13, -23],
        [0, -22],
        [4, -24],
        [14, -20],
        [54, -69],
        [4, -9],
        [-1, -7],
        [-21, -46],
        [-2, -28],
        [1, -47],
        [3, -30],
        [7, -12],
        [27, -14],
        [34, -105],
        [32, -98],
        [0, -85],
        [3, -98],
        [21, -78],
        [3, -62],
        [6, -70],
        [25, -43],
        [10, -85],
        [2, -45],
        [-1, -29],
        [19, -92],
        [2, -79],
        [-5, -57],
        [1, -32],
        [2, -22],
        [17, -20],
        [7, -13],
        [24, -5],
        [35, -2],
        [61, 21],
        [46, 20],
      ],
      [
        [31334, 38697],
        [52, -104],
        [-22, -140],
        [-36, -224],
        [-28, -174],
        [-5, -23],
        [-5, -34],
        [-60, -49],
        [-88, -71],
        [-44, -38],
        [-57, -48],
        [-13, -39],
        [-17, -22],
        [-18, -27],
        [-6, -30],
        [-17, -19],
        [-15, -68],
        [0, -52],
        [9, -35],
        [17, -15],
        [6, -43],
        [5, -30],
        [12, -23],
        [-3, -19],
        [-10, -14],
        [-18, -8],
        [-12, -43],
        [-15, -105],
        [-2, -38],
        [16, -96],
        [9, -51],
        [23, -187],
        [4, -51],
        [-32, -71],
        [-13, -43],
        [-5, -38],
        [0, -30],
        [3, -28],
        [27, -87],
        [31, -79],
        [15, -41],
        [0, -55],
        [-7, -31],
        [-17, -12],
        [-36, -21],
        [-16, -32],
        [-16, -5],
        [-16, 25],
        [-17, -6],
        [-21, -22],
        [-8, -53],
        [-19, -92],
        [-16, -25],
        [-12, -70],
        [-21, -100],
        [-10, -60],
        [-5, -44],
        [-22, -28],
        [-25, -56],
        [-19, -55],
        [-7, -15],
        [-15, -5],
        [-10, -49],
        [-36, -74],
        [-9, -85],
        [-13, -46],
        [-2, -82],
        [-20, -151],
        [-4, -33],
        [-20, -26],
        [-27, -58],
        [-8, -43],
        [12, -128],
        [15, -128],
        [1, -61],
        [-6, -82],
        [-4, -36],
        [10, -14],
        [17, -10],
        [6, -31],
        [-13, -22],
        [-5, -40],
        [-14, -44],
        [-40, -17],
        [-14, 16],
        [-5, -15],
        [2, -31],
        [-9, -37],
        [-21, -100],
        [-13, -90],
        [-8, -39],
        [3, -33],
        [7, -19],
        [0, -18],
        [-11, -21],
        [-11, -35],
        [-11, -5],
        [-12, 10],
        [-13, -21],
        [-3, -43],
        [-7, -54],
        [-3, -64],
        [-5, -81],
        [16, -56],
        [21, -101],
        [16, -25],
        [17, 2],
        [14, -20],
        [8, -24],
        [-11, -42],
        [-18, -7],
        [-2, -24],
        [6, -53],
        [6, -52],
        [18, -25],
        [7, -70],
        [17, -23],
        [-2, -89],
        [17, -105],
        [18, -30],
        [8, -14],
        [-5, -46],
        [-15, -36],
        [-3, -58],
        [6, -43],
        [18, -40],
        [14, -4],
        [20, 16],
        [21, -19],
        [3, -35],
        [3, -31],
        [-11, -41],
        [-12, -76],
        [-3, -75],
        [3, -114],
        [6, -89],
        [1, -56],
        [1, -25],
        [-7, -17],
        [-19, -9],
        [-16, -4],
        [-13, -14],
        [-3, -28],
        [-11, -47],
        [-11, -35],
        [-19, -51],
        [-12, -53],
        [-10, -35],
        [1, -24],
        [-7, -46],
        [-8, -39],
        [-15, -129],
        [-20, -27],
        [-17, -13],
        [-8, -18],
        [6, -35],
        [18, -10],
        [6, -29],
        [-3, -44],
        [12, -41],
        [-1, -50],
        [11, -93],
        [-10, -62],
        [3, -53],
        [0, -52],
        [-15, -41],
        [-29, -8],
        [-16, -38],
        [-28, -41],
        [-3, -33],
        [-5, -30],
        [-11, -11],
        [-18, 0],
        [-14, -5],
        [-20, -38],
        [-22, -21],
        [-5, -32],
        [2, -38],
        [-11, -23],
        [-15, -44],
        [-9, -48],
        [9, -44],
        [10, -78],
        [2, -34],
        [-13, -65],
        [-10, -42],
        [10, -53],
        [8, -30],
        [-8, -66],
        [-6, -41],
        [5, -76],
        [20, -85],
        [19, -75],
        [2, -88],
        [5, -70],
        [9, -76],
        [19, -29],
        [15, -26],
        [-3, -36],
        [-11, -44],
        [-15, -33],
        [-38, -11],
        [-30, -30],
        [-25, -21],
        [-19, -25],
        [-13, -26],
        [-7, -29],
        [5, -127],
        [-3, -47],
        [-13, -67],
        [-12, -53],
        [-5, -16],
        [-1, -24],
        [-2, -22],
        [-14, -5],
        [-18, 10],
        [-11, -6],
        [-8, -18],
        [7, -41],
        [7, -73],
        [9, -31],
        [-2, -24],
        [-4, -53],
        [-12, -42],
        [-17, 0],
        [-10, -18],
        [-5, -30],
        [5, -39],
        [22, -31],
        [7, -21],
        [-3, -27],
        [-17, -11],
        [-10, -22],
        [-9, -49],
        [-13, -55],
        [-13, -41],
        [-3, -56],
        [19, -60],
        [-2, -59],
        [-2, -171],
        [-1, -58],
        [5, -97],
        [-7, -26],
        [-4, -25],
        [19, -71],
        [21, -113],
        [5, -45],
        [-3, -31],
        [-28, -27],
        [-23, -11],
        [-13, 19],
        [-10, -8],
        [-10, -33],
        [-12, -27],
        [-5, -27],
        [13, -34],
        [7, -66],
        [-15, -29],
        [-10, -31],
        [4, -41],
        [4, -74],
        [-9, -123],
        [12, -44],
        [14, -21],
        [43, -25],
        [32, -12],
        [9, -41],
        [-4, -33],
        [-15, -16],
        [-24, -14],
        [0, -53],
        [20, -51],
        [23, -36],
        [5, -32],
        [-1, -34],
        [-16, -28],
        [22, -60],
        [10, -41],
        [-10, -32],
        [-15, -47],
        [-12, -23],
        [0, -26],
        [-5, -52],
        [-1, -51],
        [4, -31],
        [137, -24],
        [31, -9],
        [18, -31],
        [-3, -38],
        [-17, -40],
        [-11, -76],
        [-27, -13],
        [-27, 20],
        [-29, -7],
        [-26, -5],
        [-36, -2],
        [-48, -10],
        [-30, 11],
        [-2, -28],
        [8, -48],
        [64, -15],
        [60, -28],
        [18, -51],
        [24, -58],
        [25, -36],
        [1, -58],
        [-39, -61],
        [-5, -43],
        [-51, -13],
        [-15, -25],
        [-7, -84],
        [6, -66],
        [20, -23],
        [13, -43],
        [-15, -51],
        [-34, -35],
        [-19, -33],
        [12, -27],
        [15, -42],
        [5, -23],
        [8, -62],
        [10, -87],
        [-1, -42],
        [-9, -32],
        [-34, -49],
        [-24, -23],
        [-4, -61],
        [-2, -45],
        [2, -41],
        [15, -33],
        [-1, -33],
        [-20, -7],
        [-18, -16],
        [-17, -58],
        [-50, -60],
        [-17, -27],
        [1, -46],
        [-20, -65],
        [-16, -57],
        [-13, -53],
        [2, -56],
        [28, -24],
        [23, -54],
        [9, -69],
        [-17, -79],
        [-40, -30],
        [-23, -33],
        [-7, -25],
        [6, -83],
        [-2, -38],
        [-6, -37],
        [-10, -28],
        [-22, -31],
        [-38, -28],
        [-32, -19],
        [-14, -21],
        [-17, -48],
        [-15, -52],
        [3, -65],
        [-90, -8],
        [-6, -48],
        [-20, -38],
        [-6, -69],
        [20, -66],
        [9, -56],
        [-16, -67],
        [6, -69],
        [2, -54],
        [32, -61],
        [20, -76],
        [11, -63],
        [6, -50],
        [8, -30],
        [13, -34],
        [6, -39],
        [20, -13],
        [35, 37],
        [25, 25],
        [17, 9],
        [51, -6],
        [31, 23],
        [14, -3],
        [19, -13],
        [14, -27],
        [11, -62],
        [7, -69],
        [-9, -71],
        [-14, -16],
        [-5, -20],
        [5, -43],
        [16, -31],
        [-1, -43],
        [-17, -99],
        [-12, -41],
        [21, -46],
        [18, -40],
        [37, -31],
        [30, -43],
        [21, -35],
        [-5, -49],
        [14, -14],
        [56, -1],
        [84, -2],
        [131, -2],
        [128, -3],
        [145, -3],
        [69, -39],
        [62, -35],
        [79, 0],
        [78, -41],
        [58, -27],
        [35, -11],
        [36, -10],
        [5, -38],
      ],
      [
        [30988, 21683],
        [-157, 54],
        [-35, 30],
        [-30, 3],
        [-57, -36],
        [-32, -88],
        [-16, -25],
        [-40, -23],
        [-40, -5],
        [-134, -85],
        [-48, -7],
        [-33, -23],
        [-32, -32],
        [-12, -70],
        [5, -42],
        [-36, -152],
        [-9, -84],
        [0, -44],
        [10, -70],
        [-13, -120],
        [-25, -26],
        [-59, -34],
        [-41, 24],
        [-69, 22],
        [-50, 46],
        [-63, 33],
        [-21, 20],
        [-56, 92],
        [-6, 31],
        [-4, 39],
        [30, 56],
        [16, 4],
        [46, -2],
        [39, 9],
        [25, -30],
        [6, -65],
        [-10, -34],
        [-10, -22],
        [3, -16],
        [28, 23],
        [14, 145],
        [94, 72],
        [31, 42],
        [30, 66],
        [5, 18],
        [2, 25],
        [-20, 20],
        [-44, 27],
        [-142, -137],
        [-64, -36],
        [-42, -39],
        [-50, -71],
        [-9, -21],
        [-11, -46],
        [-5, -52],
        [-49, 24],
        [-76, 74],
        [-15, 28],
        [15, 38],
        [23, 31],
        [2, 107],
        [6, 38],
        [16, 30],
        [29, 33],
        [14, 7],
        [12, -14],
        [2, -25],
        [48, 2],
        [93, 94],
        [38, 3],
        [51, -22],
        [57, 13],
        [11, 10],
        [11, 22],
        [-42, 26],
        [-41, 13],
        [-115, 10],
        [-25, -10],
        [-34, -51],
        [-11, 13],
        [-7, 25],
        [-39, 18],
        [-19, -4],
        [-18, -24],
        [3, -37],
        [-10, -41],
        [-36, -39],
        [-25, -64],
        [1, -49],
        [-1, -26],
        [-9, -14],
        [-19, -16],
        [-60, 11],
        [-34, 52],
        [-13, 35],
        [-38, 39],
        [83, 47],
        [29, 27],
        [27, 61],
        [20, 39],
        [-14, 27],
        [-17, 0],
        [1, -43],
        [-18, -36],
        [-38, 17],
        [-57, -52],
        [-35, 14],
        [-56, -16],
        [-28, 28],
        [-6, 36],
        [10, 38],
        [-10, 63],
        [-16, 15],
        [-16, -4],
        [-7, 38],
        [-17, 66],
        [-8, 18],
        [-8, 31],
        [9, 7],
        [19, -8],
        [17, -20],
        [26, -3],
        [57, -43],
        [24, 10],
        [13, 10],
        [5, 36],
        [0, 34],
        [10, 0],
        [32, -43],
        [21, 4],
        [36, -12],
        [19, 5],
        [34, 16],
        [54, 48],
        [27, 49],
        [14, 7],
        [16, -6],
        [12, -15],
        [0, -34],
        [12, -32],
        [17, -26],
        [5, -31],
        [-3, -32],
        [-31, -43],
        [-6, -16],
        [11, -16],
        [12, 8],
        [18, 21],
        [10, 30],
        [2, 16],
        [1, 22],
        [-1, 27],
        [-25, 76],
        [-3, 18],
        [0, 35],
        [28, 32],
        [8, 25],
        [2, 48],
        [-15, 33],
        [-61, 77],
        [-101, 76],
        [-12, -8],
        [-8, -14],
        [10, -10],
        [13, -5],
        [90, -63],
        [24, -37],
        [15, -10],
        [19, -24],
        [-5, -36],
        [-91, -34],
        [-72, -76],
        [-55, -46],
        [-37, 17],
        [-18, 47],
        [-19, 60],
        [-28, 35],
        [-16, -4],
        [-13, 7],
        [-11, 18],
        [-21, -15],
        [-49, 42],
        [-13, 18],
        [35, 58],
        [39, -22],
        [9, 165],
        [-12, 37],
        [-51, 41],
        [-24, -5],
        [-34, 5],
        [-23, 19],
        [-26, 8],
        [-22, 11],
        [-29, 25],
        [-34, 14],
        [-47, 104],
        [-20, 55],
        [-11, 60],
        [72, 2],
        [42, 10],
        [10, 25],
        [-15, 47],
        [-20, 38],
        [15, 36],
        [21, 27],
        [22, -12],
        [56, -61],
        [9, -41],
        [39, -126],
        [10, -12],
        [4, -11],
        [82, -71],
        [11, 1],
        [-5, 59],
        [23, 81],
        [23, 26],
        [11, 0],
        [1, 13],
        [-22, 34],
        [11, 44],
        [-7, 1],
        [-19, -28],
        [-40, -140],
        [-24, -26],
        [-33, 64],
        [-18, 45],
        [-10, 17],
        [4, 71],
        [65, -14],
        [-22, 23],
        [-77, 42],
        [-19, 20],
        [-14, 7],
        [-25, 49],
        [-32, 41],
        [55, 74],
        [27, 52],
        [88, -27],
        [17, 16],
        [-15, 38],
        [-17, -12],
        [-27, 24],
        [-43, 72],
        [2, 36],
        [7, 67],
        [17, 14],
        [36, 14],
        [41, -22],
        [17, -17],
        [16, 8],
        [-16, 49],
        [-27, 19],
        [-29, 35],
        [3, 39],
        [9, 33],
        [8, 35],
        [5, 50],
        [-3, 39],
        [8, 17],
        [13, 8],
        [1, 15],
        [-26, -3],
        [-9, -49],
        [-3, -45],
        [-19, -35],
        [-7, -41],
        [-5, -48],
        [-10, -55],
        [-23, 21],
        [-13, 21],
        [-5, 15],
        [3, 28],
        [-6, 175],
        [-1, 147],
        [11, 114],
        [32, 45],
        [14, 13],
        [13, -6],
        [20, 1],
        [14, 16],
        [-45, 28],
        [-28, -16],
        [-20, -22],
        [-37, 17],
        [-7, 58],
        [-21, 51],
        [-4, 65],
        [2, 93],
        [51, -8],
        [42, -18],
        [110, 2],
        [90, -90],
        [40, 12],
        [-2, 18],
        [-30, 22],
        [-19, 51],
        [-11, 14],
        [-6, 31],
        [-1, 35],
        [-23, 129],
        [-9, -3],
        [-9, -44],
        [-18, -74],
        [-26, -36],
        [-40, -15],
        [-40, -8],
        [-34, 14],
        [-8, 31],
        [1, 35],
        [-15, 17],
        [-39, 16],
        [-11, 8],
        [-13, 32],
        [19, 49],
        [15, 29],
        [18, -6],
        [18, -13],
        [23, -38],
        [22, -7],
        [25, 30],
        [5, 21],
        [-16, 13],
        [-14, 5],
        [-23, 16],
        [-44, 58],
        [22, 59],
        [52, 68],
        [16, 16],
        [-14, 57],
        [16, 63],
        [-16, 51],
        [-29, 56],
        [-39, 12],
        [-8, -16],
        [-2, -23],
        [6, -17],
        [-3, -12],
        [-9, 0],
        [-50, 12],
        [-33, 37],
        [-54, 34],
        [-7, 26],
        [-6, 39],
        [19, 67],
        [-10, 1],
        [-35, -52],
        [-53, -27],
        [-40, -9],
        [-17, -20],
        [-7, -18],
        [11, -10],
        [22, -2],
        [17, -65],
        [-4, -25],
        [-8, -16],
        [-19, -4],
        [-38, 45],
        [-20, 51],
        [0, 40],
        [14, 54],
        [61, 74],
        [17, 31],
        [36, 34],
        [48, 78],
        [41, 43],
        [-20, 36],
        [-21, 53],
        [2, 75],
        [84, 30],
        [37, -13],
        [47, 3],
        [26, 18],
        [18, 3],
        [40, 21],
        [17, 29],
        [4, 22],
        [0, 20],
        [-4, 23],
        [-7, 62],
        [7, 21],
        [17, 24],
        [22, 8],
        [10, -2],
        [27, -22],
        [-6, -32],
        [-10, -39],
        [-22, -153],
        [-11, -35],
        [-17, -30],
        [12, -62],
        [-18, -44],
        [-76, -47],
        [-10, -2],
        [6, -17],
        [44, 4],
        [34, 10],
        [34, 39],
        [11, 60],
        [14, 117],
        [18, 17],
        [22, 3],
        [11, -27],
        [-4, -62],
        [0, -60],
        [-28, -176],
        [-35, -71],
        [-4, -19],
        [2, -22],
        [27, 3],
        [21, 38],
        [15, 49],
        [15, 68],
        [-2, 49],
        [5, 30],
        [6, 100],
        [11, 50],
        [-1, 71],
        [-19, 26],
        [-26, 16],
        [-7, 43],
        [14, 86],
        [50, -3],
        [48, 59],
        [31, 21],
        [18, -5],
        [62, -56],
        [12, -1],
        [-2, 21],
        [-9, 14],
        [-25, 19],
        [-45, 60],
        [-61, 10],
        [11, 78],
        [12, 72],
        [30, 9],
        [49, 24],
        [94, 107],
        [17, 81],
        [4, 91],
        [-45, 23],
        [-48, 60],
        [-39, 31],
        [-35, 40],
        [7, 59],
        [5, 97],
        [43, 21],
        [20, 132],
        [-29, 102],
        [7, 76],
        [38, 64],
        [6, 45],
        [11, 49],
        [33, 5],
        [1, 27],
        [-3, 49],
        [-22, 58],
        [-1, 80],
        [21, 94],
        [33, -6],
        [6, 4],
        [-23, 57],
        [-19, 63],
        [3, 25],
        [18, 21],
        [21, 12],
        [23, -32],
        [33, -103],
        [5, 27],
        [-13, 104],
        [-11, 130],
        [-35, -17],
        [-31, 9],
        [-12, 20],
        [-12, 30],
        [11, 35],
        [11, 27],
        [23, 33],
        [49, 12],
        [35, 42],
        [11, 87],
        [-11, -9],
        [-19, -76],
        [-32, -26],
        [-16, 4],
        [-19, 14],
        [-38, 67],
        [-21, 15],
        [-20, 2],
        [-17, -17],
        [-44, -117],
        [-19, -20],
        [-78, -9],
        [-28, 14],
        [-31, 17],
        [3, 29],
        [10, 31],
        [17, 16],
        [1, 17],
        [-24, 5],
        [-28, 32],
        [-13, 41],
        [-5, 73],
        [-25, 116],
        [-5, 83],
        [17, 59],
        [38, 232],
        [12, 119],
        [20, 104],
        [0, 68],
        [52, 63],
        [20, 38],
        [45, 211],
        [6, 114],
        [-71, 346],
        [-11, 66],
        [-3, 82],
        [17, 136],
        [2, 52],
        [-15, 75],
        [-40, 122],
        [-1, 62],
        [17, 64],
        [-16, 80],
        [8, 49],
        [9, 39],
        [63, -21],
        [29, 10],
        [15, 23],
        [12, 65],
        [6, 103],
        [4, 44],
        [5, 64],
        [31, 26],
        [11, 61],
        [26, 84],
        [26, 238],
        [27, 58],
        [27, 68],
        [-11, 100],
        [18, 46],
        [15, 34],
        [14, 61],
        [19, 58],
        [46, 83],
        [11, 101],
        [35, 176],
        [7, 112],
        [11, 76],
        [-3, 71],
        [21, 86],
        [20, 73],
        [7, 40],
        [46, 97],
        [8, 76],
        [-17, 52],
        [0, 81],
        [-13, 112],
        [30, 41],
        [12, 31],
        [39, 179],
        [-3, 70],
        [11, 87],
        [-25, 103],
        [-4, 231],
        [-14, 179],
        [-23, 188],
        [2, 105],
        [-15, 131],
        [0, 76],
        [11, 171],
        [74, 108],
        [15, 121],
        [9, 163],
        [-3, 119],
        [-8, 54],
        [-36, 87],
        [-10, 157],
        [7, 41],
        [31, 44],
        [21, 61],
        [11, 95],
        [23, 75],
        [9, 181],
        [18, 144],
        [10, 50],
        [30, 63],
        [5, 17],
        [5, 48],
        [-2, 113],
        [5, 70],
        [23, 136],
        [3, 63],
        [26, 141],
        [6, 101],
        [12, 53],
        [-5, 60],
        [8, 134],
        [-18, 76],
        [-4, 44],
        [22, 137],
        [16, 34],
        [24, 64],
        [11, 72],
        [2, 45],
        [-32, 227],
        [-4, 78],
        [7, 180],
        [11, 116],
        [-3, 93],
        [3, 48],
        [6, 60],
        [21, 72],
        [5, 52],
        [-7, 21],
        [-26, 27],
        [-21, 66],
        [-2, 65],
        [7, 47],
        [2, 67],
        [31, 13],
        [17, 37],
        [16, 70],
        [20, 169],
        [9, 209],
        [12, 125],
        [8, 63],
        [7, 130],
        [12, 85],
        [2, 78],
        [-2, 60],
        [-30, 304],
        [0, 112],
        [13, 174],
        [0, 244],
        [-2, 57],
        [-12, 54],
        [-3, 72],
        [-18, 127],
        [-17, 253],
        [0, 134],
        [-7, 114],
        [-16, 30],
      ],
      [
        [30936, 21513],
        [-1, -171],
        [0, -169],
        [-1, -157],
        [0, -158],
        [0, -152],
        [-1, -156],
        [-2, -175],
        [-2, -130],
      ],
      [
        [30929, 20245],
        [-41, 0],
        [-12, -13],
        [-66, -19],
        [-112, 29],
        [-28, 27],
        [-38, 58],
        [-13, -16],
        [-36, -25],
        [-36, -19],
        [-30, -2],
        [-28, 24],
        [-6, 12],
        [-6, 3],
        [-60, -34],
        [-66, 34],
        [-53, 21],
        [-84, 12],
        [-59, 43],
        [-109, -4],
        [-19, 14],
        [-7, 42],
        [6, 20],
        [23, 11],
        [6, 24],
        [24, -6],
        [30, -31],
        [9, 1],
        [20, 29],
        [30, 26],
        [11, 3],
        [54, -32],
        [22, 4],
        [32, 14],
        [5, 12],
        [5, 22],
        [9, 13],
        [28, 6],
        [27, -12],
        [3, -38],
        [-4, -41],
        [36, -11],
        [43, 1],
        [30, -15],
        [4, 25],
        [-48, 65],
        [-20, 40],
        [-26, 24],
        [-35, 12],
        [-28, 75],
        [1, 66],
        [-3, 64],
        [62, 36],
        [-14, 55],
        [21, 41],
        [25, 16],
        [24, -153],
        [18, -54],
        [-23, -11],
        [-47, 1],
        [27, -76],
        [43, -25],
        [37, -56],
        [1, -41],
        [20, -18],
        [50, -1],
        [34, 8],
        [16, 27],
        [19, 9],
        [33, -34],
        [57, -25],
        [15, -17],
        [11, -30],
        [0, -31],
        [3, -16],
        [17, 8],
        [23, 42],
        [12, 15],
        [14, 7],
        [8, 10],
        [1, 12],
        [-42, 31],
        [-220, 141],
        [-28, 56],
        [-18, 71],
        [1, 73],
        [16, 23],
        [39, 29],
        [72, 41],
        [84, 58],
        [10, 11],
        [-1, 37],
        [-10, 25],
        [-33, 18],
        [-35, 4],
        [-33, -1],
        [-33, -8],
        [-60, -39],
        [-34, 2],
        [-32, 21],
        [-24, 42],
        [-13, 57],
        [0, 36],
        [5, 33],
        [15, 35],
        [19, 14],
        [18, -2],
        [17, 8],
        [10, 12],
        [8, 16],
        [-3, 13],
        [-6, 12],
        [-27, 24],
        [-10, 23],
        [-23, 38],
        [12, 10],
        [41, 6],
        [28, -26],
        [26, -30],
        [16, 0],
        [15, 13],
        [33, 38],
        [28, 49],
        [25, 56],
        [21, 34],
        [23, 3],
        [69, -105],
        [24, -4],
        [80, 57],
        [9, -3],
        [28, -29],
        [8, -12],
      ],
      [
        [31366, 20072],
        [-8, -22],
        [-17, -29],
        [-24, -23],
        [-23, -6],
        [-16, 12],
        [-9, 20],
        [-4, 21],
        [-5, 11],
        [-9, 2],
        [-11, 0],
        [-14, -8],
        [-29, -29],
        [-13, -8],
        [-9, -2],
        [-84, 22],
        [-8, 9],
        [-10, 19],
        [-11, 59],
        [-35, 52],
        [54, 29],
        [65, 0],
        [125, -22],
        [49, -5],
        [39, -50],
        [6, -30],
        [1, -22],
      ],
      [
        [29507, 26874],
        [-21, -12],
        [-19, -3],
        [-20, 9],
        [-35, -1],
        [-34, 22],
        [-32, 32],
        [-9, 19],
        [3, 26],
        [24, 61],
        [22, 116],
        [15, 166],
        [-12, 63],
        [1, 26],
        [6, 31],
        [2, 33],
        [-1, 32],
        [3, 30],
        [24, 64],
        [4, 29],
        [0, 31],
        [11, 64],
        [-3, 21],
        [-9, 18],
        [7, 16],
        [85, -47],
        [56, -11],
        [3, -49],
        [11, -38],
        [7, -68],
        [8, -16],
        [-4, -49],
        [-26, -21],
        [2, -45],
        [15, -42],
        [-22, -16],
        [-23, -9],
        [-6, -11],
        [-17, -10],
        [-20, -23],
        [6, -21],
        [26, -48],
        [29, -33],
        [16, -49],
        [21, -51],
        [-10, -33],
        [-19, -46],
        [-31, -31],
        [-27, -19],
        [3, -76],
        [-10, -31],
      ],
      [
        [29312, 23532],
        [2, -85],
        [-4, -85],
        [-11, -104],
        [2, -21],
        [12, -5],
        [4, -13],
        [-4, -55],
        [-7, -42],
        [-13, -35],
        [-7, -41],
        [-7, -9],
        [-30, -7],
        [-17, 4],
        [-13, 47],
        [-4, 29],
        [1, 38],
        [-16, 51],
        [-1, 19],
        [6, 34],
        [14, 16],
        [2, 52],
        [6, 15],
        [15, 21],
        [2, 9],
        [-1, 8],
        [-5, 1],
        [-61, -64],
        [-5, -18],
        [-3, -24],
        [-1, -84],
        [-10, -49],
        [-10, -9],
        [-28, -2],
        [-37, 5],
        [-42, 44],
        [-27, -12],
        [-6, 54],
        [14, 44],
        [51, -4],
        [8, 77],
        [-16, 18],
        [-18, 32],
        [-10, 28],
        [9, 21],
        [30, 31],
        [15, 3],
        [15, -17],
        [36, 13],
        [-2, 49],
        [-32, 22],
        [7, 37],
        [41, 35],
        [24, 36],
        [2, 41],
        [-10, 40],
        [3, 16],
        [20, 34],
        [29, 16],
        [13, -2],
        [26, -24],
        [24, -3],
        [5, -7],
        [5, -26],
        [15, -193],
      ],
      [
        [29025, 23753],
        [-32, -1],
        [-7, 103],
        [36, 149],
        [3, 56],
        [-12, 43],
        [-5, 35],
        [3, 14],
        [47, 30],
        [14, -32],
        [18, -83],
        [33, -119],
        [-1, -114],
        [-18, -28],
        [-58, -29],
        [-21, -24],
      ],
      [
        [29337, 21357],
        [5, -5],
        [10, 1],
        [16, -9],
        [58, -11],
        [52, -27],
        [27, -26],
        [35, -7],
        [29, -33],
        [13, -8],
        [15, -2],
        [39, -60],
        [2, -7],
        [44, -47],
        [2, -7],
        [-25, -3],
        [-51, 22],
        [-25, 1],
        [-19, 7],
        [-4, 4],
        [-4, 27],
        [-5, 13],
        [-49, 63],
        [-21, 14],
        [-35, 12],
        [-40, -8],
        [-28, 8],
        [-9, -3],
        [-40, 50],
        [-40, 42],
        [-17, 50],
        [-25, 38],
        [-1, 11],
        [12, 9],
        [27, -22],
        [27, -37],
        [15, -14],
        [10, -36],
      ],
      [
        [29287, 23852],
        [-6, -14],
        [-34, 9],
        [-59, -15],
        [-25, 52],
        [-11, 83],
        [-8, 18],
        [-14, 47],
        [-8, 31],
        [-15, 48],
        [-6, 51],
        [-3, 16],
        [13, 30],
        [62, 29],
        [22, 48],
        [19, -6],
        [-5, -98],
        [11, -33],
        [22, -28],
        [3, -11],
        [4, -35],
        [11, -53],
        [13, -26],
        [4, -15],
        [0, -13],
        [-5, -19],
        [15, -96],
      ],
      [
        [29743, 21035],
        [8, -46],
        [4, -9],
        [20, 7],
        [34, 4],
        [57, -17],
        [6, -6],
        [24, -51],
        [19, -22],
        [28, -47],
        [-28, -32],
        [-17, -45],
        [-1, -23],
        [-11, -13],
        [-17, -14],
        [-26, -27],
        [-31, -3],
        [-31, -14],
        [-15, -12],
        [-8, -1],
        [-10, 7],
        [-11, 13],
        [-4, 15],
        [22, 14],
        [28, 50],
        [5, 52],
        [-30, 9],
        [-18, -7],
        [-14, 0],
        [-15, 16],
        [-9, -24],
        [-4, -24],
        [4, -36],
        [-3, -11],
        [-10, -7],
        [-26, 14],
        [-26, 24],
        [-2, 14],
        [6, 48],
        [-1, 25],
        [-5, 37],
        [-3, 3],
        [-10, 0],
        [-31, -7],
        [-31, 46],
        [-16, 49],
        [-57, 14],
        [44, 69],
        [67, 10],
        [22, -35],
        [74, -24],
        [-4, 37],
        [1, 13],
        [10, 16],
        [6, 2],
        [9, -12],
        [14, -5],
        [7, -11],
        [6, -23],
      ],
      [
        [29216, 22102],
        [11, -112],
        [9, -16],
        [28, -8],
        [31, -57],
        [1, -16],
        [-37, -96],
        [-8, -69],
        [-43, 5],
        [-19, 68],
        [-27, 66],
        [-10, 77],
        [-15, 66],
        [27, 38],
        [26, -8],
        [1, 50],
        [25, 12],
      ],
      [
        [30638, 20207],
        [223, -57],
        [68, 35],
        [55, -1],
        [16, -48],
        [-55, -49],
        [-5, -16],
        [8, -13],
        [57, -8],
        [14, -16],
        [13, -20],
        [-11, -31],
        [-1, -14],
        [6, -14],
        [42, -46],
        [18, -24],
        [9, -23],
        [3, -40],
        [-1, -32],
        [-9, -4],
        [-21, 10],
        [-20, 18],
        [-18, 46],
        [-12, 10],
        [-36, 9],
        [-35, 22],
        [-28, -1],
        [-25, 10],
        [-23, -9],
        [-8, 16],
        [-10, 30],
        [0, 14],
        [12, 48],
        [0, 13],
        [-7, 1],
        [-26, -10],
        [-11, 7],
        [-29, 35],
        [-11, 7],
        [-29, 3],
        [-17, -62],
        [-1, -16],
        [17, -39],
        [33, -61],
        [-17, -1],
        [-47, 19],
        [-13, 11],
        [-14, 31],
        [-28, 18],
        [-10, 11],
        [-3, 13],
        [-1, 40],
        [-6, 6],
        [-40, -11],
        [-8, 10],
        [-3, 17],
        [-6, 9],
        [-26, 16],
        [-2, 9],
        [11, 12],
        [7, 28],
        [10, 104],
        [51, -22],
      ],
      [
        [30169, 20718],
        [62, -46],
        [40, 0],
        [0, -28],
        [5, -49],
        [-6, -20],
        [-16, -20],
        [-9, -29],
        [-7, -5],
        [-45, 35],
        [-47, 48],
        [-24, -9],
        [-31, 12],
        [-25, -3],
        [-16, -27],
        [-36, -14],
        [-7, 54],
        [-33, 51],
        [-33, 41],
        [18, 63],
        [22, 10],
        [20, 21],
        [81, -22],
        [42, -19],
        [45, -44],
      ],
      [
        [29518, 26270],
        [-14, -25],
        [-22, -4],
        [-33, -29],
        [-4, -25],
        [-1, -30],
        [23, -37],
        [11, -43],
        [14, -64],
        [10, -61],
        [-1, -19],
        [2, -31],
        [17, -50],
        [1, -22],
        [-1, -22],
        [-7, -41],
        [-5, -5],
        [-17, -4],
        [-1, -25],
        [-4, -8],
        [-46, -3],
        [-23, 12],
        [2, 74],
        [-29, 30],
        [-20, 49],
        [-23, 85],
        [-19, 27],
        [-22, 67],
        [-33, 58],
        [38, 36],
        [-6, 64],
        [22, 22],
        [33, 23],
        [25, -18],
        [22, 6],
        [10, 16],
        [-3, 65],
        [7, 52],
        [25, 26],
        [26, 3],
        [10, -29],
        [13, -28],
        [32, -23],
        [-1, -29],
        [-8, -40],
      ],
      [
        [29726, 26048],
        [-68, -46],
        [-33, 15],
        [-13, 34],
        [-7, 29],
        [-7, 48],
        [12, 26],
        [24, 37],
        [10, 24],
        [4, 28],
        [-2, 27],
        [4, 25],
        [14, 9],
        [50, -28],
        [52, -43],
        [18, -29],
        [3, -23],
        [-22, -52],
        [-15, -42],
        [-24, -39],
      ],
      [
        [29155, 25984],
        [-7, -9],
        [-9, 3],
        [-7, 18],
        [-5, 31],
        [10, 12],
        [7, 0],
        [9, -17],
        [4, -26],
        [-2, -12],
      ],
      [
        [29082, 22649],
        [-7, -53],
        [-23, 5],
        [-7, 13],
        [-4, 34],
        [-7, 16],
        [9, 34],
        [7, 37],
        [-2, 29],
        [34, -2],
        [41, -7],
        [11, -8],
        [-12, -25],
        [-12, -16],
        [-25, -9],
        [-3, -48],
      ],
      [
        [29137, 23711],
        [-3, -46],
        [-41, -88],
        [-35, -52],
        [-32, -41],
        [-21, 0],
        [-17, 20],
        [19, 33],
        [24, 32],
        [-8, 42],
        [-7, 13],
        [-10, 4],
        [-17, 22],
        [5, 33],
        [10, 15],
        [14, 12],
        [12, -7],
        [54, 23],
        [17, 18],
        [32, 4],
        [4, -37],
      ],
      [
        [29135, 24286],
        [-20, -7],
        [-3, 19],
        [-18, 31],
        [16, 21],
        [31, 21],
        [24, -2],
        [22, -17],
        [3, -19],
        [-38, -25],
        [-9, -14],
        [-8, -8],
      ],
      [
        [29258, 26723],
        [-39, -10],
        [-9, 17],
        [0, 15],
        [7, 12],
        [20, 7],
        [13, -9],
        [7, -15],
        [2, -12],
        [-1, -5],
      ],
      [
        [29546, 26024],
        [-9, -7],
        [-8, 1],
        [-8, 20],
        [-3, 26],
        [-18, 39],
        [-5, 18],
        [0, 22],
        [10, 32],
        [16, 9],
        [10, -2],
        [13, -37],
        [3, -40],
        [4, -42],
        [-5, -39],
      ],
      [
        [29289, 22305],
        [-1, -48],
        [-8, -15],
        [-8, -5],
        [-20, 15],
        [-11, 1],
        [-18, -25],
        [-16, -13],
        [-23, 3],
        [-31, 17],
        [-27, -72],
        [-13, -25],
        [-27, -33],
        [-3, 39],
        [17, 60],
        [8, 40],
        [16, 60],
        [31, -22],
        [44, 22],
        [41, 41],
        [34, 1],
        [12, -23],
        [3, -18],
      ],
      [
        [29151, 22870],
        [-54, -46],
        [-16, 19],
        [-39, 0],
        [10, 50],
        [4, 37],
        [6, 14],
        [2, 32],
        [12, 58],
        [32, -19],
        [24, -6],
        [33, -19],
        [36, -12],
        [10, -51],
        [-34, -23],
        [-26, -34],
      ],
      [
        [29497, 26597],
        [6, -29],
        [-13, -4],
        [-19, 5],
        [-10, -23],
        [-5, -4],
        [-45, 20],
        [-7, 9],
        [1, 29],
        [48, 3],
        [31, 19],
        [4, -3],
        [9, -22],
      ],
      [
        [29405, 21928],
        [-9, -6],
        [-30, 14],
        [-16, 12],
        [-24, 30],
        [-3, 31],
        [-11, 38],
        [7, 0],
        [25, -15],
        [10, -11],
        [13, -24],
        [40, -34],
        [5, -10],
        [-1, -13],
        [-6, -12],
      ],
      [
        [29357, 25523],
        [-15, -26],
        [-27, -12],
        [-27, 20],
        [-32, -9],
        [-3, 43],
        [12, 36],
        [24, 43],
        [18, 58],
        [-2, 81],
        [14, 18],
        [8, 29],
        [31, 18],
        [7, -61],
        [-8, -107],
        [20, -64],
        [3, -21],
        [-4, -24],
        [-19, -22],
      ],
      [
        [31229, 19648],
        [-10, -1],
        [-25, 11],
        [-36, 4],
        [-5, 5],
        [0, 8],
        [4, 9],
        [20, 7],
        [60, -6],
        [8, -4],
        [2, -7],
        [-10, -19],
        [-8, -7],
      ],
      [
        [31535, 20029],
        [-22, -25],
        [-16, 1],
        [-6, 9],
        [-1, 12],
        [3, 12],
        [7, 11],
        [16, 14],
        [5, 2],
        [24, -14],
        [-10, -22],
      ],
      [
        [30280, 20237],
        [13, -37],
        [4, -6],
        [35, -15],
        [15, 9],
        [37, 4],
        [23, 14],
        [32, 7],
        [38, -90],
        [-4, -28],
        [-30, -30],
        [-20, -6],
        [-19, 9],
        [2, 15],
        [-4, 13],
        [-13, 17],
        [-12, -1],
        [-19, -13],
        [-10, 2],
        [-19, 14],
        [-35, 10],
        [-7, 13],
        [0, 19],
        [-7, 9],
        [-36, 31],
        [-23, 25],
        [-20, 4],
        [-7, -3],
        [-7, -13],
        [-17, -11],
        [-5, 2],
        [-6, 9],
        [-3, 14],
        [7, 29],
        [11, 3],
        [49, -6],
        [30, -13],
        [27, 0],
      ],
      [
        [31308, 19713],
        [-10, -4],
        [-7, 10],
        [-12, 8],
        [-46, 16],
        [-1, 10],
        [5, 14],
        [9, 12],
        [18, 13],
        [14, 32],
        [7, -3],
        [6, -13],
        [11, -44],
        [14, -32],
        [-2, -11],
        [-6, -8],
      ],
      [
        [56349, 58133],
        [-11, 4],
        [-25, 16],
        [-29, 13],
        [-36, 11],
        [-35, -26],
        [-37, -33],
        [-12, -25],
        [-10, -14],
        [-16, 2],
        [-15, -4],
        [-9, -24],
        [-13, -26],
        [-54, -54],
        [-11, -19],
        [-7, -20],
        [0, -21],
        [5, -44],
        [0, -55],
        [-12, -44],
        [-13, -30],
        [-16, -11],
        [-14, -6],
        [-8, -19],
        [-28, -100],
        [-12, -19],
        [-25, 3],
        [-71, -150],
        [-7, -45],
        [-25, -62],
        [-33, -70],
        [-29, -34],
        [-3, -13],
        [-8, -13],
        [-18, -16],
        [-62, -85],
        [-75, 4],
        [-33, -34],
        [-32, -15],
        [-47, -16],
        [-14, 2],
        [-61, -7],
        [-70, 2],
        [-27, -12],
        [-26, -32],
        [-19, -29],
        [-3, -9],
        [3, -12],
        [0, -9],
        [49, -70],
        [12, -34],
        [-12, -33],
        [-6, -5],
        [-1, -2],
        [-8, -26],
        [-29, -79],
        [-44, -93],
        [-22, -26],
        [-10, -17],
        [-11, -62],
        [-8, -9],
        [-30, -8],
        [-60, -6],
        [-83, -20],
        [-50, -7],
        [-31, 5],
        [-43, -42],
        [-16, -11],
        [-10, -4],
        [-43, -41],
        [-36, -64],
        [-12, -12],
        [-51, -27],
        [-20, -44],
        [-9, -4],
        [-32, 58],
        [-23, 53],
        [-10, 53],
        [-2, 17],
        [-6, -3],
        [-17, -23],
        [-16, -27],
        [-7, -51],
        [-52, -35],
        [-45, -30],
        [-20, -37],
        [-31, -18],
        [-40, 7],
        [-31, 16],
        [-31, 5],
      ],
      [
        [54299, 56177],
        [15, 46],
        [6, 35],
        [1, 42],
        [-2, 29],
        [-18, 14],
        [-12, 23],
        [-26, 133],
        [-27, 138],
        [-38, 135],
        [-41, 86],
        [-30, 53],
        [-9, 7],
        [-15, 16],
        [-11, 16],
        [-55, 92],
        [-56, 102],
        [-15, 47],
        [-28, 70],
        [-32, 72],
        [-16, 33],
        [-8, 59],
        [22, 54],
        [23, 67],
        [29, 45],
        [37, 3],
        [61, -18],
        [67, -7],
        [65, 14],
        [17, 10],
        [17, -1],
        [35, -15],
        [61, 3],
        [32, 27],
        [-34, 47],
        [-37, 74],
        [-34, 81],
        [-21, 73],
        [-19, 95],
        [-18, 116],
        [-11, 152],
        [2, 85],
        [6, 61],
        [18, 100],
        [-12, 58],
        [2, 47],
        [-1, 70],
        [-6, 36],
        [-24, 115],
        [-5, 13],
        [-21, 80],
        [-9, 134],
        [-24, 89],
        [-39, 43],
        [-21, 52],
        [-8, 91],
        [-15, 25],
        [-61, 32],
        [-50, 0],
      ],
      [
        [54495, 53150],
        [-13, 54],
        [-8, 63],
        [-4, 73],
        [2, 19],
        [-5, 22],
        [0, 4],
        [-7, 55],
        [7, 38],
        [-5, 40],
        [-16, 39],
        [-14, 30],
        [-8, 27],
        [-7, 12],
        [-15, 4],
        [-21, 14],
        [-27, 59],
        [-27, 57],
        [-34, 74],
        [-27, 63],
        [-33, 78],
        [-31, 72],
        [-19, 69],
        [-7, 40],
        [9, 4],
        [13, 1],
        [6, 7],
        [0, 19],
        [-14, 54],
        [-6, 70],
        [-12, 42],
        [-35, 66],
        [-35, 49],
        [-11, 26],
        [-6, 36],
        [-13, 231],
        [-6, 65],
        [-10, 29],
        [-8, 13],
        [-3, 16],
        [1, 41],
        [5, 37],
        [-1, 14],
        [10, 32],
        [0, 213],
        [-5, 11],
        [-6, 18],
        [-10, -1],
        [-11, 2],
        [-11, 31],
        [-9, 39],
        [3, 28],
        [9, 23],
        [11, 20],
        [13, 17],
        [39, 34],
        [11, 17],
        [7, 21],
        [4, 29],
        [23, 109],
        [33, 109],
        [15, 23],
        [14, 72],
        [20, 89],
        [8, 41],
        [6, 41],
        [10, 33],
        [37, 54],
        [28, 96],
      ],
      [
        [43008, 61604],
        [-27, -11],
        [-12, 5],
        [-3, 46],
        [-6, 30],
        [1, 14],
        [63, 59],
        [21, -10],
        [16, -47],
        [-11, -26],
        [-42, -60],
      ],
      [
        [43487, 60488],
        [-17, -53],
        [-36, 4],
        [-19, 22],
        [-22, 66],
        [0, 52],
        [8, 44],
        [-1, 39],
        [3, 10],
        [11, -6],
        [2, -26],
        [34, -64],
        [12, -13],
        [25, -75],
      ],
      [
        [43086, 61530],
        [-22, -13],
        [-14, 1],
        [-21, 21],
        [7, 22],
        [22, 24],
        [15, 5],
        [12, -43],
        [1, -17],
      ],
      [
        [43634, 61196],
        [23, -11],
        [8, 4],
        [15, -2],
        [16, -30],
        [3, -33],
        [-8, -40],
        [-30, -33],
        [-18, 4],
        [-21, 30],
        [12, 60],
        [0, 51],
      ],
      [
        [43247, 60400],
        [-21, -22],
        [-15, 10],
        [-15, 23],
        [-7, 33],
        [6, 28],
        [29, 33],
        [17, -11],
        [10, -51],
        [-4, -43],
      ],
      [
        [43309, 61418],
        [11, -17],
        [4, -12],
        [-17, -7],
        [-42, 22],
        [-11, -13],
        [-11, -48],
        [-21, 72],
        [2, 27],
        [4, 8],
        [30, -19],
        [51, -13],
      ],
      [
        [43642, 61439],
        [-9, -30],
        [-11, 44],
        [-6, 10],
        [-3, 62],
        [16, 19],
        [8, 1],
        [0, -64],
        [5, -42],
      ],
      [
        [43560, 60562],
        [-8, -2],
        [-11, 26],
        [2, 36],
        [-1, 9],
        [10, 39],
        [20, -4],
        [6, -28],
        [0, -59],
        [-18, -17],
      ],
      [
        [13151, 83022],
        [26, -34],
        [61, 22],
        [11, -4],
        [12, -13],
        [12, -28],
        [14, -42],
        [3, -46],
        [-5, -17],
        [-11, -18],
        [-98, -73],
        [-2, -7],
        [2, -7],
        [9, -7],
        [19, 1],
        [77, 18],
        [5, 13],
        [5, 57],
        [11, 31],
        [0, 23],
        [-7, 55],
        [1, 22],
        [54, 5],
        [33, 20],
        [35, 38],
        [8, -1],
        [-5, -68],
        [-5, -21],
        [-33, -84],
        [-19, -73],
        [-9, -73],
        [-2, -120],
        [-8, -41],
        [-15, -25],
        [-93, -43],
        [-48, 2],
        [-43, 40],
        [-20, 27],
        [15, 33],
        [10, 2],
        [30, -7],
        [24, -12],
        [10, 0],
        [-2, 7],
        [-66, 63],
        [-49, 28],
        [-14, 32],
        [-1, 24],
        [-4, 14],
        [-38, 86],
        [-8, 34],
        [-5, 48],
        [0, 49],
        [10, 80],
        [4, 8],
        [16, -1],
        [27, -9],
        [66, -8],
      ],
      [
        [13402, 82485],
        [28, -53],
        [8, -48],
        [-3, -56],
        [-45, -22],
        [-23, 17],
        [-11, -3],
        [-15, -17],
        [19, -7],
        [26, -28],
        [23, -36],
        [32, -6],
        [44, -25],
        [-33, -45],
        [-5, -26],
        [41, -72],
        [4, -18],
        [13, -4],
        [30, 6],
        [4, -6],
        [0, -15],
        [-19, -42],
        [2, -8],
        [17, -7],
        [32, 0],
        [8, -41],
        [-30, -38],
        [-55, 49],
        [-25, 48],
        [-14, 45],
        [-17, 25],
        [-52, 57],
        [-78, 121],
        [-21, 18],
        [-20, 48],
        [-6, 23],
        [1, 15],
        [7, 9],
        [24, 5],
        [0, 24],
        [-90, 42],
        [-10, 8],
        [-12, 30],
        [7, 4],
        [49, -5],
        [53, 14],
        [33, 11],
        [13, 13],
        [27, 17],
        [11, -1],
        [28, -20],
      ],
      [
        [14667, 81013],
        [138, -72],
        [138, -35],
        [102, -42],
        [62, -13],
        [22, -9],
        [15, -15],
        [17, -36],
        [29, -85],
        [23, -55],
        [46, -94],
        [37, -67],
        [8, -27],
        [-8, -8],
        [1, -16],
        [28, -65],
        [52, -59],
        [41, -28],
        [86, -45],
        [53, -45],
        [16, -30],
        [23, -30],
        [9, -21],
        [19, -76],
        [35, -73],
        [36, -139],
        [7, 11],
        [4, 42],
        [4, 9],
        [8, 5],
        [7, -17],
        [6, -36],
        [23, -87],
        [-7, -26],
        [-7, -2],
        [-31, 11],
        [-10, -15],
        [-15, -32],
        [-10, -13],
        [-6, 6],
        [-90, 31],
        [-55, 29],
        [-72, 45],
        [-87, 47],
        [-50, 33],
        [-41, 33],
        [-29, 29],
        [-5, 24],
        [1, 11],
        [56, 77],
        [23, 42],
        [9, 31],
        [5, 34],
        [-3, 41],
        [-3, -3],
        [-5, -40],
        [-8, -34],
        [-10, -28],
        [-6, -9],
        [-67, -14],
        [-54, 4],
        [-27, -33],
        [-8, -4],
        [-15, 11],
        [-33, 44],
        [-47, 36],
        [5, 9],
        [31, 19],
        [16, 26],
        [-3, 5],
        [-11, -2],
        [-10, 6],
        [-19, 34],
        [-10, 10],
        [-23, -16],
        [-10, -1],
        [-9, 23],
        [13, 53],
        [1, 13],
        [-24, -20],
        [-8, 7],
        [-7, 17],
        [-7, 7],
        [-19, -3],
        [-21, 15],
        [-7, -6],
        [-3, -23],
        [-7, -6],
        [-31, 39],
        [-8, 1],
        [-15, -29],
        [-5, -2],
        [-9, 13],
        [-4, 71],
        [2, 21],
        [4, 7],
        [28, 16],
        [79, 18],
        [7, 13],
        [-60, -7],
        [-15, 10],
        [-17, 24],
        [-17, 0],
        [-9, 8],
        [-10, 18],
        [-25, 64],
        [-17, 17],
        [-29, 10],
        [-15, 12],
        [-6, -5],
        [-6, -19],
        [-9, -11],
        [-19, -7],
        [-19, 5],
        [-14, 18],
        [-8, 22],
        [-4, 25],
        [8, 33],
        [0, 14],
        [-3, 15],
        [-7, 12],
        [-9, 10],
        [-5, -5],
        [-1, -20],
        [-5, -14],
        [-17, -11],
        [-13, 19],
        [-9, 27],
        [-11, 19],
        [-57, 0],
        [-27, -25],
        [-13, -2],
        [-13, 6],
        [-2, 13],
        [12, 35],
        [-3, 47],
        [-3, 12],
        [-27, 7],
        [-4, 12],
        [15, 57],
        [9, 11],
        [12, 4],
        [53, 4],
        [17, -8],
        [26, -34],
        [-1, 13],
        [-9, 39],
        [-2, 24],
        [18, 26],
        [-17, 8],
        [-63, 6],
        [1, -17],
        [5, -24],
        [-37, -21],
        [-28, -4],
        [-26, 4],
        [-21, 12],
        [-37, 51],
        [-23, 51],
        [1, 27],
        [13, 29],
        [16, 19],
        [39, 18],
        [51, 1],
        [57, -23],
        [143, -104],
      ],
      [
        [10833, 91964],
        [39, -9],
        [126, -19],
        [119, 11],
        [221, -61],
        [137, -114],
        [111, -57],
        [45, -38],
        [72, -34],
        [169, -75],
        [53, -7],
        [98, -36],
        [61, 5],
        [104, -9],
        [71, -28],
        [140, -79],
        [29, -7],
        [8, 6],
        [-49, 78],
        [-8, 7],
        [-56, 29],
        [-67, 14],
        [-5, 6],
        [-12, 27],
        [4, 11],
        [14, 5],
        [50, -1],
        [29, 5],
        [4, 11],
        [-21, 2],
        [-25, 11],
        [-30, 18],
        [-17, 17],
        [61, 115],
        [21, -11],
        [32, 26],
        [58, -17],
        [10, 10],
        [7, 58],
        [9, 14],
        [16, 11],
        [80, 10],
        [99, -10],
        [11, 5],
        [-10, 39],
        [-1, 16],
        [6, 35],
        [6, 18],
        [12, 10],
        [46, -8],
        [15, -17],
        [15, -30],
        [16, -17],
        [49, -17],
        [6, -11],
        [-19, -45],
        [-19, -24],
        [-41, -62],
        [-3, -16],
        [63, 28],
        [71, 38],
        [60, 22],
        [51, 4],
        [36, 12],
        [22, 21],
        [16, 22],
        [31, 70],
        [21, 12],
        [87, -4],
        [21, 2],
        [13, 7],
        [-2, 8],
        [-19, 11],
        [-25, 3],
        [0, 5],
        [9, 12],
        [14, 6],
        [42, 8],
        [29, -25],
        [19, -2],
        [63, 28],
        [98, 74],
        [39, 21],
        [34, 3],
        [29, -13],
        [22, 4],
        [29, 41],
        [12, 23],
        [17, 19],
        [73, 43],
        [46, 10],
        [29, -8],
        [33, -18],
        [28, -7],
        [37, 5],
        [27, -2],
        [13, 8],
        [47, 50],
        [15, 0],
        [14, -14],
        [24, -36],
        [0, -18],
        [-31, -44],
        [-225, -126],
        [-69, -54],
        [-34, -20],
        [-36, -11],
        [-69, -9],
        [-27, -11],
        [-46, -10],
        [-109, -17],
        [-21, -9],
        [-14, -10],
        [-39, -66],
        [-18, -22],
        [-38, -33],
        [-41, -20],
        [-58, -7],
        [-36, -31],
        [-42, -60],
        [-33, -42],
        [-39, -36],
        [-42, -46],
        [-11, -25],
        [13, -32],
        [7, -10],
        [42, -18],
        [16, 4],
        [-15, 17],
        [-35, 25],
        [-5, 9],
        [9, 7],
        [166, -19],
        [36, 19],
        [12, 16],
        [-2, 9],
        [-45, 3],
        [-10, 15],
        [-7, 29],
        [-2, 23],
        [4, 17],
        [10, 22],
        [49, 35],
        [52, 15],
        [40, 20],
        [22, 19],
        [60, 32],
        [23, 27],
        [13, 20],
        [2, 10],
        [-11, 7],
        [9, 18],
        [43, 16],
        [20, 1],
        [61, -15],
        [11, -12],
        [-6, -31],
        [8, 2],
        [24, 39],
        [13, 13],
        [14, 3],
        [13, -5],
        [14, -13],
        [8, -38],
        [1, -63],
        [3, -25],
        [16, 43],
        [11, 20],
        [59, 93],
        [40, 51],
        [46, 50],
        [65, 37],
        [152, 61],
        [85, 17],
        [43, 15],
        [21, 13],
        [14, 17],
        [24, 19],
        [4, -2],
        [-9, -39],
        [-6, -12],
        [-56, -24],
        [-5, -19],
        [6, -29],
        [9, -19],
        [13, -9],
        [23, 6],
        [33, 23],
        [41, 34],
        [89, 86],
        [8, 16],
        [22, 69],
        [51, 31],
        [92, 35],
        [22, 22],
        [-80, 19],
        [-17, 13],
        [-3, 8],
        [15, 19],
        [-36, 20],
        [-14, 12],
        [1, 36],
        [11, 25],
        [24, 24],
        [14, 5],
        [36, -15],
        [30, -18],
        [104, -85],
        [42, -42],
        [25, -33],
        [58, -102],
        [26, -59],
        [21, -61],
        [21, -44],
        [20, -27],
        [100, -107],
        [52, -45],
        [44, -28],
        [49, -22],
        [57, -16],
        [38, -2],
        [60, 46],
        [1, 29],
        [-26, 50],
        [-27, 35],
        [4, 21],
        [35, 40],
        [-3, 14],
        [8, 42],
        [24, -8],
        [10, 1],
        [13, 15],
        [17, 29],
        [22, 24],
        [27, 19],
        [7, 12],
        [-26, 9],
        [-16, 0],
        [-12, 4],
        [-8, 8],
        [11, 9],
        [57, 22],
        [11, 22],
        [19, 14],
        [23, 5],
        [15, -6],
        [16, -17],
        [1, -28],
        [-7, -46],
        [-2, -37],
        [18, -87],
        [16, -19],
        [63, -25],
        [-4, -22],
        [-72, -91],
        [-16, -23],
        [-7, -17],
        [2, -14],
        [13, -12],
        [24, -9],
        [63, -3],
        [18, 8],
        [122, 3],
        [22, 6],
        [19, 18],
        [28, 44],
        [31, 14],
        [10, 12],
        [19, 52],
        [10, 61],
        [9, 26],
        [14, 16],
        [19, 5],
        [48, -6],
        [22, 5],
        [88, -5],
        [88, 4],
        [92, -11],
        [58, -12],
        [54, -20],
        [104, -46],
        [41, -26],
        [145, -113],
        [43, -23],
        [79, -22],
        [274, -49],
        [34, -13],
        [72, -51],
        [50, -30],
        [59, -29],
        [73, -25],
        [144, -37],
        [24, -13],
        [26, -3],
        [30, 5],
        [132, -22],
        [35, 1],
        [25, -4],
        [31, -15],
        [45, -6],
        [-2, 11],
        [-51, 59],
        [2, 9],
        [21, 1],
        [64, -10],
        [15, 17],
        [21, -1],
        [49, -8],
        [53, -18],
        [56, -28],
        [68, -24],
        [104, -60],
        [57, -50],
        [55, -62],
        [29, -43],
        [6, -24],
        [11, -12],
        [17, 0],
        [7, -9],
        [-16, -53],
        [-9, -14],
        [-12, -9],
        [-49, -11],
        [-136, 13],
        [-25, -43],
        [-76, -37],
        [-13, -16],
        [-4, -11],
        [6, -38],
        [-10, -12],
        [-62, -43],
        [-2, -12],
        [40, -18],
        [43, -30],
        [34, -7],
        [43, 4],
        [54, -11],
        [65, -25],
        [45, -12],
        [25, 2],
        [35, -4],
        [44, -12],
        [59, -4],
        [129, 1],
        [39, -8],
        [54, -4],
        [105, 1],
        [19, 1],
        [33, 21],
        [22, 6],
        [38, 1],
        [108, 14],
        [38, 0],
        [34, 11],
        [45, 23],
        [27, 4],
        [10, -14],
        [19, -6],
        [27, 2],
        [52, 23],
        [120, 71],
        [43, 0],
        [32, 22],
        [8, 0],
        [8, -9],
        [30, -52],
        [9, -8],
        [20, -4],
        [20, -27],
        [20, -39],
        [16, -11],
        [113, -2],
        [39, -11],
        [12, -11],
        [13, -31],
        [7, -60],
        [5, -22],
        [16, -33],
        [11, -9],
        [10, 9],
        [28, 84],
        [10, 13],
        [18, -4],
        [6, -5],
        [28, -62],
        [40, -46],
        [100, -84],
        [16, -31],
        [6, -23],
        [-6, -21],
        [-17, -18],
        [-27, -15],
        [-37, -11],
        [-34, 4],
        [-32, 19],
        [-10, 1],
        [11, -17],
        [66, -70],
        [17, -28],
        [16, -18],
        [14, -10],
        [13, -15],
        [12, -21],
        [55, -56],
        [16, -27],
        [62, -84],
        [30, -33],
        [23, -19],
        [8, -1],
        [-5, 15],
        [-79, 111],
        [-41, 70],
        [-5, 17],
        [-3, 26],
        [-2, 85],
        [6, 13],
        [28, 11],
        [35, -39],
        [13, -5],
        [9, 2],
        [5, 10],
        [20, -9],
        [36, -29],
        [12, 0],
        [-27, 54],
        [-19, 27],
        [-7, 19],
        [18, 28],
        [-10, 14],
        [-45, 41],
        [-24, 42],
        [-21, 65],
        [-2, 25],
        [3, 26],
        [-3, 22],
        [-29, 43],
        [-31, 30],
        [-24, 35],
        [-5, 19],
        [3, 50],
        [19, 21],
        [36, 29],
        [9, 30],
        [-16, 31],
        [-3, 14],
        [10, -2],
        [70, 15],
        [18, -5],
        [26, 6],
        [35, 19],
        [28, 9],
        [36, -2],
        [20, 5],
        [24, 8],
        [13, 9],
        [22, 36],
        [12, 6],
        [37, -4],
        [21, -8],
        [9, 3],
        [-1, 50],
        [7, 18],
        [37, 37],
        [39, 4],
        [25, 10],
        [30, 20],
        [21, 19],
        [21, 29],
        [9, 38],
        [-7, 11],
        [-44, 15],
        [-26, -8],
        [-59, -27],
        [-61, -34],
        [-24, -33],
        [-6, -41],
        [-12, -18],
        [-47, 17],
        [-21, 0],
        [-25, -7],
        [-27, -16],
        [-29, -25],
        [-42, -4],
        [-56, 15],
        [-33, 5],
        [-34, -26],
        [2, -20],
        [15, -28],
        [-15, -17],
        [-82, -6],
        [-21, 4],
        [-44, -12],
        [-17, 3],
        [-12, 13],
        [-89, 58],
        [-9, 11],
        [22, 47],
        [82, 126],
        [9, 8],
        [152, 22],
        [91, 23],
        [169, 69],
        [32, 6],
        [108, 46],
        [45, 12],
        [41, -8],
        [60, -24],
        [32, -21],
        [22, -26],
        [18, -37],
        [23, -81],
        [8, -69],
        [14, -26],
        [51, -48],
        [26, -18],
        [16, -6],
        [14, 11],
        [9, 2],
        [7, -4],
        [7, -31],
        [9, -3],
        [30, 4],
        [32, -12],
        [5, -10],
        [-7, -37],
        [9, -16],
        [40, -34],
        [37, -12],
        [44, -6],
        [81, 6],
        [68, 16],
        [51, 26],
        [42, -29],
        [84, -71],
        [50, -51],
        [41, -23],
        [84, -29],
        [19, -15],
        [31, -2],
        [43, 12],
        [48, -5],
        [54, -21],
        [37, -9],
        [127, 40],
        [20, 2],
        [47, 19],
        [31, 6],
        [36, 0],
        [27, 5],
        [17, 13],
        [68, -1],
        [122, -14],
        [84, -17],
        [49, -23],
        [41, -12],
        [32, -3],
        [31, 4],
        [30, 11],
        [32, 20],
        [68, 11],
        [11, 5],
        [-1, 11],
        [-14, 17],
        [-39, 32],
        [-27, 31],
        [-5, 20],
        [1, 24],
        [8, 14],
        [16, 4],
        [26, -15],
        [34, -34],
        [98, -126],
        [24, -17],
        [13, -17],
        [90, -45],
        [42, -9],
        [50, 29],
        [22, 17],
        [11, 17],
        [0, 17],
        [4, 24],
        [-5, 14],
        [-13, 20],
        [-36, 26],
        [-59, 34],
        [-53, 10],
        [-48, -14],
        [-55, -29],
        [-23, 12],
        [-68, 81],
        [-17, 31],
        [0, 8],
        [31, -9],
        [2, 9],
        [-19, 40],
        [-12, 13],
        [-39, 62],
        [-5, 18],
        [24, 5],
        [11, 7],
        [15, -1],
        [70, -37],
        [35, 17],
        [83, 23],
        [-33, 36],
        [-7, 35],
        [3, 8],
        [27, 5],
        [53, -29],
        [25, -4],
        [19, 11],
        [19, 1],
        [21, -10],
        [19, -15],
        [37, -43],
        [17, -26],
        [20, -39],
        [7, -6],
        [97, -3],
        [54, 35],
        [-1, -12],
        [-12, -27],
        [-68, -105],
        [1, -13],
        [36, 6],
        [17, 8],
        [10, 13],
        [9, 29],
        [6, 9],
        [101, 49],
        [29, 8],
        [-18, -53],
        [-37, -188],
        [-8, -65],
        [-8, -23],
        [-40, -72],
        [1, -25],
        [43, -61],
        [8, -17],
        [4, -50],
        [8, -9],
        [36, -1],
        [37, 16],
        [44, 11],
        [7, -11],
        [-24, -60],
        [1, -6],
        [42, 16],
        [19, 2],
        [8, -3],
        [31, -31],
        [3, -23],
        [0, -35],
        [-3, -24],
        [-11, -14],
        [-13, -6],
        [-16, -4],
        [-14, 2],
        [-44, -5],
        [-26, 6],
        [-25, 19],
        [-18, 5],
        [-21, -15],
        [-34, 3],
        [-37, 41],
        [-15, -4],
        [-5, -6],
        [0, -9],
        [17, -27],
        [132, -143],
        [20, -29],
        [4, -43],
        [3, 0],
        [12, 43],
        [-8, 20],
        [-67, 83],
        [-9, 31],
        [3, 8],
        [18, 9],
        [96, -21],
        [38, 4],
        [25, 15],
        [13, 18],
        [9, 99],
        [17, 63],
        [-10, 57],
        [-26, 90],
        [-21, 54],
        [-47, 53],
        [-5, 20],
        [53, 163],
        [9, 14],
        [12, 6],
        [42, 2],
        [30, 14],
        [47, -19],
        [26, -5],
        [33, 17],
        [72, 69],
        [28, 21],
        [36, 40],
        [43, 59],
        [48, 43],
        [76, 40],
        [47, 32],
        [10, 12],
        [-44, 2],
        [-10, 6],
        [-9, 30],
        [4, 57],
        [-1, 31],
        [-5, 28],
        [-9, 25],
        [-14, 23],
        [-13, 13],
        [-11, 4],
        [-8, -2],
        [-4, -8],
        [-12, -54],
        [-15, -39],
        [-20, -21],
        [-43, -14],
        [-72, -10],
        [-30, 19],
        [-4, 16],
        [10, 62],
        [25, 27],
        [66, 53],
        [42, 42],
        [1, 7],
        [-39, 1],
        [-9, 8],
        [-9, 52],
        [3, 20],
        [6, 22],
        [27, 16],
        [84, 20],
        [65, 23],
        [2, -8],
        [-52, -70],
        [-5, -16],
        [20, -16],
        [50, 41],
        [32, 34],
        [6, 11],
        [-30, 4],
        [-1, 14],
        [5, 26],
        [-2, 17],
        [-33, 22],
        [-40, -12],
        [-34, -23],
        [-28, -7],
        [-41, 0],
        [-30, 5],
        [-18, 11],
        [-23, 25],
        [-26, 38],
        [-33, 38],
        [-12, 4],
        [-10, -4],
        [-22, -37],
        [-9, -4],
        [-130, 52],
        [-56, 28],
        [-26, 22],
        [-34, 13],
        [-39, 4],
        [-32, 11],
        [-24, 16],
        [-19, 23],
        [-15, 31],
        [-27, 38],
        [-62, 77],
        [-16, 49],
        [-2, 19],
        [4, 49],
        [58, 82],
        [10, 24],
        [20, 17],
        [29, 11],
        [21, 4],
        [47, -11],
        [-28, 26],
        [-3, 13],
        [29, 45],
        [-6, 2],
        [-78, -34],
        [-20, 2],
        [-28, 21],
        [-52, 75],
        [-1, 47],
        [17, 65],
        [5, 38],
        [-15, 33],
        [6, 10],
        [16, 9],
        [7, 10],
        [-7, 36],
        [11, 20],
        [38, 38],
        [36, 33],
        [22, 10],
        [19, -2],
        [19, -10],
        [21, -19],
        [34, -19],
        [26, -5],
        [19, 10],
        [33, 71],
        [11, 18],
        [-11, 8],
        [-63, 0],
        [-28, 5],
        [-16, 7],
        [-11, 27],
        [9, 15],
        [62, 50],
        [29, 53],
        [86, 73],
        [87, 34],
        [43, 11],
        [34, 3],
        [15, -5],
        [18, -36],
        [4, -39],
        [47, -48],
        [38, -4],
        [24, 7],
        [76, -2],
        [18, -14],
        [-1, -15],
        [-9, -24],
        [6, -21],
        [51, -40],
        [47, -28],
        [42, -34],
        [63, -72],
        [13, -21],
        [10, -22],
        [16, -80],
        [2, -31],
        [-6, -88],
        [-5, -17],
        [-16, -20],
        [5, -8],
        [50, -23],
        [39, -46],
        [21, -15],
        [49, -24],
        [9, -9],
        [12, -16],
        [28, -66],
        [46, -59],
        [3, -13],
        [-10, -28],
        [7, -9],
        [18, -10],
        [16, 5],
        [14, 21],
        [15, 6],
        [17, -9],
        [12, -16],
        [17, -38],
        [25, -31],
        [-2, -10],
        [-12, -8],
        [-68, -8],
        [-37, 6],
        [-35, 15],
        [-25, 16],
        [-22, 24],
        [-9, -2],
        [-12, -20],
        [-25, -29],
        [-16, -27],
        [18, -12],
        [89, 1],
        [19, -8],
        [23, -19],
        [-26, -31],
        [-60, -53],
        [-129, -103],
        [-38, -26],
        [9, -8],
        [14, -2],
        [45, 5],
        [41, 14],
        [51, -6],
        [22, -12],
        [-8, -11],
        [14, -17],
        [83, -42],
        [52, 8],
        [53, 40],
        [41, 20],
        [51, -2],
        [14, -5],
        [-5, -10],
        [-38, -22],
        [-34, -25],
        [-3, -7],
        [42, 10],
        [94, -16],
        [45, -4],
        [34, 4],
        [31, -6],
        [29, -17],
        [9, -11],
        [-27, -6],
        [-25, 0],
        [-22, -11],
        [-18, -20],
        [-13, -26],
        [-8, -33],
        [-19, -13],
        [-31, 6],
        [-13, 9],
        [7, 13],
        [-9, 2],
        [-25, -10],
        [-20, 0],
        [-5, -9],
        [137, -104],
        [44, -91],
        [30, -38],
        [3, -10],
        [-20, -26],
        [-1, -19],
        [9, -56],
        [-4, -45],
        [-14, -78],
        [13, -25],
        [29, -22],
        [18, -27],
        [12, -10],
        [9, -22],
        [10, -14],
        [12, -6],
        [8, 8],
        [5, 20],
        [11, 19],
        [31, 34],
        [30, 54],
        [5, 17],
        [-5, 42],
        [4, 18],
        [28, 64],
        [9, 44],
        [8, 69],
        [15, 48],
        [32, 40],
        [56, 82],
        [20, 16],
        [22, 8],
        [40, -2],
        [28, -26],
        [39, -49],
        [49, -44],
        [90, -60],
        [25, -23],
        [51, -59],
        [21, -59],
        [15, -84],
        [13, -50],
        [10, -17],
        [5, -25],
        [-1, -34],
        [-4, -26],
        [-7, -19],
        [-11, -11],
        [-27, -4],
        [-34, 6],
        [-9, 8],
        [-18, 40],
        [-7, 2],
        [-31, -29],
        [-4, -16],
        [12, -54],
        [-2, -102],
        [3, -23],
        [33, -106],
        [55, -81],
        [138, -155],
        [8, -18],
        [15, -64],
        [7, -13],
        [9, -8],
        [11, -2],
        [15, 6],
        [51, 48],
        [44, 51],
        [31, 27],
        [18, 2],
        [18, 9],
        [21, 17],
        [13, 18],
        [7, 19],
        [9, 77],
        [8, 37],
        [21, 50],
        [8, 14],
        [107, 127],
        [9, 16],
        [45, 147],
        [17, 67],
        [2, 41],
        [-6, 36],
        [3, 30],
        [12, 24],
        [13, 16],
        [22, 17],
        [13, 24],
        [7, 2],
        [19, 0],
        [24, -16],
        [17, -3],
        [113, 19],
        [0, 9],
        [-66, 31],
        [1, 16],
        [5, 21],
        [21, 24],
        [26, 7],
        [6, 15],
        [0, 19],
        [9, 31],
        [-8, 11],
        [-62, 42],
        [-36, -2],
        [-9, 5],
        [-31, 35],
        [-11, 50],
        [-1, 20],
        [4, 33],
        [4, 9],
        [-2, 15],
        [-8, 20],
        [0, 19],
        [6, 16],
        [-4, 19],
        [-14, 22],
        [-6, 18],
        [15, 56],
        [1, 17],
        [-15, 24],
        [-10, 9],
        [8, 6],
        [25, 2],
        [31, -7],
        [36, -18],
        [43, 0],
        [52, 17],
        [52, 9],
        [91, -4],
        [21, -5],
        [90, -52],
        [70, -26],
        [32, 3],
        [156, -11],
        [68, 6],
        [35, -3],
        [68, -28],
        [-4, -24],
        [-30, -39],
        [-38, -8],
        [-34, -14],
        [32, -21],
        [92, -28],
        [22, -44],
        [6, -21],
        [-11, -18],
        [6, -10],
        [21, 0],
        [55, 16],
        [61, -10],
        [89, -35],
        [10, -7],
        [15, -27],
        [-2, -11],
        [-78, -67],
        [-41, -27],
        [-54, -27],
        [-1, -15],
        [75, -3],
        [58, -7],
        [27, -9],
        [14, -13],
        [19, -27],
        [3, -22],
        [-2, -29],
        [-6, -20],
        [-69, -59],
        [-31, -18],
        [-53, -21],
        [-23, -17],
        [-26, 3],
        [-28, 20],
        [-28, 5],
        [-52, -17],
        [-29, 1],
        [-13, -6],
        [-2, -13],
        [25, -37],
        [14, -14],
        [6, -11],
        [-11, -18],
        [3, -6],
        [8, -7],
        [47, -80],
        [10, -6],
        [10, 3],
        [20, 23],
        [13, 9],
        [5, -1],
        [0, -12],
        [-22, -70],
        [-2, -19],
        [0, -17],
        [11, -35],
        [23, -38],
        [30, -35],
        [45, -46],
        [60, -49],
        [22, -24],
        [33, -56],
        [7, -22],
        [-9, -59],
        [-24, -97],
        [-15, -55],
        [-7, -14],
        [-45, -39],
        [-26, -9],
        [-42, 1],
        [-14, -8],
        [-22, -31],
        [-30, -55],
        [-24, -35],
        [-17, -14],
        [-32, -17],
        [-50, -51],
        [-24, -20],
        [-86, -21],
        [-69, -68],
        [-28, -23],
        [-30, -12],
        [-32, -2],
        [-19, 11],
        [-10, 42],
        [-7, 14],
        [-24, 29],
        [-50, 83],
        [-22, 29],
        [-14, 6],
        [-30, -5],
        [-14, 3],
        [-33, 26],
        [-11, 17],
        [1, 7],
        [25, 9],
        [-11, 14],
        [-43, 37],
        [-18, 20],
        [-2, 6],
        [-43, 26],
        [-43, 7],
        [-53, -42],
        [-21, -29],
        [1, -9],
        [26, -11],
        [11, 6],
        [22, 26],
        [11, 8],
        [33, -4],
        [28, -18],
        [10, -16],
        [4, -11],
        [75, -83],
        [26, -17],
        [12, -20],
        [8, -33],
        [16, -37],
        [36, -61],
        [39, -75],
        [8, -30],
        [-20, -14],
        [-10, -1],
        [-30, 11],
        [-78, 35],
        [-9, -1],
        [-20, -18],
        [-17, -42],
        [-5, -4],
        [-42, 17],
        [-78, 36],
        [-52, 31],
        [-27, 26],
        [-32, 41],
        [-37, 56],
        [-45, 17],
        [-52, -20],
        [-76, -7],
        [-158, 6],
        [-21, -5],
        [-8, -7],
        [14, -30],
        [-4, -9],
        [-10, -6],
        [-3, -9],
        [18, -32],
        [27, -23],
        [79, -30],
        [52, -25],
        [32, -22],
        [10, -19],
        [1, -20],
        [-15, -41],
        [-8, -15],
        [-183, -203],
        [-70, -82],
        [-35, -50],
        [-32, -33],
        [-27, -17],
        [-45, -9],
        [-62, -2],
        [-81, 8],
        [-42, 26],
        [-76, 70],
        [-54, 41],
        [-23, 14],
        [-20, 39],
        [-18, 7],
        [-39, 7],
        [-39, 21],
        [-92, 70],
        [-48, 28],
        [-44, 15],
        [-39, 3],
        [-15, -4],
        [27, -36],
        [-12, -2],
        [-31, 8],
        [-32, 0],
        [-55, 26],
        [-55, -4],
        [-39, 5],
        [-48, 14],
        [-51, 7],
        [-82, 0],
        [-29, -3],
        [-5, -7],
        [40, -31],
        [68, -37],
        [-9, 32],
        [2, 8],
        [23, 11],
        [107, -19],
        [122, -42],
        [31, -4],
        [34, -15],
        [38, -25],
        [52, -51],
        [99, -116],
        [32, -29],
        [43, -27],
        [215, -40],
        [75, 0],
        [149, -11],
        [79, -21],
        [22, -15],
        [7, -51],
        [-7, -26],
        [-43, -79],
        [-27, -58],
        [-168, -245],
        [-22, -57],
        [-9, -34],
        [-30, -34],
        [-76, -55],
        [-76, -46],
        [-46, -10],
        [-41, 11],
        [-26, 14],
        [-39, 45],
        [-3, -4],
        [29, -72],
        [-7, -7],
        [-23, 9],
        [-53, 32],
        [-17, -6],
        [-10, -9],
        [-14, 0],
        [-18, 10],
        [-32, 26],
        [-9, 13],
        [-7, 40],
        [-6, 7],
        [-64, -23],
        [-10, -7],
        [26, -16],
        [9, -12],
        [25, -58],
        [2, -13],
        [-19, -8],
        [-61, 23],
        [-7, -2],
        [30, -59],
        [12, -27],
        [1, -15],
        [-39, -66],
        [-25, -27],
        [-35, -10],
        [-21, 6],
        [-24, 17],
        [-18, -2],
        [-11, -22],
        [-20, -15],
        [-29, -9],
        [-37, 4],
        [-45, 17],
        [-120, 62],
        [-37, 9],
        [-70, 9],
        [-9, 9],
        [1, 8],
        [10, 8],
        [-3, 7],
        [-15, 6],
        [-15, -6],
        [-16, -16],
        [-28, -5],
        [-40, 7],
        [-59, 24],
        [-116, 62],
        [-128, 53],
        [-74, 68],
        [28, -62],
        [-3, -20],
        [-14, -19],
        [-2, -18],
        [29, -44],
        [40, -15],
        [40, 1],
        [1, 7],
        [-16, 11],
        [-15, 16],
        [-7, 24],
        [7, 4],
        [36, -12],
        [23, -15],
        [177, -81],
        [53, -15],
        [40, -15],
        [11, -10],
        [-14, -20],
        [-71, -50],
        [-1, -8],
        [49, 4],
        [59, 45],
        [33, 20],
        [32, 13],
        [43, -22],
        [55, -56],
        [44, -32],
        [63, -18],
        [37, -19],
        [62, -53],
        [10, -27],
        [6, -111],
        [-2, -27],
        [-8, -27],
        [-14, -26],
        [-26, -15],
        [-38, -3],
        [-30, -11],
        [-65, -59],
        [-28, -9],
        [-117, 18],
        [-45, 16],
        [-21, -1],
        [-12, -13],
        [-12, -6],
        [-46, -5],
        [-8, -11],
        [3, -16],
        [9, -22],
        [11, -13],
        [18, -16],
        [26, -11],
        [53, -12],
        [6, -30],
        [-2, -10],
        [-18, -20],
        [-20, 2],
        [-35, 24],
        [-18, 1],
        [-15, -13],
        [-21, -4],
        [-27, 4],
        [-15, -10],
        [-3, -25],
        [-9, -19],
        [-31, -30],
        [-17, -22],
        [0, -18],
        [18, -12],
        [21, -28],
        [23, -45],
        [5, -19],
        [-15, 5],
        [-19, 18],
        [-24, 29],
        [-36, 27],
        [-80, 35],
        [-15, -1],
        [8, -9],
        [53, -36],
        [20, -24],
        [3, -17],
        [-45, -38],
        [-1, -13],
        [12, -11],
        [3, -9],
        [-16, -18],
        [-26, -16],
        [-51, -2],
        [-4, -9],
        [19, -19],
        [6, -12],
        [-16, -16],
        [-11, -2],
        [-58, 7],
        [15, -40],
        [9, -14],
        [18, -20],
        [32, -19],
        [1, -7],
        [-11, -16],
        [-19, -20],
        [-81, -59],
        [-56, -70],
        [-8, -22],
        [14, -45],
        [1, -12],
        [-15, -21],
        [-33, 6],
        [-7, -8],
        [8, -22],
        [2, -31],
        [-5, -42],
        [-24, -66],
        [-43, -88],
        [-33, -81],
        [-23, -73],
        [-16, -36],
        [-31, -2],
        [-23, -23],
        [16, -12],
        [9, -14],
        [7, -21],
        [-7, -67],
        [-19, -111],
        [-13, -89],
        [3, -274],
        [-3, -121],
        [-9, -67],
        [-14, -37],
        [-24, -11],
        [30, -10],
        [20, -17],
        [9, -26],
        [8, -42],
        [12, -19],
        [13, 3],
        [13, -4],
        [11, -12],
        [33, -59],
        [37, -17],
        [2, -33],
        [-15, -184],
        [0, -24],
        [17, 46],
        [18, 144],
        [23, 63],
        [19, 14],
        [77, 7],
        [81, -16],
        [31, -2],
        [27, 8],
        [28, -17],
        [6, -18],
        [8, -75],
        [8, -43],
        [48, -153],
        [23, -86],
        [29, -133],
        [10, -38],
        [59, -178],
        [11, -49],
        [5, -37],
        [-2, -26],
        [-11, -40],
        [-19, -54],
        [-18, -42],
        [-17, -28],
        [-18, -22],
        [-18, -14],
        [1, -3],
        [21, 6],
        [20, 15],
        [39, 37],
        [15, 8],
        [43, 5],
        [1, -13],
        [-20, -28],
        [4, -2],
        [30, 22],
        [64, 31],
        [252, 103],
        [59, 9],
        [85, -19],
        [69, -43],
        [75, -56],
        [79, -41],
        [124, -38],
        [37, -18],
        [73, -19],
        [34, -21],
        [40, -52],
        [65, -67],
        [49, -42],
        [54, -39],
        [55, -73],
        [88, -164],
        [22, -20],
        [54, -27],
        [102, -35],
        [151, -81],
        [66, -32],
        [43, -13],
        [43, -22],
        [43, -32],
        [32, -35],
        [23, -38],
        [19, -25],
        [35, -28],
        [18, -19],
        [2, -30],
        [-42, -116],
        [-1, -10],
        [42, 84],
        [24, 24],
        [19, 11],
        [39, -1],
        [59, -14],
        [53, 0],
        [44, 13],
        [39, 6],
        [31, -1],
        [23, 4],
        [14, 10],
        [17, 0],
        [68, -29],
        [27, -2],
        [99, -27],
        [63, 10],
        [11, -5],
        [22, -36],
        [19, -3],
        [31, 6],
        [31, -10],
        [51, -46],
        [23, -40],
        [23, -82],
        [2, -25],
        [-42, -190],
        [-13, -73],
        [-2, -65],
        [8, -36],
        [37, -62],
        [6, -17],
        [22, -91],
        [6, -39],
        [-3, -45],
        [-11, -75],
        [3, -56],
        [9, -85],
        [-3, -58],
        [-17, -31],
        [-11, -29],
        [-9, -54],
        [0, -21],
        [9, -40],
        [16, -23],
        [26, -25],
        [24, -38],
        [45, -92],
        [33, -51],
        [39, -75],
        [8, -38],
        [-11, -25],
        [-14, -17],
        [-32, -23],
        [-14, -17],
        [3, -4],
        [47, 12],
        [27, -1],
        [23, -19],
        [19, -36],
        [32, -30],
        [43, -25],
        [44, -43],
        [74, -100],
        [13, -23],
        [19, -52],
        [26, -82],
        [13, -54],
        [1, -25],
        [-14, -25],
        [-49, -46],
        [-50, -81],
        [16, 4],
        [32, 34],
        [56, 67],
        [30, 13],
        [29, -7],
        [45, -20],
        [39, -27],
        [35, -36],
        [51, -95],
        [55, -76],
        [30, -65],
        [-9, 41],
        [-20, 48],
        [-51, 76],
        [-22, 39],
        [-5, 19],
        [-2, 21],
        [4, 37],
        [10, 55],
        [13, 39],
        [16, 22],
        [10, 24],
        [6, 25],
        [8, 19],
        [44, 33],
        [12, -3],
        [9, -41],
        [10, -8],
        [21, -6],
        [17, -15],
        [14, -21],
        [10, -23],
        [6, -22],
        [13, -73],
        [9, -34],
        [2, 41],
        [11, 65],
        [8, 26],
        [27, 39],
        [-2, 17],
        [-11, 22],
        [-56, 97],
        [-1, 24],
        [15, 14],
        [11, 26],
        [5, 40],
        [12, 28],
        [35, 40],
        [30, 62],
        [16, 42],
        [12, 23],
        [13, 5],
        [-18, 17],
        [-4, 11],
        [-1, 51],
        [-8, 54],
        [-12, 25],
        [-34, 53],
        [-6, 16],
        [-6, 60],
        [4, 30],
        [9, 26],
        [-5, 24],
        [-32, 45],
        [-12, 39],
        [-14, 93],
        [-13, 118],
        [-14, 86],
        [-16, 56],
        [-3, 35],
        [9, 14],
        [11, 44],
        [10, 10],
        [16, -2],
        [1, 5],
        [-25, 23],
        [-12, 30],
        [1, 11],
        [21, 29],
        [-3, 12],
        [-16, 16],
        [-49, 28],
        [18, 10],
        [11, 23],
        [-2, 7],
        [-20, 10],
        [-22, 17],
        [-17, 27],
        [-20, 42],
        [-13, 34],
        [-12, 56],
        [-22, 63],
        [-9, 16],
        [-11, 10],
        [-12, 5],
        [0, 10],
        [13, 14],
        [210, 106],
        [18, 16],
        [103, 59],
        [48, 33],
        [48, 48],
        [66, 49],
        [32, 31],
        [21, 31],
        [105, 122],
        [44, 62],
        [26, 53],
        [37, 64],
        [49, 75],
        [31, 63],
        [13, 54],
        [16, 91],
        [4, 82],
        [3, 119],
        [-1, 106],
        [-14, 167],
        [-7, 53],
        [-15, 62],
        [-37, 125],
        [-6, 35],
        [-23, 57],
        [-74, 151],
        [-92, 101],
        [-18, 26],
        [-36, 30],
        [-56, 34],
        [-36, 28],
        [-94, 104],
        [-31, 13],
        [-11, 27],
        [-3, 19],
        [4, 50],
        [5, 34],
        [7, 26],
        [7, 16],
        [52, 77],
        [29, 61],
        [20, 34],
        [22, 26],
        [41, 34],
        [23, 44],
        [-6, 17],
        [-18, 19],
        [-4, 19],
        [31, 47],
        [4, 13],
        [-4, 45],
        [6, 10],
        [38, 3],
        [54, -64],
        [13, 5],
        [-17, 18],
        [-21, 43],
        [3, 18],
        [40, 47],
        [1, 21],
        [-12, 29],
        [-1, 24],
        [23, 55],
        [-6, 12],
        [-65, 11],
        [-11, 16],
        [4, 7],
        [31, 19],
        [2, 7],
        [-54, 125],
        [-9, 37],
        [23, 46],
        [26, 20],
        [-3, 12],
        [-35, 2],
        [-21, 7],
        [-21, 35],
        [8, 23],
        [8, 10],
        [20, 54],
        [20, 11],
        [-4, 9],
        [-74, -23],
        [-35, 19],
        [-35, -5],
        [-16, 6],
        [6, 19],
        [62, 86],
        [29, 47],
        [18, 43],
        [10, 28],
        [1, 14],
        [-6, 93],
        [3, 26],
        [25, 24],
        [37, 44],
        [-51, 41],
        [-32, 39],
        [-21, 20],
        [-16, 19],
        [-21, 40],
        [-15, 53],
        [-17, 106],
        [-3, 58],
        [4, 42],
        [7, 21],
        [11, 22],
        [47, 41],
        [82, 60],
        [64, 24],
        [47, -13],
        [90, -15],
        [73, -34],
        [222, -86],
        [40, -38],
        [-37, -33],
        [5, -8],
        [84, 62],
        [22, 12],
        [19, 3],
        [63, -24],
        [25, -4],
        [32, -20],
        [76, -66],
        [6, 6],
        [-21, 34],
        [12, 15],
        [60, 35],
        [62, 29],
        [44, 28],
        [47, 37],
        [32, 20],
        [16, 2],
        [21, -11],
        [56, -49],
        [36, -25],
        [29, -26],
        [40, -47],
        [16, -10],
        [31, -32],
        [41, 3],
        [13, -3],
        [4, -7],
        [7, -21],
        [4, -14],
        [0, -14],
        [-9, -42],
        [-30, -66],
        [13, -1],
        [18, 14],
        [24, 25],
        [19, 9],
        [40, -20],
        [38, -32],
        [13, -17],
        [15, -28],
        [12, -15],
        [12, -27],
        [-1, -9],
        [-11, -14],
        [-46, -22],
        [9, -9],
        [53, 14],
        [17, 14],
        [11, 24],
        [16, 6],
        [63, -41],
        [9, -14],
        [-4, -11],
        [-10, -13],
        [-27, -14],
        [-24, -35],
        [-4, -15],
        [17, -11],
        [41, -4],
        [0, -8],
        [-24, -16],
        [-3, -20],
        [53, -71],
        [36, -32],
        [21, -5],
        [48, -2],
        [39, -12],
        [86, -40],
        [51, -8],
        [44, 13],
        [29, 2],
        [25, -16],
        [8, -11],
        [4, -23],
        [0, -34],
        [14, -24],
        [28, -14],
        [23, 2],
        [31, 27],
        [25, 5],
        [9, 20],
        [7, 37],
        [8, 20],
        [18, 6],
        [15, -11],
        [9, -17],
        [16, -51],
        [4, -23],
        [-1, -20],
        [-8, -19],
        [-16, -20],
        [-23, -21],
        [-19, -31],
        [-22, -70],
        [-9, -46],
        [-2, -28],
        [1, -29],
        [4, -33],
        [9, -27],
        [21, -37],
        [1, -13],
        [2, -30],
        [-2, -14],
        [-12, -27],
        [-34, -26],
        [-46, -7],
        [-152, -1],
        [-41, 6],
        [10, -24],
        [43, -8],
        [38, 0],
        [145, -15],
        [20, -15],
        [17, -28],
        [12, -28],
        [12, -57],
        [2, -27],
        [-6, -31],
        [-15, -33],
        [-10, -44],
        [-5, -55],
        [8, -29],
        [78, -3],
        [15, -20],
        [-1, -15],
        [-28, -56],
        [-3, -15],
        [13, -38],
        [-2, -11],
        [-7, -11],
        [-8, -28],
        [-7, -46],
        [-9, -30],
        [-21, -22],
        [-11, -5],
        [-8, 6],
        [-21, 62],
        [-9, 9],
        [-8, -6],
        [-4, -9],
        [0, -14],
        [-4, -14],
        [-7, -14],
        [-31, -23],
        [-50, -16],
        [1, -16],
        [34, -9],
        [43, -28],
        [25, -4],
        [38, 22],
        [75, 71],
        [31, 19],
        [27, 7],
        [30, 1],
        [34, -5],
        [68, 9],
        [17, -7],
        [20, -15],
        [25, -25],
        [17, -23],
        [9, -23],
        [15, -85],
        [21, -23],
        [4, -16],
        [2, -25],
        [-1, -49],
        [-23, -100],
        [-11, -36],
        [-31, -50],
        [-36, -23],
        [-64, -21],
        [-33, -18],
        [-25, -25],
        [-1, -13],
        [72, 42],
        [79, 20],
        [23, 24],
        [17, 22],
        [17, 50],
        [32, 130],
        [18, 40],
        [25, 7],
        [11, -14],
        [26, -76],
        [0, -19],
        [-7, -16],
        [-42, -74],
        [15, 7],
        [43, 70],
        [9, 21],
        [5, 32],
        [14, 23],
        [5, -11],
        [13, -82],
        [0, -60],
        [3, -19],
        [-5, -56],
        [5, -10],
        [13, 49],
        [4, 37],
        [6, 26],
        [7, 16],
        [52, 50],
        [61, 40],
        [40, 36],
        [33, 17],
        [50, 17],
        [31, 33],
        [14, 50],
        [12, 35],
        [10, 20],
        [33, 34],
        [17, 2],
        [17, -12],
        [20, -26],
        [21, -40],
        [13, -30],
        [4, -23],
        [4, -78],
        [4, 0],
        [19, 60],
        [3, 21],
        [-2, 23],
        [-6, 22],
        [-20, 49],
        [-7, 31],
        [1, 18],
        [22, 11],
        [31, 4],
        [5, 8],
        [-23, 21],
        [-1, 11],
        [22, 36],
        [13, 1],
        [26, -5],
        [-5, 18],
        [0, 12],
        [7, 5],
        [42, -11],
        [5, 14],
        [36, 1],
        [4, 12],
        [-32, 18],
        [-30, 12],
        [-10, 9],
        [-7, 15],
        [-9, 35],
        [2, 9],
        [9, 0],
        [14, -9],
        [9, 18],
        [9, 43],
        [9, 18],
        [28, -21],
        [1, 9],
        [-23, 68],
        [4, 13],
        [35, 5],
        [21, -9],
        [55, -49],
        [11, 5],
        [-9, 14],
        [-28, 29],
        [-26, 19],
        [-23, 9],
        [-17, 16],
        [-19, 45],
        [-3, 18],
        [2, 24],
        [13, 50],
        [7, 11],
        [14, 8],
        [19, 5],
        [21, -5],
        [44, -33],
        [7, 11],
        [-23, 18],
        [-13, 16],
        [-6, 21],
        [2, 24],
        [19, 49],
        [9, 40],
        [39, 109],
        [12, 20],
        [12, 12],
        [8, 13],
        [31, 3],
        [57, -39],
        [18, -23],
        [4, -32],
        [-30, -45],
        [-51, -33],
        [-16, -14],
        [10, -9],
        [49, 27],
        [42, 12],
        [34, 0],
        [28, -53],
        [4, -73],
        [-16, -60],
        [21, 30],
        [26, 17],
        [22, -40],
        [2, -32],
        [12, -31],
        [24, -40],
        [25, -36],
        [-28, -37],
        [-33, -23],
        [7, -16],
        [46, -18],
        [6, -18],
        [-5, -24],
        [7, 1],
        [32, 36],
        [27, -5],
        [34, -79],
        [-25, -46],
        [-39, -21],
        [-30, -9],
        [-43, 2],
        [-17, -7],
        [8, -15],
        [41, -1],
        [63, 12],
        [47, 19],
        [20, 1],
        [22, -8],
        [7, -6],
        [-23, -13],
        [-1, -5],
        [8, -13],
        [18, -44],
        [-2, -10],
        [-17, -25],
        [27, -5],
        [38, 12],
        [11, -14],
        [23, -52],
        [15, -53],
        [-64, -73],
        [-32, -15],
        [-48, -39],
        [-13, -31],
        [-28, -39],
        [18, 0],
        [49, 64],
        [24, 15],
        [18, -4],
        [7, -11],
        [-3, -18],
        [16, 3],
        [66, 37],
        [28, 7],
        [36, 3],
        [3, -13],
        [-22, -89],
        [-38, -69],
        [-70, -42],
        [-24, -25],
        [-31, -40],
        [12, -7],
        [66, 52],
        [45, 21],
        [64, 17],
        [28, -3],
        [51, -104],
        [28, -10],
        [24, 5],
        [44, -30],
        [16, -29],
        [-4, -21],
        [-15, -13],
        [-7, -19],
        [18, -58],
        [-11, -33],
        [-32, -29],
        [-23, -14],
        [-24, -5],
        [-24, -25],
        [-10, -4],
        [-33, 6],
        [11, -15],
        [16, -8],
        [26, -4],
        [30, 8],
        [30, -1],
        [46, -19],
        [20, -23],
        [0, -6],
        [-10, -13],
        [-15, -42],
        [-10, -15],
        [9, -12],
        [23, -18],
        [17, -6],
        [23, 6],
        [25, -8],
        [82, -99],
        [-4, -51],
        [-12, -40],
        [5, -44],
        [0, -55],
        [-44, -15],
        [-148, 25],
        [-84, 39],
        [-5, 12],
        [24, 26],
        [-21, 2],
        [-24, -10],
        [-11, -10],
        [29, -41],
        [77, -36],
        [35, -44],
        [38, -4],
        [11, -8],
        [21, -25],
        [-6, -9],
        [-39, -3],
        [-30, -30],
        [19, -17],
        [69, -16],
        [49, -4],
        [25, -17],
        [-20, -19],
        [-58, -22],
        [-2, -33],
        [43, -14],
        [38, 8],
        [16, -3],
        [11, -80],
        [7, -17],
        [-41, -14],
        [-1, -16],
        [28, -13],
        [45, -10],
        [15, -15],
        [3, -24],
        [9, -12],
        [26, -3],
        [29, 30],
        [17, 25],
        [25, -9],
        [1, -31],
        [30, -36],
        [10, -6],
        [9, -51],
        [24, 45],
        [17, -9],
        [20, -2],
        [-7, -44],
        [-11, -34],
        [15, -22],
        [12, -32],
        [33, -43],
        [-9, -21],
        [-38, -45],
        [-20, -72],
        [-5, -24],
        [-20, -41],
        [-26, -40],
        [16, 4],
        [60, 73],
        [36, 25],
        [78, 13],
        [19, 20],
        [29, 9],
        [18, -23],
        [1, -42],
        [24, -14],
        [24, 14],
        [22, -12],
        [-13, -26],
        [-71, -108],
        [-21, -44],
        [-6, -31],
        [24, 43],
        [90, 97],
        [9, 14],
        [20, 42],
        [18, 27],
        [48, -10],
        [25, -19],
        [11, -55],
        [19, -59],
        [30, -65],
        [78, -32],
        [28, -6],
        [49, 23],
        [7, 30],
        [38, 10],
        [27, -4],
        [9, -59],
        [29, -31],
        [28, -26],
        [27, -14],
        [40, -6],
        [22, -28],
        [0, -12],
        [-22, -30],
        [-22, -45],
        [-38, -31],
        [-53, -1],
        [-72, -20],
        [-3, -17],
        [-16, -19],
        [-39, -20],
        [-21, -14],
        [-35, -72],
        [-20, -31],
        [-24, -6],
        [-34, 3],
        [-22, -6],
        [-16, -14],
        [-10, -19],
        [-7, -7],
        [-45, -20],
        [-82, -55],
        [-44, -2],
        [-26, 7],
        [-21, -5],
        [-14, -15],
        [-39, -27],
        [-12, -16],
        [-7, -19],
        [-5, -38],
        [-5, -14],
        [-7, -8],
        [-33, 8],
        [-37, 25],
        [7, -27],
        [58, -44],
        [17, -25],
        [-16, -21],
        [-37, -34],
        [-4, -18],
        [15, -10],
        [-6, -15],
        [-20, -16],
        [2, -7],
        [2, -6],
        [51, 23],
        [45, 50],
        [29, 50],
        [14, 14],
        [58, 19],
        [34, 22],
        [49, 39],
        [54, 58],
        [58, 76],
        [74, 59],
        [91, 42],
        [66, 22],
        [42, 1],
        [2, 7],
        [-37, 14],
        [-32, 2],
        [-39, -10],
        [-12, 24],
        [2, 10],
        [12, 17],
        [33, 15],
        [161, -21],
        [55, -17],
        [60, -137],
        [14, -44],
        [4, -32],
        [-6, -20],
        [-24, -24],
        [-69, -48],
        [-9, -12],
        [-1, -7],
        [30, -9],
        [9, -14],
        [15, -52],
        [31, 34],
        [58, 83],
        [48, 38],
        [40, 11],
        [47, 5],
        [17, -1],
        [6, -27],
        [25, -54],
        [23, -14],
        [45, -7],
        [40, -67],
        [15, -46],
        [14, -27],
        [-1, -19],
        [2, -15],
        [11, -23],
        [5, -19],
        [-3, -45],
        [-23, -77],
        [17, -71],
        [-7, -31],
        [-4, -51],
        [14, -33],
        [5, -20],
        [-13, -11],
        [-88, -28],
        [-35, -1],
        [-9, -17],
        [27, -5],
        [48, 1],
        [59, -17],
        [27, -19],
        [11, -27],
        [-3, -21],
        [-17, -16],
        [-33, 3],
        [-32, 15],
        [2, -14],
        [47, -35],
        [14, -17],
        [26, -22],
        [5, -30],
        [-6, -30],
        [-90, -120],
        [-73, -76],
        [-74, -67],
        [-119, -129],
        [-12, -6],
        [-21, -3],
        [-57, 21],
        [-45, -5],
        [-85, -25],
        [-24, -15],
        [-47, -45],
        [-18, -6],
        [-51, -9],
        [-47, 6],
        [-19, -7],
        [-23, -21],
        [-6, -12],
        [-7, -38],
        [-115, -168],
        [-31, -57],
        [-59, -60],
        [-65, -106],
        [-57, -43],
        [-19, -58],
        [-54, -36],
        [-100, -9],
        [-47, -10],
        [-55, 16],
        [-42, -25],
        [-62, -8],
        [-30, 6],
        [-121, -57],
        [-31, 54],
        [-23, 21],
        [-68, 3],
        [-55, 22],
        [-50, 4],
        [-48, 10],
        [-32, 0],
        [-33, -6],
        [-52, 2],
        [-29, -30],
        [-96, 9],
        [-41, 27],
        [-34, 6],
        [-44, -6],
        [-43, -20],
        [-94, 22],
        [-100, -19],
        [-87, 13],
        [-24, 13],
        [-138, -35],
        [-53, 20],
        [-48, -54],
        [-32, 11],
        [-35, -8],
        [-12, 11],
        [-23, -8],
        [-15, -29],
        [-20, -3],
        [-33, -52],
        [-56, -41],
        [-82, -226],
        [-7, -87],
        [-31, -59],
        [-27, -8],
        [-22, -1],
        [-141, -44],
        [-63, -34],
        [18, -27],
        [-21, -21],
        [-33, -8],
        [-36, -25],
        [-24, -28],
        [-11, -39],
        [-72, -64],
        [-84, -147],
        [-40, -108],
        [-49, -78],
        [-34, -30],
        [-25, -5],
        [-25, 10],
        [-41, 36],
        [-30, 4],
        [-76, 51],
        [-177, 52],
        [27, -19],
        [23, -32],
        [47, -8],
        [47, 0],
        [99, -63],
        [48, -22],
        [30, -19],
        [25, -43],
        [-18, -84],
        [-19, -69],
        [-24, -53],
        [-85, -137],
        [-41, -46],
        [-72, -163],
        [-74, -77],
        [-40, -47],
        [-42, -74],
        [-99, -56],
        [-37, -15],
        [-34, 8],
        [-41, -46],
        [-49, -28],
        [-15, -42],
        [-117, -114],
        [-45, -15],
        [-39, -30],
        [-11, -51],
        [-34, -32],
        [-10, -23],
        [-29, -72],
        [-53, -94],
        [-66, -15],
        [-24, -32],
        [-27, -53],
        [-39, -36],
        [-77, 17],
        [18, -22],
        [69, -34],
        [7, -51],
        [-34, -12],
        [-72, -68],
        [-98, -117],
      ],
      [
        [15892, 80064],
        [-11, 21],
        [-27, 26],
        [-10, 0],
        [-11, -8],
        [-7, -12],
        [-6, -27],
        [-4, -8],
        [-4, 1],
        [-3, 7],
        [-8, 37],
        [2, 16],
        [9, 20],
        [-1, 7],
        [-20, -6],
        [-8, 5],
        [-4, 10],
        [1, 42],
        [-11, 23],
        [13, 10],
        [32, 8],
        [34, 1],
        [9, 18],
        [10, 43],
        [-24, -40],
        [-14, -4],
        [-44, 15],
        [-29, -2],
        [-4, 8],
        [2, 9],
        [6, 9],
        [4, 31],
        [7, 85],
        [9, 31],
        [3, 16],
        [-2, 4],
        [-38, -59],
        [-3, -19],
        [4, -16],
        [-4, -33],
        [-18, -10],
        [-10, 5],
        [-20, -28],
        [-6, -3],
        [-91, 50],
        [-10, 6],
        [-15, 23],
        [-23, 40],
        [-7, 34],
        [9, 28],
        [8, 14],
        [10, 0],
        [10, -10],
        [21, -47],
        [8, -29],
        [22, 4],
        [35, 37],
        [8, 14],
        [-35, -14],
        [-15, 1],
        [-15, 15],
        [-16, 30],
        [-8, 34],
        [0, 107],
        [5, 20],
        [17, 16],
        [11, 25],
        [-1, 11],
        [-10, 22],
        [-15, 16],
        [-15, 9],
        [-4, -3],
        [23, -46],
        [0, -18],
        [-26, -46],
        [-4, -13],
        [0, -45],
        [-3, -9],
        [-21, -13],
        [-23, -35],
        [-39, -12],
        [-36, 4],
        [-20, 17],
        [-61, 86],
        [-22, 36],
        [0, 30],
        [-42, 107],
        [0, 23],
        [-14, 33],
        [-16, 5],
        [-4, 32],
        [33, 68],
        [21, 58],
        [2, 18],
        [-1, 28],
        [-5, 62],
        [5, 27],
        [-21, -36],
        [-4, -26],
        [5, -27],
        [-3, -30],
        [-12, -43],
        [-21, -45],
        [-42, -21],
        [-74, 12],
        [-8, 6],
        [-5, 15],
        [-4, 66],
        [-5, -8],
        [-8, -35],
        [-7, -51],
        [-9, -11],
        [-15, -1],
        [-12, 8],
        [-9, 17],
        [-19, 2],
        [-34, -14],
        [-17, 6],
        [-19, 0],
        [-40, 15],
        [-47, 4],
        [-12, 11],
        [1, 22],
        [8, 11],
        [49, 10],
        [48, 23],
        [47, 12],
        [-2, 11],
        [-21, 4],
        [-108, -26],
        [-34, 4],
        [-6, 4],
        [-1, 26],
        [13, 24],
        [21, 23],
        [6, 18],
        [-12, 7],
        [-20, -5],
        [-9, 14],
        [10, 55],
        [-9, 55],
        [-13, -52],
        [-19, -29],
        [-91, -13],
        [-15, -15],
        [-12, 0],
        [-58, 28],
        [-25, 17],
        [-23, 25],
        [-42, 57],
        [-33, 37],
        [-1, 67],
        [7, 43],
        [15, 49],
        [59, 104],
        [21, 19],
        [18, 7],
        [87, 9],
        [65, 13],
        [12, 7],
        [-95, 7],
        [-85, -5],
        [-29, -16],
        [-37, -67],
        [-9, -28],
        [-10, -21],
        [-7, 0],
        [-12, 7],
        [-4, 9],
        [-6, 22],
        [-19, 34],
        [-9, 40],
        [-5, 59],
        [1, 26],
        [10, 34],
        [28, 66],
        [-36, -2],
        [4, 55],
        [13, 61],
        [34, 35],
        [34, 26],
        [31, 34],
        [55, 22],
        [18, -46],
        [47, -14],
        [13, -21],
        [17, -38],
        [20, -36],
        [25, -35],
        [7, -3],
        [-11, 30],
        [-40, 65],
        [-2, 23],
        [-10, 25],
        [-52, 36],
        [-10, 14],
        [-9, 36],
        [-4, 24],
        [6, 22],
        [53, 69],
        [13, 37],
        [0, 17],
        [-5, 19],
        [-11, 34],
        [-3, 0],
        [3, -51],
        [-2, -20],
        [-6, -22],
        [-8, -17],
        [-12, -11],
        [-114, -158],
        [-12, -9],
        [-42, -14],
        [-22, -17],
        [-12, -22],
        [-19, -58],
        [-25, -115],
        [-30, -93],
        [-25, 121],
        [-46, 92],
        [89, 92],
        [2, 14],
        [-8, 49],
        [2, 15],
        [8, 21],
        [23, 32],
        [-1, 3],
        [-26, -12],
        [-41, -71],
        [-16, -22],
        [-8, -3],
        [-1, 41],
        [22, 109],
        [18, 105],
        [6, 30],
        [16, 30],
        [-15, -2],
        [-72, -47],
        [-24, 29],
        [-20, 153],
        [-36, 59],
        [-60, 49],
        [-59, 22],
        [-13, 43],
        [-12, 53],
        [16, 62],
        [26, 29],
        [23, 14],
        [23, -7],
        [1, -22],
        [-15, -61],
        [20, -6],
        [81, -74],
        [17, -6],
        [34, 28],
        [18, -1],
        [44, -23],
        [15, -28],
        [42, -54],
        [-6, 32],
        [-46, 66],
        [-25, 22],
        [-45, 4],
        [-28, -11],
        [-12, 3],
        [-24, 17],
        [-21, 28],
        [-21, 61],
        [-5, 29],
        [1, 21],
        [5, 19],
        [9, 17],
        [17, 13],
        [26, 7],
        [7, 8],
        [-32, 35],
        [-15, 0],
        [-52, -51],
        [-10, -5],
        [-5, 10],
        [-4, 1],
        [-15, -25],
        [-12, -11],
        [-42, -79],
        [-7, -37],
        [-2, -57],
        [-5, -35],
        [-7, -14],
        [-50, -27],
        [-28, -55],
        [-34, 48],
        [-38, 45],
        [-25, 80],
        [-45, 14],
        [-52, 45],
        [-20, 41],
        [28, 82],
        [41, 63],
        [6, 74],
        [6, 16],
        [70, 19],
        [46, 37],
        [-47, 4],
        [-29, -6],
        [-52, -26],
        [-57, 51],
        [-29, 47],
        [-10, 40],
        [10, 34],
        [2, 34],
        [5, 47],
        [5, 20],
        [12, 26],
        [25, 17],
        [22, 54],
        [9, 37],
        [44, 111],
        [16, 48],
        [31, 67],
        [61, 105],
        [-20, -6],
        [-10, -9],
        [-9, 1],
        [-9, 11],
        [-9, 24],
        [-8, 35],
        [-5, -16],
        [-1, -66],
        [-6, -57],
        [-11, -39],
        [-30, -80],
        [-18, -31],
        [-12, 29],
        [10, 50],
        [17, 40],
        [3, 54],
        [-16, 65],
        [-10, 53],
        [-4, 40],
        [-1, 36],
        [3, 33],
        [7, 35],
        [11, 39],
        [-1, 4],
      ],
      [
        [19495, 97148],
        [49, -34],
        [17, 1],
        [21, -6],
        [10, -9],
        [29, -43],
        [6, -21],
        [2, -27],
        [-5, -23],
        [-10, -19],
        [-27, -11],
        [-62, -8],
        [-87, 11],
        [-75, -14],
        [-35, -2],
        [-93, 9],
        [-24, 7],
        [-52, 26],
        [-40, 11],
        [-16, -6],
        [-20, -23],
        [-37, -28],
        [-23, -7],
        [-68, 4],
        [-103, 48],
        [-118, -14],
        [-123, -28],
        [-48, -5],
        [-14, 8],
        [-19, 21],
        [3, 11],
        [36, 32],
        [82, 33],
        [60, 19],
        [118, 28],
        [141, 15],
        [52, 17],
        [33, 23],
        [92, 37],
        [53, 16],
        [73, 13],
        [58, -1],
        [74, -30],
        [56, -15],
        [34, -16],
      ],
      [
        [19317, 96833],
        [223, -14],
        [9, -3],
        [1, -10],
        [-17, -33],
        [-25, -24],
        [-119, -30],
        [-153, -28],
        [-32, -13],
        [2, -8],
        [13, -10],
        [25, -12],
        [119, 2],
        [29, -5],
        [10, -8],
        [6, -12],
        [4, -15],
        [0, -53],
        [-6, -30],
        [-17, -27],
        [-48, -20],
        [-86, -26],
        [-59, -11],
        [-46, 4],
        [-46, -3],
        [-202, -48],
        [-62, -1],
        [-55, 12],
        [-75, 46],
        [-78, 18],
        [-34, 21],
        [-33, 11],
        [-9, 16],
        [-3, 13],
        [6, 11],
        [14, 10],
        [4, 9],
        [-13, 25],
        [-6, 24],
        [-22, 35],
        [-4, 20],
        [0, 13],
        [4, 14],
        [15, 25],
        [8, 5],
        [46, 4],
        [60, 13],
        [139, 37],
        [305, 47],
        [93, -4],
        [40, 9],
        [75, 4],
      ],
      [
        [17902, 96407],
        [21, -23],
        [2, -9],
        [-10, -9],
        [-33, -15],
        [-164, -51],
        [-32, -24],
        [12, -20],
        [59, -41],
        [60, -35],
        [13, -18],
        [-28, -17],
        [-55, 5],
        [-20, -1],
        [-20, -8],
        [6, -16],
        [60, -52],
        [20, -28],
        [0, -14],
        [-11, -14],
        [-26, -19],
        [-40, -25],
        [-68, -19],
        [-148, -26],
        [-5, -21],
        [1, -15],
        [-3, -38],
        [-6, -18],
        [-17, -29],
        [-13, -14],
        [-22, -9],
        [-32, -6],
        [-40, 0],
        [-67, 26],
        [-30, 16],
        [-42, 35],
        [-8, 24],
        [5, 28],
        [11, 45],
        [18, 46],
        [25, 46],
        [8, 29],
        [-10, 12],
        [-18, 0],
        [-54, -19],
        [-35, -7],
        [-27, -13],
        [-20, -21],
        [-11, -22],
        [-6, -43],
        [-10, -23],
        [-29, -13],
        [-44, 0],
        [-17, -7],
        [-8, -16],
        [6, -12],
        [43, -27],
        [6, -30],
        [-6, -18],
        [-46, -33],
        [-11, -11],
        [-29, -52],
        [-11, -13],
        [-24, -12],
        [-24, 1],
        [-23, 19],
        [-33, 36],
        [-22, 31],
        [-12, 26],
        [-9, 11],
        [-16, -8],
        [-19, -26],
        [0, -21],
        [4, -31],
        [-1, -19],
        [-28, -23],
        [4, -10],
        [49, -28],
        [6, -12],
        [0, -19],
        [-3, -9],
        [-20, 1],
        [-16, -22],
        [-19, -17],
        [-49, -33],
        [-69, -4],
        [-57, -16],
        [-12, 1],
        [-14, 26],
        [-15, 50],
        [-14, 29],
        [-20, 15],
        [-26, 58],
        [-12, 18],
        [-11, 8],
        [-10, 2],
        [-15, -12],
        [-33, -82],
        [-54, -21],
        [-29, -4],
        [-30, 2],
        [-74, 23],
        [-60, 8],
        [-41, -9],
        [-68, -34],
        [-27, -9],
        [-37, 4],
        [-16, 13],
        [-14, 21],
        [-1, 12],
        [10, 5],
        [17, 24],
        [0, 9],
        [-17, 14],
        [0, 11],
        [6, 7],
        [-2, 6],
        [-8, 3],
        [-17, -3],
        [-61, -16],
        [7, 17],
        [29, 37],
        [71, 72],
        [26, 21],
        [16, 6],
        [209, 24],
        [15, 7],
        [99, 97],
        [28, 22],
        [29, 18],
        [143, 58],
        [13, 14],
        [22, 40],
        [13, 10],
        [31, 16],
        [102, 82],
        [94, 59],
        [47, 37],
        [65, 37],
        [75, 16],
        [227, 28],
        [163, -37],
        [38, -2],
        [19, 10],
        [17, 17],
        [25, -7],
        [60, -5],
        [14, 4],
        [25, 19],
        [-17, 11],
        [-73, 19],
        [-5, 10],
        [2, 9],
        [26, 23],
        [31, 14],
        [90, 11],
        [41, -3],
        [43, -15],
        [55, -32],
        [127, -56],
      ],
      [
        [19919, 95654],
        [35, -1],
        [41, 6],
        [46, -4],
        [21, -13],
        [15, -23],
        [0, -13],
        [-3, -10],
        [-6, -9],
        [-60, -58],
        [-14, -20],
        [19, -5],
        [10, 3],
        [59, 44],
        [45, 13],
        [34, 4],
        [57, -9],
        [22, -8],
        [15, -8],
        [9, -11],
        [22, -41],
        [16, -54],
        [2, 5],
        [1, 31],
        [3, 23],
        [55, 16],
        [1, 5],
        [-20, 13],
        [-16, 18],
        [-12, 33],
        [4, 12],
        [12, 14],
        [35, 28],
        [42, 17],
        [36, 4],
        [137, -29],
        [54, -25],
        [21, -12],
        [8, -9],
        [12, -28],
        [23, -78],
        [-1, -25],
        [-10, -40],
        [-44, -76],
        [-7, -51],
        [-44, -127],
        [-31, -35],
        [-33, -24],
        [-138, -43],
        [-104, -43],
        [-26, -7],
        [-27, -1],
        [-86, 15],
        [-99, 27],
        [-57, -7],
        [-56, -20],
        [-36, -6],
        [-33, 3],
        [-33, 7],
        [-44, 19],
        [23, 8],
        [10, 10],
        [-9, 10],
        [-46, 14],
        [-48, -32],
        [-139, -73],
        [-187, -25],
        [-58, -15],
        [-44, -19],
        [-22, -16],
        [-35, -38],
        [-53, -28],
        [-96, -31],
        [-123, -48],
        [-220, -49],
        [-138, -8],
        [-138, 16],
        [-44, 13],
        [-46, 21],
        [-93, 49],
        [-26, 17],
        [-31, 39],
        [18, 25],
        [50, 30],
        [75, 26],
        [150, 36],
        [135, 58],
        [49, 11],
        [130, 9],
        [66, -5],
        [48, 3],
        [31, 8],
        [47, 20],
        [68, 42],
        [50, 39],
        [12, 18],
        [-16, 17],
        [-25, 2],
        [-81, -40],
        [-41, -13],
        [-44, -1],
        [-61, -14],
        [-60, -5],
        [-11, 0],
        [-62, 39],
        [-33, 6],
        [-16, -4],
        [-14, -10],
        [-26, -28],
        [-16, -10],
        [-27, -8],
        [-107, -8],
        [-104, -14],
        [-23, 9],
        [-14, 16],
        [-3, 10],
        [-1, 33],
        [-7, 14],
        [9, 28],
        [12, 21],
        [14, 15],
        [71, 43],
        [10, 11],
        [-34, -2],
        [-80, -21],
        [-11, 7],
        [-18, 24],
        [-9, 2],
        [-11, -10],
        [-6, -14],
        [-13, -58],
        [-13, -30],
        [-32, 6],
        [-40, 18],
        [-15, 3],
        [-9, -6],
        [3, -10],
        [41, -50],
        [0, -17],
        [-26, -31],
        [-114, -51],
        [-44, -13],
        [-16, 5],
        [-14, 14],
        [-13, 22],
        [-29, 31],
        [-18, 7],
        [-19, 1],
        [-18, -7],
        [-17, -13],
        [-10, -15],
        [-10, -26],
        [-20, -21],
        [-13, -3],
        [-115, 42],
        [-93, 75],
        [-101, -11],
        [-46, 2],
        [-138, 28],
        [-17, 17],
        [-10, 22],
        [1, 12],
        [6, 12],
        [17, 25],
        [35, 37],
        [15, 12],
        [21, 10],
        [29, 8],
        [73, 4],
        [189, 2],
        [37, 5],
        [206, 72],
        [24, 12],
        [30, 23],
        [7, 10],
        [-1, 6],
        [-254, -57],
        [-109, -12],
        [-166, 9],
        [-31, 9],
        [-8, 16],
        [35, 42],
        [18, 16],
        [48, 15],
        [114, 21],
        [154, 19],
        [100, 0],
        [84, 15],
        [51, 17],
        [-170, -1],
        [-204, -8],
        [-30, 5],
        [-58, 23],
        [-3, 16],
        [23, 19],
        [9, 15],
        [-17, 33],
        [5, 13],
        [38, 27],
        [68, 29],
        [42, 5],
        [81, -11],
        [230, -10],
        [45, 4],
        [-28, 13],
        [-40, 9],
        [-178, 17],
        [-37, 7],
        [-7, 10],
        [-3, 15],
        [2, 19],
        [13, 20],
        [55, 42],
        [162, 35],
        [64, 5],
        [65, -3],
        [65, -15],
        [29, -14],
        [15, -16],
        [7, -16],
        [1, -26],
        [3, -11],
        [12, -17],
        [37, -42],
        [28, -13],
        [128, 24],
        [53, 6],
        [54, -8],
        [78, -24],
        [101, -75],
        [130, -77],
        [-1, -16],
        [-49, -26],
        [-10, -11],
        [7, -7],
        [50, -5],
        [46, 4],
        [45, -6],
        [10, -7],
        [16, -28],
        [23, -49],
        [27, -37],
        [31, -24],
        [31, -13],
        [45, -1],
        [46, 8],
        [74, -3],
        [381, -28],
        [23, 5],
        [16, 15],
        [9, 26],
        [4, 22],
        [-5, 29],
        [-8, 14],
        [-236, 94],
        [-21, 38],
        [116, 54],
        [8, 12],
        [2, 17],
        [-4, 21],
        [-15, 21],
        [-62, 39],
        [-55, 6],
        [-82, 38],
        [-12, 10],
        [-11, 15],
        [-8, 21],
        [1, 16],
        [11, 12],
        [80, 39],
        [33, 21],
        [100, 98],
        [46, 39],
        [33, 19],
        [34, 11],
        [74, 5],
        [77, -36],
        [17, -2],
        [7, -10],
        [-3, -17],
        [-10, -16],
        [-27, -29],
        [-7, -12],
        [2, -13],
        [19, -29],
        [6, -19],
        [4, -32],
        [3, -5],
        [47, -27],
        [42, -36],
        [20, -55],
        [-16, -19],
        [-35, -27],
        [-21, -22],
        [-7, -18],
        [5, -10],
        [27, -6],
      ],
      [
        [18188, 93659],
        [18, -7],
        [32, 6],
        [47, 19],
        [60, 15],
        [74, 13],
        [19, -15],
        [12, 3],
        [22, 24],
        [4, 16],
        [-3, 18],
        [1, 40],
        [12, 23],
        [44, 50],
        [23, 18],
        [37, 9],
        [89, -6],
        [84, -28],
        [112, -28],
        [165, -71],
        [52, -30],
        [5, -26],
        [-29, -55],
        [-71, -79],
        [-57, -28],
        [-22, -18],
        [37, -12],
        [24, -20],
        [36, 29],
        [27, 33],
        [38, 27],
        [13, 3],
        [3, -6],
        [-7, -14],
        [-2, -14],
        [1, -13],
        [4, -8],
        [23, -4],
        [13, 5],
        [50, 38],
        [49, 59],
        [75, 38],
        [20, 19],
        [65, 16],
        [-1, 12],
        [3, 44],
        [-22, 19],
        [-77, 40],
        [-37, 48],
        [8, 37],
        [42, -5],
        [115, -4],
        [24, -5],
        [111, -62],
        [39, -39],
        [31, -19],
        [65, -28],
        [22, -23],
        [16, -9],
        [5, -10],
        [-5, -10],
        [-2, -23],
        [12, -8],
        [42, -9],
        [12, -9],
        [16, -30],
        [19, -50],
        [17, -55],
        [27, -103],
        [54, -137],
        [18, -86],
        [7, -16],
        [12, -11],
        [34, -16],
        [27, -22],
        [31, -7],
        [7, 2],
        [8, 19],
        [20, 31],
        [94, 59],
        [5, 9],
        [-11, 14],
        [-3, 9],
        [1, 7],
        [20, 5],
        [-66, 75],
        [-43, 71],
        [-27, 89],
        [-4, 25],
        [-4, 55],
        [-9, 15],
        [-15, 13],
        [-6, 17],
        [2, 21],
        [-3, 18],
        [-17, 37],
        [-67, 261],
        [0, 26],
        [9, 19],
        [24, 11],
        [38, 2],
        [13, 6],
        [-15, 10],
        [-25, 27],
        [-3, 13],
        [17, 26],
        [86, -11],
        [62, -24],
        [106, -55],
        [11, 3],
        [12, 28],
        [22, 17],
        [34, -6],
        [96, -40],
        [112, -71],
        [74, -36],
        [53, -48],
        [35, -45],
        [23, -35],
        [1, -13],
        [-5, -14],
        [5, -19],
        [16, -22],
        [8, -20],
        [7, -43],
        [15, -56],
        [3, -28],
        [99, -250],
        [19, -45],
        [12, -20],
        [69, -97],
        [37, -70],
        [3, -48],
        [5, -13],
        [2, -22],
        [-2, -30],
        [-8, -25],
        [-14, -22],
        [-14, -30],
        [-22, -67],
        [-1, -16],
        [15, -23],
        [97, -79],
        [59, -95],
        [28, -16],
        [74, -59],
        [81, -34],
        [27, -15],
        [26, -21],
        [7, -1],
        [16, 4],
        [4, 6],
        [1, 9],
        [-22, 45],
        [-2, 17],
        [11, 3],
        [83, -76],
        [45, -31],
        [61, -32],
        [105, -74],
        [15, -6],
        [57, 7],
        [15, -5],
        [10, -7],
        [4, -10],
        [2, -44],
        [16, -21],
        [90, 9],
        [25, -2],
        [16, -7],
        [13, -14],
        [20, -47],
        [17, -92],
        [1, -33],
        [-8, -56],
        [-13, -20],
        [-17, -7],
        [-48, 7],
        [-33, 17],
        [-18, 23],
        [-16, 48],
        [-7, 10],
        [-7, -10],
        [-16, -45],
        [-10, -19],
        [-13, -14],
        [-24, 4],
        [-35, 19],
        [-66, 50],
        [-23, 12],
        [-15, -2],
        [-31, -17],
        [-49, -31],
        [-20, -23],
        [8, -15],
        [6, -19],
        [3, -22],
        [-2, -17],
        [-6, -10],
        [-16, -13],
        [-34, -2],
        [-49, 9],
        [-39, 18],
        [-68, 46],
        [-15, 6],
        [-21, -10],
        [-8, -14],
        [13, -19],
        [34, -24],
        [42, -41],
        [11, -8],
        [11, 0],
        [4, -7],
        [5, -22],
        [-2, -38],
        [-21, -76],
        [-2, -18],
        [9, 4],
        [57, 75],
        [29, 21],
        [65, 32],
        [27, 24],
        [82, 7],
        [30, -13],
        [18, -23],
        [1, -10],
        [-21, -27],
        [-4, -14],
        [-1, -17],
        [2, -15],
        [5, -13],
        [14, -13],
        [26, 6],
        [8, -4],
        [14, -13],
        [9, -20],
        [0, -29],
        [-19, -62],
        [-34, -21],
        [-105, -37],
        [-36, -20],
        [-70, -14],
        [-26, -18],
        [-17, -5],
        [-74, 2],
        [-85, -11],
        [-98, 23],
        [-69, 10],
        [-79, 36],
        [-30, -9],
        [-31, -24],
        [-148, 28],
        [-18, 20],
        [6, 14],
        [35, 42],
        [2, 9],
        [-1, 8],
        [-68, 7],
        [-75, 23],
        [-75, 11],
        [-57, -3],
        [-37, 8],
        [-36, 19],
        [-19, 17],
        [-4, 16],
        [0, 17],
        [3, 34],
        [-5, 24],
        [-16, 18],
        [-34, 17],
        [-33, -2],
        [-28, -18],
        [-26, -35],
        [-50, -96],
        [-24, -16],
        [-65, -70],
        [-24, -17],
        [-117, -27],
        [-140, -11],
        [-52, -22],
        [-49, -40],
        [-60, -39],
        [-146, -48],
        [-135, -27],
        [-142, -12],
        [-105, -18],
        [-31, 9],
        [-47, -3],
        [-51, -27],
        [-57, -5],
        [-219, -10],
        [-100, -17],
        [-55, -5],
        [-44, 2],
        [-30, 8],
        [-28, 23],
        [-30, 37],
        [-60, 97],
        [-17, 41],
        [6, 70],
        [-4, 40],
        [-20, 88],
        [-4, 7],
        [-105, 33],
        [-70, 10],
        [-104, 2],
        [-128, -4],
        [-127, 10],
        [-68, 12],
        [-67, 19],
        [-114, 51],
        [-7, 5],
        [-8, 17],
        [-12, 28],
        [-28, 37],
        [-78, 82],
        [-31, 49],
        [-5, 13],
        [-7, 36],
        [-10, 59],
        [-3, 36],
        [9, 22],
        [7, 5],
        [162, 43],
        [284, 47],
        [261, 32],
        [118, -3],
        [69, -15],
        [70, -7],
        [126, -3],
        [160, -22],
        [31, 2],
        [72, 15],
        [21, 12],
        [113, -2],
        [22, 8],
        [20, 13],
        [-26, 25],
        [-108, 55],
        [-286, 97],
        [-70, 21],
        [-100, 22],
        [-58, 3],
        [-74, -12],
        [-28, 0],
        [-72, -19],
        [-69, -12],
        [-131, -12],
        [-189, -8],
        [-26, 2],
        [-39, 15],
        [-28, 4],
        [-185, -11],
        [-165, 15],
        [-188, 149],
        [-32, 46],
        [7, 18],
        [23, 20],
        [93, 58],
        [34, 13],
        [139, 31],
        [138, 38],
        [110, 35],
        [53, 12],
        [52, 2],
        [42, 11],
        [-9, 11],
        [-34, 13],
        [0, 18],
        [18, 9],
        [69, 9],
        [72, -11],
        [37, 3],
        [10, 13],
        [-10, 9],
        [-69, 21],
        [-331, -59],
        [-155, -5],
        [-107, -26],
        [-59, 1],
        [-70, 25],
        [-10, 8],
        [-1, 10],
        [22, 34],
        [75, 20],
        [38, 57],
        [-41, 1],
        [-134, -12],
        [-59, 5],
        [-79, 22],
        [-23, 26],
        [-10, 18],
        [-2, 23],
        [3, 63],
        [6, 34],
        [5, 8],
        [98, 105],
        [62, 22],
        [43, 33],
        [1, 13],
        [-10, 14],
        [-40, 34],
        [-17, 17],
        [-9, 17],
        [7, 25],
        [23, 36],
        [67, 57],
        [162, 114],
        [82, 48],
        [79, 26],
        [110, 55],
        [284, 91],
        [254, 92],
        [93, -24],
        [27, -19],
        [12, -16],
        [10, -23],
        [9, -29],
        [12, -63],
        [1, -32],
        [-2, -33],
        [-6, -29],
        [-9, -27],
        [-19, -32],
        [-29, -38],
        [-60, -65],
        [-7, -19],
      ],
      [
        [16740, 94534],
        [2, -2],
        [71, 53],
        [44, 3],
        [30, -5],
        [10, -6],
        [6, -11],
        [3, -23],
        [2, -58],
        [4, -7],
        [9, 3],
        [16, 14],
        [78, 85],
        [33, 23],
        [23, 7],
        [96, 13],
        [65, 0],
        [71, -8],
        [54, -12],
        [88, -35],
        [69, -40],
        [63, -43],
        [212, -161],
        [90, -47],
        [35, -27],
        [15, -20],
        [13, -25],
        [4, -23],
        [-5, -21],
        [-10, -15],
        [-21, -13],
        [-130, -54],
        [-69, -16],
        [-67, -24],
        [-162, -84],
        [-111, -40],
        [-144, -78],
        [-272, -126],
        [-32, -25],
        [-15, -18],
        [-77, -143],
        [-29, -33],
        [-71, -34],
        [-89, -9],
        [-25, -9],
        [-5, -50],
        [-32, -82],
        [-15, -55],
        [-22, -148],
        [-5, -15],
        [-16, -28],
        [-28, -29],
        [-86, -35],
        [-64, -18],
        [-86, -15],
        [-21, 10],
        [-21, 24],
        [-22, 2],
        [-13, -4],
        [-114, -103],
        [-109, -41],
        [-47, -38],
        [-33, -18],
        [-27, -5],
        [-44, 3],
        [-32, 17],
        [-29, 27],
        [-22, 28],
        [-56, 118],
        [-24, 40],
        [-21, 20],
        [-55, 72],
        [-15, 14],
        [-209, 91],
        [-101, 51],
        [-25, 18],
        [-23, 11],
        [-130, -7],
        [-18, 2],
        [-4, 8],
        [15, 27],
        [6, 16],
        [2, 17],
        [-2, 27],
        [2, 4],
        [49, 27],
        [-8, 5],
        [-5, 10],
        [-4, 15],
        [5, 11],
        [15, 6],
        [17, 24],
        [21, 43],
        [15, 24],
        [22, 16],
        [38, 41],
        [27, 17],
        [23, 21],
        [1, 9],
        [-10, 8],
        [-3, 16],
        [5, 50],
        [-1, 25],
        [5, 22],
        [8, 17],
        [11, 11],
        [97, 37],
        [4, 10],
        [2, 12],
        [-3, 14],
        [-5, 9],
        [-15, 8],
        [-26, 2],
        [-23, 21],
        [-5, 10],
        [9, 29],
        [44, 45],
        [14, 22],
        [48, 101],
        [86, 63],
        [23, 67],
        [65, 72],
        [0, 10],
        [-21, 24],
        [-60, 17],
        [-29, 26],
        [-19, 29],
        [-87, 170],
        [-15, 13],
        [-5, 20],
        [-18, 13],
        [4, 12],
        [341, 51],
        [235, 16],
        [243, 44],
        [68, 2],
        [52, -8],
        [52, -23],
        [69, -40],
        [90, -39],
        [170, -58],
        [106, -12],
        [-42, -45],
        [-6, -14],
        [0, -10],
      ],
      [
        [30697, 99663],
        [227, -10],
        [73, 4],
        [84, -26],
        [51, -3],
        [83, 5],
        [61, -6],
        [226, -6],
        [47, -10],
        [-1, -11],
        [-48, -26],
        [-66, -25],
        [-423, -82],
        [-31, -13],
        [83, -4],
        [121, 3],
        [94, 9],
        [111, 28],
        [37, 2],
        [70, 13],
        [137, 38],
        [109, 20],
        [49, -9],
        [42, -14],
        [28, -2],
        [15, 11],
        [23, 30],
        [14, 11],
        [36, 7],
        [22, -1],
        [35, -14],
        [40, -33],
        [36, -23],
        [20, 0],
        [83, 26],
        [41, 3],
        [96, -9],
        [39, -12],
        [7, -12],
        [-25, -13],
        [-16, -11],
        [-6, -10],
        [14, -11],
        [58, -23],
        [83, -51],
        [-1, -19],
        [-45, -38],
        [1, -9],
        [214, 40],
        [217, -18],
        [61, -12],
        [23, -15],
        [25, -24],
        [27, -34],
        [-19, -35],
        [-95, -55],
        [-98, -43],
        [-58, -38],
        [-88, -22],
        [-305, -92],
        [-149, -30],
        [-85, -30],
        [-39, -5],
        [-181, 6],
        [-48, -16],
        [-26, -28],
        [-58, -13],
        [-84, -9],
        [-172, -7],
        [-39, -31],
        [-10, -21],
        [-17, -16],
        [-15, -8],
        [-492, -111],
        [-10, -18],
        [50, -8],
        [63, 8],
        [717, 134],
        [137, 9],
        [128, -10],
        [-14, -31],
        [-181, -88],
        [-231, -80],
        [-115, -61],
        [-291, -104],
        [-237, -105],
        [-92, -52],
        [-122, -95],
        [-42, -23],
        [-51, -9],
        [-60, 4],
        [-54, 14],
        [-72, 35],
        [-65, 39],
        [-21, 7],
        [13, -19],
        [126, -132],
        [-14, -25],
        [-232, -27],
        [-103, -23],
        [-53, -6],
        [-38, 4],
        [-36, -2],
        [-36, -10],
        [-2, -10],
        [32, -11],
        [92, -9],
        [206, 30],
        [33, -2],
        [52, -15],
        [3, -13],
        [-55, -42],
        [-166, -50],
        [21, -2],
        [48, -18],
        [-1, -16],
        [-52, -38],
        [-25, -12],
        [-160, -35],
        [-70, -8],
        [-62, 4],
        [-281, 77],
        [-99, 11],
        [-94, 19],
        [-69, -3],
        [-74, -22],
        [33, -12],
        [136, -21],
        [114, -4],
        [47, -9],
        [18, -14],
        [49, -51],
        [8, -27],
        [-11, -25],
        [-15, -18],
        [-18, -10],
        [-30, -5],
        [-111, 3],
        [-41, -6],
        [-48, -15],
        [-61, -6],
        [-109, 3],
        [-128, -22],
        [-67, -4],
        [-76, 10],
        [-81, 24],
        [-86, 12],
        [-145, 11],
        [12, -14],
        [51, -6],
        [104, -37],
        [50, -51],
        [47, -8],
        [96, -42],
        [69, -6],
        [72, -14],
        [102, 18],
        [68, -3],
        [-14, -102],
        [-30, -10],
        [-164, 0],
        [-79, 15],
        [-34, 15],
        [-76, 17],
        [-68, -10],
        [-62, 2],
        [-42, -10],
        [-67, 1],
        [-174, -18],
        [-92, 0],
        [-68, 11],
        [-79, 4],
        [-90, -4],
        [7, -12],
        [38, -4],
        [55, -19],
        [52, -29],
        [43, -13],
        [51, 7],
        [52, 14],
        [190, 23],
        [84, 3],
        [75, -10],
        [49, -12],
        [35, -15],
        [44, -40],
        [109, -5],
        [84, -13],
        [133, -58],
        [37, -4],
        [15, -15],
        [-28, -38],
        [-4, -22],
        [-92, -44],
        [-144, -12],
        [-158, 4],
        [-113, -5],
        [-12, -6],
        [78, -11],
        [180, -51],
        [69, -28],
        [13, -16],
        [-100, -61],
        [-87, -122],
        [-29, -10],
        [-30, -3],
        [-77, 2],
        [-98, -30],
        [-74, -6],
        [-134, 11],
        [-154, -1],
        [-13, -19],
        [-7, -38],
        [1, -57],
        [9, -76],
        [-8, -56],
        [-26, -35],
        [-32, -26],
        [-58, -25],
        [-60, -16],
        [-44, -5],
        [-75, -1],
        [-213, -18],
        [-105, 1],
        [-81, 8],
        [-83, 25],
        [-140, 71],
        [-40, 16],
        [-38, 9],
        [1, -15],
        [42, -40],
        [35, -26],
        [28, -11],
        [-7, -12],
        [-62, -20],
        [-67, -8],
        [-81, 0],
        [-2, -7],
        [27, -21],
        [35, -19],
        [24, -6],
        [61, 6],
        [72, 24],
        [44, 8],
        [89, -6],
        [35, -9],
        [110, -54],
        [16, -2],
        [82, 27],
        [118, 1],
        [44, -21],
        [16, -43],
        [2, -34],
        [-12, -26],
        [27, -25],
        [67, -25],
        [52, -6],
        [37, 14],
        [56, 35],
        [24, 8],
        [23, -2],
        [34, -25],
        [46, -48],
        [5, -56],
        [-38, -64],
        [-46, -42],
        [-180, -69],
        [-55, -27],
        [-43, -28],
        [-63, -26],
        [-123, -34],
        [-64, -6],
        [-140, -37],
        [-31, -2],
        [-45, 6],
        [-9, 18],
        [11, 32],
        [15, 30],
        [19, 27],
        [0, 23],
        [-39, 35],
        [-28, 16],
        [-27, 9],
        [-54, -5],
        [-30, -10],
        [-33, -2],
        [-35, 6],
        [-29, 15],
        [-59, 63],
        [-22, 8],
        [-29, -2],
        [-25, 9],
        [-23, 20],
        [-38, 19],
        [10, -15],
        [38, -35],
        [26, -36],
        [15, -35],
        [-7, -27],
        [-321, -16],
        [-138, 8],
        [-28, 25],
        [-66, 103],
        [-14, -183],
        [-241, -30],
        [-56, 5],
        [-93, 20],
        [-121, 50],
        [-50, 33],
        [-20, 32],
        [-15, 18],
        [-9, 3],
        [-30, -39],
        [-33, -81],
        [-83, 21],
        [-104, 20],
        [-38, 80],
        [-2, -115],
        [-169, 15],
        [-81, -4],
        [-23, 100],
        [-4, 112],
        [-33, -71],
        [14, -59],
        [5, -73],
        [-72, 21],
        [-157, 10],
        [-56, 10],
        [7, 96],
        [13, 97],
        [202, 96],
        [60, 45],
        [44, 18],
        [69, 12],
        [89, 7],
        [60, -6],
        [70, 6],
        [82, 17],
        [59, 5],
        [11, 6],
        [-17, 9],
        [-63, 62],
        [-23, 14],
        [-23, 6],
        [-45, 3],
        [-45, 27],
        [-25, 24],
        [-28, 32],
        [-43, 62],
        [-44, 69],
        [22, 37],
        [72, 30],
        [72, 21],
        [73, 11],
        [60, 0],
        [73, -16],
        [103, -32],
        [59, -36],
        [74, -76],
        [49, -61],
        [39, -27],
        [177, -50],
        [59, -8],
        [70, 4],
        [143, 13],
        [70, 14],
        [31, 16],
        [16, 23],
        [21, 18],
        [63, 41],
        [96, 85],
        [54, 68],
        [12, 22],
        [11, 28],
        [9, 32],
        [-30, -17],
        [-167, -167],
        [-35, -30],
        [-97, -51],
        [-42, -8],
        [-66, 2],
        [-89, 23],
        [-104, -36],
        [-63, 9],
        [-56, 25],
        [0, 118],
        [-71, 95],
        [78, 48],
        [65, 29],
        [115, 77],
        [26, 1],
        [84, -12],
        [-46, 17],
        [-45, 26],
        [-100, -7],
        [35, 166],
        [-67, -124],
        [-68, -65],
        [-42, -33],
        [-46, -18],
        [-176, -17],
        [43, 61],
        [40, 90],
        [-42, -34],
        [-99, -50],
        [-74, -27],
        [-61, -14],
        [-118, 4],
        [-59, 25],
        [17, 62],
        [0, 77],
        [36, 35],
        [54, 46],
        [59, 61],
        [40, 63],
        [158, 29],
        [153, 11],
        [128, 34],
        [63, 6],
        [60, -16],
        [244, -26],
        [100, -20],
        [44, -15],
        [33, -5],
        [34, 22],
        [44, 21],
        [152, -2],
        [42, 3],
        [39, 10],
        [47, 20],
        [56, 30],
        [8, 15],
        [-38, -1],
        [-39, -8],
        [-58, -21],
        [-56, -12],
        [-57, 2],
        [-115, 19],
        [-200, 3],
        [-102, 8],
        [-47, 9],
        [-27, 12],
        [-23, 19],
        [-20, 25],
        [10, 17],
        [41, 10],
        [35, 2],
        [57, -14],
        [63, -23],
        [69, -3],
        [-24, 21],
        [-88, 42],
        [-60, 36],
        [-51, 44],
        [-40, 43],
        [-87, 68],
        [-70, 68],
        [-50, 30],
        [-52, 15],
        [-160, 18],
        [-32, 12],
        [-76, 59],
        [-20, 100],
        [-34, 61],
        [32, 77],
        [54, 35],
        [318, -27],
        [135, 4],
        [172, -10],
        [92, -19],
        [106, -47],
        [94, -53],
        [91, -36],
        [83, -48],
        [91, -73],
        [54, -34],
        [47, -21],
        [62, -19],
        [118, -23],
        [103, -7],
        [53, 3],
        [57, 16],
        [41, 20],
        [-45, 6],
        [-119, -3],
        [-82, 11],
        [-47, 23],
        [-50, 31],
        [-79, 59],
        [-60, 39],
        [-131, 63],
        [-97, 62],
        [-78, 60],
        [-7, 25],
        [56, 18],
        [69, 13],
        [432, 40],
        [258, 48],
        [105, 56],
        [12, 13],
        [346, 78],
        [244, 29],
        [94, 5],
        [85, 13],
        [3, 7],
        [-74, 11],
        [-75, 5],
        [-171, 0],
        [-152, 9],
        [-46, 18],
        [10, 28],
        [14, 25],
        [46, 40],
        [49, 31],
        [209, 88],
        [140, 38],
        [41, 25],
        [-302, -57],
        [-106, -41],
        [-106, -62],
        [-55, -19],
        [-39, 5],
        [-35, -6],
        [-31, -16],
        [-25, -30],
        [-21, -44],
        [-19, -30],
        [-17, -16],
        [-43, -23],
        [-104, -44],
        [-242, -63],
        [-82, -16],
        [-70, -3],
        [-226, -35],
        [-68, -2],
        [-76, 11],
        [33, 31],
        [119, 56],
        [32, 25],
        [-77, -5],
        [-78, -16],
        [-172, -12],
        [-69, -23],
        [-66, -42],
        [-53, -26],
        [-40, -11],
        [-55, -6],
        [-202, -3],
        [-45, 2],
        [-116, 32],
        [-103, -11],
        [-43, 2],
        [-78, 23],
        [-23, 14],
        [3, 20],
        [45, 37],
        [53, 35],
        [170, 78],
        [109, 37],
        [158, 31],
        [369, 35],
        [17, 26],
        [-374, -29],
        [-318, -36],
        [-52, -14],
        [-74, -35],
        [-235, -129],
        [-70, -33],
        [-106, -7],
        [-81, 11],
        [-63, 16],
        [-109, 38],
        [-83, 21],
        [-39, 13],
        [-23, 14],
        [-18, 17],
        [-15, 18],
        [27, 16],
        [210, 33],
        [284, -6],
        [128, 7],
        [125, 20],
        [186, 51],
        [201, 71],
        [40, 22],
        [-74, 5],
        [-54, -5],
        [-131, -26],
        [-208, -68],
        [-181, -24],
        [-448, -13],
        [-142, -19],
        [-63, 4],
        [-47, 19],
        [-52, 34],
        [9, 28],
        [107, 31],
        [84, 7],
        [15, 7],
        [-119, 30],
        [-11, 15],
        [69, 34],
        [148, 50],
        [76, 15],
        [137, 10],
        [142, -5],
        [5, 9],
        [-140, 19],
        [-105, 4],
        [-138, -14],
        [-369, -80],
        [-31, 2],
        [-53, 14],
        [15, 20],
        [197, 85],
        [6, 13],
        [-141, -2],
        [-42, 4],
        [-40, 10],
        [-58, -9],
        [-75, -27],
        [-52, -12],
        [-31, 4],
        [-78, 33],
        [10, 27],
        [62, 35],
        [57, 25],
        [77, 23],
        [126, 29],
        [90, 10],
        [148, 0],
        [70, 13],
        [62, 22],
        [78, 36],
        [86, 25],
        [140, 20],
        [118, -6],
        [65, -18],
        [51, -31],
        [56, -24],
        [5, 19],
        [46, 24],
        [58, 10],
        [69, -3],
        [61, -12],
        [79, -28],
        [63, -13],
        [30, 0],
        [38, 16],
        [99, 1],
        [-2, 6],
        [-32, 17],
        [-40, 12],
        [-352, 82],
        [-10, 18],
        [119, 17],
        [74, 21],
        [35, 5],
        [87, 43],
        [57, 22],
        [105, 25],
        [43, -7],
        [53, -22],
        [51, -14],
        [151, -13],
        [65, -14],
        [115, -81],
        [46, -26],
        [66, -27],
        [39, -10],
        [77, -6],
        [8, 15],
        [-92, 38],
        [-25, 22],
        [11, 18],
        [20, 10],
        [28, 1],
        [72, -17],
        [192, -56],
        [288, -67],
        [110, -15],
        [68, -24],
        [62, -29],
        [61, -20],
        [11, 2],
        [-57, 45],
        [-139, 55],
        [-371, 94],
        [-147, 50],
        [-72, 33],
        [-53, 34],
        [-1, 19],
        [50, 21],
        [68, 17],
        [85, 7],
        [11, 7],
        [-78, 26],
        [-45, 22],
        [1, 16],
        [93, 12],
        [57, -4],
        [108, -28],
        [91, -13],
        [15, 8],
        [-94, 75],
        [-9, 17],
        [14, 9],
        [33, 10],
        [98, -5],
        [161, -37],
        [290, -18],
        [79, 3],
        [-12, 8],
        [-115, 23],
        [-121, 30],
        [-53, 18],
        [-39, 24],
        [-47, 21],
        [-4, 9],
        [75, 16],
        [196, -3],
        [182, -24],
        [155, 9],
        [97, -6],
        [39, -8],
        [70, -26],
        [224, -96],
        [23, -15],
        [26, -23],
        [27, -32],
        [38, -8],
        [74, 21],
        [49, 21],
        [-21, 20],
        [-124, 46],
        [-28, 20],
        [-61, 34],
        [-139, 59],
        [-37, 28],
        [-23, 24],
        [383, 22],
        [370, -20],
        [60, -14],
        [40, -19],
        [38, -30],
        [60, -30],
        [119, -46],
        [171, -29],
        [-33, 20],
        [-127, 51],
        [-58, 35],
        [1, 23],
        [10, 18],
        [20, 13],
        [144, 47],
        [206, 15],
        [24, -3],
        [161, -73],
        [76, -30],
        [53, -12],
        [2, 5],
        [-74, 34],
        [-56, 18],
        [-6, 12],
        [94, 35],
        [59, 9],
        [251, 10],
        [28, -3],
        [24, -10],
        [59, -39],
        [22, -5],
      ],
      [
        [23476, 96654],
        [70, -22],
        [76, 11],
        [81, 2],
        [182, -10],
        [120, 6],
        [31, -3],
        [47, -14],
        [25, -18],
        [23, -28],
        [-59, -18],
        [-50, -89],
        [-7, -5],
        [-54, -1],
        [-27, -7],
        [-159, 13],
        [-438, 5],
        [-19, 11],
        [-58, 53],
        [-4, 21],
        [11, 24],
        [12, 16],
        [14, 8],
        [128, 39],
        [55, 6],
      ],
      [
        [24016, 95061],
        [18, -43],
        [3, -18],
        [1, -37],
        [-8, -49],
        [-5, -9],
        [-7, -4],
        [-4, -13],
        [0, -21],
        [-7, -12],
        [-15, -5],
        [-99, -10],
        [-62, 2],
        [-91, -6],
        [-45, 3],
        [-30, 11],
        [-43, 22],
        [-91, 55],
        [-45, 2],
        [-116, 19],
        [-63, 58],
        [-24, 11],
        [-25, -18],
        [-7, 4],
        [-6, 12],
        [-7, 20],
        [-12, 10],
        [-48, -5],
        [-9, 6],
        [-2, 18],
        [0, 15],
        [9, 23],
        [51, 65],
        [25, 5],
        [31, 12],
        [17, 35],
        [-2, 33],
        [48, 49],
        [28, 15],
        [51, 34],
        [172, 54],
        [48, 4],
        [63, -4],
        [62, -17],
        [47, -28],
        [97, -70],
        [44, -43],
        [23, -43],
        [21, -25],
        [26, -54],
        [-9, -21],
        [-6, -28],
        [3, -14],
      ],
      [
        [22221, 94438],
        [235, -123],
        [33, 10],
        [71, 6],
        [73, 19],
        [102, 15],
        [63, 27],
        [26, 8],
        [45, 5],
        [25, 0],
        [70, -15],
        [29, -11],
        [15, -11],
        [16, -19],
        [28, -49],
        [4, -18],
        [-1, -5],
        [-26, -30],
        [-17, -13],
        [-36, -12],
        [-30, -4],
        [-27, -21],
        [-29, 5],
        [-9, -19],
        [4, -12],
        [8, -6],
        [15, 1],
        [17, 8],
        [33, -4],
        [18, -13],
        [15, -21],
        [-11, -20],
        [-59, -27],
        [-87, -31],
        [-105, -98],
        [-56, -41],
        [-11, -13],
        [-6, -13],
        [2, -24],
        [2, -10],
        [16, -4],
        [51, 34],
        [34, 16],
        [33, 9],
        [60, 0],
        [25, -5],
        [44, -20],
        [41, -32],
        [9, -11],
        [-4, -11],
        [-17, -12],
        [-2, -8],
        [39, -15],
        [43, -43],
        [3, -26],
        [-19, -26],
        [-5, -18],
        [9, -8],
        [21, 5],
        [51, 29],
        [55, 15],
        [22, -2],
        [14, -6],
        [14, -40],
        [12, -45],
        [2, -37],
        [-9, -31],
        [-13, -23],
        [-33, -30],
        [-30, -11],
        [-16, 0],
        [2, -5],
        [35, -25],
        [15, -19],
        [6, -19],
        [-2, -18],
        [-5, -16],
        [-41, -57],
        [2, -9],
        [12, -3],
        [26, -33],
        [3, -78],
        [-93, -24],
        [-22, -18],
        [-25, -28],
        [-29, -22],
        [-67, -22],
        [-33, -3],
        [-167, 19],
        [-17, 11],
        [-11, 19],
        [-7, 29],
        [-1, 22],
        [2, 16],
        [0, 9],
        [-5, 3],
        [-19, -16],
        [-19, -29],
        [11, -33],
        [52, -91],
        [10, -39],
        [2, -16],
        [-3, -13],
        [-59, -53],
        [-34, -18],
        [-36, -9],
        [-33, 7],
        [-32, 22],
        [-24, 10],
        [-51, -1],
        [-15, 11],
        [-15, 22],
        [-35, 76],
        [-50, 54],
        [-42, 61],
        [-108, 89],
        [-56, 53],
        [-75, 86],
        [-31, 19],
        [-26, 8],
        [-51, 6],
        [-12, 11],
        [-19, 29],
        [-32, 22],
        [-11, 3],
        [-19, -6],
        [-50, -20],
        [-63, 22],
        [-14, 15],
        [-8, 25],
        [-8, 14],
        [-21, 13],
        [-18, 31],
        [-119, 63],
        [-71, 72],
        [-14, 26],
        [-2, 10],
        [7, 35],
        [17, 39],
        [21, 39],
        [14, 16],
        [46, 33],
        [37, 8],
        [51, -4],
        [27, -8],
        [24, -22],
        [11, -26],
        [12, -18],
        [38, -19],
        [20, -15],
        [30, -36],
        [24, -43],
        [21, -14],
        [52, -5],
        [53, 7],
        [114, 27],
        [5, 4],
        [7, 16],
        [13, 98],
        [8, 0],
        [39, -45],
        [11, -5],
        [16, 9],
        [10, 23],
        [-1, 10],
        [-24, 53],
        [-15, 23],
        [-13, 15],
        [-14, 4],
        [-31, -4],
        [-24, 10],
        [-5, 15],
        [4, 19],
        [13, 21],
        [14, 12],
        [27, 6],
        [32, -6],
        [44, -25],
        [28, -6],
        [40, 7],
        [-51, 15],
        [-70, 58],
        [-30, 11],
        [-36, -25],
        [-25, -9],
        [-47, -12],
        [-37, -2],
        [-156, 89],
        [-9, 9],
        [-11, 23],
        [1, 11],
        [16, 17],
        [38, 22],
        [58, 13],
        [39, 3],
        [34, -17],
        [49, -44],
        [43, -26],
        [4, 9],
        [-8, 26],
        [-20, 38],
        [-14, 10],
        [-34, 11],
        [-33, 26],
        [-15, 20],
        [-8, 20],
        [-1, 22],
        [7, 15],
        [13, 8],
        [120, 22],
        [83, -24],
        [53, -4],
        [22, 30],
        [-7, 6],
        [-28, -8],
        [-32, 0],
        [-19, 17],
        [-1, 9],
        [25, 23],
        [37, 10],
      ],
      [
        [26411, 89435],
        [10, -7],
        [11, 4],
        [20, 29],
        [44, 82],
        [13, 7],
        [18, -1],
        [65, -52],
        [24, -30],
        [13, -41],
        [14, -16],
        [51, -21],
        [49, -7],
        [65, -21],
        [23, -16],
        [51, -78],
        [6, -5],
        [59, -32],
        [89, -72],
        [23, -10],
        [87, -24],
        [32, -17],
        [30, -27],
        [34, -49],
        [39, -77],
        [31, -123],
        [2, -24],
        [-3, -14],
        [-12, -16],
        [-50, -50],
        [4, -9],
        [48, 4],
        [105, 31],
        [65, -23],
        [22, -3],
        [5, 1],
        [24, 39],
        [25, -6],
        [38, -38],
        [24, -30],
        [10, -23],
        [-2, -13],
        [-25, -5],
        [60, -22],
        [53, -35],
        [-11, -23],
        [-57, -51],
        [-57, -45],
        [-67, -66],
        [-17, -11],
        [-9, 0],
        [-37, 12],
        [-54, 32],
        [-164, 73],
        [-51, 15],
        [-64, 9],
        [-9, 17],
        [-16, 110],
        [-29, 19],
        [-99, 23],
        [-29, 13],
        [-2, 22],
        [7, 38],
        [-14, 19],
        [-33, -1],
        [-33, -8],
        [-53, -26],
        [-25, -24],
        [-9, -25],
        [-6, -55],
        [-7, -26],
        [-18, -35],
        [-82, -88],
        [-33, -27],
        [-33, -7],
        [-13, -9],
        [-22, -32],
        [-33, -80],
        [-14, -23],
        [-21, -24],
        [-46, -36],
        [-46, -29],
        [-77, -33],
        [-42, -11],
        [-29, 11],
        [-20, 76],
        [-41, 223],
        [-7, 15],
        [-8, 9],
        [-10, 4],
        [-138, -29],
        [-76, 3],
        [-75, -50],
        [-19, -3],
        [-39, 1],
        [-27, 8],
        [-7, 6],
        [-5, 21],
        [2, 23],
        [9, 25],
        [34, 66],
        [28, 41],
        [13, 13],
        [129, 74],
        [31, 24],
        [16, 25],
        [0, 25],
        [-6, 33],
        [-23, 80],
        [-5, 73],
        [0, 36],
        [8, 55],
        [33, 135],
        [11, 66],
        [20, 234],
        [11, 67],
        [16, 62],
        [15, 37],
        [42, 73],
        [31, 30],
        [41, 20],
        [8, -2],
        [8, -9],
        [15, -31],
        [56, -29],
        [18, -28],
        [12, -31],
        [7, -40],
        [-7, -18],
        [-26, -27],
        [-5, -11],
        [1, -9],
        [51, -42],
        [38, -102],
      ],
      [
        [22861, 95890],
        [3, -26],
        [-4, -19],
        [-9, -14],
        [-1, -17],
        [9, -18],
        [37, -45],
        [12, -25],
        [2, -25],
        [-2, -17],
        [-23, -32],
        [-10, -43],
        [-1, -22],
        [14, -35],
        [0, -16],
        [-26, -28],
        [-54, -25],
        [8, -13],
        [117, -30],
        [9, -7],
        [0, -70],
        [20, -76],
        [-8, -1],
        [-28, 24],
        [-52, 28],
        [-63, -53],
        [7, -90],
        [42, -40],
        [12, -23],
        [-4, -13],
        [-35, -6],
        [-12, 2],
        [-35, 19],
        [-21, 27],
        [-7, -1],
        [-6, -13],
        [4, -14],
        [24, -24],
        [11, -29],
        [-11, -8],
        [-36, -8],
        [-48, 0],
        [-76, -13],
        [-38, -2],
        [-36, 7],
        [-49, 1],
        [-41, -3],
        [-24, 6],
        [-23, 14],
        [-26, -3],
        [-57, -35],
        [-89, 11],
        [-80, 3],
        [-16, 11],
        [-18, 23],
        [-35, 70],
        [6, 18],
        [86, 9],
        [2, 6],
        [-61, 25],
        [-70, 18],
        [-32, 15],
        [7, 27],
        [-2, 7],
        [120, 32],
        [88, 62],
        [54, 25],
        [4, 12],
        [46, 13],
        [106, 7],
        [4, 18],
        [-200, -10],
        [-274, -35],
        [-85, -18],
        [-71, 10],
        [-300, -54],
        [-13, 0],
        [-31, 17],
        [-27, 33],
        [19, 22],
        [88, 43],
        [44, 37],
        [-5, 21],
        [35, 35],
        [56, 5],
        [96, -29],
        [49, -29],
        [45, -14],
        [39, 3],
        [41, 20],
        [-11, 3],
        [-69, -11],
        [-8, 3],
        [-35, 33],
        [-15, 20],
        [-10, 21],
        [-1, 15],
        [21, 28],
        [-79, 9],
        [-29, 19],
        [-14, 24],
        [3, 11],
        [25, 28],
        [67, 39],
        [-8, 10],
        [-97, -1],
        [-22, 5],
        [-42, 24],
        [9, 27],
        [39, 39],
        [30, 23],
        [19, 7],
        [31, 0],
        [73, -15],
        [21, -8],
        [56, -38],
        [14, -21],
        [-2, -21],
        [11, -15],
        [43, -22],
        [186, -115],
        [35, -27],
        [23, -12],
        [43, -9],
        [26, 2],
        [20, 8],
        [3, 10],
        [-80, 40],
        [-20, 22],
        [-17, 29],
        [7, 9],
        [24, 4],
        [58, -4],
        [69, 8],
        [-76, 12],
        [-50, 16],
        [-52, 1],
        [-64, 26],
        [0, 8],
        [16, 9],
        [87, 11],
        [17, 5],
        [1, 7],
        [-29, 18],
        [-26, 9],
        [-132, 21],
        [-47, 24],
        [-15, 11],
        [-4, 11],
        [17, 28],
        [71, 35],
        [51, 17],
        [89, 12],
        [71, -1],
        [40, -5],
        [94, -59],
        [45, -39],
        [77, 7],
        [-22, 40],
        [-15, 45],
        [23, 17],
        [64, 29],
        [51, -15],
        [66, -40],
        [15, -13],
        [75, -25],
        [44, -8],
        [23, -13],
        [7, -17],
      ],
      [
        [21270, 97531],
        [65, -11],
        [77, -37],
        [73, -79],
        [4, -10],
        [0, -13],
        [-3, -16],
        [-9, -21],
        [-13, -12],
        [37, -15],
        [4, -8],
        [-3, -17],
        [4, -12],
        [23, 12],
        [19, 19],
        [5, 12],
        [4, 32],
        [57, 17],
        [60, 23],
        [28, 5],
        [47, -5],
        [112, -56],
        [43, -5],
        [16, -7],
        [14, -13],
        [1, -14],
        [-23, -32],
        [-9, -20],
        [6, -13],
        [58, -11],
        [134, 22],
        [117, -53],
        [65, -63],
        [47, -21],
        [8, -11],
        [-14, -11],
        [-13, -29],
        [-39, -22],
        [-8, -10],
        [21, -26],
        [1, -17],
        [-2, -22],
        [7, -13],
        [53, -14],
        [119, -93],
        [22, -26],
        [14, -32],
        [1, -11],
        [-17, -18],
        [-19, -51],
        [-10, -12],
        [-49, -10],
        [-88, -9],
        [-83, -17],
        [-88, 22],
        [-87, 34],
        [-26, 22],
        [-22, 28],
        [-5, 10],
        [-9, 44],
        [-4, 9],
        [-37, 24],
        [-32, 37],
        [-63, 3],
        [-147, 38],
        [-63, 8],
        [-64, -2],
        [-89, -15],
        [-17, 4],
        [-15, 11],
        [-14, 18],
        [-3, 14],
        [14, 23],
        [-262, -29],
        [-75, -35],
        [-105, 6],
        [-53, 14],
        [-69, 33],
        [-32, 29],
        [-30, 38],
        [-3, 29],
        [24, 20],
        [25, 11],
        [26, 4],
        [142, -23],
        [125, -11],
        [54, 11],
        [25, 32],
        [-30, 16],
        [-120, 7],
        [26, 17],
        [101, 16],
        [53, 26],
        [-10, 8],
        [-30, 10],
        [-137, -2],
        [-48, 9],
        [-3, 9],
        [11, 10],
        [77, 51],
        [-3, 10],
        [-33, 16],
        [-29, 22],
        [-11, 3],
        [-68, -20],
        [-95, -75],
        [-22, -11],
        [-22, 1],
        [-21, 12],
        [1, 16],
        [21, 19],
        [44, 58],
        [-4, 21],
        [-42, 14],
        [-114, -10],
        [-63, -1],
        [-9, 17],
        [-3, 30],
        [3, 29],
        [15, 45],
        [22, 35],
        [13, 12],
        [151, -7],
        [245, 21],
        [71, 2],
        [78, -21],
      ],
      [
        [24476, 98578],
        [36, -48],
        [134, -115],
        [61, -42],
        [103, -51],
        [13, -19],
        [1, -8],
        [-3, -28],
        [30, -10],
        [88, -16],
        [99, -28],
        [18, 1],
        [34, 17],
        [42, 5],
        [54, -4],
        [26, -12],
        [19, -18],
        [8, -13],
        [1, -10],
        [-19, -19],
        [2, -8],
        [12, -8],
        [2, -11],
        [-20, -41],
        [5, -15],
        [50, -37],
        [45, -19],
        [89, -20],
        [58, 2],
        [36, -13],
        [1, 8],
        [-17, 23],
        [-34, 34],
        [-65, 18],
        [-9, 19],
        [-5, 34],
        [6, 22],
        [33, 18],
        [27, 6],
        [84, 1],
        [45, -8],
        [80, -25],
        [8, -14],
        [4, -27],
        [3, -54],
        [-2, -12],
        [-67, -30],
        [-15, -21],
        [17, -6],
        [58, -5],
        [90, -19],
        [35, -2],
        [35, -44],
        [27, -41],
        [-20, -52],
        [-26, -82],
        [-21, -19],
        [-21, -28],
        [15, -6],
        [88, 12],
        [18, 4],
        [59, 28],
        [86, -7],
        [29, -7],
        [15, -10],
        [26, -31],
        [22, -41],
        [16, 3],
        [40, 51],
        [15, 12],
        [20, 12],
        [9, -2],
        [40, -47],
        [91, -82],
        [31, -34],
        [6, -26],
        [-39, -29],
        [-30, -15],
        [-223, -62],
        [-99, -35],
        [-50, -28],
        [-25, -9],
        [-54, 5],
        [-12, -5],
        [-16, -44],
        [-18, -19],
        [-46, -30],
        [-65, -55],
        [-38, -24],
        [-68, 18],
        [-17, 25],
        [-10, 58],
        [-1, 24],
        [2, 13],
        [9, 20],
        [29, 49],
        [-3, 6],
        [-14, -5],
        [-45, -24],
        [-18, -13],
        [-16, -23],
        [-8, -38],
        [7, -70],
        [-6, -28],
        [-18, -14],
        [7, -11],
        [53, -15],
        [9, -7],
        [6, -12],
        [3, -17],
        [-3, -16],
        [-16, -25],
        [-30, -10],
        [-38, 11],
        [-82, 61],
        [-36, -3],
        [-9, -7],
        [8, -22],
        [25, -48],
        [5, -41],
        [-12, -33],
        [-23, -53],
        [-16, -29],
        [-9, -3],
        [-41, -1],
        [-35, 14],
        [-104, 93],
        [-51, 39],
        [-76, 78],
        [-19, 15],
        [-12, 4],
        [-10, -33],
        [20, -31],
        [68, -72],
        [41, -53],
        [29, -43],
        [4, -19],
        [-9, -7],
        [-15, 4],
        [-20, 16],
        [-62, 25],
        [-26, 15],
        [-16, 16],
        [-31, 13],
        [-45, 8],
        [-45, 2],
        [-45, -5],
        [-8, -8],
        [50, -23],
        [18, -12],
        [13, -17],
        [9, -19],
        [-17, -13],
        [-63, -7],
        [-84, 5],
        [-137, 17],
        [-136, 28],
        [-125, 44],
        [-91, 44],
        [-36, 23],
        [-11, 17],
        [34, 16],
        [119, 19],
        [119, 13],
        [-19, 11],
        [-217, 25],
        [-71, 4],
        [-38, -6],
        [-44, 4],
        [-34, 20],
        [-45, 37],
        [-23, 25],
        [3, 10],
        [17, 5],
        [99, -4],
        [13, 3],
        [-49, 19],
        [-157, 37],
        [-59, 33],
        [-11, 12],
        [-4, 13],
        [1, 12],
        [59, 25],
        [183, 59],
        [62, 9],
        [63, 1],
        [44, 16],
        [38, 56],
        [189, 20],
        [146, 25],
        [12, 7],
        [-109, -4],
        [-155, 5],
        [-65, 37],
        [-45, 7],
        [-49, 0],
        [-57, -12],
        [-98, -35],
        [-47, -9],
        [-107, -36],
        [-29, 3],
        [-15, 4],
        [-5, 7],
        [19, 24],
        [28, 17],
        [-5, 5],
        [-42, 4],
        [-48, -1],
        [-34, -6],
        [-122, -32],
        [-55, -23],
        [-17, -3],
        [-59, 38],
        [-95, 20],
        [-21, 16],
        [20, 63],
        [27, 13],
        [73, 11],
        [217, 57],
        [13, 11],
        [20, 29],
        [-50, -7],
        [-109, -27],
        [-90, -14],
        [-71, 0],
        [-51, 4],
        [-33, 8],
        [-40, 17],
        [-128, 83],
        [-36, 39],
        [-4, 36],
        [-9, 26],
        [-38, 65],
        [276, -40],
        [107, -8],
        [208, -2],
        [10, 3],
        [3, 11],
        [-4, 17],
        [2, 13],
        [7, 9],
        [77, 23],
        [12, 8],
        [-91, 4],
        [-168, -39],
        [-59, 0],
        [-67, 56],
        [-71, -10],
        [-34, 4],
        [-53, 14],
        [-26, 12],
        [-16, 15],
        [-7, 13],
        [2, 10],
        [14, 12],
        [62, 16],
        [28, 1],
        [73, -10],
        [55, 1],
        [-18, 17],
        [-79, 43],
        [-70, 47],
        [5, 80],
        [57, 17],
        [60, 2],
        [58, -20],
        [77, -2],
        [56, -23],
        [37, -44],
        [44, 1],
        [69, -8],
        [155, 1],
        [-28, 15],
        [-49, 14],
        [-109, 18],
        [-53, 63],
        [-114, 33],
        [-88, 17],
        [1, 15],
        [67, 79],
        [80, 28],
        [128, -10],
        [85, 14],
        [108, 28],
        [106, -13],
        [28, 2],
        [16, 8],
        [14, 17],
        [0, 15],
        [-14, 14],
        [-34, 17],
        [-135, 2],
        [-60, 7],
        [-23, 9],
        [-7, 14],
        [-4, 14],
        [0, 15],
        [4, 9],
        [11, 5],
        [33, 6],
        [126, 0],
        [76, 8],
        [83, -10],
        [173, -39],
        [55, -20],
        [60, -34],
        [31, -30],
      ],
      [
        [23807, 96147],
        [52, -5],
        [44, 8],
        [38, -2],
        [56, -23],
        [53, -36],
        [40, -16],
        [13, -8],
        [5, -9],
        [6, -27],
        [0, -12],
        [-3, -10],
        [-18, -25],
        [-15, -30],
        [-30, -27],
        [-16, -20],
        [-14, -26],
        [31, 15],
        [119, 85],
        [77, -10],
        [117, 7],
        [141, 35],
        [67, 5],
        [67, -3],
        [50, -10],
        [108, -47],
        [37, -22],
        [14, -16],
        [3, -11],
        [-22, -18],
        [-67, 11],
        [-111, 10],
        [-20, 5],
        [-18, 0],
        [-12, -6],
        [8, -25],
        [22, -5],
        [133, -6],
        [436, -78],
        [19, -25],
        [-5, -11],
        [-16, -12],
        [-31, -17],
        [-252, -18],
        [-143, 16],
        [-120, 26],
        [-41, -6],
        [35, -35],
        [72, -10],
        [61, -21],
        [25, -17],
        [128, -13],
        [21, -13],
        [40, -34],
        [33, -3],
        [33, -24],
        [27, -41],
        [13, -5],
        [39, 7],
        [64, -36],
        [21, -19],
        [0, -14],
        [-15, -22],
        [-29, -31],
        [-73, -35],
        [-6, -11],
        [86, 4],
        [16, -5],
        [101, -63],
        [13, -1],
        [8, 6],
        [10, 23],
        [-4, 21],
        [-13, 28],
        [4, 21],
        [19, 13],
        [19, 7],
        [19, 0],
        [21, -8],
        [102, -77],
        [131, 37],
        [24, -17],
        [20, -30],
        [9, -5],
        [49, 61],
        [30, 15],
        [123, -73],
        [75, -16],
        [30, -15],
        [55, -18],
        [79, -6],
        [13, 27],
        [-45, 35],
        [26, 15],
        [109, 29],
        [58, -4],
        [107, 42],
        [66, 5],
        [40, 0],
        [133, 63],
        [31, 10],
        [23, 22],
        [52, -3],
        [141, -36],
        [40, 3],
        [150, 36],
        [56, 8],
        [55, -1],
        [141, -21],
        [105, -22],
        [33, -12],
        [-12, -29],
        [6, -9],
        [13, -7],
        [35, -8],
        [131, -1],
        [57, -7],
        [46, -28],
        [10, -11],
        [-1, -11],
        [-45, -30],
        [7, -7],
        [45, -6],
        [100, -4],
        [22, -7],
        [21, -37],
        [21, -52],
        [0, -20],
        [-35, -35],
        [-95, -46],
        [-106, -39],
        [-7, -10],
        [34, -18],
        [35, -9],
        [27, 2],
        [85, 18],
        [19, -1],
        [38, -18],
        [18, -17],
        [17, -24],
        [-30, -22],
        [-121, -27],
        [-72, 36],
        [-24, 7],
        [-16, -3],
        [9, -15],
        [35, -28],
        [11, -18],
        [-12, -9],
        [-6, -18],
        [1, -26],
        [-3, -27],
        [-11, -42],
        [-5, -2],
        [-263, -8],
        [-32, -8],
        [-74, -29],
        [-56, -15],
        [-36, -2],
        [-36, 5],
        [-96, 31],
        [-64, -6],
        [-25, 3],
        [-55, 20],
        [-13, 10],
        [-22, 27],
        [-16, 37],
        [1, 22],
        [7, 32],
        [-4, 17],
        [-16, 0],
        [-17, 6],
        [-52, 33],
        [-32, 10],
        [-6, -6],
        [10, -25],
        [6, -8],
        [34, -19],
        [6, -21],
        [-14, -55],
        [-5, -8],
        [-34, -40],
        [-25, -12],
        [-68, -1],
        [-105, -28],
        [-50, -4],
        [-67, 7],
        [-42, 12],
        [-27, 15],
        [-27, 21],
        [-13, 2],
        [-7, -46],
        [-13, -6],
        [-23, 1],
        [-35, 14],
        [-28, 33],
        [-9, 0],
        [-4, -19],
        [-7, -13],
        [-8, -6],
        [-74, -20],
        [-41, 0],
        [-43, 23],
        [-28, -2],
        [-36, -13],
        [-87, 24],
        [-21, 1],
        [18, -39],
        [-29, -6],
        [-62, 1],
        [-103, 13],
        [-64, -19],
        [-114, 11],
        [-116, 3],
        [-22, 9],
        [-15, 18],
        [-1, 16],
        [6, 23],
        [17, 33],
        [28, 45],
        [10, 23],
        [-26, 11],
        [-15, 15],
        [-13, 1],
        [-41, -17],
        [-27, -50],
        [-20, -14],
        [-9, 12],
        [-7, 30],
        [-9, 15],
        [-11, -1],
        [-11, -8],
        [-11, -15],
        [-15, -6],
        [-22, 4],
        [-7, -7],
        [6, -20],
        [2, -18],
        [-2, -16],
        [-18, -17],
        [-52, -24],
        [-31, -7],
        [-79, -4],
        [-48, 7],
        [-96, 29],
        [-53, 1],
        [-64, 48],
        [-51, 11],
        [3, 18],
        [22, 32],
        [-1, 9],
        [-69, -47],
        [-10, -15],
        [9, -34],
        [-10, -3],
        [-47, 13],
        [-47, -10],
        [-12, 3],
        [-32, 25],
        [-57, 26],
        [-25, 28],
        [-39, 90],
        [-20, 59],
        [2, 12],
        [29, 16],
        [-4, 13],
        [-36, 34],
        [-39, 28],
        [-12, 19],
        [-5, 20],
        [-5, 28],
        [-1, 21],
        [5, 13],
        [22, 42],
        [62, 76],
        [8, 14],
        [3, 13],
        [-8, 40],
        [-12, 40],
        [-12, 29],
        [-34, 39],
        [-46, 42],
        [-66, 73],
        [-48, 58],
        [-58, 80],
        [-28, 7],
        [-32, -3],
        [-70, -28],
        [-24, -13],
        [-5, -9],
        [-52, -2],
        [-148, 7],
        [-56, 9],
        [-42, -3],
        [-72, -20],
        [-77, 4],
        [-48, 56],
        [-110, 31],
        [-33, 17],
        [-22, 24],
        [7, 15],
        [63, 14],
        [26, 15],
        [12, 12],
        [-62, -10],
        [-27, 1],
        [-186, 77],
        [-57, 14],
        [-10, 7],
        [-4, 9],
        [0, 11],
        [5, 17],
        [55, -22],
        [25, -1],
        [39, 6],
        [14, 14],
        [-9, 7],
        [-64, 26],
        [-30, 19],
        [-12, 15],
        [12, 20],
        [3, 13],
        [21, 8],
        [37, 1],
        [48, 10],
        [88, 26],
        [59, 10],
        [59, -2],
        [142, -27],
        [142, -34],
        [89, -26],
      ],
      [
        [23276, 97079],
        [66, -15],
        [113, 7],
        [41, -11],
        [106, -39],
        [32, -23],
        [8, -17],
        [0, -9],
        [-35, -19],
        [-71, -28],
        [-17, -22],
        [63, -27],
        [32, -24],
        [15, -17],
        [0, -18],
        [-42, -48],
        [-31, -14],
        [-48, 2],
        [-23, -5],
        [-60, -22],
        [-95, -21],
        [-129, -9],
        [-35, -13],
        [-65, -22],
        [-43, -3],
        [-14, 12],
        [-6, 18],
        [3, 13],
        [9, 15],
        [-21, 15],
        [-92, 28],
        [-54, 39],
        [-8, 13],
        [-2, 11],
        [119, 7],
        [52, 8],
        [23, 13],
        [4, 7],
        [-23, 3],
        [-82, 26],
        [-138, 16],
        [-7, 19],
        [-57, 36],
        [-6, 35],
        [-12, 10],
        [-39, 15],
        [-6, 5],
        [-12, 22],
        [-1, 12],
        [2, 12],
        [71, 23],
        [-10, 16],
        [-54, 61],
        [-14, 34],
        [2, 13],
        [34, 18],
        [47, 0],
        [124, -5],
        [59, -8],
        [59, -14],
        [65, -22],
        [97, -19],
        [31, -12],
        [58, -41],
        [7, -12],
        [-4, -13],
        [14, -12],
      ],
      [
        [24119, 94562],
        [109, -27],
        [53, -18],
        [26, -12],
        [50, -40],
        [25, -12],
        [97, 24],
        [68, 8],
        [151, -10],
        [127, -34],
        [48, -24],
        [28, -23],
        [-7, -26],
        [-24, -41],
        [-27, -38],
        [-56, -61],
        [-47, -31],
        [-11, -14],
        [-8, -21],
        [-18, -29],
        [-50, -65],
        [-14, -11],
        [-71, -28],
        [25, -12],
        [11, -11],
        [-10, -29],
        [-45, -69],
        [-46, -63],
        [-33, -39],
        [-59, -55],
        [-32, -15],
        [-44, -5],
        [-263, 48],
        [-66, -1],
        [-176, -25],
        [17, -12],
        [64, -19],
        [41, -20],
        [55, -63],
        [8, -16],
        [3, -18],
        [-2, -36],
        [-4, -9],
        [-87, -97],
        [-29, -71],
        [-18, -58],
        [-29, -16],
        [-98, 24],
        [-32, 0],
        [-110, -17],
        [-52, 8],
        [8, 88],
        [-8, 95],
        [-16, 90],
        [-82, 161],
        [-9, 29],
        [-6, 31],
        [-3, 33],
        [0, 33],
        [6, 67],
        [0, 34],
        [-4, 88],
        [-11, 132],
        [-1, 47],
        [1, 19],
        [3, 14],
        [18, 19],
        [34, 13],
        [17, 2],
        [108, -40],
        [50, -13],
        [33, 0],
        [2, 5],
        [-30, 8],
        [-27, 18],
        [-46, 51],
        [-20, 44],
        [-5, 14],
        [-1, 16],
        [3, 15],
        [6, 15],
        [23, 22],
        [18, 10],
        [68, 26],
        [69, 15],
        [151, 11],
        [42, -8],
        [66, 29],
        [38, 6],
        [67, -10],
      ],
      [
        [22933, 91959],
        [9, -7],
        [16, 6],
        [12, 19],
        [8, 4],
        [12, -4],
        [39, -34],
        [29, -35],
        [32, -25],
        [50, -23],
        [110, -73],
        [32, -49],
        [34, -77],
        [31, -58],
        [26, -41],
        [29, -32],
        [46, -36],
        [41, 26],
        [18, 7],
        [15, -11],
        [14, -27],
        [-7, -12],
        [-18, -16],
        [-29, -17],
        [-42, -2],
        [-20, -5],
        [-32, -28],
        [-26, -35],
        [-36, -11],
        [-67, -57],
        [-37, -22],
        [-55, -5],
        [-114, 44],
        [-71, -6],
        [-58, 9],
        [-64, 48],
        [-51, 26],
        [-97, 39],
        [-6, 6],
        [-4, 13],
        [-2, 20],
        [-5, 14],
        [-6, 7],
        [-16, -1],
        [-15, -14],
        [-30, -11],
        [-46, 2],
        [-20, 9],
        [-15, 12],
        [-8, 15],
        [-1, 17],
        [-5, 13],
        [-7, 9],
        [-16, 0],
        [-26, -8],
        [-10, -11],
        [5, -13],
        [-4, -8],
        [-46, 0],
        [-18, 8],
        [-34, 24],
        [-15, 24],
        [-19, 43],
        [2, 11],
        [12, 26],
        [16, 19],
        [103, 10],
        [48, 11],
        [53, 29],
        [61, 52],
        [13, 15],
        [1, 11],
        [-4, 12],
        [-19, 30],
        [-7, 20],
        [6, 9],
        [25, 1],
        [-13, 12],
        [-11, 16],
        [-4, 10],
        [1, 16],
        [19, 4],
        [24, -8],
        [47, -47],
        [18, -9],
        [32, -7],
        [-34, 32],
        [-35, 67],
        [-4, 24],
        [1, 13],
        [9, 36],
        [8, 14],
        [11, 10],
        [33, 21],
        [53, 14],
        [28, 2],
        [27, -11],
        [24, -23],
        [54, -37],
        [8, -15],
        [-1, -7],
        [-20, -10],
        [-3, -9],
        [8, -14],
      ],
      [
        [33026, 78308],
        [9, -4],
        [38, 28],
        [20, -1],
        [-1, -20],
        [-32, -22],
        [-15, -17],
        [18, -15],
        [0, -10],
        [-22, -25],
        [-11, -27],
        [9, -26],
        [36, 26],
        [14, 0],
        [20, -6],
        [19, 8],
        [11, 13],
        [63, 99],
        [3, 13],
        [-68, -20],
        [-8, 13],
        [45, 61],
        [-4, 31],
        [23, 51],
        [20, 30],
        [15, 16],
        [22, 16],
        [15, -24],
        [5, -43],
        [37, 6],
        [37, -9],
        [26, -18],
        [5, -10],
        [0, -17],
        [-9, -29],
        [-15, -25],
        [30, -31],
        [-4, -13],
        [-48, -36],
        [-28, -35],
        [-25, -44],
        [-50, -51],
        [-80, -36],
        [-25, 0],
        [-30, 11],
        [-30, -3],
        [-29, -13],
        [-29, 1],
        [-13, -7],
        [-14, 1],
        [-11, 14],
        [-23, 41],
        [-12, 27],
        [-12, 130],
        [4, 68],
        [20, 63],
        [29, 43],
        [17, 34],
        [72, 200],
        [14, 45],
        [17, 39],
        [31, 39],
        [40, 64],
        [12, 14],
        [23, 6],
        [23, -4],
        [-7, -23],
        [2, -23],
        [26, -89],
        [0, -18],
        [-15, -71],
        [-27, -116],
        [-7, -63],
        [4, -19],
        [-11, -32],
        [-12, -25],
        [-47, -45],
        [-24, -11],
        [-22, -17],
        [-54, -58],
      ],
      [
        [29247, 77766],
        [40, 22],
        [82, 85],
        [61, 30],
        [80, 89],
        [57, 17],
        [11, 20],
        [9, 73],
        [6, 26],
        [26, 73],
        [33, 61],
        [26, 84],
        [47, 54],
        [71, 45],
        [66, 98],
        [36, 30],
        [35, 22],
        [15, 40],
        [21, 23],
        [58, 46],
        [64, 13],
        [64, 38],
        [50, 21],
        [30, 36],
        [44, 19],
        [132, 104],
        [36, 49],
        [48, 99],
        [41, 51],
        [14, 54],
        [60, 87],
        [62, 116],
        [30, 83],
        [46, 45],
        [89, 132],
        [47, 52],
        [20, 6],
        [53, 47],
        [34, 48],
        [54, 49],
        [97, 60],
        [91, 72],
        [123, 63],
        [144, 93],
        [117, 50],
        [82, 7],
        [100, 24],
        [35, -3],
        [156, -40],
        [74, -50],
        [85, -106],
        [13, -27],
        [2, -39],
        [-45, 19],
        [-40, 1],
        [28, -22],
        [47, -65],
        [-3, -81],
        [-26, -73],
        [-79, -36],
        [-20, -29],
        [-16, -47],
        [-16, -18],
        [-39, -22],
        [-21, -30],
        [-62, -49],
        [-28, -6],
        [-32, 11],
        [-78, 47],
        [-47, 44],
        [-24, -24],
        [-20, -25],
        [-46, 8],
        [-21, -11],
        [-34, 12],
        [-71, -56],
        [20, -6],
        [56, 32],
        [19, -4],
        [42, -41],
        [100, -45],
        [26, -29],
        [25, -95],
        [16, -15],
        [35, 10],
        [39, 47],
        [32, 25],
        [63, 20],
        [-13, -31],
        [48, 3],
        [48, -42],
        [-18, -30],
        [-24, -59],
        [-16, -116],
        [-49, -78],
        [-64, -76],
        [16, -19],
        [19, -11],
        [41, 22],
        [28, -1],
        [31, -15],
        [-10, -59],
        [-11, -40],
        [7, -38],
        [18, -71],
        [25, -16],
        [10, -92],
        [14, -50],
        [-2, -40],
        [25, -25],
        [4, -41],
        [92, -12],
        [19, -16],
        [63, -15],
        [12, -12],
        [12, -22],
        [-63, -49],
        [51, -36],
        [47, -59],
        [38, 12],
        [16, -2],
        [42, -36],
        [12, -19],
        [6, -16],
        [21, 4],
        [31, 14],
        [54, -4],
        [59, -20],
        [-5, -32],
        [-9, -21],
        [46, 7],
        [28, -23],
        [10, 11],
        [7, 14],
        [57, 38],
        [73, 79],
        [9, -9],
        [3, -30],
        [10, -49],
        [28, -34],
        [33, -8],
        [45, 26],
        [18, -22],
        [22, -43],
        [20, -57],
        [-1, -20],
        [-26, -17],
        [-24, -26],
        [99, -10],
        [10, -11],
        [10, -22],
        [-10, -22],
        [-9, -11],
        [-18, 13],
        [-33, -12],
        [-28, -29],
        [-31, -16],
        [-20, -2],
        [-22, -14],
        [-20, -20],
        [-20, -6],
        [-65, -52],
        [-66, -33],
        [-69, -54],
        [-71, -34],
        [-73, -40],
        [-16, -4],
        [-19, 2],
        [-41, -40],
        [-21, 6],
        [-21, -7],
        [-25, 9],
        [-16, 16],
        [13, -42],
        [3, -39],
        [-6, -16],
        [-12, -20],
        [-42, 3],
        [-16, 14],
        [-20, 21],
        [-9, 33],
        [-21, 24],
        [-13, -33],
        [1, -25],
        [-16, -33],
        [-18, 57],
        [-34, -21],
        [-14, -61],
        [7, -17],
        [10, -46],
        [-16, -25],
        [-12, 7],
        [-25, -68],
        [-31, -25],
        [-31, -69],
        [-37, -53],
        [-11, -36],
        [-62, -81],
        [-24, 2],
        [-17, -2],
        [-26, -34],
        [-5, -68],
        [-11, 9],
        [-12, -2],
        [-6, -22],
        [-9, -3],
        [-23, 20],
        [-27, -11],
        [-21, 15],
        [-27, 100],
        [-14, 35],
        [-26, 12],
        [-6, -22],
        [-10, -20],
        [-25, 41],
        [-18, 153],
        [0, 37],
        [26, 129],
        [64, 116],
        [-21, 4],
        [-56, -81],
        [6, 20],
        [9, 20],
        [19, 33],
        [29, 31],
        [39, 17],
        [27, 3],
        [18, 17],
        [27, 30],
        [5, 16],
        [-24, -18],
        [-39, -18],
        [10, 23],
        [10, 13],
        [209, 208],
        [42, 34],
        [84, 44],
        [12, 28],
        [-12, 19],
        [33, -17],
        [-3, -23],
        [-5, -18],
        [-2, -29],
        [3, -28],
        [34, -14],
        [27, -52],
        [-13, 71],
        [25, 40],
        [96, 54],
        [80, 6],
        [25, 25],
        [-68, 17],
        [-81, -9],
        [-50, 19],
        [-70, -12],
        [-73, 11],
        [-22, -15],
        [-19, -34],
        [-23, 15],
        [-12, 3],
        [-11, 11],
        [24, 58],
        [74, 87],
        [46, 75],
        [12, 15],
        [10, 31],
        [-24, -5],
        [-22, -12],
        [-15, 34],
        [-27, 47],
        [-2, -20],
        [13, -57],
        [-51, -101],
        [-34, -7],
        [-44, -47],
        [-62, -41],
        [-73, -78],
        [-95, -66],
        [-19, -1],
        [-43, 55],
        [12, 24],
        [11, 34],
        [-11, -10],
        [-7, -14],
        [-25, -24],
        [21, -45],
        [-11, -17],
        [-30, -22],
        [-27, -32],
        [-25, -22],
        [-20, 28],
        [-54, -35],
        [-46, -9],
        [-10, 17],
        [-3, 28],
        [-16, 7],
        [-30, -8],
        [-11, 15],
      ],
      [
        [34594, 81530],
        [-20, -58],
        [-14, -28],
        [-13, -9],
        [-28, -8],
        [-59, -9],
        [-25, -8],
        [-3, -39],
        [4, -20],
        [8, -16],
        [11, -4],
        [24, 9],
        [9, -1],
        [7, -8],
        [6, -15],
        [3, -20],
        [0, -25],
        [-4, -31],
        [-20, -73],
        [-25, -40],
        [-33, -33],
        [-7, -12],
        [-5, -15],
        [-4, -48],
        [-16, -38],
        [-52, -96],
        [-20, -22],
        [0, -17],
        [-8, -46],
        [-16, -36],
        [-43, -85],
        [-10, -30],
        [-5, -24],
        [1, -33],
        [-2, -15],
        [-10, -28],
        [-14, -27],
        [-3, -13],
        [6, -23],
        [5, -8],
        [1, -22],
        [-4, -34],
        [18, 22],
        [40, 78],
        [31, 47],
        [20, 16],
        [15, 21],
        [15, 46],
        [20, 44],
        [19, 15],
        [9, -9],
        [7, -21],
        [-1, -28],
        [-10, -33],
        [0, -10],
        [24, 24],
        [41, 21],
        [15, -3],
        [30, -30],
        [26, 3],
        [40, 18],
        [7, -8],
        [-7, -27],
        [-15, -26],
        [-37, -36],
        [-90, -72],
        [-28, -49],
        [5, 2],
        [20, 21],
        [20, 11],
        [21, 2],
        [9, -7],
        [-3, -14],
        [-3, -37],
        [-54, -74],
        [13, 3],
        [62, 33],
        [39, -46],
        [52, 16],
        [31, 16],
        [0, -10],
        [6, -20],
        [0, -33],
        [3, -5],
        [15, 11],
        [3, 12],
        [-1, 58],
        [5, 6],
        [10, -9],
        [6, -15],
        [2, -42],
        [-7, -43],
        [-9, -39],
        [-23, -57],
        [3, -24],
        [-6, -27],
        [5, -1],
        [23, 25],
        [1, 10],
        [-2, 24],
        [3, 11],
        [19, 26],
        [31, 31],
        [11, 4],
        [4, -7],
        [-2, -19],
        [10, 5],
        [20, 28],
        [18, 16],
        [17, 6],
        [18, 19],
        [19, 32],
        [20, 27],
        [21, 21],
        [9, 2],
        [-3, -35],
        [4, -40],
        [1, -34],
        [4, -7],
        [17, 36],
        [9, 13],
        [11, 6],
        [12, -3],
        [87, 13],
        [27, -9],
        [30, -24],
        [37, -36],
        [14, -33],
        [3, -42],
        [-4, -29],
        [-27, -37],
        [-24, -24],
        [-14, -24],
        [-5, -25],
        [-5, -15],
        [-16, -20],
        [-72, -59],
        [17, -2],
        [41, 13],
        [28, 3],
        [1, -9],
        [-11, -16],
        [-21, -17],
        [-2, -8],
        [1, -11],
        [22, -12],
        [29, 6],
        [24, -9],
        [-3, -14],
        [-19, -46],
        [-5, -28],
        [-26, -25],
        [-50, -37],
        [-13, -15],
        [3, -3],
        [46, 28],
        [24, 7],
        [14, 0],
        [17, 27],
        [26, 9],
        [26, -17],
        [39, 46],
        [14, 6],
        [24, -5],
        [15, 8],
        [26, 32],
        [20, 15],
        [4, -1],
        [4, -13],
        [2, -36],
        [-5, -32],
        [-6, -21],
        [-21, -45],
        [-13, -16],
        [-12, -6],
        [-21, 2],
        [-9, -7],
        [-20, -35],
        [-35, -36],
        [-22, -14],
        [14, -20],
        [5, -37],
        [-8, -12],
        [-37, -12],
        [-2, -6],
        [-13, -8],
        [-31, -13],
        [21, -6],
        [39, 9],
        [4, -6],
        [-5, -27],
        [-11, -27],
        [-46, -70],
        [0, -7],
        [7, -35],
        [9, -26],
        [11, -18],
        [26, -1],
        [19, 8],
        [27, 47],
        [62, 146],
        [55, 41],
        [45, 45],
        [11, -9],
        [5, -11],
        [-2, -11],
        [-23, -37],
        [-12, -31],
        [-31, -94],
        [-12, -45],
        [-6, -47],
        [1, -81],
        [4, -14],
        [9, -19],
        [19, 17],
        [31, 40],
        [20, 38],
        [15, 63],
        [10, 24],
        [10, -1],
        [10, -13],
        [2, -30],
        [8, -42],
        [6, -41],
        [-4, -46],
        [-5, -25],
        [-63, -186],
        [6, -33],
        [2, -20],
        [-2, -22],
        [-20, -89],
        [-19, -55],
        [-11, -24],
        [-12, -15],
        [-15, -5],
        [-13, 8],
        [-11, 21],
        [-10, 11],
        [-9, 1],
        [-17, -4],
        [-42, -45],
        [-9, -3],
        [-6, 6],
        [-8, 24],
        [6, 120],
        [4, 40],
        [-9, 30],
        [9, 52],
        [1, 19],
        [-6, 7],
        [-10, -4],
        [-17, -26],
        [-22, -47],
        [-23, -42],
        [-42, -57],
        [-18, -11],
        [-8, 2],
        [-8, 8],
        [-12, 24],
        [1, 22],
        [5, 29],
        [17, 68],
        [34, 101],
        [28, 72],
        [5, 31],
        [-7, 13],
        [-7, 27],
        [-11, 78],
        [-13, 64],
        [-16, 28],
        [-41, 32],
        [-7, -8],
        [-4, -43],
        [-48, -124],
        [-8, -54],
        [-6, -20],
        [-9, -14],
        [-21, -17],
        [6, 29],
        [22, 64],
        [-3, 6],
        [-28, -51],
        [-21, -29],
        [-26, -7],
        [-16, 2],
        [-15, -8],
        [-65, -121],
        [-3, -41],
        [-11, -33],
        [-32, -60],
        [-17, -21],
        [-24, -4],
        [-21, 11],
        [-15, -2],
        [-33, -19],
        [-38, -8],
        [-16, 4],
        [-10, 7],
        [-19, 24],
        [-2, 16],
        [1, 10],
        [10, 25],
        [22, 32],
        [18, 11],
        [45, 16],
        [33, 24],
        [25, 35],
        [12, 21],
        [47, 110],
        [60, 39],
        [29, 31],
        [21, 40],
        [3, 14],
        [-30, -20],
        [-15, -5],
        [-25, 7],
        [-11, 14],
        [-34, -4],
        [-47, 6],
        [-7, -10],
        [-6, -54],
        [-6, -28],
        [-7, -9],
        [-11, -6],
        [-21, -6],
        [-55, 19],
        [-11, 11],
        [-14, 8],
        [-60, -18],
        [-13, 2],
        [12, 12],
        [60, 40],
        [6, 112],
        [-3, 18],
        [-17, -16],
        [-28, -16],
        [-20, 5],
        [-9, 10],
        [-8, -9],
        [-19, -59],
        [-12, -7],
        [-17, -3],
        [-38, -21],
        [-73, -15],
        [-14, -15],
        [-49, 5],
        [-145, 33],
        [-52, -3],
        [-62, 20],
        [-12, 8],
        [-87, -3],
        [-26, 4],
        [2, 25],
        [-3, 6],
        [-25, -27],
        [-23, -18],
        [-29, -15],
        [-91, -26],
        [-49, -6],
        [-28, 18],
        [-11, 19],
        [-17, 59],
        [-12, 74],
        [0, 13],
        [6, 26],
        [19, 36],
        [87, 94],
        [69, 96],
        [30, 49],
        [28, 18],
        [46, 41],
        [2, 5],
        [-45, -5],
        [-32, 11],
        [-32, 5],
        [-62, -11],
        [-62, 0],
        [0, 21],
        [29, 40],
        [62, 68],
        [6, 1],
        [-19, -32],
        [-5, -24],
        [8, -16],
        [9, -10],
        [36, -4],
        [8, 14],
        [12, 73],
        [27, 85],
        [14, 61],
        [25, 47],
        [13, 7],
        [11, -9],
        [37, -11],
        [38, -43],
        [12, -3],
        [4, 3],
        [-14, 13],
        [-11, 20],
        [-5, 19],
        [14, 59],
        [16, 17],
        [3, 12],
        [-32, 0],
        [-26, 16],
        [-8, 27],
        [1, 47],
        [9, 28],
        [21, 37],
        [25, 25],
        [15, -6],
        [30, -34],
        [18, 10],
        [-3, 10],
        [-27, 53],
        [-9, 39],
        [1, 19],
        [59, 187],
        [29, 100],
        [40, 153],
        [9, 24],
        [20, 45],
        [9, 12],
        [25, 0],
        [16, 6],
        [-23, 19],
        [-8, 14],
        [-1, 15],
        [6, 15],
        [9, 12],
        [31, 24],
        [22, 40],
        [13, 48],
        [-2, 16],
        [-7, 16],
        [0, 9],
        [17, 10],
        [42, 56],
        [5, 11],
        [16, 75],
        [19, 33],
        [17, 17],
        [28, 21],
        [86, 52],
        [51, 46],
        [34, -3],
        [10, -32],
        [49, -21],
        [9, 23],
        [-12, 28],
        [10, 11],
        [40, 10],
        [7, -4],
        [12, -16],
        [-2, -14],
      ],
      [
        [25947, 92747],
        [11, -12],
        [64, 16],
        [54, 18],
        [84, 45],
        [50, 15],
        [152, 0],
        [26, -9],
        [-11, -26],
        [-7, -8],
        [5, -11],
        [17, -16],
        [33, -17],
        [13, 16],
        [9, 37],
        [23, 153],
        [9, 46],
        [5, 44],
        [-1, 41],
        [-11, 26],
        [-39, 16],
        [-53, -3],
        [-28, 4],
        [-33, 8],
        [-25, 13],
        [-16, 17],
        [-31, 52],
        [-24, 28],
        [-60, 52],
        [-28, 17],
        [14, 21],
        [55, 23],
        [33, 23],
        [39, 65],
        [23, 11],
        [84, -9],
        [114, -51],
        [72, -44],
        [19, -5],
        [0, 8],
        [-18, 21],
        [-82, 54],
        [-38, 40],
        [-17, 29],
        [8, 12],
        [46, 13],
        [6, 14],
        [-63, 17],
        [-31, 0],
        [-26, -12],
        [-28, -1],
        [-51, 22],
        [-14, 13],
        [-30, 38],
        [-15, 34],
        [-17, 21],
        [-7, 16],
        [-3, 51],
        [2, 30],
        [7, 26],
        [12, 22],
        [33, 39],
        [19, 12],
        [35, 5],
        [76, -20],
        [203, -71],
        [-5, 23],
        [-227, 96],
        [-81, 24],
        [-20, 35],
        [122, 133],
        [111, 31],
        [56, 38],
        [91, 2],
        [85, -25],
        [1, 7],
        [-38, 46],
        [3, 12],
        [48, 27],
        [89, 32],
        [108, 26],
        [22, 13],
        [28, 9],
        [51, 9],
        [127, 3],
        [71, -3],
        [95, -20],
        [55, -35],
        [17, -21],
        [30, -69],
        [24, -95],
        [35, -40],
        [56, -21],
        [39, -24],
        [22, -26],
        [6, -32],
        [-10, -39],
        [7, -40],
        [25, -41],
        [20, -24],
        [42, -26],
        [1, -14],
        [-13, -16],
        [-28, -23],
        [-70, -69],
        [-90, -76],
        [-64, -66],
        [-3, -20],
        [133, 104],
        [42, -4],
        [2, -15],
        [-28, -50],
        [-33, -45],
        [-33, -29],
        [6, -11],
        [63, -50],
        [-11, -9],
        [-31, 5],
        [-12, -5],
        [-9, -9],
        [-6, -14],
        [0, -20],
        [5, -25],
        [-1, -18],
        [-6, -13],
        [7, -5],
        [18, 2],
        [16, 10],
        [27, 34],
        [88, 93],
        [57, 34],
        [18, 4],
        [52, -23],
        [13, 1],
        [-58, 72],
        [-5, 18],
        [12, 26],
        [7, 9],
        [32, 20],
        [26, 10],
        [15, -4],
        [24, -36],
        [11, -25],
        [19, -11],
        [44, 14],
        [28, 30],
        [36, -20],
        [54, -48],
        [-5, -48],
        [0, -49],
        [3, -35],
        [65, -65],
        [44, -28],
        [9, -1],
        [-2, 10],
        [-9, 22],
        [-24, 21],
        [-23, 34],
        [-20, 40],
        [12, 95],
        [34, 50],
        [32, -13],
        [43, -29],
        [34, -2],
        [53, 3],
        [108, -58],
        [58, -1],
        [-5, 23],
        [-44, 12],
        [-64, 31],
        [-101, 38],
        [-46, 44],
        [-8, 20],
        [1, 22],
        [6, 20],
        [10, 16],
        [20, 17],
        [97, 50],
        [69, 22],
        [51, 7],
        [87, -1],
        [100, -9],
        [55, -14],
        [62, -36],
        [79, -35],
        [28, -6],
        [33, 0],
        [38, 8],
        [36, -3],
        [114, -52],
        [30, -27],
        [18, -32],
        [14, -32],
        [8, -31],
        [-3, -24],
        [-95, -108],
        [-41, -18],
        [-28, -41],
        [-40, -77],
        [-35, -42],
        [-3, -8],
        [7, -2],
        [21, 19],
        [36, 53],
        [26, 46],
        [47, 38],
        [78, 45],
        [68, 22],
        [58, -2],
        [49, -6],
        [39, -11],
        [24, -10],
        [7, -7],
        [16, -34],
        [-1, -23],
        [-10, -26],
        [-19, -29],
        [-84, -33],
        [-47, -25],
        [-29, -10],
        [-87, -9],
        [4, -10],
        [65, -14],
        [71, 4],
        [-1, -16],
        [-34, -44],
        [-11, -38],
        [9, -31],
        [-1, -26],
        [-26, -53],
        [-28, -48],
        [10, -7],
        [66, 69],
        [18, 76],
        [27, 66],
        [31, 37],
        [23, 14],
        [74, 5],
        [40, 39],
        [35, 12],
        [15, 1],
        [29, -15],
        [-1, -15],
        [-43, -70],
        [-92, -112],
        [38, 13],
        [25, 27],
        [34, 26],
        [38, 40],
        [25, -36],
        [39, -27],
        [23, -61],
        [38, -29],
        [23, -23],
        [-3, 39],
        [-33, 77],
        [9, 31],
        [25, 16],
        [79, 65],
        [55, -22],
        [34, -19],
        [17, 5],
        [43, -3],
        [69, -10],
        [67, -19],
        [66, -25],
        [50, -31],
        [35, -34],
        [21, -24],
        [8, -14],
        [12, -34],
        [-9, -23],
        [-50, -53],
        [-27, -24],
        [-27, -11],
        [-73, 11],
        [-23, -7],
        [-24, -16],
        [-76, -73],
        [-42, -32],
        [-41, -20],
        [-10, -10],
        [89, 1],
        [24, 21],
        [21, 41],
        [39, 42],
        [74, 19],
        [103, -41],
        [52, 2],
        [39, 41],
        [44, 28],
        [17, 6],
        [9, -3],
        [33, -30],
        [10, -26],
        [0, -60],
        [-5, -18],
        [-29, -46],
        [-73, -68],
        [-47, -25],
        [-52, -14],
        [-57, -23],
        [-20, -19],
        [-20, -26],
        [-20, -18],
        [-25, -14],
        [33, -22],
        [12, 1],
        [13, 13],
        [33, 50],
        [24, 23],
        [14, 4],
        [14, -2],
        [14, -10],
        [14, -18],
        [-1, -43],
        [-42, -171],
        [7, 0],
        [26, 47],
        [74, 178],
        [18, 36],
        [36, 36],
        [80, 54],
        [63, 29],
        [70, 24],
        [37, 9],
        [43, -6],
        [28, -28],
        [37, -5],
        [46, 7],
        [30, -4],
        [33, -11],
        [29, -20],
        [48, -24],
        [110, -44],
        [14, -10],
        [12, -16],
        [12, -25],
        [-1, -24],
        [-15, -23],
        [-18, -15],
        [-22, -5],
        [-23, -13],
        [-42, -34],
        [-13, -6],
        [-66, -14],
        [-61, -7],
        [-38, -14],
        [-73, -37],
        [-101, -68],
        [1, -16],
        [40, -8],
        [33, 10],
        [45, 47],
        [42, 18],
        [66, 15],
        [91, 12],
        [39, -2],
        [7, -2],
        [5, -11],
        [3, -20],
        [-15, -26],
        [-17, -12],
        [-47, -58],
        [31, -15],
        [42, -7],
        [24, 16],
        [22, 36],
        [25, 19],
        [27, 4],
        [24, 9],
        [21, 15],
        [5, 9],
        [-30, 19],
        [-2, 11],
        [12, 28],
        [22, 30],
        [23, 19],
        [17, 2],
        [57, -21],
        [39, -35],
        [98, -107],
        [12, -21],
        [34, -79],
        [7, -35],
        [-6, -25],
        [-8, -15],
        [-10, -5],
        [-22, -1],
        [-130, 33],
        [-60, -4],
        [-26, -9],
        [-21, -14],
        [-16, -17],
        [-12, -21],
        [-23, -12],
        [-82, 0],
        [-47, -12],
        [-80, -28],
        [-28, -16],
        [-7, -20],
        [49, 4],
        [81, 26],
        [75, 8],
        [127, -59],
        [41, -9],
        [23, 9],
        [28, 2],
        [101, -4],
        [35, -7],
        [51, -22],
        [78, -49],
        [15, -14],
        [9, -15],
        [2, -16],
        [0, -39],
        [-8, -13],
        [-27, -9],
        [-112, 10],
        [-35, 9],
        [-42, -11],
        [-34, 4],
        [-44, 15],
        [-48, 28],
        [-72, -26],
        [-58, 17],
        [-59, -15],
        [-117, -62],
        [13, -11],
        [160, 53],
        [31, -3],
        [51, -20],
        [80, -38],
        [23, -16],
        [0, -60],
        [-12, -41],
        [-25, -45],
        [-37, 6],
        [-85, 28],
        [-35, 4],
        [-26, -4],
        [-34, -19],
        [-17, 0],
        [-137, 36],
        [-31, 2],
        [-3, -4],
        [6, -7],
        [125, -56],
        [92, -6],
        [57, -10],
        [34, -17],
        [16, -13],
        [2, -37],
        [30, -38],
        [28, -15],
        [18, -1],
        [30, 14],
        [30, 2],
        [25, -9],
        [31, -21],
        [37, -6],
        [33, -13],
        [26, -2],
        [71, 6],
        [31, -8],
        [8, -7],
        [-13, -12],
        [-66, -29],
        [-10, -28],
        [37, -36],
        [20, -28],
        [-1, -20],
        [-20, -47],
        [-5, -19],
        [6, -2],
        [48, 38],
        [7, -4],
        [5, -53],
        [6, 3],
        [16, 43],
        [-7, 58],
        [28, 23],
        [88, 18],
        [-15, -91],
        [-2, -47],
        [-39, -79],
        [-32, -25],
        [1, -6],
        [23, -10],
        [14, -1],
        [14, 13],
        [33, 60],
        [66, 64],
        [12, 1],
        [0, -23],
        [-9, -42],
        [31, -20],
        [29, 19],
        [16, 17],
        [37, -2],
        [17, -8],
        [5, -19],
        [-17, -79],
        [3, -19],
        [39, -52],
        [4, 3],
        [-8, 25],
        [-8, 62],
        [8, 28],
        [32, 34],
        [65, 50],
        [25, 10],
        [15, -7],
        [24, -24],
        [-8, -14],
        [-26, -15],
        [-19, -27],
        [-13, -39],
        [14, -21],
        [53, -2],
        [54, 32],
        [30, -16],
        [37, -42],
        [66, -67],
        [39, 18],
        [47, -51],
        [-64, -40],
        [20, -86],
        [-82, 4],
        [-46, -7],
        [-31, 8],
        [-34, -3],
        [31, -20],
        [59, -9],
        [6, -25],
        [46, 0],
        [35, 5],
        [63, -1],
        [4, 30],
        [41, 17],
        [23, 19],
        [20, -12],
        [57, -12],
        [77, -59],
        [-34, -35],
        [-9, -33],
        [-12, -28],
        [-6, -25],
        [-14, -17],
        [-109, -99],
        [18, -1],
        [46, 24],
        [91, 35],
        [50, 15],
        [36, -10],
        [18, 0],
        [16, 13],
        [30, -15],
        [62, -13],
        [71, 81],
        [43, -16],
        [40, -50],
        [86, -89],
        [45, -51],
        [15, -23],
        [-2, -23],
        [-41, -25],
        [-20, -5],
        [-55, 46],
        [-50, 24],
        [-31, -3],
        [-30, -18],
        [10, -9],
        [121, -71],
        [21, -52],
        [2, -23],
        [-81, -35],
        [-26, -2],
        [-57, 17],
        [-32, 31],
        [-28, 11],
        [-37, 3],
        [-12, -6],
        [41, -52],
        [-4, -16],
        [-21, -10],
        [-11, -25],
        [81, -46],
        [61, -46],
        [9, -19],
        [-41, -13],
        [-29, -4],
        [-62, 7],
        [-35, 10],
        [-9, -11],
        [35, -24],
        [14, -16],
        [10, -23],
        [7, -22],
        [1, -20],
        [-28, -17],
        [-35, -46],
        [-14, -48],
        [-31, -5],
        [-13, 9],
        [-42, -15],
        [-55, 21],
        [-20, 22],
        [-61, 90],
        [-1, -10],
        [15, -46],
        [-3, -27],
        [-64, -20],
        [0, -7],
        [39, -15],
        [48, -11],
        [-8, -42],
        [1, -181],
        [-11, -64],
        [-23, -56],
        [-34, -53],
        [-36, 35],
        [-15, 36],
        [-12, 18],
        [-17, 15],
        [-23, 7],
        [-23, 0],
        [-25, -32],
        [-28, 28],
        [-26, 33],
        [10, 88],
        [11, 44],
        [-4, -1],
        [-16, -20],
        [-36, -64],
        [-23, -79],
        [-31, 30],
        [-28, 38],
        [-22, 38],
        [-37, 43],
        [-36, 51],
        [-19, 60],
        [-8, 13],
        [-21, 50],
        [-8, 14],
        [-8, 5],
        [-17, 31],
        [6, 34],
        [28, 39],
        [26, 28],
        [42, 28],
        [50, 16],
        [22, 36],
        [28, 66],
        [30, 46],
        [33, 26],
        [-16, 4],
        [-42, -22],
        [-30, -32],
        [-35, -54],
        [-32, -34],
        [-84, -40],
        [-30, -8],
        [-36, -4],
        [-78, 5],
        [-18, 14],
        [9, 38],
        [56, 68],
        [-9, 5],
        [-20, -24],
        [-27, -17],
        [-23, -9],
        [-34, 3],
        [-41, 43],
        [-19, 13],
        [-39, 15],
        [-16, 14],
        [-66, 104],
        [-13, 28],
        [-7, 27],
        [-21, 23],
        [-35, 18],
        [-8, -3],
        [13, -23],
        [0, -20],
        [-30, -12],
        [-31, 4],
        [-33, 21],
        [-3, -28],
        [35, -51],
        [1, -64],
        [-10, -6],
        [-24, -4],
        [-16, 8],
        [-53, 48],
        [-50, 33],
        [-36, 18],
        [-4, -13],
        [23, -57],
        [27, -57],
        [44, -47],
        [69, -56],
        [32, -32],
        [-25, -46],
        [-22, -14],
        [-13, -5],
        [-42, 0],
        [-77, 25],
        [-37, 28],
        [-52, 67],
        [-87, 69],
        [-19, -1],
        [-61, -28],
        [9, -4],
        [40, -2],
        [29, -9],
        [69, -55],
        [6, -23],
        [-18, -25],
        [1, -32],
        [20, -39],
        [20, -25],
        [41, -18],
        [20, -2],
        [8, -11],
        [-25, -87],
        [-2, -24],
        [7, -10],
        [9, 0],
        [52, 35],
        [22, 9],
        [19, 2],
        [22, -10],
        [26, -22],
        [14, -23],
        [5, -23],
        [7, -15],
        [52, -24],
        [-5, -12],
        [-53, -37],
        [-3, -5],
        [11, -3],
        [33, -22],
        [31, -34],
        [19, -41],
        [4, -21],
        [0, -19],
        [4, -11],
        [16, -2],
        [7, 7],
        [7, -1],
        [9, -10],
        [8, -32],
        [19, -92],
        [10, -25],
        [5, -1],
        [3, 92],
        [9, 15],
        [33, -16],
        [47, -36],
        [34, -32],
        [4, -15],
        [-25, -29],
        [5, -13],
        [19, -19],
        [17, 7],
        [12, 33],
        [22, 32],
        [25, 22],
        [48, -18],
        [39, -48],
        [6, -16],
        [26, -21],
        [22, 11],
        [44, -54],
        [-21, -25],
        [-45, -36],
        [-5, -12],
        [11, 2],
        [87, 1],
        [23, -15],
        [5, -28],
        [-38, -76],
        [-35, 6],
        [-47, 2],
        [-24, -4],
        [4, -10],
        [65, -35],
        [18, -29],
        [25, -30],
        [12, -25],
        [-1, -11],
        [-10, -17],
        [5, -6],
        [44, -11],
        [28, 10],
        [34, 4],
        [30, -3],
        [3, -11],
        [-5, -28],
        [-31, -25],
        [9, -7],
        [36, 8],
        [17, -12],
        [21, -62],
        [25, -48],
        [-20, -11],
        [-22, -5],
        [3, -61],
        [14, -64],
        [1, -60],
        [-5, -54],
        [-20, -12],
        [-22, 1],
        [-8, 14],
        [-53, 161],
        [-13, 29],
        [-16, 25],
        [-56, 70],
        [2, -11],
        [14, -32],
        [12, -48],
        [16, -96],
        [8, -61],
        [-4, -23],
        [-11, -6],
        [-3, -11],
        [5, -17],
        [43, -63],
        [21, -38],
        [15, -39],
        [13, -26],
        [13, -14],
        [-3, -11],
        [-19, -9],
        [-32, -4],
        [-16, 5],
        [-58, 36],
        [-8, -11],
        [32, -133],
        [-1, -32],
        [-16, -11],
        [-20, 13],
        [-24, 38],
        [-36, 42],
        [-49, 46],
        [-47, 37],
        [-11, -1],
        [-7, -11],
        [-7, -2],
        [-9, 7],
        [-16, 28],
        [-16, 18],
        [-68, 62],
        [-7, 0],
        [7, -18],
        [6, -41],
        [-7, -8],
        [-18, 3],
        [-34, 18],
        [-23, 40],
        [-28, 70],
        [-16, 26],
        [-1, -17],
        [8, -67],
        [-1, -22],
        [-17, -6],
        [-8, 6],
        [-7, 18],
        [-6, 29],
        [-17, 23],
        [-25, 15],
        [-14, 16],
        [-7, 29],
        [-5, 6],
        [-45, -6],
        [-23, 20],
        [-65, 81],
        [-59, 88],
        [-38, 46],
        [-14, 12],
        [20, -57],
        [22, -84],
        [6, -39],
        [-10, -1],
        [-22, 17],
        [-113, 108],
        [-69, 51],
        [-39, 9],
        [-63, 6],
        [-14, -28],
        [34, -62],
        [33, -47],
        [32, -32],
        [50, -61],
        [46, -79],
        [19, -25],
        [62, -34],
        [33, -9],
        [34, -2],
        [3, -12],
        [-16, -23],
        [-4, -14],
        [75, -35],
        [28, -20],
        [27, -32],
        [15, -8],
        [65, -82],
        [16, -14],
        [58, -26],
        [19, -17],
        [32, -53],
        [20, -27],
        [28, -64],
        [21, -29],
        [52, -32],
        [22, -10],
        [10, -12],
        [-7, -29],
        [-6, -12],
        [-30, -20],
        [5, -28],
        [17, -50],
        [-1, -31],
        [-18, -12],
        [-37, -14],
        [-19, 1],
        [-28, 12],
        [-35, 20],
        [-70, 50],
        [-105, 35],
        [-39, 18],
        [-13, 17],
        [-20, 10],
        [-260, 48],
        [-44, 12],
        [-27, 15],
        [-25, 22],
        [-100, 49],
        [-12, 11],
        [-67, 85],
        [-49, 100],
        [-17, 13],
        [-54, 13],
        [-45, -8],
        [-30, -11],
        [-46, 4],
        [-30, 16],
        [-63, 45],
        [-64, 23],
        [-56, 39],
        [-29, 14],
        [3, 10],
        [41, 58],
        [-12, 0],
        [-73, -45],
        [-26, 14],
        [-43, 35],
        [-32, 35],
        [-66, 97],
        [-38, 35],
        [5, 8],
        [43, 3],
        [34, -3],
        [23, 8],
        [44, 39],
        [19, 25],
        [2, 14],
        [-37, 4],
        [-8, 7],
        [-7, 16],
        [-17, 21],
        [-27, 25],
        [-31, 10],
        [-107, -9],
        [-19, 11],
        [0, 17],
        [21, 47],
        [12, 19],
        [3, 10],
        [-4, 3],
        [-14, -2],
        [-62, -42],
        [-14, 3],
        [-24, 45],
        [-15, 51],
        [-11, 17],
        [-14, 6],
        [-51, 50],
        [-72, 95],
        [-27, 30],
        [-30, 27],
        [-21, 11],
        [3, 14],
        [46, 79],
        [2, 12],
        [-40, -4],
        [-59, 16],
        [-28, -20],
        [-18, -1],
        [-21, 11],
        [-12, -4],
        [-11, -65],
        [-9, -16],
        [-12, -9],
        [-11, 2],
        [-9, 11],
        [0, 15],
        [-9, 79],
        [-21, 12],
        [-58, 3],
        [-13, 6],
        [-14, 15],
        [-12, 27],
        [-10, 40],
        [-12, 22],
        [-13, 3],
        [-11, -3],
        [-8, -10],
        [-18, -6],
        [-28, -3],
        [-1, -15],
        [27, -28],
        [25, -40],
        [25, -52],
        [-15, -35],
        [-55, -18],
        [-48, -5],
        [-41, 7],
        [-32, 12],
        [-44, 29],
        [-63, -9],
        [-15, -76],
        [-13, -4],
        [-60, 1],
        [-24, -6],
        [-80, -42],
        [-25, -6],
        [-18, 5],
        [-18, -11],
        [-27, -24],
        [-37, -2],
        [-47, 19],
        [-39, 8],
        [-33, -3],
        [-33, 12],
        [-34, 25],
        [-29, 11],
        [-36, -2],
        [-9, 4],
        [-54, 55],
        [-17, 22],
        [-35, 68],
        [-7, 27],
        [-1, 29],
        [3, 21],
        [13, 32],
        [13, 76],
        [12, 25],
        [17, 23],
        [32, 29],
        [120, 51],
        [24, 20],
        [-1, 14],
        [-27, 62],
        [0, 16],
        [9, 9],
        [19, 37],
        [9, 10],
        [21, 6],
        [44, -19],
        [37, -7],
        [50, -3],
        [83, -25],
        [115, -49],
        [66, -33],
        [50, -50],
        [36, -49],
        [5, -25],
        [-16, -38],
        [-9, -11],
        [1, -13],
        [9, -15],
        [29, -21],
        [7, 8],
        [-3, 26],
        [6, 22],
        [15, 17],
        [1, 23],
        [-11, 28],
        [-13, 24],
        [-17, 21],
        [-74, 70],
        [-7, 23],
        [25, 11],
        [108, -24],
        [42, 6],
        [16, 27],
        [17, 19],
        [18, 10],
        [37, 4],
        [51, -13],
        [25, -2],
        [23, 5],
        [29, 15],
        [42, 50],
        [28, 11],
        [41, 8],
        [31, 1],
        [56, -20],
        [35, 0],
        [-3, 34],
        [-23, 63],
        [-28, 66],
        [-23, 22],
        [-57, 41],
        [-68, 78],
        [-34, 49],
        [-9, 24],
        [4, 16],
        [12, 24],
        [123, 86],
        [97, 86],
        [42, 44],
        [21, 31],
        [21, 22],
        [22, 14],
        [47, 16],
        [13, 22],
        [3, 36],
        [8, 32],
        [44, 85],
        [33, 23],
        [51, 16],
        [33, 20],
        [41, 70],
        [-4, 17],
        [-19, 13],
        [-14, 20],
        [-62, 182],
        [-42, 89],
        [-49, 76],
        [-45, 94],
        [-73, 92],
        [-1, 24],
        [13, 28],
        [-6, 6],
        [-76, -40],
        [-18, -2],
        [-29, 17],
        [-20, 22],
        [-16, 38],
        [1, 20],
        [11, 19],
        [15, 47],
        [0, 24],
        [-5, 22],
        [-6, 16],
        [-9, 9],
        [-23, 6],
        [-38, 2],
        [-13, -8],
        [43, -71],
        [-7, -17],
        [-54, -7],
        [-24, 3],
        [-22, 8],
        [-20, 14],
        [-63, 74],
        [-13, 27],
        [4, 20],
        [-5, 11],
        [-13, -7],
        [-17, 0],
        [-24, 7],
        [-5, 8],
        [44, 40],
        [3, 12],
        [-21, 13],
        [-30, 3],
        [-8, 12],
        [10, 12],
        [41, 22],
        [15, 14],
        [-25, 11],
        [-13, 1],
        [-28, -24],
        [-42, -48],
        [-30, -18],
        [-41, 22],
        [-27, 8],
        [-18, -5],
        [-28, -38],
        [-61, -27],
        [-109, -65],
        [-46, -20],
        [-51, 4],
        [-9, 13],
        [0, 22],
        [4, 18],
        [6, 14],
        [2, 18],
        [-4, 73],
        [9, 21],
        [17, 12],
        [32, 12],
        [81, -15],
        [37, 3],
        [27, 17],
        [26, 24],
        [27, 33],
        [5, 31],
        [-28, 50],
        [-10, 11],
        [-72, 39],
        [-40, 14],
        [-35, 6],
        [-26, 11],
        [-16, 17],
        [-15, 27],
        [-1, 18],
        [2, 24],
        [15, 17],
        [64, 20],
        [0, 5],
        [-53, 14],
        [-25, -2],
        [-21, -16],
        [-27, -37],
        [-16, -11],
        [-48, 22],
        [-29, 3],
        [-19, 11],
        [-11, 10],
        [7, 10],
        [24, 11],
        [41, 32],
        [3, 18],
        [-29, 28],
        [-15, 7],
        [-60, 10],
        [-72, -10],
        [-28, 5],
        [-12, 32],
        [-7, 37],
        [-4, 44],
        [-13, 75],
        [-14, 39],
        [-19, 4],
        [-88, -16],
        [-20, 0],
        [-15, 6],
        [-57, 50],
        [-24, 18],
        [-13, 4],
        [-42, 53],
        [-16, 10],
        [-19, 26],
        [-22, 42],
        [-24, 13],
        [-26, -16],
        [-26, -24],
        [-26, -30],
        [-14, -26],
        [-2, -21],
        [16, -16],
        [91, -28],
        [24, -18],
        [19, -30],
        [15, -36],
        [10, -43],
        [-1, -32],
        [-11, -22],
        [-20, -19],
        [-57, -30],
        [-58, -17],
        [-59, -5],
        [-28, 4],
        [-152, 59],
        [-27, 0],
        [-35, 8],
        [-79, 24],
        [-43, 3],
        [-76, 20],
        [-128, 11],
        [-25, -9],
        [34, -27],
        [30, -14],
        [25, 0],
        [37, -24],
        [48, -47],
        [28, -29],
        [22, -34],
        [1, -12],
        [-23, -23],
        [-178, 122],
        [-109, -42],
        [-51, -16],
        [-43, -3],
        [-54, 17],
        [-121, 59],
        [-46, 20],
        [-16, 3],
        [-106, -25],
        [-91, -1],
        [-185, 24],
        [-67, 17],
        [-18, 17],
        [-22, 8],
        [-40, 0],
        [-105, 19],
        [-97, -43],
        [-116, 40],
        [-35, 23],
        [-11, 16],
        [-33, 66],
        [-5, 36],
        [10, 32],
        [9, 22],
        [10, 12],
        [-64, -37],
        [-22, -6],
        [-29, -1],
        [-87, 13],
        [-14, -7],
        [5, -12],
        [23, -19],
        [2, -12],
        [-48, -8],
        [-73, 9],
        [-33, -4],
        [-14, -5],
        [-33, -30],
        [-14, -7],
        [-17, 3],
        [-77, 67],
        [-62, 43],
        [-73, 16],
        [-33, 14],
        [-18, 16],
        [-100, 137],
        [-14, 29],
        [-32, 107],
        [-10, 23],
        [-13, 15],
        [25, 3],
        [95, -13],
        [91, 0],
        [50, -8],
        [57, -27],
        [76, -19],
        [53, -4],
        [87, 7],
        [98, 18],
        [11, 14],
        [-63, 24],
        [-57, 31],
        [-52, 41],
        [-31, 17],
        [-53, 11],
        [-146, 8],
        [-137, 27],
        [-93, 37],
        [-77, 41],
        [-31, 22],
        [-11, 18],
        [-12, 54],
        [-13, 90],
        [-12, 61],
        [-12, 31],
        [-1, 27],
        [26, 57],
        [74, 63],
        [2, 10],
        [-15, 3],
        [-31, 16],
        [-10, 23],
        [-4, 37],
        [0, 32],
        [3, 25],
        [13, 33],
        [32, 57],
        [46, 71],
        [49, 65],
        [9, 22],
        [4, 58],
        [7, 43],
        [6, 30],
        [11, 22],
        [31, 43],
        [38, 41],
        [60, 34],
        [5, 13],
        [1, 18],
        [3, 13],
        [6, 9],
        [149, 110],
        [68, 45],
        [57, 29],
        [69, 21],
        [195, 43],
        [101, 12],
        [127, -3],
        [233, -24],
        [28, -17],
        [7, -9],
        [10, -24],
        [-7, -16],
        [-64, -52],
        [-80, -44],
        [-53, -38],
        [-88, -86],
        [-24, -30],
        [-110, -173],
        [-26, -28],
        [-15, -23],
        [-11, -63],
        [4, -22],
        [17, -36],
        [59, -79],
        [16, -36],
        [0, -34],
        [-7, -81],
        [-1, -41],
        [3, -39],
        [12, -56],
        [22, -73],
        [51, -74],
        [79, -74],
        [59, -50],
        [59, -36],
        [69, -54],
        [15, -26],
        [-32, -29],
        [-74, -44],
        [-98, -19],
        [-52, -18],
        [-65, -39],
        [-82, -30],
        [-32, -19],
      ],
      [
        [32833, 80122],
        [-117, -8],
        [-92, 36],
        [-69, 17],
        [-67, 31],
        [-146, 100],
        [-16, 35],
        [-14, 43],
        [-28, 39],
        [-30, 32],
        [-154, 98],
        [-13, 34],
        [31, 23],
        [36, 10],
        [31, -1],
        [104, -38],
        [130, -34],
        [56, -26],
        [64, -38],
        [62, -47],
        [140, -125],
        [24, -10],
        [63, -61],
        [23, -46],
        [11, -38],
        [-14, -19],
        [-15, -7],
      ],
      [
        [32274, 78610],
        [8, -8],
        [13, 15],
        [15, 47],
        [41, -12],
        [22, -21],
        [12, 4],
        [12, -2],
        [23, -28],
        [44, -22],
        [46, 4],
        [70, 13],
        [8, 5],
        [72, 11],
        [72, 5],
        [25, -12],
        [9, -12],
        [5, -14],
        [-41, -38],
        [-41, -44],
        [-58, -44],
        [-7, -21],
        [4, -39],
        [-1, -40],
        [11, -3],
        [7, -14],
        [-15, -13],
        [-59, -6],
        [-17, 4],
        [-21, 16],
        [-7, 39],
        [-25, -6],
        [-7, 5],
        [35, 32],
        [-16, 42],
        [-18, -3],
        [-11, 19],
        [1, 27],
        [16, 13],
        [5, 14],
        [-22, -13],
        [-17, -24],
        [-21, -9],
        [-22, -22],
        [33, -6],
        [-17, -17],
        [-17, -3],
        [-81, 32],
        [-20, 12],
        [-26, 34],
        [-19, 45],
        [11, 2],
        [3, 8],
        [-2, 7],
        [-28, 6],
        [-45, -2],
        [-25, 12],
        [1, 79],
        [-8, 22],
        [-28, 18],
        [-42, 5],
        [-4, 30],
        [13, 44],
        [21, 39],
        [16, 37],
        [18, 31],
        [46, 62],
        [-1, -46],
        [4, -40],
        [-30, -79],
        [52, -79],
        [6, -17],
        [5, -21],
        [-4, -19],
        [-8, -18],
        [20, -8],
        [6, -15],
      ],
      [
        [27222, 88107],
        [11, -17],
        [3, -24],
        [-4, -32],
        [-7, -30],
        [-10, -27],
        [-25, -44],
        [-76, -77],
        [-28, -42],
        [-22, -25],
        [-124, -111],
        [-16, -6],
        [-16, 2],
        [-34, 17],
        [-34, 3],
        [-90, -45],
        [-4, 8],
        [-4, 48],
        [-9, 27],
        [-40, 57],
        [-2, 13],
        [0, 16],
        [3, 13],
        [45, 54],
        [101, 193],
        [24, 10],
        [49, -22],
        [24, -7],
        [17, 1],
        [72, 41],
        [68, -5],
        [63, 24],
        [29, 0],
        [23, -4],
        [13, -9],
      ],
      [
        [27904, 87794],
        [22, -16],
        [36, -52],
        [14, -26],
        [4, -36],
        [-10, -48],
        [-5, -44],
        [-13, -34],
        [-25, -42],
        [-22, -50],
        [-20, -56],
        [-15, -38],
        [-13, -18],
        [-14, -10],
        [-14, -1],
        [-23, 21],
        [-30, 42],
        [-24, 25],
        [-31, 18],
        [-17, 23],
        [-3, 23],
        [-1, 76],
        [1, 37],
        [4, 32],
        [7, 25],
        [16, 34],
        [44, 75],
        [26, 29],
        [16, 7],
        [43, -6],
        [18, 2],
        [14, 9],
        [15, -1],
      ],
      [
        [28979, 91199],
        [145, -51],
        [14, -19],
        [7, -16],
        [4, -19],
        [0, -38],
        [-3, -15],
        [-14, -36],
        [-1, -12],
        [11, -123],
        [-1, -67],
        [-10, -56],
        [-21, -45],
        [-31, -34],
        [-24, -20],
        [-105, -48],
        [-75, -12],
        [-79, -2],
        [-100, -13],
        [-46, 3],
        [-24, 5],
        [-17, 10],
        [-19, 30],
        [-23, 51],
        [-18, 58],
        [-23, 102],
        [0, 12],
        [21, 83],
        [29, 56],
        [50, 82],
        [57, 81],
        [14, 14],
        [26, 14],
        [64, 23],
        [54, -6],
        [23, 3],
        [29, 10],
        [33, 3],
        [53, -8],
      ],
      [
        [27906, 94270],
        [47, -7],
        [300, 14],
        [63, -11],
        [189, -64],
        [48, -21],
        [25, -28],
        [21, -45],
        [10, -10],
        [69, -26],
        [28, -32],
        [10, -17],
        [14, -38],
        [31, -22],
        [36, -12],
        [11, -11],
        [-5, -48],
        [15, -22],
        [33, -27],
        [13, -18],
        [-26, -22],
        [-61, -13],
        [-170, 13],
        [-228, 31],
        [-133, -9],
        [-67, -14],
        [-161, -49],
        [-51, -8],
        [-51, -1],
        [-89, 40],
        [-32, 22],
        [-11, 16],
        [-21, 49],
        [-17, 58],
        [-9, 48],
        [-10, 37],
        [-31, 12],
        [-90, 14],
        [-30, 20],
        [-14, 17],
        [-13, 27],
        [0, 27],
        [7, 25],
        [6, 6],
        [11, 1],
        [-25, 29],
        [-9, 33],
        [-1, 46],
        [4, 29],
        [7, 13],
        [17, 8],
        [39, 5],
        [58, -1],
        [81, -33],
        [64, -4],
        [98, -27],
      ],
      [
        [27574, 82227],
        [-19, -8],
        [-58, 16],
        [-24, 11],
        [-71, 41],
        [-135, 61],
        [-46, 31],
        [-10, 22],
        [25, 48],
        [13, 19],
        [15, 12],
        [143, 22],
        [55, -11],
        [65, -97],
        [38, -66],
        [15, -52],
        [0, -26],
        [-6, -23],
      ],
      [
        [28103, 84184],
        [-14, -8],
        [-10, 1],
        [2, 19],
        [14, 38],
        [8, 34],
        [2, 28],
        [6, 25],
        [10, 22],
        [11, 11],
        [15, -1],
        [4, -70],
        [-5, -32],
        [-10, -28],
        [-14, -22],
        [-19, -17],
      ],
      [
        [28073, 84254],
        [-23, -58],
        [-18, -56],
        [-25, -106],
        [-15, -3],
        [-13, 25],
        [37, 123],
        [1, 14],
        [-1, 12],
        [-11, 18],
        [-11, -21],
        [-52, -139],
        [-13, -22],
        [-11, -13],
        [-9, -2],
        [-22, 3],
        [-44, -40],
        [74, 165],
        [1, 13],
        [-14, 8],
        [-6, -5],
        [-60, -104],
        [-34, -40],
        [-23, 13],
        [-6, 10],
        [2, 13],
        [59, 104],
        [54, 75],
        [22, 48],
        [9, 44],
        [4, 33],
        [0, 36],
        [3, 10],
        [3, -2],
        [3, -14],
        [1, -38],
        [-12, -77],
        [-10, -38],
        [-12, -32],
        [5, -7],
        [22, 18],
        [18, 38],
        [15, 57],
        [9, 50],
        [10, 80],
        [3, -3],
        [4, -16],
        [10, -11],
        [15, -7],
        [9, -10],
        [6, -26],
        [7, -12],
        [23, -10],
        [9, -8],
        [6, -29],
        [-1, -16],
        [3, -9],
        [7, -5],
        [-8, -31],
      ],
      [
        [27784, 84220],
        [-14, -4],
        [-17, 8],
        [9, 42],
        [14, 18],
        [36, 17],
        [7, 11],
        [11, 6],
        [17, 0],
        [19, 15],
        [21, 32],
        [7, 4],
        [-14, -46],
        [-16, -34],
        [-80, -69],
      ],
      [
        [25046, 96352],
        [-73, -33],
        [-37, 1],
        [-212, 67],
        [-43, 33],
        [-8, 23],
        [-2, 32],
        [0, 43],
        [9, 30],
        [12, 10],
        [25, 10],
        [49, 7],
        [46, -4],
        [70, -12],
        [70, -19],
        [92, -60],
        [34, -28],
        [7, -37],
        [0, -22],
        [-5, -17],
        [-10, -10],
        [-24, -14],
      ],
      [
        [20956, 96279],
        [-43, -10],
        [-84, 23],
        [-56, 10],
        [-45, 42],
        [-49, 57],
        [-39, 62],
        [-14, 37],
        [-28, 22],
        [-10, 36],
        [-51, 57],
        [8, 9],
        [48, 8],
        [77, -11],
        [36, -20],
        [47, -34],
        [60, -54],
        [18, -24],
        [4, -33],
        [11, -17],
        [51, -4],
        [63, -43],
        [12, -17],
        [13, -34],
        [-1, -16],
        [-10, -34],
        [-18, -12],
      ],
      [
        [22558, 97915],
        [6, -75],
        [-6, -38],
        [-14, -28],
        [-12, -7],
        [-17, 0],
        [-76, 21],
        [-23, 13],
        [-1, 11],
        [-8, 21],
        [-50, 27],
        [-95, -4],
        [-40, 3],
        [-16, 8],
        [-10, 12],
        [-9, 47],
        [2, 17],
        [11, 29],
        [7, 7],
        [70, 27],
        [20, 2],
        [85, -10],
        [75, -1],
        [38, -7],
        [34, -17],
        [20, -26],
        [9, -32],
      ],
      [
        [20753, 93847],
        [-14, -3],
        [-26, 14],
        [-39, 29],
        [-63, 60],
        [-75, 59],
        [-12, 36],
        [-19, 26],
        [-96, 63],
        [-62, 26],
        [-48, 13],
        [-7, 17],
        [33, 51],
        [37, 41],
        [23, 15],
        [70, 13],
        [236, 27],
        [54, 1],
        [56, -13],
        [78, -56],
        [32, -6],
        [20, -13],
        [17, -21],
        [9, -21],
        [0, -44],
        [-8, -64],
        [-11, -25],
        [-47, -82],
        [-49, -46],
        [-10, -29],
        [-20, -23],
        [-35, -29],
        [-24, -16],
      ],
      [
        [21603, 95630],
        [58, -35],
        [3, -9],
        [-11, -6],
        [-75, -19],
        [-29, -15],
        [-25, -35],
        [-19, -16],
        [-101, -10],
        [-103, 1],
        [20, 33],
        [56, 56],
        [-44, 22],
        [-158, -38],
        [-60, 24],
        [51, 60],
        [-51, 5],
        [-71, 0],
        [-46, 36],
        [16, 42],
        [94, 24],
        [122, 20],
        [131, 31],
        [103, -3],
        [40, -14],
        [14, -49],
        [12, -59],
        [18, -5],
        [55, -41],
      ],
      [
        [21105, 95957],
        [13, -3],
        [43, 12],
        [27, 2],
        [30, -22],
        [8, -14],
        [110, -36],
        [38, -16],
        [5, -10],
        [-13, -15],
        [-33, -20],
        [-31, -13],
        [-44, -11],
        [-222, -2],
        [-24, 5],
        [-14, 18],
        [-28, 65],
        [-19, 36],
        [-8, 24],
        [5, 14],
        [24, 13],
        [82, 21],
        [36, 0],
        [23, -5],
        [9, -11],
        [-3, -14],
        [-14, -18],
      ],
      [
        [17131, 95379],
        [-79, -37],
        [-57, 4],
        [-75, 27],
        [-61, 10],
        [-21, 9],
        [-3, 9],
        [20, 26],
        [26, 21],
        [62, 41],
        [105, 78],
        [69, 30],
        [67, 21],
        [69, 47],
        [38, 21],
        [33, 2],
        [33, -9],
        [4, -13],
        [-35, -64],
        [-25, -26],
        [-49, -67],
        [-93, -112],
        [-28, -18],
      ],
      [
        [18380, 96632],
        [-76, -19],
        [-51, 0],
        [-89, 28],
        [-103, 84],
        [-14, 30],
        [39, 5],
        [28, 9],
        [17, 13],
        [34, 15],
        [76, 21],
        [10, -8],
        [-2, -18],
        [6, -16],
        [28, -4],
        [26, -11],
        [53, -36],
        [36, -7],
        [13, -8],
        [6, -12],
        [22, -21],
        [0, -11],
        [-30, -22],
        [-29, -12],
      ],
      [
        [13631, 83225],
        [-6, -1],
        [-3, 12],
        [2, 25],
        [9, 42],
        [4, 9],
        [36, -7],
        [5, -3],
        [1, -8],
        [-2, -13],
        [-12, -19],
        [-34, -37],
      ],
      [
        [13603, 81774],
        [-5, -1],
        [-9, 12],
        [-7, 19],
        [-4, 51],
        [3, 20],
        [3, 8],
        [24, -32],
        [-5, -77],
      ],
      [
        [13823, 82925],
        [-8, -21],
        [-20, -32],
        [-13, -13],
        [-6, 6],
        [-18, 4],
        [-19, 32],
        [-14, 13],
        [-10, 1],
        [-5, -12],
        [-1, -15],
        [3, -20],
        [-2, -9],
        [-6, 2],
        [-5, 9],
        [-6, 16],
        [-1, 17],
        [4, 17],
        [13, 24],
        [42, 47],
        [13, 9],
        [14, -2],
        [23, -22],
        [5, -7],
        [17, -44],
      ],
      [
        [13931, 82469],
        [-6, -2],
        [-18, 7],
        [-60, 98],
        [-43, 35],
        [-29, 48],
        [-30, 31],
        [18, 50],
        [16, -6],
        [56, -41],
        [44, -39],
        [25, -26],
        [53, -110],
        [-4, -16],
        [-22, -29],
      ],
      [
        [13829, 83540],
        [3, -46],
        [-40, -76],
        [-52, -38],
        [-11, 12],
      ],
      [
        [14291, 82338],
        [13, -184],
        [-1, -59],
        [-19, -38],
        [-13, -65],
        [-15, -29],
        [-15, 39],
        [-1, 64],
        [-4, 51],
        [-5, 24],
        [6, 95],
        [-6, -7],
        [-18, -41],
        [-19, -3],
        [-34, 47],
        [-17, 38],
        [-3, 40],
        [-22, 42],
        [-3, 15],
        [2, 16],
        [19, 42],
        [7, 28],
        [7, 58],
        [7, 23],
        [18, -3],
        [31, -27],
        [33, -28],
        [30, -39],
        [22, -99],
      ],
      [
        [14974, 80272],
        [8, -52],
        [-34, 9],
        [-12, 10],
        [0, 25],
        [6, 23],
        [26, -8],
        [6, -7],
      ],
      [
        [15513, 80374],
        [4, -12],
        [-62, 45],
        [-27, 27],
        [-10, 19],
        [-6, 11],
        [-32, 28],
        [-5, 13],
        [7, 10],
        [21, -6],
        [35, -21],
        [32, -35],
        [43, -79],
      ],
      [
        [14822, 80417],
        [-11, -2],
        [-18, 7],
        [-19, 16],
        [-35, 44],
        [-3, 10],
        [3, 9],
        [9, 7],
        [3, 11],
        [-8, 32],
        [27, 20],
        [25, -17],
        [11, -20],
        [13, -36],
        [6, -41],
        [1, -28],
        [-4, -12],
      ],
      [
        [15226, 80700],
        [-3, -30],
        [-17, 49],
        [-28, 105],
        [4, 24],
        [12, 35],
        [11, 2],
        [18, -16],
        [16, -29],
        [3, -11],
        [10, -30],
        [5, -27],
        [-11, -33],
        [-20, -39],
      ],
      [
        [14184, 82090],
        [-9, -26],
        [-37, 63],
        [-13, 18],
        [-28, 67],
        [-5, 29],
        [1, 17],
        [5, 6],
        [9, -4],
        [8, -8],
        [53, -74],
        [15, -35],
        [1, -53],
      ],
      [
        [14465, 81493],
        [-4, -9],
        [-11, 0],
        [-18, 10],
        [-13, 21],
        [-16, 66],
        [2, 12],
        [5, 12],
        [25, 24],
        [10, -3],
        [3, -18],
        [15, -39],
        [5, -11],
        [0, -46],
        [-3, -19],
      ],
      [
        [14342, 82027],
        [-21, -7],
        [7, 31],
        [2, 18],
        [-4, 17],
        [-3, 33],
        [-1, 79],
        [21, 49],
        [33, 1],
        [-1, -25],
        [-14, -111],
        [-7, -51],
        [-5, -19],
        [-7, -15],
      ],
      [
        [14079, 82368],
        [-4, -5],
        [-11, 8],
        [-11, 15],
        [-19, 43],
        [-6, 18],
        [-4, 29],
        [3, 5],
        [9, -3],
        [5, -5],
        [30, -70],
        [8, -35],
      ],
      [
        [14120, 82440],
        [-2, -4],
        [-28, 0],
        [-8, 6],
        [-5, 12],
        [-2, 19],
        [4, 22],
        [17, 42],
        [1, 18],
        [3, 8],
        [12, -22],
        [5, -19],
        [3, -82],
      ],
      [
        [15712, 79927],
        [-11, -15],
        [-7, 2],
        [-5, 10],
        [-18, 102],
        [8, -3],
        [24, -31],
        [-5, -12],
        [18, -31],
        [4, -21],
        [-8, -1],
      ],
      [
        [33392, 77153],
        [-37, -20],
        [-32, 1],
        [-22, 19],
        [-1, 8],
        [51, -8],
        [19, 5],
        [39, 32],
        [-17, -37],
      ],
      [
        [33066, 78046],
        [-12, -4],
        [-2, 8],
        [-18, 24],
        [-1, 12],
        [15, 11],
        [32, -6],
        [-12, -30],
        [-2, -15],
      ],
      [
        [32801, 79080],
        [10, -11],
        [18, 1],
        [12, -4],
        [-17, -21],
        [-33, -3],
        [-16, 9],
        [23, 110],
        [27, 26],
        [56, 71],
        [22, 22],
        [21, 9],
        [21, -5],
        [-22, -43],
        [-30, -2],
        [-28, -35],
        [-18, -40],
        [-23, -22],
        [-15, -27],
        [-8, -35],
      ],
      [
        [32081, 79427],
        [-7, -42],
        [-25, -36],
        [-12, -2],
        [-5, 3],
        [6, 23],
        [0, 40],
        [20, 6],
        [7, -4],
        [16, 12],
      ],
      [
        [32090, 79469],
        [-32, -30],
        [14, 45],
        [6, 11],
        [5, 5],
        [5, -4],
        [2, -27],
      ],
      [
        [31590, 77357],
        [-14, -21],
        [4, 20],
        [17, 51],
        [11, 7],
        [-18, -57],
      ],
      [
        [31455, 77581],
        [-38, -31],
        [15, 78],
        [12, 24],
        [15, -8],
        [-2, -47],
        [-2, -16],
      ],
      [
        [29565, 78034],
        [-22, -11],
        [-36, 11],
        [-41, -15],
        [-11, 0],
        [30, 42],
        [46, 27],
        [46, 80],
        [13, 2],
        [-18, -91],
        [-3, -33],
        [-4, -12],
      ],
      [
        [29529, 78102],
        [-34, -12],
        [-11, 5],
        [37, 56],
        [42, 13],
        [-34, -62],
      ],
      [
        [30270, 78843],
        [-25, -5],
        [6, 20],
        [35, 36],
        [25, 20],
        [15, 0],
        [-24, -44],
        [-32, -27],
      ],
      [
        [34573, 81059],
        [-9, -6],
        [-9, 0],
        [-8, 7],
        [-1, 11],
        [8, 23],
        [21, 12],
        [17, -3],
        [-1, -12],
        [-9, -19],
        [-9, -13],
      ],
      [
        [34846, 80408],
        [-43, -34],
        [-10, -13],
        [-12, -7],
        [-9, 11],
        [-12, 35],
        [2, 12],
        [12, 2],
        [7, -5],
        [1, -11],
        [5, -6],
        [9, 0],
        [32, 35],
        [16, 5],
        [6, -6],
        [-4, -18],
      ],
      [
        [34622, 81733],
        [-14, -1],
        [-3, 7],
        [6, 22],
        [15, 26],
        [20, 7],
        [-6, -38],
        [-18, -23],
      ],
      [
        [34974, 80497],
        [20, -37],
        [11, -10],
        [-72, -41],
        [-8, -2],
        [-5, 4],
        [-1, 38],
        [4, 29],
        [5, 5],
        [16, -18],
        [17, 36],
        [13, -4],
      ],
      [
        [34937, 79171],
        [-14, -20],
        [-14, 0],
        [2, 18],
        [17, 34],
        [8, 24],
        [1, 15],
        [3, 11],
        [13, 13],
        [11, 23],
        [-5, -43],
        [-22, -75],
      ],
      [
        [31993, 87879],
        [53, -6],
        [33, 2],
        [14, -9],
        [13, -28],
        [-17, -40],
        [-19, -16],
        [-31, -4],
        [-50, 13],
        [-17, 8],
        [-16, 22],
        [7, 15],
        [25, 5],
        [4, 7],
        [-6, 12],
        [0, 10],
        [7, 9],
      ],
      [
        [31046, 86544],
        [-25, -5],
        [-12, 12],
        [0, 35],
        [8, 26],
        [29, 55],
        [25, 62],
        [15, 14],
        [31, -10],
        [18, -17],
        [18, -30],
        [8, -22],
        [-7, -34],
        [-22, -29],
        [-25, -21],
        [-61, -36],
      ],
      [
        [30462, 87873],
        [-20, -2],
        [-37, 4],
        [-40, 12],
        [-23, 14],
        [-19, 29],
        [-4, 33],
        [-38, 48],
        [-42, 16],
        [-23, 33],
        [24, 3],
        [34, -8],
        [49, -14],
        [45, -19],
        [64, -42],
        [21, -40],
        [21, -28],
        [7, -22],
        [-6, -10],
        [-13, -7],
      ],
      [
        [31991, 87192],
        [-7, -7],
        [-6, 2],
        [-21, 30],
        [-28, 13],
        [-10, 12],
        [-84, 63],
        [-9, 28],
        [-2, 22],
        [28, 11],
        [56, 10],
        [49, -1],
        [46, -13],
        [9, -14],
        [24, -26],
        [-6, -30],
        [-2, -40],
        [-10, -19],
        [-15, -14],
        [-12, -27],
      ],
      [
        [31936, 87487],
        [6, -5],
        [7, 6],
        [6, -5],
        [4, -16],
        [6, -12],
        [17, -14],
        [5, -11],
        [0, -11],
        [-14, -16],
        [-9, 0],
        [-66, 37],
        [-18, 39],
        [-1, 19],
        [7, 18],
        [10, 8],
        [13, 0],
        [16, -10],
        [11, -27],
      ],
      [
        [30789, 85852],
        [-17, -42],
        [-23, 5],
        [-8, -8],
        [-6, -1],
        [10, 39],
        [1, 27],
        [-5, 27],
        [8, 13],
        [30, 1],
        [0, -31],
        [4, -11],
        [7, -6],
        [-1, -13],
      ],
      [
        [32109, 86616],
        [-10, -40],
        [-32, 15],
        [-50, 30],
        [-20, 20],
        [-7, 22],
        [0, 30],
        [15, 5],
        [37, 3],
        [32, -42],
        [9, -6],
        [26, -37],
      ],
      [
        [33057, 84123],
        [3, -14],
        [-43, 10],
        [-15, 9],
        [-1, 9],
        [2, 15],
        [8, 17],
        [20, 13],
        [11, -7],
        [23, -17],
        [3, -11],
        [-11, -24],
      ],
      [
        [32849, 84996],
        [23, -17],
        [6, -63],
        [-44, 4],
        [-50, 42],
        [-10, 31],
        [1, 5],
        [7, 5],
        [13, -7],
        [12, 11],
        [13, 3],
        [29, -14],
      ],
      [
        [31134, 91901],
        [-7, -4],
        [-72, 26],
        [-6, 21],
        [36, 24],
        [29, 12],
        [22, 2],
        [22, -4],
        [21, -25],
        [-25, -23],
        [-20, -29],
      ],
      [
        [32588, 90469],
        [-34, -15],
        [-19, 19],
        [13, 6],
        [19, 23],
        [26, 20],
        [11, 17],
        [43, 8],
        [15, -1],
        [5, -6],
        [-24, -26],
        [-55, -45],
      ],
      [
        [28038, 95579],
        [3, -34],
        [-20, 1],
        [-65, -22],
        [-52, -3],
        [-26, 10],
        [-17, 23],
        [41, 43],
        [47, 31],
        [57, 47],
        [47, 30],
        [23, -6],
        [22, -16],
        [-28, -47],
        [-30, -23],
        [-2, -34],
      ],
      [
        [27814, 84547],
        [-7, -10],
        [-14, 11],
        [0, 29],
        [13, 22],
        [10, -1],
        [10, -12],
        [-3, -15],
        [-9, -24],
      ],
      [
        [27856, 84974],
        [-4, -5],
        [-12, 4],
        [-5, -38],
        [-4, -3],
        [-9, 23],
        [7, 20],
        [-1, 14],
        [2, 10],
        [12, 23],
        [7, 5],
        [4, -2],
        [4, -29],
        [-1, -22],
      ],
      [
        [27911, 84479],
        [-9, -7],
        [-7, 0],
        [7, 51],
        [-8, 18],
        [-1, 9],
        [4, 9],
        [5, 2],
        [11, -16],
        [6, -17],
        [2, -16],
        [0, -16],
        [-4, -11],
        [-6, -6],
      ],
      [
        [27795, 82548],
        [-1, -17],
        [-18, 3],
        [-9, 10],
        [-8, 15],
        [-2, 12],
        [7, 11],
        [21, -7],
        [10, -27],
      ],
      [
        [27949, 81769],
        [-12, -4],
        [-26, 4],
        [-22, 15],
        [-13, 18],
        [86, 51],
        [18, -6],
        [0, -10],
        [-13, -27],
        [-3, -18],
        [-6, -14],
        [-9, -9],
      ],
      [
        [15730, 80003],
        [-4, -7],
        [-43, 41],
        [-29, 54],
        [-12, 32],
        [57, -81],
        [29, -26],
        [2, -13],
      ],
      [
        [15284, 80661],
        [-7, -5],
        [-7, 66],
        [9, 23],
        [2, 12],
        [-1, 13],
        [15, -30],
        [6, -20],
        [2, -27],
        [0, -8],
        [-19, -24],
      ],
      [
        [29549, 90889],
        [-135, -1],
        [-74, 4],
        [-29, 9],
        [-26, 13],
        [-29, 45],
        [-19, 45],
        [-1, 20],
        [5, 17],
        [7, 11],
        [91, 15],
        [74, -19],
        [64, -22],
        [83, -4],
        [25, -9],
        [9, -6],
        [7, -11],
        [9, -53],
        [1, -28],
        [-2, -21],
        [-60, -5],
      ],
      [
        [28367, 88404],
        [24, -25],
        [24, 2],
        [14, -20],
        [32, -63],
        [3, -10],
        [-1, -21],
        [-17, -26],
        [-18, -13],
        [-37, -20],
        [-42, -9],
        [-23, 14],
        [-64, 58],
        [-59, 68],
        [-19, 38],
        [8, 16],
        [25, 11],
        [51, 11],
        [83, -6],
        [16, -5],
      ],
      [
        [23742, 95477],
        [-27, 0],
        [-35, 12],
        [-10, 12],
        [-8, 17],
        [-5, 22],
        [-7, 18],
        [-10, 13],
        [-2, 10],
        [6, 9],
        [11, 5],
        [27, 2],
        [57, 22],
        [11, -2],
        [8, -12],
        [7, -31],
        [9, -19],
        [22, -34],
        [10, -22],
        [-2, -6],
        [-7, -6],
        [-55, -10],
      ],
      [
        [27698, 86188],
        [-9, -2],
        [-2, 7],
        [7, 24],
        [12, 3],
        [13, 26],
        [12, -9],
        [-4, -15],
        [-16, -22],
        [-13, -12],
      ],
      [
        [27760, 86273],
        [-29, -4],
        [13, 34],
        [10, 16],
        [12, 11],
        [24, 4],
        [16, -14],
        [-15, -25],
        [-31, -22],
      ],
      [
        [23116, 93857],
        [-45, -6],
        [-41, 40],
        [-2, 38],
        [3, 21],
        [5, 18],
        [15, 16],
        [43, 18],
        [19, -13],
        [7, -17],
        [6, -6],
        [28, -14],
        [13, -16],
        [-2, -19],
        [-8, -28],
        [-10, -18],
        [-11, -8],
        [-20, -6],
      ],
      [
        [22957, 94772],
        [-84, -34],
        [-18, 13],
        [-8, 12],
        [65, 53],
        [28, 14],
        [27, -16],
        [7, -13],
        [-3, -9],
        [-14, -20],
      ],
      [
        [22702, 94394],
        [-80, -13],
        [-36, 6],
        [-20, -16],
        [-15, -7],
        [-44, -3],
        [-90, 29],
        [-24, 10],
        [-9, 9],
        [4, 9],
        [16, 9],
        [69, 12],
        [25, 10],
        [11, 13],
        [17, 11],
        [24, 8],
        [65, 8],
        [146, 41],
        [72, 5],
        [28, -3],
        [9, -11],
        [2, -10],
        [-4, -11],
        [-22, -28],
        [-30, -21],
        [-79, -46],
        [-35, -11],
      ],
      [
        [24944, 91831],
        [6, -36],
        [-24, -49],
        [-8, -8],
        [-10, -4],
        [-9, 6],
        [-28, 38],
        [-8, 24],
        [10, 12],
        [22, 15],
        [15, 7],
        [20, -6],
        [6, 10],
        [8, -9],
      ],
      [
        [24863, 91716],
        [-23, -6],
        [-14, 24],
        [-12, 5],
        [-5, 16],
        [-24, 3],
        [2, 25],
        [7, 12],
        [22, 10],
        [17, -4],
        [17, -25],
        [8, -20],
        [7, -25],
        [-2, -15],
      ],
      [
        [29444, 87913],
        [-15, -5],
        [-55, 7],
        [-69, 27],
        [-35, 25],
        [2, 8],
        [15, 4],
        [18, -4],
        [29, -17],
        [80, -9],
        [25, -10],
        [8, -16],
        [-3, -10],
      ],
      [
        [29199, 91214],
        [-21, -4],
        [-32, 36],
        [-66, 40],
        [-25, 30],
        [-1, 14],
        [2, 22],
        [8, 27],
        [23, 30],
        [24, 5],
        [35, -7],
        [25, -21],
        [27, -59],
        [19, -29],
        [5, -21],
        [-9, -10],
        [1, -10],
        [4, -6],
        [-1, -11],
        [-8, -16],
        [-10, -10],
      ],
      [
        [28185, 86824],
        [-38, -6],
        [0, 8],
        [16, 24],
        [59, 20],
        [44, 6],
        [-10, -20],
        [-26, -16],
        [-45, -16],
      ],
      [
        [28060, 91124],
        [-23, -6],
        [-30, 30],
        [0, 17],
        [6, 41],
        [55, 10],
        [24, -24],
        [11, -24],
        [-43, -44],
      ],
      [
        [28700, 88360],
        [-29, -6],
        [-38, 13],
        [-38, 25],
        [-85, 80],
        [64, 54],
        [102, -63],
        [31, -40],
        [-7, -63],
      ],
      [
        [27936, 92043],
        [11, -33],
        [7, -11],
        [-11, -15],
        [-41, -31],
        [-92, -13],
        [-46, 14],
        [22, -44],
        [4, -19],
        [-6, -8],
        [-20, 2],
        [-31, 13],
        [-19, 15],
        [-4, 18],
        [-7, 4],
        [-9, -10],
        [-8, 2],
        [-19, 27],
        [-14, 9],
        [-92, 16],
        [-4, 7],
        [5, 12],
        [14, 18],
        [20, 5],
        [52, -7],
        [4, 4],
        [4, 22],
        [4, 9],
        [36, -3],
        [22, 5],
        [13, -11],
        [12, -26],
        [17, 5],
        [26, -4],
        [28, 9],
        [43, 23],
        [33, 9],
        [46, -13],
      ],
      [
        [28325, 92001],
        [14, -29],
        [3, -15],
        [-20, -17],
        [-74, -33],
        [-46, -28],
        [-22, -7],
        [-31, 7],
        [-37, -14],
        [-15, 2],
        [17, 23],
        [58, 67],
        [49, 7],
        [16, 14],
        [14, -5],
        [8, 12],
        [1, 17],
        [17, 13],
        [16, 0],
        [32, -14],
      ],
      [
        [28432, 88704],
        [-20, -26],
        [-59, 9],
        [-8, 8],
        [-2, 10],
        [9, 12],
        [61, 12],
        [26, 1],
        [14, -4],
        [1, -4],
        [-22, -18],
      ],
      [
        [26910, 90024],
        [28, -7],
        [21, 1],
        [4, -9],
        [-22, -28],
        [-13, -5],
        [-25, 20],
        [-18, 25],
        [-5, 15],
        [-2, 16],
        [4, 3],
        [28, -31],
      ],
      [
        [27997, 91500],
        [-19, -4],
        [-23, 11],
        [-8, 19],
        [-4, 19],
        [4, 10],
        [10, 9],
        [13, 21],
        [18, 32],
        [27, 22],
        [60, 21],
        [8, 7],
        [27, 58],
        [9, 9],
        [30, 6],
        [3, 8],
        [-10, 14],
        [0, 14],
        [10, 15],
        [15, 12],
        [39, 10],
        [35, -2],
        [9, -4],
        [7, -10],
        [11, -27],
        [1, -6],
        [-16, -24],
        [-42, -36],
        [-26, -31],
        [-5, -10],
        [-3, -13],
        [-9, -15],
        [-31, -38],
        [-20, -36],
        [-21, -20],
        [-56, -19],
        [-43, -22],
      ],
      [
        [28612, 91672],
        [-35, -7],
        [-26, 4],
        [-17, 13],
        [-13, 19],
        [-16, 46],
        [6, 22],
        [2, 38],
        [3, 15],
        [6, 7],
        [37, 14],
        [21, -2],
        [32, -15],
        [69, -4],
        [18, -14],
        [4, -8],
        [-1, -10],
        [-4, -12],
        [-34, -35],
        [-17, -24],
        [-12, -29],
        [-23, -18],
      ],
      [
        [25857, 92230],
        [32, -4],
        [30, 5],
        [22, -5],
        [13, -16],
        [9, -17],
        [5, -18],
        [-12, -13],
        [-49, -11],
        [-34, 6],
        [-35, 15],
        [-17, -7],
        [-41, 11],
        [-20, 14],
        [-17, 21],
        [0, 13],
        [43, 14],
        [17, 12],
        [54, -20],
      ],
      [
        [26743, 89744],
        [35, -23],
        [36, -13],
        [57, -6],
        [8, -5],
        [0, -11],
        [-8, -16],
        [-19, -22],
        [-14, 0],
        [-31, 15],
        [-12, 7],
        [-13, 17],
        [-6, 2],
        [-9, -6],
        [-2, -7],
        [4, -10],
        [-5, -2],
        [-39, 6],
        [-6, 6],
        [3, 18],
        [27, 27],
        [-23, 8],
        [-8, 10],
        [-35, -17],
        [-19, -4],
        [-30, 12],
        [-4, 60],
        [-3, 23],
        [-14, 15],
        [-9, 16],
        [-13, 12],
        [-27, 12],
        [-23, 30],
        [-4, 14],
        [3, 10],
        [13, 14],
        [79, -30],
        [48, -29],
        [46, -36],
        [23, -26],
        [2, -17],
        [-5, -17],
        [-14, -17],
        [11, -20],
      ],
      [
        [25945, 90861],
        [-11, -1],
        [-19, 9],
        [-43, 35],
        [-9, 15],
        [-4, 17],
        [0, 21],
        [3, 20],
        [14, 42],
        [-26, 33],
        [-6, 19],
        [3, 10],
        [14, 26],
        [4, 16],
        [14, 22],
        [37, 44],
        [37, -10],
        [32, -36],
        [9, -24],
        [-3, -26],
        [3, -37],
        [8, -49],
        [3, -36],
        [-4, -22],
        [-14, -41],
        [-12, -20],
        [-16, -18],
        [-14, -9],
      ],
      [
        [26479, 89616],
        [-15, -6],
        [-15, 3],
        [-13, 17],
        [-11, 30],
        [-17, 22],
        [-39, 28],
        [-7, 11],
        [-11, 37],
        [-2, 37],
        [-9, 34],
        [0, 16],
        [7, 25],
        [33, 6],
        [26, -10],
        [5, -7],
        [8, -14],
        [6, -18],
        [31, -47],
        [18, -38],
        [25, -78],
        [0, -15],
        [-7, -16],
        [-13, -17],
      ],
      [
        [24154, 87467],
        [-11, -1],
        [-26, 29],
        [-5, 15],
        [33, 9],
        [23, -26],
        [-2, -12],
        [-12, -14],
      ],
      [
        [21881, 95955],
        [-72, -2],
        [-33, 6],
        [-2, 11],
        [29, 13],
        [95, 22],
        [57, 39],
        [21, 5],
        [73, 6],
        [43, -1],
        [55, -10],
        [-132, -49],
        [-134, -40],
      ],
      [
        [21388, 96858],
        [-32, -12],
        [-37, 7],
        [-6, 7],
        [0, 9],
        [3, 10],
        [42, 36],
        [38, 12],
        [23, 3],
        [18, -13],
        [10, -18],
        [-59, -41],
      ],
      [
        [21752, 96599],
        [-39, -6],
        [-69, 3],
        [-83, 21],
        [-22, 24],
        [-5, 38],
        [1, 22],
        [7, 4],
        [51, 5],
        [96, 6],
        [78, -5],
        [88, -22],
        [36, -14],
        [18, -10],
        [22, -20],
        [8, -8],
        [5, -16],
        [-110, -4],
        [-52, -6],
        [-30, -12],
      ],
      [
        [25076, 95914],
        [-13, -8],
        [-42, 4],
        [-14, -8],
        [-22, 5],
        [-31, 16],
        [-36, 32],
        [-41, 48],
        [-32, 41],
        [-1, 12],
        [10, 19],
        [32, 13],
        [76, 15],
        [52, 0],
        [48, -31],
        [14, -11],
        [8, -13],
        [1, -12],
        [-4, -11],
        [-22, -24],
        [-10, -16],
        [0, -17],
        [5, -23],
        [9, -16],
        [13, -8],
        [0, -7],
      ],
      [
        [23311, 95339],
        [-21, -19],
        [-23, -1],
        [-30, 18],
        [-32, -7],
        [-45, -36],
        [-16, -22],
        [-12, -7],
        [-37, -7],
        [-16, 6],
        [-15, 19],
        [-14, 32],
        [10, 24],
        [35, 16],
        [93, 27],
        [26, 13],
        [3, 14],
        [6, 9],
        [8, 4],
        [62, -23],
        [29, -16],
        [22, -18],
        [-2, -8],
        [-31, -18],
      ],
      [
        [23526, 94760],
        [-13, -2],
        [-25, 3],
        [-93, 26],
        [-16, 10],
        [-4, 8],
        [21, 9],
        [8, 10],
        [23, 12],
        [42, 0],
        [44, -29],
        [21, -27],
        [1, -12],
        [-9, -8],
      ],
      [
        [16368, 95475],
        [-22, -3],
        [-24, 6],
        [5, 15],
        [54, 41],
        [3, 11],
        [0, 9],
        [-7, 11],
        [14, 15],
        [22, 5],
        [7, -5],
        [2, -12],
        [-5, -36],
        [-6, -18],
        [-10, -14],
        [-14, -14],
        [-19, -11],
      ],
      [
        [18455, 96049],
        [-42, -18],
        [-289, 27],
        [-15, 9],
        [-8, 12],
        [52, 33],
        [64, 14],
        [146, 11],
        [51, -13],
        [34, -15],
        [19, -12],
        [8, -24],
        [-20, -24],
      ],
      [
        [21078, 95066],
        [-53, -3],
        [-90, 18],
        [-54, 33],
        [-17, 16],
        [2, 8],
        [9, 7],
        [13, 22],
        [31, 63],
        [12, 17],
        [48, 36],
        [36, 10],
        [75, -3],
        [44, -19],
        [18, -12],
        [13, -15],
        [13, -32],
        [3, -21],
        [22, -24],
        [7, -14],
        [0, -14],
        [-6, -14],
        [-12, -13],
        [-29, -21],
        [-85, -25],
      ],
      [
        [22162, 91478],
        [-9, -18],
        [-11, -6],
        [-22, -21],
        [-8, -3],
        [-13, 14],
        [-11, 22],
        [-4, 3],
        [-7, 0],
        [-14, -15],
        [-7, 1],
        [-5, 9],
        [-3, 19],
        [0, 29],
        [7, 43],
        [1, 16],
        [-4, 12],
        [3, 12],
        [10, 10],
        [12, 4],
        [30, -4],
        [23, -17],
        [12, -23],
        [23, -18],
        [7, -13],
        [-10, -56],
      ],
      [
        [22223, 91597],
        [-6, -34],
        [-34, 9],
        [-15, 12],
        [-13, 28],
        [-2, 8],
        [3, 10],
        [14, 25],
        [9, 9],
        [23, -11],
        [10, -14],
        [8, -23],
        [3, -19],
      ],
      [
        [22136, 92451],
        [-3, -5],
        [-60, 22],
        [-23, 12],
        [-8, 10],
        [-5, 19],
        [-3, 28],
        [12, 14],
        [27, -1],
        [28, -11],
        [44, -31],
        [-12, -10],
        [-1, -20],
        [5, -20],
        [-1, -7],
      ],
      [
        [23468, 91920],
        [37, -39],
        [0, -19],
        [-5, -31],
        [-11, -24],
        [-16, -16],
        [-23, -8],
        [-29, -1],
        [-13, 7],
        [10, 24],
        [7, 8],
        [1, 21],
        [-5, 35],
        [-6, 22],
        [-16, 12],
        [-12, 1],
        [-3, -12],
        [5, -23],
        [-4, -30],
        [-13, -38],
        [-9, -17],
        [-18, 9],
        [-8, 14],
        [2, 23],
        [-4, 22],
        [4, 22],
        [12, 34],
        [17, 22],
        [21, 12],
        [26, -1],
        [29, -13],
        [24, -16],
      ],
      [
        [21897, 91818],
        [-23, -5],
        [-4, 1],
        [2, 16],
        [-2, 8],
        [-6, 5],
        [20, 13],
        [3, 10],
        [-7, 7],
        [-27, 14],
        [-8, 13],
        [2, 11],
        [10, 10],
        [19, -2],
        [41, -18],
        [19, -26],
        [8, -20],
        [-13, -3],
        [-11, -8],
        [-11, -16],
        [-12, -10],
      ],
      [
        [21709, 91351],
        [-11, -1],
        [-16, 10],
        [-90, 35],
        [-11, 11],
        [10, 15],
        [33, 19],
        [22, 19],
        [17, 30],
        [51, -16],
        [19, -14],
        [8, -12],
        [3, -17],
        [-3, -41],
        [-17, -9],
        [-15, -29],
      ],
      [
        [20961, 91247],
        [-16, -2],
        [-28, 9],
        [-43, 21],
        [-31, 21],
        [-21, 23],
        [-3, 16],
        [16, 9],
        [24, 4],
        [57, -3],
        [28, -8],
        [36, -34],
        [7, -19],
        [2, -12],
        [-5, -11],
        [-23, -14],
      ],
      [
        [20028, 90668],
        [-14, -48],
        [-6, 5],
        [-9, 23],
        [-20, 11],
        [-21, 25],
        [0, 55],
        [8, 25],
        [-2, 35],
        [22, 21],
        [16, -25],
        [5, -42],
        [-4, -20],
        [16, -22],
        [7, -5],
        [4, -17],
        [-2, -21],
      ],
      [
        [19676, 91003],
        [31, -6],
        [23, 4],
        [17, -23],
        [7, -23],
        [-2, -8],
        [-8, -4],
        [-49, 26],
        [-18, 16],
        [-6, 14],
        [5, 4],
      ],
      [
        [19974, 90440],
        [35, -5],
        [45, 1],
        [-8, -44],
        [-17, -29],
        [-14, -8],
        [-6, 16],
        [-26, 35],
        [-9, 34],
      ],
      [
        [19632, 91008],
        [-10, -2],
        [-38, 34],
        [8, 30],
        [35, -31],
        [5, -18],
        [0, -13],
      ],
      [
        [11377, 91921],
        [-23, -21],
        [-37, 22],
        [-9, 11],
        [42, 30],
        [19, -1],
        [39, -18],
        [14, -15],
        [-45, -8],
      ],
      [
        [52722, 53170],
        [6, 135],
        [12, 113],
        [5, 105],
        [18, 93],
        [-9, 93],
        [-11, 40],
        [-57, 131],
        [26, 50],
        [-34, -7],
        [-7, 49],
        [-17, 58],
        [10, 10],
        [10, 32],
        [31, -10],
        [-1, 16],
        [-27, 49],
        [3, 25],
        [11, 27],
        [-6, 12],
        [-19, -29],
        [-14, 1],
        [-10, 19],
        [-8, 3],
        [5, -38],
        [-11, -33],
        [-10, -12],
        [-18, 2],
        [-14, 8],
        [-4, 19],
        [-13, 15],
        [-38, 24],
        [-31, 30],
        [-7, 80],
        [-12, 34],
        [-6, 39],
        [-3, 44],
        [5, 69],
        [-8, 11],
        [-9, 3],
        [-14, -3],
        [-13, 4],
        [-15, 38],
        [-13, 14],
        [8, -69],
        [-9, -20],
        [-23, 6],
        [-9, 26],
        [-2, 20],
        [10, 84],
        [-4, 2],
      ],
      [
        [78623, 58343],
        [-5, -5],
        [-5, 0],
        [-5, 8],
        [1, 34],
        [3, 20],
        [9, 4],
        [2, -61],
      ],
      [
        [78699, 58017],
        [-11, -22],
        [-16, 46],
        [0, 12],
        [27, -36],
      ],
      [
        [79007, 57840],
        [-46, 75],
        [-90, 26],
        [-10, 33],
        [-9, 6],
        [-8, -43],
        [-50, -41],
        [-21, 25],
        [-15, 30],
        [2, 37],
        [15, 30],
        [24, 22],
        [12, 76],
        [-19, 97],
        [-16, 28],
        [-18, 23],
        [-18, -37],
        [-15, -61],
        [-16, -32],
        [-23, -7],
        [-33, 2],
        [-13, 93],
        [-4, 79],
        [4, 90],
        [5, 53],
        [-32, 74],
        [-2, 70],
        [-15, 37],
        [-4, -19],
        [0, -20],
      ],
      [
        [77417, 57713],
        [-13, -7],
        [-26, -83],
        [-12, 42],
        [-7, 43],
        [8, 98],
        [-16, 186],
        [10, 25],
        [9, 13],
        [18, 71],
        [21, 70],
        [2, 85],
        [17, 61],
        [-4, 52],
        [1, 61],
        [4, 49],
        [-2, 41],
        [14, 42],
        [24, 32],
        [-10, 11],
        [-10, 23],
        [-31, -35],
        [-16, 11],
        [-3, 37],
        [4, 39],
        [2, 23],
        [12, 27],
        [-1, 52],
        [-6, 46],
        [9, 57],
        [-18, 0],
        [-9, 11],
        [5, 32],
        [17, 28],
        [-15, 53],
        [11, 57],
        [0, 71],
        [-8, 62],
        [0, 45],
        [-11, 79],
        [-6, 101],
        [-24, 76],
        [-19, 110],
        [-48, 144],
        [0, 61],
        [-2, 54],
        [-11, 27],
        [-14, -192],
        [-11, 38],
        [-3, 107],
        [-7, 50],
        [7, 101],
        [-28, 100],
        [-6, 73],
        [-19, 110],
        [6, 25],
        [25, -25],
        [-23, 64],
        [-19, -14],
        [-16, 69],
        [-3, 188],
        [-16, 70],
        [9, 71],
        [-18, 257],
        [-35, 82],
        [7, 72],
        [9, 63],
        [-2, 118],
        [8, 36],
        [17, 28],
        [-16, -10],
        [-13, -8],
        [-32, -7],
        [-36, -2],
        [-12, 86],
        [-18, 41],
        [-16, 86],
        [-9, 98],
        [6, 19],
        [-27, 40],
        [-8, 24],
        [-29, 64],
        [-33, 48],
        [8, -34],
        [9, -21],
        [-17, -59],
        [16, -99],
        [-14, -63],
        [-13, -82],
        [-12, -40],
        [-40, -84],
        [-32, -29],
        [-21, -5],
        [-19, 9],
        [-23, 44],
        [-5, 36],
        [-4, 61],
        [-8, 9],
        [-9, -7],
        [14, -79],
        [0, -37],
        [24, -71],
        [-9, -20],
        [-44, -38],
        [-15, 6],
        [-10, -8],
        [-3, -32],
        [-6, -18],
        [-69, -48],
        [-14, -56],
        [-9, -55],
        [-35, -80],
        [-46, -66],
        [-11, 3],
        [-13, 16],
        [2, 71],
        [15, 61],
        [-5, 64],
        [-3, -37],
        [-30, -90],
        [-14, -29],
        [-27, 8],
        [-38, -12],
        [-14, 92],
        [0, 34],
        [-3, 29],
        [5, 30],
        [-2, 25],
        [-9, -47],
        [-3, -40],
        [-14, -35],
        [-38, -39],
        [-1, 49],
        [-2, 44],
        [8, 39],
        [-1, 63],
        [12, 90],
        [-1, 31],
        [-3, 34],
        [-7, -50],
        [-4, -51],
        [-8, -16],
        [-13, -11],
        [-26, -60],
        [-15, -52],
        [-40, -50],
        [-20, 5],
        [-3, 63],
        [16, 225],
        [15, 32],
        [8, 39],
        [12, 131],
        [15, 50],
        [6, 104],
        [6, 18],
        [19, 82],
        [7, 150],
        [-8, 75],
        [-18, 72],
        [-18, 217],
        [-46, 176],
        [-4, 59],
        [-22, 71],
        [21, 5],
        [-43, 62],
        [-6, 26],
        [-9, 146],
        [2, 81],
        [-6, -11],
        [-6, -50],
        [-17, -21],
        [7, -87],
        [-1, -21],
        [-9, -33],
        [-36, 35],
        [-26, 38],
        [-30, 93],
        [-29, 104],
        [10, 16],
        [13, 2],
        [42, -77],
        [27, -16],
        [17, 19],
        [21, 33],
        [10, 64],
        [-11, 24],
        [-20, 13],
        [-13, 17],
        [-20, 43],
        [-2, 23],
        [-6, 28],
        [-20, 20],
        [-16, 25],
        [14, 45],
        [13, 34],
        [-35, -2],
        [-39, 58],
        [-9, 16],
        [-13, 12],
        [-31, 7],
        [-26, -17],
        [12, -82],
        [-2, -27],
        [-17, 4],
        [-36, 124],
        [11, 32],
        [15, 30],
        [-7, 4],
        [-15, -2],
        [14, 111],
        [-9, 16],
        [-4, -34],
        [-8, -34],
        [-30, -78],
        [-15, 15],
        [-10, 19],
        [14, 41],
        [8, 11],
        [5, 22],
        [-11, 43],
        [-18, 32],
        [-14, 53],
        [-7, 1],
        [6, -63],
        [-2, -91],
        [-32, 100],
        [-64, 143],
        [-15, 42],
      ],
      [
        [75645, 63819],
        [-4, 42],
        [-7, 39],
        [-5, 42],
        [-1, 33],
        [-14, 29],
        [-6, 52],
        [-3, 52],
        [8, 38],
        [19, 40],
        [15, 7],
        [11, -18],
        [28, -27],
        [19, -24],
        [8, -33],
        [8, 4],
        [9, 21],
        [-2, 25],
        [-8, 68],
        [-3, 81],
        [0, 191],
        [-3, 22],
      ],
      [
        [77280, 58152],
        [23, -100],
        [-3, -15],
        [-3, -8],
        [-6, 3],
        [-9, 54],
        [-17, 34],
        [-21, -6],
        [17, 44],
        [7, 10],
        [12, -16],
      ],
      [
        [77272, 57565],
        [-13, -34],
        [-5, 2],
        [6, 55],
        [23, 41],
        [19, 4],
        [-2, -26],
        [-14, -31],
        [-14, -11],
      ],
      [
        [77283, 58455],
        [-1, -13],
        [-2, 0],
        [-7, 9],
        [4, 55],
        [11, 44],
        [11, 66],
        [6, 14],
        [2, -35],
        [-7, -74],
        [-5, -40],
        [-12, -26],
      ],
      [
        [77365, 58700],
        [-12, -3],
        [-5, 93],
        [3, 14],
        [16, -46],
        [24, -28],
        [-9, -18],
        [-17, -12],
      ],
      [
        [77375, 58608],
        [-7, -119],
        [-17, 17],
        [-9, 0],
        [-10, 67],
        [0, 18],
        [-6, 44],
        [41, 8],
        [8, -35],
      ],
      [
        [77336, 59100],
        [7, -16],
        [9, 0],
        [-3, -56],
        [-22, -69],
        [-13, -10],
        [-5, 0],
        [5, 101],
        [-9, 57],
        [3, 39],
        [24, -18],
        [4, -28],
      ],
      [
        [77259, 58842],
        [-3, -3],
        [-4, 1],
        [-10, 10],
        [-10, 39],
        [5, 27],
        [4, 7],
        [10, -2],
        [4, -5],
        [2, -10],
        [-3, -22],
        [1, -19],
        [4, -23],
      ],
      [
        [76243, 61028],
        [-18, -56],
        [-7, 84],
        [30, 47],
        [14, 44],
        [15, 31],
        [5, -37],
        [-14, -71],
        [-25, -42],
      ],
      [
        [77103, 61205],
        [-10, -7],
        [-16, 37],
        [-3, 90],
        [13, 20],
        [7, 5],
        [11, -11],
        [3, -14],
        [2, -18],
        [-3, -19],
        [-4, -83],
      ],
      [
        [76024, 62605],
        [-4, -5],
        [-29, 49],
        [-23, 62],
        [36, 12],
        [35, -13],
        [1, -33],
        [-8, -53],
        [-8, -19],
      ],
      [
        [75969, 63301],
        [6, -79],
        [-19, 30],
        [-7, 41],
        [-2, 42],
        [22, -34],
      ],
      [
        [76031, 63109],
        [32, -48],
        [13, 3],
        [19, -30],
        [1, -12],
        [-4, -24],
        [-9, -19],
        [-24, -20],
        [-16, 16],
        [-7, 52],
        [-19, 25],
        [-6, 20],
        [13, 29],
        [7, 8],
      ],
      [
        [77372, 58157],
        [-6, -1],
        [-6, 2],
        [-6, 9],
        [14, 62],
        [4, -72],
      ],
      [
        [77242, 58578],
        [3, -32],
        [-18, 34],
        [-3, 95],
        [14, -60],
        [6, -14],
        [-2, -23],
      ],
      [
        [77240, 58980],
        [-2, -21],
        [-16, -43],
        [-14, 25],
        [-4, 13],
        [15, 28],
        [15, -4],
        [4, 6],
        [2, -4],
      ],
      [
        [77309, 59388],
        [-2, -95],
        [-14, 46],
        [-2, 52],
        [1, 49],
        [3, 8],
        [1, -8],
        [8, -22],
        [5, -30],
      ],
      [
        [76334, 60955],
        [-6, -15],
        [-11, 11],
        [-3, 6],
        [26, 64],
        [3, -24],
        [-9, -42],
      ],
      [
        [75835, 63320],
        [4, -55],
        [-13, 22],
        [-18, 76],
        [1, 50],
        [12, -23],
        [14, -70],
      ],
      [
        [50663, 58696],
        [-7, -33],
        [-21, -86],
        [-16, -36],
        [-69, -121],
        [-35, 14],
        [-71, -24],
        [-11, 28],
        [-16, 3],
        [-21, -4],
        [-8, -11],
        [-2, -12],
        [-7, -17],
        [-14, -48],
        [-10, -12],
        [-13, -8],
        [-15, 1],
        [-9, -6],
        [0, -24],
        [-3, -21],
        [-11, -10],
        [-4, -23],
        [1, -23],
        [-6, -10],
        [-14, 5],
        [-8, 7],
        [-7, -30],
        [-10, -20],
        [-6, 0],
      ],
      [
        [57940, 77040],
        [-7, -139],
        [-27, -65],
        [-40, 22],
        [-52, -18],
        [-27, -73],
        [-16, -22],
        [-14, -26],
        [-9, -95],
        [-2, -156],
        [-19, -19],
        [-18, -6],
        [-75, -137],
        [43, -39],
        [19, -29],
        [32, -82],
        [44, -93],
        [9, -45],
      ],
      [
        [81951, 54665],
        [32, 0],
      ],
      [
        [81684, 54488],
        [31, -1],
        [34, 10],
        [35, 30],
        [33, 37],
        [28, 43],
        [27, 47],
        [27, 38],
        [43, 44],
        [15, -4],
        [0, -31],
        [-6, -36],
      ],
      [
        [35652, 54182],
        [26, 99],
        [3, 45],
        [24, 2],
        [37, -52],
        [30, -75],
        [40, -243],
        [6, -225],
        [17, -118],
        [46, -245],
        [3, -45],
        [7, -55],
        [15, -58],
        [16, -96],
        [1, -18],
        [-11, -26],
        [16, -2],
        [14, -15],
        [9, -61],
        [11, -41],
        [21, -56],
        [43, -19],
        [33, -7],
        [37, -31],
        [27, -41],
        [21, -138],
        [-7, -87],
        [2, -61],
        [-11, -24],
        [-30, -40],
        [-7, -22],
        [-62, -103],
        [-14, -49],
        [-33, -65],
        [-33, -125],
        [-48, -114],
        [-17, -29],
        [-26, -7],
        [-16, -17],
        [-37, -93],
        [-51, -31],
        [-4, -54],
        [-29, -124],
        [-26, -67],
        [-16, -23],
        [-41, -122],
        [-5, -54],
        [0, -94],
        [-28, -57],
        [-28, -37],
        [-4, -80],
        [-12, -27],
        [-11, -18],
        [-58, 21],
        [-91, -88],
        [-30, -21],
        [98, -5],
        [32, -46],
        [69, 31],
        [83, 110],
        [32, 24],
        [65, 75],
        [27, 50],
        [48, 60],
        [10, 26],
        [27, 29],
        [15, -36],
        [1, -23],
        [-22, -44],
        [6, -28],
        [14, -36],
        [5, -49],
        [2, -37],
        [9, -66],
        [30, -88],
        [1, -30],
        [-4, -38],
        [14, -32],
        [15, -19],
        [51, -95],
        [39, 53],
        [25, 15],
        [15, 23],
        [33, 14],
        [27, -22],
        [51, -32],
        [37, 34],
        [75, 78],
        [-23, -138],
        [-17, -127],
        [-13, -51],
        [-13, -138],
        [-13, -37],
        [-10, -42],
        [17, 15],
        [14, 20],
        [18, 53],
        [14, 92],
        [55, 247],
        [16, 22],
        [45, 28],
        [78, 197],
        [31, -1],
        [19, -45],
        [19, -27],
        [4, 54],
        [27, 22],
        [-28, 27],
        [-5, 24],
        [-2, 40],
        [19, 55],
        [-12, 48],
        [40, 61],
        [-3, 45],
        [14, 38],
        [18, 39],
        [21, 19],
        [3, 33],
        [13, 14],
        [10, 4],
        [20, -37],
        [22, 44],
        [21, 17],
        [9, -7],
        [12, -20],
        [12, -8],
        [10, 4],
        [26, 28],
        [24, -46],
        [15, -10],
        [-4, 26],
        [-8, 23],
        [6, 20],
        [11, 12],
        [36, -11],
        [19, -20],
        [21, -38],
        [28, -3],
        [23, 4],
        [14, -21],
        [23, 1],
        [11, -33],
        [35, -46],
        [7, -31],
        [28, -16],
        [27, -19],
        [28, -5],
        [28, 5],
        [1, -40],
        [21, -11],
        [26, 9],
        [21, -48],
        [53, -37],
        [38, -56],
        [24, 10],
        [27, -15],
        [30, -121],
        [6, -87],
        [13, 12],
        [12, 39],
        [16, 71],
        [29, 23],
        [14, -27],
        [30, -44],
        [25, -47],
        [11, -31],
        [19, -5],
        [-16, -38],
        [16, 4],
        [19, 27],
        [17, -55],
        [13, -60],
        [2, -62],
        [-12, -35],
        [-10, -22],
        [-12, -44],
        [-13, -7],
        [-14, -14],
        [16, -32],
        [10, -30],
        [24, 82],
        [16, 23],
        [23, 13],
        [13, -59],
        [2, -55],
        [-38, -23],
        [0, -44],
        [-12, -24],
        [-7, -29],
        [-6, -59],
        [-8, -50],
        [-23, -218],
        [0, -36],
        [27, 38],
        [52, 112],
        [16, 118],
        [20, 117],
        [22, 37],
        [14, 0],
        [20, -13],
        [1, -38],
        [-3, -22],
        [-22, -58],
        [-9, -32],
        [9, -32],
        [50, 97],
        [22, 33],
        [19, -6],
        [38, 44],
        [76, 9],
        [5, 51],
        [16, 22],
        [41, -6],
        [82, -45],
        [29, -37],
        [43, -35],
        [23, -41],
        [96, -76],
        [69, -8],
        [34, 35],
        [43, -36],
        [23, -41],
        [44, -21],
        [45, -12],
        [35, 29],
        [88, 10],
        [112, 42],
        [66, -10],
        [75, -28],
        [54, -71],
        [45, -41],
        [27, -40],
        [44, -41],
        [94, -111],
        [33, -64],
        [58, -88],
        [59, -37],
        [32, -91],
        [25, -42],
        [61, -154],
        [71, -108],
        [47, -109],
        [90, -69],
        [35, -115],
        [61, -14],
        [26, -17],
        [32, -49],
        [44, -27],
        [56, 8],
        [63, -6],
        [50, 23],
        [120, -43],
        [19, -21],
        [24, -49],
        [44, -182],
        [26, -202],
        [13, -154],
        [30, -120],
        [16, -226],
        [14, -71],
        [1, -54],
        [12, -13],
        [7, -152],
        [-3, -61],
        [-11, -80],
        [-1, -35],
        [2, -23],
        [-5, -33],
        [-2, -32],
        [12, -72],
        [0, -57],
        [-15, -70],
        [-21, -181],
        [-53, -302],
        [-51, -173],
        [-71, -178],
        [-47, -93],
        [-18, -10],
        [-17, 19],
        [12, -50],
        [-11, -43],
        [-47, -131],
        [-46, -86],
        [-49, -150],
        [-4, -3],
        [-62, -58],
        [-37, -47],
        [-47, -85],
        [-43, -135],
        [-9, -18],
        [-16, 10],
        [0, -69],
        [-37, -108],
        [-11, -16],
        [0, 30],
        [7, 24],
        [3, 25],
        [-1, 29],
        [-10, -21],
        [-22, -82],
        [7, -59],
        [-16, -90],
        [-60, -257],
        [-75, -217],
        [-17, -66],
        [-62, -146],
        [-45, -70],
        [-12, -1],
        [-15, 6],
        [-7, 112],
        [-36, 68],
        [-10, 12],
        [-15, -72],
        [-12, -20],
        [-18, -4],
        [19, -31],
        [6, -36],
        [-19, -73],
        [-1, -66],
        [-34, -72],
        [-20, -53],
        [-10, -67],
        [-7, -62],
        [16, 17],
        [7, -13],
        [5, -19],
        [-3, -29],
        [-11, -54],
        [2, -134],
        [-4, -30],
        [11, -33],
        [12, 56],
        [6, -16],
        [-32, -359],
        [13, -162],
        [4, -183],
        [15, -179],
        [16, -160],
        [1, -13],
        [-22, -185],
        [-28, -183],
        [-17, -150],
        [-11, -161],
        [-11, -78],
        [-4, -79],
        [13, -188],
        [4, -35],
        [-34, -84],
        [-37, -41],
        [-21, -40],
        [-45, -151],
        [-25, -224],
        [-1, -118],
        [12, -249],
        [-9, -102],
        [-14, -67],
        [-17, -45],
        [-44, -53],
        [-39, -131],
        [-17, -137],
        [-27, -50],
        [-5, -76],
        [-21, -83],
        [-56, -124],
        [-36, -36],
        [-18, -34],
        [-11, -72],
        [-35, -119],
        [-25, -154],
        [6, -53],
        [1, -8],
        [9, -178],
        [-3, -46],
        [-34, -49],
        [-128, -91],
        [-34, -39],
        [-77, -156],
        [-4, -36],
        [3, -53],
        [12, -30],
        [-13, -33],
        [-15, -59],
        [-22, 4],
        [-127, 0],
        [-69, -19],
        [-36, 4],
        [-16, 14],
        [-18, 23],
        [-6, 30],
        [10, 46],
        [-6, 27],
        [-19, -1],
        [-20, -13],
        [-4, -27],
        [1, -20],
        [8, -28],
        [4, -35],
        [-8, -30],
        [-40, -4],
        [-46, -28],
        [-56, -12],
        [-45, -20],
        [-21, 26],
        [21, 13],
        [29, -7],
        [32, 21],
        [-7, 25],
        [-45, 32],
        [-51, -19],
        [-28, -39],
        [-61, 4],
        [-75, -29],
        [-12, -30],
        [2, -57],
        [15, -13],
        [14, -26],
        [-14, -24],
        [-13, -11],
        [-79, -27],
        [-73, -112],
        [-31, -14],
        [-27, -49],
        [-3, -42],
        [-8, -25],
        [-18, -2],
        [-38, 23],
        [-50, 1],
        [-35, -18],
        [-183, -182],
        [-66, -72],
        [-75, -148],
        [-126, -166],
        [-67, -99],
        [-12, -26],
        [-11, -1],
        [-21, -21],
        [9, -17],
        [14, -2],
        [-6, -57],
        [-26, -40],
        [-50, -103],
        [-11, 7],
        [16, 54],
        [-25, 2],
        [-36, 20],
        [-15, -22],
        [9, -54],
        [-14, -22],
        [-24, -3],
        [-23, 6],
        [-24, 39],
        [11, -70],
        [51, -18],
        [22, -16],
        [8, -27],
        [-40, -126],
        [-34, -17],
        [-3, -17],
        [18, 0],
        [10, -35],
        [-12, -141],
        [-16, -26],
        [-10, -1],
        [-10, -24],
        [14, -46],
        [13, -33],
        [-2, -65],
        [-5, -54],
        [0, -52],
        [17, -101],
        [6, -104],
        [7, -37],
        [4, -42],
        [-11, -39],
        [6, -63],
        [-19, -107],
        [10, -154],
        [-4, -144],
        [-8, -76],
        [-12, -59],
        [-29, -76],
        [-1, -77],
        [-62, -71],
        [-69, -99],
        [-63, -118],
        [-69, -166],
        [-80, -252],
        [-73, -360],
        [-89, -272],
        [-36, -98],
        [-48, -109],
        [-64, -128],
        [-86, -128],
        [-94, -114],
        [-34, -51],
        [-33, -73],
        [-8, 30],
        [7, 49],
        [-4, 37],
        [-1, 48],
        [19, 9],
        [28, -30],
        [14, 20],
        [11, 21],
        [34, 12],
        [65, 125],
        [48, 47],
        [28, 79],
        [4, 42],
        [-1, 85],
        [16, 22],
        [35, -8],
        [7, 24],
        [-3, 27],
        [6, 59],
        [48, 52],
        [22, 63],
        [-7, 160],
        [8, 7],
        [20, -25],
        [9, 11],
        [10, 70],
        [-5, 36],
        [-23, 10],
        [-79, -79],
        [-26, 3],
        [-4, 62],
        [-39, 29],
        [-15, 52],
        [-4, 35],
        [-14, 14],
        [1, -61],
        [4, -59],
        [34, -69],
        [-8, -28],
        [-17, -32],
        [-11, -71],
        [1, -92],
        [-9, 28],
        [-12, 16],
        [-5, -99],
        [-23, -38],
        [-7, -38],
        [6, -43],
        [-12, -30],
        [-58, -80],
        [-58, -55],
        [-13, -26],
        [-6, -61],
        [-9, -63],
        [-26, -55],
        [-21, -110],
        [1, -47],
        [7, -69],
        [11, -46],
        [-18, -31],
        [-23, -59],
        [-19, -67],
        [-46, -251],
        [-40, -151],
        [-31, -74],
        [-44, -78],
        [-125, -196],
      ],
      [
        [33997, 34453],
        [13, 28],
        [44, 61],
        [24, 54],
        [5, 48],
        [21, 43],
        [38, 38],
        [41, 70],
        [47, 102],
        [28, 75],
        [9, 49],
        [19, 37],
        [26, 26],
        [23, 55],
        [20, 84],
        [26, 66],
        [35, 49],
        [19, 41],
        [4, 32],
        [9, 21],
        [15, 9],
        [8, 17],
        [-1, 25],
        [5, 17],
        [8, 10],
        [15, -4],
        [21, -15],
        [12, 3],
        [4, 21],
        [-5, 24],
        [-15, 27],
        [5, 30],
        [40, 48],
        [29, 18],
        [19, 30],
        [18, 47],
        [28, 33],
        [40, 18],
        [10, 18],
        [-1, 23],
        [8, 16],
        [23, 12],
        [13, 22],
        [2, 33],
        [7, 31],
        [13, 28],
        [15, 10],
        [16, -7],
        [15, 10],
        [14, 29],
        [17, 13],
        [19, -2],
        [10, 6],
        [34, 14],
        [18, 24],
        [16, 53],
        [13, 20],
        [12, -12],
        [21, 18],
        [29, 48],
        [5, 1],
        [22, 22],
        [22, 82],
        [11, 55],
        [-3, 45],
        [-7, 32],
        [3, 48],
        [7, 128],
        [2, 53],
        [12, 37],
        [-1, 36],
        [-21, 82],
        [-21, 71],
        [-12, 121],
        [-7, 46],
        [-18, 13],
        [-16, 40],
        [-20, 3],
        [-9, 16],
        [-10, 12],
        [-15, -3],
        [-12, -24],
        [-23, -1],
        [-14, -9],
        [-17, -21],
        [-16, 9],
        [-10, 19],
        [-21, 0],
      ],
      [
        [33844, 40227],
        [19, 8],
        [7, 23],
        [13, 32],
        [17, 8],
        [20, 12],
        [7, 24],
        [-47, 84],
        [-28, 51],
        [17, 69],
        [27, 116],
        [27, 112],
        [21, 85],
        [5, 16],
        [18, 6],
        [-3, 44],
        [-1, 29],
        [-14, 1],
        [16, 105],
        [24, 148],
        [18, 113],
        [6, 19],
        [13, 5],
        [3, 13],
        [-16, 19],
        [-10, 35],
        [-20, 100],
        [-33, 159],
        [-3, 57],
        [-12, 35],
        [-20, -12],
        [-24, 12],
        [-60, 86],
        [-39, 47],
        [-13, 27],
        [-6, 89],
        [-12, 97],
        [-5, 121],
        [2, 30],
        [33, 91],
        [0, 47],
        [3, 40],
        [-1, 32],
        [-9, 1],
        [-13, -14],
        [-20, -11],
        [-12, -1],
        [-116, 9],
        [-133, 10],
        [-110, 8],
        [-96, 7],
        [-3, 79],
        [-5, 133],
        [-4, 94],
        [-6, 149],
        [-39, 93],
        [-41, 101],
        [-15, 25],
        [50, 4],
        [36, 2],
        [-7, 271],
        [-11, 28],
        [-10, 87],
        [-6, 49],
        [-18, 41],
        [-4, 45],
        [3, 30],
        [10, 19],
        [7, 46],
        [-5, 47],
        [-11, 43],
        [-13, 42],
        [-24, 26],
        [-36, 46],
        [-53, 60],
        [-45, 41],
        [-15, -5],
        [-79, -16],
        [-27, -9],
        [-18, 10],
        [-59, -1],
        [-24, 32],
        [-19, 37],
        [-42, 95],
        [-6, 47],
        [-16, 15],
        [-25, -6],
        [-25, 7],
        [-48, 39],
        [-44, 40],
        [-22, -1],
        [-20, 25],
        [-34, 61],
        [-16, 24],
        [-7, 32],
        [-7, 47],
        [-14, 10],
        [-18, -9],
        [-19, -24],
        [-27, 16],
        [-33, 44],
        [-21, 33],
        [-12, 16],
        [-29, 24],
        [-27, 5],
        [-42, -35],
        [-34, 14],
        [-54, 13],
        [-46, 25],
        [-17, 65],
        [-9, 44],
        [-27, 27],
        [-22, 33],
        [-26, 50],
        [-13, 17],
        [-23, 14],
        [-22, 17],
        [-2, 32],
        [-8, 42],
        [-2, 10],
        [-15, 51],
        [-7, 4],
        [-8, -10],
        [-5, -8],
        [-6, 10],
        [-2, 22],
        [4, 37],
        [-8, 38],
        [-21, 40],
        [-11, 41],
        [-1, 43],
        [-5, 29],
        [-8, 14],
        [-5, 25],
        [-1, 36],
        [6, 43],
        [13, 49],
        [-3, 76],
        [-19, 103],
        [-10, 74],
        [-2, 45],
        [3, 34],
        [11, 32],
        [23, 81],
        [4, 61],
        [-7, 69],
        [-1, 52],
        [5, 37],
        [-8, 47],
        [-16, 45],
        [-11, 1],
        [-16, -12],
        [-18, -38],
        [-22, -7],
        [-19, 24],
        [-61, -10],
        [-94, -24],
        [-38, -24],
        [-22, -10],
        [-26, -8],
        [-43, -44],
        [-106, -169],
        [-22, -24],
        [-25, -3],
        [-15, -24],
        [-23, -18],
        [-46, -67],
        [-24, -54],
        [-15, -48],
        [-18, -2],
        [-13, 13],
        [-44, -6],
        [-22, -17],
        [-24, -47],
        [-30, -85],
        [-13, -24],
        [-24, -26],
        [-28, -20],
        [-34, -32],
        [-16, -2],
        [-13, -5],
        [-12, 14],
        [-4, 31],
        [-18, 19],
        [-42, 10],
        [-63, 22],
        [-65, 4],
        [-33, -2],
      ],
      [
        [36214, 51711],
        [26, -3],
        [37, 11],
        [24, 27],
        [28, 5],
        [27, -3],
        [92, -30],
        [55, -9],
        [20, -9],
        [20, -14],
        [14, -15],
        [4, -32],
        [-14, -51],
        [-10, -53],
        [-9, -75],
        [-7, -16],
        [-12, 4],
        [7, -67],
        [-2, -27],
        [-6, -26],
        [-15, -54],
        [-22, -69],
        [-7, -14],
        [-17, -24],
        [-14, -31],
        [3, -29],
        [7, -29],
        [-8, -36],
        [-27, -53],
        [-16, -13],
        [-14, -6],
        [-14, 6],
        [-23, 53],
        [-3, -42],
        [-6, -42],
        [-8, -24],
        [-31, 3],
        [-17, 23],
        [-28, 25],
        [-5, -69],
        [-18, -47],
        [-17, -15],
        [-27, -10],
        [-16, -20],
        [-30, 16],
        [-27, 31],
        [-16, 3],
        [-12, -25],
        [-63, -5],
        [-29, -26],
        [-18, 8],
        [-26, 52],
        [-5, 34],
        [-15, 70],
        [-14, 84],
        [-10, 75],
        [8, 66],
        [17, -3],
        [20, -9],
        [4, 4],
        [1, 21],
        [-4, 18],
        [-32, -3],
        [-21, 39],
        [-3, 60],
        [4, 124],
        [2, 26],
        [15, 36],
        [4, 31],
        [-3, 34],
        [6, 61],
        [13, 52],
        [52, 67],
        [59, 24],
        [172, -65],
      ],
      [
        [37741, 38512],
        [9, -16],
        [-16, 2],
        [-18, -14],
        [-28, -12],
        [-11, 23],
        [24, 32],
        [9, 24],
        [6, -5],
        [8, -17],
        [17, -17],
      ],
      [
        [36504, 36634],
        [-5, -7],
        [-18, 72],
        [35, 69],
        [12, -28],
        [-9, -55],
        [-10, -38],
        [-5, -13],
      ],
      [
        [37427, 38082],
        [0, -30],
        [-11, 15],
        [-31, -12],
        [-11, 23],
        [42, 97],
        [8, -14],
        [6, -18],
        [5, -25],
        [-5, -16],
        [-3, -20],
      ],
      [
        [37639, 50149],
        [-28, -56],
        [9, 65],
        [-4, 45],
        [3, 35],
        [19, 34],
        [6, 5],
        [-2, -41],
        [1, -13],
        [-4, -74],
      ],
      [
        [39237, 44298],
        [-10, -12],
        [-2, 36],
        [29, 47],
        [4, 54],
        [15, -25],
        [4, -28],
        [0, -12],
        [-40, -60],
      ],
      [
        [39193, 44081],
        [-9, -33],
        [-11, 5],
        [-5, 22],
        [-8, 22],
        [4, 18],
        [8, 10],
        [20, -2],
        [1, -42],
      ],
      [
        [37532, 51083],
        [-18, -27],
        [-5, -15],
        [-15, 11],
        [3, 16],
        [4, -2],
        [5, 47],
        [25, -6],
        [1, -24],
      ],
      [
        [36183, 51997],
        [12, -30],
        [-40, -120],
        [-21, -18],
        [-24, -3],
        [-31, 36],
        [-48, -3],
        [-15, 9],
        [-1, 52],
        [20, 56],
        [40, -3],
        [69, 45],
        [39, -21],
      ],
      [
        [36028, 52959],
        [-28, -26],
        [-16, 10],
        [-15, 69],
        [5, 57],
        [20, 19],
        [16, -4],
        [6, -8],
        [14, -93],
        [-2, -24],
      ],
      [
        [35929, 51767],
        [-76, -113],
        [-25, 37],
        [-6, 22],
        [5, 21],
        [-1, 9],
        [8, 39],
        [43, 32],
        [21, 5],
        [27, -10],
        [5, -27],
        [-1, -15],
      ],
      [
        [36265, 51778],
        [-73, -18],
        [-34, 29],
        [8, 24],
        [25, 38],
        [30, 28],
        [28, 12],
        [28, -15],
        [8, -33],
        [-2, -32],
        [-18, -33],
      ],
      [
        [35992, 51923],
        [-5, -85],
        [-50, 36],
        [4, 87],
        [24, 24],
        [20, 46],
        [7, 56],
        [1, 77],
        [8, 14],
        [6, 5],
        [6, -5],
        [3, -116],
        [2, -70],
        [-26, -69],
      ],
      [
        [36068, 52069],
        [-30, -19],
        [-5, 18],
        [0, 72],
        [8, 40],
        [38, 11],
        [4, 12],
        [11, 7],
        [7, -25],
        [-1, -41],
        [-32, -75],
      ],
      [
        [35602, 51017],
        [-30, -11],
        [38, 144],
        [34, 67],
        [1, 133],
        [36, 118],
        [34, 49],
        [47, 14],
        [26, -72],
        [-32, -205],
        [-9, -1],
        [-43, -108],
        [-48, -75],
        [-54, -53],
      ],
      [
        [36531, 35848],
        [-19, -26],
        [4, 137],
        [10, 45],
        [11, 34],
        [14, 21],
        [10, -29],
        [-8, -67],
        [-25, -81],
        [3, -34],
      ],
      [
        [54907, 76553],
        [-23, 24],
      ],
      [
        [32597, 39035],
        [-4, 9],
        [-22, 34],
        [-20, 63],
        [-5, 29],
        [-3, 1],
        [-118, -1],
        [-113, -3],
        [-11, -13],
        [-17, 0],
        [-12, 13],
        [-11, -1],
        [-17, -13],
        [-15, -25],
        [-44, -169],
        [-21, -72],
        [-16, -65],
        [-12, -110],
        [-4, -19],
        [-14, 38],
        [-20, 102],
        [-9, 57],
        [-13, 66],
        [-22, 82],
        [-27, 25],
        [-16, 8],
        [-23, 16],
        [-42, 20],
        [-18, 4],
        [-119, 2],
        [-9, 2],
        [-46, -9],
        [-24, 6],
        [-25, 46],
        [-55, 81],
        [-11, 26],
        [-21, 17],
        [-13, 2],
        [-7, -16],
        [-10, -68],
        [-11, -61],
        [-12, -35],
        [-39, -25],
        [-37, -27],
        [-20, -7],
        [-11, -30],
        [-5, -43],
        [-9, -38],
        [-53, -58],
        [-12, -24],
        [-6, -57],
        [-29, -71],
        [-10, -27],
      ],
      [
        [50751, 55512],
        [-116, -24],
        [-130, -39],
        [-55, -25],
      ],
      [
        [25473, 62483],
        [-15, -65],
        [15, -9],
        [14, 7],
        [32, -3],
        [13, -71],
        [-4, -61],
        [-30, -158],
        [-4, -55],
        [-14, -81],
        [19, -54],
        [-18, -72],
        [-6, -46],
        [-1, -69],
        [9, -132],
        [-15, -190],
        [-25, -83],
        [-16, -32],
        [-28, -82],
        [-37, -25],
        [-51, -133],
        [-9, -35],
        [5, -38],
      ],
      [
        [25569, 62168],
        [-13, -11],
        [11, 34],
        [1, 21],
        [16, 89],
        [10, -1],
        [3, -8],
        [-28, -124],
      ],
      [
        [25596, 61879],
        [-21, -81],
        [-2, 23],
        [9, 60],
        [12, 21],
        [8, 22],
        [2, 26],
        [10, -13],
        [-3, -25],
        [-15, -33],
      ],
      [
        [50701, 81276],
        [121, 97],
        [73, 50],
        [35, 15],
      ],
      [
        [33474, 59378],
        [-8, -11],
        [-25, 23],
        [-9, 28],
        [-1, 88],
        [15, 8],
        [29, -70],
        [17, -25],
        [-18, -41],
      ],
      [
        [75645, 63819],
        [-5, 0],
        [-16, 53],
        [-15, 58],
        [-39, 110],
        [-12, 197],
        [-1, 97],
        [-26, 114],
        [-18, 158],
        [-7, 41],
        [9, 51],
        [2, 19],
        [-5, -4],
        [-14, -26],
        [-17, 63],
        [-11, 56],
        [-46, 117],
        [-13, 52],
        [-1, 50],
        [-19, -50],
        [-27, -36],
        [-27, -54],
        [-18, -16],
        [-57, -10],
        [-33, 72],
        [-47, 175],
        [-7, 40],
        [6, 103],
        [-11, 97],
        [0, 52],
        [-3, 34],
        [-8, -8],
        [-4, -23],
        [2, -36],
        [-3, -31],
        [-41, 6],
        [-39, 14],
        [34, -51],
        [36, -12],
        [19, -46],
        [3, -36],
        [-1, -40],
        [-19, -29],
        [-17, -17],
        [3, -39],
        [21, -47],
        [-26, -14],
        [-6, -31],
        [-1, -43],
        [13, -39],
        [5, -29],
        [-3, -26],
        [12, -29],
        [18, -60],
        [5, -42],
        [-7, -60],
        [-10, -23],
        [-16, -23],
        [-39, -75],
        [-19, -86],
        [-16, -40],
        [-20, -7],
        [-7, 18],
        [-17, 22],
        [0, 42],
        [5, 33],
        [33, 81],
        [-18, -11],
        [-21, -23],
        [-31, -43],
        [-11, 53],
        [-6, 50],
        [0, 61],
        [25, 91],
        [-29, -45],
        [-8, -57],
        [4, -67],
        [-4, -47],
        [-11, -62],
        [-15, -37],
        [-25, -24],
        [-11, -37],
        [-17, -27],
        [0, 54],
        [-5, 71],
        [-18, 168],
        [-4, -36],
        [9, -104],
        [0, -68],
        [-14, -54],
        [-27, -57],
        [-21, -8],
        [-12, 8],
        [-19, 36],
        [-20, 51],
        [-4, 82],
        [-8, 45],
      ],
      [
        [75319, 64616],
        [-30, -40],
        [10, 239],
        [22, -89],
        [6, -48],
        [-8, -62],
      ],
      [
        [75432, 64736],
        [-13, -17],
        [-12, 14],
        [-16, 56],
        [8, 71],
        [5, 11],
        [7, -23],
        [11, -50],
        [7, -38],
        [3, -24],
      ],
      [
        [75215, 64567],
        [-48, -20],
        [-25, 6],
        [46, 151],
        [-1, 68],
        [-7, 55],
        [-24, 44],
        [-1, 32],
        [-11, 43],
        [-5, 51],
        [26, 16],
        [21, -29],
        [3, -16],
        [4, -42],
        [11, -43],
        [36, -88],
        [0, -55],
        [-10, -132],
        [-15, -41],
      ],
      [
        [75520, 64419],
        [-10, -47],
        [-5, 34],
        [4, 43],
        [4, 24],
        [3, 0],
        [6, -25],
        [-2, -29],
      ],
      [
        [75541, 64232],
        [-17, -3],
        [-8, 17],
        [4, 24],
        [-5, 78],
        [14, 8],
        [7, -1],
        [5, -22],
        [3, -42],
        [-3, -59],
      ],
      [
        [75178, 65070],
        [4, -25],
        [-15, 15],
        [-12, 17],
        [-7, 23],
        [12, 12],
        [18, -42],
      ],
      [
        [64057, 66752],
        [-9, -44],
        [-9, 16],
        [-21, 76],
        [6, 53],
        [-10, 76],
        [5, 22],
        [26, 11],
        [6, -4],
        [-8, -24],
        [15, -43],
        [2, -70],
        [-3, -69],
      ],
      [
        [28428, 65811],
        [1, -13],
        [-28, -36],
        [20, -26],
        [19, 56],
        [15, -46],
        [8, -86],
        [-1, -15],
        [1, -12],
        [3, -17],
        [1, -24],
        [-16, -75],
        [-54, 8],
        [-2, 63],
        [-8, 12],
        [-13, 91],
        [-17, 29],
        [-24, 74],
        [14, 19],
        [18, -6],
        [10, 9],
        [25, 7],
        [16, 10],
        [12, -22],
      ],
      [
        [28548, 66764],
        [-6, -5],
        [-24, 58],
        [-19, 17],
        [30, 41],
        [13, 35],
        [0, 76],
        [7, 42],
        [-2, 36],
        [7, 37],
        [-9, 42],
        [-26, 33],
        [-50, 131],
        [-79, 32],
        [-41, 1],
        [22, 21],
        [21, -2],
        [32, -13],
        [39, -6],
        [23, -39],
        [22, -51],
        [21, -20],
        [8, -14],
        [-1, -14],
        [3, -14],
        [27, -24],
        [26, -39],
        [8, -113],
        [-36, -54],
        [-6, -164],
        [-10, -30],
      ],
      [
        [29714, 64050],
        [-8, -42],
        [-30, -81],
        [-65, -20],
        [-73, -4],
        [-5, 22],
        [-2, 20],
        [5, 30],
        [0, 12],
        [-3, 12],
        [26, 13],
        [18, 37],
        [27, 7],
        [34, -27],
        [19, -1],
        [27, 29],
        [22, 63],
        [13, -8],
        [-5, -62],
      ],
      [
        [28404, 66075],
        [0, -70],
        [3, -52],
        [-3, -19],
        [-30, -34],
        [-8, -20],
        [-28, -20],
        [-17, -27],
        [-9, 45],
        [-17, 27],
        [-2, 47],
        [-13, -16],
        [-19, 10],
        [-30, 35],
        [-19, 48],
        [27, 8],
        [5, -30],
        [22, 37],
        [-5, 19],
        [-4, 3],
        [-7, 36],
        [32, 94],
        [7, 60],
        [-15, 98],
        [14, 6],
        [36, -34],
        [16, -34],
        [0, -46],
        [16, -35],
        [21, -86],
        [27, -50],
      ],
      [
        [28196, 67240],
        [34, -18],
        [18, 2],
        [11, 12],
        [49, -5],
        [41, 17],
        [6, -30],
        [-1, -16],
        [-86, -15],
        [-78, -45],
        [-43, -31],
        [-21, -3],
        [-15, 16],
        [-52, 93],
        [14, -10],
        [38, -52],
        [24, 10],
        [22, 34],
        [4, 26],
        [-4, 13],
        [10, 41],
        [29, -39],
      ],
      [
        [28514, 66252],
        [-31, -12],
        [-23, 11],
        [-5, 10],
        [9, 16],
        [21, 13],
        [34, 1],
        [15, -15],
        [2, -7],
        [-22, -17],
      ],
      [
        [29428, 64932],
        [6, -10],
        [-17, -23],
        [-40, 28],
        [-9, -2],
        [-8, 31],
        [-3, 22],
        [2, 21],
        [24, -16],
        [12, -30],
        [33, -21],
      ],
      [
        [29325, 65707],
        [-22, -63],
        [-12, 6],
        [7, 78],
        [15, 12],
        [6, 0],
        [6, -33],
      ],
      [
        [29387, 64639],
        [-20, -18],
        [5, 30],
        [37, 51],
        [21, 44],
        [11, 16],
        [5, 12],
        [16, 17],
        [8, 28],
        [-2, 24],
        [-17, 38],
        [0, 27],
        [6, 20],
        [29, 9],
        [-8, -29],
        [12, -82],
        [-39, -103],
        [-33, -31],
        [-31, -53],
      ],
      [
        [28708, 66524],
        [46, -65],
        [39, -24],
        [42, -82],
        [18, -29],
        [4, -26],
        [-7, -120],
        [-10, -73],
        [2, -63],
        [-10, 18],
        [-10, 42],
        [-17, 24],
        [-5, 13],
        [29, 2],
        [3, 66],
        [14, 51],
        [-2, 54],
        [-34, 59],
        [-24, 53],
        [-36, 16],
        [-34, 52],
        [-20, 7],
        [-24, -10],
        [9, 31],
        [6, 41],
        [4, 8],
        [17, -45],
      ],
      [
        [28982, 65351],
        [-12, -4],
        [-21, 16],
        [-48, 70],
        [-23, 6],
        [8, 39],
        [17, -14],
        [39, -60],
        [15, -30],
        [25, -23],
      ],
      [
        [29211, 65031],
        [-2, -15],
        [-35, 115],
        [-44, 28],
        [-26, 28],
        [6, 15],
        [17, 7],
        [3, 37],
        [-7, 39],
        [-24, 80],
        [-13, 54],
        [-6, 12],
        [-1, 45],
        [27, -70],
        [12, -62],
        [18, -61],
        [13, -105],
        [35, -36],
        [25, -51],
        [2, -60],
      ],
      [
        [29081, 65783],
        [2, -30],
        [-19, 6],
        [-28, -11],
        [-9, 0],
        [6, 20],
        [19, 27],
        [1, 26],
        [-24, 37],
        [-27, 92],
        [-13, 22],
        [-6, 35],
        [-23, 38],
        [5, 20],
        [4, 4],
        [16, -9],
        [35, -134],
        [2, -12],
        [59, -131],
      ],
      [
        [29711, 64763],
        [17, -9],
        [9, 1],
        [32, -17],
        [19, -24],
        [4, -10],
        [-10, -21],
        [-29, 40],
        [-26, 5],
        [-36, -1],
        [-14, 8],
        [10, 43],
        [24, -15],
      ],
      [
        [29745, 64231],
        [-37, -28],
        [-3, 33],
        [18, 27],
        [22, -32],
      ],
      [
        [62435, 74713],
        [27, 9],
        [46, 27],
        [13, -13],
        [13, -27],
        [7, -23],
        [1, -42],
        [5, -7],
        [23, 14],
        [9, -17],
        [18, -21],
        [29, -20],
        [43, 32],
        [21, 8],
        [18, -1],
        [9, -10],
        [4, -33],
        [-4, -41],
        [-5, -22],
        [9, -16],
        [35, -40],
        [15, -21],
        [-7, -38],
        [26, -93],
        [9, -36],
        [10, -44],
      ],
      [
        [62913, 74254],
        [-1, 53],
        [1, 41],
        [-4, 24],
        [-20, 33],
        [-1, 14],
        [6, 8],
        [16, -5],
        [20, 2],
        [10, 13],
        [-22, 43],
        [-19, 29],
        [-17, 19],
        [-3, 12],
        [0, 8],
        [3, 10],
        [28, 24],
        [3, 21],
        [-2, 25],
        [-44, 36],
        [-34, -14],
        [-30, 41],
        [-19, 31],
        [-24, 33],
        [-21, 19],
        [-20, 42],
        [-36, 43],
        [-23, 12],
        [1, 7],
        [4, 8],
        [9, 6],
        [64, -1],
        [7, 7],
        [4, 19],
        [9, 27],
        [10, 41],
        [-1, 34],
        [-63, 55],
        [-46, 50],
        [-32, 67],
        [-22, 61],
        [1, 20],
        [6, 19],
        [49, 56],
        [4, 15],
        [-1, 10],
        [-18, 29],
        [-22, 29],
        [-7, 22],
        [-14, 11],
        [-27, 1],
        [-46, 36],
        [-10, 4],
        [-2, 7],
        [2, 7],
        [33, 15],
        [0, 12],
        [-10, 16],
        [-19, 12],
        [-17, 29],
        [-6, 26],
      ],
      [
        [63492, 75947],
        [25, -34],
        [45, -90],
        [63, -147],
        [15, -42],
        [10, -48],
        [9, -59],
        [14, -52],
        [64, -130],
        [28, -48],
        [45, -63],
        [16, -14],
        [21, -3],
        [39, -1],
        [35, -24],
        [18, -17],
        [18, -25],
        [16, -28],
        [17, -77],
        [-62, 25],
        [-62, -4],
        [-36, -16],
        [-34, -22],
        [-32, -32],
        [-21, -62],
        [-17, -142],
        [-25, -134],
        [0, -61],
        [12, -59],
        [-2, -29],
        [-11, -12],
        [-15, -25],
        [-19, -122],
        [-10, -25],
        [-12, -15],
        [-3, 15],
        [0, 32],
        [-27, 28],
        [-14, -32],
        [-10, -67],
        [-20, -71],
        [-1, -13],
        [5, -219],
      ],
      [
        [62653, 75239],
        [3, 19],
        [-8, 9],
        [-8, 0],
        [-8, -9],
        [0, -24],
        [10, -5],
        [11, 10],
      ],
      [
        [62506, 75476],
        [-6, -7],
        [-9, 7],
        [-3, 14],
        [1, 16],
        [9, 3],
        [7, -4],
        [2, -14],
        [-1, -15],
      ],
      [
        [89771, 44956],
        [-7, -69],
        [-14, -55],
        [-1, -32],
        [3, -45],
        [22, -33],
        [18, -21],
        [10, -58],
        [31, -81],
        [-1, -56],
        [16, -69],
        [16, -137],
        [4, -121],
        [16, -80],
        [-10, -172],
        [11, -70],
        [15, -58],
        [18, -116],
        [13, -106],
        [19, -30],
        [38, -36],
        [41, 40],
        [28, 53],
        [31, 13],
        [43, 28],
        [31, -71],
        [17, -80],
        [74, -104],
        [42, -68],
        [32, -37],
        [30, -50],
        [-3, -50],
        [-7, -39],
        [7, -61],
        [4, -71],
        [-6, -86],
        [22, -130],
        [7, -103],
        [23, -101],
        [-2, -104],
        [-4, -40],
        [-3, -58],
        [18, -72],
        [17, -54],
        [24, -58],
        [33, -89],
        [23, -17],
        [20, -2],
        [-3, -90],
        [41, -179],
        [22, -147],
        [-15, -197],
        [-14, -114],
        [3, -56],
        [53, -137],
        [30, -25],
        [-6, -65],
        [-4, -101],
        [24, -78],
        [27, -58],
        [30, -35],
        [29, -28],
        [38, -28],
        [48, -12],
        [25, -44],
        [13, -35],
        [39, -12],
        [17, 7],
        [22, 14],
        [14, -24],
        [11, -31],
        [21, -86],
        [44, -85],
        [30, -14],
        [18, -43],
        [24, -11],
        [22, -6],
        [30, -33],
        [49, -75],
        [45, -13],
        [20, -21],
        [44, -83],
        [17, -44],
        [18, -66],
        [-22, -6],
        [-21, 13],
        [-13, -64],
        [29, -90],
        [35, -63],
        [41, -67],
        [40, -94],
        [10, -72],
        [11, -29],
        [13, -101],
        [35, -59],
        [2, -108],
        [18, -149],
        [19, -135],
        [14, -40],
        [17, -65],
        [18, 8],
        [14, 21],
        [28, -64],
        [15, -28],
        [8, 16],
        [-17, 123],
        [11, 72],
        [10, 9],
        [16, 2],
        [18, -58],
        [26, -62],
        [47, -55],
        [38, -52],
        [11, 2],
        [-4, 40],
        [1, 59],
        [15, 9],
        [14, -29],
        [25, -91],
        [5, -188],
        [0, -158],
        [17, -162],
        [24, -43],
        [16, -40],
        [28, -54],
        [18, -51],
        [23, -23],
        [74, -108],
        [20, -13],
        [32, -2],
        [40, -49],
        [19, -45],
        [43, -169],
        [20, -59],
        [43, -59],
        [19, -19],
        [29, -40],
        [10, -59],
        [3, -34],
        [17, -63],
        [25, -75],
        [38, -41],
        [34, -91],
        [2, -148],
        [18, -73],
        [12, -32],
        [27, -30],
        [11, -24],
        [-23, -195],
        [22, -391],
        [-12, -122],
        [22, -121],
        [52, -210],
        [12, -74],
        [7, -87],
        [34, -111],
        [-2, -169],
        [13, -80],
        [-3, -105],
        [-40, -112],
        [-31, -139],
        [-1, -118],
        [-20, -228],
        [-14, -62],
        [-10, -95],
        [-43, -230],
        [-2, -90],
        [6, -108],
        [-7, -103],
        [-11, -71],
        [-10, -130],
        [-44, -202],
        [-63, -150],
        [-4, -114],
        [-8, -50],
        [-13, -62],
        [-39, -69],
        [-23, -29],
        [-9, -40],
        [-22, 0],
        [0, -13],
        [15, -12],
        [-7, -21],
        [-58, -36],
        [-40, -47],
        [-40, -113],
        [-17, -60],
        [-21, -57],
        [-13, -27],
        [-6, -28],
        [-8, -72],
        [-21, -13],
        [-18, -21],
        [8, -68],
        [-9, -78],
        [-3, -53],
        [-10, -34],
        [-11, 12],
        [-10, -5],
        [-12, -19],
        [19, -5],
        [11, -9],
        [-39, -76],
        [-36, -78],
        [-10, -51],
        [-15, -65],
        [-14, -144],
        [-11, -83],
        [8, -58],
        [-2, -11],
        [-8, -4],
        [-5, 7],
        [-17, -20],
        [-4, -20],
        [7, -25],
        [4, -8],
        [-2, -12],
        [-6, -13],
        [-16, 0],
        [-19, -21],
        [-53, -213],
        [-23, -57],
        [-27, -87],
        [-10, -79],
        [-8, -86],
        [-10, -145],
        [-9, -103],
        [-21, -99],
        [-7, -71],
        [-3, -135],
        [10, -103],
        [-7, -54],
        [0, -52],
        [-8, -49],
        [-34, -11],
        [-28, -40],
        [-40, -65],
        [-23, -24],
        [-51, -18],
        [-99, 8],
        [-189, -24],
        [-37, -15],
        [-70, -45],
        [-68, -70],
        [-66, -94],
        [-149, -256],
        [-117, -28],
        [-22, 0],
        [-18, 7],
        [-21, -16],
        [0, -32],
        [19, -33],
        [14, -31],
        [25, 43],
        [11, -12],
        [4, -79],
        [1, -50],
        [-8, -27],
        [-15, -20],
        [-17, 13],
        [-2, 27],
        [-22, 65],
        [-26, 57],
        [-25, 18],
        [-14, -19],
        [-23, -20],
        [-20, 72],
        [-20, 63],
        [-28, 7],
        [-24, -1],
        [-19, 27],
        [-39, 43],
        [8, 33],
        [10, 35],
        [22, 13],
        [-6, 48],
        [-12, 39],
        [-30, 10],
        [-21, -7],
        [-12, -30],
        [-16, -54],
        [-64, -67],
        [-32, 37],
        [-36, 55],
        [18, -4],
        [36, 2],
        [30, 49],
        [13, 31],
        [15, 66],
        [-19, 46],
        [-18, 34],
        [-26, 30],
        [-98, -102],
        [-21, -15],
        [-19, -20],
        [34, -17],
        [20, 5],
        [21, -30],
        [-34, -42],
        [-26, -12],
        [-34, -25],
        [-63, -66],
        [-80, -136],
        [-35, -39],
        [-41, -32],
        [-56, 37],
        [-31, 8],
        [-40, 56],
        [-67, 38],
        [-63, 74],
        [-44, 38],
        [-31, 8],
        [-43, -16],
        [-73, 67],
        [-56, 7],
        [-36, -67],
        [-29, 5],
        [-18, 9],
        [-59, 110],
        [-56, 55],
        [-107, 28],
        [-66, 76],
        [-49, 146],
        [-94, 168],
        [-25, 61],
        [-12, 60],
        [-1, 47],
        [13, 90],
        [17, 89],
        [3, 50],
        [-35, 167],
        [-50, 159],
        [-23, 49],
        [-62, 106],
        [-57, 79],
        [-15, 42],
        [-4, 21],
        [27, -10],
        [12, 32],
        [19, 11],
        [14, -43],
        [17, -8],
        [0, 72],
        [10, 35],
        [-7, 15],
        [-5, 14],
        [-25, 16],
        [-28, -24],
        [-21, -31],
        [-28, -26],
        [-11, -28],
        [-29, -1],
        [-12, -7],
        [-58, -53],
        [-36, -1],
        [-57, 18],
        [18, 73],
        [23, 43],
        [19, 50],
        [30, 173],
        [-5, 151],
        [-15, 61],
        [-48, 125],
        [-22, 76],
        [-27, 80],
        [-13, -46],
        [-8, -49],
        [-26, -70],
        [-13, -157],
        [-50, -239],
        [-35, -3],
        [-30, 10],
        [-52, -28],
        [-35, -33],
        [-32, 0],
        [-18, -10],
        [-23, 8],
        [37, 187],
        [31, -5],
        [35, 7],
        [15, -3],
        [23, 2],
        [18, 86],
        [11, 96],
        [-7, 62],
        [-3, 64],
        [7, 73],
        [3, 53],
        [43, 174],
        [37, 89],
        [42, 72],
        [-5, 68],
        [-14, 85],
        [-4, 65],
        [20, 20],
        [19, 41],
        [-22, 187],
        [-14, 55],
        [-22, 55],
        [0, -71],
        [2, -70],
        [-30, -89],
        [-40, -64],
        [-26, -60],
        [-25, -136],
        [-32, -115],
        [-30, -43],
        [-27, -9],
        [-27, -18],
        [-42, -45],
        [-42, -39],
        [-30, -50],
        [-26, -27],
        [-86, -230],
        [-40, -76],
        [-8, -31],
        [-16, -26],
        [3, -37],
        [13, -24],
        [13, -102],
        [-8, -22],
        [-14, 11],
        [-35, 57],
        [-22, -21],
        [-18, -23],
        [-46, 105],
        [-20, 24],
        [-24, 42],
        [-26, 36],
        [-11, 5],
        [-19, -8],
        [2, 28],
        [13, 23],
        [11, 6],
        [21, -34],
        [24, -30],
        [14, -2],
        [6, 12],
        [-23, 118],
        [-15, 104],
        [-7, 30],
        [-19, 106],
        [-9, 30],
        [-40, 74],
        [-43, 88],
        [-11, 104],
        [-16, 67],
        [-20, 42],
        [-31, 38],
        [-85, 14],
        [-35, 107],
        [-21, 133],
        [16, 9],
        [19, 2],
        [7, 41],
        [-5, 64],
        [-84, 78],
        [-40, 83],
        [-34, 35],
        [-31, 14],
        [-42, -3],
        [-53, 3],
        [-126, 131],
        [-30, 4],
        [-90, -41],
        [-31, 7],
        [-137, 179],
        [-91, 86],
        [-30, 15],
        [-39, 15],
        [-32, -21],
        [-22, -19],
        [-46, -22],
        [-182, 14],
        [-156, -28],
        [-105, -19],
        [-67, -24],
        [-112, -107],
        [-133, -103],
        [-108, -49],
        [-99, -65],
        [-66, -19],
        [-84, -8],
        [-179, 32],
        [-61, -24],
        [-97, -120],
        [-29, -29],
        [-55, -33],
        [-141, -155],
        [-65, -33],
        [-42, -11],
        [-36, -32],
        [-32, -66],
        [-45, -183],
        [-27, -86],
        [-61, -138],
        [-39, -46],
        [-40, 6],
        [-44, -48],
        [-38, 51],
        [-31, 9],
        [-50, -4],
        [-174, -58],
        [-25, 68],
        [-32, 10],
        [-60, -3],
        [-90, 20],
        [-164, -25],
        [-79, -28],
        [-31, -25],
        [-58, 16],
        [-99, -23],
        [-35, -38],
        [-26, -35],
        [-51, -154],
        [-56, -51],
        [-47, -1],
        [-51, -12],
        [-105, -148],
        [-106, -144],
        [-36, -15],
        [-40, -24],
        [-52, -12],
        [-26, -13],
        [-122, 37],
        [-77, 4],
        [-97, 22],
        [-83, 71],
        [-64, 40],
        [-73, 155],
        [-44, 58],
        [-80, 70],
        [-23, -2],
        [-19, -19],
        [-33, 49],
        [-1, 64],
        [-9, 54],
        [1, 142],
        [5, 167],
        [29, -38],
        [23, -36],
        [49, 2],
        [44, 62],
        [25, 92],
        [21, 103],
        [-3, 110],
        [-15, 193],
        [10, 41],
        [15, 16],
        [5, 96],
        [4, 296],
        [-11, 111],
        [-68, 226],
        [-45, 197],
        [-32, 88],
        [-28, 143],
        [-23, 198],
        [-7, 100],
        [-7, 185],
        [8, 105],
        [-4, 61],
        [-28, 167],
        [-64, 156],
        [-10, 58],
        [0, 61],
        [-15, 71],
        [-51, 143],
        [-52, 123],
        [-9, 60],
        [-10, 249],
        [-19, 114],
        [-89, 287],
        [-104, 248],
        [-29, 102],
        [-13, 34],
        [8, 4],
        [11, -13],
        [13, -25],
        [7, -2],
        [6, 21],
        [-1, 47],
        [4, 26],
        [9, -14],
        [11, -53],
        [33, -138],
        [10, -70],
        [42, -21],
        [13, 18],
        [15, 36],
        [5, 97],
        [-21, 44],
        [-20, 19],
        [-32, 72],
        [-21, 115],
        [-33, 107],
        [0, 38],
        [15, 28],
        [25, -15],
        [23, -62],
        [25, -57],
        [-4, -100],
        [-4, -27],
        [2, -23],
        [9, -22],
        [12, -17],
        [13, 25],
        [11, 58],
        [7, -7],
        [18, -133],
        [14, -36],
        [27, -42],
        [24, 32],
        [11, 28],
        [-4, 94],
        [7, 91],
        [-4, 67],
        [-61, 177],
        [-56, 218],
        [-34, 109],
        [-28, 164],
        [-18, 56],
        [-24, 92],
        [-1, 104],
        [2, 71],
        [19, 151],
        [18, 79],
        [56, 181],
        [3, 78],
        [0, 59],
        [8, 90],
        [0, 63],
        [-8, 59],
        [-23, 100],
        [31, 177],
        [45, 226],
        [18, 33],
        [28, 31],
        [5, -47],
        [-13, -157],
        [19, -81],
        [-6, -92],
        [18, 15],
        [27, 18],
        [21, 48],
        [11, 46],
        [51, 184],
        [30, 69],
        [41, 50],
        [85, 61],
        [81, 80],
        [39, 77],
        [49, 66],
        [34, 73],
        [32, 49],
        [166, 183],
        [28, 34],
        [36, 4],
        [44, -6],
        [40, 10],
        [43, -42],
        [31, -5],
        [77, 46],
        [41, 40],
        [71, 89],
        [31, 25],
        [72, 28],
        [82, 37],
        [98, 154],
        [70, -10],
        [63, -15],
        [51, 46],
        [119, 28],
        [66, 39],
        [124, 102],
        [33, 35],
        [50, 73],
        [44, 91],
        [43, 123],
        [27, 110],
        [11, 57],
        [26, 90],
        [17, 71],
        [14, 34],
        [48, 48],
        [71, 135],
        [23, 28],
        [4, 43],
        [-15, 24],
        [-19, 15],
        [-13, 143],
        [-12, 99],
        [-1, 69],
        [5, 66],
        [28, 103],
        [19, 44],
        [28, 51],
        [25, 16],
        [21, 45],
        [34, 44],
        [15, 45],
        [21, 91],
        [19, 69],
        [15, -3],
        [28, -160],
        [19, -85],
        [35, -100],
        [32, -148],
        [27, -67],
        [13, -44],
        [10, -20],
        [3, 28],
        [-3, 33],
        [13, 112],
        [-6, 79],
        [2, 31],
        [6, 12],
        [13, -8],
        [25, -44],
        [13, -16],
        [9, 3],
        [-1, 72],
        [13, 45],
        [-5, 31],
        [-22, -2],
        [-9, 39],
        [-18, 44],
        [-21, 32],
        [-24, 74],
        [-8, 28],
        [10, 14],
        [15, -2],
        [13, 31],
        [5, 42],
        [-11, 69],
        [12, 25],
        [22, -7],
        [36, -109],
        [16, 11],
        [13, 44],
        [23, 12],
        [24, -8],
        [15, -31],
        [32, -32],
        [42, 4],
        [22, -8],
        [45, 6],
        [22, -10],
        [-4, 17],
        [-25, 20],
        [-27, 4],
        [-32, -2],
        [-14, 21],
        [-5, 55],
        [8, 40],
        [5, 17],
        [21, -7],
        [19, 2],
        [2, 53],
        [7, 48],
        [11, 39],
        [0, 37],
        [-12, -10],
        [-28, -86],
        [-14, 70],
        [-20, 53],
        [4, 77],
        [12, 76],
        [18, 10],
        [16, -12],
        [23, 45],
        [13, 34],
        [-3, 28],
        [3, 21],
        [16, -6],
        [62, -69],
        [12, -36],
        [13, 14],
        [4, 39],
        [-1, 39],
        [-14, -5],
        [-32, 3],
        [-7, 22],
        [3, 19],
        [-15, 46],
        [21, 29],
        [17, 2],
        [13, 20],
        [0, 27],
        [4, 12],
        [10, -17],
        [32, -7],
        [31, -35],
        [15, -8],
        [6, 19],
        [2, 41],
        [-39, 41],
        [-1, 40],
        [-17, 47],
        [0, 45],
        [24, 39],
        [5, 37],
        [15, 15],
        [27, 0],
        [19, 32],
        [21, 11],
        [6, 70],
        [-1, 48],
        [9, 12],
        [21, -20],
        [-6, -55],
        [0, -53],
        [-6, -29],
        [8, 2],
        [5, 12],
        [9, 35],
        [22, -14],
        [6, -37],
        [3, -37],
        [11, -12],
        [15, 56],
        [21, 15],
        [-1, 71],
        [8, 51],
        [2, 38],
        [13, 19],
        [3, 40],
        [-10, 28],
        [-6, 51],
        [18, 11],
        [18, -25],
        [13, -64],
        [8, -29],
        [11, 16],
        [7, 42],
        [22, 25],
        [22, -34],
        [24, -48],
        [31, 42],
        [28, 77],
        [-5, 47],
        [4, 49],
        [35, 26],
        [29, -19],
        [25, -52],
        [54, -39],
        [46, -56],
        [20, -36],
        [40, -58],
        [25, -60],
        [34, -107],
        [82, -131],
        [5, -23],
        [-11, -43],
        [-10, -56],
        [-12, -95],
        [-3, -139],
        [12, 10],
        [12, 50],
        [13, -10],
        [14, -32],
        [2, 31],
        [-9, 18],
        [-15, 64],
        [0, 34],
        [12, 28],
        [19, 32],
        [20, 22],
        [13, 18],
        [2, 24],
        [18, 23],
        [28, 8],
        [16, -4],
        [118, -59],
        [29, -60],
        [3, -73],
        [11, -26],
        [7, 46],
        [-2, 100],
        [10, 20],
        [31, -15],
        [22, -20],
        [30, -66],
        [6, -32],
        [13, -21],
        [4, 30],
        [-6, 43],
        [-4, 50],
        [6, 43],
        [36, 3],
        [23, 10],
        [-11, 16],
        [-15, 6],
        [-25, 40],
        [-17, 42],
        [27, 42],
        [-1, 10],
        [-25, -1],
        [-34, 40],
        [-29, 56],
        [22, 103],
        [45, 101],
        [25, 34],
        [2, 34],
        [12, 62],
        [8, 53],
        [2, 42],
        [11, 43],
        [28, 41],
        [37, 15],
        [18, 16],
        [18, 38],
        [16, 46],
        [-34, 90],
        [3, 50],
        [6, 59],
        [42, 43],
        [22, 112],
        [15, 17],
        [33, -3],
        [13, 10],
        [-2, 89],
        [3, 35],
        [14, 14],
        [18, -12],
        [11, -39],
        [25, -36],
        [9, 20],
        [-4, 39],
        [-3, 51],
        [23, 11],
        [19, 3],
        [1, 41],
        [-3, 33],
        [8, 14],
        [48, 7],
        [13, 34],
        [7, 29],
        [6, -16],
        [8, -66],
        [27, -38],
        [80, -1],
        [45, 27],
        [19, -16],
        [30, -13],
        [32, 31],
        [20, 24],
        [33, -31],
        [11, -32],
        [8, 69],
        [20, 24],
        [20, 14],
        [26, -12],
        [10, 4],
        [-23, 51],
        [1, 46],
        [-1, 69],
        [4, 62],
        [8, 45],
        [-55, 91],
        [-55, 14],
        [-40, -19],
        [-17, 15],
        [-36, 72],
        [-34, 27],
        [-2, 18],
        [41, 52],
        [16, -9],
        [24, -49],
        [14, -17],
        [12, 4],
        [7, 38],
        [11, 20],
        [20, -12],
        [62, -82],
        [34, -80],
        [18, 21],
        [31, 45],
        [29, -9],
        [17, -27],
        [25, -97],
        [20, -48],
        [48, -13],
        [24, -19],
        [25, -32],
        [33, 3],
        [70, -12],
        [65, -62],
        [27, -39],
        [32, -11],
        [18, -15],
        [34, -5],
        [53, 44],
        [24, -40],
        [11, -28],
        [48, -52],
        [53, -16],
        [37, 53],
        [55, 39],
        [38, 60],
        [28, 29],
        [27, 49],
        [10, -2],
        [-22, -46],
        [-2, -26],
        [17, -11],
        [-2, -14],
        [-23, -35],
        [-29, -56],
        [1, -34],
        [11, -18],
        [13, 8],
        [18, 28],
        [23, 16],
        [19, -23],
        [7, -80],
        [14, -53],
        [30, -7],
        [19, 0],
        [19, 74],
        [-10, 63],
        [-12, 14],
        [6, 23],
        [48, 104],
        [27, -3],
        [19, -102],
        [31, -53],
        [32, 4],
        [17, -14],
        [14, -61],
        [-114, -251],
        [-5, -28],
        [15, -45],
        [6, -53],
        [-37, -128],
        [-14, -6],
        [-13, 35],
        [-19, 22],
        [-18, -16],
        [-18, -9],
        [-66, -71],
        [0, -183],
        [17, -109],
        [-10, -71],
        [-19, -126],
        [-22, -47],
        [-17, -30],
        [-57, -172],
        [-18, -41],
        [-19, -59],
        [6, -56],
        [7, -39],
        [22, -44],
        [83, -92],
        [38, -64],
        [66, -76],
        [15, -53],
        [9, -43],
        [47, -49],
        [34, -30],
        [10, 8],
        [7, 10],
        [8, 0],
        [9, -6],
        [-2, -38],
        [-3, -21],
        [3, -27],
        [24, -34],
        [38, 1],
        [22, 8],
        [25, -36],
        [22, -24],
        [36, -48],
        [63, -58],
        [49, -38],
        [58, -140],
        [44, -81],
        [49, -59],
        [72, -41],
        [33, 7],
        [54, -48],
        [53, -22],
        [28, -66],
        [9, -50],
        [3, -39],
        [26, -92],
        [54, -30],
        [69, -92],
        [57, -41],
        [14, -24],
        [25, -29],
        [48, -1],
        [84, 46],
        [38, 47],
        [51, 74],
        [23, 128],
        [14, 103],
        [71, 212],
        [20, 105],
        [18, 140],
        [15, 87],
        [-5, 95],
        [16, 172],
        [36, 237],
        [13, 79],
        [-7, 118],
        [-22, 220],
        [10, 77],
        [10, 106],
        [-16, 77],
        [-16, 53],
        [-2, 75],
        [17, 140],
        [15, 74],
        [16, 96],
        [-9, 182],
        [34, 63],
        [13, 32],
        [26, 0],
        [12, -14],
        [3, 36],
        [-10, 34],
        [-4, 39],
        [-8, 20],
        [-16, 7],
        [-13, 21],
        [-19, 22],
        [3, 81],
        [33, 156],
        [18, 60],
        [11, -25],
        [14, -20],
        [2, 45],
        [-5, 46],
        [25, 152],
        [27, 207],
        [8, 188],
        [44, 36],
        [23, 47],
        [13, 55],
        [25, 0],
        [17, -24],
        [-11, -41],
        [-4, -31],
        [47, -79],
        [16, -60],
        [7, -57],
        [9, -54],
        [4, -72],
        [0, -115],
        [6, -109],
        [17, -34],
        [15, -22],
        [22, -3],
        [31, -18],
      ],
      [
        [92521, 37009],
        [-7, -16],
        [-13, 29],
        [-8, 102],
        [6, 59],
        [14, 55],
        [3, 30],
        [-6, 62],
        [42, 71],
        [10, 37],
        [4, 48],
        [-15, 52],
        [-12, 10],
        [10, 29],
        [12, 15],
        [9, 6],
        [7, -5],
        [5, -102],
        [17, -36],
        [-3, -50],
        [-58, -259],
        [-16, -97],
        [-1, -40],
      ],
      [
        [88751, 42296],
        [-21, -51],
        [-11, 7],
        [-10, -27],
        [-20, -13],
        [-12, 0],
        [-22, -13],
        [-4, 16],
        [4, 51],
        [19, 56],
        [18, 35],
        [46, 16],
        [36, 25],
        [4, -4],
        [26, -65],
        [-38, -8],
        [-15, -25],
      ],
      [
        [87975, 43891],
        [12, -24],
        [13, 2],
        [12, 53],
        [7, -8],
        [5, -13],
        [4, -23],
        [-17, -40],
        [-8, -6],
        [-7, -23],
        [-12, -73],
        [1, -24],
        [11, -25],
        [27, -22],
        [13, 10],
        [5, -3],
        [-5, -36],
        [-11, -27],
        [-36, 12],
        [-32, -4],
        [-52, 26],
        [-27, 3],
        [-8, 10],
        [16, 21],
        [10, 28],
        [-5, 67],
        [4, 84],
        [30, 41],
        [14, 42],
        [20, 26],
        [13, -3],
        [-2, -26],
        [5, -45],
      ],
      [
        [86238, 45115],
        [23, -14],
        [10, -19],
        [7, -21],
        [1, -25],
        [-29, -11],
        [-52, 37],
        [-51, -31],
        [-15, 0],
        [-10, 22],
        [8, 61],
        [19, -9],
        [16, 22],
        [-3, 67],
        [-9, 37],
        [27, 68],
        [12, 13],
        [12, 0],
        [11, -48],
        [2, -51],
        [13, -48],
        [8, -50],
      ],
      [
        [86282, 45290],
        [37, -5],
        [45, 43],
        [21, -18],
        [9, 4],
        [33, 41],
        [21, 12],
        [15, 30],
        [14, -33],
        [32, -38],
        [11, -40],
        [13, -19],
        [5, -12],
        [-20, -42],
        [-3, -45],
        [-21, 3],
        [-25, -74],
        [-95, -124],
        [-85, 106],
        [-37, 71],
        [-24, 99],
        [-5, 82],
        [-10, 51],
        [4, 14],
        [5, 6],
        [7, -1],
        [25, -56],
        [12, -15],
        [16, -40],
      ],
      [
        [81439, 36835],
        [-7, -24],
        [-54, 180],
        [-15, 123],
        [10, 22],
        [10, 6],
        [32, -170],
        [10, -39],
        [0, -40],
        [4, -12],
        [10, -46],
      ],
      [
        [88220, 31256],
        [67, -14],
        [26, 21],
        [32, -17],
        [22, -56],
        [-16, -27],
        [-15, -4],
        [-49, 23],
        [-46, -18],
        [-13, -23],
        [-9, -51],
        [-40, -28],
        [-18, 31],
        [-48, 22],
        [-17, -32],
        [-34, 9],
        [-32, -14],
        [-43, 8],
        [-46, 57],
        [-14, 26],
        [11, 47],
        [16, 34],
        [126, 49],
        [67, 41],
        [55, -7],
        [15, -9],
        [14, -21],
        [-10, -38],
        [-1, -9],
      ],
      [
        [90412, 29749],
        [-42, -38],
        [-15, 17],
        [1, 29],
        [3, 13],
        [36, 2],
        [17, -23],
      ],
      [
        [90364, 29671],
        [10, -28],
        [2, -11],
        [-24, 22],
        [-39, -5],
        [24, 40],
        [20, -8],
        [7, -10],
      ],
      [
        [91400, 40154],
        [-6, -6],
        [-10, 0],
        [-13, 11],
        [12, 74],
        [6, -39],
        [12, -32],
        [-1, -8],
      ],
      [
        [91370, 40236],
        [-6, -3],
        [-7, 6],
        [5, 24],
        [7, 19],
        [10, 14],
        [-3, -52],
        [-6, -8],
      ],
      [
        [91984, 38311],
        [10, -15],
        [8, 2],
        [8, -9],
        [-3, -38],
        [13, -42],
        [6, -30],
        [-10, -24],
        [-6, -8],
        [-15, 20],
        [-42, 121],
        [7, 41],
        [24, -18],
      ],
      [
        [91809, 38984],
        [-8, -1],
        [-7, 10],
        [6, 23],
        [1, 32],
        [10, -10],
        [7, -45],
        [-9, -9],
      ],
      [
        [91646, 39059],
        [-10, -18],
        [-6, 42],
        [1, 44],
        [10, 15],
        [5, -58],
        [0, -25],
      ],
      [
        [92649, 36038],
        [-24, -158],
        [-7, 3],
        [-9, 24],
        [1, 91],
        [10, 58],
        [24, -10],
        [5, -8],
      ],
      [
        [92622, 36108],
        [-6, -9],
        [-12, 55],
        [-4, 56],
        [5, 51],
        [14, 11],
        [10, -4],
        [-11, -94],
        [4, -66],
      ],
      [
        [89520, 45676],
        [-23, -33],
        [-15, 18],
        [-4, 36],
        [2, 16],
        [19, 28],
        [21, -65],
      ],
      [
        [89538, 45972],
        [-17, -36],
        [-17, 11],
        [-6, 20],
        [6, 29],
        [23, 5],
        [11, -29],
      ],
      [
        [89490, 45994],
        [-7, -16],
        [-12, 34],
        [14, 40],
        [12, -19],
        [-7, -39],
      ],
      [
        [90632, 41341],
        [6, -55],
        [12, -42],
        [-5, -28],
        [-7, -21],
        [-18, 19],
        [-12, 51],
        [-21, 41],
        [-5, 23],
        [25, -2],
        [12, 8],
        [5, 9],
        [8, -3],
      ],
      [
        [87943, 45288],
        [-20, -35],
        [-1, 26],
        [10, 21],
        [25, 84],
        [11, 20],
        [6, 11],
        [5, 31],
        [1, 46],
        [14, 7],
        [-11, -105],
        [-40, -106],
      ],
      [
        [87871, 45159],
        [-44, -43],
        [24, 58],
        [51, 52],
        [8, 12],
        [-2, -25],
        [-26, -43],
        [-11, -11],
      ],
      [
        [88081, 42754],
        [-12, -27],
        [-15, 28],
        [-3, 29],
        [-12, 8],
        [6, 27],
        [6, 7],
        [6, 33],
        [16, -39],
        [1, -43],
        [7, -23],
      ],
      [
        [88017, 42845],
        [-5, -4],
        [0, 48],
        [8, 24],
        [4, -50],
        [-7, -18],
      ],
      [
        [87941, 42840],
        [-17, -2],
        [-4, 3],
        [-3, 25],
        [5, 23],
        [18, 6],
        [7, -6],
        [-6, -49],
      ],
      [
        [88738, 41984],
        [-11, -10],
        [-3, 24],
        [14, 24],
        [9, 34],
        [19, -30],
        [3, -30],
        [-31, -12],
      ],
      [
        [87843, 43879],
        [-7, -6],
        [-25, 11],
        [0, 21],
        [3, 15],
        [7, 10],
        [16, 41],
        [11, -24],
        [5, -49],
        [-10, -19],
      ],
      [
        [86831, 45332],
        [-6, -9],
        [-22, 89],
        [6, 27],
        [-9, 46],
        [15, 5],
        [12, 34],
        [4, -16],
        [1, -63],
        [9, -36],
        [-10, -77],
      ],
      [
        [84777, 43444],
        [-18, -36],
        [-12, 29],
        [7, 58],
        [12, 20],
        [10, -10],
        [-1, -45],
        [2, -16],
      ],
      [
        [84610, 42971],
        [-11, -17],
        [-10, 5],
        [0, 23],
        [-11, 24],
        [6, 28],
        [4, 14],
        [9, -2],
        [3, -23],
        [12, -26],
        [-2, -26],
      ],
      [
        [82068, 39868],
        [-17, -45],
        [-19, 9],
        [-3, 23],
        [13, 37],
        [22, 45],
        [7, -28],
        [-3, -41],
      ],
      [
        [90289, 28348],
        [32, -2],
        [18, 14],
        [16, -2],
        [19, -33],
        [22, -18],
        [16, 3],
        [13, -6],
        [12, -24],
        [30, -20],
        [14, -13],
        [11, -20],
        [13, -16],
        [81, -54],
        [57, -26],
        [71, 12],
        [21, 15],
        [21, 22],
        [17, -20],
        [17, -32],
        [-3, 34],
        [6, 30],
        [17, 23],
        [20, 15],
        [32, -2],
        [31, 7],
        [14, 13],
        [14, 2],
        [19, -17],
        [19, -9],
        [13, 22],
        [21, 51],
        [12, 18],
        [55, -16],
        [15, 0],
        [27, 53],
        [17, -1],
        [51, -42],
        [22, -53],
        [-3, -97],
        [2, -34],
        [4, -34],
        [2, -67],
        [-6, -67],
        [-1, -52],
        [3, -52],
        [-3, -98],
        [8, -64],
        [-4, -44],
        [0, -21],
        [7, -19],
        [4, -22],
        [-3, -28],
        [3, -32],
        [-3, -27],
        [-11, 4],
        [-4, 21],
        [2, 26],
        [-2, 22],
        [-6, 19],
        [-20, 22],
        [6, 13],
        [10, 12],
        [-7, 29],
        [-13, -24],
        [-8, -33],
        [5, -11],
        [-8, -9],
        [-17, -38],
        [-12, -51],
        [-5, -50],
        [1, -52],
        [-10, -40],
        [-14, -39],
        [-3, -49],
        [1, -91],
        [11, -83],
        [7, -113],
        [-10, -15],
        [-30, -7],
        [-14, -15],
        [-24, 57],
        [-15, 58],
        [11, 24],
        [24, -14],
        [8, 13],
        [2, 16],
        [-2, 15],
        [-30, 32],
        [-33, 15],
        [-11, -19],
        [4, -55],
        [-3, -13],
        [-24, -21],
        [-12, 80],
        [-31, 60],
        [1, -29],
        [13, -50],
        [-1, -21],
        [-5, -29],
        [-13, -10],
        [-5, -23],
        [0, -32],
        [-5, -51],
        [-20, -23],
        [-48, 57],
        [-4, -19],
        [1, -17],
        [25, -33],
        [-12, -25],
        [-8, -29],
        [-14, -76],
        [-23, -64],
        [-11, -4],
        [-37, 10],
        [-42, 54],
        [-38, -6],
        [-62, 4],
        [-40, -20],
        [-9, 59],
        [-8, 21],
        [3, 18],
        [32, 13],
        [33, -1],
        [-6, 23],
        [-8, 8],
        [-15, -5],
        [-41, 19],
        [-29, -8],
        [-19, 28],
        [-34, 96],
        [-20, 45],
        [-12, 18],
        [-13, 9],
        [-9, 14],
        [-61, 221],
        [-8, 51],
        [-11, 129],
        [48, -62],
        [18, -39],
        [9, -50],
        [16, 61],
        [-3, 19],
        [-43, 73],
        [-6, 21],
        [-2, 25],
        [-10, -25],
        [-17, -3],
        [7, 51],
        [-6, 51],
        [-51, 111],
        [-39, 105],
        [-38, 130],
        [-3, 16],
        [-1, 28],
        [-18, 87],
        [-10, 65],
        [-4, 56],
        [17, 114],
        [3, 64],
        [27, -29],
        [63, -37],
      ],
      [
        [89979, 28734],
        [-8, -2],
        [-6, 32],
        [3, 47],
        [-14, 45],
        [8, 46],
        [-1, 50],
        [5, 22],
        [16, 24],
        [3, 43],
        [14, 2],
        [25, -33],
        [9, -85],
        [-4, -51],
        [9, -46],
        [-8, -40],
        [-21, -32],
        [-30, -22],
      ],
      [
        [91110, 28941],
        [50, -104],
        [25, -17],
        [8, -11],
        [-2, -45],
        [-11, -20],
        [20, -26],
        [-3, -17],
        [-4, 1],
        [-24, -35],
        [-29, -17],
        [-9, 12],
        [-8, 17],
        [-6, 23],
        [-37, 91],
        [4, 25],
        [-8, 38],
        [-18, -3],
        [-12, 23],
        [20, 22],
        [26, 61],
        [18, -18],
      ],
      [
        [90931, 26844],
        [-13, -59],
        [-21, 10],
        [-22, -10],
        [-13, 40],
        [0, 10],
        [16, -10],
        [6, 13],
        [4, 17],
        [5, 4],
        [4, 24],
        [14, 29],
        [8, 0],
        [9, -38],
        [3, -30],
      ],
      [
        [91139, 27240],
        [-15, -5],
        [-6, 2],
        [1, 30],
        [-2, 13],
        [13, 27],
        [20, -13],
        [7, -20],
        [-19, -17],
        [1, -17],
      ],
      [
        [90953, 26934],
        [-17, 0],
        [-7, 5],
        [-3, 29],
        [-11, 12],
        [6, 9],
        [2, 18],
        [7, 20],
        [13, -22],
        [10, -71],
      ],
      [
        [90217, 28509],
        [-10, -47],
        [-11, 60],
        [12, 8],
        [9, 21],
        [2, -3],
        [-2, -39],
      ],
      [
        [91201, 28624],
        [26, -34],
        [15, -38],
        [-20, -31],
        [-14, -6],
        [-9, 36],
        [-29, -13],
        [-31, 3],
        [-23, 27],
        [-3, 14],
        [14, 14],
        [38, -1],
        [36, 29],
      ],
      [
        [91176, 28504],
        [-14, -44],
        [-17, 28],
        [-2, 13],
        [21, 10],
        [7, -1],
        [5, -6],
      ],
      [
        [94132, 20328],
        [-9, -23],
        [-3, 26],
        [17, 114],
        [17, 20],
        [-3, -60],
        [-19, -77],
      ],
      [
        [79367, 45798],
        [-8, -41],
        [-14, 23],
        [-17, 7],
        [3, 30],
        [14, 5],
        [7, 1],
        [10, 11],
        [5, -36],
      ],
      [
        [76899, 44825],
        [4, -2],
        [4, 3],
        [2, -4],
        [-7, -5],
        [-4, 10],
        [-2, 16],
        [-1, 15],
        [2, 0],
        [0, -6],
        [1, -5],
        [1, -9],
        [-1, -6],
        [1, -7],
      ],
      [
        [76921, 44818],
        [-3, -3],
        [-3, 3],
        [-1, 1],
        [0, 3],
        [3, 1],
        [3, 2],
        [1, 5],
        [1, 7],
        [1, -7],
        [0, -6],
        [-2, -6],
      ],
      [
        [70474, 21234],
        [-34, -28],
        [-34, 1],
        [-14, 21],
        [-22, 68],
        [-14, 4],
        [-8, 19],
        [-1, 8],
        [15, 5],
        [23, -19],
        [55, -16],
        [40, -37],
        [30, -12],
        [-12, -10],
        [-24, -4],
      ],
      [
        [96649, 35127],
        [6, -6],
        [5, -3],
        [3, -5],
        [0, -9],
        [-3, -10],
        [-3, -4],
        [-1, -2],
        [-1, -1],
        [0, -3],
        [0, -2],
        [-2, 8],
        [-3, 6],
        [-3, 0],
        [-2, -6],
        [-1, 0],
        [-1, 6],
        [-1, 6],
        [-1, 5],
        [1, 5],
        [3, 5],
        [0, 4],
        [-2, 2],
        [-3, -2],
        [4, 9],
        [5, -3],
      ],
      [
        [84331, 44685],
        [0, -6],
        [-6, 1],
        [0, 6],
        [6, -1],
      ],
      [
        [33833, 33138],
        [-6, -53],
        [14, -228],
        [-8, -32],
        [-14, -37],
        [-16, -2],
        [-19, 6],
        [-13, -23],
        [-9, -100],
        [-25, -218],
        [4, -51],
        [21, -84],
        [7, -53],
        [6, -41],
        [5, -76],
        [-12, -34],
        [-11, -6],
        [-14, -19],
        [16, -93],
        [13, -43],
        [38, -87],
        [144, -122],
        [60, -72],
        [68, -97],
        [37, -100],
        [3, -83],
        [-54, -124],
        [-6, -103],
        [11, -73],
        [20, -68],
        [52, -88],
        [39, -32],
        [52, 4],
        [9, -25],
        [5, -21],
        [8, -178],
        [-1, -67],
        [-15, -61],
        [-100, -282],
        [-86, -172],
        [-31, -94],
        [-11, -102],
        [-27, -48],
        [-148, -154],
        [-230, -137],
        [-186, -71],
        [-42, -24],
        [-299, -78],
        [-58, -11],
        [-75, 7],
        [-61, -10],
        [-68, 21],
        [-61, 25],
        [-34, 61],
        [-41, 7],
        [-11, -30],
        [20, -78],
        [-9, -93],
        [11, -54],
        [24, -11],
        [23, -27],
        [21, -37],
        [-35, -4],
        [13, -29],
        [15, -18],
        [-2, -61],
        [-13, -148],
        [-34, -32],
        [-9, -8],
        [-11, -32],
        [-21, -142],
        [-7, -92],
        [9, -59],
        [41, -124],
        [-15, -81],
        [-26, -44],
        [-112, -90],
        [-45, -36],
        [-70, -25],
        [-114, -4],
        [-42, 6],
        [-97, 82],
        [-73, 49],
        [-66, 39],
        [-64, 23],
        [9, 12],
        [4, 21],
        [-18, 12],
        [-13, 3],
        [-42, -43],
        [-18, -43],
        [-5, -38],
        [-1, -92],
        [8, -76],
        [30, -189],
        [3, -103],
        [-14, -130],
        [20, -76],
        [24, -34],
        [56, -34],
        [21, -23],
        [24, 3],
        [7, -9],
        [-4, -16],
        [-14, -33],
        [1, -35],
        [42, -10],
        [43, 7],
        [46, 16],
        [11, 24],
        [0, 50],
        [-53, 9],
        [6, 18],
        [41, 21],
        [53, 33],
        [27, 7],
        [18, -23],
        [12, -21],
        [16, -54],
        [9, -71],
        [1, -86],
        [-7, -81],
        [-7, -28],
        [-14, -35],
        [-95, -43],
        [-26, 11],
        [-25, 61],
        [-8, 63],
        [-21, 42],
        [-46, 34],
        [-45, -10],
        [-45, -59],
        [-44, -19],
        [-15, -53],
        [110, -86],
        [52, -24],
        [17, 0],
        [17, -11],
        [-15, -32],
        [-16, -20],
        [-79, -44],
        [-34, -31],
        [-41, -60],
        [-57, -132],
        [-17, -28],
        [-9, -34],
        [-6, -91],
        [19, -150],
        [-20, -63],
        [12, -70],
        [-6, -47],
        [-20, -67],
        [-80, -106],
        [-14, -78],
        [28, -45],
        [-2, -40],
        [-9, -36],
        [-33, 0],
        [-120, 24],
        [-44, -39],
        [-41, -49],
        [-11, -23],
        [-14, -14],
        [-83, -26],
        [-16, -17],
        [-88, -184],
        [-38, -115],
        [-45, -112],
        [-12, -47],
        [-3, -66],
        [7, -59],
        [6, -44],
        [16, -56],
        [33, -64],
        [170, -260],
        [35, -23],
        [181, -28],
        [40, -36],
        [23, -58],
        [9, -51],
        [-10, -128],
        [-11, -41],
        [-20, -36],
        [-43, -47],
        [-51, -25],
        [14, -18],
        [21, 2],
        [46, 16],
        [19, -16],
        [15, -50],
        [-28, -21],
        [-9, -24],
        [-20, -38],
        [-105, -148],
        [-56, -45],
        [-52, -59],
        [-70, -61],
        [-27, -34],
        [-37, -73],
        [-56, -79],
        [-61, -170],
        [-2, -33],
        [9, -22],
        [-34, -298],
        [-12, -35],
        [-24, -37],
        [-65, -62],
        [-31, -7],
        [-41, 35],
        [-23, 38],
        [-22, 63],
        [-28, 66],
        [-1, -23],
        [9, -40],
        [-6, -42],
        [-70, -19],
        [-18, -20],
        [63, 9],
        [43, -13],
        [18, -15],
        [16, -32],
        [15, -38],
        [-12, -21],
        [-35, -18],
        [-44, -32],
        [-53, -58],
        [-29, -68],
        [-13, -48],
        [-14, -98],
        [-4, -64],
        [-22, -50],
        [-35, -44],
        [2, -11],
        [24, 23],
        [18, 7],
        [16, -59],
        [22, -119],
        [10, -82],
        [-2, -25],
        [-6, -33],
        [-45, -8],
        [-39, 1],
        [-29, -15],
        [15, -15],
        [27, 6],
        [37, -36],
        [41, 15],
        [19, -23],
        [13, -22],
        [63, -172],
        [55, -106],
        [27, -63],
        [-13, -29],
      ],
      [
        [30936, 21513],
        [16, -25],
        [65, -118],
        [17, -48],
        [10, -56],
        [-26, 35],
        [-27, -20],
        [-13, -34],
        [-12, -36],
        [0, -26],
        [9, -23],
        [27, -19],
        [64, -7],
        [5, -7],
        [37, -141],
        [19, -32],
        [22, -25],
        [51, -72],
        [49, -77],
        [58, -74],
        [62, -57],
        [57, -42],
        [54, -53],
        [58, -73],
        [63, -54],
        [67, -37],
        [69, -32],
        [105, 13],
        [32, -4],
        [20, -23],
        [-20, -64],
        [-26, -51],
        [-35, -21],
        [-36, -8],
        [-34, 1],
        [-33, 10],
        [-31, -6],
        [-29, -21],
        [-31, -11],
        [-32, -2],
        [-31, -18],
        [-32, -13],
        [-32, 11],
        [-84, 51],
        [-55, 12],
        [-185, 20],
        [-59, 12],
        [-59, 18],
        [-31, 0],
        [-45, -11],
        [-35, 1],
        [-10, -11],
      ],
      [
        [32069, 20324],
        [31, -13],
        [61, 10],
        [32, 0],
        [14, -5],
        [6, -7],
        [42, 11],
        [18, -1],
        [-5, -25],
        [-38, -24],
        [-16, 10],
        [-82, -2],
        [-36, -25],
        [-15, 0],
        [-36, -36],
        [-26, 23],
        [-7, 21],
        [18, 29],
        [18, 1],
        [12, 12],
        [9, 21],
      ],
      [
        [32812, 29278],
        [3, -36],
        [-15, 4],
        [-34, 35],
        [-12, 33],
        [-2, 14],
        [35, -15],
        [16, -14],
        [9, -21],
      ],
      [
        [32856, 61657],
        [-9, -23],
        [-31, 9],
        [-6, 29],
        [-1, 20],
        [19, 41],
        [22, -18],
        [9, -19],
        [6, -4],
        [0, -17],
        [-3, -12],
        [-6, -6],
      ],
      [
        [32848, 61966],
        [-4, -15],
        [-23, 28],
        [-7, 51],
        [1, 11],
        [3, 6],
        [9, -10],
        [12, -4],
        [8, -17],
        [1, -50],
      ],
      [
        [53392, 48525],
        [-4, 16],
        [-12, 57],
        [7, 54],
        [7, 41],
        [-8, 82],
        [-18, 74],
        [-20, 93],
        [-6, 18],
      ],
      [
        [53261, 41906],
        [11, 218],
        [11, 96],
        [0, 116],
        [-7, 298],
        [-7, 41],
        [-5, 48],
        [27, 37],
        [14, 27],
        [19, 50],
        [13, 69],
        [16, 153],
        [58, 352],
        [27, 345],
        [35, 163],
        [13, 183],
        [96, 236],
        [24, 145],
        [50, 72],
        [70, 75],
        [51, 135],
        [24, 94],
        [28, 179],
        [-1, 187],
        [18, 250],
        [-4, 72],
        [-26, 99],
        [-5, 71],
        [-24, 70],
        [-27, 53],
        [-12, 94],
        [-45, 149],
        [-13, 99],
        [-21, 71],
        [-4, 88],
        [-11, 93],
        [-22, 92],
        [-22, 105],
        [0, 32],
        [14, 40],
        [12, 13],
        [-4, -20],
        [-8, -23],
        [2, -19],
        [84, 184],
        [6, 36],
        [-3, 41],
        [-1, 49],
        [4, 57],
        [-80, 340],
        [-64, 316],
        [-10, 159],
        [-84, 210],
        [-33, 137],
        [-19, 96],
        [-15, 36],
        [6, 18],
        [21, 5],
        [49, 22],
        [65, 24],
        [61, 56],
        [17, 24],
      ],
      [
        [49383, 72064],
        [56, -11],
        [29, 5],
        [67, 51],
        [52, 69],
        [41, 36],
        [37, 75],
        [32, 48],
        [48, 52],
        [136, 111],
        [21, 1],
        [45, -25],
        [39, 8],
        [27, 39],
        [29, 93],
        [44, 57],
        [57, 58],
        [76, 54],
        [50, 51],
        [80, 43],
        [199, 28],
        [102, 24],
        [70, -5],
        [70, 80],
        [35, 26],
        [152, 6],
        [72, 58],
        [272, 0],
        [33, -19],
        [33, -32],
        [56, -75],
        [27, -17],
        [36, 16],
        [84, 72],
        [94, 37],
        [51, 42],
        [22, 62],
        [44, 23],
        [25, -47],
        [98, -48],
        [60, 13],
        [26, 15],
        [-9, 71],
        [63, -19],
        [49, -34],
        [51, -69],
        [33, -14],
        [60, 31],
        [125, 16],
      ],
      [
        [55555, 74717],
        [-1, 52],
        [-9, 41],
        [-31, 99],
        [-102, 96],
        [-24, 43],
        [-11, 36],
        [-10, 35],
        [10, 1],
        [10, -9],
        [13, -11],
        [5, 18],
        [-6, 37],
        [-26, 87],
        [-2, 24],
        [13, 74],
        [21, 82],
        [-1, 99],
        [7, 75],
        [-8, 49],
        [-3, 60],
        [15, 79],
        [14, 20],
        [8, 25],
        [1, 85],
        [-31, 39],
        [-35, 8],
      ],
      [
        [37300, 16980],
        [61, -14],
        [31, -21],
        [8, -24],
        [36, -9],
        [5, -5],
        [7, -14],
        [4, -15],
        [-1, -20],
        [-62, 48],
        [-86, 3],
        [-20, 34],
        [-44, -20],
        [-5, 13],
        [0, 17],
        [6, 24],
        [28, -10],
        [32, 13],
      ],
      [
        [24851, 12213],
        [-3, -1],
        [-6, 0],
        [-4, 0],
        [-4, -1],
        [-3, -1],
        [-3, 0],
        [-1, 0],
        [0, 1],
        [-2, 2],
        [-3, 5],
        [-2, 5],
        [-1, 5],
        [1, 7],
        [3, 3],
        [0, 4],
        [0, 5],
        [1, 5],
        [1, 5],
        [0, 3],
        [1, 1],
        [2, 1],
        [5, 0],
        [3, 0],
        [5, -1],
        [3, -1],
        [3, -2],
        [4, -5],
        [3, -6],
        [0, -3],
        [1, -3],
        [0, -4],
        [1, -5],
        [1, -4],
        [-1, -7],
        [-2, -7],
        [-2, -1],
        [-1, 0],
      ],
      [
        [0, 0],
        [99608, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
        [-390, 0],
        [-391, 0],
      ],
      [
        [0, 3253],
        [447, 8],
        [89, -8],
        [94, -25],
        [207, -2],
        [194, -11],
        [50, -33],
        [65, -20],
        [137, 17],
        [110, 9],
        [89, 1],
        [823, -46],
        [843, -81],
        [172, -26],
        [154, -61],
        [162, 9],
        [957, -47],
        [148, 0],
        [586, -50],
        [1026, -114],
        [89, -4],
        [97, 3],
        [-51, 62],
        [-96, 56],
        [-129, 40],
        [84, 12],
        [184, 1],
        [-37, 29],
        [-101, 16],
        [-366, 19],
        [-1463, 144],
        [-32, 9],
        [-21, 12],
        [-38, 13],
        [-60, 14],
        [-223, 8],
        [-61, 13],
        [-29, 16],
        [15, 7],
        [17, -3],
        [340, 13],
        [38, 16],
        [2, 11],
        [-18, 8],
        [-59, 10],
        [-137, 48],
        [-44, 21],
        [23, 33],
        [28, 14],
        [105, 11],
        [31, 22],
        [-20, 40],
        [-241, 80],
        [-162, 29],
        [-107, -20],
        [-203, -1],
        [-251, -10],
        [-68, 11],
        [-70, 32],
        [-82, 57],
        [-42, 15],
        [-80, 47],
        [-106, 46],
        [-561, 111],
        [-98, 30],
        [-702, 175],
        [-29, 30],
        [-18, 32],
        [324, -71],
        [61, 0],
        [72, 18],
        [55, -5],
        [75, 18],
        [84, 9],
        [219, -55],
        [442, -89],
        [118, -31],
        [63, -22],
        [52, -7],
        [51, -16],
        [63, 9],
        [38, -8],
        [92, 5],
        [419, 10],
        [166, -8],
        [195, -43],
        [75, -71],
        [56, -32],
        [107, 25],
        [90, 30],
        [173, 25],
        [56, -10],
        [93, -37],
        [105, -62],
        [445, 17],
        [187, -3],
        [133, -28],
        [485, 93],
        [75, 20],
        [111, 65],
        [-91, 20],
        [-65, 7],
        [-25, 33],
        [44, 13],
        [140, 18],
        [272, 28],
        [161, 27],
        [86, 70],
        [369, 109],
        [117, 48],
        [108, 79],
        [-242, 157],
        [-232, 136],
        [74, 42],
        [73, 33],
        [35, 26],
        [29, 37],
        [-76, 45],
        [-71, 33],
        [-117, 32],
        [-440, 77],
        [-150, 33],
        [60, 51],
        [80, 39],
        [169, 17],
        [1079, 60],
        [1087, 74],
        [27, 37],
        [-144, 44],
        [-123, 11],
        [-45, 13],
        [-17, 28],
        [-1, 38],
        [-14, 6],
        [-46, 4],
        [-196, 44],
        [-41, 17],
        [-65, 41],
        [-17, 32],
        [39, 82],
        [60, 35],
        [104, 19],
        [75, 7],
        [225, -1],
        [88, 10],
        [37, 11],
        [-7, 39],
        [-25, 18],
        [-1, 24],
        [38, 14],
        [47, -1],
        [13, 29],
        [-26, 47],
        [-67, 25],
        [-176, 43],
        [-400, 65],
        [-155, 49],
        [-89, 38],
        [-74, 44],
        [-75, 21],
        [-52, 23],
        [11, 28],
        [-24, 44],
        [-29, 8],
        [-127, -19],
        [-227, 10],
        [-278, 41],
        [-192, 47],
        [-251, 127],
        [-99, 63],
        [73, 44],
        [80, 28],
        [334, 65],
        [50, 22],
        [68, 58],
        [-112, 24],
        [-95, -2],
        [-84, 16],
        [-342, 4],
        [-193, -8],
        [-162, 72],
        [-121, 71],
        [-34, 36],
        [-26, 64],
        [41, 94],
        [34, 67],
        [-4, 83],
        [9, 113],
        [58, 38],
        [45, 7],
        [105, -87],
        [90, -7],
        [131, 17],
        [83, 45],
        [44, 17],
        [81, 4],
        [156, -20],
        [71, 8],
        [80, -4],
        [251, -58],
        [55, -27],
        [30, -21],
        [9, -30],
        [31, -31],
        [107, -15],
        [299, 17],
        [78, -7],
        [212, -85],
        [180, -91],
        [62, -23],
        [102, -15],
        [36, 13],
        [31, 34],
        [97, 42],
        [218, 52],
        [52, 52],
        [-29, 28],
        [-84, 30],
        [-51, 10],
        [-28, 34],
        [2, 47],
        [17, 45],
        [57, 11],
        [104, -61],
        [130, -57],
        [45, -8],
        [35, 2],
        [65, 20],
        [78, 15],
        [149, -122],
        [88, -8],
        [110, -1],
        [21, 19],
        [-13, 32],
        [-18, 35],
        [-23, 5],
        [-3, 32],
        [48, 30],
        [33, 13],
        [-13, 22],
        [-53, 33],
        [-31, 6],
        [-28, 14],
        [9, 23],
        [35, 10],
        [49, 34],
        [-15, 39],
        [2, 51],
        [-20, 27],
        [-116, 53],
        [-169, 87],
        [-157, 39],
        [-350, -31],
        [-124, 20],
        [-81, 23],
        [-88, 30],
        [103, 32],
        [108, 22],
        [32, 20],
        [41, 40],
        [48, 29],
        [39, 8],
        [128, -15],
        [289, -108],
        [61, -12],
        [198, -49],
        [55, -3],
        [68, 11],
        [-55, 48],
        [-61, 34],
        [-145, 96],
        [16, 46],
        [94, 76],
        [245, 6],
        [107, 27],
        [139, 58],
        [179, 96],
        [154, 12],
        [192, 30],
        [65, -22],
        [164, -93],
        [103, -32],
        [35, -3],
        [37, 3],
        [-97, 116],
        [63, 14],
        [80, 13],
        [66, 29],
        [49, 24],
        [168, 111],
        [150, 31],
        [426, 48],
        [146, -44],
        [123, -5],
        [27, 13],
        [25, 59],
        [65, 115],
        [55, 41],
        [185, 43],
        [145, -2],
        [104, -47],
        [97, -31],
        [89, -14],
        [90, 1],
        [134, 27],
        [178, 9],
        [83, 14],
        [96, -26],
        [236, -9],
        [183, -37],
        [113, 0],
        [153, 36],
        [83, 5],
        [299, 58],
        [234, 12],
        [177, -26],
        [286, 16],
        [290, -12],
        [117, -21],
        [652, 13],
        [518, 55],
        [71, 19],
        [111, 60],
        [61, 55],
        [41, 17],
        [87, 6],
        [149, -12],
        [205, -41],
        [176, 15],
        [337, -23],
        [32, 19],
        [32, 104],
        [55, 165],
        [47, 49],
        [77, -13],
        [233, -94],
        [5, -40],
        [-24, -29],
        [-38, -11],
        [-11, -80],
        [31, -23],
        [52, 7],
        [33, -35],
        [-73, -60],
        [-52, -34],
        [-33, -15],
        [-23, -115],
        [-31, -38],
        [-4, -42],
        [50, 0],
        [50, 18],
        [44, 4],
        [140, 30],
        [255, 35],
        [84, 17],
        [48, 6],
        [31, 23],
        [-43, 57],
        [-14, 47],
        [26, 39],
        [-7, 67],
        [-24, 68],
        [49, 50],
        [46, -11],
        [79, 8],
        [45, -25],
        [69, -22],
        [66, -11],
        [63, -45],
        [21, -98],
        [-19, -100],
        [-65, -73],
        [-121, -66],
        [-137, -105],
        [29, -50],
        [71, 17],
        [309, -5],
        [199, 8],
        [125, -12],
        [158, -27],
        [125, -39],
        [149, -8],
        [93, 15],
        [87, -20],
        [339, 84],
        [138, 48],
        [79, -24],
        [128, 20],
        [71, -18],
        [133, 30],
        [84, 3],
        [97, -12],
        [296, -6],
        [22, -55],
        [90, -83],
        [73, -32],
        [93, 14],
        [67, 25],
        [106, -9],
        [153, 35],
        [153, -11],
        [64, 6],
        [29, 23],
        [25, 51],
        [-47, 28],
        [-134, 36],
        [-123, 74],
        [-55, 16],
        [-88, -9],
        [-41, 13],
        [-44, 24],
        [57, 29],
        [70, 93],
        [-29, 84],
        [-33, 18],
        [-81, -3],
        [-98, -30],
        [-39, 21],
        [-64, 11],
        [-25, 78],
        [-68, 146],
        [-36, 42],
        [-108, 38],
        [-93, 19],
        [-90, 24],
        [-27, 58],
        [17, 79],
        [108, 17],
        [104, -8],
        [58, -15],
        [67, -6],
        [77, -16],
        [50, -23],
        [40, -13],
        [75, 0],
        [260, 21],
        [35, 15],
        [31, 28],
        [55, 7],
        [51, -4],
        [74, 17],
        [-85, 23],
        [-91, 44],
        [-137, 53],
        [-115, 29],
        [-209, 20],
        [-107, -7],
        [-67, 11],
        [-239, -6],
        [-65, 21],
        [-46, 58],
        [-65, 138],
        [-18, 72],
        [44, 27],
        [29, 30],
        [71, 3],
        [103, -12],
        [34, -14],
        [25, -43],
        [-25, -44],
        [-33, -23],
        [20, -21],
        [105, -6],
        [52, -13],
        [45, -5],
        [97, 20],
        [142, 8],
        [71, -20],
        [85, -15],
        [125, 24],
        [445, -13],
        [54, -6],
        [54, -40],
        [46, -24],
        [49, 10],
        [145, -46],
        [77, -36],
        [79, -18],
        [67, -5],
        [75, 9],
        [98, 30],
        [81, 12],
        [58, -11],
        [123, -6],
        [94, -36],
        [73, 15],
        [77, 41],
        [244, 29],
        [163, -8],
        [298, -74],
        [69, -7],
        [136, 44],
        [44, 72],
        [-6, 81],
        [39, 19],
        [33, -9],
        [60, 56],
        [82, -5],
        [51, -10],
        [31, 36],
        [28, 77],
        [97, 6],
        [70, -12],
        [92, -49],
        [0, -41],
        [-38, -43],
        [-63, -107],
        [39, -63],
        [59, 6],
        [75, -13],
        [91, 27],
        [58, 1],
        [101, -93],
        [68, -5],
        [53, 5],
        [172, 84],
        [50, 9],
        [61, -38],
        [89, -89],
        [78, -51],
        [114, -32],
        [99, -9],
        [116, -42],
        [64, -35],
        [146, 0],
        [62, -15],
        [176, -72],
        [160, 36],
        [83, 33],
        [40, 61],
        [-20, 91],
        [-7, 92],
        [24, 38],
        [42, 7],
        [191, -103],
        [-12, 62],
        [-16, 47],
        [-49, 82],
        [7, 61],
        [41, 19],
        [80, -32],
        [96, -16],
        [79, -36],
        [155, -128],
        [50, -112],
        [105, -28],
        [73, 5],
        [83, 18],
        [111, 16],
        [86, -5],
        [79, 21],
        [24, -61],
        [-74, -88],
        [-29, -57],
        [24, -15],
        [45, 13],
        [37, 18],
        [129, -9],
        [104, 40],
        [89, 14],
        [84, 42],
        [69, -4],
        [53, -7],
        [72, -33],
        [69, 18],
        [41, -7],
        [56, -2],
        [297, 145],
        [67, -3],
        [85, 9],
        [107, 34],
        [83, 15],
        [68, -1],
        [121, 53],
        [193, -7],
        [98, 28],
        [191, 32],
        [128, 37],
        [228, 98],
        [92, 58],
        [100, 129],
        [63, 129],
        [70, 171],
        [-34, 111],
        [-37, 49],
        [-31, 54],
        [-73, 111],
        [-20, 139],
        [7, 131],
        [-26, 123],
        [-26, 91],
        [-54, 152],
        [-66, 99],
        [-77, 133],
        [0, 121],
        [-19, 95],
        [-46, 68],
        [-20, 54],
        [36, 11],
        [33, 17],
        [89, 21],
        [213, -37],
        [19, 54],
        [54, 40],
        [38, 50],
        [-13, 77],
        [-47, 31],
        [-56, 65],
        [26, 52],
        [45, 0],
        [22, 57],
        [-17, 56],
        [21, 70],
        [41, 90],
        [28, 33],
        [-51, 54],
        [-48, 70],
        [12, 54],
        [24, 57],
        [29, 80],
        [40, 57],
        [26, 20],
        [-7, 19],
        [-61, 20],
        [-56, 4],
        [-101, -36],
        [-16, 7],
        [-5, 19],
        [-6, 39],
        [10, 95],
        [16, 90],
        [14, 13],
        [40, 11],
        [38, 69],
        [35, 4],
        [22, -23],
        [7, -91],
        [12, -21],
        [-4, -44],
        [18, -15],
        [22, 28],
        [41, 14],
        [16, -31],
        [15, -15],
        [7, 26],
        [-5, 74],
        [-7, 30],
        [-5, 48],
        [9, 23],
        [10, 37],
        [-17, 78],
        [6, 29],
        [37, 47],
        [18, 9],
        [35, 0],
        [63, -31],
        [30, -2],
        [22, 14],
        [14, 30],
        [11, 98],
        [-27, 35],
        [0, 31],
        [16, 20],
        [28, 68],
        [42, 4],
        [41, -6],
        [40, 13],
        [-14, 28],
        [-12, 43],
        [45, 21],
        [29, 6],
        [77, -27],
        [30, -15],
        [28, 35],
        [-9, 34],
        [-30, 19],
        [-5, 30],
        [7, 39],
        [48, -20],
        [11, 8],
        [13, 34],
        [-8, 17],
        [-6, 22],
        [63, 5],
        [9, 9],
        [13, 27],
        [19, 9],
        [56, -1],
        [12, 14],
        [6, 30],
        [-31, 7],
        [-39, 29],
        [-6, 80],
        [9, 57],
        [35, 50],
        [42, 34],
        [78, -30],
        [60, 7],
        [24, -30],
        [33, -8],
        [8, 34],
        [-15, 30],
        [-10, 49],
        [96, 59],
        [31, -10],
        [38, 14],
        [-14, 45],
        [21, 57],
        [27, 8],
        [19, -50],
        [26, -10],
        [29, 12],
        [71, 58],
        [35, 9],
        [35, 3],
        [36, 34],
        [9, 40],
        [20, 28],
        [62, 38],
        [25, 27],
        [55, 95],
        [-10, 24],
        [16, 20],
        [163, 86],
        [80, 8],
        [133, 52],
        [81, 61],
        [51, 25],
        [45, 68],
        [55, 11],
        [128, 47],
        [96, 77],
        [133, 53],
        [62, -5],
        [25, -16],
        [16, -63],
        [26, -77],
        [40, -38],
        [-15, -34],
        [-38, 4],
        [-41, -8],
        [-9, 38],
        [15, 28],
        [-15, 25],
        [-37, -6],
        [-49, -13],
        [-33, -19],
        [-43, -41],
        [-34, -23],
        [-113, -61],
        [-74, -88],
        [-53, -93],
        [-32, -63],
        [-47, -5],
        [-11, -23],
        [19, -18],
        [15, -8],
        [35, -7],
        [-6, -27],
        [-24, -7],
        [3, -22],
        [25, -32],
        [5, -46],
        [-29, -7],
        [-44, 49],
        [-50, 5],
        [-39, 23],
        [-25, 33],
        [-24, -7],
        [-18, -47],
        [11, -52],
        [-21, -31],
        [-24, 14],
        [-9, 62],
        [-23, 11],
        [-32, 1],
        [-77, -67],
        [-28, -2],
        [-14, -34],
        [-45, -38],
        [-29, -31],
        [-71, -102],
        [-40, -43],
        [-76, -24],
        [-30, 3],
        [-18, 10],
        [-27, 7],
        [-28, 1],
        [-9, -27],
        [44, -88],
        [-24, -30],
        [-53, 2],
        [-26, 25],
        [-21, -24],
        [-17, -23],
        [-17, -34],
        [26, -72],
        [41, -32],
        [30, -6],
        [11, -27],
        [-65, -11],
        [-44, -63],
        [-20, -44],
        [-23, -38],
        [3, -44],
        [34, -66],
        [46, -47],
        [46, -4],
        [60, 15],
        [14, 13],
        [59, 7],
        [26, 46],
        [19, 3],
        [17, -8],
        [27, -2],
        [15, 30],
        [20, 11],
        [28, -8],
        [54, 1],
        [15, -27],
        [-17, -30],
        [-33, -42],
        [-31, 23],
        [-27, -4],
        [-15, -22],
        [29, -47],
        [-11, -42],
        [-24, -42],
        [-29, 27],
        [-4, 45],
        [-40, 27],
        [-39, 12],
        [-26, -47],
        [-41, -13],
        [-6, -54],
        [-17, -50],
        [-24, 15],
        [-9, 62],
        [-67, 50],
        [-35, 7],
        [-70, -13],
        [-24, 1],
        [-28, -11],
        [-20, -42],
        [29, -31],
        [10, -42],
        [-1, -31],
        [-6, -12],
        [-5, -26],
        [32, -37],
        [1, -50],
        [-25, 1],
        [-21, 15],
        [-81, 130],
        [-51, 58],
        [-22, 51],
        [-53, 12],
        [-38, 1],
        [-46, -21],
        [18, -24],
        [9, -36],
        [-28, -15],
        [-35, -56],
        [-23, -48],
        [-14, -10],
        [-18, -28],
        [75, -61],
        [11, -24],
        [4, -41],
        [-24, -22],
        [-56, -9],
        [-99, 43],
        [-43, 1],
        [-15, 30],
        [-22, -4],
        [-13, -51],
        [-16, -45],
        [-24, -30],
        [7, -46],
        [19, -11],
        [-15, -19],
        [-31, -15],
        [-21, -18],
        [46, -17],
        [9, -15],
        [2, -22],
        [-72, -16],
        [-47, -4],
        [-28, 18],
        [-26, -9],
        [-17, -29],
        [-5, -39],
        [5, -48],
        [9, -34],
        [7, -13],
        [8, -29],
        [-43, -74],
        [-5, -16],
        [-3, -34],
        [21, -31],
        [16, -46],
        [-23, -23],
        [-25, -47],
        [26, -9],
        [44, -2],
        [48, 6],
        [72, 41],
        [20, 7],
        [9, -16],
        [6, -25],
        [-18, -24],
        [-130, -68],
        [-24, -29],
        [33, -16],
        [67, -3],
        [26, -22],
        [-16, -24],
        [-24, -23],
        [-28, -54],
        [23, -20],
        [72, -32],
        [131, -42],
        [97, -14],
        [-22, 49],
        [-3, 60],
        [68, 49],
        [35, 16],
        [162, 29],
        [44, -1],
        [34, -13],
        [-13, -25],
        [-37, 9],
        [-65, -16],
        [-100, -54],
        [-18, -23],
        [7, -42],
        [85, -34],
        [27, -27],
        [-37, -81],
        [6, -52],
        [43, -57],
        [57, -65],
        [28, -43],
        [43, -25],
        [70, -61],
        [38, -61],
        [12, -140],
        [57, -116],
        [67, -53],
        [8, -46],
        [-22, -46],
        [-57, 27],
        [-32, -27],
        [-12, -49],
        [40, -34],
        [64, -42],
        [138, 4],
        [4, -46],
        [-32, -27],
        [-25, -34],
        [-31, -19],
        [-52, -12],
        [-13, -41],
        [22, -58],
        [72, 26],
        [53, 3],
        [55, -10],
        [17, -77],
        [65, -95],
        [16, -45],
        [-12, -43],
        [-40, -13],
        [-25, -34],
        [-36, -29],
        [-41, -16],
        [-76, -79],
        [-33, -8],
        [-14, -16],
        [65, -8],
        [45, -2],
        [97, 64],
        [37, -14],
        [24, -42],
        [12, -49],
        [-24, -42],
        [-169, -25],
        [-82, -23],
        [-88, -66],
        [101, -31],
        [74, 11],
        [37, -12],
        [51, -23],
        [56, 12],
        [44, 24],
        [33, -1],
        [31, -12],
        [4, -41],
        [4, -72],
        [7, -54],
        [-18, -36],
        [-88, -26],
        [-64, 1],
        [-2, -76],
        [96, -57],
        [60, 30],
        [53, -15],
        [0, -91],
        [41, -101],
        [36, -6],
        [30, 46],
        [38, 0],
        [15, -54],
        [-17, -91],
        [-29, -50],
        [-78, 21],
        [-44, 16],
        [-35, -34],
        [-58, -29],
        [-51, -4],
        [-45, 45],
        [-52, 34],
        [-83, 17],
        [-78, 8],
        [26, -39],
        [35, -23],
        [14, -69],
        [28, -72],
        [65, 19],
        [90, -41],
        [56, -43],
        [24, -57],
        [-31, -91],
        [-51, -34],
        [-32, -16],
        [-56, 35],
        [-39, 0],
        [-41, -16],
        [-14, -42],
        [-27, -20],
        [144, -3],
        [45, -11],
        [33, -38],
        [-52, -50],
        [-95, 8],
        [-41, -19],
        [-35, -36],
        [142, -22],
        [59, 12],
        [93, 38],
        [22, -38],
        [-37, -38],
        [-48, -61],
        [-100, -19],
        [-75, -1],
        [-98, 22],
        [-26, 13],
        [-41, 7],
        [3, -37],
        [26, -26],
        [66, -94],
        [11, -36],
        [-21, -50],
        [-58, -37],
        [-65, -14],
        [-54, 32],
        [-39, 91],
        [-50, 27],
        [-51, 8],
        [-30, -4],
        [3, -46],
        [12, -49],
        [-20, -35],
        [-44, 18],
        [-57, -16],
        [-53, -27],
        [-48, -30],
        [98, -14],
        [65, -3],
        [46, -46],
        [-17, -23],
        [-88, -10],
        [-86, -20],
        [-117, -50],
        [86, -21],
        [81, 1],
        [57, -5],
        [47, -9],
        [13, -27],
        [-29, -30],
        [-192, -74],
        [-201, -91],
        [-74, -29],
        [-77, -16],
        [-179, -78],
        [-113, -36],
        [-318, -55],
        [-497, -139],
        [-169, -99],
        [-50, -76],
        [-32, -11],
        [-95, -26],
        [-95, -12],
        [-251, -8],
        [-257, 37],
        [-208, 8],
        [-113, -12],
        [-386, 66],
        [-49, -3],
        [-60, -12],
        [-48, 0],
        [-36, 9],
        [-78, 4],
        [-263, -26],
        [-27, -41],
        [33, -76],
        [96, -92],
        [160, -162],
        [86, -35],
        [53, -37],
        [100, -43],
        [224, -2],
        [306, -34],
        [174, -31],
        [-6, -60],
        [-105, -115],
        [-65, -44],
        [-155, -80],
        [-213, -40],
        [-163, 12],
        [-289, 65],
        [-362, 60],
        [-538, 57],
        [-118, 26],
        [-139, 26],
        [-79, -27],
        [-60, -25],
        [-133, -2],
        [39, -23],
        [537, -155],
        [458, -113],
        [54, -29],
        [65, -20],
        [-6, -72],
        [-26, -57],
        [-90, -50],
        [-234, -3],
        [-293, -40],
        [-146, -1],
        [-145, 39],
        [-309, 113],
        [-189, 84],
        [-132, 96],
        [-91, 76],
        [-102, 75],
        [7, -47],
        [18, -47],
        [50, -58],
        [73, -63],
        [5, -25],
        [-35, -4],
        [-53, 30],
        [-45, -28],
        [-16, -33],
        [19, -44],
        [28, -42],
        [93, -94],
        [80, -25],
        [106, -57],
        [258, -106],
        [44, -36],
        [78, -78],
        [16, -59],
        [76, -58],
        [52, -9],
        [47, 2],
        [16, 50],
        [-3, 61],
        [20, 15],
        [74, 15],
        [193, -21],
        [821, -12],
        [78, -35],
        [31, -45],
        [21, -93],
        [-87, -112],
        [-59, -48],
        [-97, -29],
        [-88, -23],
        [-133, -9],
        [-275, 8],
        [-269, 0],
        [209, -54],
        [203, -44],
        [282, 8],
        [112, 12],
        [97, 21],
        [41, -37],
        [77, -78],
        [45, -25],
        [31, -26],
        [43, -85],
        [17, -50],
        [42, -58],
        [30, -49],
        [44, -33],
        [75, -15],
        [82, 28],
        [159, 13],
        [154, -45],
        [99, -14],
        [132, 37],
        [105, 52],
        [221, 47],
        [41, 20],
        [60, 16],
        [91, -4],
        [36, -12],
        [46, -51],
        [43, -70],
        [63, -35],
        [67, -25],
        [38, -3],
        [126, -22],
        [164, 21],
        [73, -21],
        [12, -40],
        [39, -32],
        [49, -11],
        [665, -180],
        [229, -35],
        [353, -18],
        [274, -2],
        [38, -11],
        [53, -35],
        [-105, -24],
        [-112, -3],
        [-169, 9],
        [-60, -5],
        [-129, 10],
        [-67, -7],
        [-61, 11],
        [-91, -25],
        [-166, -19],
        [37, -27],
        [62, -6],
        [126, -10],
        [172, 5],
        [15, -43],
        [-158, -10],
        [-336, -9],
        [-35, -7],
        [-26, -22],
        [50, -11],
        [31, -12],
        [16, -31],
        [-36, -79],
        [56, -55],
        [39, -9],
        [41, 8],
        [71, -22],
        [69, -30],
        [146, -2],
        [173, 40],
        [85, -1],
        [228, 25],
        [207, -4],
        [289, 48],
        [48, -1],
        [44, -6],
        [-80, -44],
        [-355, -110],
        [-127, -21],
        [-51, -14],
        [29, -54],
        [46, -56],
        [94, -60],
        [59, -89],
        [57, -19],
        [110, 41],
        [27, -31],
        [5, -61],
        [-29, -49],
        [-37, -28],
        [-26, -27],
        [-17, -36],
        [46, -31],
        [123, -19],
        [163, -7],
        [151, -1],
        [93, -9],
        [341, 194],
        [137, 94],
        [67, 39],
        [56, 29],
        [288, 119],
        [67, 36],
        [76, 53],
        [141, 8],
        [193, 86],
        [171, 66],
        [68, 13],
        [51, 5],
        [60, 14],
        [150, -5],
        [107, 11],
        [190, 42],
        [145, 27],
        [154, 23],
        [174, 4],
        [463, 44],
        [132, -19],
        [146, -46],
        [95, 1],
        [125, 14],
        [86, 18],
        [39, -53],
        [20, -69],
        [-42, -64],
        [-70, -40],
        [-19, -66],
        [95, -33],
        [108, 10],
        [206, 32],
        [164, 42],
        [45, 28],
        [64, -8],
        [109, 35],
        [136, 146],
        [171, 147],
        [144, 94],
        [93, 110],
        [77, 63],
        [86, 48],
        [58, 24],
        [132, 5],
        [189, 76],
        [275, 87],
        [211, -42],
        [223, -63],
        [110, 51],
        [87, 9],
        [74, 23],
        [74, 17],
        [53, 45],
        [71, 38],
        [54, 55],
        [271, 27],
        [284, 36],
        [38, 13],
        [37, -7],
        [98, 10],
        [125, 30],
        [172, 10],
        [90, -3],
        [82, 81],
        [164, 17],
        [175, 32],
        [73, 24],
        [57, 6],
        [1413, 63],
        [61, 31],
        [124, 25],
        [47, 61],
        [-190, 25],
        [-58, 26],
        [-65, 7],
        [-38, -9],
        [-164, 7],
        [-1303, 94],
        [-27, 8],
        [-45, 57],
        [9, 105],
        [-39, 81],
        [-91, 22],
        [-94, -2],
        [-119, -10],
        [-314, -44],
        [-125, -4],
        [-335, 68],
        [-221, 77],
        [-145, 25],
        [-104, 52],
        [-97, 40],
        [-7, 91],
        [23, 85],
        [187, 246],
        [117, 118],
        [78, 9],
        [71, 53],
        [73, 119],
        [59, 56],
        [135, 66],
        [59, 17],
        [212, 81],
        [58, 2],
        [95, -13],
        [108, 73],
        [329, 156],
        [75, 59],
        [91, 35],
        [266, 132],
        [238, 64],
        [118, 19],
        [144, 39],
        [160, 59],
        [139, 57],
        [497, 109],
        [298, 29],
        [203, 32],
        [144, -19],
        [143, 6],
        [123, 29],
        [57, 23],
        [83, 59],
        [276, -28],
        [178, 40],
        [74, 4],
        [78, 18],
        [-31, 21],
        [-28, 3],
        [-28, 28],
        [-37, 58],
        [37, 73],
        [28, 36],
        [82, 45],
        [43, 64],
        [40, 94],
        [135, 185],
        [38, 26],
        [86, 8],
        [73, -5],
        [83, 2],
        [210, -48],
        [39, 19],
        [67, 54],
        [56, 68],
        [120, 100],
        [23, 30],
        [-10, 49],
        [-180, -21],
        [-136, -33],
        [-131, 18],
        [-17, 28],
        [28, 21],
        [49, 8],
        [19, 34],
        [-45, 28],
        [-81, 16],
        [-36, 21],
        [3, 51],
        [20, 75],
        [44, 21],
        [36, 31],
        [96, 105],
        [57, 32],
        [164, 29],
        [190, -44],
        [44, 12],
        [46, 59],
        [-47, 90],
        [-36, 34],
        [0, 30],
        [100, -14],
        [93, -20],
        [109, 4],
        [129, 90],
        [181, 75],
        [88, 31],
        [78, 18],
        [42, 76],
        [62, 144],
        [46, 75],
        [-1, 45],
        [-14, 38],
        [-47, -11],
        [-43, -5],
        [-101, 38],
        [-125, 61],
        [-38, 68],
        [-18, 61],
        [39, 33],
        [38, 20],
        [41, 5],
        [73, -25],
        [93, -62],
        [46, -24],
        [53, -46],
        [40, 4],
        [47, 64],
        [38, 85],
        [32, 26],
        [49, 28],
        [54, 41],
        [-24, 40],
        [-58, 21],
        [-8, 24],
        [25, 27],
        [47, 6],
        [59, -59],
        [80, -39],
        [55, -13],
        [47, -32],
        [74, -108],
        [89, -179],
        [41, -2],
        [78, 16],
        [84, 7],
        [56, 51],
        [12, 128],
        [22, 58],
        [-8, 59],
        [-38, 60],
        [-33, 44],
        [6, 33],
        [28, 24],
        [37, 8],
        [64, 24],
        [99, -28],
        [54, -5],
        [81, 16],
        [84, 35],
        [86, 25],
        [67, -19],
        [29, -64],
        [-33, -65],
        [-54, -48],
        [-49, -59],
        [-13, -64],
        [2, -35],
        [47, -9],
        [416, 8],
        [55, -6],
        [72, 0],
        [78, -21],
        [132, 8],
        [118, 23],
        [56, 0],
        [97, -21],
        [69, -44],
        [143, 13],
        [40, 14],
        [39, 59],
        [41, 11],
        [48, -48],
        [15, -110],
        [22, -52],
        [61, -47],
        [60, 40],
        [39, 48],
        [94, 93],
        [107, 71],
        [82, 42],
        [200, 70],
        [99, 44],
        [194, 60],
        [250, 32],
        [446, 108],
        [147, 12],
        [240, 28],
        [123, 30],
        [125, 23],
        [77, 80],
        [175, -61],
        [60, -7],
        [82, 48],
        [90, 119],
        [131, -49],
        [75, -77],
        [93, -60],
        [208, -104],
        [66, -24],
        [138, -21],
        [37, 17],
        [65, 69],
        [67, 100],
        [42, 42],
        [61, 35],
        [69, 55],
        [-18, 30],
        [-39, 10],
        [-36, 16],
        [9, 29],
        [122, 7],
        [64, -101],
        [66, -33],
        [80, -33],
        [186, 26],
        [159, 2],
        [138, -20],
        [68, 3],
        [61, 76],
        [99, 28],
        [56, -34],
        [35, -112],
        [127, -31],
        [266, -51],
        [30, 13],
        [33, 59],
        [23, 72],
        [54, 12],
        [69, 39],
        [37, -6],
        [52, -47],
        [-18, -114],
        [-29, -105],
        [35, -85],
        [31, -47],
        [40, -8],
        [67, -2],
        [82, 6],
        [51, -4],
        [261, 42],
        [32, 94],
        [42, 107],
        [103, 136],
        [40, -10],
        [31, -14],
        [70, -67],
        [42, -34],
        [9, -49],
        [-46, -47],
        [13, -31],
        [46, -25],
        [148, -41],
        [48, 8],
        [71, 43],
        [72, 85],
        [39, 98],
        [61, -5],
        [59, -20],
        [41, -51],
        [0, -97],
        [57, -66],
        [46, -42],
        [120, -45],
        [128, -11],
        [90, -27],
        [146, 10],
        [71, 30],
        [46, 8],
        [80, 24],
        [84, 57],
        [52, 23],
        [192, 52],
        [145, 57],
        [154, 102],
        [150, 61],
        [230, 31],
        [64, 13],
        [88, -1],
        [217, 73],
        [82, 42],
        [46, 15],
        [52, 52],
        [28, 102],
        [22, 63],
        [-4, 61],
        [-20, 80],
        [-46, 71],
        [-47, 104],
        [21, 119],
        [37, 49],
        [96, 54],
        [95, 11],
        [108, -7],
        [94, -11],
        [8, -51],
        [-41, -55],
        [-52, -54],
        [-31, -23],
        [11, -46],
        [68, -7],
        [149, 10],
        [43, -42],
        [106, -184],
        [26, -87],
        [37, -25],
        [58, 12],
        [125, -1],
        [87, 13],
        [71, 1],
        [37, -9],
        [38, -42],
        [72, -49],
        [72, 36],
        [52, 17],
        [63, -4],
        [99, -57],
        [99, -133],
        [107, -67],
        [7, 43],
        [-14, 53],
        [44, 47],
        [53, 79],
        [77, 103],
        [61, 105],
        [15, 145],
        [29, 119],
        [49, 57],
        [48, 37],
        [75, 38],
        [92, 8],
        [88, 86],
        [62, 35],
        [130, 47],
        [163, 47],
        [114, 131],
        [39, 16],
        [58, 21],
        [107, 8],
        [173, 42],
        [54, 6],
        [91, 33],
        [80, 78],
        [58, 22],
        [104, -3],
        [88, 44],
        [74, 2],
        [68, 22],
        [10, 49],
        [-32, 33],
        [-1, 43],
        [38, 56],
        [30, 21],
        [90, -4],
        [75, -48],
        [55, -2],
        [14, -27],
        [-48, -34],
        [-32, -61],
        [55, -53],
        [49, -36],
        [59, 7],
        [71, 32],
        [70, -23],
        [31, -49],
        [0, -76],
        [15, -41],
        [49, 36],
        [27, 76],
        [-8, 97],
        [3, 60],
        [117, 97],
        [46, 73],
        [-82, 15],
        [-58, -10],
        [-32, 27],
        [-37, 73],
        [101, 61],
        [116, -2],
        [67, -52],
        [144, -83],
        [80, 2],
        [72, -13],
        [15, 26],
        [-27, 122],
        [3, 68],
        [-60, 38],
        [-17, 87],
        [25, 91],
        [71, 51],
        [97, 24],
        [208, 140],
        [55, 30],
        [137, 29],
        [160, 14],
        [199, 50],
        [355, -34],
        [95, -21],
        [59, -28],
        [57, -45],
        [74, -74],
        [107, -94],
        [139, -30],
        [39, -29],
        [51, -80],
        [-55, -52],
        [-45, -5],
        [-87, 31],
        [-60, 32],
        [-42, -13],
        [41, -55],
        [45, -34],
        [7, -46],
        [-24, -66],
        [-164, -130],
        [98, -37],
        [59, 30],
        [54, 55],
        [55, 25],
        [37, 10],
        [130, 2],
        [74, 22],
        [56, -16],
        [55, -35],
        [81, -35],
        [116, -38],
        [143, -147],
        [111, 15],
        [60, 32],
        [171, 10],
        [147, -65],
        [83, -24],
        [240, -20],
        [143, -42],
        [91, 50],
        [61, 20],
        [128, 10],
        [65, -10],
        [178, -54],
        [315, -55],
        [217, -29],
        [191, -1],
        [91, -24],
        [166, -26],
        [63, -20],
        [159, 16],
        [74, 21],
        [70, 47],
        [39, -11],
        [27, -59],
        [-15, -101],
        [29, -69],
        [23, -69],
        [33, -57],
        [21, -49],
        [-15, -41],
        [-46, -37],
        [-64, -79],
        [4, -69],
        [27, -44],
        [-32, -53],
        [24, -74],
        [4, -45],
        [-22, -36],
        [-50, -22],
        [-85, -3],
        [-44, -21],
        [-7, -55],
        [22, -41],
        [49, -22],
        [14, -44],
        [-7, -66],
        [-22, -56],
        [-45, -26],
        [-49, -6],
        [-91, 12],
        [-66, 37],
        [-42, -33],
        [-31, -34],
        [-95, -77],
        [-44, -52],
        [-41, -55],
        [108, -31],
        [79, -55],
        [172, 6],
        [55, 24],
        [73, 26],
        [39, -5],
        [24, -56],
        [-14, -89],
        [-3, -69],
        [-87, -190],
        [-29, -32],
        [-41, -52],
        [-48, -41],
        [-39, -20],
        [-75, -60],
        [-46, -109],
        [-50, -90],
        [-73, -154],
        [-42, -165],
        [-18, -99],
        [-28, -103],
        [-62, -177],
        [-40, -30],
        [-69, -72],
        [20, -48],
        [54, -5],
        [66, -11],
        [89, -37],
        [118, 76],
        [62, 48],
        [13, 96],
        [-13, 97],
        [38, 57],
        [87, 78],
        [205, 56],
        [42, 6],
        [68, 19],
        [60, 70],
        [53, 70],
        [93, 44],
        [77, 72],
        [12, 51],
        [32, 11],
        [96, 50],
        [25, 37],
        [30, 28],
        [21, 63],
        [8, 118],
        [24, 89],
        [47, 118],
        [38, 86],
        [37, 54],
        [99, 29],
        [43, 33],
        [57, 73],
        [39, 44],
        [-5, 90],
        [22, 83],
        [61, 50],
        [78, 92],
        [98, 13],
        [74, 48],
        [79, -33],
        [95, -46],
        [161, 14],
        [76, -21],
        [59, 25],
        [52, 71],
        [19, 87],
        [61, 50],
        [69, -1],
        [114, 87],
        [118, 77],
        [96, 18],
        [77, 60],
        [55, 102],
        [59, 79],
        [73, 75],
        [20, 133],
        [46, 67],
        [85, 59],
        [71, 32],
        [298, 100],
        [229, 66],
        [231, 82],
        [71, -1],
        [93, 45],
        [153, 2],
        [40, 4],
        [53, 93],
        [114, 86],
        [71, 28],
        [90, 74],
        [73, 7],
        [103, -13],
        [87, -20],
        [78, -1],
        [113, 62],
        [176, 10],
        [54, 30],
        [38, 26],
        [249, 87],
        [93, -17],
        [132, 15],
        [80, -4],
        [76, -11],
        [96, -3],
        [166, 31],
        [70, 20],
        [132, 76],
        [146, 18],
        [64, 20],
        [82, 17],
        [67, -29],
        [48, -27],
        [29, -6],
        [39, -5],
        [95, 30],
        [79, -8],
        [101, -33],
        [67, -27],
        [35, 0],
        [62, 24],
        [76, 60],
        [71, 24],
        [67, -15],
        [46, -25],
        [81, -34],
        [126, 5],
        [120, 13],
        [101, 28],
        [87, 30],
        [80, -46],
        [92, -17],
        [149, 81],
        [57, -18],
        [39, -21],
        [32, -10],
        [39, -68],
        [142, 13],
        [126, 57],
        [108, 43],
        [105, 28],
        [83, 39],
        [122, 148],
        [-2, 46],
        [17, 28],
        [26, 12],
        [194, -1],
        [60, 12],
        [79, 39],
        [137, -30],
        [131, -47],
        [34, 5],
        [53, 1],
        [93, -29],
        [105, -57],
        [93, -15],
        [385, -142],
        [218, -35],
        [110, -47],
        [28, -16],
        [32, -48],
        [58, -5],
        [46, 19],
        [61, -73],
        [148, -55],
        [154, -27],
        [100, 43],
        [170, 123],
        [52, 55],
        [-10, 122],
        [89, 136],
        [151, 67],
        [188, 35],
        [116, 30],
        [154, 28],
        [74, -29],
        [38, -21],
        [57, -25],
        [68, -76],
        [106, -172],
        [79, -62],
        [69, -5],
        [60, -10],
        [63, -39],
        [90, -121],
        [-54, -108],
        [-46, -40],
        [-196, -46],
        [-86, -37],
        [-75, -23],
        [-21, -90],
        [31, -42],
        [81, 21],
        [95, 10],
        [74, 18],
        [68, 28],
        [59, 40],
        [140, 21],
        [91, 34],
        [82, 20],
        [57, 35],
        [58, -5],
        [58, -34],
        [46, 3],
        [124, -11],
        [58, 25],
        [51, 2],
        [52, -19],
        [54, -26],
        [54, -7],
        [70, 16],
        [98, 42],
        [125, 46],
        [117, 15],
        [28, -1],
        [24, -9],
        [-117, -53],
        [-187, -72],
        [-100, -69],
        [59, -29],
        [352, 77],
        [160, 58],
        [142, 26],
        [35, 19],
        [116, 90],
        [42, 24],
        [125, 32],
        [163, 34],
        [124, 39],
        [84, 40],
        [63, 4],
        [49, -29],
        [63, -33],
        [62, 8],
        [76, 31],
        [53, 72],
        [31, 53],
        [57, 18],
        [73, 16],
        [59, -18],
        [96, -38],
        [67, -20],
        [58, -151],
        [136, -133],
        [49, -35],
        [119, 13],
        [128, -52],
        [55, 7],
        [52, 15],
        [47, -10],
        [70, 32],
        [73, 167],
        [67, 163],
        [66, 72],
        [40, 30],
        [50, 14],
        [77, 34],
        [104, 10],
        [77, -14],
        [167, -12],
        [136, 41],
        [154, -7],
        [76, 48],
        [82, 7],
        [111, -41],
        [32, -29],
        [61, -42],
        [15, -41],
        [16, -74],
        [30, -2],
        [103, 73],
        [56, 13],
        [106, 117],
        [56, -30],
        [127, -50],
        [51, -15],
        [100, -85],
        [51, 18],
        [42, 40],
        [124, -4],
        [116, -35],
        [48, -29],
        [59, -50],
        [37, -12],
        [29, 14],
        [240, -19],
        [104, -37],
        [79, -45],
        [278, -21],
        [107, -46],
        [64, 22],
        [127, -7],
        [52, -39],
        [46, -43],
        [101, -37],
        [55, 7],
        [78, 30],
        [78, 41],
        [78, 0],
        [37, -35],
        [13, -88],
        [58, 2],
        [64, 40],
        [56, -10],
        [20, -61],
        [-30, -81],
        [-71, -114],
        [-29, -95],
        [-59, -86],
        [10, -41],
        [59, -20],
        [59, 61],
        [132, 42],
        [69, 55],
        [119, 20],
        [117, -19],
        [83, -73],
        [154, -123],
        [6, -44],
        [13, -46],
        [-5, -41],
        [-23, -47],
        [82, -55],
        [73, -9],
        [59, 5],
        [247, -53],
        [118, 21],
        [106, 0],
        [126, 8],
        [97, -2],
        [77, -9],
        [91, 19],
        [74, 26],
        [36, -16],
        [15, -137],
        [5, -81],
        [42, -30],
        [44, 30],
        [32, 41],
        [195, -19],
        [78, -2],
        [74, -22],
        [75, -51],
        [72, 22],
        [43, 31],
        [58, 20],
        [17, 51],
        [7, 85],
        [-2, 83],
        [34, 15],
        [35, -15],
        [46, -39],
        [105, -125],
        [80, -81],
        [34, -38],
        [49, -31],
        [99, -75],
        [136, -31],
        [133, -60],
        [155, 4],
        [121, -77],
        [82, 60],
        [43, 14],
        [63, -16],
        [76, -52],
        [60, -12],
        [205, -86],
        [110, -30],
        [41, -63],
        [54, -60],
        [0, -61],
        [24, -77],
        [123, -61],
        [48, -59],
        [59, -78],
        [107, -293],
        [56, -52],
        [81, 5],
        [76, -76],
        [24, 11],
        [3, 30],
        [-69, 198],
        [-5, 107],
        [54, 61],
        [127, 17],
        [98, -114],
        [90, -69],
        [60, -12],
        [120, 3],
        [113, 73],
        [86, -26],
        [138, -9],
        [179, -43],
        [77, 8],
        [137, -18],
        [167, -62],
        [95, -23],
        [20, -25],
        [44, -41],
        [22, -50],
        [24, -46],
        [58, -52],
        [58, -9],
        [115, -44],
        [241, -136],
        [87, -40],
        [51, -29],
        [25, 35],
        [7, 72],
        [44, 15],
        [47, -107],
        [49, -81],
        [22, -71],
        [-51, -58],
        [-74, 12],
        [-52, 0],
        [-53, -96],
        [-22, -166],
        [49, 3],
        [36, 15],
        [8, -61],
        [-22, -51],
        [-44, -20],
        [-76, 39],
        [-93, 27],
        [-102, 10],
        [-99, 49],
        [-39, 3],
        [-41, -3],
        [53, -51],
        [55, -46],
        [125, -40],
        [156, -62],
        [4, -38],
        [-37, -47],
        [-46, -100],
        [-142, -86],
        [-83, 62],
        [-98, 15],
        [-49, -37],
        [-98, 4],
        [-194, -14],
        [-76, 79],
        [-119, 43],
        [4, -36],
        [102, -130],
        [109, -30],
        [108, -35],
        [26, -33],
        [-49, -31],
        [-66, 5],
        [-83, -61],
        [-158, 10],
        [-75, -3],
        [-44, -25],
        [-39, -9],
        [31, -22],
        [38, -60],
        [-54, -51],
        [-50, -25],
        [-51, 12],
        [-56, -20],
        [-27, 56],
        [-2, 122],
        [-32, 109],
        [-34, 4],
        [-54, -13],
        [-17, -93],
        [38, -161],
        [26, -50],
        [-21, -45],
        [-36, -14],
        [73, -136],
        [62, -93],
        [38, -31],
        [3, -46],
        [-30, -20],
        [-83, 18],
        [-41, -9],
        [-46, 6],
        [-77, 20],
        [-66, 6],
        [-66, -26],
        [-56, 2],
        [-50, 85],
        [-44, 20],
        [-36, -26],
        [-28, -101],
        [-57, -31],
        [-58, -46],
        [-41, -50],
        [-20, -198],
        [-34, -41],
        [-51, 2],
        [-39, -16],
        [-49, 16],
        [-64, 10],
        [-214, -68],
        [34, -35],
        [54, 6],
        [188, -11],
        [80, -36],
        [13, -89],
        [32, -38],
        [63, -41],
        [48, -20],
        [18, -30],
        [-20, -61],
        [-27, -56],
        [-59, -61],
        [17, -31],
        [64, -10],
        [27, -137],
        [-42, -61],
        [20, -51],
        [5, -51],
        [-43, -47],
        [-33, -24],
        [-11, -50],
        [64, -29],
        [47, -10],
        [66, -5],
        [45, -54],
        [58, -82],
        [42, -68],
        [3, -113],
        [41, -67],
        [77, -43],
        [-2, -45],
        [54, -14],
        [53, -4],
        [19, -41],
        [-17, -50],
        [-89, -61],
        [-37, -44],
        [89, -7],
        [92, -46],
        [117, 52],
        [62, 56],
        [40, 50],
        [30, -13],
        [3, -52],
        [36, -87],
        [151, -84],
        [84, -27],
        [81, -14],
        [71, 2],
        [20, -50],
        [-22, -46],
        [-56, 3],
        [-90, -8],
        [-66, 38],
        [-47, 33],
        [-412, -19],
        [-93, -15],
        [-111, -50],
        [-110, -24],
        [-169, -50],
        [-71, -30],
        [-185, 117],
        [-60, 86],
        [-25, 5],
        [-44, -20],
        [-2, -61],
        [86, -133],
        [39, -35],
        [0, -35],
        [-25, -18],
        [-35, 0],
        [-53, 26],
        [-99, 21],
        [-88, -41],
        [-108, -87],
        [23, -58],
        [29, -33],
        [-7, -39],
        [-121, -74],
        [-40, -3],
        [-25, -15],
        [30, -27],
        [66, -1],
        [7, -33],
        [-23, -26],
        [-109, -30],
        [7, -40],
        [59, -19],
        [77, 7],
        [48, -29],
        [0, -46],
        [-49, -26],
        [-57, -19],
        [-392, -118],
        [-57, -32],
        [3, -43],
        [137, -11],
        [410, 10],
        [27, -14],
        [-10, -34],
        [-23, -43],
        [23, -31],
        [61, -21],
        [2, -33],
        [-30, -13],
        [-61, -15],
        [-67, -4],
        [4, -35],
        [92, -31],
        [31, -4],
        [3, -118],
        [-3, -50],
        [-49, -24],
        [-18, -17],
        [-2, -40],
        [122, -29],
        [187, -127],
        [41, 0],
        [74, -24],
        [119, -65],
        [42, -39],
        [68, -19],
        [9, -32],
        [42, -27],
        [166, -88],
        [22, -38],
        [-348, -73],
        [-350, -54],
        [32, -48],
        [378, 5],
        [102, -30],
        [45, 9],
        [26, 32],
        [204, 38],
        [207, 25],
        [65, -18],
        [278, -141],
        [129, -53],
        [82, -20],
        [60, -5],
        [44, -21],
        [43, -45],
        [-10, -42],
        [18, -19],
        [29, -8],
        [55, -28],
        [63, 10],
        [73, 32],
        [51, -7],
        [92, -45],
        [-35, -35],
        [-20, -17],
        [-24, -32],
        [-23, -11],
        [-74, -7],
        [-41, 0],
        [-42, 6],
        [-4, -23],
        [51, -25],
        [74, -27],
        [480, -21],
        [137, -46],
        [135, 25],
        [61, -8],
        [51, -15],
        [19, -46],
        [69, -15],
        [106, -36],
        [148, -18],
        [118, 1],
        [145, -49],
        [73, -2],
        [45, -28],
        [330, -17],
        [49, -22],
        [38, -36],
        [78, -16],
        [85, -3],
        [464, -59],
        [174, -31],
        [40, 2],
        [40, -6],
        [125, -26],
        [127, -14],
        [61, -36],
        [-99894, -48],
      ],
      [
        [37438, 6445],
        [36, -2],
        [146, 6],
        [146, -2],
        [89, -7],
        [26, -16],
        [23, -32],
        [26, -50],
        [24, -55],
        [27, -45],
        [16, -79],
        [25, -29],
        [43, -73],
        [6, -59],
        [-13, -128],
        [-21, -52],
        [-56, -50],
        [-64, 5],
        [-29, -2],
        [-28, -10],
        [-11, -7],
        [-4, -10],
        [74, -43],
        [9, -16],
        [1, -19],
        [-10, -13],
        [-10, -7],
        [-1573, -260],
        [-61, -14],
        [-61, -27],
        [-20, -23],
        [-20, -18],
        [-1219, -49],
        [-11, 3],
        [-11, 10],
        [-31, 50],
        [-6, 79],
        [7, 31],
        [61, 30],
        [23, 17],
        [103, 117],
        [53, 55],
        [25, 46],
        [13, -3],
        [47, -27],
        [35, -8],
        [68, 11],
        [67, 33],
        [29, 18],
        [29, -6],
        [5, -28],
        [12, -9],
        [162, 88],
        [147, 98],
        [144, 111],
        [73, 66],
        [18, 19],
        [12, 28],
        [-10, 28],
        [-13, 25],
        [-12, 10],
        [-12, 4],
        [-75, 18],
        [23, 29],
        [22, 35],
        [14, 39],
        [5, 47],
        [-3, 24],
        [3, 18],
        [34, 15],
        [23, 24],
        [16, 28],
        [-26, 9],
        [-12, 21],
        [23, 49],
        [21, 52],
        [21, 28],
        [56, 55],
        [163, 138],
        [59, 74],
        [17, 27],
        [386, 119],
        [63, 12],
        [121, 17],
        [56, 3],
        [158, -12],
        [74, -12],
        [128, -32],
        [189, -61],
        [71, -27],
        [71, -34],
        [68, -43],
        [67, -52],
        [13, -16],
        [6, -29],
        [2, -28],
        [-3, -27],
        [-18, -56],
        [-26, -39],
        [-311, -37],
        [-41, -15],
        [-22, -30],
        [-16, -30],
        [36, -11],
      ],
      [
        [33407, 5562],
        [-11, -124],
        [1, -56],
        [-16, -45],
        [-28, -23],
        [-55, -39],
        [-40, -23],
        [-87, -38],
        [-400, 34],
        [-180, 31],
        [-75, 40],
        [-12, 18],
        [-23, 61],
        [-21, 19],
        [-159, -13],
        [-97, -19],
        [-17, -10],
        [-26, -38],
        [-14, -8],
        [-259, 81],
        [-273, 95],
        [-113, 49],
        [-39, 22],
        [-11, 15],
        [25, 19],
        [26, 12],
        [29, 9],
        [30, 1],
        [22, -7],
        [22, -14],
        [14, -51],
        [14, -8],
        [38, -14],
        [961, 7],
        [80, 2],
        [166, 15],
        [89, 21],
        [33, 29],
        [-80, 7],
        [-32, 22],
        [-27, 41],
        [-6, 38],
        [9, 28],
        [106, 14],
        [16, 10],
        [-28, 16],
        [1, 37],
        [63, 14],
        [25, 31],
        [124, 39],
        [196, -21],
        [47, -56],
        [-13, -36],
        [-9, -37],
        [-1, -57],
        [80, -8],
        [25, -21],
        [24, -26],
        [-29, -1],
        [-28, -6],
        [-23, -27],
        [-20, -35],
        [-14, -16],
      ],
      [
        [30541, 11987],
        [-8, -70],
        [46, 25],
        [17, -5],
        [40, -26],
        [81, -151],
        [18, -48],
        [33, -139],
        [40, -105],
        [100, -183],
        [49, -100],
        [26, -58],
        [3, -78],
        [31, -22],
        [7, -32],
        [10, -107],
        [7, -124],
        [7, -238],
        [-4, -55],
        [-43, -89],
        [-18, -63],
        [-23, -42],
        [-27, -30],
        [-141, -125],
        [-17, -62],
        [-237, -53],
        [-134, -22],
        [-52, 24],
        [-53, 6],
        [-66, -8],
        [-191, -7],
        [-144, -18],
        [-19, 7],
        [-13, 23],
        [-14, 16],
        [-38, -3],
        [-31, 9],
        [-30, 19],
        [-33, 36],
        [-14, 21],
        [-8, 23],
        [64, 60],
        [33, 13],
        [33, 4],
        [67, -13],
        [67, -20],
        [147, -16],
        [204, -4],
        [56, 6],
        [67, 19],
        [62, 55],
        [-30, 21],
        [-31, 13],
        [-30, 3],
        [-30, -4],
        [-84, -34],
        [-65, -20],
        [-65, -12],
        [-69, 20],
        [-64, 56],
        [-2, 18],
        [220, 43],
        [20, 7],
        [40, 27],
        [13, 24],
        [6, 23],
        [-148, 40],
        [-31, -1],
        [-30, -7],
        [-67, 17],
        [-64, 47],
        [-59, 57],
        [-22, 5],
        [-21, -17],
        [-143, -150],
        [-12, -1],
        [-54, 11],
        [-68, 30],
        [-62, 10],
        [-40, -8],
        [-15, -12],
        [39, -34],
        [33, -30],
        [10, -24],
        [-101, -75],
        [-27, -10],
        [-43, 7],
        [-16, 9],
        [-31, 40],
        [-30, 10],
        [-65, -8],
        [-34, 3],
        [-34, 18],
        [-32, 26],
        [-30, 14],
        [-36, 29],
        [-26, 20],
        [-8, 29],
        [3, 28],
        [11, 16],
        [2, 15],
        [-8, 27],
        [5, 19],
        [12, 18],
        [54, 34],
        [65, 7],
        [63, -43],
        [42, -13],
        [19, -1],
        [7, 2],
        [5, 12],
        [-1, 22],
        [-12, 42],
        [-1, 29],
        [14, 24],
        [19, 9],
        [20, 6],
        [13, 3],
        [41, -15],
        [29, -16],
        [59, -46],
        [49, -32],
        [19, -3],
        [14, 11],
        [13, 18],
        [-59, 46],
        [-6, 30],
        [3, 26],
        [36, 15],
        [22, 2],
        [104, -27],
        [56, -9],
        [55, -3],
        [114, 31],
        [-61, 35],
        [-132, 30],
        [-25, 21],
        [-18, 34],
        [97, 31],
        [99, -1],
        [177, -40],
        [59, 19],
        [55, 58],
        [32, 15],
        [125, -5],
        [101, 27],
        [16, -3],
        [15, -8],
        [97, -97],
        [13, 5],
        [10, 19],
        [3, 34],
        [1, 34],
        [-3, 35],
        [-12, 22],
        [-16, -3],
        [-17, -10],
        [-28, 9],
        [-28, 18],
        [-29, 8],
        [-100, 11],
        [-71, 18],
        [-37, 14],
        [-34, 28],
        [-5, 31],
        [36, 71],
        [138, 76],
        [65, 25],
        [66, 6],
        [32, -5],
        [76, -32],
        [12, 2],
        [11, 8],
        [-73, 53],
        [-65, 41],
        [-33, 31],
        [-26, 12],
        [-109, 12],
        [-57, -31],
        [-27, -5],
        [-27, 3],
        [-160, 74],
        [-9, 8],
        [-23, 28],
        [-12, 21],
        [-7, 35],
        [3, 35],
        [5, 23],
        [24, 91],
        [13, 72],
        [-7, 59],
        [-25, 32],
        [-36, 22],
        [-33, 35],
        [-9, 24],
        [-6, 28],
        [-1, 36],
        [9, 32],
        [14, 34],
        [18, 17],
        [34, 17],
        [133, 39],
        [270, 49],
        [30, -25],
        [43, -52],
        [14, -21],
        [15, -104],
        [0, -29],
      ],
      [
        [22752, 10418],
        [-23, -61],
        [2, -60],
        [68, 4],
        [30, 114],
        [64, 21],
        [31, -68],
        [-30, -55],
        [15, -31],
        [18, -22],
        [32, -1],
        [29, 33],
        [13, 24],
        [11, 26],
        [19, 58],
        [61, 54],
        [135, 8],
        [71, -34],
        [-48, -86],
        [-115, -50],
        [-74, -52],
        [25, -14],
        [25, -7],
        [23, 2],
        [65, 27],
        [160, 50],
        [61, 37],
        [22, -6],
        [0, -62],
        [21, -42],
        [-12, -93],
        [-69, -17],
        [-71, -8],
        [18, -41],
        [-4, -17],
        [-6, -13],
        [-178, 17],
        [-31, -6],
        [-31, -12],
        [-31, 3],
        [-62, 30],
        [-32, 0],
        [-64, -15],
        [-65, -6],
        [-93, 1],
        [-68, 5],
        [-64, 33],
        [-67, 9],
        [-75, 1],
        [-79, 38],
        [-66, 15],
        [-95, 39],
        [-25, 15],
        [-25, 8],
        [-45, -3],
        [-346, 59],
        [-51, -1],
        [-33, -8],
        [-33, 4],
        [-67, 28],
        [-14, 31],
        [7, 29],
        [15, 13],
        [30, 13],
        [480, 69],
        [50, 19],
        [37, -2],
        [28, -59],
        [42, -61],
        [14, 1],
        [14, 6],
        [47, 51],
        [86, -16],
        [48, 23],
        [33, 45],
        [97, 52],
        [61, -10],
        [57, -22],
        [27, -54],
      ],
      [
        [16512, 9357],
        [49, -58],
        [19, -38],
        [11, -39],
        [-199, -96],
        [-9, -10],
        [-9, -50],
        [5, -11],
        [8, -8],
        [1, -19],
        [-17, -6],
        [-340, -38],
        [-159, 35],
        [-22, 23],
        [-5, 36],
        [19, 7],
        [35, 5],
        [-9, 16],
        [-22, 29],
        [-2, 24],
        [48, 61],
        [23, 16],
        [-90, 57],
        [-11, 13],
        [-12, 4],
        [-44, -7],
        [-43, 3],
        [15, 23],
        [12, 38],
        [38, 32],
        [28, 5],
        [28, -3],
        [132, -2],
        [130, -17],
        [131, -12],
        [215, -11],
        [46, -2],
      ],
      [
        [14908, 9627],
        [74, -16],
        [25, -25],
        [33, -18],
        [33, -10],
        [31, -27],
        [18, -48],
        [16, -15],
        [49, -32],
        [17, -28],
        [-3, -14],
        [-93, -11],
        [-31, 4],
        [-29, -9],
        [-9, -17],
        [1, -18],
        [16, -13],
        [34, -12],
        [34, 2],
        [63, 14],
        [28, -3],
        [32, -15],
        [32, -2],
        [84, 46],
        [21, 8],
        [21, -2],
        [115, -54],
        [24, -27],
        [-17, -15],
        [-14, -22],
        [6, -15],
        [55, -22],
        [23, -28],
        [14, -11],
        [-3, -24],
        [-8, -29],
        [1, -33],
        [-28, -18],
        [-13, 0],
        [-60, 18],
        [-187, 10],
        [-60, 15],
        [-92, 65],
        [-36, 4],
        [-37, 16],
        [-57, 46],
        [-99, 37],
        [-63, 45],
        [2, 38],
        [-9, 27],
        [-12, 11],
        [-12, 6],
        [-36, 10],
        [-35, -3],
        [-18, -11],
        [-29, -28],
        [-32, -5],
        [-25, 6],
        [-5, 6],
        [-1, 74],
        [-27, 10],
        [-23, 30],
        [-4, 40],
        [10, 37],
        [35, 45],
        [40, 5],
        [40, -7],
        [41, 10],
        [65, 6],
        [74, -4],
      ],
      [
        [5819, 5871],
        [-347, -22],
        [-141, 19],
        [-294, 63],
        [-403, 114],
        [-110, 36],
        [-72, 31],
        [-70, 39],
        [-16, 43],
        [10, 62],
        [13, 51],
        [21, 32],
        [86, 38],
        [43, 42],
        [87, 44],
        [25, 33],
        [37, 2],
        [70, -4],
        [69, -9],
        [65, -11],
        [63, -19],
        [144, -62],
        [100, -61],
        [144, -72],
        [143, -81],
        [80, -30],
        [77, -45],
        [74, -61],
        [14, -21],
        [31, -27],
        [19, -26],
        [19, -22],
        [19, -11],
        [15, -24],
        [-3, -26],
        [-12, -15],
      ],
      [
        [96411, 7303],
        [105, -42],
        [275, 4],
        [229, -39],
        [21, -40],
        [-65, -21],
        [-101, -53],
        [-65, -17],
        [-55, 0],
        [-112, 22],
        [-146, -3],
        [-31, -31],
        [-71, -31],
        [-82, -54],
        [-22, 44],
        [-33, 42],
        [-82, 89],
        [-6, 13],
        [45, 17],
        [22, 29],
        [47, 39],
        [-5, 24],
        [-39, 26],
        [-15, 22],
        [26, 36],
        [58, 16],
        [75, -15],
        [34, -48],
        [-6, -21],
        [-1, -8],
      ],
      [
        [41355, 5876],
        [38, -12],
        [40, 28],
        [-6, 26],
        [23, 45],
        [33, -49],
        [219, -52],
        [71, -50],
        [-29, -12],
        [-22, 2],
        [-64, -5],
        [-109, -43],
        [-117, 41],
        [-209, 29],
        [-63, 22],
        [-49, 68],
        [88, 56],
        [21, -6],
        [135, -88],
      ],
      [
        [40573, 6151],
        [-32, -21],
        [-675, 36],
        [-33, 7],
        [9, 43],
        [92, 7],
        [52, 8],
        [72, 19],
        [53, 33],
        [18, 1],
        [317, -77],
        [111, -32],
        [13, -15],
        [3, -9],
      ],
      [
        [30462, 5944],
        [-60, -2],
        [-120, 5],
        [-120, 20],
        [-31, 10],
        [-44, 32],
        [-14, 17],
        [-12, 21],
        [-1, 33],
        [32, 114],
        [59, 68],
        [56, 39],
        [174, 92],
        [23, 10],
        [159, 43],
        [62, 23],
        [97, 48],
        [534, 186],
        [122, 27],
        [55, -20],
        [31, -19],
        [-16, -22],
        [-72, -53],
        [-34, -32],
        [-87, -65],
        [-188, -109],
        [-133, -82],
        [-171, -113],
        [-40, -40],
        [-81, -95],
        [15, -42],
        [-27, -58],
        [-107, -28],
        [-61, -8],
      ],
      [
        [33931, 14945],
        [11, -8],
        [9, 4],
        [9, 8],
        [9, 19],
        [33, 27],
        [31, 3],
        [-10, -28],
        [74, -50],
        [-6, -39],
        [14, -32],
        [-30, -10],
        [-24, -33],
        [21, -13],
        [12, -28],
        [-25, -7],
        [-54, 17],
        [-28, -4],
        [3, 26],
        [-9, 10],
        [-33, -5],
        [-14, -57],
        [-10, -5],
        [-12, 9],
        [9, 37],
        [-14, 6],
        [-14, -1],
        [-42, -27],
        [-12, -1],
        [-25, 32],
        [79, 42],
        [-33, 21],
        [-7, 26],
        [5, 36],
        [-29, -5],
        [-28, -14],
        [-13, -2],
        [-11, 12],
        [4, 26],
        [23, 43],
        [18, 45],
        [36, 22],
        [21, 17],
        [28, 8],
        [12, 16],
        [26, 1],
        [15, -37],
        [-1, -22],
        [-12, -24],
        [-6, -61],
      ],
      [
        [34575, 15452],
        [17, -15],
        [70, 1],
        [16, -4],
        [14, -25],
        [9, -43],
        [-23, -17],
        [-121, 10],
        [-44, 22],
        [-22, -1],
        [-49, -24],
        [-21, -24],
        [-82, -32],
        [-23, 12],
        [-11, 34],
        [-1, 14],
        [8, 9],
        [4, 9],
        [-1, 11],
        [22, 29],
        [95, 44],
        [126, 16],
        [17, -16],
        [0, -10],
      ],
      [
        [33895, 16179],
        [35, -16],
        [31, 11],
        [17, -12],
        [12, -33],
        [-1, -12],
        [-47, 5],
        [-43, -38],
        [-51, 8],
        [-7, -31],
        [11, -17],
        [-14, -14],
        [-44, 30],
        [-35, -11],
        [-11, -51],
        [-16, -10],
        [-8, -2],
        [-14, 13],
        [-29, 4],
        [-2, 7],
        [-18, 20],
        [-51, -22],
        [13, 26],
        [68, 69],
        [8, 21],
        [79, 40],
        [37, -9],
        [80, 24],
      ],
      [
        [32450, 14706],
        [-27, -60],
        [40, 1],
        [28, 21],
        [29, 9],
        [25, -30],
        [-52, -23],
        [-50, -40],
        [-20, -21],
        [-22, -9],
        [-28, 3],
        [-28, -4],
        [-25, -39],
        [-26, -18],
        [-8, 15],
        [-10, 9],
        [-56, 14],
        [-26, 20],
        [-23, 14],
        [-25, 7],
        [13, 35],
        [15, 31],
        [85, 42],
        [-8, 13],
        [-6, 17],
        [67, 21],
        [2, 21],
        [-4, 24],
        [21, 16],
        [20, 24],
        [14, 7],
        [42, -3],
        [29, -33],
        [-12, -33],
        [26, -51],
      ],
      [
        [32687, 14732],
        [-20, -23],
        [-16, -4],
        [-15, 10],
        [-20, -35],
        [-40, 11],
        [-16, 9],
        [10, 5],
        [7, 15],
        [22, 31],
        [38, 79],
        [7, 25],
        [-31, 41],
        [-5, 13],
        [7, 24],
        [11, 17],
        [26, 19],
        [34, 0],
        [17, -16],
        [0, -29],
        [58, -27],
        [-10, -56],
        [-22, -35],
        [-3, -42],
        [-33, -19],
        [-6, -13],
      ],
      [
        [31114, 12975],
        [-29, -37],
        [-23, -11],
        [-21, 10],
        [-21, 5],
        [-15, -14],
        [-16, -58],
        [-19, -29],
        [-20, -15],
        [-12, 6],
        [-12, 0],
        [-19, -13],
        [-24, -5],
        [-23, 6],
        [-22, 37],
        [-32, 44],
        [-6, 14],
        [-5, 36],
        [1, 37],
        [14, 28],
        [73, 98],
        [24, 44],
        [21, 50],
        [23, 45],
        [44, 80],
        [22, 29],
        [111, 84],
        [30, 19],
        [33, -5],
        [8, -44],
        [-16, -22],
        [-53, -56],
        [-11, -79],
        [1, -29],
        [5, -8],
        [20, -10],
        [14, -11],
        [18, -24],
        [21, -14],
        [-45, -41],
        [-30, -21],
        [-21, -26],
        [-40, -25],
        [-17, -16],
        [26, -6],
        [38, -21],
        [10, -18],
        [-5, -14],
      ],
      [
        [29526, 11154],
        [43, -51],
        [-40, -40],
        [-142, -75],
        [-83, -29],
        [-84, -22],
        [-380, -69],
        [-27, 0],
        [-26, 9],
        [-15, 15],
        [-25, 57],
        [3, 29],
        [34, 27],
        [35, 18],
        [60, 16],
        [229, 36],
        [23, 12],
        [19, 27],
        [6, 31],
        [8, 24],
        [15, 11],
        [16, 0],
        [30, -23],
        [55, -93],
        [18, 13],
        [16, 25],
        [3, 80],
        [16, 6],
        [49, -22],
        [30, -23],
        [1, 45],
        [21, 14],
        [22, -5],
        [22, -10],
        [48, -33],
      ],
      [
        [29170, 11677],
        [49, -14],
        [72, -63],
        [24, -32],
        [7, -19],
        [-6, -13],
        [-33, -15],
        [-25, -77],
        [-50, -27],
        [-116, 17],
        [-128, 31],
        [-10, 6],
        [-11, 27],
        [-2, 31],
        [14, 39],
        [22, 20],
        [95, 24],
        [7, 14],
        [14, 39],
        [24, 8],
        [11, -3],
        [42, 7],
      ],
      [
        [29346, 9735],
        [-40, -75],
        [-7, -8],
        [-40, -19],
        [14, -20],
        [11, -10],
        [7, -24],
        [23, -33],
        [28, -21],
        [-23, -59],
        [-34, -26],
        [-369, 160],
        [-28, 26],
        [-14, 19],
        [-11, 30],
        [-1, 30],
        [9, 24],
        [13, 14],
        [33, 17],
        [34, 1],
        [75, -32],
        [10, 5],
        [14, 28],
        [40, 1],
        [9, 24],
        [-55, 8],
        [-44, 24],
        [-29, 24],
        [-8, 19],
        [99, 33],
        [251, -42],
        [38, -14],
        [17, -19],
        [14, -26],
        [-36, -59],
      ],
      [
        [24677, 9687],
        [-51, -14],
        [-46, 6],
        [17, 132],
        [26, 33],
        [-7, 24],
        [-47, 66],
        [-33, 75],
        [16, 17],
        [86, 27],
        [99, -5],
        [39, -32],
        [12, -40],
        [-5, -29],
        [-32, -53],
        [33, -18],
        [7, -36],
        [-7, -44],
        [-32, -52],
        [-30, -31],
        [-45, -26],
      ],
      [
        [96566, 6830],
        [-34, -43],
        [-39, -19],
        [-123, 16],
        [-86, -36],
        [-95, -13],
        [-45, 19],
        [-20, 35],
        [-10, 47],
        [0, 17],
        [27, 7],
        [127, -33],
        [53, -29],
        [29, 1],
        [76, 38],
        [63, 48],
        [16, 23],
        [21, 8],
        [27, -17],
        [13, -52],
        [0, -17],
      ],
      [
        [78050, 14010],
        [-121, -14],
        [-10, 15],
        [-45, 2],
        [-16, 12],
        [-6, 28],
        [15, 47],
        [24, 32],
        [37, 33],
        [18, 7],
        [76, 11],
        [55, -15],
        [39, -40],
        [11, -32],
        [-6, -21],
        [-71, -65],
      ],
      [
        [57460, 11301],
        [-18, -22],
        [-51, 4],
        [-39, -21],
        [-31, 8],
        [-98, 35],
        [-11, 45],
        [-3, 20],
        [8, 35],
        [88, 73],
        [35, 7],
        [50, -10],
        [22, -21],
        [14, -41],
        [39, -83],
        [-5, -29],
      ],
      [
        [48362, 11202],
        [-73, -34],
        [-6, 15],
        [-24, 20],
        [-48, 56],
        [54, 4],
        [49, 24],
        [27, -10],
        [6, -6],
        [15, -69],
      ],
      [
        [49088, 11213],
        [-45, -1],
        [-13, 16],
        [-2, 11],
        [58, 83],
        [32, 21],
        [62, 16],
        [40, -5],
        [26, -18],
        [8, -33],
        [0, -49],
        [-15, -26],
        [-151, -15],
      ],
      [
        [31618, 5715],
        [-26, -2],
        [-14, 4],
        [-14, 12],
        [-12, 46],
        [-137, 37],
        [-16, 21],
        [-9, 46],
        [-23, 18],
        [-178, 82],
        [-15, 17],
        [-10, 25],
        [33, 10],
        [70, -18],
        [127, -5],
        [28, -8],
        [26, -14],
        [142, -3],
        [72, -7],
        [40, -65],
        [81, -19],
        [11, -38],
        [10, -68],
        [-110, -50],
        [-25, -7],
        [-51, -14],
      ],
      [
        [31316, 6075],
        [-48, -28],
        [-202, 13],
        [-69, 9],
        [-39, 15],
        [36, 60],
        [27, 20],
        [25, 8],
        [56, 33],
        [88, 8],
        [67, -6],
        [113, -26],
        [-29, -25],
        [-19, -9],
        [-18, -38],
        [12, -34],
      ],
      [
        [34676, 16577],
        [-37, -16],
        [-13, 22],
        [-7, 37],
        [-19, 23],
        [14, 20],
        [199, -26],
        [-11, -13],
        [-96, -17],
        [-30, -30],
      ],
      [
        [34480, 15244],
        [-24, -26],
        [-61, 39],
        [-16, 25],
        [7, 19],
        [98, 17],
        [26, -9],
        [12, -40],
        [-42, -25],
      ],
      [
        [34100, 14650],
        [-24, -2],
        [-30, 17],
        [-4, 30],
        [0, 16],
        [23, 13],
        [14, 2],
        [81, 47],
        [36, 12],
        [-16, -29],
        [2, -26],
        [-13, -23],
        [-69, -57],
      ],
      [
        [34427, 15507],
        [-55, -54],
        [-26, 2],
        [-54, 41],
        [-15, 21],
        [-3, 9],
        [34, 37],
        [97, -14],
        [22, -4],
        [1, -3],
        [2, -21],
        [-3, -14],
      ],
      [
        [34062, 15087],
        [4, -10],
        [56, 6],
        [15, -15],
        [-31, -20],
        [-8, 4],
        [-27, -6],
        [-76, 15],
        [-18, 23],
        [67, 13],
        [18, -10],
      ],
      [
        [32778, 11680],
        [-24, -4],
        [-24, 53],
        [-13, 82],
        [-77, 119],
        [-20, 62],
        [14, 15],
        [21, 5],
        [56, -17],
        [34, -23],
        [38, -49],
        [46, -44],
        [9, -37],
        [-7, -42],
        [-29, -11],
        [1, -31],
        [-17, -60],
        [-8, -18],
      ],
      [
        [33159, 15806],
        [14, -7],
        [121, 13],
        [38, -40],
        [43, 2],
        [-103, -75],
        [-28, 22],
        [-9, 16],
        [-7, 36],
        [-67, -9],
        [-22, 7],
        [-27, -24],
        [-55, -10],
        [-19, 0],
        [-24, 26],
        [-1, 26],
        [49, -1],
        [38, 33],
        [11, 34],
        [19, -9],
        [29, -40],
      ],
      [
        [30004, 11694],
        [-60, -24],
        [-40, 19],
        [-120, 36],
        [-50, 66],
        [5, 35],
        [23, 21],
        [36, 11],
        [73, -22],
        [37, -23],
        [96, -119],
      ],
      [
        [23603, 9985],
        [-53, -3],
        [-15, 13],
        [16, 28],
        [128, 47],
        [52, 28],
        [8, -4],
        [7, -9],
        [22, -56],
        [2, -14],
        [-167, -30],
      ],
      [
        [20961, 9696],
        [-33, -26],
        [-62, 6],
        [-48, 43],
        [-19, 58],
        [-2, 20],
        [13, 15],
        [31, 14],
        [120, -130],
      ],
      [
        [16792, 9152],
        [-58, -6],
        [-18, 1],
        [-18, 13],
        [-5, 9],
        [30, 30],
        [29, 13],
        [9, 10],
        [-40, 101],
        [37, 3],
        [43, 20],
        [83, -2],
        [72, -18],
        [13, -15],
        [9, -25],
        [-31, -51],
        [-19, -18],
        [-108, -45],
        [-28, -20],
      ],
      [
        [14620, 8857],
        [-42, -10],
        [-83, 38],
        [-27, 18],
        [-24, 31],
        [-19, 7],
        [-7, 6],
        [-11, 80],
        [25, 9],
        [53, -11],
        [102, -42],
        [71, -12],
        [24, -32],
        [-24, -56],
        [-38, -26],
      ],
      [
        [5002, 3963],
        [-87, -13],
        [-206, 26],
        [-54, 21],
        [-30, 22],
        [-56, 20],
        [-14, 11],
        [0, 24],
        [-9, 14],
        [-19, 13],
        [-9, 13],
        [-17, 8],
        [277, -12],
        [108, -20],
        [20, -14],
        [195, -60],
        [-53, -9],
        [-46, -44],
      ],
      [
        [5426, 4844],
        [-29, -5],
        [-745, 67],
        [-143, 22],
        [-34, 12],
        [-14, 11],
        [-3, 8],
        [6, 22],
        [17, 16],
        [185, 25],
        [207, -19],
        [250, -48],
        [172, -38],
        [89, -34],
        [37, -26],
        [5, -13],
      ],
      [
        [95548, 8736],
        [-37, 0],
        [-22, 18],
        [-7, 39],
        [1, 13],
        [72, 48],
        [58, 12],
        [-31, -72],
        [-11, -12],
        [-23, -46],
      ],
      [
        [95268, 8313],
        [-50, -74],
        [-35, 2],
        [-20, 14],
        [36, 41],
        [34, 18],
        [21, 5],
        [14, -6],
      ],
      [
        [97178, 9444],
        [-38, -12],
        [-51, 37],
        [-12, 12],
        [50, 70],
        [-4, 23],
        [7, 19],
        [19, 14],
        [12, -2],
        [29, -77],
        [20, -32],
        [-28, -31],
        [-4, -21],
      ],
      [
        [95786, 12937],
        [-24, -16],
        [-15, 5],
        [-15, 34],
        [16, 53],
        [-6, 69],
        [3, 17],
        [39, -39],
        [7, -21],
        [16, -32],
        [3, -17],
        [-16, -32],
        [-8, -21],
      ],
      [
        [95361, 13351],
        [-5, -35],
        [-14, 8],
        [-20, 28],
        [-20, 69],
        [18, 7],
        [23, -12],
        [9, -34],
        [8, -18],
        [1, -13],
      ],
      [
        [95169, 13549],
        [-15, -27],
        [-13, 3],
        [-58, 69],
        [7, 30],
        [-8, 25],
        [1, 23],
        [2, 8],
        [71, -105],
        [13, -26],
      ],
      [
        [76836, 13804],
        [32, -15],
        [57, 2],
        [20, -22],
        [4, -25],
        [-1, -14],
        [-23, -21],
        [-150, -14],
        [-24, 22],
        [26, 61],
        [28, 20],
        [31, 6],
      ],
      [
        [77851, 13700],
        [-37, -8],
        [-14, 15],
        [-2, 9],
        [27, 33],
        [33, 11],
        [-3, -39],
        [-4, -21],
      ],
      [
        [77456, 13554],
        [-26, -7],
        [-27, 16],
        [-13, 31],
        [-3, 10],
        [42, 8],
        [56, -30],
        [-29, -28],
      ],
      [
        [78721, 14144],
        [-17, -14],
        [-45, 8],
        [-10, 12],
        [-4, 55],
        [-3, 16],
        [-17, 15],
        [-73, 28],
        [-8, 40],
        [10, 18],
        [27, 4],
        [67, -35],
        [15, -27],
        [-2, -41],
        [1, -13],
        [21, -27],
        [33, -28],
        [5, -11],
      ],
      [
        [75722, 13935],
        [-37, -8],
        [-38, 8],
        [-19, 27],
        [-4, 12],
        [15, 19],
        [54, 3],
        [38, -17],
        [8, -17],
        [2, -8],
        [-19, -19],
      ],
      [
        [73839, 13275],
        [-48, -15],
        [-8, 8],
        [-1, 9],
        [-72, 55],
        [-12, 45],
        [7, 31],
        [59, -3],
        [70, -27],
        [37, -69],
        [-32, -34],
      ],
      [
        [74039, 13382],
        [-32, -14],
        [-25, 3],
        [-29, 31],
        [12, 21],
        [30, 13],
        [38, -7],
        [10, -11],
        [26, -7],
        [-30, -29],
      ],
      [
        [73702, 13472],
        [-30, -18],
        [-24, 3],
        [-16, 19],
        [-3, 12],
        [15, 38],
        [12, -2],
        [8, -20],
        [38, -32],
      ],
      [
        [69421, 10415],
        [-35, -74],
        [-13, 2],
        [-15, 43],
        [13, 27],
        [16, 16],
        [28, -8],
        [6, -6],
      ],
      [
        [69016, 10195],
        [-14, 0],
        [7, 23],
        [37, 41],
        [28, 50],
        [17, 8],
        [31, -44],
        [-7, -37],
        [-41, -27],
        [-58, -14],
      ],
      [
        [70000, 11156],
        [-20, 0],
        [-25, 6],
        [-32, 42],
        [-18, 30],
        [-7, 31],
        [3, 61],
        [16, 30],
        [26, 12],
        [11, -28],
        [4, -32],
        [8, -21],
        [33, -30],
        [16, -25],
        [5, -14],
        [6, -29],
        [-5, -20],
        [-21, -13],
      ],
      [
        [63484, 13373],
        [-46, -14],
        [-21, 6],
        [-2, 14],
        [-1, 13],
        [2, 15],
        [16, 12],
        [78, 1],
        [31, -10],
        [9, -7],
        [1, -21],
        [-3, -6],
        [-64, -3],
      ],
      [
        [54506, 11516],
        [-18, -37],
        [-87, 51],
        [-51, 16],
        [-13, 9],
        [-12, 32],
        [-3, 12],
        [10, 20],
        [28, 32],
        [59, 26],
        [93, 13],
        [91, -10],
        [15, -16],
        [-86, -54],
        [-26, -94],
      ],
      [
        [50843, 11176],
        [-94, -15],
        [-21, 17],
        [-11, 34],
        [13, 20],
        [123, 68],
        [33, -6],
        [11, -6],
        [8, -26],
        [-11, -41],
        [-14, -20],
        [-37, -25],
      ],
      [
        [49179, 10821],
        [-30, -13],
        [-39, 3],
        [-30, 17],
        [-21, 34],
        [-5, 13],
        [3, 22],
        [-2, 11],
        [38, 6],
        [14, -14],
        [6, -11],
        [66, -68],
      ],
      [
        [50360, 11373],
        [-24, -73],
        [-15, 2],
        [-15, 43],
        [-31, 46],
        [-11, 32],
        [-1, 43],
        [22, 25],
        [80, 16],
        [27, -10],
        [13, -55],
        [-45, -69],
      ],
      [
        [49296, 11078],
        [30, -19],
        [47, 3],
        [45, -15],
        [-7, -20],
        [-26, -26],
        [-22, -56],
        [-22, -26],
        [-66, -56],
        [-49, -15],
        [-11, 31],
        [1, 32],
        [4, 25],
        [2, 18],
        [-46, 24],
        [-3, 33],
        [-9, 19],
        [-134, 66],
        [-23, 19],
        [10, 12],
        [138, 5],
        [81, -12],
        [60, -42],
      ],
      [
        [51257, 11244],
        [-45, -13],
        [-51, 29],
        [-14, 20],
        [-15, 53],
        [-2, 20],
        [12, 13],
        [40, 15],
        [66, -6],
        [25, -24],
        [9, -43],
        [-8, -37],
        [-17, -27],
      ],
      [
        [44275, 9281],
        [-13, -125],
        [4, -27],
        [11, -26],
        [49, -70],
        [4, -52],
        [-2, -20],
        [-20, -29],
        [-69, 7],
        [-22, 15],
        [-8, 10],
        [-36, 122],
        [-21, 29],
        [-32, 25],
        [-123, 23],
        [-118, -8],
        [29, 28],
        [178, 39],
        [45, 29],
        [28, 34],
        [13, 52],
        [31, 65],
        [49, 30],
        [31, 3],
        [16, -53],
        [0, -50],
        [-24, -51],
      ],
      [
        [45526, 9977],
        [-19, -14],
        [-40, 1],
        [-38, 28],
        [-16, 41],
        [-1, 29],
        [17, 33],
        [27, 9],
        [15, -11],
        [36, -71],
        [19, -45],
      ],
      [
        [46525, 10268],
        [-22, -13],
        [-37, 5],
        [-47, 29],
        [-15, 22],
        [-5, 20],
        [13, 29],
        [11, 8],
        [24, -4],
        [42, -37],
        [29, -40],
        [7, -19],
      ],
      [
        [41016, 5948],
        [-48, -6],
        [-19, 15],
        [23, 38],
        [34, 33],
        [63, 3],
        [60, -22],
        [-6, -21],
        [-13, -2],
        [-94, -38],
      ],
      [
        [34980, 16532],
        [-12, -5],
        [-19, 22],
        [-3, 13],
        [20, 26],
        [20, 35],
        [7, 4],
        [-5, -70],
        [-8, -25],
      ],
      [
        [33656, 15954],
        [-62, -26],
        [-32, 27],
        [-7, 10],
        [38, 26],
        [20, -6],
        [8, -9],
        [23, -2],
        [12, -20],
      ],
      [
        [33011, 11534],
        [-42, -1],
        [-19, 16],
        [-7, 10],
        [5, 23],
        [16, 21],
        [49, -16],
        [12, -41],
        [-14, -12],
      ],
      [
        [33151, 12230],
        [-11, -16],
        [-35, 10],
        [-20, 11],
        [-34, 28],
        [19, 17],
        [37, -4],
        [30, -20],
        [14, -26],
      ],
      [
        [33127, 11111],
        [-23, 0],
        [-20, 12],
        [-17, 35],
        [-5, 17],
        [9, 38],
        [16, 9],
        [92, 5],
        [28, -20],
        [1, -34],
        [-10, -25],
        [-71, -37],
      ],
      [
        [33180, 10914],
        [-28, -4],
        [-38, 10],
        [-33, 20],
        [-11, 23],
        [16, 19],
        [30, 12],
        [47, -4],
        [22, -24],
        [5, -22],
        [-6, -23],
        [-4, -7],
      ],
      [
        [33503, 15872],
        [-38, -4],
        [-26, 33],
        [-12, 23],
        [51, 2],
        [23, -9],
        [12, -26],
        [-10, -19],
      ],
      [
        [33193, 15571],
        [-14, -6],
        [-18, 5],
        [0, -10],
        [15, -13],
        [-16, -5],
        [-19, 13],
        [-14, 27],
        [10, 25],
        [19, 6],
        [37, -42],
      ],
      [
        [33152, 15053],
        [-35, -20],
        [-21, 6],
        [-33, 24],
        [45, 7],
        [4, 70],
        [22, 27],
        [43, -15],
        [-26, -37],
        [-9, -28],
        [9, -24],
        [1, -10],
      ],
      [
        [32791, 14932],
        [-26, -2],
        [7, 31],
        [23, 21],
        [38, 14],
        [-24, -35],
        [-7, -16],
        [-11, -13],
      ],
      [
        [32412, 14480],
        [-44, -26],
        [-23, 0],
        [27, 64],
        [26, 2],
        [41, 36],
        [11, -5],
        [-22, -30],
        [-16, -41],
      ],
      [
        [32607, 15512],
        [-12, -2],
        [5, 24],
        [31, 62],
        [58, 29],
        [-7, -25],
        [-19, -31],
        [-56, -57],
      ],
      [
        [31709, 13915],
        [-60, -22],
        [-31, 8],
        [-2, 23],
        [8, 31],
        [29, 16],
        [-5, 46],
        [18, 19],
        [9, 35],
        [38, 26],
        [54, -12],
        [-8, -45],
        [-1, -16],
        [-32, -12],
        [-8, -7],
        [-7, -30],
        [1, -43],
        [-3, -17],
      ],
      [
        [31501, 13709],
        [-62, -65],
        [-9, 4],
        [-5, 7],
        [1, 11],
        [20, 24],
        [4, 70],
        [41, 26],
        [16, -9],
        [-14, -30],
        [9, -26],
        [-1, -12],
      ],
      [
        [31288, 13309],
        [-13, -4],
        [-31, 2],
        [-20, 13],
        [26, 41],
        [-3, 28],
        [23, 11],
        [26, -10],
        [18, -35],
        [3, -15],
        [-29, -31],
      ],
      [
        [31292, 12807],
        [-55, -11],
        [-41, 12],
        [1, 44],
        [-11, 5],
        [-4, 10],
        [52, 33],
        [39, 8],
        [47, -5],
        [20, -14],
        [7, -15],
        [-36, -36],
        [-6, -14],
        [-13, -17],
      ],
      [
        [30084, 11367],
        [14, -17],
        [60, 16],
        [21, -17],
        [4, -12],
        [-27, -42],
        [-32, -28],
        [-37, -2],
        [-27, 71],
        [-3, 18],
        [27, 13],
      ],
      [
        [29478, 9586],
        [-27, -11],
        [-17, 6],
        [-31, 29],
        [4, 23],
        [14, 15],
        [10, 17],
        [60, 62],
        [44, 7],
        [36, -13],
        [-39, -59],
        [-10, -41],
        [-44, -35],
      ],
      [
        [23945, 9838],
        [-47, 0],
        [-31, 21],
        [-10, 14],
        [19, 21],
        [11, 2],
        [57, -36],
        [12, -15],
        [-11, -7],
      ],
      [
        [17572, 9121],
        [-136, -16],
        [-37, 18],
        [-10, 22],
        [6, 23],
        [277, 125],
        [49, -17],
        [14, -8],
        [-83, -62],
        [-37, -22],
        [-6, -7],
        [20, -9],
        [6, -7],
        [-16, -17],
        [-47, -23],
      ],
      [
        [13225, 8961],
        [-44, -32],
        [-86, 21],
        [7, 23],
        [78, 20],
        [53, -13],
        [-8, -19],
      ],
      [
        [13592, 8880],
        [-31, -12],
        [-116, 29],
        [-68, 6],
        [-31, 17],
        [-20, 15],
        [-6, 14],
        [-32, 22],
        [62, 44],
        [49, 14],
        [47, -3],
        [10, -22],
        [90, -26],
        [70, -1],
        [7, -24],
        [-3, -34],
        [-28, -39],
      ],
      [
        [9003, 7690],
        [3, -8],
        [-42, 6],
        [-63, 32],
        [-12, 11],
        [28, 11],
        [36, -10],
        [33, -20],
        [17, -22],
      ],
      [
        [9656, 8230],
        [-31, -3],
        [-53, 14],
        [-140, 46],
        [-30, 23],
        [21, 23],
        [50, 16],
        [38, -5],
        [95, -43],
        [29, -31],
        [17, -23],
        [4, -17],
      ],
      [
        [9276, 7510],
        [-104, -25],
        [-27, 7],
        [9, 36],
        [-16, 25],
        [-4, 12],
        [9, 17],
        [61, 0],
        [172, -27],
        [23, -37],
        [-123, -8],
      ],
      [
        [8723, 7484],
        [-116, -7],
        [-64, 15],
        [-16, 44],
        [18, 9],
        [148, -20],
        [54, -11],
        [20, -13],
        [-10, -12],
        [-34, -5],
      ],
      [
        [8518, 7651],
        [113, -8],
        [74, 6],
        [77, -14],
        [18, -16],
        [-14, -13],
        [-83, -4],
        [-40, -22],
        [-47, -3],
        [-71, 16],
        [-64, 35],
        [37, 23],
      ],
      [
        [8550, 7294],
        [81, -29],
        [-142, 9],
        [-62, 41],
        [40, 15],
        [40, -3],
        [35, -21],
        [8, -12],
      ],
      [
        [8269, 7617],
        [-118, -8],
        [-48, 16],
        [-12, 15],
        [10, 13],
        [183, 13],
        [20, -16],
        [6, -10],
        [-41, -23],
      ],
      [
        [9253, 7922],
        [-57, -8],
        [-71, 36],
        [-54, 29],
        [-18, 25],
        [-3, 8],
        [0, 10],
        [16, 6],
        [115, -21],
        [72, -85],
      ],
      [
        [9225, 7699],
        [-33, -46],
        [-87, 24],
        [-37, 30],
        [21, 39],
        [40, 12],
        [52, -14],
        [19, -5],
        [25, -40],
      ],
      [
        [8223, 7275],
        [-22, -2],
        [-241, 44],
        [-47, 13],
        [82, 27],
        [54, 4],
        [146, -66],
        [39, -8],
        [-11, -12],
      ],
      [
        [7241, 5741],
        [-51, -2],
        [-65, 6],
        [-50, 14],
        [-114, 20],
        [-29, 38],
        [-134, 31],
        [-62, 10],
        [21, 37],
        [142, -49],
        [175, -49],
        [141, -30],
        [26, -26],
      ],
      [
        [8547, 7418],
        [-17, -9],
        [-121, 5],
        [-25, 8],
        [-11, 9],
        [-168, 13],
        [-76, 41],
        [-15, 13],
        [30, 20],
        [57, 12],
        [23, 16],
        [145, 14],
        [23, -8],
        [13, -22],
        [66, -43],
        [17, -27],
        [7, -18],
        [36, -8],
        [16, -16],
      ],
      [
        [6115, 4547],
        [-25, -4],
        [-22, 31],
        [-108, 63],
        [-64, 42],
        [-42, 33],
        [-18, 23],
        [21, 0],
        [158, -70],
        [24, -27],
        [118, -48],
        [-42, -43],
      ],
      [
        [32497, 62251],
        [-1, -14],
        [-3, -15],
        [-18, 13],
        [-10, 13],
        [0, 3],
      ],
    ],
    bbox: [-180, -89.999, 180, 83.599609375],
    transform: {
      scale: [0.0036000360003600037, 0.001736003453784538],
      translate: [-180, -89.999],
    },
  },
]
