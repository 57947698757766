import React, { useState } from 'react'

import TabChapter from '../../component/tools/administration/TabChapter'
import TabProduct from '../../component/tools/administration/TabProduct'
import TabSubChapter from '../../component/tools/administration/TabSubChapter'

const Products = () => {
  // const [show, setShow] = useState(false)
  // const [showError, setShowError] = useState(false)
  // const [message, setMessage] = useState('')
  // const [titleMessage, settitleMessage] = useState('')
  // const [messageError, setMessageError] = useState('')
  // console.log('authcontext data chapter : ', dataChapter)
  // const [itemSelectedChapter, setItemSelectedChapter] = useState(null)
  // const [titleModalchapter, settitleModalchapter] = useState('')
  // const [chapters, setChapters] = useState([])
  // const [refreshChapter, setRefreshChapter] = useState(true)
  // const [refreshSubChapter, setRefreshSubChapter] = useState(true)
  // const [pending, setPending] = useState(true)
  const [seletedTab, setSelectedTab] = useState('tabs-chapter')

  // // Chapter Colmuns
  // const [columnsChapter, setColumnsChapter] = useState([
  //   {
  //     name: 'Id',
  //     selector: (row) => row.id,
  //     width: '50px',
  //     // sortable: true,
  //   },
  //   {
  //     name: 'Nom',
  //     selector: (row) => row.name,
  //     wrap: true,
  //     // sortable: true,
  //   },
  //   {
  //     name: 'Nom douanier',
  //     selector: (row) => row.name_douanier,
  //     wrap: true,
  //     // sortable: true,
  //   },
  //   {
  //     name: 'Actions',
  //     button: true,
  //     cell: (row) => (
  //       // <button
  //       //   className='btn btn-outline btn-xs'
  //       //   onClick={(e) => handleButtonClick(e, row.id)}
  //       // >
  //       //   Edit
  //       // </button>
  //       <div className='dropdown '>
  //         <a
  //           href='#'
  //           className='btn-action dropdown-toggle '
  //           data-bs-toggle='dropdown'
  //           aria-haspopup='true'
  //           aria-expanded='false'
  //         >
  //           <IconDotsVertical size={24} className='icon' />
  //         </a>
  //         <div className='dropdown-menu dropdown-menu-end'>
  //           <a
  //             className='dropdown-item btn'
  //             data-bs-toggle='modal'
  //             data-bs-target='#add-chapter'
  //             onClick={(e) => handleUpdateChapter(row.id)}
  //           >
  //             <IconRefresh size={24} className='icon' />
  //             Modifier
  //           </a>
  //         </div>
  //       </div>
  //     ),
  //   },
  // ])

  // // Chapter Datatable
  // const [dataTableChapter, setDataTableChapter] = useState([
  //   // {
  //   //   id: 1,
  //   //   name: 'name 1',
  //   //   name_douanier: 'name 2',
  //   // },
  // ])

  // SubChapter Datatable
  // const [dataTableSubChapter, setDataTableSubChapter] = useState([])

  // // Handle Update Chapter
  // const handleUpdateChapter = (id) => {
  //   setItemSelectedChapter(id)
  //   settitleModalchapter('Modifier chapitre')
  // }
  // // Handle Add Chapter
  // const handleAddChapter = (id) => {
  //   setItemSelectedChapter(null)
  //   settitleModalchapter('Nouveau chapitre')
  // }

  // // Function to get chapters
  // const getChapter = async () => {
  //   const response = await axiosInstance
  //     .get('api/trade/chapter/')
  //     .then((res) => {
  //       const data = res.data
  //       // console.log(data)

  //       const arr = []

  //       data.map((x) => {
  //         return arr.push({ value: x.id, label: x.name })
  //       })
  //       // setChapters(arr)

  //       // setPending(false)
  //       setDataTableChapter(data)
  //     })
  //     .catch(function (error) {
  //       console.log(error)
  //     })
  // }

  // useEffect(() => {
  //   getChapter()
  // }, [refreshChapter])

  return (
    <>
      {/* Page header */}
      <div className='page-header d-print-none'>
        <div className='container-xl'>
          <div className='row g-2 align-items-center'>
            <div className='col'>
              {/* Page pre-title */}
              {/* <div className='page-pretitle'>Overview</div> */}
              <h2 className='page-title'>Produits </h2>
            </div>
          </div>
        </div>
      </div>
      {/* Page body */}
      <div className='page-body'>
        <div className='container-xl'>
          {/* tabs */}
          <div className='row row-cards '>
            <div className='col-12'>
              <div className='card ' style={{ borderColor: '#0073AD' }}>
                {/* tabs header border border-dark*/}
                <div className='card-header  '>
                  <ul
                    className='nav nav-tabs card-header-tabs nav-fill '
                    data-bs-toggle='tabs'
                    style={{ borderColor: '#0073AD' }}
                  >
                    <li className='nav-item '>
                      <a
                        href='#tabs-chapter'
                        className='nav-link active'
                        data-bs-toggle='tab'
                        onClick={() => setSelectedTab('tabs-chapter')}
                      >
                        Chapitres
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        href='#tabs-subchapter'
                        className='nav-link'
                        data-bs-toggle='tab'
                        onClick={() => setSelectedTab('tabs-subchapter')}
                      >
                        Produits
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        href='#tabs-product'
                        className='nav-link'
                        data-bs-toggle='tab'
                        onClick={() => setSelectedTab('tabs-product')}
                      >
                        Sous positions tarifaire
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='card-body ' style={{ borderColor: '#0073AD' }}>
                  <div className='tab-content'>
                    {/* Tab Chapter */}
                    <div className='tab-pane active show' id='tabs-chapter'>
                      <TabChapter seletedTab={seletedTab} />
                    </div>
                    {/* Tab Product */}
                    <div className='tab-pane' id='tabs-subchapter'>
                      <TabSubChapter seletedTab={seletedTab} />
                    </div>
                    {/* Sous Position tarifaire */}
                    <div className='tab-pane' id='tabs-product'>
                      <TabProduct seletedTab={seletedTab} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Products
