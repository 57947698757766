// import { geoMercator } from 'd3'
import { geoCylindricalEqualArea } from 'd3-geo-projection'

export default function MapProjection(mapData) {
  const projection = geoCylindricalEqualArea()
    // .center([0, 10])
    .scale(200)
  // .translate([500, 250])
  // adjust projection to fit area of map canvas
  // projection
  //   .precision(0)
  //   .rotate([90, 0, 0])
  //   .fitExtent(
  //     [
  //       [0, 0],
  //       [960, 480],
  //     ],
  //     mapData
  //   )
  // projection.fitExtent(
  //   [
  //     [0, 0],
  //     [960, 480],
  //   ],
  //   mapData
  // )
  return projection
}
