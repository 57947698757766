import React, { useRef, useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import Select from 'react-select'
import * as Yup from 'yup'
import axiosInstance from '../../../utils/AxiosInstance'
const ModalImportFile = ({
  setShow,
  setMessage,
  setShowError,
  setMessageError,
  settitleMessage,
}) => {
  const modalImportButton = useRef()
  const [selectedFile, setSelectedValueFile] = useState(null)

  // const [selectedValueTypeTrade, setSelectedValueTypeTrade] = useState('')

  // Set Option Years
  const currentYear = new Date().getFullYear()
  let optionsYears = []
  for (let i = currentYear - 11; i <= currentYear; i++) {
    optionsYears.push({ value: i.toString(), label: i.toString() })
  }
  optionsYears = optionsYears.reverse()

  // Set Option Type Trade
  const optionsTypeTrade = [
    { value: 'import', label: 'Importation' },
    { value: 'export', label: 'Exportation' },
  ]

  const [initialValues, setInitialValues] = useState({
    type_trade: '',
    year: '',
    file: '',
  })

  const handleFileChange = (e) => {
    setInitialValues({
      ...initialValues,
      file: e.target.files[0],
    })
    setSelectedValueFile({
      file: e.target.files[0],
    })
  }

  // handle selection Type Trade
  const handleChangeTapeTrade = (value) => {
    if (value) {
      setInitialValues({
        ...initialValues,
        type_trade: value.value,
      })
      // setSelectedValueTypeTrade(value.value)
    } else {
      setInitialValues({
        ...initialValues,
        type_trade: '',
      })
      // setSelectedValueTypeTrade(null)
    }
  }

  // handle selection Year
  const handleChangeYear = (value) => {
    // console.log(fileInputRef)
    if (value) {
      setInitialValues({
        ...initialValues,
        year: value.value,
      })
      // setSelectedValueYear(value.value)
    } else {
      setInitialValues({
        ...initialValues,
        year: '',
      })
      // setSelectedValueYear(null)
    }
    // // console.log(selectedValueYear)
    // const arr = []
    // value.map((x) => {
    //   return arr.push(x.value)
    // })
    // arr.sort()
    // setSelectedValueYear(arr)
  }

  const validationSchema = Yup.object().shape({
    //  user_name: Yup.string()
    //    .min(4, "Nom d'utilisateur doit être au moins 4 caractères")
    //    .max(128, "Nom d'utilisateur est trop long !")
    //    .trim()
    //    .required("Nom d'utilisateur est obligatoire"),

    //  first_name: Yup.string()
    //    .min(4, 'Prénom doit être au moins 4 caractères')
    //    .max(128, 'Prénom est trop long !')
    //    .trim()
    //    .required('Prénom est obligatoire'),

    //  last_name: Yup.string()
    //    .min(4, 'Nom doit être au moins 4 caractères')
    //    .max(128, 'Nom est trop long !')
    //    .trim()
    //    .required('Nom est obligatoire'),

    year: Yup.string().required("L'année est obligatoire"),
    type_trade: Yup.string().required('Le type est obligatoire'),
    file: Yup.string().required('Le fichier est obligatoire'),

    // permissions: Yup.object().nullable(),
  })

  const modalRef = useRef()
  const yearSelectRef = useRef()
  const typeSelectRef = useRef()
  const fileInputRef = useRef()

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        modalImportButton.current.disabled = true
        // console.log(modalImportButton.current)
        // console.log(values)
        console.log('initialValues before call axios', initialValues)
        // console.log(values.file)
        let form_data = new FormData()
        form_data.append('year', values.year)
        form_data.append('type_trade', values.type_trade)
        form_data.append('file', selectedFile.file, selectedFile.name)
        // form_data.append('file', values.file, values.file.name)
        var object = {}
        form_data.forEach((value, key) => (object[key] = value))
        // var json = JSON.stringify(object)
        // console.log(json)
        // console.log(form_data)
        const response = await axiosInstance
          .post('api/trade/importationvalues/', form_data, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((res) => {
            const data = res.data
            // console.log(data.message)
            settitleMessage("importation s'est terminée avec succès.")
            setMessage(data.message)

            setInitialValues({
              ...initialValues,
              year: '',
              type_trade: '',
              file: '',
            })
            yearSelectRef.current.clearValue()
            typeSelectRef.current.clearValue()
            fileInputRef.current.value = null
            setSelectedValueFile(null)
            // resetForm()
            modalRef.current.click()
            modalImportButton.current.disabled = false
            setShow(true)
          })
          .catch(function (error) {
            // console.log(error.response.status)
            // console.log(error.response.data)
            settitleMessage("Il ya eu une erreur d'importation du fichier.")
            setMessageError(error.response.data.message)
            setInitialValues({
              ...initialValues,
              year: '',
              type_trade: '',
              file: '',
            })
            yearSelectRef.current.clearValue()
            typeSelectRef.current.clearValue()
            fileInputRef.current.value = null
            setSelectedValueFile(null)
            // resetForm()
            modalRef.current.click()
            modalImportButton.current.disabled = false
            setShowError(true)
          })
        modalImportButton.current.disabled = false
        console.log('initialValues after call axios', initialValues)
      }}
    >
      {({ values, errors, touched }) => (
        <div
          className='modal modal-blur fade'
          id='modal-import-file'
          tabIndex='-1'
          role='dialog'
          aria-hidden='true'
        >
          <div
            className='modal-dialog modal-lg modal-dialog-centered'
            role='document'
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Imporation les données</h5>
                {/* <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                ></button> */}
              </div>
              <Form>
                <div className='modal-body'>
                  <div className='row'>
                    {/* Year */}
                    <div className='col-lg-6'>
                      <div className='mb-3'>
                        <label className='form-label'>Année</label>
                        <Select
                          options={optionsYears}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          // isMulti
                          id='year'
                          name='year'
                          isSearchable={true}
                          isClearable={true}
                          placeholder='Sélectionner l"année'
                          ref={yearSelectRef}
                          // required
                          onChange={handleChangeYear}
                        />
                        <ErrorMessage
                          component='div'
                          name='year'
                          style={{
                            // display: 'none',
                            width: '100%',
                            color: '#d63939',
                            fontSize: '85.714285%',
                            marginTop: '0.25rem',
                          }}
                        />
                      </div>
                    </div>
                    {/* Type */}
                    <div className='col-lg-6'>
                      <div className='mb-3'>
                        <label className='form-label'>Type</label>
                        <Select
                          options={optionsTypeTrade}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          id='type_trade'
                          name='type_trade'
                          isSearchable={true}
                          isClearable={true}
                          placeholder='Sélectionner le type'
                          ref={typeSelectRef}
                          onChange={handleChangeTapeTrade}
                        />
                        <ErrorMessage
                          component='div'
                          name='type_trade'
                          style={{
                            // display: 'none',
                            width: '100%',
                            color: '#d63939',
                            fontSize: '85.714285%',
                            marginTop: '0.25rem',
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='mb-3'>
                        <div class='form-label'>Fichier</div>
                        {/* <Field
                          type='file'
                          class='form-control'
                          name='file'
                          accept='.xlsx, .xls'
                          // value='testr'
                          ref={fileInputRef}
                          onChange={handleFileChange}
                        /> */}
                        <input
                          type='file'
                          class='form-control'
                          name='file'
                          accept='.xlsx, .xls'
                          ref={fileInputRef}
                          onChange={handleFileChange}
                        />
                        <ErrorMessage
                          component='div'
                          name='file'
                          style={{
                            // display: 'none',
                            width: '100%',
                            color: '#d63939',
                            fontSize: '85.714285%',
                            marginTop: '0.25rem',
                          }}
                        />
                        {/* {errors.file && touched.file ? (
                          <div className='invalid-feedback'>{errors.file}</div>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='modal-footer'>
                  <a
                    href='#'
                    className='btn btn-link link-secondary'
                    data-bs-dismiss='modal'
                    ref={modalRef}
                  >
                    Annuler
                  </a>
                  <button
                    type='submit'
                    className='btn btn-primary ms-auto'
                    ref={modalImportButton}
                    style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
                  >
                    Importer
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default ModalImportFile
