import Login from './pages/Login'
import Home from './pages/Home'
import Layout from './pages/Layout'
import Data from './pages/Data'
import HomeAdmin from './pages/administration/HomeAdmin'
import Users from './pages/administration/Users'
import Products from './pages/administration/Products'
import Regions from './pages/administration/Regions'
import Chart from './pages/Chart'
import Map from './pages/Map'
import Export from './pages/Export'
import FirstLogin from './pages/FirstLogin'
import DataManagement from './pages/administration/DataManagement'
import { Route, Routes } from 'react-router-dom'
import PrivateRoutes from './utils/PrivateRoutes'
import PrivateRoutesAdmin from './utils/PrivateRoutesAdmin'
// import PemissionRoutes from './utils/PemissionRoutes'
import { AuthProvider } from './context/AuthContext'

function App() {
  return (
    // <Login />
    <>
      <AuthProvider>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path='/*' element={<Layout />}>
              <Route element={<Home />} path='home' exact />

              <Route path='*' element={<Home />} />

              {/* <Route element={<PemissionRoutes />}> */}
              <Route path='data' element={<Data />} />
              <Route path='chart' element={<Chart />} />
              <Route path='map' element={<Map />} />
              <Route path='export' element={<Export />} />
              {/* </Route> */}

              <Route element={<PrivateRoutesAdmin />}>
                <Route path='homeadmin' element={<HomeAdmin />} />
                {/* <Route element={<PemissionRoutes />}> */}
                <Route path='users' element={<Users />} />
                <Route path='products' element={<Products />} />
                <Route path='regions' element={<Regions />} />
                <Route path='datamanagement' element={<DataManagement />} />
                {/* </Route> */}
              </Route>
            </Route>
          </Route>
          <Route path='/firstlogin' element={<FirstLogin />} />
          <Route element={<Login />} path='/login' />
        </Routes>
      </AuthProvider>
    </>
  )
}

export default App
