import React, { useEffect, useRef, useState } from 'react'
import axiosInstance from '../../../utils/AxiosInstance'
import Select from 'react-select'
const AssignCountryRegion = ({
  itemSelectedCountry,
  dataTableCountry,
  setItemSelectedCountry,
  refreshCountry,
  setRefreshCountry,
}) => {
  const modalRefAssignCountry = useRef()
  const [selectedValueAssign, setSelectedValueAssign] = useState([])
  const [economicRegion, setEconomicRegion] = useState([])
  const [economicRegionId, setEconomicRegionId] = useState([])

  // handle selection EconomicRegion
  const handleChangeEconomicRegion = (value) => {
    // console.log(value)
    setSelectedValueAssign(value)
    if (value) {
      const arr = []
      value.map((x) => {
        return arr.push(x.value)
      })
      setEconomicRegionId(arr)
    } else {
      setEconomicRegionId([])
    }
  }

  // Function to get EconomicRegion
  const getEconomicRegion = async () => {
    const response = await axiosInstance
      .get('api/trade/economicregions/')
      .then((res) => {
        const data = res.data
        // console.log(data)

        const arr = []

        data.map((x) => {
          return arr.push({ value: x.id, label: x.name_region })
        })
        setEconomicRegion(arr)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleClickClose = async (e) => {
    // console.log('close')
    setItemSelectedCountry(null)
  }

  const handleClickAssign = async (e) => {
    e.preventDefault()
    console.log(economicRegionId)
    const response = await axiosInstance
      .post('api/trade/assigncountry/', {
        code: itemSelectedCountry,
        regionid: economicRegionId,
      })
      .then((res) => {
        const data = res.data
        console.log(data)
        modalRefAssignCountry.current.click()
        setSelectedValueAssign([])
        setEconomicRegionId([])
        setRefreshCountry(!refreshCountry)
      })
      .catch(function (error) {
        console.log(error)
      })

    setItemSelectedCountry(null)
  }

  useEffect(() => {
    getEconomicRegion()
  }, [])

  useEffect(() => {
    const arr_object = []
    const arr_id = []
    // console.log('useeffect : ', itemSelectedCountry)
    if (itemSelectedCountry != null) {
      // console.log('item is not null')
      // console.log(dataTableCountry.find((x) => x.code === '113').regions)
      // // dataTableCountry.find((x) => x.code === itemSelectedCountry).regions

      if (
        dataTableCountry.find((x) => x.code === itemSelectedCountry).regions
          .length > 0
      ) {
        console.log('array is NOT empty')
        dataTableCountry
          .find((x) => x.code === itemSelectedCountry)
          .regions.map((y) => {
            // console.log(y)
            // console.log(economicRegion.find((x) => x.value === y))
            arr_object.push(economicRegion.find((x) => x.value === y))
            arr_id.push(economicRegion.find((x) => x.value === y).value)
          })
        setSelectedValueAssign(arr_object)
        setEconomicRegionId(arr_id)
      } else {
        // console.log('array is  empty')
        setSelectedValueAssign([])
        setEconomicRegionId([])
      }
      // console.log(arr_object)
      // console.log(arr_id)
    }
    // else {
    //   // setSelectedValueAssign([])
    //   // setEconomicRegionId([])
    //   getEconomicRegion()
    // }
    // setItemSelectedCountry(null)
  }, [itemSelectedCountry])
  return (
    <div
      className='modal modal-blur fade'
      id='assign-country-region'
      tabIndex='-1'
      role='dialog'
      aria-hidden='true'
    >
      <div
        className='modal-dialog modal-lg modal-dialog-centered'
        role='document'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Assosier</h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={handleClickClose}
            ></button>
          </div>
          <form
            // action='./'
            // method='get'
            autoComplete='off'
            // noValidate
            onSubmit={handleClickAssign}
          >
            <div className='modal-body'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='mb-3'>
                    <label className='form-label'>Région économique</label>
                    <Select
                      isMulti
                      value={selectedValueAssign}
                      options={economicRegion}
                      getOptionLabel={(e) => e.value + '-' + e.label}
                      getOptionValue={(e) => e.value}
                      id='continent_id'
                      name='continent_id'
                      isSearchable={true}
                      isClearable={true}
                      placeholder='Sélectionner le continent'
                      onChange={handleChangeEconomicRegion}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              {/* <a
                href='#'
                className='btn btn-link link-secondary'
                // data-bs-dismiss='modal'
                ref={modalRefAssignCountry}
                onClick={handleClickClose}
              >
                Annuler
              </a> */}
              <button
                type='button'
                className='btn btn-link link-secondary'
                data-bs-dismiss='modal'
                ref={modalRefAssignCountry}
                onClick={handleClickClose}
              >
                Annuler
              </button>

              <button
                type='submit'
                className='btn btn-primary ms-auto'
                style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
              >
                Enregister
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AssignCountryRegion
