import React, { useEffect, useState } from 'react'
import {
  IconDotsVertical,
  IconPlus,
  IconRefresh,
  IconX,
} from '@tabler/icons-react'
import DataTables from '../DataTables'
import axiosInstance from '../../../utils/AxiosInstance'
import ModalCountry from './ModalCountry'
import AssignCountryRegion from './AssignCountryRegion'
import ModalSuccess from './ModalSuccess'
import ModalError from './ModalError'
const TabCountry = ({
  continents,
  selectedValueContinent,
  setSelectedValueContinent,
  continentId,
  setContinentId,
  handleChangeContinent,
}) => {
  const [show, setShow] = useState(false)
  const [showError, setShowError] = useState(false)
  const [message, setMessage] = useState('')
  const [titleMessage, settitleMessage] = useState('')
  const [messageError, setMessageError] = useState('')
  const [refreshCountry, setRefreshCountry] = useState(true)
  const [titleModalCountry, settitleModalCountry] = useState('')
  const [itemSelectedCountry, setItemSelectedCountry] = useState(null)
  const [searchFilterCountry, setsearchFilterCountry] = useState('')
  // SubChapter Datatable
  const [dataTableCountry, setDataTableCountry] = useState([])
  const [dataTableCountryCopy, setDataTableCountryCopy] = useState([])

  // SubChapter Colmuns
  const [columnsCountry, setColumnsCountry] = useState([
    {
      name: 'Code',
      selector: (row) => row.code,
      width: '80px',
      // sortable: true,
    },
    {
      name: 'Alpha2',
      selector: (row) => row.alpha2,
      width: '80px',
      // sortable: true,
    },
    {
      name: 'Alpha3',
      selector: (row) => row.alpha3,
      width: '80px',
      // sortable: true,
    },
    {
      name: 'Nom',
      selector: (row) => row.name_fr,
      wrap: true,
      // sortable: true,
    },
    // {
    //   name: 'Continent',
    //   selector: (row) => row.continent,
    //   wrap: true,
    //   // sortable: true,
    // },
    {
      name: 'Actions',
      button: true,
      cell: (row) => (
        // <button
        //   className='btn btn-outline btn-xs'
        //   onClick={(e) => handleButtonClick(e, row.id)}
        // >
        //   Edit
        // </button>
        <div className='dropdown '>
          <a
            href='#'
            className='btn-action dropdown-toggle '
            data-bs-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <IconDotsVertical size={24} className='icon' />
          </a>
          <div className='dropdown-menu dropdown-menu-end'>
            <a
              className='dropdown-item btn'
              data-bs-toggle='modal'
              data-bs-target='#add-country'
              onClick={(e) => handleUpdateCountry(row.code)}
            >
              <IconRefresh size={24} className='icon' />
              Modifier
            </a>
            <a
              className='dropdown-item btn'
              data-bs-toggle='modal'
              data-bs-target='#assign-country-region'
              onClick={(e) => handleAssign(row.code)}
            >
              <IconRefresh size={24} className='icon' />
              Assosier a une région
            </a>
          </div>
        </div>
      ),
    },
  ])

  // Handle Add Country
  const handleAddCountry = (id) => {
    setItemSelectedCountry(null)
    settitleModalCountry('Nouveau pays')
  }

  // Handle Update Country
  const handleUpdateCountry = (id) => {
    // console.log('code pay :', id)
    setItemSelectedCountry(id)
    settitleModalCountry('Modifier pays')
  }

  // Handle Update Country to EconomicRegion
  const handleAssign = (id) => {
    // console.log('HandleAssaign :', id)

    setItemSelectedCountry(id)
  }

  // Function to clear search input
  const clearSearchCountry = () => {
  
    setsearchFilterCountry('')
    setDataTableCountry(dataTableCountryCopy)
  }

  // handle search Country
  const handleSearchCountry = (e) => {
   
    setsearchFilterCountry(e.target.value)
    if (e.target.value === '') {
     
      setDataTableCountry(dataTableCountryCopy)
    } else {
   
      let filteredvalue_traited = []
      const filteredvalue = dataTableCountryCopy.reduce(
        (accumulator, value) => {
       
          if (
            value['name_fr']
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          ) {
            accumulator.push(value)
       
          }
          if (
            value['alpha2'].toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            accumulator.push(value)
          }
          if (
            value['alpha3'].toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            accumulator.push(value)
          }
          if (
            value['code'].toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            accumulator.push(value)
          }

          return accumulator
        },
        []
      )
      filteredvalue_traited = filteredvalue.filter(
        (item, index) => filteredvalue.indexOf(item) === index
      )
      if (filteredvalue_traited.length > 0) {
        setDataTableCountry(filteredvalue_traited)
      }
    }
  }

  // Function to call api for getting subchapter by chpater
  const GetCountries = async (chapter_id) => {
 

    const response = await axiosInstance
      .get('api/trade/country/')
      .then((res) => {
        const data = res.data
       
        setDataTableCountry(data)
        setDataTableCountryCopy(data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(() => {
    GetCountries()
  }, [refreshCountry])
  return (
    <>
      <div className='row g-2 align-items-center mb-2'>
        {/* Search */}
        <div class='col-sm-3 col-md-3'>
          <div className='input-group input-group-flat'>
            <input
              type='text'
              placeholder='Recherche'
              className='form-control '
              required
              id='filter'
              name='filter'
              value={searchFilterCountry}
              onChange={(e) => handleSearchCountry(e)}
            />
            <span className='input-group-text'>
              <a
                role='button'
                class='link-secondary'
                title='Clear search'
                data-bs-toggle='tooltip'
                onClick={() => clearSearchCountry()}
              >
                {searchFilterCountry !== '' && (
                  <IconX size={24} className='icon' />
                )}
              </a>
            </span>
          </div>
        </div>
        {/* Page title actions */}
        <div className='col-auto ms-auto d-print-none'>
          <div className='btn-list'>
            <a
              href='#'
              className='btn btn-primary'
              data-bs-toggle='modal'
              data-bs-target='#add-country'
              onClick={() => handleAddCountry()}
              style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
            >
              <IconPlus
                size={24}
                className='icon'
                style={{ color: '#ffffff' }}
              />
              Ajouter
            </a>
          </div>
        </div>
        <div className='card' style={{ borderColor: '#0073AD' }}>
          <div className='card-body p-0'>
            {dataTableCountry.length !== 0 ? (
              <DataTables
                columns={columnsCountry}
                data_table={dataTableCountry}
                // pending={pending}
              />
            ) : (
              <div className='empty'>
                <p class='empty-title'>Aucun résultat trouvé</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalCountry
        titleModalCountry={titleModalCountry}
        itemSelectedCountry={itemSelectedCountry}
        dataTableCountry={dataTableCountry}
        refreshCountry={refreshCountry}
        setRefreshCountry={setRefreshCountry}
        setItemSelectedCountry={setItemSelectedCountry}
        continents={continents}
        selectedValueContinent={selectedValueContinent}
        setSelectedValueContinent={setSelectedValueContinent}
        continentId={continentId}
        setContinentId={setContinentId}
        handleChangeContinent={handleChangeContinent}
        setShow={setShow}
        setMessage={setMessage}
        setShowError={setShowError}
        setMessageError={setMessageError}
        settitleMessage={settitleMessage}
      />
      <AssignCountryRegion
        itemSelectedCountry={itemSelectedCountry}
        dataTableCountry={dataTableCountry}
        setItemSelectedCountry={setItemSelectedCountry}
        refreshCountry={refreshCountry}
        setRefreshCountry={setRefreshCountry}
      />
      <ModalSuccess
        show={show}
        setShow={setShow}
        message={message}
        titleMessage={titleMessage}
      />
      <ModalError
        showError={showError}
        setShowError={setShowError}
        messageError={messageError}
        titleMessage={titleMessage}
      />
    </>
  )
}

export default TabCountry
