import React, { useContext, useState } from 'react'
import AuthContext from '../context/AuthContext'
import TopHeader from '../component/main/TopHeader'
import Header from '../component/main/Header'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { IconEye } from '@tabler/icons-react'
import * as Yup from 'yup'
import axiosInstance from '../utils/AxiosInstance'
import { Navigate, useNavigate } from 'react-router-dom'
const FirstLogin = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [error, setError] = useState(false)
  const { user, reload, setReload } = useContext(AuthContext)
  const [hiddenPassword, setHiddenPassword] = useState(true)
  const [hiddenNewPassword, setHiddenNewPassword] = useState(true)
  const [hiddenConfirmNewPassword, setHiddenConfirmNewPassword] = useState(true)
  const [initialValues, setInitialValues] = useState({
    current_password: '',
    new_password: '',
    confirm_new_password: '',
  })

  const validationSchema = Yup.object().shape({
    // current_password: Yup.string()
    //   // .min(4, "Nom d'utilisateur doit être au moins 4 caractères")
    //   // .max(128, "Nom d'utilisateur est trop long !")
    //   .trim()
    //   .required("Le nom d'utilisateur est obligatoire"),
    // .test('user_name', "Le Nom d'utilisater exist déja", async (value) => {
    //   try {
    //     if (value != '' && value != null) {
    //       const res = await axiosInstance.post('serie/checkusername', {
    //         user_name: value,
    //       })
    //       return !res.data.username_exist
    //     } else return true
    //   } catch (err) {
    //     console.error(err)
    //   }
    // }),

    current_password: Yup.string()
      .min(8, "Le mot de passe doit être au moins 8 caractères")
      // .max(128, "Nom d'utilisateur est trop long !")
      .trim()
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&_^])[A-Za-z\d@.#$!%*?&_]{8,64}$/,
      //   'Le mot de passe doit se composer de majuscules, minuscules, chiffres, et signes de ponctuation ou caractères spéciaux (€, #...)'
      // )
      .required('Le mot de passe est obligatoire'),

    new_password: Yup.string()
      .min(8, "Le mot de passe doit être au moins 8 caractères")
      // .max(128, "Nom d'utilisateur est trop long !")
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&_^])[A-Za-z\d@.#$!%*?&_]{8,64}$/,
        'Le mot de passe doit se composer de majuscules, minuscules, chiffres, et signes de ponctuation ou caractères spéciaux (€, #...)'
      )
      .required('Le mot de passe est obligatoire'),

    confirm_new_password: Yup.string()
      .min(8, "Le mot de passedoit être au moins 8 caractères")
      // .max(128, "Nom d'utilisateur est trop long !")
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&_^])[A-Za-z\d@.#$!%*?&_]{8,64}$/,
        'Le mot de passe doit se composer de majuscules, minuscules, chiffres, et signes de ponctuation ou caractères spéciaux (€, #...)'
      )
      .oneOf([Yup.ref('new_password')], 'Passwords must match')
      .required('Le mot de passe est obligatoire'),

    // permissions: Yup.object().nullable(),
  })

  // LogIn Function
  const SetNewPassword = async (current_password, new_password) => {
    // e.preventDefault()
    // console.log('Form submitted')
    let response_token
    const response = await axiosInstance
      .put('api/base/setnewpassword/' + user.id + '/', {
        current_password: current_password,
        new_password: new_password,
      })
      .then((res) => {
        const data = res.data
        // console.log(data)
        response_token = res
      })
      .catch(function (error) {
        response_token = error
        // console.log(error)
        // if (
        //   error.response.status === 406 &&
        //   error.response.data['message'] ===
        //     'Le mot de passe actuel est incorrect.' &&
        //   error.response.data['status'] === 'fail'
        // ) {
        //   console.log(error.response.status)
        //   setError(true)
        //   setErrorMessage(error.response.data.detail)
        // }
      })

    return response_token
  }

  if (Object.keys(user).length > 0 && user.first_login) {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          console.log(values)
          setLoading(true)
          const response = await SetNewPassword(
            values.current_password,
            values.new_password
          )
          // console.log(response)
          if (response.status === 200) {
            setLoading(false)

            setReload(!reload)
          }
          if (
            'response' in response &&
            response.response.status === 406 &&
            response.response.data['message'] ===
              'Le mot de passe actuel est incorrect.' &&
            response.response.data['status'] === 'fail'
          ) {
            console.log(response.response.status)
            setErrorMessage('Le mot de passe actuel est incorrect.')
            setError(true)
            setLoading(false)
          }
          // Call Api for getting Data
          // const response = await axiosInstance
          //   .put('api/base/setnewpassword/' + user.id + '/', {
          //     current_password: values.current_password,
          //     new_password: values.new_password,
          //   })
          //   .then((res) => {
          //     const data = res.data
          //     console.log(data)
          //   })
          //   .catch(function (error) {
          //     // console.log(error)
          //     if (
          //       error.response.status === 406 &&
          //       error.response.data['message'] ===
          //         'Le mot de passe actuel est incorrect.' &&
          //       error.response.data['status'] === 'fail'
          //     ) {
          //       console.log(error.response.status)
          //       setError(true)
          //       setErrorMessage(error.response.data.detail)
          //     }
          //   })

          // let tst = loginUser(values.user_name, values.password)
          // setLoading(true)
          // const response = await loginUser(
          //   values.user_name,
          //   values.password
          // )
          // setLoading(false)
          // if (response.code === 'ERR_BAD_REQUEST') {
          //   if (
          //     response.response.status === 401 &&
          //     response.response.data.detail ===
          //       'No active account found with the given credentials'
          //   ) {
          //     setError(true)
          //     setErrorMessage(
          //       'Aucun compte actif ne correspond aux identifiants fournis.'
          //     )
          //     console.log(
          //       'Aucun compte actif ne correspond aux identifiants fournis.'
          //     )
          //   }
          // } else if (response.code === 'ERR_NETWORK') {
          //   setError(true)
          //   setErrorMessage('Erreur de réseau.')
          //   console.log('ERR_NETWORK')
          // }
          // console.log('token', response) className='d-flex flex-column'
        }}
      >
        {({ values, errors, touched }) => (
          <div className='layout-fluid'>
            <div className='page'>
              <TopHeader />
              <Header />
              <div className='page-wrapper'>
                <div className='page-body'>
                  <div className='container container-tight py-4'>
                    <div className='row row-cards mb-4 justify-content-center'>
                      <div className='col-lg-4'>
                        <div
                          className='card   '
                          style={{ borderColor: '#0073AD' }}
                        >
                          <div className='card-body'>
                            <div className='text-center font-bold'>
                              <p style={{ fontSize: '1.5rem' }}>
                                Entrez un nouveau mot de passe
                              </p>
                            </div>

                            <p className='mb-4'>
                              Les utilisateurs connectés pour la première fois
                              doivent mettre à jour leur mot de passe
                            </p>
                            <Form>
                              <div className='mb-3'>
                                <label className='form-label'>
                                  Mot de passe actuel
                                </label>
                                <div className='input-group '>
                                  <Field
                                    type={hiddenPassword ? 'password' : 'text'}
                                    className={
                                      errors.password
                                        ? 'form-control is-invalid'
                                        : 'form-control '
                                    }
                                    id='current_password'
                                    name='current_password'
                                  />
                                  <button
                                    className='btn'
                                    type='button'
                                    onClick={() =>
                                      setHiddenPassword(!hiddenPassword)
                                    }
                                  >
                                    <IconEye
                                      size={24}
                                      className='btn-icon-only'
                                    />
                                  </button>
                                </div>
                                <ErrorMessage
                                  component='div'
                                  name='current_password'
                                  style={{
                                    // display: 'none',
                                    width: '100%',
                                    color: '#d63939',
                                    fontSize: '85.714285%',
                                    marginTop: '0.25rem',
                                  }}
                                />
                                {error ? (
                                  <div
                                    style={{
                                      // display: 'none',
                                      width: '100%',
                                      color: '#d63939',
                                      fontSize: '85.714285%',
                                      marginTop: '0.25rem',
                                    }}
                                  >
                                    {errorMessage}
                                  </div>
                                ) : null}
                              </div>
                              <div className='mb-2'>
                                <label className='form-label'>
                                  Nouveau mot de passe
                                </label>
                                <div className='input-group '>
                                  <Field
                                    type={
                                      hiddenNewPassword ? 'password' : 'text'
                                    }
                                    className={
                                      errors.password
                                        ? 'form-control is-invalid'
                                        : 'form-control '
                                    }
                                    id='new_password'
                                    name='new_password'
                                  />
                                  <button
                                    className='btn'
                                    type='button'
                                    onClick={() =>
                                      setHiddenNewPassword(!hiddenNewPassword)
                                    }
                                  >
                                    <IconEye
                                      size={24}
                                      className='btn-icon-only'
                                    />
                                  </button>
                                </div>
                                <ErrorMessage
                                  component='div'
                                  name='new_password'
                                  style={{
                                    // display: 'none',
                                    width: '100%',
                                    color: '#d63939',
                                    fontSize: '85.714285%',
                                    marginTop: '0.25rem',
                                  }}
                                />
                              </div>
                              <div className='mb-2'>
                                <label className='form-label'>
                                  Confirmer le nouveau mot de passe
                                </label>
                                <div className='input-group '>
                                  <Field
                                    type={
                                      hiddenConfirmNewPassword
                                        ? 'password'
                                        : 'text'
                                    }
                                    className={
                                      errors.password
                                        ? 'form-control is-invalid'
                                        : 'form-control '
                                    }
                                    id='confirm_new_password'
                                    name='confirm_new_password'
                                  />
                                  <button
                                    className='btn'
                                    type='button'
                                    onClick={() =>
                                      setHiddenConfirmNewPassword(
                                        !hiddenConfirmNewPassword
                                      )
                                    }
                                  >
                                    <IconEye
                                      size={24}
                                      className='btn-icon-only'
                                    />
                                  </button>
                                </div>
                                <ErrorMessage
                                  component='div'
                                  name='confirm_new_password'
                                  style={{
                                    // display: 'none',
                                    width: '100%',
                                    color: '#d63939',
                                    fontSize: '85.714285%',
                                    marginTop: '0.25rem',
                                  }}
                                />
                              </div>
                              <div className='form-footer'>
                                <button
                                  type='submit'
                                  className='btn btn-primary w-100 mb-2'
                                  style={{ backgroundColor: '#0073AD' }}
                                >
                                  <div className='pe-2'>S'identifier</div>
                                  {loading ? (
                                    <div
                                      className='spinner-border '
                                      role='status'
                                    >
                                      <span className='visually-hidden'>
                                        Loading...
                                      </span>
                                    </div>
                                  ) : null}
                                </button>

                                {/* {error ? (
                                  <div
                                    style={{
                                      // display: 'none',
                                      width: '100%',
                                      color: '#d63939',
                                      fontSize: '85.714285%',
                                      marginTop: '0.25rem',
                                    }}
                                  >
                                    {errorMessage}
                                  </div>
                                ) : null} */}
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    )
  } else {
    return <Navigate to='/home' />
    // return null
  }
}

export default FirstLogin
