import React, { useEffect, useState } from 'react'
import axiosInstance from '../utils/AxiosInstance'
import ReactECharts from 'echarts-for-react'
import Select from 'react-select'
import { IconArrowUp, IconArrowDown } from '@tabler/icons-react'
const Home = () => {
  const [loading, setLoading] = useState(true)
  const currentYear = new Date().getFullYear()
  const [selectedValueYear, setSelectedValueYear] = useState(currentYear)
  const [selectedValueMonth, setSelectedValueMonth] = useState('12')
  const [selectedValue, setSelectedValue] = useState('value_usd')

  // Set Option Years
  let optionsYears = []
  for (let i = currentYear - 11; i <= currentYear; i++) {
    optionsYears.push({ value: i.toString(), label: i.toString() })
  }
  optionsYears = optionsYears.reverse()
  // Set Option Months
  const optionsMonth = [
    // { value: 'year', label: 'Tous les mois' },
    { value: '12', label: '12' },
    { value: '11', label: '11' },
    { value: '10', label: '10' },
    { value: '09', label: '09' },
    { value: '08', label: '08' },
    { value: '07', label: '07' },
    { value: '06', label: '06' },
    { value: '05', label: '05' },
    { value: '04', label: '04' },
    { value: '03', label: '03' },
    { value: '02', label: '02' },
    { value: '01', label: '01' },
  ]

  // Set Option Values
  const optionsValues = [
    { value: 'value_usd', label: 'Valeur USD' },
    { value: 'value_dzd', label: 'Valeur DZD' },
    { value: 'weight', label: 'Poids' },
  ]

  const [topSupplierCountriesFoodProduct, setTopSupplierCountriesFoodProduct] =
    useState([])

  // const [
  //   topSupplierCountriesNoFoodProduct,
  //   setTopSupplierCountriesNoFoodProduct,
  // ] = useState([])

  const [
    topImportingCountriesFoodProduct,
    setTopImportingCountriesFoodProduct,
  ] = useState([])

  // const [
  //   topImportingCountriesNoFoodProduct,
  //   setTopImportingCountriesNoFoodProduct,
  // ] = useState([])

  const [topImportingSubChapter, setTopImportingSubChapter] = useState([])
  const [topExportingSubChapter, setTopExportingSubChapter] = useState([])
  const [evolutionImportExportByMonth, setEvolutionImportExportByMonth] =
    useState({ legend: [], xAxis: [], series: [[], []] })
  // const [topImportingChaptervalues, setTopImportingChaptervalues] = useState([])
  // const [topImportingChapternames, setTopImportingChapternames] = useState([])
  // const [topExportingChaptervalues, setTopExportingChaptervalues] = useState([])
  // const [topExportingChapternames, setTopExportingChapternames] = useState([])
  // const [topImportingSubChaptervalues, setTopImportingSubChaptervalues] =
  //   useState([])
  // const [topImportingSubChapternames, setTopImportingSubChapternames] =
  //   useState([])
  // const [topExportingSubChaptervalues, setTopExportingSubChaptervalues] =
  //   useState([])
  // const [topExportingSubChapternames, setTopExportingSubChapternames] =
  //   useState([])
  // const [topImportingProductvalues, setTopImportingProductvalues] = useState([])
  // const [topImportingProductnames, setTopImportingProductnames] = useState([])
  // const [topExportingProductvalues, setTopExportingProductvalues] = useState([])
  // const [topExportingProductnames, setTopExportingProductnames] = useState([])
  const [
    topImportingProduct5608ValuesSelectedYear,
    setTopImportingProduct5608ValuesSelectedYear,
  ] = useState([])
  const [
    topImportingProduct5608ValuesLastYear,
    setTopImportingProduct5608ValuesLastYear,
  ] = useState([])
  const [topImportingProduct5608Names, setTopImportingProduct5608Names] =
    useState([])
  const [totalImportingProduct5608Values, setTotalImportingProduct5608Values] =
    useState(0)

  const [
    topImportingAlbacoreTunasValuesSelectedYear,
    setTopImportingAlbacoreTunasValuesSelectedYear,
  ] = useState([])
  const [
    topImportingAlbacoreTunasValuesLastYear,
    setTopImportingAlbacoreTunasValuesLastYear,
  ] = useState([])
  const [topImportingAlbacoreTunasNames, setTopImportingAlbacoreTunasNames] =
    useState([])
  const [
    totalImportingAlbacoreTunasValues,
    setTotalImportingAlbacoreTunasValues,
  ] = useState(0)

  const [
    topImportingSardineValuesSelectedYear,
    setTopImportingSardineValuesSelectedYear,
  ] = useState([])
  const [
    topImportingSardineValuesLastYear,
    setTopImportingSardineValuesLastYear,
  ] = useState([])
  const [topImportingSardineNames, setTopImportingSardineNames] = useState([])
  const [totalImportingSardineValues, setTotalImportingSardineValues] =
    useState(0)

  const [trendImport, setTrendImport] = useState({
    base: null,
    current: null,
    percent: null,
  })

  const [trendImportFoodProduct, setTrendImportFoodProduct] = useState({
    base: null,
    current: null,
    percent: null,
  })

  const [trendImportNoFoodProduct, setTrendImportNoFoodProduct] = useState({
    base: null,
    current: null,
    percent: null,
  })

  const [typeProductImport, setTypeProductImport] = useState([])

  const [trendExport, setTrendExport] = useState({
    base: null,
    current: null,
    percent: null,
  })

  const [trendExportFoodProduct, setTrendExportFoodProduct] = useState({
    base: null,
    current: null,
    percent: null,
  })

  const [trendExportNoFoodProduct, setTrendExportNoFoodProduct] = useState({
    base: null,
    current: null,
    percent: null,
  })
  const [typeProductExport, setTypeProductExport] = useState([])

  // Top 5 Supplier Countries Food Product
  const eChartOptionSupplierCountriesFoodProduct = {
    title: {
      text:
        'Top 5 Pays Fournisseurs ' +
        selectedValueYear +
        ' (' +
        selectedValueMonth +
        ' Mois)',
      subtext: 'Produits',
      left: 'center',
      textStyle: {
        fontSize: 1 + 'rem',
        fontWeight: 'bolder',
        color: '#182433',
        fontFamily: 'sans-serif',
      },
    },
    toolbox: {
      left: 'right',
      feature: {
        // dataZoom: {},
        saveAsImage: {
          type: 'png',
        },
        // dataView: {},
        // magicType: {
        //   type: ['line', 'bar', 'stack'],
        // },
      },
    },
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
      formatter: function (params) {
        // console.log('tooltip', params)

        if (selectedValue === 'value_usd') {
          return (
            `${params.seriesName}<br />
              ${params.marker}${params.name}: <b>` +
            '$' +
            `${Intl.NumberFormat('en-US').format(params.data.value)}</b><br />`
          )
        } else if (selectedValue === 'value_dzd') {
          return (
            `${params.seriesName}<br />
              ${params.marker}${params.name}: <b>` +
            `${Intl.NumberFormat('en-US').format(
              params.data.value
            )} DZD</b><br />`
          )
        } else if (selectedValue === 'weight') {
          return (
            `${params.seriesName}<br />
              ${params.marker}${params.name}: <b>` +
            `${Intl.NumberFormat('en-US').format(
              params.data.value
            )} Kg</b><br />`
          )
        }
      },
    },
    legend: {
      // top: '5%',
      top: 'bottom',
      left: 'center',
    },
    series: [
      {
        name: 'Top 5 Pays Fournisseurs ' + selectedValueYear,
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
          normal: {
            label: {
              show: true,
              position: 'inner',
              formatter: function (params) {
                // console.log('itemStyle', params.data.extra)
                return params.data.extra + '%\n'
                // return params.value + '%\n'
              },
            },
            labelLine: {
              show: false,
            },
          },
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        // data: [
        //   { value: 1048, name: 'Search Engine' },
        //   { value: 735, name: 'Direct' },
        //   { value: 580, name: 'Email' },
        //   { value: 484, name: 'Union Ads' },
        //   { value: 300, name: 'Video Ads' },
        // ],
        data: topSupplierCountriesFoodProduct,
      },
    ],
  }

  // Top 5 Importing Countries Food Product
  const eChartOptionImportingCountriesFoodProduct = {
    title: {
      text:
        'TOP 5 Pays Importateurs ' +
        selectedValueYear +
        ' (' +
        selectedValueMonth +
        ' Mois)',
      subtext: 'Produits',
      left: 'center',
      textStyle: {
        fontSize: 1 + 'rem',
        fontWeight: 'bolder',
        color: '#182433',
        fontFamily: 'sans-serif',
      },
    },
    toolbox: {
      left: 'right',
      feature: {
        // dataZoom: {},
        saveAsImage: {
          type: 'png',
        },
        // dataView: {},
        // magicType: {
        //   type: ['line', 'bar', 'stack'],
        // },
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        // console.log(
        //   'tooltip',
        //   Intl.NumberFormat('en-US').format(params.data.value)
        // )
        // ${params.data.value}
        if (selectedValue === 'value_usd') {
          //  return `${params.seriesName}<br />
          //     ${params.marker}${params.name}: <b>${Intl.NumberFormat(
          //    'en-US'
          //  ).format(params.data.value)}</b><br />`
          return (
            `${params.seriesName}<br />
              ${params.marker}${params.name}: <b>` +
            '$' +
            `${Intl.NumberFormat('en-US').format(params.data.value)}</b><br />`
          )
        } else if (selectedValue === 'value_dzd') {
          return (
            `${params.seriesName}<br />
              ${params.marker}${params.name}: <b>` +
            `${Intl.NumberFormat('en-US').format(
              params.data.value
            )} DZD</b><br />`
          )
        } else if (selectedValue === 'weight') {
          return (
            `${params.seriesName}<br />
              ${params.marker}${params.name}: <b>` +
            `${Intl.NumberFormat('en-US').format(
              params.data.value
            )} Kg</b><br />`
          )
        }
      },
    },
    legend: {
      // top: '5%',
      top: 'bottom',
      left: 'center',
    },
    series: [
      {
        name: 'TOP 5 Pays Importateurs ' + selectedValueYear,
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
          normal: {
            label: {
              show: true,
              position: 'inner',
              formatter: function (params) {
                return params.data.extra + '%\n'
                // return params.value + '%\n'
              },
            },
            labelLine: {
              show: false,
            },
          },
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        color: [
          '#3fb1e3',
          '#6be6c1',
          '#626c91',
          '#a0a7e6',
          '#c4ebad',
          '#96dee8',
        ],

        data: topImportingCountriesFoodProduct,
      },
    ],
  }

  // Top 5 Importing SubChapter
  const eChartOptionImportingSubChapter = {
    title: {
      text:
        'Top 5 Produits Importés ' +
        selectedValueYear +
        ' (' +
        selectedValueMonth +
        ' Mois)',
      // subtext: 'Produits',
      left: 'center',
      textStyle: {
        fontSize: 1 + 'rem',
        fontWeight: 'bolder',
        color: '#182433',
        fontFamily: 'sans-serif',
      },
    },
    toolbox: {
      left: 'right',
      feature: {
        // dataZoom: {},
        saveAsImage: {
          type: 'png',
        },
        // dataView: {},
        // magicType: {
        //   type: ['line', 'bar', 'stack'],
        // },
      },
    },
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
      formatter: function (params) {
        // console.log('tooltip', params)

        if (selectedValue === 'value_usd') {
          return (
            `${params.seriesName}<br />
              ${params.marker}${params.name}: <b>` +
            '$' +
            `${Intl.NumberFormat('en-US').format(params.data.value)}</b><br />`
          )
        } else if (selectedValue === 'value_dzd') {
          return (
            `${params.seriesName}<br />
              ${params.marker}${params.name}: <b>` +
            `${Intl.NumberFormat('en-US').format(
              params.data.value
            )} DZD</b><br />`
          )
        } else if (selectedValue === 'weight') {
          return (
            `${params.seriesName}<br />
              ${params.marker}${params.name}: <b>` +
            `${Intl.NumberFormat('en-US').format(
              params.data.value
            )} Kg</b><br />`
          )
        }
      },
    },
    legend: {
      // top: '5%',
      top: 'bottom',
      left: 'center',
    },
    series: [
      {
        name: 'Top 5 Produits Importés ' + selectedValueYear,
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
          normal: {
            label: {
              show: true,
              position: 'inner',
              formatter: function (params) {
                // console.log('itemStyle', params.data.extra)
                return params.data.extra + '%\n'
                // return params.value + '%\n'
              },
            },
            labelLine: {
              show: false,
            },
          },
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        // data: [
        //   { value: 1048, name: 'Search Engine' },
        //   { value: 735, name: 'Direct' },
        //   { value: 580, name: 'Email' },
        //   { value: 484, name: 'Union Ads' },
        //   { value: 300, name: 'Video Ads' },
        // ],
        data: topImportingSubChapter,
      },
    ],
  }

  // Top 5 Exporting SubChapter
  const eChartOptionExportingSubChapter = {
    title: {
      text:
        'Top 5 Produits Exportés ' +
        selectedValueYear +
        ' (' +
        selectedValueMonth +
        ' Mois)',
      // subtext: 'Produits',
      left: 'center',
      textStyle: {
        fontSize: 1 + 'rem',
        fontWeight: 'bolder',
        color: '#182433',
        fontFamily: 'sans-serif',
      },
    },
    toolbox: {
      left: 'right',
      feature: {
        // dataZoom: {},
        saveAsImage: {
          type: 'png',
        },
        // dataView: {},
        // magicType: {
        //   type: ['line', 'bar', 'stack'],
        // },
      },
    },
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
      formatter: function (params) {
        // console.log('tooltip', params)

        if (selectedValue === 'value_usd') {
          return (
            `${params.seriesName}<br />
              ${params.marker}${params.name}: <b>` +
            '$' +
            `${Intl.NumberFormat('en-US').format(params.data.value)}</b><br />`
          )
        } else if (selectedValue === 'value_dzd') {
          return (
            `${params.seriesName}<br />
              ${params.marker}${params.name}: <b>` +
            `${Intl.NumberFormat('en-US').format(
              params.data.value
            )} DZD</b><br />`
          )
        } else if (selectedValue === 'weight') {
          return (
            `${params.seriesName}<br />
              ${params.marker}${params.name}: <b>` +
            `${Intl.NumberFormat('en-US').format(
              params.data.value
            )} Kg</b><br />`
          )
        }
      },
    },
    legend: {
      // top: '5%',
      top: 'bottom',
      left: 'center',
    },
    series: [
      {
        name: 'Top 5 Produits Exportés ' + selectedValueYear,
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
          normal: {
            label: {
              show: true,
              position: 'inner',
              formatter: function (params) {
                // console.log('itemStyle', params.data.extra)
                return params.data.extra + '%\n'
                // return params.value + '%\n'
              },
            },
            labelLine: {
              show: false,
            },
          },
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        color: [
          '#3fb1e3',
          '#6be6c1',
          '#626c91',
          '#a0a7e6',
          '#c4ebad',
          '#96dee8',
        ],
        // data: [
        //   { value: 1048, name: 'Search Engine' },
        //   { value: 735, name: 'Direct' },
        //   { value: 580, name: 'Email' },
        //   { value: 484, name: 'Union Ads' },
        //   { value: 300, name: 'Video Ads' },
        // ],
        data: topExportingSubChapter,
      },
    ],
  }


  const getTitleEvolutionImportExport = (value) => {
    if (selectedValue === 'value_usd') {
      return 'Importation/Exportation par mois en $'
    } else if (selectedValue === 'value_dzd') {
      return 'Importation/Exportation par mois en DZD'
    } else if (selectedValue === 'weight') {
      return 'Importation/Exportation par mois en Kg'
    }
  }

  //  Evolution Import And Export By Month
  const eChartOptionImportExportByMonth = {
    title: {
      text: getTitleEvolutionImportExport(),
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: evolutionImportExportByMonth.legend,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: evolutionImportExportByMonth.xAxis,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      evolutionImportExportByMonth.series[0][0],
      evolutionImportExportByMonth.series[1][0],
    ],
  }



  const getsubTextProduct5608 = (value) => {
    if (selectedValue === 'value_usd') {
      return 'Total : $' + Intl.NumberFormat('en-US').format(value)
    } else if (selectedValue === 'value_dzd') {
      return 'Total : ' + Intl.NumberFormat('en-US').format(value) + ' DZD'
    } else if (selectedValue === 'weight') {
      return 'Total : ' + Intl.NumberFormat('en-US').format(value) + ' Kg'
    }
  }

  // Top 5 Country Import Product 5608
  const eChartOptionImportingProduct5608 = {
    title: {
      text:
        'TOP 5 Pays Fournisseur des filets ' +
        selectedValueYear +
        ' (' +
        selectedValueMonth +
        ' Mois)',
      // subtext:
      //   'Total : ' +
      //   Intl.NumberFormat('en-US').format(totalImportingProduct5608Values) +
      //   ' Kg',
      subtext: getsubTextProduct5608(totalImportingProduct5608Values),
      subtextStyle: {
        fontWeight: 'bolder',
        color: '#182433',
        fontSize: 13,
      },
      left: 'center',
      textStyle: {
        fontSize: 1 + 'rem',
        fontWeight: 'bolder',
        color: '#182433',
        fontFamily: 'sans-serif',
      },
    },
    xAxis: {
      data: topImportingProduct5608Names,
    },
    yAxis: {},
    legend: {
      data: [selectedValueYear, (parseInt(selectedValueYear) - 1).toString()],
      orient: 'horizontal',
      // right: 10,
      top: 'bottom',
    },
    // series: {
    //   type: 'bar',
    //   data: topImportingProduct5608Values,
    //   label: {
    //     show: true,
    //     position: 'top',
    //     formatter: function (params) {
    //       return `${Intl.NumberFormat('en-US').format(params.data)} Kg`
    //     },
    //   },
    // },
    series: [
      {
        name: selectedValueYear,
        type: 'bar',
        data: topImportingProduct5608ValuesSelectedYear,
        label: {
          show: true,
          position: 'top',
          formatter: function (params) {
            return `${Intl.NumberFormat('en-US').format(params.data)}`
          },
        },
      },
      {
        name: (parseInt(selectedValueYear) - 1).toString(),
        type: 'bar',
        data: topImportingProduct5608ValuesLastYear,
        label: {
          show: true,
          position: 'top',
          formatter: function (params) {
            return `${Intl.NumberFormat('en-US').format(params.data)}`
          },
        },
      },
    ],
  }

  const getsubTextProductAlbacoreTunas = (value) => {
    if (selectedValue === 'value_usd') {
      return 'Total : $' + Intl.NumberFormat('en-US').format(value)
    } else if (selectedValue === 'value_dzd') {
      return 'Total : ' + Intl.NumberFormat('en-US').format(value) + ' DZD'
    } else if (selectedValue === 'weight') {
      return 'Total : ' + Intl.NumberFormat('en-US').format(value) + ' Kg'
    }
  }

  // Top 5 Country Import  Product Albacore Tunas
  const eChartOptionImportingAlbacoreTunas = {
    title: {
      text:
        'TOP 5 Pays Fournisseur de thon blanc ' +
        selectedValueYear +
        ' (' +
        selectedValueMonth +
        ' Mois)',
      // subtext:
      //   'Total : ' +
      //   Intl.NumberFormat('en-US').format(totalImportingAlbacoreTunasValues) +
      //   ' Kg',
      // subtext:
      //   'Total : ' +
      //   Intl.NumberFormat('en-US').format(totalImportingAlbacoreTunasValues) +
      //   ' Kg',
      subtext: getsubTextProductAlbacoreTunas(
        totalImportingAlbacoreTunasValues
      ),
      subtextStyle: {
        fontWeight: 'bolder',
        color: '#182433',
        fontSize: 13,
      },
      left: 'center',
      textStyle: {
        fontSize: 1 + 'rem',
        fontWeight: 'bolder',
        color: '#182433',
        fontFamily: 'sans-serif',
      },
    },
    xAxis: {
      data: topImportingAlbacoreTunasNames,
    },
    yAxis: {},
    legend: {
      data: [selectedValueYear, (parseInt(selectedValueYear) - 1).toString()],
      orient: 'horizontal',
      // right: 10,
      top: 'bottom',
    },
    // series: {
    //   type: 'bar',
    //   data: topImportingAlbacoreTunasValues,
    //   label: {
    //     show: true,
    //     position: 'top',
    //     formatter: function (params) {
    //       return `${Intl.NumberFormat('en-US').format(params.data)} Kg`
    //     },
    //   },
    // },
    series: [
      {
        name: selectedValueYear,
        type: 'bar',
        data: topImportingAlbacoreTunasValuesSelectedYear,
        label: {
          show: true,
          position: 'top',
          formatter: function (params) {
            return `${Intl.NumberFormat('en-US').format(params.data)}`
          },
        },
      },
      {
        name: (parseInt(selectedValueYear) - 1).toString(),
        type: 'bar',
        data: topImportingAlbacoreTunasValuesLastYear,
        label: {
          show: true,
          position: 'top',
          formatter: function (params) {
            return `${Intl.NumberFormat('en-US').format(params.data)}`
          },
        },
      },
    ],
  }

  // Top 5 Country Import  Product Sardine
  const eChartOptionImportingSardine = {
    title: {
      text:
        'TOP 5 Pays Fournisseur de la sardine ' +
        selectedValueYear +
        ' (' +
        selectedValueMonth +
        ' Mois)',
      // subtext:
      //   'Total : ' +
      //   Intl.NumberFormat('en-US').format(totalImportingSardineValues) +
      //   ' Kg',
      subtext: getsubTextProductAlbacoreTunas(totalImportingSardineValues),
      subtextStyle: {
        fontWeight: 'bolder',
        color: '#182433',
        fontSize: 13,
      },
      left: 'center',
      textStyle: {
        fontSize: 1 + 'rem',
        fontWeight: 'bolder',
        color: '#182433',
        fontFamily: 'sans-serif',
      },
    },
    xAxis: {
      data: topImportingSardineNames,
    },
    yAxis: {},
    legend: {
      data: [selectedValueYear, (parseInt(selectedValueYear) - 1).toString()],
      orient: 'horizontal',
      // right: 10,
      top: 'bottom',
    },
    // series: {
    //   type: 'bar',
    //   data: topImportingSardineValues,
    //   label: {
    //     show: true,
    //     position: 'top',
    //     formatter: function (params) {
    //       return `${Intl.NumberFormat('en-US').format(params.data)} Kg`
    //     },
    //   },
    // },
    series: [
      {
        name: selectedValueYear,
        type: 'bar',
        data: topImportingSardineValuesSelectedYear,
        label: {
          show: true,
          position: 'top',
          formatter: function (params) {
            return `${Intl.NumberFormat('en-US').format(params.data)}`
          },
        },
      },
      {
        name: (parseInt(selectedValueYear) - 1).toString(),
        type: 'bar',
        data: topImportingSardineValuesLastYear,
        label: {
          show: true,
          position: 'top',
          formatter: function (params) {
            return `${Intl.NumberFormat('en-US').format(params.data)}`
          },
        },
      },
    ],
  }

  // handle selection Year
  const handleChangeYear = (value) => {
    setLoading(true)
    if (value) {
      setSelectedValueYear(value.value)
    } else {
      setSelectedValueYear(null)
    }
  }

  // handle selection Month
  const handleChangeMonth = (value) => {
    setLoading(true)
    if (value) {
      setSelectedValueMonth(value.value)
    } else {
      setSelectedValueMonth(null)
    }
  }

  // handle selection Value
  const handleChangeValue = (value) => {
    setLoading(true)
    if (value) {
      setSelectedValue(value.value)
    } else {
      setSelectedValue(null)
    }
  }

  // Call function GetChapitre
  useEffect(() => {
    setTopSupplierCountriesFoodProduct([])
    // setTopSupplierCountriesNoFoodProduct([])
    setTopImportingCountriesFoodProduct([])
    // setTopImportingCountriesNoFoodProduct([])
    setTrendImport({
      base: 0,
      current: 0,
      percent: 0,
    })
    setTrendImportFoodProduct({
      base: 0,
      current: 0,
      percent: 0,
    })
    setTrendExport({
      base: 0,
      current: 0,
      percent: 0,
    })
    setTypeProductImport([])
    setTypeProductExport([])

    setTopImportingSubChapter([])
    setTopExportingSubChapter([])

    // setTopImportingChaptervalues([])
    // setTopImportingChapternames([])
    // setTopImportingSubChaptervalues([])
    // setTopImportingSubChapternames([])
    // setTopExportingProductvalues([])
    // setTopExportingProductnames([])

    setTopImportingProduct5608ValuesSelectedYear([])
    setTopImportingProduct5608Names([])
    setTotalImportingProduct5608Values(0)
    setTopImportingAlbacoreTunasValuesSelectedYear([])
    setTopImportingAlbacoreTunasNames([])
    setTotalImportingAlbacoreTunasValues(0)
    setTopImportingSardineValuesSelectedYear([])
    setTopImportingSardineNames([])
    setTotalImportingSardineValues(0)
    if (selectedValueYear != null && selectedValueMonth != null) {
      const response = axiosInstance
        .post('api/trade/indicator/', {
          year: selectedValueYear,
          month: selectedValueMonth,
          value: selectedValue,
        })
        .then((res) => {
          // console.log(res.data)
          // console.log(res.data.TrendImportFoodProduct)

          setEvolutionImportExportByMonth(res.data.EvolutionImportExportByMonth)

          setTopSupplierCountriesFoodProduct(
            res.data.Top5SupplierCountriesFoodProduct
          )
          // setTopSupplierCountriesNoFoodProduct(
          //   res.data.Top5SupplierCountriesNoFoodProduct
          // )
          setTopImportingCountriesFoodProduct(
            res.data.Top5ImportingCountriesFoodProduct
          )
          // setTopImportingCountriesNoFoodProduct(
          //   res.data.Top5ImportingCountriesNoFoodProduct
          // )

          // setTrendImport(res.data.TrendImport)
          setTrendImport(res.data.TrendImportFoodProduct)
          // setTrendImportFoodProduct(res.data.TrendImportFoodProduct)
          // setTrendImportNoFoodProduct(res.data.TrendImportNoFoodProduct)

          // setTrendExport(res.data.TrendExport)
          setTrendExport(res.data.TrendExportFoodProduct)
          // setTrendExportFoodProduct(res.data.TrendExportFoodProduct)
          // setTrendExportNoFoodProduct(res.data.TrendExportNoFoodProduct)

          // setTypeProductImport(res.data.TypeProductImport)
          // setTypeProductExport(res.data.TypeProductExport)

          setTopImportingSubChapter(res.data.Top5ImportingSubChapter)
          setTopExportingSubChapter(res.data.Top5ExportingSubChapter)

          // Set Import Chapter Data Chart
          // const arr_import_chapter_values = []
          // const arr_import_chapter_names = []
          // res.data.Top5ImportingChapter.map((x) => {
          //   arr_import_chapter_values.push(x.value)
          //   arr_import_chapter_names.push(x.name)
          // })
          // setTopImportingChaptervalues(arr_import_chapter_values)
          // setTopImportingChapternames(arr_import_chapter_names)

          // Set Export Chapter Data Chart
          // const arr_export_chapter_values = []
          // const arr_export_chapter_names = []
          // res.data.Top5ExportingChapter.map((x) => {
          //   arr_export_chapter_values.push(x.value)
          //   arr_export_chapter_names.push(x.name)
          // })
          // setTopExportingChaptervalues(arr_export_chapter_values)
          // setTopExportingChapternames(arr_export_chapter_names)

          // Set Import SubChapter Data Chart
          // const arr_import_subchapter_values = []
          // const arr_import_subchapter_names = []
          // res.data.Top5ImportingSubChapter.map((x) => {
          //   arr_import_subchapter_values.push(x.value)
          //   arr_import_subchapter_names.push(x.name)
          // })
          // setTopImportingSubChaptervalues(arr_import_subchapter_values)
          // setTopImportingSubChapternames(arr_import_subchapter_names)

          // Set Export SubChapter Data Chart
          // const arr_export_subchapter_values = []
          // const arr_export_subchapter_names = []
          // res.data.Top5ExportingSubChapter.map((x) => {
          //   arr_export_subchapter_values.push(x.value)
          //   arr_export_subchapter_names.push(x.name)
          // })
          // setTopExportingSubChaptervalues(arr_export_subchapter_values)
          // setTopExportingSubChapternames(arr_export_subchapter_names)

          // Set Import Product Data Chart
          // const arr_import_product_values = []
          // const arr_import_product_names = []
          // res.data.Top5ImportingProduct.map((x) => {
          //   arr_import_product_values.push(x.value)
          //   arr_import_product_names.push(x.name)
          // })
          // setTopImportingProductvalues(arr_import_product_values)
          // setTopImportingProductnames(arr_import_product_names)

          // Set Export Product Data Chart
          // const arr_export_product_values = []
          // const arr_export_product_names = []
          // res.data.Top5ExportingProduct.map((x) => {
          //   arr_export_product_values.push(x.value)
          //   arr_export_product_names.push(x.name)
          // })
          // setTopExportingProductvalues(arr_export_product_values)
          // setTopExportingProductnames(arr_export_product_names)

          // Set Import Product 5608 Data Chart

          const arr_import_product_5608_values_selected_year = []
          const arr_import_product_5608_values_last_year = []
          const arr_import_product_5608_names = []
          res.data.Top5SupplierCountriesImportingSubChapter5608.map((x) => {
            // arr_import_product_5608_values.push(x.value)
            arr_import_product_5608_values_selected_year.push(x.this_year)
            arr_import_product_5608_values_last_year.push(x.last_year)
            // arr_import_product_5608_values.push({
            //   last_year: x.last_year,
            //   this_year: x.this_year,
            // })
            arr_import_product_5608_names.push(x.name)
          })
          // console.log(
          //   'selectedyear',
          //   arr_import_product_5608_values_selected_year
          // )
          // console.log('lastyear', arr_import_product_5608_values_last_year)
          // console.log('name', arr_import_product_5608_names)
          setTopImportingProduct5608ValuesSelectedYear(
            arr_import_product_5608_values_selected_year
          )
          setTopImportingProduct5608ValuesLastYear(
            arr_import_product_5608_values_last_year
          )
          setTopImportingProduct5608Names(arr_import_product_5608_names)

          // Set total Import Product 5608 Data Chart
          setTotalImportingProduct5608Values(res.data.TotalImportProduct5608)

          // Set Import Product Albacore Tunas Data Chart

          const arr_import_albacore_tunas_values_selected_year = []
          const arr_import_albacore_tunas_values_last_year = []
          const arr_import_albacore_tunas_names = []
          res.data.Top5SupplierCountriesImportingAlbacoreTunas.map((x) => {
            // arr_import_albacore_tunas_values.push(x.value)
            arr_import_albacore_tunas_values_selected_year.push(x.this_year)
            arr_import_albacore_tunas_values_last_year.push(x.last_year)
            arr_import_albacore_tunas_names.push(x.name)
          })
          setTopImportingAlbacoreTunasValuesSelectedYear(
            arr_import_albacore_tunas_values_selected_year
          )
          setTopImportingAlbacoreTunasValuesLastYear(
            arr_import_albacore_tunas_values_last_year
          )
          setTopImportingAlbacoreTunasNames(arr_import_albacore_tunas_names)

          // Set total Import Product  Albacore Tunas Data Chart
          setTotalImportingAlbacoreTunasValues(
            res.data.TotalImportProductAlbacoreTunas
          )

          // Set Import Product Sardine Data Chart

          const arr_import_sardine_values_selected_year = []
          const arr_import_sardine_names_last_year = []
          const arr_import_sardine_names = []
          res.data.Top5SupplierCountriesImportingSardine.map((x) => {
            // arr_import_sardine_values.push(x.value)
            arr_import_sardine_values_selected_year.push(x.this_year)
            arr_import_sardine_names_last_year.push(x.last_year)
            arr_import_sardine_names.push(x.name)
          })
          setTopImportingSardineValuesSelectedYear(
            arr_import_sardine_values_selected_year
          )
          setTopImportingSardineValuesLastYear(
            arr_import_sardine_names_last_year
          )

          setTopImportingSardineNames(arr_import_sardine_names)

          // Set total Import Product  Sardine Data Chart
          setTotalImportingSardineValues(res.data.TotalImportProductSardine)

          setLoading(false)
        })
        .catch(function (error) {
          setLoading(false)
          console.log(error)
        })
    }
  }, [selectedValueYear, selectedValueMonth, selectedValue])

  return (
    <>
      {/* <TopHeader />
      <Header /> */}
      <div className='page-wrapper'>
        {/* Page header */}
        <div className='page-header d-print-none'>
          <div className='container-xl'>
            <div className='row g-2 align-items-center'>
              <div className='col'>
                <div className='btn-list'>
                  <Select
                    defaultValue={[optionsYears[0]]}
                    options={optionsYears}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    id='type'
                    name='type'
                    isSearchable={true}
                    isClearable={true}
                    placeholder='Sélectionner le type'
                    // ref={subProductSelectRef}
                    onChange={handleChangeYear}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#0073AD',
                      }),
                    }}
                  />
                  <Select
                    defaultValue={[optionsMonth[0]]}
                    options={optionsMonth}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    id='month'
                    name='month'
                    isSearchable={true}
                    isClearable={true}
                    placeholder='Sélectionner le type'
                    // ref={subProductSelectRef}
                    onChange={handleChangeMonth}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#0073AD',
                      }),
                    }}
                  />
                  <Select
                    defaultValue={[optionsValues[0]]}
                    options={optionsValues}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    id='value'
                    name='values'
                    isSearchable={true}
                    isClearable={true}
                    placeholder='Sélectionner la valeur'
                    // ref={subProductSelectRef}
                    onChange={handleChangeValue}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#0073AD',
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page body */}
        <div className='page-body'>
          <div className='container-xl'>
            <div className='row row-deck row-cards mb-2'>
              {/* Trend Import  */}
              <div className='col-sm-6 col-lg-6'>
                <div className='card' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body  text-center'>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#0073AD',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : trendImport.current === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <div className='r'>
                        <div
                          className=' mb-0 me-2'
                          style={{
                            fontSize: '1rem',
                            fontWeight: 'bolder',
                            color: '#182433',
                            fontFamily: 'sans-serif',
                          }}
                        >
                          Total Importation {selectedValueYear} (
                          {selectedValueMonth} Mois)
                        </div>
                        <div className='h2 mb-0 me-2'>
                          {selectedValue === 'value_usd' && '$'}

                          {trendImport.current}
                          {selectedValue === 'value_dzd' && ' DZD'}
                          {selectedValue === 'weight' && ' Kg'}
                        </div>
                        <div className='h3 mb-0 me-2'>
                          {' '}
                          {trendImport.percent < 0 ? (
                            <span className='text-green d-inline-flex align-items-center lh-1'>
                              <IconArrowDown size={24} className='icon ms-1' />
                              {trendImport.percent}%
                            </span>
                          ) : (
                            <span className='text-red d-inline-flex align-items-center lh-1'>
                              <IconArrowUp size={24} className='icon ms-1' />
                              {trendImport.percent}%
                            </span>
                          )}
                        </div>
                        <div className=' mb-0 me-2'>
                          <span className=''>
                            {' '}
                            C'était {selectedValue === 'value_usd' && '$'}
                            {trendImport.base}{' '}
                            {selectedValue === 'value_dzd' && ' DZD'}
                            {selectedValue === 'weight' && ' Kg'} l'an dernier.
                          </span>
                        </div>
                      </div>
                    )}
                    {/* {trendImport.current === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <div className='r'>
                        <div
                          className=' mb-0 me-2'
                          style={{
                            fontSize: '1rem',
                            fontWeight: 'bolder',
                            color: '#182433',
                            fontFamily: 'sans-serif',
                          }}
                        >
                          Total Importation {selectedValueYear} (
                          {selectedValueMonth} Mois)
                        </div>
                        <div className='h2 mb-0 me-2'>
                          {selectedValue === 'value_usd' && '$'}

                          {trendImport.current}
                          {selectedValue === 'value_dzd' && ' DZD'}
                          {selectedValue === 'weight' && ' Kg'}
                        </div>
                        <div className='h3 mb-0 me-2'>
                          {' '}
                          {trendImport.percent < 0 ? (
                            <span className='text-green d-inline-flex align-items-center lh-1'>
                              <IconArrowDown size={24} className='icon ms-1' />
                              {trendImport.percent}%
                            </span>
                          ) : (
                            <span className='text-red d-inline-flex align-items-center lh-1'>
                              <IconArrowUp size={24} className='icon ms-1' />
                              {trendImport.percent}%
                            </span>
                          )}
                        </div>
                        <div className=' mb-0 me-2'>
                          <span className=''>
                            {' '}
                            C'était {selectedValue === 'value_usd' && '$'}
                            {trendImport.base}{' '}
                            {selectedValue === 'value_dzd' && ' DZD'}
                            {selectedValue === 'weight' && ' Kg'} l'an dernier.
                          </span>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
              {/* Trend Export  */}
              <div className='col-sm-6 col-lg-6'>
                <div className='card' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body  text-center'>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#0073AD',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : trendExport.current === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <>
                        <div className='r'>
                          <div
                            className=' mb-0 me-2'
                            style={{
                              fontSize: '1rem',
                              fontWeight: 'bolder',
                              color: '#182433',
                              fontFamily: 'sans-serif',
                            }}
                          >
                            Total Exportation {selectedValueYear} (
                            {selectedValueMonth} Mois)
                          </div>
                          <div className='h1 mb-0 me-2'>
                            {selectedValue === 'value_usd' && '$'}
                            {trendExport.current}
                            {selectedValue === 'value_dzd' && ' DZD'}
                            {selectedValue === 'weight' && ' Kg'}
                          </div>
                          <div className='h3 mb-0 me-2'>
                            {' '}
                            {trendExport.percent < 0 ? (
                              <span className='text-red d-inline-flex align-items-center lh-1'>
                                <IconArrowDown
                                  size={24}
                                  className='icon ms-1'
                                />
                                {trendExport.percent}%
                              </span>
                            ) : (
                              <span className='text-green d-inline-flex align-items-center lh-1'>
                                <IconArrowUp size={24} className='icon ms-1' />
                                {trendExport.percent}%
                              </span>
                            )}
                          </div>
                          <div className=' mb-0 me-2'>
                            <span className=''>
                              {' '}
                              C'était ${trendExport.base} l'an dernier.
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    {/* {trendExport.current === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <>
                        <div className='r'>
                          <div
                            className=' mb-0 me-2'
                            style={{
                              fontSize: '1rem',
                              fontWeight: 'bolder',
                              color: '#182433',
                              fontFamily: 'sans-serif',
                            }}
                          >
                            Total Exportation {selectedValueYear} (
                            {selectedValueMonth} Mois)
                          </div>
                          <div className='h1 mb-0 me-2'>
                            {selectedValue === 'value_usd' && '$'}
                            {trendExport.current}
                            {selectedValue === 'value_dzd' && ' DZD'}
                            {selectedValue === 'weight' && ' Kg'}
                          </div>
                          <div className='h3 mb-0 me-2'>
                            {' '}
                            {trendExport.percent < 0 ? (
                              <span className='text-red d-inline-flex align-items-center lh-1'>
                                <IconArrowDown
                                  size={24}
                                  className='icon ms-1'
                                />
                                {trendExport.percent}%
                              </span>
                            ) : (
                              <span className='text-green d-inline-flex align-items-center lh-1'>
                                <IconArrowUp size={24} className='icon ms-1' />
                                {trendExport.percent}%
                              </span>
                            )}
                          </div>
                          <div className=' mb-0 me-2'>
                            <span className=''>
                              {' '}
                              C'était ${trendExport.base} l'an dernier.
                            </span>
                          </div>
                        </div>
                      </>
                    )} */}
                  </div>
                </div>
              </div>
              {/* Trend Import Food Product */}
              {/* <div className='col-sm-6 col-lg-3'>
                <div className='card' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body  text-center'>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#0073AD',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : trendImport.current === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <div className='r'>
                        <div
                          className=' mb-0 me-2'
                          style={{
                            fontSize: '1rem',
                            fontWeight: 'bolder',
                            color: '#182433',
                            fontFamily: 'sans-serif',
                          }}
                        >
                          Importation Produits {selectedValueYear} (
                          {selectedValueMonth} Mois)
                        </div>
                        <div className='h2 mb-0 me-2'>
                          {selectedValue === 'value_usd' && '$'}

                          {trendImportFoodProduct.current}
                          {selectedValue === 'value_dzd' && ' DZD'}
                          {selectedValue === 'weight' && ' Kg'}
                        </div>
                        <div className='h3 mb-0 me-2'>
                          {' '}
                          {trendImportFoodProduct.percent < 0 ? (
                            <span className='text-green d-inline-flex align-items-center lh-1'>
                              <IconArrowDown size={24} className='icon ms-1' />
                              {trendImportFoodProduct.percent}%
                            </span>
                          ) : (
                            <span className='text-red d-inline-flex align-items-center lh-1'>
                              <IconArrowUp size={24} className='icon ms-1' />
                              {trendImportFoodProduct.percent}%
                            </span>
                          )}
                        </div>
                        <div className=' mb-0 me-2'>
                          <span className=''>
                            {' '}
                            C'était {selectedValue === 'value_usd' && '$'}
                            {trendImportFoodProduct.base}
                            {selectedValue === 'value_dzd' && ' DZD'}
                            {selectedValue === 'weight' && ' Kg'} l'an dernier.
                          </span>
                        </div>
                      </div>
                    )}
                    
                  </div>
                </div>
              </div> */}
              {/* Trend Import No Food Product */}
              {/* <div className='col-sm-6 col-lg-3'>
                <div className='card' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body  text-center'>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#0073AD',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : trendImport.current === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <div className='r'>
                        <div
                          className=' mb-0 me-2'
                          style={{
                            fontSize: '1rem',
                            fontWeight: 'bolder',
                            color: '#182433',
                            fontFamily: 'sans-serif',
                          }}
                        >
                          Importation Matériels {selectedValueYear} (
                          {selectedValueMonth} Mois)
                        </div>
                        <div className='h2 mb-0 me-2'>
                          {selectedValue === 'value_usd' && '$'}

                          {trendImportNoFoodProduct.current}
                          {selectedValue === 'value_dzd' && ' DZD'}
                          {selectedValue === 'weight' && ' Kg'}
                        </div>
                        <div className='h3 mb-0 me-2'>
                          {' '}
                          {trendImportNoFoodProduct.percent < 0 ? (
                            <span className='text-green d-inline-flex align-items-center lh-1'>
                              <IconArrowDown size={24} className='icon ms-1' />
                              {trendImportNoFoodProduct.percent}%
                            </span>
                          ) : (
                            <span className='text-red d-inline-flex align-items-center lh-1'>
                              <IconArrowUp size={24} className='icon ms-1' />
                              {trendImportNoFoodProduct.percent}%
                            </span>
                          )}
                        </div>
                        <div className=' mb-0 me-2'>
                          <span className=''>
                            {' '}
                            C'était {selectedValue === 'value_usd' && '$'}
                            {trendImportNoFoodProduct.base}
                            {selectedValue === 'value_dzd' && ' DZD'}
                            {selectedValue === 'weight' && ' Kg'} l'an dernier.
                          </span>
                        </div>
                      </div>
                    )}
                   
                  </div>
                </div>
              </div> */}
              {/* Trend Export Food Product */}
              {/* <div className='col-sm-6 col-lg-3'>
                <div className='card' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body  text-center'>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#0073AD',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : trendExport.current === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <>
                        <div className='r'>
                          <div
                            className=' mb-0 me-2'
                            style={{
                              fontSize: '1rem',
                              fontWeight: 'bolder',
                              color: '#182433',
                              fontFamily: 'sans-serif',
                            }}
                          >
                            Exportation Produits {selectedValueYear} (
                            {selectedValueMonth} Mois)
                          </div>
                          <div className='h1 mb-0 me-2'>
                            {selectedValue === 'value_usd' && '$'}
                            {trendExportFoodProduct.current}
                            {selectedValue === 'value_dzd' && ' DZD'}
                            {selectedValue === 'weight' && ' Kg'}
                          </div>
                          <div className='h3 mb-0 me-2'>
                            {' '}
                            {trendExportFoodProduct.percent < 0 ? (
                              <span className='text-red d-inline-flex align-items-center lh-1'>
                                <IconArrowDown
                                  size={24}
                                  className='icon ms-1'
                                />
                                {trendExportFoodProduct.percent}%
                              </span>
                            ) : (
                              <span className='text-green d-inline-flex align-items-center lh-1'>
                                <IconArrowUp size={24} className='icon ms-1' />
                                {trendExportFoodProduct.percent}%
                              </span>
                            )}
                          </div>
                          <div className=' mb-0 me-2'>
                            <span className=''>
                              {' '}
                              C'était ${trendExportFoodProduct.base} l'an
                              dernier.
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div> */}
              {/* Trend Export No Food Product */}
              {/* <div className='col-sm-6 col-lg-3'>
                <div className='card' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body  text-center'>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#0073AD',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : trendExport.current === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <>
                        <div className='r'>
                          <div
                            className=' mb-0 me-2'
                            style={{
                              fontSize: '1rem',
                              fontWeight: 'bolder',
                              color: '#182433',
                              fontFamily: 'sans-serif',
                            }}
                          >
                            Exportation Matériels {selectedValueYear} (
                            {selectedValueMonth} Mois)
                          </div>
                          <div className='h1 mb-0 me-2'>
                            {selectedValue === 'value_usd' && '$'}
                            {trendExportNoFoodProduct.current}
                            {selectedValue === 'value_dzd' && ' DZD'}
                            {selectedValue === 'weight' && ' Kg'}
                          </div>
                          <div className='h3 mb-0 me-2'>
                            {' '}
                            {trendExportNoFoodProduct.percent < 0 ? (
                              <span className='text-red d-inline-flex align-items-center lh-1'>
                                <IconArrowDown
                                  size={24}
                                  className='icon ms-1'
                                />
                                {trendExportNoFoodProduct.percent}%
                              </span>
                            ) : (
                              <span className='text-green d-inline-flex align-items-center lh-1'>
                                <IconArrowUp size={24} className='icon ms-1' />
                                {trendExportNoFoodProduct.percent}%
                              </span>
                            )}
                          </div>
                          <div className=' mb-0 me-2'>
                            <span className=''>
                              {' '}
                              C'était ${trendExportNoFoodProduct.base} l'an
                              dernier.
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    
                  </div>
                </div>
              </div> */}
              {/* Type Product Import  */}
              {/* <div className='col-sm-6 col-lg-3'>
                <div className='card' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body  '>
                    {trendImport.current === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <>
                        <div
                          className=' mb-1 me-2'
                          style={{
                            fontSize: '1rem',
                            fontWeight: 'bolder',
                            color: '#182433',
                            fontFamily: 'sans-serif',
                          }}
                        >
                          Importation Par Catégorie {selectedValueYear} (
                          {selectedValueMonth} Mois)
                        </div>
                        <div className='r'>
                          <div className='h4 mb-0 me-2'>
                            {typeProductImport.length !== 0
                              ? 'Produits : $' +
                                typeProductImport[0].food_product +
                                ' ' +
                                '(' +
                                typeProductImport[0].food_product_percent +
                                '%' +
                                ')'
                              : 'Produits :'}
                          </div>
                          <div className='h4 mb-0 me-2'>
                            {typeProductImport.length !== 0
                              ? 'Matériels : $' +
                                typeProductImport[1].no_food_product +
                                ' ' +
                                '(' +
                                typeProductImport[1].no_food_product_percent +
                                '%' +
                                ')'
                              : 'Matériels :'}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div> */}
              {/* Trend Export  */}
              {/* <div className='col-sm-6 col-lg-3'>
                <div className='card' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body  text-center'>
                    {trendExport.current === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <>
                        <div className='r'>
                          <div
                            className=' mb-0 me-2'
                            style={{
                              fontSize: '1rem',
                              fontWeight: 'bolder',
                              color: '#182433',
                              fontFamily: 'sans-serif',
                            }}
                          >
                            Total Exportation {selectedValueYear} (
                            {selectedValueMonth} Mois)
                          </div>
                          <div className='h1 mb-0 me-2'>
                            {selectedValue === 'value_usd' && '$'}
                            {trendExport.current}
                            {selectedValue === 'value_dzd' && ' DZD'}
                            {selectedValue === 'weight' && ' Kg'}
                          </div>
                          <div className='h3 mb-0 me-2'>
                            {' '}
                            {trendExport.percent < 0 ? (
                              <span className='text-red d-inline-flex align-items-center lh-1'>
                                <IconArrowDown
                                  size={24}
                                  className='icon ms-1'
                                />
                                {trendExport.percent}%
                              </span>
                            ) : (
                              <span className='text-green d-inline-flex align-items-center lh-1'>
                                <IconArrowUp size={24} className='icon ms-1' />
                                {trendExport.percent}%
                              </span>
                            )}
                          </div>
                          <div className=' mb-0 me-2'>
                            <span className=''>
                              {' '}
                              C'était ${trendExport.base} l'an dernier.
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div> */}
              {/* Type Product Export  */}
              {/* <div className='col-sm-6 col-lg-3'>
                <div className='card' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body  '>
                    {trendExport.current === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <>
                        {' '}
                        <div
                          className=' mb-1 me-2'
                          style={{
                            fontSize: '1rem',
                            fontWeight: 'bolder',
                            color: '#182433',
                            fontFamily: 'sans-serif',
                          }}
                        >
                          Exportation Par Catégorie {selectedValueYear} (
                          {selectedValueMonth} Mois)
                        </div>
                        <div className='r'>
                          <div className='h4 mb-0 me-2'>
                            {typeProductExport.length !== 0
                              ? 'Produits : $' +
                                typeProductExport[0].food_product +
                                ' ' +
                                '(' +
                                typeProductExport[0].food_product_percent +
                                '%' +
                                ')'
                              : 'Produits :'}
                          </div>
                          <div className='h4 mb-0 me-2'>
                            {typeProductExport.length !== 0
                              ? 'Matériels : $' +
                                typeProductExport[1].no_food_product +
                                ' ' +
                                '(' +
                                typeProductExport[1].no_food_product_percent +
                                '%' +
                                ')'
                              : 'Matériels :'}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div> */}
            </div>
            {/* Top 5 Countries */}
            <div className='row row-deck row-cards'>
              {/* Top 5 pays fournisseurs  Food Product*/}
              <div className='col-lg-6'>
                <div className='card' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body'>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#0073AD',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : topSupplierCountriesFoodProduct.length === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <div>
                        <ReactECharts
                          option={eChartOptionSupplierCountriesFoodProduct}
                          // style={{ width: '100%', height: '100%' }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Top 5 pays importateurs Food Product*/}
              <div className='col-lg-6'>
                <div className='card' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body'>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#0073AD',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : topImportingCountriesFoodProduct.length === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <div>
                        <ReactECharts
                          option={eChartOptionImportingCountriesFoodProduct}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Top Importing SubChapter*/}
              <div className='col-lg-6'>
                <div className='card' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body'>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#0073AD',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : topImportingSubChapter.length === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <div>
                        <ReactECharts
                          option={eChartOptionImportingSubChapter}
                          // style={{ width: '100%', height: '100%' }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Top Exporting SubChapter*/}
              <div className='col-lg-6'>
                <div className='card' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body'>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#0073AD',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : topImportingSubChapter.length === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <div>
                        <ReactECharts
                          option={eChartOptionExportingSubChapter}
                          // style={{ width: '100%', height: '100%' }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Top Import Export By Month*/}
              <div className='col-lg-12'>
                <div className='card' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body'>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#0073AD',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : topImportingSubChapter.length === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <div>
                        <ReactECharts
                          option={eChartOptionImportExportByMonth}
                          // style={{ width: '100%', height: '100%' }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Top 5 Country Import Product 5608 col-lg-6*/}
              <div className='col-lg-6 '>
                <div className='card ' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body '>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#0073AD',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : topImportingProduct5608ValuesSelectedYear.length ===
                      0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <div>
                        <ReactECharts
                          option={eChartOptionImportingProduct5608}
                          style={{ height: '500px' }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Top 5 Country Import Product Albacore Tunas*/}
              <div className='col-lg-6 '>
                <div className='card ' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body '>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#0073AD',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : topImportingAlbacoreTunasValuesSelectedYear.length ===
                      0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <div>
                        <ReactECharts
                          option={eChartOptionImportingAlbacoreTunas}
                          style={{ height: '500px' }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Top 5 Country Import Product Sardine*/}
              <div className='col-lg-6 '>
                <div className='card ' style={{ borderColor: '#0073AD' }}>
                  <div className='card-body '>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          padding: '1rem',
                          texAalign: 'center',
                        }}
                      >
                        <div
                          className='spinner-grow  '
                          style={{
                            width: '3rem',
                            height: '3rem',
                            color: '#0073AD',
                          }}
                          role='status'
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    ) : topImportingSardineValuesSelectedYear.length === 0 ? (
                      <div className='empty'>
                        <p className='empty-title'>Aucun résultat trouvé</p>
                      </div>
                    ) : (
                      <div>
                        <ReactECharts
                          option={eChartOptionImportingSardine}
                          style={{ height: '500px' }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
