import React, { useEffect, useState, useRef } from 'react'
import Select from 'react-select'
import SelectChapter from './SelectChapter'
import SelectSubChapter from './SelectSubChapter'
import SelectProduct from './SelectProduct'
import axiosInstance from '../../utils/AxiosInstance'
import styled from 'styled-components'
import { IconChevronDown } from '@tabler/icons-react'

const StyledCell = styled.div`
  &.low {
    background: #51c88c;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    font-size: 0.9rem;
    font-weight: 550;
  }
  &.test2 {
    background: #fef9a7;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    font-size: 0.9rem;
    font-weight: 550;
  }
  &.test {
    background: #fac213;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    font-size: 0.9rem;
    font-weight: 550;
  }
  &.medium {
    background: #f77e21;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    font-size: 0.9rem;
    font-weight: 550;
  }
  &.high {
    background: #ff4848;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    font-size: 0.9rem;
    font-weight: 550;
  }
`

const StyledRowTotal = styled.div`
  &.total {
    font-size: 1rem;
    font-weight: 500;
    background: #4299e1;
    display: grid;
    width: 100%;
    height: 100%;
  }
  &.nototal {
    font-size: 1rem;
    font-weight: 500;
  }
`

const StyledCellText = styled.div`
  & {
    font-size: 1rem;
    font-weight: 500;
  }
`
const Filter = ({
  selectedValueChapter,
  setSelectedValueChapter,
  selectedValueSubChapter,
  setSelectedValueSubChapter,
  selectedValueProduct,
  setSelectedValueProduct,
  selectedValueRegion,
  setSelectedValueRegion,
  selectRadioRegion,
  setSelectRadioRegion,
  selectedValueYear,
  setSelectedValueYear,
  selectedValueMonth,
  setSelectedValueMonth,
  selectedValueTypeTrade,
  setSelectedValueTypeTrade,
  selectedValueDisplay,
  setSelectedValueDisplay,
  selectedValueBy,
  setSelectedValueBy,
  setTitleUnit,
  setDataTable,
  setDataTableCopy,
  setColumns,
  setPending,
  setTitleTable,
  setSubTitleTable,
  setLoading,
}) => {
  const [monthIsMulti, setMonthIsMulti] = useState(false)
  const subHideFilterRef = useRef()
  const subChapterSelectRef = useRef()
  const subProductSelectRef = useRef()
  const selectRegionRef = useRef()
  const selectMontRef = useRef()
  const [chapters, setChapters] = useState([])
  const [subchapters, setSubChapters] = useState([])
  const [products, setProducts] = useState([])
  const [regions, setRegions] = useState([])
  const [valuesBy, setValuesBy] = useState([
    { value: 'country', label: 'Pays' },
    { value: 'product', label: 'Produits' },
    { value: 'continent', label: 'Continents' },
    { value: 'economic_region', label: 'Régions économiques' },
  ])
  // const [selectRadioRegion, setSelectRadioRegion] = useState('countries')
  // const [selectedValueChapter, setSelectedValueChapter] = useState(null)
  const [selectedOptionChapter, setSelectedOptionChapter] = useState(null)
  // const [selectedValueSubChapter, setSelectedValueSubChapter] = useState(null)
  const [selectedOptionSubChapter, setSelectedOptionSubChapter] = useState(null)
  // const [selectedValueProduct, setSelectedValueProduct] = useState([])
  const [selectedOptionProduct, setSelectedOptionProduct] = useState(null)
  // const [selectedValueRegion, setSelectedValueRegion] = useState(null)
  // const [selectedValueYear, setSelectedValueYear] = useState(['2022', '2023'])
  // const [selectedValueMonth, setSelectedValueMonth] = useState([])
  const [selectedOptionMonth, setSelectedOptionMonth] = useState(null)
  // const [selectedValueTypeTrade, setSelectedValueTypeTrade] = useState('import')
  // const [selectedValueBy, setSelectedValueBy] = useState(null)
  const [isDisabledBy, setIsDisabledBy] = useState(false)
  // const [selectedValueDisplay, setSelectedValueDisplay] = useState('value_usd')
  const [indexselectby, setIndexselectBy] = useState(1)

  // Set Option Months
  const optionsMonth = [
    { value: 'year', label: 'Tous les mois' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
  ]

  // Set Option Years
  const currentYear = new Date().getFullYear()
  let optionsYears = []
  for (let i = currentYear - 11; i <= currentYear; i++) {
    optionsYears.push({ value: i.toString(), label: i.toString() })
  }
  optionsYears = optionsYears.reverse()

  // const optionsYears = [
  //   { value: '2012', label: '2012' },
  //   { value: '2013', label: '2013' },
  //   { value: '2014', label: '2014' },
  //   { value: '2015', label: '2015' },
  //   { value: '2016', label: '2016' },
  //   { value: '2017', label: '2017' },
  //   { value: '2018', label: '2018' },
  //   { value: '2019', label: '2019' },
  //   { value: '2020', label: '2020' },
  //   { value: '2021', label: '2021' },
  //   { value: '2022', label: '2022' },
  //   { value: '2023', label: '2023' },
  // ]

  // Set Option TypeTrade
  const optionsTypeTrade = [
    { value: 'import', label: 'Importation' },
    { value: 'export', label: 'Exportation' },
  ]

  // Set Option DisplayValue
  const optionsValues = [
    { value: 'value_usd', label: 'Valeur USD' },
    { value: 'value_dzd', label: 'Valeur DZD' },
    { value: 'weight', label: 'Poids' },
  ]

  // Set Option DisplayBy
  const optionsBy = [
    { value: 'country', label: 'Pays' },
    { value: 'product', label: 'Produits' },
    { value: 'continent', label: 'Continents' },
    { value: 'economic_region', label: 'Régions économiques' },
  ]

  // handle selection Chapter
  const handleChangeChapter = (value, action) => {
    
    if (
      value &&
      value.find((element) => element.value === '00') &&
      // action.option &&
      action.option.value === '00'
    ) {
      
      setSelectedOptionChapter(value.filter((value) => value.value === '00'))
      setSelectedValueChapter(['00'])
    } else {
      setSelectedOptionChapter(value.filter((value) => value.value !== '00'))
      const arr = []
      value
        .filter((value) => value.value !== '00')
        .map((x) => {
          return arr.push(x.value)
        })
     
      setSelectedValueChapter(arr)
    }
    // if (value) {
    //   setSelectedValueChapter(value.value)
    // } else {
    //   setSelectedValueChapter(null)
    // }

    subProductSelectRef.current.clearValue()
    setSelectedValueProduct([])
    subChapterSelectRef.current.clearValue()
    setSelectedValueSubChapter([])
  }

  // handle selection SubChapter
  const handleChangeSubChapter = (value, action) => {
    if (
      value &&
      value.find((element) => element.value === '00') &&
      // action.option &&
      action.option.value === '00'
    ) {
      
      setSelectedOptionSubChapter(value.filter((value) => value.value === '00'))
      setSelectedValueSubChapter(['00'])
    } else {
      setSelectedOptionSubChapter(value.filter((value) => value.value !== '00'))
      const arr = []
      value
        .filter((value) => value.value !== '00')
        .map((x) => {
          return arr.push(x.value)
        })
      setSelectedValueSubChapter(arr)
    }

    // if (value) {
    //   setSelectedValueSubChapter(value.value)
    // } else {
    //   setSelectedValueSubChapter(null)
    // }
    subProductSelectRef.current.clearValue()
    setSelectedValueProduct([])
  }

  // handle selection Product
  const handleChangeProduct = (value, action) => {
    if (
      value &&
      value.find((element) => element.value === '00') &&
      // action.option &&
      action.option.value === '00'
    ) {
      setSelectedOptionProduct(value.filter((value) => value.value === '00'))
      setSelectedValueProduct(['00'])
     
    } else {
      setSelectedOptionProduct(value.filter((value) => value.value !== '00'))
      const arr = []
      value
        .filter((value) => value.value !== '00')
        .map((x) => {
          return arr.push(x.value)
        })
      setSelectedValueProduct(arr)
    }

    // if (value) {
    //   // setSelectedValueProduct(value.value)
    //   const arr = []
    //   value.map((x) => {
    //     return arr.push(x.value)
    //   })
    //   setSelectedValueProduct(arr)
    // } else {
    //   setSelectedValueProduct([])

    //   // setSelectedValueProduct(null)
    // }
  }

  // handle selection Region
  const handleSelectRegion = (value) => {
    
    if (value) {
      

      setSelectedValueRegion(value.value)
    } else {
      setSelectedValueRegion(null)
    }
  }

  // handle selection Radio region
  const handleChangeRegion = (e) => {
    // selectByRef.current.clearValue()
    selectRegionRef.current.clearValue()
    setSelectRadioRegion(e.target.value)
  }

  // handle selection Year
  const handleChangeYear = (value) => {
   
    const arr = []
    value.map((x) => {
      return arr.push(x.value)
    })
    arr.sort()
    setSelectedValueYear(arr)
  }

  // handle selection Month
  const handleChangeMonth = (value, action) => {
    

    if (
      value &&
      value.find((element) => element.value === 'year') &&
      // action.option &&
      action.option.value === 'year'
    ) {
      setSelectedOptionMonth(value.filter((value) => value.value === 'year'))
      setSelectedValueMonth(['year'])

      // setSelectedOptionMonth([{ value: 'year', label: 'Tous les mois' }])
      
    } else {
      setSelectedOptionMonth(value.filter((value) => value.value !== 'year'))
      const arr = []
      value
        .filter((value) => value.value !== 'year')
        .map((x) => {
          return arr.push(x.value)
        })
      setSelectedValueMonth(arr)
    }
    // console.log()
    // const arr = []
    // value.map((x) => {
    //   return arr.push(x.value)
    // })
    // setSelectedValueMonth(arr)
  }

  // handle selection Type Trade
  const handleChangeTapeTrade = (value) => {
    if (value) {
      setSelectedValueTypeTrade(value.value)
    } else {
      setSelectedValueTypeTrade(null)
    }
  }

  // handle selection By
  const handleChangeBy = (value) => {
    if (value) {
      setSelectedValueBy(value.value)
    } else {
      setSelectedValueBy(null)
    }
  }

  // handle selection Display
  const handleChangeDisplay = (value) => {
    

    if (value) {
      if (value.value === 'value_dzd') {
        setTitleUnit('Dinar Algérien')
      } else if (value.value === 'value_usd') {
        setTitleUnit('Dollar Américain')
      } else {
        setTitleUnit('Quantité (Kg)')
      }
      setSelectedValueDisplay(value.value)
    } else {
      setSelectedValueDisplay(null)
    }
  }

  // // Function to call api for getting chapitres
  const GetChapters = async (e) => {
    const response = await axiosInstance
      .get('api/trade/chapter/')
      .then((res) => {
        const data = res.data
        const arr = [{ value: '00', label: 'Tous les chapitres' }]

        data.map((x) => {
          return arr.push({ value: x.id, label: x.name })
        })
        setChapters(arr)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  // Function to call api for getting subchapter by chpater
  const GeTSubChapter = async (chapter_id) => {
    // console.log(chapter_id)
    if (chapter_id !== '00') {
      const response = await axiosInstance
        .post('api/trade/subchapterlist/', { chapter_id: chapter_id })
        .then((res) => {
          const data = res.data
          const arr = [{ value: '00', label: 'Tous les produits' }]
         

          data.map((x) => {
            return arr.push({ value: x.id, label: x.name })
          })
          setSubChapters(arr)
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      setSubChapters([{ value: '00', label: 'Tous les produits' }])
    }
  }

  // Function to call api for getting product by subchpater
  const GeTProduct = async (subchapter_id) => {
    // console.log(subchapter_id)
    if (subchapter_id !== '00') {
      const response = await axiosInstance
        .post('api/trade/productlist/', { subchapter_id: subchapter_id })
        .then((res) => {
          const data = res.data
          const arr = []
          arr.push({ value: '00', label: 'Toutes les sous-positions' })
         

          data.map((x) => {
            return arr.push({ value: x.id, label: x.name })
          })
          setProducts(arr)
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      setProducts([{ value: '00', label: 'Toutes les sous-positions' }])
    }
  }

  // Function to call api for getting region countrizs or regions
  const GetRegions = async (selectRadioRegion) => {
    // console.log(selectRadioRegion)
    let region = ''
    if (selectRadioRegion === 'countries') {
      region = 'country'
    } else {
      region = 'regionlist'
    }
    const response = await axiosInstance
      .get('api/trade/' + region + '/')
      .then((res) => {
        const data = res.data
      
        const arr = []

        if (selectRadioRegion === 'countries') {
          arr.push({ value: '00', label: 'Aucun' })
          data.map((x) => {
            return arr.push({ value: x.code, label: x.name_fr })
          })
        } else {
          arr.push({ value: '00', label: 'Aucun' })
          data.map((x) => {
            return arr.push({ value: x.id, label: x.name_region })
          })
        }
        setRegions(arr)
      })
      .catch(function (error) {
        console.log(error)
      })
    // setValuesBy([])
    // selectRegionRef.current.setValue(regions[0])
  }

  // get the title of table
  const getTitleTable = () => {
    // console.log('titel get region', selectedValueRegion)
    let type_value = ''
    let type_value_2 = ''
    if (selectedValueTypeTrade === 'import') {
      type_value = 'importé'
      type_value_2 = 'fournisseurs'
    } else {
      type_value = 'exporté'
      type_value_2 = 'importateurs'
    }

    if (selectedValueBy === 'country' && selectedValueRegion === '00') {
      return (
        'Liste des pays ' +
        type_value_2 +
        ' pour un produit ' +
        type_value +
        " par l'Algerie"
      )
    } else if (selectedValueBy === 'product' && selectedValueRegion !== '00') {
      // return 'Commerce bilatéral entre Algerie et Afghanistan importation'
      return (
        'Commerce bilatéral entre Algerie et ' +
        regions.find((x) => x.value === selectedValueRegion).label
      )
    } else if (selectedValueBy === 'product' && selectedValueRegion === '00') {
      return 'Liste des produits ' + type_value + " par l'Algerie"
    } else if (
      selectedValueBy === 'continent' &&
      selectedValueRegion === '00'
    ) {
      return (
        'Liste des continents ' +
        type_value_2 +
        ' pour un produit ' +
        type_value +
        " par l'Algerie"
      )
    } else if (
      selectedValueBy === 'economic_region' &&
      selectedValueRegion === '00'
    ) {
      return (
        'Liste des régions économiques ' +
        type_value_2 +
        ' pour un produit ' +
        type_value +
        " par l'Algerie"
      )
    } else if (
      selectedValueBy === 'country' &&
      selectedValueRegion !== '00' &&
      selectRadioRegion == 'regions'
    ) {
      return (
        'Liste des pays ' +
        type_value_2 +
        " de l'" +
        regions.find((x) => x.value === selectedValueRegion).label +
        ' pour un produit ' +
        type_value +
        " par l'Algerie"
      )
    }
  }

  // get the subtitle of table
  const getSubTitleTable = () => {
    if (selectedValueChapter === '00') {
      return 'Produit : Total des produits'
    } else if (
      selectedValueChapter !== '00' &&
      selectedValueSubChapter === '00'
    ) {
      return (
        'Produit : ' +
        chapters.find((x) => x.value === selectedValueChapter).value +
        '-' +
        chapters.find((x) => x.value === selectedValueChapter).label
      )
    } else if (
      selectedValueChapter !== '00' &&
      selectedValueSubChapter !== '00'
      //&& selectedValueProduct.length === 0
    ) {
      return (
        'Produit : ' +
        subchapters.find((x) => x.value === selectedValueSubChapter).value +
        '-' +
        subchapters.find((x) => x.value === selectedValueSubChapter).label
      )
    }
  }

  // Call function GetChapitre
  useEffect(() => {
    GetChapters()
  }, [])

  // Call function GetSubChpater
  useEffect(() => {
 
    GeTSubChapter(selectedValueChapter)
  }, [selectedValueChapter])

  // Call function GetProduct
  useEffect(() => {
    
    GeTProduct(selectedValueSubChapter)
  }, [selectedValueSubChapter])

  // Call function GetRegion
  useEffect(() => {
    GetRegions(selectRadioRegion)
  }, [selectRadioRegion])

  // Function get Css Class for Evolution column
  function getCssClass(value, row, valueBy) {
    if (selectedValueTypeTrade == 'import') {
      if (value > 75) return 'high'
      else if (value > 50) return 'medium'
      else if (value > 25) return 'test'
      else if (value > 10) return 'test2'
      return 'low'
    } else {
      if (value > 75) return 'low'
      else if (value > 50) return 'test2'
      else if (value > 25) return 'test'
      else if (value > 10) return 'medium'
      return 'high'
    }
  }

  // Function get Css Class for Total row
  function getCssClassTotal(row, valueBy) {
    
    if (row[valueBy] === 'Total') {
      
      return 'total'
    }
    // if (selectedValueTypeTrade == 'import') {
    //   if (value > 75) return 'high'
    //   else if (value > 50) return 'medium'
    //   else if (value > 25) return 'test'
    //   else if (value > 10) return 'test2'
    //   return 'low'
    // } else {
    //   if (value > 75) return 'low'
    //   else if (value > 50) return 'test2'
    //   else if (value > 25) return 'test'
    //   else if (value > 10) return 'medium'
    //   return 'high'
    // }
  }

  // GET  DATA
  useEffect(() => {
    if (
      selectedValueChapter != null &&
      selectedValueRegion != null &&
      selectedValueTypeTrade != null &&
      selectedValueBy != null &&
      selectedValueDisplay != null &&
      selectedValueYear.length > 0 &&
      selectedValueMonth.length > 0
    ) {
      setLoading(true)
      setPending(true)
      
      

      ////////Set Columns Name DataTable
      const arr_columns = []
      selectedValueYear.map((x) => {
        
        return arr_columns.push({
          name: x,
          selector: (row) => row[optionsYears.find((y) => y.value === x).label],
          // format: (row) =>
          //   Intl.NumberFormat().format(
          //     row[optionsYears.find((y) => y.value === x).label]
          //   ),
          // cell: (row) =>
          //   Intl.NumberFormat().format(
          //     row[optionsYears.find((y) => y.value === x).label]
          //   ),
          cell: (row) =>
            Intl.NumberFormat().format(
              row[optionsYears.find((y) => y.value === x).label]
            ),

          sortable: true,
          right: true,
        })
      })
      // <StyledCellText> {/* </StyledCellText> */}
      // <StyledRowTotal
      //       className={getCssClassTotal(
      //         row,
      //         valuesBy.find((x) => x.value === selectedValueBy).value
      //         // row.Evolution,
      //         // row,
      //       )}
      //     >  </StyledRowTotal>
      arr_columns.unshift({
        name: valuesBy.find((x) => x.value === selectedValueBy).label,
        selector: (row) =>
          row[valuesBy.find((x) => x.value === selectedValueBy).value],
        cell: (row) =>
          row[valuesBy.find((x) => x.value === selectedValueBy).value],
        wrap: true,
        // (row) => row.country_name
      })

      // Add Column Evolution if the minimum of years select is 2
      // <StyledCell
      //         className={getCssClass(row.Evolution, selectedValueTypeTrade)}
      //       > </StyledCell>
      if (selectedValueYear.length >= 2) {
        arr_columns.push({
          name: 'Evolution',
          selector: (row) => row.Evolution,
          cell: (row) => (
            <StyledCell
              className={getCssClass(
                row.Evolution,
                row,
                valuesBy.find((x) => x.value === selectedValueBy).label
              )}
            >
              {row.Evolution}%
            </StyledCell>
          ),
          // format: (row) =>
          //   Intl.NumberFormat().format(
          //     row[optionsYears.find((y) => y.value === x).label]
          //   ),

          sortable: true,
          center: true,
        })
      }

     
      setColumns(arr_columns)

      // Call Api for getting Data
      const response = axiosInstance
        .post('api/trade/data/', {
          chapter: selectedValueChapter,
          subchapter: selectedValueSubChapter,
          product: selectedValueProduct,
          region_type: selectRadioRegion,
          region: selectedValueRegion,
          years: selectedValueYear,
          month: selectedValueMonth,
          type_trade: selectedValueTypeTrade,
          display_value: selectedValueDisplay,
          display_by: selectedValueBy,
        })
        .then((res) => {
          const data = res.data
          

          // Set rows datatable
          const arr_data_table = []
          const arr_data_table_test = []
          // selectedValueYear.map((y, index_year) => {
          //   // console.log(y)
          //   return arr_data_table_test.push({
          //     id: index_year,
          //     [y]: y,
          //   })
          // })
          // console.log(arr_data_table_test)
          // console.log(data[selectedValueYear[0]])
          // data['2012'].map((x, index) => {
          //   // console.log(index, x)
          //   return arr_data_table.push({
          //     id: index,
          //     [selectedValueBy]: data['country_name'][index],
          //     2012: x,
          //   })
          //   // console.log({
          //   //   id: index,
          //   //   country: x.country__name_fr,
          //   //   value_2012: x.value_usd,
          //   // })
          // })
          // selectedValueYear.map((x, index) => {
          //   return arr_data_table.push({ id: index, x: x, value_x: 1000 })
          //   // console.log(x)
          // })
          setPending(false)

          setDataTable(data)
          setDataTableCopy(data)
          setTitleTable(getTitleTable)
          // setSubTitleTable(getSubTitleTable)
          setLoading(false)

          // subHideFilterRef.current.click()
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [
    selectedValueChapter,
    selectedValueSubChapter,
    selectedValueProduct,
    selectRadioRegion,
    selectedValueRegion,
    selectedValueYear,
    selectedValueMonth,
    selectedValueTypeTrade,
    selectedValueBy,
    selectedValueDisplay,
  ])

  return (
    <div className='row row-cards mb-4'>
      <div className='col-12'>
        <form action='' method='post' className='card'>
          <div
            className='card-header '
            style={{
              borderColor: '#0073AD',
              borderWidth: '1px',
              borderStyle: 'solid',
            }}
          >
            <h4 className='card-title '>Filtre</h4>
            <div className='card-actions'>
              <a
                href='#'
                className='btn'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseExample'
                aria-expanded='false'
                aria-controls='collapseExample'
                ref={subHideFilterRef}
              >
                <IconChevronDown size={24} className='btn-icon-only' />
              </a>
            </div>
            {/* <button
                    className='btn btn-primary'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseExample'
                    aria-expanded='false'
                    aria-controls='collapseExample'
                  >
                    Button with data-bs-target
                  </button> */}
          </div>
          <div
            className='card-body collapse '
            style={{
              borderColor: '#0073AD',
              borderWidth: '1px',
              borderStyle: 'solid',
            }}
            id='collapseExample'
            toggle={false}
          >
            <div className='row row-cards'>
              {/* Chapter SubChapter Product */}
              <div className='col-sm-6 col-md-6'>
                <SelectChapter
                  chapters={chapters}
                  handleChangeChapter={handleChangeChapter}
                  setChapters={setChapters}
                  selectedOptionChapter={selectedOptionChapter}
                />

                <SelectSubChapter
                  subchapters={subchapters}
                  handleChangeSubChapter={handleChangeSubChapter}
                  subChapterSelectRef={subChapterSelectRef}
                  selectedOptionSubChapter={selectedOptionSubChapter}
                />

                <SelectProduct
                  products={products}
                  value={selectedOptionProduct}
                  handleChangeProduct={handleChangeProduct}
                  subProductSelectRef={subProductSelectRef}
                />
              </div>
              <div className='col-sm-6 col-md-6'>
                {/* Country / Region */}
                <div className='row row-cards'>
                  <div className='col-sm-4 col-md-4'>
                    <div className='mb-3'>
                      <div className='form-label'>Inline Radios</div>
                      <div>
                        <label className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='radios_region'
                            value='countries'
                            checked={selectRadioRegion === 'countries'}
                            onChange={handleChangeRegion}
                          />
                          <span className='form-check-label'>Pays</span>
                        </label>
                        <label className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='radios_region'
                            value='regions'
                            checked={selectRadioRegion === 'regions'}
                            onChange={handleChangeRegion}
                          />
                          <span className='form-check-label'>Régions</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-8 col-md-8'>
                    <div className='mb-3'>
                      <label className='form-label mb-3'></label>
                      <Select
                        defaultValue={[regions[0]]}
                        options={regions}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        id='region'
                        name='region'
                        isSearchable={true}
                        isClearable={true}
                        placeholder='Sélectionner la région'
                        ref={selectRegionRef}
                        onChange={handleSelectRegion}
                      />
                    </div>
                  </div>
                </div>

                <div className='row row-cards'>
                  {/* Year */}
                  <div className='col-sm-6 col-md-6'>
                    <div className='mb-2'>
                      <label className='form-label'>Année</label>
                      <Select
                        defaultValue={[optionsYears[0], optionsYears[1]]}
                        options={optionsYears}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        isMulti
                        id='year'
                        name='year'
                        isSearchable={true}
                        isClearable={true}
                        placeholder='Sélectionner l"année'
                        // ref={subProductSelectRef}
                        onChange={handleChangeYear}
                      />
                    </div>
                  </div>
                  {/* Month */}
                  <div className='col-sm-6 col-md-6'>
                    <div className='mb-2'>
                      <label className='form-label'>Mois</label>
                      <Select
                        // defaultValue={[optionsMonth[9], optionsMonth[10]]}
                        options={optionsMonth}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        isMulti
                        value={selectedOptionMonth}
                        id='month'
                        name='month'
                        isSearchable={true}
                        isClearable={true}
                        placeholder='Sélectionner le mois'
                        // ref={selectMontRef}
                        onChange={handleChangeMonth}
                      />
                    </div>
                  </div>
                  {/* <div className='col-sm-6 col-md-6'>
                          <div className='mb-2'>
                            <label className='form-label'>Type</label>
                            <Select
                              defaultValue={[optionsTypeTrade[0]]}
                              options={optionsTypeTrade}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              id='type'
                              name='type'
                              isSearchable={true}
                              isClearable={true}
                              placeholder='Sélectionner le type'
                              // ref={subProductSelectRef}
                              onChange={handleChangeTapeTrade}
                            />
                          </div>
                        </div> */}
                </div>

                <div className='row row-cards'>
                  {/* Type */}
                  <div className='col-sm-4 col-md-4'>
                    <div className='mb-2'>
                      <label className='form-label'>Type</label>
                      <Select
                        defaultValue={[optionsTypeTrade[0]]}
                        options={optionsTypeTrade}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        id='type'
                        name='type'
                        isSearchable={true}
                        isClearable={true}
                        placeholder='Sélectionner le type'
                        // ref={subProductSelectRef}
                        onChange={handleChangeTapeTrade}
                      />
                    </div>
                  </div>
                  {/* By */}
                  <div className='col-sm-4 col-md-4'>
                    <div className='mb-3'>
                      <label className='form-label'>Par</label>
                      <Select
                        // isDisabled={isDisabledBy}
                        // defaultValue={[optionsBy[indexselectby]]}
                        options={optionsBy}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        id='by'
                        name='by'
                        isSearchable={true}
                        isClearable={true}
                        placeholder='Sélectionner '
                        // ref={selectByRef}
                        onChange={handleChangeBy}
                      />
                    </div>
                  </div>
                  {/* Display */}
                  <div className='col-sm-4 col-md-4'>
                    <div className='mb-3'>
                      <label className='form-label'>Afficher</label>
                      <Select
                        defaultValue={[optionsValues[0]]}
                        options={optionsValues}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        id='value'
                        name='value'
                        isSearchable={true}
                        isClearable={true}
                        placeholder='Sélectionner la valeur'
                        // ref={subProductSelectRef}
                        onChange={handleChangeDisplay}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='card-footer text-end'>
                  <button type='submit' className='btn btn-primary'>
                    Update Profile
                  </button>
                </div> */}
        </form>
      </div>
    </div>
  )
}

export default Filter
