import React, { useContext } from 'react'
import AuthContext from '../context/AuthContext'
import { Navigate, Outlet } from 'react-router-dom'
const PrivateRoutesAdmin = () => {
  const { user } = useContext(AuthContext)
  // console.log('PrivateRoutesAdmin : ', user.role)
  return user.role === 'Admin' ? <Outlet /> : <Navigate to='/home' />
}

export default PrivateRoutesAdmin
