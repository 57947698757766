import React from 'react'
import { useLocation } from 'react-router-dom'
function HomeAdmin() {
  const location = useLocation()
  return (
    <div>
      Home ADMIN <h2>Current Pathname {location.pathname}</h2>
    </div>
  )
}

export default HomeAdmin
