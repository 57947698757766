import React, { useContext, useEffect, useState } from 'react'
import TabCountry from '../../component/tools/administration/TabCountry'
import TabContinent from '../../component/tools/administration/TabContinent'
import axiosInstance from '../../utils/AxiosInstance'
import AuthContext from '../../context/AuthContext'
const Regions = () => {
  const { user } = useContext(AuthContext)
  // const [tabCountry, setTabCountry] = useState(true)
  // const [tabContinent, setTabContinent] = useState(false)

  // // handle Click Tab  Continent
  // const handleChangeContinent = (value) => {
  //   console.log('tab Continent')
  //   setTabContinent(!tabContinent)
  // }

  const [continents, setContinents] = useState([])
  const [selectedValueContinent, setSelectedValueContinent] = useState(null)
  const [continentId, setContinentId] = useState('')

  // handle selection Continent
  const handleChangeContinent = (value) => {
    setSelectedValueContinent(value)
    if (value) {
      setContinentId(value.value)
    } else {
      setContinentId('')
    }
  }

  // Function to get Continent
  const getContinent = async () => {
    const response = await axiosInstance
      .get('api/trade/continent/')
      .then((res) => {
        const data = res.data
        

        const arr = []

        data.map((x) => {
          return arr.push({ value: x.id, label: x.name_fr })
        })
        setContinents(arr)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(() => {
    getContinent()
  }, [])
  if (Object.keys(user).length > 0 && user.permissions['region_management']) {
    return (
      <>
        {/* Page header */}
        <div className='page-header d-print-none'>
          <div className='container-xl'>
            <div className='row g-2 align-items-center'>
              <div className='col'>
                {/* Page pre-title */}
                {/* <div className='page-pretitle'>Overview</div> */}
                <h2 className='page-title'>Régions </h2>
              </div>
            </div>
          </div>
        </div>
        {/* Page body */}
        <div className='page-body'>
          <div className='container-xl'>
            {/* tabs */}
            <div className='row row-cards '>
              <div className='col-12'>
                <div className='card ' style={{ borderColor: '#0073AD' }}>
                  {/* tabs header*/}
                  <div className='card-header  '>
                    <ul
                      className='nav nav-tabs card-header-tabs nav-fill '
                      data-bs-toggle='tabs'
                    >
                      <li className='nav-item '>
                        <a
                          href='#tabs-country'
                          className='nav-link active'
                          data-bs-toggle='tab'
                        >
                          Pays
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a
                          href='#tabs-continent'
                          className='nav-link'
                          data-bs-toggle='tab'
                        >
                          Continents
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a
                          href='#tabs-activity-7'
                          className='nav-link'
                          data-bs-toggle='tab'
                        >
                          Régions économique
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='card-body'>
                    <div className='tab-content'>
                      {/* Tab Countries */}
                      <div className='tab-pane active show' id='tabs-country'>
                        <TabCountry
                          continents={continents}
                          selectedValueContinent={selectedValueContinent}
                          setSelectedValueContinent={setSelectedValueContinent}
                          continentId={continentId}
                          setContinentId={setContinentId}
                          handleChangeContinent={handleChangeContinent}
                        />
                      </div>
                      {/* Tab Continent */}
                      <div className='tab-pane' id='tabs-continent'>
                        <TabContinent continents={continents} />
                      </div>

                      {/* Tab EconomicRegion */}
                      <div className='tab-pane' id='tabs-economicregion'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return null
  }
}

export default Regions
