import React, { useEffect, useState } from 'react'
import ModalAddSubChapter from './ModalAddSubChapter'
import {
  IconDotsVertical,
  IconPlus,
  IconRefresh,
  IconX,
} from '@tabler/icons-react'
import Select from 'react-select'
import DataTables from '../DataTables'
import axiosInstance from '../../../utils/AxiosInstance'
import ModalSuccess from './ModalSuccess'
import ModalError from './ModalError'
function TabSubChapter({ seletedTab }) {
  const [loading, setLoading] = useState(false)
  const [searchFilterSubChapter, setsearchFilterSubChapter] = useState('')
  const [show, setShow] = useState(false)
  const [showError, setShowError] = useState(false)
  const [message, setMessage] = useState('')
  const [titleMessage, settitleMessage] = useState('')
  const [messageError, setMessageError] = useState('')
  const [chapters, setChapters] = useState([])
  const [selectedValueChapter, setSelectedValueChapter] = useState(null)
  const [refreshSubChapter, setRefreshSubChapter] = useState(true)
  const [titleModalSubchapter, settitleModalSubchapter] = useState('')
  const [itemSelectedSubChapter, setItemSelectedSubChapter] = useState(null)
  // SubChapter Datatable
  const [dataTableSubChapter, setDataTableSubChapter] = useState([])
  const [dataTableSubChapterCopy, setDataTableSubChapterCopy] = useState([])

  // SubChapter Colmuns
  const [columnsSubChapter, setColumnsSubChapter] = useState([
    {
      name: 'Id',
      selector: (row) => row.id,
      width: '80px',
      // sortable: true,
    },
    {
      name: 'Nom',
      selector: (row) => row.name,
      wrap: true,
      // sortable: true,
    },
    {
      name: 'Nom douanier',
      selector: (row) => row.name_douanier,
      wrap: true,
      // sortable: true,
    },
    {
      name: 'Actions',
      button: true,
      cell: (row) => (
        // <button
        //   className='btn btn-outline btn-xs'
        //   onClick={(e) => handleButtonClick(e, row.id)}
        // >
        //   Edit
        // </button>
        <div className='dropdown '>
          <a
            href='#'
            className='btn-action dropdown-toggle '
            data-bs-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <IconDotsVertical size={24} className='icon' />
          </a>
          <div className='dropdown-menu dropdown-menu-end'>
            <a
              className='dropdown-item btn'
              data-bs-toggle='modal'
              data-bs-target='#add-subchapter'
              onClick={(e) => handleUpdateSubChapter(row.id)}
            >
              <IconRefresh size={24} className='icon' />
              Modifier
            </a>
          </div>
        </div>
      ),
    },
  ])

  // Handle Update SubChapter
  const handleUpdateSubChapter = (id) => {
    setItemSelectedSubChapter(id)
    settitleModalSubchapter('Modifier produit')
  }

  // Handle Add SubChapter
  const handleAddSubChapter = (id) => {
    setItemSelectedSubChapter(null)
    settitleModalSubchapter('Nouveau produit')
  }

  // Function to call api for getting chapter
  const getChapter = async () => {
    const response = await axiosInstance
      .get('api/trade/chapter/')
      .then((res) => {
        const data = res.data
       

        const arr = []

        data.map((x) => {
          return arr.push({ value: x.id, label: x.name })
        })
        setChapters(arr)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  // handle selection Chapter
  const handleChangeChapter = (value) => {
  
    setLoading(true)
    if (value) {
      setSelectedValueChapter(value.value)
    } else {
      setSelectedValueChapter(null)
    }
  }

  // Function to call api for getting subchapter by chpater
  const GeTSubChapter = async (chapter_id) => {
    
    //clear search input
    setsearchFilterSubChapter('')

    if (chapter_id !== '00') {
      const response = await axiosInstance
        .get('api/trade/getsubchapter/' + chapter_id + '/')
        .then((res) => {
          const data = res.data
         
          setDataTableSubChapter(data)
          setDataTableSubChapterCopy(data)
        })
        .catch(function (error) {
          console.log(error)
        })
      setLoading(false)
    }
  }

  // Function to clear search input
  const clearSearchSubChapter = () => {

    setsearchFilterSubChapter('')
    setDataTableSubChapter(dataTableSubChapterCopy)
  }

  // handle search
  const handleSerachSubChapter = (e) => {
   
    setsearchFilterSubChapter(e.target.value)
    if (e.target.value === '') {
      
      setDataTableSubChapter(dataTableSubChapterCopy)
    } else {
      let filteredvalue_traited = []
      const filteredvalue = dataTableSubChapterCopy.reduce(
        (accumulator, value) => {
        

          if (
            value['name'].toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            accumulator.push(value)
          }
          if (
            value['name_douanier']
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          ) {
            accumulator.push(value)
          }
          if (
            value['id'].toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            accumulator.push(value)
          }

          return accumulator
        },
        []
      )
      
      filteredvalue_traited = filteredvalue.filter(
        (item, index) => filteredvalue.indexOf(item) === index
      )

      if (filteredvalue_traited.length > 0) {
        setDataTableSubChapter(filteredvalue_traited)
      }
      
    }
  }

  // UseEffect for getting chapter depends in selecting tab
  useEffect(() => {
    if (seletedTab === 'tabs-subchapter') {
      getChapter()
    }
  }, [seletedTab])

  // UseEffect for getting subchapter depends in chapter
  useEffect(() => {
    if (seletedTab === 'tabs-subchapter') {
      GeTSubChapter(selectedValueChapter)
    }
  }, [selectedValueChapter, refreshSubChapter, seletedTab])

  return (
    <>
      <div className='row g-2 align-items-center mb-2'>
        {/* Chapter */}
        <div class='col-sm-4 col-md-4'>
          {/* <div class='page-pretitle'>Overview</div>
                          <h2 class='page-title'>Horizontal layout</h2> */}
          <Select
            options={chapters}
            defaultValue={chapters[0]}
            getOptionLabel={(e) => e.value + '-' + e.label}
            getOptionValue={(e) => e.value}
            id='chapter'
            name='chapter'
            isSearchable={true}
            isClearable={true}
            placeholder='Sélectionner le chapitre'
            onChange={handleChangeChapter}
          />
        </div>
        {/* Search */}
        <div class='col-sm-3 col-md-3'>
          <div className='input-group input-group-flat'>
            <input
              type='text'
              placeholder='Recherche'
              className='form-control '
              required
              id='filter'
              name='filter'
              value={searchFilterSubChapter}
              onChange={(e) => handleSerachSubChapter(e)}
            />
            <span className='input-group-text'>
              <a
                role='button'
                class='link-secondary'
                title='Clear search'
                data-bs-toggle='tooltip'
                onClick={() => clearSearchSubChapter()}
              >
                {searchFilterSubChapter !== '' && (
                  <IconX size={24} className='icon' />
                )}
              </a>
            </span>
          </div>
        </div>
        {/* Refresh and Add */}
        <div className='col-auto ms-auto d-print-none'>
          <div className='btn-list'>
            <button
              className='btn btn-primary'
              onClick={() => setRefreshSubChapter(!refreshSubChapter)}
              style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
            >
              <IconRefresh
                size={24}
                className='btn-icon-only'
                style={{ color: '#ffffff' }}
              />
            </button>
            <a
              href='#'
              className='btn btn-primary'
              data-bs-toggle='modal'
              data-bs-target='#add-subchapter'
              onClick={() => handleAddSubChapter()}
              style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
            >
              <IconPlus
                size={24}
                className='icon'
                style={{ color: '#ffffff' }}
              />
              Ajouter
            </a>
          </div>
        </div>
      </div>
      <div className='card' style={{ borderColor: '#0073AD' }}>
        <div className='card-body p-0'>
          {loading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                padding: '1rem',
                texAalign: 'center',
              }}
            >
              <div
                className='spinner-grow  '
                style={{
                  width: '3rem',
                  height: '3rem',
                  color: '#0073AD',
                }}
                role='status'
              >
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : dataTableSubChapter.length === 0 ? (
            <div className='empty'>
              <p class='empty-title'>Aucun résultat trouvé</p>
            </div>
          ) : (
            <DataTables
              columns={columnsSubChapter}
              data_table={dataTableSubChapter}
              // pending={pending}
            />
          )}
          {/* {dataTableSubChapter.length === 0 ? (
            <div className='empty'>
              <p class='empty-title'>Aucun résultat trouvé</p>
            </div>
          ) : (
            <DataTables
              columns={columnsSubChapter}
              data_table={dataTableSubChapter}
              // pending={pending}
            />
          )} */}
        </div>
      </div>
      {/* Modal Add Product */}
      <ModalAddSubChapter
        chapters={chapters}
        refreshSubChapter={refreshSubChapter}
        setRefreshSubChapter={setRefreshSubChapter}
        titleModalSubchapter={titleModalSubchapter}
        itemSelectedSubChapter={itemSelectedSubChapter}
        setItemSelectedSubChapter={setItemSelectedSubChapter}
        dataTableSubChapter={dataTableSubChapter}
        setShow={setShow}
        setMessage={setMessage}
        setShowError={setShowError}
        setMessageError={setMessageError}
        settitleMessage={settitleMessage}
      />
      <ModalSuccess
        show={show}
        setShow={setShow}
        message={message}
        titleMessage={titleMessage}
      />
      <ModalError
        showError={showError}
        setShowError={setShowError}
        messageError={messageError}
        titleMessage={titleMessage}
      />
    </>
  )
}

export default TabSubChapter
