import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import TopHeader from '../component/main/TopHeader'
import Header from '../component/main/Header'
import SidebarAdmin from '../component/main/SidebarAdmin'
import { useContext } from 'react'
import AuthContext from '../context/AuthContext'
const Layout = () => {
  const { adminRoutes } = useContext(AuthContext)
  const location = useLocation()
  // console.log('location from layout : ', location.pathname)

  if (adminRoutes.includes(location.pathname)) {
    return (
      <>
        <div className='page'>
          <SidebarAdmin />
          <div className='page-wrapper'>
            <Outlet />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <div className='layout-fluid'>
        {/* className='page' */}
        <div className='page'>
          <TopHeader />
          <Header />
          <Outlet />
        </div>
      </div>
    )
  }
}

export default Layout
