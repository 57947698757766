import React from 'react'

import Select from 'react-select'
const SelectChapter = ({
  chapters,
  setChapters,
  handleChangeChapter,
  selectedOptionChapter,
}) => {
  return (
    <div className='mb-2'>
      <label className='form-label'>Chapitre</label>

      <Select
        // defaultValue={[chapters[0]]}
        options={chapters}
        getOptionLabel={(e) => e.value + '-' + e.label}
        getOptionValue={(e) => e.value}
        isMulti
        value={selectedOptionChapter}
        id='chapter'
        name='chapter'
        isSearchable={true}
        isClearable={true}
        placeholder='Sélectionner le chapitre'
        onChange={handleChangeChapter}
      />
    </div>
  )
}

export default SelectChapter
