import React, { useEffect, useState } from 'react'
import {
  IconDotsVertical,
  IconPlus,
  IconRefresh,
  IconX,
} from '@tabler/icons-react'
import DataTables from '../DataTables'
import ModalAddChapter from './ModalAddChapter'
import ModalSuccess from './ModalSuccess'
import axiosInstance from '../../../utils/AxiosInstance'
import ModalError from './ModalError'
const TabChapter = ({ seletedTab }) => {
  const [loading, setLoading] = useState(false)
  const [searchFilterChapter, setsearchFilterChapter] = useState('')
  const [show, setShow] = useState(false)
  const [showError, setShowError] = useState(false)
  const [message, setMessage] = useState('')
  const [titleMessage, settitleMessage] = useState('')
  const [messageError, setMessageError] = useState('')
  const [itemSelectedChapter, setItemSelectedChapter] = useState(null)
  const [titleModalchapter, settitleModalchapter] = useState('')
  // const [chapters, setChapters] = useState([])
  const [refreshChapter, setRefreshChapter] = useState(true)
  // Chapter Datatable
  const [dataTableChapter, setDataTableChapter] = useState([])
  const [dataTableChapterCopy, setDataTableChapterCopy] = useState([])

  // Chapter Colmuns
  const [columnsChapter, setColumnsChapter] = useState([
    {
      name: 'Id',
      selector: (row) => row.id,
      width: '50px',
      // sortable: true,
    },
    {
      name: 'Nom',
      selector: (row) => row.name,
      wrap: true,
      // sortable: true,
    },
    {
      name: 'Nom douanier',
      selector: (row) => row.name_douanier,
      wrap: true,
      // sortable: true,
    },
    {
      name: 'Actions',
      button: true,
      cell: (row) => (
        // <button
        //   className='btn btn-outline btn-xs'
        //   onClick={(e) => handleButtonClick(e, row.id)}
        // >
        //   Edit
        // </button>
        <div className='dropdown '>
          <a
            href='#'
            className='btn-action dropdown-toggle '
            data-bs-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <IconDotsVertical size={24} className='icon' />
          </a>
          <div className='dropdown-menu dropdown-menu-end'>
            <a
              className='dropdown-item btn'
              data-bs-toggle='modal'
              data-bs-target='#add-chapter'
              onClick={(e) => handleUpdateChapter(row.id)}
            >
              <IconRefresh size={24} className='icon' />
              Modifier
            </a>
          </div>
        </div>
      ),
    },
  ])

  // Handle Update Chapter
  const handleUpdateChapter = (id) => {
    setItemSelectedChapter(id)
    settitleModalchapter('Modifier chapitre')
  }

  // Handle Add Chapter
  const handleAddChapter = (id) => {
    setItemSelectedChapter(null)
    settitleModalchapter('Nouveau chapitre')
  }

  // Function to get chapters
  const getChapter = async () => {
    setLoading(true)
    const response = await axiosInstance
      .get('api/trade/chapter/')
      .then((res) => {
        const data = res.data

        const arr = []

        data.map((x) => {
          return arr.push({ value: x.id, label: x.name })
        })
        // setChapters(arr)

        // setPending(false)
        setDataTableChapter(data)
        setDataTableChapterCopy(data)
      })
      .catch(function (error) {
        console.log(error)
      })
    setLoading(false)
  }

  // Function to clear search input
  const clearSearchChapter = () => {
    setsearchFilterChapter('')
    setDataTableChapter(dataTableChapterCopy)
  }

  // handle search
  const handleSearchChapter = (e) => {
    setsearchFilterChapter(e.target.value)
    if (e.target.value === '') {
      setDataTableChapter(dataTableChapterCopy)
    } else {
      let filteredvalue_traited = []
      const filteredvalue = dataTableChapterCopy.reduce(
        (accumulator, value) => {
          if (
            value['name'].toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            accumulator.push(value)
          }
          if (
            value['name_douanier']
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          ) {
            accumulator.push(value)
          }
          if (
            value['id'].toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            accumulator.push(value)
          }

          return accumulator
        },
        []
      )

      filteredvalue_traited = filteredvalue.filter(
        (item, index) => filteredvalue.indexOf(item) === index
      )

      if (filteredvalue_traited.length > 0) {
        setDataTableChapter(filteredvalue_traited)
      }
    }
  }

  useEffect(() => {
    if (seletedTab === 'tabs-chapter') {
      getChapter()
    }
  }, [refreshChapter, seletedTab])

  return (
    <>
      <div className='row g-2 align-items-center mb-2'>
        {/* Search */}
        <div class='col-sm-3 col-md-3'>
          <div className='input-group input-group-flat'>
            <input
              type='text'
              placeholder='Recherche'
              className='form-control '
              required
              id='filter'
              name='filter'
              value={searchFilterChapter}
              onChange={(e) => handleSearchChapter(e)}
            />
            <span className='input-group-text'>
              <a
                role='button'
                class='link-secondary'
                title='Clear search'
                data-bs-toggle='tooltip'
                onClick={() => clearSearchChapter()}
              >
                {searchFilterChapter !== '' && (
                  <IconX size={24} className='icon' />
                )}
              </a>
            </span>
          </div>
        </div>
        {/* Refresh and Add */}
        <div className='col-auto ms-auto d-print-none'>
          <div className='btn-list'>
            <a
              href='#'
              className='btn btn-primary'
              data-bs-toggle='modal'
              data-bs-target='#add-chapter'
              onClick={() => handleAddChapter()}
              style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
            >
              <IconPlus
                size={24}
                className='icon'
                style={{ color: '#ffffff' }}
              />
              Ajouter
            </a>
          </div>
        </div>
      </div>
      <div className='card' style={{ borderColor: '#0073AD' }}>
        <div className='card-body p-0'>
          {loading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                padding: '1rem',
                texAalign: 'center',
              }}
            >
              <div
                className='spinner-grow  '
                style={{
                  width: '3rem',
                  height: '3rem',
                  color: '#0073AD',
                }}
                role='status'
              >
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : dataTableChapter.length === 0 ? (
            <div className='empty'>
              <p class='empty-title'>Aucun résultat trouvé</p>
            </div>
          ) : (
            <DataTables
              columns={columnsChapter}
              data_table={dataTableChapter}
              // pending={pending}
            />
          )}
          {/* {dataTableChapter.length === 0 ? (
            <div className='empty'>
              <p class='empty-title'>Aucun résultat trouvé</p>
            </div>
          ) : (
            <DataTables
              columns={columnsChapter}
              data_table={dataTableChapter}
              // pending={pending}
            />
          )} */}
        </div>
      </div>
      <ModalAddChapter
        refreshChapter={refreshChapter}
        setRefreshChapter={setRefreshChapter}
        titleModalChapter={titleModalchapter}
        itemSelectedChapter={itemSelectedChapter}
        setItemSelectedChapter={setItemSelectedChapter}
        dataTableChapter={dataTableChapter}
        setShow={setShow}
        setMessage={setMessage}
        setShowError={setShowError}
        setMessageError={setMessageError}
        settitleMessage={settitleMessage}
      />
      <ModalSuccess
        show={show}
        setShow={setShow}
        message={message}
        titleMessage={titleMessage}
      />
      <ModalError
        showError={showError}
        setShowError={setShowError}
        messageError={messageError}
        titleMessage={titleMessage}
      />
    </>
  )
}

export default TabChapter
