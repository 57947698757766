import React from 'react'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import AuthContext from '../../context/AuthContext'
const TopHeader = () => {
  const { userName, logoutUser, getAdminRoutes, user } = useContext(AuthContext)
  // console.log('user info from authcontext', user.role)

  return (
    //  Navbar
    <header
      className='navbar navbar-expand-md d-print-none'
      style={{ backgroundColor: '#0073AD' }}
    >
      <div className='container-xl'>
        {/* Logo */}
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbar-menu'
          aria-controls='navbar-menu'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <h1
          className='navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3'
          style={{ color: '#ffffff' }}
        >
          {/* <a href='.'>
            <img
              src='./static/logo.svg'
              width='110'
              height='32'
              alt='Tabler'
              className='navbar-brand-image'
            />
          </a> */}
          Trade App
        </h1>
        <div className='navbar-nav flex-row order-md-last'>
          {/* Profile Option */}
          <div className='nav-item dropdown'>
            <a
              href='#'
              className='nav-link d-flex lh-1 text-reset p-0'
              data-bs-toggle='dropdown'
              aria-label='Open user menu'
            >
              <div className='d-none d-xl-block ps-2'>
                <div style={{ color: '#ffffff' }}>{userName}</div>
              </div>
            </a>
            <div
              className='dropdown-menu dropdown-menu-end dropdown-menu-arrow'
              style={{ backgroundColor: '#0073AD' }}
            >
              {/* <Link
                to='./profile.html'
                className='dropdown-item'
                style={{ color: '#ffffff' }}
              >
                Profile
              </Link> */}
              <div
                className='dropdown-divider'
                style={{ backgroundColor: '#ffffff' }}
              ></div>
              {user.role === 'Admin' && !user.first_login && (
                <Link
                  to='homeadmin'
                  className='dropdown-item'
                  style={{ color: '#ffffff' }}
                  onClick={getAdminRoutes}
                >
                  Administration
                </Link>
              )}
              <Link
                onClick={logoutUser}
                className='dropdown-item'
                style={{ color: '#ffffff' }}
              >
                {' '}
                Se déconnecter
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default TopHeader
