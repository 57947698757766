import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useContext } from 'react'
import AuthContext from '../context/AuthContext'
const PrivateRoutes = () => {
  const { userName, user } = useContext(AuthContext)

  if (userName == null) {
    return <Navigate to='/login' />
  } else if (
    userName != null &&
    Object.keys(user).length > 0 &&
    user.first_login
  ) {
    // console.log('privateroute', user.first_login)
    return <Navigate to='/firstlogin' />
    // return <Outlet />
  } else if (
    userName != null &&
    Object.keys(user).length > 0 &&
    user.first_login === false
  ) {
    // console.log('privateroute', 'null')
    return <Outlet />
  }
  // if (userName === null) {
  //   return <Navigate to='/login' />
  // }
  // else if (path_name === '/home') {
  //   return <Outlet />
  // }
  // else {
  //   return <Outlet />
  // }
  // else if (user.permissions[permissionRoutes[path_name]]) {
  //   console.log(user.permissions[permissionRoutes[path_name]])
  //   return <Outlet />
  // } else {
  //   return <Navigate to='/home' />
  // }
  // return userName ? <Outlet /> : <Navigate to='/login' />
  // return userName ? <Outlet /> : <Navigate to='/login' />
}

export default PrivateRoutes
