import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import axiosInstance from '../../../utils/AxiosInstance'
import { useFormik } from 'formik'
import * as Yup from 'yup'
const ModalAddSubChapter = ({
  chapters,
  refreshSubChapter,
  setRefreshSubChapter,
  titleModalSubchapter,
  itemSelectedSubChapter,
  setItemSelectedSubChapter,
  dataTableSubChapter,
  setShow,
  setMessage,
  setShowError,
  setMessageError,
  settitleMessage,
}) => {
  const selectlRefChapter = useRef()
  const modalRefSubChapter = useRef()

  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [selectedValueChapter, setSelectedValueChapter] = useState(null)
  const [initialValues, setInitialValues] = useState({
    id: '',
    name: '',
    name_douanier: '',
    chapter: '',
  })

  const validationSchema = Yup.object().shape({
    id: Yup.string()
      .min(4, 'Id du produit doit être  4 caractères')
      .max(4, 'Id du produit est trop long !')
      .trim()
      .required('Id du produit est obligatoire'),
    name: Yup.string().trim().required('Nom du produit est obligatoire.'),
    name_douanier: Yup.string()
      .trim()
      .required('Nom dounier du produit  est obligatoire.'),
    chapter: Yup.string().trim().required('Chapitre est obligatoire.'),
    // .test('user_name', "Le Nom d'utilisater exist déja", async (value) => {
    //   try {
    //     if (value != '' && value != null) {
    //       const res = await axiosInstance.post('serie/checkusername', {
    //         user_name: value,
    //       })
    //       return !res.data.username_exist
    //     } else return true
    //   } catch (err) {
    //     console.error(err)
    //   }
    // }),
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,

    onSubmit: (values, errors, touched) => {
      // alert(JSON.stringify(values, null, 2))

      setLoadingSubmit(true)
      if (itemSelectedSubChapter == null) {
        AddSubChapter(
          values.id,
          values.name,
          values.name_douanier,
          values.chapter
        )
      } else {
        UppdateSubChapter(
          values.id,
          values.name,
          values.quantity_unit,
          values.type_trade,
          values.parent_id,
          values.subchapter
        )
      }
    },
  })

  // const handleChangeIdSubChapter = (e) => {
  //   setIdSubChapter(e.target.id.value)
  // }
  // const handleChangeNameSubChapter = (e) => {
  //   setNameSubChapter(e.target.id.value)
  // }
  // const handleChangeNameDouanierSubChapter = (e) => {
  //   setnameDouanierSubChapter(e.target.id.value)
  // }

  // handle selection Chapter
  const handleChangeChapter = (value) => {
    setSelectedValueChapter(value)

    if (value) {
      // setSelectedValueChapter(value.value)
      formik.values.chapter = value.value
    } else {
      // setSelectedValueChapter(null)
      formik.values.chapter = ''
    }
  }

  // Hide Prdocut Modal
  const hideModalProduct = () => {
    // console.log(itemSelectedProduct)
    formik.resetForm()
    formik.initialValues.chapter = ''
    selectlRefChapter.current.clearValue()
    setSelectedValueChapter(null)
    setItemSelectedSubChapter(null)
    modalRefSubChapter.current.click()
  }

  // Function Update SubChapter
  const UppdateSubChapter = async (
    id,
    name,
    quantity_unit,
    type_trade,
    parent_id,
    subchapter
  ) => {
    const response = await axiosInstance
      .put('api/trade/subchapter/' + id + '/', {
        name: name,
        quantity_unit: quantity_unit,
        type_trade: type_trade,
        parent_id: parent_id,
        subchapter: subchapter,
      })
      .then((res) => {
        const data = res.data
        settitleMessage("L'opération s'est terminée avec succès.")
        setMessage(data.message)

        formik.resetForm()
        formik.initialValues.chapter = ''
        selectlRefChapter.current.clearValue()
        // modalRefProduct.current.click()
        setLoadingSubmit(false)
        hideModalProduct()
        setRefreshSubChapter(!refreshSubChapter)
        setShow(true)
      })
      .catch(function (error) {
        settitleMessage("L'opération a échoué.")
        setMessageError(error.response.data.message)
        formik.resetForm()
        formik.initialValues.chapter = ''

        selectlRefChapter.current.clearValue()
        setLoadingSubmit(false)
        hideModalProduct()
        setRefreshSubChapter(!refreshSubChapter)
        setShowError(true)
      })
  }

  // Function Add SubChapter
  const AddSubChapter = async (id, name, name_douanier, chapter) => {
    const response = await axiosInstance
      .post('api/trade/subchapter/', {
        id: id,
        name: name,
        name_douanier: name_douanier,
        chapter: chapter,
      })
      .then((res) => {
        const data = res.data
        settitleMessage("L'opération s'est terminée avec succès.")
        setMessage(data.message)

        formik.resetForm()
        formik.initialValues.chapter = ''
        // console.log(formik)
        selectlRefChapter.current.clearValue()
        setLoadingSubmit(false)
        hideModalProduct()
        setRefreshSubChapter(!refreshSubChapter)
        setShow(true)
      })
      .catch(function (error) {
        settitleMessage("L'opération a échoué.")
        setMessageError(error.response.data.message)
        formik.resetForm()
        formik.initialValues.chapter = ''

        selectlRefChapter.current.clearValue()
        setLoadingSubmit(false)
        hideModalProduct()
        setRefreshSubChapter(!refreshSubChapter)
        setShowError(true)
      })
  }

  useEffect(() => {
    formik.initialValues = {
      id: '',
      name: '',
      name_douanier: '',
      chapter: '',
    }
    formik.values.id = ''
    formik.values.name = ''
    formik.values.name_douanier = ''
    formik.resetForm()
    setSelectedValueChapter('')

    formik.values.id =
      (itemSelectedSubChapter !== null) & (dataTableSubChapter.length !== 0)
        ? dataTableSubChapter.find((x) => x.id === itemSelectedSubChapter).id
        : ''

    formik.values.name =
      (itemSelectedSubChapter !== null) & (dataTableSubChapter.length !== 0)
        ? dataTableSubChapter.find((x) => x.id === itemSelectedSubChapter).name
        : ''

    formik.values.name_douanier =
      (itemSelectedSubChapter !== null) & (dataTableSubChapter.length !== 0)
        ? dataTableSubChapter.find((x) => x.id === itemSelectedSubChapter)
            .name_douanier
        : ''

    setSelectedValueChapter(
      (itemSelectedSubChapter !== null) & (dataTableSubChapter.length !== 0)
        ? chapters[
            chapters
              .map((object) => object.value)
              .indexOf(
                dataTableSubChapter.find((x) => x.id === itemSelectedSubChapter)
                  .chapter
              )
          ]
        : ''
    )

    formik.values.chapter =
      (itemSelectedSubChapter !== null) & (dataTableSubChapter.length !== 0)
        ? dataTableSubChapter.find((x) => x.id === itemSelectedSubChapter)
            .chapter
        : ''
    // if (itemSelectedSubChapter != null) {
    //   if (dataTableSubChapter.length !== 0) {
    //     // setIdSubChapter(
    //     //   dataTableSubChapter.find((x) => x.id === itemSelectedSubChapter).id
    //     // )
    //     // formik.values.id = dataTableSubChapter.find(
    //     //   (x) => x.id === itemSelectedSubChapter
    //     // ).id
    //     // setNameSubChapter(
    //     //   dataTableSubChapter.find((x) => x.id === itemSelectedSubChapter).name
    //     // )
    //     // formik.values.name = dataTableSubChapter.find(
    //     //   (x) => x.id === itemSelectedSubChapter
    //     // ).name
    //     // setnameDouanierSubChapter(
    //     //   dataTableSubChapter.find((x) => x.id === itemSelectedSubChapter)
    //     //     .name_douanier
    //     // )
    //     // formik.values.name_douanier = dataTableSubChapter.find(
    //     //   (x) => x.id === itemSelectedSubChapter
    //     // ).name_douanier

    //     // setSelectedValueChapter(
    //     //   chapters[
    //     //     chapters
    //     //       .map((object) => object.value)
    //     //       .indexOf(
    //     //         dataTableSubChapter.find((x) => x.id === itemSelectedSubChapter)
    //     //           .chapter
    //     //       )
    //     //   ]
    //     // )
    //     formik.values.chapter = dataTableSubChapter.find(
    //       (x) => x.id === itemSelectedSubChapter
    //     ).chapter
    //   }
    // }
  }, [itemSelectedSubChapter])
  return (
    <div
      className='modal modal-blur fade'
      id='add-subchapter'
      tabIndex='-1'
      role='dialog'
      aria-hidden='true'
    >
      <div
        className='modal-dialog modal-lg modal-dialog-centered'
        role='document'
      >
        <div className='modal-content' style={{ borderColor: '#0073AD' }}>
          <div className='modal-header' style={{ borderColor: '#0073AD' }}>
            <h5 className='modal-title'>{titleModalSubchapter}</h5>
            {/* <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={hideModalProduct}
            ></button> */}
          </div>
          <form autoComplete='off' onSubmit={formik.handleSubmit}>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='mb-3'>
                    <label className='form-label'>Chapitre </label>

                    <Select
                      ref={selectlRefChapter}
                      value={selectedValueChapter}
                      defaultValue={chapters[0]}
                      options={chapters}
                      getOptionLabel={(e) => e.value + '-' + e.label}
                      getOptionValue={(e) => e.value}
                      id='chapter'
                      name='chapter'
                      isSearchable={true}
                      isClearable={true}
                      placeholder='Sélectionner le chapitre'
                      onChange={handleChangeChapter}
                    />
                    {formik.errors.chapter && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.chapter}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-2'>
                  <div className='mb-3'>
                    <label className='form-label'>Id </label>

                    <input
                      disabled={itemSelectedSubChapter != null ? true : false}
                      type='text'
                      className='form-control'
                      id='id'
                      name='id'
                      onChange={formik.handleChange}
                      value={formik.values.id}
                    />

                    {formik.errors.id && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.id}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-10'>
                  <div className='mb-3'>
                    <label className='form-label'>Nom </label>

                    <input
                      type='text'
                      className='form-control'
                      id='name'
                      name='name'
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />

                    {formik.errors.name && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.name}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='mb-3'>
                    <label className='form-label'>Nom douanier </label>

                    <input
                      type='text'
                      className='form-control'
                      id='name_douanier'
                      name='name_douanier'
                      onChange={formik.handleChange}
                      value={formik.values.name_douanier}
                    />

                    {formik.errors.name_douanier && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.name_douanier}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer' style={{ borderColor: '#0073AD' }}>
              <a
                href='#'
                className='btn btn-link link-secondary'
                data-bs-dismiss='modal'
                ref={modalRefSubChapter}
                onClick={hideModalProduct}
              >
                Annuler
              </a>
              {/* <a
                href='#'
                className='btn btn-primary ms-auto'
                // data-bs-dismiss='modal'
                // onClick={handleClick}
              >
                
                Enregister
              </a> */}
              <button
                type='submit'
                className='btn btn-primary ms-auto'
                style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
                disabled={loadingSubmit ? true : false}
              >
                <div className='pe-2'>Enregister</div>
                {loadingSubmit ? (
                  <div className='spinner-border ' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                ) : null}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ModalAddSubChapter
