import React, { useEffect, useRef, useState } from 'react'
import axiosInstance from '../../../utils/AxiosInstance'
import { useFormik } from 'formik'
import * as Yup from 'yup'
const ModalAddChapter = ({
  refreshChapter,
  setRefreshChapter,
  titleModalChapter,
  itemSelectedChapter,
  setItemSelectedChapter,
  dataTableChapter,
  setShow,
  setMessage,
  setShowError,
  setMessageError,
  settitleMessage,
}) => {
  const modalRefChapter = useRef()

  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const [initialValues, setInitialValues] = useState({
    id_chapter: '',
    name_chapter: '',
    name_douanier_chapter: '',
  })

  const validationSchema = Yup.object().shape({
    id_chapter: Yup.string()
      .min(2, 'Id du chapitre doit être  2 caractères')
      .max(2, 'Id du chapitre est trop long !')
      .trim()
      .required('Id du chapitre est obligatoire'),
    name_chapter: Yup.string()
      .trim()
      .required('Nom du chapitre est obligatoire.'),
    name_douanier_chapter: Yup.string()
      .trim()
      .required('Nom dounier du chapitre est obligatoire.'),
    // .test('user_name', "Le Nom d'utilisater exist déja", async (value) => {
    //   try {
    //     if (value != '' && value != null) {
    //       const res = await axiosInstance.post('serie/checkusername', {
    //         user_name: value,
    //       })
    //       return !res.data.username_exist
    //     } else return true
    //   } catch (err) {
    //     console.error(err)
    //   }
    // }),
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,

    onSubmit: (values, errors, touched) => {
      // buttonSubmitRef.current.disabled = true
      setLoadingSubmit(true)
      if (itemSelectedChapter == null) {
        AddChapter(
          values.id_chapter,
          values.name_chapter,
          values.name_douanier_chapter
        )
      } else {
        UpdateChapter(
          values.id_chapter,
          values.name_chapter,
          values.name_douanier_chapter
        )
      }
      // buttonSubmitRef.current.disabled = false
    },
  })

  // const handleChangeIdChapter = (e) => {
  //   setIdChapter(e.target.id.value)
  // }

  // Hide Prdocut Modal
  const hideModalChapter = () => {
    formik.resetForm()
    setItemSelectedChapter(null)
    modalRefChapter.current.click()
  }

  // Function Update SubChapter
  const UpdateChapter = async (id, name, name_douanier) => {
    const response = await axiosInstance
      .put('api/trade/chapter/' + id + '/', {
        name: name,
        name_douanier: name_douanier,
      })
      .then((res) => {
        const data = res.data
        settitleMessage("L'opération s'est terminée avec succès.")
        setMessage(data.message)

        formik.resetForm()
        // modalRefProduct.current.click()
        setLoadingSubmit(false)
        hideModalChapter()
        setRefreshChapter(!refreshChapter)
        setShow(true)
      })
      .catch(function (error) {
        settitleMessage("L'opération a échoué.")
        setMessageError(error.response.data.message)
        formik.resetForm()
        setLoadingSubmit(false)
        hideModalChapter()
        setRefreshChapter(!refreshChapter)
        setShowError(true)
      })
  }

  // Function Add SubChapter
  const AddChapter = async (id, name, name_douanier) => {
    const response = await axiosInstance
      .post('api/trade/chapter/', {
        id: id,
        name: name,
        name_douanier: name_douanier,
      })
      .then((res) => {
        const data = res.data
        settitleMessage("L'opération s'est terminée avec succès.")
        setMessage(data.message)

        formik.resetForm()
        setLoadingSubmit(false)
        hideModalChapter()
        setRefreshChapter(!refreshChapter)
        setShow(true)
      })
      .catch(function (error) {
        settitleMessage("L'opération a échoué.")
        setMessageError(error.response.data.message)
        formik.resetForm()
        setLoadingSubmit(false)
        hideModalChapter()
        setRefreshChapter(!refreshChapter)
        setShowError(true)
      })
  }

  useEffect(() => {
    // console.log(dataTableChapter)
    formik.initialValues = {
      id_chapter: '',
      name_chapter: '',
      name_douanier_chapter: '',
    }
    formik.values.id_chapter = ''
    formik.values.name_chapter = ''
    formik.values.name_douanier_chapter = ''
    formik.resetForm()

    formik.values.id_chapter =
      (itemSelectedChapter !== null) & (dataTableChapter.length !== 0)
        ? dataTableChapter.find((x) => x.id === itemSelectedChapter).id
        : ''

    formik.values.name_chapter =
      (itemSelectedChapter !== null) & (dataTableChapter.length !== 0)
        ? dataTableChapter.find((x) => x.id === itemSelectedChapter).name
        : ''

    formik.values.name_douanier_chapter =
      (itemSelectedChapter !== null) & (dataTableChapter.length !== 0)
        ? dataTableChapter.find((x) => x.id === itemSelectedChapter)
            .name_douanier
        : ''
  }, [itemSelectedChapter])

  return (
    <div
      className='modal modal-blur fade'
      id='add-chapter'
      tabIndex='-1'
      role='dialog'
      aria-hidden='true'
    >
      <div
        className='modal-dialog modal-lg modal-dialog-centered'
        role='document'
      >
        <div className='modal-content' style={{ borderColor: '#0073AD' }}>
          <div className='modal-header' style={{ borderColor: '#0073AD' }}>
            <h5 className='modal-title'>{titleModalChapter}</h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={hideModalChapter}
            ></button>
          </div>
          <form autoComplete='off' onSubmit={formik.handleSubmit}>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-lg-2'>
                  <div className='mb-3'>
                    <label className='form-label'>Id </label>

                    <input
                      disabled={itemSelectedChapter != null ? true : false}
                      type='text'
                      className='form-control'
                      id='id_chapter'
                      name='id_chapter'
                      onChange={formik.handleChange}
                      value={formik.values.id_chapter}
                    />

                    {formik.errors.id_chapter && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.id_chapter}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-10'>
                  <div className='mb-3'>
                    <label className='form-label'>Nom </label>

                    <input
                      type='text'
                      className='form-control'
                      id='name_chapter'
                      name='name_chapter'
                      onChange={formik.handleChange}
                      value={formik.values.name_chapter}
                    />

                    {formik.errors.name_chapter && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.name_chapter}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='mb-3'>
                    <label className='form-label'>Nom douanier </label>

                    <input
                      type='text'
                      className='form-control'
                      id='name_douanier_chapter'
                      name='name_douanier_chapter'
                      onChange={formik.handleChange}
                      value={formik.values.name_douanier_chapter}
                    />

                    {formik.errors.name_douanier_chapter && (
                      <div
                        style={{
                          width: '100%',
                          color: '#d63939',
                          fontSize: '85.714285%',
                          marginTop: '0.25rem',
                        }}
                      >
                        {formik.errors.name_douanier_chapter}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer' style={{ borderColor: '#0073AD' }}>
              <a
                href='#'
                className='btn btn-link link-secondary'
                data-bs-dismiss='modal'
                ref={modalRefChapter}
                onClick={hideModalChapter}
              >
                Annuler
              </a>

              <button
                type='submit'
                className='btn btn-primary ms-auto'
                style={{ backgroundColor: '#0073AD', color: '#ffffff' }}
                disabled={loadingSubmit ? true : false}
              >
                <div className='pe-2'>Enregister</div>
                {loadingSubmit ? (
                  <div className='spinner-border ' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                ) : null}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ModalAddChapter
